import React from 'react';
import { Grid, Icon, withStyles, withTheme } from '@material-ui/core';
import { getinlineStyleMarcador, getInlineStyleFaixas, getPosicaoMarcador } from '../fechamentoConsumoUtils';
const TAMANHO_GRAFICO_RANGE = 280;

export const fechamentoConsumoGrafico = props => {
  const { classes, faixaAceite, consumoFormula } = props;
  const { ConsumoOcorrido, ConsumoObjetivo } = consumoFormula;
  const posicaoMarcadorReal = getPosicaoMarcador(ConsumoOcorrido, ConsumoObjetivo);
  const { faixaIdeal, faixasAbaixo, faixasAcima } = getInlineStyleFaixas(
    ConsumoOcorrido,
    ConsumoObjetivo,
    faixaAceite
  );
  const inlineStyleMarcador = getinlineStyleMarcador(props.theme, posicaoMarcadorReal);
  return (
    <div className={classes.graficoContainer}>
      <Grid container className={classes.rangeContainer}>
        <Grid container item direction="row" justify="center">
          <div className={classes.marcadorReferencia}>
            <div className={classes.iconeCheck}>
              <Icon fontSize="small">check</Icon>
            </div>
            <div className={classes.linhaMarcadorReferencia} />
          </div>
        </Grid>
        <Icon style={inlineStyleMarcador}>arrow_drop_down</Icon>
        <Grid container direction="row" style={{ zIndex: 1 }} id="containerFaixasConsumo">
          <div style={faixasAbaixo} className={classes.range} />
          <div style={faixaIdeal} className={classes.range} />
          <div style={faixasAcima} className={classes.range} />
        </Grid>
      </Grid>
    </div>
  );
};

const styles = theme => {
  return {
    graficoContainer: {
      height: 110,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      width: TAMANHO_GRAFICO_RANGE
    },
    rangeContainer: {
      width: TAMANHO_GRAFICO_RANGE
    },
    marcadorReferencia: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: -10
    },
    linhaMarcadorReferencia: {
      width: 3,
      height: 35,
      backgroundColor: theme.palette.disabled.main,
      marginBottom: -10,
      zIndex: 0
    },
    range: {
      height: 7
    },
    iconeCheck: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.disabled.main,
      borderRadius: '50%',
      height: 20,
      width: 20,
      color: theme.palette.white.main
    }
  };
};
export default withTheme()(withStyles(styles)(fechamentoConsumoGrafico));
