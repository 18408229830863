import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import * as actions from "redux/actions";
import FormatadorNumericoCustomizado from "features/manejo/views/components/FormatadorNumericoCustomizado";
import FormatadorPorcentagemCustomizado from "features/manejo/views/components/FormatadorPorcentagemCustomizado";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  InputAdornment,
  Typography,
  withStyles,
} from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Icon from "@material-ui/core/Icon";
import { InfoOutlined } from "@material-ui/icons";
import CustomLabel from "features/shared/components/CustomLabel";
import SelectDropdown from "components/SelectDropdown";

export const FormPasture = ({
  classes,
  Pasto,
  PastosPrime,
  exibirCampoIdPrime,
  errosValidacao,
  Retiros,
  Forragens,
  handleChangeFormBasico,
  AreaNovoPasto,
  ListaCoordenadasPastos,
  habilitarDesenhar,
}) => {
  const hasModule = Pasto.IdModulo !== null;
  const erros = errosValidacao.Pasto;

  const listaRetiro = [
    {
      emptyKey: true,
      value: 0,
      disabled: true,
      label: "Selecione",
    },
    ...Retiros.map((retiro) => ({
      value: retiro.IdRetiro,
      label: retiro.Nome,
    })),
  ];

  const listaForragem = [
    {
      emptyKey: true,
      value: 0,
      disabled: true,
      label: "Selecione uma forragem",
    },
    ...Forragens.map((forragem) => ({
      value: forragem.IdForragem,
      label: forragem.Nome,
    })),
  ];

  const listaPastoPrime = (PastosPrime || []).map((pasto) => ({
    value: pasto.value,
    label: pasto.key,
  }));

  const ModuleWarnig = () => (
    <Grid
      xs={12}
      container
      justify="flex-start"
      alignItems="center"
      className={classes.moduleWarnig}
      wrap="nowrap"
      spacing={8}
    >
      <Grid item>
        <InfoOutlined color="primary" />
      </Grid>
      <Grid item>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ textAlign: "start" }}
        >
          Pastos vinculados a módulos não podem ter seu Retiro alterado. Caso
          deseje alterar o retiro você terá que desvinculá-lo do módulo.
        </Typography>
      </Grid>
    </Grid>
  );

  const onChangeRetiro = (value) => {
    const event = {
      target: {
        value,
      },
    }
    handleChangeFormBasico("IdRetiro", event);
  }

  const onChangeForragem = (value) => {
    const event = {
      target: {
        value,
      },
    }
    handleChangeFormBasico("IdForragem", event);
  }

  const onChangePastoPrime = (value) => {
    const event = {
      target: {
        value,
      },
    }
    handleChangeFormBasico("IdPastoPrime", event);
  }

  return (
    <div id="formBasicoComponent">
      {hasModule && <ModuleWarnig />}

      <Grid
        container
        alignItems="flex-start"
        direction="row"
        justify="flex-start"
        className={classes.formRow}
      >
        <TextField
          id="campoNome"
          placeholder="Informe um nome para o pasto"
          className={classes.textField}
          error={!!erros.Nome}
          helperText={erros.Nome}
          inputProps={{
            shrink: true,
            maxLength: 50,
            "data-testid": "campoNome",
          }}
          InputLabelProps={{ required: false, shrink: true }}
          label={<CustomLabel required label="Nome" />}
          onChange={(event) => handleChangeFormBasico("Nome", event)}
          required
          value={Pasto.Nome ? Pasto.Nome : ""}
        />
      </Grid>

      <Grid
        id="teste"
        container
        alignItems="flex-start"
        direction="row"
        justify="flex-start"
        className={classes.formRow}
      >
        <Grid item xs={12}>
          <TextField
            id="campoNomeReduzido"
            placeholder="Informe um apelido(opcional)"
            className={classes.textField}
            error={!!erros.NomeReduzido}
            helperText={erros.NomeReduzido}
            inputProps={{
              maxLength: 20,
            }}
            InputLabelProps={{ shrink: true }}
            label="Apelido"
            margin="normal"
            onChange={(event) => handleChangeFormBasico("NomeReduzido", event)}
            value={Pasto.NomeReduzido ? Pasto.NomeReduzido : ""}
          />
        </Grid>
      </Grid>

      <Grid
        id="teste"
        container
        alignItems="flex-start"
        direction="row"
        justify="flex-start"
        className={classes.formRow}
      >
        <Grid item xs={12}>
          <SelectDropdown
            id="cocho-retiro"
            label={{ text: "Retiro", required: true }}
            options={listaRetiro}
            value={Number(Pasto.IdRetiro)}
            onChange={onChangeRetiro}
            error={!!erros.Retiro}
            helperText={erros.Retiro}
            isDisabled={hasModule}
          />
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="flex-start"
        direction="row"
        justify="flex-start"
        spacing={16}
        className={classes.formRow}
      >
        <Grid item xs={6}>
          <TextField
            id="campoAreaAberta"
            placeholder="Informe a área"
            className={classes.textField}
            error={!!erros.AreaAberta}
            helperText={erros.AreaAberta}
            InputProps={{
              inputComponent: FormatadorNumericoCustomizado,
              endAdornment: <InputAdornment position="end">ha</InputAdornment>,
            }}
            label={
              <CustomLabel label="Área Aberta" tooltip="Área total do pasto" />
            }
            InputLabelProps={{
              shrink: true,
              className: classes.labelWithTooltip,
            }}
            margin="normal"
            disabled={true}
            onChange={(event) => handleChangeFormBasico("AreaAberta", event)}
            value={
              AreaNovoPasto
                ? AreaNovoPasto
                : Pasto.IdPasto && Pasto.AreaAberta
                  ? Pasto.AreaAberta
                  : ""
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="campoAreaEfetiva"
            placeholder="Informe a área"
            className={classes.textField}
            error={!!erros.AreaEfetiva}
            helperText={erros.AreaEfetiva}
            InputProps={{
              inputComponent: FormatadorPorcentagemCustomizado,
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            required={false}
            label={
              <CustomLabel
                required={false}
                label="Área Efetiva"
                tooltip="Área produtiva de pasto"
              />
            }
            InputLabelProps={{
              shrink: true,
              className: classes.labelWithTooltip,
            }}
            margin="normal"
            onChange={(event) => handleChangeFormBasico("AreaEfetiva", event)}
            value={Pasto.AreaEfetiva ? Pasto.AreaEfetiva : ""}
          />
        </Grid>
      </Grid>

      <Grid
        id="teste"
        container
        alignItems="flex-start"
        direction="row"
        justify="flex-start"
        className={classes.formRow}
      >
        <Grid item xs={12}>
          <SelectDropdown
            id="cocho-forragem"
            label={{ text: "Tipo da Forragem", required: true }}
            options={listaForragem}
            value={Number(Pasto.IdForragem)}
            onChange={onChangeForragem}
          />
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="flex-start"
        direction="row"
        justify="flex-start"
        spacing={16}
        className={classes.formRow}
      >
        <Grid item xs={12}>
          <TextField
            id="campoPotencialDeProducao"
            placeholder="Informe P. Produção"
            className={classes.textField}
            error={!!erros.PotencialDeProducao}
            helperText={erros.PotencialDeProducao}
            InputProps={{
              inputComponent: FormatadorNumericoCustomizado,
              endAdornment: (
                <InputAdornment position="end">kg/ha</InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: true }}
            label="P. Produção"
            margin="normal"
            onChange={(event) =>
              handleChangeFormBasico("PotencialDeProducao", event)
            }
            value={Pasto.PotencialDeProducao ? Pasto.PotencialDeProducao : ""}
          />
        </Grid>
        {exibirCampoIdPrime && (
          <Grid item xs={12}>
            <SelectDropdown
              id="pastos-prime"
              label={{ text: "Pasto Prime" }}
              options={listaPastoPrime}
              value={Number(Pasto.IdPastoPrime)}
              onChange={onChangePastoPrime}
            />
          </Grid>
        )}
      </Grid>

      {!Pasto.IdPasto && (
        <>
          <Grid item xs={12} className={classes.drawingPastureTitle}>
            Desenhar pastos
          </Grid>

          <Grid item xs={12} className={classes.drawingPastureMessage}>
            Para desenhar os pastos da fazenda no mapa ao lado, clique no botão
            abaixo. Para excluir, posicione o mouse sobre o pasto.
          </Grid>

          <Grid item xs={12}>
            <Button
              id="btnDesenhar"
              disabled={ListaCoordenadasPastos.length > 0}
              aria-label="Edit"
              onClick={() => habilitarDesenhar()}
              className={classes.drawing}
            >
              <Icon className={classes.buttonIcon}>brush</Icon>
              Desenhar pastos
            </Button>
          </Grid>
        </>
      )}
    </div>
  );
};

FormPasture.propTypes = {
  IdRetiro: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  IdFazenda: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  Pasto: PropTypes.object,
  errosValidacao: PropTypes.object,
  classes: PropTypes.object.isRequired,
  Retiros: PropTypes.array.isRequired,
  IdForragem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleChangeFormBasico: PropTypes.func.isRequired,
};

function mapStateToProps({ pasto }) {
  return {
    ListaCoordenadasPastos: pasto.CoordenadasNovoPasto,
    AreaNovoPasto: pasto.AreaNovoPasto,
  };
}

const styles = (theme) => ({
  moduleWarnig: {
    marginBottom: "1rem",
  },
  textField: {
    width: "100%",
    label: {
      transform: "translate(0, 1.5px) scale(0.95)",
      "transform-origin": "top left",
    },
  },
  formRow: {
    marginBottom: 8,
  },
  forragemErrorMessage: {
    textAlign: "center",
    color: "red",
    verticalAlign: "top",
    marginTop: "0px",
  },
  drawing: {
    marginTop: 16,
    textTransform: "none",
    color: "#FFFFFF",
    width: "100%",
    borderRadius: "8px",
    backgroundColor: "#4fd26b",
  },
  buttonIcon: {
    marginEnd: 6,
  },
  labelWithTooltip: {
    zIndex: 1,
  },
  drawingPastureTitle: {
    marginTop: 24,
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#212121",
  },
  drawingPastureMessage: {
    marginTop: 24,
    fontFamily: "Roboto",
    fontSize: "16px",
    lineHeight: 1.63,
    letterSpacing: "normal",
    color: "rgba(33, 33, 33, 0.7)",
  },
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, actions)
)(FormPasture);
