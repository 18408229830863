import { Tab, withStyles } from "@material-ui/core";
import React from "react";

export const ManejoTab = withStyles({
  tabWrapper: {
    alignItems: "flex-start",
    alignSelf: "end",
  },
  tabContainer: { width: "auto", padding: "4px 0px" },
  tabRoot: {
    minHeight: "auto",
    textTransform: "none",
    fontSize: "20px",
    fontFamily: "Roboto",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#212121",
  },
})((props) => (
  <Tab
    {...props}
    classes={{
      wrapper: props.classes.tabWrapper,
      labelContainer: props.classes.tabContainer,
      root: props.classes.tabRoot,
    }}
  />
));
