/*global google*/
import { PolygonF } from '@react-google-maps/api';
import React, { Component } from 'react';

class PoligonoMundo extends Component {
  state = {
    coordenadas: [
      new google.maps.LatLng(-85.1054596961173, -180),
      new google.maps.LatLng(85.1054596961173, -180),
      new google.maps.LatLng(85.1054596961173, 180),
      new google.maps.LatLng(-85.1054596961173, 180),
      new google.maps.LatLng(-85.1054596961173, 0)
    ],
    propriedades: {
      strokeColor: '#000000',
      strokeOpacity: 0.5,
      strokeWeight: 2,
      fillColor: '#000000',
      fillOpacity: 0.5,
      zIndex: -2,
      cursor: 'grab'
    }
  };

  render() {
    return (
      <PolygonF
        paths={this.state.coordenadas}
        options={{
          ...this.state.propriedades,
        }}
        onClick={this.props.onClick}
        onMouseMove={this.props.onMouseMove}
        onMouseOver={this.props.onMouseOver}
      />
    );
  }
}

export default PoligonoMundo;
