import { useState, useEffect, useCallback } from "react";
import localConstants from "../views/shared/constants";
import moment from "moment";
import { calcPeriodMonthTitles } from "shared/utils";

const initialGoalPlanData = {
  name: "",
  initialDate: null,
  finalDate: null,
  goals: [],
};

export const useGoalPlanDataForm = () => {
  const [goalPlanData, setGoalPlanData] = useState(initialGoalPlanData);
  const [periodTitles, setPeriodTitles] = useState([]);

  const calcPeriodTitles = useCallback(() => {
    const titles = calcPeriodMonthTitles(
      goalPlanData.initialDate,
      localConstants.dateMonthFormat
    );
    setPeriodTitles(titles);
  }, [goalPlanData.initialDate]);

  const changeGoalPlanName = (name) => {
    setGoalPlanData({ ...goalPlanData, name });
  };

  const changeGoalPlanInitialDate = (initialDate) => {
    setGoalPlanData({ ...goalPlanData, initialDate });
  };

  const changeFinalDate = (finalDate) => {
    setGoalPlanData({ ...goalPlanData, finalDate });
  };

  useEffect(() => {
    if (goalPlanData.initialDate) {
      const finalDateClone = moment(goalPlanData.initialDate).clone();
      finalDateClone.add(11, "M");
      changeFinalDate(finalDateClone);
      calcPeriodTitles();
    } else {
      changeFinalDate(null);
      setPeriodTitles([]);
    }
  }, [goalPlanData.initialDate]);

  return {
    goalPlanData,
    periodTitles,
    changeGoalPlanName,
    changeGoalPlanInitialDate,
    setGoalPlanData,
  };
};
