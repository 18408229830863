import React from "react";
import LotesPage from "./lotesPage";
import * as LotesOperations from "../redux/loteOperations";
import * as ObjetivoOperations from "../../objetivo/redux/objetivoOperations";
import * as RetiroOperations from "../../retiro/redux/retiroOperations";
import * as categoriaOperations from "../../categoria/redux/categoriaOperations";
import * as pastoActions from "features/manejo/redux/actions/pastoActions";

import { connect } from "react-redux";
import {
  deveExibirColunaPesoProjetado,
  deveExibirColunaSanidade,
  deveExibirTrocaCategoria,
} from "../../shared/utils";
import { mapeiaRetirosLotes } from "../loteUtils";

export const LotesContainer = (props) => {
  return (
    <LotesPage
      id="lotesPage"
      lotes={props.lotesPorPasto}
      retiros={props.retiros}
      pastos={props.pastos}
      objetivos={props.objetivos}
      obterLotesReduzidosPorPastos={props.obterLotesReduzidosPorPastos}
      obterCategoriasFazenda={props.obterCategoriasFazenda}
      categorias={props.categorias}
      atualizarLotes={props.atualizarLotes}
      listaObjetivos={props.listaObjetivos}
      listarRetiros={props.listarRetiros}
      exibirColunaPesoProjetado={props.exibirColunaPesoProjetado}
      projecaoPesoSemestre={props.projecaoPesoSemestre}
      obterProjecaoSemestrePorLote={props.obterProjecaoSemestrePorLote}
      exibirColunaSanidade={props.exibirColunaSanidade}
      exibirTrocaCategoria={props.exibirTrocaCategoria}
      fazendaSelecionada={props.fazendaSelecionada}
      obtemPastosFazenda={props.obtemPastosFazenda}
      obterCurvaGanhoPeso={props.obterCurvaGanhoPeso}
      listaCurvaGanhoPeso={props.listaCurvaGanhoPeso}
      fazenda={props.fazenda}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    fazendaSelecionada: state.fazenda.FazendaSelecionada,
    fazenda: state.fazenda,
    retiros: state.retiro.Retiros,
    pastos: state.pasto.Pastos,
    lotesPorPasto: mapeiaRetirosLotes(
      state.lote.LotesPorPasto,
      state.pasto.Pastos,
      state.retiro.Retiros
    ),
    exibirColunaPesoProjetado: deveExibirColunaPesoProjetado(state),
    objetivos: state.objetivo.Objetivos,
    categorias: state.categoria.categorias,
    projecaoPesoSemestre: state.lote.ProjecaoPesoSemestre,
    exibirColunaSanidade: deveExibirColunaSanidade(state),
    exibirTrocaCategoria: deveExibirTrocaCategoria(state),
    listaCurvaGanhoPeso: state.lote.CurvaGanhoPeso,
  };
};

const mapDispatchToProps = {
  listarRetiros: RetiroOperations.listarRetiros,
  obterLotesReduzidosPorPastos: LotesOperations.obterLotesReduzidosPorPastos,
  listaObjetivos: ObjetivoOperations.listarObjetivos,
  atualizarLotes: LotesOperations.atualizarLotes,
  obterProjecaoSemestrePorLote: LotesOperations.obterProjecaoSemestrePorLote,
  obtemPastosFazenda: pastoActions.obtemPastosFazenda,
  obterCurvaGanhoPeso: LotesOperations.obterCurvaGanhoPeso,
  obterCategoriasFazenda: categoriaOperations.obterCategoriasFazenda,
};

export default connect(mapStateToProps, mapDispatchToProps)(LotesContainer);
