import csvtojson from "csvtojson";
import jsontocsv from "json2csv";
import downloadArquivo from "lib/downloadArquivo";
import CochoService from "./CochoService";
import PastoService from "./PastoService";
import ForragemService from "./ForragemService";
import ModuloService from "./ModuloService";
import _ from "lodash";

const pastureFields = [
  "IdPasto",
  "Nome",
  "NomeReduzido",
  "NomeRetiro",
  "NomeModulo",
  "AreaAberta(ha)",
  "AreaEfetiva(%)",
  "PotencialDeProducao",
  "IdForragem",
  "NomeForragem",
];

const troughFields = [
  "Coberto",
  "Elevado",
  "Comprimento",
  "EstoqueFinal",
  "Compartilhado",
  "IdCocho",
];

export async function exportPastureCSV(IdFazenda) {
  try {
    let pasturesResponse = await PastoService.listaPastosPorIdFazenda(
      IdFazenda,
      [
        "IdPasto",
        "Nome",
        "NomeReduzido",
        "IdRetiro",
        "IdModulo",
        "AreaAberta",
        "AreaEfetiva",
        "PotencialDeProducao",
        "IdForragem",
      ],
      ["Modulo", "Cocho", "Retiro"]
    );

    if (!pasturesResponse.erro) {
      const forages = await ForragemService.obterForragens();

      const pastosFazenda = pasturesResponse.data.map((pastoFazenda) => {
        const pastureRetire = pastoFazenda.Retiro
          ? pastoFazenda.Retiro.NomeReduzido || pastoFazenda.Retiro.Nome
          : "";
        const pastureModule = pastoFazenda.Modulo
          ? pastoFazenda.Modulo.NomeReduzido || pastoFazenda.Modulo.Nome
          : "";
        const pastureForage = forages.data
          ? forages.data.find((f) => f.IdForragem === pastoFazenda.IdForragem)
          : null;
        const openedArea = pastoFazenda.AreaAberta
          ? pastoFazenda.AreaAberta.toString().replace(".", ",")
          : undefined;
        const effectiveArea = pastoFazenda.AreaEfetiva;

        const pasture = _.pick(pastoFazenda, pastureFields);
        const activeTrough = pastoFazenda.PastoCochos
          ? pastoFazenda.PastoCochos.find(
              (e) =>
                !e.CochoCreep && !e.DataExclusao && !e.PastoCocho.DataValidade
            )
          : null;

        const trough = activeTrough
          ? {
              IdCocho: activeTrough.IdCocho,
              Coberto: trocarCampoBoolean(activeTrough.Coberto),
              Elevado: trocarCampoBoolean(activeTrough.Elevado),
              EstoqueFinal: trocarCampoBoolean(activeTrough.EstoqueFinal),
              Comprimento: activeTrough.Comprimento,
              Compartilhado: trocarCampoBoolean(
                activeTrough.CochoCompartilhado
              ),
            }
          : null;

        return {
          ...pasture,
          ...trough,
          NomeRetiro: pastureRetire,
          NomeModulo: pastureModule,
          "AreaAberta(ha)": openedArea,
          "AreaEfetiva(%)": effectiveArea,
          NomeForragem: pastureForage ? pastureForage.Nome : "",
        };
      });

      const dadosCSV = [...pastosFazenda];
      const csvfields = [...pastureFields, ...troughFields];
      const opts = { fields: csvfields, delimiter: ";", encoding: "utf-8" };
      const csv = jsontocsv.parse(dadosCSV, opts);
      downloadArquivo("Pastos.csv", csv);
    } else {
      return { erro: "Não foi possível buscar pastos ou cochos" };
    }
  } catch (erro) {
    return { erro };
  }
}

export async function saveCSV(csv, IdFazenda) {
  try {
    console.log("salvaDadosCSV");
    const objetCsv = await csvtojson({ delimiter: "\t" }).fromString(
      csv.replace(/;/g, "\t")
    );

    // Encerrar se nenhum pasto for informado
    if (!objetCsv.length) return { erro: "CSV vazio" };

    const pasturesToUpdate = [];
    const pasturesWithError = [];
    let troughsToUpdate = [];
    let troughsWithError = [];

    let { data: pastosFazenda } = await PastoService.listaPastosPorIdFazenda(
      IdFazenda,
      ["IdPasto"]
    );

    objetCsv.forEach((pastureTroughCSV) => {
      const pastureExists = pastosFazenda.find(
        (p) => p.IdPasto.toString() === pastureTroughCSV.IdPasto
      );

      // Ignorar se idpasto não for encontrado para editar
      if (!pastureExists) {
        pasturesWithError.push(pastureTroughCSV.Nome);
      } else {
        const pastureHasRequiredFields = [
          pastureTroughCSV.Nome,
          pastureTroughCSV.NomeReduzido,
          pastureTroughCSV.IdForragem,
        ].every((value) => value !== "");

        const pastureHasFieldsToSave = [
          pastureTroughCSV.Nome,
          pastureTroughCSV.NomeReduzido,
          pastureTroughCSV["AreaEfetiva(%)"],
          pastureTroughCSV.PotencialDeProducao,
          pastureTroughCSV.IdForragem,
        ].some((value) => value !== "");

        const isPastureValid =
          pastureHasRequiredFields && pastureHasFieldsToSave;

        if (isPastureValid) {
          pasturesToUpdate.push({
            IdPasto: pastureTroughCSV.IdPasto,
            Nome: pastureTroughCSV.Nome,
            NomeReduzido: pastureTroughCSV.NomeReduzido,
            AreaEfetiva: trataCasaDecimal(pastureTroughCSV["AreaEfetiva(%)"]),
            PotencialDeProducao: trataCasaDecimal(
              pastureTroughCSV.PotencialDeProducao
            ),
            IdForragem: pastureTroughCSV.IdForragem,
          });
        }

        const troughHasFieldsToSave = [
          pastureTroughCSV.Comprimento,
          pastureTroughCSV.Coberto,
          pastureTroughCSV.Elevado,
          pastureTroughCSV.EstoqueFinal,
        ].some((value) => value !== "");

        if (troughHasFieldsToSave) {
          troughsToUpdate.push({
            IdPasto: Number(pastureTroughCSV.IdPasto),
            Comprimento: trataCasaDecimal(pastureTroughCSV.Comprimento),
            Coberto: trataCampoBooleano(pastureTroughCSV.Coberto),
            Elevado: trataCampoBooleano(pastureTroughCSV.Elevado),
            EstoqueFinal: trataCampoBooleano(pastureTroughCSV.EstoqueFinal),
            CochoCreep: false,
            IdCocho: Number(pastureTroughCSV.IdCocho),
          });
        }
      }
    });

    const sharedTroughGroups = await seachSharedTroughsGroup(
      troughsToUpdate,
      IdFazenda
    );

    Object.entries(sharedTroughGroups).forEach(([id, group]) => {
      const allEqual = group.every((val, i, g) =>
        _.isEqual(_.omit(val, "IdPasto"), _.omit(g[0], "IdPasto"))
      );
      if (!allEqual) {
        troughsWithError.push(group[0]);
        troughsToUpdate = troughsToUpdate.filter((t) => t.IdCocho !== id);
      }
    });

    if (pasturesToUpdate.length) {
      try {
        await PastoService.atualizaMultiplosPastos(pasturesToUpdate);
      } catch (error) {}
    }
    if (troughsToUpdate.length) {
      try {
        await CochoService.atualizaMultiplosCochos(troughsToUpdate);
      } catch (error) {}
    }

    await ModuloService.excluirModulosSemVinculo(IdFazenda);

    const pastureError = pasturesWithError.length
      ? {
          warning: `Os seguintes pastos não foram atualizados e apresentam erros nos dados: ${pasturesWithError.join(
            ", "
          )}`,
        }
      : null;
    const troughError = troughsWithError.length
      ? {
          warning: `Os seguintes Cochos compartilhados não foram atualizados e apresentam erros nos dados: ${troughsWithError.join(
            ", "
          )}`,
        }
      : null;
    return pastureError || troughError || {};
  } catch (erro) {
    console.log("Error salvar CSV :", erro);
    return {
      erro: "Erro ao tentar salvar dados CSV",
    };
  }
}

export const trocarCampoBoolean = (field) => {
  if (field === null || field === undefined) return null;
  return field.toString() === "true" ? "Sim" : "Não";
};
export const trataCasaDecimal = (campo) => {
  if(campo === "") {
    return null;
  }
  const campoParaNumero = parseFloat(campo.replace(/\./g, "").replace(/,/g, "."));
  if(!isNaN(campoParaNumero)) {
    return campoParaNumero;
  } else {
    return null;
  }
}
export const trataCampoBooleano = (campo) => {
  if (campo && campo.length)
    return (
      campo.toLowerCase() === "true" ||
      campo.toLowerCase() === "sim" ||
      campo.toLowerCase() === "1"
    );
  return false;
};
async function seachSharedTroughsGroup(troughsToUpdate, IdFazenda) {
  const troughsIds = [...new Set(troughsToUpdate.map((t) => t.IdCocho))];
  const farmTroughs =
    (await CochoService.getCochos(IdFazenda, {
      IdCocho: troughsIds,
    })) || [];
  const farmSharedTroughs = farmTroughs.filter(
    (t) => t.PastoCochos.filter((pc) => !pc.DataValidade).length > 1
  );
  troughsToUpdate.forEach((t) => {
    if (farmSharedTroughs.some((st) => st.IdCocho === t.IdCocho)) {
      t.Compartilhado = true;
    }
  });

  const sharedTroughGroups = _.groupBy(
    troughsToUpdate.filter((t) => t.Compartilhado),
    "IdCocho"
  );
  return sharedTroughGroups;
}
