import React, { useState, useEffect, useMemo } from "react";
import MovimentacaoTabela from "./movimentacaoTabela";
import Button from "@material-ui/core/Button";
import { Grid, withStyles, Typography, Paper } from "@material-ui/core";
import MovimentacaoForm from "./movimentacaoForm";
import { DialogoConfirmacaoAcao } from "features/shared/components/DialogoConfirmacaoAcao";
import { enviaDadosGAPorRotulo, getComparator, stableSort } from "../../../shared/utils.js";
import constants from "constants/global";

export const MovimentacaoPage = ({
  fazendaSelecionada,
  obterMovimentacoes,
  obterTiposMovimentacao,
  criarMovimentacao,
  deletarMovimentacao,
  atualizarMovimentacao,
  movimentacoes,
  tiposMovimentacao,
  obterCategoriasFazenda,
  categorias,
  classes
}) => {

  const getDefaultMovimentacao = (override) => {
    return {
      IdRegistro: null,
      IdFazenda: fazendaSelecionada,
      IdCategoriaAnimal: null,
      TipoRegistro: null,
      TipoMovimentacao: null,
      Confirmado: null,
      Quantidade: null,
      PesoPesado: null,
      RendimentoDeCarcaca: null,
      DataRegistro: Date.now(),
      ...override,
    };
  };
  const [confirmacaoExclusaoAberto, setConfirmacaoExclusaoAberto] = useState(false);
  const [adicionando, setAdicionando] = useState(false);
  const [movimentacao, setMovimentacao] = useState(getDefaultMovimentacao());
  const [movimentacaoClicada, setMovimentacaoClicada] = useState({ IdRegistro: null });
  const [categoriasAnimais, setcategoriasAnimais] = useState(categorias);
  const [order, setOrder] = useState(constants.orderBy.desc);
  const [orderBy, setOrderBy] = useState();
  const [rendimentoCarcadaError, setRendimentoCarcadaError] = useState(false);


  const enviaDadosMovimentacoesGA = () => {
    const acao = "Ver movimentações";
    const categoria = "Análise";
    const rotulo = "Entradas e saídas";
    enviaDadosGAPorRotulo(acao, rotulo, categoria);
  };

  const enviaDadosCriarMovimentacaoGA = () => {
    const acao = "Criar movimentação";
    const categoria = "Entrada e Saida";
    const rotulo = `idFazenda ${fazendaSelecionada}`;
    enviaDadosGAPorRotulo(acao, rotulo, categoria);
  };

  useEffect(() => {
    obterMovimentacoes(fazendaSelecionada);
    obterCategoriasFazenda(false);
    obterTiposMovimentacao();
    enviaDadosMovimentacoesGA()
  }, []);

  const excluirMovimentacao = async (IdRegistro) => {
    await deletarMovimentacao(IdRegistro);
    await obterMovimentacoes();
    setMovimentacao(getDefaultMovimentacao());
    setAdicionando(false)
  };

  const abreDialogExclusao = (movimentacao) => {
    setConfirmacaoExclusaoAberto(true);
    setMovimentacaoClicada(movimentacao)
  };


  const movimetacoesOrdenadas = useMemo(() => {
    if(orderBy === 'DataRegistro') {
      return stableSort(movimentacoes, getComparator(order, orderBy))
    }
     return movimentacoes
  }, [movimentacoes, order, orderBy]);
  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === constants.orderBy.asc;
    setOrder(isAsc ? constants.orderBy.desc : constants.orderBy.asc);
    setOrderBy(property);
  };


  const habilitarMovimentacao = (acao) => {
    setAdicionando(acao);
    setMovimentacao(getDefaultMovimentacao({ Confirmado: 1 }));
    setcategoriasAnimais(categorias);
  };

  const habilitarEdicao = (movimentacao) => {
    setAdicionando(true);
    setMovimentacao(movimentacao);
  };

  const handleChangeCategoria = (value) => {
    setMovimentacao(prevState => ({
      ...prevState,
      IdCategoriaAnimal: value
    }))
  };

  const handleChangeTipoRegistro = (value) => {
    setMovimentacao(prevState => ({
      ...prevState,
      TipoRegistro: value,
      TipoMovimentacao: null,
    }))
  };
  const montaTextoMovimentacao = (movimentacao) => {
    switch (movimentacao.TipoMovimentacao) {
      case 1: {
        return "Compra";
      }
      case 2: {
        return "Transferência";
      }
      case 3: {
        return "Venda";
      }
      default: {
        return "-";
      }
    }
  };

  const handleChangeTipoMovimentacao = (value) => {
    setMovimentacao(prevState => ({
      ...prevState,
      TipoMovimentacao: value,
    }))

  };

  const handleChangeQuantidade = ({ target: { value } }) => {
    const valorConvertidoEmNumero = Number(value);
    if (
      !isNaN(valorConvertidoEmNumero) &&
      Number.isInteger(valorConvertidoEmNumero)
    ) {
      setMovimentacao(prevState => ({
        ...prevState,
        Quantidade: valorConvertidoEmNumero
      }))
    }
  };
  const handleChangePesoPesado = ({ target: { value } }) => {
    const valorConvertidoEmNumero = Number(value);
    if (
      !isNaN(valorConvertidoEmNumero) &&
      Number.isInteger(valorConvertidoEmNumero)
    ) {
      setMovimentacao(prevState => ({
        ...prevState,
        PesoPesado: valorConvertidoEmNumero
      }))
    }
  };

  const formataRendimentoCarcaca = (value) => {
    let inputValue = `${value}`.replace(".", ",");
    if (inputValue.includes(",")) {
      const splitValue = inputValue.split(",");
      if (splitValue[1] && splitValue[1].length > 2) {
        splitValue[1] = splitValue[1].substr(0, 2);
      }
      inputValue = splitValue.join(",");
    }
    return inputValue;
  };

  const handleChangeRendimentoCarcada = ({ target: { value } }) => {
      const valorFormatado = formataRendimentoCarcaca(value);
      const valorConvertidoEmNumero = parseFloat(valorFormatado);
      setMovimentacao(prevState => ({
        ...prevState,
        RendimentoDeCarcaca: valorFormatado
      }))
      if (valorConvertidoEmNumero <= 100) {
        setRendimentoCarcadaError(false);
      } else {
        setRendimentoCarcadaError(true);
      }
  };

  const fechaDialogExclusao = async () => {
    setConfirmacaoExclusaoAberto(false);
  };

  const confirmaMovimentacao = async (movimentacao) => {
    let movimentacaoConfirmada = {
      IdRegistro: movimentacao.IdRegistro,
      Confirmado: true,
    };
    await atualizarMovimentacao(movimentacaoConfirmada);
    await obterMovimentacoes();
  };

  const adicionarMovimentacao = async (movimentacao) => {
    if (movimentacao.IdRegistro) {
      await atualizarMovimentacao(movimentacao);
    } else {
      enviaDadosCriarMovimentacaoGA();
      await criarMovimentacao(movimentacao);
    }
    await obterMovimentacoes();
    setMovimentacao(getDefaultMovimentacao());
    setRendimentoCarcadaError(false);
    setAdicionando(false)
  };

  const handleChangeDataRegistro = (data) => {
    setMovimentacao(prevState => ({
      ...prevState,
      DataRegistro: data,
    }))
  };


  return (
    <React.Fragment>
      <Paper square className={classes.root}>
        <Grid item sm={12} xs={12} className={classes.containerPage}>
          <div className={classes.containerCabecalho}>
            <div>
              <Typography className={classes.titulo}>
                Registros de Entradas e Saídas
              </Typography>
            </div>
            <div>
              {!adicionando ? (
                <Button
                  id="botaoAdicionar"
                  onClick={() => habilitarMovimentacao(true)}
                  variant="contained"
                  color="primary"
                  className={classes.botaoNovo}
                >
                  Criar Novo
                </Button>
              ) : null}
            </div>
          </div>
          <Typography class={classes.warningText}>
            <img
              src="../images/ic_warning_outlined.svg"
              alt="icone de alerta"
              class={classes.warningIcon}
            />
            <b>Atenção!</b> Os lançamentos de entradas e saídas realizadas na plataforma, são utilizadas somente
            para realizar a conciliação entre as informações inseridas aqui na plataforma, com as informações
            inseridas no aplicativo pelo vaqueiro, ou seja, as informações criadas na plataforma FarmTell Views,
            não reflete no aplicativo do vaqueiro.
          </Typography>
          {adicionando ? (
            <Paper square className={classes.corpoAdicionar}>
              <MovimentacaoForm
                id="formAdicionar"
                classes={classes}
                categorias={categorias}
                movimentacao={movimentacao}
                tiposMovimentacao={tiposMovimentacao}
                rendimentoCarcadaError={rendimentoCarcadaError}
                handleChangeCategoria={handleChangeCategoria}
                habilitarMovimentacao={habilitarMovimentacao}
                handleChangeTipoRegistro={handleChangeTipoRegistro}
                handleChangeTipoMovimentacao={
                  handleChangeTipoMovimentacao
                }
                handleChangeQuantidade={handleChangeQuantidade}
                handleChangeDataRegistro={handleChangeDataRegistro}
                abreDialogExclusao={abreDialogExclusao}
                adicionarMovimentacao={adicionarMovimentacao}
                handleChangePesoPesado={handleChangePesoPesado}
                handleChangeRendimentoCarcada={
                  handleChangeRendimentoCarcada
                }
              />
            </Paper>
          ) : null}{" "}
          <Paper square className={classes.corpoTabela}>
            <MovimentacaoTabela
              id="movimentacaoTabela"
              movimentacoes={movimetacoesOrdenadas}
              adicionando={adicionando}
              categorias={categoriasAnimais}
              order={order}
              orderBy={orderBy}
              handleRequestSort={handleRequestSort}
              habilitarEdicao={habilitarEdicao}
              confirmaMovimentacao={confirmaMovimentacao}
              montaTextoMovimentacao={montaTextoMovimentacao}
            ></MovimentacaoTabela>
          </Paper>
        </Grid>
      </Paper>
      <DialogoConfirmacaoAcao
        id={`dialogConfirmaExclusaoMovimentacao`}
        tituloDialog={"Confirmar exclusão de Entrada e Saída"}
        textoDialog={
          <span id="mensagemConfirmaExclusaoMovimentação">
            Você deseja excluir a movimentação?
          </span>
        }
        dialogAberto={confirmacaoExclusaoAberto}
        cancelarAcaoDialog={fechaDialogExclusao}
        objetoSelecionado={movimentacaoClicada.IdRegistro}
        excluir={excluirMovimentacao}
      />
    </React.Fragment>
  );
};
const styles = (theme) => {
  return {
    containerPage: {
      flexgrow: 1,
      height: "100%",
      backgroundColor: "#fff",
      width: "100%",
    },
    tabs: {
      position: "fixed",
      flexGrow: 1,
      top: `64px`,
      width: "100%",
      backgroundColor: "#ccc",
      zIndex: 999,
      boxShadow: "0 1px 3px 1px rgba(0, 0, 0, 0.5)",
    },
    root: {
      width: "100%",
      height: "100%",
      overflowX: "auto",
      padding: "46px",
      zIndex: -1,
      bottom: 0,
      top: "17%",
      minHeight: window.innerHeight,
    },
    botaoNovo: {
      borderRadius: "18px",
      marginTop: "4%",
    },
    corpoTabela: {
      width: "100%",
      marginTop: "4vh",
      borderRadius: "16px",
    },
    corpoAdicionar: {
      width: "100%",
      marginTop: "10px",
      padding: "20px",
      marginBottom: "20px",
    },
    titulo: {
      fontSize: "20px",
      fontWeight: "bold",
      fontStyle: "medium",
      fontStretch: "medium",
      lineHeight: "medium",
      letterSpacing: "medium",
      color: "#333333",
      marginBottom: "2%",
    },
    formContainer: {
      marginTop: "20px",
    },
    inputFiltro: {
      width: "400px",
    },
    warningIcon: {
      marginRight: "4px",
      marginBottom: "-3px",
      height: "20px",
      color: "red",
    },
    warningText: {
      fontFamily: "Roboto",
      lineHeight: 1.71,
      color: "#666",
      fontSize: "0.9rem",
      marginTop: "4vh"
    },
    containerCabecalho: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
      marginTop: "40px"
    },
  };
};

export default withStyles(styles)(MovimentacaoPage);
