import React from "react";
import MovementHistoryListTable from "./components/movementHistoryListTable";

const MovementHistoryListPage = ({
  handleRequestSort,
  handleSelectAllClick,
  handleClick,
  handleChangePage,
  handleChangeRowsPerPage,
  selected,
  order,
  orderBy,
  historicoMovimentacao,
  rowsPerPage,
  page,
}) => {
  return (
    <MovementHistoryListTable
      handleRequestSort={handleRequestSort}
      handleSelectAllClick={handleSelectAllClick}
      handleClick={handleClick}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      selected={selected}
      order={order}
      orderBy={orderBy}
      historicoMovimentacao={historicoMovimentacao}
      rowsPerPage={rowsPerPage}
      page={page}
    />
  );
};

export default MovementHistoryListPage;
