import React from 'react';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';

export const Breadcrumbs = props => {
  const { classes, etapas, callbackClique } = props;

  function gerarLinks(etapas) {
    const ultimaEtapa = _.last(etapas);

    return etapas.map(etapa => (
      <Button
        key={etapa.titulo}
        id={`etapa_${etapa.titulo}`}
        onClick={() => callbackClique(etapa.titulo)}
        disabled={etapa === ultimaEtapa}
        className={classes.botaoEtapa}>
        <Typography className={etapa === ultimaEtapa ? classes.ultimaEtapa : classes.etapa}>
          {etapa === ultimaEtapa ? etapa.titulo : `${etapa.titulo} >`}
        </Typography>
      </Button>
    ));
  }

  return (
    <div className={classes.geral}>
      <div className={classes.barra}>
        {gerarLinks(etapas)}
      </div>
      <div className={classes.linha} />
    </div>
  );
};

const styles = () => ({
  geral: {
    width: '100%',
    position: 'fixed',
    zIndex: '1001',
    marginTop: '-1px'
  },
  barra: {
    width: '100%',
    height: '60px',
    paddingLeft: '30px',
    backgroundColor: '#f8f8f8'
  },
  linha: {
    width: '100%',
    height: '1px',
    border: 'solid 1px #dadada'
  },
  botaoEtapa: {
    height: '100%'
  },
  etapa: {
    height: '28px',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.05px',
    color: '#2a2a2a',
    textTransform: 'none'
  },
  ultimaEtapa: {
    height: '28px',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontWeight: '500',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.05px',
    color: '#2a2a2a',
    textTransform: 'none'
  }
});

export default withStyles(styles)(Breadcrumbs);
