export const LISTA_TIPO_MOVIMENTACAO = 'LISTA_TIPO_MOVIMENTACAO';


export const listarTiposMovimentacao = tiposMovimentacao => {
  return {
    type: LISTA_TIPO_MOVIMENTACAO,
    payload: {
      TiposMovimentacao: tiposMovimentacao
    }
  };
};
