import BaseService from "./BaseService";

const URL_BASE = `${process.env.REACT_APP_ENDPOINT}farois/api/v1/coletas`;

class ColetaService extends BaseService {
  async obterColetasComGeolocalizacao(IdFazenda, periodoColeta) {
    const dataFim = periodoColeta.final.format("YYYY-MM-DD");
    const dataInicio = periodoColeta.inicio.format("YYYY-MM-DD");

    return this.get(
      `${URL_BASE}/${IdFazenda}/altura-pasto?DataFim=${dataFim}&DataInicio=${dataInicio}&IdFazenda=${IdFazenda}`
    );
  }
}

export default new ColetaService();
