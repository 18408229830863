import React from "react";
import * as actions from "redux/actions";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import NotificacaoBase from "./NotificacaoBase";

class Notificacao extends React.Component {
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.props.fecharNotificacao();
  };

  render() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={this.props.notificacao.aberto}
        autoHideDuration={6000}
        onClose={this.handleClose}
      >
        <NotificacaoBase
          onClose={this.handleClose}
          variant={this.props.notificacao.estilo}
          message={this.props.notificacao.mensagem}
        />
      </Snackbar>
    );
  }
}

function mapStateToProps({ notificacao }) {
  return { notificacao };
}

export default connect(mapStateToProps, actions)(Notificacao);
