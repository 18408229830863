import React, { useState } from "react";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Grid, Button, Collapse, withStyles } from "@material-ui/core";
import { TEXTODETALHES, INFORMACAOBEZERROS } from "../fechamentoConsumoTextos";

const FechamentoConsumoDetalhes = ({ classes }) => {
  const [collapseFilter, setCollapseFilter] = useState(false);
  const {
    actionButton,
    containerDetalhes,
    collapseStyle,
    spanValor,
    spanDescricao,
    spanAviso,
  } = classes;
  return (
    <Grid
      container
      direction="column"
      className={containerDetalhes}
      alignItems="flex-start"
      justify="center"
    >
      <Button
        className={actionButton}
        onClick={() => setCollapseFilter(!collapseFilter)}
      >
        Detalhes
        {!collapseFilter ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
      </Button>
      <Collapse in={collapseFilter} className={collapseStyle}>
        {TEXTODETALHES.map(({ valor, descricao, style = {} }) => (
          <div key={valor} style={{ marginBottom: 10, ...style }}>
            <div>
              <span className={spanValor}>{valor}: </span>
              <span className={spanDescricao}>{descricao}</span>
            </div>
          </div>
        ))}
        <span className={spanAviso}>
          {INFORMACAOBEZERROS}
        </span>
      </Collapse>
    </Grid>)
};

const styles = () => {
  const font = { fontFamily: "Roboto" };
  const span = {
    ...font,
    fontSize: '12px',
    letterSpacing: 0,
  };
  return {
    actionButton: {
      ...font,
      color: "#333333",
      fontSize: '16px',
      fontWeight: 500,
      marginTop: 20,
      marginBottom: 10
    },
    collapseStyle: {
      width: "80%",
    },
    containerDetalhes: {
      borderBottom: 'solid 1px #eee',
      marginBottom: '12px',
      padding: '5px',
    },
    spanAviso: {
      ...span,
      color: '#ce2d24',
      fontWeight: 400,
      lineHeight: '23px',
    },
    spanDescricao: {
      ...span,
      fontWeight: 400,
    },
    spanValor: {
      ...span,
      fontWeight: 500,
      lineHeight: '23px',
    },
  };
};
export default withStyles(styles)(FechamentoConsumoDetalhes);
