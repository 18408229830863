import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@material-ui/core';

export const DialogExclusaoModuloDoPasto = props => {
  const { ExcluirModuloAoExcluirPasto } = props;

  return ExcluirModuloAoExcluirPasto ? (
    <Dialog
      id="excluirModuloAoExcluirPasto"
      open={ExcluirModuloAoExcluirPasto}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={() => props.handleFechaModalExclusaoComModulo()}
    >
      <DialogTitle id="alert-dialog-modal-exclusao-modulo-pasto-title">
        {'Deseja confirmar a exclusão do módulo também?'}
      </DialogTitle>
      <DialogContent id="alert-dialog-description">
        <DialogContentText>
          {'Este pasto pertence a um módulo. Excluí-lo fará com que o módulo seja excluído também.'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          id="botaoCancelar"
          onClick={() => props.handleFechaModalExclusaoComModulo()}
          color="primary"
          autoFocus
        >
          CANCELAR
        </Button>
        <Button id="botaoConfirmar" onClick={() => props.handleExcluiPastoComModulo()} color="primary">
          CONFIRMAR
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

DialogExclusaoModuloDoPasto.propTypes = {
  ExcluirModuloAoExcluirPasto: PropTypes.bool.isRequired,
  handleFechaModalExclusaoComModulo: PropTypes.func.isRequired,
  handleExcluiPastoComModulo: PropTypes.func.isRequired
};

export default DialogExclusaoModuloDoPasto;
