import BaseService from "./BaseService";

const URL_BASE = `${process.env.REACT_APP_ENDPOINT}usuarios/api/v1/usuarios/`;
const URL_BASE_CLIENTES = `${process.env.REACT_APP_ENDPOINT}clientes/api/v1/clientes/`;

class ClienteService extends BaseService {
  async listarClientes(idUsuario) {
    const retorno = await this.get(URL_BASE + idUsuario + "/clientes");
    return retorno;
  }

  async listarClientesAtivos(idUsuario) {
    const filter = this.montaFiltro({
      where: {
        IdUsuario: idUsuario,
        $or: [{ Ativo: true }, { UsuarioAdministrador: true }],
      },
    });

    const url = this.montaURL(URL_BASE + idUsuario + "/clientes", filter);
    const retorno = await this.get(url);
    return retorno;
  }

  async listarTodosClientes() {
    const retorno = await this.get(URL_BASE_CLIENTES);
    return retorno;
  }

  async listarDadosClientes(idsClientes) {
    let dadosClientes = [];
    for (const idCliente of idsClientes) {
      const dadoCliente = await this.get(`${URL_BASE_CLIENTES}${idCliente}`);
      dadosClientes.push(dadoCliente.data);
    }
    return dadosClientes;
  }
}

export default new ClienteService();
