/**
 * Funções auxiliares(de uso geral) para as services
 */
import qs from 'qs';

export const montaURLParametros = (params) => {
  return qs.stringify({ ...params });
};

export const montaURL = (urlBase, complementoURL = '') => {
  return `${urlBase}${complementoURL}`;
};

/**
 * O loopback possibilita definir na query quais campos retornar
 * atravez do atributo 'fields'
 * Essa função recebe um array com nome dos campos a retornar para montar os campos para fields
 * @param {Array<string>} campos
 */
export const montaCamposParaRetornar = (campos) => {
  const camposRetornar = {};
  campos.forEach((campo) => {
    camposRetornar[campo] = true
  });
  return camposRetornar;
};
