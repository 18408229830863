import {
  LISTA_LOTES,
  LISTA_LOTES_POR_PASTO,
  PROJECAO_PESO,
  BUSCA_ENTREVERO,
  CURVA_GANHO_PESO,
} from "../../../redux/actions/actionsConstants";

export const initialState = {
  Lotes: [],
  LotesPorPasto: [],
  ProjecaoPesoSemestre: {},
  lotesComEntrevero: [],
  CurvaGanhoPeso: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LISTA_LOTES:
      return { ...state, Lotes: payload.Lotes };
    case LISTA_LOTES_POR_PASTO:
      return { ...state, LotesPorPasto: payload.LotesPorPasto };
    case PROJECAO_PESO:
      return { ...state, ProjecaoPesoSemestre: payload.ProjecaoPesoSemestre };
    case BUSCA_ENTREVERO:
      return { ...state, lotesComEntrevero: payload.lotesComEntrevero };
    case CURVA_GANHO_PESO:
      return { ...state, CurvaGanhoPeso: payload.CurvaGanhoPeso };
    default:
      return state;
  }
};
