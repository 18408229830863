import React from "react";
import { withStyles } from "@material-ui/core";
import localConstants from "../constants";
import SelectDropdown from "components/SelectDropdown";

const SelectParameters = ({
  classes,
  disabled,
  goalLine,
  parameters,
  onChangeParameter,
}) => {
  const parameterOptions = parameters.map((parameter) => ({
    value: parameter.name,
    label: localConstants.parametersTranslated[parameter.name],
  }));

  const handleChange = (value) => {
    onChangeParameter(value, goalLine.indicatorName);
  };

  return (
    <div style={{ padding: "8px 0" }}>
      <SelectDropdown
        testId={`${goalLine.indicatorName || ""}-Select-Parameter`}
        isDisabled={disabled}
        label={{ text: goalLine.parameterName ? "Parâmetro" : "Selecione" }}
        options={parameterOptions}
        value={goalLine.parameterName}
        onChange={handleChange}
      />
    </div>
  );
};
const styles = (theme) => ({
  inputInTable: {
    width: "95%",
    height: 42,
    "&>fieldset": {
      borderRadius: "4px",
      border: `solid 1px ${theme.palette.default2.light}`,
    },
    alignSelf: "center",
  },
  innerInput: {
    boxSizing: "border-box",
    borderRadius: 4,
    color: theme.palette.default1.light,
    backgroundColor: theme.palette.disabled.light,
    width: "95%",
  },
  select: {
    color: "rgba(0, 0, 0, 0.38)",
    width: "95%",
  },
  focused: {
    fontSize: "0.75rem",
    "&:focus": {
      backgroundColor: "unset",
    },
    width: "95%",
  },
});

export default withStyles(styles)(SelectParameters);
