import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";

import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Grid from "@material-ui/core/Grid";
import CloseICon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";

import styles from "styles/shared/ModalCustomizado";
import * as actions from "redux/actions";

class ModalCustomizado extends Component {
  handleClose = () => {
    this.props.setModalHistorico(false);
  };

  render() {
    const { classes, exibeBotaoFechar, alturaImagem, proporcoes } = this.props;
    const imagem = "../images/" + this.props.imagem;

    return (
      <Modal
        open={this.props.modalAberto}
        onBackdropClick={this.handleClose}
        disableBackdropClick={true}
        keyboard={false}
        onClickAway={this.handleClose}
        style={{ outline: "none" }}
      >
        <Grid
          container
          className={classes.modalGeral}
          style={{ ...proporcoes }}
        >
          <Grid
            container
            direction="column"
            justify="space-evenly"
            style={{ padding: "15px 40px 0" }}
          >
            <Grid container item direction="row" justify="space-between">
              <Grid item>
                <Typography variant="h6" id="modal-title">
                  <img
                    src={imagem}
                    style={alturaImagem}
                    alt="logo prodap"
                    className={classes.imagem}
                  />
                </Typography>
              </Grid>
              <Grid item>
                {exibeBotaoFechar && (
                  <CloseICon
                    className={classes.fechar}
                    onClick={this.handleClose}
                  />
                )}
              </Grid>
            </Grid>
            <Grid container item>
              <Grid container>
                <Typography
                  variant="h6"
                  id="modal-title"
                  className={classes.titulo}
                >
                  {this.props.titulo}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              direction="column"
              justify="space-evenly"
              container
              style={{ height: "35%" }}
            >
              <Grid container>
                <Typography
                  variant="body1"
                  id="simple-modal-description"
                  className={classes.mensagem}
                  style={{
                    maxHeight: "110px",
                    overflow: "auto",
                    lineHeight: "200%",
                    paddingRight: "20px",
                  }}
                >
                  {this.props.mensagem}
                </Typography>
              </Grid>
              <Grid container className={classes.personalizado}>
                {this.props.personalizado()}
              </Grid>
            </Grid>
            <Grid container justify="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color={this.props.botaoCor}
                  onClick={this.props.botaoCallback}
                >
                  {this.props.botaoNome}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    );
  }
}

ModalCustomizado.propTypes = {
  aberto: PropTypes.bool,
  fecharModal: PropTypes.func,
  classes: PropTypes.object.isRequired,
  imagem: PropTypes.string.isRequired,
  titulo: PropTypes.string.isRequired,
  mensagem: PropTypes.object,
  personalizado: PropTypes.func,
  exibeBotaoFechar: PropTypes.bool.isRequired,
  botaoCor: PropTypes.string.isRequired,
  botaoNome: PropTypes.string.isRequired,
  botaoCallback: PropTypes.func.isRequired,
};

ModalCustomizado.defaultProps = {
  modalAberto: false,
  modalEmails: false,
  imagem: "",
  titulo: "",
  mensagem: "",
  personalizado: () => {},
  botaoCor: "primary",
  botaoNome: "",
  alturaImagem: { height: 52 },
  proporcoes: { width: "490px", height: "400px" },
  botaoCallback: () => {},
};

export default compose(
  withStyles(styles),
  connect(null, actions)
)(ModalCustomizado);
