import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import MenuLateralModulo from "./Vinculo/VinculoContainer";
import Farol from "features/manejo/views/MenuLateral/Farol/FarolContainer";
import PastureRegistrations from "./Registration/FormPasto/PastureRegistrationsContainer";
import CadastroRetiroContainer from "../../../retiro/views/cadastroRetiroContainer";
import Registrations from "./Registration/Registrations";
import NewFarmFormContainer from "./Registration/FormPasto/NewFarmFormContainer";

class SwitchMenuLateral extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/farol" component={Farol} />
        <Route path="/vinculo" component={MenuLateralModulo} />
        <Route exact path="/cadastro" component={Registrations} />
        <Route
          exact
          path="/cadastro_editar_pasto"
          component={PastureRegistrations}
        />
        <Route
          exact
          path="/cadastro_novo_pasto"
          component={PastureRegistrations}
        />
        <Route
          exact
          path="/cadastro_nova_fazenda"
          component={NewFarmFormContainer}
        />
        <Route
          exact
          path="/cadastro_retiro"
          component={CadastroRetiroContainer}
        />
      </Switch>
    );
  }
}

export default SwitchMenuLateral;
