import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PlanningViewPage from "./planningViewPage";
import {
  disableLoading,
  enableLoading,
  notifyError as notifyErrorAction,
  notifySuccess as notifySuccessAction,
} from "../../redux/planningOperations";
import { usePlanningPlanDataForm } from "features/planning/hooks/usePlanningDataForm";
import planningService from "features/planning/planningService";
import usePlanningRegisterTable from "features/planning/hooks/usePlanningRegisterTable";
import usePrintPlanning from "features/planning/hooks/usePrintPlanning";
import PlanningPrintTemplate from "../shared/PlanningPrintTemplate";
import { withStyles } from "@material-ui/core";
import { parsePlanning } from "../shared/utils";

const PlanningViewContainer = ({
  classes,
  planningCode,
  farmId,
  currentFarm,
  enableLoading,
  disableLoading,
  notifyError,
  notifySuccess,
  goBack = () => {},
}) => {
  const {
    planningList,
    setPlanningList,
    formulaHasIngredients,
    setSummaryReport,
    summaryReport,
  } = usePlanningRegisterTable(
    farmId,
    enableLoading,
    disableLoading,
    notifyError
  );

  const { planningPlanData, periodTitles, setPlanningPlanData } =
    usePlanningPlanDataForm();

  const findPlan = async () => {
    if (!planningCode) return;
    try {
      enableLoading("findInitialPlanning");
      const planning = await planningService.getPlanReport(planningCode);

      const planningItemsArr = parsePlanning(planning, formulaHasIngredients);

      setPlanningList(planningItemsArr);
      setPlanningPlanData({
        ...planning,
        initialDate: planning.initialDate,
        finalDate: planning.finalDate,
      });
      setSummaryReport(planning);
    } catch (error) {
      notifyError(error);
    } finally {
      disableLoading("findInitialPlanning");
    }
  };

  useEffect(() => {
    findPlan();
  }, [planningCode]);

  const farm = useState(currentFarm.Nome);

  const { printRef, planningToPrint, handlePrintPlanning } = usePrintPlanning({
    farmId,
    enableLoading,
    disableLoading,
    notifyError,
  });

  return (
    <>
      <PlanningViewPage
        goBack={goBack}
        planningPlanData={planningPlanData}
        planningList={planningList}
        handlePrintPlanning={() => {
          handlePrintPlanning(planningCode);
        }}
        categories={summaryReport.categories}
        formulas={summaryReport.formulas}
        summaryReport={summaryReport.planningReport}
        periodTitles={periodTitles}
        planningTotal={summaryReport.total}
      />
      <div className={classes.printComponent}>
        <PlanningPrintTemplate
          ref={printRef}
          farm={farm}
          categories={planningToPrint.summaryReport.categories}
          formulas={planningToPrint.summaryReport.formulas}
          periodTitles={planningToPrint.periodTitles}
          summaryReport={planningToPrint.summaryReport.planningReport}
          planningTotal={planningToPrint.summaryReport.total}
          planningPlanData={planningToPrint.planningPlanData}
          planningList={planningToPrint.planningList}
        />
      </div>
    </>
  );
};

export const mapDispatchToProps = {
  enableLoading: enableLoading,
  disableLoading: disableLoading,
  notifyError: notifyErrorAction,
  notifySuccess: notifySuccessAction,
};

const mapStateToProps = ({ fazenda }) => {
  return {
    farmId: fazenda.FazendaSelecionada,
    currentFarm: fazenda.currentFarm,
  };
};

const styles = (theme) => ({
  printComponent: {
    display: "none",
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PlanningViewContainer));
