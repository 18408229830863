import React from "react";
import { Table, Typography, withStyles } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import HistoricoMovimentacaoUtils from "../../../historicoMovimentacaoUtils";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { dateDiff } from "shared/utils";
import moment from "moment";

const headCells = [
  {
    id: "UsuarioCriacao",
    numeric: false,
    disablePadding: true,
    label: "Usuário",
  },
  {
    id: "TipoOperacao",
    numeric: false,
    disablePadding: true,
    label: "Tipo de Operação",
  },
  {
    id: "Quantidade",
    numeric: true,
    disablePadding: false,
    label: "Total movimentado",
  },
  {
    id: "OrigemNomeCategoriaAnimal",
    numeric: false,
    disablePadding: true,
    label: "Categoria Inicial",
  },
  {
    id: "DestinoNomeCategoriaAnimal",
    numeric: false,
    disablePadding: true,
    label: "Categoria Final",
  },
  {
    id: "OrigemNomePasto",
    numeric: false,
    disablePadding: true,
    label: "Pasto de Origem",
  },
  {
    id: "DestinoNomePasto",
    numeric: false,
    disablePadding: true,
    label: "Pasto de Destino",
  },
  {
    id: "DataColeta",
    numeric: false,
    disablePadding: true,
    label: "Data/Hora coleta",
  },
  {
    id: "DataCriacao",
    numeric: false,
    disablePadding: true,
    label: "Data/Hora sincronização",
  },
];

export const renderDateDiff = ({ DataCriacao, DataISO }, classes) => {
  let hours = 0;

  if (DataCriacao && DataISO) {
    const diff = dateDiff(DataCriacao, DataISO);
    hours = diff.hours;
  }

  return (
    <TableCell
      component="th"
      id="movimentacao-atrasada"
      scope="row"
      className={classes.bodyCell}
    >
      <div className={classes.dataSinc}>
        {hours > 12 && (
          <Tooltip
            title="Essa movimentação teve um atraso considerável na sincronização. Indicamos que as movimentações
          sejam sincronizadas todos os dias ao fim do trabalho, e que o aplicativo seja sincronizado também antes
          de iniciar o trabalho a fim de receber as operações realizadas por outros usuários e sincronizadas após
          a sua ultima sincronização"
            className={classes.tooltipDataSinc}
            placement="right"
          >
            <InfoIcon style={{ color: "#FFC944" }} />
          </Tooltip>
        )}
        {moment(DataCriacao).format("DD/MM/YYYY")}
      </div>
      <div>{moment(DataCriacao).format("HH:mm")}H</div>
    </TableCell>
  );
};

export function HistoricoMovimentacaoHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const infoMessage = "Hora referente ao estado da fazenda.";
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.defaultCells}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{""}</span>
              ) : null}
              {headCell.id === "DataColeta" && (
                <Tooltip
                  title={infoMessage}
                  className={classes.tooltip}
                  placement="right"
                >
                  <InfoIcon color="primary" />
                </Tooltip>
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const MovementHistoryListTable = ({
  handleRequestSort,
  handleSelectAllClick,
  handleClick,
  handleChangePage,
  handleChangeRowsPerPage,
  selected,
  order,
  orderBy,
  historicoMovimentacao,
  rowsPerPage,
  page,
  classes,
}) => {
  return (
    <div className={classes.tableContent}>
      <Typography className={classes.textoHeaderTable}>
        Histórico de Movimentações de Rebanho
      </Typography>
      <Paper className={classes.containerTable}>
        <div className={classes.tableContainer}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            stickyHeader
            aria-label="enhanced table"
          >
            <HistoricoMovimentacaoHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={(historicoMovimentacao.items || []).length}
            />
            <TableBody>
              {HistoricoMovimentacaoUtils.stableSort(
                historicoMovimentacao.items,
                HistoricoMovimentacaoUtils.getComparator(order, orderBy)
              ).map((row, index) => {
                const labelId = `historico-movimentacao-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.name)}
                    tabIndex={-1}
                    key={row.IdOperacaoLote}
                  >
                    <TableCell
                      style={{ color: "#757575" }}
                      component="th"
                      id={labelId}
                      scope="row"
                    >
                      {row.UsuarioCriacao}
                    </TableCell>
                    <TableCell className={classes.bodyCell}>
                      {row.TipoOperacao}
                    </TableCell>
                    <TableCell className={classes.bodyCell}>
                      {Math.abs(row.Quantidade)}
                    </TableCell>
                    <TableCell className={classes.bodyCell}>
                      {row.OrigemNomeCategoriaAnimal || "_"}
                    </TableCell>
                    <TableCell className={classes.bodyCell}>
                      {row.DestinoNomeCategoriaAnimal || "_"}
                    </TableCell>
                    <TableCell className={classes.bodyCell}>
                      {row.OrigemNomePasto || "_"}
                    </TableCell>
                    <TableCell className={classes.bodyCell}>
                      {row.DestinoNomePasto || "_"}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.bodyCell}
                    >
                      <div className={classes.dateCell}>{`${row.Data}H`}</div>
                    </TableCell>
                    {renderDateDiff(row, classes)}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={historicoMovimentacao.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="Linhas por página"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count}`
          }
        />
      </Paper>
    </div>
  );
};

const styles = (theme) => {
  return {
    table: {
      width: "100%",
      maxHeight: "280px",
      "&::-webkit-scrollbar": {
        width: 9,
        borderRadius: 10,
      },
    },
    textoHeaderTable: {
      fontWeight: "bold",
      fontSize: "24px",
      padding: "0!important",
      letterSpacing: "-0.05px",
      margin: "24px 0",
      color: "#333333",
    },
    tableContent: {
      width: "100%",
      height: "100%",
      marginBottom: 60,
      maxHeight: 700,
      padding: 46,
    },
    defaultCells: {
      fontFamily: "Roboto",
      textTransform: "uppercase",
      fontSize: 14,
      lineHeight: 1.29,
      color: theme.palette.primary.main,
      backgroundColor: "#e1e1e1",
      position: "sticky",
      top: 0,
      width: "10%",
      maxWidth: "200px",
      padding: "4px 0 4px 24px",
      zIndex: 1,
    },
    bodyCell: {
      textTransform: "uppercase",
      color: "#757575",
    },
    dateCell: {
      width: 54,
    },
    tooltip: {
      maxWidth: 60,
      paddingLeft: "9px",
      marginBottom: "3px",
    },
    tableContainer: {
      maxHeight: 540,
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: 9,
        height: 10,
        borderRadius: 10,
      },
      borderRadius: "16px",
    },
    containerTable: {
      borderRadius: "16px",
    },
    dataSinc: {
      position: "relative",
      display: "flex",
    },
    tooltipDataSinc: {
      maxWidth: 16,
      fontSize: "16px",
    },
  };
};

export default withStyles(styles)(MovementHistoryListTable);
