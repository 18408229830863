import ReportManager from "../tableauReportManager";
import { obterRelatorio, obterRelatoriosAction } from "./tableauReportActions";
import { notifyError } from "../../../redux/actions/notificacaoActions";
export const obterRelatorios = (body) => async (dispatch) => {
  try {
    const respostaRelatorio = await ReportManager.obterRelatorioManager(body);
    dispatch(obterRelatorio(respostaRelatorio.url));
  } catch (error) {
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao obter o relatório.",
      })
    );
  }
};
export const obterRelatoriosTableau = () => async (dispatch) => {
  try {
    const respostaRelatorio = await ReportManager.obterRelatoriosTableau();
    dispatch(obterRelatoriosAction(respostaRelatorio));
  } catch (error) {
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao obter os relatórios.",
      })
    );
  }
};
