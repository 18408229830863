import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, IconButton, Paper, withStyles } from "@material-ui/core";
import { Edit, FormatListBulleted } from "@material-ui/icons";
import { formulaNameFormat } from "features/collect/supplementation/views/shared/utils";
import SwitchCustomizado from "features/shared/components/SwitchCustomizado";
import TabelaProdap from "features/shared/components/TabelaProdap";

export const ListaFormulas = ({
  classes,
  listaFormulas,
  alterarStatusModal,
  editarFormulacao,
  ativaDesativaFormulacao,
}) => {
  const [listaTratada, setListaTratada] = useState([]);

  const TituloSemQuebraDeLinha = ({ titulo }) => (
    <span style={{ whiteSpace: "nowrap" }}>{titulo}</span>
  );

  const columns = (classes) => [
    {
      id: "Nome",
      nome: "Nome",
      titulo: "Nome",
      ordenavel: true,
      pegaValor: (row) => (
        <span className={classes.text}>{formulaNameFormat(row.Nome)}</span>
      ),
    },
    {
      id: "ConsumoDiario",
      nome: "ConsumoDiario",
      titulo: <TituloSemQuebraDeLinha titulo="Consumo Objetivo" />,
      ordenavel: true,
      pegaValor: (row) => (
        <span className={classes.text}>
          {formulaNameFormat(row.ConsumoDiario)}
        </span>
      ),
    },
    {
      id: "CategoriaTexto",
      nome: "CategoriaTexto",
      titulo: "Rebanho Alvo",
      ordenavel: true,
      pegaValor: (row) => (
        <span className={classes.text}>
          {formulaNameFormat(row.CategoriaTexto)}
        </span>
      ),
    },
    {
      id: "EpocasTexto",
      nome: "EpocasTexto",
      titulo: "Época",
      ordenavel: true,
      pegaValor: (row) => (
        <span className={classes.text}>
          {formulaNameFormat(row.EpocasTexto)}
        </span>
      ),
    },
    {
      id: "GranelTexto",
      nome: "GranelTexto",
      titulo: "Embalagem",
      ordenavel: true,
      pegaValor: (row) => (
        <span className={classes.text}>
          {formulaNameFormat(row.GranelTexto)}
        </span>
      ),
    },
    {
      id: "PesoSaco",
      nome: "PesoSaco",
      titulo: <TituloSemQuebraDeLinha titulo="PESO DO SACO (KG)" />,
      ordenavel: true,
      pegaValor: (row) => (
        <span className={classes.text}>{formulaNameFormat(row.PesoSaco)}</span>
      ),
    },
    {
      id: "detalhesFormula",
      nome: "detalhes",
      titulo: "Detalhes",
      ordenavel: false,
      pegaValor: (row) => (
        <div className={classes.detailsButton}>
          <Button
            id={`iconButtonDetails-${row.IdFormula}`}
            onClick={() => alterarStatusModal(row)}
            aria-label="Detalhes"
          >
            <FormatListBulleted className={classes.iconeMenu} />
          </Button>
        </div>
      ),
    },
    {
      id: "editarFormula",
      nome: "editarFormula",
      titulo: "Editar",
      ordenavel: false,
      pegaValor: (row) => (
        <IconButton
          id={`iconButton-${row.IdFormula}`}
          onClick={() => editarFormulacao(row)}
          aria-label="Editar"
          disabled={row.Excluido}
        >
          <Edit />
        </IconButton>
      ),
    },
    {
      id: "Status",
      nome: "Status",
      titulo: "Ativo",
      ordenavel: true,
      pegaValor: (row) => {
        return (
          <SwitchCustomizado
            id={`switch-${row.IdFormula}`}
            defaultChecked={!row.Excluido}
            checked={!row.Excluido}
            handleClick={() =>
              ativaDesativaFormulacao(row, row.IdFormula, row.Excluido)
            }
          />
        );
      },
    },
  ];

  const colocarArrayEmOrdemAfabetica = (array, filtro) => {
    return array.sort((a, b) =>
      a[filtro] > b[filtro] ? 1 : b[filtro] > a[filtro] ? -1 : 0
    );
  };

  const tratarLinhaListaFormulas = () => {
    const listaTratada = listaFormulas.map((row) => {
      let Categoria = "Não informado";
      let Epocas = "Não informado";

      if (row.Categoria.length) {
        const categoriasEmOrdemAlfabetica = colocarArrayEmOrdemAfabetica(
          row.Categoria,
          "Descricao"
        );

        const descricaoDaCategoria = categoriasEmOrdemAlfabetica.map(
          (categoria) => {
            return categoria.Descricao;
          }
        );

        Categoria = descricaoDaCategoria.toString().replace(/,/g, ", ");
      }

      if (row.Epocas.length) {
        const epocasEmOrdemAlfabetica = colocarArrayEmOrdemAfabetica(
          row.Epocas,
          "Descricao"
        );

        const descricaoDaEpoca = epocasEmOrdemAlfabetica.map((epoca) => {
          return epoca.Descricao;
        });
        Epocas = descricaoDaEpoca.toString().replace(/,/g, ", ");
      }

      return {
        ...row,
        CategoriaTexto: Categoria,
        EpocasTexto: Epocas,
        GranelTexto: row.Granel ? "Granel" : "Ensacado",
        Status: row.Excluido ? "Excluido" : "Ativo",
      };
    });

    return listaTratada;
  };

  useEffect(() => {
    const _listaTratada = tratarLinhaListaFormulas();
    setListaTratada(_listaTratada);
  }, []);

  return (
    <Paper className={classes.containnerTable}>
      <TabelaProdap
        modoOrdenacao={"CLIENTE"}
        ativarPaginacao={false}
        linhas={listaTratada}
        colunas={columns(classes)}
        tabelaEsquerda={true}
      />
    </Paper>
  );
};

ListaFormulas.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => {
  return {
    table: {
      width: "100%",
    },
    tableHeaderFormulacao: {
      height: 56,
      backgroundColor: "#e1e1e1",
    },
    dropdownObjetivo: {
      width: "155px",
    },
    cellPrincipal: {
      width: "15%",
      fontFamily: "Roboto",
      fontSize: 14,
      textTransform: "uppercase",
      top: 0,
      position: "sticky",
      zIndex: 10,
      color: theme.palette.primary.main,
      backgroundColor: "#e1e1e1",
      textAlign: "center",
      padding: "15px 15px 15px 15px",
    },
    firstCellPrincipal: {
      width: "20%",
      fontFamily: "Roboto",
      fontSize: 14,
      textTransform: "uppercase",
      top: 0,
      position: "sticky",
      zIndex: 10,
      color: theme.palette.primary.main,
      backgroundColor: "#e1e1e1",
      textAlign: "center",
      padding: "15px 15px 15px 15px",
    },
    gridTableRow: {
      padding: "20px",
    },
    conteudoPrincipal: {
      color: "#757575",
      fontSize: 14,
      textTransform: "uppercase",
      textAlign: "center",
      padding: "11px",
    },
    firstConteudoPrincipal: {
      color: "#757575",
      fontSize: 14,
      textTransform: "uppercase",
      textAlign: "center",
      padding: "11px",
    },
    input: {
      paddingTop: "15px",
    },
    root: {
      padding: 0,
      marginLeft: -18,
    },
    switchBase: {
      "&$checked": {
        color: theme.palette.common.white,
        "& + $bar": {
          backgroundColor: "#34a47c",
        },
      },
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.sharp,
      }),
    },
    checked: {
      transform: "translateX(22px)",
      "& + $bar": {
        opacity: 1,
        border: "none",
      },
    },
    bar: {
      borderRadius: 20,
      width: 42,
      height: 20,
      marginTop: -10,
      marginLeft: -17,
      border: "solid 1px",
      borderColor: theme.palette.grey[400],
      backgroundColor: theme.palette.grey[400],
      opacity: 1,
    },
    iconeOff: {
      width: 20,
      height: 20,
      borderRadius: 13,
      color: theme.palette.grey[400],
      backgroundColor: theme.palette.grey[50],
      border: `solid 1px ${theme.palette.grey[400]}`,
    },
    iconeOn: {
      width: 20,
      height: 20,
      borderRadius: 13,
      color: "#34a47c",
      backgroundColor: theme.palette.grey[50],
      border: "solid 1px #34a47c",
    },
    defaultCell: {
      top: 0,
      position: "sticky",
      zIndex: 10,
      color: theme.palette.primary.main,
      backgroundColor: "#e1e1e1",
    },
    containnerTable: {
      overflow: "hidden",
      width: "100%",
      borderRadius: "16px",
    },
    divTable: {
      overflow: "auto",
      maxHeight: 600,
    },
    iconeMenu: {
      color: theme.palette.primary.main,
    },
    detailsButton: {
      width: "100%",
    },
  };
};

export default withStyles(styles)(ListaFormulas);
