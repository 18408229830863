import {
  SET_FAZENDA,
  LISTA_FAZENDAS,
  NOTIFICAR,
  SET_COORDENADAS_CENTRO,
  BUSCA_PASTOS_POR_ID_FAZENDA,
  CHANGE_CENTER,
  EXCLUIR_FAZENDA,
  LIMPAR_NOVA_FAZENDA,
  TEM_COCHO_CREEP,
} from "redux/actions/actionsConstants";
import FazendaService from "services/FazendaService";
import PastoService from "services/PastoService";
import ToggleService from "services/ToggleService";
import CochoService from "services/CochoService";
import history from "features/app/History";
import { getFazendaSelecionadaStorage } from "shared/storageUtils";

export const limparNovaFazenda = () => (dispatch) => {
  dispatch({
    type: LIMPAR_NOVA_FAZENDA,
    payload: null,
  });
};

export const setFazenda = (IdFazenda) => async (dispatch) => {
  const toggleResponse = await ToggleService.listarToggles(IdFazenda);
  dispatch({
    type: SET_FAZENDA,
    payload: {
      FazendaSelecionada: IdFazenda,
      Toggles: toggleResponse.data,
    },
  });
};

export const changeCenterCoordinates = (coordinates) => async (dispatch) => {
  dispatch({
    type: CHANGE_CENTER,
    payload: {
      Coordenadas: coordinates,
    },
  });
};

export const retornaCoordenadas = (lat, long) => async (dispatch) => {
  const res = await FazendaService.retornaCidadeComCoordenadas(lat, long);

  const resultado =
    res && res.results && res.results.length > 0
      ? res.results[0].geometry.location
      : [];

  dispatch({
    type: CHANGE_CENTER,
    payload: {
      Coordenadas: resultado,
    },
  });
};

export const excluirFazenda = (idFazenda) => async (dispatch) => {
  await FazendaService.excluirFazenda(idFazenda);
  dispatch({ type: EXCLUIR_FAZENDA, payload: null });
};

export const setCoordenadasFazenda = (coordenadas) => (dispatch) => {
  dispatch({ type: SET_COORDENADAS_CENTRO, payload: coordenadas });
};

/**
 * FIXME: Retirar chamadas pra serviços deste local
 * O service deve ser o responsável por fazer requisições
 **/
export const listaFazendas = (idCliente) => async (dispatch) => {
  try {
    const fazendasCliente = await FazendaService.listarFazendasCliente(
      localStorage.getItem("IdUsuario"),
      idCliente
    );

    let lastSelectedFarmId = null;
    if (fazendasCliente.data && fazendasCliente.data.length) {
      const savedFarmId = Number(getFazendaSelecionadaStorage());
      const lastSelectedFarm =
        fazendasCliente.data.find((f) => savedFarmId === f.IdFazenda) ||
        fazendasCliente.data[0];
      lastSelectedFarmId = lastSelectedFarm.IdFazenda;
    }

    dispatch({
      type: LISTA_FAZENDAS,
      payload: {
        Fazendas: fazendasCliente.data,
        FazendaSelecionada: lastSelectedFarmId,
      },
    });
  } catch (error) {
    if (error.status === 401) {
      history.push("/");
      dispatch({
        type: NOTIFICAR,
        payload: {
          mensagem: "Ocorreu um erro de autenticação. Faça login novamente.",
          variant: "error",
          estilo: "error",
        },
      });
    } else {
      dispatch({
        type: NOTIFICAR,
        payload: {
          mensagem: "Ocorreu um erro ao listar as fazendas.",
          variant: "error",
          estilo: "error",
        },
      });
    }
  }
};

export const buscaPastosPorIdFazenda = (idFazenda) => async (dispatch) => {
  try {
    const pastosFazenda = await PastoService.listaPastosPorIdFazenda(idFazenda);
    const payload = pastosFazenda.data && pastosFazenda.data.length > 0;
    dispatch({ type: BUSCA_PASTOS_POR_ID_FAZENDA, payload });
  } catch (error) {
    dispatch({
      type: NOTIFICAR,
      payload: {
        mensagem: error.message,
        variant: "error",
        estilo: "error",
      },
    });
  }
};

export const verificaSeExisteCochoCreep = (idFazenda) => async (dispatch) => {
  try {
    const cochosCreep = await CochoService.getCochoCreepPorIdFazenda(idFazenda);
    if (cochosCreep.data && cochosCreep.data.length > 0) {
      dispatch({
        type: TEM_COCHO_CREEP,
        payload: true,
      });
      return;
    }

    dispatch({
      type: TEM_COCHO_CREEP,
      payload: false,
    });
  } catch (err) {
    dispatch({
      type: TEM_COCHO_CREEP,
      payload: false,
    });
  }
};
