import BaseService from '../../services/BaseService';
const URL_BASE = `${process.env.REACT_APP_ENDPOINT}relatorios-tableau/api/v1/relatorios-tableau/`;
class TableauReportService extends BaseService {
  async retornaRelatorios(body) {
    return await this.post(URL_BASE, body);
  }
  async obterRelatorios() {
    return await this.get(URL_BASE + 'relatorio');
  }

  async buscaTicketTableauEmbedded(login) {
    return await this.get(
      `${process.env.REACT_APP_ENDPOINT}tableauembedded/api/v1/autenticacao?username=${login}`
    );
  }
}
export default new TableauReportService();
