import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Close, Check } from "@material-ui/icons";
import { Switch, withStyles } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    padding: 0,
    marginLeft: -18,
  },
  switchBase: {
    "&$checked": {
      color: theme.palette.common.white,
      "& + $bar": {
        backgroundColor: "#34a47c",
      },
    },
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp,
    }),
  },
  checked: {
    transform: "translateX(22px)",
    "& + $bar": {
      opacity: 1,
      border: "none",
    },
  },
  bar: {
    borderRadius: 20,
    width: 42,
    height: 20,
    marginTop: -10,
    marginLeft: -17,
    border: "solid 1px",
    borderColor: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[400],
    opacity: 1,
  },
  iconeOff: {
    width: 20,
    height: 20,
    borderRadius: 13,
    color: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[50],
    border: `solid 1px ${theme.palette.grey[400]}`,
  },
  iconeOn: {
    width: 20,
    height: 20,
    borderRadius: 13,
    color: "#34a47c",
    backgroundColor: theme.palette.grey[50],
    border: "solid 1px #34a47c",
  },
});

class SwitchCustomizado extends PureComponent {
  render() {
    const { classes, handleClick, defaultChecked, checked, testId } = this.props;
    return (
      <Switch
        checked={checked}
        defaultChecked={defaultChecked}
        disableRipple={true}
        onClick={handleClick}
        data-testid={testId}
        icon={<Close className={classes.iconeOff} />}
        checkedIcon={<Check className={classes.iconeOn} />}
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          bar: classes.bar,
          checked: classes.checked,
        }}
      />
    );
  }
}

SwitchCustomizado.propTypes = {
  handleClick: PropTypes.func.isRequired,
  defaultChecked: PropTypes.bool
};

export default withStyles(styles)(SwitchCustomizado);
