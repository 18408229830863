import React from "react";
import { connect } from "react-redux";
import GoalPlanReviewPage from "features/goalPlan/views/goalPlanReview/goalPlanReviewPage";
import {
  disableLoading,
  enableLoading,
  notifyError as notifyErrorAction,
  notifySuccess as notifySuccessAction,
} from "features/goalPlan/redux/goalPlanOperations";

import { useSubmitUtils } from "features/goalPlan/hooks/useSubmitUtils";

const GoalPlanReviewContainer = ({
  goalPlanId,
  farmId,
  onBack = () => {},
  enableLoading,
  disableLoading,
  notifySuccess,
  notifyError,
}) => {
  const [, { onCreateReview }] = useSubmitUtils({
    disableLoading,
    enableLoading,
    notifyError,
    notifySuccess,
    farmId,
    callbackSubmit: onBack,
  });

  return (
    <GoalPlanReviewPage
      goalPlanId={goalPlanId}
      onSubmit={onCreateReview}
      onBack={onBack}
      enableLoading={enableLoading}
      disableLoading={disableLoading}
      notifyError={notifyError}
    />
  );
};

export const mapDispatchToProps = {
  notifySuccess: notifySuccessAction,
  enableLoading: enableLoading,
  disableLoading: disableLoading,
  notifyError: notifyErrorAction,
};

const mapStateToProps = ({ fazenda }) => {
  return {
    farmId: fazenda.FazendaSelecionada,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoalPlanReviewContainer);
