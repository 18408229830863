import React from 'react';
import { Typography, Grid, withStyles } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { getLegendas } from '../fechamentoConsumoUtils';
import { TextLabel } from '../../shared/components';

export const fechamentoConsumoLegenda = props => {
  const { classes } = props;
  const legendas = getLegendas();
  return (
    <Grid container item direction="column" className={classes.containerLegenda}>
      <Grid container>
        <TextLabel color="default2" bold id="legendaFechamentoConsumo">
          Legenda:
        </TextLabel>
      </Grid>
      <Grid container direction="row" justify="flex-start">
        {legendas.map(legenda => (
          <div className={classes.itemLegenda} key={legenda.legendaId}>
            <div className={classes.divLegendaCor} style={{ background: legenda.legendaCor }} />
            <Typography
              variant="subtitle2"
              color="textSecondary"
              className={classes.divLegendaLabel}
              id={legenda.legendaId}
            >
              {legenda.legendaLabel}
            </Typography>
          </div>
        ))}
        <div className={classes.itemLegenda}>
          <CheckCircle color="disabled" style={{ fontSize: 18 }} />
          <Typography
            variant="subtitle2"
            color="textSecondary"
            className={classes.divLegendaLabel}
            id="consumoObjetivo"
          >
            Consumo objetivo
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

const styles = theme => {
  return {
    containerLegenda: {
      flexGrow: 1,
      margin: '0 0 10px 0'
      // backgroundColor: 'purple'
    },
    itemLegenda: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: '4%'
    },
    divLegendaCor: {
      width: 16,
      height: 6,
      display: 'inline-block'
    },
    divLegendaLabel: {
      marginLeft: 10
    }
  };
};

export default withStyles(styles)(fechamentoConsumoLegenda);
