import React from "react";
import GoalPlanContent from "../shared/goalPlanContent";
import GoalPlanRevisionHeader from "./components/goalPlanRevisionHeader";
import GoalPlanRevisionList from "./components/goalPlanRevisionList";
import GoalPlanDetailsContainer from "features/goalPlan/views/goalPlanRevision/components/goalPlanDetails/goalPlanDetailsContainer";

const GoalPlanRevisionPage = ({
  onBack,
  goalPlanRevisionList,
  onShowRevisionPlan,
  onShowOriginalPlan,
  revisionId,
  onBackRevisionList,
  enableLoading,
  disableLoading,
  notifyError,
}) => {
  const titleDetailsContainer = (goalPlan) => {
    if (goalPlan.version === 1) return "Plano original -";
    return "Revisões:";
  };

  const badgeDetailsContainer = (goalPlan) => {
    if (goalPlan.version === 1) return "Plano original";
    return undefined;
  };

  if (revisionId) {
    return (
      <GoalPlanDetailsContainer
        onBack={onBackRevisionList}
        title={titleDetailsContainer}
        badge={badgeDetailsContainer}
        goalPlanId={revisionId}
        enableLoading={enableLoading}
        disableLoading={disableLoading}
        notifyError={notifyError}
      />
    );
  }

  return (
    <GoalPlanContent
      data-testid="revision-goals-content"
      title="Voltar"
      onBack={onBack}
    >
      <GoalPlanRevisionHeader
        goalPlanRevisionList={goalPlanRevisionList}
        handleShowOriginalPlan={onShowOriginalPlan}
      />
      <GoalPlanRevisionList
        goalPlanRevisionList={goalPlanRevisionList}
        onShowRevisionPlan={onShowRevisionPlan}
      />
    </GoalPlanContent>
  );
};

export default GoalPlanRevisionPage;
