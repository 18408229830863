import React from "react";
import history from "../../../app/History";
import ConfirmationModal from "features/shared/components/confirmationModal";

const ModalPastureRegistration = ({
  classes,
  setIsModalShown
}) => {

  const navigateToRegisterPasture = () => {
    const registerPath = "/cadastro";
    setIsModalShown(false);
    history.push(registerPath);
  }

  return (
    <ConfirmationModal
      customClass={classes.customClass}
      buttonsContainer={classes.buttonsContainer}
      submitButtonClass={classes.submitButtonClass}
      cancelButtonClass={classes.cancelButtonClass}
      title={
        <span className={classes.modalTitle}>
          Você não possui pasto cadastrado!
        </span>
      }
      text={
        <span className={classes.modalText}>
          Verificamos que ainda não existe pasto cadastrado em sua fazenda.
          Seria interessante realizar esse cadastro para visualizar todos os
          dados disponíveis na plataforma. Para cadastrar um pasto clique no
          botão{" "}
          <span className={classes.modalTextInfo}>“Cadastrar pasto”</span>.
          Caso não queira cadastrar agora, é só clicar no botão{" "}
          <span className={classes.modalTextInfo}>“Fechar”</span>, e navegar
          normalmente na plataforma.
        </span>
      }
      submitButtonTitle="Cadastrar pasto"
      cancelButtonTitle="Fechar"
      onSubmit={navigateToRegisterPasture}
      onCancel={setIsModalShown}
      onClose={setIsModalShown}
    />
  );
}

export default ModalPastureRegistration;
