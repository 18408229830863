import React, { Component } from "react";
import { connect } from "react-redux";
import {
  listaFazendas,
  listaPastos,
  listaCurrais,
  buscaPastosPorIdFazenda,
  setFazenda,
  setLoading,
  visualizarPontosColetas,
} from "redux/actions";
import { listarRetiros } from "features/retiro/redux/retiroOperations";
import SelectDropdown from "components/SelectDropdown";
import history from "features/app/History";

class FazendaDropdown extends Component {
  componentDidMount() {
    if (this.props.FazendaSelecionada) {
      const fazenda = this.props.Fazendas.find(
        (f) => this.props.FazendaSelecionada === f.IdFazenda
      );
      this.selecionaFazenda(fazenda);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.Fazendas) !== JSON.stringify(prevProps.Fazendas)
    ) {
      if (this.props.Fazendas && this.props.Fazendas.length === 0) {
        this.selecionaFazenda({ IdFazenda: null });
      } else {
        this.selecionaFazenda(this.props.Fazendas[0]);
      }
    }
  }

  selecionaFazenda = async ({ IdFazenda, Coordenadas }) => {
    this.props.setLoading();
    await this.props.setFazenda(IdFazenda);
    this.props.listarRetiros(IdFazenda);
    this.props.listaPastos(IdFazenda, Coordenadas);
    this.props.listaCurrais(IdFazenda);
    this.props.buscaPastosPorIdFazenda(IdFazenda);
    history.replace("/farol");
    this.props.visualizarPontosColetas(false);
  };

  handleChange = async (newValue) => {
    if (Number(newValue) !== Number(this.props.FazendaSelecionada)) {
      const fazendaSelecionada = this.props.Fazendas.find(
        (fazenda) => Number(fazenda.IdFazenda) === Number(newValue)
      );
      if (fazendaSelecionada) {
        this.selecionaFazenda(fazendaSelecionada);
      }
    }
  };

  render() {
    const { Fazendas, Bloqueado } = this.props;
    const disabled = Fazendas.length <= 1 || Bloqueado;
    const idFazendaSel = this.props.FazendaSelecionada
      ? this.props.FazendaSelecionada
      : null;

    const listaFazendas = Fazendas.sort((a, b) => {
      a.NomeReduzido = a.NomeReduzido || a.Nome;
      b.NomeReduzido = b.NomeReduzido || b.Nome;
      return a.NomeReduzido.localeCompare(b.NomeReduzido, "pt-BR");
    }).map((fazenda) => ({
      value: Number(fazenda.IdFazenda),
      label: fazenda.NomeReduzido || fazenda.Nome,
    }));

    const fazendaSelecionada = listaFazendas.find(
      (fazenda) => fazenda.value === idFazendaSel
    );

    const value = fazendaSelecionada ? fazendaSelecionada.value : null;

    return (
      <SelectDropdown
        isDisabled={disabled}
        options={listaFazendas}
        value={value}
        label={{ id: "select-farm", text: "FAZENDA" }}
        styles={{ minWidth: "180px" }}
        onChange={this.handleChange}
        size="small"
      />
    );
  }
}

function mapStateToProps({ cliente, fazenda, ui }) {
  return {
    Fazendas: fazenda.Fazendas,
    FazendaSelecionada: fazenda.FazendaSelecionada,
    Bloqueado: !ui.Dropdown.Fazenda.ativo,
    ClienteSelecionado: cliente.ClienteSelecionado,
  };
}

const mapDispatchToProps = {
  listaFazendas,
  listarRetiros,
  listaPastos,
  listaCurrais,
  buscaPastosPorIdFazenda,
  setFazenda,
  setLoading,
  visualizarPontosColetas,
};

export default connect(mapStateToProps, mapDispatchToProps)(FazendaDropdown);
