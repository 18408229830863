import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import localConstants from "./constants";
import SelectIndicators from "./tableFormComponents/selectIndicators";
import SelectParameters from "./tableFormComponents/selectParameters";
import GoalInput from "./tableFormComponents/goalInput";
import DefaultInput from "./tableFormComponents/defaultInput";
import DisableInput from "./tableFormComponents/disableInput";
import GoalTitleTable from "./tableFormComponents/goalTitleTable";

const checkColSpan = (goalLine, index) => {
  if (goalLine.indicatorType === localConstants.period.monthly) return 1;
  if (index === 0) return 12;
};

const checkHidden = (goalLine) => {
  if (goalLine.id === "selecionaIndicador") return true;
  return false;
};

const checkPeriodHidden = (goalLine, index) => {
  if (checkHidden(goalLine)) return true;
  if (goalLine.indicatorType === localConstants.period.monthly || index === 0)
    return false;
  return true;
};

const calcAccumulated = (goalLine, indicators, parameters) => {
  const indicator = indicators.find(
    (ind) => ind.name === goalLine.indicatorName
  );

  const isBetweenParameter = goalLine.parameterName === "between";

  if (!indicator || !indicator.accumulated || isBetweenParameter) {
    return renderNoAccumulatedValueMessage();
  }

  if (Array.isArray(goalLine.values)) {
    const accumulated = goalLine.values.reduce(
      (acc, curr) => acc + (Number(curr.value) || 0),
      0
    );
    return accumulated;
  }
  return undefined;
};

const renderNoAccumulatedValueMessage = () => {
  const noAccumulatedValueMessage =
    "Este indicador não possui o cálculo acumulado.";
  return (
    <Tooltip title={noAccumulatedValueMessage}>
      <div align="center" style={{ width: "100%" }}>
        <span>-</span>
      </div>
    </Tooltip>
  );
};

const checkIfParameterIsSelected = (goalLine) => {
  return (
    goalLine &&
    goalLine.parameterName &&
    goalLine.parameterName !== "" &&
    goalLine.parameterName !== "selecione-parametro"
  );
};

const commonStyle = {
  whiteSpace: "nowrap",
  verticalAlign: "middle",
  textAlign: "start",
  minWidth: "82px",
};

const getValues = ({
  addNewLine = () => {},
  onChangeIndicator = () => {},
  onChangeName = () => {},
  onChangeParameter = () => {},
  onChangeGoal = () => {},
  onDelete = () => {},
  freeIndicators,
  periodTitles,
  parameters = [],
  disabled,
  indicators,
}) => {
  if (periodTitles.length === 0) return [];
  const periodColumns = periodTitles.map((month, monthIndex) => ({
    id: month,
    titulo: <GoalTitleTable title={month} required />,
    ordenavel: false,
    estilo: commonStyle,
    propriedadesCustomizadas: (goalLine) => ({
      colsPan: checkColSpan(goalLine, monthIndex),
    }),
    hidden: (goalLine) => checkPeriodHidden(goalLine, monthIndex),
    pegaValor: (goalLine) => (
      <GoalInput
        disabled={disabled || !checkIfParameterIsSelected(goalLine)}
        goalLine={goalLine}
        onChangeGoal={onChangeGoal}
        monthIndex={monthIndex}
      />
    ),
  }));

  return [
    {
      id: localConstants.titles.indicator,
      titulo: (
        <GoalTitleTable title={localConstants.titles.indicator} required />
      ),
      ordenavel: false,
      required: true,
      estilo: {
        ...commonStyle,
        paddingRight: 0,
      },
      pegaValor: (goalLine) => (
        <SelectIndicators
          disabled={disabled}
          goalLine={goalLine}
          freeIndicators={freeIndicators}
          indicators={indicators}
          addNewLine={addNewLine}
          onChangeIndicator={onChangeIndicator}
        />
      ),
    },
    {
      id: localConstants.titles.name,
      titulo: <GoalTitleTable title={localConstants.titles.name} />,
      required: false,
      ordenavel: false,
      estilo: {
        ...commonStyle,
      },
      hidden: checkHidden,
      pegaValor: (goalLine) => (
        <DefaultInput
          testId={`${goalLine.indicatorName || ""}-Input-Name`}
          disabled={disabled}
          placeholder={!goalLine.name && "Informe o apelido"}
          value={goalLine.name}
          onChange={(event) => onChangeName(event, goalLine.indicatorName)}
        />
      ),
    },
    {
      id: localConstants.titles.unit,
      titulo: <GoalTitleTable title={localConstants.titles.unit} />,
      required: false,
      ordenavel: false,
      estilo: {
        ...commonStyle,
      },
      hidden: checkHidden,
      pegaValor: (goalLine) => (
        <DisableInput
          fullWidth
          placeholder="-"
          value={goalLine.indicatorUnit.toUpperCase()}
          disabled
        />
      ),
    },
    {
      id: localConstants.titles.parameter,
      titulo: (
        <GoalTitleTable title={localConstants.titles.parameter} required />
      ),
      required: true,
      ordenavel: false,
      estilo: {
        ...commonStyle,
        paddingRight: 5,
      },
      infoMessage: localConstants.tooltipMessage,
      hidden: checkHidden,
      pegaValor: (goalLine) => (
        <SelectParameters
          disabled={disabled}
          goalLine={goalLine}
          parameters={parameters}
          onChangeParameter={onChangeParameter}
        />
      ),
    },
    ...periodColumns,
    {
      id: localConstants.titles.accumulated,
      titulo: <GoalTitleTable title={localConstants.titles.accumulated} />,
      ordenavel: false,
      estilo: { ...commonStyle, minWidth: "124px" },
      hidden: checkHidden,
      pegaValor: (goalLine) => (
        <DisableInput
          fullWidth
          disabled
          value={calcAccumulated(goalLine, indicators, parameters)}
          textAlign="center"
        />
      ),
    },
    {
      id: localConstants.titles.delete,
      titulo: <GoalTitleTable title={localConstants.titles.delete} />,
      ordenavel: false,
      estilo: commonStyle,
      hidden: checkHidden,
      pegaValor: (goalLine) => (
        <IconButton
          disabled={disabled}
          onClick={() => onDelete(goalLine.indicatorName)}
        >
          <Delete />
        </IconButton>
      ),
    },
  ];
};

export default getValues;
