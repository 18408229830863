import React from "react";
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import styles from "styles/MenuLateral";

const MensagemMenuLateral = ({
  mensagem,
  mensagemClass,
  children,
  childrenContainerClass,
  classes,
}) => {
  return (
    <div className={classes.telaInfomacao}>
      <div className={childrenContainerClass || classes.iconeMapa}>
        {children}
      </div>
      <Typography className={mensagemClass || classes.mensagem}>
        {mensagem}
      </Typography>
    </div>
  );
};

MensagemMenuLateral.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  mensagem: PropTypes.string.isRequired,
};

export default withStyles(styles)(MensagemMenuLateral);
