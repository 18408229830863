import React from "react";
import BatchListingFilterPage from "./batchListingFilterPage";
import { useReactToPrint } from "react-to-print";
import { useEffect } from "react";

const BatchListingFilterContainer = ({
  retiros,
  filtroPesoSelecionado,
  filtroPesoProjetadoSelecionado,
  filtroGeral,
  componentRef,
  valorRetiroSelecionado,
  setValorRetiroSelecionado,
  categoriaTexto,
  setCategoriaTexto,
  valorFiltroPeso,
  setValorFiltroPeso,
  valorFiltroProjetado,
  setValorFiltroProjetado,
}) => {
  const tiposFiltrosPesoPesado = [
    { Tipo: "Todos" },
    { Tipo: "Informado" },
    { Tipo: "Não Informado" },
  ];

  const tiposFiltrosPesoProjetado = [
    { Tipo: "Todos" },
    { Tipo: "Disponível" },
    { Tipo: "Não disponível" },
  ];

  const filtroDefault = (valorParaFiltrar, tipoDoFiltro) => {
    if (
      tiposFiltrosPesoPesado.some((v) => v.Tipo === valorParaFiltrar) &&
      tipoDoFiltro === "peso"
    ) {
      setValorFiltroPeso(
        valorParaFiltrar === "Todos" ? "Todos" : valorParaFiltrar
      );
    } else if (
      tiposFiltrosPesoProjetado.some((v) => v.Tipo === valorParaFiltrar) &&
      !tipoDoFiltro
    ) {
      setValorFiltroProjetado(
        valorParaFiltrar === "Todos" ? "Todos" : valorParaFiltrar
      );
    } else if (tipoDoFiltro) {
      setCategoriaTexto(valorParaFiltrar);
    } else if (
      valorRetiroSelecionado.some(
        (v) => v.Nome === valorParaFiltrar && !tipoDoFiltro
      )
    ) {
      setValorRetiroSelecionado(
        valorParaFiltrar === "Todos" ? "Todos" : valorParaFiltrar
      );
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef,
  });

  useEffect(() => {
    const filtroMontado = {
      categoriaTexto,
      valorFiltroPeso,
      valorFiltroProjetado,
      valorRetiroSelecionado,
    };
    filtroGeral(filtroMontado);

  }, [
    categoriaTexto,
    valorFiltroPeso,
    valorFiltroProjetado,
    valorRetiroSelecionado,
  ]);

  return (
    <BatchListingFilterPage
      retiros={retiros}
      filtroPesoSelecionado={filtroPesoSelecionado}
      filtroPesoProjetadoSelecionado={filtroPesoProjetadoSelecionado}
      tiposFiltrosPesoPesado={tiposFiltrosPesoPesado}
      tiposFiltrosPesoProjetado={tiposFiltrosPesoProjetado}
      filtroDefault={filtroDefault}
      handlePrint={handlePrint}
      valorRetiroSelecionado={valorRetiroSelecionado}
      categoriaTexto={categoriaTexto}
      valorFiltroPeso={valorFiltroPeso}
      valorFiltroProjetado={valorFiltroProjetado}
      setValorRetiroSelecionado={setValorRetiroSelecionado}
      setCategoriaTexto={setCategoriaTexto}
      setValorFiltroPeso={setValorFiltroPeso}
      setValorFiltroProjetado={setValorFiltroProjetado}
    />
  );
};

export default BatchListingFilterContainer;
