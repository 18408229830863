import _ from "lodash";
import { idsFarois } from "../../../../../constants/idsFarois";
import { nomesTogglesPorIdFarol } from "../../../../../constants/idsFarois";
import { opcoesFarolAltura } from "../../MenuLateral/Farol/farolContainerUtils";

const FAROIS_REBANHO = { PRESENCA_REBANHO: 11 };

const particionaFaroisPorToggle = (farois, nomesTogglesPorIdFarol) => {
  return _.partition(farois, (farol) => nomesTogglesPorIdFarol[farol.IdFarol]);
};

const montarObjetosToggleFarol = (farois, nomesTogglesPorIdFarol) => {
  const farolPorToggle = {};
  farois.forEach((farol) => {
    if (nomesTogglesPorIdFarol && nomesTogglesPorIdFarol[farol.IdFarol]) {
      const nomeToggle = nomesTogglesPorIdFarol[farol.IdFarol];
      farolPorToggle[nomeToggle] = farol;
    }
  });
  return farolPorToggle;
};
const filtraFaroisToggleDesabilitados = (farolPorToggle, Toggles) => {
  const arrayFaroisPorToggle = Object.entries(farolPorToggle);
  return arrayFaroisPorToggle.filter(([nomeToggle]) =>
    _.some(Toggles, (toggle) => toggle.Nome === nomeToggle && toggle.Ativo)
  );
};
const mapearFaroisFiltrados = (faroisToggle) => {
  return faroisToggle.map(([nome, farol]) => farol);
};

const deveExibirBotaoDetalhesRebanho = (tipoFarol) => {
  return tipoFarol === "rebanho";
};
const deveExibirBotaoDetalhesForragem = (
  exibirDetalhes,
  CiclosForragemFazenda,
  exibirFiltro,
  TipoFarol
) => {
  return (
    !exibirDetalhes &&
    CiclosForragemFazenda.length > 0 &&
    exibirFiltro &&
    TipoFarol === "altura-pasto"
  );
};

const deveExibirDetalhesRebanho = (
  exibirDetalhes,
  TipoFarol,
  farolSelecionado
) => {
  return (
    exibirDetalhes &&
    TipoFarol &&
    TipoFarol === "rebanho" &&
    Number(farolSelecionado) === idsFarois.rebanhoPresenca
  );
};
const deveExibirAvisoEntrevero = (lotesComEntrevero, TipoFarol) =>
  TipoFarol === "rebanho" && lotesComEntrevero.length > 0;

const deveExibirDropDownColeta = (exibirDetalhes, FazendaSelecionada) => {
  return !exibirDetalhes && FazendaSelecionada;
};

const deveExibirSelectFarois = (exibirFiltro, TipoFarol, exibirDetalhes) => {
  return exibirFiltro && TipoFarol && !exibirDetalhes;
};

const deveExibirFiltroPeriodo = (exibirDetalhes, exibirFiltro, TipoFarol) => {
  return (
    !exibirDetalhes &&
    exibirFiltro &&
    TipoFarol &&
    TipoFarol !== "rebanho" &&
    TipoFarol !== "sanidade"
  );
};

const deveExibirDetalhesForragem = (exibirDetalhes, TipoFarol) => {
  return exibirDetalhes && TipoFarol && TipoFarol === "altura-pasto";
};

const deveExibirAlbumFotoMorte = (TipoFarol, pastoSelecionado) => {
  return TipoFarol === "nascimento-morte" && pastoSelecionado.IdPasto !== null;
};

const ordenaFarois = (faroisOrdenar) => {
  return _.orderBy(faroisOrdenar, (farol) => farol.OrdemFarol);
};

const trataFaroisRenderizar = (farois, Toggles) => {
  const [faroisAfetados, faroisNaoAfetados] = particionaFaroisPorToggle(
    farois,
    nomesTogglesPorIdFarol
  );
  const objetoToggleFarol = montarObjetosToggleFarol(
    faroisAfetados,
    nomesTogglesPorIdFarol
  );
  const objetoToggleFarolFiltrado = filtraFaroisToggleDesabilitados(
    objetoToggleFarol,
    Toggles
  );
  const faroisAlteradosFiltrados = mapearFaroisFiltrados(
    objetoToggleFarolFiltrado
  );
  const faroisFiltrados = [...faroisNaoAfetados, ...faroisAlteradosFiltrados];
  const faroisOrdenados = ordenaFarois(faroisFiltrados);
  return faroisOrdenados;
};

const deveDesabilitarBotao = () => false;

const obtemOpcaoTipoAfericao = (grupoFarol) => {
  if (grupoFarol === "ALTURA_PASTO") return opcoesFarolAltura[0];
  else return null;
};

const getDadosFarolEnviarGA = (farolSelecionado) => {
  let value = "";
  if (farolSelecionado === idsFarois.rebanhoPresenca) {
    value = "Farol Presença Rebanho";
  }
  const acao = `Ver detalhes de faróis Rebanho`;
  const rotulo = "Carga suporte";
  const categoria = "Análise";
  return { acao, rotulo, categoria, value };
};

const verificaToggleNascimentoMorte = (Toggles) => {
  const nascimento = verificaToggleNascimento(Toggles);
  const morte = verificaToggleMorte(Toggles);

  return nascimento || morte;
};

const retornaLabelNascimentoMorte = (Toggles) => {
  const nascimento = verificaToggleNascimento(Toggles);
  const morte = verificaToggleMorte(Toggles);

  if (nascimento && morte) return "Nascimento/Morte";
  else if (nascimento) return "Nascimento";
  else if (morte) return "Morte";
};

const verificaToggleNascimento = (Toggles) => {
  return _.some(Toggles, (t) => t.Nome === "COLETA_NASCIMENTO" && t.Ativo);
};

const verificaToggleCreep = (Toggles) => {
  return _.some(Toggles, (t) => t.Nome === "CREEP_MANEJO" && t.Ativo);
};

const verificaToggleMorte = (Toggles) => {
  return _.some(Toggles, (t) => t.Nome === "COLETA_MORTE" && t.Ativo);
};

const verificaToggleVisualizaGeolocalizacaoAlturaPasto = (Toggles) => {
  return _.some(
    Toggles,
    (t) => t.Nome === "VISUALIZA_GEOLOCALIZACAO_ALTURA_PASTO" && t.Ativo
  );
};

const constroiListaFarois = (toggles = [], isFazendaCreep, isClienteLite) => {
  const listaTiposColeta = [];

  if (toggles.some((t) => t.Nome === "COLETA_AGUADA" && t.Ativo)) {
    listaTiposColeta.push({ value: "aguada", label: "Aguada" });
  }
  if (!isClienteLite) {
    listaTiposColeta.push({
      value: "altura-pasto",
      label: "Altura de Forragem",
    });
  }
  if (
    !isClienteLite &&
    toggles.some((t) => t.Nome === "COLETA_CERCA" && t.Ativo)
  ) {
    listaTiposColeta.push({ value: "cerca", label: "Cerca" });
  }
  listaTiposColeta.push({ value: "cocho", label: "Cocho" });
  if (!isClienteLite && verificaToggleCreep(toggles) && isFazendaCreep) {
    listaTiposColeta.push({ value: "cocho-creep", label: "Cocho Creep" });
  }
  if (
    !isClienteLite &&
    toggles.some((t) => t.Nome === "COLETA_FEZES" && t.Ativo)
  ) {
    listaTiposColeta.push({ value: "fezes", label: "Fezes" });
  }
  if (verificaToggleNascimentoMorte(toggles)) {
    listaTiposColeta.push({
      value: "nascimento-morte",
      label: retornaLabelNascimentoMorte(toggles),
    });
  }
  if (
    toggles.some(
      (t) => t.Nome === "COLETA_E_MOVIMENTACAO_DE_REBANHO" && t.Ativo
    )
  ) {
    listaTiposColeta.push({ value: "rebanho", label: "Rebanho" });
  }
  if (
    !isClienteLite &&
    toggles.some((t) => t.Nome === "COLETA_SANIDADE" && t.Ativo)
  ) {
    listaTiposColeta.push({ value: "sanidade", label: "Sanidade" });
  }

  return listaTiposColeta;
};

export {
  filtraFaroisToggleDesabilitados,
  montarObjetosToggleFarol,
  particionaFaroisPorToggle,
  mapearFaroisFiltrados,
  FAROIS_REBANHO,
  deveExibirBotaoDetalhesRebanho,
  ordenaFarois,
  trataFaroisRenderizar,
  deveDesabilitarBotao,
  obtemOpcaoTipoAfericao,
  deveExibirDetalhesRebanho,
  getDadosFarolEnviarGA,
  deveExibirDropDownColeta,
  deveExibirSelectFarois,
  deveExibirFiltroPeriodo,
  deveExibirDetalhesForragem,
  deveExibirBotaoDetalhesForragem,
  deveExibirAlbumFotoMorte,
  verificaToggleNascimentoMorte,
  retornaLabelNascimentoMorte,
  verificaToggleNascimento,
  verificaToggleMorte,
  verificaToggleCreep,
  deveExibirAvisoEntrevero,
  constroiListaFarois,
  verificaToggleVisualizaGeolocalizacaoAlturaPasto,
};
