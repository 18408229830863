import React from "react";
import moment from "moment";
import { IconButton, withStyles } from "@material-ui/core";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import EditIcon from "@material-ui/icons/Create";
import { FormatListBulleted } from "@material-ui/icons";
import SwitchCustomizado from "features/shared/components/SwitchCustomizado";
import TabelaProdap from "features/shared/components/TabelaProdap";
import IngredientRegisterHistoricModal from "../../ingredientRegisterHistoricModal/components/ingredientRegisterHistoricModal";

const columnCodeProduct = ({ classes }) => ({
  id: "productName",
  titulo: (
    <span>
      CÓDIGO DO
      <br /> INGREDIENTE (PA)
    </span>
  ),
  pegaValor: (linha) =>
    linha.Produto ? (
      <span>
        <span>{linha.Produto.name}</span>
        <span className={classes.subtitle}>{linha.Produto.code}</span>
      </span>
    ) : (
      <span>-</span>
    ),
  propriedadesCustomizadas: {
    "data-testid": "codigoPAIngredientes",
  },
});

const columnNomeMateriaPrima = () => ({
  id: "NomeMateriaPrima",
  titulo: "MATÉRIA PRIMA",
  pegaValor: (linha) => <span>{linha.NomeMateriaPrima || "-"}</span>,
  propriedadesCustomizadas: {
    "data-testid": "nomeIngredientes",
  },
});

const columnDescricao = () => ({
  id: "Descricao",
  titulo: "NOME DO INGREDIENTE",
  infoMessage: "É o nome escolhido para identificação do produto.",
  pegaValor: (linha) => <span>{linha.Descricao}</span>,
  propriedadesCustomizadas: {
    "data-testid": "descricaoIngredientes",
  },
});

const columnDataCompra = () => ({
  id: "DataCompra",
  titulo: (
    <span>
      DATA DA <br />
      COMPRA
    </span>
  ),
  sortMethod: "date",
  pegaValor: (linha) => (
    <span>
      {linha.DataCompra ? moment(linha.DataCompra).format("DD/MM/YYYY") : "-"}
    </span>
  ),
  propriedadesCustomizadas: {
    "data-testid": "dataIngredientes",
  },
});

const columnCustoPorQuilo = () => ({
  id: "CustoPorQuilo",
  titulo: (
    <span>
      CUSTO <br />
      POR KG
    </span>
  ),
  pegaValor: (linha) => (
    <span>
      {linha.CustoPorQuilo ? `R$${linha.CustoPorQuilo.toFixed(2)}` : "-"}
    </span>
  ),
  propriedadesCustomizadas: {
    "data-testid": "precoIngredientes",
  },
});

const columnLancaCompra = ({ classes, lancarCompra }) => ({
  id: "",
  ordenavel: false,
  titulo: (
    <span className={classes.divAlinhamento}>
      LANÇAR
      <br /> COMPRA
    </span>
  ),
  headerCellClass: classes.middleCellClass,
  cellClass: classes.middleCellClass,
  pegaValor: (linha) => (
    <div className={classes.divAlinhamento}>
      <IconButton
        data-testid="botaoLancarCompra"
        onClick={() => lancarCompra(linha)}
        aria-label="Lançar compra"
      >
        <MonetizationOnIcon />
      </IconButton>
    </div>
  ),
  propriedadesCustomizadas: {
    "data-testid": "lancarCompra",
  },
});

const columnEditar = ({ classes, ativarEdicaoIngrediente }) => ({
  id: "",
  titulo: <div className={classes.divAlinhamento}>EDITAR</div>,
  ordenavel: false,
  headerCellClass: classes.middleCellClass,
  cellClass: classes.middleCellClass,
  pegaValor: (linha) => (
    <div className={classes.divAlinhamento}>
      <IconButton
        data-testid="botaoEditarIngredientes"
        onClick={() => ativarEdicaoIngrediente(linha)}
        aria-label="Edit"
      >
        <EditIcon />
      </IconButton>
    </div>
  ),
  propriedadesCustomizadas: {
    "data-testid": "editarIngredientes",
  },
});

const columnAtivo = ({ classes, ativaOuDesativaIngrediente }) => ({
  id: "Ativo",
  titulo: "ATIVO",
  estilo: { textAlign: "center" },
  headerCellClass: classes.middleCellClassWithOrder,
  cellClass: classes.middleCellClass,
  pegaValor: (linha) => (
    <div className={classes.divAlinhamento}>
      <SwitchCustomizado
        testId={`botaoAtivaDesativa-${linha.IdIngrediente}`}
        handleClick={() =>
          ativaOuDesativaIngrediente(
            linha.IdIngrediente,
            linha.Ativo,
            linha.Descricao
          )
        }
        checked={linha.Ativo}
      />
    </div>
  ),
  propriedadesCustomizadas: {
    "data-testid": "switchIngredientes",
  },
});

const columnHistorico = ({ classes, abreHistoricoIngredientes }) => ({
  ordenavel: false,
  titulo: <div className={classes.divAlinhamento}>HISTÓRICO</div>,
  headerCellClass: classes.middleCellClass,
  cellClass: classes.middleCellClass,
  pegaValor: (linha) => (
    <div className={classes.divAlinhamento}>
      <IconButton
        data-testid="botaoDetalhesIngredientes"
        aria-label="detalhes"
        onClick={() => abreHistoricoIngredientes(linha)}
      >
        <FormatListBulleted className={classes.iconeMenu} />
      </IconButton>
    </div>
  ),
  propriedadesCustomizadas: {
    "data-testid": "detalhesIngredientes",
  },
});

const pegaColunas = ({
  classes,
  lancarCompra,
  ativarEdicaoIngrediente,
  ativaOuDesativaIngrediente,
  abreHistoricoIngredientes,
  isInternalUser,
}) => {
  const communColumns = [
    columnNomeMateriaPrima({ classes }),
    columnDescricao({ classes }),
    columnDataCompra({ classes }),
    columnCustoPorQuilo({ classes }),
    columnLancaCompra({ classes, lancarCompra }),
    columnEditar({ classes, ativarEdicaoIngrediente }),
    columnAtivo({ classes, ativaOuDesativaIngrediente }),
    columnHistorico({ classes, abreHistoricoIngredientes }),
  ];
  if (isInternalUser) {
    return [columnCodeProduct({ classes }), ...communColumns];
  } else {
    return communColumns;
  }
};

export const IngredientRegisterTable = ({
  historico,
  ingredientes,
  abrirHistorico,
  ingredienteSelecionado,
  abreHistoricoIngredientes,
  obterHistorico,
  setAbrirHistorico,
  lancarCompra,
  ativarEdicaoIngrediente,
  ativaOuDesativaIngrediente,
  classes,
  isInternalUser,
}) => {

  return (
    <div className={classes.wrapperTable}>
      <TabelaProdap
        tabelaEsquerda
        modoOrdenacao={"CLIENTE"}
        linhas={ingredientes}
        ativarPaginacao={false}
        ingredientesHeaderCelulas
        propriedadesLinhas={{
          "data-testid": "linhasTabelaIngredientes",
        }}
        propriedadesCelulasCabecalho={{
          "data-testid": "ingredientesHeaderCelulas",
        }}
        colunas={pegaColunas({
          classes,
          lancarCompra,
          ativarEdicaoIngrediente,
          ativaOuDesativaIngrediente,
          abreHistoricoIngredientes,
          isInternalUser,
        })}
      />

      <IngredientRegisterHistoricModal
        ingrediente={ingredienteSelecionado}
        abrirHistorico={abrirHistorico}
        aoFechar={() => setAbrirHistorico(false)}
        historico={historico.items}
        obterHistorico={obterHistorico}
        isInternalUser={isInternalUser}
      />
    </div>
  );
};

const styles = (theme) => ({
  divAlinhamento: {
    display: "block",
    textAlign: "center",
  },
  subtitle: {
    fontWeight: "500",
    display: "block",
  },
  iconeMenu: {
    color: theme.palette.primary.main,
  },
  middleCellClass: {
    padding: "4px 24px 4px 24px",
  },
  middleCellClassWithOrder: {
    padding: "4px 28px 4px 42px",
  },
});

export default withStyles(styles)(IngredientRegisterTable);
