import React from "react";
import HistoricoChuvaContainer from "../../historicoChuva/views/historicoChuvaContainer";
import CategoriaContainer from "../../categoria/views/categoriaContainer";
import ListagemFormulacaoContainer from "../../formulacao/views/formulationList/listagemFormulacaoContainer";
import LotesContainer from "../../lote/views/lotesContainer";
import CurralContainer from "../../curral/views/curralContainer";
import GanhoPesoContainer from "../../projecaoGanhoPeso/views/ganhoPesoContainer";
import ObjetivoContainer from "../../objetivo/views/objetivoContainer";
import CausaMorteContainer from "../../causaMorte/views/causaMorteContainer";
import AjusteEscoreContainer from "../../ajusteEscore/views/ajusteEscoreContainer";
import PesagemCategoriaContainer from "../../pesagemCategoria/views/pesagemCategoriaContainer";
import GoalPlanRouter from "features/goalPlan/views/goalPlanRouter";
import { TabContentDefault } from "../../layout";
import CadastroIngredientesContainer from "../../cadastroIngredientes/views/cadastroIngredientesContainer";
import PlanningRouter from "features/planning/views/planningRouter";

const ConfiguracaoTabContent = (props) => {
  const {
    changeInterceptOnChangeTab,
    changeTabRequested,
    confirmChangeTab,
    onCancelChangeTab,
    exibirGanhoPeso,
    exibirCadastroFormulacao,
    exibirObjetivoLote,
    exibirHistoricoChuva,
    exibirCausaMorte,
    exibirAjusteEscore,
    exibirEvolucaoRebanhoPan,
    fazendaSelecionada,
    abaSelecionada,
    tabToChange,
  } = props;

  const componentsList = [
    {
      nome: "Categoria Animal",
      id: "tabCategoriaAnimal",
      componente: (props) => (
        <CategoriaContainer
          exibeTamanhoLote={true}
          exibirGanhoPeso={exibirGanhoPeso}
          exibeCurvaGanhoPeso={true}
          {...props}
        />
      ),
      exibir: fazendaSelecionada,
    },
    {
      nome: "Listagem de Lote",
      componente: (props) => <LotesContainer {...props} />,
      exibir: fazendaSelecionada,
    },
    {
      nome: "Listagem de Curral",
      componente: (props) => <CurralContainer {...props} />,
      exibir: fazendaSelecionada,
    },
    {
      nome: "Formulações",
      componente: (props) => <ListagemFormulacaoContainer {...props} />,
      exibir: exibirCadastroFormulacao && fazendaSelecionada,
    },
    {
      nome: "Curva de ganho de peso",
      componente: (props) => <GanhoPesoContainer {...props} />,
      exibir: fazendaSelecionada,
    },
    {
      nome: "Objetivos de lote",
      componente: (props) => <ObjetivoContainer {...props} />,
      exibir: exibirObjetivoLote && fazendaSelecionada,
    },
    {
      nome: "Causa de morte",
      componente: (props) => <CausaMorteContainer {...props} />,
      exibir: exibirCausaMorte && fazendaSelecionada,
    },
    {
      nome: "Histórico de Chuva",
      componente: (props) => <HistoricoChuvaContainer {...props} />,
      exibir: exibirHistoricoChuva && fazendaSelecionada,
    },
    {
      nome: "Ajuste de Escore",
      componente: (props) => <AjusteEscoreContainer {...props} />,
      exibir: exibirAjusteEscore && fazendaSelecionada,
    },
    {
      nome: "Ingredientes",
      componente: (props) => <CadastroIngredientesContainer {...props} />,
      exibir: fazendaSelecionada,
    },
    {
      nome: "Pesagem por Categoria",
      componente: (props) => <PesagemCategoriaContainer {...props} />,
      exibir: fazendaSelecionada,
    },
    {
      nome: "Metas da Fazenda",
      componente: (props) => <GoalPlanRouter {...props} />,
      exibir: fazendaSelecionada,
    },
    {
      nome: "Evolução de rebanho e plano de nutrição",
      componente: (props) => <PlanningRouter {...props} />,
      exibir: exibirEvolucaoRebanhoPan && fazendaSelecionada,
    },
  ];
  return componentsList.map((value) => {
    if (value.nome === abaSelecionada && value.exibir) {
      return (
        <TabContentDefault
          style={{ paddingTop: "0!important", marginTop: 24 }}
          index={value.IdFormula}
        >
          {value.componente({
            tabToChange,
            changeInterceptOnChangeTab,
            changeTabRequested,
            confirmChangeTab,
            onCancelChangeTab,
          })}
        </TabContentDefault>
      );
    } else return <></>;
  });
};

export default ConfiguracaoTabContent;
