import { createMuiTheme } from "@material-ui/core/styles";

export const palette = {
  primary: {
    main: "#307297",
  },
  secondary: {
    main: "#4da32f",
  },
  error: {
    main: "#fa3336",
  },
  disabled: {
    main: "#c7c7cc",
    light: "#fafafa",
  },
  default1: {
    main: "#333333",
    light: "#757575",
  },
  default2: {
    light: "#e1e1e1",
    main: "#666666",
  },
  default3: {
    main: "#999999",
  },
  white: {
    main: "#ffffff",
  },
  complementary: {
    main: "#CBD938",
  },
  red: {
    main: "#F44336",
  },
  gray: {
    main: "#BDBDBD",
  },
};

const fontSize = {
  small: "12px",
  normal: "14px",
  regular: "18px",
  large: "20px",
};

const typography = {
  baseTituloTela: {
    fontFamily: "Roboto",
    fontSize: fontSize.large,
    fontWeight: "bold",
    fontStyle: "medium",
    fontStretch: "medium",
    lineHeight: "medium",
    letterSpacing: "medium",
    color: palette.default1.main,
  },
};

const telas = {
  marginTopTitulo: "40px",
  marginBottomTitulo: "20px",
};

const botoes = {
  defaultHeight: "36px",
  defaultBorderRadius: "18px",
};

const tabela = {
  borderRadius: "16px",
  scrollBar: {
    "&::-webkit-scrollbar": {
      width: 9,
      height: 10,
      borderRadius: 10,
    },
  },
};

export default createMuiTheme({
  shape: {
    borderRadius: 16,
  },
  palette,
  typography: {
    // fontFamily: "'Roboto'",
    useNextVariants: true,
    size: fontSize,
  },
  props: {
    tamanhoAppBarSm: 220.5,
    tamanhoAppBarMd: 147,
    tamanhoAppBarXs: 75,
    subTitle: { color: palette.default2.main },
  },
  tabela,
  telas: {
    botoes: {
      base: {
        height: botoes.defaultHeight,
        borderRadius: botoes.defaultBorderRadius,
      },
    },
    telaContainer: {
      width: "100%",
      height: "100%",
      overflowX: "auto",
      padding: "40px",
      zIndex: -1,
      bottom: 0,
      top: "17%",
      minHeight: "calc(100vh - 90px)",
    },
    marginTopTitulo: telas.marginTopTitulo,
    marginBottomTitulo: telas.marginBottomTitulo,
    defaultTitulo: typography.baseTituloTela,
    tituloComMarginTop: {
      ...typography.baseTituloTela,
      marginTop: telas.marginTopTitulo,
    },
    tituloComMarginTopBottom: {
      ...typography.baseTituloTela,
      marginTop: telas.marginTopTitulo,
      marginBottom: telas.marginBottomTitulo,
    },
  },
});
