import { LISTA_USUARIOS_CLIENTES } from "../../../redux/actions/actionsConstants";

export const initialState = {
  UsuariosClientes: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LISTA_USUARIOS_CLIENTES:
      return { ...state, UsuariosClientes: action.payload.UsuariosClientes };
    default:
      return state;
  }
};
