import React, { Component } from "react";
import ReactGA from "react-ga";
import { Router } from "react-router";
import { Switch, Route } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core";
import Theme from "../../styles/theme";
import PaginaInicial from "../paginaInicial";
import Manejo from "../manejo/views/Manejo";
import LoginPage from "../authentication/views/LoginPage";
import ForgotPasswordPage from "../authentication/views/ForgotPasswordPage";
import auth from "../authentication/Auth";
import history from "./History";
import ConfiguracaoRouter from "../configuracao/configuracaoRouter";
import CollectRouter from "../collect/collectRouter";
import { FechamentoConsumoContainer } from "../fechamentoConsumo";
import TableauContainer from "../tableauEmbedded/views/tableauContainer";
import HistoricoMovimentacaoAppContainer from "../historicoMovimentacaoApp/views/historicoMovimentacaoAppContainer";

import "./App.css";
import ErroBoundary from "../layout/views/erroBoundary";
import MapaDeRebanho from "../mapaDeRebanho/views/mapaDeRebanho";
import SupplementationRouter from "features/collect/supplementation/views/supplementationRouter";
import ChangePasswordPage from "features/authentication/views/ChangePasswordPage";
import UserManagementRouter from "features/userManagement/userManagementRouter";
import PendencyContainer from "features/pendency/views/pendencyContainer";

export class App extends Component {
  constructor(props) {
    super(props);
    this.auth = auth;
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
      debug: false,
    });
  }

  render() {
    const usuarioAnalytics = this.auth.isAnalyticsUser();
    return usuarioAnalytics ? (
      <Router history={history}>
        <React.Fragment>
          <CssBaseline />
          <MuiThemeProvider theme={Theme}>
            <Switch>
              <Route
                exact
                path="/"
                component={usuarioAnalytics ? TableauContainer : PaginaInicial}
                on
              />
              <Route exact path="/analytics" component={TableauContainer} />
            </Switch>
          </MuiThemeProvider>
        </React.Fragment>
      </Router>
    ) : (
      <Router history={history}>
        <React.Fragment>
          <CssBaseline />
          <MuiThemeProvider theme={Theme}>
            <Switch>
              <Route exact path="/" component={PaginaInicial} />
              <Route path="/login" component={LoginPage} on />
              <Route path="/esqueceu-senha" component={ForgotPasswordPage} />
              <Route
                path="/alterar-senha/:token"
                component={ChangePasswordPage}
              />
              <Route exact path="/analytics" component={TableauContainer} />
              <Route
                path="/configuracoes"
                render={(routeProps) => <ConfiguracaoRouter {...routeProps} />}
              />
              <Route
                path="/gestao-de-usuarios"
                component={UserManagementRouter}
              />
              <Route
                path="/coletas"
                render={(routeProps) => <CollectRouter {...routeProps} />}
              />
              <Route
                exact
                path="/fechamento_consumo"
                component={FechamentoConsumoContainer}
              />
              <Route
                exact
                path="/suplementacao"
                component={SupplementationRouter}
              />
              <Route
                exact
                path="/controleDeRebanho"
                component={MapaDeRebanho}
              />
              <Route
                exact
                path="/historico_movimentacao_aplicativo"
                component={HistoricoMovimentacaoAppContainer}
              />
              <Route
                path="/gestao-de-usuarios"
                component={(routeProps) => <UserManagementRouter {...routeProps} />}
              />
              <Route
                path="/movimentacoesPendentes"
                component={PendencyContainer}
              />
              <ErroBoundary>
                <Route exact path="/(\w+)" component={Manejo} />
              </ErroBoundary>
            </Switch>
          </MuiThemeProvider>
        </React.Fragment>
      </Router>
    );
  }
}

export default App;
