import ConciliacaoManager from "../conciliacaoManager";
import {
  notifyError,
  notifySuccess,
} from "../../../redux/actions/notificacaoActions";
import {
  listarConciliacoesFazenda,
  listarDetalhesCategoria,
  atualizaDataConciliacao,
  exibirCarregando,
} from "./conciliacaoActions";
import moment from "moment";
import { getFazendaStorage, setFazendaStorage } from "shared/storageUtils";

export const obterConciliacoes =
  (idFazenda, dataInicial, dataFinal, dataInicioConciliacao) =>
    async (dispatch) => {
      dispatch(exibirCarregando(true));
      try {
        const dataInicio = moment(dataInicial).format("YYYY-MM-DD");
        const dataFim = moment(dataFinal).format("YYYY-MM-DD");
        const dataInicioConciliacaoFormatada = moment(dataInicioConciliacao).format("YYYY-MM-DD");
        const conciliacoesFazenda =
          await ConciliacaoManager.listarConciliacoesFazenda(
            idFazenda,
            dataInicio,
            dataFim,
            dataInicioConciliacaoFormatada
          );
        dispatch(listarConciliacoesFazenda(conciliacoesFazenda.data));
      } catch (error) {
        const mensagemErro = {
          mensagem: "Ocorreu um erro ao obter a lista de conciliações.",
        };
        dispatch(listarConciliacoesFazenda([]));
        dispatch(notifyError(mensagemErro));
      } finally {
        dispatch(exibirCarregando(false));
      }
    };

export const salvaDataInicioConciliacao =
  (dataInicioConciliacao, idFazenda) => async (dispatch) => {
    dispatch(exibirCarregando(true));
    try {
      const dataSalvada = await ConciliacaoManager.salvaDataInicioConciliacao(
        dataInicioConciliacao,
        idFazenda
      );
      await dispatch(
        atualizaDataConciliacao(dataSalvada.DataInicioConciliacao)
      );
      const localFazendas = JSON.parse(getFazendaStorage());
      setFazendaStorage({
        ...localFazendas,
        DataInicioConciliacao: dataSalvada.DataInicioConciliacao,
      })
      await dispatch(
        notifySuccess({
          mensagem: "Data de conciliação editada com sucesso.",
        })
      );
    } catch (error) {
      console.log(error);
      const mensagemErro = {
        mensagem: "Ocorreu um erro ao salvar a data de início da conciliação.",
      };
      dispatch(atualizaDataConciliacao(null));
      dispatch(notifyError(mensagemErro));
    } finally {
      dispatch(exibirCarregando(false));
    }
  };

export const obterDetalhesCategoria =
  (idFazenda, idCategoria, dataInicial, dataFinal) => async (dispatch) => {
    try {
      const operacoes = await ConciliacaoManager.detalhesCategoriaConciliacao(
        idFazenda,
        idCategoria,
        dataInicial,
        dataFinal
      );
      dispatch(listarDetalhesCategoria(operacoes.data));
    } catch (error) {
      const mensagemErro = {
        mensagem: "Ocorreu um erro ao obter a lista de operações",
      };
      dispatch(listarDetalhesCategoria({}));
      dispatch(notifyError(mensagemErro));
    }
  };
