import React from "react";
import { connect } from "react-redux";
import GoalPlanCopyPage from "./goalPlanCopyPage";
import { useSubmitUtils } from "../../hooks/useSubmitUtils";
import {
  disableLoading,
  enableLoading,
  notifyError,
  notifySuccess,
} from "../../redux/goalPlanOperations";

const GoalPlanCopyContainer = ({
  goalPlanId,
  farmId,
  onBack,
  notifySuccess,
  notifyError,
  enableLoading,
  disableLoading,
}) => {
  const [showConfirmationModal, { onCreatePlan }] = useSubmitUtils({
    disableLoading,
    enableLoading,
    notifyError,
    notifySuccess,
    farmId,
    callbackSubmit: onBack,
  });

  return (
    <GoalPlanCopyPage
      goalPlanId={goalPlanId}
      onBack={onBack}
      onSubmit={onCreatePlan}
      showConfirmationModal={showConfirmationModal}
      enableLoading={enableLoading}
      disableLoading={disableLoading}
      notifyError={notifyError}
    />
  );
};

const mapDispatchToProps = {
  enableLoading: enableLoading,
  disableLoading: disableLoading,
  notifyError: notifyError,
  notifySuccess: notifySuccess,
};

const mapStateToProps = ({ fazenda }) => {
  return {
    farmId: fazenda.FazendaSelecionada,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoalPlanCopyContainer);
