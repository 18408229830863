import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { withStyles } from "@material-ui/core";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "recompose";
import * as actions from "redux/actions";
import UsuarioService from "services/UsuarioService";
import { palette } from "styles/theme";
import { getCentralDeAjudaStorage } from "shared/storageUtils";

const MyButton = styled(Button)(() => ({
  backgroundColor: palette.primary.main,
  lineHeight: "0",
  fontSize: "0.875rem",
  padding: "8px 20px",
  transition: "filter 0.3s",
  borderRadius: "8px",
  minHeight: "36px",
  textTransform: "capitalize",
  color: "white",
  "&:hover": {
    backgroundColor: palette.primary.main,
    filter: "brightness(0.8)",
  },
  "&:active": {
    backgroundColor: palette.primary.main,
    filter: "brightness(0.8)",
  },
  "@media (max-width: 1200px)": {
    "& .responsive-text": {
      display: "none",
    },
    "& span": {
      padding: "0",
      margin: "0",
    },
  },
}));

const subdomain = `prodapsuporte`;
const return_to = encodeURIComponent(
  `https://farmtellviews.zendesk.com/hc/pt-br`
);
const actionUrl = `https://${subdomain}.zendesk.com/access/jwt?&return_to=${return_to}`;

export const BotaoCentralAjuda = ({ clienteSelecionado }) => {
  const formRef = useRef(null);
  const inputRef = useRef(null);

  const [centralAjuda, setCentralAjuda] = useState({
    acessaCentralAjuda: false,
    liberadoSuporte: false,
  });

  useEffect(() => {
    if (clienteSelecionado) {
      setCentralAjuda({
        acessaCentralAjuda: clienteSelecionado.AcessaCentralAjuda,
        liberadoSuporte: clienteSelecionado.LiberadoSuporte,
      });
    }

    if (!clienteSelecionado && obterInfoCentralAjuda()) {
      const centralAjuda = JSON.parse(obterInfoCentralAjuda());
      setCentralAjuda(centralAjuda);
    }
  }, [clienteSelecionado]);

  const obterTokenCentralAjuda = async () => {
    try {
      const response = await UsuarioService.tokenCentralAjuda(
        localStorage.getItem("IdUsuario"),
        centralAjuda.liberadoSuporte
      );
      const jwt = response.data.token;
      // add the JWT to a hidden form on your login page
      inputRef.current.value = jwt;
      formRef.current.submit();
    } catch (error) {
      return;
    }
  };

  const obterInfoCentralAjuda = () => {
    return getCentralDeAjudaStorage();
  };

  if (centralAjuda && centralAjuda.acessaCentralAjuda) {
    return (
      <>
        <MyButton
          component="button"
          variant="contained"
          startIcon={<SupportAgentIcon fontSize="16px" />}
          onClick={obterTokenCentralAjuda}
        >
          <span className="responsive-text">Central de ajuda</span>
        </MyButton>
        <form ref={formRef} action={actionUrl} method="post" target="_blank">
          <input ref={inputRef} type="hidden" name="jwt"></input>
        </form>
      </>
    );
  }

  return null;
};

const styles = (theme) => {
  return {};
};

function mapStateToProps({ cliente }) {
  return {
    clienteSelecionado: cliente.ClienteSelecionado,
  };
}

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, actions)
)(BotaoCentralAjuda);
