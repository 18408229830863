import React, { useEffect, useState } from "react";
import IngredientRegisterPage from "./ingredientRegisterPage";
import _ from "lodash";

const IngredientRegisterContainer = ({
  setFormulacaoValida,
  setIngredientesCadastrados,
  ingredientes,
  ingredientesCadastrados,
}) => {
  const [quantidade, setQuantidade] = useState("");
  const [ingredienteSelecionado, setIngredienteSelecionado] = useState(0);
  const [botaoCadastroDesativado, setBotaoCadastroDesativado] = useState(true);
  const [listaIngredientes, setListaIngredientes] = useState([
    { value: 0, label: "" },
  ]);

  // inicializa o selectIngrediente
  useEffect(() => {
    const arrayOrdenado = _.orderBy(
      [
        ...listaIngredientes,
        ...ingredientes.map((ing) => ({
          value: ing.IdIngrediente,
          label: ing.Descricao,
        })),
      ],
      "label"
    );

    setListaIngredientes([...arrayOrdenado]);
  }, [ingredientes]);

  // adiciona ou remove itens já adicionado ou removidos dos ingredientes
  useEffect(() => {
    const listaIngredientesRestantes = ingredientes.filter((ing) => {
      return !ingredientesCadastrados.some(
        (ingCad) => ingCad.IdIngrediente === ing.IdIngrediente
      );
    });

    const arrayOrdenado = _.orderBy(
      [
        { value: 0, label: "" },
        ...listaIngredientesRestantes.map((ing) => ({
          value: ing.IdIngrediente,
          label: ing.Descricao,
        })),
      ],
      "label"
    );

    // seta a lista novamente
    setListaIngredientes([...arrayOrdenado]);
  }, [ingredientesCadastrados, ingredientes]);

  // responsavel por alterar o estado dos botões
  useEffect(() => {
    if (ingredienteSelecionado !== 0 || quantidade !== "") {
      setFormulacaoValida(true);
      if (ingredienteSelecionado && quantidade !== "")
        setBotaoCadastroDesativado(false);
      else setBotaoCadastroDesativado(true);
    } else {
      setBotaoCadastroDesativado(true);
      setFormulacaoValida(true);
    }
  }, [ingredienteSelecionado, quantidade]);

  // adiciona ingrediente na lista
  const adicionarIngrediente = () => {
    // filtra o ingrediente pelo IdIngrediente
    const nomeIngrediente = ingredientes.find(
      (ing) => ing.IdIngrediente === ingredienteSelecionado
    ).Descricao;

    // insere o novo ingrediente na lista
    const novoIngrediente = {
      OperacaoIngrediente: {
        Descricao: nomeIngrediente,
      },
      FormulaIngrediente: {
        Quantidade: quantidade,
      },
      IdIngrediente: ingredienteSelecionado,
      _create: true,
    };
    setIngredientesCadastrados([...ingredientesCadastrados, novoIngrediente]);

    // limpa os campos após adicionar o ingrediente
    setIngredienteSelecionado(0);
    setQuantidade("");
  };

  return (
    <IngredientRegisterPage
      listaIngredientes={listaIngredientes}
      ingredienteSelecionado={ingredienteSelecionado}
      setIngredienteSelecionado={setIngredienteSelecionado}
      quantidade={quantidade}
      botaoCadastroDesativado={botaoCadastroDesativado}
      adicionarIngrediente={adicionarIngrediente}
      setQuantidade={setQuantidade}
    />
  );
};

export default IngredientRegisterContainer;
