import React from "react";
import { withStyles } from "@material-ui/core";

const AlertBanner = ({
  className,
  classes,
  children,
  type,
  icon,
  iconAlign,
}) => {
  const containerClass = classes[type];
  return (
    <div
      data-testid="Form-Alert"
      className={[className, classes.container, containerClass].join(" ")}
    >
      <span style={{ alignSelf: iconAlign }}>{icon}</span>
      {children}
    </div>
  );
};
const styles = () => ({
  container: {
    display: "flex",
    alignSelf: "stretch",
    flexGrow: 0,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "12px",
    borderRadius: "4px",
    padding: "14px 16px",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.43,
    letterSpacing: "0.17px",
    textAlign: "left",
    color: "rgba(0, 0, 0, 0.6)",
  },
  warnning: {
    backgroundColor: "#fef1e6",
    color: "#5f2b01",
  },
  error: {
    backgroundColor: "#fbebeb",
    color: "#541313",
  },
  success: {
    backgroundColor: "dff1df",
  },
});

export default withStyles(styles)(AlertBanner);
