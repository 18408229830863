import { Typography, withStyles } from "@material-ui/core";
import React from "react";
import Button from "../../shared/button";
import Title from "../../shared/title";

const GoalPlanRevisionHeader = ({
  classes,
  goalPlanRevisionList,
  handleShowOriginalPlan,
}) => {
  const revisionName =
    goalPlanRevisionList && goalPlanRevisionList.length > 0
      ? goalPlanRevisionList[0].name
      : "";

  return (
    <div className="goal-plan-revision-header">
      <div className={classes.header}>
        <Typography className={classes.title}>
          Você está visualizando o plano: <b>{revisionName}</b>
        </Typography>
        <Button
          data-testid="button"
          label="Ver plano original"
          onClick={() => handleShowOriginalPlan(goalPlanRevisionList.id)}
        />
      </div>
      <Title>Revisões:</Title>
    </div>
  );
};

const styles = {
  header: {
    marginTop: "40px",
    marginBottom: "40px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: "20px",
    color: "#333333",
  },
};

export default withStyles(styles)(GoalPlanRevisionHeader);
