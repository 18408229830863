export default {
  google_maps: {
    libraries: ["geometry", "drawing", "places", "marker"],
    drawing_mode: {
      polygon: "polygon",
    },
    mapPaneName: {
      overlayMouseTarget: "overlayMouseTarget",
    }
  },
  collect: {
    collect_types_colors: {
      insidePasture: "green",
      outsidePasture: "red",
      noGps: "grey"
    },
    collect_filter: {
      all: "todos",
      insidePasture: "dentro_da_area",
      outsidePasture: "fora_da_area",
      noGps: "sem_geolocalizacao"
    }
  }
}
