import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import TableauPage from '../../tableauEmbedded/views/tableauPage';
import * as relatoriosOperations from '../redux/relatoriosOperations';

export class TableauContainer extends PureComponent {
  render() {
    return (
      <TableauPage
        obterRelatorios={this.props.obterRelatorios}
        obterPastasUsuario={this.props.obterPastasUsuario}
        pastas={this.props.pastas}
        relatorios={this.props.relatorios} />
    );
  }
};

const mapDispatchToProps = {
  obterPastasUsuario: relatoriosOperations.obterPastasUsuario,
  obterRelatorios: relatoriosOperations.obterRelatorios
};

export const mapStateToProps = ({ relatoriosTableauEmbedded }) => {
  return {
    pastas: relatoriosTableauEmbedded.Pastas,
    relatorios: relatoriosTableauEmbedded.Relatorios
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableauContainer);
