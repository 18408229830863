import { idsCabecalhoCategoriaAnimal } from "../../constants/idsCabecalhoCategoriaAnimal"

export const headCells = [
  {
    id: idsCabecalhoCategoriaAnimal.Nome,
    numeric: false,
    disablePadding: true,
    label: "CATEGORIAS",
  },
  {
    id: idsCabecalhoCategoriaAnimal.QuantidadeInicialParaConciliacao,
    numeric: false,
    disablePadding: true,
    label: "QUANTIDADE INICIAL P/ CONCILIAÇÃO",
  },
  {
    id: idsCabecalhoCategoriaAnimal.TamanhoDesejado,
    numeric: false,
    disablePadding: true,
    label: "TAMANHO DE LOTE DESEJADO (CAB.)",
  },
  {
    id: idsCabecalhoCategoriaAnimal.Sexo,
    numeric: true,
    disablePadding: true,
    label: "SEXO",
  },
  {
    id: idsCabecalhoCategoriaAnimal.FaixaIdade,
    numeric: false,
    disablePadding: true,
    label: "FAIXA IDADE",
  },
  {
    id: idsCabecalhoCategoriaAnimal.CurvaGanhoPeso,
    numeric: false,
    disablePadding: true,
    label: "CURVA GANHO DE PESO",
  },
];


