import { Button, withStyles } from "@material-ui/core";
import BotaoUpload from "features/shared/components/BotaoUpload";
import React from "react";

const ButtonWithLabelComponent = ({
  labelText,
  buttonText,
  onClick,
  classes,
}) => {
  return (
    <div className={classes.containerBotao}>
      <span className={classes.buttonLabel}>{labelText}</span>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        type="button"
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </div>
  );
};

const UploadButtonWithLabelComponent = ({
  labelText,
  buttonText,
  onInputFileOpen,
  allowedExtensions,
  uploadType,
  onDrop,
  disabled,
  cunstomClasses,
  buttomCustomClasses,
  classes,
}) => {
  return (
    <div className={classes.containerBotao}>
      <span className={classes.buttonLabel}>{labelText}</span>
      <BotaoUpload
        onInputFileOpen={onInputFileOpen}
        extensaoArquivoAceito={allowedExtensions}
        tipoUpload={uploadType}
        onDrop={onDrop}
        disabled={disabled}
        tituloBotao={buttonText}
        cunstomClasses={cunstomClasses || classes.containerBotao}
        buttomCustomClasses={buttomCustomClasses || classes.button}
      />
    </div>
  );
};

const styles = () => ({
  buttonLabel: {
    fontFamily: "Roboto",
    color: "rgba(33, 33, 33, 0.7)",
    marginTop: 16,
    marginBottom: 8,
  },
  button: {
    textTransform: "none",
    fontSize: "16px",
    borderRadius: 4,
    width: "100%",
  },
  containerBotao: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    margin: 0,
  },
});

export const ButtonWithLabel = withStyles(styles)(ButtonWithLabelComponent);
export const UploadButtonWithLabel = withStyles(styles)(
  UploadButtonWithLabelComponent
);
