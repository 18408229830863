import React from "react";
import { withStyles } from "@material-ui/core";
import SupplementationFilterContainer from "../shared/supplementationFilter/supplementationFilterContainer";
import SupplementationNotFound from "../shared/supplementationNotFound";
import SupplementationListHeader from "./components/supplementationListHeader";
import SupplementationListTable from "./components/supplementationListTable";

const SupplementationListPage = ({
  classes,
  filter,
  getSupplementations,
  deleteSupplementation,
  isDeletedSupplementationsPage,
  goToSupplementationEdit = () => {},
  goToViewSupplementation = () => {},
  goToDeletedSupplementationsPage = () => {},
  goBack = () => {},
}) => {
  const isFilterEmpty =
    filter &&
    !filter.formula.selected.length &&
    !filter.pasture.selected.length &&
    !filter.user.selected.length &&
    !filter.beginDate.value &&
    !filter.endDate.value;

  const notExistSupplementationsText = (
    <span>
      Você ainda não possui nenhuma coleta de cocho, por favor utilize o app
      <span className={classes.textAccent}> “FarmTell Views”</span>, e faça o
      lançamento de uma coleta.
    </span>
  );

  const notExistSupplementationsDeletedText = (
    <span>Você ainda não possui nenhuma coleta de cocho excluída.</span>
  );

  const notFoundSupplementationsText = (
    <span>
      Não encontramos nenhum resultado para a busca informada. Por favor tente
      novamente.
    </span>
  );

  const renderEmptyMessage = () => {
    if (isFilterEmpty) {
      return isDeletedSupplementationsPage
        ? notExistSupplementationsDeletedText
        : notExistSupplementationsText;
    } else {
      return notFoundSupplementationsText;
    }
  };

  return (
    <div className={classes.supplementationListPage} id="SupplementationList">
      <div>
        <SupplementationListHeader
          isDeletedSupplementationsPage={isDeletedSupplementationsPage}
          onGoBack={goBack}
        />
        <SupplementationFilterContainer
          filter={filter}
          isDeletedSupplementationsPage={isDeletedSupplementationsPage}
          goToDeletedSupplementationsPage={goToDeletedSupplementationsPage}
        />
        <div className={classes.pageContentWrapper}>
          <SupplementationListTable
            getSupplementations={getSupplementations}
            deleteSupplementation={deleteSupplementation}
            removeActionsColumn={isDeletedSupplementationsPage}
            goToSupplementationEdit={goToSupplementationEdit}
            goToViewSupplementation={goToViewSupplementation}
            NotFoundComponent={() => (
              <SupplementationNotFound notFoundText={renderEmptyMessage()} />
            )}
          />
        </div>
      </div>
    </div>
  );
};

const styles = (theme) => {
  return {
    supplementationListPage: {
      width: "100%",
      minHeight: "80vh",
      overflowX: "auto",
      padding: "5px 40px",
      zIndex: -1,
      bottom: 0,
    },
    pageContentWrapper: {
      width: "100%",
      marginTop: "40px",
    },
    textAccent: {
      color: theme.palette.primary.main,
    },
  };
};

export default withStyles(styles)(SupplementationListPage);
