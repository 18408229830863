import React from "react";
import { TextLabel, FeedBackPage } from "../../../../shared/components";
import { Grid } from "@material-ui/core";

const GoalPlanListFilterNotFound = () => (
  <Grid
    container
    direction="column"
    justify="center"
    style={{
      minHeight: "60vh",
    }}
  >
    <FeedBackPage>
      <Grid container direction="column" alignItems="center">
        <img src="../images/busca.png" alt="Lupa de tela vazia" />
        <TextLabel
          id="labelFechamentoFeedBack"
          block
          color="default2"
          customStyle={{ paddingTop: 10, textAlign: "center" }}
        >
          Não encontramos nenhum resultado para a sua busca.
          <br />
          Por favor, tente novamente.
        </TextLabel>
      </Grid>
    </FeedBackPage>
  </Grid>
);

export default GoalPlanListFilterNotFound;
