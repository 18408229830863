import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Grid, withStyles } from "@material-ui/core";
import { DialogoConfirmacaoAcao } from "features/shared/components/DialogoConfirmacaoAcao";
import * as categoriaUtils from "../categoriaUtils";
import CategoriaTabela from "./categoriaTabela";
import CategoriaForm from "./categoriaForm";
import CategoriaFiltro from "./categoriaFiltro";
import CategoriaCabecalho from "./categoriaCabecalho";
import * as _ from "lodash";

export class CategoriaPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataAtual: Date.now(),
      confirmacaoExclusaoAberto: false,
      modalAberto: false,
      adicionando: false,
      textoFiltrar: "",
      categoriaStatus: 1,
      curvaStatus: 0,
      categoria: {
        IdCategoriaAnimal: null,
        Nome: "",
        Sexo: "M",
        TamanhoDesejado: "0",
        IdFaixaIdade: 0,
        QuantidadeInicialParaConciliacao: 0,
        IdCurvaGanhoPeso: null,
      },
      FaixasIdade: [],
      CurvaGanhoPeso: [],
      categoriaClicada: {
        IdCategoriaAnimal: null,
        Nome: "",
      },
      erroNome: false,
      erroQuantidadeInicial: false,
      dataInicioConciliacao: "",
    };
  }

  async componentDidMount() {
    await this.props.obterCategoriasFazenda();
    await this.props.obterCurvasGanhoPeso();
    this.listarFaixas();
  }

  abreDialogExclusao = (categoria) => {
    this.setState({
      confirmacaoExclusaoAberto: true,
      categoriaClicada: categoria,
    });
  };

  handleClose = async () => {
    this.setState({ modalAberto: false });
  };

  handleOpen = async () => {
    this.setState({ modalAberto: true });
  };

  fechaDialogExclusao = async () => {
    this.setState({ confirmacaoExclusaoAberto: false });
    await this.props.obterCategoriasFazenda();
  };

  handleChangeNomeCategoria = (event) => {
    const { categorias } = this.props;
    this.setState({
      categoria: {
        ...this.state.categoria,
        Nome: event.target.value.toUpperCase(),
      },
    });
    this.setState({
      erroNome: categorias.some(
        (categoria) =>
          categoria.Nome.trim() === event.target.value.toUpperCase().trim()
      ),
    });
  };

  handleChangeQuantidadeInicialCategoria = (event) => {
    const valorConvertidoEmNumero = Number(event.target.value);
    if (
      !isNaN(valorConvertidoEmNumero) &&
      Number.isInteger(valorConvertidoEmNumero)
    ) {
      this.setState({
        categoria: {
          ...this.state.categoria,
          QuantidadeInicialParaConciliacao: event.target.value,
        },
      });
    }
  };

  handleChangeSexoCategoria = (value) => {
    this.setState({
      categoria: {
        ...this.state.categoria,
        Sexo: value,
        IdFaixaIdade: undefined,
      },
      FaixasIdade: categoriaUtils.filtrarSexo(value, this.props.FaixasIdade),
    });
  };

  handleChangeTamanhoCategoria = (event) => {
    const valorConvertidoEmNumero = Number(event.target.value);
    if (
      !isNaN(valorConvertidoEmNumero) &&
      Number.isInteger(valorConvertidoEmNumero)
    ) {
      this.setState({
        categoria: {
          ...this.state.categoria,
          TamanhoDesejado: event.target.value,
        },
      });
    }
  };

  handleChangeFaixaIdade = (value) => {
    this.setState({
      categoria: { ...this.state.categoria, IdFaixaIdade: value },
    });
  };

  handleChangeCurvaGanhoPeso = (value) => {
    this.setState({
      categoria: {
        ...this.state.categoria,
        IdCurvaGanhoPeso: value,
      },
    });
  };

  handleChangeFiltroCategoria = (event) => {
    this.setState({ textoFiltrar: event.target.value });
  };

  handleChangeFiltroPorEstado = (value) => {
    this.setState({ categoriaStatus: value });
  };

  handleChangeFiltroPorCurva = (value) => {
    this.setState({ curvaStatus: value });
  };

  habilitarCategoria = (acao) => {
    this.setState({
      adicionando: acao,
      categoria: {
        Nome: "",
        Sexo: "M",
        TamanhoDesejado: null,
      },
      erroNome: false,
      FaixasIdade: categoriaUtils.filtrarSexo("M", this.props.FaixasIdade),
      CurvasGanhoPeso: this.props.CurvasGanhoPeso,
    });
  };

  listarFaixas = async () => {
    await this.props.obterFaixas();
  };

  adicionarCategoria = async (categoria) => {
    if (categoria.IdCategoriaAnimal) {
      // ocorre quando o usuário quer remover a quantidade inical
      if (categoria.QuantidadeInicialParaConciliacao === "") {
        categoria = { ...categoria, QuantidadeInicialParaConciliacao: null };
      }
      await this.props.editarCategoria(categoria);
    } else {
      await this.props.criarCategoria(categoria);
    }
    await this.props.obterCurvasGanhoPeso();
    await this.props.obterCategoriasFazenda();
    this.setState({ categoria: { Nome: "", Sexo: "" }, adicionando: false });
  };

  ativaDesativaCategoriaAnimal = async (IdCategoriaAnimal, excluido) => {
    if (excluido) {
      await this.props.desexcluirCategoria(IdCategoriaAnimal);
    } else {
      await this.props.excluirCategoria(IdCategoriaAnimal);
    }
    await this.props.obterCategoriasFazenda();
    await this.props.obterCurvasGanhoPeso();
    this.listarFaixas();
  };

  habilitarEdicao = (categoria) => {
    this.setState({
      adicionando: true,
      categoria: categoria,
      FaixasIdade: _.filter(this.props.FaixasIdade, (f) => {
        return f.Sexo === categoria.Sexo;
      }),
      CurvasGanhoPeso: this.props.CurvasGanhoPeso,
    });
  };

  excluirCategoria = async (idCategoria) => {
    await this.props.excluirCategoria(idCategoria);
  };

  render() {
    const {
      classes,
      categorias,
      dataInicioConciliacao,
      timezoneFazenda,
      exibeQuantidadeConciliacao,
      exibeTamanhoLote,
      exibeCurvaGanhoPeso,
    } = this.props;

    const {
      adicionando,
      categoria,
      confirmacaoExclusaoAberto,
      erroNome,
      erroQuantidadeInicial,
      categoriaStatus,
      curvaStatus,
    } = this.state;

    const categoriasFiltradas = categoriaUtils.filterCategoriaMemoize(
      categorias,
      this.state.textoFiltrar,
      this.state.categoriaStatus,
      this.state.curvaStatus
    );

    return (
      <React.Fragment>
        <Paper square className={classes.root}>
          <Grid item sm={12} xs={12} className={classes.containerPage}>
            <CategoriaCabecalho
              dataInicioConciliacao={dataInicioConciliacao}
              loading={this.props.loading}
              salvaDataConciliacao={this.props.salvaDataConciliacao}
              idFazenda={this.props.idFazenda}
              timezoneFazenda={timezoneFazenda}
            ></CategoriaCabecalho>
            <Typography className={classes.titulo} gutterBottom>
              Informações sobre categorias da fazenda
            </Typography>
            <CategoriaFiltro
              handleChangeFiltroCategoria={this.handleChangeFiltroCategoria}
              desativado={adicionando}
              categoriaStatus={categoriaStatus}
              curvaStatus={curvaStatus}
              handleChangeFiltroPorEstado={this.handleChangeFiltroPorEstado}
              handleChangeFiltroPorCurva={this.handleChangeFiltroPorCurva}
            />
            {adicionando ? (
              <Paper square className={classes.corpoAdicionar}>
                <CategoriaForm
                  id="formAdicionar"
                  classes={classes}
                  categoria={categoria}
                  erroNome={erroNome}
                  erroQuantidadeInicial={erroQuantidadeInicial}
                  handleChangeNomeCategoria={this.handleChangeNomeCategoria}
                  handleChangeQuantidadeInicialCategoria={
                    this.handleChangeQuantidadeInicialCategoria
                  }
                  handleChangeSexoCategoria={this.handleChangeSexoCategoria}
                  handleChangeTamanhoCategoria={
                    this.handleChangeTamanhoCategoria
                  }
                  handleChangeFaixaIdade={this.handleChangeFaixaIdade}
                  handleChangeCurvaGanhoPeso={this.handleChangeCurvaGanhoPeso}
                  adicionarCategoria={this.adicionarCategoria}
                  habilitarCategoria={this.habilitarCategoria}
                  faixasIdade={this.state.FaixasIdade}
                  CurvasGanhoPeso={this.state.CurvasGanhoPeso}
                  exibirGanhoPeso={this.props.exibirGanhoPeso}
                  exibeTamanhoLote={this.props.exibeTamanhoLote}
                  exibeQuantidadeConciliacao={
                    this.props.exibeQuantidadeConciliacao
                  }
                />
              </Paper>
            ) : (
              <Button
                id="botaoAdicionar"
                onClick={() => this.habilitarCategoria(true)}
                variant="contained"
                color="primary"
                className={classes.botaoNovo}
              >
                Nova Categoria
              </Button>
            )}
            <Paper square className={classes.corpoTabela}>
              <CategoriaTabela
                id="tabelaCategoria"
                categoriasFiltradas={categoriasFiltradas}
                exibeTamanhoLote={exibeTamanhoLote}
                exibeCurvaGanhoPeso={exibeCurvaGanhoPeso}
                exibeQuantidadeConciliacao={exibeQuantidadeConciliacao}
                habilitarEdicao={this.habilitarEdicao}
                adicionando={adicionando}
                ativaDesativaCategoriaAnimal={this.ativaDesativaCategoriaAnimal}
                abreDialogExclusao={this.abreDialogExclusao}
              />
            </Paper>
          </Grid>
        </Paper>
        <DialogoConfirmacaoAcao
          id={`dialogConfirmaExclusaoCategoria`}
          tituloDialog={"Confirmar exclusão de Categoria"}
          textoDialog={
            <span id="mensagemConfirmaExclusaoFormula">
              Você deseja excluir a categoria?
            </span>
          }
          dialogAberto={confirmacaoExclusaoAberto}
          cancelarAcaoDialog={this.fechaDialogExclusao}
          objetoSelecionado={this.state.categoriaClicada.IdCategoriaAnimal}
          excluir={this.props.excluirCategoria}
        />
      </React.Fragment>
    );
  }
}

const styles = (theme) => {
  return {
    containerPage: {
      flexgrow: 1,
      height: "100%",
      backgroundColor: "#fff",
      width: "100%",
    },
    root: {
      width: "100%",
      height: "100%",
      overflowX: "auto",
      padding: "46px",
      zIndex: -1,
      bottom: 0,
      top: "17%",
      minHeight: "calc(100vh - 90px)",
    },
    corpoTabela: {
      width: "100%",
      marginTop: "20px",
      maxHeight: 600,
      overflow: "hidden",
      borderRadius: "16px",
    },
    titulo: {
      fontSize: "20px",
      fontWeight: "bold",
      fontStyle: "medium",
      fontStretch: "medium",
      lineHeight: "medium",
      letterSpacing: "medium",
      color: "#333333",
      marginTop: "40px",
    },
    corpoAdicionar: {
      width: "100%",
      marginTop: "10px",
      padding: "20px",
      borderRadius: "16px",
    },
    botaoNovo: {
      borderRadius: "18px",
      float: "right",
      marginRight: 0,
      marginTop: "34px",
      marginBottom: "15px",
    },
    textTamanhoDesejado: {
      width: "180px",
    },
  };
};

export default withStyles(styles)(CategoriaPage);
