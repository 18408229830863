import React, { Component } from "react";
import _ from "lodash";

import { cores } from "../../../../../../../constants/coresModulos";
import ClearIcon from "@material-ui/icons/Clear";
import RadioCor from "../../RadioCor";
import { DialogExcluir } from "../DialogExcluir";
import { MensagemSemPasto } from "../MensagemSemPasto";
import {
  Grid,
  TextField,
  Button,
  FormLabel,
  ListItem,
} from "@material-ui/core";
import { SingleClickButton } from "features/shared/components/SingleClickButton";

export class CadastroModulo extends Component {
  render() {
    const {
      classes,
      pastos,
      pastosSelecionados,
      telaEdicao,
      nomeModulo,
      onChangeNome,
      nomeReduzido,
      onChangeNomeReduzido,
      corProps,
      onChangeCor,
      handleAbreModalExclusao,
      modalConfirmacaoExclusao,
      handleFechaModalExclusao,
      handleDelete,
      handleUpdate,
      desabilitaBotaoAtualizar,
      handleLimparSelecao,
      handleSubmit,
    } = this.props;

    if (pastos.length === 0 || pastosSelecionados.length === 0) {
      const mensagemUsuario = {
        semPastos: "Para começar cadastre o KML da fazenda selecionada.",
        nadaSelecionado:
          "Para começar, selecione mais de um pasto ou módulo no mapa ao lado.",
      };
      return (
        <MensagemSemPasto
          pastos={pastos}
          pastosSelecionados={pastosSelecionados}
          mensagemUsuario={mensagemUsuario}
          imagemFundo={"../images/map_indicator.svg"}
        />
      );
    }

    return (
      <div id="menuModulo" className={classes.abaContainer}>
        <Grid container direction="row" spacing={12}>
          <Grid item xs={12}>
            <TextField
              id="name"
              label="Nome"
              className={classes.textField}
              value={nomeModulo}
              onChange={onChangeNome}
              margin="normal"
              placeholder="adicione"
              inputProps={{
                maxLength: 50,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="shortName"
              label="Nome Reduzido"
              className={classes.textField}
              value={nomeReduzido}
              onChange={onChangeNomeReduzido}
              margin="normal"
              placeholder="adicione"
              inputProps={{
                maxLength: 15,
              }}
            />
          </Grid>
        </Grid>
        <ListItem>
          <FormLabel
            className={classes.formlabel}
            component="legend"
            align="left"
          >
            Definir cor
          </FormLabel>
        </ListItem>
        <ListItem>
          <Grid container justify="space-between">
            {_.map(cores, (cor, index) => (
              <RadioCor
                key={index}
                corSelecionada={corProps}
                corRadio={cor}
                style={classes[`iconCor${index + 1}`]}
                handleChange={onChangeCor}
              />
            ))}
          </Grid>
        </ListItem>
        <ListItem>
          {telaEdicao ? (
            <Grid
              id="conteudoMenuEdicao"
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-start"
            >
              <Button
                id="botaoExcluirMenuEdicao"
                className={classes.buttonDelete}
                onClick={handleAbreModalExclusao}
                color="default"
                variant="contained"
                aling="rigth"
              >
                <ClearIcon className={classes.leftIcon} />
                EXCLUIR
              </Button>
              <DialogExcluir
                texto={"Excluir o Módulo selecionado?"}
                modalConfirmacaoExclusao={modalConfirmacaoExclusao}
                handleFechaModalExclusao={handleFechaModalExclusao}
                handleDelete={handleDelete}
              />
              <Button
                id="botaoAtualizarMenuEdicao"
                style={{ marginTop: "30px" }}
                onClick={handleUpdate}
                variant="contained"
                color="primary"
                aling="rigth"
                disabled={desabilitaBotaoAtualizar || !nomeModulo}
              >
                ATUALIZAR
              </Button>
            </Grid>
          ) : (
            <Grid
              id="conteudoMenuCriacao"
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-start"
            >
              <Button
                id="botaoLimparMenuCriacao"
                className={classes.buttonDelete}
                onClick={handleLimparSelecao}
                color="default"
                variant="contained"
                aling="rigth"
              >
                <ClearIcon className={classes.leftIcon} />
                LIMPAR
              </Button>
              <SingleClickButton
                id="botaoSalvarMenuCriacao"
                style={{ marginTop: "30px" }}
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                type="submit"
                aling="rigth"
                disabled={
                  this.props.pastosSelecionados.length < 2 ||
                  !this.props.nomeModulo ||
                  this.props.nomeModulo === ""
                }
              >
                SALVAR
              </SingleClickButton>
            </Grid>
          )}
        </ListItem>
      </div>
    );
  }
}

export default CadastroModulo;
