import React from "react";
import MovementHistoryFilter from "./components/movementHistoryFilter";

const MovementHistoryFilterPage = ({
  handlerUsuariosSelecionados,
  handlerFiltroCategoriaInicial,
  handlerFiltroCategoriaFinal,
  handlerTipoOperacao,
  handlerPastoOrigemSelecionado,
  handlerPastoDestinoSelecionado,
  handlerCollapse,
  handlerDataInicial,
  handlerDataFinal,
  maxDataInicio,
  minDataInicio,
  dataInicio,
  dataFinal,
  maxDataFinal,
  listaUsuarios,
  usuariosSelecionados,
  listaTipoOperacoes,
  tiposOperacoesSelecionadas,
  collapseFilter,
  listaCategoriaAnimal,
  categoriasFinaisSelecionadas,
  categoriasIniciaisSelecionadas,
  listaPastoFazenda,
  pastoOrigemSelecionado,
  pastoDestinoSelecionado,
  executarFiltro,
  executarFiltroParaPrint,
  retirosSelecionados,
  listaRetiros,
  handleRetirosSelecionados,
}) => {
  return (
    <MovementHistoryFilter
      handlerUsuariosSelecionados={handlerUsuariosSelecionados}
      handlerFiltroCategoriaInicial={handlerFiltroCategoriaInicial}
      handlerFiltroCategoriaFinal={handlerFiltroCategoriaFinal}
      handlerTipoOperacao={handlerTipoOperacao}
      handlerPastoOrigemSelecionado={handlerPastoOrigemSelecionado}
      handlerPastoDestinoSelecionado={handlerPastoDestinoSelecionado}
      handlerCollapse={handlerCollapse}
      handlerDataInicial={handlerDataInicial}
      handlerDataFinal={handlerDataFinal}
      maxDataInicio={maxDataInicio}
      minDataInicio={minDataInicio}
      dataInicio={dataInicio}
      dataFinal={dataFinal}
      maxDataFinal={maxDataFinal}
      listaUsuarios={listaUsuarios}
      usuariosSelecionados={usuariosSelecionados}
      listaTipoOperacoes={listaTipoOperacoes}
      tiposOperacoesSelecionadas={tiposOperacoesSelecionadas}
      collapseFilter={collapseFilter}
      listaCategoriaAnimal={listaCategoriaAnimal}
      categoriasFinaisSelecionadas={categoriasFinaisSelecionadas}
      categoriasIniciaisSelecionadas={categoriasIniciaisSelecionadas}
      listaPastoFazenda={listaPastoFazenda}
      pastoOrigemSelecionado={pastoOrigemSelecionado}
      pastoDestinoSelecionado={pastoDestinoSelecionado}
      executarFiltro={executarFiltro}
      executarFiltroParaPrint={executarFiltroParaPrint}
      retirosSelecionados={retirosSelecionados}
      listaRetiros={listaRetiros}
      handleRetirosSelecionados={handleRetirosSelecionados}
    />
  );
};

export default MovementHistoryFilterPage;
