import React from "react";
import { withStyles } from "@material-ui/core";
import BackIcon from "@material-ui/icons/ArrowBack";

const SupplementationListHeader = ({
  classes,
  isDeletedSupplementationsPage,
  onGoBack,
}) => {
  return (
    <div className={classes.supplementationListHeader}>
      {isDeletedSupplementationsPage ? (
        <div className={classes.pageTitleContainer}>
          <BackIcon className={classes.iconBack} onClick={onGoBack} />
          <h1 className={classes.title}>Coletas excluídas</h1>
        </div>
      ) : (
        <div className={classes.pageTitleContainer}>
          <h1 className={classes.title}>
            Listagem de coletas de suplementação
          </h1>
        </div>
      )}
    </div>
  );
};

const syles = (theme) => {
  return {
    supplementationListHeader: {
      width: "100%",
      height: "100%",
      overflowX: "auto",
      zIndex: -1,
      bottom: 0,
      background: "#fff",
      marginTop: "40px",
    },
    pageTitleContainer: {
      display: "flex",
      alignItems: "center",
      marginTop: "40px",
    },
    title: {
      fontFamily: "Roboto",
      fontSize: "20px",
      fontWeight: "bold",
      color: "#444",
    },
    iconBack: {
      color: theme.palette.primary.main,
      cursor: "pointer",
      marginRight: "12px",
    },
  };
};

export default withStyles(syles)(SupplementationListHeader);
