import * as cadastroIngredientesActions from "./cadastroIngredientesActions";

export const INITIAL_STATE = {
  ingredientes: [],
  ingredientesPadroes: [],
  historicoIngredientes: { items: [] },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case cadastroIngredientesActions.LISTAR_INGREDIENTES:
      return {
        ...state,
        ingredientes: action.payload.Ingredientes,
      };
    case cadastroIngredientesActions.LISTAR_INGREDIENTE_PADROES:
      return {
        ...state,
        ingredientesPadroes: action.payload.IngredientesPadroes,
      };
    case cadastroIngredientesActions.LISTAR_HISTORICO_INGREDIENTE_PADROES:
      return {
        ...state,
        historicoIngredientes: action.payload.historicoIngredientes,
      };
    default:
      return state;
  }
};
