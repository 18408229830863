import React from "react";
import MensagemMenuLateral from "features/manejo/views/components/MensagemMenuLateral";
import { Component } from "react";

export class MensagemSemPasto extends Component {
  render() {
    const { pastos, pastosSelecionados, mensagemUsuario, imagemFundo } =
      this.props;

    if (!pastos || pastos.length === 0) {
      return (
        <div>
          <MensagemMenuLateral
            id="menuLateralSemPastos"
            mensagem={mensagemUsuario.semPastos}
          >
            <img src={imagemFundo} alt="icone de um mapa" />
          </MensagemMenuLateral>
        </div>
      );
    } else if (!pastosSelecionados || pastosSelecionados.length === 0) {
      return (
        <div>
          <MensagemMenuLateral
            id="menuLateralNadaSelecionado"
            mensagem={mensagemUsuario.nadaSelecionado}
          >
            <img src={imagemFundo} alt="icone de um mapa" />
          </MensagemMenuLateral>
        </div>
      );
    }
  }
}

export default MensagemSemPasto;
