import React from "react";
import { withStyles } from "@material-ui/core";
import ConfirmationModal from "features/shared/components/confirmationModal";
import GoalPlanFormContainer from "features/goalPlan/views/shared/goalPlanForm/goalPlanFormContainer";

const TEXT_BUTTON_SUBMIT = "SALVAR PLANO";

const GoalPlanCopyPage = ({
  onBack,
  onSubmit,
  showConfirmationModal,
  enableLoading,
  disableLoading,
  notifyError,
  goalPlanId,
}) => {
  const title =
    "Você está copiando um plano já cadastrado, dessa forma é possível editar todos os dados do plano.";

  return (
    <GoalPlanFormContainer
      titlePage="Copiar plano"
      titleContent={title}
      subtitleContent=""
      onBack={onBack}
      onSubmit={onSubmit}
      enableLoading={enableLoading}
      disableLoading={disableLoading}
      notifyError={notifyError}
      initialDataPlanId={goalPlanId}
      textButtonSubmit={TEXT_BUTTON_SUBMIT}
    >
      {showConfirmationModal && (
        <ConfirmationModal
          title={showConfirmationModal.title}
          text={showConfirmationModal.text}
          submitButtonTitle={showConfirmationModal.buttonConfirmText}
          onSubmit={showConfirmationModal.onConfirm}
          onCancel={showConfirmationModal.onCancel}
        />
      )}
    </GoalPlanFormContainer>
  );
};

const styles = () => {
  return {
    actionButtonContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      marginTop: 130,
    },
    cancelButton: {
      marginRight: 32,
      width: 124,
      height: 36,
      fontSize: 14,
    },
    saveButton: {
      fontSize: 14,
      width: 196,
      boxShadow: "none",
      height: 36,
    },
  };
};

export default withStyles(styles)(GoalPlanCopyPage);
