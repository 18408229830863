import * as avisoConsultoriaActions from './redux/avisoConsultoriaActions';
import * as avisoConsultoriaManager from './avisoConsultoriaManager';

export const setPrimeiraVisualizacao = flag => dispatch => {
  const idUsuarioAviso = avisoConsultoriaManager.obterIdUsuario();
  avisoConsultoriaManager.salvarIdUsuarioAviso(idUsuarioAviso);
  avisoConsultoriaManager.enviarDadosGA(idUsuarioAviso);
  dispatch(avisoConsultoriaActions.setPrimeiraVisualizacao(flag));
  dispatch(avisoConsultoriaActions.setVisibilidadeAviso(flag));
};

export const toggleAvisoConsultoria = () => (dispatch, getState) => {
  const { avisoConsultoria } = getState();
  dispatch(avisoConsultoriaActions.setVisibilidadeAviso(!avisoConsultoria.visibilidadeAviso));
};

export const obterPrimeiraVisualizacao = () => dispatch => {
  const idUsuario = avisoConsultoriaManager.obterIdUsuario();
  const primeiraVisualizacao = avisoConsultoriaManager.obterFlagPrimeiraVsualizacao(idUsuario);
  dispatch(avisoConsultoriaActions.setPrimeiraVisualizacao(primeiraVisualizacao));
};
