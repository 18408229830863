import {
  notifyError,
  notifySuccess,
} from "../../../redux/actions/notificacaoActions";
import CurralManager from "../curralManager";
import {
  listarLotes,
  salvarCurral,
  editarCurral,
  excluirCurral,
  exibirCarregando,
} from "./curralActions";
import _ from "lodash";

export const obterLotesPorCurral = (currais) => async (dispatch) => {
  dispatch(exibirCarregando(true));
  try {
    const lotes = await CurralManager.obterLotesPorCurrais(currais);
    dispatch(listarLotes(lotes));
  } catch (error) {
    dispatch(listarLotes([]));
    dispatch(notifyError({ mensagem: "Ocorreu um erro ao obter os lotes" }));
  } finally {
    dispatch(exibirCarregando(false));
  }
};

export const criarCurral = (curral) => async (dispatch, getState) => {
  try {
    const { fazenda } = getState();
    curral.IdFazenda = fazenda.FazendaSelecionada;

    const curralCriadoResposta = await CurralManager.salvarCurral(curral);

    dispatch(salvarCurral(curralCriadoResposta));
    dispatch(
      notifySuccess({
        mensagem: "Curral criado com sucesso.",
      })
    );
  } catch (error) {
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao criar curral.",
      })
    );
  }
};

export const atualizarCurral = (curral) => async (dispatch) => {
  try {
    const id = curral.IdPasto;
    curral = _.pick(curral, [
      "Nome",
      "NomeReduzido",
      "IdRetiro",
      "AreaAberta",
      "AreaEfetiva",
      "PotencialDeProducao",
      "IdForragem",
      "IdModulo",
      "Coordenadas",
    ]);
    await CurralManager.editarCurral(curral, id);
    dispatch(editarCurral());
    dispatch(
      notifySuccess({
        mensagem: "Curral editado com sucesso.",
      })
    );
  } catch (error) {
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao editar o curral.",
      })
    );
  }
};

export const deletarCurral = (idCurral) => async (dispatch) => {
  try {
    await CurralManager.excluirCurral(idCurral);
    dispatch(excluirCurral());
    dispatch(
      notifySuccess({
        mensagem: "Curral excluído com sucesso.",
      })
    );
  } catch (error) {
    if (
      !_.isNil(error.response) &&
      !_.isNil(error.response.data) &&
      !_.isNil(error.response.data.codigo) &&
      Number(error.response.data.codigo) === 406
    ) {
      dispatch(
        notifyError({
          mensagem: error.response.data.mensagem,
        })
      );
    } else {
      dispatch(
        notifyError({
          mensagem: "Ocorreu um erro ao excluir a curral.",
        })
      );
    }
  }
};
