import React from "react";
import AppBar from "./appBar";

const PaginaComAppBar = (props) => {
  return (
    <React.Fragment>
      <AppBar
        conteudoCustomizadoAppBar={props.conteudoCustomizadoAppBar}
        menuNavegacaoProps={{
          ...props.menuNavegacaoProps,
        }}
        clienteSelecionado={props.clienteSelecionado}
      />
      {props.conteudoPagina}
    </React.Fragment>
  );
};
PaginaComAppBar.defaultProps = {
  menuNavegacaoProps: {},
};
export default PaginaComAppBar;
