import React from "react";
import PropTypes from "prop-types";
import { Divider, withStyles } from "@material-ui/core";
import { obterQuantidadePastosCurralEPesoMedio } from "./detalhesRebanhoUtils";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const informacaoCategoriaAnimal = (props) => {
  const { classes, categoria, pastos, curraisRetiroComLotes } = props;
  const { quantidadeDeCabecas, quantidadeDeCabecasNoCurral, pesoMedio } =
    obterQuantidadePastosCurralEPesoMedio(
      pastos,
      curraisRetiroComLotes,
      categoria
    );

  return (
    <Box
      id={`categoria${categoria.IdCategoriaAnimal}`}
      key={categoria.IdCategoriaAnimal}
      sx={{ width: "100%", maxWidth: 500 }}
    >
      <Typography variant="subtitle2" className={classes.title} gutterBottom>
        {categoria.Nome}
      </Typography>
      <Typography variant="body2" gutterBottom className={classes.text}>
        Total de animais nos pastos:{" "}
        <b>{quantidadeDeCabecas ? quantidadeDeCabecas : "0"}</b>
      </Typography>

      <Typography variant="body2" gutterBottom className={classes.text}>
        Total de animais nos currais:{" "}
        <b>{quantidadeDeCabecasNoCurral ? quantidadeDeCabecasNoCurral : "0"}</b>
      </Typography>

      <Typography variant="body2" gutterBottom className={classes.text}>
        Total de animais nos currais e pastos:{" "}
        <b>{quantidadeDeCabecasNoCurral + quantidadeDeCabecas}</b>
      </Typography>
      <Typography variant="body2" gutterBottom className={classes.text}>
        Peso visual médio: <b>{`${pesoMedio.toFixed(0)} KG`}</b>
      </Typography>
      <Divider className={classes.divider} />
    </Box>
  );
};

const styles = (theme) => ({
  title: {
    color: theme.palette.primary.main,
    padding: "20px 0",
  },
  text: {
    color: "#757575",
    fontWeight: "normal",
    padding: "5px 0",
  },

  divider: {
    width: "100%",
    marginTop: "20px",
  },
});

informacaoCategoriaAnimal.propTypes = {
  pastos: PropTypes.array,
  categorias: PropTypes.array,
};

export default withStyles(styles)(informacaoCategoriaAnimal);
