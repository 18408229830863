import React from "react";
import { Typography, withStyles } from "@material-ui/core";
import PlanningFormContainer from "../shared/planningForm/planningFormContainer";
import { usePlanningPlanDataForm } from "features/planning/hooks/usePlanningDataForm";
import { useGoalValidations } from "features/planning/hooks/useGoalValidations";
import usePlanningRegisterTable from "features/planning/hooks/usePlanningRegisterTable";
import ConfirmationModal from "features/shared/components/confirmationModal";

const TITLE = "Novo planejamento";

const PlanningRegisterPage = ({
  classes,
  farmId,
  enableLoading,
  disableLoading,
  notifyError,
  onSubmit = () => {},
  goBack = () => {},
}) => {
  const SubTitle = () => (
    <Typography className={classes.subtitle}>
      Para começar, informe o período desejado. Em seguida, uma tabela será
      habilitada para que você informe o número de animais e as formulações
      utilizadas em cada mês. Se a formulação que você procura não estiver
      disponível, você poderá cadastrá-la acessando a tela{" "}
      <span className={classes.subTitleHighlight}>"Formulações"</span> e, em
      seguida, ela será exibida aqui.
    </Typography>
  );

  const IngredientSubTitle = () => (
    <Typography className={classes.subtitle}>
      Aqui, você pode criar um planejamento de custo de todos os ingredientes
      utilizados nas formulações, selecionadas anteriormente, informando o valor
      anual que deseja investir em cada ingrediente ao longo do período de
      planejamento.
    </Typography>
  );

  const SummarySubTitle = () => (
    <Typography className={classes.subtitle}>
      Por favor, revise os detalhes do seu planejamento. Se desejar fazer
      alterações, clique no botão{" "}
      <span className={classes.subTitleHighlight}>"VOLTAR"</span>. Caso esteja
      tudo correto, clique em{" "}
      <span className={classes.subTitleHighlight}>"Salvar planejamento"</span>.
    </Typography>
  );

  const { planningPlanData, periodTitles, onChangePlanningInitialDate } =
    usePlanningPlanDataForm();

  const {
    freeCategories,
    categories,
    formulas,
    formulasIngredients,
    planningsTableList,
    planningList,
    ingredientsPrices,
    summaryReport,
    updateIngredientsList,
    onChangeCategory,
    onChangeFormula,
    onChangeHerdCount,
    onChangeIngredientPrice,
    addNewLine,
    onDeleteLine,
    updateSummaryReport,
  } = usePlanningRegisterTable(
    farmId,
    enableLoading,
    disableLoading,
    notifyError
  );

  const { modalDatePickerActiveGoalPeriod: modalActiveGoalPeriod } =
    useGoalValidations({
      farmId,
      enableLoading,
      disableLoading,
      planningPlanDataInitialDate: planningPlanData.initialDate,
      onChangePlanningInitialDate,
    });

  const handleOnSubmit = () =>
    onSubmit({
      ...planningPlanData,
      planningList,
      ingredientsPrices,
      summaryReport,
      formulas,
      categories,
    });

  const handleOnChangePlanningInitialDate = (value) => {
    onChangePlanningInitialDate(value);
    modalActiveGoalPeriod.onChangeInitialDate();
  };
  return (
    <>
      <PlanningFormContainer
        title={TITLE}
        subtitle={<SubTitle />}
        ingredientsSubtitle={<IngredientSubTitle />}
        summarySubTitle={<SummarySubTitle />}
        planningPlanData={planningPlanData}
        periodTitles={periodTitles}
        freeCategories={freeCategories}
        categories={categories}
        ingredientsPrices={ingredientsPrices}
        formulas={formulas}
        formulasIngredients={formulasIngredients}
        planningsTableList={planningsTableList}
        onChangeCategory={onChangeCategory}
        onChangeFormula={onChangeFormula}
        onChangeHerdCount={onChangeHerdCount}
        onChangeIngredientPrice={onChangeIngredientPrice}
        updateIngredientsList={updateIngredientsList}
        addNewLine={addNewLine}
        onDeleteLine={onDeleteLine}
        onChangePlanningInitialDate={handleOnChangePlanningInitialDate}
        interceptDatePicker={modalActiveGoalPeriod.interceptDatePicker}
        showDatePicker={modalActiveGoalPeriod.showDatePicker}
        setShowDatePicker={modalActiveGoalPeriod.setShowDatePicker}
        onClickInitialDate={modalActiveGoalPeriod.openModal}
        onSubmit={handleOnSubmit}
        goBack={goBack}
        updateSummaryReport={updateSummaryReport}
        summaryReport={summaryReport}
      />

      {modalActiveGoalPeriod.modalGoalsDateSuggest && (
        <ConfirmationModal
          title="Novo planejamento"
          text={modalActiveGoalPeriod.getActiveGoalPeriodMessage()}
          cancelButtonTitle="Não utilizar data"
          submitButtonTitle="Sim, utilizar data"
          onSubmit={modalActiveGoalPeriod.onConfirmGoalPeriodDate}
          onCancel={modalActiveGoalPeriod.onCancelGoalPeriodDate}
        />
      )}
    </>
  );
};
const styles = (theme) => ({
  subtitle: {
    margin: "25px 0 12px 0",
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "normal",
    letterSpacing: "0.2px",
    textAlign: "left",
    color: "#616161",
  },
  subTitleHighlight: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
});
export default withStyles(styles)(PlanningRegisterPage);
