import React, { useState } from "react";
import moment from "moment";
import localConstants from "../views/shared/constants";
import goalPlanService from "features/goalPlan/goalPlanService";
import { checkNullOrUndefined } from "features/goalPlan/goalPlanUtils";

export const useSubmitUtils = ({
  farmId,
  enableLoading,
  disableLoading,
  notifyError,
  notifySuccess,
  callbackSubmit,
}) => {
  const [showConfirmationModal, setConfirmationModal] = useState(null);

  const onCloseConfirmModal = () => {
    setConfirmationModal(null);
  };

  const showModalError = (text) => {
    setConfirmationModal({
      title: "Salvar Plano",
      text,
      buttonConfirmText: "Continuar",
      onConfirm: onCloseConfirmModal,
      onCancel: null,
    });
  };

  const showErrorModalDateExist = (goalPlan) => {
    const formattedInitialDate = moment(goalPlan.initialDate).format(
      localConstants.dateShortFormat
    );
    const formattedFinalDate = moment(goalPlan.finalDate).format(
      localConstants.dateShortFormat
    );
    showModalError(
      <span>
        Não foi possível salvar suas alterações, pois já existe um plano com a
        data <strong>{formattedInitialDate}</strong> até{" "}
        <strong>{formattedFinalDate}</strong>. Por favor, insira outra data para
        salvar.
      </span>
    );
  };

  const showErrorModalNameExist = (name) => {
    showModalError(
      <span>
        Não foi possível salvar suas alterações, pois já existe um plano com o
        nome <strong>{name}</strong>. Por favor, insira outro nome para salvar.
      </span>
    );
  };

  const showErrorModalNameAndDate = (goalPlan) => {
    const formattedInitialDate = moment(goalPlan.initialDate).format(
      localConstants.dateShortFormat
    );
    const formattedFinalDate = moment(goalPlan.finalDate).format(
      localConstants.dateShortFormat
    );
    showModalError(
      <span>
        Não foi possível salvar suas alterações, pois já existe um plano com o
        nome <strong>{goalPlan.name}</strong> e data{" "}
        <strong>{formattedInitialDate}</strong> até{" "}
        <strong>{formattedFinalDate}</strong>. Por favor, insira outro nome e
        data para salvar.
      </span>
    );
  };

  const checkErrorGoalPlan = (error, goalPlan) => {
    if (
      error.response &&
      error.response.status === 400 &&
      error.response.data &&
      error.response.data.message
    ) {
      let includeDate = error.response.data.message.includes("date");
      let includeName = error.response.data.message.includes("name");
      if (!includeDate && !includeName) {
        return;
      }
      if (includeDate && includeName) {
        showErrorModalNameAndDate(goalPlan);
        return;
      }
      if (includeName) {
        showErrorModalNameExist(goalPlan.name);
        return;
      }
      if (includeDate) {
        showErrorModalDateExist(goalPlan);
        return;
      }
    }
  };

  const checkNotifyError = (error) => {
    if (error.response && error.response.status === 500) {
      notifyError("Ocorreu um erro ao salvar o plano, tente novamente.");
    }
  };
  const onCreateReview = async (revisionData) => {
    const { id: planId, linesGoals } = revisionData;

    const goalsToReview = linesGoals.map((goalLine) => {
      const values = goalLine.values.map((goal, index) => {
        const valueToReturn = {};
        valueToReturn.position = index + 1;
        if (checkNullOrUndefined(goal.value)) {
          valueToReturn.value = goal.value;
        }
        if (checkNullOrUndefined(goal.firstValue)) {
          valueToReturn.firstValue = goal.firstValue;
        }
        if (checkNullOrUndefined(goal.lastValue)) {
          valueToReturn.lastValue = goal.lastValue;
        }
        return valueToReturn;
      });

      return {
        name: goalLine.name && goalLine.name.trim(),
        indicatorName: goalLine.indicatorName,
        indicatorUnit: goalLine.indicatorUnit,
        indicatorType: goalLine.indicatorType,
        parameterName: goalLine.parameterName,
        parameterType: goalLine.parameterType,
        values,
      };
    });

    const planToReview = {
      goals: goalsToReview,
    };

    try {
      onCloseConfirmModal();
      enableLoading();
      await goalPlanService.postReview(planId, planToReview);
      callbackSubmit && callbackSubmit();
      notifySuccess("Revisão criada com sucesso!");
    } catch (error) {
      notifyError("Ocorreu um erro ao criar revisão, tente novamente.");
    } finally {
      disableLoading();
    }
  };

  const onCreatePlan = async (goalPlanDataToSubmit) => {
    const { name, initialDate, finalDate, linesGoals } = goalPlanDataToSubmit;
    const goalsToRegister = linesGoals.map((goalLine) => {
      const values = goalLine.values.map((goal, index) => {
        const valueToReturn = {};
        valueToReturn.position = index + 1;
        if (checkNullOrUndefined(goal.value)) {
          valueToReturn.value = goal.value;
        }
        if (checkNullOrUndefined(goal.firstValue)) {
          valueToReturn.firstValue = goal.firstValue;
        }
        if (checkNullOrUndefined(goal.lastValue)) {
          valueToReturn.lastValue = goal.lastValue;
        }
        return valueToReturn;
      });

      return {
        name: goalLine.name && goalLine.name.trim(),
        indicatorName: goalLine.indicatorName,
        indicatorUnit: goalLine.indicatorUnit,
        indicatorType: goalLine.indicatorType,
        parameterName: goalLine.parameterName,
        parameterType: goalLine.parameterType,
        values,
      };
    });

    const goalPlanDataToRegister = {
      farmId: farmId,
      name: name && name.trim(),
      initialDate: moment(initialDate).toISOString(),
      finalDate: moment(finalDate).toISOString(),
      goals: goalsToRegister,
    };

    try {
      onCloseConfirmModal();
      enableLoading();
      await goalPlanService.postPlan(goalPlanDataToRegister);
      callbackSubmit && callbackSubmit();
      notifySuccess("Plano adicionado com sucesso!");
    } catch (error) {
      checkErrorGoalPlan(error, goalPlanDataToRegister);
      checkNotifyError(error);
    } finally {
      disableLoading();
    }
  };

  const onUpdatePlan = async (goalPlanDataToSubmit) => {
    const { id, name, farmId, initialDate, finalDate } = goalPlanDataToSubmit;

    if (
      !goalPlanDataToSubmit ||
      !id ||
      !name ||
      !farmId ||
      !initialDate ||
      !finalDate
    )
      return;

    const goalPlanDataToUpdate = {
      id: id,
      farmId: farmId,
      name: name && name.trim(),
      initialDate: initialDate,
      finalDate: finalDate,
    };

    try {
      onCloseConfirmModal();
      enableLoading("updatePlan");
      await goalPlanService.goalPlanEdition(
        goalPlanDataToUpdate.id,
        goalPlanDataToUpdate
      );
      callbackSubmit && callbackSubmit();
      notifySuccess("Edição salva com sucesso!");
    } catch (error) {
      checkErrorGoalPlan(error, goalPlanDataToUpdate);
      checkNotifyError(error);
    } finally {
      disableLoading("updatePlan");
    }
  };

  return [
    showConfirmationModal,
    {
      onUpdatePlan,
      onCreatePlan,
      onCreateReview,
      onCloseConfirmModal,
    },
  ];
};
