export default function(Coordenadas) {
  const numeroVerticesPoligono = Coordenadas.length;
  let coordenadaPonto = Coordenadas[0];
  let maiorLatitude = Coordenadas[0]['lat'];

  for (var i = 1; i < numeroVerticesPoligono; i++) {
    if (Coordenadas[i]['lat'] > maiorLatitude) {
      maiorLatitude = Coordenadas[i]['lat'];
      coordenadaPonto = Coordenadas[i];
    }
  }
  return coordenadaPonto;
}
