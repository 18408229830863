import React from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  IconButton,
  withStyles
} from "@material-ui/core";
import moment from "moment";
import EditIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import PesagemCategoriaTabela from "./pesagemCategoriaTabela";

const Accordion = ({
  categorias,
  classes,
  criarNovo,
  editar,
  deletarEventoPesagemOuAbrirModal,
  painel,
  pesagens,
  setEditar,
  setPainel,
  setPesagens,
}) => {
  const ativaDesativaPainel = (index) => {
    setPainel({ ...painel, [index]: !painel[index] });
  };

  const botaoEditar = (index) => {
    setPainel({ ...painel, [index]: true });
    setEditar(true);
  };
  const classePainel = (i) => {
    if (i === 0 || pesagens.length === 1) {
      return classes.primeiroPainel;
    }
    if (i === pesagens.length - 1 && !painel[i]) {
      return classes.ultimoPainel;
    }
    return classes.painelExpansivo;
  };
  return (
    <React.Fragment>
      {pesagens.map((pesagem, i) => (
        <ExpansionPanel
          data-testid="painelExpansivo"
          expanded={!!painel[i]}
          key={i}
          style={{ width: "100%", margin: 0, boxShadow: "none" }}
        >
          <ExpansionPanelSummary
            classes={{
              content: classes.boxTexto,
            }}
            className={classePainel(i)}
          >
            <p
              onClick={() => ativaDesativaPainel(i)}
              className={classes.painelExpansivoTexto}
            >
              {pesagem.Nome} _{" "}
              {moment(pesagem.DataPesagem).format("DD/MM/YYYY")}
            </p>
            <div style={{ display: "flex" }}>
              {!criarNovo && !editar && (
                <IconButton
                  id="botaoEditar"
                  aria-label="Edit"
                  onClick={() => botaoEditar(i)}
                  data-testid={`editar${i}`}
                >
                  <EditIcon style={{ color: "#fff" }} />
                </IconButton>
              )}
              <IconButton
                id="botaoDeletar"
                data-testid={`deletar${i}`}
                onClick={() =>
                  deletarEventoPesagemOuAbrirModal(pesagem.IdEventoPesagem)
                }
                aria-label="Delete"
              >
                <DeleteIcon style={{ color: "#fff" }} />
              </IconButton>
              <IconButton id="arrowButton">
                {painel[i] ? (
                  <ArrowDropUpIcon
                    data-testid={`seta${i}up`}
                    onClick={() => ativaDesativaPainel(i)}
                    style={{ color: "#fff" }}
                  />
                ) : (
                  <ArrowDropDownIcon
                    data-testid={`seta${i}down`}
                    onClick={() => ativaDesativaPainel(i)}
                    style={{ color: "#fff" }}
                  />
                )}
              </IconButton>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ padding: 0 }}>
            <PesagemCategoriaTabela
              setPesagens={setPesagens}
              indexEventoAtual={i}
              categorias={categorias}
              CategoriaEventoPesagems={pesagem.CategoriaEventoPesagems}
              editar={editar}
              criarNovo={criarNovo}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </React.Fragment>
  );
};
const styles = () => {
  const painelExpansivo = {
    backgroundColor: "#418ace",
    height: "46px",
    justifyContent: "center",
  };
  return {
    painelExpansivoTexto: {
      color: "white",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "500",
      textAlign: "center",
      flexGrow: 3,
      margin: 0,
      padding: "14px 0",
    },
    boxTexto: {
      justifyContent: "center",
    },
    primeiroPainel: {
      ...painelExpansivo,
      borderRadius: "16px 16px 0 0",
    },
    ultimoPainel: {
      ...painelExpansivo,
      borderRadius: "0 0 16px 16px",
    },
    painelExpansivo,
  };
};
export default withStyles(styles)(Accordion);
