import {
  LISTA_LOTES,
  LISTA_LOTES_POR_PASTO,
  EXIBIR_CARREGANDO,
  PROJECAO_PESO,
  CURVA_GANHO_PESO,
} from "../../../redux/actions/actionsConstants";

export const listarLotes = (lotes) => {
  return {
    type: LISTA_LOTES,
    payload: { Lotes: lotes },
  };
};

export const listarLotesPorPasto = (lotes) => {
  return {
    type: LISTA_LOTES_POR_PASTO,
    payload: { LotesPorPasto: lotes },
  };
};

export const exibirCarregando = (exibir) => {
  return {
    type: EXIBIR_CARREGANDO,
    payload: exibir,
  };
};

export const listarProjecaoPesoSemestre = (projecaoPesoSemestre) => {
  return {
    type: PROJECAO_PESO,
    payload: { ProjecaoPesoSemestre: projecaoPesoSemestre },
  };
};

export const listarCurvaGanhoPeso = (curvaGanhoPeso) => {
  return {
    type: CURVA_GANHO_PESO,
    payload: { CurvaGanhoPeso: curvaGanhoPeso },
  };
};
