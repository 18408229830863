export const constantsInfoFarol = {
  labels: {
    cochoPadrao: "Cocho Padrão:",
    cochoCompartilhado: "Cocho Compartilhado:",
    nomeDoPasto: "Nome do pasto",
    nomeDosPastos: "Nome dos pastos",
    data: "Data",
    quantidadeDeAnimais: "Quantidade de animais",
    estruturaDoCocho: "Estrutura do cocho",
    acessoAoCocho: "Acesso ao cocho",
    cochoCoberto: "Cocho coberto",
    formulacao: "Formulação",
    quantidade: "Quantidade",
    bom: "Bom",
    ruim: "Ruim",
    cochoCobertoMsg: "O Cocho é coberto",
    cochoNaoCobertoMsg: "O Cocho não é coberto",
    coleta: "Coleta",
    desabastecimento: "Desabastecimento",
  },
  adornmentCochoCor: {
    padrao: "#24e9de",
    compartilhado: "#7a3cdc",
  },
  dataFormat: "DD/MM/YYYY",
};
