import React from "react";
import { Typography, withStyles, InputLabel } from "@material-ui/core";
import LogoProdap from "../../../../layout/views/logoProdap";
import TabelaCadastroIngrediente from "../../ingredientRegister/components/tabelaCadastroIngrediente";

const FormulacaoModeloImpressao = ({
  referencia,
  capacidadeMisturador,
  formula,
  classes,
  fazendaSelecionada,
  quantidadeTotal,
}) => {
  const getPageMargins = () => {
    return `
    @media all {
      .page-break {
        display: none;
      }
    }
    @media print {
      html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
      .page-break {
        display: block;
        page-break-before: auto;
      }
    }
    @page {
      size: auto;
      margin: 6mm 0 0 0;
      size: portrait;
    }
    `;
  };

  const formatarCampos = (campo) => {
    if (campo) {
      const campoTratato = campo.map((ca, key) => {
        return (key === 0 ? "" : "; ") + ca.Descricao;
      });

      return campoTratato;
    } else {
      return "-";
    }
  };

  if (formula && capacidadeMisturador) {
    const { Ingrediente, Total } = formula;
    const ingredientesEditado = Ingrediente.map((value) => {
      const percentual =
        (value.FormulaIngrediente.Quantidade * 100) / Total.Quantidade;
      const pesoIndividual =
        (percentual / 100) * parseFloat(capacidadeMisturador);
      return {
        ...value,
        FormulaIngrediente: { Quantidade: pesoIndividual.toFixed(2) },
      };
    });

    formula = { ...formula, Ingrediente: ingredientesEditado };
  }

  return (
    <div>
      <style>{getPageMargins()}</style>
      <div ref={referencia} className={classes.containerImpressao}>
        <div style={{ width: "100%" }}>
          <div className={classes.logoContainer}>
            <LogoProdap genericClass={classes.logo} id="logoPropdapAppBar" />
          </div>
          <div className={classes.titleContainer}>
            <Typography
              size={21}
              style={{ textAlign: "center", fontWeight: "bold" }}
              id="tituloDoAppBar"
            >
              {fazendaSelecionada.NomeReduzido}
            </Typography>
          </div>
        </div>
        <Typography className={classes.tituloImpressao}>
          Detalhes da Formulação
        </Typography>
        <div className={classes.listaFiltros}>
          <Typography className={classes.subtituloImpressao}>
            FORMULAÇÃO
          </Typography>
          <div className={classes.linhaFiltro}>
            <div className={classes.camposInput}>
              <InputLabel className={classes.inputLabel}>
                Nome da fórmula
              </InputLabel>
              <div className={classes.styleContent}>
                {formula && formula.Nome}
              </div>
            </div>
            <div className={classes.camposInput}>
              <InputLabel className={classes.inputLabel}>
                Unidade de Medida
              </InputLabel>
              <div className={classes.styleContent}>
                {formula ? (formula.Granel ? "A Granel" : "ENSACADO") : "-"}
              </div>
            </div>
            {formula && !formula.Granel && (
              <div className={classes.camposInput}>
                <InputLabel className={classes.inputLabel}>
                  Peso Saco (KG)
                </InputLabel>
                <div className={classes.styleContent}>
                  {formula
                    ? (formula.PesoSaco || 0)
                        .toFixed(2)
                        .toString()
                        .replace(".", ",") + " KG"
                    : "-"}
                </div>
              </div>
            )}
          </div>

          <div className={classes.linhaFiltro}>
            <div className={classes.camposInput}>
              <InputLabel className={classes.inputLabel}>Categoria</InputLabel>
              <div className={classes.styleContent}>
                {formatarCampos(formula ? formula.Categoria : null)}
              </div>
            </div>
          </div>
          <div className={classes.linhaFiltro}>
            <div className={classes.camposInput}>
              <InputLabel className={classes.inputLabel}>Época</InputLabel>
              <div className={classes.styleContent}>
                {formatarCampos(formula ? formula.Epocas : null)}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            margin: "24px",
            borderTop: "solid 1px #e0e0e0",
            paddingTop: "16px",
          }}
        >
          <Typography className={classes.subtituloImpressao}>
            INGREDIENTES
          </Typography>
          <Typography className={classes.capacidade}>
            Capacidade do misturador: {capacidadeMisturador} Kg
          </Typography>

          <TabelaCadastroIngrediente
            ingredientesParaMostrar={formula ? formula.Ingrediente : []}
            classes={classes}
            setIngredientesCadastrados={() => {}}
            quantidadeTotal={quantidadeTotal}
            setQuantidadeTotal={() => {}}
            modeloImpressao={true}
            capacidadeMisturador={capacidadeMisturador}
          />
        </div>
      </div>
    </div>
  );
};

const style = () => {
  return {
    containerImpressao: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "1200px",
      boxSizing: "border-box",
    },
    logo: {
      width: "101px",
      height: "35px",
    },
    logoContainer: {
      marginTop: 21,
      marginLeft: "2rem",
      position: "absolute",
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 90,
      width: "100%",
    },
    costsInput: {
      display: "flex",
      flexDirection: "column",
      marginLeft: 36,
      marginTop: 5,
    },
    printSpace: {
      height: 80,
      display: "flex",
      alignItems: "center",
    },
    capacidadeInputWrapper: {
      maxWidth: 280,
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "100%",
      marginRight: 26,
    },
    styleContent: {
      fontFamily: "Roboto",
      fontSize: 14,
      color: "#666",
      textTransform: "uppercase",
      marginTop: 6,
    },
    fieldFormula: {
      fontSize: 16,
      color: "#333",
      marginTop: 8,
      textTransform: "uppercase",
    },

    listaFiltros: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      width: "100%",
      marginLeft: "2rem",
    },
    linhaFiltro: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-start",
      height: 96,
    },
    tituloImpressao: {
      margin: "40px 0 20px 24px",
      fontFamily: "Roboto",
      fontSize: 18,
      fontWeight: "bold",
      color: "#2a2a2a",
    },
    subtituloImpressao: {
      marginBottom: 16,
    },
    camposInput: {
      minWidth: 240,
      display: "flex",
      flexDirection: "column",
    },
    inputLabel: {
      fontFamily: "Roboto",
      fontSize: 12,
      letterSpacing: 0.4,
      color: "rgba(0, 0, 0, 0.6",
      marginBottom: 5,
    },
    capacidade: {
      fontFamily: "Roboto",
      fonSize: 18,
      fontWeight: "bold",
      lineHeight: 1.33,
      letterSpacing: 0.15,
      color: "#666",
    },
  };
};

export default withStyles(style)(FormulacaoModeloImpressao);
