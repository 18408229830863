import { createServiceHandler } from '../services/ServiceHandler';
export default class {
  constructor(numeroMaximoTentativas) {
    this.numeroDaTentativa = 0;
    this.numeroMaximoTentativas = numeroMaximoTentativas;
  }
  novaTentativa() {
    if (this.numeroDaTentativa < this.numeroMaximoTentativas) {
      this.numeroDaTentativa++;
      return true;
    } else {
      return false;
    }
  }
  postServicoUrl(urlServico, objetoServico) {
    return new Promise(async (resolve, reject) => {
      while (true) {
        try {
          const resultado = await createServiceHandler().post(urlServico, objetoServico);
          resolve(resultado);
          break;
        } catch (erro) {
          if (!this.novaTentativa()) {
            resolve(erro);
            break;
          }
        }
      }
    });
  }
}
