import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import FormulationModalPage from "./formulationModalPage";

const FormulationModalContainer = ({
  formula,
  open,
  alterarStatusModal,
  onBackdropClick,
  onClickAway,
  componentRef,
  handlerCapacidade,
  handlerFormula,
  handrerQuantidadeTotal,
}) => {
  const containerDragRef = useRef();
  const [quantidadeTotal, setQuantidadeTotal] = useState(0);
  const [custoCabTotal, setCustoCabTotal] = useState(0);
  const [imprimir, setImprimir] = useState(false);
  const [listaIngredientes, setListaIngredientes] = useState([]);
  const [capacidadeMisturador, setCapacidadeMisturador] = useState("");

  useEffect(() => {
    setImprimir(capacidadeMisturador.length > 0);
  }, [capacidadeMisturador]);

  useEffect(() => {
    if (!open) {
      handlerCapacidade("");
    }
  }, [open]);

  const changeCapacidade = (value) => {
    setCapacidadeMisturador(value);
    handlerCapacidade(value);
  };

  const calculaCustoCabecaDia = () => {
    if (formula) {
      setCustoCabTotal(formula.Total.CustoCabTotal);
    }
  };

  const calculaQuantidadeTotal = () => {
    if (formula) {
      setQuantidadeTotal(formula.Total.Quantidade);
      handrerQuantidadeTotal(formula.Total.Quantidade);
    }
  };

  useEffect(() => {
    if (formula) {
      setListaIngredientes(formula.Ingrediente);
      calculaCustoCabecaDia();
      calculaQuantidadeTotal();
    }
  }, [formula]);

  const formatarCampos = (campo) => {
    if (campo) {
      const campoTratato = campo.map((ca, key) => {
        return (key === 0 ? "" : ", ") + ca.Descricao;
      });

      return campoTratato;
    } else {
      return "-";
    }
  };

  const imprimirFormula = useReactToPrint({
    content: () => componentRef,
  });

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const data = [...listaIngredientes];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      setListaIngredientes([...data]);
      handlerFormula({ ...formula, Ingrediente: data });
    },
    nodeSelector: "li",
    handleSelector: "a",
  };

  return (
    <FormulationModalPage
      formula={formula}
      open={open}
      onBackdropClick={onBackdropClick}
      onClickAway={onClickAway}
      dragProps={dragProps}
      ref={containerDragRef}
      capacidadeMisturador={capacidadeMisturador}
      changeCapacidade={changeCapacidade}
      imprimir={imprimir}
      quantidadeTotal={quantidadeTotal}
      custoCabTotal={custoCabTotal}
      alterarStatusModal={alterarStatusModal}
      formatarCampos={formatarCampos}
      imprimirFormula={imprimirFormula}
      listaIngredientes={listaIngredientes}
    />
  );
};

export default FormulationModalContainer;
