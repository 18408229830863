import React, { Component } from "react";
import { connect } from "react-redux";
import { default as MapaFarolComponent } from "../MapaFarol";

export class MapaFarol extends Component {
  render() {
    return (
      <MapaFarolComponent
        id="containerMapaFarol"
        {...this.props.reduxProps}
        zoomPadrao={14}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reduxProps: {
      coordenadasCentro: state.fazenda.centroMapa,
      pastos: state.pasto.Pastos,
      farois: state.farol.Farois,
      zoomExibeIconePastoFarol: state.farol.zoomExibeIconePastoFarol,
      farolSelecionado: state.farol.FarolSelecionado,
      retiroSelecionado: state.retiro.RetiroSelecionado,
      TipoIconeExibido: state.farol.TipoIconeExibido,
      forragens: state.forragem.Forragens,
      TipoFarol: state.farol.TipoFarol,
      pastoSelecionado: state.pasto.PastoSelecionado,
    },
  };
};

export default connect(mapStateToProps)(MapaFarol);
