import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core";
import styles from "./NewLoginPage/styles";
import * as action from "../../../redux/actions";
import { useChangePassword } from "../hooks/useChangePassword";
import ChangePasswordSkeletonLoading from "./components/ChangePasswordSkeletonLoading";
import PageLayout from "./components/PageLayout";
import PasswordInput from "./components/PasswordInput";

export function ChangePasswordPage({
  match,
  classes,
  notifyError,
  notifySuccess,
}) {
  const { token } = match.params || {};
  const { isTokenValid, loading, error, onSubmit, onChange } =
    useChangePassword({ token, notifyError, notifySuccess });

  if (loading) {
    return (
      <PageLayout>
        <ChangePasswordSkeletonLoading />
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      {isTokenValid ? (
        <div className={classes.content}>
          <div className={classes.greetings}>
            <h1 className={classes.greetingWelcome}>Criação de senha</h1>
            <p className={classes.greetingsMessage}>
              Crie sua senha para acessar a plataforma{" "}
            </p>
          </div>

          <form className={classes.form} onSubmit={onSubmit}>
            <PasswordInput
              label="Senha"
              inputName="password"
              placeholder="Informe uma senha"
              error={error}
              onChange={onChange}
            />
            <PasswordInput
              label="Confirmar senha"
              inputName="passwordConfirmation"
              placeholder="Repita a senha"
              error={error}
              onChange={onChange}
            />

            <div className={classes.passwordCreationRules}>
              <div className={classes.passwordCreationRulesSection}>
                <p>
                  <strong>Regras de senha:</strong>
                </p>
                <p>Conter pelo menos 8 caracteres.</p>
              </div>

              <div className={classes.passwordCreationRulesSection}>
                <p>Existem três restrições de caracteres:</p>
                <div style={{ marginLeft: 4 }}>
                  <p>1. Pelo menos uma letra minúscula</p>
                  <p>2. Pelo menos uma letra maiúscula</p>
                  <p>3. Pelo menos um número</p>
                </div>
              </div>

              <div className={classes.passwordCreationRulesSection}>
                <p>
                  É possível substituir uma das três restrições anteriores por
                  caracteres especiais:
                </p>
                <p>
                  <strong>+_!@#S%^&*?</strong>
                </p>
              </div>
            </div>

            <button className={classes.submitButton} type="submit">
              Salvar senha
            </button>
          </form>
          <div className={classes.footer}>
            <p className={classes.footerHighlight}>Atenção!</p>
            <p>
              Este link tem{" "}
              <span className={classes.footerHighlight}>validade de 24h</span>,
              após isso será necessário solicitar um novo link.
            </p>
          </div>
        </div>
      ) : (
        <InvalidToken classes={classes} />
      )}
    </PageLayout>
  );
}

const InvalidToken = ({ classes }) => {
  return (
    <div className={classes.invalidTokenContent}>
      <p>Este link não está mais disponível, por favor, solicite outro.</p>
      <img
        src="../images/sad-cloud.svg"
        alt="sad-cloud"
        style={{ width: "450px", height: "200px" }}
      />
    </div>
  );
};

const mapDispatchToProps = {
  notifyError: action.notifyError,
  notifySuccess: action.notifySuccess,
};

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(ChangePasswordPage);
