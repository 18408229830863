import React from "react";
import { Divider, withStyles } from "@material-ui/core";
import dayjs from "dayjs";
import PlanningReportTable from "./planningReport/PlanningReportTable";
import { fillPlanningItems, parsePlanningItems } from "./utils";
import PlanningFormulaReportTable from "./planningReport/planningFormulaReportTable";
import PlanningIngredientReportTable from "./planningReport/planningIngredientReportTable";
import PlanningCategoryReportTable from "./planningReport/planningCategoryReportTable";
import { tableStyles } from "./planningReport/tableStyle";
import PlanningStatus from "./planningStatus";

const PlanningPrintTemplate = ({
  classes,
  farm,
  planningList = [],
  categories = [],
  formulas = [],
  planningTotal = { months: [] },
  summaryReport = {},
  periodTitles,
  planningPlanData,
}) => {
  const TitleStart = ({ classes }) => {
    return (
      <div className={classes.titleStart}>
        <span className={classes.verticalDecorator} />
        <div>
          <div className={classes.titleStatusContent}>
            <div className={classes.titleReport}>
              Relatório evolução de rebanho e plano anual de nutrição
            </div>
            <PlanningStatus
              initialDate={planningPlanData.initialDate}
              finalDate={planningPlanData.finalDate}
              customClassName={classes.planningStatus}
            />
          </div>
          <div>{farm}</div>
        </div>
      </div>
    );
  };
  const TitleEnd = ({ classes }) => {
    const lastUpdate = planningPlanData.updatedAt || planningPlanData.createdAt;
    const formattedLastUpdate = dayjs(lastUpdate).format("DD/MM/YY - HH:mm");
    return (
      <div className={classes.titleEnd}>
        <img
          src="../images/logo-farmtell-views-white-1.png"
          alt="FarmTell Views"
          width={101}
          height={35}
        />
        Última atualização: {formattedLastUpdate}
      </div>
    );
  };
  const getPageMargins = () => {
    return `
    @media print {
      html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
        position: relative;
      }
      .header, .footer {
        display: none;
      }
      .page-break {
        break-inside: avoid;
      }
    }
    @page {
      size: 1754px 1240px; // 150 dpi
    }
    @page :not(:first){
      margin-top: 6mm;
    }
    `;
  };

  const TitleContainer = ({ classes }) => {
    return (
      <div className={classes.titleContainer}>
        <TitleStart classes={classes} />
        <TitleEnd classes={classes} />
      </div>
    );
  };

  const planningItemsList = parsePlanningItems({
    planningItems: fillPlanningItems(planningList),
  });

  return (
    <div>
      <style type="text/css" media="print">
        {getPageMargins()}
      </style>
      <div className={classes.container}>
        <TitleContainer classes={classes} />

        <div
          className={classes.contentTables}
          data-testid="Plannings-Printing-Content-Tables"
        >
          <div
            className={classes.tableContent}
            data-testid="Plannings-Printing-Table-Content"
          >
            <div>
              <div className={classes.titlePanContainer}>
                <h1 className={classes.tableTitle}>
                  Evolução de rebanho e plano de nutrição
                </h1>
                <span className={classes.periodPan}>
                  Período: 04/2022 até 03/2023
                </span>
              </div>
              <PlanningReportTable
                isPrinting
                periodTitles={periodTitles}
                categories={categories}
                formulas={formulas}
                total={planningTotal}
                planningsTableList={planningItemsList}
                tabelaEsquerda={false}
              />
            </div>

            {summaryReport && (
              <>
                <Divider />
                {summaryReport.formulaPlanning && (
                  <PlanningFormulaReportTable
                    planningFormulaReport={summaryReport.formulaPlanning}
                    periodTitles={periodTitles}
                  />
                )}
                <Divider />
                {summaryReport.ingredientPlanning && (
                  <PlanningIngredientReportTable
                    planningIngredientReport={summaryReport.ingredientPlanning}
                    periodTitles={periodTitles}
                  />
                )}
                <Divider />
                {summaryReport.categoryPlanning && (
                  <PlanningCategoryReportTable
                    planningCategoryReport={summaryReport.categoryPlanning}
                    periodTitles={periodTitles}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const styles = (theme) => ({
  ...tableStyles(theme),
  container: {
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      height: "30vh",
      zIndex: -100,
      background: "#005687",
      borderRadius: "0px 0px 16px 16px",
    },
  },

  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    padding: "32px 16px 12px 16px",
    fontFamily: "'Roboto'",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#FFFFFF",
  },

  verticalDecorator: {
    width: 5,
    background: "#9CC31A",
  },
  titleStart: {
    display: "flex",
    gap: "8px",
    "& :nth-child(2)": {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
  },
  titleEnd: {
    display: "flex",
    alignItems: "end",
    flexDirection: "column",
    gap: "12px",
  },

  contentTables: {
    "& table": {
      marginTop: "16px",
    },
    margin: "0px 16px 0px 16px",
    minHeight: 200,
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    boxShadow: "0px 2px 10px 0px #0000001A",
  },

  tableContent: {
    paddingTop: "20px",
    paddingBottom: "20px",
    margin: "0px 20px 20px 16px",
    minHeight: 200,
    "& > :first-child > :first-child": {
      maxHeight: "unset",
    },
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },

  periodPan: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 400,
    color: "#7F7F7F",
    marginBottom: "24px",
  },

  titlePanContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
  },

  planningStatus: {
    fontSize: "12px",
    height: "18px",
  },

  titleStatusContent: {
    display: "flex",
    gap: "16px",
    width: "100%",
    alignItems: "center",
  },

  titleReport: {
    fontWeight: 500,
    whiteSpace: "nowrap",
  },
});
export default withStyles(styles)(PlanningPrintTemplate);
