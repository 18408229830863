import { useState, useEffect } from 'react';
import FazendaService from 'services/FazendaService';
import RetiroService from 'features/retiro/retiroService';

export function useFarmAndRetreatLink(data, onAdd, onRemove) {
  const [farms, setFarms] = useState([]);
  const [retreats, setRetreats] = useState([]);
  const [selectedFarm, setSelectedFarm] = useState(null)
  const [selectedRetreats, setSelectedRetreats] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ farm: false, retreats: false });

  const filteredFarms = farms.filter(farm => !data.value.find(link => link.farm.id === farm.value));
  const filteredRetreats = retreats.filter(retreat => retreat.farmId === selectedFarm)

  const clearSelected = () => {
    setSelectedFarm(null);
    setSelectedRetreats([]);
  }

  const onChange = (field) => (value) => {
    if (field === 'farm') {
      setSelectedFarm(value)
      setError({ ...error, farm: false });
    } else {
      setSelectedRetreats(value)
      setError({ ...error, retreats: false });
    }
  }

  const onAddLink = () => {
    if (!selectedFarm || !selectedRetreats.length) {
      setError({
        farm: !selectedFarm,
        retreats: !selectedRetreats.length
      });
      return;
    }

    const link = {
      farm: {
        id: selectedFarm,
        name: farms.find(farm => farm.value === selectedFarm).label
      },
      retreats: selectedRetreats.map(retreat => ({
        id: retreat,
        name: retreats.find(r => r.value === retreat).label
      }))
    }
    onAdd(link);
    clearSelected()
  };

  const onRemoveLink = (farmId) => {
    onRemove(farmId);
  };


  useEffect(() => {
    const loadData = async () => {
      try {
        const currentClientId = sessionStorage.getItem('clienteSelecionado');
        if (!currentClientId) {
          return
        }
        const farms = await FazendaService.listarFazendasPorCliente(currentClientId).then(response => response.data)
        if (!farms.length) {
          return
        }

        const retreats = await RetiroService.listaRetirosPorIdsFazenda(farms.map(farm => farm.IdFazenda)).then(response => response)

        setFarms(farms.map(farm => ({ value: farm.IdFazenda, label: farm.Nome })))
        setRetreats(retreats.map(retreat => ({ value: retreat.IdRetiro, label: retreat.Nome, farmId: retreat.IdFazenda })))
      } catch (error) {
        setError({ farm: true, retreats: true })
      } finally {
        setLoading(false)
      }
    }

    loadData()
  }, []);

  return {
    loading,
    error,
    farms: filteredFarms,
    retreats: filteredRetreats,
    selectedFarm,
    selectedRetreats,
    onAddLink,
    onRemoveLink,
    onChange
  };
}
