import FechamentoConsumoService from "./fechamentoConsumoService";
import {
  getFiltroFechamentoConsumo
} from "./fechamentoConsumoUtils";
import {
  enviaDadosGAPorRotulo
} from "../../shared/utils";
import moment from "moment";

export const MSG = {
  ERRO_OBTER_FORMULAS: "Ocorreu um erro ao tentar obter fórmulas",
  ERRO_OBTER_CONSUMOS: "Ocorreu um erro ao tentar obter consumos fórmulas",
};
class FechamentoConsumoManager {
  async obtemFechamentoConsumo(IdFazenda, formulas, dataInicio, dataFim, formulaSelecionada, idRetiroSelecionada) {
    try {
      if (idRetiroSelecionada === -1)
        idRetiroSelecionada = undefined;
      const filtro = getFiltroFechamentoConsumo(formulas, formulaSelecionada, {
        IdFazenda
      }, dataInicio, dataFim, idRetiroSelecionada);
      this.enviaDadosGA(filtro);
      const respostaServico = await FechamentoConsumoService.obtemFechamentoConsumo({
        ...filtro,
      });
      return respostaServico.data;
    } catch (error) {
      throw new Error(MSG.ERRO_OBTER_CONSUMOS);
    }
  }
  enviaDadosGA({
    IdFazenda,
    DataInicio,
    DataFim
  }) {
    const acao = `IdFazenda: ${IdFazenda}`;
    const rotulo = this.obtemRotuloGAPorBusca(DataInicio, DataFim);
    const categoria = "Análise Fechamento de Consumo";
    enviaDadosGAPorRotulo(acao, rotulo, categoria);
  }
  obtemRotuloGAPorBusca(DataInicio, DataFim) {
    const novoFechamento = moment(DataInicio).isAfter(moment("2020-05-15")) ? "Novo Fechamento" : "Fechamento Antigo";
    const diferencaDias = moment(DataFim).diff(moment(DataInicio), "days");
    if (diferencaDias < 10) return `${novoFechamento} - Menos de 10 dias`;
    else if (diferencaDias < 30) return `${novoFechamento} - Menos de 30 dias`;
    else return `${novoFechamento} - Mais de 1 mês`;
  }
}

export default new FechamentoConsumoManager();
