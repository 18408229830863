import React, { Component } from "react";
import ObjetivoTabela from "./objetivoTabela";
import { filterObjetivosMemoize } from "../objetivoUtils";
import ObjetivoForm from "./objetivoForm";
import Button from "@material-ui/core/Button";
import { DialogoConfirmacaoAcao } from "features/shared/components/DialogoConfirmacaoAcao";
import {
  Grid,
  withStyles,
  Typography,
  Paper,
  FormControl,
  TextField,
} from "@material-ui/core";
import SelectDropdown from "components/SelectDropdown";

const OPCOES_SELECT_INPUT = [
  { value: 0, label: "TODOS" },
  { value: 1, label: "ATIVOS" },
  { value: 2, label: "INATIVOS" },
];

export class ObjetivoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmacaoExclusaoAberto: false,
      exibindo: false,
      adicionando: false,
      filtro: "",
      exibirForm: false,
      objetivo: {
        Selecionada: false,
        IdObjetivo: null,
        Nome: "",
        IdFazenda: props.fazendaSelecionada,
      },
      objetivoSelecionado: {
        Selecionada: false,
        IdObjetivo: null,
        IdFazenda: props.fazendaSelecionada,
        Nome: "",
      },
      erroNome: false,
      estadoSelecionado: 1,
      objetivos: [],
    };
  }

  async componentDidMount() {
    const { listarObjetivos, fazendaSelecionada } = this.props;
    await listarObjetivos(fazendaSelecionada);
    if (this.props.objetivos) {
      this.setState({
        objetivos: this.props.objetivos.filter(
          (objetivo) => !objetivo.Excluido
        ),
      });
    }
  }

  abreDialogExclusao = (objetivo) => {
    this.setState({
      confirmacaoExclusaoAberto: true,
      objetivoSelecionado: objetivo,
    });
  };

  fechaDialogExclusao = async () => {
    this.setState({ confirmacaoExclusaoAberto: false });
  };

  handleChangeNomeObjetivo = (event) => {
    const { objetivos } = this.props;
    this.setState({
      objetivo: {
        ...this.state.objetivo,
        Nome: event.target.value.replace(/  +/g, " "),
      },
    });
    this.setState({
      erroNome: objetivos.some(
        (objetivo) =>
          objetivo.Nome.toUpperCase().trim() ===
          event.target.value.toUpperCase().trim()
      ),
    });
  };

  habilitarObjetivo = (acao) => {
    this.setState({
      adicionando: acao,
      objetivo: {
        Selecionada: false,
        Nome: "",
        IdFazenda: this.props.fazendaSelecionada,
      },
      erroNome: false,
    });
  };

  adicionarObjetivo = async (objetivo) => {
    if (objetivo.IdObjetivo) {
      await this.props.atualizarObjetivo(objetivo);
    } else {
      await this.props.criarObjetivo(objetivo);
    }
    await this.props.listarObjetivos(this.props.fazendaSelecionada);
    this.setState({
      objetivo: {
        Selecionada: false,
        Nome: "",
      },
      adicionando: false,
    });

    this.alterarEstadoDoFiltro(this.state.estadoSelecionado);
  };

  habilitarEdicao = (objetivo) => {
    this.setState({
      adicionando: true,
      objetivo: objetivo,
    });
  };
  habilitarExibicao = (objetivo) => {
    objetivo.Selecionada = objetivo.Selecionada ? false : true;
    this.setState({
      objetivo: objetivo,
      exibindo: !this.state.exibindo,
    });
  };

  excluirObjetivo = async (idObjetivo) => {
    await this.props.deletarObjetivo(idObjetivo);
    await this.props.listarObjetivos(this.props.fazendaSelecionada);
    this.setState({
      objetivo: {
        Selecionada: false,
        Nome: "",
      },
      adicionando: false,
    });
  };

  alterarEstadoDoFiltro = (value) => {
    const { objetivos } = this.props;
    let objetivosFiltrados;

    switch (value) {
      case 1:
        objetivosFiltrados = objetivos.filter((objetivo) => !objetivo.Excluido);
        break;
      case 2:
        objetivosFiltrados = objetivos.filter((objetivo) => objetivo.Excluido);
        break;
      default:
        objetivosFiltrados = objetivos;
        break;
    }

    this.setState({
      objetivos: objetivosFiltrados,
      estadoSelecionado: value,
    });
  };

  ativaDesativaObjetivo = async (IdObjetivo, excluido) => {
    if (excluido) {
      await this.props.ativaObjetivo(IdObjetivo);
    } else {
      await this.props.deletarObjetivo(IdObjetivo);
    }
    await this.props.listarObjetivos(this.props.fazendaSelecionada);

    this.alterarEstadoDoFiltro(this.state.estadoSelecionado);
  };

  render() {
    const {
      filtro,
      adicionando,
      objetivo,
      confirmacaoExclusaoAberto,
      erroNome,
      exibindo,
      estadoSelecionado,
    } = this.state;
    const { classes } = this.props;
    const { objetivos } = this.state;

    const objetivosFiltrados = filterObjetivosMemoize(objetivos, filtro);

    return (
      <React.Fragment>
        <Paper square className={classes.root} elevation="0">
          <Grid container direction="column" className={classes.containerPage}>
            <Grid item>
              <Typography className={classes.titulo} gutterBottom>
                Informações sobre os objetivos de lote da fazenda
              </Typography>
            </Grid>
            <Grid container alignItems="flex-end">
              <Grid xs={3}>
                <FormControl
                  className={classes.formContainer}
                  disabled={adicionando}
                >
                  <TextField
                    id="inputFiltro"
                    autoFocus
                    label={"Buscar Objetivo de lote"}
                    className={classes.inputFiltro}
                    onChange={(event) =>
                      this.setState({ filtro: event.target.value })
                    }
                  />
                </FormControl>
              </Grid>

              <Grid xs={2}>
                <SelectDropdown
                  id="categorias-select"
                  label={{ text: "Situação do objetivo" }}
                  options={OPCOES_SELECT_INPUT}
                  value={estadoSelecionado}
                  onChange={this.alterarEstadoDoFiltro}
                />
              </Grid>

              {adicionando ? (
                <Grid xs={12}>
                  <Paper square className={classes.corpoAdicionar}>
                    <ObjetivoForm
                      id="formAdicionar"
                      classes={classes}
                      objetivo={objetivo}
                      erroNome={erroNome}
                      abreDialogExclusao={this.abreDialogExclusao}
                      adicionarObjetivo={this.adicionarObjetivo}
                      habilitarObjetivo={this.habilitarObjetivo}
                      handleChangeNomeObjetivo={this.handleChangeNomeObjetivo}
                    />
                  </Paper>
                </Grid>
              ) : (
                <Grid xs={7}>
                  <Button
                    id="botaoAdicionar"
                    onClick={() => this.habilitarObjetivo(true)}
                    variant="contained"
                    color="primary"
                    className={classes.botaoNovo}
                  >
                    Novo Objetivo
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <ObjetivoTabela
                id="objetivoTabela"
                objetivosFiltrados={objetivosFiltrados}
                ativaDesativaObjetivo={this.ativaDesativaObjetivo}
                habilitarEdicao={this.habilitarEdicao}
                adicionando={adicionando}
                habilitarExibicao={this.habilitarExibicao}
                objetivo={objetivo}
                exibindo={exibindo}
              />
            </Grid>
          </Grid>
        </Paper>
        <DialogoConfirmacaoAcao
          id={`dialogConfirmaExclusaoObjetivo`}
          tituloDialog={"Confirmar exclusão de Objetivo"}
          textoDialog={
            <span id="mensagemConfirmaExclusaoCurva">
              Você deseja excluir o objetivo de lote?
            </span>
          }
          dialogAberto={confirmacaoExclusaoAberto}
          cancelarAcaoDialog={this.fechaDialogExclusao}
          objetoSelecionado={this.state.objetivoSelecionado.IdObjetivo}
          excluir={this.excluirObjetivo}
        />
      </React.Fragment>
    );
  }
}

const styles = () => {
  return {
    root: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      minHeight: "calc(100vh - 90px)",
      zIndex: -1,
      bottom: 0,
      padding: "46px",
    },
    containerPage: {
      flexgrow: 1,
      backgroundColor: "#fff",
      width: "100%",
      marginTop: 60,
    },
    titulo: {
      fontFamily: "Roboto",
      fontSize: 20,
      fontWeight: "bold",
      color: "#444444",
    },
    botaoNovo: {
      borderRadius: "18px",
      float: "right",
      width: 145,
      height: 35,
      boxShadow: "none",
    },
    corpoAdicionar: {
      width: "100%",
      marginTop: "10px",
      padding: "24px 32px",
    },

    formContainer: {
      marginTop: "20px",
    },
    inputFiltro: {
      width: "274px",
    },
  };
};

export default withStyles(styles)(ObjetivoPage);
