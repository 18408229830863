import ObjetivoService from "./objetivoService";

export class ObjetivoManager {
  async listaObjetivos(idFazenda, withDeleted = true) {
    const resposta = await ObjetivoService.getGoals(idFazenda, withDeleted);
    return resposta;
  }
  async salvarObjetivo(objetivo, fazenda) {
    const respostaService = await ObjetivoService.createGoal({
      farmId: fazenda.FazendaSelecionada,
      name: objetivo.Nome,
    });
    return respostaService.data;
  }

  async excluirObjetivo(idObjetivo) {
    return await ObjetivoService.deleteGoal(idObjetivo);
  }

  async editarObjetivo(objetivo) {
    await ObjetivoService.updateGoal(objetivo.IdObjetivo, {
      name: objetivo.Nome,
    });
  }

  async reativarObjetivo(id) {
    const response = await ObjetivoService.reactivatingGoal(id);
    return response;
  }
}

export default new ObjetivoManager();
