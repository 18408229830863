const localConstants = {
  dateMonthFormat: "MMM/YY",
  dateShortFormat: "MM/YYYY",
  monthDiff: 12,
  info: {
    priceIngredient:
      "É o preço por kg de um determinado produto, sendo usado para calcular o custo total com base no peso.",
  },
  titles: {
    ingredients: "Ingredientes",
    priceIngredient: "Valor em R$ por kg",
  },
};

export default localConstants;
