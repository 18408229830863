import memoize from 'memoize-one';

export const filterObjetivosMemoize = memoize((list, textoFiltrar) =>
  (list || []).filter((objetivo) => {
    objetivo.Nome = objetivo.Nome || '';
    return objetivo.Nome.toUpperCase().includes(textoFiltrar.toUpperCase());
  })
);

export const convertGoalData = (response) => {
  return response.map((goal) => {
    return {
      IdObjetivo: goal.id,
      Nome: goal.name,
      IdFazenda: goal.farmId,
      UsuarioCriacao: goal.creationUser,
      DataCriacao: goal.createdAt,
      UsuarioAlteracao: goal.updateUser,
      DataAlteracao: goal.updatedAt,
      UsuarioExclusao: goal.deletionUser,
      Excluido: goal.deletedAt ? true : false,
      DataExclusao: goal.deletedAt,
    };
  });
};
