import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Modal, Grid, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import TabelaGanhoPeso from "./batchListingTable/components/loteTabelaGanhoPeso";

export const ModalGanhoPeso = (props) => {
  const { classes } = props;
  const { displayModal, handleClose, projecaoPesoSemestre } = props;
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={displayModal}
      onBackdropClick={handleClose}
      keyboard={false}
    >
      <div className={classes.modalWrapper}>
        <Grid
          id="containerModalLote"
          container
          spacing={0}
          direction="row"
          justify="space-evenly"
          className={classes.modalGeral}
        >
          <Grid item xs={10}>
            <Typography variant="h6" className={classes.titulo}>
              Projeção do ganho de peso
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <CloseIcon className={classes.fechar} onClick={handleClose} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.subTitulo} align="left">
              Veja o histórico da projeção do ganho de peso total, para o último
              dia do mês.
            </Typography>
          </Grid>
          <Grid item className={classes.tabela}>
            <TabelaGanhoPeso projecaoPesoSemestre={projecaoPesoSemestre} />
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

const styles = (themes) => {
  return {
    modalWrapper: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    modalGeral: {
      width: "425px",
      height: "445px",
      borderRadius: "2.4px",
      backgroundColor: "#ffffff",
    },
    titulo: {
      position: "relative",
      top: "25px",
      left: "40px",
      fontSize: "18px",
      fontWeight: "500",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.1px",
      color: "rgba(51, 50, 56, 0.84)",
    },
    subTitulo: {
      position: "relative",
      textAlign: "left",
      width: "351px",
      top: "10px",
      left: "40px",
      fontSize: "14px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normaL",
      lineHeight: 1.43,
      letterSpacing: "0px",
      color: "#8e8e93",
    },
    fechar: {
      position: "relative",
      top: "20px",
      left: "25px",
      width: "24px",
      height: "24px",
      cursor: "pointer",
      objectFit: "contain",
      padding: "0",
    },
    tabela: {
      position: "relative",
      width: "343px",
      height: "279px",
      borderRadius: "3px",
      padding: "0",
    },
  };
};

export default withStyles(styles)(ModalGanhoPeso);
