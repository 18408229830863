import React from "react";
import { connect } from "react-redux";
import CadastroFormulacaoPage from "./cadastroFormulacaoPage";
import {
  deveExibirCreep,
  deveExibirSemiConfinamento,
} from "../../../shared/utils";
import * as operations from "../../redux/formulaOperations";
import * as configuracoresOperations from "features/configuracao/redux/configuracaoOperations";
import * as ingredientesOperations from "../../../cadastroIngredientes/redux/cadastroIngredientesOperations";

const CadastroFormulacaoContainer = (props) => {
  return <CadastroFormulacaoPage {...props} />;
};

const mapDispatchToProps = {
  obterTodosIngredientes: ingredientesOperations.obterTodasOperacoesIngrediente,
  criarFormula: operations.criarFormula,
  atualizaFormula: operations.atualizarFormula,
  obterEpocasFormula: operations.obterEpocasFormula,
  obterCategoriasFormula: operations.obterCategoriasFormula,
  copyFormula: operations.copyFormula,
  selecionaAba: configuracoresOperations.selecionaAba
};

const mapStateToProps = (state) => {
  const { formula, fazenda, cadastroIngredientes } = state;
  const ingredientes = cadastroIngredientes.ingredientes.items
    ? cadastroIngredientes.ingredientes.items
    : [];

  return {
    ingredientes: ingredientes,
    formulasFazenda: formula.FormulasFazenda,
    categoriasRebanho: formula.categoriasFormula,
    epocas: formula.epocasFormula,
    exibirCreep: deveExibirCreep(state),
    exibirSemiConfinamento: deveExibirSemiConfinamento(state),
    fazendaSelecionada: fazenda.FazendaSelecionada,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CadastroFormulacaoContainer);
