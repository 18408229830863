import * as pesagemCategoriaActions from "./pesagemCategoriaActions";

export const INITIAL_STATE = {
  PesagensFazenda: [],
  exibirModal: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case pesagemCategoriaActions.LISTAR_PESAGENS:
      return {
        ...state,
        PesagensFazenda: action.payload.PesagensFazenda,
      };
    case pesagemCategoriaActions.EXIBIR_MODAL_DATA_INICIAL_CONCILIACAO:
      return {
        ...state,
        exibirModalConciliacao: action.payload,
      };
    default:
      return state;
  }
};
