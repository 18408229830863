import ObjetivoManager from "../objetivoManager";
import {
  listaObjetivos,
  salvarObjetivo,
  exibirCarregando,
  editarObjetivo,
  excluirObjetivo,
  ativarObjetivo,
} from "./objetivoAction";
import {
  notifyError,
  notifySuccess,
} from "../../../redux/actions/notificacaoActions";
import _ from "lodash";

export const listarObjetivos = (idFazenda, withDeleted) => async (dispatch) => {
  dispatch(exibirCarregando(true));
  try {
    const objetivos = await ObjetivoManager.listaObjetivos(
      idFazenda,
      withDeleted
    );

    dispatch(listaObjetivos(objetivos));
  } catch (err) {
    dispatch(listaObjetivos([]));
    dispatch(
      notifyError({ mensagem: "Ocorreu um erro ao obter os objetivos" })
    );
  } finally {
    dispatch(exibirCarregando(false));
  }
};

export const criarObjetivo = (objetivo) => async (dispatch, getState) => {
  try {
    const { fazenda } = getState();
    const objetivoCriadoResposta = await ObjetivoManager.salvarObjetivo(
      objetivo,
      fazenda
    );

    dispatch(salvarObjetivo(objetivoCriadoResposta));
    dispatch(
      notifySuccess({
        mensagem: "Objetivo de lote criado com sucesso.",
      })
    );
  } catch (error) {
    dispatch(
      notifyError({
        mensagem: error,
      })
    );
  }
};

export const atualizarObjetivo = (objetivo) => async (dispatch) => {
  try {
    await ObjetivoManager.editarObjetivo(objetivo);
    dispatch(editarObjetivo([]));
    dispatch(
      notifySuccess({
        mensagem: "Objetivo de lote editado com sucesso.",
      })
    );
  } catch (error) {
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao editar o Objetivo de lote.",
      })
    );
  }
};

export const ativaObjetivo = (objetivo) => async (dispatch) => {
  try {
    const response = await ObjetivoManager.reativarObjetivo(objetivo);
    dispatch(ativarObjetivo(response.data));
    dispatch(
      notifySuccess({
        mensagem: "Objetivo de lote foi reativado com sucesso.",
      })
    );
  } catch (error) {
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao reativar o Objetivo de lote.",
      })
    );
  }
};

export const deletarObjetivo = (idObjetivo) => async (dispatch) => {
  try {
    dispatch(exibirCarregando(true));
    await ObjetivoManager.excluirObjetivo(idObjetivo);
    dispatch(excluirObjetivo());
    dispatch(
      notifySuccess({
        mensagem: "Objetivo de lote desativado com sucesso.",
      })
    );
  } catch (error) {
    if (!_.isNil(error.response) && !_.isNil(error.response.data)) {
      dispatch(
        notifyError({
          mensagem: error.response.data.message,
        })
      );
    } else {
      dispatch(
        notifyError({
          mensagem: error,
        })
      );
    }
  } finally {
    dispatch(exibirCarregando(false));
  }
};
