import BaseService from "./BaseService";
const URL_BASE = `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/pastosPrime`;

class PastoPrimeService extends BaseService {

  async listaPastosPrimePorIdFazenda(IdFazenda) {
    try {
      if (IdFazenda) {
        const filtro = { where: { IdFazenda } };
        const parametrosURL = this.montaFiltro(filtro)
        const urlServico = this.montaURL(URL_BASE, parametrosURL);
        const response = await this.get(urlServico);
        return response.data;
      }
      return []
    } catch (error) {
      throw new Error("Ocorreu um erro ao buscar os pastos para a fazenda selecionada.");
    }
  }

  /**
   * Funções auxiliares
   */
  /* istanbul ignore next */
  trataCampoNumerico(campo) {
    return campo.replace(/\./g, "").replace(/,/g, ".");
  }

  /* istanbul ignore next */
  trataCampoBooleano(campo) {
    if (campo.length) return campo.toLowerCase() === "true";
    return "";
  }

  /* istanbul ignore next */
  trataTiposCampos(pastoCocho) {
    const camposNumerais = [
      "AreaAberta",
      "AreaEfetiva",
      "PotencialDeProducao",
      "Comprimento"
    ];
    const camposBooleanos = ["Coberto", "Elevado", "EstoqueFinal"];
    const newPastoCocho = {};
    Object.keys(pastoCocho).forEach(key => {
      /* istanbul ignore next */
      if (camposNumerais.includes(key) && pastoCocho[key].length)
        newPastoCocho[key] = this.trataCampoNumerico(pastoCocho[key]);
      /* istanbul ignore next */
      else if (camposBooleanos.includes(key) && pastoCocho[key].length)
        newPastoCocho[key] = this.trataCampoBooleano(pastoCocho[key]);
      /* istanbul ignore next */
      else if (pastoCocho[key].length) newPastoCocho[key] = pastoCocho[key];
    });
    return newPastoCocho;
  }
}

export default new PastoPrimeService();
