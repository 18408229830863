import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import { withStyles } from "@material-ui/core";
import * as actions from "../../../../../redux/actions";
import styles from "../../../../../styles/MenuLateral/Farol";
import { enviaDadosGAPorRotulo } from "../../../../../shared/utils.js";

const dropdownTipoAfericao = (props) => {
  const { classes, farolRetratoSelecionado, opcoesFarol } = props;

  const mudarColeta = (e) => {
    props.setTipoColeta(e.target.value);
    const acao = "Filtrar Coleta";
    const rotulo = e.target.value;
    const categoria = "Análise";
    enviaDadosGAPorRotulo(acao, rotulo, categoria);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="tipo-coleta">Tipo</InputLabel>
      <Select
        value={props.TipoIconeExibido}
        onChange={mudarColeta}
        input={<Input name="name" id="tipo-coleta" />}
        disabled={!farolRetratoSelecionado}
      >
        {exibeOpcoesDropdown(opcoesFarol)}
      </Select>
    </FormControl>
  );
};

const mapDispatchToProps = {
  setTipoColeta: actions.setTipoColeta,
};

const exibeOpcoesDropdown = (opcoesFarol) => {
  return opcoesFarol.map((opcao) => (
    <MenuItem key={opcao.value} value={opcao.value}>
      {opcao.key}
    </MenuItem>
  ));
};

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps)
)(dropdownTipoAfericao);
