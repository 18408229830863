import React from "react";
import { TableCell, Typography, Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

const NomeColunasTabela = ({
  tabelaHeaderContent,
  nomesColunas,
  minimizado,
}) => {
  const montarTextoColuna = (colunaNome, textStyle) => {
    const StyleColum = {
      display: "flex",
      alignItems: "baseline",
      justifyContent: "center",
      marginRight: "-5px",
    };

    const StyleColumSize = {
      display: "flex",
      alignItems: "baseline",
      justifyContent: "center",
    };
    const arrayNomes = colunaNome.split(" ");
    return arrayNomes.map((nome, index) => {
      return (
        <div
          key={colunaNome + index}
          style={index === arrayNomes.length - 1 ? StyleColum : StyleColumSize}
        >
          <Typography key={`${nome}-text`} className={textStyle}>
            {nome.replace("_", " ")}
          </Typography>
          {nome === "DIFERENÇA" && !minimizado ? (
            <Tooltip
              title={
                "É a diferença entre o Estoque Final Portal e o Estoque Final APP"
              }
              arrow
            >
              <InfoIcon
                fontSize="inherit"
                color="primary"
                style={{
                  fontSize: "18px",
                  position: "relative",
                  left: "4%",
                  top: "3px",
                }}
              />
            </Tooltip>
          ) : nome === "FINAL" && !minimizado ? (
            <Tooltip
              title={
                "Estoque Inicial + Entradas - Saídas + Troca Categoria Entrada - Troca Categoria Saída + Nascimentos - Mortes + Entrevero"
              }
              arrow
            >
              <InfoIcon
                fontSize="inherit"
                color="primary"
                style={{
                  fontSize: "18px",
                  position: "relative",
                  left: "20%",
                  bottom: "6px",
                }}
              />
            </Tooltip>
          ) : nome === "INICIAL" && !minimizado ? (
            <Tooltip
              title={
                "É o número de animais, referente a entradas e saídas da Data Inicial da Conciliação na tela de Categoria Animal, até a data inicial escolhida no filtro."
              }
              arrow
            >
              <InfoIcon
                fontSize="inherit"
                color="primary"
                style={{
                  fontSize: "18px",
                  position: "relative",
                  left: "20%",
                  bottom: "6px",
                }}
              />
            </Tooltip>
          ) : nome === "ENTREVERO_" && !minimizado ? (
            <Tooltip
              title={
                "É a soma dos entreveros positivos e negativos em todas as categorias"
              }
              arrow
            >
              <InfoIcon
                fontSize="inherit"
                color="primary"
                style={{
                  fontSize: "18px",
                  position: "relative",
                  left: "6%",
                  bottom: "6px",
                }}
              />
            </Tooltip>
          ) : null}
        </div>
      );
    });
  };

  const borderLeft = { borderRadius: "16px 0  0 0" };
  const borderRight = minimizado
    ? { borderRadius: "0 16px 0 0", paddingRight: 2 }
    : { borderRadius: "0 16px 0 0", minWidth: "130px" };

  return nomesColunas.map((coluna, index) => (
    <TableCell
      key={`${coluna}-head`}
      padding="none"
      align="left"
      style={
        index === 0
          ? borderLeft
          : index === nomesColunas.length - 1
          ? borderRight
          : null
      }
    >
      {montarTextoColuna(coluna, tabelaHeaderContent)}
    </TableCell>
  ));
};

export default NomeColunasTabela;
