import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { Grid, TextField, withStyles } from "@material-ui/core";
import { DialogoConfirmacaoAcao } from "features/shared/components/DialogoConfirmacaoAcao";
import * as causaMorteUtils from "../causaMorteUtils";
import CausaMorteTabela from "./causaMorteTabela";
import CausaMorteForm from "./causaMorteForm";
import SelectDropdown from "components/SelectDropdown";

export const STATE_OPTIONS = [
  { value: 0, label: "TODOS" },
  { value: 1, label: "ATIVADOS" },
  { value: 2, label: "DESATIVADOS" },
];

export class CausaMortePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmacaoExclusaoAberto: false,
      modalAberto: false,
      adicionando: false,
      textoFiltrar: "",
      causaMorte: {
        IdCausaMorte: null,
        Nome: "",
      },
      causaMorteClicada: {
        IdCausaMorte: null,
        Nome: "",
      },
      erroNome: false,
      stateFilter: 1,
    };
  }

  async componentDidMount() {
    await this.props.obtemCausasMorteFazenda();
  }

  /**
  abreDialogExclusao = causaMorte => {
    this.setState({ confirmacaoExclusaoAberto: true, causaMorteClicada: causaMorte });
  };
*/
  fechaDialogExclusao = async () => {
    this.setState({ confirmacaoExclusaoAberto: false });
    await this.props.obtemCausasMorteFazenda();
  };

  handleChangeNomeCausaMorte = (event) => {
    const { causasMorte } = this.props;
    this.setState({
      causaMorte: {
        ...this.state.causaMorte,
        Nome: event.target.value.toUpperCase(),
      },
    });
    this.setState({
      erroNome: causasMorte.some(
        (causaMorte) =>
          causaMorte.Nome.trim() === event.target.value.toUpperCase().trim()
      ),
    });
  };

  handleChangeFiltroCausaMorte = (event) => {
    this.setState({ textoFiltrar: event.target.value });
  };

  habilitarFormulario = (acao) => {
    this.setState({
      adicionando: acao,
      causaMorte: {
        Nome: "",
      },
      erroNome: false,
    });
  };

  adicionarCausaMorte = async (causaMorte) => {
    if (causaMorte.IdCausaMorte) {
      await this.props.editarCausaMorte(causaMorte);
    } else {
      await this.props.criarCausaMorte(causaMorte);
    }
    await this.props.obtemCausasMorteFazenda();
    this.setState({ causaMorte: { Nome: "" }, adicionando: false });
  };

  habilitarEdicao = (causaMorte) => {
    this.setState({
      adicionando: true,
      causaMorte: causaMorte,
    });
  };

  excluirCausaMorte = async (idCausaMorte) => {
    await this.props.excluirCausaMorte(idCausaMorte);
  };

  reativarCausaMorte = async (idCausaMorte, causaMorte) => {
    await this.props.reativarCausaMorte(idCausaMorte, causaMorte);
  };

  ativaDesativaCausaMorte = async (idCausaMorte, excluido, causaMorte) => {
    if (excluido) await this.reativarCausaMorte(idCausaMorte, causaMorte);
    else await this.excluirCausaMorte(idCausaMorte);
    await this.props.obtemCausasMorteFazenda();
  };

  handleStateChange = async (value) => {
    this.setState({ stateFilter: value });
  };

  render() {
    const { classes, causasMorte } = this.props;
    const {
      adicionando,
      causaMorte,
      confirmacaoExclusaoAberto,
      erroNome,
      stateFilter,
      textoFiltrar,
    } = this.state;
    const causasMorteFiltradas = causaMorteUtils.filterCausaMorteMemoize(
      causasMorte,
      textoFiltrar,
      stateFilter
    );
    return (
      <React.Fragment>
        <Paper square className={classes.root}>
          <Grid item sm={12} xs={12} className={classes.containerPage}>
            <Typography className={classes.titulo} gutterBottom>
              Causas de mortes de animais
            </Typography>
            <div className={classes.filterContainer}>
              <FormControl
                id="formBuscar"
                disabled={adicionando}
                className={classes.textoBuscar}
              >
                <TextField
                  id="buscarCausaMorte"
                  autoFocus
                  label={"Buscar causas"}
                  style={{ width: "400px" }}
                  onChange={this.handleChangeFiltroCausaMorte}
                />
              </FormControl>

              <div className={classes.selectInput}>
                <SelectDropdown
                  testId="state-filtro"
                  id="state-select"
                  label={{ text: "Situação da causa de morte" }}
                  options={STATE_OPTIONS}
                  value={stateFilter}
                  onChange={this.handleStateChange}
                />
              </div>
            </div>
            {adicionando ? (
              <Paper square className={classes.corpoAdicionar}>
                <CausaMorteForm
                  id="formAdicionar"
                  classes={classes}
                  causaMorte={causaMorte}
                  erroNome={erroNome}
                  handleChangeNomeCausaMorte={this.handleChangeNomeCausaMorte}
                  adicionarCausaMorte={this.adicionarCausaMorte}
                  habilitarFormulario={this.habilitarFormulario}
                />
              </Paper>
            ) : (
              <Button
                id="botaoAdicionar"
                onClick={() => this.habilitarFormulario(true)}
                variant="contained"
                color="primary"
                className={classes.botaoNovo}
              >
                Nova Causa
              </Button>
            )}
            <Paper square className={classes.corpoTabela}>
              <CausaMorteTabela
                id="tabelaCausaMorte"
                causasMorteFiltradas={causasMorteFiltradas}
                habilitarEdicao={this.habilitarEdicao}
                adicionando={adicionando}
                ativaDesativaCausaMorte={this.ativaDesativaCausaMorte}
              />
            </Paper>
          </Grid>
        </Paper>
        <DialogoConfirmacaoAcao
          id={`dialogConfirmaExclusaoCausaMorte`}
          tituloDialog={"Confirmar exclusão de Causa de Morte"}
          textoDialog={
            <span id="mensagemConfirmaExclusaoFormula">
              Você deseja excluir a causa de morte?
            </span>
          }
          dialogAberto={confirmacaoExclusaoAberto}
          cancelarAcaoDialog={this.fechaDialogExclusao}
          objetoSelecionado={this.state.causaMorteClicada.IdCausaMorte}
          excluir={this.props.excluirCausaMorte}
        />
      </React.Fragment>
    );
  }
}

const styles = (theme) => {
  return {
    containerPage: {
      flexgrow: 1,
      height: "100%",
      backgroundColor: "#fff",
      width: "100%",
    },
    root: {
      width: "100%",
      height: "100%",
      overflowX: "auto",
      padding: "46px",
      zIndex: -1,
      bottom: 0,
      top: "17%",
      minHeight: "calc(100vh - 90px)",
    },
    corpoTabela: {
      width: "100%",
      maxHeight: 600,
      overflow: "auto",
      marginTop: "20px",
      borderRadius: "16px",
    },
    titulo: {
      fontSize: "20px",
      fontWeight: "bold",
      fontStyle: "medium",
      fontStretch: "medium",
      lineHeight: "medium",
      letterSpacing: "medium",
      color: "#333333",
      marginTop: "40px",
    },
    corpoAdicionar: {
      width: "100%",
      marginTop: "10px",
      padding: "20px",
    },
    botaoNovo: {
      borderRadius: "18px",
      float: "right",
      marginRight: 0,
      marginTop: "34px",
      width: "153px",
    },
    textTamanhoDesejado: {
      width: "180px",
    },
    selectSexo: {
      width: "50px",
    },
    selectFaixa: {
      width: "180px",
    },
    selectCurva: {
      width: "180px",
    },
    filterContainer: {
      width: "100%",
      display: "flex",
      paddingTop: "20px",
    },
    textoBuscar: {
      marginTop: "4px",
    },
    selectInput: {
      maxWidth: 200,
      width: "100%",
      marginLeft: 32,
    },
  };
};

export default withStyles(styles)(CausaMortePage);
