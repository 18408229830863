/* global google*/
import { Children } from "react";
import { useGoogleMap } from "@react-google-maps/api"
import { memo, useEffect, useState } from "react";
import { createPortal } from "react-dom";

const AdvancedMarkerFunctional = ({
  position,
  children,
  content,
  collisionBehavior,
  gmpDraggable,
  id,
  title = "",
  zIndex,
  onClick,
  onDblClick,
  onDrag,
  onDragEnd,
  onDragStart,
  onMouseOut,
  onMouseOver,
  onMouseUp,
  onMouseDown,
  onRightClick,
  onClickableChanged,
  onCursorChanged,
  onAnimationChanged,
  onDraggableChanged,
  onFlatChanged,
  onIconChanged,
  onPositionChanged,
  onShapeChanged,
  onTitleChanged,
  onVisibleChanged,
  onZindexChanged,
  onLoad,
  onUnmount
}) => {

  const map = useGoogleMap();

  const [instance, setInstance] = useState(null)
  const [contentContainer, setContentContainer] = useState(null)

  const [clickListener, setClickListener] = useState(null)
  const [mouseOutListener, setMouseOutListener] = useState(null)
  const [mouseOverListener, setMouseOverListener] = useState(null)

  const numChilds = Children.count(children);

  useEffect(() => {
    if (instance && onClick) {
      if (clickListener !== null) {
        google.maps.event.removeListener(clickListener)
      }

      setClickListener(
        google.maps.event.addListener(instance, 'click', onClick)
      )
    }
  }, [onClick])

  useEffect(() => {
    if (instance && onMouseOut) {
      if (mouseOutListener !== null) {
        google.maps.event.removeListener(mouseOutListener)
      }

      setMouseOutListener(
        google.maps.event.addListener(instance, 'mouseout', onMouseOut)
      )
    }
  }, [onMouseOut])

  useEffect(() => {
    if (instance && onMouseOver) {
      if (mouseOverListener !== null) {
        google.maps.event.removeListener(mouseOverListener)
      }

      setMouseOverListener(
        google.maps.event.addListener(instance, 'mouseover', onMouseOver)
      )
    }
  }, [onMouseOver])


  useEffect(() => {

    const el = document.createElement('div');

    const advancedMarkerOptions = {
      content: el,
      title,
      position,
      map,
      zIndex: 0
    }

    const advanced = new google.maps.marker.AdvancedMarkerElement(advancedMarkerOptions);

    setContentContainer(el);


    if (onClick) {
      setClickListener(
        google.maps.event.addListener(advanced, 'click', onClick)
      )
    }

    if (onMouseOut) {
      setMouseOutListener(
        google.maps.event.addListener(advanced, 'mouseout', onMouseOut)
      )
    }

    if (onMouseOver) {
      setMouseOverListener(
        google.maps.event.addListener(advanced, 'mouseover', onMouseOver)
      )
    }

    setInstance(advanced)

    return () => {
      if (clickListener !== null) {
        google.maps.event.removeListener(clickListener)
      }
      if (mouseOutListener !== null) {
        google.maps.event.removeListener(mouseOutListener)
      }
      if (mouseOverListener !== null) {
        google.maps.event.removeListener(mouseOverListener)
      }
      advanced.map = null;
      setInstance(null);
      setContentContainer(null);
    }
  }, [numChilds, map]);

  if (contentContainer !== null)
    return createPortal(children, contentContainer);

  return null;
}

export const AdvancedMarker = memo(AdvancedMarkerFunctional)
