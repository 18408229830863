import React, { Component } from "react";
import { PaginaComAppBar, AppBarConteudoDefault } from "../../layout";
import Paper from "@material-ui/core/Paper";
import { Button, Grid, TextField, withStyles } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TableauPastaPaper from "./tableauPastaPaper";
import TableauRelatorioPaper from "./tableauRelatorioPaper";
import ContainerRelatorio from "./containerRelatorio";
import FormControl from "@material-ui/core/FormControl";
import Breadcrumbs from "./breadcrumbs";
import { buscaTicketTableauEmbedded } from "../../../shared/utils.js";
import auth from "../../authentication/Auth";
import _ from "lodash";
let usuario;
export class TableauPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      etapas: [
        {
          titulo: "Análises",
        },
      ],
      textoFiltrar: "",
      pastaFocada: null,
      relatorioFocado: null,
    };

    usuario = auth.getSession();
  }

  async componentDidMount() {
    await this.props.obterPastasUsuario(usuario.email);
    await this.props.obterRelatorios();
  }

  onClickPastas(pasta) {
    const arrayEtapas = this.state.etapas;
    arrayEtapas.push({ titulo: pasta.titulo });
    this.setState({
      pastaFocada: pasta.workBooks,
      etapas: arrayEtapas,
      textoFiltrar: "",
    });
  }

  async onClickRelatorios(relatorio) {
    const arrayEtapas = this.state.etapas;
    const ticket = await buscaTicketTableauEmbedded(usuario.email);
    localStorage.setItem("ticketTableau", ticket);
    arrayEtapas.push({ titulo: relatorio.nomePasta });
    this.setState({
      relatorioFocado: relatorio.url,
      etapas: arrayEtapas,
      textoFiltrar: "",
    });
  }

  onClickBreadcrumbs(titulo) {
    if (titulo === "Análises") {
      this.setState({
        pastaFocada: null,
        relatorioFocado: null,
        etapas: [{ titulo: "Análises" }],
        textoFiltrar: "",
      });
    } else {
      const pastaFocada = this.props.pastas.find(
        (pasta) => pasta.titulo === titulo
      );
      this.setState({
        pastaFocada: pastaFocada.workBooks,
        relatorioFocado: null,
        etapas: [{ titulo: "Análises" }, { titulo: pastaFocada.titulo }],
        textoFiltrar: "",
      });
    }
  }

  onClickBotaoVoltar() {
    const arrayEtapas = this.state.etapas;
    arrayEtapas.pop();
    const relatorioFocado = this.state.relatorioFocado;
    if (relatorioFocado) {
      this.setState({
        relatorioFocado: null,
        etapas: arrayEtapas,
        textoFiltrar: "",
      });
    } else {
      this.setState({
        pastaFocada: null,
        etapas: arrayEtapas,
        textoFiltrar: "",
      });
    }
  }

  gerarConteudoPagina(classes) {
    if (this.state.relatorioFocado) {
      return (
        <div className={classes.containerView}>
          <ContainerRelatorio url={this.state.relatorioFocado} />
        </div>
      );
    } else if (this.state.pastaFocada) {
      return (
        <Grid container spacing={32} className={classes.containerPage}>
          {this.criarCardsRelatorios()}
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={32} className={classes.containerPage}>
          {this.criarCardsPastas()}
        </Grid>
      );
    }
  }

  criarCardsRelatorios() {
    const { pastaFocada, textoFiltrar } = this.state;
    return pastaFocada.map((nomePasta) => {
      const { relatorios } = this.props;
      if (nomePasta.toLowerCase().includes(textoFiltrar.toLowerCase())) {
        const relatoriosPasta = relatorios[nomePasta];
        if (
          relatoriosPasta &&
          _.isArray(relatoriosPasta) &&
          relatoriosPasta.length > 0
        ) {
          const relatorioInicialPasta = relatoriosPasta[0];
          return (
            <Grid item key={relatorioInicialPasta.nomePasta} justify="center">
              <TableauRelatorioPaper
                title={relatorioInicialPasta.nomePasta}
                descricao={relatorioInicialPasta.descricaoPasta}
                cliqueCallback={() =>
                  this.onClickRelatorios(relatorioInicialPasta)
                }
              />
            </Grid>
          );
        }
      }
      return null;
    });
  }

  criarCardsPastas() {
    return this.props.pastas.map((pasta) =>
      pasta.titulo
        .toLowerCase()
        .includes(this.state.textoFiltrar.toLowerCase()) ? (
        <Grid item key={pasta.titulo} justify="center">
          <TableauPastaPaper
            title={pasta.titulo}
            imagemSrc={pasta.imagemSrc}
            cliqueCallback={() => this.onClickPastas(pasta)}
          />
        </Grid>
      ) : null
    );
  }

  gerarBarraOpcoes(classes) {
    return (
      <Grid container xs={12} justify="center">
        {this.state.pastaFocada !== null && this.criarBotaoVoltar(classes)}
        {this.state.relatorioFocado === null && this.criarCampoBusca(classes)}
      </Grid>
    );
  }

  criarBotaoVoltar(classes) {
    return (
      <Grid item xs={2} justify="flex-start">
        <Button
          variant="contained"
          className={classes.botaoVoltar}
          onClick={() => this.onClickBotaoVoltar()}
        >
          <ArrowBackIcon />
          <div className={classes.textoBotaoVoltar}>Voltar</div>
        </Button>
      </Grid>
    );
  }

  criarCampoBusca(classes) {
    return (
      <Grid item xs={10} justify="flex-end">
        <FormControl id="formBuscar" className={classes.textoBuscar}>
          <TextField
            id="buscarPasta"
            autoFocus
            label={"Buscar"}
            className={classes.campoBusca}
            onChange={this.handleChangeFiltro}
            value={this.state.textoFiltrar}
          />
        </FormControl>
      </Grid>
    );
  }

  handleChangeFiltro = (event) => {
    this.setState({ textoFiltrar: event.target.value });
  };

  conteudoPagina() {
    const { classes } = this.props;
    return (
      <Paper square className={classes.root}>
        <Breadcrumbs
          etapas={this.state.etapas}
          callbackClique={this.onClickBreadcrumbs.bind(this)}
        />
        {this.gerarBarraOpcoes(classes)}
        {this.gerarConteudoPagina(classes)}
      </Paper>
    );
  }

  render() {
    return (
      <PaginaComAppBar
        conteudoCustomizadoAppBar={
          <AppBarConteudoDefault tituloAppBar="" textoSecundario="" />
        }
        conteudoPagina={this.conteudoPagina()}
      />
    );
  }
}

const styles = {
  typography: {
    fontFamily: "Roboto",
  },
  root: {
    width: "100%",
    height: window.outerHeight * 1.15,
    overflowX: "auto",
    zIndex: -1,
    flexgrow: 1,
    top: "163px",
    minHeight: window.innerHeight,
  },
  containerPage: {
    flexgrow: 1,
    padding: "0",
    backgroundColor: "#fff",
    margin: "0 auto",
    marginTop: "163px",
    marginLeft: "30px",
    width: "95%",
    position: "absolute",
  },
  containerView: {
    flexgrow: 1,
    padding: "0",
    backgroundColor: "#fff",
    margin: "0 auto",
    marginTop: "163px",
    marginLeft: "5%",
    width: "90%",
    position: "absolute",
  },
  titulo: {
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#424242",
    marginBottom: "20px",
  },
  smallerArea: {
    width: "100%",
    height: "auto",
    margin: "0em 0em",
    padding: "0.8em 0em",
  },
  resultPaper: {
    paperRoot: {
      width: "100%",
      height: "auto",
      border: "2px",
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.2)",
      backgroundColor: "#ffffff",
    },
  },
  botaoVoltar: {
    marginTop: "80px",
    paddingTop: "5px",
    width: "106px",
    height: "41px",
    left: "30px",
    position: "absolute",
    textAlign: "center",
    boxShadow: "none",
  },
  textoBotaoVoltar: {
    paddingTop: "4px",
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "-0.03px",
    color: "#4c4c4c",
  },
  campoBusca: {
    width: "350px",
  },
  textoBuscar: {
    marginTop: "80px",
    paddingBottom: "30px",
    right: "40px",
    position: "absolute",
  },
};

export default withStyles(styles)(TableauPage);
