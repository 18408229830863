import React from "react";
import BatchListingFilter from "./components/batchListingFilter";

const BatchListingFilterPage = ({
  retiros,
  filtroPesoSelecionado,
  filtroPesoProjetadoSelecionado,
  tiposFiltrosPesoPesado,
  tiposFiltrosPesoProjetado,
  filtroDefault,
  handlePrint,
  valorRetiroSelecionado,
  categoriaTexto,
  valorFiltroPeso,
  valorFiltroProjetado,
  setValorRetiroSelecionado,
  setCategoriaTexto,
  setValorFiltroPeso,
  setValorFiltroProjetado
}) => {
  return (
    <BatchListingFilter
      retiros={retiros}
      filtroPesoSelecionado={filtroPesoSelecionado}
      filtroPesoProjetadoSelecionado={filtroPesoProjetadoSelecionado}
      tiposFiltrosPesoPesado={tiposFiltrosPesoPesado}
      tiposFiltrosPesoProjetado={tiposFiltrosPesoProjetado}
      filtroDefault={filtroDefault}
      handlePrint={handlePrint}
      valorRetiroSelecionado={valorRetiroSelecionado}
      categoriaTexto={categoriaTexto}
      valorFiltroPeso={valorFiltroPeso}
      valorFiltroProjetado={valorFiltroProjetado}
      setValorRetiroSelecionado={setValorRetiroSelecionado}
      setCategoriaTexto={setCategoriaTexto}
      setValorFiltroPeso={setValorFiltroPeso}
      setValorFiltroProjetado={setValorFiltroProjetado}
    />
  );
};

export default BatchListingFilterPage;
