import * as farolActions from "features/manejo/redux/actions/farolActions";
import MapaGoogleCustomizado from "features/manejo/views/components/MapaGoogleCustomizado/MapaGoogleCustomizadoContainer";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import ColetaService from "services/ColetaService";
import * as actions from "../../../../../redux/actions";
import { idsFarois } from "constants/idsFarois";
import MapaContent from "./MapaContent";
import { getColorIconCollect } from "../../PasturesUtils";


export const MapaFarol = ({
  visualizarPontosColetas,
  pastos,
  coordenadasCentro,
  zoomPadrao,
  retiroSelecionado,
  farolSelecionado,
  farois,
  TipoIconeExibido,
  zoomExibeIconePastoFarol,
  forragens,
  TipoFarol,
  fazendaSelecionada,
  obterTodasForragens,
  alteraZoomMapaFarol,
  periodoColeta,
  atualizaZoom,
}) => {
  const [pastosPorCocho, setPastosPorCocho] = useState({});
  const [coletas, setColetas] = useState([]);
  const [centroMap, setCentroMap] = useState(coordenadasCentro);
  const [pastosEnriquecidos, setPastosEnriquecidos] = useState([]);

  useEffect(() => {
    enriquecePastosComFarol();
  }, [pastos, farois, farolSelecionado])

  useEffect(() => {
    obterTodasForragens();
    ReactGA.pageview("/farol");
  }, []);

  useEffect(() => {
    setGroupPastosPorCocho();
  }, [fazendaSelecionada, pastos]);

  useEffect(() => {
    setCentroMap(coordenadasCentro);
  }, [coordenadasCentro]);

  useEffect(() => {
    if (visualizarPontosColetas) {
      obterColetas();
    }
  }, [visualizarPontosColetas, periodoColeta, pastosEnriquecidos]);

  useEffect(() => {
    setColetas([]);
  }, [fazendaSelecionada]);

  const setGroupPastosPorCocho = () => {
    const groupsPastosPorCocho = _.groupBy(pastos, (p) =>
      p.Cocho && p.Cocho.PastoCocho ? p.Cocho.PastoCocho.IdCocho : null
    );

    setPastosPorCocho({ ...groupsPastosPorCocho });
  };

  const enriquecePastosComFarol = () => {
    const farol = _.findLast(
      farois,
      (farol) => farol.IdFarol === farolSelecionado
    );
    if (farol) {
      const pastosEnriquecidos = pastos.map((p) => {
        const farolPasto = farol.Pasto.find((pasto) => pasto.IdPasto === p.IdPasto);
        var categoriasLotes;
        if (
          farolSelecionado === idsFarois.rebanhoPresenca ||
          farolSelecionado === idsFarois.sanidadeLivreDeParasitas ||
          farolSelecionado === idsFarois.sanidadeAnimaisComBicheira ||
          farolSelecionado === idsFarois.sanidadeAnimaisComOutrosProblemas
        ) {
          categoriasLotes = farol.Categorias;
        } else {
          categoriasLotes = null;
        }
        return { ...p, farolPasto, categoriasLotes };

      });
      setPastosEnriquecidos(pastosEnriquecidos);
    }
    else {
      setPastosEnriquecidos(pastos);
    }
  }

  const handleZoomChanged = () => {
    return function () {
      alteraZoomMapaFarol(this.zoom);
    };
  };

  const obterColetas = async () => {

    const obterCoordenadaPadrao = (pasto) => {
      if (pasto.CentroPasto && pasto.CentroPasto[0])
        return pasto.CentroPasto[0];

      return { lat: 0, lng: 0 };
    };

    const coletas = await ColetaService.obterColetasComGeolocalizacao(
      fazendaSelecionada,
      periodoColeta
    );

    const coletasEnriquecidas = coletas.data.map(({ Latitude = null,
      Longitude = null,
      IdPasto,
      CapturadaDentroDoPasto,
      ...coleta }) => {

      const color = getColorIconCollect(CapturadaDentroDoPasto, Latitude, Longitude);
      const pasto = pastosEnriquecidos.find((p) => p.IdPasto === IdPasto) || {};
      const url = `/images/marker-${color}.svg`;
      const position =
        Latitude && Longitude
          ? { lat: Latitude, lng: Longitude }
          : obterCoordenadaPadrao(pasto);

      return {
        CapturadaDentroDoPasto,
        ...coleta,
        pasto,
        gpsLigado: Latitude ? true : false,
        color,
        url,
        position
      };
    })
    if (coletasEnriquecidas.length > 0) {
      setColetas(coletasEnriquecidas);
    } else {
      setColetas([]);
    }
  };

  return (
    <MapaGoogleCustomizado
      coordenadasCentro={centroMap}
      onZoomChanged={handleZoomChanged()}
      HabilitaDesenho={false}
      zoomPadrao={zoomPadrao}
      mapTypeControl={false}
    >
      <MapaContent
        retiroSelecionado={retiroSelecionado}
        farolSelecionado={farolSelecionado}
        coletas={coletas}
        pastos={pastosEnriquecidos}
        farois={farois}
        TipoIconeExibido={TipoIconeExibido}
        zoomExibeIconePastoFarol={zoomExibeIconePastoFarol}
        forragens={forragens}
        TipoFarol={TipoFarol}
        pastosPorCocho={pastosPorCocho}
        periodoColeta={periodoColeta}
        visualizarPontosColetas={visualizarPontosColetas}
        setCentroMap={setCentroMap}
        atualizaZoom={atualizaZoom}
      />
    </MapaGoogleCustomizado >
  );
};

MapaFarol.propTypes = {
  coordenadasCentro: PropTypes.object.isRequired,
  pastos: PropTypes.array.isRequired,
  farois: PropTypes.array.isRequired,
  farolSelecionado: PropTypes.number,
  retiroSelecionado: PropTypes.number,
  TipoIconeExibido: PropTypes.string,
  fazendaSelecionada: PropTypes.number,
  visualizarPontosColetas: PropTypes.bool,
};

const mapDispatchToProps = {
  alteraZoomMapaFarol: farolActions.alteraZoomMapaFarol,
  obterTodasForragens: actions.obterTodasForragens,
  atualizaZoom: actions.setZoomMapa,
};

const mapStateToProps = (state) => ({
  fazendaSelecionada: state.fazenda.FazendaSelecionada,
  visualizarPontosColetas: state.mapa.visualizarPontosColetas,
  periodoColeta: state.farol.PeriodoColeta,
});

export default connect(mapStateToProps, mapDispatchToProps)(MapaFarol);
