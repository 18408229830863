import React from "react";
import LegendaAlturaForragem from "../legendaAlturaForragem";
import CustomMapButtons from "../customMapButtons";
import { SelectTypeOfCollection } from "../selectTypeOfCollection";
import { Title } from "../title";

const RenderSelectTypeOfCollection = ({ setFiltro, filtroColetas }) => {
  return (
    <div style={styles.selectWrapper}>
      <div style={styles.titlewrapper}>
        <Title>Pontos de coleta de altura</Title>
      </div>
      <SelectTypeOfCollection setFiltro={setFiltro} filtroColetas={filtroColetas} />
    </div>
  );
};

export function CustomControls({ showAllButons, setFiltro, filtroColetas }) {
  return (
    <div style={styles.container}>
      <div style={styles.wrapper}>
        <div style={styles.topButtonsWrapper}>
          <CustomMapButtons />
          {showAllButons && (
            <RenderSelectTypeOfCollection setFiltro={setFiltro} filtroColetas={filtroColetas} />
          )}
          {showAllButons && <LegendaAlturaForragem />}
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    position: "absolute",
    top: "24px",
    left: "24px",
    zIndex: "99",
    height: "calc(100% - 48px)",
    width: "calc(100% - 96px)",
    pointerEvents: "none",
  },
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  topButtonsWrapper: {
    display: "flex",
    alignitems: "flex-end",
    gap: "24px",
    pointerEvents: "all",
  },
  selectWrapper: {
    backgroundColor: "#fff",
    borderRadius: "4px",
    height: "56px",
    paddingBottom: "8px",
  },
  titlewrapper: {
    padding: "8px 10px 0",
  },
};
