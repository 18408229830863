import React, { useState } from "react";
import GoalPlanListContainer from "./goalPlanList/goalPlanListContainer";
import GoalPlanEditionContainer from "./goalPlanEdition/goalPlanEditionContainer";
import GoalsRegisterContainer from "./goalPlanRegister/goalPlanRegisterContainer";
import GoalPlanRevisionContainer from "./goalPlanRevision/goalPlanRevisionContainer";
import GoalPlanReviewContainer from "./goalPlanReview/goalPlanReviewContainer";
import GoalPlanCopyContainer from "./goalPlanCopy/goalPlanCopyContainer";

const SCREENS = {
  LIST: "list",
  CREATION: "creation",
  EDITION: "edition",
  REVISIONS: "revisions",
  REVIEW: "review",
  COPY: "copy",
};

const GoalPlanRouter = () => {
  const [screen, setScreen] = useState(SCREENS.LIST);
  const [state, setState] = useState({});

  function showCreation() {
    setScreen(SCREENS.CREATION);
    clearState();
  }

  function showEdition(goalPlanId) {
    setScreen(SCREENS.EDITION);
    setState({ goalPlanId });
  }

  function showRevisions(goalPlanId) {
    setScreen(SCREENS.REVISIONS);
    setState({ goalPlanId });
  }

  function showCopy(goalPlanId) {
    setScreen(SCREENS.COPY);
    setState({ goalPlanId });
  }

  function goBack() {
    setScreen(SCREENS.LIST);
    clearState();
  }

  function showReview(goalPlanId) {
    setScreen(SCREENS.REVIEW);
    setState({ goalPlanId });
  }

  function clearState() {
    setState({});
  }

  function renderScreen() {
    switch (screen) {
      case SCREENS.LIST:
        return (
          <GoalPlanListContainer
            showCreation={showCreation}
            showEdition={showEdition}
            showRevisions={showRevisions}
            showReview={showReview}
            showCopy={showCopy}
          />
        );
      case SCREENS.CREATION:
        return <GoalsRegisterContainer onBack={goBack} />;
      case SCREENS.EDITION:
        return (
          <GoalPlanEditionContainer
            goalPlanId={state.goalPlanId}
            onBack={goBack}
          />
        );
      case SCREENS.REVISIONS:
        return (
          <GoalPlanRevisionContainer
            onBack={goBack}
            revisionPlanId={state.goalPlanId}
          />
        );
      case SCREENS.REVIEW:
        return (
          <GoalPlanReviewContainer
            goalPlanId={state.goalPlanId}
            onBack={goBack}
          />
        );
      case SCREENS.COPY:
        return (
          <GoalPlanCopyContainer
            goalPlanId={state.goalPlanId}
            onBack={goBack}
          />
        );
      default:
        throw new Error(`Screen ${screen} not defined.`);
    }
  }

  return renderScreen();
};

export default GoalPlanRouter;
