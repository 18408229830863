import React, { Component } from "react";
import ModalGanhoPeso from "./loteModalGanhoPeso";
import { confereExistenciaDeDataPesagemIncorreto } from "../loteUtils";
import { Error } from "@material-ui/icons";
import * as _ from "lodash";
import { Grid, withStyles, Typography, Paper, Button } from "@material-ui/core";
import { getLotesFiltradosPorTexto, MESES_ANO } from "../loteUtils";
import moment from "moment";
import LoteListagemPrintTemplate from "./batchListingPrint/components/loteListagemPrintTemplate";
import BatchListingTableContainer from "./batchListingTable/batchListingTableContainer";
import BatchListingFilterContainer from "./batchListingFilter/batchListingFilterContainer";

export class LotesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lotesEstadoInicial: [],
      lotesFiltrados: [],
      desabilitaBotaoSalvar: false,
      displayModal: false,
      idsLotesParaAtualizar: [],
      registroSemPesoPesado: false,
      mensagemPesoObjetivo: false,
      pesoObjetivo: null,
      filtroTexto: "",
      filtroPesoSelecionado: "Todos",
      retiroSelecionado: "Todos",
      filtroPesoProjetadoSelecionado: "Todos",
      valorRetiroSelecionado: "Todos",
      categoriaTexto: "",
      valorFiltroPeso: "Todos",
      valorFiltroProjetado: "Todos",
      buscaRealizada: false,
    };
  }

  async componentDidMount() {
    const {
      listarRetiros,
      obterLotesReduzidosPorPastos,
      listaObjetivos,
      pastos,
      fazendaSelecionada,
      obterCurvaGanhoPeso,
      obtemPastosFazenda,
      obterCategoriasFazenda,
    } = this.props;
    await obterCategoriasFazenda();
    await Promise.all([
      obterLotesReduzidosPorPastos(pastos, fazendaSelecionada),
      listaObjetivos(fazendaSelecionada, false),
      listarRetiros(fazendaSelecionada),
      obterCurvaGanhoPeso(fazendaSelecionada),
      obtemPastosFazenda(fazendaSelecionada),
    ]);
    this.handleBuscaFinalizada();
    this.atualizaStateLotes();
    this.setLotesEstadoDataPesagem();
  }

  setValorRetiroSelecionado = (retiro) => {
    this.setState({
      valorRetiroSelecionado: retiro,
    });
  };

  setCategoriaTexto = (texto) => {
    this.setState({
      categoriaTexto: texto,
    });
  };

  setValorFiltroPeso = (filtroPeso) => {
    this.setState({
      valorFiltroPeso: filtroPeso,
    });
  };

  setValorFiltroProjetado = (filtroPesoProjetado) => {
    this.setState({
      valorFiltroProjetado: filtroPesoProjetado,
    });
  };

  handleBuscaFinalizada = () => {
    this.setState({ buscaRealizada: true });
  };

  setLotesEstadoDataPesagem = () => {
    let lotesComDataPesagem = this.props.lotes;
    const lotesFiltrados = lotesComDataPesagem.map((lote) => {
      lote.EstadoDataPesagem = true;
      return lote;
    });
    this.setState({
      lotesFiltrados,
      lotesEstadoInicial: [...lotesFiltrados],
    });
  };

  handleFaltaPesoMedido = (lotes) => {
    const loteSemLote = lotes.find((lote) => lote.PesoMedido === null);
    if (loteSemLote) {
      this.setState({
        registroSemPesoPesado: true,
      });
    } else {
      this.setState({
        registroSemPesoPesado: false,
      });
    }
  };

  handlerMensagemPesoObjetivo = (mensagem) => {
    this.setState({
      mensagemPesoObjetivo: mensagem,
    });
  };

  atualizaStateLotes = () => {
    if (this.state.lotesFiltrados && this.state.lotesFiltrados.length === 0) {
      this.setState({ lotesFiltrados: this.props.lotes });
      this.handleFaltaPesoMedido(this.state.lotesFiltrados);
    }
  };

  atualizaEstadoDataPesagem = (idLoteAlterado, value) => {
    let { lotesFiltrados } = this.state;
    _.forEach(lotesFiltrados, (lote) => {
      if (lote.IdLote === idLoteAlterado) {
        lote.EstadoDataPesagem = value;
      }
    });
    this.setState({ lotesFiltrados });
    this.atualizaBotaoSalvar();
  };

  atualizaBotaoSalvar = () => {
    const { lotesFiltrados } = this.state;
    let condicaoParaDesabilitarBotaoSalvar =
      confereExistenciaDeDataPesagemIncorreto(lotesFiltrados);
    this.setState({
      desabilitaBotaoSalvar: condicaoParaDesabilitarBotaoSalvar,
    });
  };

  showLoteModal = () => {
    this.setState({
      displayModal: true,
    });
  };

  hideLoteModal = () => {
    this.setState({
      displayModal: false,
    });
  };

  // fazer o cálculo da data que sera alcançado o peso aqui
  // achar curva ganho de peso da categoria do lote
  // quantos dias sao necessarios para o lote chegar no peso a partir do dia da pesagem
  // mudando o mes , pegar a curva de ganho de peso
  // deve ter o peso medido, data pesagem e curva ganho de peso
  // diferenca peso = peso objetivo - peso pesado
  // data objetivo = data pesagem + diferenca peso / curvaganhodepeso

  calculaDataParaPesoObjetivo = (loteAlterado, novoValor) => {
    const { categorias } = this.props;
    const categoriaAnimal = categorias.find(
      (categoria) =>
        categoria.IdCategoriaAnimal === loteAlterado.IdCategoriaAnimal
    );
    const curvaGanhoPeso = categoriaAnimal.CurvaGanhoPeso;
    if (!curvaGanhoPeso) return null;
    let pesoAnalisado = loteAlterado.PesoMedido;
    let dataAnalisada = moment(loteAlterado.DataPesagem);
    let maxIteracoes = 730;
    while (maxIteracoes > 0) {
      maxIteracoes--;
      dataAnalisada = dataAnalisada.add(1, "days");
      const mesDataAnalisada = dataAnalisada.month();
      const mesNomeAnalisado = MESES_ANO[mesDataAnalisada];
      const ganhoPesoPorCurva = curvaGanhoPeso[mesNomeAnalisado];
      pesoAnalisado += ganhoPesoPorCurva;
      if (maxIteracoes === 1) return null;
      if (pesoAnalisado >= novoValor) {
        return dataAnalisada.format("DD/MM/YY");
      }
    }
  };

  handleAlteraLote = (loteAlterado, campoAlterado, novoValorString) => {
    const { lotesFiltrados, idsLotesParaAtualizar } = this.state;
    const novoValor = Number(novoValorString) || novoValorString;
    if (
      campoAlterado === "PesoObjetivo" &&
      novoValor > loteAlterado.PesoMedido
    ) {
      this.calculaDataParaPesoObjetivo(loteAlterado, novoValor);
      this.setState({
        mensagemPesoObjetivo: true,
      });
    } else {
      this.setState({
        mensagemPesoObjetivo: false,
      });
    }
    const lotesAlterados = lotesFiltrados.map((lote) => {
      if (lote.IdLote === loteAlterado.IdLote) {
        const loteCopia = { ...lote };
        loteCopia[campoAlterado] = novoValor;
        return loteCopia;
      }
      return { ...lote };
    });

    this.setState({
      lotesFiltrados: lotesAlterados,
      idsLotesParaAtualizar: [...idsLotesParaAtualizar, loteAlterado.IdLote],
    });
    this.handleFaltaPesoMedido(lotesAlterados);
  };

  getWeightGainCurve = (batch) => {
    const { categorias } = this.props;
    if (!batch) return null;
    const animalCategory = categorias.find(
      (category) => category.IdCategoriaAnimal === batch.IdCategoriaAnimal
    );
    if (!animalCategory) return null;
    return animalCategory.CurvaGanhoPeso;
  };

  salvar = async () => {
    const {
      obterLotesReduzidosPorPastos,
      listaObjetivos,
      pastos,
      fazendaSelecionada,
    } = this.props;
    const { atualizarLotes } = this.props;
    const { idsLotesParaAtualizar, lotesFiltrados } = this.state;
    await atualizarLotes(idsLotesParaAtualizar, lotesFiltrados);
    this.setState({
      idsLotesParaAtualizar: [],
    });
    await obterLotesReduzidosPorPastos(pastos, fazendaSelecionada);
    await listaObjetivos(fazendaSelecionada);
    this.setLotesEstadoDataPesagem();

    this.setCategoriaTexto("");
    this.setValorRetiroSelecionado("Todos");
    this.setValorFiltroPeso("Todos");
    this.setValorFiltroProjetado("Todos");
  };

  filtrarLotesPorTexto = (texto, lotesEstadoInicial) => {
    const { filtroTexto } = this.state;
    let lotesFiltrados = lotesEstadoInicial;
    let textFiltro = texto !== "" ? texto : filtroTexto;

    //Filtro pelo texto digitado
    if (textFiltro) {
      lotesFiltrados = getLotesFiltradosPorTexto(lotesFiltrados, textFiltro);
      return lotesFiltrados;
    }
  };

  // Filtro por Peso Pesado
  filtrarPorPesoPesado = (tipo, lotesEstadoInicial) => {
    let lotesFiltradosPesoPesado = lotesEstadoInicial;
    if (tipo === "Informado") {
      lotesFiltradosPesoPesado = lotesEstadoInicial.filter(
        (lote) => lote.PesoMedido !== null && lote.PesoMedido > 0
      );
    } else if (tipo === "Não Informado") {
      lotesFiltradosPesoPesado = lotesEstadoInicial.filter(
        (lote) => !lote.PesoMedido
      );
    }
    return lotesFiltradosPesoPesado;
  };

  // Filtro por Peso Projetado
  filtrarPorPesoProjetado = (tipo, lotesEstadoInicial) => {
    let lotesFiltradosPesoProjetado = [...lotesEstadoInicial];
    if (tipo === "Disponível") {
      lotesFiltradosPesoProjetado = lotesEstadoInicial.filter(
        (lote) => lote.PesoProjetado !== null && lote.PesoProjetado > 0
      );
    } else if (tipo === "Não disponível") {
      lotesFiltradosPesoProjetado = lotesEstadoInicial.filter(
        (lote) => !lote.PesoProjetado
      );
    }
    return lotesFiltradosPesoProjetado;
  };

  // Filtro por Retiro
  filtrarPorRetiro = (lotes, valorRetiroSelecionado) => {
    const { retiros, pastos } = this.props;
    const retiroSelecionado = retiros.find(
      (retiro) =>
        retiro.Nome.toLowerCase() === valorRetiroSelecionado.toLowerCase()
    );
    const listaPastoRetiro = pastos.filter(
      (pasto) => pasto.IdRetiro === retiroSelecionado.IdRetiro
    );
    const lotesFiltrado = lotes.filter((lote) =>
      listaPastoRetiro.some((pasto) => pasto.IdPasto === lote.IdPasto)
    );
    return lotesFiltrado;
  };

  filtroGeral = (filtro) => {
    const {
      categoriaTexto,
      valorFiltroPeso,
      valorFiltroProjetado,
      valorRetiroSelecionado,
    } = filtro;

    const { lotesEstadoInicial } = this.state;

    let lotesFiltradosUp;
    let lotesFiltradosUp2;
    let lotesFiltradosUp3;
    let lotesFiltradosUp4;

    // Filtrar por Texto
    if (categoriaTexto !== "") {
      lotesFiltradosUp = this.filtrarLotesPorTexto(
        categoriaTexto,
        lotesEstadoInicial
      );
    } else {
      lotesFiltradosUp = lotesEstadoInicial;
    }

    // Filtrar por Peso Pesado
    if (valorFiltroPeso !== "Todos") {
      lotesFiltradosUp2 = this.filtrarPorPesoPesado(
        valorFiltroPeso,
        lotesFiltradosUp ? lotesFiltradosUp : lotesEstadoInicial
      );
    } else {
      lotesFiltradosUp2 = lotesFiltradosUp
        ? lotesFiltradosUp
        : lotesEstadoInicial;
    }

    // Filtrar por Peso Projetado
    if (valorFiltroProjetado !== "Todos") {
      const lotef = lotesFiltradosUp2
        ? lotesFiltradosUp2
        : lotesFiltradosUp
        ? lotesFiltradosUp
        : lotesEstadoInicial;
      lotesFiltradosUp3 = this.filtrarPorPesoProjetado(
        valorFiltroProjetado,
        lotef
      );
    } else {
      lotesFiltradosUp3 = lotesFiltradosUp2
        ? lotesFiltradosUp2
        : lotesFiltradosUp
        ? lotesFiltradosUp
        : lotesEstadoInicial;
    }
    // Filtrar por Retiro
    if (valorRetiroSelecionado !== "Todos") {
      lotesFiltradosUp4 = this.filtrarPorRetiro(
        lotesFiltradosUp3,
        valorRetiroSelecionado
      );
    } else {
      lotesFiltradosUp4 = lotesFiltradosUp3
        ? lotesFiltradosUp3
        : lotesFiltradosUp2
        ? lotesFiltradosUp2
        : lotesFiltradosUp
        ? lotesFiltradosUp
        : lotesEstadoInicial;
    }
    this.setState({
      lotesFiltrados: lotesFiltradosUp4,
      filtroPesoSelecionado: valorFiltroPeso,
      filtroPesoProjetadoSelecionado: valorFiltroProjetado,
      filtroTexto: categoriaTexto,
      retiroSelecionado: valorRetiroSelecionado,
    });
  };

  render() {
    const { classes, objetivos, retiros, fazenda } = this.props;
    const {
      lotesFiltrados,
      registroSemPesoPesado,
      mensagemPesoObjetivo,
      filtroPesoSelecionado,
      filtroTexto,
      retiroSelecionado,
      filtroPesoProjetadoSelecionado,
      valorRetiroSelecionado,
      categoriaTexto,
      valorFiltroPeso,
      valorFiltroProjetado,
      lotesEstadoInicial,
      buscaRealizada,
    } = this.state;

    const naoTemLotes = buscaRealizada && lotesEstadoInicial.length === 0;
    const naoTemLotesComFiltro = buscaRealizada && lotesFiltrados.length === 0;

    const loteComPesoZerado = lotesFiltrados.some(
      (lote) => lote.PesoMedido === "0" || lote.PesoObjetivo === "0"
    );

    return (
      <React.Fragment>
        <Paper square className={classes.root}>
          <Grid container className={classes.containerPage}>
            <Typography className={classes.titulo} gutterBottom>
              Informações sobre os lotes da fazenda
            </Typography>
            <Grid
              container
              direction="row"
              alignItems="flex-end"
              alignContent="flex-start"
              className={classes.containerFiltrosBotao}
              item
              xs={12}
              md={12}
            >
              <BatchListingFilterContainer
                id="filtrosListaLote"
                retiroSelecionado={retiroSelecionado}
                setRetiroSelecionado={this.setRetiroSelecionado}
                filtroGeral={this.filtroGeral}
                filtroTexto={filtroTexto}
                retiros={retiros}
                filtroPesoSelecionado={filtroPesoSelecionado}
                filtroPesoProjetadoSelecionado={filtroPesoProjetadoSelecionado}
                componentRef={this.componentRef}
                valorRetiroSelecionado={valorRetiroSelecionado}
                setValorRetiroSelecionado={this.setValorRetiroSelecionado}
                categoriaTexto={categoriaTexto}
                setCategoriaTexto={this.setCategoriaTexto}
                valorFiltroPeso={valorFiltroPeso}
                setValorFiltroPeso={this.setValorFiltroPeso}
                valorFiltroProjetado={valorFiltroProjetado}
                setValorFiltroProjetado={this.setValorFiltroProjetado}
              />
              <div className={classes.botaoSalvarContainer}>
                <Button
                  id="botao-salvar"
                  variant="contained"
                  onClick={this.salvar}
                  color="primary"
                  className={classes.botaoSalvar}
                  disabled={
                    this.state.desabilitaBotaoSalvar || loteComPesoZerado
                  }
                >
                  Salvar
                </Button>
              </div>
            </Grid>
            {registroSemPesoPesado && (
              <Grid
                container
                alignItems={"center"}
                direction={"row"}
                xs={12}
                md={12}
                className={classes.menssageAlerta}
              >
                <Error fontSize="16" />
                <Typography className={classes.textoAlerta}>
                  Você possui lotes sem registro ou com valor zero de peso
                  pesado.
                </Typography>
              </Grid>
            )}
            <Grid container item xs={12} md={12}>
              <BatchListingTableContainer
                id="lotesTabela"
                lotes={lotesFiltrados}
                objetivos={objetivos}
                handleAlteraLote={this.handleAlteraLote}
                atualizaEstadoDataPesagem={this.atualizaEstadoDataPesagem}
                showLoteModal={this.showLoteModal}
                exibirColunaPesoProjetado={this.props.exibirColunaPesoProjetado}
                obterProjecaoSemestrePorLote={
                  this.props.obterProjecaoSemestrePorLote
                }
                exibirColunaSanidade={this.props.exibirColunaSanidade}
                exibirTrocaCategoria={this.props.exibirTrocaCategoria}
                mensagemPesoObjetivo={mensagemPesoObjetivo}
                calculaDataParaPesoObjetivo={this.calculaDataParaPesoObjetivo}
                handlerMensagemPesoObjetivo={this.handlerMensagemPesoObjetivo}
                getWeightGainCurve={this.getWeightGainCurve}
                naoTemLotes={naoTemLotes}
                naoTemLotesComFiltro={naoTemLotesComFiltro}
              />
            </Grid>
          </Grid>
        </Paper>
        <ModalGanhoPeso
          displayModal={this.state.displayModal}
          projecaoPesoSemestre={this.props.projecaoPesoSemestre}
          handleClose={this.hideLoteModal}
        />
        <div style={{ display: "none" }}>
          <LoteListagemPrintTemplate
            ref={(el) => (this.componentRef = el)}
            lotes={lotesFiltrados}
            fazenda={fazenda}
            objetivos={objetivos}
            exibirColunaPesoProjetado={this.props.exibirColunaPesoProjetado}
            exibirColunaSanidade={this.props.exibirColunaSanidade}
            exibirTrocaCategoria={this.props.exibirTrocaCategoria}
            filtroPesoSelecionado={filtroPesoSelecionado}
            filtroTexto={filtroTexto}
            retiroSelecionado={retiroSelecionado}
            filtroPesoProjetadoSelecionado={filtroPesoProjetadoSelecionado}
          />
        </div>
      </React.Fragment>
    );
  }
}

const styles = (theme) => {
  return {
    botaoSalvar: {
      borderRadius: "18px",
    },
    containerPage: {
      flexgrow: 1,
      height: "100%",
      backgroundColor: "#fff",
      width: "100%",
      paddingBottom: "2em",
    },
    containerFiltrosBotao: {
      marginBottom: 15,
      marginTop: 19,
      width: "100%",

      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    menssageAlerta: {
      color: "#ff0102",
      marginTop: 6,
      marginBottom: 16,
    },
    textoAlerta: {
      color: "#ff0102",
      fontSize: 12,
      fontFamily: "Roboto",
      marginLeft: 8,
    },
    root: {
      width: "100%",
      height: "100%",
      overflowX: "auto",
      padding: "46px",
      zIndex: -1,
      bottom: 0,
      top: "17%",
      minHeight: "calc(100vh - 90px)",
    },
    titulo: {
      fontSize: "20px",
      fontWeight: "bold",
      fontStyle: "medium",
      fontStretch: "medium",
      lineHeight: "medium",
      letterSpacing: "medium",
      color: "#333333",
      marginTop: "40px",
    },
    botaoSalvarContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
  };
};

export default withStyles(styles)(LotesPage);
