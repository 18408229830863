import { ID_USUARIO_AVISO_CONSULTORIA, DADOS_GA } from './avisoConsultoriaUtil';
import * as utils from '../../shared/utils';

export const salvarIdUsuarioAviso = idUsuarioAviso => {
  localStorage.setItem(ID_USUARIO_AVISO_CONSULTORIA, idUsuarioAviso);
};

export const obterIdUsuario = () => {
  return localStorage.getItem('IdUsuario');
};

export const enviarDadosGA = idUsuarioAviso => {
  utils.enviaDadosGA(DADOS_GA.acao, DADOS_GA.rotulo, DADOS_GA.categoria, Number(idUsuarioAviso));
};

export const obterFlagPrimeiraVsualizacao = idUsuario => {
  const idUsuarioAviso = localStorage.getItem(ID_USUARIO_AVISO_CONSULTORIA);
  const flagAvisoInexistente = idUsuarioAviso === 'undefined';
  const usuarioAvisoDiferente = idUsuarioAviso !== idUsuario;
  const primeiraVisualizacao = flagAvisoInexistente || usuarioAvisoDiferente;
  return primeiraVisualizacao;
};
