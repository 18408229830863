import * as relatoriosActions from './relatoriosActions';

export const INITIAL_STATE = {
  Relatorios: [],
  Pastas: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case relatoriosActions.LISTA_RELATORIOS:
      return { ...state, Relatorios: action.payload.Relatorios };
    case relatoriosActions.LISTA_PASTAS_USUARIO:
      return { ...state, Pastas: action.payload.Pastas };
    default:
      return state;
  }
};
