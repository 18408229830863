import BalancoArrobaService from "../balancoArrobaService";
import FazendaService from "../../../services/FazendaService";

import { notifyError } from "../../../redux/actions/notificacaoActions";
import {
  listarDatasPesagem,
  exibirModalFaltaInformacao,
  exibirModalFaltaDataInicial,
  obterDadosPeriodoBalanco,
  exibirCarregando,
} from "./balancoArrobaActions";

export const obterDatas = () => async (dispatch, getState) => {
  dispatch(exibirCarregando(true));
  try {
    const { fazenda } = getState();
    const exibirModal = await validaDataInicioConciliacao(
      fazenda.FazendaSelecionada
    );
    const obtemDatasPesagem = await BalancoArrobaService.obterDatasPesagem(
      fazenda.FazendaSelecionada
    );
    const datasPesagem = obtemDatasPesagem.data.items;
    if (!exibirModal) {
      dispatch(exibirModalFaltaDataInicial(true));
    }
    if (datasPesagem.length < 2) {
      dispatch(exibirModalFaltaInformacao(true));
    } else {
      const ultimaDataPesagem =
        datasPesagem[datasPesagem.length - 1].DataPesagem;
      const penultimaDataPesagem =
        datasPesagem[datasPesagem.length - 2].DataPesagem;
      dispatch(listarDatasPesagem(datasPesagem));
      dispatch(obterDadosBalanco(penultimaDataPesagem, ultimaDataPesagem));
    }
  } catch (error) {
    const mensagemErro = {
      mensagem: "Ocorreu um erro ao obter o balanço de arroba da fazenda",
    };
    dispatch(notifyError(mensagemErro));
  } finally {
    dispatch(exibirCarregando(false));
  }
};

export const validaDataInicioConciliacao = async (idFazenda) => {
  try {
    await FazendaService.checaDataConciliacao(idFazenda);
    return false;
  } catch (error) {
    return true;
  }
};

export const desativaModalFaltaData = () => (dispatch) => {
  dispatch(exibirModalFaltaDataInicial(false));
};

export const obterDadosBalanco =
  (dataInicio, dataFim) => async (dispatch, getState) => {
    dispatch(exibirCarregando(true));
    try {
      const { fazenda } = getState();
      const balancosFazenda = await BalancoArrobaService.obterDadosBalanco(
        fazenda.FazendaSelecionada,
        dataInicio,
        dataFim
      );
      dispatch(obterDadosPeriodoBalanco(balancosFazenda.data));
    } catch (error) {
      const mensagemErro = {
        mensagem: "Ocorreu um erro ao obter o balanço de arroba da fazenda.",
      };
      dispatch(obterDadosPeriodoBalanco({}));
      dispatch(notifyError(mensagemErro));
    } finally {
      dispatch(exibirCarregando(false));
    }
  };
