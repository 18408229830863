export const ID_USUARIO_AVISO_CONSULTORIA = 'id_usuario_aviso_consultoria';
export const DADOS_GA = {
  acao: `Modal aviso consultoria`,
  categoria: 'Aviso Consultoria',
  rotulo: 'AvisoConsultoria',
  valor: ''
};

export const obterDadosAvisoConsultoria = () => {
  const textoBotao = 'OK, ENTENDI!';
  const imagem = 'prodap-views.png';
  const titulo = 'Aviso Importante';
  const texto = `O presente software oferece sugestão para equilíbrio entre lotação e capacidade de suporte das pastagens. Não se trata o conteúdo aqui oferecido de recomendação, mas apenas de oferecimento de parâmetro para suporte das pastagens. Estes parâmetros variam de
    acordo com a curva de crescimento dos animais, estado fisiológico da planta, condição geral das forragens, área efetivamente empastada, potencial de produção forrageiro, fertilidade do solo, precipitação pluviométrica e luminosidade. Portanto, a decisão sobre
    o manejo a ser aplicado é do próprio pecuarista que deverá estabelecer a lotação animal adequada a realidade constatada na fazenda.`;
  return { imagem, titulo, texto, textoBotao };
};
