import {
  SALVAR_COCHO,
  ERRO_VALIDAR_COCHO,
  SALVAR_COCHO_COMPARTILHADO,
  SELECIONA_COCHO_COMPARTILHADO,
  EXCLUI_COCHO_COMPARTILHADO,
  LISTA_PASTOS,
  DESELECIONA_COCHO_COMPARTILHADO,
  LIMPAR_NOVO_COCHO,
  NOVO_COCHO,
} from "redux/actions/actionsConstants";
import PastoService from "services/PastoService";

import trataErroGenerico from "lib/trataErroGenerico";
import { createServiceHandler } from "services/ServiceHandler";

export const selecionaCochoCompartilhado = (cocho) => ({
  type: SELECIONA_COCHO_COMPARTILHADO,
  payload: cocho,
});
export const deselecionaCochoCompartilhado = () => ({
  type: DESELECIONA_COCHO_COMPARTILHADO,
});
export const novoCocho = () => ({ type: NOVO_COCHO });

function infoBasicaCocho(cocho) {
  return {
    Coberto: cocho.Coberto,
    Comprimento: cocho.Comprimento,
    Elevado: cocho.Elevado,
    EstoqueFinal: cocho.EstoqueFinal,
  };
}

function infoBasicaCochoCompartilhado(
  cocho,
  cor,
  pastosSelecionados,
  idFazenda
) {
  return {
    Cocho: {
      Coberto: cocho.Coberto,
      Comprimento: cocho.Comprimento,
      Elevado: cocho.Elevado,
      EstoqueFinal: cocho.EstoqueFinal,
      Cor: cor,
      IdFazenda: idFazenda,
    },
    IdsPastos: pastosSelecionados.map((pasto) => {
      return pasto.IdPasto;
    }),
  };
}

function trataErro(dispatch, error) {
  trataErroGenerico(
    dispatch,
    error,
    "Ocorreu um erro ao tentar salvar o Cocho.",
    ERRO_VALIDAR_COCHO,
    "Erro ao salvar o Cocho."
  );
}

/**
 * FIXME: Retirar chamadas pra serviços deste local
 * O service deve ser o responsável por fazer requisições
 **/
export const criarCocho = (cocho) => async (dispatch) => {
  try {
    const data = infoBasicaCocho(cocho);
    //FIXME: Passar método para service
    const res = await createServiceHandler().post(
      `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/pastos/${cocho.IdPasto}/cocho`,
      data
    );
    dispatch({
      type: SALVAR_COCHO,
      payload: { ...res.data },
    });
  } catch (error) {
    trataErro(dispatch, error);
  }
};

export const criarCochoCompartilhado =
  (cocho, cor, pastosSelecionados, idFazenda) => async (dispatch) => {
    try {
      const data = infoBasicaCochoCompartilhado(
        cocho,
        cor,
        pastosSelecionados,
        idFazenda
      );

      const res = await createServiceHandler().post(
        `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/cochos`,
        data
      );

      const pastos = await PastoService.listaPastosPorIdFazenda(
        idFazenda,
        [],
        ["Modulo", "Cocho"]
      );

      dispatch({ type: LISTA_PASTOS, payload: { Pastos: pastos.data } });
      dispatch({ type: SALVAR_COCHO_COMPARTILHADO, payload: { ...res.data } });
      dispatch({ type: LIMPAR_NOVO_COCHO });
    } catch (error) {
      trataErro(dispatch, error);
    }
  };

export const excluiCochoCompartilhado =
  (idCochoCompartilhado, idFazenda) => async (dispatch) => {
    try {
      await createServiceHandler().delete(
        `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/cochos/${idCochoCompartilhado}`
      );
      const pastos = await PastoService.listaPastosPorIdFazenda(
        idFazenda,
        [],
        ["Modulo", "Cocho"]
      );

      dispatch({ type: LISTA_PASTOS, payload: { Pastos: pastos.data } });
      dispatch({ type: EXCLUI_COCHO_COMPARTILHADO });
      dispatch({ type: DESELECIONA_COCHO_COMPARTILHADO });
    } catch (error) {
      trataErro(dispatch, error);
    }
  };
