import { EXIBIR_CARREGANDO } from "../../../redux/actions/actionsConstants";
export const LISTA_MOVIMENTACOES = "LISTA_MOVIMENTACOES";
export const EXCLUIR_MOVIMENTACAO = "EXCLUIR_MOVIMENTACAO";
export const SALVAR_MOVIMENTACAO = "SALVAR_MOVIMENTACAO";
export const EDITAR_MOVIMENTACAO = "EDITAR_MOVIMENTACAO";

export const listarMovimentacoesFazenda = (movimentacoes) => {
  return {
    type: LISTA_MOVIMENTACOES,
    payload: { MovimentacoesFazenda: movimentacoes },
  };
};
export const exibirCarregando = (exibir) => {
  return {
    type: EXIBIR_CARREGANDO,
    payload: exibir,
  };
};

export const editarMovimentacao = () => {
  return {
    type: EDITAR_MOVIMENTACAO,
  };
};

export const salvarMovimentacao = (movimentacao) => {
  return {
    type: SALVAR_MOVIMENTACAO,
    payload: movimentacao,
  };
};

export const excluirMovimentacao = () => {
  return {
    type: EXCLUIR_MOVIMENTACAO,
  };
};
