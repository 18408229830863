import React, { useState } from "react";
import {
  Divider,
  IconButton,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  ListItemAvatar,
  ListItem,
  List,
  TextField,
} from "@material-ui/core";
import {
  Delete as DeleteIcon,
  Waves as RetiroIcon,
  Edit as EditIcon,
  Check as CheckIcon,
  Clear as ClearIcon,
} from "@material-ui/icons";
import {
  ordenaListaRetiros,
  estadoInicialListaRetiro,
  atualizaCampo,
} from "../retiroUtils";

export const ListaRetiro = (props) => {
  const { classes, listaRetiros, abreDialogExclusao, editaRetiro } = props;

  const listaRetirosOrdenadosPorId = ordenaListaRetiros(listaRetiros);
  const { valorCampoEditar, valorNovoNome, retiroSelecionadoEditar } =
    estadoInicialListaRetiro();

  const [campoEditar, trocaCampoEditar] = useState(valorCampoEditar);
  const [retiroEditado, trocaRetiroEditado] = useState(retiroSelecionadoEditar);
  const [novoNome, trocaNovoNome] = useState(valorNovoNome);

  const salvaNomeRetiro = (event) => {
    trocaNovoNome(event.target.value);
  };

  return (
    <List className={classes.listaRetiro} disablePadding>
      {listaRetirosOrdenadosPorId
        ? listaRetirosOrdenadosPorId.map((retiro, j) => {
            return [
              <ListItem id={`retiro-${j}`} disableGutters>
                <ListItemAvatar>
                  <Avatar className={classes.corAvatar}>
                    <RetiroIcon />
                  </Avatar>
                </ListItemAvatar>
                {atualizaCampo(campoEditar, retiroEditado.Nome, retiro.Nome) ? (
                  <div>
                    <TextField
                      placeholder="Novo nome"
                      className={classes.campoEditar}
                      onChange={salvaNomeRetiro}
                      defaultValue={retiro.Nome}
                    />
                    <ListItemSecondaryAction
                      className={classes.listItemSecondary}
                    >
                      <IconButton aria-label="Edita">
                        <CheckIcon
                          className={classes.iconeEditar}
                          onClick={() => {
                            editaRetiro(retiro, novoNome);
                            trocaCampoEditar(false);
                            trocaRetiroEditado("");
                          }}
                        />
                      </IconButton>
                      <IconButton aria-label="Deleta">
                        <ClearIcon
                          className={classes.iconeCancelarEdicao}
                          onClick={() => {
                            trocaCampoEditar(false);
                            trocaRetiroEditado("");
                          }}
                        />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </div>
                ) : (
                  <div>
                    <ListItemText
                      className={classes.textoRetiro}
                      primary={retiro.Nome}
                    />
                    <ListItemSecondaryAction
                      className={classes.listItemSecondary}
                    >
                      <IconButton aria-label="Edita">
                        <EditIcon
                          onClick={() => {
                            trocaCampoEditar(true);
                            trocaRetiroEditado(retiro);
                          }}
                        />
                      </IconButton>
                      <IconButton aria-label="Deleta">
                        <DeleteIcon
                          onClick={() => abreDialogExclusao(retiro)}
                        />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </div>
                )}
              </ListItem>,
              <Divider id={`divisor-${j}`} className={classes.divisoria} />,
            ];
          })
        : null}
    </List>
  );
};

export default ListaRetiro;
