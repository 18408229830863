import BaseService from "../../services/BaseService";
import { removeUndefinedEntries } from "../../shared/utils";

const PRODUCT_URL = `${process.env.REACT_APP_ENDPOINT}supplementations/`;

class ProductService extends BaseService {
  async getAvailableProducts(farmId, filter, page, size) {
    const newFilter = removeUndefinedEntries({ farmId, page, size, filter });
    const queryParams = new URLSearchParams(newFilter);

    const urlProducts = this.montaURL(
      `${PRODUCT_URL}products/available`,
      queryParams.toString()
    );
    const availableProducts = await this.get(urlProducts);

    if (
      !availableProducts ||
      !availableProducts.data ||
      !availableProducts.data.data
    ) {
      throw new Error("Não foram encontrados produtos disponíveis");
    }

    return availableProducts.data.data;
  }

  async getAllProducts(filter, page, size) {
    const newFilter = removeUndefinedEntries({ filter, page, size });
    const queryParams = new URLSearchParams(newFilter);
    queryParams.append("group", "code");

    const urlAllProducts = this.montaURL(
      `${PRODUCT_URL}products/`,
      queryParams.toString()
    );

    const allProducts = await this.get(urlAllProducts);

    if (!allProducts || !allProducts.data || !allProducts.data.data) {
      throw new Error("Não foram encontrados todos os Produtos");
    }

    return allProducts.data.data;
  }
}

export default new ProductService();
