import {
  LISTAR_BALANCOS_FAZENDA,
  LISTAR_DATAS_PESAGEM,
  ABRIR_MODAL_FALTA_INFORMACAO,
  ABRIR_MODAL_FALTA_DATA_CONCILIACAO
} from './balancoArrobaActions';

export const INITIAL_STATE = {
  Balancos: {},
  data: [],
  exibirModalFaltaInformacao: false,
  exibirModalFaltaDataInicial: false,
};

export default function balancoArrobaReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LISTAR_BALANCOS_FAZENDA:
      return {
        ...state,
        Balancos: action.payload.BalancosFazenda
      };
    case LISTAR_DATAS_PESAGEM:
      return {
        ...state,
        data: action.payload
      };
    case ABRIR_MODAL_FALTA_INFORMACAO:
      return {
        ...state,
        exibirModalFaltaInformacao: action.payload
      };
    case ABRIR_MODAL_FALTA_DATA_CONCILIACAO:
      return {
        ...state,
        exibirModalFaltaDataInicial: action.payload
      };
    default:
      return state;
  }
}
