import React, { useCallback } from "react";
import { withStyles } from "@material-ui/core";
import PageTitle from "../shared/pageTitle";
import SupplementationFormCard from "../supplementationEdition/components/supplementationFormCard";
import SupplementationHistory from "./components/supplementationHistory";

const SupplementationDetailPage = ({
  supplementation,
  supplementationId,
  classes,
  history,
}) => {
  const getTitle = () => {
    if (supplementation && supplementation.deleted === undefined) {
      return "Visualização de coleta";
    }
    return supplementation.deleted
      ? "Visualização de coleta excluída"
      : "Visualização de coleta ativa";
  };

  const onGoBack = useCallback(() => {
    if (supplementation.deleted) {
      return history.push("/coletas/suplementacao/excluidas");
    }
    history.push("/coletas/suplementacao");
  }, [supplementation.deleted, history]);

  return (
    <div className={classes.container}>
      <PageTitle title={getTitle()} onGoBack={onGoBack} />
      <SupplementationFormCard
        supplementationFetched={supplementation}
        forceDisableAll={true}
      />
      <div className={classes.hr} />
      <div className={classes.titleHistoryContainer}>
        <h1 className={classes.pageTitle}>Histórico da coleta</h1>
      </div>

      <SupplementationHistory supplementationId={supplementationId} />
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    padding: "80px 40px 30px 40px",
  },
  titleHistoryContainer: {
    display: "flex",
    margin: "40px 0",
  },
  pageTitle: {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "bold",
    color: "#444",
  },
  hr: {
    width: "100%",
    height: "1px",
    backgroundColor: "#d7d4d4",
    margin: "40px 0",
  },
};

export default withStyles(styles)(SupplementationDetailPage);
