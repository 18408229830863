import React from "react";
import HistoricoChuvaPage from "./historicoChuvaPage";
import { connect } from "react-redux";
import * as HistoricoChuvaOperations from "../redux/historicoChuvaOperations";
import * as RetiroOperations from "../../retiro/redux/retiroOperations";
import * as actions from "redux/actions";

export const HistoricoChuvaContainer = (props) => {
  return (
    <HistoricoChuvaPage
      id="historicoChuvaPage"
      retiros={props.retiros}
      fazendaSelecionada={props.fazendaSelecionada}
      obtemHistoricoChuva={props.obtemHistoricoChuva}
      atualizaHistoricoChuva={props.atualizaHistoricoChuva}
      notifyInfo={props.notifyInfo}
      listaRetiros={props.listaRetiros}
    />
  );
};

export const mapStateToProps = (state) => {
  const { retiro, fazenda } = state;
  return {
    retiros: retiro.Retiros,
    fazendaSelecionada: fazenda.FazendaSelecionada,
  };
};

const mapDispatchToProps = {
  obtemHistoricoChuva: HistoricoChuvaOperations.obtemHistoricoChuva,
  atualizaHistoricoChuva: HistoricoChuvaOperations.atualizaHistoricoChuva,
  notifyInfo: actions.notifyInfo,
  listaRetiros: RetiroOperations.listarRetiros,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoricoChuvaContainer);
