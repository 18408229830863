import React from "react";
import { withStyles } from "@material-ui/core";
import ConfirmationModal from "features/shared/components/confirmationModal";
import { ReportOutlined } from "@material-ui/icons";

function ExitModal({ onSubmit, onCancel, classes }) {
  const titleComponent = (
    <div className={classes.title}>
      <ReportOutlined />
      As informações serão descartadas!
    </div>
  );

  return (
    <ConfirmationModal
      title={titleComponent}
      text="Ao sair da página sem salvar, as informações preenchidas serão perdidas. Tem certeza que deseja sair e descartar as informações?"
      submitButtonTitle="Sim, sair e descartar"
      cancelButtonClass={classes.cancelButtonClass}
      submitButtonClass={classes.submitButtonClass}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
}

const styles = () => ({
  title: {
    display: "flex",
    gap: "8px",
    "& svg": {
      fill: "#EB394B",
    },
  },
  cancelButtonClass: {
    border: "unset",
    color: "#333",
  },
  submitButtonClass: {
    border: "unset",
    backgroundColor: "unset",
    color: "#EB394B",
  },
});

export default withStyles(styles)(ExitModal);
