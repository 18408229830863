import {
  ALTERA_ESTADO_DROPDOWN,
  ALTERA_ESTADO_TODOS_DROPDOWNS,
} from "features/manejo/redux/actions/uiActions";

export const initialState = {
  Dropdown: {
    Cliente: { ativo: true },
    Fazenda: { ativo: true },
    Retiro: { ativo: true },
    Mapa: { ativo: true },
  }
};

export default (state = initialState, action) => {
  let newDropdown = null;
  switch (action.type) {
    case ALTERA_ESTADO_DROPDOWN:
      newDropdown = null;
      newDropdown = {
        ...state.Dropdown,
        [action.payload.dropdown]: { ativo: action.payload.ativo },
      };
      return { ...state, Dropdown: newDropdown };
    case ALTERA_ESTADO_TODOS_DROPDOWNS:
      newDropdown = {};
      Object.keys(state.Dropdown).forEach((key) => {
        newDropdown[key] = {
          ...state.Dropdown[key],
          ativo: action.payload.ativo,
        };
      });
      return { ...state, Dropdown: newDropdown };
    default:
      return state;
  }
};
