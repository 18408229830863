import memoize from "memoize-one";
import _ from "lodash";

export const INCLUIR_FORMULAS_DELETADAS = true;
export const OBTER_FORMULAS_NAO_EXCLUIDAS = false;
export const OBTER_FORMULAS = true;

export const filterFormulaMemoize = memoize((list, textoFiltrar) =>
  _.filter(list, (formula) => {
    formula.Nome = formula.Nome || "";
    return formula.Nome.toUpperCase().includes(textoFiltrar.toUpperCase());
  })
);

export const ordenaFormulasPorNaoExcluida = (listaFormulas) => {
  return _.orderBy(listaFormulas, ["Excluido", "DataCriacao"], "asc");
};
export const ordenaFormulas = (listaFormulas) => {
  return listaFormulas.sort(function (a, b) {
    return (a.Nome > b.Nome) ? 1 : ((b.Nome > a.Nome) ? -1 : 0);
  });
};

export const validaCampoNumericoSemZero = (valor) => {
  return !valor.match(/^[0-9]+([,.][0-9]+)?$/g) || parseFloat(valor) === 0;
};

export const selecionaTipoFormulacao = (formula) => {
  const tipoFormulacao = formula.Granel ? "granel" : "saco";
  return formula ? tipoFormulacao : "saco";
};
export const exibePesoSaco = (formula) => {
  return formula ? !formula.Granel : true;
};
