import {
  CARREGAR_COORDENADAS_PASTOS,
  CRIAR_PASTO,
  ERRO_VALIDAR_PASTO,
  LISTA_PASTOS,
  LISTA_CURRAIS,
  SET_FAZENDA,
  SET_LOADING,
  DESELECIONAR_PASTO,
  SELECIONAR_PASTO,
  FOCAR_PASTO,
  DESFOCAR_PASTO,
  LIMPAR_COORDENADAS_PASTOS_KML,
  CADASTRO_PASTO_MODIFICADO,
  ERRO_SALVAR_PASTO,
  ERRO_EXCLUIR_PASTO,
  SALVAR_COORDENADAS_PASTO,
  DESENHAR_MAPA,
  LIMPAR_MAPA,
  LIMPAR_COORDENADAS,
  ATUALIZAR_LISTA_COORDENADAS_PASTO,
  EDITANDO_COORD_PASTO,
  EDITANDO_COORD_E_AREA_PASTO,
  GET_PASTURES_QUANTITY,
} from "redux/actions/actionsConstants";

export const initialState = {
  loading: true,
  EventoMapa: [],
  CoordenadasNovoPasto: [],
  CoordenadasEditandoPasto: [],
  PastoSelecionado: {
    IdPasto: null,
    IdRetiro: null,
    IdModulo: null,
    IdForragem: null,
    Selecionado: false,
  },
  Pastos: [],
  Currais: [],
  PastosKML: [],
  erros: {
    ExcluirModuloAlterandoRetiro: false,
    ExcluirModuloAoExcluirPasto: false,
    Pasto: {
      Nome: "",
      NomeReduzido: "",
      AreaAberta: "",
      AreaEfetiva: "",
      PotencialDeProducao: "",
    },
  },
  cadastroPastoModificado: false,
  Desenhando: false,
  AreaNovoPasto: null,
  pasturesQuantity: {},
};

export default (state = initialState, action) => {
  let Pastos;
  let PastosKML;

  switch (action.type) {
    case SET_FAZENDA:
      return { ...initialState };
    case CRIAR_PASTO:
      PastosKML = [];
      Pastos = action.payload;
      return {
        ...state,
        Pastos,
        PastosKML,
        PastoSelecionado: initialState.PastoSelecionado,
        erros: { ...state.erros, Pasto: { ...initialState.erros.Pasto } },
        AreaNovoPasto: null,
      };
    case ERRO_VALIDAR_PASTO:
      return {
        ...state,
        erros: {
          ...state.erros,
          Pasto: { ...initialState.erros.Pasto, ...action.payload },
        },
      };
    case ERRO_SALVAR_PASTO:
    case ERRO_EXCLUIR_PASTO:
      return {
        ...state,
        erros: {
          ...state.erros,
          ...action.payload,
        },
      };
    case LISTA_PASTOS:
      return {
        ...state,
        Pastos: action.payload.Pastos,
        loading: false,
        erros: initialState.erros,
      };
    case LISTA_CURRAIS:
      return {
        ...state,
        Currais: action.payload.Currais,
        erros: initialState.erros,
      };
    case CARREGAR_COORDENADAS_PASTOS:
      return { ...state, PastosKML: [...state.PastosKML, ...action.payload] };
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case DESELECIONAR_PASTO:
      return {
        ...state,
        PastoSelecionado: initialState.PastoSelecionado,
        erros: initialState.erros,
        AreaNovoPasto: null,
      };
    case SELECIONAR_PASTO:
      return { ...state, PastoSelecionado: { ...action.payload } };
    case FOCAR_PASTO:
      return {
        ...state,
        PastoSelecionado: {
          ...initialState.PastoSelecionado,
          Selecionado: true,
        },
      };
    case DESFOCAR_PASTO:
      return {
        ...state,
        PastoSelecionado: initialState.PastoSelecionado,
        erros: initialState.erros,
      };
    case LIMPAR_COORDENADAS_PASTOS_KML:
      return { ...state, PastosKML: [] };
    case CADASTRO_PASTO_MODIFICADO:
      return { ...state, cadastroPastoModificado: action.payload };
    case SALVAR_COORDENADAS_PASTO:
      return {
        ...state,
        CoordenadasNovoPasto: state.CoordenadasNovoPasto.concat(
          action.payload.Coordenadas
        ),
        EventoMapa: state.EventoMapa.concat(action.payload.Evento),
        Desenhando: action.payload.Desenhando,
        AreaNovoPasto: action.payload.AreaNovoPasto,
      };
    case DESENHAR_MAPA:
      return { ...state, Desenhando: action.payload.Desenhando };
    case LIMPAR_MAPA:
      return { ...state, EventoMapa: [] };
    case LIMPAR_COORDENADAS:
      return { ...state, CoordenadasNovoPasto: [] };
    case ATUALIZAR_LISTA_COORDENADAS_PASTO:
      return {
        ...state,
        CoordenadasNovoPasto: action.payload.Coordenadas,
        AreaNovoPasto: action.payload.AreaNovoPasto,
      };
    case EDITANDO_COORD_PASTO:
      return { ...state, CoordenadasEditandoPasto: action.payload.Coordenadas };
    case EDITANDO_COORD_E_AREA_PASTO:
      return {
        ...state,
        CoordenadasEditandoPasto: action.payload.Coordenadas,
        AreaNovoPasto: action.payload.Area,
      };
    case GET_PASTURES_QUANTITY:
      return {
        ...state,
        pasturesQuantity: action.payload.pasturesQuantity,
      };
    default:
      return state;
  }
};
