import React, { useState } from "react";
import { FormControl, TextField, withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import SelectDropdown from "components/SelectDropdown";

const OPCOES_SELECT_INPUT = [
  { value: 0, label: "TODAS" },
  { value: 1, label: "ATIVADAS" },
  { value: 2, label: "DESATIVADAS" },
];

const OPCOES_SELECT_INPUT_CURVA_GANHO_PESO = [
  { value: 0, label: "TODAS" },
  { value: 1, label: "SIM" },
  { value: 2, label: "NÃO" },
];

const CategoriaFiltro = (props) => {
  const {
    classes,
    desativado,
    handleChangeFiltroCategoria,
    handleChangeFiltroPorEstado,
    handleChangeFiltroPorCurva,
    categoriaStatus,
    curvaStatus,
  } = props;

  const { pathname } = props.location;

  const [estadoSelecionado, setEstadoSelecionado] = useState(categoriaStatus);
  const [estadoCurvaGanhoPesoSelecionado, setEstadoCurvaGanhoPesoSelecionado] =
    useState(curvaStatus);

  const handleEstadoCurva = (value) => {
    setEstadoCurvaGanhoPesoSelecionado(value);
    handleChangeFiltroPorCurva(value);
  };

  const handleEstado = (value) => {
    setEstadoSelecionado(value);
    handleChangeFiltroPorEstado(value);
  };

  return (
    <FormControl
      id="formBuscar"
      disabled={desativado}
      className={classes.textoBuscar}
    >
      <TextField
        id="buscarCategoria"
        autoFocus
        label={"Buscar categorias"}
        inputProps={{
          style: {
            paddingBottom: "12px",
          },
        }}
        style={{ width: "400px" }}
        onChange={handleChangeFiltroCategoria}
      />
      <div className={classes.selectInput}>
        <SelectDropdown
          id="categorias-select"
          label={{ text: "Situação da Categoria" }}
          options={OPCOES_SELECT_INPUT}
          value={estadoSelecionado}
          onChange={handleEstado}
        />
      </div>
      {pathname !== "/controleDeRebanho" && (
        <div className={classes.selectInput}>
          <SelectDropdown
            id="curva-ganho-peso-select"
            label={{ text: "Curva de Ganho de Peso" }}
            options={OPCOES_SELECT_INPUT_CURVA_GANHO_PESO}
            value={estadoCurvaGanhoPesoSelecionado}
            onChange={handleEstadoCurva}
          />
        </div>
      )}
    </FormControl>
  );
};

const styles = {
  textoBuscar: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "row",
  },
  selectInput: {
    maxWidth: "200px",
    width: "100%",
    marginLeft: 32,
  },
};

export default withRouter(withStyles(styles)(CategoriaFiltro));
