import React from "react";
import TabelaProdap from "features/shared/components/TabelaProdap";
import { planningTableViewColumns } from "./planningTableViewColumns";
import { withStyles } from "@material-ui/core";
import { WarningAmberOutlined } from "@mui/icons-material";
import { tableStyles } from "./tableStyle";
import TableDisclimer from "./TableDisclimer";

function PlanningReportTable({
  planningsTableList,
  total,
  categories,
  formulas,
  periodTitles,
  classes,
  warning,
  isPrinting,
  tabelaEsquerda = true,
}) {
  const lines =
    planningsTableList && total ? [...planningsTableList, total] : [];

  return (
    <div
      className={`${classes.tableContent} ${classes.reportTableContent}`}
      data-testId="Plannings-Report-Table"
    >
      {warning && (
        <TableDisclimer
          text={warning}
          icon={<WarningAmberOutlined />}
          iconColor={"#F69B17"}
        />
      )}

      <TabelaProdap
        className={classes.tableContainer}
        hover={false}
        isPrinting={isPrinting}
        idCabecalhoTabela={"planningsTableHead"}
        colunas={planningTableViewColumns({
          categories,
          formulas,
          periodTitles,
        })}
        ativarPaginacao={false}
        linhas={lines}
        tabelaEsquerda={tabelaEsquerda}
        noPaper={true}
      />
    </div>
  );
}

const styles = (theme) => ({
  ...tableStyles(theme),

  reportTableContent: {
    "& tbody > tr:last-child": {
      borderTop: "16px solid #fff",
      background: "#fafafa",
      minHeight: "60px",
    },
    "& tbody > tr:last-child:hover": {
      background: "#fafafa !important",
    },
    "& tr th:first-of-type, & tr td:first-of-type": {
      minWidth: "160px",
    },
    "& tr td:first-of-type > div": {
      margin: "0px",
    },
    "& td": {
      color: "#757575",
    },
  },
});

export default withStyles(styles)(PlanningReportTable);
