import LoteService from "services/LoteService";
import { BUSCA_ENTREVERO } from "redux/actions/actionsConstants";
import trataErroGenerico from "lib/trataErroGenerico";

function trataErro(dispatch, error) {
  trataErroGenerico(
    dispatch,
    error,
    "Ocorreu um erro ao buscar informação dos lotes",
    "",
    "Ocorreu um erro ao buscar informação dos lotes"
  );
}

export const buscaLotesEntrevero = () => async (dispatch, getState) => {
  const { fazenda } = getState();
  try {
    const lotesRetornados = await LoteService.obtemLotesEntrevero(
      fazenda.FazendaSelecionada
    );
    dispatch({
      type: BUSCA_ENTREVERO,
      payload: { lotesComEntrevero: lotesRetornados.data },
    });
  } catch (error) {
    dispatch({
      type: BUSCA_ENTREVERO,
      payload: { lotesComEntrevero: [] },
    });
    trataErro(dispatch, error);
  }
};
