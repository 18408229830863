import React from "react";
import { connect } from "react-redux";
import shortid from "shortid";
import { renderTextOrComponent } from "./MapaFarol/mapaFarolUtil";
import { withStyles } from "@material-ui/core";
import { OverlayViewF } from "@react-google-maps/api";
import mapaConstants from "constants/mapaFarolAlturaPasto";
export const Tooltip = ({ classes, Posicao, Texto, adornmentColor, title, visualizarPontosColetas }) => {

  const classWrapper = visualizarPontosColetas ? classes.newDefaultWrapper : classes.defaultWrapper;

  const TextComponent =
    typeof renderTextOrComponent(Texto) === "string"
      ? () => (
        <>
          {title ? (
            <span className={classes.content}>
              <span className={classes.title}>
                {adornmentColor && (
                  <>
                    <div
                      className={classes.titleAdornment}
                      style={{
                        backgroundColor: "#24e9de",
                      }}
                    />
                    {title}
                  </>
                )}
              </span>
              <br />
            </span>
          ) : null}
          <b>
            {Texto.split("\n").map(function (item, index) {
              return (
                <span key={`${item}-${index}`}>
                  {item}
                  <br />
                </span>
              );
            })}
          </b>
        </>
      )
      : renderTextOrComponent(Texto);

  return (
    <React.Fragment>
      <OverlayViewF
        key={shortid.generate()}
        position={Posicao}
        mapPaneName={mapaConstants.google_maps.mapPaneName.overlayMouseTarget}
        getPixelPositionOffset={(width, height) => ({
          x: -(width / 2),
          y: -(height / 2),
        })}
      >
        <p
          className={classWrapper}
        >
          <TextComponent />
        </p>
      </OverlayViewF>
    </React.Fragment>
  );
};
const styles = () => {
  return {
    defaultWrapper: {
      opacity: 1,
      color: "#FFFFFFBB",
      fontSize: 10,
      borderRadius: 4,
      background: "#000000B3",
      padding: 4,
      textAlign: "left",
    },
    newDefaultWrapper: {
      opacity: 1,
      color: "#333333",
      fontSize: 12,
      borderRadius: 4,
      background: "#FFFFFF",
      padding: 8,
      textAlign: "left",
    },
    content: {
      flexDirection: "column",
      display: "flex",
      textAlign: "left",
    },
    title: {
      display: "flex",
      alignItems: "center",
    },
    titleAdornment: {
      width: "10px",
      height: "10px",
      margin: "2px",
      borderRadius: "50%",
    },
  };
};
export default withStyles(styles)(connect(null, null)(Tooltip));
