import React, { useEffect, useMemo, useState } from "react";
import { CircularProgress, withStyles } from "@material-ui/core";
import historicoMovimentacaoService from "../../../../historicoMovimentacaoApp/historicoMovimentacaoService";
import dayjs from "dayjs";
import { utc } from "dayjs";

import { ajustaDataComTimezoneFazenda, formatDateHour } from "shared/utils";
import { parseMovementsHistory } from "./pendencyDetailsUtils";
import { formatDateTime } from "./totalHerd";
dayjs.extend(utc);

const TotalValue = ({ total, children }) => {
  const totalIsNegative = total < 0;

  return (
    <span
      style={{
        backgroundColor: `${totalIsNegative ? "#FCE3E6" : "#E8F8F3"}`,
        display: "inline-block",
        padding: "3px 8px",
        borderRadius: "4px",
        color: `${totalIsNegative ? "#EB394B" : "#32A07D"}`,
        fontWeight: "700",
      }}
    >
      {children}
    </span>
  );
};

const isValidDate = (value) => !isNaN(new Date(value).getTime());

const MovementsHistory = ({
  classes,
  pasture,
  category,
  baseDate,
  numberPendency = 0,
  totalHerd = 0,
  timezoneFazenda = 0,
  showLoading = false,
}) => {
  const [movementHistory, setMovementHistory] = useState([]);
  const [localLoading, setLocalLoading] = useState(false);
  const getMovementHistory = async () => {
    if (pasture && category && baseDate) {
      try {
        setLocalLoading(true);
        const response =
          await historicoMovimentacaoService.listaHistoricoMovimentacaoFazenda(
            pasture.IdFazenda,
            0,
            100,
            {
              DataInicio: ajustaDataComTimezoneFazenda(
                baseDate,
                timezoneFazenda
              ).toISOString(),
              DataFinal: new Date(),
              IdPastoOrigem: [pasture.IdPasto],
              IdCategoriaOrigem: [category.IdCategoriaAnimal],
              IdPastoDestino: [pasture.IdPasto],
              IdCategoriaDestino: [category.IdCategoriaAnimal],
            },
            true
          );
        setMovementHistory(
          parseMovementsHistory(
            response.items,
            pasture,
            category,
            timezoneFazenda
          )
        );
      } catch (err) {
        console.error(err);
      } finally {
        setLocalLoading(false);
      }
    }
  };

  const formattedDate = formatDateTime(baseDate);
  const animalText = totalHerd === 1 ? "animal" : "animais";

  const finalSituation = useMemo(() => {
    const sumMovements = movementHistory.reduce(
      (acc, movement) => acc + movement.quantidade,
      0
    );

    return totalHerd + -1 * (numberPendency || 0) + sumMovements;
  }, [totalHerd, numberPendency, movementHistory]);

  useEffect(() => {
    getMovementHistory();
  }, [pasture, category, baseDate, timezoneFazenda]);

  const styleFinalSituation =
    finalSituation >= 0
      ? classes.listFinalSituation
      : classes.listFinalSituationNegative;

  let runningTotal = totalHerd - numberPendency;

  return (
    <>
      <div className={classes.cardContainerContent}>
        <div className={classes.cardContainer}>
          {(showLoading || localLoading) && (
            <div className={classes.verificationContainer}>
              <CircularProgress className={classes.progress} />
            </div>
          )}
          <div className={classes.pastureAndCategory}>
            <div className={classes.pastureAndCategoryHeader}>
              <span className={classes.infoText}>
                <span className={classes.labelTitle}>Pasto</span>{" "}
                {pasture ? pasture.Nome : ""}
              </span>
              <div className={classes.divider} />
              <span className={classes.infoText}>
                <span className={classes.labelTitle}>Categoria</span>{" "}
                {category ? category.Nome : ""}
              </span>
            </div>

            <span className={classes.infoText}>
              <span className={classes.labelTitle}>Situação do pasto: </span>{" "}
              {formattedDate && category && pasture && totalHerd !== null
                ? `No dia ${formattedDate}, havia ${totalHerd} ${animalText} da categoria ${category.Nome} no ${pasture.Nome}.`
                : ""}
            </span>
          </div>
          <div>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th className={classes.headerCell}>DATA</th>
                  <th className={classes.headerCell}>OCORRÊNCIA</th>
                  <th className={classes.headerCell}>QTD</th>
                  <th className={classes.headerCell}>TOTAL</th>
                </tr>
              </thead>
              <tbody className={classes.list}>
                <tr className={classes.listItem}>
                  <td className={classes.listDate}></td>
                  <td>Situação inicial</td>
                  <td
                    data-testid="initial-situation-number"
                    className={classes.textCenter}
                  >
                    {totalHerd}
                  </td>
                  <td>-</td>
                </tr>
                <tr className={classes.listPendencyItem}>
                  <td className={classes.listDatePendencyItem}>
                    {isValidDate(baseDate) &&
                      formatDateHour(dayjs.utc(baseDate)).replace("H", "")}
                  </td>
                  <td>Operação a ser aplicada</td>
                  <td className={classes.textCenter}>
                    {!Number.isNaN(numberPendency) && -numberPendency}
                  </td>
                  <td className={classes.textCenter}>
                    <TotalValue total={runningTotal}>
                      {!Number.isNaN(numberPendency) &&
                        totalHerd - numberPendency}
                    </TotalValue>
                  </td>
                </tr>

                {movementHistory.map((movement) => {
                  runningTotal = runningTotal + movement.quantidade;
                  return (
                    <tr className={classes.listItem}>
                      <td className={classes.listDate}>{movement.data}</td>
                      <td>{movement.tipo}</td>
                      <td className={classes.textCenter}>
                        {movement.quantidade}
                      </td>
                      <td className={classes.textCenter}>
                        <TotalValue total={runningTotal}>
                          {!Number.isNaN(runningTotal) && runningTotal}
                        </TotalValue>
                      </td>
                    </tr>
                  );
                })}

                <tr
                  className={styleFinalSituation}
                  data-testid="final-situation-line"
                >
                  <td className={classes.listDateFinalSituation}></td>
                  <td style={{ fontWeight: "700" }}>Situação final</td>
                  <td></td>
                  <td
                    data-testid="final-situation-number"
                    className={classes.textCenter}
                  >
                    <TotalValue total={finalSituation}>
                      {finalSituation}
                    </TotalValue>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = (theme) => ({
  titleText: {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "bold",
    color: "#444",
  },
  cardContainerContent: {
    position: "relative",
  },
  cardContainer: {
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2)",
    border: "1px solid #e5e5ea",
    backgroundColor: "#fff",
    position: "relative",
  },
  pastureAndCategory: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    rowGap: "10px",
    color: "#444444",
    fontSize: "16px",
    fontFamily: "Roboto",
  },
  labelTitle: {
    fontWeight: 400,
    letterSpacing: "0.5px",
    textTransform: "capitalize",
  },
  infoText: {
    fontWeight: 700,
  },
  table: {
    width: "100%",
    textAlign: "left",
    fontFamily: "Roboto",
    borderCollapse: "separate",
    borderSpacing: "0px 18px",
  },
  headerCell: {
    color: "#8E8E93",
    border: "1px solid #E5E5EA",
    padding: "10px 16px",
    fontSize: "12px",
    borderLeft: "1px solid #E5E5EA",
    fontWeight: 400,
    letterSpacing: "0.75px",
    backgroundColor: "#fafafa",
  },
  list: {
    listStyle: "none",
    padding: 0,
    position: "relative",
  },
  listItem: {
    "& td": {
      padding: "6px 16px",
    },
  },
  listPendencyItem: {
    backgroundColor: "#307297",
    color: "#fafafa",
    "& td": {
      padding: "6px 16px",
    },
  },
  listFinalSituation: {
    backgroundColor: "#fafafa",
    color: "#32A07D",
    "& td": {
      padding: "6px 16px",
    },
  },
  listFinalSituationNegative: {
    backgroundColor: "#fafafa",
    color: "#EB394B",
    "& td": {
      padding: "6px 16px",
    },
  },
  listDate: {
    position: "relative",
    background: "white",
    zIndex: 2,
    textAlign: "left",
    minWidth: "160px",
  },
  listDatePendencyItem: {
    position: "relative",
    zIndex: 2,
    textAlign: "left",
  },
  listDateFinalSituation: {
    position: "relative",
    zIndex: 2,
    backgroundColor: "#fafafa",
    textAlign: "center",
  },
  verificationContainer: {
    borderRadius: "8px",
    zIndex: 20,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    left: 0,
    backgroundColor: "rgba(255,255,255,0.49)",
    width: "100%",
    height: "100%",
  },
  textCenter: {
    textAlign: "center",
  },
  divider: {
    width: "1px",
    height: "16px",
    backgroundColor: "#E2E2E2",
  },
  pastureAndCategoryHeader: {
    display: "flex",
    columnGap: "16px",
  },
});

export default withStyles(styles)(MovementsHistory);
