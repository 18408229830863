import React from "react";
import { InfoOutlined } from "@material-ui/icons";
import TableDisclimer from "./TableDisclimer";
import TabelaProdap from "features/shared/components/TabelaProdap";
import { tableStyles } from "./tableStyle";
import { withStyles } from "@material-ui/core";
import { formatBRL } from "../utils";

const DISCLIMER_TEXT_MONTH_COST = <>
  <b>Custo mensal: </b> Esse cálculo é realizado da seguinte forma: custo cab/dia total da formulação * número dias mês
</>
  ;
const DISCLIMER_TEXT_WEIGHTED_AVERAGE = <>
  <b>Média ponderada: </b> Esse cálculo é realizado da seguinte forma: somatório de (quantidade de animais * custo mensal (cab/mês) por categoria) / quantidades de animais totais;
</>

function PlanningCategoryReportTable({
  planningCategoryReport,
  periodTitles,
  classes,
}) {
  const planningCategoryReportTableViewColumns = () => {
    const periodColumns = periodTitles.map((month, monthIndex) => ({
      id: month,
      titulo: month,
      ordenavel: false,
      pegaValor: (report) => (
        <span className={report.category ? "" : classes.cellItemBold}>
          {formatBRL(report.months[monthIndex] || 0)}
        </span>
      ),
    }));

    return [
      {
        id: "category",
        titulo: "Categoria",
        ordenavel: false,
        pegaValor: (report) =>
          report.category ? (
            <span>{report.category.name}</span>
          ) : (
            <span className={classes.cellItemBold}>Média Ponderada</span>
          ),
      },
      ...periodColumns,
      {
        id: "total",
        titulo: "Total Ano",
        ordenavel: false,
        pegaValor: (report) =>
          report.category ? (
            <span className={classes.cellItemBold}>
              {formatBRL(report.total || 0)}
            </span>
          ) : (
            <></>
          ),
      },
    ];
  };
  const itemsWithTotal = planningCategoryReport
    ? [...planningCategoryReport.categories, planningCategoryReport.weightedAverage]
    : [];

  return (
    <div className={classes.container}>
      <h1 className={classes.tableTitle}>
        Planejamento de custo mensal (cab/mês)
      </h1>
      <div
        className={`${classes.tableContent} ${classes.categoryReportTableContent}`}
        data-testId="Plannings-Category-Report-Table"
      >
        <TabelaProdap
          className={classes.tableContainer}
          idCabecalhoTabela={"planningCategoryReportTableHead"}
          colunas={planningCategoryReportTableViewColumns()}
          ativarPaginacao={false}
          linhas={itemsWithTotal}
          tabelaEsquerda={true}
          noPaper
        />
      </div>
      <TableDisclimer text={DISCLIMER_TEXT_MONTH_COST} icon={<InfoOutlined />} />
      <TableDisclimer text={DISCLIMER_TEXT_WEIGHTED_AVERAGE} icon={<InfoOutlined />} />
    </div>
  );
}

const style = (theme) => ({
  ...tableStyles(theme),
  categoryReportTableContent: {
    "& table": {
      "& tbody > tr:last-child": {
        background: "#fafafa",
        borderTop: "16px solid #fff",
      },
      "& tbody > tr:last-child:hover": {
        background: "#fafafa !important",
      },
    },
  },
});

export default withStyles(style)(PlanningCategoryReportTable);
