import React from "react";
import ConfirmationModal from "features/shared/components/confirmationModal";


const ModalNoFarmOnClient = ({
  classes,
  setIsModalShown,
  clienteSelecionado
}) => {

  return (
    <ConfirmationModal
      customClass={classes.customClass}
      submitButtonContainer={classes.submitButtonContainer}
      title={
        <span className={classes.modalTitle}>
          Você não possui fazenda vinculada!
        </span>
      }
      text={
        <span className={classes.modalText}>
          Para acessar a plataforma{" "}
          <span className={classes.modalTextInfo}>FarmTell Views</span>,
          é necessário ter ao menos{" "}
          <span className={classes.modalTextInfo}>
            uma fazenda cadastrada,
          </span>
          {" "}para o cliente selecionado. Por favor, entre em contato com nosso time de suporte para
          realizar o cadastro de uma fazenda para o cliente{" "}
          <span className={classes.modalTextInfo}>{clienteSelecionado.Nome}</span>
          , e ter acesso à plataforma.
          <br></ br>
          Contato:
          <span className={classes.modalTextInfo}>{" "}+55 11 3003{"–"}6045</span>
        </span>
      }
      submitButtonTitle="FECHAR"
      onSubmit={setIsModalShown}
      onClose={setIsModalShown}
    />
  );
}

export default ModalNoFarmOnClient;
