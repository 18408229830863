import {
  SET_RETIRO,
  LISTA_RETIROS,
  SET_COR_RETIRO,
  CRIAR_RETIROS,
  ATUALIZA_RETIRO,
  DELETA_RETIRO,
} from "../../../redux/actions/actionsConstants";
import { TODOS_OS_PASTOS } from "../../../constants/retiro";

export const initialState = {
  Retiros: [],
  RetiroSelecionado: TODOS_OS_PASTOS,
  DropdownBloqueado: false,
  RetirosCriados: [],
  Cor: "",
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CRIAR_RETIROS:
      return { ...state, RetirosCriados: action.payload.RetirosCriados };
    case SET_RETIRO:
      return { ...state, RetiroSelecionado: payload };
    case SET_COR_RETIRO:
      return { ...state, Cor: action.payload };
    case LISTA_RETIROS:
      return {
        ...state,
        Retiros: payload.Retiros,
        RetiroSelecionado: TODOS_OS_PASTOS,
      };
    case ATUALIZA_RETIRO:
      return { ...state, RetiroSelecionado: action.payload.retiro };
    case DELETA_RETIRO:
      return { state };
    default:
      return state;
  }
};
