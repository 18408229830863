import React, { Component } from "react";
import TableauReportFormv2 from "./geradorRelatorioV2/tableauReportFormv2";
import moment from "moment";
import { enviaDadosGAPorRotulo } from "../../../shared/utils.js";
import { FazendaSelecionadaContainer } from "../../shared/crossCuttingComponents";
import {
  dataMinimaRelatorios,
  dataMinimaRelatorioEspecifico,
} from "../../../constants/datasMinimas";

export class TableauReportPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urlArquivo: "",
      valInicial: moment().add(-1, "weeks").format("MM/DD/YYYY"),
      valFinal: moment().format("MM/DD/YYYY"),
      retiroSelecionado: -1,
      relatorio: "",
      formatoArquivo: "pdf",
      opacity: 1,
      downloadDisable: true,
      filtros: true,
      periodoCompleto: false,
    };
  }

  async componentDidUpdate(propsAnteriores) {
    const modalFoiAberto =
      propsAnteriores.modalAberto !== this.props.modalAberto &&
      this.props.modalAberto;
    if (modalFoiAberto) {
      await this.props.RelatoriosTableau();
    }
  }

  isNullOrWhitespace = (input) => {
    if (typeof input === "undefined" || input == null) return true;

    return false;
  };

  enableDownload = (valor = {}, disabled = false) => {
    const { relatorio, formatoArquivo, valInicial, valFinal } = this.state;
    const valorInicial = valor.valInicial ? valor.valInicial : valInicial;
    const valorFinal = valor.valFinal ? valor.valFinal : valFinal;
    const relatorioAtualizado = valor.relatorio ? valor.relatorio : relatorio;
    const formatoArquivoAtualizado = valor.formatoArquivo
      ? valor.formatoArquivo
      : formatoArquivo;
    const condicionalDataMinima = dataMinimaRelatorioEspecifico[
      relatorioAtualizado
    ]
      ? moment(valorInicial).isBefore(
          dataMinimaRelatorioEspecifico[relatorioAtualizado]
        )
      : moment(valorInicial).isBefore(dataMinimaRelatorios);
    if (
      this.isNullOrWhitespace(relatorioAtualizado) ||
      this.isNullOrWhitespace(formatoArquivoAtualizado) ||
      this.isNullOrWhitespace(valorInicial) ||
      this.isNullOrWhitespace(valorFinal) ||
      moment(valorInicial).isAfter(valorFinal) ||
      moment(valorFinal).isAfter(moment().endOf("day")) ||
      condicionalDataMinima ||
      disabled
    ) {
      return this.setState((state) => ({
        ...state,
        ...valor,
        downloadDisable: true,
      }));
    }
    this.setState((state) => ({ ...state, ...valor, downloadDisable: false }));
  };

  handleChangeRelatorios = ({ target: { value } }) => {
    this.enableDownload({
      relatorio: value,
      filtros: value.Filtro,
    });
  };

  setPeriodoCompleto = () => {
    const valInicial =
      dataMinimaRelatorioEspecifico[this.state.relatorio] ||
      dataMinimaRelatorios;
    const valFinal = moment().format("MM/DD/YYYY");
    this.enableDownload({
      periodoCompleto: !this.state.periodoCompleto,
      valInicial,
      valFinal,
    });
  };

  desativaPeriodoCompleto = () => {
    this.enableDownload({ periodoCompleto: false });
  };

  handleChangeFormato = (event) => {
    this.enableDownload({ formatoArquivo: event.target.value });
  };

  handleClose = () => {
    this.props.setModalTableau(false);
    this.setState({ retiroSelecionado: -1 });
  };

  selecionaRetiro = (retiro) => {
    this.setState({ retiroSelecionado: retiro });
  };

  handleChangeInicio = (event) => {
    const tempPeriodo = moment(event).format("MM/DD/YYYY");
    this.enableDownload({ valInicial: tempPeriodo });
  };

  handleChangeFinal = (event) => {
    const tempPeriodo = event.format("MM/DD/YYYY");
    this.enableDownload({ valFinal: tempPeriodo });
  };

  enviaDadosTipoRelatorio = (relatorio, fazenda, usuario) => {
    try {
      const categoria = "Análise Gerador de Relatórios";
      const acao = relatorio;
      let rotulo = fazenda;
      enviaDadosGAPorRotulo(acao, rotulo, categoria);
      rotulo = usuario.login;
      enviaDadosGAPorRotulo(acao, rotulo, categoria);
    } catch (error) {
      console.log(`Erro ao enviar infos para o GA: ${error.message}`);
    }
  };

  alteraTipoRelatorio = (tipoRelatorio) => {
    const umaSemanaAtras = moment().add(-1, "weeks").format("MM/DD/YYYY");
    const dataMinima = dataMinimaRelatorioEspecifico[tipoRelatorio];
    const valInicial =
      dataMinima && moment(dataMinima).isAfter(umaSemanaAtras)
        ? dataMinima
        : umaSemanaAtras;
    const valFinal = moment().format("MM/DD/YYYY");
    const relatorio = tipoRelatorio;
    const formatoArquivo = tipoRelatorio.slice(0, 3);
    this.enableDownload({
      valInicial,
      valFinal,
      relatorio,
      formatoArquivo,
      periodoCompleto: false,
    });
  };

  botaoBaixar = async (fazenda) => {
    const { IdFazenda } = fazenda;
    let dataInicio = moment(this.state.valInicial).format("DD/MM/YYYY");
    let dataFinal = moment(this.state.valFinal).format("DD/MM/YYYY");
    if (this.state.periodoCompleto) {
      dataInicio = dataMinimaRelatorioEspecifico[this.state.relatorio]
        ? moment(dataMinimaRelatorioEspecifico[this.state.relatorio]).format(
            "DD/MM/YYYY"
          )
        : dataMinimaRelatorios;
      dataFinal = moment().format("DD/MM/YYYY");
    }
    const body = {
      IdFazenda,
      TipoRelatorio: this.state.relatorio,
      ExtensaoArquivo: this.state.formatoArquivo,
      DataInicio: dataInicio,
      DataFinal: dataFinal,
      IdRetiro:
        this.state.retiroSelecionado === -1
          ? null
          : this.state.retiroSelecionado,
      periodoCompleto: this.state.periodoCompleto,
    };
    this.enviaDadosTipoRelatorio(
      this.state.relatorio,
      fazenda.Nome,
      this.props.usuario
    );

    this.setLoading(true);
    await this.props.obterRelatorios(body);
    this.setLoading(false);
    this.downloadFile(this.props.relatorioBaixado);
  };

  obterRelatorios = async () => {
    await this.props.RelatoriosTableau();
  };

  setLoading = (carregando) => {
    if (carregando) {
      this.setState({ opacity: 0 });
      this.setState({ btnDisabled: true });
      this.setState({ downloadDisable: true });
    } else {
      this.setState({ opacity: 1 });
      this.setState({ btnDisabled: false });
      this.setState({ downloadDisable: false });
    }
    this.enableDownload();
  };

  downloadFile = (urlArquivo) => {
    const element = document.createElement("a");
    element.setAttribute("href", urlArquivo);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    this.enableDownload();
  };

  render() {
    return (
      <FazendaSelecionadaContainer
        render={(fazenda) =>
          this.props.modalAberto ? (
            <TableauReportFormv2
              enableDownload={this.enableDownload}
              handleChangeFinal={this.handleChangeFinal}
              handleChangeFormato={this.handleChangeFormato}
              handleChangeInicio={this.handleChangeInicio}
              selecionaRetiro={this.selecionaRetiro}
              handleClose={this.handleClose}
              handleChangeRelatorios={this.handleChangeRelatorios}
              setPeriodoCompleto={this.setPeriodoCompleto}
              periodoCompleto={this.state.periodoCompleto}
              desativaPeriodoCompleto={this.desativaPeriodoCompleto}
              botaoBaixar={() => this.botaoBaixar(fazenda)}
              classes={this.styles}
              modalAberto={this.props.modalAberto}
              modalEmails={this.props.modalEmails}
              imagem={this.props.imagem}
              titulo={this.props.titulo}
              valFinal={this.state.valFinal}
              valInicial={this.state.valInicial}
              relatorio={this.state.relatorio}
              formatoArquivo={this.state.formatoArquivo}
              opacity={this.state.opacity}
              downloadDisable={this.state.downloadDisable}
              PickerErrors={this.state.PickerErrors}
              filtros={this.state.filtros}
              alteraTipoRelatorio={this.alteraTipoRelatorio}
              relatorios={this.props.relatorios}
              retiros={this.props.retiros}
              retiroSelecionado={this.props.retiroSelecionado}
            />
          ) : null
        }
      />
    );
  }
}
export default TableauReportPage;
