import React, { useCallback, useEffect, useState } from "react";
import auth from "features/authentication/Auth";
import { isLoggedUserInternal } from "features/shared/utils";

export const initTrackSaleWidget = (params) => {
  try {
    const widget = new window.Tracksale();
    widget.init(params);
    return widget;
  } catch (err) { }
};
let widget = null;

const TracksaleNps = ({ ClienteSelecionado, FazendaSelecionada, Fazendas }) => {
  const [previousSelectedFarm, setPreviousSelectedFarm] = useState();

  useEffect(() => {
    prepareTracksales();
  }, [ClienteSelecionado, FazendaSelecionada, Fazendas]);

  const prepareTracksales = useCallback(() => {
    if (!ClienteSelecionado || !FazendaSelecionada || !Fazendas) return;

    if (
      document.getElementById("tracksale-js") &&
      FazendaSelecionada === previousSelectedFarm
    )
      return;

    const fazenda = Fazendas.find((f) => f.IdFazenda === FazendaSelecionada);
    const nomeCliente = ClienteSelecionado && ClienteSelecionado.Nome;
    const codigoClienteProteus =
      ClienteSelecionado && ClienteSelecionado.CodigoClienteProteus;
    const nomeFazenda = fazenda && fazenda.Nome;

    if (!nomeCliente || !nomeFazenda) return;

    let isUserClient = JSON.parse(
      localStorage.getItem("@prodap-nps-user-client")
    );

    const usuario = auth.getSession();
    if (usuario && usuario.email) {
      if (isUserClient === null || isUserClient === undefined) {
        const isEmailInterno = isLoggedUserInternal()

        localStorage.setItem(
          "@prodap-nps-user-client",
          isEmailInterno ? "false" : "true"
        );
        isUserClient = isEmailInterno ? false : true;
      }

      if (isUserClient) {
        injectTrackSaleWidget(
          usuario,
          nomeCliente,
          nomeFazenda,
          codigoClienteProteus
        );
      }
    }
    setPreviousSelectedFarm(FazendaSelecionada);
  });

  const injectTrackSaleWidget = (
    usuario,
    cliente,
    fazenda,
    codigoClienteProteus
  ) => {
    const nomeUsuario = obtemNomeFormatado(usuario.nome);
    const params = {
      key: process.env.REACT_APP_NPS_KEY,
      campaign: process.env.REACT_APP_NPS_CAMPAIGN,
      name: nomeUsuario,
      email: usuario.email,
      identification: usuario.login,
      tags: {
        "NOME CLIENTE": cliente,
        "NOME FAZENDA": fazenda,
        "CODIGO DO CLIENTE": codigoClienteProteus,
      },
      salute: "%name.first%, tudo bem?",
      thanks: "Obrigado, %name.first%!",
      onOpen: () => console.log("[trackSaleWidget] onOpen"),
      onClose: () => console.log("[trackSaleWidget] onClose"),
      onAnswer: () => console.log("[trackSaleWidget] onAnswer"),
      onUpdateInfo: () => console.log("[trackSaleWidget] onUpdateInfo"),
      onWarning: (info) => console.log("[trackSaleWidget] onWarning", info),
      onError: (info) => console.log("[trackSaleWidget] onError", info),
    };

    let npsScript;
    const script = document.getElementsByTagName("script")[0];
    if (document.getElementById("tracksale-js") && widget) {
      widget.destroy();
      widget = initTrackSaleWidget(params);
    } else {
      npsScript = document.createElement("script");
      npsScript.id = "tracksale-js";
      npsScript.src = "https://cdn.tracksale.co/tracksale-js/tracksale.js";
      npsScript.type = "text/javascript";
      npsScript.async = true;
      npsScript.onload = npsScript.onreadystatechange = (event) => {
        const state = document.readyState;
        if (state && state !== "complete" && state !== "loaded") {
          return;
        }
        widget = initTrackSaleWidget(params);
      };
      script.parentNode.insertBefore(npsScript, script);
    }
  };

  const obtemNomeFormatado = (nomeUsuario) => {
    const nomes = `${nomeUsuario}`.toLowerCase().split(" ");
    const nome = nomes
      .filter((nome) => nome)
      .map((n) => n[0].toUpperCase() + n.substring(1));

    return nome.join(" ");
  };

  return <></>;
};

export default TracksaleNps;
