import React from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

export const renderPendencyCanBeAppliedMessage = (message, classes) => {
  const success = message.includes("As pendências foram resolvidas");

  if (!success) {
    const parts = message.split("inconsistências:");
    return (
      <>
        <span style={{ color: "black" }}>{parts[0]} inconsistências:</span>
        <span style={{ color: "red" }}>{parts[1]}</span>
      </>
    );
  }

  return (
    <span className={classes.verificationMessage}>
      <TaskAltIcon className={classes.successIcon} /> {message}
    </span>
  );
};
