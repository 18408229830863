import { exibirCarregando } from "../../../redux/actions";
import {
  notifySuccess,
  notifyError,
} from "../../../redux/actions/notificacaoActions";
import RetiroManager from "../retiroManager";

import {
  listaRetiros,
  criaRetiro,
  setaRetiro,
  setaCorRetiro,
  deletaRetiro,
  atualizaRetiro,
} from "./retiroActions";

export const setCorRetiro = (cor) => async (dispatch) => {
  try {
    dispatch(setaCorRetiro(cor));
  } catch (error) {
    dispatch(setaCorRetiro(""));
  }
};
export const setRetiro = (retiro) => async (dispatch) => {
  try {
    dispatch(setaRetiro(retiro));
  } catch (error) {
    dispatch(setaRetiro(-1));
  }
};

export const listarRetiros = (IdFazenda) => async (dispatch) => {
  dispatch(exibirCarregando(true));
  try {
    const retiros = await RetiroManager.listarRetiros(IdFazenda);
    dispatch(listaRetiros(retiros));
  } catch (error) {
    dispatch(listaRetiros([]));
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao listar os retiros.",
      })
    );
  } finally {
    dispatch(exibirCarregando(false));
  }
};

export const criarRetiros = (retiros) => async (dispatch) => {
  try {
    const retirosCriados = await RetiroManager.criarRetiros(retiros);
    dispatch(
      notifySuccess({
        mensagem: "Retiro criado com sucesso.",
      })
    );
    dispatch(criaRetiro(retirosCriados));
  } catch (error) {
    dispatch(criaRetiro(null));
    dispatch(
      notifyError({
        mensagem: "Ocorreu erro ao criar o retiro.",
      })
    );
  }
};

export const criarUsuarioRetiro =
  (idUsuario, usuarioRetiros) => async (dispatch) => {
    try {
      await RetiroManager.criarUsuarioRetiro(idUsuario, usuarioRetiros);
      dispatch(
        notifySuccess({
          mensagem: "Retiros vinculados a fazenda.",
        })
      );
    } catch (error) {
      dispatch(
        notifyError({
          mensagem: "Ocorreu erro ao vincular retiros.",
        })
      );
    }
  };

export const deletarRetiro = (idRetiro) => async (dispatch) => {
  try {
    await RetiroManager.deletarRetiro(idRetiro);
    dispatch(deletaRetiro());
    dispatch(
      notifySuccess({
        mensagem: "Retiro deletado com sucesso.",
      })
    );
  } catch (error) {
    dispatch(
      notifyError({
        mensagem: "Verifique se o Retiro não está vinculado a nenhum Pasto.",
      })
    );
  }
};

export const atualizarRetiro = (retiro, erroAtualizar) => async (dispatch) => {
  if (!erroAtualizar) {
    try {
      const retiroAtualizado = await RetiroManager.atualizarRetiro(retiro);
      dispatch(atualizaRetiro(retiroAtualizado));
      dispatch(
        notifySuccess({
          mensagem: "Retiro atualizado com sucesso.",
        })
      );
    } catch (error) {
      dispatch(
        notifyError({
          mensagem: "Verifique se o Retiro não está vinculado a nenhum Pasto.",
        })
      );
    }
  } else {
    dispatch(
      notifyError({
        mensagem: "Nome do retiro já existe. Escolha outro nome.",
      })
    );
  }
};
