import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

function Form({ classes, isEdition, state, onBlur, onChange }) {
  const onChangeText = (field) => (e) => {
    onChange(field, { value: e.target.value });
  };

  const onChangeCheckbox = (field) => (e) => {
    onChange(field, { value: e.target.checked });
  };

  return (
    <div className={classes.container}>
      <div className={classes.inputContainer}>
        <div>
          <TextField
            className={classes.textField}
            label="Nome completo"
            required
            placeholder="Nome completo do usuário"
            value={state.name.value}
            onChange={onChangeText("name")}
            error={state.name.error}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {state.name.error && (
            <ErrorMessage classes={classes} message={state.name.error} />
          )}
        </div>
        <div>
          <TextField
            className={classes.textField}
            disabled={isEdition}
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tooltip
                  title="O login é a identificação do usuário na plataforma, usualmente no formato nome.sobrenome, mas permite variações como nome.nome ou nome.fazenda, adaptando-se às preferências do usuário."
                  placement="left-end"
                  arrow
                  style={{ marginRight: 4 }}
                >
                  <InfoIcon htmlColor="#757575" fontSize="small" />
                </Tooltip>
                <span>Login do usuário *</span>
              </div>
            }
            placeholder="Ex: nome.sobrenome"
            value={state.login.value}
            onChange={onChangeText("login")}
            onBlur={onBlur("login")}
            error={state.login.error}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {state.login.error && (
            <ErrorMessage classes={classes} message={state.login.error} />
          )}
        </div>
        <div>
          <TextField
            className={classes.textField}
            label="Email"
            required
            placeholder="Informe o e-mail do usuário"
            value={state.email.value}
            onChange={onChangeText("email")}
            onBlur={onBlur("email")}
            error={state.email.error}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {state.email.error && (
            <ErrorMessage classes={classes} message={state.email.error} />
          )}
        </div>
      </div>
      <div className={classes.checkboxContainer}>
        <div className={classes.checkboxHeader}>
          <Tooltip
            title="Isso significa que o usuário terá acesso a essas funcionalidades em todas as fazendas às quais ele está associado."
            placement="right-end"
            arrow
          >
            <InfoIcon htmlColor="#757575" fontSize="small" />
          </Tooltip>
          <span>Liberar acesso a:</span>
        </div>
        <div className={classes.checkboxContent}>
          <Checkbox
            classes={classes}
            label="Acessa plataforma"
            checked={state.acessaPlataforma.value}
            onChange={onChangeCheckbox("acessaPlataforma")}
          />

          <Checkbox
            classes={classes}
            label="Aplicativo"
            checked={state.acessaAplicativo.value}
            onChange={onChangeCheckbox("acessaAplicativo")}
          />

          <Checkbox
            classes={classes}
            label="Estoque final"
            checked={state.estoqueFinal.value}
            onChange={onChangeCheckbox("estoqueFinal")}
          />

          <Checkbox
            classes={classes}
            label="Importar mapas"
            checked={state.importaMapas.value}
            onChange={onChangeCheckbox("importaMapas")}
          />

          <Checkbox
            classes={classes}
            label="Importar/exportar CSV pasto"
            checked={state.importaCSV.value}
            onChange={onChangeCheckbox("importaCSV")}
          />

          <Checkbox
            classes={classes}
            label="Cadastrar ciclo de forragem"
            checked={state.cicloForragem.value}
            onChange={onChangeCheckbox("cicloForragem")}
          />
        </div>
      </div>
    </div>
  );
}

const ErrorMessage = ({ classes, message }) => {
  return (
    <div className={classes.error}>
      <span style={{ color: "red" }}>{message}</span>
    </div>
  );
};

const Checkbox = ({ classes, label, checked, onChange }) => {
  return (
    <div className={classes.checkbox}>
      <input
        className={classes.checkboxInput}
        type="checkbox"
        name={`checkbox-${label}`}
        checked={!!checked}
        onChange={onChange}
      />
      <label htmlFor={`checkbox-${label}`}>{label}</label>
    </div>
  );
};

const styles = (theme) => ({
  container: {
    marginTop: "24px",
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
  inputContainer: {
    display: "flex",
    gap: "32px",
    flexWrap: "wrap",
  },
  textField: {
    width: "300px",
  },
  error: {
    maxWidth: "300px",
    "& span": {
      fontFamily: "Roboto",
      fontSize: "14px",
    },
  },
  checkboxContainer: {
    display: "flex",
    gap: "24px",
    flexDirection: "column",
  },
  checkboxHeader: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontFamily: "Roboto",
  },
  checkboxContent: {
    display: "flex",
    alignItems: "center",
    gap: "24px",
    fontSize: "16px",
    flexWrap: "wrap",
  },
  checkbox: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontFamily: "Roboto",
  },
  checkboxInput: {
    width: "20px",
    height: "20px",
    margin: 0,
    cursor: "pointer",
  },
});

export default withStyles(styles)(Form);
