import React from "react";
import { useState } from "react";
import BatchListingTablePage from "./batchListingTablePage";

const BatchListingTableContainer = ({
  objetivos,
  handleAlteraLote,
  atualizaEstadoDataPesagem,
  showLoteModal,
  exibirColunaPesoProjetado,
  exibirColunaSanidade,
  exibirTrocaCategoria,
  obterProjecaoSemestrePorLote,
  lotes,
  mensagemPesoObjetivo,
  calculaDataParaPesoObjetivo,
  obterACurvaGanhoPeso,
  naoTemLotes,
  naoTemLotesComFiltro,
  getWeightGainCurve,
}) => {
  const [paginaAtual, setPaginaAtual] = useState(0);
  const [linhasPorPagina, setLinhasPorPagina] = useState(10);

  const alteraPagina = (pagina) => {
    setPaginaAtual(pagina);
  };

  const alteraLinhasPorPagina = (linhasPorPagina) => {
    setLinhasPorPagina(linhasPorPagina);
    setPaginaAtual(0);
  };

  const indexPrimeiroLote = paginaAtual * linhasPorPagina;
  const indexUltimoLote = indexPrimeiroLote + linhasPorPagina;
  const lotesPaginados = lotes.slice(indexPrimeiroLote, indexUltimoLote);

  return (
    <BatchListingTablePage
      objetivos={objetivos}
      handleAlteraLote={handleAlteraLote}
      atualizaEstadoDataPesagem={atualizaEstadoDataPesagem}
      showLoteModal={showLoteModal}
      exibirColunaPesoProjetado={exibirColunaPesoProjetado}
      exibirColunaSanidade={exibirColunaSanidade}
      exibirTrocaCategoria={exibirTrocaCategoria}
      obterProjecaoSemestrePorLote={obterProjecaoSemestrePorLote}
      lotes={lotes}
      mensagemPesoObjetivo={mensagemPesoObjetivo}
      calculaDataParaPesoObjetivo={calculaDataParaPesoObjetivo}
      alteraPagina={alteraPagina}
      paginaAtual={paginaAtual}
      linhasPorPagina={linhasPorPagina}
      alteraLinhasPorPagina={alteraLinhasPorPagina}
      lotesPaginados={lotesPaginados}
      obterACurvaGanhoPeso={obterACurvaGanhoPeso}
      naoTemLotes={naoTemLotes}
      naoTemLotesComFiltro={naoTemLotesComFiltro}
      getWeightGainCurve={getWeightGainCurve}
    />
  );
};

export default BatchListingTableContainer;
