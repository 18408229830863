import {
  notifyError,
  notifySuccess,
} from "../../../redux/actions/notificacaoActions";
import AjusteEscoreManager from "../ajusteEscoreManager";
import {
  listarAjustesEscore,
  excluirAjustesEscore,
  salvarAjustesEscore,
  exibirCarregando,
} from "./ajusteEscoreActions";
import _ from "lodash";

export const listarAjustesEscoreFazenda = () => async (dispatch, getState) => {
  dispatch(exibirCarregando(true));
  try {
    const { fazenda } = getState();
    const ajustesEscoresFazenda = await AjusteEscoreManager.listarAjustesEscore(
      fazenda.FazendaSelecionada
    );
    dispatch(listarAjustesEscore(ajustesEscoresFazenda));
  } catch (error) {
    dispatch(listarAjustesEscore([]));
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao obter os ajustes escores da fazenda.",
      })
    );
  } finally {
    dispatch(exibirCarregando(false));
  }
};

export const salvarAjustesEscoreFazenda =
  (ajustesEscore) => async (dispatch) => {
    try {
      await AjusteEscoreManager.salvarAjustesEscore(ajustesEscore);
      dispatch(salvarAjustesEscore());
      dispatch(
        notifySuccess({
          mensagem: "Ajuste de escore salvo com sucesso.",
        })
      );
    } catch (error) {
      dispatch(
        notifyError({
          mensagem: "Ocorreu um erro ao salvar o ajuste de escore.",
        })
      );
    }
  };

export const excluirAjustesEscoreFazenda = (idFazenda) => async (dispatch) => {
  try {
    await AjusteEscoreManager.excluirAjustesEscore(idFazenda);
    dispatch(excluirAjustesEscore());
    dispatch(
      notifySuccess({
        mensagem: "Ajuste de escore excluída com sucesso.",
      })
    );
  } catch (error) {
    if (
      !_.isNil(error.response) &&
      !_.isNil(error.response.data) &&
      !_.isNil(error.response.data.codigo) &&
      Number(error.response.data.codigo) === 406
    ) {
      dispatch(
        notifyError({
          mensagem: error.response.data.mensagem,
        })
      );
    } else {
      dispatch(
        notifyError({
          mensagem: "Ocorreu um erro ao excluir o ajuste de escore.",
        })
      );
    }
  }
};
