import memoizeOne from 'memoize-one';

export const filtraFazendaSelecionadaNasFazendas = reduxState => {
  const { fazenda } = reduxState;
  const { FazendaSelecionada, Fazendas } = fazenda;
  if (!FazendaSelecionada || !Fazendas || !Fazendas.length) {
    return {};
  }
  return Fazendas.find(
    fazendaItem => fazendaItem.IdFazenda === FazendaSelecionada
  );
};

export const filtraFazendaSelecionadaMemo = memoizeOne(
  filtraFazendaSelecionadaNasFazendas
);
