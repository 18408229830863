import { TextField } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

function NumberField({ value, onChange, disabledNullText = "", ...props }) {
  const [_value, _setValue] = useState("");

  const _onChange = (valueString) => {
    const valid = /^\d+,?\d*$/.test(valueString);
    if (valid || valueString === "") {
      _setValue(valueString);
    }
    const valueNumber = toNumber(valueString);
    if (valueNumber !== value) {
      onChange({ target: { value: valueNumber } });
    }
  };

  const toNumber = (stringNumber = "") => {
    if (stringNumber) {
      const result = Number(
        `${stringNumber}`.replace(",", ".").replace(/[.,]*$/g, "")
      );
      return result;
    }
    return null;
  };

  const toString = (number) => {
    if (number) {
      return number.toString().replace(".", ",");
    }
    return null;
  };

  useEffect(() => {
    if (toNumber(`${value}`) !== toNumber(_value)) {
      if (!value) {
        _setValue("");
      } else {
        _setValue(toString(value));
      }
    }
  }, [value]);

  return (
    <TextField
      onChange={(ev) => _onChange(ev.target.value)}
      value={
        disabledNullText && props.disabled && !value ? disabledNullText : _value
      }
      {...props}
    />
  );
}

export default NumberField;
