import React from "react";
import { TextLabel, FeedBackPage } from "../../../../shared/components";
import { Grid } from "@material-ui/core";

const GoalPlanListEmpty = () => (
  <Grid
    container
    direction="column"
    justify="center"
    style={{
      minHeight: "60vh",
    }}
  >
    <FeedBackPage>
      <Grid container direction="column" alignItems="center">
        <img src="../images/busca.png" alt="Lupa de tela vazia" />
        <TextLabel
          id="labelFechamentoFeedBack"
          inputProps={{ "data-testid": "label-fechamento-feedBack" }}
          name="metasSemItens"
          block
          color="default2"
          customStyle={{ paddingTop: 10, textAlign: "center" }}
        >
          Você ainda não possui metas cadastradas.
          <br />
          Clique no botão <b>"CRIAR PLANO E METAS"</b> para começar.
        </TextLabel>
      </Grid>
    </FeedBackPage>
  </Grid>
);

export default GoalPlanListEmpty;
