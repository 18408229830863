import React from "react";
import { withStyles } from "@material-ui/core/styles";

function Title({ classes, isEdition }) {
  return (
    <span className={classes.title}>
      {isEdition ? "Editar usuário" : "Criar novo usuário"}
    </span>
  );
}

const styles = () => ({
  title: {
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: "20px",
    color: "#444444",
    marginBottom: "20px",
  },
});

export default withStyles(styles)(Title);
