import React, { useEffect, useMemo, useState } from "react";
import Poligono from "../../../Poligono";
import PoligonoMundo from "features/manejo/views/components/PoligonoMundo";
import Marcador from "../marcador";
import { TooltipMarcador } from "../tooltipMarcador";
import { MarkerClusterer, useGoogleMap } from "@react-google-maps/api";
import constantsAlturaPasto from "constants/mapaFarolAlturaPasto";
import { PASTOS_SEM_CADASTRO, TODOS_OS_PASTOS } from "constants/retiro";
import { idsTiposColetaAltura, tiposColetaAltura } from "features/manejo/views/MenuLateral/Farol/farolContainerUtils";

const { outsidePasture } = constantsAlturaPasto.collect.collect_types_colors;

const isOutsideCollectPasture = (marker) => marker && !marker.CapturadaDentroDoPasto && marker.color === outsidePasture

export const ColetaMarcadores = ({
  coletas,
  periodoColeta,
  visualizarPontosColetas,
  filtroColetas,
  atualizaZoom,
  pastoMouseOver,
  retiroSelecionado,
  tipoIconeExibido
}) => {
  const [marcadores, setMarcadores] = useState([]);
  const [marcadorDestacado, setMarcadorDestacado] = useState(null);

  const [desaparecerMarcadores, setDesaparecerMarcadores] = useState(false);
  const map = useGoogleMap();

  const filtraPorRetiro = (coletas, retiro) => {
    switch (retiro) {
      case TODOS_OS_PASTOS:
        return coletas;
      case PASTOS_SEM_CADASTRO:
        return coletas.filter(coleta => !coleta.pasto.IdRetiro);
      default:
        return coletas.filter(coleta => coleta.pasto.IdRetiro === retiro);
    }
  }
  const filtraPorTipoColeta = (coletas, tipoColeta) => {
    switch (tipoColeta) {
      case tiposColetaAltura.Todos:
        return coletas;
      case tiposColetaAltura.Afericao:
        return coletas.filter(coleta => coleta.IdTipoColetaAltura === idsTiposColetaAltura[tiposColetaAltura.Afericao]);
      case tiposColetaAltura.Entrada:
        return coletas.filter(coleta => coleta.IdTipoColetaAltura === idsTiposColetaAltura[tiposColetaAltura.Entrada]);
      case tiposColetaAltura.Saida:
        return coletas.filter(coleta => coleta.IdTipoColetaAltura === idsTiposColetaAltura[tiposColetaAltura.Saida]);
      default:
        return coletas;
    }
  };

  const alterarFiltro = (coletas, filtro, retiro, tipoIconeExibido) => {

    const coletasFiltradasPorRetiro = filtraPorRetiro(coletas, retiro);
    const coletasFiltradasPorTipo = filtraPorTipoColeta(coletasFiltradasPorRetiro, tipoIconeExibido);

    switch (filtro) {
      case constantsAlturaPasto.collect.collect_filter.all:
        return coletasFiltradasPorTipo;
      case constantsAlturaPasto.collect.collect_filter.insidePasture:
        return coletasFiltradasPorTipo.filter((coleta) => coleta.CapturadaDentroDoPasto);
      case constantsAlturaPasto.collect.collect_filter.outsidePasture:
        return coletasFiltradasPorTipo.filter(coleta => !coleta.CapturadaDentroDoPasto && coleta.gpsLigado);
      case constantsAlturaPasto.collect.collect_filter.noGps:
        return coletasFiltradasPorTipo.filter((coleta) => !coleta.gpsLigado);
      default:
        return coletasFiltradasPorTipo;
    }
  };

  const coletasFiltradas = useMemo(() => {
    return alterarFiltro(coletas, filtroColetas, retiroSelecionado, tipoIconeExibido);
  }, [coletas, filtroColetas, retiroSelecionado, tipoIconeExibido]);

  useEffect(() => {
    if (pastoMouseOver) {
      const marcadorDoPasto = coletas.find((marcador) => marcador.pasto.IdPasto === pastoMouseOver.pasto.IdPasto);
      if (isOutsideCollectPasture(marcadorDoPasto)) {
        setDesaparecerMarcadores(true);
      }
    }

    if (!pastoMouseOver && coletas.length > 0 && desaparecerMarcadores) {
      setDesaparecerMarcadores(false);
      setMarcadorDestacado(null);
    }
  }, [pastoMouseOver]);

  useEffect(() => {
    if (coletasFiltradas.length !== null && coletasFiltradas.length !== undefined) {
      obterCoordenadasDosMarcadores(coletasFiltradas);
    }
  }, [coletasFiltradas, periodoColeta, visualizarPontosColetas]);

  const obterCoordenadasDosMarcadores = (coletas) => {
    setMarcadores(coletas);
  };


  const mostrarModal = (marcador) => {
    if (isOutsideCollectPasture(marcador)) {
      setMarcadores([marcador]);
      setMarcadorDestacado(marcador);
    }
  };

  const ocultarModal = () => {
    if (marcadorDestacado && isOutsideCollectPasture(marcadorDestacado)) {
      setMarcadores(coletasFiltradas)
      setMarcadorDestacado(null);
    }
  };

  const onMarkerClick = (marcador) => {
    atualizaZoom(15);
    if (marcador && marcador.pasto && marcador.pasto.CentroPasto[0])
      map.setCenter(marcador.pasto.CentroPasto[0]);
  };

  const markerClustererShown = !desaparecerMarcadores && coletas.length && visualizarPontosColetas && marcadores.length;
  const marcadorDestacadoShown = marcadorDestacado && marcadorDestacado.position && marcadorDestacado.pasto.IdPasto && !marcadorDestacado.CapturadaDentroDoPasto;

  return (
    <div id="markerClusterer">
      {markerClustererShown && (
        <MarkerClusterer
          averageCenter
          styles={[
            {
              url: "/images/mapCluster.svg",
              height: 48,
              width: 48,
              textColor: "#fff",
            }
          ]}
          gridSize={6}
        >
          {(clusterer) => (<>{
            marcadores.map((marcador) => (<>
              <Marcador

                position={marcador.position}
                url={marcador.url}
                onMouseOver={(e) => mostrarModal(marcador, e)}
                key={"marker" + marcador.pasto.IdPasto}
                onClick={() => onMarkerClick(marcador)}
                visible={marcador.visible}
                clusterer={clusterer}
                id={marcador.pasto.IdPasto}
              />
            </>
            ))}
          </>)}
        </MarkerClusterer>
      )}

      {marcadorDestacadoShown && (
        <>
          <TooltipMarcador
            marcador={marcadorDestacado}
            onMouseLeave={ocultarModal}
            onClick={(e) => {
              onMarkerClick(marcadorDestacado)
            }}
          />
          <PoligonoMundo onClick={(e) => {
            ocultarModal();
          }} />


          <Poligono
            coordenadas={marcadorDestacado.pasto.Coordenadas}
            highlight={true}
            block={false}
            raised={true}
            corLinha={"#fff"}
            destacarModulo={true}
            cor={marcadorDestacado.pasto.farolPasto.Cor}
          />
        </>
      )}
    </div>
  );
};
