import React, { useState } from "react";
import PlanningRegisterContainer from "./planningRegister/planningRegisterContainer";
import PlanningEditContainer from "./plannindEdit/planningEditContainer";
import PlanningListContainer from "./planningList/planningListContainer";
import PlanningViewContainer from "./planningView/planningViewContainer";

const SCREENS = {
  LIST: "list",
  CREATION: "creation",
  EDITION: "edition",
  VIEW: "view",
};

const PlanningRouter = ({
  changeInterceptOnChangeTab,
  changeTabRequested,
  confirmChangeTab,
  onCancelChangeTab,
  tabToChange,
}) => {
  const [screen, setScreen] = useState(SCREENS.LIST);
  const [state, setState] = useState();

  function showCreation() {
    setScreen(SCREENS.CREATION);
    clearState();
    changeInterceptOnChangeTab(true);
  }

  function showEdition(planningCode) {
    setScreen(SCREENS.EDITION);
    setState({ planningCode });
    changeInterceptOnChangeTab(true);
  }

  function showPlanning(planningCode) {
    setScreen(SCREENS.VIEW);
    setState({ planningCode });
  }

  function goBack() {
    if (changeTabRequested) {
      confirmChangeTab();
      if (tabToChange === "Evolução de rebanho e plano de nutrição") {
        setScreen(SCREENS.LIST);
      }
      clearState();
    } else {
      setScreen(SCREENS.LIST);
      clearState();
    }
  }

  function clearState() {
    setState({});
    changeInterceptOnChangeTab(false);
  }

  function renderScreen() {
    switch (screen) {
      case SCREENS.VIEW:
        return (
          <PlanningViewContainer
            goBack={goBack}
            planningCode={state.planningCode}
            showPlanning={showPlanning}
            showEditPlanning={showEdition}
            showNewPlanning={showCreation}
          />
        );
      case SCREENS.LIST:
        return (
          <PlanningListContainer
            showPlanning={showPlanning}
            showEditPlanning={showEdition}
            showNewPlanning={showCreation}
          />
        );

      case SCREENS.CREATION:
        return (
          <PlanningRegisterContainer
            goBack={goBack}
            onCancelChangeTab={onCancelChangeTab}
            changeTabRequested={changeTabRequested}
          />
        );
      case SCREENS.EDITION:
        return (
          <PlanningEditContainer
            planningCode={state.planningCode}
            goBack={goBack}
            onCancelChangeTab={onCancelChangeTab}
            changeTabRequested={changeTabRequested}
          />
        );

      default:
        throw new Error(`Screen ${screen} not defined.`);
    }
  }

  return renderScreen();
};

export default PlanningRouter;
