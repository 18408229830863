import React from "react";
import Typography from "@material-ui/core/Typography";
import { Modal, Grid, Button, withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export class FuncionalidadesModal extends React.Component {
  render() {
    const { classes } = this.props;
    const { exibirModal, handleClose, descricao, titulo } = this.props;
    return (
      <Modal
        id="modal"
        open={exibirModal}
        onBackdropClick={handleClose}
        onClickAway={handleClose}
      >
        <div className={classes.modalContainer}>
          <Grid id="containerModal" container className={classes.modalGeral}>
            <div className={classes.modalInfoHeader}>
              <Typography id="titulo" className={classes.titulo}>
                {titulo}
              </Typography>
              <CloseIcon
                id="icone-fechar"
                className={classes.fechar}
                onClick={handleClose}
              />
            </div>
            <Grid item xs={12}>
              <Typography
                id="descricao"
                className={classes.descricao}
                align="justify"
              >
                {descricao}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.botaoInfoContainer}>
              <Button
                variant="contained"
                className={classes.botaoConfirmacao}
                onClick={handleClose}
                color="primary"
                autoFocus
              >
                OK
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    );
  }
}

const styles = (themes) => {
  return {
    modalContainer: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
    },
    modalGeral: {
      width: "490px",
      borderRadius: "16px",
      backgroundColor: "#ffffff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
    },
    modalInfoHeader: {
      display: "flex",
      flex: 1,
      alignItems: "center",
      justifyContent: "space-between",
      padding: "42px 40px 0px 39px",
    },
    titulo: {
      fontSize: "18px",
      fontWeight: "500",
      letterSpacing: "-0.1px",
      color: "rgba(51, 50, 56, 0.84)",
    },
    fechar: {
      width: "24px",
      height: "24px",
      cursor: "pointer",
      objectFit: "contain",
      padding: "0",
      color: "#333333",
    },
    botaoInfoContainer: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "42px 40px 29px 39px",
    },
    botaoConfirmacao: {
      width: "83px",
      height: "36px",
    },
    descricao: {
      fontFamily: "Roboto",
      fontSize: 16,
      fontStretch: "normal",
      padding: "29px 52px 0 39px",
      letterSpacing: "-0.03px",
      color: "#444444",
    },
  };
};

export default withStyles(styles)(FuncionalidadesModal);
