import React, { PureComponent } from "react";
import { Typography, Grid, Button, withStyles } from "@material-ui/core";
import AjusteEscoreTabela from "../views/ajusteEscoreTabela";
import { DialogoConfirmacaoAcao } from "features/shared/components/DialogoConfirmacaoAcao";
import {
  resetarAjustesEscore,
  verificaErroAjuste,
  trocaVirgulaPorPonto,
} from "../ajusteEscoreUtils";
export class AjusteEscorePage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ajustesEscoresAtualizados: null,
      confirmacaoRestauracaoAberto: false,
      editarHabilitado: false,
      erroAjuste: false,
    };
  }

  componentDidUpdate = () => {
    const { ajustesEscoreFazenda } = this.props;
    const { ajustesEscoresAtualizados } = this.state;

    if (!ajustesEscoresAtualizados) {
      const ajustesEscoresAnterior = resetarAjustesEscore(ajustesEscoreFazenda);
      this.setState({
        ajustesEscoresAtualizados: ajustesEscoresAnterior,
      });
    }
  };

  abrirModalRestaurar = () => {
    this.setState({ confirmacaoRestauracaoAberto: true });
  };

  habilitarEditar = () => {
    const { editarHabilitado } = this.state;
    this.setState({
      editarHabilitado: !editarHabilitado,
    });
  };

  restaurarPadrao = async () => {
    const { idFazenda } = this.props;
    await this.props.excluirAjustesEscore(idFazenda);
    await this.fecharModalRestaurar();
  };

  fecharModalRestaurar = async () => {
    await this.props.listarAjustesEscore();
    const ajustesEscoresAnterior = resetarAjustesEscore(
      this.props.ajustesEscoreFazenda
    );
    this.setState({
      ajustesEscoresAtualizados: ajustesEscoresAnterior,
      confirmacaoRestauracaoAberto: false,
    });
  };

  atualizarAjuste = (event, diaAtual, diaAnterior) => {
    const { ajustesEscoresAtualizados } = this.state;
    const valorAjuste = event.target.value;
    const erroAjuste = verificaErroAjuste(valorAjuste);

    const ajustesEscores = ajustesEscoresAtualizados.map((ajuste) => {
      const valorAjusteTratado = trocaVirgulaPorPonto(erroAjuste, valorAjuste);
      if (diaAtual === ajuste.DiaAtual && diaAnterior === ajuste.DiaAnterior) {
        return {
          DiaAtual: ajuste.DiaAtual,
          DiaAnterior: ajuste.DiaAnterior,
          Ajuste: valorAjusteTratado,
          Modificado: true,
          Erro: erroAjuste,
        };
      } else {
        return {
          ...ajuste,
        };
      }
    });

    this.setState({
      ajustesEscoresAtualizados: ajustesEscores,
      erroAjuste: erroAjuste,
    });
  };

  cancelarAlteracao = () => {
    const { ajustesEscoreFazenda } = this.props;
    const { editarHabilitado } = this.state;
    const ajustesEscoresAtualizados =
      resetarAjustesEscore(ajustesEscoreFazenda);

    this.setState({
      editarHabilitado: !editarHabilitado,
      ajustesEscoresAtualizados: ajustesEscoresAtualizados,
    });
  };

  salvarAlteracao = async () => {
    const { ajustesEscoresAtualizados, editarHabilitado } = this.state;
    const { idFazenda } = this.props;

    const ajustesEscoreObjeto = {
      IdFazenda: idFazenda,
      AjustesEscore: ajustesEscoresAtualizados,
    };

    await this.props.salvarAjustesEscore(ajustesEscoreObjeto);
    await this.props.listarAjustesEscore();
    const ajustesEscoresAnterior = resetarAjustesEscore(
      this.props.ajustesEscoreFazenda
    );

    this.setState({
      editarHabilitado: !editarHabilitado,
      ajustesEscoresAtualizados: ajustesEscoresAnterior,
    });
  };

  renderBotoesPrincipais = () => {
    const { classes } = this.props;
    const { editarHabilitado } = this.state;
    const textoBotaoEditar = "EDITAR AJUSTES";
    const textoBotaoRestaurar = "RESTAURAR PADRÃO";

    return (
      <React.Fragment>
        <Button
          id="botaoEditar"
          className={classes.botaoAjuste}
          variant="contained"
          onClick={this.habilitarEditar}
          color="primary"
          disabled={editarHabilitado}
        >
          {textoBotaoEditar}
        </Button>
        <Button
          id="botaoRestaurar"
          className={classes.botaoAjuste}
          variant="contained"
          onClick={this.abrirModalRestaurar}
          color="primary"
        >
          {textoBotaoRestaurar}
        </Button>
      </React.Fragment>
    );
  };

  renderBotoesEditarHabilitado = () => {
    const { classes } = this.props;
    const { erroAjuste } = this.state;
    const textoBotaoSalvar = "SALVAR AJUSTES";
    const textobotaoCancelar = "CANCELAR";

    return (
      <React.Fragment>
        <Button
          id="botaoSalvar"
          className={classes.botaoAjuste}
          variant="contained"
          onClick={this.salvarAlteracao}
          color="primary"
          disabled={erroAjuste}
        >
          {textoBotaoSalvar}
        </Button>
        <Button
          id="botaoCancelar"
          className={classes.botaoCancelar}
          onClick={this.cancelarAlteracao}
        >
          {textobotaoCancelar}
        </Button>
      </React.Fragment>
    );
  };

  renderModal = () => {
    const { confirmacaoRestauracaoAberto } = this.state;
    const { classes } = this.props;

    return confirmacaoRestauracaoAberto ? (
      <DialogoConfirmacaoAcao
        id="dialogConfirmaRestauracaoAjusteEscore"
        tituloDialog="Restaurar padrão"
        dialogClasses={classes.ajustesContainerDialog}
        textClasses={classes.textoContentDialog}
        textoDialog={
          <span id="mensagemConfirmaRestaurarAjusteEscore">
            Você deseja restaurar os valores padrão do FarmTell Views?
          </span>
        }
        dialogAberto={confirmacaoRestauracaoAberto}
        cancelarAcaoDialog={this.fecharModalRestaurar}
        excluir={this.restaurarPadrao}
      />
    ) : null;
  };

  render = () => {
    const { classes } = this.props;
    const { ajustesEscoresAtualizados, editarHabilitado } = this.state;
    const tituloPagina =
      "Ajustes de abastecimento de cochos a partir do escore do dia anterior e atual";
    const ajustesEscoresTabela = ajustesEscoresAtualizados
      ? ajustesEscoresAtualizados
      : [];

    return (
      <React.Fragment>
        <div square className={classes.root}>
          <Grid item className={classes.containerPage}>
            <Typography className={classes.tituloAjuste} gutterBottom>
              {tituloPagina}
            </Typography>

            {this.state.editarHabilitado
              ? this.renderBotoesEditarHabilitado()
              : this.renderBotoesPrincipais()}

            <Grid item>
              <AjusteEscoreTabela
                id="ajusteEscoreTabela"
                ajustesEscoresTabela={ajustesEscoresTabela}
                editarHabilitado={editarHabilitado}
                atualizarAjuste={this.atualizarAjuste}
              />
            </Grid>
          </Grid>
        </div>
        {this.renderModal()}
      </React.Fragment>
    );
  };
}

const styles = (theme) => {
  const botao = {
    borderRadius: "18px",
    float: "right",
    marginLeft: "16px",
    marginTop: "34px",
    height: 36,
    boxShadow: "none",
    marginBottom: "20px",
  };

  return {
    root: {
      width: "100%",
      overflowX: "auto",
      zIndex: -1,
      minHeight: "calc(100vh - 90px)",
      display: "flex",
      justifyContent: "center",
      backgroundColor: "white",
    },
    ajustesContainerDialog: {
      width: 348,
      height: 206,
    },
    textoContentDialog: {
      fontFamily: "Roboto",
      fontSize: 16,
      letterSpacing: "0.2px",
      color: "#333",
    },
    containerPage: {
      height: "100%",
      marginTop: 20,
      width: "90%",
    },
    tituloAjuste: {
      fontFamily: "Roboto",
      fontSize: 20,
      fontWeight: "bold",
      color: "#444",
      marginTop: "40px",
    },
    botaoAjuste: {
      ...botao,
    },
    botaoCancelar: {
      ...botao,
      color: theme.palette.primary.main,
    },
  };
};

export default withStyles(styles)(AjusteEscorePage);
