import React from "react";
import { withStyles } from "@material-ui/core";
import LotesTabelaEmpty from "./lotesTabelaEmpty";
import TabelaProdap from "features/shared/components/TabelaProdap";
import CampoNomeCategoriaPasto from "./campoNomeCategoriaPasto";
import CampoStatusSanidade from "./campoStatusSanidade";
import CampoObjetivos from "./campoObjetivos";
import CampoPesoObjetivo from "./campoPesoObjetivo";
import CampoPesoMedido from "./campoPesoMedido";
import CampoDataPesagem from "./campoDataPesagem";
import CampoPesoProjetado from "./campoPesoProjetado";
import constants from "constants/global";

const IDS_COLUMNS = {
  lotesColumn: "categoria.Nome",
  quantidadeColumn: "Quantidade",
  sanidadeColumn: "StatusSanidade.legenda",
  objetivoColumn: "Objetivo.Nome",
  pesoObjetivoColumn: "PesoObjetivo",
  pesoPesadoColumn: "PesoMedido",
  dataPesagemColumn: "DataPesagem",
  pesoProjetadoColumn: "PesoProjetado"
}


const LotesColumn = (exibirTrocaCategoria) => ({
  id: IDS_COLUMNS.lotesColumn,
  titulo: "LOTES",
  ordenavel: true,
  pegaValor: (row) => <CampoNomeCategoriaPasto lote={row} exibirTrocaCategoria={exibirTrocaCategoria} />
})

const QuantidadeColumn = () => ({
  id: IDS_COLUMNS.quantidadeColumn,
  titulo: "QUANTIDADE",
  ordenavel: true,
  pegaValor: (row) =>
    row.Quantidade
})

const SanidadeColumn = (exibirColunaSanidade) => ({
  id: IDS_COLUMNS.sanidadeColumn,
  titulo: "SANIDADE",
  ordenavel: true,
  pegaValor: (row) => <CampoStatusSanidade lote={row} exibirColunaSanidade={exibirColunaSanidade} />
})

const ObjetivoColumn = (objetivos, handleAlteraLote) => ({
  id: IDS_COLUMNS.objetivoColumn,
  titulo: "OBJETIVO",
  ordenavel: true,
  estilo: {
    "max-width": 300
  },
  pegaValor: (row) => <CampoObjetivos
    key={`Objetivo-${row.IdLote}-${row.IdObjetivo}-${row.IdPasto}`}
    lote={row} objetivos={objetivos} handleAlteraLote={handleAlteraLote} />
})

const PesoObjetivoColumn = (mensagemPesoObjetivo, calculaDataParaPesoObjetivo, handleAlteraLote) => ({
  id: IDS_COLUMNS.pesoObjetivoColumn,
  titulo: "PESO OBJETIVO",
  ordenavel: true,
  pegaValor: (row) => <CampoPesoObjetivo
    key={`PesoObjetivo-${row.IdLote}-${row.IdObjetivo}-${row.IdPasto}`}
    lote={row}
    handleAlteraLote={handleAlteraLote}
    mensagemPesoObjetivo={mensagemPesoObjetivo}
    calculaDataParaPesoObjetivo={calculaDataParaPesoObjetivo}
  />
})

const PesoPesadoColumn = (handleAlteraLote) => ({
  id: IDS_COLUMNS.pesoPesadoColumn,
  titulo: "PESO PESADO",
  ordenavel: true,
  pegaValor: (row) => <CampoPesoMedido
    key={`PesoPesado-${row.IdLote}-${row.IdObjetivo}-${row.IdPasto}`}
    lote={row}
    handleAlteraLote={handleAlteraLote}
  />
})

const DataPesagemColumn = (handleAlteraLote, atualizaEstadoDataPesagem) => ({
  id: IDS_COLUMNS.dataPesagemColumn,
  titulo: "DATA DA PESAGEM",
  ordenavel: true,
  estilo: {
    "max-width": 300
  },
  pegaValor: (row) => <CampoDataPesagem
    key={`DataPesagem-${row.IdLote}-${row.IdObjetivo}-${row.IdPasto}`}
    lote={row}
    handleAlteraLote={handleAlteraLote}
    atualizaEstadoDataPesagem={atualizaEstadoDataPesagem}
  />
})

const infoMessagePesoProjetado = `Valores referentes ao peso total projetado para o último dia do mês.`;

const PesoProjetadoColumn = (obterProjecaoSemestrePorLote, exibirColunaPesoProjetado, showLoteModal, getWeightGainCurve) => ({
  id: IDS_COLUMNS.pesoProjetadoColumn,
  titulo: "PESO PROJETADO",
  infoMessage: infoMessagePesoProjetado,
  ordenavel: true,
  pegaValor: (row) => <CampoPesoProjetado
    key={`PesoProjetado-${row.IdLote}-${row.IdObjetivo}-${row.IdPasto}`}
    lote={row}
    obterProjecaoSemestrePorLote={obterProjecaoSemestrePorLote}
    exibirColunaPesoProjetado={exibirColunaPesoProjetado}
    showLoteModal={showLoteModal}
    getWeightGainCurve={getWeightGainCurve}
  />
})

const BatchListingTable = ({
  classes,
  objetivos,
  handleAlteraLote,
  atualizaEstadoDataPesagem,
  showLoteModal,
  exibirColunaPesoProjetado,
  exibirColunaSanidade,
  exibirTrocaCategoria,
  obterProjecaoSemestrePorLote,
  lotes,
  mensagemPesoObjetivo,
  calculaDataParaPesoObjetivo,
  naoTemLotes = false,
  naoTemLotesComFiltro = false,
  getWeightGainCurve,
}) => {

  const columns = new Map(
    [
      [IDS_COLUMNS.lotesColumn, LotesColumn(exibirTrocaCategoria)],
      [IDS_COLUMNS.quantidadeColumn, QuantidadeColumn()],
      [IDS_COLUMNS.sanidadeColumn, SanidadeColumn(exibirColunaSanidade)],
      [IDS_COLUMNS.objetivoColumn, ObjetivoColumn(objetivos, handleAlteraLote)],
      [IDS_COLUMNS.pesoObjetivoColumn, PesoObjetivoColumn(mensagemPesoObjetivo, calculaDataParaPesoObjetivo, handleAlteraLote)],
      [IDS_COLUMNS.pesoPesadoColumn, PesoPesadoColumn(handleAlteraLote)],
      [IDS_COLUMNS.dataPesagemColumn, DataPesagemColumn(handleAlteraLote, atualizaEstadoDataPesagem)],
      [IDS_COLUMNS.pesoProjetadoColumn, PesoProjetadoColumn(obterProjecaoSemestrePorLote, exibirColunaPesoProjetado, showLoteModal, getWeightGainCurve)]
    ]
  )

  if (!exibirColunaPesoProjetado) {
    columns.delete(IDS_COLUMNS.pesoProjetadoColumn);
  }

  const lotesEnriquecidosComObjetivos = lotes.map((lote) => {
    if (lote.IdObjetivo) {
      const Objetivo = objetivos.find((objetivo) => objetivo.IdObjetivo === lote.IdObjetivo);
      return { ...lote, Objetivo };
    }
    return lote;
  })

  return (
    <div className={classes.divTable}>
      <TabelaProdap
        modoOrdenacao={constants.orderMode.LOCAL}
        ordemInicial="quantidadeName"
        ordenarAsc="asc"
        tabelaEsquerda={true}
        ativarPaginacao
        linhas={lotesEnriquecidosComObjetivos}
        colunas={Array.from(columns.values())}
        ComponenteListaVazia={() => <LotesTabelaEmpty naoTemLotes={naoTemLotes} naoTemLotesComFiltro={naoTemLotesComFiltro} />}
      />
    </div>
  );
};

const styles = () => {
  return {
    divTable: {
      width: "100%",
      marginBottom: 25,
      fontSize: 14,
    },
  };
};

export default withStyles(styles)(BatchListingTable);
