import React, { useEffect, useState, useMemo, useRef } from "react";
import { InputAdornment, OutlinedInput, withStyles } from "@material-ui/core";
import IntervalInput from "./intervalInput";
import { formatPercentFieldGoals } from "features/goalPlan/goalPlanUtils";

const GoalInput = ({
  classes,
  disabled,
  goalLine,
  onChangeGoal,
  monthIndex,
}) => {
  const inputRef = useRef(null);
  const { parameterType, values, indicatorName, indicatorUnit } = goalLine;
  const [intervalParameter, setIntervalParameter] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const checkValue = (value) => {
    if (indicatorUnit.includes("%") && value) {
      return formatPercentFieldGoals(value, indicatorName);
    }
    return value.toString();
  };

  const value = useMemo(
    () =>
      Array.isArray(values) && values[monthIndex] ? values[monthIndex] : values,
    [goalLine]
  );

  const handleInput = () => {
    if (parameterType && parameterType === "interval") {
      setIntervalParameter(true);
    } else {
      setIntervalParameter(false);
    }
  };

  const adjustInputSize = (inputValue) => {
    if (!inputValue) return;
    if (inputRef.current === null || inputRef.current === undefined) return;
    let sizeInput = String(inputValue).length * 8 + 16;
    if (sizeInput < 42) {
      sizeInput = 42;
    }
    inputRef.current.style.width = sizeInput + "px";
  };

  useEffect(() => {
    if (value) {
      adjustInputSize(value.value);
      setInputValue(value.value === null ? "" : value.value);
    }
  }, [value]);

  useEffect(() => {
    handleInput();
  }, [parameterType]);

  useEffect(() => {
    if (indicatorUnit.includes("%") && inputValue) {
      setInputValue(Number(inputValue).toFixed(2));
    }
  }, [indicatorUnit]);


  const onChangeInterval = (intervalValues) => {
    onChangeGoal(intervalValues, indicatorName, monthIndex);
  };

  const getGoalValue = (value) => {
    if (indicatorUnit.includes("%")) {
      const valueToCheck = checkValue(value);
      return valueToCheck
    }
    return value ? checkValue(value) : undefined;
  };

  const onChangeSingle = (event) => {
    const goalValue = getGoalValue(event.target.value);
    onChangeGoal({ value: goalValue }, indicatorName, monthIndex);
    setInputValue(goalValue);
    adjustInputSize(event.target.value.replace(/\s/g, "&nbsp;"));
  };

  const checkIndicator = () => {
    if (indicatorUnit.includes("%")) {
      return "%";
    }

    if (indicatorUnit.includes("cm")) {
      return "CM";
    }

    if (indicatorUnit.includes("kg")) {
      return "KG";
    }

    if (indicatorUnit.includes("dias")) {
      return "DIAS";
    }

    if (indicatorUnit.includes("@")) {
      return "@";
    }

    if (indicatorUnit.includes("cabeças")) {
      return "CABEÇAS";
    }

    if (indicatorUnit.includes("ua/hectare")) {
      return "UA/HECTARE";
    }
  };

  return intervalParameter ? (
    <IntervalInput
      testId={`${indicatorName}-Goal-IntervalInput`}
      disabled={disabled}
      classes={classes}
      value={value}
      onChangeInterval={onChangeInterval}
      goalLine={goalLine}
    />
  ) : (
    <OutlinedInput
      data-testid={`${indicatorName}-Goal-SimpleInput`}
      classes={{
        input: classes.innerInput,
        disabled: classes.disabled,
      }}
      disabled={disabled}
      className={classes.inputGoal}
      type={indicatorUnit.includes("%") ? "number" : "text"}
      value={inputValue}
      inputRef={inputRef}
      endAdornment={
        <InputAdornment position="end">{checkIndicator()}</InputAdornment>
      }
      onChange={onChangeSingle}
    />
  );
};

const styles = (theme) => ({
  innerInput: {
    boxSizing: "border-box",
    borderRadius: 4,
  },
  disabled: {
    height: 53,
    borderRadius: "4px",
    color: theme.palette.default1.light,
    backgroundColor: theme.palette.disabled.light,
  },
  inputGoal: {
    fontSize: 12,
    height: 53,
    width: "100%",
    justifyContent: "center",
    "&>fieldset": {
      borderRadius: "4px",
      border: `solid 1px ${theme.palette.default2.light}`,
    },
    alignSelf: "center",
    "&>input": {
      padding: 4,
      width: 42,
      textAlign: "center",
    },
    "&>input[type='number']::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&>input[type='number']::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&>input[type='number']": {
      "-moz-appearance": "textfield",
    },
  },
  inputIntervalWrapper: {
    "&[disabled]": {
      color: theme.palette.default1.light,
      backgroundColor: theme.palette.disabled.light,
    },
    borderRadius: "4px",
    border: `solid 1px rgba(0, 0, 0, 0.23)`,
    padding: "2px 8px",
    display: "flex",

    width: "100%",
    minWidth: "42px",
    height: 53,
    justifyContent: "center",
    "&>input[type='number']::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&>input[type='number']::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&>input[type='number']": {
      "-moz-appearance": "textfield",
    },
  },
  inputInterval: {
    "&[disabled]": {
      color: theme.palette.default1.light,
      backgroundColor: theme.palette.disabled.light,
    },
    "font-size": "14px",
    border: "none",
    width: "45%",
    padding: "1px 1px",
    outline: 0,
  },
  separator: {
    alignSelf: "center",
    fontSize: 14,
  },
});

export default withStyles(styles)(GoalInput);
