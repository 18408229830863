import * as EmailValidator from 'email-validator';

export default function(emails) {
  let emailsValidos = true;
  emails = emails.trim();
  if (emails.slice(-1) === ';') {
    emails = emails.slice(0, -1);
  }

  let listaEmails = emails.split(';');

  let listaEmailsFormatada = listaEmails.map(function(email) {
    if (!EmailValidator.validate(email.trim())) emailsValidos = false;
    return email.trim();
  });

  const retornoProcessamento = {
    valido: emailsValidos,
    listaEmails: listaEmailsFormatada
  };

  return retornoProcessamento;
}
