/* eslint-disable react/jsx-no-duplicate-props*/
import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import { Grid, TextField, InputAdornment, withStyles } from "@material-ui/core";
import { DatePicker } from "material-ui-pickers";
import Typography from "@material-ui/core/Typography";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { TextLabel } from "../../shared/components";
import SelectDropdown from "components/SelectDropdown";

export const MovimentacaoForm = (props) => {
  const {
    movimentacao,
    categorias,
    classes,
    handleChangeCategoria,
    handleChangeDataRegistro,
    handleChangePesoPesado,
    handleChangeRendimentoCarcada,
    handleChangeTipoMovimentacao,
    handleChangeTipoRegistro,
    adicionarMovimentacao,
    habilitarMovimentacao,
    abreDialogExclusao,
    handleChangeQuantidade,
    rendimentoCarcadaError,
    tiposMovimentacao,
  } = props;

  const tiposMovimentacaoEntrada = (tiposMovimentacao || []).filter((t) =>
    ["Transferência", "Compra"].includes(t.DescricaoTipoMovimentacao)
  );
  const tiposMovimentacaoSaida = (tiposMovimentacao || []).filter((t) =>
    ["Transferência", "Venda"].includes(t.DescricaoTipoMovimentacao)
  );
  const disabledButton =
    !movimentacao.Quantidade ||
    !movimentacao.IdCategoriaAnimal ||
    rendimentoCarcadaError ||
    !movimentacao.PesoPesado ||
    !movimentacao.TipoMovimentacao;

  const categoriaOptions = categorias.map((categoria) => ({
    value: categoria.IdCategoriaAnimal,
    label: categoria.Nome,
  }));

  const tipoRegistroOptions = [
    {
      value: 1,
      label: "Entrada",
    },
    {
      value: 2,
      label: "Saída",
    },
  ];

  const tipoMovimentacaoOptions =
    movimentacao.TipoRegistro === 1
      ? tiposMovimentacaoEntrada.map((tipo) => ({
          value: tipo.IdTipoMovimentacao,
          label: tipo.DescricaoTipoMovimentacao,
        }))
      : tiposMovimentacaoSaida.map((tipo) => ({
          value: tipo.IdTipoMovimentacao,
          label: tipo.DescricaoTipoMovimentacao,
        }));

  return (
    <div>
      <Grid item sm={12} xs={12}>
        <Typography className={classes.titulo2} gutterBottom>
          Nova Movimentação
        </Typography>
      </Grid>
      <Grid item sm={12} xs={12}>
        <Typography className={classes.subtitulo} gutterBottom>
          Informações gerais
        </Typography>
      </Grid>
      <div className={classes.formContainer}>
        <SelectDropdown
          id="select_IdCategoria"
          label={{ text: "CATEGORIA ANIMAL", required: true }}
          options={categoriaOptions}
          value={movimentacao.IdCategoriaAnimal}
          onChange={handleChangeCategoria}
          styles={{ width: "221px" }}
        />

        <SelectDropdown
          id="select_TipoRegistro"
          label={{ text: "MOVIMENTAÇÃO", required: true }}
          options={tipoRegistroOptions}
          value={movimentacao.TipoRegistro}
          onChange={handleChangeTipoRegistro}
          renderOptionComponent={CustomOption}
          styles={{ width: "221px" }}
        />
        <FormControl>
          <TextField
            value={movimentacao.Quantidade ? movimentacao.Quantidade : " "}
            id={"adornment-quantidade"}
            inputProps={{
              style: {
                paddingBottom: 12,
              },
            }}
            onChange={handleChangeQuantidade}
            label={"QUANTIDADE"}
            required
          />
        </FormControl>

        <DatePicker
          id={"adornment-data"}
          disableFuture
          keyboard
          disableOpenOnEnter
          className={classes.campoData}
          label={
            movimentacao.TipoRegistro === 1
              ? "DATA DE ENTRADA"
              : "DATA DE SAÍDA"
          }
          style={{ padding: "5px 0" }}
          required
          invalidDateMessage="O Formato da data é inválido"
          maxDateMessage="A data inserida não pode ser maior que a data atual"
          format="DD/MM/YYYY"
          placeholder="dd/mm/aaaa"
          mask={(value) =>
            value
              ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
              : []
          }
          value={movimentacao.DataRegistro}
          onChange={(date) => {
            handleChangeDataRegistro(date);
          }}
        />

        <SelectDropdown
          id="select_IdTipoMovimentacao"
          label={{ text: "TIPO", required: true }}
          options={tipoMovimentacaoOptions}
          value={movimentacao.TipoMovimentacao}
          onChange={handleChangeTipoMovimentacao}
          styles={{ width: "221px" }}
        />

        <FormControl>
          <TextField
            InputProps={{
              endAdornment: <InputAdornment position="end">KG</InputAdornment>,
            }}
            value={movimentacao.PesoPesado ? movimentacao.PesoPesado : " "}
            className={classes.textoPesoPesado}
            inputProps={{
              style: {
                paddingBottom: 12,
              },
            }}
            id={"adornment-peso"}
            onChange={handleChangePesoPesado}
            label={"PESO PESADO"}
            required
          />
        </FormControl>
        {movimentacao.TipoRegistro === 2 && (
          <FormControl className={classes.textoCarcaca}>
            <TextField
              value={
                movimentacao.RendimentoDeCarcaca
                  ? movimentacao.RendimentoDeCarcaca
                  : " "
              }
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              error={rendimentoCarcadaError}
              helperText={
                rendimentoCarcadaError && "O valor não pode ser acima de 100"
              }
              id={"adornment-rendimento"}
              onChange={handleChangeRendimentoCarcada}
              label="RENDIMENTO DE CARCAÇA"
            />
          </FormControl>
        )}
      </div>

      <Grid item sm={12} xs={12} style={{ marginTop: "2%" }}>
        <Button
          id="habilitarMovimentacao"
          className={classes.corpoBotoesFormCancelar}
          onClick={() => habilitarMovimentacao(false)}
        >
          Cancelar
        </Button>

        <Button
          id="botaoAdicionar"
          disabled={disabledButton}
          variant="contained"
          color="primary"
          onClick={() => adicionarMovimentacao(movimentacao)}
          className={classes.corpoBotoesForm}
        >
          Salvar
        </Button>
        {disabledButton && (
          <TextLabel
            color="error"
            size={12}
            label={
              <span>
                Os campos marcados com
                <span className={classes.spanAsterisco}> * </span>
                são de preenchimento obrigatório.
              </span>
            }
          />
        )}
        {movimentacao.IdRegistro ? (
          <Button
            id="excluirMovimentacao"
            onClick={() => abreDialogExclusao(movimentacao)}
            className={classes.botaoExcluir}
          >
            <DeleteIcon style={{ color: "#FF0000" }}></DeleteIcon>
            <Typography className={classes.excluirMov}>
              Excluir Movimentação
            </Typography>
          </Button>
        ) : null}
      </Grid>
    </div>
  );
};

const CustomOption = (props, option) => (
  <div {...props} style={{ display: "flex" }}>
    <FiberManualRecordIcon
      style={{
        color: option.value === 1 ? "#4fd26b" : "#e98e4f",
        width: "20px",
        height: "20px",
        marginRight: "5px",
      }}
    />
    {option.label}
  </div>
);

const styles = (theme) => {
  return {
    spanAsterisco: {
      color: "#666666",
      verticalAlign: "bottom",
      fontSize: "21px",
    },
    textoCarcaca: {
      marginRight: "6%",
    },
    titulo2: {
      marginTop: "20px",
      fontSize: "20px",
      fontWeight: "bold",
      fontStyle: "medium",
      fontStretch: "medium",
      lineHeight: "medium",
      letterSpacing: "medium",
      color: "#333333",
    },
    textoPesoPesado: {
      width: "120px",
      marginRight: "4%",
    },
    corpoBotoesForm: {
      marginRight: "20px",
    },
    corpoBotoesFormCancelar: {
      marginRight: "20px",
      color: "#8e8e93",
    },
    botaoExcluir: {
      float: "right",
    },
    excluirMov: {
      color: "#FF0000",
      fontWeight: "bold",
    },
    DropDown: {
      marginRight: "3%",
      marginBottom: "2.25rem",
    },
    DropDownTipoRegistro: {
      marginRight: "3%",
    },
    subtitulo: {
      width: "227px",
      height: "16px",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "bold",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.03px",
      color: "#8e8e93",
      marginTop: "24px",
      marginBottom: "16px",
    },
    campoData: {
      width: "157px",
      opacity: "0.9",
      fontSize: "8px",
    },
    formContainer: {
      display: "flex",
      flexDirection: "row",
      gap: "32px",
      flexWrap: "wrap",
    },
  };
};

export default withStyles(styles)(MovimentacaoForm);
