import {
  LISTA_OBJETIVOS,
  EXIBIR_CARREGANDO,
} from "../../../redux/actions/actionsConstants";

export const SALVAR_OBJETIVO = "SALVAR_OBJETIVO";
export const EXCLUIR_OBJETIVO = "EXCLUIR_OBJETIVO";
export const EDITAR_OBJETIVO = "EDITAR_OBJETIVO";
export const REATIVAR_OBJETIVO = "REATIVAR_OBJETIVO";

export const listaObjetivos = (objetivos) => {
  return {
    type: LISTA_OBJETIVOS,
    payload: {
      Objetivos: objetivos,
    },
  };
};

export const salvarObjetivo = (objetivo) => {
  return {
    type: SALVAR_OBJETIVO,
    payload: objetivo,
  };
};

export const excluirObjetivo = () => {
  return {
    type: EXCLUIR_OBJETIVO,
  };
};

export const editarObjetivo = () => {
  return {
    type: EDITAR_OBJETIVO,
  };
};

export const ativarObjetivo = (objetivo) => {
  return {
    type: REATIVAR_OBJETIVO,
    payload: objetivo,
  };
};

export const exibirCarregando = (exibir) => {
  return {
    type: EXIBIR_CARREGANDO,
    payload: exibir,
  };
};
