/* eslint-disable react/jsx-no-duplicate-props*/
import React from "react";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

function Input({
  InputProps,
  label,
  tooltipMessage,
  isLoading,
  isSearchable,
  variant="outlined",
  emptyText,
  ...params
}) {
  return (
    <TextField
      {...params}
      sx={getSx(isSearchable)}
      placeholder={emptyText}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <EndAdornment
            isLoading={isLoading}
            tooltipMessage={tooltipMessage}
            InputProps={InputProps}
          />
        ),
      }}
      label={label && <Label label={label} />}
      variant={variant}
    />
  );
}

function EndAdornment({ isLoading, tooltipMessage, InputProps }) {
  return (
    <div
      style={{
        height: "26px",
        display: "flex",
        alignItems: "center",
      }}
    >
      {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
      {tooltipMessage && (
        <Tooltip title={tooltipMessage} placement="right-end" arrow>
          <InfoIcon color="info" fontSize="small" />
        </Tooltip>
      )}
      {InputProps.endAdornment}
    </div>
  );
}

function Label({ label }) {
  return (
    <div>
      {label.text}
      {label.required && <span>*</span>}
    </div>
  );
}

function getSx(isSearchable) {
  return {
    input: {
      fontSize: "14px",
      "&:hover": {
        cursor: "pointer",
      },
      "&:focus": {
        cursor: isSearchable ? "auto" : "pointer",
        caretColor: isSearchable ? "auto" : "transparent",
      },
    },
  };
}

export default Input;
