import React, { useCallback, useEffect, useState } from "react";
import Notifier from "./notifier/index.js";
import PendencyMovementsModal from "./pendencyMovementsModal/index.js";
import { WarningAmberOutlined } from "@mui/icons-material";
import { connect } from "react-redux";
import pendencyService from "features/pendency/pendencyService.js";
import dayjs from "dayjs";
import history from "features/app/History";
import useCookNotification from "./hooks/useCookNotification.js";
import { withRouter } from "react-router-dom";

const NOTIFICATIONS_CONFIG = {
  PENDENCIES: {
    ID: "pendencies",
    TITLE: "Movimentações pendentes",
    ICON: <WarningAmberOutlined color="error" />,
  },
};

const isValidPendencyData = (pendencyData) => dayjs(pendencyData).isValid();

const calcCounterBadgeNotifications = (itens, pendencyData) => {
  return itens.reduce((prev, current) => {
    if (current.id === NOTIFICATIONS_CONFIG.PENDENCIES.ID) {
      if (!isValidPendencyData(pendencyData) || pendencyData === undefined) {
        return prev + 1;
      }
    }
    return prev;
  }, 0);
};

const Notification = ({ farmId, location }) => {
  const [itensNotification, setItensNotification] = useState([]);
  const [pendencyMovementsModal, setPendencyMovementsModal] = useState(false);
  const [pendencyData, setPendencyData] = useState();
  const [redirectToPendencies, setRedirectToPendencies] = useState(false);

  const [getPendencie, setPendencie] = useCookNotification();

  useEffect(() => {
    setPendencyData(getPendencie(farmId));
  }, [farmId]);

  useEffect(() => {
    setPendencie(farmId, pendencyData);
  }, [pendencyData, farmId]);

  useEffect(() => {
    if (
      redirectToPendencies &&
      isValidPendencyData(pendencyData) &&
      pendencyData !== undefined
    ) {
      history.push("/movimentacoesPendentes");
    }
  }, [redirectToPendencies, pendencyData]);

  const actionPendencyNotification = useCallback(
    (callback) => {
      if (!isValidPendencyData(pendencyData) || pendencyData === undefined) {
        callback();
        setRedirectToPendencies(true);
        return;
      }

      history.push("/movimentacoesPendentes");

      return;
    },
    [pendencyData]
  );

  const checkPendencies = useCallback(
    async (farmId) => {
      try {
        const result = await pendencyService.getLastPendencyByFarmId(farmId);

        const baseDate = dayjs().subtract(7, "days");

        if (
          result &&
          result.data &&
          result.data.data &&
          baseDate.isBefore(result.data.data.operationDate)
        ) {
          const pendenciesDateStorage = pendencyData;
          if (
            pendenciesDateStorage &&
            dayjs(Number(pendenciesDateStorage)).isBefore(
              result.data.data.operationDate
            )
          ) {
            setPendencyData(null);
          }

          const setDatePendenciesInLocalStorage = () => {
            setPendencyData(dayjs(result.data.data.operationDate).valueOf());
          };

          return {
            icon: NOTIFICATIONS_CONFIG.PENDENCIES.ICON,
            title: NOTIFICATIONS_CONFIG.PENDENCIES.TITLE,
            id: NOTIFICATIONS_CONFIG.PENDENCIES.ID,
            action: () => {
              actionPendencyNotification(setDatePendenciesInLocalStorage);
            },
          };
        }
      } catch (error) {
        return null;
      }

      return null;
    },
    [pendencyData, actionPendencyNotification]
  );

  const checkItensToNotificate = async (farmId) => {
    const itens = [];

    const notificatePendencies = await checkPendencies(farmId);
    if (notificatePendencies) itens.push(notificatePendencies);

    setItensNotification(itens);
  };

  useEffect(() => {
    if (farmId) {
      checkItensToNotificate(farmId);
    }
  }, [farmId, pendencyData, location.pathname]);

  const countBadge = calcCounterBadgeNotifications(
    itensNotification,
    pendencyData
  );

  return (
    <>
      <Notifier itens={itensNotification} badgeCount={countBadge} />
      {pendencyMovementsModal ? (
        <PendencyMovementsModal
          onClose={() => setPendencyMovementsModal(false)}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = ({ fazenda }) => {
  return {
    farmId: fazenda.FazendaSelecionada,
    currentFarm: fazenda.currentFarm,
  };
};

export default withRouter(connect(mapStateToProps, null)(Notification));
