import React from "react";
import { Switch, Route } from "react-router-dom";
import MapaCadastro from "features/manejo/views/Mapa/MapaCadastroContainer";
import MapaVinculo from "features/manejo/views/Mapa/MapaVinculoContainer";
import MapaFarol from "features/manejo/views/Mapa/MapaFarolContainer";

export const Mapa = () => {
  return (
    <div style={{ position: "relative" }}>
      <Switch>
        <Route path="/cadastro(.*)" component={MapaCadastro} />
        <Route path="/vinculo" component={MapaVinculo} />
        <Route path="/farol" component={MapaFarol} />
      </Switch>
    </div>
  );
};

export default Mapa;
