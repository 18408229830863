import React from "react";
import { withStyles } from "@material-ui/core/styles";

function Footer({ classes, isEdition, onSubmit, onCancel }) {
  return (
    <div className={classes.container}>
      <button className={classes.cancelButton} type="button" onClick={onCancel}>
        <span>CANCELAR</span>
      </button>
      <button className={classes.submitButton} type="button" onClick={onSubmit}>
        <span>{isEdition ? "SALVAR EDIÇÃO" : "SALVAR USUÁRIO"}</span>
      </button>
    </div>
  );
}

const styles = (theme) => ({
  container: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "flex-end",
    gap: "16px",
    "& span": {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
    },
  },
  cancelButton: {
    backgroundColor: "#fff",
    border: "none",
    color: "#333333",
    padding: "12px 16px",
    cursor: "pointer",
  },
  submitButton: {
    backgroundColor: theme.palette.primary.main,
    border: "none",
    borderRadius: "18px",
    color: "#fff",
    padding: "12px 16px",
    cursor: "pointer",
  },
});

export default withStyles(styles)(Footer);
