import { FazendaSelecionadaContainer } from "features/shared/crossCuttingComponents";
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { AppBar as AppBarCustomizado, AppBarConteudoDefault } from "../layout";
import { NavegacaoTabs } from "../navegacao";
import SupplementationRouter from "./supplementation/views/supplementationRouter";

const COLLECT_TABS = [
  {
    id: "supplementation",
    label: "Suplementação",
    exibir: true,
  },
];
const CollectRouter = ({ match }) => {
  const [selectedTab, setSelectedTab] = React.useState("Suplementação");

  const onChangeTab = (event) => {
    setSelectedTab(event.target.textContent);
  };

  const farmNameAppBar = () => {
    return (
      <FazendaSelecionadaContainer
        render={(fazenda) => {
          const textoSecundarioAppBar = fazenda
            ? fazenda.NomeReduzido
            : "NENHUMA FAZENDA SELECIONADA";
          return <AppBarConteudoDefault tituloAppBar={textoSecundarioAppBar} />;
        }}
      />
    );
  };

  return (
    <>
      <AppBarCustomizado
        conteudoCustomizadoAppBar={farmNameAppBar()}
        id="appBarPersonalizado"
      />
      <NavegacaoTabs
        tabs={COLLECT_TABS}
        mudarTab={onChangeTab}
        abaSelecionada={selectedTab}
        styles={{
          backgroundColor: "#f8f8f8",
          minHeight: 60,
          display: "flex",
          alignItems: "center",
        }}
        estilosTab={{
          fontSize: 16,
        }}
      />
      <div style={styles.contentWrapper}>
        <Switch>
          <Route exact path={`${match.path}/`}>
            <Redirect to={`${match.path}/suplementacao`} />
          </Route>
          <Route
            path={`${match.path}/suplementacao`}
            component={SupplementationRouter}
          />
        </Switch>
      </div>
    </>
  );
};

const styles = {
  contentWrapper: {
    width: "100%",
    minHeight: "93vh",
    paddingTop: 20,
    paddingBottom: 40,
    backgroundColor: "#fff",
  },
  appBarTitle: {
    fontFamily: "Roboto",
    fontSize: "18px",
    color: "#444",
  },
};
export default CollectRouter;
