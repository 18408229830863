import React from "react";
import PlanningRegisterPage from "./planningRegisterPage";
import { connect } from "react-redux";
import {
  disableLoading,
  enableLoading,
  notifyError as notifyErrorAction,
  notifySuccess as notifySuccessAction,
} from "../../redux/planningOperations";
import { useSubmitUtils } from "features/planning/hooks/useSubmitUtils";
import ConfirmationModal from "features/shared/components/confirmationModal";
import ExitModal from "./exitModal";
import { useExitConfirmation } from "../../hooks/useExitConfirmation";

const PlanningRegisterContainer = ({
  farmId,
  enableLoading,
  disableLoading,
  notifyError,
  notifySuccess,
  goBack = () => {},
  changeTabRequested,
  onCancelChangeTab,
}) => {
  const {
    showExitModal,
    handleExitModalCancel,
    handleExitModalSubmmit,
    requestExit,
  } = useExitConfirmation({ goBack, changeTabRequested, onCancelChangeTab });

  const callbackSubmit = () => {
    goBack();
  };

  const [showConfirmationModal, { onCreatePlan }] = useSubmitUtils({
    farmId,
    enableLoading,
    disableLoading,
    notifyError,
    notifySuccess,
    callbackSubmit,
  });

  return (
    <>
      <PlanningRegisterPage
        farmId={farmId}
        enableLoading={enableLoading}
        disableLoading={disableLoading}
        notifyError={notifyError}
        onSubmit={onCreatePlan}
        goBack={requestExit}
      />

      {showConfirmationModal !== null && (
        <ConfirmationModal
          title={showConfirmationModal.title}
          text={showConfirmationModal.text}
          submitButtonTitle={showConfirmationModal.buttonConfirmText}
          onSubmit={showConfirmationModal.onConfirm}
          onCancel={showConfirmationModal.onCancel}
        />
      )}

      {showExitModal && (
        <ExitModal
          onSubmit={handleExitModalSubmmit}
          onCancel={handleExitModalCancel}
        />
      )}
    </>
  );
};

export const mapDispatchToProps = {
  enableLoading: enableLoading,
  disableLoading: disableLoading,
  notifyError: notifyErrorAction,
  notifySuccess: notifySuccessAction,
};

const mapStateToProps = ({ fazenda }) => {
  return {
    farmId: fazenda.FazendaSelecionada,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanningRegisterContainer);
