import React from "react";
import { withStyles, TableCell, TableRow } from "@material-ui/core";
import moment from "moment";

export const IngredientRegisterModalList = (props) => {
  const { isInternalUser, i, item, classes } = props;
  const dataHora = moment(item.DataCriacao)
    .format("DD/MM/YYYY HH:mm")
    .split(" ");
  return (
    <TableRow key={`linha-${i}`}>
      <TableCell
        id={`tipo-operacao-${i}`}
        align="center"
        className={classes.tipoRow}
      >
        {item.TipoOperacaoIngrediente.Tipo}
      </TableCell>
      <TableCell id={`usuario-${i}`} align="center" className={classes.userRow}>
        {item.UsuarioCriacao}
      </TableCell>
      {isInternalUser && <TableCell
        id={`codigo-PA-ingrediente-${i}`}
        align="center"
        className={classes.codigoRow}
        data-testid="Codigo-ingrediente-PA"
      >
        {item.ProductId ? (
      <>
        <span  className={classes.productRow}>
          {item.Produto.name}
        </span>
        <span className={classes.productCode}>
          {item.Produto.code}
        </span>
      </>) : (
      <span  className={classes.productRow}>
        -
      </span>
    )}
      </TableCell>
      }
      <TableCell
        id={`materia-prima-${i}`}
        align="center"
        className={classes.materiaRow}
      >
        {item.Ingrediente.MateriaPrima.Nome}
      </TableCell>
      <TableCell
        id={`tipo-ingrediente-${i}`}
        align="center"
        className={classes.row}
      >
        {item.Descricao}
      </TableCell>
      <TableCell align="center" className={classes.row}>
        {item.Custo ? `R$${item.Custo.toFixed(2)}` : "-"}
      </TableCell>
      <TableCell id={`data-${i}`} align="center" className={classes.row}>
        {item.DataCompra ? moment(item.DataCompra).format("DD/MM/YYYY") : "-"}
      </TableCell>
      <TableCell id={`data-${i}`} align="center" className={classes.row}>
        <div>{dataHora[0]}</div>
        <div>{dataHora[1]}</div>
      </TableCell>
    </TableRow>
  );
};

const styles = (theme) => {
  return {
    productRow: {
      textAlign: "center",
      display: "block"
    },
    productCode: {
      textAlign: "center",
      display: "block",
      fontWeight: "500"
    }
  }
}

export default withStyles(styles)(IngredientRegisterModalList);
