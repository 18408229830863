import React, { Component } from "react";
import PropTypes from "prop-types";
import auth from "../../authentication/Auth";
import styles from "../../../styles/MenuLateral/CadastroRetiro";
import ListaRetiro from "./listaRetiro.jsx";
import { DialogoConfirmacaoAcao } from "features/shared/components/DialogoConfirmacaoAcao";
import { Button, TextField, Grid, withStyles } from "@material-ui/core";
import CustomLabel from "features/shared/components/CustomLabel";

export class CadastroRetiro extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmacaoExclusaoAberto: false,
      retiro: {
        IdFazenda: this.props.fazendaSelecionada,
        Nome: "",
        NomeReduzido: "",
      },
      listaRetiros: [],
      retiroClicado: {
        IdRetiro: null,
        Nome: "",
      },
      erroNome: false,
    };

    this.auth = auth;
  }

  async componentDidMount() {
    await this.props.listarRetiros(this.props.fazendaSelecionada);
    this.setState({ listaRetiros: this.props.listaRetiros });
  }

  abreDialogExclusao = (retiro) => {
    this.setState({ confirmacaoExclusaoAberto: true, retiroClicado: retiro });
  };

  fechaDialogExclusao = () => {
    this.setState({ confirmacaoExclusaoAberto: false });
  };

  deletaRetiro = async (id) => {
    await this.props.deletarRetiro(id);
    await this.props.listarRetiros(this.props.fazendaSelecionada);

    this.setState({ listaRetiros: this.props.listaRetiros });
  };

  editaRetiro = async (retiro, novoNome) => {
    const { listaRetiros } = this.props;

    if (retiro.Nome !== novoNome && novoNome !== "") {
      retiro = {
        ...retiro,
        Nome: novoNome,
        NomeReduzido: novoNome,
      };

      const erro = listaRetiros.some(
        (retiro) => retiro.Nome.trim() === novoNome
      );

      await this.props.atualizarRetiro(retiro, erro);
      await this.props.listarRetiros(this.props.fazendaSelecionada);
      this.setState({ listaRetiros: this.props.listaRetiros });
    }
  };

  handleChange = (name) => (event) => {
    this.validaNomeIgual(event.target.value.trim());
    this.setState({
      retiro: {
        ...this.state.retiro,
        [name]: event.target.value,
        NomeReduzido: event.target.value,
      },
    });
  };

  salvarRetiros = async (retiro) => {
    await this.props.criarRetiros([retiro]);
    await this.props.listarRetiros(this.props.fazendaSelecionada);

    this.setState({
      listaRetiros: this.props.listaRetiros,
      retiro: {
        IdFazenda: this.props.fazendaSelecionada,
        Nome: "",
        NomeReduzido: "",
      },
    });
  };

  validaNomeIgual = (nome) => {
    const { listaRetiros } = this.state;
    this.setState({
      erroNome: listaRetiros.some((retiro) => retiro.Nome.trim() === nome),
    });
  };

  render() {
    const { classes, listaRetiros } = this.props;
    const { retiro, confirmacaoExclusaoAberto, erroNome } = this.state;

    return (
      <div>
        <Grid item xs={12}>
          <form noValidate autoComplete="off">
            <Grid container direction="collumn">
              <Grid container direction="row">
                <TextField
                  item
                  id="novoRetiro"
                  placeholder="Informe o nome do retiro"
                  label={<CustomLabel required label="Nome do retiro" />}
                  InputLabelProps={{ shrink: true }}
                  error={erroNome}
                  helperText={
                    erroNome ? "Retiro já existente. Tente um novo!" : ""
                  }
                  value={retiro.Nome}
                  onChange={this.handleChange("Nome")}
                  className={classes.textField}
                  margin="normal"
                  inputProps={{
                    maxLength: 20,
                  }}
                />
                <Button
                  item
                  className={classes.botaoSalvar}
                  id="botaoAdicionar"
                  disabled={!retiro.Nome.length || erroNome}
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => this.salvarRetiros(retiro)}
                >
                  Salvar
                </Button>
              </Grid>
              <Grid item xs={12} container>
                <ListaRetiro
                  classes={classes}
                  listaRetiros={listaRetiros}
                  abreDialogExclusao={this.abreDialogExclusao}
                  editaRetiro={this.editaRetiro}
                />

                <DialogoConfirmacaoAcao
                  id={`dialogConfirmaExclusaoFormula`}
                  tituloDialog={"Confirmar exclusão de Retiro"}
                  textoDialog={
                    <span id="mensagemConfirmaExclusaoFormula">
                      Você deseja excluir o Retiro?
                    </span>
                  }
                  dialogAberto={confirmacaoExclusaoAberto}
                  cancelarAcaoDialog={this.fechaDialogExclusao}
                  objetoSelecionado={this.state.retiroClicado.IdRetiro}
                  excluir={this.deletaRetiro}
                />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </div>
    );
  }
}

CadastroRetiro.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CadastroRetiro);
