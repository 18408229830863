import React, { Component } from "react";
import { connect } from "react-redux";
import { default as MapaGoogleCustomizadoComponent } from "./MapaGoogleCustomizado";
import PropTypes from "prop-types";

export class MapaGoogleCustomizado extends Component {
  render() {
    return (
      <MapaGoogleCustomizadoComponent
        id="containerMapaGoogle"
        zoomControl={this.props.zoomControl}
        scrollwheel={this.props.scrollwheel}
        panControl={this.props.panControl}
        draggable={this.props.draggable}
        scaleControl={this.props.scaleControl}
        mapTypeControl={this.props.mapTypeControl}
        coordenadasCentro={this.props.coordenadasCentro}
        onZoomChanged={this.props.onZoomChanged}
        HabilitaDesenho={this.props.HabilitaDesenho}
        coordenadasNovoPasto={this.props.coordenadasNovoPasto}
        Desenhando={this.props.desenhando}
        zoomPadrao={this.props.zoomPadrao}
        visualizarPontosColetas={this.props.visualizarPontosColetas}
        mapTypeId={this.props.mapTypeId}
      >
        {this.props.children}
      </MapaGoogleCustomizadoComponent>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    zoomControl: state.mapa.zoomControl,
    scrollwheel: state.mapa.scrollwheel,
    panControl: state.mapa.panControl,
    draggable: state.mapa.draggable,
    scaleControl: state.mapa.scaleControl,
    mapTypeControl: state.mapa.mapTypeControl,
    coordenadasNovoPasto: state.pasto.coordenadasNovoPasto,
    desenhando: state.pasto.Desenhando,
    visualizarPontosColetas: state.mapa.visualizarPontosColetas,
    mapTypeId: state.mapa.mapTypeId,
  };
};

MapaGoogleCustomizado.propTypes = {
  coordenadasCentro: PropTypes.object.isRequired,
  children: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(MapaGoogleCustomizado);
