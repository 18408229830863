import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import moment from "moment";

export const DialogInformacaoDataMudancaBaseDados = ({
  visivel,
  processaFechar,
  dataReferencia,
  dataInicio,
  dataFim,
}) => {
  const dataReferenciaMenosUm = moment(dataReferencia, "DD/MM/YYYY")
    .subtract(1, "day")
    .format("DD/MM/YYYY");
  return visivel ? (
    <Dialog
      id="informacaoDataMudancaBaseDados"
      open={visivel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={() => processaFechar()}
    >
      <DialogTitle id="alert-dialog-data-mudanca-base-dados-title">
        {"Erro ao filtrar a data selecionada."}
      </DialogTitle>
      <DialogContent id="alert-dialog-data-mudanca-base-dados-description">
        <DialogContentText>
          Prezado cliente, informamos que no dia{" "}
          <strong>{dataReferencia}</strong> realizamos uma reestruturação em
          nossa base de dados, de modo a trazer resultados cada vez mais
          assertivos. Devido a isso não será possível realizar a busca do dia{" "}
          <strong>{dataInicio}</strong> ao dia <strong>{dataFim}</strong>. Por
          favor, selecione um novo intervalo de datas até o dia{" "}
          <strong>{dataReferenciaMenosUm}</strong> ou iniciando no dia{" "}
          <strong>{dataReferencia}</strong>.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          id="botaoCancelar"
          onClick={() => processaFechar()}
          color="primary"
          autoFocus
        >
          FECHAR
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

DialogInformacaoDataMudancaBaseDados.propTypes = {
  dataReferencia: PropTypes.string.isRequired,
  dataInicio: PropTypes.string.isRequired,
  dataFim: PropTypes.string.isRequired,
  visivel: PropTypes.bool.isRequired,
  processaFechar: PropTypes.func.isRequired,
};

export default DialogInformacaoDataMudancaBaseDados;
