import SelectDropdown from "components/SelectDropdown";
import React from "react";

export const PlanningTableCategoryItem = ({
  categoryId,
  freeCategories,
  categories,
  onChangeCategory,
  addNewLine,
}) => {
  const onChange = (value) => {
    if (categoryId) {
      onChangeCategory(categoryId, value);
    } else {
      addNewLine(value);
    }
  };

  const selectedCategory = categories.find(
    (category) => category.IdCategoriaAnimal === categoryId
  );

  const options = freeCategories
    .map((category) => ({
      id: category.IdCategoriaAnimal,
      value: category.IdCategoriaAnimal,
      label: category.Nome,
      deleted: category.Excluido,
      active: category.Excluido ? "Inativas" : "Ativas",
    }))
    .sort((a, b) => (a.deleted === b.deleted ? 0 : b.deleted ? -1 : 1));

  if (selectedCategory) {
    options.unshift({
      id: selectedCategory.IdCategoriaAnimal,
      value: selectedCategory.IdCategoriaAnimal,
      label: selectedCategory.Nome,
      deleted: selectedCategory.Excluido,
      active: selectedCategory.Excluido ? "Inativas" : "Ativas",
      disabled: true,
    });
  }

  return (
    <div style={{ padding: "7px" }}>
      <SelectDropdown
        isSearchable
        emptyText="Selecione..."
        value={categoryId || null}
        options={options}
        groupBy={(formula) => formula.active}
        onChange={onChange}
        styles={{
          "& .MuiOutlinedInput-notchedOutline": { border: "none" },
        }}
      />
    </div>
  );
};
