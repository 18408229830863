import SwitchCustomizado from "features/shared/components/SwitchCustomizado";
import TabelaProdap from "features/shared/components/TabelaProdap";
import React from "react";
import { TruncatedTextWithTooltip } from "../../../../shared/components/TruncatedTextWithTooltip";
import { UserActionsMenu } from "./userActionsMenu";
import UserListEmpty from "./userListEmpty";

const getColunms = (onEdit, onSendLink, onCopyLink, onChangeStatus) => [
  {
    id: "name",
    titulo: "Nome Completo",
    ordenavel: false,
    pegaValor: (row) => row.nome,
  },
  {
    id: "login",
    titulo: "Login",
    ordenavel: false,
    pegaValor: (row) => row.login,
  },
  {
    id: "email",
    titulo: "E-mail",
    ordenavel: false,
    pegaValor: (row) => row.email,
  },
  {
    id: "farms",
    titulo: "Fazendas com acesso",
    ordenavel: false,
    pegaValor: (row) => (
      <TruncatedTextWithTooltip
        childrenText={row.fazendas.map((f) => f.nome)}
      />
    ),
  },
  {
    id: "retires",
    titulo: "Retiros",
    ordenavel: false,
    pegaValor: (row) => (
      <TruncatedTextWithTooltip childrenText={row.retiros.map((r) => r.nome)} />
    ),
  },
  {
    id: "permissions",
    titulo: "Permissão de acesso",
    ordenavel: false,
    pegaValor: (row) => (
      <TruncatedTextWithTooltip
        childrenText={row.permissoes.map((p) => p.nome)}
      />
    ),
  },
  {
    id: "status",
    titulo: "Ativo/Inativo",
    ordenavel: false,
    pegaValor: (row) => (
      <SwitchCustomizado
        checked={row.clientes[0].ativo}
        handleClick={() => onChangeStatus(row)}
      />
    ),
  },
  {
    id: "options",
    titulo: "Ações",
    ordenavel: false,
    pegaValor: (row) => (
      <UserActionsMenu
        user={row}
        onEdit={onEdit}
        onSendLink={onSendLink}
        onCopyLink={onCopyLink}
      />
    ),
  },
];

export const UserList = ({
  isFilterEmpty,
  getUsers,
  lastUpdate,
  onEdit,
  onSendLink,
  onCopyLink,
  onChangeStatus,
}) => {
  return (
    <div>
      <TabelaProdap
        servico={getUsers}
        atualizar={lastUpdate}
        colunas={getColunms(onEdit, onSendLink, onCopyLink, onChangeStatus)}
        ComponenteListaVazia={() => (
          <UserListEmpty isFilterEmpty={isFilterEmpty} />
        )}
        tabelaEsquerda
        ativarPaginacao
      />
    </div>
  );
};

export default UserList;
