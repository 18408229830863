import React from "react";
import WarningIcon from "@mui/icons-material/Warning";

export function InfoMorteComPendencia({ morteComPendenciaPasto }) {
  const plural = morteComPendenciaPasto.NomePasto.size > 1 && "s";

  return (
    <div style={styles.container}>
      <WarningIcon fontSize="24px" style={styles.warningIcon} />

      <div>
        <span style={styles.inportant}>Atenção:</span> você possui pendências
        não resolvidas no{plural} pasto{plural} <br />
        {morteComPendenciaPasto.NomePasto.size &&
          Array.from(morteComPendenciaPasto.NomePasto).map((nome) => (
            <span style={styles.bold}>{nome}.</span>
          ))}
        Para mais detalhes, clique no pasto ou acesse a tela{" "}
        <span style={styles.bold}>movimentações de rebanho.</span>
      </div>
    </div>
  );
}

const styles = {
  container: {
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "18px",
    border: "1px solid #EB394B",
    padding: "8px 6px",
    display: "flex",
    gap: "8px",
    borderRadius: "4px",
  },
  inportant: {
    color: "#EB394B",
    fontWeight: "700",
  },
  warningIcon: {
    color: "#EB394B",
    marginTop: "1px",
    fontSize: "20px",
  },
  bold: {
    fontWeight: "700",
  },
};
