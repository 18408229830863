import React, { Component } from "react";
import { connect } from "react-redux";
import Farol from "../../MenuLateral/Farol";
import { clienteLite } from "../../../../cliente/clienteUtils";

class FarolContainer extends Component {
  render() {
    return <Farol {...this.props.reduxProps} />;
  }
}

const mapStateToProps = (state) => {
  return {
    reduxProps: {
      farois: state.farol.Farois,
      exibirDetalhes: state.farol.exibirDetalhes,
      farolSelecionado: state.farol.FarolSelecionado,
      FazendaSelecionada: state.fazenda.FazendaSelecionada,
      isFazendaCreep: state.fazenda.TemCochoCreep,
      Toggles: state.fazenda.Toggles,
      TipoFarol: state.farol.TipoFarol,
      CiclosForragemFazenda: state.forragem.CiclosForragemFazenda,
      TipoIconeExibido: state.farol.TipoIconeExibido,
      PeriodoColeta: state.farol.PeriodoColeta,
      Pastos: state.pasto.Pastos,
      Currais: state.pasto.Currais,
      RetiroSelecionado: state.retiro.RetiroSelecionado,
      pastoSelecionado: state.pasto.PastoSelecionado,
      isClienteLite: clienteLite(state.cliente.ClienteSelecionado),
    },
  };
};

export default connect(mapStateToProps)(FarolContainer);
