import ReactGA from "react-ga";
import history from "../app/History";
import { createServiceHandler } from "../../services/ServiceHandler";
import { enviaDadosGAPorRotulo } from "../../shared/utils";
import * as AuthService from "./authService";

export class Auth {
  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
  }

  async login(username, password) {
    try {
      const authServiceResponse = await AuthService.login(username, password);

      if (!authServiceResponse || !authServiceResponse.data) {
        throw new Error("Erro ao autenticar usuário");
      }

      await this.handleAuthentication(authServiceResponse.data);
    } catch (error) {
      const loginOrPasswordInvalid = error.message.includes("401");
      const notAllowed = error.message.includes("403");
      const userNotFound = error.message.includes("404");

      if (loginOrPasswordInvalid) {
        throw new Error("Usuário ou senha inválidos");
      }
      if (notAllowed) {
        throw new Error("Usuário não está autorizado a acessar essa aplicação");
      }
      if (userNotFound) {
        throw new Error("Usuário não encontrado");
      }
      throw new Error("Erro ao autenticar usuário");
    }
  }

  async verifyPasswordToken(token) {
    try {
      const authServiceResponse = await AuthService.verifyPasswordToken(token);

      if (!authServiceResponse || !authServiceResponse.data) {
        throw new Error("Token inválido");
      }

      return authServiceResponse;
    } catch (error) {
      console.error("[Auth.verifyPasswordToken] - ", error.message);
      if (error.message.includes("404")) {
        throw new Error("Token não encontrado ou expirado");
      }
      throw error;
    }
  }

  async changePassword(token, password) {
    try {
      const authServiceResponse = await AuthService.changePassword(
        token,
        password
      );

      if (!authServiceResponse || !authServiceResponse.data) {
        throw new Error("Erro ao alterar senha");
      }
    } catch (error) {
      console.error("[Auth.changePassword] - ", error.message);
      if (error.message.includes("404")) {
        throw new Error("Token não encontrado ou expirado");
      }
      throw error;
    }
  }

  async resetPassword(email) {
    try {
      const authServiceResponse = await AuthService.resetPassword(email);

      if (!authServiceResponse || !authServiceResponse.data) {
        throw new Error("Erro ao enviar e-mail de recuperação de senha");
      }
    } catch (error) {
      throw error;
    }
  }

  async handleAuthentication(authResult) {
    try {
      if (authResult && authResult.access_token) {
        const email = authResult.email;
        localStorage.setItem("access_token", authResult.access_token);
        localStorage.setItem("Email", email);
        this.enviaDadosGA(email);

        const usuarios = await createServiceHandler()
          .get(
            `${process.env.REACT_APP_ENDPOINT}usuarios/api/v1/usuarios?filter={"where":{"Email":"${email}","$and": {"Excluido":false}}}`
          )
          .then((response) => response.data);

        if (usuarios.length === 1) {
          authResult.IdUsuario = usuarios[0].IdUsuario;
          authResult.Nome = usuarios[0].Nome;
          authResult.Email = usuarios[0].Email;
          authResult.Login = usuarios[0].Login;
          authResult.UsuarioInterno = usuarios[0].UsuarioInterno;
        } else if (usuarios.length === 0) {
          throw new Error("Email não cadastrado");
        } else {
          throw new Error("Inconsistencia no banco");
        }

        const permissions = authResult.permissions;
        this.setSession(authResult, permissions);
        ReactGA.set({
          userId: this.getSession().idUsuario,
        });

        history.replace("/");
      }
    } catch (error) {
      history.push("/");
      throw error;
    }
  }

  enviaDadosGA(email) {
    if (email) {
      const inicioEmailUsuario = email.split("@")[0];
      const finalEmailUsuario = email.split("@")[1];

      const acao = `Efetuando o Login inicio do E-Mail: ${inicioEmailUsuario} final do email ${finalEmailUsuario}`;
      const rotulo = `Usuário efetuou o login no sistema`;
      const categoria = "Login";
      enviaDadosGAPorRotulo(acao, rotulo, categoria);
    }
  }

  setSession(authResult, permissions) {
    localStorage.setItem("IdUsuario", authResult.IdUsuario);
    localStorage.setItem("Login", authResult.Login);
    localStorage.setItem("Imagem", authResult.Imagem);
    localStorage.setItem("Nome", authResult.Nome);
    localStorage.setItem("UsuarioInterno", authResult.UsuarioInterno);
    localStorage.setItem("usuario_tableau", permissions.usuario_tableau);
    localStorage.setItem("permisions", JSON.stringify(permissions || {}));
  }

  getSession() {
    return {
      access_token: localStorage.getItem("access_token"),
      email: localStorage.getItem("Email"),
      idUsuario: localStorage.getItem("IdUsuario"),
      imagem: localStorage.getItem("Imagem"),
      nome: localStorage.getItem("Nome"),
      login: localStorage.getItem("Login"),
      usuarioTableau: localStorage.getItem("usuario_tableau"),
      usuarioInterno: localStorage.getItem('UsuarioInterno'),
      permissions: JSON.parse(localStorage.getItem("permisions") || "{}"),
    };
  }

  isAnalyticsUser() {
    const permissions = JSON.parse(localStorage.getItem("permisions") || "{}");
    return permissions.usuario_analytics;
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    history.replace("/");
  }

  isAuthenticated() {
    const accessToken = localStorage.getItem("access_token");
    return !!accessToken;
  }
}

export default new Auth();
