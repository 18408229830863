import { EXIBIR_CARREGANDO } from "../../../redux/actions/actionsConstants";
export const LISTA_CURVAS = "LISTA_CURVAS";
export const SALVAR_CURVA = "SALVAR_CURVA";
export const EXCLUIR_CURVA = "EXCLUIR_CURVA";
export const EDITAR_CURVA = "EDITAR_CURVA";

export const listarCurvas = (curvas) => {
  return {
    type: LISTA_CURVAS,
    payload: { CurvasGanhoDePeso: curvas },
  };
};

export const salvarCurva = (curva) => {
  return {
    type: SALVAR_CURVA,
    payload: curva,
  };
};

export const excluirCurva = () => {
  return {
    type: EXCLUIR_CURVA,
  };
};

export const editarCurva = () => {
  return {
    type: EDITAR_CURVA,
  };
};
export const exibirCarregando = (exibir) => {
  return {
    type: EXIBIR_CARREGANDO,
    payload: exibir,
  };
};
