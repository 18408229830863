import React, { useState, useEffect } from "react";
import MovementHistoryFilterPage from "./movementHistoryFilterPage";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

const MovementHistoryFilterContainer = ({
  filtrosHistoricoMovimentacao,
  componentRef,
  filtrarHistorioMovimentacaoSemPaginacao,
  filtrarHistorioMovimentacao,
  montarDadosGA,
}) => {
  // monta o objeto do filtro da forma correta
  const listaUsuarios = [
    ...(filtrosHistoricoMovimentacao.Usuarios || []).map((value) => ({
      label: value,
      value: value,
    })),
  ];
  // monta o objeto do filtro da forma correta
  const listaTipoOperacoes = [
    ...(filtrosHistoricoMovimentacao.TiposOperacoes || [])
      .map((v) => ({
        label: v.Nome,
        value: v.IdTipoOperacao,
      })),
  ];
  // monta o objeto do filtro da forma correta
  const listaCategoriaAnimal = [
    ...(filtrosHistoricoMovimentacao.CategoriasAnimal || []).map((v) => ({
      label: v.Nome,
      value: v.IdCategoriaAnimal,
    })),
  ];

  // monta o objeto do filtro da forma correta
  const listaPastoFazenda = [
    ...(filtrosHistoricoMovimentacao.Pastos || []).map((v) => ({
      label: v.Nome,
      value: v.IdPasto,
    })),
  ];

  // monta objeto para o dropdown do retiro vem os retiros da fazenda
  const listaRetiros = [
    ...(filtrosHistoricoMovimentacao.Retiros || []).map((retiro) => ({
      label: retiro.Nome,
      value: retiro.IdRetiro,
    })),
  ];

  const [dataInicio, setDataInicio] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  );
  const [dataFinal, setDataFinal] = useState(new Date());
  const [maxDataInicio] = useState(new Date());
  const [minDataInicio] = useState(moment("17-08-2021", "DD-MM-YYYY"));
  const [maxDataFinal] = useState(new Date());

  const [collapseFilter, setCollapseFilter] = useState(false);
  const [usuariosSelecionados, setUsuariosSelecionados] = useState([]);
  const [tiposOperacoesSelecionadas, setTiposOperacoesSelecionadas] = useState(
    []
  );
  const [categoriasIniciaisSelecionadas, setCategoriasIniciaisSelecionadas] =
    useState([]);
  const [categoriasFinaisSelecionadas, setCategoriasFinaisSelecionadas] =
    useState([]);
  const [pastoOrigemSelecionado, setPastoOrigemSelecionado] = useState([]);
  const [pastoDestinoSelecionado, setPastoDestinoSelecionado] = useState([]);
  const [retirosSelecionados, setRetirosSelecionados] = useState([]);

  const handlerUsuariosSelecionados = (selecaoUsuarios) => {
    setUsuariosSelecionados(selecaoUsuarios);
  };

  const handlerFiltroCategoriaInicial = (selecaoCategoriaInicial) => {
    setCategoriasIniciaisSelecionadas(selecaoCategoriaInicial);
  };

  const handlerFiltroCategoriaFinal = (selecaoCategoriaFinal) => {
    setCategoriasFinaisSelecionadas(selecaoCategoriaFinal);
  };

  const handlerTipoOperacao = (selecaoTipoOperacoes) => {
    setTiposOperacoesSelecionadas(selecaoTipoOperacoes);
  };

  const handlerPastoOrigemSelecionado = (selecaoPastosDeOrigem) => {
    setPastoOrigemSelecionado(selecaoPastosDeOrigem);
  };

  const handlerPastoDestinoSelecionado = (selecaPastosDeDestisno) => {
    setPastoDestinoSelecionado(selecaPastosDeDestisno);
  };

  const handlerCollapse = (collapseState) => {
    setCollapseFilter(!collapseState);
  };

  const handlerDataInicial = (novaDataInicial) => {
    setDataInicio(novaDataInicial);
  };

  const handlerDataFinal = (novaDataFinal) => {
    setDataFinal(novaDataFinal);
  };

  const handleRetirosSelecionados = (selecaoRetiro) => {
    setRetirosSelecionados(selecaoRetiro);
  };

  const validaFiltros = () => {
    const todosUsuariosSelecionados =
      usuariosSelecionados.length === listaUsuarios.length;
    const todasOperacoesSelecionadas =
      tiposOperacoesSelecionadas.length === listaTipoOperacoes.length;
    const todasCategoriasOriginaisSelecionadas =
      categoriasIniciaisSelecionadas.length === listaCategoriaAnimal.length;
    const todasCategoriasFinaisSelecionadas =
      categoriasFinaisSelecionadas.length === listaCategoriaAnimal.length;
    const todosPastosOrigemSelecionados =
      pastoOrigemSelecionado.length === listaPastoFazenda.length;
    const todosPastosDestinoSelecionados =
      pastoDestinoSelecionado.length === listaPastoFazenda.length;
    const todosRetirosSelecionados =
      retirosSelecionados.length === listaRetiros.length;
    return {
      Usuario: todosUsuariosSelecionados ? [] : usuariosSelecionados,
      TipoOperacao: todasOperacoesSelecionadas
        ? []
        : tiposOperacoesSelecionadas,
      IdCategoriaOrigem: todasCategoriasOriginaisSelecionadas
        ? []
        : categoriasIniciaisSelecionadas,
      IdCategoriaDestino: todasCategoriasFinaisSelecionadas
        ? []
        : categoriasFinaisSelecionadas,
      IdPastoOrigem: todosPastosOrigemSelecionados
        ? []
        : pastoOrigemSelecionado,
      IdPastoDestino: todosPastosDestinoSelecionados
        ? []
        : pastoDestinoSelecionado,
      IdRetiro: todosRetirosSelecionados ? [] : retirosSelecionados,
    };
  };

  const executarFiltro = async () => {
    const filtro = validaFiltros();
    const filtroTratado = Object.fromEntries(
      Object.entries(filtro)
        .filter(([_, value]) => value.length !== 0)
        .map((entrie) => [
          entrie[0],
          entrie[1].map((valorFiltro) => valorFiltro.value || valorFiltro),
        ])
    );

    filtroTratado.DataInicio = dataInicio;
    filtroTratado.DataFinal = dataFinal;

    await filtrarHistorioMovimentacao(filtroTratado);
    montarDadosGA();
  };

  const executarFiltroSemPaginacao = async () => {
    const filtro = validaFiltros();

    const filtroTratado = Object.fromEntries(
      Object.entries(filtro)
        .filter(([_, value]) => value.length !== 0)
        .map((entrie) => [
          entrie[0],
          entrie[1].map((valorFiltro) => valorFiltro.value || valorFiltro),
        ])
    );

    filtroTratado.DataInicio = dataInicio;
    filtroTratado.DataFinal = dataFinal;

    await filtrarHistorioMovimentacaoSemPaginacao(filtroTratado);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef,
  });

  const executarFiltroParaPrint = async () => {
    await executarFiltroSemPaginacao();
    handlePrint();
  };

  // seta o estado inicial de todos selecionados dos filtros com as respectivas listas
  useEffect(() => { }, [filtrosHistoricoMovimentacao.Usuarios]);

  useEffect(() => { }, [filtrosHistoricoMovimentacao.TiposOperacoes]);

  useEffect(() => { }, [filtrosHistoricoMovimentacao.CategoriasAnimal]);

  useEffect(() => { }, [filtrosHistoricoMovimentacao.CategoriasAnimal]);

  useEffect(() => { }, [filtrosHistoricoMovimentacao.Pastos]);

  useEffect(() => { }, [filtrosHistoricoMovimentacao.Pastos]);

  useEffect(() => { }, [filtrosHistoricoMovimentacao.Retiros]);

  return (
    <MovementHistoryFilterPage
      handlerUsuariosSelecionados={handlerUsuariosSelecionados}
      handlerFiltroCategoriaInicial={handlerFiltroCategoriaInicial}
      handlerFiltroCategoriaFinal={handlerFiltroCategoriaFinal}
      handlerTipoOperacao={handlerTipoOperacao}
      handlerPastoOrigemSelecionado={handlerPastoOrigemSelecionado}
      handlerPastoDestinoSelecionado={handlerPastoDestinoSelecionado}
      handlerCollapse={handlerCollapse}
      handlerDataInicial={handlerDataInicial}
      handlerDataFinal={handlerDataFinal}
      maxDataInicio={maxDataInicio}
      minDataInicio={minDataInicio}
      dataInicio={dataInicio}
      dataFinal={dataFinal}
      maxDataFinal={maxDataFinal}
      listaUsuarios={listaUsuarios}
      usuariosSelecionados={usuariosSelecionados}
      listaTipoOperacoes={listaTipoOperacoes}
      tiposOperacoesSelecionadas={tiposOperacoesSelecionadas}
      collapseFilter={collapseFilter}
      listaCategoriaAnimal={listaCategoriaAnimal}
      categoriasFinaisSelecionadas={categoriasFinaisSelecionadas}
      categoriasIniciaisSelecionadas={categoriasIniciaisSelecionadas}
      listaPastoFazenda={listaPastoFazenda}
      pastoOrigemSelecionado={pastoOrigemSelecionado}
      pastoDestinoSelecionado={pastoDestinoSelecionado}
      executarFiltro={executarFiltro}
      executarFiltroParaPrint={executarFiltroParaPrint}
      retirosSelecionados={retirosSelecionados}
      listaRetiros={listaRetiros}
      handleRetirosSelecionados={handleRetirosSelecionados}
    />
  );
};

export default MovementHistoryFilterContainer;
