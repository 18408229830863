import {
  SELECIONAR_PASTO,
  SALVAR_COCHO,
  ERRO_VALIDAR_COCHO,
  SELECIONA_COCHO_COMPARTILHADO,
  DESELECIONA_COCHO_COMPARTILHADO,
  NOVO_COCHO,
  LIMPAR_NOVO_COCHO,
} from "redux/actions/actionsConstants";

export const initialState = {
  CochoSelecionado: null,
  Cor: null,
  Cocho: null,
  erros: {
    Cocho: {
      Coberto: "",
      Comprimento: "",
      Elevado: "",
      EstoqueFinal: "",
    },
  },
  limparNovoCocho: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECIONAR_PASTO:
      if (action.payload.cocho) {
        return {
          ...state,
          CochoSelecionado: action.payload.IdPasto,
          Cocho: action.payload.cocho,
        };
      } else {
        return initialState;
      }
    case SALVAR_COCHO:
      return {
        ...state,
        CochoSelecionado: action.payload.IdPasto,
        Cocho: action.payload,
      };
    case ERRO_VALIDAR_COCHO:
      return {
        ...state,
        erros: {
          ...state.erros,
          Cocho: { ...initialState.erros.Cocho, ...action.payload },
        },
      };
    case SELECIONA_COCHO_COMPARTILHADO:
      return {
        ...state,
        CochoSelecionado: action.payload.IdCocho,
        Cor: action.payload.Cor,
        Cocho: action.payload,
      };
    case DESELECIONA_COCHO_COMPARTILHADO:
      return {
        ...state,
        CochoSelecionado: null,
        Cor: "",
        Cocho: null,
      };
    case NOVO_COCHO:
      return {
        ...state,
        limparNovoCocho: false,
      };
    case LIMPAR_NOVO_COCHO:
      return {
        ...state,
        limparNovoCocho: true,
      };
    default:
      return state;
  }
};
