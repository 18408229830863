import {
  notifySuccess,
  notifyError,
} from "../../../redux/actions/notificacaoActions";
import FormulaManager from "../formulaManager";
import formulaService from "../formulaService";
import {
  deletarFormula,
  listarFormulasFazenda,
  exibirCarregando,
  restaurarFormula,
  listarEpocasFormula,
  listarCategoriasFormula,
} from "./formulaActions";

const MENSAGEM_NOME_FORMULA_EXISTENTE =
  "Já existe uma formulação com esse nome. Por favor, insira outro e tente novamente.";
const MENSAGEM_CRIACAO_SUCESSO = "Fórmula criada com sucesso.";
const MENSAGEM_CRIACAO_ERRO = "Ocorreu um erro ao criar a fórmula.";
const MENSAGEM_ATUALIZACAO_SUCESSO = "Fórmula atualizada com sucesso.";
const MENSAGEM_ATUALIZACAO_FORMULA_INGREDIENTE_SUCESSO =
  "Formulação e ingredientes ativados com sucesso!";
const MENSAGEM_ATUALIZACAO_ERRO = "Ocorreu um erro ao atualizar a fórmula";
const MENSAGEM_EXCLUIR_SUCESSO = "Fórmula desativada com sucesso.";
const MENSAGEM_EXCLUIR_ERRO = "Ocorreu um erro ao desativar a fórmula.";
const MENSAGEM_COPIA_ERRO = "Ocorreu um erro ao copiar formulação";
const MENSAGEM_LISTA_CATEGORIAS_ERRO =
  "Ocorreu um erro ao obter a lista de categorias";
const MENSAGEM_LISTA_EPOCAS_ERRO = "Ocorreu um erro ao obter a lista de epocas";
const MENSAGEM_LISTA_ERRO = "Ocorreu um erro ao obter a lista de fórmulas.";

export const criarFormula = (Formula) => async (dispatch) => {
  try {
    await FormulaManager.adicionarFormula(Formula);

    dispatch(
      notifySuccess({
        mensagem: MENSAGEM_CRIACAO_SUCESSO,
      })
    );
  } catch ({ response }) {
    let mensagem = MENSAGEM_CRIACAO_ERRO;

    if (response && response.status && response.status === 409) {
      mensagem = MENSAGEM_NOME_FORMULA_EXISTENTE;
    }

    dispatch(
      notifyError({
        mensagem,
      })
    );

    throw new Error(mensagem);
  }
};

export const atualizarFormula = (idFormula, Formula) => async (dispatch) => {
  try {
    await FormulaManager.atualizarFormula(idFormula, Formula);
    dispatch(
      notifySuccess({
        mensagem: MENSAGEM_ATUALIZACAO_SUCESSO,
      })
    );
  } catch ({ response }) {
    let mensagem = MENSAGEM_ATUALIZACAO_ERRO;

    if (response && response.status && response.status === 409) {
      mensagem = MENSAGEM_NOME_FORMULA_EXISTENTE;
    }

    dispatch(
      notifyError({
        mensagem,
      })
    );
    throw new Error(mensagem);
  }
};

export const excluirFormula = (idFormula) => async (dispatch) => {
  try {
    await FormulaManager.excluirFormula(idFormula);
    dispatch(deletarFormula());
    dispatch(
      notifySuccess({
        mensagem: MENSAGEM_EXCLUIR_SUCESSO,
      })
    );
  } catch (error) {
    dispatch(
      notifyError({
        mensagem: MENSAGEM_EXCLUIR_ERRO,
      })
    );
  }
};

export const desexcluirFormula =
  (idFormula, ingredientes) => async (dispatch) => {
    try {
      await FormulaManager.desexcluirFormula(idFormula);
      dispatch(restaurarFormula());
      if (ingredientes && ingredientes.length > 0) {
        dispatch(
          notifySuccess({
            mensagem: MENSAGEM_ATUALIZACAO_FORMULA_INGREDIENTE_SUCESSO,
          })
        );
      } else {
        dispatch(
          notifySuccess({
            mensagem: MENSAGEM_ATUALIZACAO_SUCESSO,
          })
        );
      }
    } catch (error) {
      const message =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.body &&
        error.response.data.body.message
          ? error.response.data.body.message
          : MENSAGEM_ATUALIZACAO_ERRO;
      dispatch(
        notifyError({
          mensagem: message,
        })
      );
    }
  };

export const obterFormulasFazenda =
  (pegarFormulasDeletadas) => async (dispatch, getState) => {
    dispatch(exibirCarregando(true));
    try {
      const { fazenda } = getState();
      const formulasFazenda = await FormulaManager.obterFormulasFazenda(
        fazenda.FazendaSelecionada,
        pegarFormulasDeletadas
      );
      dispatch(listarFormulasFazenda(formulasFazenda));
    } catch (error) {
      dispatch(listarFormulasFazenda([]));
      dispatch(
        notifyError({
          mensagem: MENSAGEM_LISTA_ERRO,
        })
      );
    } finally {
      dispatch(exibirCarregando(false));
    }
  };

export const obterEpocasFormula = () => async (dispatch) => {
  dispatch(exibirCarregando(true));
  try {
    const epocasFormula = await FormulaManager.obterEpocasFormula();

    dispatch(listarEpocasFormula(epocasFormula));
  } catch (error) {
    dispatch(listarEpocasFormula([]));
    dispatch(
      notifyError({
        mensagem: MENSAGEM_LISTA_EPOCAS_ERRO,
      })
    );
  } finally {
    dispatch(exibirCarregando(false));
  }
};

export const obterCategoriasFormula = () => async (dispatch) => {
  dispatch(exibirCarregando(true));
  try {
    const categoriasFormula = await FormulaManager.obterCategoriasFormula();
    dispatch(listarCategoriasFormula(categoriasFormula));
  } catch (error) {
    dispatch(listarCategoriasFormula([]));
    dispatch(
      notifyError({
        mensagem: MENSAGEM_LISTA_CATEGORIAS_ERRO,
      })
    );
  } finally {
    dispatch(exibirCarregando(false));
  }
};

export const copyFormula =
  (idFormula, copiedFormula) => async (dispatch, getState) => {
    dispatch(exibirCarregando(true));
    try {
      const { fazenda } = getState();
      await excluirFormula(idFormula)(dispatch);
      const response = await formulaService.adicionarFormula(copiedFormula);
      const newFormula = await formulaService.buscarFormulacaoPorId(
        fazenda.fazendaSelecionada,
        response.data.body.IdFormula
      );
      dispatch(
        notifySuccess({
          mensagem: MENSAGEM_CRIACAO_SUCESSO,
        })
      );
      return newFormula;
    } catch (error) {
      dispatch(
        notifyError({
          mensagem: MENSAGEM_COPIA_ERRO,
        })
      );
      return null;
    } finally {
      dispatch(exibirCarregando(false));
    }
  };
