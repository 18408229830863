import React from "react";
import { AdvancedMarker } from "components/ViewsGoogleMaps/AdvancedMarker";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const DarkTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    slotProps={{
      popper: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [-8, -8],
            },
          },
        ],
      },
    }}
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(0, 0, 0,0.75)",
    padding: "12px 16px",
  },
}));

export const IconeFarol = (props) => {
  const {
    cor,
    valorExibido,
    posicaoIcone,
    handleSetPastoComColetaMorte,
    styles,
    icon,
  } = props;
  const label = obtemLabelIcone(valorExibido.toString(), cor);
  return renderIcone(
    posicaoIcone,
    label,
    handleSetPastoComColetaMorte,
    styles,
    icon
  );
};

const renderIcone = (
  position,
  label,
  handleSetPastoComColetaMorte,
  styles,
  icon
) => {
  return (
    <AdvancedMarker
      position={position}
      key={position.lat}
      onClick={handleSetPastoComColetaMorte}
    >
      <div
        style={{
          backgroundColor: "#fff",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          color: label.color,
          fontSize: "12px",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...styles,
        }}
      >
        {!icon && label.text}
        {icon && (
          <DarkTooltip title="Pendências não resolvidas nesse pasto">
            <div
              style={{
                fontSize: "20px",
                color: "#FFC944",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              {icon}
            </div>
          </DarkTooltip>
        )}
      </div>
    </AdvancedMarker>
  );
};
const obtemLabelIcone = (textoIcone, cor) => {
  return { text: textoIcone, color: cor, fontSize: "12px", fontWeight: "bold" };
};

export default IconeFarol;
