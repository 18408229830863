import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Marker, OverlayView } from "@react-google-maps/api";
import Tooltip from "../Tooltip";
import calculaCoordenadaBotao from "../../../../../lib/calculaCoordenadaBotao";
import CalculadoraCoordenadas from "../../../../../lib/calculadoraCoordenadas";
import Poligono from "../Poligono";
import { coresModulos } from "../../../../../constants/coresModulos";
import shortid from "shortid";

class PastosModulo extends Component {
  state = {
    mouseOver: false,
  };

  onMouseOverChange = (over) => {
    this.setState({ ...this.state, mouseOver: over });
  };

  render() {
    return (
      <div>
        {this.renderPoligonos()}
        {this.renderBotaoFechar()}
        {this.renderInfo()}
        {this.renderNumerosPastosSelecionados()}
      </div>
    );
  }

  renderPoligonos = () => {
    const { Pastos, selecionado, bloqueado, corSelecionada } = this.props;

    return _.map(Pastos, (pasto) => {
      return (
        <Poligono
          id="poligono"
          key={pasto.IdPasto}
          onClick={(e) => this.props.onClick(e)}
          coordenadas={pasto.Coordenadas}
          cor={coresModulos[selecionado ? corSelecionada : pasto.Modulo.Cor]}
          onMouseOverChange={(over) => this.onMouseOverChange(over)}
          highlight={this.state.mouseOver || selecionado}
          block={bloqueado}
          raised={selecionado}
        />
      );
    });
  };

  renderBotaoFechar = () => {
    if (!this.props.selecionado) return null;

    const position = calculaCoordenadaBotao(
      _.flatMap(this.props.Pastos, (pasto) => {
        return pasto.Coordenadas;
      })
    );

    return (
      <Marker
        id="marker"
        position={position}
        options={{
          icon: {
            url: "/images/botaoFechar.svg",
            scaledSize: { height: 30, width: 30 },
          },
        }}
        onClick={() => {
          this.props.onClose();
        }}
      />
    );
  };

  renderInfo = () => {
    if (this.props.selecionado === false && this.state.mouseOver) {
      const position = CalculadoraCoordenadas.calcularCentroAlto(
        _.flatMap(this.props.Pastos, (pasto) => {
          return pasto.Coordenadas;
        })
      );
      return (
        <Tooltip
          id="toolTip"
          Posicao={position}
          Texto={this.props.Modulo.Nome}
        />
      );
    } else {
      return <div />;
    }
  };

  renderNumerosPastosSelecionados = () => {
    const { selecionado, zoomMapa, Pastos } = this.props;

    if (zoomMapa && zoomMapa < 13) return <React.Fragment />;
    if (selecionado) {
      const tamanhoFonte = obtemTamanhoFonte(zoomMapa);
      return (
        <div id="numeroPastosSelecionados">
          <React.Fragment>
            {_.map(Pastos, (pasto) => {
              return (
                <OverlayView
                  key={shortid.generate()}
                  position={CalculadoraCoordenadas.calcularCentro(
                    pasto.Coordenadas
                  )}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  getPixelPositionOffset={(width, height) => ({
                    x: -(width / 2),
                    y: -(height / 2),
                  })}
                >
                  <span style={{ color: "#FFF", fontSize: tamanhoFonte }}>
                    {pasto.OrdemModulo}
                  </span>
                </OverlayView>
              );
            })}
          </React.Fragment>
        </div>
      );
    }
    return null;
  };
}

const obtemTamanhoFonte = (zoomMapa) => {
  if (zoomMapa === 13) return "1em";
  else if (zoomMapa === 14) return "1.5em";
  else return "2em";
};

PastosModulo.propTypes = {
  Modulo: PropTypes.object.isRequired,
  Pastos: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  zoomMapa: PropTypes.number,
  selecionado: PropTypes.bool.isRequired,
  bloqueado: PropTypes.bool.isRequired,
  corSelecionada: PropTypes.string.isRequired,
};

export default PastosModulo;
