import React from "react";
import { withStyles, InputLabel } from "@material-ui/core";

import DatePickerMUI from "../../../../../shared/components/datePicker";

const DatePicker = ({
  classes,
  label,
  minDate,
  maxDate,
  onChangeDate,
  value,
}) => {
  return (
    <>
      <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      <DatePickerMUI
        minDate={minDate}
        maxDate={maxDate}
        onChangeDate={onChangeDate}
        value={value}
        maxDateMessage="A data inserida não pode ser maior que a data fim"
        minDateMessage="A data inserida não poder ser menor que a data início"
        clearable
        showTodayButton={true}
        InputProps={{
          classes: {
            input: classes.datePickerInput,
          },
        }}
        customStyle={{
          width: "150px",
          paddingTop: "6px",
          fontFamily: "Roboto",
          fontSize: "12px",
        }}
        disableFuture
      />
    </>
  );
};

const styles = () => ({
  inputLabel: {
    fontSize: 12,
    color: "#7f7f7f",
    fontFamily: "Roboto",
  },
  datePickerInput: {
    fontSize: "12px",
    fontFamily: "Roboto",
  },
});

export default withStyles(styles)(DatePicker);
