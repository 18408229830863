import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Grid, withStyles } from "@material-ui/core";
import ConciliacaoTabela from "./conciliacaoTabela";
import ConciliacaoCabecalho from "./conciliacaoCabecalho";
import ModalDescritivoLote from "./conciliacaoModalDescritivoLote";
import { imprimirPage } from "../../shared/utils";
import ConciliacaoResumoTable from "./conciliacaoResumoTable";
import ModalAlerta from "../../shared/components/modalAlerta";
import { withRouter } from "react-router-dom";
import {
  CONCILIACAO_DESCRICAO_FALTA_DATA,
  CONCILIACAO_DESCRICAO_FALTA_OPERACAO_SOMENTE_CURRAL,
} from "../../constants/actionsConstants";
import {
  getStylesLinha,
  gerarListaCustomSelect,
  filtrarConciliacoesPorCategoria,
  TODAS_CATEGORIAS,
  getCategoriasDeConciliacoes,
  getInitialDateValues,
} from "../conciliacaoUtil";
import { montarTextoFiltro } from "../../../shared/utils";
import ConciliacaoPrintTemplate from "./conciliacaoPrintTemplate";

export class ConciliacaoPage extends Component {
  constructor(props) {
    super(props);
    localStorage.setItem("firstRequest", "notMade");
    const { dataInicioFiltro, dataFimFiltro } = getInitialDateValues(
      Date.now(),
      props.dataInicioConciliacao
    );
    this.state = {
      categoriaSelecionada: TODAS_CATEGORIAS,
      exibirDescritivoModal: false,
      dataInicio: dataInicioFiltro,
      dataFim: dataFimFiltro,
      nomeCategoria: "",
    };
  }

  async componentDidMount() {
    const { dataInicio, dataFim } = this.state;
    await this.onButtonClick(dataInicio, dataFim);
  }

  handlerDataInicio = (data) => {
    this.setState({
      dataInicio: data,
    });
  };

  handlerDataFim = (data) => {
    this.setState({
      dataFim: data,
    });
  };

  onSelectFormChange = (value) => {
    this.setState({ categoriaSelecionada: value });
  };

  onButtonClick = async (dataInicio, dataFim) => {
    this.props.loading(true, true);
    const { idFazendaSelecionada, obterConciliacoes, dataInicioConciliacao } =
      this.props;
    montarTextoFiltro(idFazendaSelecionada, dataInicio, dataFim);

    this.setState({
      dataInicio: dataInicio,
      dataFim: dataFim,
    });

    await obterConciliacoes(
      idFazendaSelecionada,
      dataInicio,
      dataFim,
      dataInicioConciliacao
    );

    this.props.loading(false);
  };

  onImprimirClick = async () => {
    this.props.loading(true, true);
    var contentInnerHTML = document.getElementById("printarea").innerHTML;
    var frame = document.getElementById("ifmcontentstoprint").contentWindow;
    var css = "";
    var styletags = document.getElementsByTagName("style");
    for (var i = 0; i < styletags.length; i++) {
      css += styletags[i].innerHTML;
    }

    var head = document.head || document.getElementsByTagName("head")[0];
    var style = document.createElement("style");

    style.type = "text/css";
    if (head.style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    imprimirPage(contentInnerHTML, frame, style);
    this.props.loading(false);
  };

  goBack = () => {
    const history = this.props.history;
    history.replace("/farol");
  };

  goToCategoriaAnimal = () => {
    // Trocar para aba Categoria animal
    const history = this.props.history;
    history.go(0);
  };

  exibirDescritivoModal = (nomeCategoria) => {
    this.setState({
      exibirDescritivoModal: !this.state.exibirDescritivoModal,
      nomeCategoria,
    });
  };

  obterDescritivoDeColetas = async (idCategoria) => {
    this.props.loading(true, true);
    const { idFazendaSelecionada, obterDetalhesCategoria } = this.props;
    const { dataInicio, dataFim } = this.state;
    await obterDetalhesCategoria(
      idFazendaSelecionada,
      idCategoria,
      dataInicio,
      dataFim
    );
    this.props.loading(false);
  };

  conteudoPagina() {
    const {
      classes,
      conciliacoesFazenda,
      operacoesCategoria,
      listaMorteNascimento,
      dataInicioConciliacao,
      exibirConciliacao,
      arrayEntradaSaida,
      fazenda,
    } = this.props;
    if (!exibirConciliacao) {
      return (
        <ModalAlerta
          id="modal-bloqueio-operacao-curral"
          textoDescricao={CONCILIACAO_DESCRICAO_FALTA_OPERACAO_SOMENTE_CURRAL}
          aoFechar={this.goBack}
        />
      );
    }

    const { categoriaSelecionada, nomeCategoria, dataInicio, dataFim } =
      this.state;

    const categoriasAnimais = getCategoriasDeConciliacoes(
      conciliacoesFazenda.Detalhes
    );
    const concilicoesFiltradas = filtrarConciliacoesPorCategoria(
      conciliacoesFazenda.Detalhes || [],
      categoriaSelecionada
    );
    const stylesLinhaHorizontal = getStylesLinha(2, "#eeeeee;", 0.3);

    return dataInicioConciliacao ? (
      <>
        <Paper square className={classes.root}>
          <Grid container className={classes.containerPage}>
            <Grid item className={classes.smallerArea}>
              <ConciliacaoCabecalho
                componentRef={this.componentRef}
                categoriaSelecionada={categoriaSelecionada}
                list={gerarListaCustomSelect(categoriasAnimais)}
                onSelectFormChange={this.onSelectFormChange}
                onButtonClick={this.onButtonClick}
                onImprimirClick={this.onImprimirClick}
                dataInicio={dataInicio}
                dataFim={dataFim}
                handlerDataInicio={this.handlerDataInicio}
                handlerDataFim={this.handlerDataFim}
                dataInicioConciliacao={dataInicioConciliacao}
              />
            </Grid>
            <div className={classes.wrapperGeral}>
              <Grid item className={classes.smallerArea}>
                <Typography className={classes.titulo} gutterBottom>
                  RESUMO
                </Typography>

                {conciliacoesFazenda.Resumo ? (
                  <ConciliacaoResumoTable resumo={conciliacoesFazenda.Resumo} />
                ) : null}
              </Grid>
              <hr style={stylesLinhaHorizontal} />
              <Grid item className={classes.smallerArea}>
                <Typography className={classes.titulo} gutterBottom>
                  DETALHADO
                </Typography>
                <ConciliacaoTabela
                  dadosConciliacao={concilicoesFiltradas}
                  exibirDescritivoModal={this.exibirDescritivoModal}
                  obterDescritivoDeColetas={this.obterDescritivoDeColetas}
                />
              </Grid>
            </div>
          </Grid>
        </Paper>
        <ModalDescritivoLote
          exibirDescritivoModal={this.state.exibirDescritivoModal}
          aoFechar={this.exibirDescritivoModal}
          dataInicial={dataInicio}
          dataFinal={dataFim}
          operacoesCategoria={operacoesCategoria}
          nomeCategoria={nomeCategoria}
          arrayEntradaSaida={arrayEntradaSaida}
          listaMorteNascimento={listaMorteNascimento}
        />
        <div style={{ display: "none" }}>
          <ConciliacaoPrintTemplate
            ref={(el) => (this.componentRef = el)}
            dadosConciliacao={concilicoesFiltradas}
            exibirDescritivoModal={this.exibirDescritivoModal}
            obterDescritivoDeColetas={this.obterDescritivoDeColetas}
            resumo={conciliacoesFazenda.Resumo}
            listaDeCategorias={gerarListaCustomSelect(categoriasAnimais)}
            categoriaSelecionada={categoriaSelecionada}
            fazenda={fazenda}
            stylesLinhaHorizontal={stylesLinhaHorizontal}
            dataInicio={dataInicio}
            dataFim={dataFim}
          />
        </div>
      </>
    ) : (
      <ModalAlerta
        textoDescricao={CONCILIACAO_DESCRICAO_FALTA_DATA}
        aoFechar={this.goToCategoriaAnimal}
      />
    );
  }

  render() {
    return this.conteudoPagina();
  }
}

const styles = {
  typography: {
    fontFamily: "Roboto",
  },
  wrapperGeral: {
    width: "100%",
  },
  root: {
    width: "100%",
    height: "100%",
    zIndex: -1,
    padding: "46px",
    flexgrow: 1,
    minHeight: window.innerHeight,
  },
  containerPage: {
    flexgrow: 1,
    height: "100%",
    padding: "0",
    backgroundColor: "#fff",
    margin: "0 auto",
    width: "100%",
    position: "relative",
  },
  titulo: {
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "Roboto",
    color: "#424242",
    marginBottom: "20px",
  },
  smallerArea: {
    width: "100%",
    height: "auto",
    margin: "0em 0em",
    padding: "0.8em 0em",
  },
  resultPaper: {
    paperRoot: {
      width: "100%",
      height: "auto",
      border: "2px",
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.2)",
      backgroundColor: "#ffffff",
    },
  },
};

export default withStyles(styles)(withRouter(ConciliacaoPage));
