import React from "react";
import Auth from "features/authentication/Auth";
import ConfirmationModal from "features/shared/components/confirmationModal";


const ModalNoFarmOnUser = ({
  classes,
}) => {
  return (
    <ConfirmationModal
      customClass={classes.customClass}
      submitButtonContainer={classes.submitButtonContainer}
      title={
        <span className={classes.modalTitle}>
          Você não possui fazenda cadastrada!
        </span>
      }
      text={
        <span className={classes.modalText}>
          Para acessar a plataforma{" "}
          <span className={classes.modalTextInfo}>FarmTell Views</span>,
          é necessário ter ao menos{" "}
          <span className={classes.modalTextInfo}>
            uma fazenda cadastrada
          </span>
          . Por favor, entre em contato com nosso time de suporte para
          realizar o cadastro da sua fazenda e ter acesso à plataforma.
          <br></ br>
          Contato:
          <span className={classes.modalTextInfo}>{" "}+55 11 3003{"–"}6045</span>
        </span>
      }
      submitButtonTitle="FECHAR"
      onSubmit={Auth.logout}
      onClose={Auth.logout}
    />
  );
}

export default ModalNoFarmOnUser;
