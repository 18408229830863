import {
  notifyError,
  notifySuccess,
} from "../../../redux/actions/notificacaoActions";
import {
  listarPesagensFazenda,
  exibirCarregando,
  exibirModalDataInicial,
} from "./pesagemCategoriaActions";
import PesagemCategoriaService from "../pesagemCategoriaService";
import FazendaService from "../../../services/FazendaService";

export const obterPesagensFazenda = () => async (dispatch, getState) => {
  dispatch(exibirCarregando(true));
  try {
    const { fazenda } = getState();
    const exibeModal = await validaDataInicialConciliacao(
      fazenda.FazendaSelecionada
    );
    if (!exibeModal) {
      const eventosPesagemFazenda =
        await PesagemCategoriaService.obterPesagensFazenda(
          fazenda.FazendaSelecionada
        );
      dispatch(listarPesagensFazenda(eventosPesagemFazenda.data));
    } else {
      dispatch(exibirModalDataInicial(exibeModal));
      dispatch(listarPesagensFazenda({ items: [] }));
    }
  } catch (error) {
    dispatch(listarPesagensFazenda({ items: [] }));
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao obter a lista de pesagens.",
      })
    );
  } finally {
    dispatch(exibirCarregando(false));
  }
};

const validaDataInicialConciliacao = async (IdFazenda) => {
  try {
    await FazendaService.checaDataConciliação(IdFazenda);
    return false;
  } catch (error) {
    return true;
  }
};

export const desativaModal = () => (dispatch) => {
  dispatch(exibirModalDataInicial(false));
};

export const obterQuantidadesPorCategoria =
  (data) => async (dispatch, getState) => {
    dispatch(exibirCarregando(true));
    try {
      const { fazenda } = getState();
      const quantidadesPorCategoriaData =
        await PesagemCategoriaService.obterQuantidadesPorCategoria(
          data,
          fazenda.FazendaSelecionada
        );
      return quantidadesPorCategoriaData;
    } catch (error) {
      dispatch(
        notifyError({
          mensagem: "ocorreu um erro ao obter as quantidades de animais",
        })
      );
    } finally {
      dispatch(exibirCarregando(false));
    }
  };

export const criarEventoPesagem = (pesagem) => async (dispatch) => {
  dispatch(exibirCarregando(true));
  try {
    await PesagemCategoriaService.criarPesagensFazenda(pesagem);
    await dispatch(obterPesagensFazenda());
    dispatch(
      notifySuccess({ mensagem: "Evento de pesagens salvo com sucesso." })
    );
  } catch (error) {
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao criar evento de pesagens.",
      })
    );
  } finally {
    dispatch(exibirCarregando(false));
  }
};

export const editarPesagensFazenda = (eventosPesagens) => async (dispatch) => {
  dispatch(exibirCarregando(true));
  try {
    await Promise.all(
      eventosPesagens.map((eventos) =>
        PesagemCategoriaService.editarPesagensFazenda(eventos)
      )
    );
    await dispatch(obterPesagensFazenda());
    dispatch(
      notifySuccess({ mensagem: "Eventos de pesagens editados com sucesso." })
    );
  } catch (error) {
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao editar a lista de pesagens.",
      })
    );
  } finally {
    dispatch(exibirCarregando(false));
  }
};

export const excluirPesagemFazenda = (IdEventoPesagem) => async (dispatch) => {
  dispatch(exibirCarregando(true));
  try {
    await PesagemCategoriaService.excluirPesagemFazenda(IdEventoPesagem);
    await dispatch(obterPesagensFazenda());
    dispatch(
      notifySuccess({ mensagem: "Evento de pesagens excluído com sucesso." })
    );
  } catch (error) {
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao excluir um evento de pesagens.",
      })
    );
  } finally {
    dispatch(exibirCarregando(false));
  }
};
