import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import PlanningListPage from "./planningListPage";
import {
  disableLoading,
  enableLoading,
  notifyError,
} from "../../redux/planningOperations";

import planningService from "../../planningService";
import PlanningPrintTemplate from "../shared/PlanningPrintTemplate";
import usePrintPlanning from "features/planning/hooks/usePrintPlanning";
import { withStyles } from "@material-ui/core";

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 30;

const PlanningListContainer = ({
  classes,
  selectedFarm,
  currentFarm,
  enableLoading,
  disableLoading,
  notifyError,
  showPlanning = () => {},
  showEditPlanning = () => {},
  showNewPlanning = () => {},
}) => {
  const [plannings, setPlannings] = useState([]);

  useEffect(() => {
    getPlannings(selectedFarm);
  }, []);

  const farm = useState(currentFarm.Nome);

  const { printRef, planningToPrint, handlePrintPlanning } = usePrintPlanning({
    farmId: selectedFarm,
    enableLoading,
    disableLoading,
    notifyError,
  });

  const downloadReport = (planningCode) => {
    handlePrintPlanning(planningCode);
  };

  const getPlannings = async (selectedFarm) => {
    try {
      enableLoading();
      const plannings = await planningService.listPlannings(
        selectedFarm,
        DEFAULT_PAGE,
        DEFAULT_PAGE_SIZE
      );
      setPlannings(plannings);
    } catch (err) {
      console.error(err);
      notifyError("Ocorreu um erro ao listar os planejamentos.");
    } finally {
      disableLoading();
    }
  };

  return (
    <>
      <PlanningListPage
        id="planejamentos-page"
        plannings={plannings}
        showPlanning={showPlanning}
        showEditPlanning={showEditPlanning}
        showNewPlanning={showNewPlanning}
        downloadReport={downloadReport}
      />
      <div className={classes.printComponent}>
        <PlanningPrintTemplate
          ref={printRef}
          farm={farm}
          categories={planningToPrint.summaryReport.categories}
          formulas={planningToPrint.summaryReport.formulas}
          periodTitles={planningToPrint.periodTitles}
          summaryReport={planningToPrint.summaryReport.planningReport}
          planningTotal={planningToPrint.summaryReport.total}
          planningPlanData={planningToPrint.planningPlanData}
          planningList={planningToPrint.planningList}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedFarm: state.fazenda.FazendaSelecionada,
    currentFarm: state.fazenda.currentFarm,
  };
};

const mapDispatchToProps = {
  enableLoading: enableLoading,
  disableLoading: disableLoading,
  notifyError: notifyError,
};

const styles = (theme) => ({
  printComponent: {
    display: "none",
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PlanningListContainer));
