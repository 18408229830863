import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import Title from "features/goalPlan/views/shared/title";
import SubTitle from "features/goalPlan/views/shared/subtitle";
import AlertBanner from "features/shared/components/alertBanner";

const TEXT_TITLE = "Cadastro de metas da fazenda";
const TEXT_SUBTITLE = `Informe nos campos abaixo o nome do plano e a data de início, logo após será habilitada uma tabela para finalizar a crição do seu plano.`;

const GoalsHeader = ({
  classes,
  title = TEXT_TITLE,
  subtitle = TEXT_SUBTITLE,
  alertOptions,
}) => {
  const TitleComponent =
    typeof title === "string"
      ? () => (
          <Title
            style={{
              fontWeight: 500,
            }}
          >
            {title}
          </Title>
        )
      : title;
  const SubTitileComponent =
    typeof subtitle === "string"
      ? () => (
          <SubTitle
            style={{
              marginTop: "16px",
            }}
          >
            {subtitle}
          </SubTitle>
        )
      : subtitle;

  return (
    <Grid container direction="column" className={classes.titleContainer}>
      <TitleComponent />
      <SubTitileComponent />
      {alertOptions && (
        <AlertBanner
          className={classes.alertBanner}
          type={alertOptions.type}
          icon={alertOptions.icon}
        >
          {alertOptions.message}
        </AlertBanner>
      )}
    </Grid>
  );
};

const styles = (theme) => ({
  titleContainer: {
    marginTop: theme.telas.marginTopTitulo,
  },
  alertBanner: {
    marginTop: 24,
  },
});

export default withStyles(styles)(GoalsHeader);
