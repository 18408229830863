import { PERMISSSIONS } from "./userService"

const STORAGE_KEY = "userManagementFormState"

const getFromStorage = () => {
  return JSON.parse(sessionStorage.getItem(STORAGE_KEY))
}
const saveToStorage = (state) => {
  sessionStorage.setItem(STORAGE_KEY, JSON.stringify(state))
}
const cleanStorage = () => {
  sessionStorage.removeItem(STORAGE_KEY)
}

const hasPermission = (name, permissions) => {
  const permissionName = PERMISSSIONS.find((permission) => permission.id === name).name
  return permissions.some((permission) => permission.nome === permissionName)
}

export {
  getFromStorage,
  saveToStorage,
  cleanStorage,
  hasPermission
}
