import React from "react";
import InformacaoCategoriaAnimal from "./InformacaoCategoriaAnimal";
import CardDetalhesFarol from "features/manejo/views/components/cardDetalhesFarol";

export const detalhesCategorias = (props) => {
  const { categorias, pastosRetiroComLotes, curraisRetiroComLotes } = props;

  return (
    <CardDetalhesFarol
      id="detalhesCategorias"
      tituloCard="Categoria"
      children={categorias.map((categoria) => (
        <InformacaoCategoriaAnimal
          key={`${categoria.IdCategoriaAnimal}-categoria`}
          pastos={pastosRetiroComLotes}
          categoria={categoria}
          curraisRetiroComLotes={curraisRetiroComLotes}
        />
      ))}
    />
  );
};

export default detalhesCategorias;
