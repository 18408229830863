import BaseService from "../../services/BaseService";
import { fixInitialDateAndFinalDateTimezone } from "../shared/utils";

export const URL_GOALS = `${process.env.REACT_APP_ENDPOINT}locales/goalPlans`;
export const URL_INDICATORS = `${process.env.REACT_APP_ENDPOINT}locales/indicators`;
export const URL_PARAMETERS = `${process.env.REACT_APP_ENDPOINT}locales/referenceParameters`;
export const URL_LAST_GOAL_PLANS = `${URL_GOALS}/lastVersion`;

class GoalPlanService extends BaseService {
  async filterGoalPlan(farmId, filter = {}, page, size) {
    //Remove undefined entries
    const newFilter = Object.entries(filter).reduce((acc, [key, value]) => {
      if (value || value === 0 || value === false) {
        acc[key] = value;
      }
      return acc;
    }, {});

    //Fix field "active" before send to api
    if (filter && filter.active === "TODOS") {
      delete newFilter.active;
    }

    const queryParams = new URLSearchParams({
      ...newFilter,
      farmId,
      page,
      size,
    });

    const urlMetas = this.montaURL(URL_GOALS, queryParams.toString());
    const response = await this.get(urlMetas);
    return fixInitialDateAndFinalDateTimezone(response.data.data);
  }

  async listIndicators() {
    const response = await this.get(URL_INDICATORS);
    return response.data.data;
  }

  async listParameters() {
    const response = await this.get(URL_PARAMETERS);
    return response.data.data;
  }

  async listLastGoalPlans(farmId, filter) {
    const response = await this.get(URL_LAST_GOAL_PLANS, { farmId, ...filter });
    return fixInitialDateAndFinalDateTimezone(response.data.data);
  }

  async getPlan(goalPlanId) {
    const response = await this.get(`${URL_GOALS}/${goalPlanId}`);
    return fixInitialDateAndFinalDateTimezone(response.data.data);
  }

  async getRevision(goalPlanId) {
    const response = await this.get(`${URL_GOALS}/${goalPlanId}/revisions`);
    return fixInitialDateAndFinalDateTimezone(response.data.data);
  }

  async findPlanByPeriod(farmId, initialDate, finalDate) {
    const urlFindPlanByPeriod = `${URL_GOALS}/period`;

    const response = await this.get(urlFindPlanByPeriod, {
      farmId,
      initialDate,
      finalDate,
    });

    return response.data;
  }

  async findPlanByName(farmId, name) {
    if (!farmId || !name) return;

    const params = new URLSearchParams();
    params.append("farmId", farmId);
    params.append("name", name);
    params.append("page", 1);
    params.append("size", 1);

    const response = await this.get(URL_GOALS, params);

    return response.data;
  }

  async postPlan(goalPlanData) {
    if (!goalPlanData) return;

    const response = await this.post(URL_GOALS, goalPlanData);
    return response.data;
  }

  async goalPlanEdition(goalPlanId, goalPlanData) {
    if (!goalPlanId || !goalPlanData) return;

    const urlMetas = `${URL_GOALS}/${goalPlanId}`;

    const response = await this.patch(urlMetas, goalPlanData);
    return response.data;
  }

  async postReview(goalPlanId, reviewData) {
    if (!goalPlanId || !reviewData) return;
    const urlReview = `${URL_GOALS}/${goalPlanId}/revisions`;
    const response = await this.post(urlReview, reviewData);
    return response.data;
  }
}

export default new GoalPlanService();
