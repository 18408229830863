const cadastroIngredientesUtils = {};

export const ordenaIngredientesPadroes = (ingredientesPadroes) =>
  ingredientesPadroes.sort((a, b) => {
    let nameA = a.Nome.toUpperCase(); // ignore upper and lowercase
    let nameB = b.Nome.toUpperCase(); // ignore upper and lowercase
    return nameA > nameB ? 1 : nameA < nameB ? -1 : 0;
  });

export default cadastroIngredientesUtils;
