import React from "react";
import { connect } from "react-redux";
import CadastroIngredientesPage from "./cadastroIngredientesPage";
import * as cadastroIngredientesOperations from "../redux/cadastroIngredientesOperations";

export const CadastroIngredientesContainer = ({
  obterTodasOperacoesIngrediente,
  filtrarOperacoesIngrediente,
  obterTodasMateriasPrimas,
  ingredientesPadroes,
  ingredientes,
  criarIngrediente,
  editarIngrediente,
  ativaIngrediente,
  desativaIngrediente,
  obterHistorico,
  historico,
  farmId,
}) => (
  <CadastroIngredientesPage
    ativaIngrediente={ativaIngrediente}
    desativaIngrediente={desativaIngrediente}
    obterTodasOperacoesIngrediente={obterTodasOperacoesIngrediente}
    obterTodasMateriasPrimas={obterTodasMateriasPrimas}
    ingredientes={ingredientes}
    ingredientesPadroes={ingredientesPadroes}
    criarIngrediente={criarIngrediente}
    editarIngrediente={editarIngrediente}
    obterHistorico={obterHistorico}
    historico={historico}
    filtrarOperacoesIngrediente={filtrarOperacoesIngrediente}
    farmId={farmId}
  />
);

const mapStateToProps = ({ cadastroIngredientes = {}, fazenda }) => {
  return {
    ingredientes: cadastroIngredientes.ingredientes,
    ingredientesPadroes: cadastroIngredientes.ingredientesPadroes,
    historico: cadastroIngredientes.historicoIngredientes,
    obterHistorico: cadastroIngredientes.obterHistorico,
    farmId: fazenda.FazendaSelecionada,
  };
};
const mapDispatchToProps = {
  obterTodasOperacoesIngrediente:
    cadastroIngredientesOperations.obterTodasOperacoesIngrediente,
  obterTodasMateriasPrimas:
    cadastroIngredientesOperations.obterTodasMateriasPrimas,
  criarIngrediente: cadastroIngredientesOperations.criarIngrediente,
  editarIngrediente: cadastroIngredientesOperations.editarIngrediente,
  ativaIngrediente: cadastroIngredientesOperations.ativaIngrediente,
  desativaIngrediente: cadastroIngredientesOperations.desativaIngrediente,
  obterHistorico: cadastroIngredientesOperations.obterHistorico,
  filtrarOperacoesIngrediente:
    cadastroIngredientesOperations.filtrarOperacoesIngrediente,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CadastroIngredientesContainer);
