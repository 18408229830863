import memoize from "memoize-one";
import * as _ from "lodash";

export const filterGanhoPesoMemoize = memoize((list, textoFiltrar) =>
  _.filter(list, (ganhoPeso) => {
    ganhoPeso.Nome = ganhoPeso.Nome || "";
    return ganhoPeso.Nome.toUpperCase().includes(textoFiltrar.toUpperCase());
  })
);

export const MESES_ANO = [
  { Nome: "Janeiro", Id: "janeiro", Label: "Jan" },
  { Nome: "Fevereiro", Id: "fevereiro", Label: "Fev" },
  { Nome: "Marco", Id: "março", Label: "Mar" },
  { Nome: "Abril", Id: "abril", Label: "Abr" },
  { Nome: "Maio", Id: "maio", Label: "Maio" },
  { Nome: "Junho", Id: "junho", Label: "Jun" },
  { Nome: "Julho", Id: "julho", Label: "Jul" },
  { Nome: "Agosto", Id: "agosto", Label: "Ago" },
  { Nome: "Setembro", Id: "setembro", Label: "Set" },
  { Nome: "Outubro", Id: "outubro", Label: "Out" },
  { Nome: "Novembro", Id: "novembro", Label: "Nov" },
  { Nome: "Dezembro", Id: "dezembro", Label: "Dez" },
];

export const mesesNull = {
  Janeiro: null,
  Fevereiro: null,
  Marco: null,
  Abril: null,
  Maio: null,
  Junho: null,
  Julho: null,
  Agosto: null,
  Setembro: null,
  Outubro: null,
  Novembro: null,
  Dezembro: null,
};

export const mensagemBotao = {
  mesesNaoPreenchidos: "Por favor, preencha todos os campos para salvar a curva.",
  mesValorDiferenteZero: "Ao menos um mês deve conter valor diferente de zero."
}

export const translateWeightGainCurveToPortuguese = (weightGainCurve) => {
  if (!weightGainCurve) return null;
  return {
    IdCurvaGanhoPeso: weightGainCurve.id,
    Nome: weightGainCurve.name,
    IdFazenda: weightGainCurve.farmId,
    Janeiro: weightGainCurve.january,
    Fevereiro: weightGainCurve.february,
    Marco: weightGainCurve.march,
    Abril: weightGainCurve.april,
    Maio: weightGainCurve.may,
    Junho: weightGainCurve.june,
    Julho: weightGainCurve.july,
    Agosto: weightGainCurve.august,
    Setembro: weightGainCurve.september,
    Outubro: weightGainCurve.october,
    Novembro: weightGainCurve.november,
    Dezembro: weightGainCurve.december,
    UsuarioCriacao: weightGainCurve.creationUser,
    UsuarioAlteracao: weightGainCurve.updateUser,
    UsuarioExclusao: weightGainCurve.deletionUser,
    DataCriacao: weightGainCurve.createdAt,
    DataAlteracao: weightGainCurve.updatedAt,
    DataExclusao: weightGainCurve.deletedAt,
    Excluido: weightGainCurve.deletedAt ? true : false,
  }
};

export const translateWeightGainCurveToEnglish = (curvaGanhoPeso) => {
  if (!curvaGanhoPeso) return null;
  return {
    id: curvaGanhoPeso.IdCurvaGanhoPeso,
    name: curvaGanhoPeso.Nome,
    farmId: curvaGanhoPeso.IdFazenda,
    january: curvaGanhoPeso.Janeiro,
    february: curvaGanhoPeso.Fevereiro,
    march: curvaGanhoPeso.Marco,
    april: curvaGanhoPeso.Abril,
    may: curvaGanhoPeso.Maio,
    june: curvaGanhoPeso.Junho,
    july: curvaGanhoPeso.Julho,
    august: curvaGanhoPeso.Agosto,
    september: curvaGanhoPeso.Setembro,
    october: curvaGanhoPeso.Outubro,
    november: curvaGanhoPeso.Novembro,
    december: curvaGanhoPeso.Dezembro,
    creationUser: curvaGanhoPeso.UsuarioCriacao,
    updateUser: curvaGanhoPeso.UsuarioAlteracao,
    deletionUser: curvaGanhoPeso.UsuarioExclusao,
    createdAt: curvaGanhoPeso.DataCriacao,
    updatedAt: curvaGanhoPeso.DataAlteracao,
    deletedAt: curvaGanhoPeso.DataExclusao,
  }
};
