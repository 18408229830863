import React from "react";
import {
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  withStyles,
} from "@material-ui/core";

function FormSteps({ steps, activeStep, classes }) {
  const connector = (
    <StepConnector
      classes={{
        line: classes.connectorLine,
      }}
    />
  );

  return (
    <Stepper alternativeLabel activeStep={activeStep} connector={connector}>
      {steps.map((step) => (
        <Step>
          <StepLabel
            StepIconProps={{
              classes: { root: classes.stepIconRoot },
            }}
            classes={{
              active: classes.labelActive,
              completed: classes.labelActive,
            }}
          >
            {step}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

const styles = (theme) => ({
  connectorLine: {
    border: "none",
    height: "2px",
    background: "repeating-linear-gradient(90deg,#bdbdbd 0 8px,#0000 0 12px)",
  },
  stepIconRoot: {
    fontSize: "32px",
  },
  labelActive: {
    color: `${theme.palette.primary.main} !important`,
  },
});

export default withStyles(styles)(FormSteps);
