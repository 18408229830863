import React from "react";
import { connect } from "react-redux";
import GoalPlanEditionPage from "./goalPlanEditionPage";
import {
  disableLoading,
  enableLoading,
  notifyError,
  notifySuccess,
} from "../../redux/goalPlanOperations";
import { useSubmitUtils } from "features/goalPlan/hooks/useSubmitUtils";

const GoalPlanEditionContainer = ({
  goalPlanId,
  onBack,
  notifySuccess,
  notifyError,
  enableLoading,
  disableLoading,
  farmId,
}) => {
  const [showConfirmationModal, { onUpdatePlan }] = useSubmitUtils({
    disableLoading,
    enableLoading,
    notifyError,
    notifySuccess,
    farmId,
    callbackSubmit: onBack,
  });

  return (
    <GoalPlanEditionPage
      goalPlanId={goalPlanId}
      onBack={onBack}
      onSubmit={onUpdatePlan}
      showConfirmationModal={showConfirmationModal}
      enableLoading={enableLoading}
      disableLoading={disableLoading}
      notifyError={notifyError}
      farmId={farmId}
    />
  );
};

const mapDispatchToProps = {
  enableLoading: enableLoading,
  disableLoading: disableLoading,
  notifyError: notifyError,
  notifySuccess: notifySuccess,
};

const mapStateToProps = ({ fazenda }) => {
  return {
    farmId: fazenda.FazendaSelecionada,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoalPlanEditionContainer);
