import _ from "lodash";
import {
  CADASTRANDO_KML,
  LIMPAR_COORDENADAS_PASTOS_KML,
  EXIBIR_CARREGANDO,
  ERRO_VALIDAR_PASTO,
  CRIAR_PASTO,
  BUSCA_PASTOS_POR_ID_FAZENDA,
  NOTIFICAR,
  LISTA_PASTOS,
  LISTA_RETIROS,
} from "redux/actions/actionsConstants";
import trataErroGenerico from "lib/trataErroGenerico";
import criarConfigRequisicaoHeader from "lib/criarConfigRequisicaoHeader";
import novatentativa from "lib/novatentativa";
import auth from "features/authentication/Auth";

import {
  extraiPastosModulosDeRetiros,
  atualizaRetirosFazenda,
  atualizaPastosFazenda,
  atualizaModulosFazenda,
} from "features/manejo/redux/actions/funcoesKMLActinons";
import PastoService from "services/PastoService";
import RetiroService from "features/retiro/retiroService";

export const SET_FAZENDA_INFO = "SET_FAZENDA_INFO";
export const ATUALIZA_KML_FAZENDA = "ATUALIZA_KML_FAZENDA";

function trataErro(dispatch, error) {
  trataErroGenerico(
    dispatch,
    error,
    "Ocorreu um erro ao criar alguns pastos.",
    ERRO_VALIDAR_PASTO,
    "Erro ao criar pastos."
  );
}

export const setCadastrandoKml = (Cadastrando) => (dispatch) => {
  dispatch({ type: CADASTRANDO_KML, payload: Cadastrando });
};
export const limparCoordPastosKML = () => (dispatch) => {
  dispatch({ type: LIMPAR_COORDENADAS_PASTOS_KML });
};

/**
 * FIXME: Retirar chamadas pra serviços deste local
 * O service deve ser o responsável por fazer requisições
 **/
export const salvarPastosBatelada =
  (pastosKML, idFazenda) => async (dispatch) => {
    dispatch({ type: EXIBIR_CARREGANDO, payload: true });
    try {
      const listaPastosKML = pastosKML.map((pasto, index) => {
        pasto = _.omit(pasto, ["IdPasto", "Centro"]);
        pasto.IdFazenda = idFazenda;
        pasto.Nome = `Pasto ${index + 1}`;
        return pasto;
      });
      const config = criarConfigRequisicaoHeader(auth.getSession().email);
      const tentativa = new novatentativa(2);

      const pastosKMLInseridos = await Promise.all(
        listaPastosKML.map((pasto) =>
          tentativa.postServicoUrl(
            `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/pastos`,
            pasto,
            config
          )
        )
      );

      const retonoPastos = _.filter(
        pastosKMLInseridos,
        (res) => res.status === 200
      );
      const pastos = _.map(retonoPastos, (pasto) => pasto.data);
      const qtdPastosErro = listaPastosKML.length - retonoPastos.length;
      if (qtdPastosErro > 0) {
        dispatch({
          type: NOTIFICAR,
          payload: {
            mensagem: `Ocorreu um erro ao criar ${qtdPastosErro} pastos.`,
            variant: "error",
            estilo: "error",
          },
        });
      } else {
        dispatch({
          type: NOTIFICAR,
          payload: {
            mensagem: "Pastos Criados com Sucesso!",
            variant: "success",
            estilo: "success",
          },
        });
      }
      if (retonoPastos.length > 0)
        dispatch({ type: CRIAR_PASTO, payload: pastos });
    } catch (error) {
      trataErro(dispatch, error);
    }
    dispatch({ type: CADASTRANDO_KML, payload: false });
    dispatch({ type: BUSCA_PASTOS_POR_ID_FAZENDA, payload: true });
    dispatch({ type: EXIBIR_CARREGANDO, payload: false });
  };

export const setFazendaInfo = (fazendaInfo) => {
  return {
    type: SET_FAZENDA_INFO,
    payload: fazendaInfo,
  };
};

const getFeedBack = (mensagem, variant, estilo) => {
  return {
    mensagem,
    variant,
    estilo,
  };
};

export const atualizarKMLFazenda = (fazendaInfo) => async (dispatch) => {
  let feedBack;
  try {
    dispatch({ type: EXIBIR_CARREGANDO, payload: true });

    const { IdFazenda, Retiros } = fazendaInfo;
    const { pastosKML, modulosKML } = extraiPastosModulosDeRetiros(Retiros);

    const retirosAtualizados = await atualizaRetirosFazenda(IdFazenda, Retiros);
    let pastosAtualizados = null;
    if (retirosAtualizados.naoSalvos === 0) {
      pastosAtualizados = await atualizaPastosFazenda(
        IdFazenda,
        retirosAtualizados.retirosFazenda,
        pastosKML
      );
      if (pastosAtualizados.naoSalvos === 0) {
        const modulosAtualizados = await atualizaModulosFazenda(
          IdFazenda,
          retirosAtualizados.retirosFazenda,
          pastosAtualizados.pastosFazenda,
          modulosKML
        );
        if (modulosAtualizados.naoSalvos === 0) {
          feedBack = getFeedBack(
            "Mapa atualizado com Sucesso!",
            "success",
            "success"
          );
        } else {
          feedBack = getFeedBack(
            `${modulosAtualizados.naoSalvos} módulos não foram atualizados!`,
            "error",
            "error"
          );
        }
      } else {
        feedBack = getFeedBack(
          `${pastosAtualizados.naoSalvos} pastos não foram atualizados!`,
          "error",
          "error"
        );
      }
    } else {
      feedBack = getFeedBack(
        `${retirosAtualizados.naoSalvos} retiros não foram atualizados!`,
        "error",
        "error"
      );
    }

    const retornoPastos = await PastoService.listaPastosPorIdFazenda(
      IdFazenda,
      [],
      ["Modulo", "Cocho"]
    );
    dispatch({ type: LISTA_PASTOS, payload: { Pastos: retornoPastos.data } });
    const retornoRetiros = await RetiroService.listaRetirosPorIdFazenda(
      IdFazenda
    );
    dispatch({
      type: LISTA_RETIROS,
      payload: { Retiros: retornoRetiros.data },
    });
    dispatch({ type: LIMPAR_COORDENADAS_PASTOS_KML });
  } catch (error) { }
  dispatch({ type: CADASTRANDO_KML, payload: false });
  dispatch({ type: BUSCA_PASTOS_POR_ID_FAZENDA, payload: true });
  dispatch({ type: EXIBIR_CARREGANDO, payload: false });
  dispatch({ type: SET_FAZENDA_INFO, payload: null });
  dispatch({
    type: NOTIFICAR,
    payload: {
      ...feedBack,
    },
  });
};
