import React from "react";
import { Typography } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";

export const textLabel = (props) => {
  const {
    bold,
    color,
    typographyProps,
    theme,
    customStyle,
    size,
    className,
    label,
  } = props;
  return (
    <Typography
      {...typographyProps}
      component="span"
      className={className}
      style={{
        display: "inline",
        fontSize: size,
        fontWeight: bold ? "bold" : "",
        color: theme.palette[color].main,
        ...customStyle,
      }}
    >
      {label || props.children}
    </Typography>
  );
};

textLabel.defaultProps = {
  bold: false,
  color: "default1",
  typographyProps: {},
  customStyle: {},
  size: "inherit",
  className: "",
};
export default withTheme()(textLabel);
