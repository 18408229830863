import React from "react";
import CurralPage from "./curralPage";
import * as CurralOperations from "../redux/curralOperations";
import * as pastoActions from "features/manejo/redux/actions/pastoActions";
import * as RetiroOperations from "../../retiro/redux/retiroOperations";
import { connect } from "react-redux";

export const CurralContainer = (props) => {
  return (
    <CurralPage
      id="curralPage"
      currais={props.currais}
      fazendaSelecionada={props.fazendaSelecionada}
      retiros={props.retiros}
      lotes={props.lotes}
      obterLotesPorCurral={props.obterLotesPorCurral}
      criarCurral={props.criarCurral}
      editarCurral={props.editarCurral}
      excluirCurral={props.excluirCurral}
      listaCurrais={props.listaCurrais}
      listaRetiros={props.listaRetiros}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    currais: state.pasto.Currais,
    fazendaSelecionada: state.fazenda.FazendaSelecionada,
    retiros: state.retiro.Retiros,
    lotes: state.lote.Lotes,
  };
};

const mapDispatchToProps = {
  obterLotesPorCurral: CurralOperations.obterLotesPorCurral,
  criarCurral: CurralOperations.criarCurral,
  editarCurral: CurralOperations.atualizarCurral,
  excluirCurral: CurralOperations.deletarCurral,
  listaCurrais: pastoActions.listaCurrais,
  listaRetiros: RetiroOperations.listarRetiros,
};

export default connect(mapStateToProps, mapDispatchToProps)(CurralContainer);
