import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import * as moduloActions from "features/manejo/redux/actions/moduloActions";
import * as cochoActions from "features/manejo/redux/actions/cochoActions";
import * as pastoActions from "features/manejo/redux/actions/pastoActions";
import * as uiActions from "features/manejo/redux/actions/uiActions";
import MapaGoogleCustomizado from "features/manejo/views/components/MapaGoogleCustomizado/MapaGoogleCustomizadoContainer";
import PastosModulo from "./PastosModulo";
import PastosCochoCompartilhado from "./PastosCochoCompartilhado";
import Pastos from "./Pastos";
import PoligonoMundo from "features/manejo/views/components/PoligonoMundo";
import { cores } from "../../../../../constants/coresModulos";
import ReactGA from "react-ga";

class MapaVinculo extends Component {
  constructor(props) {
    super(props);
    ReactGA.pageview("/modulo");
  }
  componentDidMount = () => {
    this.props.alteraEstadoDropdown(uiActions.DROPDOWN_RETIRO, false);
  };
  componentWillUnmount = () => {
    this.props.alteraEstadoDropdown(uiActions.DROPDOWN_RETIRO, true);
  };

  onSelecionarModulo = (modulo) => {
    this.props.alteraEstadoPoligonoMundo(true);
    this.props.selecionaModulo(modulo);
  };

  onSelecionarCochoCompartilhado = (cochoCompartilhado) => {
    this.props.alteraEstadoPoligonoMundo(true);
    this.props.selecionaCochoCompartilhado(cochoCompartilhado);
  };

  onDesselecionarModulo = (modulo) => {
    this.props.alteraEstadoPoligonoMundo(false);
    this.props.deselecionaModulo();
  };

  onDesselecionarCochoCompartilhado = (cochoCompartilhado) => {
    this.props.alteraEstadoPoligonoMundo(false);
    this.props.deselecionaCochoCompartilhado();
  };

  onClickPasto = (pasto, selecionar) => {
    if (selecionar) {
      if (this.props.pastosSelecionados.length === 0) {
        this.props.setCorModulo(this.getRandomCor());
        this.props.alteraEstadoPoligonoMundo(true);
      }
      this.props.selecionaPasto(pasto);
    } else {
      if (this.props.pastosSelecionados.length === 1) {
        this.props.alteraEstadoPoligonoMundo(false);
      }
      this.props.deselecionaPasto(pasto);
    }
  };

  getRandomCor() {
    var rand = (Math.random() * (cores.length - 1)).toFixed(0);
    return cores[rand];
  }

  renderMundo = () => {
    return this.props.poligonoMundo ? <PoligonoMundo /> : "";
  };

  setState = (estado) => {
    this.props.alteraEstadoPoligonoMundo(estado);
  };

  handleZoomChanged() {
    const { alteraZoomMapa } = this.props;
    return function () {
      alteraZoomMapa(this.zoom);
    };
  }

  renderPastos = () => {
    const {
      zoomMapa,
      pastos,
      pastosSelecionados,
      Cor,
      corCocho,
      moduloSelecionado,
      cochoSelecionado,
    } = this.props;

    if (this.props.aba === 0) {
      if (pastos.length <= 0) return;
      const modulos = _.groupBy(pastos, (p) =>
        p.Modulo ? p.Modulo.IdModulo : null
      );

      return _.map(modulos, (pastos) => {
        if (_.first(pastos).Modulo) {
          const modulo = _.first(pastos).Modulo;
          modulo.Pasto = pastos;
          const outroModuloFoiSelecionado = _.some(
            pastosSelecionados,
            (pastoSelecionado) => pastoSelecionado.IdModulo !== modulo.IdModulo
          );

          return (
            <PastosModulo
              key={modulo.IdModulo}
              Modulo={modulo}
              Pastos={pastos}
              onClick={() => this.onSelecionarModulo(modulo)}
              onClose={() => this.onDesselecionarModulo(modulo)}
              zoomMapa={zoomMapa}
              selecionado={moduloSelecionado === modulo.IdModulo}
              bloqueado={outroModuloFoiSelecionado}
              corSelecionada={Cor}
            />
          );
        } else {
          return _.map(pastos, (pasto) => {
            const pastoSelecionado = _.some(pastosSelecionados, pasto);
            const pastoDeOutroRetiroFoiSelecionado = _.some(
              pastosSelecionados,
              (pastoSelecionado) => pastoSelecionado.IdRetiro !== pasto.IdRetiro
            );
            return (
              <Pastos
                key={pasto.IdPasto}
                Pasto={pasto}
                onClick={() => this.onClickPasto(pasto, !pastoSelecionado)}
                zoomMapa={zoomMapa}
                selecionado={pastoSelecionado}
                bloqueado={
                  moduloSelecionado !== 0 || pastoDeOutroRetiroFoiSelecionado
                }
                corSelecionada={pastoSelecionado ? Cor : "corPastoSemModulo"}
                ordem={
                  _.indexOf(
                    _.intersectionWith(
                      pastosSelecionados,
                      pastosSelecionados,
                      _.isEqual
                    ),
                    pasto
                  ) + 1
                }
                clicavel={!pastoDeOutroRetiroFoiSelecionado}
              />
            );
          });
        }
      });
    } else {
      if (pastos.length <= 0) return;
      const cochosCompartilhados = _.groupBy(pastos, (p) =>
        p.Cocho && p.Cocho.PastoCocho ? p.Cocho.PastoCocho.IdCocho : null
      );

      return _.map(cochosCompartilhados, (pastos) => {
        if (_.first(pastos).Cocho && _.first(pastos).Cocho.CochoCompartilhado) {
          const cocho = _.first(pastos).Cocho;
          cocho.Pasto = pastos;

          return (
            <PastosCochoCompartilhado
              key={cocho.IdCocho}
              Modulo={cocho}
              Pastos={pastos}
              pasturesQuantity={this.props.pasturesQuantity}
              onClick={() => this.onSelecionarCochoCompartilhado(cocho)}
              onClose={() => this.onDesselecionarCochoCompartilhado(cocho)}
              zoomMapa={zoomMapa}
              selecionado={cochoSelecionado === cocho.IdCocho}
              bloqueado={false}
              corSelecionada={corCocho}
              pastoSelecionados={pastosSelecionados}
            />
          );
        } else {
          return _.map(pastos, (pasto) => {
            const pastoSelecionado = _.some(pastosSelecionados, pasto);

            return (
              <Pastos
                key={pasto.IdPasto}
                Pasto={pasto}
                onClick={() => this.onClickPasto(pasto, !pastoSelecionado)}
                zoomMapa={zoomMapa}
                selecionado={pastoSelecionado}
                bloqueado={moduloSelecionado !== 0 || false}
                corSelecionada={pastoSelecionado ? Cor : "corPastoSemModulo"}
                ordem={null}
                clicavel={!cochoSelecionado}
              />
            );
          });
        }
      });
    }
  };

  render() {
    return (
      <div>
        <MapaGoogleCustomizado
          onZoomChanged={this.handleZoomChanged()}
          coordenadasCentro={this.props.coordenadasCentro}
          HabilitaDesenho={false}
          zoomPadrao={this.props.zoomPadrao}
        >
          {this.renderMundo()}
          {this.renderPastos()}
        </MapaGoogleCustomizado>
      </div>
    );
  }
}

MapaVinculo.propTypes = {
  coordenadasCentro: PropTypes.object.isRequired,
  fazendaSelecionada: PropTypes.number,
  pastos: PropTypes.array.isRequired,
  pastosSelecionados: PropTypes.array.isRequired,
  retiroSelecionado: PropTypes.number,
  zoomMapa: PropTypes.number,
  modulos: PropTypes.array.isRequired,
  pastoChaveModulo: PropTypes.number,
  poligonoMundo: PropTypes.bool.isRequired,
  moduloSelecionado: PropTypes.number,
  cochoSelecionado: PropTypes.number,
  corCocho: PropTypes.string,
};

const mapDispatchToProps = {
  selecionaPasto: moduloActions.selecionaPasto,
  deselecionaPasto: moduloActions.deselecionaPasto,
  setCorModulo: moduloActions.setCorModulo,
  alteraZoomMapa: moduloActions.alteraZoomMapa,
  alteraEstadoDropdown: uiActions.alteraEstadoDropdown,
  alteraEstadoPoligonoMundo: moduloActions.alteraEstadoPoligonoMundo,
  selecionaModulo: moduloActions.selecionaModulo,
  deselecionaModulo: moduloActions.deselecionaModulo,
  selecionaCochoCompartilhado: cochoActions.selecionaCochoCompartilhado,
  deselecionaCochoCompartilhado: cochoActions.deselecionaCochoCompartilhado,
  listaPastos: pastoActions.listaPastos,
};

const mapStateToProps = (state) => {
  return {
    aba: state.vinculo.aba,
    pasturesQuantity: state.pasto.pasturesQuantity,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MapaVinculo);
