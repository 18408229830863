import React, { useCallback, useState } from "react";
import UserListPage from "./userListPage";
import { connect } from "react-redux";
import * as actions from "redux/actions";
import userService from "../../userService";
import history from "features/app/History";
import { useDialogConfirm } from "features/collect/supplementation/views/shared/dialogConfirm";

const DEACTVATE_USER_ALERT_MESSAGE =
  "Ao desativar o usuário, seu acesso às fazendas será desativado, impedindo a sincronização de coletas. Tem certeza de que deseja prosseguir com esta ação? Você poderá reativá-lo a qualquer momento.";
const DEACTVATE_USER_ALERT_TITLE = "Desativar usuário";

export const UserListContainer = ({
  loading,
  notifyError,
  notifySuccess,
  filter,
  setFilter,
}) => {
  const clientId = sessionStorage.getItem("clienteSelecionado");
  const [currentUser, setCurrentUser] = useState();
  const [lastUpdate, setLastUpdate] = useState(new Date().getTime());

  const {
    render: renderDeactivateUserAlert,
    setVisible: setVisibleDeactivateUserAlert,
  } = useDialogConfirm({
    title: DEACTVATE_USER_ALERT_TITLE,
    text: DEACTVATE_USER_ALERT_MESSAGE,
    confirmButtonText: "SIM, DESATIVAR",
    handleConfirm: () => {
      changeStatus(currentUser.isActive, currentUser.userId);
      setCurrentUser(null);
    },
  });

  const saveFilterInRedux = (userManagmentFilter) => {
    setFilter({
      ...filter,
      userManagmentFilter,
    });
  };

  const onFilterChange = (value) => {
    saveFilterInRedux(value);
  };

  const getUsers = useCallback(
    async ({ limit, offset }) => {
      try {
        if (!filter.userManagmentFilter) {
          return;
        }
        const requestFilter = {
          ...(filter.userManagmentFilter || {}),
          usuarioInterno: false,
          usuarioAdministrador: false,
          idCliente: [clientId],
        };
        const users = await userService.listarUsuarios(
          limit,
          offset,
          requestFilter
        );
        return {
          total: users.total,
          itens: users.data,
        };
      } catch (err) {
        console.error(err);
        notifyError({
          mensagem: "Ocorreu um erro ao buscar as usuários.",
          variant: "error",
        });
        return {
          total: 0,
          itens: [],
        };
      } finally {
      }
    },
    [filter.userManagmentFilter]
  );

  const onNewUser = () => history.push(`/gestao-de-usuarios/cadastrar`);

  const onEdit = (user) =>
    history.push(`/gestao-de-usuarios/editar/${user.idUsuario}`);

  const onSendLink = async (user) => {
    try {
      loading(true);
      await userService.alterarSenha({ email: user.email });
      notifySuccess({
        mensagem: "Link enviado com sucesso.",
      });
    } catch (err) {
      console.error(err);
      notifyError({
        mensagem: "Ocorreu um erro ao enviar o link para alterar a senha.",
        variant: "error",
      });
    } finally {
      loading(false);
    }
  };

  const onCopyLink = async (user) => {
    try {
      loading(true);
      const response = await userService.alterarSenha({
        email: user.email,
        idUsuario: user.idUsuario,
      });
      navigator.clipboard.writeText(response.url);
      notifySuccess({
        mensagem: "Link copiado para área de transferência.",
      });
    } catch (err) {
      console.error(err);
      notifyError({
        mensagem: "Ocorreu um erro ao enviar o link para alterar a senha.",
        variant: "error",
      });
    } finally {
      loading(false);
    }
  };

  const changeStatus = async (isActive, userId) => {
    try {
      loading(true);
      if (isActive) {
        await userService.desativarUsuario(userId, clientId);
      } else {
        await userService.ativarUsuario(userId, clientId);
      }
      const message = isActive
        ? "Usuário inativado com sucesso!"
        : "Usuário ativado com sucesso!";
      notifySuccess({
        mensagem: message,
      });
    } catch (err) {
      console.error(err);
      notifyError({
        mensagem: "Ocorreu um erro ao alterar o status.",
        variant: "error",
      });
    } finally {
      setLastUpdate(new Date().getTime());
      loading(false);
    }
  };

  const onChangeStatus = async (user) => {
    const isActive = user.clientes.find(
      (c) => c.idCliente.toString() === clientId.toString()
    ).ativo;
    const userId = user.idUsuario;
    if (isActive) {
      setCurrentUser({ isActive, userId });
      setVisibleDeactivateUserAlert(true);
    } else {
      changeStatus(isActive, userId);
    }
  };

  return (
    <>
      <UserListPage
        getUsers={getUsers}
        lastUpdate={lastUpdate}
        clientId={clientId}
        filter={filter.userManagmentFilter}
        onFilterChange={onFilterChange}
        onEdit={onEdit}
        onSendLink={onSendLink}
        onCopyLink={onCopyLink}
        onNewUser={onNewUser}
        onChangeStatus={onChangeStatus}
      />
      {renderDeactivateUserAlert()}
    </>
  );
};

const mapStateToProps = ({ filter }) => ({
  filter,
});

export const mapDispatchToProps = {
  loading: actions.exibirCarregando,
  notifyError: actions.notifyError,
  notifySuccess: actions.notifySuccess,
  setFilter: actions.setFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserListContainer);
