import {
  SALVAR_REBANHO,
  ERRO_VALIDAR_REBANHO,
} from "redux/actions/actionsConstants";
import trataErroGenerico from "lib/trataErroGenerico";
import { createServiceHandler } from "services/ServiceHandler";

function infoBasicaRebanho(Rebanho) {
  return {
    QtdAnimais: Rebanho.QtdAnimais,
    PesoMedido: Rebanho.PesoMedido,
    DataPesagem: Rebanho.DataPesagem,
    PesoVisual: Rebanho.PesoVisual,
  };
}

function trataErro(dispatch, error) {
  trataErroGenerico(
    dispatch,
    error,
    "Ocorreu um erro ao tentar salvar o Rebanho.",
    ERRO_VALIDAR_REBANHO,
    "Erro ao salvar o Rebanho."
  );
}

/**
 * FIXME: Retirar chamadas pra serviços deste local
 * O service deve ser o responsável por fazer requisições
 **/
export const criarRebanho = (Rebanho) => async (dispatch) => {
  try {
    const data = infoBasicaRebanho(Rebanho);
    /**
     * //TODO:
     * Passar método para service
     */
    const res = await createServiceHandler().post(
      `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/pastos/${Rebanho.IdPasto}/rebanho`,
      data
    );
    dispatch({
      type: SALVAR_REBANHO,
      payload: { ...res.data },
    });
  } catch (error) {
    trataErro(dispatch, error);
  }
};

/**
 * FIXME: Retirar chamadas pra serviços deste local
 * O service deve ser o responsável por fazer requisições
 **/
export const salvarRebanho = (Rebanho) => async (dispatch) => {
  try {
    const data = infoBasicaRebanho(Rebanho);
    /**
     * //TODO:
     * Passar método para service
     */
    const res = await createServiceHandler().put(
      `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/pastos/${Rebanho.IdPasto}/rebanho`,
      data
    );
    dispatch({
      type: SALVAR_REBANHO,
      payload: { ...res.data },
    });
  } catch (error) {
    trataErro(dispatch, error);
  }
};
