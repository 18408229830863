import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import { Minimize } from "@material-ui/icons";
import DatePicker from "features/shared/components/DatePickers/MonthDatePicker";
import PlanningStatus from "./planningStatus";

const PlanningsForm = ({
  classes,
  disabled,
  showStatus,
  planningPlanData,
  showDatePicker,
  setShowDatePicker,
  interceptDatePicker,
  onClickInitialDate,
  onChangeInitialDate = () => {},
}) => {
  const { initialDate, finalDate } = planningPlanData;

  const onClick = () => {
    if (interceptDatePicker && onClickInitialDate) onClickInitialDate();
  };

  const createDialogProps = () => {
    if (!interceptDatePicker) return null;
    return {
      open: showDatePicker,
      onDismiss: () => setShowDatePicker(false),
    };
  };

  const handleChangeInitialDate = (initialDate) => {
    onChangeInitialDate(initialDate);
  };

  return (
    <Grid
      data-testid="planning-form-container"
      container
      className={classes.registerFormContainer}
    >
      <Grid className={classes.rangeDateContainer}>
        <DatePicker
          testid="Initial-Date-Picker"
          className={classes.datePicker}
          label="Período"
          required
          value={initialDate}
          disabled={disabled}
          onChange={handleChangeInitialDate}
          onClick={onClick}
          DialogProps={createDialogProps()}
        />
        <Minimize className={classes.separator} />
        <DatePicker
          testid="Final-Date-Picker"
          className={classes.datePicker}
          disabled
          value={finalDate}
        />
      </Grid>
      {showStatus && (
        <PlanningStatus
          initialDate={planningPlanData.initialDate}
          finalDate={planningPlanData.finalDate}
          customClassName={classes.status}
        />
      )}
    </Grid>
  );
};

const styles = (theme) => ({
  registerFormContainer: {
    marginTop: "25px",
    gap: "24px",
  },
  rangeDateContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    gap: "5px",
  },
  separator: {
    color: theme.palette.default1.light,
  },
  datePicker: {
    width: "150px",
  },
  inputPlanName: {
    width: "263px",
  },
  required: {
    "&>span": {
      color: "red",
    },
  },
  status: {
    height: "28px",
    alignSelf: "end",
    "&>span": {
      fontSize: "14px",
      fontWeight: "500",
    },
  },
});

export default withStyles(styles)(PlanningsForm);
