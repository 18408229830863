import { useState } from "react";
import auth from "../Auth";
import history from "../../app/History";

export function useForgotPassword({ origem, notifyError, notifySuccess }) {
  const [error, setError] = useState(false);
  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      setError(false);
      const email = (event.target.email.value || "").toLowerCase().trim();

      const regex = /^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]+(\.[a-z]+)?$/;
      const isValid = regex.test(email);

      if (!isValid) {
        setError(true);
        notifyError({
          mensagem: "Informe um e-mail válido",
          variant: "error",
        });
        return;
      }

      await auth.resetPassword(email);

      notifySuccess({
        mensagem: "E-mail enviado com sucesso!",
        variant: "success",
      });
    } catch (error) {
      const userNotFound = error.message.includes("404");
      setError(true);
      notifyError({
        mensagem: userNotFound
          ? "Usuário não encontrado"
          : "Erro ao enviar e-mail de recuperação de senha",
        variant: "error",
      });
    }
  };

  const navigateToLogin = () => {
    if (origem === "app") {
      const urlApp = process.env.URL_APP;
      window.location.href(urlApp);
      return;
    }
    history.replace("/login");
  };

  return { onSubmit, navigateToLogin, error };
}
