import React, { Component } from "react";
import { connect } from "react-redux";
import { default as MapaCadastroComponent } from "../MapaCadastro";

export class MapaCadastro extends Component {
  render() {
    const criandoNovoPasto = this.props.location.pathname.includes(
      "cadastro_novo_pasto"
    );

    return (
      <MapaCadastroComponent
        id="containerMapaCadastro"
        {...this.props.reduxProps}
        zoomPadrao={14}
        criandoNovoPasto={criandoNovoPasto}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reduxProps: {
      pastos: state.pasto.Pastos,
      pastosKML: state.pasto.PastosKML,
      pastoSelecionado: state.pasto.PastoSelecionado,
      retiroSelecionado: state.retiro.RetiroSelecionado,
      coordenadasCentro: state.fazenda.centroMapa,
      cadastroPastoModificado: state.pasto.cadastroPastoModificado,
      fazendaSelecionada: state.fazenda.FazendaSelecionada,
      forragens: state.forragem.Forragens,
    },
  };
};

export default connect(mapStateToProps)(MapaCadastro);
