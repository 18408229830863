import React from "react";
import { Divider, withStyles } from "@material-ui/core";
import { compose } from "redux";
import { connect } from "react-redux";
import SkeletonLoading from "features/shared/components/SkeletonLoading";

import { useUserManagementMutation } from "../../../hooks/useUserManagementMutation";
import * as actions from "redux/actions";
import {
  Title,
  Form,
  FarmAndRetreatLink,
  PermissionInfoCard,
  Footer,
} from "./components";

function UserForm({
  classes,
  notifyError,
  notifySuccess,
  loadingShown,
  userId,
}) {
  const {
    isLoading,
    formState,
    onChange,
    onBlur,
    onAddFarmAndRetreatLink,
    onRemoveFarmAndRetreatLink,
    onSubmit,
    onCancel,
  } = useUserManagementMutation({
    userId,
    loadingShown,
    notifyError,
    notifySuccess,
  });

  const isEdition = userId !== undefined;

  if (isLoading) {
    return (
      <div>
        <SkeletonLoading width="11%" height={24} />
        <SkeletonLoading width="100%" height={144} marginTop={24} />
        <SkeletonLoading width="100%" height={245} marginTop={81} />
        <SkeletonLoading width="100%" height={505} marginTop={81} />
        <SkeletonLoading width="22%" height={53} marginTop={40} />
      </div>
    );
  }

  return (
    <div>
      <Title isEdition={isEdition} />
      <Form
        isEdition={isEdition}
        state={formState}
        onBlur={onBlur}
        onChange={onChange}
      />
      <Divider className={classes.divider} />
      <FarmAndRetreatLink
        data={formState.farmAndRetreatLinks}
        onAdd={onAddFarmAndRetreatLink}
        onRemove={onRemoveFarmAndRetreatLink}
      />
      <Divider className={classes.divider} />
      <PermissionInfoCard />
      <Footer isEdition={isEdition} onSubmit={onSubmit} onCancel={onCancel} />
    </div>
  );
}

const styles = () => ({
  divider: {
    margin: "40px 0",
  },
});

const mapDispatchToProps = {
  notifyError: actions.notifyError,
  notifySuccess: actions.notifySuccess,
  loadingShown: actions.exibirCarregando,
};

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps)
)(UserForm);
