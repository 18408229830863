import { TipoCliente } from './clienteConstants'

export const obterMensagemErro = erro => {
  let codigoErro = '';
  const regex = /\d+/g;
  const matches = String(erro).match(regex);

  if (matches && matches[0]) {
    codigoErro = matches[0];
  }

  switch (codigoErro) {
    case '404':
      return 'Desculpe, nenhum cliente encontrado. Por favor, tente novamente.';
    case '422':
      return 'Não foi possível realizar essa ação. Por favor, tente novamente.';
    default:
      return 'Ocorreu algo inesperado ao listar clientes. Por favor, tente novamente.';
  }
};

export const clienteLite = (cliente) => {
  return cliente && cliente.TipoCliente === TipoCliente.ClienteLite;
}
