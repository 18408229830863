import {
  SET_CLIENTE,
  LISTA_USUARIOS_CLIENTES,
} from "../../../redux/actions/actionsConstants";

export const initialState = {
  ClienteSelecionado: null,
  Cliente: null,
  UsuarioClientes: [],
  Clientes: [],
  erros: {
    Cliente: "",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CLIENTE: {
      const clienteSelecionado =
        state.Clientes.find(
          (cliente) => cliente.IdCliente === action.payload.IdCliente
        ) || state.Clientes[0];

      return { ...state, ClienteSelecionado: clienteSelecionado };
    }
    case LISTA_USUARIOS_CLIENTES: {
      return {
        ...state,
        ClienteSelecionado: action.payload.ClienteSelecionado,
        UsuarioClientes: action.payload.UsuarioClientes,
        Clientes: action.payload.Clientes,
      };
    }
    default:
      return state;
  }
};
