import React from "react";
import { Input, withStyles } from "@material-ui/core";
import TabelaProdap from "features/shared/components/TabelaProdap";

const TableItem = ({ classes, month, value, disabled = false, onChange }) => {
  const mean = (value ? value * 30 : 0).toFixed(2);
  return (
    <div className={classes.container}>
      <Input
        disabled={disabled}
        className={classes.input}
        disableUnderline={true}
        value={value}
        onChange={onChange}
        endAdornment={"kg/dia"}
        id={month.Id}
        label={month.Label}
        inputProps={{
          min: 0,
        }}
        type="number"
      />
      <span className={classes.dailyMean}>{mean} kg/mês</span>
    </div>
  );
};

const TableItemMean = ({ classes, months, curves }) => {
  const dailySum = months
    .map((month) => curves[month.Nome])
    .reduce((a, b) => a + b);
  const dailyMean = (dailySum / 12).toFixed(2);
  const monthMean = ((dailySum * 30) / 12).toFixed(2);

  return (
    <div className={classes.meanContainer}>
      <span className={classes.totalMean} data-testid="totalDailyMean">
        {dailyMean} kg/dia
      </span>
      <span className={classes.totalMean} data-testid="totalMonthMean">
        {monthMean} kg/mês
      </span>
    </div>
  );
};

const getCollums = (classes, months, curves, disabled, onChange = () => {}) => {
  const monthsCollums = months.map((month) => ({
    id: month.Id,
    titulo: month.Label,
    ordenavel: false,
    pegaValor: (curves) => (
      <TableItem
        disabled={disabled}
        classes={classes}
        month={month}
        value={curves[month.Nome]}
        onChange={(event) => onChange(event, month)}
      />
    ),
  }));

  return [
    ...monthsCollums,
    {
      id: "mean",
      titulo: "Média",
      ordenavel: false,
      pegaValor: () => (
        <TableItemMean classes={classes} months={months} curves={curves} />
      ),
    },
  ];
};

const GanhoPesoTableForm = ({
  classes,
  months,
  curves,
  disabled = false,
  onChangeCurve = () => {},
}) => {
  const ganhoPesoTableFormList = [curves];
  const columns = getCollums(classes, months, curves, disabled, onChangeCurve);

  return (
    <div
      className={`${disabled && classes.disabled} ${classes.wrapperTable} ${
        classes.fixLastColumn
      } `}
    >
      <TabelaProdap
        idCabecalhoTabela={"ganhoPesoTableFormHeader"}
        colunas={columns}
        ativarPaginacao={false}
        linhas={ganhoPesoTableFormList}
        tabelaEsquerda={true}
      />
    </div>
  );
};

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    fontFamily: "Roboto",
    fontSize: "14px",
    letterSpacing: "normal",
    textAlign: "left",
  },
  input: {
    borderRadius: "4px",
    border: "solid 1px #d9d9d9",
    fontSize: "14px",
    letterSpacing: "0.44px",
    padding: "3px 8px",
    color: "#424242",
  },
  meanContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
    fontFamily: "Roboto",
    fontSize: "14px",
    letterSpacing: "normal",
    textAlign: "left",
    padding: 10,
  },
  dailyMean: {
    padding: 8,
    color: "#616161",
  },
  totalMean: {
    fontWeight: 500,
    color: "#616161",
  },
  fixLastColumn: {
    "& td + td:last-child": {
      position: "sticky",
      minWidth: "132px !important",
      right: 0,
      zIndex: 0,
      boxShadow: "inset 4px 0 4px -2px rgba(0, 0, 0, 0.2)",
    },
    "& thead tr th:last-child": {
      padding: "0  !important",
      textAlign: "center",
      position: "sticky",
      minWidth: "104px !important",
      right: 0,
      top: 0,
      zIndex: 200,
      boxShadow: "inset 4px 0 4px -2px rgba(0, 0, 0, 0.2)",
    },
  },
  wrapperTable: {
    marginTop: "40px",
    "& thead th": {
      textTransform: "capitalize !important",
      padding: "0 8px  !important",
      position: "sticky",
      zIndex: 100,
      top: 0,
      backgroundColor: "#e1e1e1",
    },
    "& td": {
      padding: "11px 8px  !important",
      backgroundColor: "white",
      zIndex: 1,
      minWidth: 125,
      whiteSpace: "nowrap",
    },
  },
  disabled: {
    margin: "0px 8px",
    width: "calc(100vw - 116px)",
    "& *": {
      backgroundColor: "#fafafa",
      "&:hover": {
        backgroundColor: "#fafafa !important",
      },
    },
    "& thead tr": {
      height: "auto !important",
    },
    "& thead th": {
      paddingTop: "16px !important",
      backgroundColor: "unset",
      color: theme.palette.primary.main,
      border: "unset",
    },
    "& > :first-child": {
      borderRadius: "unset !important",
      boxShadow: "unset",
    },
    "& td": {
      backgroundColor: "unset",
      border: "unset",
    },
    "& td + td:last-child": {
      backgroundColor: "#fafafa",
    },
    "& thead tr th:last-child": {
      backgroundColor: "#fafafa",
    },
  },
});

export default withStyles(styles)(GanhoPesoTableForm);
