import BaseService from "../../services/BaseService";
import moment from "moment";

export const CONCILIACAO_BASE_URL = `${process.env.REACT_APP_ENDPOINT}herds/conciliation`;
class ConciliacaoService extends BaseService {
  async listarConciliacoes(IdFazenda, DataInicio, DataFim, InicioConciliacao) {
    const urlService = this.montaURL(
      `${CONCILIACAO_BASE_URL}/calculate`,
      `farmId=${IdFazenda}&startConciliation=${InicioConciliacao}T00:00:00.000Z&startInterval=${DataInicio}T00:00:00.000Z&endInterval=${DataFim}T23:59:59.999Z`
    );
    const response = await this.get(urlService);
    response.data = {
      Resumo: this.buildResume(response.data),
      Detalhes: this.buildDetail(response.data),
    };
    return response;
  }
  async detalhesCategoriaConciliacao(
    IdFazenda,
    IdCategoriaAnimal,
    DataInicial,
    DataFinal
  ) {
    const urlService = this.montaURL(
      `${CONCILIACAO_BASE_URL}/history`,
      `farmId=${IdFazenda}&startInterval=${moment(
        DataInicial
      ).toISOString()}&endInterval=${moment(
        DataFinal
      ).toISOString()}&animalCategoryId=${IdCategoriaAnimal}`
    );
    const response = await this.get(urlService);
    response.data = this.buildHistory(response.data);
    return response;
  }
  buildHistory(data) {
    return {
      items: data.map((item) => ({
        DataOperacao: item.operationDate,
        UsuarioCriacao: item.creationUser,
        TipoOperacao: item.recordType,
        Origem: item.origin,
        Quantidade: Math.abs(item.quantity),
        PesoMedido: item.weightType === "P" ? item.weight : null,
        PesoVisual: item.weightType === "V" ? item.weight : null,
        PastoOperacao: { Nome: item.pastureName },
      })),
    };
  }

  buildResume(newData) {
    const resumeApp = {
      EstoqueInicial: 0,
      Entrada: 0,
      Saida: 0,
      EntradaTrocaDeCategoria: 0,
      SaidaTrocaDeCategoria: 0,
      Nascimento: 0,
      Morte: 0,
      Entrevero: 0,
      EstoqueFinal: 0,
      Origem: "Aplicativo",
      Diferenca: 0,
    };

    const resumePortal = {
      ...resumeApp,
      Entrevero: "-",
      Origem: "Portal",
    };

    for (const resumeCategory of newData) {
      resumeApp.EstoqueInicial += Math.abs(resumeCategory.appInitialStock);
      resumeApp.Entrada += Math.abs(resumeCategory.appIn);
      resumeApp.Saida += Math.abs(resumeCategory.appOut);
      resumeApp.EntradaTrocaDeCategoria += Math.abs(
        resumeCategory.categoryChangeIn
      );
      resumeApp.SaidaTrocaDeCategoria += Math.abs(
        resumeCategory.categoryChangeOut
      );
      resumeApp.Nascimento += Math.abs(resumeCategory.birth);
      resumeApp.Morte += Math.abs(resumeCategory.death);
      resumeApp.Entrevero += Math.abs(resumeCategory.interfusion);
      resumeApp.EstoqueFinal += Math.abs(resumeCategory.appFinal);
      resumeApp.Diferenca +=
        Number(resumeCategory.portalFinal) - Number(resumeCategory.appFinal);

      resumePortal.EstoqueInicial += Number(resumeCategory.portalInitialStock);
      resumePortal.Entrada += Math.abs(resumeCategory.portalIn);
      resumePortal.Saida += Math.abs(resumeCategory.portalOut);
      resumePortal.EntradaTrocaDeCategoria += Math.abs(
        resumeCategory.categoryChangeIn
      );
      resumePortal.SaidaTrocaDeCategoria += Math.abs(
        resumeCategory.categoryChangeOut
      );
      resumePortal.Nascimento += Math.abs(resumeCategory.birth);
      resumePortal.Morte += Math.abs(resumeCategory.death);
      resumePortal.EstoqueFinal += Number(resumeCategory.portalFinal);
      resumePortal.Diferenca +=
        Number(resumeCategory.portalFinal) - Number(resumeCategory.appFinal);
    }
    resumeApp.Diferenca = resumePortal.EstoqueFinal - resumeApp.EstoqueFinal;
    resumePortal.Diferenca = resumePortal.EstoqueFinal - resumeApp.EstoqueFinal;

    return [resumeApp, resumePortal];
  }

  buildDetail(newData) {
    const detail = [];
    for (const resumeCategory of newData) {
      detail.push({
        CategoriaAnimal: {
          IdCategoriaAnimal: resumeCategory.animalCategoryId,
          Nome: resumeCategory.animalCategoryName,
        },
        EstoqueInicial: {
          App: Math.abs(resumeCategory.appInitialStock),
          Portal: Number(resumeCategory.portalInitialStock),
        },
        Entrada: {
          App: Math.abs(resumeCategory.appIn),
          Portal: Math.abs(resumeCategory.portalIn),
        },
        Saida: {
          App: Math.abs(resumeCategory.appOut),
          Portal: Math.abs(resumeCategory.portalOut),
        },
        EntradaTrocaDeCategoria: Math.abs(resumeCategory.categoryChangeIn),
        SaidaTrocaDeCategoria: Math.abs(resumeCategory.categoryChangeOut),
        Nascimento: Math.abs(resumeCategory.birth),
        Morte: Math.abs(resumeCategory.death),
        Entrevero: {
          App: Math.abs(resumeCategory.interfusion),
          Portal: "-",
        },
        EstoqueFinal: {
          App: Math.abs(resumeCategory.appFinal),
          Portal: resumeCategory.portalFinal,
        },
        Diferenca: Math.abs(
          resumeCategory.portalFinal - resumeCategory.appFinal
        ),
      });
    }
    return detail;
  }
}
export default new ConciliacaoService();
