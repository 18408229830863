import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  disableLoading,
  enableLoading,
  notifyError as notifyErrorAction,
  notifySuccess as notifySuccessAction,
} from "../../redux/planningOperations";
import { useSubmitUtils } from "features/planning/hooks/useSubmitUtils";
import ConfirmationModal from "features/shared/components/confirmationModal";
import PlanningEditPage from "./planningEditPage";
import planningService from "features/planning/planningService";

import { usePlanningPlanDataForm } from "features/planning/hooks/usePlanningDataForm";
import { useGoalValidations } from "features/planning/hooks/useGoalValidations";
import usePlanningRegisterTable from "features/planning/hooks/usePlanningRegisterTable";
import ExitModal from "../planningRegister/exitModal";
import { useExitConfirmation } from "../../hooks/useExitConfirmation";

const PlanningEditContainer = ({
  farmId,
  planningCode,
  enableLoading,
  disableLoading,
  notifyError,
  notifySuccess,
  goBack = () => {},
  changeTabRequested,
  onCancelChangeTab,
}) => {
  const [currentPlanning, setCurrentPlanning] = useState({});
  const {
    showExitModal,
    handleExitModalCancel,
    handleExitModalSubmmit,
    requestExit,
  } = useExitConfirmation({ changeTabRequested, onCancelChangeTab, goBack });

  const callbackSubmit = () => {
    goBack();
  };

  const [showConfirmationModal, { onEditPlan }] = useSubmitUtils({
    farmId,
    enableLoading,
    disableLoading,
    notifyError,
    notifySuccess,
    callbackSubmit,
  });

  const findPlanning = async () => {
    try {
      if (!planningCode) return;
      enableLoading("findPlanning");
      const result = await planningService.getPlan(planningCode);
      setPlanningPlanData({
        id: result.id,
        initialDate: result.initialDate,
        finalDate: result.finalDate,
      });
      setCurrentPlanning(result);
      loadExistingPlanning(result);
    } catch (error) {
      if (error) notifyError(error);
    } finally {
      disableLoading("findPlanning");
    }
  };

  const {
    planningPlanData,
    periodTitles,
    setPlanningPlanData,
    onChangePlanningInitialDate,
  } = usePlanningPlanDataForm();

  const {
    freeCategories,
    categories,
    formulas,
    formulasIngredients,
    planningsTableList,
    planningList,
    ingredientsPrices,
    onChangeCategory,
    onChangeFormula,
    onChangeHerdCount,
    onChangeIngredientPrice,
    addNewLine,
    onDeleteLine,
    updateIngredientsList,
    summaryReport,
    updateSummaryReport,
    loadExistingPlanning,
  } = usePlanningRegisterTable(
    farmId,
    enableLoading,
    disableLoading,
    notifyError
  );

  useEffect(() => {
    if (formulas && formulas.length > 0) findPlanning();
  }, [planningCode, formulas]);

  const {
    activeGoalPeriod,
    hasGoalPlanSync,
    isGoalPlanUnsync,
    modalSubmitActiveGoalPeriod,
  } = useGoalValidations({
    farmId,
    enableLoading,
    disableLoading,
    currentPlanning,
    planningPlanDataInitialDate: planningPlanData.initialDate,
    onChangePlanningInitialDate,
  });

  const onConfirmSubmit = () => {
    modalSubmitActiveGoalPeriod.setModalSubmitActiveGoalPeriod(false);
    onSubmit();
  };

  const handleOnSubmit = () => {
    if (modalSubmitActiveGoalPeriod.shouldInterceptSubmit) {
      modalSubmitActiveGoalPeriod.setModalSubmitActiveGoalPeriod(true);
    } else {
      onConfirmSubmit();
    }
  };

  const onSubmit = () => {
    onEditPlan(planningCode, {
      ...planningPlanData,
      planningList,
      ingredientsPrices,
      summaryReport,
      formulas,
      categories,
    });
  };

  return (
    <>
      <PlanningEditPage
        planningPlanData={planningPlanData}
        periodTitles={periodTitles}
        freeCategories={freeCategories}
        ingredientsPrices={ingredientsPrices}
        formulasIngredients={formulasIngredients}
        onChangeIngredientPrice={onChangeIngredientPrice}
        updateIngredientsList={updateIngredientsList}
        categories={categories}
        formulas={formulas}
        planningsTableList={planningsTableList}
        planningList={planningList}
        onChangeCategory={onChangeCategory}
        onChangeFormula={onChangeFormula}
        onChangeHerdCount={onChangeHerdCount}
        addNewLine={addNewLine}
        onDeleteLine={onDeleteLine}
        onChangePlanningInitialDate={onChangePlanningInitialDate}
        hasGoalPlanSync={hasGoalPlanSync}
        isGoalPlanUnsync={isGoalPlanUnsync}
        activeGoalPeriod={activeGoalPeriod}
        onSubmit={handleOnSubmit}
        goBack={requestExit}
        summaryReport={summaryReport}
        updateSummaryReport={updateSummaryReport}
      />
      {modalSubmitActiveGoalPeriod.modalSubmitActiveGoalPeriod && (
        <ConfirmationModal
          title="Editar planejamento"
          text={modalSubmitActiveGoalPeriod.getSubmitActiveGoalMessage()}
          submitButtonTitle="Sim, salvar"
          onSubmit={onConfirmSubmit}
          onCancel={modalSubmitActiveGoalPeriod.onCancelSubmit}
        />
      )}

      {showConfirmationModal !== null && (
        <ConfirmationModal
          title={showConfirmationModal.title}
          text={showConfirmationModal.text}
          submitButtonTitle={showConfirmationModal.buttonConfirmText}
          onSubmit={showConfirmationModal.onConfirm}
          onCancel={showConfirmationModal.onCancel}
        />
      )}
      {showExitModal && (
        <ExitModal
          onSubmit={handleExitModalSubmmit}
          onCancel={handleExitModalCancel}
        />
      )}
    </>
  );
};

export const mapDispatchToProps = {
  enableLoading: enableLoading,
  disableLoading: disableLoading,
  notifyError: notifyErrorAction,
  notifySuccess: notifySuccessAction,
};

const mapStateToProps = ({ fazenda }) => {
  return {
    farmId: fazenda.FazendaSelecionada,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanningEditContainer);
