import { Input, Tooltip, withStyles } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import SelectDropdown from "components/SelectDropdown";
import React from "react";

const ingredientWarnningText = `A formulação atual não possui ingredientes
  cadastrados. Para continuar com o planejamento
  de custo, é necessário cadastrar os ingredientes
  na tela "Ingredientes". Em seguida, vá para a tela
  "Formulações", selecione a formulação desejada
  e faça a vinculação dos ingredientes.`;

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  input: {
    borderRadius: "4px",
    border: "solid 1px #d9d9d9",
    fontSize: "14px",
    letterSpacing: "0.44px",
    padding: "3px 8px",
    color: "#333",
    "& input::placeholder": {
      color: "rgba(117, 117, 117, 0.7)",
    },
  },
  ingredientWarnning: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    "& svg": {
      width: "16px",
      height: "16px",
      alignSelf: "center",
      fill: "#EB394B",
    },
    "& span": {
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      color: "#333333",
    },
  },
  tooltip: {
    width: "325px",
    padding: "16px",
    borderRadius: "4px",
    fontFamily: "Roboto",
    fontSize: "14px",
  },
});

const PlanningTablePeriodItem = ({
  formulas,
  classes,
  onChangeHerdCount,
  onChangeFormula,
  herdCount,
  formulaId,
  hasIngredients,
}) => {
  const options = formulas
    .map((formula) => ({
      id: formula.IdFormula,
      value: formula.IdFormula,
      label: formula.Nome,
      deleted: formula.Excluido,
      active: formula.Excluido ? "Inativas" : "Ativas",
    }))
    .sort((a, b) => (a.deleted === b.deleted ? 0 : b.deleted ? -1 : 1));

  const handleNumeric = ({ target }) => {
    if (!target.value) onChangeHerdCount(0);
    if (target.value) {
      onChangeHerdCount(Number(target.value.replace(/\D/g, "")).toFixed());
    }
  };

  const selectStyles = {
    fontSize: "14px",
    letterSpacing: "0.44px",
    color: "#333",
    padding: 0,
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: hasIngredients === false ? "#EB394B" : "#d9d9d9",
    },
    "& .MuiInputBase-root": {
      padding: 0,
    },
    "& .MuiButtonBase-root": {
      padding: 0,
    },
    "& input::placeholder": {
      color: "rgba(117, 117, 117, 0.7)",
    },
  };

  return (
    <div className={classes.container}>
      <Input
        className={classes.input}
        placeholder="Nº animais"
        endAdornment="cab"
        disableUnderline={true}
        onChange={handleNumeric}
        value={herdCount}
      />

      <SelectDropdown
        isSearchable
        emptyText="Formulação"
        options={options}
        groupBy={(formula) => formula.active}
        onChange={onChangeFormula}
        value={formulaId || null}
        styles={selectStyles}
      />
      {hasIngredients === false && (
        <div className={classes.ingredientWarnning}>
          <Tooltip
            title={ingredientWarnningText}
            classes={{ tooltip: classes.tooltip }}
          >
            <InfoOutlined />
          </Tooltip>
          <span>Sem ingrediente cadastrado</span>
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(PlanningTablePeriodItem);
