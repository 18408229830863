import { useEffect, useRef, useState } from "react";
import usePlanningRegisterTable from "./usePlanningRegisterTable";
import { usePlanningPlanDataForm } from "./usePlanningDataForm";
import planningService from "../planningService";
import { useReactToPrint } from "react-to-print";
import { parsePlanning } from "../views/shared/utils";

export default function usePrintPlanning({
  farmId,
  enableLoading,
  disableLoading,
  notifyError,
}) {
  const {
    categories,
    formulas,
    planningList,
    setPlanningList,
    summaryReport,
    setSummaryReport,
    formulaHasIngredients,
  } = usePlanningRegisterTable(
    farmId,
    enableLoading,
    disableLoading,
    notifyError
  );
  const { planningPlanData, periodTitles, setPlanningPlanData } =
    usePlanningPlanDataForm();

  const [isPrinting, setIsPrinting] = useState(false);
  const printRef = useRef(null);

  const promiseResolveRef = useRef(null);

  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
      });
    },
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setIsPrinting(false);
    },
  });

  const findPlanning = async (planningId) => {
    try {
      if (!planningId) return;
      enableLoading("findPlanning");
      const planning = await planningService.getPlanReport(planningId);

      const planningItemsArr = parsePlanning(planning, formulaHasIngredients);

      setPlanningList(planningItemsArr);
      setPlanningPlanData({
        ...planning,
        initialDate: planning.initialDate,
        finalDate: planning.finalDate,
      });
      setSummaryReport(planning);
    } catch (error) {
      notifyError(error);
    } finally {
      disableLoading("findPlanning");
      setIsPrinting(true);
    }
  };

  const handlePrintPlanning = async (idPlanning) => {
    handlePrint();
    findPlanning(idPlanning);
  };

  return {
    printRef,
    handlePrintPlanning,
    planningToPrint: {
      categories,
      formulas,
      periodTitles,
      planningPlanData,
      planningList,
      summaryReport,
    },
  };
}
