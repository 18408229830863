import React from 'react';
import AlbumFotoMortePage from './albumFotoMortePage';
import { connect } from 'react-redux';

export const AlbumFotoMorteContainer = props => {
  return (
    <AlbumFotoMortePage
      id="albumFotoMortePage"
      farolSelecionado={props.farolSelecionado}
      pastoSelecionado={props.pastoSelecionado}
      farois={props.farois}
    />
  );
};

const mapStateToProps = state => {
  return {
    pastoSelecionado: state.pasto.PastoSelecionado,
    farolSelecionado: state.farol.FarolSelecionado,
    farois: state.farol.Farois
  };
};

export default connect(mapStateToProps, null)(AlbumFotoMorteContainer);
