/**
 * Funções auxiliares para serem usadas pelo KMLActions
 */

import * as _ from "lodash";
import RetiroService from "features/retiro/retiroService";
import PastoService from "services/PastoService";
import ModuloService from "services/ModuloService";

/**
 * Funções para tratar Retiros
 */

const listaRetirosDaFazenda = async (IdFazenda) => {
  const response = await RetiroService.listaRetirosPorIdFazenda(IdFazenda, [
    "Nome",
    "IdRetiro",
  ]);
  return trataRetornoServico(response, "Retiro");
};

const montaRetirosSalvar = (retirosFazenda, Retiros, IdFazenda) => {
  retirosFazenda = [...retirosFazenda].map((retiro) => retiro.Nome);
  return Retiros.map((retiro) => {
    return {
      IdFazenda,
      Nome: String(retiro.Nome).toUpperCase(),
      NomeReduzido: retiro.Nome,
    };
  }).filter(
    (retiro) => !retirosFazenda.includes(String(retiro.Nome).toUpperCase())
  );
};

const obtemIdDoRetiroPeloNome = (retirosFazenda, nomeRetiro) => {
  try {
    const retirosFazendaPadronizado = retirosFazenda.map((retiros) => ({
      ...retiros,
      Nome: retiros.Nome.trim().toLowerCase(),
    }));
    const retiro = _.find(retirosFazendaPadronizado, {
      Nome: nomeRetiro.trim().toLowerCase(),
    });
    return retiro.IdRetiro;
  } catch (e) {
    console.error(e.message);
  }
};

const incluiRetirosSalvosEmRetirosFazenda = (retirosFazenda, retirosSalvos) => {
  return mergeDadosFazendaComDadosSalvos(retirosFazenda, retirosSalvos);
};

export const atualizaRetirosFazenda = async (IdFazenda, retirosKML) => {
  try {
    let retirosSalvos = [];
    let retirosFazenda = await listaRetirosDaFazenda(IdFazenda);
    const retirosSalvar = montaRetirosSalvar(
      retirosFazenda,
      retirosKML,
      IdFazenda
    );
    if (retirosSalvar.length) {
      retirosSalvos = await RetiroService.salvaMultiplosRetirosFazenda(
        retirosSalvar
      );
    }
    retirosFazenda = incluiRetirosSalvosEmRetirosFazenda(
      retirosFazenda,
      retirosSalvos
    );
    const quantidadeRestante = todosForamSalvos(
      retirosSalvar.length,
      retirosSalvos.length
    );
    return {
      retirosFazenda,
      naoSalvos: quantidadeRestante,
    };
  } catch (error) {
    throw new Error("Ocorreu um erro ao tentar atualizar retiros da fazenda");
  }
};

/**
 * Funções para tratar Modulos
 */
const listaModulosDaFazenda = async (IdFazenda) => {
  const response = await ModuloService.listaModulosPorIdFazenda(IdFazenda, [
    "IdModulo",
    "Nome",
    "IdFazenda",
    "Cor",
  ]);
  return trataRetornoServico(response, "Modulo");
};

const obtemIdsPastos = (modKML, pastosFazenda, retirosFazenda) => {
  const IdRetiro = obtemIdDoRetiroPeloNome(retirosFazenda, modKML.nomeRetiro);
  return modKML.Pasto.map((pastoModulo) => {
    const pasto = _.find(pastosFazenda, { Nome: pastoModulo.Nome, IdRetiro });
    return { IdPasto: pasto.IdPasto };
  });
};

const montaModulosSalvar = (parametros) => {
  const { modulosKML, pastosFazenda, IdFazenda, retirosFazenda } = parametros;
  const modulosSalvar = modulosKML.map((modKML) => {
    const { Nome } = modKML;
    return {
      IdFazenda,
      IdRetiro: obtemIdDoRetiroPeloNome(retirosFazenda, modKML.nomeRetiro),
      Nome,
      Cor: "cor1",
      Pasto: obtemIdsPastos(modKML, pastosFazenda, retirosFazenda),
    };
  });

  return modulosSalvar || [];
};

const getModulosJaExistentesParaDeleter = (modulosSalvar, modulosFazenda) => {
  const idsModulosRetornar = [];
  modulosSalvar.forEach((moduloSalvar) => {
    const moduloFazenda = _.find(modulosFazenda, { Nome: moduloSalvar.Nome });
    if (moduloFazenda) {
      idsModulosRetornar.push(moduloFazenda.IdModulo);
    }
  });
  return idsModulosRetornar;
};

const incluiModulosSalvosEmModulosFazenda = (modulosFazenda, modulosSalvos) => {
  return mergeDadosFazendaComDadosSalvos(modulosFazenda, modulosSalvos);
};

export const atualizaModulosFazenda = async (
  IdFazenda,
  retirosFazenda,
  pastosFazenda,
  modulosKML
) => {
  let modulosSalvos = [];
  let modulosFazenda = await listaModulosDaFazenda(IdFazenda);
  const parametrosMontarModulos = {
    modulosFazenda,
    modulosKML,
    pastosFazenda,
    IdFazenda,
    retirosFazenda,
  };

  const modulosSalvar = montaModulosSalvar(parametrosMontarModulos);
  const modulosDeletar = getModulosJaExistentesParaDeleter(
    modulosSalvar,
    modulosFazenda
  );
  const modulosDeletados = await ModuloService.excluirMultiplosModulos(
    modulosDeletar
  );
  const todosModulosDeletados =
    modulosDeletar.length === modulosDeletados.length;
  if (modulosSalvar.length && todosModulosDeletados) {
    modulosSalvos = await ModuloService.salvaMultiplosModulosFazenda(
      modulosSalvar
    );
  }
  modulosFazenda = incluiModulosSalvosEmModulosFazenda(
    modulosFazenda,
    modulosSalvos
  );
  const quantidadeRestante = todosForamSalvos(
    modulosSalvar.length,
    modulosSalvos.length
  );
  return {
    modulosFazenda,
    naoSalvos: quantidadeRestante,
  };
};

/**
 * Funções para tratar Pastos
 */
const listaPastosDaFazenda = async (IdFazenda) => {
  const response = await PastoService.listaPastosPorIdFazenda(
    IdFazenda,
    ["Nome", "IdRetiro", "IdPasto"],
    ["Modulo", "Cocho"]
  );
  return trataRetornoServico(response, "Pasto");
};

const montaPastosSalvar = (
  pastosDaFazenda,
  pastosKML,
  retirosFazenda,
  IdFazenda
) => {
  pastosDaFazenda = pastosDaFazenda.map((pasto) => {
    return `${pasto.Nome}-${
      pasto.IdRetiro ? pasto.IdRetiro.toString() : "sem-retiro"
    }`;
  });
  return pastosKML
    .map((pastoKML) => {
      const { Nome, Coordenadas, nomeRetiro, nomeModulo } = pastoKML;
      return {
        nomeRetiro,
        nomeModulo,
        Nome,
        NomeReduzido: Nome,
        Coordenadas,
        IdFazenda,
        IdRetiro: obtemIdDoRetiroPeloNome(retirosFazenda, nomeRetiro),
      };
    })
    .filter(
      (pasto) =>
        !pastosDaFazenda.includes(
          `${pasto.Nome}-${
            pasto.IdRetiro ? pasto.IdRetiro.toString() : "sem-retiro"
          }`
        )
    );
};

const incluiPastosSalvosEmPastosFazenda = (pastosFazenda, pastosSalvos) => {
  return mergeDadosFazendaComDadosSalvos(pastosFazenda, pastosSalvos);
};

export const atualizaPastosFazenda = async (
  IdFazenda,
  retirosFazenda,
  pastosKML
) => {
  let pastosFazenda = await listaPastosDaFazenda(IdFazenda);
  const pastosSalvar = montaPastosSalvar(
    pastosFazenda,
    pastosKML,
    retirosFazenda,
    IdFazenda
  );
  let pastosSalvos = [];
  if (pastosSalvar.length) {
    pastosSalvos = await PastoService.salvaMultiplosPastosFazenda(pastosSalvar);
  }
  pastosFazenda = incluiPastosSalvosEmPastosFazenda(
    pastosFazenda,
    pastosSalvos
  );
  const quantidadeRestante = todosForamSalvos(
    pastosSalvar.length,
    pastosSalvos.length
  );
  return {
    pastosFazenda,
    naoSalvos: quantidadeRestante,
  };
};

/**
 * Funções para uso em Retiros, Modulos e/ou Pastos
 */
export const extraiPastosModulosDeRetiros = (retirosKML) => {
  let pastosKML = [];
  const modulosKML = [];
  retirosKML.forEach((retiro) => {
    if (retiro.Modulos) {
      const { Modulos } = retiro;
      Modulos.forEach((modulo) => {
        const moduloIncluir = {
          Nome: modulo.Nome,
          nomeRetiro: retiro.Nome,
        };
        if (modulo.Pastos) {
          moduloIncluir.Pasto = modulo.Pastos.map((pasto) => {
            return { Nome: pasto.Nome };
          });
        }
        modulosKML.push(moduloIncluir);
        pastosKML = [...pastosKML, ...modulo.Pastos];
      });
    }
    pastosKML = [...pastosKML, ...retiro.Pastos];
  });

  return {
    pastosKML,
    modulosKML,
  };
};

const trataRetornoServico = (response, tipoServico = "") => {
  if (response.erro) {
    return [];
  } else {
    return response.data;
  }
};

const mergeDadosFazendaComDadosSalvos = (
  dadosFazenda = [],
  dadosSalvos = []
) => {
  if (dadosSalvos.length) {
    dadosSalvos = dadosSalvos.map((dadoSalvo) => {
      if (dadoSalvo.data) {
        return dadoSalvo.data;
      }
      return null;
    });
  }
  return [...dadosFazenda, ...dadosSalvos];
};

const todosForamSalvos = (quantidadeASalvar, quantidadeSalvos) => {
  return Number(quantidadeASalvar - quantidadeSalvos);
};
