import { combineReducers } from "redux";
import cochoReducer from "features/manejo/redux/reducers/cochoReducer";
import fazendaReducer from "features/manejo/redux/reducers/fazendaReducer";
import forragemReducer from "features/manejo/redux/reducers/forragemReducer";
import notificacaoReducer from "./notificacaoReducer";
import rebanhoReducer from "features/manejo/redux/reducers/rebanhoReducer";
import pastoReducer from "features/manejo/redux/reducers/pastoReducer";
import pastoPrimeReducer from "features/manejo/redux/reducers/pastoPrimeReducer";
import retiroReducer from "features/retiro/redux/retiroReducer";
import farolReducer from "features/manejo/redux/reducers/farolReducer";
import modalReducer from "./modalReducer";
import kmlReducer from "features/manejo/redux/reducers/kmlReducer";
import loadingReducer from "./loadingReducer";
import moduloReducer from "features/manejo/redux/reducers/moduloReducer";
import mapaReducer from "features/manejo/redux/reducers/mapaReducer";
import uiReducer from "features/manejo/redux/reducers/uiReducer";
import clienteReducer from "features/cliente/redux/clienteReducer";
import usuarioReducer from "./usuarioReducer";
import usuarioClienteReducer from "features/usuarioCliente/redux/usuarioClienteReducer";
import { fechamentoConsumoReducer } from "features/fechamentoConsumo/redux/index";
import { novaNotificacaoReducer } from "features/notificacao/redux";
import categoriaReducer from "features/categoria/redux/categoriaReducers";
import tipoMovimentacaoReducer from "features/movimentacao/redux/tipoMovimentacaoReducers";
import loteReducer from "features/lote/redux/loteReducer";
import objetivoReducer from "features/objetivo/redux/objetivoReducer";
import faixaIdadeReducer from "features/categoria/redux/faixaIdadeReducers";
import relatorioReducer from "features/analiticopdf/redux/tableauReportReducer";
import formulaReducer from "features/formulacao/redux/formulaReducer";
import movimentacaoReducer from "features/movimentacao/redux/movimentacaoReducer";
import ganhoPesoReducer from "features/projecaoGanhoPeso/redux/ganhoPesoReducers";
import avisoConsultoriaReducer from "features/avisoConsultoria/redux/avisoConsultoriaReducer";
import conciliacaoReducer from "features/conciliacao/redux/conciliacaoReducer";
import vinculoReducer from "features/manejo/redux/reducers/vinculoReducer";
import relatoriosReducer from "features/tableauEmbedded/redux/relatoriosReducer";
import causaMorteReducer from "features/causaMorte/redux/causaMorteReducer";
import ajusteEscoreReducer from "features/ajusteEscore/redux/ajusteEscoreReducer";
import historicoMovimentacaoReducer from "features/historicoMovimentacaoApp/redux/historicoMovimentacaoReducers";
import configuracaoReducer from "features/configuracao/redux/configuracaoReducer";
import balancoArrobaReducer from "features/balancoDeArroba/redux/balancoArrobaReducer";
import pesagemCategoriaReducer from "features/pesagemCategoria/redux/pesagemCategoriaReducer";
import cadastroIngredientesReducers from "features/cadastroIngredientes/redux/cadastroIngredientesReducers";
import filterReducer from "./filterReducer";

export default combineReducers({
  cocho: cochoReducer,
  fazenda: fazendaReducer,
  forragem: forragemReducer,
  notificacao: notificacaoReducer,
  pasto: pastoReducer,
  pastosPrime: pastoPrimeReducer,
  rebanho: rebanhoReducer,
  retiro: retiroReducer,
  farol: farolReducer,
  modal: modalReducer,
  kml: kmlReducer,
  loading: loadingReducer,
  modulo: moduloReducer,
  mapa: mapaReducer,
  ui: uiReducer,
  formula: formulaReducer,
  usuarioCliente: usuarioClienteReducer,
  cliente: clienteReducer,
  usuario: usuarioReducer,
  vinculo: vinculoReducer,
  fechamentoConsumo: fechamentoConsumoReducer,
  novaNotificacao: novaNotificacaoReducer,
  categoria: categoriaReducer,
  tipoMovimentacao: tipoMovimentacaoReducer,
  lote: loteReducer,
  relatorio: relatorioReducer,
  objetivo: objetivoReducer,
  faixaIdade: faixaIdadeReducer,
  movimentacoes: movimentacaoReducer,
  curvaGanhoPeso: ganhoPesoReducer,
  avisoConsultoria: avisoConsultoriaReducer,
  conciliacao: conciliacaoReducer,
  balanco: balancoArrobaReducer,
  relatoriosTableauEmbedded: relatoriosReducer,
  causaMorte: causaMorteReducer,
  ajusteEscore: ajusteEscoreReducer,
  historicoMovimentacao: historicoMovimentacaoReducer,
  pesagemCategoria: pesagemCategoriaReducer,
  configuracao: configuracaoReducer,
  cadastroIngredientes: cadastroIngredientesReducers,
  filter: filterReducer,
});
