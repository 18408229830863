import {
  SALVAR_FORRAGEM,
  ERRO_VALIDAR_FORRAGEM,
  OBTER_FORRAGEM_FAZENDA,
  OBTER_TODAS_FORRAGENS,
  NOTIFICAR,
} from "redux/actions/actionsConstants";
import trataErroGenerico from "lib/trataErroGenerico";
import ForragemService from "services/ForragemService";
import { createServiceHandler } from "services/ServiceHandler";

function infoBasicaForragem(Forragem) {
  return {
    AlturaForragem: Forragem.AlturaForragem,
  };
}

function trataErro(dispatch, error) {
  trataErroGenerico(
    dispatch,
    error,
    "Ocorreu um erro ao tentar salvar o Forragem.",
    ERRO_VALIDAR_FORRAGEM,
    "Erro ao salvar o Forragem."
  );
}

/**
 * FIXME: Retirar chamadas pra serviços deste local
 * O service deve ser o responsável por fazer requisições
 **/
export const criarForragem = (Forragem) => async (dispatch) => {
  try {
    const data = infoBasicaForragem(Forragem);
    const res = await createServiceHandler().post(
      `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/pastos/${Forragem.IdPasto}/forragem`,
      data
    );
    dispatch({
      type: SALVAR_FORRAGEM,
      payload: { ...res.data },
    });
  } catch (error) {
    trataErro(dispatch, error);
  }
};

/**
 * FIXME: Retirar chamadas pra serviços deste local
 * O service deve ser o responsável por fazer requisições
 **/
export const salvarForragem = (Forragem) => async (dispatch) => {
  try {
    const data = infoBasicaForragem(Forragem);
    const res = await createServiceHandler().put(
      `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/pastos/${Forragem.IdPasto}/forragem`,
      data
    );
    dispatch({
      type: SALVAR_FORRAGEM,
      payload: { ...res.data },
    });
  } catch (error) {
    trataErro(dispatch, error);
  }
};

export const obterTodasForragens = () => async (dispatch) => {
  try {
    const response = await ForragemService.obterForragens();
    dispatch({
      type: OBTER_TODAS_FORRAGENS,
      payload: { Forragens: response.data ? response.data : [] },
    });
  } catch (error) {
    trataErro(dispatch, error);
  }
};

export const obterForragensFazenda =
  (fazendaSelecionada) => async (dispatch) => {
    try {
      const response = await ForragemService.obterForragensFazenda(
        fazendaSelecionada
      );

      dispatch({
        type: OBTER_FORRAGEM_FAZENDA,
        payload: { CiclosForragemFazenda: response.data ? response.data : [] },
      });
    } catch (error) {
      let mensagem = "";
      let codigoErro = "";
      const regex = /\d+/g;
      const matches = String(error).match(regex);

      if (matches && matches[0]) {
        codigoErro = matches[0];
      }

      switch (codigoErro) {
        case "404":
          mensagem =
            "Desculpe, nenhuma forragem encontrada para esta fazenda. Por favor, tente novamente.";
          break;
        case "422":
          mensagem =
            "Não foi possível realizar essa ação. Por favor, tente novamente.";
          break;
        default:
          mensagem =
            "Ocorreu algo inesperado ao listar forragens para esta fazenda. Por favor, tente novamente.";
      }

      dispatch({
        type: OBTER_FORRAGEM_FAZENDA,
        payload: { CiclosForragemFazenda: [] },
      });
      dispatch({
        type: NOTIFICAR,
        payload: {
          mensagem: mensagem,
          variant: "error",
          estilo: "error",
        },
      });
    }
  };
