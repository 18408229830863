import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core/';
import DialogActions from '@material-ui/core/DialogActions';

export const ModalDataConciliacao = props => {
  return (
    <div style={{ width: '30px' }}>
      <Dialog
        open={props.modalAberto}
        onClose={props.handleClose}
        fullWidth={true}
        style={{ maxWidth: '650px', marginLeft: '30%', marginRight: '30%' }}
      >
        <DialogTitle style={{ fontWeight: 'bold' }} id="tituloModalConciliacao">
          Você deseja editar sua data de conciliação?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Ao editar sua data de conciliação, todos os dados calculados anteriormente poderão ser alterados.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary" id="btnCancelarLogout">
            Cancelar
          </Button>
          <Button onClick={props.onConfirm} color="primary" autoFocus id="btnSairLogout">
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalDataConciliacao;
