import React from "react";
import { withStyles } from "@material-ui/core";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

function PlanningStatus({
  classes,
  initialDate,
  finalDate,
  customClassName = "",
}) {
  const isActive = getStatus() === "Ativo";

  function getStatus() {
    const now = dayjs.utc();
    if (
      initialDate &&
      finalDate &&
      (dayjs.utc(finalDate) < now || dayjs.utc(initialDate) > now)
    ) {
      return "Fora de vigência";
    }
    return "Ativo";
  }

  return (
    <div
      data-testid="planning-status"
      className={`${classes.container} ${
        isActive ? classes.activeBackground : classes.inactiveBackground
      } ${customClassName}`}
    >
      <span
        className={isActive ? classes.activeStatus : classes.inactiveStatus}
      >
        {getStatus()}
      </span>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    maxWidth: "150px",
    borderRadius: "100px",
    padding: "6px 12px",
    fontFamily: "Roboto",
  },
  tooltip: {
    padding: 16,
    width: 200,
    fontSize: 14,
  },
  activeBackground: {
    backgroundColor: "#dff1df",
  },
  inactiveBackground: {
    backgroundColor: "#FEE6E4",
  },
  activeStatus: {
    color: "#4caf50",
    fontWeight: "550",
  },
  inactiveStatus: {
    textAlign: "center",
    color: "#f44336",
    fontWeight: "550",
  },
};

export default withStyles(styles)(PlanningStatus);
