import React from "react";
import "./styles.css";

export const MapTypeButton = ({ text, active = false, ...props }) => {
  return (
    <button
      style={{
        fontWeight: `${active ? "600" : "400"}`,
        color: `${active ? "#212121" : "#878686"}`,
      }}
      className="button"
      {...props}
    >
      {text}
    </button>
  );
};
