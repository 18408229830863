import React from "react";
import { Button, Tooltip, withStyles } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import AlertBanner from "features/shared/components/alertBanner";

const BannerNameExists = ({
  classes,
  sameNameFormula,
  submmitDisabled,
  handleClose,
  handleSubmmit,
}) => {
  const tooltipMessage = submmitDisabled
    ? "Por favor, preencha todos os campos obrigatórios."
    : "";
  return (
    <AlertBanner
      className={classes.container}
      type="error"
      icon={<InfoOutlined color="error" />}
      iconAlign="start"
    >
      <div className={classes.content}>
        <div>
          Já existe uma formulação com esse nome. Caso queira criar uma nova
          formulação com o mesmo nome, é preciso inativar a formulação{" "}
          <b>"{sameNameFormula}"</b>. Deseja continuar e inativar a formulação{" "}
          <b>"{sameNameFormula}"</b> e criar a nova formulação com esse nome?
        </div>
        <div
          className={classes.actionButtons}
          data-testid="banner-name-butons-container"
        >
          <Button variant="text" onClick={handleClose}>
            Cancelar
          </Button>
          <Tooltip
            title={tooltipMessage}
            placement="bottom"
            classes={{ tooltip: classes.tooltip }}
          >
            <div>
              <Button
                variant="text"
                onClick={handleSubmmit}
                disabled={submmitDisabled}
                classes={{
                  root: classes.subimmitButton,
                  disabled: classes.submmitDisabled,
                }}
              >
                Sim, desativar e copiar dados
              </Button>
            </div>
          </Tooltip>
        </div>
      </div>
    </AlertBanner>
  );
};

const styles = () => ({
  container: {
    alignSelf: "flex-start",
    marginBottom: "32px",
    maxWidth: "800px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  actionButtons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "end",
    gap: "8px",
    "& button": {
      fontSize: 12,
    },
  },
  subimmitButton: {
    fontSize: 12,
    color: "#d32f2f",
  },
  submmitDisabled: {
    color: "rgba(0, 0, 0, 0.26)",
  },
  tooltip: {
    padding: "16px",
    borderRadius: "4px",
    fontFamily: "Roboto",
    fontSize: "14px",
  },
});

export default withStyles(styles)(BannerNameExists);
