import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import CardDetalhesFarol from "features/manejo/views/components/cardDetalhesFarol";
import { TextLabel } from "features/shared/components";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const detalhesFazenda = (props) => {
  const {
    quantidadeRebanhoTodasCategorias,
    quantidadeRebanhoNoCurral,
    nomeFazenda,
    pesoMedioTodasCategorias,
    classes,
  } = props;

  const renderNomeFazenda = () => {
    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.nomeFazendaContainer}
      >
        <TextLabel
          id="labelNomeFazenda"
          label={`Fazenda ${nomeFazenda}`}
          bold
          color="default3"
          size={14}
        />
      </Grid>
    );
  };
  return (
    <CardDetalhesFarol
      primaryCard
      tituloCard="Total"
      subTituloCard={renderNomeFazenda()}
    >
      <Box sx={{ width: "100%", maxWidth: 500 }}>
        <Typography variant="body2" gutterBottom className={classes.text}>
          Total de animais nos pastos:{" "}
          <b>
            {quantidadeRebanhoTodasCategorias
              ? quantidadeRebanhoTodasCategorias
              : "0"}
          </b>
        </Typography>

        <Typography
          variant="body2"
          gutterBottom
          className={`${classes.text} total-curral`}
        >
          Total de animais nos currais:{" "}
          <b>{quantidadeRebanhoNoCurral ? quantidadeRebanhoNoCurral : "0"}</b>
        </Typography>

        <Typography
          variant="body2"
          gutterBottom
          className={`${classes.text} total-pastos-currais`}
        >
          Total de animais nos currais e pastos:{" "}
          <b>{quantidadeRebanhoTodasCategorias + quantidadeRebanhoNoCurral}</b>
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          className={`${classes.text} peso-medio`}
        >
          Peso visual médio:{" "}
          <b>{`${pesoMedioTodasCategorias.toFixed(0)} KG`}</b>
        </Typography>
      </Box>
    </CardDetalhesFarol>
  );
};

const styles = (theme) => ({
  nomeFazendaContainer: {
    margin: "15px 0",
  },
  title: {
    color: theme.palette.primary.main,
    padding: "20px 0",
  },
  text: {
    color: "#757575",
    fontWeight: "normal",
    padding: "5px 0",
  },
});

const detalhesFazendaIgual = (prevProps, nextProps) => {
  const quantidadeRebanhoIgual =
    prevProps.quantidadeRebanhoTodasCategorias ===
    nextProps.quantidadeRebanhoTodasCategorias;
  const quantidadeNoCurralIgual =
    prevProps.quantidadeRebanhoNoCurral === nextProps.quantidadeRebanhoNoCurral;
  const nomeFazendaIgual = prevProps.nomeFazenda === nextProps.nomeFazenda;
  return quantidadeRebanhoIgual && quantidadeNoCurralIgual & nomeFazendaIgual;
};

const detalhesFazendaMemo = React.memo(detalhesFazenda, detalhesFazendaIgual);

export default withStyles(styles)(detalhesFazendaMemo);
