import React from "react";
import moment from "moment";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import KeyboardArrowUpSharpIcon from "@material-ui/icons/KeyboardArrowUpSharp";
import KeyboardArrowDownSharpIcon from "@material-ui/icons/KeyboardArrowDownSharp";
import { DatePicker } from "material-ui-pickers";
import { Grid, Checkbox, withStyles } from "@material-ui/core";
import SwitchCustomizado from "features/shared/components/SwitchCustomizado";
import SelectDropdown from "components/SelectDropdown";

export const RelatorioItem = (props) => {
  const {
    classes,
    checked,
    text,
    handleChangeInicio,
    selecionaRetiro,
    filtroRetiro,
    filtroData,
    periodoCompleto,
    valor,
    indexItem,
    ativaItem,
    dataInicial,
    dataFinal,
    desativaItem,
    handleChangeFinal,
    setPeriodoCompleto,
    conteudoDetalhado,
    retiros,
    retiroSelecionado,
    minDate,
    enableDownload,
  } = props;
  const textoSwitchToggle = "Todo o Período";
  const desabilitaDatePicker = periodoCompleto ? true : false;
  const dataInicialTratada = periodoCompleto ? minDate : dataInicial;
  const dataFinalTratada = periodoCompleto ? new Date() : dataFinal;
  const listaRetiros = (retiros || []).map((retiro) => ({
    value: retiro.IdRetiro,
    label: retiro.NomeReduzido || retiro.Nome,
  }));

  listaRetiros.unshift({ value: -1, label: "Todos" });

  const seta = () => {
    if (checked) {
      return <KeyboardArrowUpSharpIcon className={classes.icon} />;
    } else {
      return <KeyboardArrowDownSharpIcon className={classes.icon} />;
    }
  };

  const onError = (date, campo) => {
    enableDownload({ [campo]: date.isValid() ? date : null }, true);
  };

  const mudaCheckBox = () => {
    if (conteudoDetalhado) {
      desativaItem();
    } else {
      ativaItem(indexItem, valor);
    }
  };
  try {
    return (
      <React.Fragment>
        <Grid
          container
          direction="row"
          className={
            indexItem % 2 === 0 ? classes.containerPar : classes.container
          }
        >
          <Grid
            onClick={mudaCheckBox}
            item
            xs={12}
            container
            direction="column"
            alignContent="space-between"
            className={classes.header}
          >
            <Grid item xs={11} classeNames={classes.gridStyle}>
              <Checkbox
                checked={checked}
                className={classes.checkbox}
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<RadioButtonCheckedIcon />}
              />
              <span>
                {text} .{valor.slice(0, 3)}
              </span>
            </Grid>
            <Grid item xs={1} className={classes.gridStyle}>
              {filtroRetiro || filtroData ? seta() : null}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {filtroData && checked && (
              <div className={classes.flexBox}>
                <div className={classes.switch}>
                  <SwitchCustomizado
                    checked={periodoCompleto}
                    defaultChecked={periodoCompleto}
                    handleClick={setPeriodoCompleto}
                  />
                </div>
                <div className={classes.textoSwitch}>{textoSwitchToggle}</div>
                <DatePicker
                  className={classes.datePickerInicio}
                  disabled={desabilitaDatePicker}
                  disableFuture
                  keyboard
                  mask={(value) =>
                    value
                      ? [
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]
                      : []
                  }
                  disableOpenOnEnter
                  id="relatorio-primeiro-DataPicker"
                  label="Início"
                  onChange={handleChangeInicio}
                  onError={(date) => onError(date, "valInicial")}
                  invalidDateMessage="O Formato da data é inválido"
                  maxDateMessage="A data inserida não pode ser maior que a data atual"
                  minDateMessage={`A data inserida ultrapassa a data minima permitida de ${moment(
                    minDate
                  ).format("DD/MM/YYYY")}`}
                  format="DD/MM/YYYY"
                  placeholder="dd/mm/aaaa"
                  value={dataInicialTratada}
                  minDate={minDate}
                />
                <DatePicker
                  className={classes.datePickerFinal}
                  disableFuture
                  disabled={desabilitaDatePicker}
                  keyboard
                  disableOpenOnEnter
                  label="Final"
                  invalidDateMessage="O Formato da data é inválido"
                  maxDateMessage="A data inserida não pode ser maior que a data atual"
                  minDateMessage={`A data inserida ultrapassa a data minima permitida de ${moment(
                    minDate
                  ).format("DD/MM/YYYY")}`}
                  format="DD/MM/YYYY"
                  placeholder="dd/mm/aaaa"
                  mask={(value) =>
                    value
                      ? [
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]
                      : []
                  }
                  value={dataFinalTratada}
                  onError={(date) => onError(date, "valFinal")}
                  onChange={handleChangeFinal}
                  minDate={minDate}
                />
              </div>
            )}
            {checked && filtroRetiro && (
              <div className={classes.retiroDropDown}>
                <SelectDropdown
                  id="selectRetiroRelatorio"
                  label={{ text: "Retiro" }}
                  options={listaRetiros}
                  value={retiroSelecionado}
                  onChange={selecionaRetiro}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  } catch (error) {
    return null;
  }
};
const styles = () => {
  return {
    container: {
      width: "100%",
      fontFamily: "Roboto",
      lineHeight: "1.5",
      letterSpacing: "0.15px",
      color: "#666666",
      borderBottom: "1px solid #e0e0e0",
      backgroundColor: "#fafafa",
    },
    containerPar: {
      width: "100%",
      fontFamily: "Roboto",
      lineHeight: "1.5",
      letterSpacing: "0.15px",
      color: "#666666",
      borderBottom: "1px solid #e0e0e0",
    },
    header: {
      padding: "1.5em 2.5em",
      height: "71px",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#EEEEEE",
      },
    },
    gridStyle: {
      height: "24px",
    },
    icon: {
      padding: "auto",
    },
    checkbox: {
      ...styles.icon,
      paddingRight: "1em",
      height: "24px",
      width: "12px",
    },
    bottomLine: {
      backgroundColor: "#e0e0e0",
      height: "1px",
    },
    datePickerInicio: {
      fontSize: "16px",
      maxWidth: "155px",
      paddingTop: "0.6em",
      marginBottom: "5px",
    },
    datePickerFinal: {
      fontSize: "16px",
      maxWidth: "155px",
      paddingTop: "0.6em",
      marginLeft: "0.8em",
      marginBottom: "5px",
    },
    switch: {
      paddingLeft: "0.7em",
      paddingRight: "0.6em",
    },
    textoSwitch: {
      width: "170px",
      paddingTop: "0.8em",
    },
    flexBox: {
      paddingLeft: "2.0em",
      paddingRight: "2.0em",
      display: "flex",
      flexDirection: "row",
    },
    retiroDropDown: {
      padding: "24px 24px 24px 200px",
    },
    textField: {
      width: "296px",
      paddingBottom: "1.5em",
    },
  };
};

export default withStyles(styles)(RelatorioItem);
