import { notifyError } from "../../../redux/actions/notificacaoActions";
import TipoMovimentacaoManager from "../tipoMovimentacaoManager";
import { listarTiposMovimentacao } from "./tipoMovimentacaoActions";

export const obterTiposMovimentacao = () => async (dispatch) => {
  try {
    const tiposMovimentacao =
      await TipoMovimentacaoManager.obterTipoMovimentacao();
    dispatch(listarTiposMovimentacao(tiposMovimentacao));
  } catch (error) {
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao obter a lista de tipos de movimentacao.",
      })
    );
  }
};
