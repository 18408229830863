import React from "react";

import { Grid, Divider, ListItem, withStyles } from "@material-ui/core";

import SelectDropdown from "components/SelectDropdown";

import { constroiListaFarois } from "../FarolUtils";

export const DropdownSelecaoTipoColeta = (props) => {
  const { tipoFarol, toggles, mudarFarol, isFazendaCreep, isClienteLite } =
    props;

  const onChange = (newValue) => {
    mudarFarol(newValue);
  };

  const listaTiposColeta = constroiListaFarois(
    toggles,
    isFazendaCreep,
    isClienteLite
  );

  return (
    <div>
      <ListItem key="DropdownColetaListItem">
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <SelectDropdown
              id="select-collect"
              options={listaTiposColeta}
              value={tipoFarol || ""}
              label={{ id: "select-collect", text: "Tipo de coleta" }}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </ListItem>
      <Divider key="DividerDropdownColetaListItem" variant="middle" />
    </div>
  );
};

const styles = (theme) => {
  return {
    formControl: {
      minWidth: "100%",
      textAlign: "left",
    },
  };
};

export default withStyles(styles)(DropdownSelecaoTipoColeta);
