export const setFazendaSelecionadaStorage = (fazenda) => {
  sessionStorage.setItem(
    "fazendaSelecionada",
    JSON.stringify(fazenda)
  );
}

export const getFazendaSelecionadaStorage = () => {
  return sessionStorage.getItem("fazendaSelecionada");
}
export const setFazendaStorage = (fazenda) => {
  sessionStorage.setItem("fazenda", JSON.stringify(fazenda));
}

export const getFazendaStorage = () => {
  return sessionStorage.getItem("fazenda");
}

export const setClienteStorage = (cliente) => {
  sessionStorage.setItem(
    "clienteSelecionado",
    JSON.stringify(cliente)
  );
}

export const getClienteStorage = () => {
  return sessionStorage.getItem("clienteSelecionado");
}

export const setCentralDeAjudaStorage = (centralDeAjuda) => {
  sessionStorage.setItem(
    "centralDeAjuda",
    JSON.stringify(centralDeAjuda)
  );
}

export const getCentralDeAjudaStorage = () => {
  return sessionStorage.getItem("centralDeAjuda");
}
