import React from "react";
import GoalPlanContent from "features/goalPlan/views/shared/goalPlanContent";
import GoalsRegisterHeader from "features/goalPlan/views/shared/tableFormComponents/goalsHeader";
import GoalsRegisterForm from "features/goalPlan/views/shared/tableFormComponents/goalsForm";
import GoalsRegisterTable from "features/goalPlan/views/shared/tableFormComponents/goalsTable";
import ActionButtons from "features/shared/components/actionButtons";
import { renderTextOrComponent } from "features/goalPlan/goalPlanUtils";

const TOOLTIP_MESSAGE =
  "Por favor, preencha todos os campos para salvar a meta";

const GoalPlanFormPage = ({
  children,
  titlePage,
  titleContent,
  subtitleContent,
  goalPlanData,
  goalsTableList,
  columns,
  onBack,
  onSubmit,
  onChangeName,
  onChangeInitialDate,
  renderTable,
  enableButtonSubmit,
  disableEditPlanInfo,
  textButtonSubmit,
  onClickInitialDate,
  interceptDatePicker,
  showDatePicker,
  setShowDatePicker,
  alertOptions,
}) => {
  const titleHeader = renderTextOrComponent(titleContent, goalPlanData);
  const subtitleHeader = renderTextOrComponent(subtitleContent, goalPlanData);

  return (
    <GoalPlanContent
      testid="Goal-Plan-Form-Content"
      title={titlePage}
      onBack={onBack}
    >
      <GoalsRegisterHeader
        title={titleHeader}
        subtitle={subtitleHeader}
        alertOptions={alertOptions}
      />
      <GoalsRegisterForm
        disabled={disableEditPlanInfo}
        goalPlanData={goalPlanData}
        onChangeInitialDate={onChangeInitialDate}
        onChangeName={onChangeName}
        onClickInitialDate={onClickInitialDate}
        interceptDatePicker={interceptDatePicker}
        showDatePicker={showDatePicker}
        setShowDatePicker={setShowDatePicker}
      />
      <GoalsRegisterTable
        renderTable={renderTable}
        goalsTableList={goalsTableList}
        columns={columns}
      />
      <ActionButtons
        onSubmit={onSubmit}
        onBack={onBack}
        enableButtonSubmit={enableButtonSubmit}
        textButtonSubmit={textButtonSubmit}
        tooltipMessage={!enableButtonSubmit && TOOLTIP_MESSAGE}
      />
      {children}
    </GoalPlanContent>
  );
};

export default GoalPlanFormPage;
