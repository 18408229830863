import React from "react";
import ModalMarcador from "../modalMarcador";
import { AdvancedMarker } from "components/ViewsGoogleMaps/AdvancedMarker";



export const TooltipMarcador = ({ marcador, onMouseLeave, onClick }) => {
  const {
    pasto,
    position,
    color,
    url
  } = marcador;

  const showTooltip = pasto && pasto.IdPasto && pasto.Nome;

  if (showTooltip) {
    return (
      <AdvancedMarker
        position={position}
        onClick={onClick}
        id={"tooltip-marcador-" + pasto.IdPasto}
      >
        <ModalMarcador onMouseLeave={onMouseLeave} pasto={pasto} color={color} url={url} />
      </AdvancedMarker>
    );
  }
  return null;

};
