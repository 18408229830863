import React from "react";
import { PlanningTableCategoryItem } from "./planningTablecategoryItem";
import PlanningListItem from "./planningTablePeriodItem";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";

const checkHidden = (planningLine) => {
  if (planningLine.id === "selecionaCategoria") return true;
  return false;
};

export function planningTableColumns({
  freeCategories,
  categories,
  formulas,
  periodTitles,
  onChangeCategory,
  addNewLine,
  onDeleteLine,
  onChangeFormula,
  onChangeHerdCount,
}) {
  const periodColumns = periodTitles.map((month, monthIndex) => ({
    id: month,
    titulo: month,
    ordenavel: false,
    hidden: (planningLine) => checkHidden(planningLine),
    pegaValor: (planning) => (
      <PlanningListItem
        formulas={formulas}
        onChangeFormula={(formulaId) =>
          onChangeFormula(formulaId, planning, monthIndex)
        }
        onChangeHerdCount={(value) =>
          onChangeHerdCount(value, planning, monthIndex)
        }
        formulaId={planning.items[monthIndex].formulaId || ""}
        hasIngredients={planning.items[monthIndex].hasIngredients}
        herdCount={
          planning.items[monthIndex].herdCount !== null
            ? planning.items[monthIndex].herdCount
            : ""
        }
      />
    ),
  }));
  return [
    {
      id: "categoria",
      titulo: "Categoria",
      ordenavel: false,
      pegaValor: (planning) => (
        <PlanningTableCategoryItem
          categoryId={planning.categoryId}
          categories={categories}
          freeCategories={freeCategories}
          onChangeCategory={onChangeCategory}
          addNewLine={addNewLine}
        />
      ),
    },
    ...periodColumns,
    {
      id: "delete-categories",
      titulo: "Deletar",
      ordenavel: false,
      hidden: (planningLine) => checkHidden(planningLine),
      pegaValor: (planning) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            title="delete"
            onClick={() => onDeleteLine(planning.categoryId)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];
}
