import React from "react";
import { Typography, withStyles } from "@material-ui/core";

function AnalyticsRequiredWarning({ classes }) {
  return (
    <Typography class={classes.warningAnalytics}>
      <img
        src="../images/ic_warning_outlined.svg"
        alt="icone de alerta"
        class={classes.warningAnalyticsIcon}
      />
      As <b>análises </b>
      geradas a partir da criação de um plano e metas, como acompanhar a meta e
      o realizado para o indicador, estarão disponíveis para consulta no
      <b> módulo analítico</b>. Para utilizar as análises e realizar esse
      acompanhamento, é essencial possuir esse módulo.
      <b>
        {" "}
        Caso ainda não tenha adquirido, por favor entre em contato com nosso
        suporte e solicite.
      </b>
    </Typography>
  );
}

const styles = () => {
  return {
    warningAnalyticsIcon: {
      marginRight: "4px",
      marginBottom: "-3px",
      height: "20px",
      color: "red",
    },
    warningAnalytics: {
      margin: "8px",
      fontFamily: "Roboto",
      lineHeight: 1.71,
      color: "#666",
    },
  };
};
export default withStyles(styles)(AnalyticsRequiredWarning);
