import * as movimentacaoActions from './movimentacaoActions';

export const INITIAL_STATE = {
  movimentacoes: [],
  movimentacao: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case movimentacaoActions.LISTA_MOVIMENTACOES:
      return { ...state, movimentacoes: action.payload.MovimentacoesFazenda };
    case movimentacaoActions.SALVAR_MOVIMENTACAO:
      return { ...state, movimentacao: action.payload };
    default:
      return state;
  }
};
