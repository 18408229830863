import React, { useEffect } from "react";
import { Modal, Button, withStyles } from "@material-ui/core";
import { withRouter } from "react-router";
import { compose } from "recompose";

export const ModalPesagemCategoria = (props) => {
  const { classes, desativaModal, history } = props;

  useEffect(() => {
    return () => desativaModal();
  }, []);

  return (
    <Modal
      className={classes.modal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={true}
    >
      <div className={classes.modalContainer}>
        <div id="containerModalLote" className={classes.modalConteudo}>
          <div item className={classes.titulo}>
            Cadastro de pesagem por categoria
          </div>
          <div data-testid="textoExclusao" item className={classes.data}>
            Você não possui dados suficientes para realizar um cadastro de
            pesagem por categoria.{" "}
            <span style={{ fontWeight: "bold" }}>
              Entre no menu Controle de Rebanho, acesse a tela de Categoria
              Animal e insira a data inicial da sua conciliação,
            </span>{" "}
            ou clique no botão{" "}
            <span style={{ fontWeight: "bold" }}>CATEGORIA ANIMAL.</span>
          </div>
          <div style={{ alignSelf: "center", marginRight: "5px" }}>
            <Button
              className={classes.botoes}
              onClick={() => history.goBack()}
              style={{
                color: "#333333",
                marginRight: "26px",
              }}
              data-testid="cancelarExclusao"
            >
              VOLTAR
            </Button>
            <Button
              className={classes.botoesComBackground}
              onClick={() => {
                history.push("/controleDeRebanho");
              }}
              data-testid="confirmarExclusao"
            >
              CATEGORIA ANIMAL
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const styles = (theme) => {
  return {
    modal: {
      zIndex: "0",
    },
    modalContainer: {
      display: "flex",
      height: "100%",
    },
    modalConteudo: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      width: "566px",
      height: "251px",
      backgroundColor: "#ffffff",
      outline: "none",
      boxShadow: "0 2px 20px 0 rgba(0, 0, 0, 0.5)",
      borderRadius: "10px",
      padding: "10px",
    },
    data: {
      margin: "10px 20px",
      fontFamily: "Roboto",
      letterSpacing: "0.2px",
      fontSize: "16px",
      color: "rgba(51, 50, 56, 0.84)",
      lineHeight: 1.5,
      maxHeight: "115px",
    },
    titulo: {
      margin: "15px",
      alignSelf: "flex-start",
      fontFamily: "Roboto",
      fontSize: "20px",
      color: "#333",
      fontWeight: 500,
    },
    botoes: {
      fontSize: "14px",
      margin: "20px 5px 30px 0px",
      borderRadius: "18px",
      fontWeight: "500",
    },
    botoesComBackground: {
      margin: "20px 5px 30px 0px",
      borderRadius: "18px",
      fontWeight: "500",
      fontSize: "13px",
      minWidth: "165px",
      boxShadow: "none",
      color: "#ffffff",
      backgroundColor: theme.palette.primary.main,
    },
  };
};

export default compose(withRouter, withStyles(styles))(ModalPesagemCategoria);
