export const ALTERA_ESTADO_DROPDOWN = "UI/ALTERA_ESTADO_DROPDOWN";
export const ALTERA_ESTADO_TODOS_DROPDOWNS = "UI/ALTERA_ESTADO_TODOS_DROPDOWNS";

export const DROPDOWN_CLIENTE = "Cliente";
export const DROPDOWN_FAZENDA = "Fazenda";
export const DROPDOWN_RETIRO = "Retiro";
export const DROPDOWN_MAPA = "Mapa";

export const alteraEstadoDropdown = (dropdown, ativo) => ({
  type: ALTERA_ESTADO_DROPDOWN,
  payload: { dropdown, ativo },
});

export const alteraEstadoTodosDropdowns = (ativo) => ({
  type: ALTERA_ESTADO_TODOS_DROPDOWNS,
  payload: { ativo },
});
