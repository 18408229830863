import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle
} from '@material-ui/core';

export const DialogExcluir = props => {
  const {
    texto,
    modalConfirmacaoExclusao,
    handleFechaModalExclusao,
    handleDelete
  } = props;
  return (
    <Dialog
      id="dialogConfirmacaoExclusao"
      open={modalConfirmacaoExclusao}
      aria-labelledby="alert-dialog-title"
      onClose={handleFechaModalExclusao}
    >
      <DialogTitle id="alert-dialog-title">{texto}</DialogTitle>
      <DialogActions>
        <Button id="botaoCancelarDialog" onClick={handleFechaModalExclusao} color="primary" autoFocus>
          CANCELAR
          </Button>
        <Button id="botaoExcluirDialog" onClick={handleDelete} color="primary">
          EXCLUIR
          </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogExcluir;



