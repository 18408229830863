import React from "react";
import { Route } from 'react-router-dom';
import ConfiguracaoTabsContainer from "./views/configuracaoTabsContainer";
import ConfiguracoesContainer from "./views/ConfiguracaoContainer"
import CadastroFormulacaoContainer from "../formulacao/views/formulationRegister/cadastroFormulacaoContainer";

const ConfiguracoesRouter = (props) => {
  const { match } = props
  return (
    <>
      <Route
        exact
        path={`${match.path}/`}
        component={ConfiguracoesContainer}
      />
      <Route
        path={`${match.url}/cadastros`}
        render={({ match: { url } }) => (
          <>
            <Route path={`${url}/`} component={ConfiguracaoTabsContainer} exact />
            <Route path={`${url}/edicao-formulacao`} component={CadastroFormulacaoContainer} />
          </>
        )}
      />
    </>
  )
}

export default ConfiguracoesRouter
