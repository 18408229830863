import React from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";

export const formatDateTime = (date) =>
  dayjs(date).isValid() ? dayjs(date).format("DD/MM/YY, [às] HH:mm") : "";

export function TotalHerd({
  operationDate,
  total,
  category,
  pasture,
  customStyles,
  ...props
}) {
  const formattedDate = formatDateTime(operationDate);
  const animalText = total === 1 ? "animal" : "animais";

  return (
    <div style={{ ...styles.wrapper, ...customStyles }} {...props}>
      <p style={styles.title}>Total de animais no dia da coleta</p>
      <div style={styles.info}>
        {formattedDate && category && pasture && total !== null
          ? `No dia ${formattedDate}, havia ${total} ${animalText} da Categoria ${category.Nome} no pasto ${pasture.Nome}.`
          : "Carregando..."}
      </div>
    </div>
  );
}

TotalHerd.propTypes = {
  operationDate: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  categoryName: PropTypes.string.isRequired,
  pastureName: PropTypes.string.isRequired,
  customStyles: PropTypes.object,
};

const styles = {
  wrapper: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    fontFamily: "Roboto, sans-serif",
  },
  title: {
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.54)",
    margin: 0,
  },
  info: {
    padding: "6px 0 0",
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.54)",
  },
};
