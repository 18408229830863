import BaseService from '../../services/BaseService';
import downloadArquivo from 'lib/downloadArquivo';

const URL_BASE = process.env.REACT_APP_ENDPOINT;
export const URI_CHUVA = 'chuva/api/v1/chuva/csv';

class HistoricoChuvaService extends BaseService {
  async getHistoricoChuva(IdFazenda, IdRetiro) {
    const URI = `${URL_BASE}${URI_CHUVA}?IdFazenda=${IdFazenda}&IdRetiro=${IdRetiro}`;
    const resposta = await this.get(URI);
    downloadArquivo('HistoricoChuva.csv', resposta.data);
    return resposta;
  }

  async setHistoricoChuva(IdFazenda, IdRetiro, csv) {
    var arquivo = new FormData();
    arquivo.append('CSV', csv, 'arquivo.csv');
    const URI = `${URL_BASE}${URI_CHUVA}?IdFazenda=${IdFazenda}&IdRetiro=${IdRetiro}`;
    const resposta = await this.post(URI, arquivo);
    return resposta;
  }
}
export default new HistoricoChuvaService();
