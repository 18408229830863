import React from "react";
import { withStyles } from "@material-ui/core";
import TabelaProdap from "features/shared/components/TabelaProdap";

const GoalsTable = ({ classes, renderTable, goalsTableList, columns }) => {

  return renderTable ? (
    <div className={classes.wrapperTable} data-testid="Goals-Register-Table">
      <TabelaProdap
        idCabecalhoTabela={"goalsTableHead"}
        colunas={columns}
        ativarPaginacao={false}
        linhas={goalsTableList}
      />
    </div>
  ) : null;
};

const styles = () => ({
  wrapperTable: {
    marginTop: "40px",
    "& tr:hover": {
      backgroundColor: "white !important",
    },
    "& thead th": {
      position: "sticky",
      zIndex: 888,
      top: 0,
      padding: "4px 2px 4px 25px",
      backgroundColor: "#e1e1e1",
    },
    "& td": {
      padding: "4px 2px 4px 25px",
      backgroundColor: "white",
      zIndex: 1,
    },
    "& td:nth-child(1)": {
      position: "sticky",
      minWidth: "315px !important",
      left: 0,
      zIndex: 10,
    },
    "& thead tr th:nth-child(1)": {
      position: "sticky",
      minWidth: "315px !important",
      left: 0,
      top: 0,
      zIndex: 999
    },
    "& td:nth-child(2)": {
      position: "sticky",
      width: 235,
      minWidth: "206px !important",
      left: 315,
      zIndex: 10,
    },
    "& thead tr th:nth-child(2)": {
      position: "sticky",
      width: 235,
      minWidth: "206px !important",
      left: 315,
      top: 0,
      zIndex: 999,
    },
    "& td:nth-child(3)": {
      position: "sticky",
      width: 300,
      minWidth: "222px !important",
      left: 521,
      zIndex: 10,
    },
    "& thead tr th:nth-child(3)": {
      position: "sticky",
      width: 300,
      minWidth: "222px !important",
      left: 521,
      top: 0,
      zIndex: 999
    },
    "& td:nth-child(4)": {
      left: 743,
      width: 136,
      minWidth: "197px !important",
      position: "sticky",
      zIndex: 10,
      boxShadow: "inset -4px 0 4px -2px #e1e1e1",
    },
    "& thead tr th:nth-child(4)": {
      left: 743,
      width: 136,
      minWidth: "197px !important",
      position: "sticky",
      top: 0,
      boxShadow: "inset -4px 0 4px -2px rgba(0, 0, 0, 0.2)",
      zIndex: 999
    },
  },
});

export default withStyles(styles)(GoalsTable);
