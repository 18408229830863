import React from "react";
import { Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { formatDateHour } from "shared/utils";
import dayjs from "dayjs";
import { utc } from "dayjs";
dayjs.extend(utc);
const CONTENT_TOOLTIP =
  "Atenção: a última sincronização ocorreu há mais de 12 horas. É recomendado sincronizar os dados pelo menos duas vezes por dia.";

const calcDiffSincronization = (createdAt, operationDate) => {
  return dayjs(createdAt).diff(operationDate, "hours");
};

const SynchronizationTimeCell = ({ row, classes }) => {
  const isMoreThan12H =
    calcDiffSincronization(row.createdAt, row.operationDate) >= 12;
  return (
    <div>
      {isMoreThan12H && (
        <Tooltip
          title={CONTENT_TOOLTIP}
          className={classes.tooltip}
          classes={{
            tooltip: classes.tooltipContent,
          }}
        >
          <InfoIcon />
        </Tooltip>
      )}

      {formatDateHour(dayjs.utc(row.createdAt))}
    </div>
  );
};

export default SynchronizationTimeCell;
