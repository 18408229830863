import React, { Component } from 'react';
import { Grid, withStyles } from '@material-ui/core';
export class ItemModalFoto extends Component {

  constructor(props) {

    super(props);
    this.state = {
      urlExiste: true
    };
  };

  substituiFotoAnimal() {
    this.setState({ urlExiste: false })
  }

  render() {
    const { foto } = this.props;
    const { urlExiste } = this.state;

    return (
      <Grid container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ height: window.innerHeight * 0.9, alignSelf: 'center' }}>
        {urlExiste ?
          <img
            id="img_coletaMorte"
            src={foto}
            onError={() => this.substituiFotoAnimal(foto)}
            style={styles.imgAmpliadaExiste}
            alt={'img_coletaMorte'}
          />
          :
          <img
            id="img_semImagem"
            src={'../images/sem-imagem.svg'}
            style={styles.imgAmpliadaNaoExiste}
            alt={'img_semImagem'}
          />
        }
      </Grid>
    );
  }
};

const styles = {
  imgAmpliadaExiste: {
    width: '100%',
    heigth: '100%',
    borderRadius: '5px'
  },
  imgAmpliadaNaoExiste: {
    height: '24px',
    width: '24px',
    alignSelf: 'center',
    borderRadius: '5px'
  }
};
export default withStyles(styles)(ItemModalFoto);
