import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import PropTypes from "prop-types";
import FormPasture from "./FormPasture";
import FormTrough from "./FormTrough";
import { Grid, Button, withStyles, Tooltip } from "@material-ui/core";
import * as pastoActions from "features/manejo/redux/actions/pastoActions";
import * as pastoPrimeActions from "features/manejo/redux/actions/pastoPrimeActions";
import * as moduloActions from "features/manejo/redux/actions/moduloActions";
import * as forragemActions from "features/manejo/redux/actions/forragemActions";
import * as loteOperations from "../../../../../lote/redux/loteOperations";
import DialogExclusaoPasto from "./DialogExclusaoPasto";
import DialogExclusaoModuloDoPasto from "./DialogExclusaoModuloDoPasto";
import DialogAtualizacaoRetiroPasto from "./DialogAtualizacaoRetiroPasto";
import history from "features/app/History";
import { enviaDadosGAPorRotulo } from "shared/utils";
import { deveExibirCampoIdPrime } from "features/shared/utils";
import { ManejoTabs } from "features/manejo/views/components/Tabs/ManejoTabs";
import { ManejoTab } from "features/manejo/views/components/Tabs/ManejoTab";
import MensagemMenuLateral from "features/manejo/views/components/MensagemMenuLateral";

export class PastureRegistrations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aba: 0,
      isEdit: this.props.isEdit,
      Desenhando: this.props.Desenhando,
      ModalConfirmacaoExclusao: false,
      IdRetiroAlterado: false,
    };
  }

  async loadPastureForm() {
    const { PastoSelecionado, setTrough, setCreepTrough } = this.props;

    if (PastoSelecionado) {
      this.props.setPasture({
        ...this.props.PastoSelecionado,
        IdFazenda: this.props.IdFazenda,
        IdPastoPrime: this.props.PastoSelecionado.IdPastoPrime
          ? this.props.PastoSelecionado.IdPastoPrime
          : 0,
      });
    }

    if (PastoSelecionado.IdPasto) {
      const cochoCreep = PastoSelecionado.PastoCochos
        ? PastoSelecionado.PastoCochos.find(
          (e) => e.CochoCreep && !e.DataExclusao && !e.PastoCocho.DataValidade
        )
        : null;

      const cocho = PastoSelecionado.PastoCochos
        ? PastoSelecionado.PastoCochos.find(
          (e) =>
            !e.CochoCreep && !e.DataExclusao && !e.PastoCocho.DataValidade
        )
        : null;

      setTrough(cocho);
      setCreepTrough(cochoCreep);
      this.setState({
        aba: 0,
      });

      await this.props.listarLotes(this.props.PastoSelecionado.IdPasto);

      if (this.props.listarPastosPrime) {
        await this.props.listarPastosPrime(
          this.props.PastoSelecionado.IdFazenda
        );
      }
    }
  }

  componentDidMount = async () => {
    await this.props.obterForragem();
    await this.loadPastureForm();
  };

  componentDidUpdate(nextProps) {
    if (this.props.pasture.IdPasto && !this.props.PastoSelecionado.IdPasto) {
      history.replace("/cadastro");
    }

    if (
      nextProps.PastoSelecionado.IdPasto !==
      this.props.PastoSelecionado.IdPasto &&
      this.props.pasture.IdPasto !== this.props.PastoSelecionado.IdPasto
    ) {
      this.loadPastureForm();
    }
  }

  componentWillUnmount = () => {
    if (this.props.cadastroPastoModificado) {
      this.props.modificarCadastroPasto(false);
    }
    if (this.props.errosPasto.ExcluirModuloAlterandoRetiro) {
      this.props.limparErroExcluirModuloPelaAlteracaoRetiro();
    }
    if (this.props.errosPasto.ExcluirModuloAoExcluirPasto) {
      this.props.limparErroExcluirModuloPelaExclusaoPasto();
    }
    this.setState({ IdRetiroAlterado: false });
  };

  handleChangeAba = (event, aba) => {
    this.setState({ aba });
  };

  handleChangeFormBasico = (propertyName, event) => {
    const { cadastroPastoModificado, pasture } = this.props;
    this.props.setPasture({
      ...this.props.pasture,
      [propertyName]: event.target.value,
    });
    if (!cadastroPastoModificado) {
      this.props.modificarCadastroPasto(true);
    }
    if (pasture.IdModulo && propertyName === "IdRetiro") {
      this.setState({ IdRetiroAlterado: true });
    }
  };

  handleChangeFormCocho = (propertyName, event) => {
    const { cadastroPastoModificado, trough, setTrough } = this.props;
    setTrough({ ...trough, [propertyName]: event.target.value });
    if (!cadastroPastoModificado) {
      this.props.modificarCadastroPasto(true);
    }
  };

  handleChangeFormCochoCreep = (propertyName, event) => {
    const { cadastroPastoModificado, creepTrough, setCreepTrough } = this.props;
    setCreepTrough({
      ...creepTrough,
      [propertyName]: event.target.value,
      CochoCreep: true,
      IdCocho: null,
      IdPasto: this.props.pasture.IdPasto,
    });
    if (!cadastroPastoModificado) {
      this.props.modificarCadastroPasto(true);
    }
  };

  handleAbrirModalExclusao = () => {
    this.setState({ ModalConfirmacaoExclusao: true });
  };

  recarregarCadastro = () => {
    history.replace("/cadastro");
  };

  handleBack = (coord) => {
    if (this.props.onBack) {
      this.props.onBack();
    } else {
      if (coord && coord.length > 0) {
        this.props.deletarPoligono(coord[0].Evento, coord[0].Id);
      }
      history.replace("/cadastro");
    }
  };

  handleFechaModalExclusao = () => {
    this.setState({ ModalConfirmacaoExclusao: false });
  };

  handleExcluiPasto = () => {
    const { IdPasto, IdFazenda } = this.props.pasture;
    this.props.excluiPasto(IdPasto, IdFazenda);
    this.setState({ ModalConfirmacaoExclusao: false });
    const acao = "Excluir";
    const rotulo = "Pasto";
    const categoria = "Cadastro";
    enviaDadosGAPorRotulo(acao, rotulo, categoria);
  };

  handleExcluiPastoComModulo = async () => {
    const { IdPasto, IdModulo, IdFazenda } = this.props.pasture;
    await this.props.excluirModulo(IdModulo, IdFazenda);
    await this.props.excluiPasto(IdPasto, IdFazenda);
    this.setState({ ModalConfirmacaoExclusao: false });
    const acao = "Excluir";
    const rotulo = "Pasto";
    const categoria = "Cadastro";
    enviaDadosGAPorRotulo(acao, rotulo, categoria);
  };

  handleConfirmarExclusaoModuloAlterandoRetiro = async () => {
    const { pasture, trough } = this.props;
    await this.props.excluirModulo(pasture.IdModulo, pasture.IdFazenda);
    await this.props.salvarCadastroPasto(
      { ...pasture, IdModulo: null },
      { ...trough, IdPasto: pasture.IdPasto }
    );
  };

  handleFechaConfirmarExclusaoModulo = () => {
    this.props.limparErroExcluirModuloPelaAlteracaoRetiro();
  };

  handleFechaModalExclusaoComModulo = () => {
    this.props.limparErroExcluirModuloPelaExclusaoPasto();
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { pasture, trough, creepTrough } = this.props;

    if (pasture.IdPasto) {
      if (creepTrough) {
        await this.props.salvarCadastroPasto(pasture, creepTrough);
      }
      await this.props.salvarCadastroPasto(pasture, trough);
    } else {
      const sucesso = await this.props.novoPasto(pasture, trough, creepTrough);
      if (!sucesso) {
        return;
      }
    }

    this.props.setPasture({
      Nome: "",
      NomeReduzido: "",
      AreaAberta: "",
      AreaEfetiva: "",
      PotencialDeProducao: "",
      IdPastoPrime: null,
    });
    const acao = "Cadastrar pasto";
    const rotulo = "Salvar pasto";
    const categoria = "Mapa";
    enviaDadosGAPorRotulo(acao, rotulo, categoria);
    this.props.limparCoordenadasEditando([]);
    this.recarregarCadastro();
  };

  montarDropdownPastosPrime = (pastosPrime) => {
    let dropdownPastosPrime = [
      {
        value: 0,
        key: "Não Vinculado",
      },
    ];
    if (pastosPrime) {
      pastosPrime.forEach((item) => {
        let pastoPrime = {
          value: item.IdPastoPrime,
          key: item.Nome,
        };
        dropdownPastosPrime.push(pastoPrime);
      });
    }
    return dropdownPastosPrime;
  };

  buscarPastosPrime = async (IdFazenda) => {
    if (this.props.listarPastosPrime) {
      await this.props.listarPastosPrime(IdFazenda);
    }
  };

  tabPasture = () => {
    const {
      IdFazenda,
      IdRetiro,
      errosPasto,
      Retiros,
      Forragens,
      exibirCampoIdPrime,
      PastosPrime,
      pasture,
      habilitarDesenhar,
      AreaNovoPasto,
    } = this.props;

    const pastosPrimeDropDown = this.montarDropdownPastosPrime(PastosPrime);

    return (
      <Grid container direction="row" spacing={12}>
        <Grid item xs={12}>
          <FormPasture
            id="formBasico"
            Pasto={pasture}
            IdRetiro={IdRetiro}
            PastosPrime={pastosPrimeDropDown}
            IdFazenda={IdFazenda}
            errosValidacao={errosPasto}
            Retiros={Retiros}
            Forragens={Forragens}
            exibirCampoIdPrime={exibirCampoIdPrime}
            handleChangeFormBasico={this.handleChangeFormBasico}
            habilitarDesenhar={habilitarDesenhar}
            AreaNovoPasto={AreaNovoPasto}
          />
        </Grid>
      </Grid>
    );
  };

  tabTrough = () => {
    const { errosCocho, Toggles, trough, creepTrough } = this.props;
    return (
      <div container>
        <FormTrough
          id="formCocho"
          Cocho={trough}
          CochoCreep={creepTrough}
          Toggles={Toggles}
          errosValidacao={errosCocho}
          handleChangeFormCocho={this.handleChangeFormCocho}
          handleChangeFormCochoCreep={this.handleChangeFormCochoCreep}
        />
      </div>
    );
  };

  validatePastureCanSave = (pasture, openedArea) => {
    const pastureOpenedArea = openedArea
      ? openedArea
      : pasture.IdPasto && pasture.AreaAberta
        ? pasture.AreaAberta
        : "";

    return (
      pasture.Nome &&
      pasture.Nome.trim() &&
      pasture.IdForragem &&
      pasture.IdRetiro &&
      pastureOpenedArea &&
      pastureOpenedArea !== ""
    );
  };

  controlButtons = () => {
    const { classes, CoordenadasNovosPastos, pasture, AreaNovoPasto } =
      this.props;

    const saveButtonDisabled = !this.validatePastureCanSave(
      pasture,
      AreaNovoPasto
    );

    return (
      <div
        container
        direction="row"
        spacing={12}
        justify="flex-end"
        alignItems="flex-start"
        className={classes.buttonsContainer}
      >
        {!pasture.IdPasto ? (
          <Button
            id="botaoExcluir"
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => this.handleBack(CoordenadasNovosPastos)}
          >
            VOLTAR
          </Button>
        ) : (
          <Button
            id="botaoExcluir"
            variant="outlined"
            className={[classes.botaoExcluir, classes.button].join(" ")}
            onClick={this.handleAbrirModalExclusao}
          >
            DELETAR PASTO
          </Button>
        )}

        <Tooltip
          disableHoverListener={!saveButtonDisabled}
          classes={{ tooltip: classes.tooltip }}
          placement="right"
          title="Por favor, preencha todos os campos obrigatórios para salvar."
        >
          <span
            className={[classes.saveButtonWrapper, classes.buttonWrapper].join(
              " "
            )}
          >
            <Button
              id="botaoSalvar"
              variant="contained"
              color="primary"
              classes={{ disabled: classes.saveButtonDisabled }}
              className={classes.button}
              disabled={saveButtonDisabled}
              onClick={this.handleSubmit}
            >
              SALVAR PASTO
            </Button>
          </span>
        </Tooltip>
      </div>
    );
  };

  render() {
    const { classes, errosPasto, Lotes, PastoSelecionado, isEdit } = this.props;
    const { aba, ModalConfirmacaoExclusao } = this.state;

    const canShowForm = (isEdit && PastoSelecionado.IdPasto) || !isEdit;

    return (
      <form
        id="menuCadastro"
        noValidate
        autoComplete="off"
        className={classes.form}
      >
        <div className={classes.root}>
          <ManejoTabs
            id="tabController"
            value={this.state.aba}
            onChange={this.handleChangeAba}
            indicatorColor="primary"
            fullWidth
          >
            <ManejoTab
              disabled={!canShowForm}
              id="tabBasicoCadastroPasto"
              label="Pasto"
            />
            <ManejoTab
              disabled={!canShowForm}
              id="tabAvancadoCadastroPasto"
              label="Cocho"
            />
          </ManejoTabs>

          <div className={classes.formContent}>
            <div
              container
              direction="row"
              spacing={12}
              className={classes.tabsContent}
            >
              {!canShowForm && (
                <MensagemMenuLateral
                  id="selectPastureToEditMessage"
                  mensagem="No mapa ao lado, clique sobre o pasto que deseja editar."
                  childrenContainerClass={classes.imageMessage}
                  mensagemClass={classes.message}
                >
                  <img
                    src="../images/map_indicator.svg"
                    alt="icone de um mapa"
                  />
                </MensagemMenuLateral>
              )}
              {canShowForm && aba === 0 && this.tabPasture()}
              {canShowForm && aba === 1 && this.tabTrough()}
            </div>

            {canShowForm && this.controlButtons()}
          </div>
        </div>

        <DialogExclusaoPasto
          id="dialogExclusaoPasto"
          ContemLote={Lotes.length > 0}
          ModalConfirmacaoExclusao={ModalConfirmacaoExclusao}
          handleFechaModalExclusao={this.handleFechaModalExclusao}
          handleExcluiPasto={this.handleExcluiPasto}
        />
        <DialogAtualizacaoRetiroPasto
          ExcluirModuloAlterandoRetiro={errosPasto.ExcluirModuloAlterandoRetiro}
          handleFechaConfirmarExclusaoModulo={
            this.handleFechaConfirmarExclusaoModulo
          }
          handleConfirmarExclusaoModuloAlterandoRetiro={
            this.handleConfirmarExclusaoModuloAlterandoRetiro
          }
        />
        <DialogExclusaoModuloDoPasto
          ExcluirModuloAoExcluirPasto={errosPasto.ExcluirModuloAoExcluirPasto}
          handleFechaModalExclusaoComModulo={
            this.handleFechaModalExclusaoComModulo
          }
          handleExcluiPastoComModulo={this.handleExcluiPastoComModulo}
        />
      </form>
    );
  }
}

PastureRegistrations.propTypes = {
  classes: PropTypes.object.isRequired,
  PastoSelecionado: PropTypes.object.isRequired,
  IdRetiro: PropTypes.number.isRequired,
  IdFazenda: PropTypes.number.isRequired,
  Retiros: PropTypes.array,
};

const mapDispatchToProps = {
  excluiPasto: pastoActions.excluiPasto,
  salvarCadastroPasto: pastoActions.salvarCadastroPasto,
  novoPasto: pastoActions.novoPasto,
  excluirModulo: moduloActions.excluirModulo,
  modificarCadastroPasto: pastoActions.modificarCadastroPasto,
  limparErroExcluirModuloPelaAlteracaoRetiro:
    pastoActions.limparErroExcluirModuloPelaAlteracaoRetiro,
  limparErroExcluirModuloPelaExclusaoPasto:
    pastoActions.limparErroExcluirModuloPelaExclusaoPasto,
  habilitarDesenhar: pastoActions.habilitarDesenhar,
  obterForragem: forragemActions.obterTodasForragens,
  listarLotes: loteOperations.listaLotesPasto,
  deletarPoligono: pastoActions.deletarPoligono,
  limparCoordenadasEditando: pastoActions.editandoCoordenadasPasto,
  listarPastosPrime: pastoPrimeActions.listarPastosPrime,
};

const mapStateToProps = (state) => {
  return {
    CoordenadasNovosPastos: state.pasto.CoordenadasNovoPasto,
    exibirCampoIdPrime: deveExibirCampoIdPrime(state),
    AreaNovoPasto: state.pasto.AreaNovoPasto,
  };
};

const styles = () => ({
  form: {
    height: "100%",
    width: "100%",
  },
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  tabsContent: {
    flexGrow: 1,
    marginTop: 24,
  },
  buttonsContainer: {
    alignSelf: "end",
    marginTop: 40,
  },
  botaoExcluir: {
    color: "#ff0202",
    padding: "7px 22px",
    height: 36,
    border: "solid 1px #ff0202",
  },
  button: {
    minWidth: 150,
  },
  saveButtonWrapper: {
    marginStart: "24px",
  },
  saveButtonDisabled: {
    backgroundColor: "rgba(0, 83, 160, 0.5) !important",
    color: "#FFF !important",
  },
  imageMessage: {
    padding: 0,
    margin: 40,
  },
  message: {
    maxWidth: 263,
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "justify",
    color: "#212121",
  },
  formContent: {
    display: "flex",
    paddingBottom: "32px",
    flex: 1,
    flexDirection: "column",
  },
  tooltip: {
    width: "257px",
    padding: "16px",
    borderRadius: "4px",
    backgroundColor: "#616161",
    fontFamily: "Roboto",
    fontSize: "14px",
  },
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(PastureRegistrations);
