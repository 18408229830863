import * as ganhoPesoActions from './ganhoPesoActions';

export const INITIAL_STATE = {
  curvas: [],
  curva: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ganhoPesoActions.LISTA_CURVAS:
      return { ...state, curvas: action.payload.CurvasGanhoDePeso };
    case ganhoPesoActions.SALVAR_CURVA:
      return { ...state, curva: action.payload };
    default:
      return state;
  }
};
