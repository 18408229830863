import React from "react";
import { Modal, Grid, Typography, withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TabelaDescritivoLote from "./conciliacaoModalTabela";
import FeedbackDescritivoLote from "./conciliacaoModalFeedback";
import moment from "moment";

export const modalDescritivoLote = (props) => {
  const {
    classes,
    exibirDescritivoModal,
    aoFechar,
    dataInicial,
    dataFinal,
    operacoesCategoria,
    nomeCategoria,
  } = props;
  const exibeOperacoes = (operacoesCategoria.items || []).length > 0;
  return (
    <div className={classes.boxModal}>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={exibirDescritivoModal}
        onBackdropClick={aoFechar}
      >
        <Grid
          id="containerModalLote"
          container
          className={classes.modalConteudo}
          alignItems="flex-start"
        >
          <Grid item xs={12} className={classes.cabecalho}>
            <Typography variant="h6" className={classes.tituloTexto}>
              Histórico de Operações
            </Typography>
            <CloseIcon className={classes.fechar} onClick={aoFechar} />
          </Grid>
          <Grid item xs={6} className={classes.nome}>
            {nomeCategoria}
          </Grid>
          <Grid item xs={6} className={classes.data}>
            {exibeOperacoes ? (
              <Typography className={classes.dataTexto}>
                Data inicial: {moment(dataInicial).format("DD/MM/YYYY")} | Data
                final: {moment(dataFinal).format("DD/MM/YYYY")}
              </Typography>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            {exibeOperacoes ? (
              <TabelaDescritivoLote
                operacoesCategoria={operacoesCategoria.items}
              />
            ) : (
              <FeedbackDescritivoLote />
            )}
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

const styles = () => {
  return {
    boxModal: {
      overflow: "auto",
    },
    modalConteudo: {
      margin: "100px auto",
      padding: "40px 40px",
      width: "70%",
      maxHeight: "70%",
      backgroundColor: "#ffffff",
      outline: "none",
      boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.5)",
      borderRadius: 16,
    },
    cabecalho: {
      display: "flex",
      marginTop: "16px",
      marginBottom: "16px",
      justifyContent: "space-between",
      alignItems: "center",
    },
    nome: {
      height: "32px",
      textAlign: "left",
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 500,
      letterSpacing: "normal",
      color: "#333333",
    },
    data: {
      height: "32px",
      textAlign: "right",
      fontFamily: "Roboto",
      fontSize: "14px",
      letterSpacing: "normal",
      color: "#333333",
    },
    tituloTexto: {
      position: "relative",
      fontSize: "18px",
      fontWeight: "500",
      letterSpacing: "-0.1px",
      color: "rgba(51, 50, 56, 0.84)",
    },
    fechar: {
      width: "24px",
      height: "24px",
      cursor: "pointer",
      objectFit: "contain",
    },
  };
};

export default withStyles(styles)(modalDescritivoLote);
