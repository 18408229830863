import {
  notifyError,
  notifySuccess,
} from "../../../redux/actions/notificacaoActions";
import CausaMorteManager from "../causaMorteManager";
import {
  listarCausasMorteFazenda,
  excluirCausaMorte,
  salvarCausaMorte,
  editarCausaMorte,
  exibirCarregando,
  reativarCausaMorte,
} from "./causaMorteActions";
import _ from "lodash";

export const obterCausasMorteFazenda =
  (deleted) => async (dispatch, getState) => {
    dispatch(exibirCarregando(true));
    try {
      const { fazenda } = getState();
      const causasMorteFazenda =
        await CausaMorteManager.obterCausasMorteFazenda(
          fazenda.FazendaSelecionada,
          deleted
        );
      dispatch(listarCausasMorteFazenda(causasMorteFazenda));
    } catch (error) {
      dispatch(listarCausasMorteFazenda([]));
      dispatch(
        notifyError({
          mensagem: "Ocorreu um erro ao obter a lista de causas de morte.",
        })
      );
    } finally {
      dispatch(exibirCarregando(false));
    }
  };

export const deletarCausaMorte = (idCausaMorte) => async (dispatch) => {
  try {
    await CausaMorteManager.excluirCausaMorte(idCausaMorte);
    dispatch(excluirCausaMorte());
    dispatch(
      notifySuccess({
        mensagem: "Causa de morte desativada com sucesso.",
      })
    );
  } catch (error) {
    if (
      !_.isNil(error.response) &&
      !_.isNil(error.response.data) &&
      !_.isNil(error.response.data.codigo) &&
      Number(error.response.data.codigo) === 406
    ) {
      dispatch(
        notifyError({
          mensagem: error.response.data.mensagem,
        })
      );
    } else {
      dispatch(
        notifyError({
          mensagem: "Ocorreu um erro ao desativar a causa de morte.",
        })
      );
    }
  }
};

export const reativarCausasMorte =
  (idCausaMorte, causaMorte) => async (dispatch) => {
    try {
      await CausaMorteManager.reativarCausaMorte(idCausaMorte, causaMorte);
      dispatch(reativarCausaMorte());
      dispatch(
        notifySuccess({
          mensagem: "Causa de morte reativada com sucesso.",
        })
      );
    } catch (error) {
      if (
        !_.isNil(error.response) &&
        !_.isNil(error.response.data) &&
        !_.isNil(error.response.data.codigo) &&
        Number(error.response.data.codigo) === 406
      ) {
        dispatch(
          notifyError({
            mensagem: error.response.data.mensagem,
          })
        );
      } else {
        dispatch(
          notifyError({
            mensagem: "Ocorreu um erro ao reativar a causa de morte.",
          })
        );
      }
    }
  };

export const criarCausaMorte = (causaMorte) => async (dispatch, getState) => {
  try {
    const { fazenda } = getState();
    causaMorte.IdFazenda = fazenda.FazendaSelecionada;

    const causaMorteCriadaResposta = await CausaMorteManager.salvarCausaMorte(
      causaMorte
    );

    dispatch(salvarCausaMorte(causaMorteCriadaResposta));
    dispatch(
      notifySuccess({
        mensagem: "Causa de morte criada com sucesso.",
      })
    );
  } catch (error) {
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao criar a causa de morte.",
      })
    );
  }
};

export const atualizarCausaMorte = (causaMorte) => async (dispatch) => {
  try {
    const id = causaMorte.IdCausaMorte;
    await CausaMorteManager.editarCausaMorte(causaMorte, id);
    dispatch(editarCausaMorte());
    dispatch(
      notifySuccess({
        mensagem: "Causa de morte editada com sucesso.",
      })
    );
  } catch (error) {
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao editar a causa de morte.",
      })
    );
  }
};
