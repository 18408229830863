import { withStyles } from "@material-ui/core";
import React, { useCallback } from "react";
import MovementSituationCards from "./pendencyDetailsView/components/movementSituationCards";
import PendencyDetailsCard from "./pendencyDetailsView/components/pendencyDetailsCard";
import PendencyDetailsHeader from "./pendencyDetailsView/components/pendencyDetailsHeader";
import PendencyEditHeader from "./pendencyEditView/components/pendencyEditHeader";
import { getDisabledEditPendency } from "../common/utils/pendencyUtils";

const EDIT_SITUATION_CARD_TITLE = "Situação atual do pasto";
const DETAILS_SITUATION_CARD_TITLE = "Resumo da movimentação";

const PendencyInfosPage = ({
  classes,
  history,
  countOrigin,
  countDestiny,
  pendency,
  movementGrouper,
  originCategoryName,
  originCategoryId,
  originPastureName,
  originPastureId,
  destinyCategoryName,
  destinyCategoryId,
  destinyPastureName,
  destinyPastureId,
  isEditPage,
  pageType,
}) => {
  const onGoBack = useCallback(() => {
    return history.push("/movimentacoesPendentes");
  });
  const goToEdit = () => {
    return history.push(`/movimentacoesPendentes/${movementGrouper}/edicao`);
  };

  return (
    <div className={classes.pageContent}>
      {pageType === "details" ? (
        <PendencyDetailsHeader
          classes={classes}
          history={history}
          goToEdit={goToEdit}
          onGoBack={onGoBack}
          movementGrouper={movementGrouper}
          enableButtonSubmit={!getDisabledEditPendency(pendency)}
        />
      ) : (
        <PendencyEditHeader classes={classes} />
      )}

      <MovementSituationCards
        data-testid="MovementSituationCards"
        classes={classes}
        countOrigin={countOrigin}
        countDestiny={countDestiny}
        movementGrouper={movementGrouper}
        originCategoryName={originCategoryName}
        originCategoryId={originCategoryId}
        originPastureName={originPastureName}
        originPastureId={originPastureId}
        destinyCategoryName={destinyCategoryName}
        destinyCategoryId={destinyCategoryId}
        destinyPastureName={destinyPastureName}
        destinyPastureId={destinyPastureId}
        cardTitle={
          pageType === "details"
            ? DETAILS_SITUATION_CARD_TITLE
            : EDIT_SITUATION_CARD_TITLE
        }
      />
      <PendencyDetailsCard
        data-testid="PendencyDetailsCard"
        classes={classes}
        history={history}
        onGoBack={onGoBack}
        pendency={pendency}
        isEditPage={isEditPage}
        originCategoryName={originCategoryName}
        originCategoryId={originCategoryId}
        originPastureName={originPastureName}
        originPastureId={originPastureId}
        destinyCategoryName={destinyCategoryName}
        destinyCategoryId={destinyCategoryId}
        destinyPastureName={destinyPastureName}
        destinyPastureId={destinyPastureId}
        editable={pageType === "edit"}
        pageType={pageType}
        countOrigin={countOrigin}
      />
    </div>
  );
};

const styles = () => ({
  pageContent: {
    width: "100%",
    minHeight: "80vh",
    overflowX: "auto",
    padding: "5px 40px",
    zIndex: -1,
    bottom: 0,
    background: "#fff",
  },
});

export default withStyles(styles)(PendencyInfosPage);
