import React, { Component } from "react";
import GanhoPesoTabela from "./ganhoPesoTabela";
import { filterGanhoPesoMemoize, mesesNull, mensagemBotao } from "../ganhoPesoUtils";
import GanhoPesoForm from "./ganhoPesoForm";
import Button from "@material-ui/core/Button";
import { DialogoConfirmacaoAcao } from "features/shared/components/DialogoConfirmacaoAcao";
import {
  Grid,
  withStyles,
  Typography,
  Paper,
  FormControl,
  TextField,
} from "@material-ui/core";

export class GanhoPesoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmacaoExclusaoAberto: false,
      exibindo: false,
      adicionando: false,
      filtro: "",
      exibirForm: false,
      curva: {
        Selecionada: false,
        IdCurvaGanhoPeso: null,
        Nome: "",
        IdFazenda: props.fazendaSelecionada,
        ...mesesNull,
      },
      curvaSelecionada: {
        Selecionada: false,
        IdCurvaGanhoPeso: null,
        IdFazenda: props.fazendaSelecionada,
        Nome: "",
        ...mesesNull,
      },
      erroNome: false,
    };
  }
  async componentDidMount() {
    const { obterCurvasGanhoPeso, fazendaSelecionada } = this.props;
    await obterCurvasGanhoPeso(fazendaSelecionada);
  }

  abreDialogExclusao = (curva) => {
    this.setState({ confirmacaoExclusaoAberto: true, curvaSelecionada: curva });
  };

  fechaDialogExclusao = async () => {
    this.setState({ confirmacaoExclusaoAberto: false });
  };

  handleChangeNomeCurva = (event) => {
    const { curvas } = this.props;
    this.setState({
      curva: { ...this.state.curva, Nome: event.target.value.toUpperCase() },
    });
    this.setState({
      erroNome: curvas.some(
        (curva) => curva.Nome.trim() === event.target.value.toUpperCase().trim()
      ),
    });
  };

  handleChangeMes = async (nomeMes, novoValor) => {
    if (novoValor < 0) {
      novoValor = 0;
    }
    const valorConvertidoEmNumero = Number(novoValor);
    this.setState({
      curva: { ...this.state.curva, [nomeMes]: valorConvertidoEmNumero },
    });
  };

  extraiMesesCurvaGanhoPeso = (curva) => {
    if (!curva) return []
    const {
      Janeiro,
      Fevereiro,
      Marco,
      Abril,
      Maio,
      Junho,
      Julho,
      Agosto,
      Setembro,
      Outubro,
      Novembro,
      Dezembro
    } = curva;
    return [
      Janeiro,
      Fevereiro,
      Marco,
      Abril,
      Maio,
      Junho,
      Julho,
      Agosto,
      Setembro,
      Outubro,
      Novembro,
      Dezembro
    ]
  }
  pesosNaoSaoNulos = () => {
    const { curva } = this.state;
    const camposMeses = this.extraiMesesCurvaGanhoPeso(curva);
    return camposMeses.every(
      (value) => value !== null && typeof value === "number"
    );
  };

  pesosSaoValidos = () => {
    const { curva } = this.state;
    const camposMeses = this.extraiMesesCurvaGanhoPeso(curva);
    return camposMeses.some((value) => value > 0);
  };

  habilitarBotao = () => {
    const { curva, erroNome } = this.state;
    const [pesosNaoSaoNulos, pesosSaoValidos] = this._validaCamposPeso();
    if (curva.Nome !== "" && !erroNome && (pesosNaoSaoNulos && pesosSaoValidos)) {
      return false
    }
    return true
  }

  mostrarMensagemBotao = () => {
    const [pesosNaoSaoNulos, pesosSaoValidos] = this._validaCamposPeso();
    if (pesosNaoSaoNulos === false && pesosSaoValidos === false) {
      return mensagemBotao.mesesNaoPreenchidos
    } else if (pesosNaoSaoNulos === false && pesosSaoValidos === true) {
      return mensagemBotao.mesesNaoPreenchidos
    } else if (pesosNaoSaoNulos === true && pesosSaoValidos === false) {
      return mensagemBotao.mesValorDiferenteZero
    } else {
      return ""
    }
  };

  _validaCamposPeso = () => {
    return [this.pesosNaoSaoNulos(), this.pesosSaoValidos()]
  }

  habilitarCurva = (acao) => {
    this.setState({
      adicionando: acao,
      curva: {
        Selecionada: false,
        Nome: "",
        IdFazenda: this.props.fazendaSelecionada,
        ...mesesNull,
      },
      erroNome: false,
    });
  };

  adicionarCurva = async (curva) => {
    if (curva.IdCurvaGanhoPeso) {
      await this.props.atualizarCurva(curva);
    } else {
      await this.props.criarCurva(curva);
    }
    await this.props.obterCurvasGanhoPeso();
    this.setState({
      curva: {
        Selecionada: false,
        Nome: "",
        ...mesesNull,
      },
      adicionando: false,
    });
  };

  habilitarEdicao = (curva) => {
    this.setState({
      adicionando: true,
      curva: curva,
    });
  };
  habilitarExibicao = (curva) => {
    curva.Selecionada = curva.Selecionada ? false : true;
    this.setState({
      curva: curva,
      exibindo: !this.state.exibindo,
    });
  };

  excluirCurva = async (idCurva) => {
    await this.props.deletarCurva(idCurva);
    await this.props.obterCurvasGanhoPeso();
    this.setState({
      curva: {
        Selecionada: false,
        Nome: "",
        ...mesesNull,
      },
      adicionando: false,
    });
  };

  render() {
    const {
      filtro,
      adicionando,
      curva,
      confirmacaoExclusaoAberto,
      erroNome,
      exibindo,
    } = this.state;
    const { classes, curvas } = this.props;
    const ganhosDePesoFiltrados = filterGanhoPesoMemoize(curvas, filtro);
    return (
      <React.Fragment>
        <Paper square className={classes.root}>
          <Grid container className={classes.containerPage}>
            <Typography className={classes.titulo} gutterBottom>
              Informações sobre as curvas de ganho de peso da fazenda
            </Typography>

            <Grid
              container
              xs={12}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <FormControl
                className={classes.formContainer}
                disabled={adicionando}
              >
                <TextField
                  id="inputFiltro"
                  autoFocus
                  label={"Buscar curva"}
                  style={{ width: 274 }}
                  inputProps={{
                    className: {
                      fontsize: "14px",
                      fontcolor: "#757575",
                    },
                  }}
                  className={classes.inputFiltro}
                  onChange={(event) =>
                    this.setState({ filtro: event.target.value })
                  }
                />
              </FormControl>
              {adicionando ? (
                <Paper square className={classes.corpoAdicionar}>
                  <GanhoPesoForm
                    id="formAdicionar"
                    classes={classes}
                    curva={curva}
                    erroNome={erroNome}
                    abreDialogExclusao={this.abreDialogExclusao}
                    adicionarCurva={this.adicionarCurva}
                    habilitarCurva={this.habilitarCurva}
                    handleChangeNomeCurva={this.handleChangeNomeCurva}
                    handleChangeMes={this.handleChangeMes}
                    habilitarBotao={this.habilitarBotao}
                    mensagemBotaoDesabilitado={this.mostrarMensagemBotao()}
                  />
                </Paper>
              ) : (
                <Button
                  id="botaoAdicionar"
                  onClick={() => this.habilitarCurva(true)}
                  variant="contained"
                  color="primary"
                  className={classes.botaoNovo}
                >
                  Nova Curva
                </Button>
              )}
            </Grid>
            <Grid container xs={12}>
              <GanhoPesoTabela
                id="ganhoDePesoTabela"
                ganhosDePesoFiltrados={ganhosDePesoFiltrados}
                habilitarEdicao={this.habilitarEdicao}
                adicionando={adicionando}
                habilitarExibicao={this.habilitarExibicao}
                curva={curva}
                exibindo={exibindo}
              />
            </Grid>
          </Grid>
        </Paper>
        <DialogoConfirmacaoAcao
          id={`dialogConfirmaExclusaoCurva`}
          tituloDialog={"Confirmar exclusão de Curva"}
          textoDialog={
            <span id="mensagemConfirmaExclusaoCurva">
              Você deseja excluir a curva?
            </span>
          }
          dialogAberto={confirmacaoExclusaoAberto}
          cancelarAcaoDialog={this.fechaDialogExclusao}
          objetoSelecionado={this.state.curvaSelecionada.IdCurvaGanhoPeso}
          excluir={this.excluirCurva}
        />
      </React.Fragment>
    );
  }
}

const styles = (theme) => {
  return {
    containerPage: {
      flexgrow: 1,
      height: "100%",
      backgroundColor: "#fff",
      width: "100%",
    },
    root: {
      width: "100%",
      height: "100%",
      overflowX: "auto",
      padding: "46px",
      zIndex: -1,
      bottom: 0,
      top: "17%",
      minHeight: "calc(100vh - 90px)",
    },
    botaoNovo: {
      borderRadius: "18px",
      float: "right",
      marginRight: 0,
      marginTop: 34,
      width: 122,
      height: 35,
      fontSize: 14,
      boxShadow: "none!important",
    },
    corpoAdicionar: {
      width: "100%",
      marginTop: "19.5px",
      padding: "24px 32px",
      borderRadius: "16px"
    },
    titulo: {
      fontSize: "20px",
      fontWeight: "bold",
      color: "#444",
      marginTop: "40px",
      fontFamily: "Roboto",
    },
    formContainer: {
      marginTop: "20px",
    },
    inputFiltro: {
      width: "400px",
    },
  };
};

export default withStyles(styles)(GanhoPesoPage);
