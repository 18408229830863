import React from "react";
import { connect } from "react-redux";
import ConfiguracaoTabs from "./configuracaoTabs";
import {
  deveExibirCadastroFormulacao,
  deveExibirGanhoPeso,
  deveExibirObjetivoLote,
  deveExibirHistoricoChuva,
  deveExibirCausaMorte,
  deveExibirFechamentoConsumo,
  deveExibirCreep,
  deveExibirBombona,
  deveExibirMorteNascimento,
  deveExibirAjusteEscore,
  isLoggedUserInternal,
} from "../../shared/utils";
import * as configuracaoOperarions from "../redux/configuracaoOperations";

export const ConfiguracaoTabsContainer = (props) => {
  return (
    <ConfiguracaoTabs
      exibirCadastroFormulacao={props.exibirCadastroFormulacao}
      exibirGanhoPeso={props.exibirGanhoPeso}
      exibirObjetivoLote={props.exibirObjetivoLote}
      exibirHistoricoChuva={props.exibirHistoricoChuva}
      exibirCausaMorte={props.exibirCausaMorte}
      exibirFechamentoDeConsumo={props.exibirFechamentoDeConsumo}
      exibirCreep={props.exibirCreep}
      exibirBombona={props.exibirBombona}
      exibirMorteNascimento={props.exibirMorteNascimento}
      exibirAjusteEscore={props.exibirAjusteEscore}
      exibirEvolucaoRebanhoPan={props.exibirEvolucaoRebanhoPan}
      opcaoDeCadastroSelecionada={props.opcaoDeCadastroSelecionada}
      abaSelecionada={props.abaSelecionada}
      selecionaAbaCadastro={props.selecionaAbaCadastro}
      fazendaSelecionada={props.fazendaSelecionada}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    abaSelecionada: state.configuracao.abaSelecionada,
    fazendaSelecionada: state.fazenda.FazendaSelecionada,
    exibirGanhoPeso: deveExibirGanhoPeso(state),
    exibirCadastroFormulacao: deveExibirCadastroFormulacao(state),
    exibirObjetivoLote: deveExibirObjetivoLote(state),
    exibirHistoricoChuva: deveExibirHistoricoChuva(state),
    exibirCausaMorte: deveExibirCausaMorte(state),
    exibirFechamentoDeConsumo: deveExibirFechamentoConsumo(state),
    exibirCreep: deveExibirCreep(state),
    exibirBombona: deveExibirBombona(state),
    exibirMorteNascimento: deveExibirMorteNascimento(state),
    exibirAjusteEscore: deveExibirAjusteEscore(state),
    exibirEvolucaoRebanhoPan: isLoggedUserInternal(),
  };
};

const mapDispatchToProps = {
  selecionaAbaCadastro: configuracaoOperarions.selecionaAba,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfiguracaoTabsContainer);
