import React, { PureComponent } from "react";
import * as actions from "redux/actions";
import { connect } from "react-redux";
import { montarTextoMapaRebanhoGA } from "../../../shared/utils";
import CategoriaPage from "./categoriaPage";
import * as categoriaOperations from "../redux/categoriaOperations";
import * as faixaIdadeOperations from "../redux/faixaIdadeOperations";
import * as conciliacaoOperations from "../../conciliacao/redux/conciliacaoOperations";

export class CategoriaContainer extends PureComponent {
  render() {
    enviarDadosGA(this.props.idFazenda);
    return (
      <CategoriaPage
        id="categoriaPage"
        exibeTamanhoLote={this.props.exibeTamanhoLote}
        exibeQuantidadeConciliacao={this.props.exibeQuantidadeConciliacao}
        exibeCurvaGanhoPeso={this.props.exibeCurvaGanhoPeso}
        salvaDataConciliacao={this.props.salvaDataConciliacao}
        obterCategoriasFazenda={this.props.obterCategoriasFazenda}
        obterCurvasGanhoPeso={this.props.obterCurvasGanhoPeso}
        criarCategoria={this.props.criarCategoria}
        obterFaixas={this.props.obterFaixas}
        excluirCategoria={this.props.excluirCategoria}
        desexcluirCategoria={this.props.desexcluirCategoria}
        editarCategoria={this.props.editarCategoria}
        categorias={this.props.categorias}
        FaixasIdade={this.props.FaixasIdade}
        loading={this.props.loading}
        CurvasGanhoPeso={this.props.CurvasGanhoPeso}
        exibirGanhoPeso={this.props.exibirGanhoPeso}
        idFazenda={this.props.idFazenda}
        dataInicioConciliacao={this.props.dataInicioConciliacao}
        timezoneFazenda={this.props.currentFarm.TimezoneFazenda}
      />
    );
  }
}

const enviarDadosGA = (idFazenda) => {
  montarTextoMapaRebanhoGA(idFazenda, "Categoria Animal");
};

const mapDispatchToProps = {
  loading: actions.exibirCarregandoSalvando,
  obterCategoriasFazenda: categoriaOperations.obterCategoriasFazenda,
  obterCurvasGanhoPeso: categoriaOperations.obterCurvasGanhoPeso,
  criarCategoria: categoriaOperations.criarCategoria,
  obterFaixas: faixaIdadeOperations.obterFaixas,
  excluirCategoria: categoriaOperations.deletarCategoria,
  salvaDataConciliacao: conciliacaoOperations.salvaDataInicioConciliacao,
  editarCategoria: categoriaOperations.atualizarCategoria,
  desexcluirCategoria: categoriaOperations.desexcluirCategoria,
};

export const mapStateToProps = ({ categoria, faixaIdade, fazenda }) => {
  return {
    categorias: categoria.categorias,
    FaixasIdade: faixaIdade.Faixas,
    CurvasGanhoPeso: categoria.curvas,
    idFazenda: fazenda.FazendaSelecionada,
    dataInicioConciliacao: fazenda.DataInicioConciliacao,
    currentFarm: fazenda.currentFarm
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriaContainer);
