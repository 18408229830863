import React from 'react';
import { Grid } from '@material-ui/core';
import { TextLabel, FeedBackPage } from '../../shared/components';
import { FazendaSelecionadaContainer } from '../../shared/crossCuttingComponents/fazendaSelecionada';
import { getMensagemFeedbackFechamento } from '../fechamentoConsumoUtils';
export const fechamentoConsumoFeedback = props => {
  return (
    <FazendaSelecionadaContainer
      render={fazenda => {
        const msgFeedback = getMensagemFeedbackFechamento(fazenda);

        return (
          <Grid
            container
            direction="column"
            justify="center"
            style={{
              minHeight: '60vh'
            }}
          >
            <FeedBackPage>
              <Grid container direction="column" alignItems="center">
                <img
                  src="../images/fechamentoTelaVazia.png"
                  alt={msgFeedback}
                />
                <TextLabel
                  id="labelFechamentoFeedBack"
                  block
                  color="default2"
                  customStyle={{ paddingTop: 10 }}
                >
                  {msgFeedback}
                </TextLabel>
              </Grid>
            </FeedBackPage>
          </Grid>
        );
      }}
    />
  );
};

export default fechamentoConsumoFeedback;
