import React from 'react';
import { Grid, Typography } from '@material-ui/core';

export const feedBackPage = props => {
  const { mensagem, customStyle } = props;
  const { containerMensagem, textoMensagem } = customStyle;
  return (
    <Grid container direction="row" justify="center" alignItems="center" style={containerMensagem}>
      {mensagem && (
        <Typography variant="subtitle2" color="textSecondary" style={textoMensagem}>
          {mensagem}
        </Typography>
      )}
      {props.children}
    </Grid>
  );
};

feedBackPage.defaultProps = {
  customStyle: {
    containerMensagem: {},
    textoMensagem: {}
  }
};
export default feedBackPage;
