import BaseService from "../../services/BaseService";
import moment from "moment";

const URL_BASE = `${process.env.REACT_APP_ENDPOINT}entradasaida/api/v1/evento-pesagem`;

class PesagemCategoriaService extends BaseService {
  async obterPesagensFazenda(IdFazenda) {
    return await this.get(
      `${URL_BASE}?where={"IdFazenda": ${IdFazenda}}
      &attributes=["Nome","DataPesagem","IdFazenda","IdEventoPesagem"]
      &sort=[["DataPesagem", "DESC"]]`
    );
  }

  async obterQuantidadesPorCategoria(dataPesagem, IdFazenda) {
    const dataFormatada = moment(dataPesagem).format("YYYY-MM-DD 23:59:59");
    return await this.get(
      `${URL_BASE}/quantidade-animais?DataPesagem=${dataFormatada}&IdFazenda=${IdFazenda}`
    );
  }

  async criarPesagensFazenda(eventoPesagem) {
    return await this.post(`${URL_BASE}`, eventoPesagem);
  }

  async editarPesagensFazenda(eventoPesagem) {
    return await this.put(
      `${URL_BASE}/${eventoPesagem.IdEventoPesagem}`,
      eventoPesagem
    );
  }

  async excluirPesagemFazenda(IdEventoPesagem) {
    return await this.delete(`${URL_BASE}/${IdEventoPesagem}`);
  }
}

export default new PesagemCategoriaService();
