import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@material-ui/core';

export const DialogAtualizacaoRetiroPasto = props => {
  const { ExcluirModuloAlterandoRetiro } = props;

  return ExcluirModuloAlterandoRetiro ? (
    <Dialog
      id="excluirModuloAlterandoRetiro"
      open={ExcluirModuloAlterandoRetiro}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={() => props.handleFechaConfirmarExclusaoModulo()}
    >
      <DialogTitle id="alert-dialog-modal-exclusao-modulo-retiro-title">
        {'Deseja confirmar a exclusão do módulo?'}
      </DialogTitle>
      <DialogContent id="alert-dialog-description">
        <DialogContentText>
          {`Todos os pastos de um módulo devem ser do mesmo retiro.
            Para alterar este pasto de retiro o módulo ao qual ele pertence precisa ser excluído.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          id="botaoCancelar"
          onClick={() => props.handleFechaConfirmarExclusaoModulo()}
          color="primary"
          autoFocus
        >
          CANCELAR
        </Button>
        <Button
          id="botaoConfirmar"
          onClick={() => props.handleConfirmarExclusaoModuloAlterandoRetiro()}
          color="primary"
        >
          CONFIRMAR
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

DialogAtualizacaoRetiroPasto.propTypes = {
  ExcluirModuloAlterandoRetiro: PropTypes.bool.isRequired,
  handleFechaConfirmarExclusaoModulo: PropTypes.func.isRequired,
  handleConfirmarExclusaoModuloAlterandoRetiro: PropTypes.func.isRequired
};

export default DialogAtualizacaoRetiroPasto;
