import React from "react";
import { Divider, withStyles } from "@material-ui/core";
import PlanningFormulaReportTable from "./planningReport/planningFormulaReportTable";
import PlanningIngredientReportTable from "./planningReport/planningIngredientReportTable";
import PlanningCategoryReportTable from "./planningReport/planningCategoryReportTable";
import PlanningReportTable from "./planningReport/PlanningReportTable";
import { fillPlanningItems, parsePlanningItems } from "./utils";

function PlanningSummary({
  periodTitles,
  categories,
  formulas,
  planningList,
  planningTotal,
  summaryReport,
  classes,
  warning,
}) {
  const planningItemsList = parsePlanningItems({
    planningItems: fillPlanningItems(planningList),
  });

  return (
    <div data-testid="planning-view-summary" className={classes.container}>
      <PlanningReportTable
        warning={warning}
        periodTitles={periodTitles}
        categories={categories}
        formulas={formulas}
        planningsTableList={planningItemsList}
        total={planningTotal}
      />
      {summaryReport && (
        <>
          <Divider />
          <div className={classes.reportTitle}>Planejamento de custos</div>
          {summaryReport.formulaPlanning && (
            <PlanningFormulaReportTable
              planningFormulaReport={summaryReport.formulaPlanning}
              periodTitles={periodTitles}
            />
          )}
          <Divider />
          {summaryReport.ingredientPlanning && (
            <PlanningIngredientReportTable
              planningIngredientReport={summaryReport.ingredientPlanning}
              periodTitles={periodTitles}
            />
          )}

          <Divider />
          {summaryReport.categoryPlanning && (
            <PlanningCategoryReportTable
              planningCategoryReport={summaryReport.categoryPlanning}
              periodTitles={periodTitles}
            />
          )}
        </>
      )}
    </div>
  );
}

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    marginTop: "32px",
    "& table": {
      marginTop: "16px",
    },
  },
  reportTitle: {
    color: theme.palette.primary.main,
    fontFamily: "Roboto",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  },
});

export default withStyles(styles)(PlanningSummary);
