import {
  EXIBIR_CARREGANDO,
  ALTERAR_TEXTO,
  EXIBIR_CARREGANDO_SALVANDO,
  EXIBIR_SUCESSO,
} from "../actions/actionsConstants";

export const initialState = {
  ExibirCarregando: false,
  ExibirCarregandoSalvando: false,
  textoCarregando: "",
  valorBarraProgresso: 0,
  sucesso: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EXIBIR_CARREGANDO:
      return { ...state, ExibirCarregando: action.payload };
    case EXIBIR_CARREGANDO_SALVANDO:
      return { ...state, ExibirCarregandoSalvando: action.payload };
    case ALTERAR_TEXTO:
      return {
        ...state,
        textoCarregando: action.payload.textoCarregando,
        valorBarraProgresso: action.payload.valorBarraProgresso,
      };
    case EXIBIR_SUCESSO:
      return { ...state, sucesso: action.payload };
    default:
      return state;
  }
};
