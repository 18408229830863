import FaixaIdadeService from './faixaIdadeService';
import * as _ from 'lodash';

export class FaixaIdadeManager {
  async obterFaixaIdade() {
    const respostaService = await FaixaIdadeService.listarFaixaIdade();
    const primeirasFaixas = _.filter(respostaService.data, f => { return f.FaixaIdade === '0 a 8 meses'; });
    const ultimasFaixas = _.filter(respostaService.data, f => { return f.FaixaIdade !== '0 a 8 meses'; });
    const result = _.concat(primeirasFaixas, ultimasFaixas);
    return result;
  }
}

export default new FaixaIdadeManager();
