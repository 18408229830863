import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import CausaMortePage from './causaMortePage';
import * as causaMorteOperations from '../redux/causaMorteOperations';

export class CausaMorteContainer extends PureComponent {
  render() {
    return (
      <CausaMortePage
        id="causaMortePage"
        obtemCausasMorteFazenda={this.props.obtemCausasMorteFazenda}
        criarCausaMorte={this.props.criarCausaMorte}
        excluirCausaMorte={this.props.excluirCausaMorte}
        reativarCausaMorte={this.props.reativarCausaMorte}
        editarCausaMorte={this.props.editarCausaMorte}
        causasMorte={this.props.causasMorte}
      />
    );
  }
}

const mapDispatchToProps = {
  obtemCausasMorteFazenda: causaMorteOperations.obterCausasMorteFazenda,
  criarCausaMorte: causaMorteOperations.criarCausaMorte,
  excluirCausaMorte: causaMorteOperations.deletarCausaMorte,
  reativarCausaMorte: causaMorteOperations.reativarCausasMorte,
  editarCausaMorte: causaMorteOperations.atualizarCausaMorte
};

export const mapStateToProps = ({ causaMorte }) => {
  return {
    causasMorte: causaMorte.causasMorte
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CausaMorteContainer);
