import {
  SET_RETIRO,
  LISTA_RETIROS,
  SET_COR_RETIRO,
  CRIAR_RETIROS,
  ATUALIZA_RETIRO,
  DELETA_RETIRO,
} from "../../../redux/actions/actionsConstants";

export const listaRetiros = (retiros) => {
  return {
    type: LISTA_RETIROS,
    payload: { Retiros: retiros },
  };
};

export const criaRetiro = (retiros) => {
  return {
    type: CRIAR_RETIROS,
    payload: { RetirosCriados: retiros },
  };
};

export const setaCorRetiro = (cor) => {
  return {
    type: SET_COR_RETIRO,
    payload: cor,
  };
};

export const setaRetiro = (retiro) => {
  return {
    type: SET_RETIRO,
    payload: retiro,
  };
};

export const deletaRetiro = () => {
  return {
    type: DELETA_RETIRO,
  };
};

export const atualizaRetiro = (retiro) => {
  return {
    type: ATUALIZA_RETIRO,
    payload: retiro,
  };
};
