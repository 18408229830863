import React from "react";
import { Button, Paper, withStyles, Grid } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Title from "./title";

const backButton = (onClick) =>
  onClick && (
    <Button onClick={onClick} style={{ marginLeft: -23 }}>
      <ArrowBack color="primary" />
    </Button>
  );

const GoalPlanContent = ({ testid, classes, children, title, onBack }) => {
  return (
    <Paper data-testid={testid} className={classes.goalPlanListContainer}>
      <Grid container alignItems="center" className={classes.tituloContainer}>
        {backButton(onBack)}
        <Title>{title}</Title>
      </Grid>

      {children}
    </Paper>
  );
};

const styles = (theme) => ({
  tituloContainer: {
    marginTop: theme.telas.marginTopTitulo,
  },
  goalPlanListContainer: theme.telas.telaContainer,
  button: {
    margin: "none",
  },
});

export default withStyles(styles)(GoalPlanContent);
