import React from "react";
import {
  ListItem,
  Grid,
  List,
  Typography,
  Divider,
  withStyles,
} from "@material-ui/core";
import {
  deveExibirBotaoDetalhesRebanho,
  trataFaroisRenderizar,
} from "./FarolUtils";
import FarolItem from "./farolItem";
import { idsFarois } from "../../../../../constants/idsFarois";
import { InfoMorteComPendencia } from "features/shared/components/InfoMorteComPendencia";

export const faroisList = ({
  farois,
  toggles,
  farolSelecionado,
  tipoFarol,
  abrirDetalhes,
  tipoIconeExibido,
  dataAtualizacao,
  fazendaSelecionada,
  totalNascimentoMorte,
  classes,
}) => {
  const retornaListaFarois = () => {
    const farolRebanho = deveExibirBotaoDetalhesRebanho(tipoFarol);
    const faroisListar = trataFaroisRenderizar(farois, toggles);
    return faroisListar.map((farol) => {
      const exibirBotao =
        farolRebanho &&
        farol.IdFarol === farolSelecionado &&
        farol.IdFarol !== idsFarois.ganhoPeso;
      return (
        <FarolItem
          key={farol.IdFarol}
          farol={farol}
          farolSelecionado={farolSelecionado}
          tipoIconeExibido={tipoIconeExibido}
          abrirDetalhes={abrirDetalhes}
          dataAtualizacao={dataAtualizacao}
          exibirBotao={exibirBotao}
          fazendaSelecionada={fazendaSelecionada}
          toggles={toggles}
          totalNascimentoMorte={totalNascimentoMorte}
        />
      );
    });
  };

  const retornaTotalNascimentoMorte = () => {
    const {
      totalNascimento,
      totalNascimentoEmPastoExcluido,
      totalMorte,
      totalMorteEmPastoExcluido,
    } = totalNascimentoMorte;
    return (
      <>
        {farolSelecionado === idsFarois.mortes ||
        farolSelecionado === idsFarois.nascimentos ? (
          <>
            <Grid item xs={12}>
              <Typography align="left" variant="subtitle1" gutterBottom>
                <span className={classes.bold}>Total:</span>
              </Typography>
            </Grid>
            <div className={classes.containerInfoNascimentoMorte}>
              <Typography
                className={classes.textoNascimentoMorte}
                align="left"
                variant="subtitle1"
                gutterBottom
              >
                Nascimentos :{" "}
                <span className={classes.valorTextoNascimentoMorte}>
                  <span className={classes.bold}>{totalNascimento}</span>{" "}
                  {totalNascimentoEmPastoExcluido > 0 && (
                    <>
                      <span className={classes.bold}>
                        ({totalNascimentoEmPastoExcluido}
                      </span>{" "}
                      nascimentos em pastos que foram{" "}
                      <span className={classes.bold}>excluídos</span>)
                    </>
                  )}
                </span>
              </Typography>
              <Typography
                className={classes.textoNascimentoMorte}
                align="left"
                variant="subtitle1"
                gutterBottom
              >
                Mortes :{" "}
                <span className={classes.valorTextoNascimentoMorte}>
                  <span className={classes.bold}>{totalMorte}</span>{" "}
                  {totalMorteEmPastoExcluido > 0 && (
                    <>
                      <span className={classes.bold}>
                        ({totalMorteEmPastoExcluido}
                      </span>{" "}
                      mortes em pastos que foram{" "}
                      <span className={classes.bold}>excluídos</span>)
                    </>
                  )}
                </span>
              </Typography>
              <Typography
                className={classes.textoNascimentoMorte}
                align="left"
                variant="subtitle1"
                gutterBottom
              >
                Total de mortes e nascimentos:{" "}
                <span className={classes.bold}>
                  {totalMorte + totalNascimento}{" "}
                </span>
              </Typography>
            </div>
          </>
        ) : null}
      </>
    );
  };

  return (
    <React.Fragment>
      <ListItem key="SelectFaroisListItem">
        <Grid container>
          {retornaTotalNascimentoMorte()}
          <Grid item xs={12}>
            <Typography align="left" variant="subtitle1" gutterBottom>
              Faróis:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <List>{retornaListaFarois()}</List>
            {totalNascimentoMorte.totalDeMortesComPendencia > 0 &&
              farolSelecionado === idsFarois.mortes && (
                <InfoMorteComPendencia
                  morteComPendenciaPasto={
                    totalNascimentoMorte.morteComPendenciaPasto
                  }
                />
              )}
          </Grid>
        </Grid>
      </ListItem>
      <Divider key="DividerSelectFaroisListItem" variant="middle" />
    </React.Fragment>
  );
};

const styles = (theme) => ({
  containerInfoNascimentoMorte: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "16px",
  },
  textoNascimentoMorte: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: "16px",
    color: theme.palette.primary.main,
  },
  valorTextoNascimentoMorte: {
    fontWeight: "normal",
    color: "#212121",
  },
  bold: {
    fontWeight: "bold",
    color: "#212121",
  },
});
export default withStyles(styles)(faroisList);
