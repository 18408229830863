import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { getFazendaSelecionadaStorage, getFazendaStorage } from "shared/storageUtils";

dayjs.extend(utc);

const hourFormat = "HH:mm";
const dateFormat = "DD/MM/YYYY";

export const getFarmTimezone = () => {
  const selectedFarm = getSelectedFarm();
  return selectedFarm && selectedFarm.TimezoneFazenda ? Number(selectedFarm.TimezoneFazenda) : null;
};

export const getSelectedFarm = () => {
  const selectedFarmId = Number(getFazendaSelecionadaStorage());
  const farmList = JSON.parse(getFazendaStorage());

  if (selectedFarmId && farmList && farmList.Fazendas && farmList.Fazendas.length > 0) {
    return farmList.Fazendas.find(farm => farm.IdFazenda === selectedFarmId);
  }
  return null;
}

export const getFarmRegion = () => {
  const selectedFarm = getSelectedFarm();
  return selectedFarm && selectedFarm.Estado ? selectedFarm.Estado : null;
};

export const formatDate = (date, formatPattern) => {
  const farmTimezone = getFarmTimezone();
  if (farmTimezone) {
    return dayjs(date).utc().add(farmTimezone, 'hours').format(formatPattern || dateFormat);
  }
  return dayjs(date).format(formatPattern || dateFormat);
}

export const formatHour = (hour, formatPattern) => {
  const farmTimezone = getFarmTimezone();
  if (farmTimezone) {
    return dayjs(hour).utc().add(farmTimezone, 'hours').format(formatPattern || hourFormat);
  }
  return dayjs(hour).format(formatPattern || hourFormat);
}

export const formatTimezone = () => {
  const farmTimezone = getFarmTimezone();
  const farmRegion = getFarmRegion();

  if (farmTimezone && farmRegion) {
    return `(${farmRegion} GMT${farmTimezone})`;
  }
  return "";
}

export const formatDateToFarmTimezone = (date) => `${formatDate(date)} às ${formatHour(date)} ${formatTimezone()}`

export const parseDateToFarmTimezone = (date) => {
  const farmTimezoneOffset = getFarmTimezone() || 0;
  const userTimezoneOffset = new Date().getTimezoneOffset() / 60;
  const diffTimezoneOffeset = userTimezoneOffset + farmTimezoneOffset;

  return dayjs(date).add(diffTimezoneOffeset, 'hours').toDate();
}
