import React from "react";
import { TextLabel, FeedBackPage } from "../../../../shared/components";
import { Grid } from "@material-ui/core";

const FormulacaoSemItems = () => (
  <Grid
    container
    direction="column"
    justify="center"
    style={{
      minHeight: "60vh",
    }}
  >
    <FeedBackPage>
      <Grid container direction="column" alignItems="center">
        <img src="../images/fechamentoTelaVazia.png" alt="Lupa de tela vazia" />
        <TextLabel
          id="labelFormulacaoFeedBack"
          block
          color="default2"
          customStyle={{ paddingTop: 10 }}
        >
          Não existe Formulação cadastrada!
        </TextLabel>
      </Grid>
    </FeedBackPage>
  </Grid>
);

export default FormulacaoSemItems;
