import BaseService from "services/BaseService";
import axiosRetry from "axios-retry";
import { createServiceHandler } from "services/ServiceHandler";

export const URL_BASE = `${process.env.REACT_APP_ENDPOINT}usuarios/api/v1`;
export const BASE_URL_V2 = `${process.env.REACT_APP_ENDPOINT}usuarios/api/v2`;

export const PERMISSSIONS = [
  {
    name: "Acesso App Views",
    id: "acessa_aplicativo",
    description: "Permite o login ao FarmtellViews",
  },
  {
    name: "Acesso Portal Views Manejo",
    id: "acessa_plataforma",
    description: "Permite o login ao FarmtellViews",
  },
  {
    name: "Estoque Final",
    id: "estoque_final",
    description: "Permite acesso a coleta de estoque final",
  },
  {
    name: "Importar mapas",
    id: "importar_mapas",
    description: "Permite importar arquivos kml do mapa da fazenda",
  },
  {
    name: "Exportar/Importar CSV pasto",
    id: "exportar_importar_csv_pasto",
    description: "Permite a importar/exportar CSV para edição de pastos",
  },
  {
    name: "Cadastrar ciclo de forragems",
    id: "cadastrar_ciclo_forragem",
    description: "Permite o cadastro de ciclo de forragem",
  },
];

class UserService extends BaseService {
  // Users

  async listarUsuarios(limit, offset, filter) {
    const filterParsed = Object.entries(filter)
      .filter(
        ([key, value]) =>
          !(
            value === null ||
            value === undefined ||
            value === "" ||
            (Array.isArray(value) && value.length === 0)
          )
      )
      .reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: Array.isArray(value) ? JSON.stringify(value) : value,
        }),
        {}
      );
    try {
      const response = await this.get(`${BASE_URL_V2}/usuarios`, {
        limit,
        offset,
        ...filterParsed,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async buscarUsuario(idUsuario, idCliente) {
    if (!idUsuario || !idCliente) {
      return { data: {} };
    }
    try {
      const { data: response } = await this.get(
        `${BASE_URL_V2}/usuarios/${idUsuario}?idCliente=[${idCliente}]`
      );
      return response.data && response.data[0];
    } catch (error) {
      throw error;
    }
  }

  async verificarExistenciaLogin(username) {
    if (!username) {
      return false;
    }

    try {
      const response = await this.get(
        `${process.env.REACT_APP_ENDPOINT}usuarios/api/v2/usuarios/verificaExistencia?username=${username}`
      );

      return (
        response &&
        response.data &&
        response.data.username &&
        response.data.username.exist
      );
    } catch (error) {
      throw error;
    }
  }

  async verificarExistenciaEmail(email) {
    if (!email) {
      return false;
    }

    try {
      const response = await this.get(
        `${process.env.REACT_APP_ENDPOINT}usuarios/api/v2/usuarios/verificaExistencia?email=${email}`
      );
      return (
        response &&
        response.data &&
        response.data.email &&
        response.data.email.exist
      );
    } catch (error) {
      throw error;
    }
  }

  async cadastrarUsuario(userData) {
    try {
      const response = await this.post(`${BASE_URL_V2}/usuarios`, userData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async cadastrarUsuarioEVinculos(
    userData,
    permissions,
    farmAndRetreatLinks,
    clientId
  ) {
    const user = await this.cadastrarUsuario(userData).catch(() => {
      throw new Error(
        "Houve um erro ao criar o usuário. Por favor, tente novamente."
      );
    });

    const userId = user.views.IdUsuario;

    const farmsToAssociate = farmAndRetreatLinks.map(({ farm }) => farm.id);
    const retreatsToAssociate = farmAndRetreatLinks.reduce(
      (acc, { farm, retreats }) => {
        retreats.forEach((retreat) =>
          acc.push({ IdFazenda: farm.id, IdRetiro: retreat.id })
        );
        return acc;
      },
      []
    );

    const permissionsArray = Object.entries(permissions)
      .filter(([_, value]) => {
        return !!value;
      })
      .map(([key, _]) => key);

    await Promise.all([
      this.vincularCliente(userId, clientId).catch(() => {
        throw new Error(
          "O usuário foi criado, mas houve um erro ao vincular ao cliente. Por favor, entre em contato com nossa equipe de suporte para obter ajuda."
        );
      }),
      this.vincularFazendaBulk(userId, farmsToAssociate).catch(() => {
        throw new Error(
          "Houve um erro ao vincular as fazendas. Por favor, entre em contato com nossa equipe de suporte para obter ajuda."
        );
      }),
      this.vincularRetiroBulk(userId, retreatsToAssociate).catch(() => {
        throw new Error(
          "Houve um erro ao vincular os retiros. Por favor, entre em contato com nossa equipe de suporte para obter ajuda."
        );
      }),
      this.atualizarPermissoes(userData.Login, permissionsArray).catch(() => {
        throw new Error(
          "O usuário foi criado, mas houve um erro ao atribuir as permissões. Por favor, entre em contato com nossa equipe de suporte para obter ajuda."
        );
      }),
    ]);
  }

  async atualizarUsuario(idUsuario, userData) {
    try {
      const response = await this.put(
        `${URL_BASE}/usuarios/${idUsuario}`,
        userData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async editaUsuarioEVinculos(userData, permissions, farmAndRetreatLinks) {
    await this.atualizarUsuario(userData.IdUsuario, userData).catch(() => {
      throw new Error(
        "Houve um erro ao atualizar o usuário. Por favor, tente novamente."
      );
    });

    const farmsToAssociate = farmAndRetreatLinks.map(({ farm }) => farm.id);
    const retreatsToAssociate = farmAndRetreatLinks.reduce(
      (acc, { farm, retreats }) => {
        retreats.forEach((retreat) =>
          acc.push({ IdFazenda: farm.id, IdRetiro: retreat.id })
        );
        return acc;
      },
      []
    );
    const permissionsArray = Object.entries(permissions)
      .filter(([_, value]) => {
        return !!value;
      })
      .map(([key, _]) => key);

    // Favor não agrupar as requisições abaixo, pois pode gerar race conditions :/
    await this.vincularFazendaBulk(userData.IdUsuario, farmsToAssociate).catch(
      () => {
        throw new Error(
          "Houve um erro ao vincular as fazendas. Por favor, entre em contato com nossa equipe de suporte para obter ajuda."
        );
      }
    );
    await this.vincularRetiroBulk(
      userData.IdUsuario,
      retreatsToAssociate
    ).catch(() => {
      throw new Error(
        "Houve um erro ao vincular os retiros. Por favor, entre em contato com nossa equipe de suporte para obter ajuda."
      );
    });
    await this.atualizarPermissoes(userData.Login, permissionsArray).catch(
      () => {
        throw new Error(
          "Houve um erro ao atribuir as permissões. Por favor, entre em contato com nossa equipe de suporte para obter ajuda."
        );
      }
    );
  }

  async ativarUsuario(idUsuario, idCliente) {
    try {
      const response = await this.put(`${URL_BASE}/usuarios/clientes/ativar`, {
        idUsuario,
        idCliente,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async desativarUsuario(idUsuario, idCliente) {
    try {
      const response = await this.put(
        `${URL_BASE}/usuarios/clientes/desativar`,
        { idUsuario, idCliente }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async atualizarPermissoes(login, permissions) {
    try {
      const axiosInstance = createServiceHandler();
      axiosRetry(axiosInstance, { retries: 5, retryDelay: () => 1000 });
      const response = await axiosInstance.put(
        `${URL_BASE}/usuarios/${login}/permissoes`,
        permissions
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async alterarSenha(passwordData) {
    try {
      const response = await this.post(
        `${URL_BASE}/auth/reset-password`,
        passwordData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Clientes
  async buscarCliente(idUsuario, idCliente) {
    try {
      const response = await this.get(
        `${URL_BASE}/usuarios/${idUsuario}/clientes/${idCliente}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async vincularCliente(idUsuario, idCliente) {
    try {
      const axiosInstance = createServiceHandler();
      axiosRetry(axiosInstance, { retries: 5, retryDelay: () => 3000 });
      const response = await axiosInstance.post(
        `${URL_BASE}/usuarios/${idUsuario}/clientes`,
        { IdUsuario: idUsuario, IdCliente: idCliente }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async desvincularCliente(idUsuario, idCliente) {
    try {
      const response = await this.delete(
        `${URL_BASE}/usuarios/${idUsuario}/clientes/${idCliente}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Fazendas
  async buscarFazenda(idUsuario, idFazenda) {
    try {
      const response = await this.get(
        `${URL_BASE}/usuarios/${idUsuario}/fazendas/${idFazenda}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async vincularFazenda(idUsuario, idCliente, idFazenda) {
    try {
      const response = await this.post(
        `${URL_BASE}/usuarios/${idUsuario}/fazendas`,
        { IdUsuario: idUsuario, IdCliente: idCliente, IdFazenda: idFazenda }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async vincularFazendaBulk(idUsuario, idsFazenda) {
    try {
      const response = await this.post(
        `${BASE_URL_V2}/usuarios/${idUsuario}/fazendas/bulk`,
        idsFazenda
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async desvincularFazenda(idUsuario, idFazenda) {
    try {
      const response = await this.delete(
        `${URL_BASE}/usuarios/${idUsuario}/fazendas/${idFazenda}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Retiros
  async buscarRetiros(idUsuario, filter) {
    try {
      const params = filter ? { IdUsuario: idUsuario, ...filter } : filter;
      const response = await this.get(
        `${URL_BASE}/usuarios/${idUsuario}/retiros`,
        { params }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async vincularRetiro(idUsuario, idFazenda, idRetiro) {
    try {
      const response = await this.post(
        `${URL_BASE}/usuarios/${idUsuario}/retiros`,
        { IdUsuario: idUsuario, IdFazenda: idFazenda, IdRetiro: idRetiro }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async vincularRetiroBulk(idUsuario, retiros) {
    try {
      const response = await this.post(
        `${BASE_URL_V2}/usuarios/${idUsuario}/retiros/bulk`,
        retiros
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async desvincularRetiro(idUsuario, idRetiro, idFazenda) {
    try {
      const response = await this.delete(
        `${URL_BASE}/usuarios/${idUsuario}/retiros/${idRetiro}/fazendas/${idFazenda}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new UserService();
