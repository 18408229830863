import moment from "moment";
import BaseService from "./BaseService";

moment.locale("pt-BR");
const URL_BASE = `${process.env.REACT_APP_ENDPOINT}farois/api/v1`;

class FarolService extends BaseService {
  async listaFarol(
    fazendaSelecionada,
    tipoFarol,
    DataInicio,
    DataFim,
    idCliente
  ) {
    const dtInicio = DataInicio
      ? DataInicio.utc().startOf("day").toISOString()
      : "";
    const dtFim = DataFim
      ? DataFim.utc().endOf("day").toISOString()
      : moment().toISOString();
    const url = `${URL_BASE}/farois/fazendas/${fazendaSelecionada}/${tipoFarol}?DataInicio=${dtInicio}&DataFim=${dtFim}&IdCliente=${idCliente}`;
    return await this.get(url);
  }
}

export default new FarolService();
