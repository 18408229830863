import FazendaService from "../../services/FazendaService";
import ConciliacaoService from "./conciliacaoService";
import moment from "moment";
export class ConciliacaoManager {
  async listarConciliacoesFazenda(
    idFazenda,
    dataInicial,
    dataFinal,
    inicioConciliacao
  ) {
    return await ConciliacaoService.listarConciliacoes(
      idFazenda,
      dataInicial,
      dataFinal,
      inicioConciliacao
    );
  }
  async detalhesCategoriaConciliacao(
    idFazenda,
    idCategoria,
    dataInicial,
    dataFinal
  ) {
    return await ConciliacaoService.detalhesCategoriaConciliacao(
      idFazenda,
      idCategoria,
      moment(dataInicial).startOf("day"),
      moment(dataFinal).endOf("day")
    );
  }
  async salvaDataInicioConciliacao(dataInicioConciliacao, idFazenda) {
    const response = await FazendaService.salvaDataConciliacao(
      dataInicioConciliacao,
      idFazenda
    );
    return response.data;
  }
}

export default new ConciliacaoManager();
