import { useEffect, useState } from "react"
import UserService from "../userService"
import history from "features/app/History"
import { getFromStorage, cleanStorage, saveToStorage, hasPermission } from "../utils"

const MESSAGES = {
  REQUIRED: "Campo obrigatório",
  FILL_ALL_REQUIRED_FIELDS: "Por favor, preencha todos os campos obrigatorios.",
  INVALID_EMAIL: "Email inválido. O e-mail deve conter somente letras minúsculas",
  INVALID_LOGIN: "Login do usuário inválido. O formato deve ser ex: nome.sobrenome ou nome.nome",
  LOGIN_ALREADY_EXISTS: "Este usuário já está em uso e não pode ser utilizado novamente. Tente utilizar um nome login de usuário diferente.",
  EMAIL_ALREADY_EXISTS: "Este e-mail já foi registrado e não é possível cadastrá-lo novamente. Para associar o usuário à fazenda, por favor, entre em contato com nossa equipe de suporte para obter ajuda.",
  USER_CREATED_SUCCESS: "Usuário criado com sucesso",
  USER_UPDATED_SUCCESS: "Edição salva com sucesso!",
  UNEXPECTED_ERROR: "Erro ao criar usuário"
}

const EMAIL_REGEX = /^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]+(\.[a-z]+)?$/;
const LOGIN_REGEX = /^[a-zA-Z0-9._-]{3,20}$/;

export function useUserManagementMutation({ userId, loadingShown, notifyError, notifySuccess }) {
  const [isLoading, setIsLoading] = useState(userId ? true : false)
  const [previousEmail, setPreviousEmail] = useState("")
  const [formState, setFormState] = useState(() => {
    const stateFromLocalStorage = getFromStorage()
    if (stateFromLocalStorage) {
      return stateFromLocalStorage
    }
    return {
      name: {
        value: "",
        error: null
      },
      login: {
        value: "",
        error: null
      },
      email: {
        value: "",
        error: null
      },
      acessaPlataforma: {
        value: false
      },
      acessaAplicativo: {
        value: false
      },
      estoqueFinal: {
        value: false
      },
      importaMapas: {
        value: false
      },
      importaCSV: {
        value: false
      },
      cicloForragem: {
        value: false
      },
      farmAndRetreatLinks: {
        value: [],
        error: null
      },
    }
  })

  const isEdition = userId !== undefined;

  const onBlur = (field) => async () => {
    if (field === "login") {
      const login = formState.login.value;
      let error = null;

      if (!LOGIN_REGEX.test(login)) {
        error = MESSAGES.INVALID_LOGIN;
      } else {
        const loginAlreadyExists = await UserService.verificarExistenciaLogin(login);

        if (loginAlreadyExists) {
          error = MESSAGES.LOGIN_ALREADY_EXISTS;
        }
      }

      setFormState((prevState) => ({
        ...prevState,
        login: {
          ...prevState.login,
          error
        }
      }))
    }
    if (field === "email") {
      const email = formState.email.value;
      let error = null;

      if (email && !EMAIL_REGEX.test(email)) {
        error = MESSAGES.INVALID_EMAIL;
      } else if (previousEmail.toLowerCase() !== email.toLowerCase()) {
        const emailAlreadyExists = await UserService.verificarExistenciaEmail(email);

        if (emailAlreadyExists) {
          error = MESSAGES.EMAIL_ALREADY_EXISTS;
        }
      }

      setFormState((prevState) => ({
        ...prevState,
        email: {
          ...prevState.email,
          error
        }
      }))

    }
  }

  const onChange = (field, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value
    }))
  }

  const onAddFarmAndRetreatLink = ({ farm, retreats }) => {
    const newFarmAndRetreatLinks = { ...formState.farmAndRetreatLinks }
    newFarmAndRetreatLinks.value.push({ farm, retreats })
    newFarmAndRetreatLinks.error = null

    onChange("farmAndRetreatLinks", newFarmAndRetreatLinks)
  };

  const onRemoveFarmAndRetreatLink = (farmId) => {
    const newFarmAndRetreatLinks = {
      ...formState.farmAndRetreatLinks,
      value: [...formState.farmAndRetreatLinks.value].filter(
        (farmAndRetreatLink) => farmAndRetreatLink.farm.id !== farmId
      )
    }

    onChange("farmAndRetreatLinks", newFarmAndRetreatLinks);
  };

  const _validateForm = async () => {
    const { name, login, email, farmAndRetreatLinks } = formState

    let newState = { ...formState }
    let error = null

    if (!name.value) {
      error = MESSAGES.FILL_ALL_REQUIRED_FIELDS
      newState = {
        ...newState,
        name: {
          ...newState.name,
          error: MESSAGES.REQUIRED
        }
      }
    }

    if (!LOGIN_REGEX.test(login.value)) {
      error = MESSAGES.FILL_ALL_REQUIRED_FIELDS
      newState = {
        ...newState,
        login: {
          ...newState.login,
          error: MESSAGES.REQUIRED
        }
      }
    }

    if (!login.value || login.error) {
      error = MESSAGES.FILL_ALL_REQUIRED_FIELDS
      newState = {
        ...newState,
        login: {
          ...newState.login,
          error: login.error || MESSAGES.REQUIRED
        }
      }
    }

    if (!email.value || email.error) {
      error = MESSAGES.FILL_ALL_REQUIRED_FIELDS
      newState = {
        ...newState,
        email: {
          ...newState.email,
          error: email.error || MESSAGES.REQUIRED
        }
      }
    }

    if (email.value && !EMAIL_REGEX.test(email.value)) {
      error = MESSAGES.FILL_ALL_REQUIRED_FIELDS
      newState = {
        ...newState,
        email: {
          ...newState.email,
          error: MESSAGES.INVALID_EMAIL
        }
      }
    }

    if (!farmAndRetreatLinks.value.length) {
      error = MESSAGES.FILL_ALL_REQUIRED_FIELDS
      newState = {
        ...newState,
        farmAndRetreatLinks: {
          ...newState.farmAndRetreatLinks,
          error: MESSAGES.REQUIRED
        }
      }
    }

    const [loginAlreadyExists, emailAlreadyExists] = await Promise.all(
      [
        UserService.verificarExistenciaLogin(login.value),
        UserService.verificarExistenciaEmail(email.value)
      ]
    )

    if (!isEdition && loginAlreadyExists) {
      error = MESSAGES.FILL_ALL_REQUIRED_FIELDS
      newState = {
        ...newState,
        login: {
          ...newState.login,
          error: MESSAGES.LOGIN_ALREADY_EXISTS
        }
      }
    }

    if (previousEmail.toLowerCase() !== email.value.toLowerCase() && emailAlreadyExists) {
      error = MESSAGES.FILL_ALL_REQUIRED_FIELDS
      newState = {
        ...newState,
        email: {
          ...newState.email,
          error: MESSAGES.EMAIL_ALREADY_EXISTS
        }
      }
    }

    if (error) {
      setFormState(newState)
      saveToStorage(newState)
      throw new Error(error)
    }
  }

  const onSubmit = async () => {
    try {
      loadingShown(true)

      await _validateForm()

      const userData = {
        Nome: formState.name.value,
        Login: formState.login.value.trim().toLowerCase(),
        Email: formState.email.value.trim().toLowerCase(),
      }

      const permissions = {
        acessa_aplicativo: formState.acessaAplicativo.value,
        acessa_plataforma: formState.acessaPlataforma.value,
        estoque_final: formState.estoqueFinal.value,
        importar_mapas: formState.importaMapas.value,
        exportar_importar_csv_pasto: formState.importaCSV.value,
        cadastrar_ciclo_forragem: formState.cicloForragem.value
      }

      const farmAndRetreatLinks = formState.farmAndRetreatLinks.value


      const currentClientId = sessionStorage.getItem("clienteSelecionado")

      if (isEdition) {
        await UserService.editaUsuarioEVinculos({ ...userData, IdUsuario: userId }, permissions, farmAndRetreatLinks, currentClientId)
      } else {
        await UserService.cadastrarUsuarioEVinculos(userData, permissions, farmAndRetreatLinks, currentClientId)
      }

      notifySuccess({ mensagem: isEdition ? MESSAGES.USER_UPDATED_SUCCESS : MESSAGES.USER_CREATED_SUCCESS })
      cleanStorage()
      history.replace("/gestao-de-usuarios")
    } catch (error) {
      window.scrollTo(0, 0)
      notifyError({ mensagem: error.message || MESSAGES.UNEXPECTED_ERROR })
    } finally {
      loadingShown(false)
    }
  };

  const onCancel = () => {
    cleanStorage()
    history.replace("/gestao-de-usuarios");
  }

  useEffect(() => {
    const loadUserData = async () => {
      setIsLoading(true)
      const currentClientId = sessionStorage.getItem("clienteSelecionado")
      const user = await UserService.buscarUsuario(userId, currentClientId)

      setPreviousEmail(user.email)
      setFormState({
        name: {
          value: user.nome
        },
        login: {
          value: user.login
        },
        email: {
          value: user.email
        },
        acessaAplicativo: {
          value: hasPermission('acessa_aplicativo', user.permissoes)
        },
        acessaPlataforma: {
          value: hasPermission('acessa_plataforma', user.permissoes)
        },
        estoqueFinal: {
          value: hasPermission('estoque_final', user.permissoes)
        },
        importaMapas: {
          value: hasPermission('importar_mapas', user.permissoes)
        },
        importaCSV: {
          value: hasPermission('exportar_importar_csv_pasto', user.permissoes)
        },
        cicloForragem: {
          value: hasPermission('cadastrar_ciclo_forragem', user.permissoes)
        },
        farmAndRetreatLinks: {
          value: user.fazendas.reduce((acc, farm) => {
            const farmAndRetreatLink = {
              farm: {
                id: farm.idFazenda,
                name: farm.nome
              },
              retreats: user.retiros
                .filter(retreat => retreat.idFazenda === farm.idFazenda)
                .map(retreat => ({
                  id: retreat.idRetiro,
                  name: retreat.nome
                }))
            }
            acc.push(farmAndRetreatLink)
            return acc
          }, [])
        }
      })

      setIsLoading(false)
    }

    if (userId) loadUserData()
  }, [])

  return {
    isLoading,
    formState,
    onChange,
    onBlur,
    onAddFarmAndRetreatLink,
    onRemoveFarmAndRetreatLink,
    onSubmit,
    onCancel,
  };
}

