import React from "react";
import { Button as ButtonMUI, withStyles } from "@material-ui/core";

const Button = ({ classes, onClick, label }) => {
  return (
    <div className={classes.buttonWrapper}>
      <ButtonMUI
        id="button"
        data-testid="button"
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={onClick}
      >
        {label}
      </ButtonMUI>
    </div>
  );
};

const styles = () => {
  return {
    button: {
      borderRadius: "30px",
      fontFamily: "Roboto",
      fontSize: "14px",
      letterSpacing: "1.25px",
      boxShadow: "none!important",
      padding: "10px 48px",
    },
    buttonWrapper: {
      display: "flex",
      flexDirecton: "row",
      justifyContent: "flex-end",
      alignItems: "center",
    },
  };
};

export default withStyles(styles)(Button);
