import React, { useState, useEffect } from "react";
import { Grid, Button, Typography, withStyles } from "@material-ui/core";
import moment from "moment";
import { mensagem } from "./balancoArrobaUtil";
import SelectDropdown from "components/SelectDropdown";

const BalancoArrobaCabecalho = ({ classes, obterDadosBalanco, datas }) => {
  const listaDatasPesagem = datas.map((data) => ({
    label: moment(data.DataPesagem).format("DD/MM/YYYY"),
    value: moment(data.DataPesagem).format("DD/MM/YYYY"),
  }));

  const [dataInicio, setDataInicio] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [erroData, setErroData] = useState(true);
  const [exibeMensagem, setExibeMensagem] = useState(mensagem.selecioneData);

  useEffect(() => {
    const listaLength = listaDatasPesagem.length;
    const ultimaDataPesagem =
      listaLength >= 1 ? listaDatasPesagem[listaLength - 1].value : null;
    const penultimaDataPesagem =
      listaLength >= 2 ? listaDatasPesagem[listaLength - 2].value : null;
    setDataInicio(penultimaDataPesagem);
    setDataFim(ultimaDataPesagem);
  }, [datas]);

  useEffect(() => {
    comparaDatas();
  }, [dataInicio, dataFim, datas]);

  const comparaDatas = () => {
    const dataInicial = (
      listaDatasPesagem.find((dp) => dp.value === dataInicio) || {}
    ).label;
    const dataFinal = (
      listaDatasPesagem.find((dp) => dp.value === dataFim) || {}
    ).label;
    const momentInicial = moment(dataInicial, "DD-MM-YYYY");
    const momentFinal = moment(dataFinal, "DD-MM-YYYY");

    let erroData = false;
    if (momentFinal.isSame(momentInicial)) {
      setExibeMensagem(mensagem.erroMesmaData);
      erroData = true;
    } else if (momentInicial.isAfter(momentFinal)) {
      setExibeMensagem(mensagem.erroDataMax);
      erroData = true;
    } else {
      setExibeMensagem(mensagem.selecioneData);
    }
    setErroData(erroData);
  };

  const selecionaDataInicial = (value) => {
    setDataInicio(value);
  };

  const selecionaDataFinal = (value) => {
    setDataFim(value);
  };

  return (
    <Grid>
      <Typography className={classes.titulo}>Balanço de Arroba</Typography>
      {erroData && (
        <Typography className={classes.alertas}>{exibeMensagem}</Typography>
      )}
      <div className={classes.container}>
        <Grid item xs={3} data-testid="DataInicial">
          <SelectDropdown
            testId="balanco-primeiro-Select"
            label={{ text: "Data Inicial" }}
            options={listaDatasPesagem}
            onChange={selecionaDataInicial}
            value={dataInicio}
          />
        </Grid>
        <Grid item xs={3} data-testid="DataFinal">
          <SelectDropdown
            testId="balanco-segundo-Select"
            label={{ text: "Data Final" }}
            options={listaDatasPesagem}
            value={dataFim}
            onChange={selecionaDataFinal}
          />
        </Grid>
        <Grid item xs={5}>
          <Button
            data-testid="botaoAplicarFiltroBalanco"
            variant="contained"
            color="primary"
            className={classes.botao}
            disabled={erroData}
            onClick={() => {
              obterDadosBalanco(dataInicio, dataFim);
            }}
          >
            Gerar Balanço
          </Button>
        </Grid>
      </div>
    </Grid>
  );
};

const styles = () => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 0",
  },
  botao: {
    height: "36px",
    width: "180px",
    borderRadius: "18px",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1.25px",
    textAlign: "center",
    color: "#ffffff",
  },
  textoPesagem: {
    width: "147px",
    height: "32px",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#eb384c",
  },
  titulo: {
    fontSize: "20px",
    fontWeight: "bold",
    fontStyle: "medium",
    fontStretch: "medium",
    lineHeight: "medium",
    letterSpacing: "medium",
    color: "#333333",
    marginTop: "40px",
    marginBottom: "20px",
  },
  alertas: {
    color: "#ff0102",
    fontFamily: "Roboto",
    marginLeft: 2,
    fontSize: "12px",
  },
});

export default withStyles(styles)(BalancoArrobaCabecalho);
