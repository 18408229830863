import React from "react";
import { FormControl, TextField, withStyles } from "@material-ui/core";

const GoalPlanListFilter = ({ classes, filter, onChangeFilter }) => {
  const onChangeFilterName = (event) => {
    onChangeFilter({ ...filter, name: event.target.value });
  };

  return (
    <FormControl id="buscar" className={classes.searchForm}>
      <TextField
        id="buscarMetas"
        inputProps={{ "data-testid": "goalPlanListFilterNameInput" }}
        value={filter.name}
        autoFocus
        label="BUSCAR"
        className={classes.textField}
        placeholder="Digite o que deseja buscar"
        onChange={onChangeFilterName}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </FormControl>
  );
};

const styles = () => {
  return {
    searchForm: {
      display: "flex",
      flexDirection: "row",
    },
    textField: {
      width: "450px",
      marginRight: 16,
    },
  };
};

export default withStyles(styles)(GoalPlanListFilter);
