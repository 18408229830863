import { AppBar, AppBarConteudoDefault } from "features/layout";
import { NavegacaoTabs } from "features/navegacao";
import FazendaSelecionadaContainer from "features/shared/crossCuttingComponents/fazendaSelecionada/fazendaSelecionadaContainer";
import React from "react";
import { withStyles } from "@material-ui/core";

const AppBarTitle = () => {
  return (
    <FazendaSelecionadaContainer
      render={(fazenda) => {
        const textoSecundarioAppBar = fazenda
          ? fazenda.NomeReduzido
          : "NENHUMA FAZENDA SELECIONADA";
        return <AppBarConteudoDefault tituloAppBar={textoSecundarioAppBar} />;
      }}
    />)
}

const tabs = [{
  label: "Histórico de movimentações de rebanho",
  id: "historico_movimentacao_aplicativo",
  exibir: true
},
{
  label: "Movimentações pendentes",
  id: "movimentacoesPendentes",
  exibir: true
},
]

const getLabelTabById = (id) => {
  return tabs.find(tab => tab.id === id).label
}

const getTabByPath = (path) => {
  const id = path.split("/").pop();
  return getLabelTabById(id);
}


const ContainerMovements = ({ classes, history, match, children }) => {

  const selectedTab = getTabByPath(match.path);

  const clickTab = (event) => {
    history.push(`/${event.currentTarget.id}`);
  }

  return (<>
    <AppBar
      conteudoCustomizadoAppBar={AppBarTitle()}
      preventPadding={true}
    />
    <NavegacaoTabs
      tabs={tabs}
      mudarTab={clickTab}
      estilosTab={{
        fontSize: 16,
        height: 60
      }}
      abaSelecionada={selectedTab}
      styles={
        {
          backgroundColor: "#f8f8f8",
          display: "flex",
        }
      }
      fullWidth={true}
      justify="flex-start"
    />
    <div className={classes.rootPendency}>
      {children}
    </div>
  </>);
};

const styles = () => ({
  rootPendency: {
    paddingTop: "60px"
  }
})

export default withStyles(styles)(ContainerMovements);
