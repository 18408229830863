import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import { TextLabel } from ".";
const tabContent = (props) => {
  const { classes, tituloTab, children, id } = props;
  return (
    <Grid container direction="row" justify="center">
      <Grid container item className={classes.containerPage}>
        <TextLabel
          size={20}
          bold
          typographyProps={{ gutterBottom: true }}
          id={id}
        >
          {tituloTab}
        </TextLabel>
        {children}
      </Grid>
    </Grid>
  );
};

tabContent.defaultProps = {
  id: "",
};

const styles = () => {
  return {
    containerPage: {
      paddingTop: 50,
      width: "100%",
    },
  };
};
export default withStyles(styles)(tabContent);
