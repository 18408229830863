import _ from "lodash";
import BaseService from "./BaseService";
import downloadArquivo from "lib/downloadArquivo";
import jsontocsv from "json2csv";
import { createServiceHandler } from "./ServiceHandler";

const URL_BASE = `${process.env.REACT_APP_ENDPOINT}forragens/api/v1`;

class ForragemService extends BaseService {
  async obterForragensFazenda(fazendaSelecionada) {
    const filter = {
      where: {
        IdFazenda: fazendaSelecionada,
        $and: { Excluido: false },
      },
      include: [
        { model: "Forragem" },
        { model: "PeriodoCicloForragemFazenda" },
      ],
    };
    const url = this.montaURL(
      `${URL_BASE}/ciclos-forragem-fazenda`,
      this.montaFiltro(filter)
    );
    const response = await this.get(url);
    return response;
  }

  async obterForragens() {
    const url = this.montaURL(`${URL_BASE}/forragens`, "");
    const response = await this.get(url);
    return response;
  }

  //FIXME: O service não deve conhecer de csv
  /* istanbul ignore next */
  async listaForragensERealizaDownloadCsv() {
    const tempFilter = {
      fields: this.montaCampos([
        "IdForragem",
        "Nome",
        "DataCriacao",
        "UsuarioCriacao",
      ]),
    };

    const filtro = this.montaURLParametros({
      filter: JSON.stringify(tempFilter),
    });

    const urlServico = this.montaURL(`${URL_BASE}/forragens`, filtro);
    const response = await this.get(urlServico);
    downloadArquivo(
      "Forragens.csv",
      jsontocsv.parse(response.data, { delimiter: ";" })
    );
  }

  /**
   * FIXME: O service não deve conhecer de csv
   */
  /* istanbul ignore next */
  async listaCiclosForragensPorIdFazenda(IdFazenda) {
    const tempFilter = {
      where: { IdFazenda, $and: { Excluido: false } },
      include: [{ model: "PeriodoCicloForragemFazenda" }],
      attributes: ["IdCicloForragemFazenda", "IdForragem"],
    };

    const filtro = this.montaURLParametros({
      filter: JSON.stringify(tempFilter),
    });

    const urlServico = this.montaURL(
      `${URL_BASE}/ciclos-forragem-fazenda`,
      filtro
    );
    const response = await this.get(urlServico);

    const ciclosForragem = [];
    if (response.data.length <= 0) {
      ciclosForragem.push({
        IdCicloForragemFazenda: "",
        IdForragem: "",
        IdPeriodoCicloForragemFazenda: "",
        DiaInicio: "",
        MesInicio: "",
        DiaFim: "",
        MesFim: "",
        AlturaEntrada: "",
        AlturaSaida: "",
        QuantidadeDiasDescanso: "",
      });
    } else {
      response.data.forEach((ciclo) => {
        ciclosForragem.push(
          ...ciclo.PeriodoCicloForragemFazenda.map((periodo) => {
            return {
              ..._.omit(ciclo, ["PeriodoCicloForragemFazenda"]),
              ..._.pick(periodo, [
                "IdPeriodoCicloForragemFazenda",
                "DiaInicio",
                "MesInicio",
                "DiaFim",
                "MesFim",
                "AlturaEntrada",
                "AlturaSaida",
                "QuantidadeDiasDescanso",
              ]),
            };
          })
        );
      });
    }
    downloadArquivo(
      "Ciclos Forragem.csv",
      jsontocsv.parse(ciclosForragem, { delimiter: ";" })
    );
  }

  /**
   * FIXME: O service não deve conhecer de csv
   */
  /* istanbul ignore next */
  async salvaCiclosForragemCsv(objetoCsv, IdFazenda) {
    try {
      const forragensAgrupadas = _.chain(objetoCsv)
        .groupBy('IdForragem')
        .map(f => f);

      for (const grupo of forragensAgrupadas) {
        let NovoCiclo = null;
        let IdForragem = null;
        let IdCicloForragemFazenda = null;
        const PeriodoCicloForragemFazenda = grupo.map(periodo => {
          NovoCiclo = !periodo.IdCicloForragemFazenda;
          IdForragem = periodo.IdForragem;
          IdCicloForragemFazenda = periodo.IdCicloForragemFazenda;
          const periodoFormatado = _.omit(periodo, [
            'IdCicloForragemFazenda',
            'IdForragem',
            'IdPeriodoCicloForragemFazenda',
          ]);
          return { ...periodoFormatado };
        });
        let ciclo = {
          IdFazenda,
          IdForragem,
          PeriodoCicloForragemFazenda,
        };

        const verbo = NovoCiclo ? "post" : "patch";
        await this.upsertCicloForragem(ciclo, verbo, IdCicloForragemFazenda);
      }
      return { sucesso: true };
    } catch (error) {
      const mensagemErro =
        error.response && error.response.data && error.response.data.mensagem
          ? error.response.data.mensagem
          : "Erro ao tentar salvar ciclos forragem";
      throw new Error(mensagemErro);
    }
  }

  async upsertCicloForragem(cicloForragem, verbo, idEntidade) {
    const idURI = verbo === "patch" ? `/${idEntidade}` : "";
    return await createServiceHandler()[verbo](
      `${URL_BASE}/ciclos-forragem-fazenda${idURI}`,
      cicloForragem
    );
  }
}

export default new ForragemService();
