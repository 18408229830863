import React from 'react';
import Icon from '@material-ui/core/Icon';
import PropTypes from 'prop-types';
import { ListItem, ListItemIcon, ListItemText, withStyles } from '@material-ui/core';

export const itemMenuNavegacao = props => {
  const { icone, idComponent, descricaoItem, classes, navegar } = props;
  const getProspComumListItem = () => {
    return {
      className: classes.listItemText,
      primaryTypographyProps: {
        variant: 'body2'
      }
    };
  };

  const prospListItem = getProspComumListItem();
  return (
    <ListItem button key={idComponent} className={classes.heightConteudo} onClick={navegar}>
      <ListItemIcon>
        <Icon fontSize="small">{icone}</Icon>
      </ListItemIcon>
      <ListItemText primary={descricaoItem} {...prospListItem} />
    </ListItem>
  );
};
const styles = {
  list: {
    width: 250
  },
  heightConteudo: {
    height: 70
  },
  listItemText: {
    padding: 0
  }
};

itemMenuNavegacao.propTypes = {
  icone: PropTypes.string,
  idComponent: PropTypes.string,
  descricaoItem: PropTypes.string,
  navegar: PropTypes.func
};

export default withStyles(styles)(itemMenuNavegacao);
