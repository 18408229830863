import { LISTA_PASTOS_PRIME } from "redux/actions/actionsConstants";

export const initialState = {
  PastosPrime: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LISTA_PASTOS_PRIME:
      return {
        ...state,
        PastosPrime: action.payload.ListaPastosPrime,
      };
    default:
      return state;
  }
};
