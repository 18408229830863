import constants from "constants/global";
import dayjs from "dayjs";
import { utc } from "dayjs";
import pendencyService from "features/pendency/pendencyService";

dayjs.extend(utc);

export const checkPendencyConsistency = async (
  pastureOrigin,
  pastureDestiny,
  animalCategoriesOrigin,
  animalCategoriesDestiny,
  number,
  operationDate,
  movementTypeId
) => {
  try {
    const dateTocheck = operationDate.toISOString();
    const response = await pendencyService.checkConsistency(
      pastureOrigin,
      pastureDestiny,
      animalCategoriesOrigin,
      animalCategoriesDestiny,
      number,
      dateTocheck,
      movementTypeId
    );
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const validateData = async (
  movementTypeId,
  animalCategoriesOrigin,
  animalCategoriesDestiny,
  pastureOrigin,
  pastureDestiny,
  quantityToValidate,
  adjustedDateToChecks,
  setShowLoading = () => {},
  setIsSaveEnabled = () => {},
  setVerificationMessage = () => {},
  verificationMessage = "",
  dateInTheFuture
) => {
  if (quantityToValidate > 0) {
    setShowLoading(true);
    const response = await checkPendencyConsistency(
      pastureOrigin,
      pastureDestiny,
      animalCategoriesOrigin,
      animalCategoriesDestiny,
      quantityToValidate,
      adjustedDateToChecks,
      movementTypeId
    );
    if (response.status === 200 && !dateInTheFuture) {
      setIsSaveEnabled(true);
      setVerificationMessage(verificationMessage);
    } else {
      setIsSaveEnabled(false);
      setVerificationMessage("");
    }
    setShowLoading(false);
  }
};

export function generateMessageHistory(movement, pasture, category) {
  if (
    movement.DestinoNomePasto === undefined ||
    movement.DestinoNomePasto === ""
  ) {
    return [movement.TipoOperacao, movement.Quantidade];
  }

  if (
    movement.OrigemNomePasto === undefined ||
    movement.OrigemNomePasto === ""
  ) {
    return [movement.TipoOperacao, movement.Quantidade];
  }

  // Movimentação entre pastos
  if (movement.OrigemNomePasto !== movement.DestinoNomePasto) {
    if (
      movement.OrigemNomePasto === pasture.Nome ||
      movement.OrigemNomePasto === pasture.NomeReduzido
    ) {
      return [
        `Movimentação para o pasto ${movement.DestinoNomePasto}`,
        movement.Quantidade * -1,
      ];
    }
    return [
      `Movimentação recebida do pasto ${movement.OrigemNomePasto}`,
      movement.Quantidade,
    ];
  }

  // Troca de categoria
  if (movement.OrigemNomeCategoriaAnimal === category.Nome) {
    return [
      `Troca de categoria para ${movement.DestinoNomeCategoriaAnimal}`,
      movement.Quantidade * -1,
    ];
  }

  return [
    `Troca de categoria de ${movement.OrigemNomeCategoriaAnimal}`,
    movement.Quantidade,
  ];
}

export function parseMovementsHistory(
  movementsHistory,
  pasture,
  category,
  timezoneFazenda
) {
  return movementsHistory
    .sort((a, b) => (dayjs(a.DataISO).isBefore(dayjs(b.DataISO)) ? -1 : 1))
    .map((movement) => {
      const [operacao, quantidade] = utils.generateMessageHistory(
        movement,
        pasture,
        category
      );

      return {
        data: dayjs(movement.DataISO)
          .utcOffset(timezoneFazenda)
          .format(constants.altDateTimeFormat),
        tipo: operacao,
        quantidade: quantidade,
      };
    });
}

export const utils = {
  checkPendencyConsistency,
  validateData,
  generateMessageHistory,
  parseMovementsHistory,
};

export default utils;
