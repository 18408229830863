import React, { useState } from "react";
import { Grid, Button, withStyles } from "@material-ui/core";
import { DatePicker } from "../../shared/components";
import ImprimirIcon from "features/shared/components/icons/ImprimirIcon";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import SelectDropdown from "components/SelectDropdown";

export const ConciliacaoCabecalho = (props) => {
  const {
    classes,
    list,
    onSelectFormChange,
    onButtonClick,
    categoriaSelecionada,
    componentRef,
    dataInicio,
    dataFim,
    handlerDataInicio,
    handlerDataFim,
    dataInicioConciliacao,
  } = props;

  const [erroData, setErroData] = useState(false);

  const dataString = moment(dataInicio).format("DD/MM/YYYY");
  const dataStringMinimo = moment(dataInicioConciliacao).format("DD/MM/YYYY");
  const erroMaxDateMessage = "A data não pode ser maior que a data atual";
  const erroMinDateMessage = `A data não pode ser menor que ${dataString}`;
  const erroPrimeiroMinDateMessage = `A data não pode ser menor que ${dataStringMinimo}, pois ela é o início da conciliação.`;

  /*
    essa funcão é necessaria para o controle do estado do botao
    pois o OnError do datepicker é uma funcao colateral e nao muda o status da pagina
  */
  const verificaDataFormatoErrado = (e, tipo = "fim") => {
    if (e.search("_") >= 0 || e === "") {
      setErroData(true);
    } else {
      const dataSuperior = moment(dataInicio).subtract(1, "days");
      if (
        moment(e, "DD-MM-YYYY").isBefore(dataSuperior) ||
        moment(e, "DD-MM-YYYY").isAfter(dataFim)
      ) {
        setErroData(true);
      } else if (
        tipo === "fim" &&
        moment(e, "DD-MM-YYYY").isBefore(dataInicio)
      ) {
        setErroData(true);
      } else if (
        tipo === "inicio" &&
        moment(e, "DD-MM-YYYY").isAfter(dataFim)
      ) {
        setErroData(true);
      } else {
        setErroData(false);
      }
    }
  };

  const printaTabela = useReactToPrint({
    content: () => componentRef,
  });

  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid item xs={3} className={classes.areaSelectInput}>
        <SelectDropdown
          id="concilio-categoriaAnimal"
          label={{ text: "Categoria" }}
          options={list}
          value={categoriaSelecionada}
          onChange={onSelectFormChange}
        />
      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={5} style={{ position: "relative" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "36px",
          }}
        >
          <div style={{ marginRight: "5%", height: "80px" }}>
            <DatePicker
              id="concilio-primeiro-DataPicker"
              minDate={dataInicioConciliacao}
              maxDate={dataFim}
              onChangeDate={(e) => handlerDataInicio(e)}
              onChangeInputValue={(e) => verificaDataFormatoErrado(e, "inicio")}
              label="Data Inicial"
              maxDateMessage={erroMaxDateMessage}
              minDateMessage={erroPrimeiroMinDateMessage}
              value={dataInicio}
            />
          </div>
          <div style={{ height: "80px" }}>
            <DatePicker
              id="concilio-segundo-DataPicker"
              minDate={dataInicio}
              onChangeDate={(e) => handlerDataFim(e)}
              onChangeInputValue={(e) => verificaDataFormatoErrado(e, "fim")}
              label="Data Final"
              maxDateMessage={erroMaxDateMessage}
              minDateMessage={erroMinDateMessage}
              value={dataFim}
            />
          </div>
          <div className={classes.botaoAplicativoWrapper}>
            <Button
              id="botaoAplicarFiltroConciliacao"
              onClick={() => {
                onButtonClick(dataInicio, dataFim);
              }}
              variant="contained"
              color="primary"
              disabled={erroData}
              className={classes.botaoAplicado}
            >
              editar data
            </Button>
          </div>
          <div style={{ height: "80px" }}>
            <Button
              id="botaoImprimirConciliacao"
              onClick={() => printaTabela()}
              variant="contained"
              color="primary"
              className={classes.botaoImprimir}
            >
              <ImprimirIcon />
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

const styles = () => {
  return {
    botaoImprimir: {
      float: "right",
      height: "36px",
      marginTop: "10px",
      borderRadius: "18px",
      fontFamily: "Roboto",
      fontSize: "14px",
      minWidth: 56,
      fontWeight: "500",
      letterSpacing: "1.25px",
      textAlign: "center",
      color: "#ffffff",
      boxShadow: "none",
      marginLeft: "16px",
    },
    botaoAplicativoWrapper: {
      height: "80px",
      position: "relative",
      marginLeft: "16px",
    },
    botaoAplicado: {
      float: "right",
      height: "36px",
      marginTop: "10px",
      borderRadius: "18px",
      fontFamily: "Roboto",
      fontSize: "13px",
      fontWeight: "500",
      minWidth: 128,
      boxShadow: "none",
      letterSpacing: "1.25px",
      textAlign: "center",
      color: "#ffffff",
    },
    areaSelectInput: {
      paddingBottom: "0.480em",
      marginLeft: "-0.5%",
    },
    areaFirstDatePicker: { marginRight: "-2.5em", marginLeft: "2.5em" },
    areaSecondDatePicker: { marginRight: "-3.5em", marginLeft: "3.5em" },
  };
};

export default withStyles(styles)(ConciliacaoCabecalho);
