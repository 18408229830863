import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Paper, Grid, withStyles } from "@material-ui/core";
import BalancoArrobaCabecalho from "./balancoArrobaCabecalho";
import BalancoArrobaTabelaTotal from "./balancoArrobaTabelaTotal";
import BalancoArrobaTabelaGmd from "./balancoArrobaTabelaGmd";
import BalancoArrobaTabelaCategorias from "./balancoArrobaTabelaCategoria";
import ModalAlerta from "../../shared/components/modalAlerta";

const BalancoArrobaPage = (props) => {
  const {
    classes,
    balancosFazenda,
    dataInicioConciliacao,
    permiteBalanco,
    obterDadosBalanco,
    datas,
    obterDatas,
    history,
    exibirModalFaltaInformacao,
    desativaModalFaltaData,
    exibirModalFaltaDataInicial,
  } = props;

  const fecharModalDataInicial = () => {
    desativaModalFaltaData();
    // Trocar para aba Categoria animal
    history.go(0);
  };
  useEffect(() => {
    const fetchData = async () => obterDatas();
    fetchData();
  }, []);

  if (!permiteBalanco) {
    return (
      <ModalAlerta
        textoDescricao={
          "<b>Para utilizar o balanço de Arroba deve-se sempre fazer a entrada e saída de animais pelo curral. </b> No momento, seu sistema está configurado para permitir a criação de lotes em qualquer pasto.<b> Por favor entre em contato com o suporte </b> para alterar essa configuração se caso desejar utilizar a conciliação de rebanho."
        }
        aoFechar={() => history.replace("/farol")}
      />
    );
  }

  if (exibirModalFaltaInformacao) {
    return (
      <ModalAlerta
        textoDescricao={
          "Para realizar o Balanço de Arroba entre em <b>cadastros da fazenda</b> e acesse <b>Pesagem por Categoria</b> para cadastrar as pesagens."
        }
        aoFechar={() => history.replace("/farol")}
      />
    );
  }

  if (!dataInicioConciliacao || exibirModalFaltaDataInicial) {
    return (
      <ModalAlerta
        id="modal-bloqueio-operacao-curral"
        textoDescricao={
          "Para realizar o Balanço de Arroba entre na aba de <b>categoria animal</b> e escolha a Data inicial desejada para a <b>conciliação.</b>"
        }
        aoFechar={fecharModalDataInicial}
      />
    );
  }
  return (
    <Paper container data-testid="balancoArrobaPage" className={classes.root}>
      <Grid container className={classes.containerPage}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.smallerArea}
        >
          <BalancoArrobaCabecalho
            dataInicioConciliacao={dataInicioConciliacao}
            obterDadosBalanco={obterDadosBalanco}
            datas={datas}
          />
        </Grid>
        {Object.keys(balancosFazenda).length > 0 && (
          <>
            <Grid className={classes.areaTabelaTotal}>
              <BalancoArrobaTabelaTotal balancosFazenda={balancosFazenda} />
            </Grid>
            <Grid className={classes.areaTabelaGmd}>
              <BalancoArrobaTabelaGmd balancosFazenda={balancosFazenda} />
            </Grid>
            <Grid className={classes.areaTabelaCategoria}>
              <BalancoArrobaTabelaCategorias
                balancosFazenda={balancosFazenda}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
};

const styles = {
  root: {
    width: "100%",
    height: "100%",
    overflowX: "auto",
    zIndex: -1,
    padding: "4px 24px 4px 24px",
    flexgrow: 1,
    minHeight: window.innerHeight,
  },
  containerPage: {
    flexgrow: 1,
    height: "100%",
    backgroundColor: "#fff",
    margin: "0 auto",
    width: "90%",
  },
  smallerArea: {
    width: "100%",
    height: "auto",
    marginBottom: "21px",
  },
  areaTabelaTotal: {
    width: "100%",
    height: "auto",
    align: "center",
    marginBottom: "34px",
  },
  areaTabelaGmd: {
    width: "150%",
    height: "auto",
    align: "center",
    marginBottom: "34px",
  },
  areaTabelaCategoria: {
    width: "100%",
    height: "auto",
    align: "center",
    marginBottom: "34px",
  },
};

export default withStyles(styles)(withRouter(BalancoArrobaPage));
