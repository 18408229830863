import { idsFarois } from "../../../../../constants/idsFarois";
export const obtemValorIconeFarois = (
  farolPasto,
  TipoIconeExibido,
  idFarolSelecionado,
  pasto
) => {
  return obtemIconePorFarol(
    farolPasto,
    TipoIconeExibido,
    idFarolSelecionado,
    pasto
  );
};

const obtemIconePorFarol = (
  farolPasto,
  TipoIconeExibido,
  idFarolSelecionado,
  pasto
) => {
  if (farolPasto && farolPasto.Valor) {
    switch (idFarolSelecionado) {
      case idsFarois.cochoAbastecido:
        return obterIconCochoAbastecido(farolPasto);
      case idsFarois.rebanhoPresenca:
        return obterIconPresencaRebanho(farolPasto);
      case idsFarois.mortes:
        return obterIconeNascimentoMorte(farolPasto);
      case idsFarois.nascimentos:
        return obterIconeNascimentoMorte(farolPasto);
      default:
        return { exibeIcone: false, valorExibido: 0, cor: "#FFFFFF" };
    }
  }
  return { exibeIcone: false, valorExibido: 0, cor: "#FFFFFF" };
};

const obterIconPresencaRebanho = (farolPasto) => {
  const cor = farolPasto.Cor;
  const valorExibido = farolPasto.Valor.QuantidadeLotes;
  const exibeIcone = farolPasto.Valor.QuantidadeLotes ? true : false;
  return { valorExibido, exibeIcone, cor };
};

const obterIconCochoAbastecido = (farolPasto) => {
  const cor = farolPasto.Cor;
  const valorExibido = farolPasto.Valor.Quantidade;
  const exibeIcone = farolPasto.Valor.Quantidade ? true : false;
  return { valorExibido, exibeIcone, cor };
};

const obterIconeNascimentoMorte = (farolPasto) => {
  const cor = farolPasto.Cor;
  const valorExibido = farolPasto.Valor.Quantidade;
  const exibeIcone = farolPasto.Valor.Quantidade ? true : false;
  return { valorExibido, exibeIcone, cor };
};

export const renderTextOrComponent = (textComponent) => {
  if (typeof textComponent === "function") {
    if (textComponent() === undefined || textComponent() === null)
      return undefined;
    if (typeof textComponent() === "string") {
      return textComponent();
    }
    return () => textComponent();
  }
  return textComponent;
};
