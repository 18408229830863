/* eslint-disable react/jsx-no-duplicate-props*/
import { InputAdornment, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import supplementationService from "../../../supplementationService";
import formulaService from "features/formulacao/formulaService";
import { DateTimePicker } from "material-ui-pickers";
import CalendarIcon from "@material-ui/icons/Today";
import dayjs from "dayjs";
import { validate } from "../../shared/validators";
import _ from "lodash";
import { useDialogConfirm } from "../../shared/dialogConfirm";
import SkeletonLoading from "features/shared/components/SkeletonLoading";
import NumberField from "features/shared/components/NumberField";
import SelectDropdown from "components/SelectDropdown";
import CustomTextField from "features/shared/components/CustomTextField";
import CustomLabel from "features/shared/components/CustomLabel";
import { parseDateToFarmTimezone } from "features/collect/supplementation/supplementationUtils";

function SupplementationFormCard({
  forceDisableAll,
  supplementationId,
  supplementationFetched,
  onChangeValues = () => {},
  onFormValidation = () => {},
  classes,
}) {
  const [supplementation, setSupplementation] = useState({});
  const [formulas, setFormulas] = useState([]);
  const [values, _setValues] = useState({});
  const [errors, setErrors] = useState({});

  const [unit, setUnit] = useState("");
  const {
    render: renderFormulaCreepAlert,
    setVisible: setVisibleFormulaCreepAlert,
    setText: setTextFormulaCreepAlert,
  } = useDialogConfirm({
    title: "Edição de coleta",
    handleCancel: () => {
      _setValues({ values, formulaId: supplementation.formulaId });
    },
  });

  const runValidation = (valuesToValidate) => {
    if (forceDisableAll) return;
    const errorsForm = validate(valuesToValidate);
    if (Object.keys(errorsForm).length) {
      onFormValidation(true);
    } else {
      onFormValidation(false);
    }
    setErrors(errorsForm);
  };

  const checkValueFormula = () => {
    if (
      values.formulaId &&
      Number(values.formulaId) !== Number(supplementation.formulaId)
    ) {
      const formulaSelecionada = formulas.find(
        (el) => el.IdFormula === values.formulaId
      );
      if (!formulaSelecionada) return;
      if (formulaSelecionada.FormulaCreep && !supplementation.troughCreep) {
        setTextFormulaCreepAlert(
          `Atenção, você está tentando abastecer um cocho normal com a formulação ${formulaSelecionada.Nome} que é do tipo CREEP deseja continuar? `
        );
        setVisibleFormulaCreepAlert(true);
      } else if (
        !formulaSelecionada.FormulaCreep &&
        supplementation.troughCreep
      ) {
        setTextFormulaCreepAlert(
          `Atenção, você está tentando abastecer um cocho CREEP com a formulação ${formulaSelecionada.Nome} que não é do tipo CREEP deseja continuar? `
        );
        setVisibleFormulaCreepAlert(true);
      }
    }
  };

  useEffect(checkValueFormula, [
    values.formulaId,
    supplementation.formulaId,
    supplementation.troughCreep,
  ]);

  const getBasicFields = () => ({
    formulaId: supplementation.formulaId,
    amountTroughBags: supplementation.amountTroughBags,
    collectionDate: supplementation.collectionDate,
  });

  const setValue = (key) => (event) => {
    const basicFields = getBasicFields();
    const value = event.target.value;
    const newValues = { ...basicFields, ...values, [key]: value };
    if (key === "formulaId") {
      const formula = formulas.find((f) => f.IdFormula === value);
      if (formula) {
        if (formula.Granel) {
          setUnit("kg");
        } else {
          setUnit("sacos");
        }
      } else {
        newValues.amountTroughBags = "";
      }
    }
    runValidation(newValues);
    onChangeValues(newValues);
    _setValues(newValues);
  };

  const setValueDate = (key) => (date) => {
    const basicFields = getBasicFields();
    const newValues = {
      ...basicFields,
      ...values,
      [key]: dayjs(date).toISOString(),
    };
    runValidation(newValues);
    onChangeValues(newValues);
    _setValues({ ...newValues, [key]: date });
  };

  const sortAndConvert = (items, group) => {
    return items
      .sort((a, b) => (a.Nome.toUpperCase() < b.Nome.toUpperCase() ? -1 : 1))
      .map((formula) => ({
        value: formula.IdFormula,
        label: formula.Nome,
        group,
      }));
  };

  const prepareFormulasOption = (formulas) => {
    if (formulas.length) {
      const result = _.partition(formulas, (el) => el.Excluido);
      const inactive = sortAndConvert(result[0], "Inativas");
      const active = sortAndConvert(result[1], "Ativas");
      return [...(active || []), ...(inactive || [])];
    } else {
      return [{ value: null, label: "NÃO INFORMADA" }];
    }
  };

  const prepare = async () => {
    let _supplementation = supplementationFetched;

    if (!_supplementation) {
      const result = await supplementationService.getSupplementation(
        supplementationId
      );
      _supplementation = result.data;
    }

    _setValues({
      formulaId: _supplementation.formulaId,
      amountTroughBags: _supplementation.amountTroughBags,
      collectionDate: _supplementation.collectionDate,
    });

    const farmId = _supplementation.farmId;

    if (!forceDisableAll) {
      const formulas = await formulaService.listarFormulasPorFazenda(
        farmId,
        true
      );
      setFormulas(formulas.data.body);
    } else {
      setFormulas([
        {
          IdFormula: _supplementation.formulaId,
          Nome: _supplementation.formulaName || "NÃO INFORMADA",
        },
      ]);
    }
    setSupplementation(_supplementation);

    _supplementation.amountTroughBags = (
      _supplementation.amountTroughBags || ""
    )
      .toString()
      .replace(".", ",");

    runValidation(_supplementation);

    onChangeValues({
      formulaId: _supplementation.formulaId,
      amountTroughBags: _supplementation.amountTroughBags,
      collectionDate: _supplementation.collectionDate,
    });

    if (_supplementation.formulaFractionable) {
      setUnit("kg");
    } else {
      setUnit("sacos");
    }
  };

  useEffect(() => {
    prepare();
  }, [supplementationId, supplementationFetched]);

  const formulasSelect = prepareFormulasOption(formulas);

  const checkTroughType = () => {
    if (supplementation.creepCollection) {
      return "Cocho Creep";
    }
    if (supplementation.sharedTrough) {
      return "Cocho Compartilhado";
    }
    return "Cocho padrão";
  };

  const checkQuality = () => {
    if (Number.isInteger(supplementation.quality)) {
      if (supplementation.quality > 0) {
        return "Boa";
      }
      if (supplementation.quality === 0) {
        return "Ruim";
      }
    }
    return "Não informada";
  };

  const checkTroughScore = () => {
    if (supplementation.volume <= 1) {
      return "Vazio";
    }
    if (supplementation.volume >= 3) {
      return "Cheio";
    }
    return "Adequado";
  };

  const checkTroughAccess = () => {
    if (supplementation.goodAccess > 0) {
      return "Bom";
    }
    return "Ruim";
  };

  const checkTroughStructure = () => {
    if (supplementation.goodStructure > 0) {
      return "Boa";
    }
    return "Ruim";
  };

  const checkDate = () => {
    if (values.collectionDate) {
      return parseDateToFarmTimezone(values.collectionDate);
    }
    if (supplementation.collectionDate) {
      return parseDateToFarmTimezone(supplementation.collectionDate);
    }
    return dayjs().toDate();
  };

  const renderSkeleton = () => {
    return (
      <SkeletonLoading width="100%" height="365px" borderRadius="16px 16px" />
    );
  };

  if (!supplementation.id || !formulas.length) {
    return renderSkeleton();
  }

  const onChangeFormula = (value) =>
    setValue("formulaId")({ target: { value } });

  return (
    <div className={classes.container}>
      <h2 className={classes.title}>
        {supplementation.formulaName || "FORMULAÇÃO NÃO INFORMADA"}
      </h2>
      <div className={classes.content}>
        <div className={classes.fieldLine}>
          <CustomTextField
            classes={classes}
            testId={`inputTroughType`}
            id="troughType"
            label="Tipo de Cocho"
            value={checkTroughType()}
            flex={3}
          />
          <CustomTextField
            classes={classes}
            testId={`inputTroughScore`}
            id="troughScore"
            label="Escore de Cocho"
            value={checkTroughScore()}
          />
          <CustomTextField
            classes={classes}
            testId={`inputTroughQuality`}
            id="troughQuality"
            label="Qualidade do suplemento no cocho"
            value={checkQuality()}
          />
          <CustomTextField
            classes={classes}
            testId={`inputTroughReplenished`}
            id="troughReplenished"
            label="O cocho foi abastecido?"
            value={supplementation.replenished ? "Sim" : "Não"}
          />
        </div>
        <div className={classes.fieldLine}>
          <div className={classes.fieldFlex} style={{ flex: 3 }}>
            <SelectDropdown
              id="formulaId"
              data-testid="inputTroughAccess"
              isDisabled={forceDisableAll || !supplementation.replenished}
              label={{
                text: "Formulação",
                required: true,
              }}
              error={!!errors.formula}
              helperText={errors.Coberto}
              options={formulasSelect}
              groupBy={(option) => option.group}
              emptyText="NÃO INFORMADA"
              value={
                forceDisableAll ? supplementation.formulaId : values.formulaId
              }
              onChange={onChangeFormula}
            />
          </div>
          <div className={classes.fieldFlex} style={{ flex: 2 }}>
            <NumberField
              data-testid={"inputQuantity"}
              id="quantity"
              InputLabelProps={{ shrink: true }}
              className={classes.textField}
              label={
                <CustomLabel
                  classes={classes}
                  label="Quant. abastecida"
                  tooltip={
                    forceDisableAll
                      ? ""
                      : "Não é possivel alterar a unidade de medida, somente a quantidade."
                  }
                />
              }
              value={
                forceDisableAll
                  ? supplementation.amountTroughBags
                  : values.amountTroughBags
              }
              disabledNullText={"NÃO ABASTECIDO"}
              onChange={setValue("amountTroughBags")}
              inputProps={{
                style: {
                  paddingBottom: "13px",
                },
              }}
              InputProps={{
                endAdornment: values.formulaId ? (
                  <InputAdornment position="end">{unit}</InputAdornment>
                ) : null,
              }}
              error={!!errors.amountTroughBags}
              helperText={errors.amountTroughBags}
              disabled={
                forceDisableAll ||
                !values.formulaId ||
                !supplementation.replenished ||
                false
              }
            />
          </div>
          <CustomTextField
            classes={classes}
            testId={`inputQuality`}
            id="quality"
            label="Acesso ao cocho"
            value={checkTroughAccess()}
          />
          <CustomTextField
            classes={classes}
            testId={`inputTroughStructure`}
            id="troughStructure"
            label="Estrutura do cocho"
            value={checkTroughStructure()}
          />
        </div>

        <div className={classes.fieldLine}>
          <CustomTextField
            classes={classes}
            flex={3}
            testId={`inputPastureName`}
            id="pastureName"
            label="Pasto"
            value={
              supplementation.pastureName
                ? supplementation.pastureName.join(", ")
                : ""
            }
          />
          <CustomTextField
            classes={classes}
            flex={3}
            testId={`inputCreationUser`}
            id="creationUser"
            label="Responsável pela coleta"
            value={`${supplementation.creationUser}`}
          />
          <div className={classes.fieldFlex} style={{ flex: 2 }}>
            <DateTimePicker
              okLabel={"Selecionar"}
              cancelLabel={"Cancelar"}
              data-testid={`inputCollectionDate`}
              InputLabelProps={{ shrink: true }}
              ampm={false}
              disableFuture
              disabled={forceDisableAll}
              className={classes.datePickerField}
              label={
                <CustomLabel
                  classes={classes}
                  label="Data da coleta"
                  tooltip={
                    forceDisableAll
                      ? ""
                      : "Para editar a data de uma coleta, só será possível selecionar uma data que esteja em um período de 60 dias, anterior ao dia atual."
                  }
                />
              }
              value={checkDate()}
              onChange={setValueDate("collectionDate")}
              maxDate={new Date()}
              minDate={
                forceDisableAll
                  ? new Date(0)
                  : dayjs().subtract(60, "days").toDate()
              }
              format="DD/MM/YYYY HH:mm"
              minDateMessage="Para editar a data de uma coleta, só será possível selecionar uma data que esteja em um período de 60 dias, anterior ao dia atual"
              maxDateMessage="Não é possível selecionar uma data no futuro."
              invalidDateMessage="Data Inválida"
              helperText={forceDisableAll ? "" : errors.collectionDate}
              error={!forceDisableAll && errors.collectionDate}
              inputProps={{
                style: {
                  paddingBottom: "13px",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarIcon />
                  </InputAdornment>
                ),
                style: {
                  paddingBottom: "5px",
                },
              }}
            />
          </div>

          <div className={classes.fieldFlex} style={{ flex: 1 }}></div>
        </div>
      </div>
      {renderFormulaCreepAlert()}
    </div>
  );
}

const styles = {
  container: {
    padding: "32px",
    borderRadius: "16px",
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2)",
    border: "1px solid #e5e5ea",
    backgroundColor: "#fff",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  textField: {
    backgroundColor: "#fff",
    width: "100%",
  },
  datePickerField: {
    width: "100%",
  },
  title: {
    marginBottom: "32px",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "bold",
    letterSpacing: "0.5px",
    color: "#444",
  },
  fieldLine: {
    display: "flex",
    flexDirection: "row",
    gap: "32px",
  },
  fieldFlex: {},
};

export default withStyles(styles)(SupplementationFormCard);
