import React from "react";
import MovimentacaoPage from "./movimentacaoPage";
import * as MovimentacaoOperations from "../redux/movimentacaoOperations";
import * as categoriaOperations from "../../categoria/redux/categoriaOperations";
import * as tipoMovimentacaoOperations from "../redux/tipoMovimentacaoOperations";
import { montarTextoMapaRebanhoGA } from "../../../shared/utils";
import { connect } from "react-redux";

export const movimentacaoContainer = (props) => {
  enviarDadosGA(props.fazendaSelecionada);
  return (
    <MovimentacaoPage
      id="MovimentacaoPage"
      fazendaSelecionada={props.fazendaSelecionada}
      obterMovimentacoes={props.obterMovimentacoes}
      obterTiposMovimentacao={props.obterTiposMovimentacao}
      criarMovimentacao={props.criarMovimentacao}
      deletarMovimentacao={props.deletarMovimentacao}
      atualizarMovimentacao={props.atualizarMovimentacao}
      movimentacoes={props.movimentacoes}
      tiposMovimentacao={props.tiposMovimentacao}
      obterCategoriasFazenda={props.obterCategoriasFazenda}
      categorias={props.categorias}
    />
  );
};


const enviarDadosGA = (idFazenda) => {
  montarTextoMapaRebanhoGA(idFazenda, "Entrada e Saida");
};

export const mapStateToProps = (state) => {
  return {
    movimentacoes: state.movimentacoes.movimentacoes,
    fazendaSelecionada: state.fazenda.FazendaSelecionada,
    categorias: state.categoria.categorias,
    tiposMovimentacao: state.tipoMovimentacao.TiposMovimentacao,
  };
};

const mapDispatchToProps = {
  obterMovimentacoes: MovimentacaoOperations.obterMovimentacoes,
  criarMovimentacao: MovimentacaoOperations.criarMovimentacao,
  atualizarMovimentacao: MovimentacaoOperations.atualizarMovimentacao,
  deletarMovimentacao: MovimentacaoOperations.deletarMovimentacao,
  obterCategoriasFazenda: categoriaOperations.obterCategoriasFazenda,
  obterTiposMovimentacao: tipoMovimentacaoOperations.obterTiposMovimentacao,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(movimentacaoContainer);
