import {
  SET_ABA_SELECIONADA
} from "./configuracaoReducer";

export const setAbaSelecionada = abaSelecionada => {
  return {
    type: SET_ABA_SELECIONADA,
    payload: abaSelecionada
  };
};
