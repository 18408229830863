import {
  SELECIONAR_PASTO,
  SALVAR_REBANHO,
  ERRO_VALIDAR_REBANHO,
} from "redux/actions/actionsConstants";

export const initialState = {
  RebanhoSelecionado: null,
  Rebanho: null,
  erros: {
    Rebanho: {
      QtdAnimais: "",
      PesoMedido: "",
      DataPesagem: "",
      PesoVisual: "",
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECIONAR_PASTO:
      if (action.payload.rebanho) {
        return {
          ...state,
          RebanhoSelecionado: action.payload.IdPasto,
          Rebanho: action.payload.rebanho,
        };
      } else {
        return initialState;
      }
    case SALVAR_REBANHO:
      return {
        ...state,
        RebanhoSelecionado: action.payload.IdPasto,
        Rebanho: action.payload,
      };
    case ERRO_VALIDAR_REBANHO:
      return {
        ...state,
        erros: {
          ...state.erros,
          Rebanho: { ...initialState.erros.Rebanho, ...action.payload },
        },
      };
    default:
      return state;
  }
};
