import React from "react";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import {
  withStyles,
  Button,
  Paper,
  Typography,
  FormControl,
  TextField,
  InputAdornment,
  InputLabel,
  Input,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import moment from "moment";
import InfoIcon from "@material-ui/icons/Info";
import ClearIcon from "@material-ui/icons/Clear";
import "moment/locale/pt-br";
import SelectDropdown from "components/SelectDropdown";

moment.locale("pt-br");

const IngredientRegisterFormPage = ({
  classes,
  ingredienteEditado,
  ingredientesPadrao,
  setEditando,
  editando,
  lancaCompra,
  controleData,
  setControleData,
  nomeIngrediente,
  setNomeIngrediente,
  valorIngrediente,
  ingrediente,
  setIngrediente,
  erroCodigo,
  camposPreenchido,
  textoCusto,
  textoData,
  validarDataCusto,
  cancelaLimpa,
  acaoFormulario,
  isInternalUser,
  selectedproductId,
  handleChangeProductIdList,
  filteredProductList,
  validaDadosEditados,
  handleProductChange,
  onEndReached = () => {},
}) => {
  const regexData = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
  const erroCodigoString =
    "Código já cadastrado, insira\n um novo ou edite o\n ingrediente existente.";

  const onSubmit = () => {
    if (editando && isInternalUser && !lancaCompra) {
      validaDadosEditados();
    } else {
      acaoFormulario();
    }
  };

  const productListOptions = filteredProductList
    .map((product) => ({
      value: product.id,
      label: `${product.code} ${product.name}`,
      code: product.code,
    }))
    .filter((product) =>
      ingredienteEditado && ingredienteEditado.Produto
        ? product.id !== ingredienteEditado.Produto.id
        : true
    );

  const materiaPrimaOptions = (ingredientesPadrao.items || []).map(
    (ingrediente) => ({
      value: ingrediente.Nome,
      label: ingrediente.Nome,
    })
  );

  if (ingredienteEditado && ingredienteEditado.Produto) {
    productListOptions.unshift({
      value: ingredienteEditado.Produto.id,
      label: `${ingredienteEditado.Produto.code} ${ingredienteEditado.Produto.name}`,
      code: ingredienteEditado.Produto.code,
    });
  }

  return (
    <>
      {editando && (
        <Paper className={classes.paperContainer}>
          <Typography className={classes.paperTitulo}>
            {lancaCompra
              ? "LANÇAR COMPRA"
              : ingredienteEditado
              ? "Editar Cadastro"
              : "Novo Cadastro"}
          </Typography>
          <div className={classes.camposWrapper}>
            {isInternalUser && (
              <SelectDropdown
                isSearchable
                testId="productId-select"
                tooltipMessage="Aqui estão listados todos os códigos PA que não estão vinculados a um ingrediente."
                isDisabled={lancaCompra}
                options={productListOptions}
                value={selectedproductId}
                onChange={handleProductChange}
                onPaginate={onEndReached}
                onSearch={handleChangeProductIdList}
                error={erroCodigo}
                helperText={erroCodigo ? erroCodigoString : ""}
                renderOptionComponent={(props, option) => (
                  <div {...props} style={{ display: "flex" }}>
                    <span>
                      <b>{option.code}</b>
                      {option.label.replace(option.code, "")}
                    </span>
                  </div>
                )}
                label={{
                  text: "CÓDIGO DO INGREDIENTE (PA)",
                }}
                styles={{
                  maxWidth: "300px",
                }}
                overrideStrings={{
                  noOptions:
                    "Não encontramos nenhum resultado para a busca informada. Por favor tente novamente.",
                }}
              />
            )}
            <SelectDropdown
              id="formIngrediente"
              testId="ingrediente-select"
              isSearchable
              isDisabled={ingredienteEditado || lancaCompra}
              label={{ text: "MATÉRIA PRIMA", required: true }}
              options={materiaPrimaOptions}
              value={ingrediente}
              onChange={setIngrediente}
              styles={{
                width: "300px",
              }}
            />
            <FormControl
              id="nomeIngrediente"
              className={classes.formControl}
              disabled={lancaCompra}
            >
              <InputLabel
                shrink
                className={classes.ingredienteLabel}
                htmlFor="ingrediente-select"
              >
                <div>NOME DO INGREDIENTE*</div>
                <Tooltip
                  title={
                    "É o nome escolhido pela fazenda para identificação do ingrediente."
                  }
                  placement="top"
                  arrow
                >
                  <InfoIcon className={classes.iconeInfo} placement="right" />
                </Tooltip>
              </InputLabel>
              <Input
                className={classes.defaultPadding}
                id="nomeIngrediente"
                required={true}
                focused
                placeholder={"DIGITE UM NOME"}
                value={nomeIngrediente}
                inputProps={{
                  "data-testid": "nome-ingrediente",
                  style: { overflow: "hidden", textOverflow: "ellipsis" },
                }}
                onChange={({ target: { value } }) => setNomeIngrediente(value)}
              />
            </FormControl>
            <FormControl className={classes.campoData}>
              <InputLabel shrink htmlFor="ingrediente-select">
                {textoData}
              </InputLabel>
              <MuiPickersUtilsProvider
                moment={moment}
                locale={"pt-br"}
                utils={MomentUtils}
              >
                <DatePicker
                  className={classes.defaultPadding}
                  disableFuture
                  keyboard
                  mask={(value) => (value ? regexData : [])}
                  disableOpenOnEnter
                  id="data-compra-DataPicker"
                  data-testid="data-compra-DataPicker"
                  onChange={(newData) => validarDataCusto(newData)}
                  invalidDateMessage={
                    controleData !== "" ? "O Formato da data é inválido" : ""
                  }
                  minDateMessage="A data selecionada é inválida"
                  maxDateMessage="A data selecionada é inválida"
                  format="DD/MM/YYYY"
                  placeholder="dd/mm/aaaa"
                  style={{
                    width: "100%",
                    marginTop: "18px",
                  }}
                  value={controleData}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
            {controleData && (
              <IconButton
                className={classes.botaoLimparFiltro}
                edge="end"
                size="small"
                data-testid="botao-limpar-data"
                onClick={() => setControleData(null)}
              >
                <ClearIcon />
              </IconButton>
            )}
            <FormControl
              id="formValor"
              className={classes.formControl}
              style={{ width: 200 }}
            >
              <TextField
                className={classes.defaultPadding}
                id="valorIngrediente"
                InputLabelProps={{
                  shrink: true,
                }}
                focused
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
                // eslint-disable-next-line
                inputProps={{
                  "data-testid": "valor-ingrediente",
                }}
                type={"number"}
                label={textoCusto}
                value={valorIngrediente}
                onChange={({ target: { value } }) =>
                  validarDataCusto(null, value)
                }
              />
            </FormControl>
          </div>
          <div className={classes.botoesWrapper}>
            <Button
              id="botao-cancelar-ingrediente"
              className={classes.botaoCancelar}
              onClick={() => cancelaLimpa()}
            >
              CANCELAR
            </Button>
            <Button
              id="botao-salvar-ingrediente"
              data-testid="botao-salvar-ingrediente"
              variant="contained"
              color="primary"
              className={classes.botaoSalvar}
              onClick={onSubmit}
              disabled={erroCodigo || camposPreenchido}
            >
              SALVAR
            </Button>
          </div>
        </Paper>
      )}
      <div className={classes.botaoWrapper}>
        <Button
          id="botao-novo-ingrediente"
          variant="contained"
          color="primary"
          className={classes.botaoNovo}
          onClick={() => setEditando(true)}
          disabled={editando || lancaCompra}
        >
          NOVO INGREDIENTE
        </Button>
      </div>
    </>
  );
};

const styles = (theme) => {
  return {
    ingredientesFormContainer: {
      width: "100%",
    },
    botaoWrapper: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    ingredienteLabel: {
      display: "flex",
      alignItems: "center",
    },
    botaoNovo: {
      height: "36px",
      borderRadius: "30px",
      fontFamily: "Roboto",
      fontSize: "14px",
      letterSpacing: "1.25px",
      boxShadow: "none!important",
      margin: "40px 0 17px 0",
    },
    botoesWrapper: {
      marginTop: 40,
    },
    iconeInfo: {
      fontSize: 16,
      position: "relative",
      left: 8,
      color: theme.palette.primary.main,
    },
    botaoSalvar: {
      height: "36px",
      width: 112,
      borderRadius: "30px",
      fontFamily: "Roboto",
      fontSize: "14px",
      letterSpacing: "1.25px",
      boxShadow: "none!important",
    },
    botaoCancelar: {
      height: "36px",
      borderRadius: "30px",
      fontFamily: "Roboto",
      fontSize: "14px",
      letterSpacing: "1.25px",
      boxShadow: "none!important",
      color: "#8e8e93",
      marginRight: "17px",
    },
    paperContainer: {
      minHeight: 210,
      marginTop: 40,
      borderRadius: 16,
      padding: "24px 34px",
      display: "flex",
      flexDirection: "column",
    },
    paperTitulo: {
      textTransform: "uppercase",
      fontFamily: "Roboto",
      fontSize: 12,
      letterSpacing: 0.5,
      color: "#666",
      fontWeight: 500,
    },
    botaoLimparFiltro: {
      maxHeight: 40,
      maxWidth: 40,
      marginTop: 15,
      marginLeft: 4,
    },
    camposWrapper: {
      marginTop: 16,
      width: "100%",

      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      rowGap: "16px",
      columnGap: "32px",
    },
    campoData: {
      minWidth: 145,
      maxWidth: 238,
    },
    formControl: {
      width: "100%",
      minWidth: 160,
      maxWidth: 500,
      color: "#666666",
    },
    dropdownSearchInput: {
      padding: "12px 20px",
      border: "none",
      width: "100%",
      fontSize: "14px",
      fontFamily: "Roboto",
    },
    dropDownMenuItem: {
      textTransform: "uppercase",
      whiteSpace: "normal",
      lineHeight: "normal",
      fontFamily: "Roboto",
      fontSize: "14px",
      color: "#666666",
    },
    dropdownNotFound: {
      textTransform: "none",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "10px 16px",
      fontFamily: "Roboto",
      fontSize: "14px",
      color: "#666666",
    },
    selectPlaceholder: {
      textTransform: "uppercase",
      color: "#666666",
    },
    disabledPlaceholder: {
      color: "rgba(102, 102, 102, 0.5)",
    },
    disabledInput: {
      color: "rgba(102, 102, 102, 0.5)",
      textTransform: "uppercase",
    },
    selectProduct: {
      width: "100%",
      maxWidth: "300px",
      maxHeight: "300px",
      color: "#666666",
    },
    selectContainer: {
      display: "flex",
      flexDirection: "row",
    },
    defaultPadding: {
      padding: "2px 0",
    },
  };
};

export default withStyles(styles)(IngredientRegisterFormPage);
