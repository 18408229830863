import React from "react";
import { Tooltip, withStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { Grid, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { MESES_ANO } from "../ganhoPesoUtils";
import DeleteIcon from "@material-ui/icons/Delete";
import GanhoPesoTableForm from "./ganhoPesoTableForm";

export const GanhoPesoForm = (props) => {
  const { classes } = props;

  const onChangeCurve = (event, mes) =>
    props.handleChangeMes(mes.Nome, event.target.value);

  return (
    <div classes={classes.resumoWrapper}>
      <Grid item>
        <Typography className={classes.tituloGanhoPesoForm} gutterBottom>
          {props.curva.IdCurvaGanhoPeso ? "Editar Curva" : "Nova Curva"}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.subtitulo} gutterBottom>
          Insira as informações da curva
        </Typography>
      </Grid>
      <Grid item>
        <FormControl className={classes.textoCadastro}>
          <TextField
            value={props.curva.Nome}
            autoFocus
            id={"adornment-nome"}
            required
            label={"Nome da curva"}
            error={props.erroNome}
            helperText={props.erroNome ? "Erro - Nome já cadastrado" : ""}
            onChange={props.handleChangeNomeCurva}
          />
        </FormControl>
        <Grid item>
          <Typography className={classes.avisoMedias} gutterBottom>
            Projeção do ganho de peso (kg/dia). Para calcular a média mensal,
            são utilizados como referência 30 dias.
          </Typography>
        </Grid>s
        <Grid item>
          <GanhoPesoTableForm
            months={MESES_ANO}
            curves={props.curva}
            onChangeCurve={onChangeCurve}
          />
        </Grid>
      </Grid>
      <Grid item className={classes.botoesEmLinha}>
        <Button
          id="habilitarCurva"
          color="primary"
          className={classes.corpoBotoesForm}
          onClick={() => props.habilitarCurva(false)}
        >
          Cancelar
        </Button>
        <Tooltip title={props.mensagemBotaoDesabilitado ? <Typography className={classes.textoTooltip}>{props.mensagemBotaoDesabilitado}</Typography> : "" }  placement="right-end" >
          <span>
            <Button
              id="botaoAdicionar"
              disabled={props.habilitarBotao()}
              variant="contained"
              color="primary"
              onClick={() => props.adicionarCurva(props.curva)}
              className={classes.corpoBotoesForm}
            >
              {props.curva.IdCurvaGanhoPeso ? 'Salvar' : 'Adicionar'}
            </Button>
          </span>
        </Tooltip>
        {props.curva.IdCurvaGanhoPeso ? (
          <Button
            onClick={() => props.abreDialogExclusao(props.curva)}
            className={classes.botaoExcluir}
          >
            <DeleteIcon style={{ marginRight: "0.4rem" }} />
            <Typography color="error">Excluir Curva</Typography>
          </Button>
        ) : null}
      </Grid>
    </div>
  );
};
const styles = () => {
  return {
    textoCadastro: {
      width: 200,
      marginBottom: 16,
    },
    resumoWrapper: {
      height: 112,
      backgroundColor: "#fafafa",
    },
    textoMes: {
      marginRight: "30px",
      width: "5%",
    },
    corpoBotoesForm: {
      marginTop: "36px",
      marginRight: "20px",
      boxShadow: "none!important",
    },
    botaoExcluir: {
      marginTop: "36px",
      marginLeft: "auto",
      color: "#FF0000",
    },
    botoesEmLinha: {
      display: "flex",
    },
    tituloGanhoPesoForm: {
      fontFamily: "Roboto",
      fontSize: 20,
      fontWeight: "bold",
      color: "#212121",
    },
    subtitulo: {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: "bold",
      color: "#757575",
      marginTop: "16px",
    },
    avisoMedias:{
      fontSize: "14px",
      fontWeight: "bold",
      fontStretch: "normal",
      color: "#333",
      marginTop: "16px",
    },
    textoTooltip: {
      color: "#ffffff",
      fontSize: 14,
      maxWidth: "220px",
      maxHeight: "59px",
      padding: "9px 9px 9px 16px",
    },
  };
};

export default withStyles(styles)(GanhoPesoForm);
