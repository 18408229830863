import _ from "lodash";
import BaseService from "./BaseService";

const URL_BASE = `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/pastos`;

class CochoService extends BaseService {
  async getCochos(idFazenda, filter) {
    try {
      const tempFilter = {
        include: "PastoCochos",
        where: { IdFazenda: idFazenda, ...filter },
      };
      const filtro = this.montaFiltro(tempFilter);
      const parametrosURL = filtro;
      const urlServico = this.montaURL(
        `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/cochos`,
        parametrosURL
      );
      let response = await this.get(urlServico);
      return response.data;
    } catch (erro) {
      return null;
    }
  }

  async exiteCochoNoPasto(IdPasto, cochoCreep = false) {
    try {
      const urlServico = `${process.env.REACT_APP_ENDPOINT}pastos/api/v2/pastos/${IdPasto}/cocho`;
      const response = await this.get(urlServico);
      const cochoAtivo = response.data.find(
        (c) => c.CochoCreep === cochoCreep && !c.PastoCocho.DataValidade
      );
      if (response.data) {
        return { cochoExiste: true, cochoAtivo };
      } else {
        return { cochoExiste: false };
      }
    } catch (erro) {
      return { cochoExiste: false };
    }
  }

  async getCochosPorIdPasto(IdPasto) {
    try {
      const tempFilter = { where: { IdPasto } };
      const filtro = this.montaFiltro(tempFilter);
      const parametrosURL = filtro;
      const urlServico = this.montaURL(
        `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/cochos`,
        parametrosURL
      );
      let response = await this.get(urlServico);
      return response;
    } catch (erro) {
      return { cochoExiste: false };
    }
  }

  async atualizaMultiplosCochos(cochosAtualizar) {
    try {
      const cochosAtualizados = await Promise.all(
        cochosAtualizar.map(async (cochoAtualizar) => {
          const IdPasto = cochoAtualizar.IdPasto;
          cochoAtualizar = _.omit(cochoAtualizar, ["IdPasto"]);
          let urlServico = `${URL_BASE}/${IdPasto}/cocho`;
          const { cochoExiste } = await this.exiteCochoNoPasto(
            IdPasto,
            cochoAtualizar.CochoCreep
          );
          if (!cochoExiste) {
            return await this.post(urlServico, cochoAtualizar);
          } else {
            return await this.put(urlServico, cochoAtualizar);
          }
        })
      );
      return cochosAtualizados;
    } catch (erro) {
      console.log(erro);
      return { erro };
    }
  }

  async getCochoCreepPorIdFazenda(idFazenda) {
    if (idFazenda) {
      const filtro = JSON.stringify({
        where: {
          IdFazenda: idFazenda,
          CochoCreep: 1,
          Excluido: 0,
        },
      });

      const urlServico = `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/cochos?filter=${filtro}`;
      return this.get(urlServico);
    }
  }
}

export default new CochoService();
