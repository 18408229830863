import React from 'react';
import Slide from '@material-ui/core/Slide';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  withStyles
} from '@material-ui/core/';

const transitionDialog = props => {
  return <Slide direction="up" {...props} />;
};



export const DialogoConfirmacaoAcao = props => {
  const {
    tituloDialog,
    textoDialog,
    dialogAberto,
    aoFecharDialog,
    cancelarAcaoDialog,
    objetoSelecionado,
    excluir,
    textClasses,
    dialogClasses
  } = props;


  const confirmar = async (Id) => {
    await excluir(Id);
    cancelarAcaoDialog();
  }

  return (
    <Dialog
      TransitionComponent={transitionDialog}
      open={dialogAberto}
      onClose={aoFecharDialog}
      aria-labelledby="dialogTitle"
      aria-describedby="dialogDescription"
    >
      <div className={(dialogClasses || null)}>
        <DialogTitle id="dialogTitle">{tituloDialog}</DialogTitle>
        {textoDialog && (
          <DialogContent>
            <DialogContentText
              className={(textClasses || null)}
              id="dialogDescription"
            >
              {textoDialog}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions style={{ marginBottom: 12 }}>
          <Button id="btnCancelaAcaoDialog" onClick={cancelarAcaoDialog} autoFocus>
            Cancelar
          </Button>
          <Button id="btnConfirmaAcaoDialog" onClick={() => confirmar(objetoSelecionado)} color="primary">
            Sim
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

DialogoConfirmacaoAcao.defaultProps = {
  tituloDialog: '',
  dialogAberto: false
};
const styles = theme => ({
  conteudoDialog: {
    maxWidth: 340
  }
});
export default withStyles(styles)(DialogoConfirmacaoAcao);
