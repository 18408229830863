import { SET_FILTER, CLEAR_FILTER } from "./actionsConstants";

export const clearFilter = () => {
  return {
    type: CLEAR_FILTER,
    payload: {},
  };
};

export const setFilter = (filter) => {
  return {
    type: SET_FILTER,
    payload: filter,
  };
};
