import React from "react";
import { compose } from "recompose";
import { Tab, Tabs, Grid, Paper, withStyles, withTheme } from "@material-ui/core";

export const navegacaoTabs = ({
  classes,
  theme,
  tabs,
  abaSelecionada,
  mudarTab,
  tabsCustomizadas,
  estilosTab,
  materialUITabProps,
  styles,
  fullWidth = false,
  justify = "center",
}) => {

  const tabsMapeadas = () => {
    return tabs.map((tab) => {
      return tab.exibir ? (
        <Tab
          key={tab.id}
          label={tab.label}
          id={tab.id}
          TouchRippleProps={{
            id: tab.id,
          }}
          name={tab.id}
          itemID={tab.id}
          style={{
            color:
              abaSelecionada === tab.label
                ? theme.palette.primary.main
                : COR_TAB_DEFAULT,
            ...estilosTab,
          }}
          {...materialUITabProps}
        />
      ) : null;
    });
  };

  return (
    <React.Fragment>
      <div className={classes.tabs} id="navegacao-tabs">
        <Paper square style={{ ...styles }}>
          <Grid container direction="row" justify={justify}>
            <Grid container direction="row" item lg={12} justify={justify}>
              <Tabs
                boxShadow={5}
                value={abaSelecionada}
                onChange={mudarTab}
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                fullWidth={fullWidth}
              >
                {tabsMapeadas()}
                {tabsCustomizadas}
              </Tabs>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </React.Fragment>
  );
};

navegacaoTabs.defaultProps = {
  tabs: [],
  abaSelecionada: "",
  mudarTab: () => { },
  tabsCustomizadas: [],
  estilosTab: {},
  styles: {},
};

const styles = {
  tabs: {
    position: "fixed",
    flexGrow: 1,
    top: `75px`,
    width: "100%",
    backgroundColor: "#ccc",
    zIndex: 30,
  },

};

export const COR_TAB_DEFAULT = "#757575";

const navegacaoTabsIgual = (prevProps, nextProps) => {
  return prevProps.abaSelecionada === nextProps.abaSelecionada;
};
const componenteMemoizado = compose(
  withTheme(),
  withStyles(styles)
)(navegacaoTabs);
export default React.memo(componenteMemoizado, navegacaoTabsIgual);
