import * as notificacaoActions from "../../../redux/actions/notificacaoActions";
import * as clienteActions from "./clienteActions";
import * as clienteUtils from "../clienteUtils";

export const setarClienteSelecionado = (idCliente) => (dispatch) => {
  try {
    dispatch(clienteActions.setClienteSelecionado(idCliente));
  } catch (error) {
    const mensagem = clienteUtils.obterMensagemErro(error);
    dispatch(notificacaoActions.notifyError({ mensagem }));
  }
};
