import * as fechamentoConsumoActions from './fechamentoConsumoActions';
import FechamentoConsumoManager from '../fechamentoConsumoManager';
import FormulaManager from '../../formulacao/formulaManager';
import {
  notificacaoActions
} from '../../notificacao/redux';

export const obtemFechamentoConsumo = (
  IdFazenda,
  _,
  dataInicio,
  dataFim,
  formulaSelecionada,
  retirosSelecionados
) => async dispatch => {
  try {
    const formulas2 = await FormulaManager.obterFormulasFazenda(IdFazenda, true);
    const consumos = await FechamentoConsumoManager.obtemFechamentoConsumo(
      IdFazenda,
      formulas2,
      dataInicio,
      dataFim,
      formulaSelecionada,
      retirosSelecionados
    );
    dispatch(fechamentoConsumoActions.setConsumosFormulas(consumos.Formulas, formulas2, consumos.DiariasSemFormula));
  } catch (error) {
    dispatch(notificacaoActions.notificaErro(error.message));
  }
};
