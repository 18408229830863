export default (theme) => ({
  titulo: {
    alignSelf: "flex-start",
    flexBasis: "100%",
    textAlign: "start",
  },
  container: {
    flexGrow: 1,
    height: "100%",
    maxHeight: "550px",
  },
  textField: {
    width: "100%",
  },
  button: {
    float: "right",
  },
  telaInfomacao: {
    display: "flex",
    flexDirection: "column",
    margin: "auto 40px",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  iconeKML: {
    margin: "89px 89px 40px 89px",
  },
  iconeMapa: {
    margin: "60px 40px 90px 40px",
    height: "220px",
    objectFit: "contain",
    borderRadius: "50%",
    padding: "60px",
  },
  iconeMapaGroup: {
    marginTop: "70px",
    marginBottom: "40px",
  },
  mensagem: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.38",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#212121",
  },
  botaoPrimarioKml: {
    width: "280px",
    height: "40px",
    marginTop: "38px",
  },
  botaoSecundarioKml: {
    width: "280px",
    height: "40px",
    backgroundColor: "#ffffff",
    marginTop: "20px",
    color: "#000000",
    boxShadow:
      "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
  },
  leftIcon: {
    position: "absolute",
    left: "16px",
  },
});
