import BaseService from "services/BaseService";

export const PENDENCY_URL = `${process.env.REACT_APP_ENDPOINT}herds/pendency`;
export const LIVESTOCK_URL = `${process.env.REACT_APP_ENDPOINT}herds/livestock`;
export const MOVEMENTS_URL = `${process.env.REACT_APP_ENDPOINT}herds/movements`;

class PendencyService extends BaseService {
  async getPendenciesByFarmId(page, size, farmId, filterDate) {
    const urlPendenciesByFarmId = `${PENDENCY_URL}/byFarmId/${farmId}`;
    return this.get(urlPendenciesByFarmId, { page, size, filterDate });
  }

  async getPendenciesByMovementGrouper(movementGrouper) {
    const urlPendenciesByMovementGrouper = `${PENDENCY_URL}/byGrouper/${movementGrouper}`;
    const response = await this.get(urlPendenciesByMovementGrouper);
    return response.data.data;
  }

  async getCountAnimalCategoryInPasture(
    animalCategoryId,
    pastureId,
    todayDate
  ) {
    let query = {
      animalCategoryId: animalCategoryId,
      pastureId: pastureId,
      checkDate: todayDate,
    };
    const parametrosURL = this.montaURLParametros(query);
    const urlServico = this.montaURL(`${LIVESTOCK_URL}/count/`, parametrosURL);
    const response = await this.get(urlServico);

    return response.data;
  }

  async getLastPendencyByFarmId(farmId) {
    const urlLastPendencyByFarmId = `${PENDENCY_URL}/moreRecentByFarmId/${farmId}`;
    return this.get(urlLastPendencyByFarmId);
  }

  async checkConsistency(
    pastureOrigin,
    pastureDestiny,
    animalCategoriesOrigin,
    animalCategoriesDestiny,
    number,
    operationDate,
    movementTypeId
  ) {
    let query = {
      pastureOrigin: pastureOrigin,
      pastureDestiny: pastureDestiny,
      animalCategoriesOrigin: animalCategoriesOrigin,
      animalCategoriesDestiny: animalCategoriesDestiny,
      number: number,
      operationDate: operationDate,
      movementTypeId: movementTypeId,
    };

    const parametrosURL = this.montaURLParametros(query);
    const urlServico = this.montaURL(`${MOVEMENTS_URL}/check`, parametrosURL);
    const response = await this.get(urlServico);
    return response;
  }

  /**
   * Aplica pendências de movimentação de pasto e categorias de animais.
   *
   * @param {Object} params - Parâmetros para a aplicação de pendências.
   * @param {number} params.pastureOrigin - Id de origem do pasto.
   * @param {number} params.pastureDestiny - Id de destino do pasto.
   * @param {number} params.animalCategoryOrigin - Id de origem da categoria.
   * @param {number} params.animalCategoryDestiny - Id de destino da categoria.
   * @param {...Object} data - Restante dos campos que é possivel editar na pendencia ex (number).
   * @returns {Promise}
   */
  async applyPendency({
    pastureOrigin,
    pastureDestiny,
    animalCategoryOrigin,
    animalCategoryDestiny,
    ...data
  }) {
    const movementFields = {
      pastures: {
        origin: pastureOrigin,
        destiny: pastureDestiny,
      },
      animalCategories: {
        origin: animalCategoryOrigin,
        destiny: animalCategoryDestiny,
      },
      ...data,
    };

    const urlApplyPendency = `${PENDENCY_URL}/apply`;
    const res = this.post(urlApplyPendency, movementFields);
    return res;
  }

  async removePendency(movementGrouper) {
    const urlRemovePendency = `${PENDENCY_URL}/${movementGrouper}`;
    const response = await this.delete(urlRemovePendency);

    return response;
  }
}

export default new PendencyService();
