import React from "react";
import { connect } from "react-redux";
import HistoricoMovimentacaoAppPage from "./historicoMovimentacaoAppPage";
import * as historicoMovimentacaoOperations from "../redux/historicoMovimentacaoOperations";
import { enviaDadosGAPorRotulo } from "../../../shared/utils";

export const HistoricoMovimentacaoAppContainer = ({
  filtrosHistoricoMovimentacao,
  obtemFiltrosHistoricoMovimentacao,
  obterHistoricoMovimentacaoFazenda,
  historicoMovimentacaoSemPaginacao,
  historicoMovimentacao,
  atualizaFiltroSelecionado,
  filtrosSelecionados,
  estadoModal,
  alterarEstadoModal,
  fazenda,
  history,
  match
}) => {
  return (
    <HistoricoMovimentacaoAppPage
      id="historicoMovimentacaoAppPage"
      filtrosHistoricoMovimentacao={filtrosHistoricoMovimentacao}
      obtemFiltrosHistoricoMovimentacao={obtemFiltrosHistoricoMovimentacao}
      obterHistoricoMovimentacaoFazenda={obterHistoricoMovimentacaoFazenda}
      historicoMovimentacaoSemPaginacao={historicoMovimentacaoSemPaginacao}
      historicoMovimentacao={historicoMovimentacao}
      atualizaFiltroSelecionado={atualizaFiltroSelecionado}
      filtrosSelecionados={filtrosSelecionados}
      estadoModal={estadoModal}
      alterarEstadoModal={alterarEstadoModal}
      enviaDadosGAPorRotulo={enviaDadosGAPorRotulo}
      fazenda={fazenda}
      history={history}
      match={match}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    filtrosHistoricoMovimentacao:
      state.historicoMovimentacao.filtrosHistoricoMovimentacao,
    historicoMovimentacao: state.historicoMovimentacao.historico,
    historicoMovimentacaoSemPaginacao:
      state.historicoMovimentacao.historicoSemPaginacao,
    filtrosSelecionados: state.historicoMovimentacao.filtrosSelecionados,
    estadoModal: state.historicoMovimentacao.estadoModal,
    fazenda: state.fazenda,
  };
};

const mapDispatchToProps = {
  obtemFiltrosHistoricoMovimentacao:
    historicoMovimentacaoOperations.obterFiltrosHistoricoMovimentacao,
  obterHistoricoMovimentacaoFazenda:
    historicoMovimentacaoOperations.obterHistoricoMovimentacaoFazenda,
  atualizaFiltroSelecionado:
    historicoMovimentacaoOperations.atualizaFiltroSelecionado,
  alterarEstadoModal: historicoMovimentacaoOperations.alterarEstadoModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoricoMovimentacaoAppContainer);
