import React from "react";
import { Grid, Card, CardContent, Divider } from "@material-ui/core";
import TextLabel from "../../../shared/components/textLabel";
import DetalhesFarolMarcador from "../../views/components/detalhesFarolMarcador";

export const COR_CARD_PRIMARIO = "#775bbc";
export const COR_CARD_SECUNDARIO = "#5aa5ef";

const cardDetalhes = (props) => {
  const { tituloCard, primaryCard } = props;
  const corCard = primaryCard ? COR_CARD_PRIMARIO : COR_CARD_SECUNDARIO;
  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Card>
          <Grid
            container
            direction="row"
            alignContent="flex-start"
            alignItems="center"
            style={{ padding: "20px 25px" }}
          >
            <DetalhesFarolMarcador color={corCard} />
            <TextLabel bold label={tituloCard} />
          </Grid>
          <Divider />
          <CardContent style={{ padding: "10px 25px " }}>
            <Grid container>
              {props.subTituloCard}
              {props.children}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export const cardIgual = (prevProps, nextProps) => {
  const tituloIgual = prevProps.tituloCard === nextProps.tituloCard;
  const tipoIgual = prevProps.primaryCard === nextProps.primaryCard;
  return tituloIgual && tipoIgual;
};
export default React.memo(cardDetalhes, cardIgual);
