import { MarkerF } from "@react-google-maps/api";
import React from "react";

const Marcador = ({ position, url, onClick, ...props }) => {
  return (
    <MarkerF
      id={`marker${props.id}`}
      position={{ lat: position.lat, lng: position.lng }}
      options={{
        id: props.id,
        title: props.title && "" + props.title,
        icon: {
          url,
          scaledSize: { height: 32, width: 32 },
        },
      }}
      onClick={onClick}
      {...props}

    />
  );
};

const diffToMemoize = (prevProps, nextProps) => {
  const position = prevProps.position === nextProps.position;
  const url = prevProps.url === nextProps.url;
  const visible = prevProps.visible === nextProps.visible;
  return (position && url && visible);
};

export default React.memo(Marcador, diffToMemoize);
