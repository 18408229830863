import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import { TableRow, TableSortLabel, withStyles } from "@material-ui/core";

export const headCells = [
  {
    id: "finalDate",
    label: "Período",
  },
  {
    id: "active",
    label: "Situação",
  },
];

const PlanningListTableHeader = ({
  classes,
  order,
  orderBy,
  onRequestSort,
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.tableCell}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell className={classes.tableCellCenter}>Planejamento</TableCell>
        <TableCell className={classes.tableCellCenter}>Editar</TableCell>
      </TableRow>
    </TableHead>
  );
};

const tableCell = (theme) => ({
  fontFamily: "Roboto",
  textTransform: "uppercase",
  fontSize: 14,
  lineHeight: 1.29,
  color: theme.palette.primary.main,
  backgroundColor: "#e1e1e1",
  maxWidth: "130px",
  padding: "4px 56px 4px 24px",
});

const styles = (theme) => ({
  tableCell: tableCell(theme),
  tableCellCenter: {
    ...tableCell(theme),
    textAlign: "center",
  },
});

export default withStyles(styles)(PlanningListTableHeader);
