import { LISTA_OBJETIVOS } from "../../../redux/actions/actionsConstants";
import * as ObjetivoActions from "./objetivoAction";

export const initialState = {
  Objetivos: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LISTA_OBJETIVOS:
      return { ...state, Objetivos: payload.Objetivos };
    case ObjetivoActions.SALVAR_OBJETIVO:
      return { ...state, Objetivo: action.payload.Objetivo };
    case ObjetivoActions.EDITAR_OBJETIVO:
      return { ...state };
    case ObjetivoActions.EXCLUIR_OBJETIVO:
      return { state };
    case ObjetivoActions.REATIVAR_OBJETIVO:
      return { state, Objetivo: action.payload.Objetivo };
    default:
      return state;
  }
};
