import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { DatePicker } from "material-ui-pickers";

const ptBrDialogProps = {
  okLabel: "Ok",
  cancelLabel: "Cancelar",
  clearLabel: "Limpar",
  todayLabel: "Hoje",
};

const MonthDatePicker = ({
  testid,
  classes,
  label,
  tooltipMessage,
  required = false,
  className,
  disabled,
  value,
  DialogProps,
  onClick,
  onChange = () => {},
}) => {
  const [focusLabel, setFocusLabel] = useState(false);

  const toggleFocusLabel = () => {
    setFocusLabel((prevFocus) => !prevFocus);
  };

  const dialogProps = DialogProps
    ? {
        ...DialogProps,
        PaperProps: { "data-testid": `${testid}-dialog` },
        ...ptBrDialogProps,
      }
    : { PaperProps: { "data-testid": `${testid}-dialog` }, ...ptBrDialogProps };

  return (
    <FormControl className={className}>
      <InputLabel
        shrink
        required={required}
        focused={focusLabel}
        className={classes.label}
        classes={{
          required: !disabled && classes.required,
          focused: classes.focused,
        }}
      >
        {label}
        {tooltipMessage && (
          <Tooltip
            className={classes.tooltip}
            classes={{ tooltip: classes.tooltipContent }}
            title={tooltipMessage}
            aria-label="tooltipMessage"
            placement="top"
          >
            <InfoIcon width={16} />
          </Tooltip>
        )}
      </InputLabel>
      <DatePicker
        className={classes.root}
        format="MM/yyyy"
        placeholder="mm/aaaa"
        onlyCalendar
        keyboard
        onFocus={toggleFocusLabel}
        onBlur={toggleFocusLabel}
        DialogProps={dialogProps}
        InputAdornmentProps={{
          "data-testid": `${testid}-button`,
          onClick: (event) => {
            if (onClick) onClick(event);
          },
        }}
        InputProps={{
          "data-testid": testid,
          inputProps: {
            "data-testid": `${testid}-input`,
            onClick: (event) => {
              if (onClick) onClick(event);
            },
          },
        }}
        clearable
        disabled={disabled}
        adornmentPosition="end"
        views={["month", "year"]}
        onChange={onChange}
        value={value}
      />
    </FormControl>
  );
};

const styles = (theme) => ({
  root: {
    marginTop: 16,
  },
  label: {
    fontSize: theme.typography.size.regular,
  },
  tooltip: {
    position: "absolute",
    right: "-30px",
    top: "-5px",
  },
  required: {
    "&>span": {
      color: "red",
    },
  },
  focused: {
    color: "red",
  },
  tooltipContent: {
    fontSize: 14,
  },
});

export default withStyles(styles)(MonthDatePicker);
