import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { Grid, TextField, withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

export const CausaMorteForm = props => {
  return (
    <>
      <Grid item sm={12} xs={12}>
        <Typography className={props.classes.tituloNovo} gutterBottom>
          Nova Causa
        </Typography>
      </Grid>
      <Grid item sm={12} xs={12}>
        <Typography className={props.classes.subtituloNovo} gutterBottom>
          Informações Gerais
        </Typography>
      </Grid>
      <Grid item sm={12} xs={12}>
        <FormControl className={props.classes.textoCadastro}>
          <TextField
            value={props.causaMorte.Nome}
            autoFocus
            id={'adornment-nome'}
            required
            label={'Causa'}
            error={props.erroNome}
            helperText={props.erroNome ? 'Erro - Causa já cadastrada' : ''}
            onChange={props.handleChangeNomeCausaMorte}
          />
        </FormControl>
      </Grid>
      <Grid item sm={12} xs={12}>
        <Button
          id="habilitarCausaMorte"
          className={props.classes.botaoCancelar}
          onClick={() => props.habilitarFormulario(false)}
        >
          Cancelar
        </Button>
        <Button
          id="botaoAdicionar"
          disabled={
            props.causaMorte.Nome === '' ||
            props.erroNome
          }
          variant="contained"
          color="primary"
          onClick={() => props.adicionarCausaMorte(props.causaMorte)}
          className={props.classes.corpoBotoesForm}
        >
          {props.causaMorte.IdCausaMorte ? 'Salvar' : 'Adicionar'}
        </Button>
      </Grid>
    </>
  );
};

const styles = (theme) => {
  return {
    textoCadastro: {
      marginRight: '30px'
    },
    corpoBotoesForm: {
      marginTop: '36px',
      marginRight: '20px'
    },
    botaoCancelar: {
      marginTop: '36px',
      marginRight: '20px',
      color: theme.palette.primary.main
    },
    FaixaIdade: {
      marginRight: '20px'
    },
    tituloNovo: {
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      fontSize: '24px',
      letterSpacing: '0.56px',
      color: '#212121'
    },
    subtituloNovo: {
      fontFamily: 'Roboto',
      fontSize: '14px',
      letterSpacing: '-0.03px',
      color: '#757575',
      fontWeight: 'bold',
      marginTop: '16px',
      marginBottom: '12px'
    }
  };
};

export default withStyles(styles)(CausaMorteForm);
