import {
  NOTIFICAR,
  FECHAR_NOTIFICACAO,
  SALVAR_PASTO,
  SALVAR_COCHO,
  SALVAR_COCHO_COMPARTILHADO,
  SALVAR_REBANHO,
  SALVAR_FORRAGEM,
  EXCLUI_COCHO_COMPARTILHADO,
} from "../actions/actionsConstants";
import {
  CRIA_MODULO,
  EXCLUI_MODULO,
  ATUALIZA_MODULO,
} from "features/manejo/redux/actions/moduloActions.js";

export const initialState = {
  aberto: false,
  mensagem: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICAR:
      return { ...state, aberto: true, ...action.payload };
    case FECHAR_NOTIFICACAO:
      return { ...state, aberto: false, mensagem: "" };
    case SALVAR_PASTO:
      return {
        ...state,
        aberto: true,
        mensagem: "Pasto Salvo com Sucesso!",
        estilo: "success",
      };
    case SALVAR_COCHO:
      return {
        ...state,
        aberto: true,
        mensagem: "Pasto Salvo com Sucesso!",
        estilo: "success",
      };
    case SALVAR_COCHO_COMPARTILHADO:
      return {
        ...state,
        aberto: true,
        mensagem: "Cocho Compartilhado Salvo com Sucesso!",
        estilo: "success",
      };
    case SALVAR_REBANHO:
      return {
        ...state,
        aberto: true,
        mensagem: "Pasto Salvo com Sucesso!",
        estilo: "success",
      };
    case SALVAR_FORRAGEM:
      return {
        ...state,
        aberto: true,
        mensagem: "Pasto Salvo com Sucesso!",
        estilo: "success",
      };
    case CRIA_MODULO:
      return {
        ...state,
        aberto: true,
        mensagem: "Módulo Salvo com Sucesso!",
        estilo: "success",
      };
    case EXCLUI_MODULO:
      return {
        ...state,
        aberto: true,
        mensagem: "Módulo excluído com sucesso!",
        estilo: "success",
      };
    case ATUALIZA_MODULO:
      return {
        ...state,
        aberto: true,
        mensagem: "Módulo atualizado com sucesso!",
        estilo: "success",
      };
    case EXCLUI_COCHO_COMPARTILHADO:
      return {
        ...state,
        aberto: true,
        mensagem: "Cocho Compartilhado excluído com sucesso!",
        estilo: "success",
      };
    default:
      return state;
  }
};
