import {
  SELECIONA_MODAL_HISTORICO,
  ENVIA_EMAIL_HISTORICO,
  SELECIONA_MODAL_LOGOUT,
  SELECIONA_MODAL_TABLEAU,
} from "../actions/actionsConstants";

export const initialState = {
  ModalHistoricoAberto: false,
  ModalLogoutAberto: false,
  ModalHistoricoStatusEnvioEmail: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECIONA_MODAL_HISTORICO:
      return { ...state, ModalHistoricoAberto: action.payload };
    case SELECIONA_MODAL_LOGOUT:
      return { ...state, ModalLogoutAberto: action.payload };
    case ENVIA_EMAIL_HISTORICO:
      return { ...state, ModalHistoricoStatusEnvioEmail: action.payload };
    case SELECIONA_MODAL_TABLEAU:
      return { ...state, ModalTableauAberto: action.payload };

    default:
      return state;
  }
};
