import * as causaMorteActions from './causaMorteActions';

export const INITIAL_STATE = {
  causasMorte: [],
  causaMorte: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case causaMorteActions.LISTA_CAUSAS_MORTE:
      return { ...state, causasMorte: action.payload.CausasMorteFazenda };
    case causaMorteActions.SALVAR_CAUSAS_MORTE:
      return { ...state, causaMorte: action.payload };
    default:
      return state;
  }
};
