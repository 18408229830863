import { Drawer, Toolbar, withStyles } from "@material-ui/core/";
import { default as MaterialAppBar } from "@material-ui/core/AppBar";
import Icon from "@material-ui/core/Icon";
import BotaoCentralAjuda from "components/SelectDropdown/botaoCentralAjuda";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router";
import { compose } from "recompose";
import { LogoProdap } from "..";
import { MenuNavegacao } from "../../navegacao";
import { DropDownUsuario } from "../../shared/components";
import Auth from "features/authentication/Auth";
import Notification from "features/notificationNews";

export class AppBar extends React.Component {
  state = {
    abreMenuFuncionalidades: false,
  };

  abreFechaMenu = () => {
    this.setState((prevState) => ({
      abreMenuFuncionalidades: !prevState.abreMenuFuncionalidades,
    }));
  };

  render() {
    const { classes, preventPadding = false } = this.props;

    const rootClass = preventPadding
      ? classes.rootWithoutPadding
      : classes.root;

    return (
      <div className={rootClass}>
        <MaterialAppBar
          className={classes.materialAppBar}
          position="static"
          color="inherit"
        >
          <Toolbar style={{ minHeight: "75px" }}>
            <div className={classes.container}>
              <div className={classes.containerLogo}>
                {Auth.isAnalyticsUser() ? null : (
                  <Icon
                    color="primary"
                    className={classes.iconeMenu}
                    onClick={this.abreFechaMenu}
                  >
                    menu
                  </Icon>
                )}

                <div className={classes.logo}>
                  <LogoProdap id="logoPropdapAppBar" />
                </div>

                <Drawer
                  open={this.state.abreMenuFuncionalidades}
                  onClose={() =>
                    this.setState({ abreMenuFuncionalidades: false })
                  }
                >
                  <MenuNavegacao
                    id="menuNavegacaoAppBar"
                    abreFechaMenu={this.abreFechaMenu}
                    {...this.props.menuNavegacaoProps}
                  />
                </Drawer>
              </div>

              <div className={classes.alignCenter}>
                {this.props.conteudoCustomizadoAppBar}
              </div>

              <div className={classes.avatarWrapper}>
                <BotaoCentralAjuda />
                <Notification />

                <DropDownUsuario id="dropDownUsuarioAppBar" />
              </div>
            </div>
          </Toolbar>
        </MaterialAppBar>
      </div>
    );
  }
}

AppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

AppBar.defaultProps = {
  menuNavegacaoProps: {},
};

const styles = (theme) => {
  return {
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      paddingTop: theme.props.tamanhoAppBarXs,
    },
    rootWithoutPadding: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      paddingTop: theme.props.tamanhoAppBarXs,
    },
    materialAppBar: {
      position: "fixed",
      top: 0,
      boxShadow: "none",
    },
    iconeMenu: {
      cursor: "pointer",
    },
    containerLogo: {
      display: "flex",
      alignItems: "center",
      gap: "16px",
    },
    logo: {
      display: "flex",
      alignItems: "center",
    },
    container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    alignCenter: {
      display: "flex",
      alignItems: "center",
    },
    avatarWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      "@media (max-width: 720px)": {
        justifyContent: "flex-start",
      },
    },
  };
};
export default compose(withRouter, withStyles(styles))(AppBar);
