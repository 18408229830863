import React from "react";
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Grid,
} from "@material-ui/core";

import {
  obterEstiloLinhaTabela,
  obterEstiloDiferencaResumo,
  retirarAcentuacao,
  nomesColunasResumo,
  camposEsperadosResumo,
} from "../conciliacaoUtil";
import NomeColunasTabela from "./nomeColunasTabela";

export class ConciliacaoResumoTable extends React.Component {
  defineEspacamentoColuna = (colunasTabela) => {
    const tamanhoColuna = "9%";
    return (
      <colgroup>
        {colunasTabela.map((i) => {
          return <col key={`${i}-width`} style={{ width: tamanhoColuna }} />;
        })}
      </colgroup>
    );
  };

  retirarAcentuacao = (texto) => {
    return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  retornaValorCelularResumo = (campo, resumo, classes, minimizado) => {
    const camposSemValorPortal = [
      "EntradaTrocaDeCategoria",
      "SaidaTrocaDeCategoria",
      "Nascimento",
      "Morte",
      "Diferenca",
    ];

    let textoLinha;

    if (camposSemValorPortal.includes(campo)) {
      textoLinha = (
        <>
          <Typography
            key={`${campo}-Typography-app`}
            className={
              minimizado
                ? classes.tabelaBodyContentMini
                : classes.tabelaBodyContent
            }
            style={obterEstiloDiferencaResumo(
              campo,
              resumo[0][campo],
              minimizado
            )}
          >
            {typeof resumo[0][campo] === "number"
              ? Math.abs(resumo[0][campo])
              : resumo[0][campo]}
          </Typography>
        </>
      );
    } else {
      textoLinha = resumo.map((res) => (
        <Typography
          key={`${campo}-Typography-${res[campo].Origem}`}
          className={
            minimizado
              ? classes.tabelaBodyContentMini
              : classes.tabelaBodyContent
          }
          style={obterEstiloDiferencaResumo(campo, res[campo], minimizado)}
        >
          {res[campo]}
        </Typography>
      ));
    }
    return textoLinha;
  };

  render() {
    const { classes, resumo, minimizado } = this.props;
    const borderLeft = { borderRadius: "0 0  0 16px", border: "none" };
    const borderRight = { borderRadius: "0 0 16px 0", border: "none" };

    return (
      <Paper className={classes.containerTable}>
        <Table className={classes.table}>
          {this.defineEspacamentoColuna(camposEsperadosResumo)}
          <TableHead id="cabecalhoTabela">
            <TableRow style={{ backgroundColor: "#e1e1e1" }}>
              <NomeColunasTabela
                tabelaHeaderContent={
                  minimizado
                    ? classes.tabelaHeaderContentMini
                    : classes.tabelaHeaderContent
                }
                nomesColunas={nomesColunasResumo}
                minimizado={minimizado}
              />
            </TableRow>
          </TableHead>
          <TableBody key="dadosTabela" id="dadosTabela">
            <TableRow
              key={`resumo-linhasTabela`}
              id={`resumo-linhasTabelaId`}
              className={classes.gridTableRow}
              style={obterEstiloLinhaTabela(1)}
            >
              {camposEsperadosResumo.map((campo, i) => {
                campo = retirarAcentuacao(campo);
                return (
                  <TableCell
                    key={`${i}-TableCell`}
                    padding="none"
                    component="th"
                    scope="row"
                    align="center"
                    className={classes.defaultCell}
                    style={
                      i === 0
                        ? borderLeft
                        : i === camposEsperadosResumo.length - 1
                        ? borderRight
                        : null
                    }
                  >
                    <Grid
                      container
                      direction="row"
                      style={{ justifyContent: "center" }}
                    >
                      <div className={classes.divBodyContent}>
                        {this.retornaValorCelularResumo(campo, resumo, classes)}
                      </div>
                    </Grid>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

const styles = (theme) => ({
  typography: {
    fontFamily: "Roboto",
  },
  table: {
    width: "100%",
    marginTop: "25px",
    marginBottom: "25px",
    fontFamily: "Roboto",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "1px",
    borderRadius: "0 0 16px 16px",
  },
  tabelaHeaderContent: {
    fontSize: "14px",
    fontWeight: "500",
    color: theme.palette.primary.main,
  },
  tabelaHeaderContentMini: {
    fontSize: "12px",
    fontWeight: "500",
    color: theme.palette.primary.main,
    textAlign: "center",
  },
  tabelaBodyContent: {
    fontFamily: "Roboto",
    fontSize: "21px",
    fontWeight: "normal",
    color: "#666666",
  },
  tabelaBodyContentMini: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    color: "#666666",
  },
  tabelaBodyContentPortal: {
    fontFamily: "Roboto",
    fontSize: "21px",
    fontWeight: "normal",
    color: "#666666",
    marginTop: "12px",
  },
  root: {
    width: "100%",
    height: "100%",
    zIndex: -1,
    padding: "20px",
    flexgrow: 1,
    minHeight: window.innerHeight,
  },
  containerPage: {
    flexgrow: 1,
    height: "100%",
    padding: "0",
    backgroundColor: "#fff",
    margin: "0 auto",
    width: "80%",
  },
  titulo: {
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#424242",
    marginBottom: "20px",
  },
  smallerArea: {
    width: "100%",
    height: "auto",
    margin: "0em 0em",
    padding: "0.8em 0em",
  },
  resultPaper: {
    paperRoot: {
      width: "100%",
      height: "auto",
      border: "2px",
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.2)",
      backgroundColor: "#ffffff",
    },
  },
  defaultCell: {
    height: "96px",
    border: "solid 1px #e5e5ea",
    maxWidth: "156px",
  },
  containerTable: {
    borderRadius: "16px",
  },
});

export default withStyles(styles)(ConciliacaoResumoTable);
