import React, { PureComponent } from "react";
import { AppBar as AppBarCustomizado } from "../../layout/index";
import { NavegacaoTabs } from "../../navegacao/index";
import ConciliacaoContainer from "../../conciliacao/views/conciliacaoContainer";
import { connect } from "react-redux";
import CategoriaContainer from "../../categoria/views/categoriaContainer";
import MovimentacaoContainer from "../../movimentacao/views/movimentacaoContainer";
import BalancoArrobaContainer from "../../balancoDeArroba/views/balancoArrobaContainer";
import {
  getTabsConciliacaoBalanco,
  deveExibirAba,
} from "../../conciliacao/conciliacaoUtil";
import { deveExibirBalancoArroba } from "../../shared/utils";
import { AppBarConteudoDefault, TabContentDefault } from "../../layout";
import { FazendaSelecionadaContainer } from "../../shared/crossCuttingComponents";

export class MapaDeRebanho extends PureComponent {
  state = {
    abaSelecionada: "Categoria Animal",
  };

  handleMudarAbaConfiguracao = (evento) => {
    this.setState({ abaSelecionada: evento.target.textContent });
  };
  conteudoCustomizadoAppBar = (fazenda) => {
    const tituloAppBar = fazenda
      ? fazenda.NomeReduzido
      : "NENHUMA FAZENDA SELECIONADA";
    return <AppBarConteudoDefault tituloAppBar={tituloAppBar} />;
  };

  contentTab = (fazenda) => {
    const { abaSelecionada } = this.state;
    const exibirBalancoArrobaContainer = deveExibirAba(
      "Balanço de Arroba",
      abaSelecionada,
      fazenda.IdFazenda
    );

    const exibirConciliacaoContainer = deveExibirAba(
      "Conciliação",
      abaSelecionada,
      fazenda.IdFazenda
    );

    const exibirMovimentacaoContainer = deveExibirAba(
      "Entradas e Saídas",
      abaSelecionada,
      fazenda.IdFazenda
    );

    const exibirCategoriaAnimal = deveExibirAba(
      "Categoria Animal",
      abaSelecionada,
      fazenda.IdFazenda
    );

    return (
      <TabContentDefault>
        {!!exibirCategoriaAnimal && (
          <CategoriaContainer
            exibeQuantidadeConciliacao={true}
            id="categoriaAnimal"
          />
        )}
        {!!exibirMovimentacaoContainer && (
          <MovimentacaoContainer id="movimentacaoTabContent" />
        )}
        {!!exibirConciliacaoContainer && (
          <ConciliacaoContainer id="conciliacaoTabContent" />
        )}
        {!!exibirBalancoArrobaContainer && (
          <BalancoArrobaContainer id="balancoArrobaTabContent" />
        )}
      </TabContentDefault>
    );
  };

  render() {
    const { abaSelecionada } = this.state;
    const tabConciliacaoBalanco = getTabsConciliacaoBalanco(
      this.props.exibirBalancoArroba
    );

    return (
      <FazendaSelecionadaContainer
        render={(fazenda) => (
          <React.Fragment>
            <AppBarCustomizado
              conteudoCustomizadoAppBar={this.conteudoCustomizadoAppBar(
                fazenda
              )}
              id="appBarPersonalizado"
            />
            <NavegacaoTabs
              tabs={tabConciliacaoBalanco}
              abaSelecionada={abaSelecionada}
              mudarTab={this.handleMudarAbaConfiguracao}
              estilosTab={{ minWidth: "auto" }}
            />
            {this.contentTab(fazenda)}
          </React.Fragment>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    exibirBalancoArroba: deveExibirBalancoArroba(state),
  };
};

export default connect(mapStateToProps, null)(MapaDeRebanho);
