import React from "react";
import { Avatar, Grid, Typography, withStyles } from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { abreviaNome } from "../utils";

const formatarNome = (nome) => abreviaNome(nome).toLocaleLowerCase();

export const avatarUsuario = (props) => {
  const { usuario, handleOpen, classes } = props;

  const iniciaisDoNome =
    usuario && usuario.nome && (usuario.nome || "").substring(0, 2);

  return (
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Avatar onClick={handleOpen} className={classes.smallAvatar}>
        {iniciaisDoNome}
      </Avatar>
      <Typography
        variant="caption"
        onClick={handleOpen}
        className={classes.userName}
      >
        {formatarNome(usuario.nome)}
      </Typography>
      <ExpandMoreIcon className={classes.icon} />
    </Grid>
  );
};

avatarUsuario.defaultProps = {
  handleOpen: () => {},
};

const styles = (theme) => {
  return {
    smallAvatar: {
      backgroundColor: theme.palette.complementary.main,
      fontSize: 14,
      width: 36,
      height: 36,
    },
    userName: {
      fontSize: "12px",
      lineHeight: "24px",
      margin: "0 8px",
      color: "#666666",
      textTransform: "capitalize",
    },
    icon: {
      fontSize: "24px",
      lineHeight: "24px",
      color: "#666666",
    },
  };
};
export default withStyles(styles)(avatarUsuario);
