import React, { PureComponent } from "react";
import { Typography, Paper, Grid, Button, withStyles } from "@material-ui/core";
import BotaoUpload from "../../shared/components/BotaoUpload";
import LeitorArquivo from "../../../lib/LeitorArquivo";
import SelectDropdown from "components/SelectDropdown";

export class HistoricoChuvaPage extends PureComponent {
  constructor(props) {
    super(props);
    this.uploadCsv = React.createRef();
    this.leitorArquivo = new LeitorArquivo();
  }

  state = {
    retiroSelecionado: null,
    tipoUpload: "",
  };
  async componentDidMount() {
    const { listaRetiros, fazendaSelecionada } = this.props;
    await listaRetiros(fazendaSelecionada);
  }

  handleChangeFormBasico = (value) => {
    this.setState({ retiroSelecionado: value });
  };

  exportarCsvChuva = () => {
    const { fazendaSelecionada } = this.props;
    const { retiroSelecionado } = this.state;
    this.props.obtemHistoricoChuva(fazendaSelecionada, retiroSelecionado);
  };

  importarCsvChuva = (arquivoCsv) => {
    const { fazendaSelecionada } = this.props;
    const { retiroSelecionado } = this.state;
    this.props.atualizaHistoricoChuva(
      fazendaSelecionada,
      retiroSelecionado,
      arquivoCsv
    );
  };

  onDrop = (files) => {
    files.forEach((file) => {
      this.importarCsvChuva(file);
    });
  };

  onInputFileOpen = (tipoUpload, extensao) => {
    this.leitorArquivo.setExtensaoArquivo(extensao);
    this.setState({ tipoUpload });
  };

  validaInputArquivo = (accepted, rejected) => {
    let respostaInputArquivo = this.leitorArquivo.validaInputArquivo(
      accepted,
      rejected
    );
    if (respostaInputArquivo.entradaArquivoValido) {
      this.onDrop(accepted);
    } else {
      this.props.notifyInfo({
        mensagem: respostaInputArquivo.mensagem,
      });
    }
  };

  cancelar = () => {
    this.setState({ retiroSelecionado: null });
  };

  render() {
    const { classes, retiros } = this.props;
    const { retiroSelecionado } = this.state;
    const listaRetiro = retiros.map((retiro) => {
      return {
        value: retiro.IdRetiro,
        label: retiro.Nome,
      };
    });
    return (
      <div
        square
        className={classes.rootHistoricoChuva}
        id="PaperHistoricoChuvaPage"
      >
        <div className={classes.containerPage}>
          <Typography className={classes.tituloHistoricoChuva} gutterBottom>
            Histórico de chuva
          </Typography>
          <Paper square className={classes.paper}>
            <Grid container direction="column" xs={12}>
              <Typography className={classes.tituloSecundario} gutterBottom>
                Importar ou exportar histórico de chuva
              </Typography>
              <Typography className={classes.textoInform}>
                Para importar o historico de chuva corretamente, exporte o
                arquivo Excel (.csv) preencha a tabela e importe este mesmo
                arquivo no botão Importar CSV abaixo.
              </Typography>
              <Typography className={classes.textoForm}>
                Importar ou exportar dados de
              </Typography>
              <div className={classes.selectContainer}>
                <SelectDropdown
                  isSearchable
                  id="select-retiro"
                  label={{ text: "Retiro", required: true }}
                  options={listaRetiro}
                  value={retiroSelecionado}
                  onChange={this.handleChangeFormBasico}
                />
              </div>
            </Grid>
            <Grid container className={classes.historicoChuvaBotoes}>
              <Button
                id="btn-exportar"
                onClick={this.exportarCsvChuva}
                variant="contained"
                className={classes.botaoDefault}
                color="primary"
                disabled={retiroSelecionado ? false : true}
              >
                Exportar CSV
              </Button>
              <BotaoUpload
                id="btn-importar"
                onInputFileOpen={this.onInputFileOpen}
                extensaoArquivoAceito=".csv"
                tipoUpload="csvHistoricoChuva"
                onDrop={this.validaInputArquivo}
                cunstomClasses={classes.botaoDefault}
                buttomCustomClasses={classes.uplodButtonCuston}
                disabled={retiroSelecionado ? false : true}
                stylesGenericos={{ margin: "0!important" }}
                tituloBotao="Importar CSV"
              />
              <Button
                id="btn-cancelar"
                onClick={this.cancelar}
                variant="contained"
                color="primary"
                className={classes.botaoDefault}
                disabled={retiroSelecionado ? false : true}
              >
                Cancelar
              </Button>
            </Grid>
          </Paper>
        </div>
      </div>
    );
  }
}

const styles = () => {
  return {
    rootHistoricoChuva: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      overflowX: "auto",
      zIndex: -1,
      minHeight: window.innerHeight,
    },
    containerPage: {
      flexgrow: 1,
      backgroundColor: "#fff",
      width: "100%",
      padding: "46px",
      marginTop: 70,
      display: "flex",
      flexDirection: "column",
    },
    tituloHistoricoChuva: {
      fontFamily: "Roboto",
      fontSize: 20,
      fontWeight: "bold",
      letterSpacing: "0.56px",
      color: "#333",
      marginBottom: 27,
    },
    paper: {
      width: "100%",
      padding: "30px 40px",
    },
    tituloSecundario: {
      fontSize: "20px",
      fontWeight: 500,
      color: "#333333",
      letterSpacing: "0.56px",
    },
    textoInform: {
      fontFamily: "Roboto",
      fontSize: 18,
      letterSpacing: "0.5px",
      color: "#7f7f7f",
      marginTop: 24,
    },
    textoForm: {
      marginTop: 40,
      fontFamily: "Roboto",
      fontSize: 18,
      letterSpacing: "0.5px",
      color: "#7f7f7f",
    },
    textField: {
      width: 300,
      marginLeft: "0!important",
    },
    historicoChuvaBotoes: {
      display: "flex",
      marginTop: 40,
    },
    botaoDefault: {
      borderRadius: "18px",
      width: 137,
      height: 36,
      boxShadow: "none",
      marginRight: 16,
    },
    uplodButtonCuston: {
      borderRadius: "18px",
      boxShadow: "none",
    },
    selectContainer: {
      width: "300px",
      marginTop: "16px",
    },
  };
};

export default withStyles(styles)(HistoricoChuvaPage);
