import React from "react";
import { withStyles } from "@material-ui/core";
import GoalPlanContent from "../shared/goalPlanContent";
import GoalPlanListFilterNotFound from "./components/goalPlanListFilterNotFound";
import GoalPlanListEmpty from "./components/goalPlanListEmpty";
import GoalPlanListing from "./components/goalPlanListing";
import GoalPlanListHeader from "./components/goalPlanListHeader";
import AnalyticsRequiredWarning from "./components/analyticsRequiredWarning";

const GoalPlanListPage = ({
  goalPlans,
  filter,
  onChangeFilter,
  showCreation,
  showEdition,
  showRevisions,
  showReview,
  showCopy,
}) => {
  const isExistsGoalPlans = goalPlans.length > 0;
  const isFilterEmpty =
    !isExistsGoalPlans && filter && filter.name.length !== 0;

  return (
    <GoalPlanContent title="Listagem de planos e metas">
      <GoalPlanListHeader
        filter={filter}
        onChangeFilter={onChangeFilter}
        onClick={showCreation}
      />

      <AnalyticsRequiredWarning />

      {isExistsGoalPlans ? (
        <GoalPlanListing
          id="tabela-metas"
          goalPlans={goalPlans}
          showEdition={showEdition}
          showRevisions={showRevisions}
          showReview={showReview}
          showCopy={showCopy}
        />
      ) : isFilterEmpty ? (
        <GoalPlanListFilterNotFound />
      ) : (
        <GoalPlanListEmpty />
      )}
    </GoalPlanContent>
  );
};

const styles = () => {
  return {
    goalPlanListContainer: {
      width: "100%",
      height: "100%",
      overflowX: "auto",
      padding: "40px",
      zIndex: -1,
      bottom: 0,
      top: "17%",
      minHeight: "calc(100vh - 90px)",
    },
    titulo: {
      fontSize: "20px",
      fontWeight: "bold",
      fontStyle: "medium",
      fontStretch: "medium",
      lineHeight: "medium",
      letterSpacing: "medium",
      color: "#333333",
      marginTop: "40px",
    },
  };
};

export default withStyles(styles)(GoalPlanListPage);
