import React, { useEffect, useState } from "react";
import * as actions from "redux/actions";
import productService from "../../productService";
import IngredientRegisterFormPage from "./ingredientRegisterFormPage";
import ConfirmationModal from "features/shared/components/confirmationModal";
import "moment/locale/pt-br";
import moment from "moment";
import { withStyles } from "@material-ui/core";
import { compose } from "recompose";
import { connect } from "react-redux";
import { getFazendaSelecionadaStorage } from "shared/storageUtils";
import { debounce } from "features/shared/utils";
moment.locale("pt-br");

const IngredientRegisterFormContainer = ({
  salvarNovoIngrediente,
  editarIngrediente,
  ingredienteEditado,
  ingredientesPadrao,
  setEditando,
  editando,
  setIngredienteEditado,
  lancaCompra,
  setLancaCompra,
  isInternalUser,
  classes,
  notifyError,
  farmId,
}) => {
  const [controleData, setControleData] = useState(null);
  const [nomeIngrediente, setNomeIngrediente] = useState("");
  const [valorIngrediente, setValorIngrediente] = useState("");
  const [ingrediente, setIngrediente] = useState("");
  const [erroProductId, setErroProductId] = useState(false);
  const [camposPreenchido, setCamposPreenchido] = useState(true);
  const [textoCusto, setTextoCusto] = useState("CUSTO POR KG");
  const [textoData, setTextoData] = useState("DATA DA COMPRA");

  const [selectedproductId, setSelectedproductId] = useState(null);
  const [listAvailableProducts, setListAvailableProducts] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedProductInfos, setSelectedProductInfos] = useState("");
  const [productSearchText, setProductSearchText] = useState("");
  const [, setProductSearchPage] = useState(1);

  const getAvailableProducts = async (filter, page = 1, size = 10) => {
    try {
      const availableProducts = await productService.getAvailableProducts(
        farmId,
        filter,
        page,
        size
      );
      setListAvailableProducts(availableProducts);
    } catch (error) {
      notifyError({
        mensagem: error.message,
      });
    }
  };

  const getAvailableProductsNewPage = async (filter, page = 1, size = 10) => {
    try {
      const availableProducts = await productService.getAvailableProducts(
        farmId,
        filter,
        page,
        size
      );

      const orderedProducts = listAvailableProducts.concat(availableProducts);

      setListAvailableProducts(orderedProducts);
    } catch (error) {
      notifyError({
        mensagem: error.message,
      });
    }
  };

  useEffect(() => {
    if (isInternalUser) {
      getAvailableProducts();
    }
  }, []);

  const onProductRequestData = () => {
    setProductSearchPage((previousPage) => {
      const newPage = previousPage + 1;
      getAvailableProductsNewPage(productSearchText, newPage);
      return newPage;
    });
  };

  const handleChangeProductIdList = (text) => {
    setProductSearchText(text);
    debounce(() => {
      getAvailableProducts(text);
      setProductSearchPage(1);
    }, 500);
  };

  const handleProductChange = (value) => {
    const product = listAvailableProducts.find(
      (product) => product.id === value
    );
    if (product) {
      const productInfos = `${product.code} ${product.name}`;
      setSelectedProductInfos(productInfos);
    }
    setSelectedproductId(value);
  };

  const clearSearch = () => {
    setSelectedproductId(null);
    setListAvailableProducts([]);
    setProductSearchPage(1);
    setProductSearchText("");
    getAvailableProducts();
  };

  useEffect(() => {
    if (ingredienteEditado) {
      clearSearch();
      setControleData(
        ingredienteEditado.DataCompra
          ? moment(ingredienteEditado.DataCompra)
          : null
      );
      setNomeIngrediente(ingredienteEditado.Descricao);
      setSelectedproductId(
        ingredienteEditado.Produto ? ingredienteEditado.Produto.id : null
      );
      if (ingredienteEditado.Produto) {
        const productInfos = `${ingredienteEditado.Produto.code} ${ingredienteEditado.Produto.name}`;
        setSelectedProductInfos(productInfos);
      }
      setValorIngrediente(
        ingredienteEditado.CustoPorQuilo
          ? ingredienteEditado.CustoPorQuilo.toFixed(2)
          : ""
      );
      setIngrediente(ingredienteEditado.NomeMateriaPrima);
      if (ingredienteEditado.DataCompra) {
        setTextoData("DATA DA COMPRA*");
        setTextoCusto("CUSTO POR KG*");
      } else if (ingredienteEditado.CustoPorQuilo) {
        setTextoData("DATA DA COMPRA*");
        setTextoCusto("CUSTO POR KG*");
      } else {
        setTextoData("DATA DA COMPRA");
        setTextoCusto("CUSTO POR KG");
      }
    }
  }, [ingredienteEditado]);

  useEffect(() => {
    if (lancaCompra) {
      setControleData("");
      setNomeIngrediente(lancaCompra.Descricao);
      setSelectedproductId(lancaCompra.Produto ? lancaCompra.Produto.id : null);
      if (lancaCompra.Produto) {
        const productInfos = `${lancaCompra.Produto.code} ${lancaCompra.Produto.name}`;
        setSelectedProductInfos(productInfos);
      }
      setValorIngrediente("");
      setIngrediente(lancaCompra.NomeMateriaPrima);
    }
  }, [lancaCompra]);

  useEffect(() => {
    if (ingrediente !== "" && nomeIngrediente !== "") {
      setCamposPreenchido(false);
      if (lancaCompra) {
        validarCamposObrigatorios();
      }
    } else {
      setCamposPreenchido(true);
    }
  }, [
    ingrediente,
    nomeIngrediente,
    controleData,
    valorIngrediente,
    lancaCompra,
  ]);

  const textosDataCustoRequired = () => {
    if (lancaCompra) {
      setTextoData("DATA DA COMPRA*");
      setTextoCusto("CUSTO POR KG*");
    } else if (controleData === null && valorIngrediente === "") {
      setTextoData("DATA DA COMPRA");
      setTextoCusto("CUSTO POR KG");
    } else {
      setTextoData("DATA DA COMPRA*");
      setTextoCusto("CUSTO POR KG*");
    }
  };

  useEffect(() => {
    textosDataCustoRequired();
  }, [controleData, valorIngrediente]);

  const validarCamposObrigatorios = () => {
    if (lancaCompra) {
      setCamposPreenchido(true);
      if (controleData && controleData.isValid() && valorIngrediente) {
        setCamposPreenchido(false);
      }
    } else if (controleData && controleData.isValid() && valorIngrediente) {
      setCamposPreenchido(false);
    } else if (controleData && !controleData.isValid() && !valorIngrediente) {
      setCamposPreenchido(false);
    } else {
      setCamposPreenchido(true);
    }
  };

  const validarDataCusto = (newData, custo) => {
    if (newData && newData.isValid()) {
      setControleData(newData);
      setTextoCusto("CUSTO POR KG *");
    }
    if (custo || valorIngrediente) {
      setValorIngrediente(custo || custo === "" ? custo : valorIngrediente);
      if (custo === "" && !controleData) setTextoData("DATA DA COMPRA");
      else setTextoData("DATA DA COMPRA *");
    } else {
      setTextoData("DATA DA COMPRA");
    }
  };

  const cancelaLimpa = () => {
    clearSearch();
    setEditando(false);
    setControleData(null);
    setNomeIngrediente("");
    setValorIngrediente("");
    setIngrediente("");
    setErroProductId(false);
    setIngredienteEditado(false);
    setTextoData("DATA DA COMPRA");
    setTextoCusto("CUSTO POR KG");
    setLancaCompra(false);
  };

  const validarDadosIngrediente = (
    item,
    controleData,
    valorIngrediente,
    selectedproductId
  ) => {
    if (controleData) item.DataCompra = controleData;

    if (valorIngrediente) item.Custo = Number(valorIngrediente);

    if (selectedproductId) item.ProductId = selectedproductId;
  };

  const validaDadosEditados = () => {
    const alteradoProduto =
      ingredienteEditado &&
      ingredienteEditado.Produto &&
      selectedproductId !== ingredienteEditado.Produto.id;

    const vinculadoNovoProduto =
      ingredienteEditado && !ingredienteEditado.Produto && selectedproductId;

    const novoIngredienteComProduto = !ingredienteEditado && selectedproductId;

    const shouldShowConfirmationModal =
      alteradoProduto || vinculadoNovoProduto || novoIngredienteComProduto;

    if (shouldShowConfirmationModal) {
      setShowConfirmationModal(true);
      return;
    }

    acaoFormulario();
  };

  const closeModal = () => setShowConfirmationModal(false);

  const acaoFormulario = () => {
    const idIngrediente = ingredientesPadrao.items.find(
      (v) => v.Nome === ingrediente
    ).IdMateriaPrima;

    const idFazenda = getFazendaSelecionadaStorage();

    const nomeUsuario = localStorage.getItem("Login");

    if (ingredienteEditado) {
      const item = {
        DataCompra: ingredienteEditado.DataCompra,
        Custo: ingredienteEditado.Custo,
        ProductId: ingredienteEditado.ProductId,
        Descricao: nomeIngrediente,
        IdIngrediente: ingredienteEditado.IdIngrediente,
        UsuarioCriacao: nomeUsuario,
      };
      validarDadosIngrediente(
        item,
        controleData,
        valorIngrediente,
        selectedproductId
      );
      editarIngrediente(item);
      setShowConfirmationModal(false);
    } else if (lancaCompra) {
      const item = {
        IdTipoOperacaoIngrediente: 3,
        IdIngrediente: lancaCompra.IdIngrediente,
        UsuarioCriacao: nomeUsuario,
      };

      validarDadosIngrediente(
        item,
        controleData,
        valorIngrediente,
        selectedproductId
      );

      salvarNovoIngrediente(item);
    } else {
      const item = {
        IdTipoOperacaoIngrediente: 1,
        IdMateriaPrima: idIngrediente,
        IdFazenda: idFazenda,
        Descricao: nomeIngrediente,
        Ativo: true,
        UsuarioCriacao: nomeUsuario,
        ProductId: isInternalUser ? selectedproductId : null,
      };

      validarDadosIngrediente(
        item,
        controleData,
        valorIngrediente,
        selectedproductId
      );
      salvarNovoIngrediente(item);
      setShowConfirmationModal(false);
    }
    cancelaLimpa();
  };

  const formartModalText = (
    selectedProductInfos,
    ingrediente,
    classes,
    selectedproductId
  ) => {
    if (
      ingredienteEditado &&
      selectedProductInfos !== "" &&
      selectedproductId
    ) {
      return (
        <span>
          Tem certeza que deseja salvar um novo código do ingrediente (PA){" "}
          <span className={classes.ingredient}>
            {selectedProductInfos.toUpperCase()}
          </span>
          , para o ingrediente{" "}
          <span className={classes.ingredient}>
            {ingrediente.toUpperCase()}
          </span>
          ? As alterações feitas, não irão mudar o histórico passado desse
          ingrediente.
        </span>
      );
    } else if (ingredienteEditado && !selectedproductId) {
      return (
        <span>
          Tem certeza que deseja não vincular código do ingrediente (PA) para o
          ingrediente{" "}
          <span className={classes.ingredient}>
            {ingrediente.toUpperCase()}
          </span>
          ? As alterações feitas, não irão mudar o histórico passado desse
          ingrediente.
        </span>
      );
    } else if (!ingredienteEditado) {
      return (
        <span>
          Você está prestes a vincular o código do ingrediente (PA){" "}
          <span className={classes.ingredient}>
            {selectedProductInfos.toUpperCase()}
          </span>
          , para o ingrediente{" "}
          <span className={classes.ingredient}>
            {ingrediente.toUpperCase()}.
          </span>{" "}
          Por favor, verifique se está correto, caso queira, você poderá editar
          e vincular a outro código do ingrediente (PA).
        </span>
      );
    }
  };

  return (
    <>
      <IngredientRegisterFormPage
        ingredienteEditado={ingredienteEditado}
        ingredientesPadrao={ingredientesPadrao}
        setEditando={setEditando}
        editando={editando}
        lancaCompra={lancaCompra}
        controleData={controleData}
        setControleData={setControleData}
        nomeIngrediente={nomeIngrediente}
        setNomeIngrediente={setNomeIngrediente}
        valorIngrediente={valorIngrediente}
        ingrediente={ingrediente}
        setIngrediente={setIngrediente}
        erroProductId={erroProductId}
        camposPreenchido={camposPreenchido}
        textoCusto={textoCusto}
        textoData={textoData}
        validarDataCusto={validarDataCusto}
        cancelaLimpa={cancelaLimpa}
        acaoFormulario={acaoFormulario}
        isInternalUser={isInternalUser}
        selectedproductId={selectedproductId}
        setSelectedproductId={setSelectedproductId}
        handleChangeProductIdList={handleChangeProductIdList}
        searchProductId={productSearchText}
        filteredProductList={listAvailableProducts}
        clearSearch={clearSearch}
        validaDadosEditados={validaDadosEditados}
        handleProductChange={handleProductChange}
        onEndReached={onProductRequestData}
        selectedProductInfos={selectedProductInfos}
      />
      {showConfirmationModal && (
        <ConfirmationModal
          onSubmit={acaoFormulario}
          onCancel={closeModal}
          title={
            ingredienteEditado ? "Salvar Edição" : "Salvar novo ingrediente"
          }
          submitButtonTitle={"SALVAR"}
          text={formartModalText(
            selectedProductInfos,
            ingrediente,
            classes,
            selectedproductId
          )}
        />
      )}
    </>
  );
};

const styles = (theme) => {
  return {
    ingredient: {
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
  };
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  notifyError: actions.notifyError,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(IngredientRegisterFormContainer);
