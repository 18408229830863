import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Grid,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import {
  changeCenterCoordinates,
  retornaCoordenadas,
} from "features/manejo/redux/actions/fazendaActions";
import { setZoomMapa } from "redux/actions";
import { compose } from "recompose";
import { connect } from "react-redux";
import FazendaService from "services/FazendaService";
import { notifyError } from "redux/actions";
import { debounce } from "features/shared/utils";

export function FormLocation({
  location,
  setLocation,
  lat,
  setLat,
  long,
  setLong,
  onBack,
  onNext,
  changeCenterCoordinates,
  atualizaZoomMapa,
  notifyError,
  classes,
}) {
  const searchCoordinates = async (lat, lng) => {
    if (lat && lng) {
      changeCenterCoordinates({ lat: Number(lat), lng: Number(lng) });
      atualizaZoomMapa(14);
    }
  };

  const searchLocation = async (location) => {
    try {
      const { results } = await FazendaService.retornaEndereco(location);
      const resultado = results ? results[0].geometry.location : {};
      changeCenterCoordinates(resultado);
      atualizaZoomMapa(14);
    } catch {
      notifyError({ mensagem: "Não foi possível obter o endereço" });
    }
  };

  const handleChangeLocation = (event) => {
    const location = event.target.value;
    setLocation(location);
    debounce(() => searchLocation(location), 1000);
  };

  const handleChangeLat = (event) => {
    const lat = event.target.value;
    setLat(lat);
    debounce(() => searchCoordinates(lat, long), 1000);
  };

  const handleChangeLong = (event) => {
    const lng = event.target.value;
    setLong(lng);
    debounce(() => searchCoordinates(lat, lng), 1000);
  };

  const controlButtons = (
    <>
      <Grid item>
        <Button
          id="botaoExcluir"
          variant="outlined"
          color="primary"
          onClick={onBack}
          className={classes.button}
        >
          VOLTAR
        </Button>
      </Grid>
      <Grid item>
        <Button
          id="botaoSalvar"
          variant="contained"
          color="primary"
          className={[classes.saveButton, classes.button].join(" ")}
          onClick={onNext}
        >
          PRÓXIMO
        </Button>
      </Grid>
    </>
  );

  return (
    <Grid container item className={classes.content}>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Typography className={classes.mensagem}>
            Nos campo abaixo, informe a localização ou coordenadas da fazenda,
            onde deseja criar os pastos.
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.fieldRow}>
          <TextField
            className={classes.textField}
            id="textCidade"
            placeholder="Informe a localização da fazenda"
            label="Localidade"
            InputLabelProps={{ shrink: true }}
            value={location}
            onChange={handleChangeLocation}
            margin="none"
          />
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.subtitle}>Coordenadas</Typography>
        </Grid>

        <Grid container item xs={12} className={classes.fieldRow} spacing={16}>
          <Grid item xs={6}>
            <TextField
              className={classes.textField}
              id="lat-input"
              label="Latitude"
              value={lat}
              onChange={handleChangeLat}
              margin="none"
              placeholder="Informe uma latitude"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                maxLength: 20,
                type: "number",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.textField}
              id="lng-input"
              label="Longitude"
              value={long}
              onChange={handleChangeLong}
              margin="none"
              placeholder="Informe uma longitude"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                maxLength: 20,
                type: "number",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs
        direction="row"
        justify="flex-end"
        alignItems="center"
        className={classes.buttonsContainer}
      >
        {controlButtons}
      </Grid>
    </Grid>
  );
}

FormLocation.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  content: {
    alignContent: "space-between",
  },
  fieldRow: {
    marginTop: 24,
  },
  mensagem: {
    marginTop: 48,
    fontFamily: "Roboto",
    fontSize: "16px",
    color: "#212121",
  },
  textField: {
    width: "100%",
  },
  textFieldLng: {
    marginStart: 0,
  },
  subtitle: {
    marginTop: 48,
    fontFamily: "Roboto",
    fontSize: "14px",
    color: "#424242",
  },
  buttonsContainer: {
    padding: "32px 0px",
    marginBottom: -32,
  },
  button: {
    minWidth: 150,
  },
  saveButton: {
    marginStart: "24px",
  },
});

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  changeCenterCoordinates,
  retornaCoordenadas,
  notifyError,
  atualizaZoomMapa: setZoomMapa,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(FormLocation);
