const TEXTODETALHES = [
  {
    valor: 'Fechamento de Consumo',
    descricao: 'É a quantidade em kg de formulação abastecida no período, dividida pela quantidade de animais (diárias) que come a formulação no período filtrado.',
    style: { maxWidth: '600px' }
  },
  {
    valor: 'Consumo total',
    descricao: 'É a quantidade em Kg abastecidas.',
  },
  {
    valor: 'Consumo ocorrido',
    descricao: 'É a quantidade em grama que cada cabeça comeu por dia.',
  },
  {
    valor: 'Consumo objetivo',
    descricao: 'É a quantidade ideal em grama que cada cabeça deve consumir ao dia.',
  },
  {
    valor: 'Diária',
    descricao: 'Cada dia que um animal consumiu a formulação, é contado como uma diária.',
  },
];

const INFORMACAOBEZERROS = "Bezerros de idade 0 a 8 meses contam como meia diária.";

const PERIODO = "Período (dias):";

const CONSUMOTOTAL = "Consumo total (kg):";

const SEMCOLETAS = "Não foram realizadas coletas dessa formulação no periodo!";

const DIARIAS = "Diárias:";

const QUANTIDADEABASTECIDA = "Quantidade Abastecida:";

const CONSUMOOCORRIDO = "Consumo ocorrido (g/cab):";

const CONSUMOOBJETIVO = "Consumo objetivo (g/cab):";

export {
  TEXTODETALHES,
  INFORMACAOBEZERROS,
  PERIODO,
  CONSUMOTOTAL,
  SEMCOLETAS,
  DIARIAS,
  QUANTIDADEABASTECIDA,
  CONSUMOOCORRIDO,
  CONSUMOOBJETIVO,
};
