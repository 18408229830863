import HistoricoMovimentacaoService from "./historicoMovimentacaoService";
export class HistoricoMovimentacaoManager {
  async obterHistoricoMovimentacaoFazenda(
    fazendaSelecionada,
    offset,
    limit,
    filtro
  ) {
    const historicoMovimentacao =
      HistoricoMovimentacaoService.listaHistoricoMovimentacaoFazenda(
        fazendaSelecionada,
        offset,
        limit,
        filtro
      );
    return historicoMovimentacao;
  }

  async obterFiltrosHistoricoMovimentacao(fazendaSelecionada) {
    const filtrosHistoricoMovimentacao = await HistoricoMovimentacaoService
      .listarfiltrosHistoricoMovimentacao(fazendaSelecionada);
    return filtrosHistoricoMovimentacao;
  }
}

export default new HistoricoMovimentacaoManager();
