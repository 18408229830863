import React from 'react';
import GanhoPesoPage from './ganhoPesoPage';
import * as GanhoPesoOperations from '../redux/ganhoPesoOperations';
import { connect } from 'react-redux';

export const GanhoPesoContainer = props => {
  return (
    <GanhoPesoPage
      id="ganhoPesoPage"
      fazendaSelecionada={props.fazendaSelecionada}
      obterCurvasGanhoPeso={props.obterCurvasGanhoPeso}
      curvas={props.curvas}
      deletarCurva={props.deletarCurva}
      atualizarCurva={props.atualizarCurva}
      criarCurva={props.criarCurva}
    />
  );
};

const mapStateToProps = state => {
  return {
    fazendaSelecionada: state.fazenda.FazendaSelecionada,
    curvas: state.curvaGanhoPeso.curvas
  };
};

const mapDispatchToProps = {
  obterCurvasGanhoPeso: GanhoPesoOperations.obterCurvasGanhoPeso,
  criarCurva: GanhoPesoOperations.criarCurva,
  atualizarCurva: GanhoPesoOperations.atualizarCurva,
  deletarCurva: GanhoPesoOperations.deletarCurva
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GanhoPesoContainer);
