import React, { Component } from "react";
import { connect } from "react-redux";
import { default as LoadingComponent } from "./Loading";

export class Loading extends Component {
  render() {
    const { loading } = this.props;
    return <LoadingComponent id="containerLoading" loading={loading} />;
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
  };
};

export default connect(mapStateToProps)(Loading);
