import MovimentacaoService from "./movimentacaoService";
import CategoriaService from "../categoria/categoriaService";
import _ from "lodash";

export class MovimentacaoManager {
  async obterMovimentacoesFazenda(fazendaSelecionada) {
    const promiseMovimentacoes =
      await MovimentacaoService.obterMovimentacoesFazenda();
    let movimentacoes = promiseMovimentacoes.data;
    const promiseCategorias = await CategoriaService.listaCategoriasPorFazenda(
      fazendaSelecionada
    );
    let categorias = promiseCategorias.data;
    return this.enriqueceMovimentacoes(movimentacoes, categorias);
  }

  enriqueceMovimentacoes(movimentacoes, categorias) {
    return movimentacoes.map((m) => {
      const categoriaDaMovimentacao = categorias.find(
        (c) => c.IdCategoriaAnimal === m.IdCategoriaAnimal
      );

      m.CategoriaAnimal = categoriaDaMovimentacao
        ? categoriaDaMovimentacao.Nome
        : "";
      return m;
    });
  }

  async salvarMovimentacao(movimentacao) {
    const respostaService = await MovimentacaoService.adicionarMovimentacao(
      movimentacao
    );
    return respostaService.data;
  }

  async editarMovimentacao(movimentacao, IdRegistro) {
    movimentacao = _.pick(movimentacao, [
      "IdCategoriaAnimal",
      "TipoRegistro",
      "Quantidade",
      "DataRegistro",
      "Confirmado",
      "RendimentoDeCarcaca",
      "TipoMovimentacao",
      "PesoPesado",
    ]);
    await MovimentacaoService.editarMovimentacao(movimentacao, IdRegistro);
  }
  async excluirMovimentacao(IdRegistro) {
    await MovimentacaoService.deletarMovimentacao(IdRegistro);
  }
}

export default new MovimentacaoManager();
