import React from "react";
import {
  withStyles,
  Table,
  TablePagination,
  TableFooter,
  TableBody,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import {
  obterEstiloLinhaTabela,
  retirarAcentuacao,
  nomesColunas,
  camposEsperados,
} from "../conciliacaoUtil";
import PropTypes from "prop-types";
import ConciliacaoItemTabela from "./conciliacaoItemTabela";
import NomeColunasTabela from "./nomeColunasTabela";

export class ConciliacaoTabela extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paginaAtual: 0,
      linhasPorPagina: 10,
    };
  }

  alteraPagina = (pagina) => {
    this.setState({ paginaAtual: pagina });
  };

  alteraLinhasPorPagina = (linhasPorPagina) => {
    this.setState({ linhasPorPagina, paginaAtual: 0 });
  };

  defineEspacamentoColuna = (colunasTabela) => {
    const tamanhoColuna = "9%";
    return (
      <colgroup>
        {colunasTabela.map((i) => {
          return <col key={`${i}-width`} style={{ width: tamanhoColuna }} />;
        })}
      </colgroup>
    );
  };

  render() {
    const {
      classes,
      dadosConciliacao,
      exibirDescritivoModal,
      obterDescritivoDeColetas,
      minimizado,
    } = this.props;
    const { paginaAtual, linhasPorPagina } = this.state;
    const indexPrimeiroLote = paginaAtual * linhasPorPagina;
    const indexUltimoLote = indexPrimeiroLote + linhasPorPagina;
    let dadosConciliacaoPaginados = dadosConciliacao.slice(
      indexPrimeiroLote,
      indexUltimoLote
    );

    if (minimizado) {
      dadosConciliacaoPaginados = dadosConciliacao;
    }

    const camposUnicos = ["CategoriaAnimal", "Diferenca"];

    const camposUnicosApps = [
      "EntradaTrocaDeCategoria",
      "SaidaTrocaDeCategoria",
      "Nascimento",
      "Morte",
    ];

    return (
      <Paper className={classes.containerTable}>
        <Table className={classes.table}>
          {this.defineEspacamentoColuna(nomesColunas)}
          <TableHead id="cabecalhoTabela">
            <TableRow style={{ backgroundColor: "#e1e1e1" }}>
              <NomeColunasTabela
                tabelaHeaderContent={
                  minimizado
                    ? classes.tabelaHeaderContentMini
                    : classes.tabelaHeaderContent
                }
                nomesColunas={nomesColunas}
                minimizado={minimizado}
              />
            </TableRow>
          </TableHead>
          <TableBody key="dadosTabela" id="dadosTabela">
            {dadosConciliacaoPaginados.map((dado, i) => (
              <TableRow
                key={`${i}-linhasTabela`}
                id={`${i}-linhasTabelaId`}
                className={classes.gridTableRow}
                style={obterEstiloLinhaTabela(i)}
              >
                {camposEsperados.map((campo, keyCampo) => {
                  campo = retirarAcentuacao(campo);
                  let valoresUnicos, valorApp, valorPortal;
                  if (dado[campo] || typeof dado[campo] === "number") {
                    valoresUnicos = dado[campo]["Nome"] || dado[campo];
                    valorApp =
                      dado[campo] && typeof dado[campo].App === "number"
                        ? dado[campo].App
                        : valoresUnicos;
                    valorPortal =
                      typeof dado[campo] === "object"
                        ? dado[campo].Portal
                        : valoresUnicos;
                  }

                  return (
                    <ConciliacaoItemTabela
                      fieldName={campo}
                      valorApp={campo === "Origem" ? "Aplicativo" : valorApp}
                      valorPortal={campo === "Origem" ? "Portal" : valorPortal}
                      camposUnicos={camposUnicos}
                      camposUnicosApps={camposUnicosApps}
                      uniqueKey={keyCampo}
                      exibirDescritivoModal={exibirDescritivoModal}
                      obterDescritivoDeColetas={obterDescritivoDeColetas}
                      dado={dado}
                      minimizado={minimizado}
                    />
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
          {!minimizado && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={dadosConciliacao ? dadosConciliacao.length : 0}
                  page={paginaAtual}
                  rowsPerPage={linhasPorPagina}
                  rowsPerPageOptions={[10, 20, 50]}
                  labelRowsPerPage={"Linhas por página"}
                  onChangePage={(event, page) => this.alteraPagina(page)}
                  onChangeRowsPerPage={(event) =>
                    this.alteraLinhasPorPagina(event.target.value)
                  }
                ></TablePagination>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </Paper>
    );
  }
}

ConciliacaoTabela.propTypes = {
  dadosConciliacao: PropTypes.array.isRequired,
};

export const styles = (theme) => {
  return {
    table: {
      width: "100%",
      marginTop: "25px",
      marginBottom: "25px",
      fontFamily: "Roboto",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "1px",
      borderRadius: "0 0 16px 16px",
    },
    tabelaHeaderContent: {
      fontSize: "14px",
      fontWeight: "500",
      color: theme.palette.primary.main,
      paddingLeft: "8px"
    },
    tabelaHeaderContentMini: {
      fontSize: "12px",
      fontWeight: "500",
      color: theme.palette.primary.main,
      textAlign: "center",
    },
    campoData: {
      width: "90%",
      paddingTop: "15px",
      opacity: "0.9",
      fontSize: "8px",
    },
    gridTableRow: {
      height: "66px",
      maxHeight: 66,
      fontFamily: "Roboto",
    },
    input: {
      width: "85%",
      paddingTop: "15px",
    },
    containerTable: {
      borderRadius: "16px",
    },
  };
};

export default withStyles(styles)(ConciliacaoTabela);
