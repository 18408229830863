import { withStyles } from "@material-ui/core";
import React from "react";

const PendencyListHeader = ({
  classes
}) => {
  return (<div>
    <h1 className={classes.title}>Movimentações pendentes</h1>
    <p className={classes.description}>As movimentações listadas abaixo não puderam ser registradas, pois sincronizações anteriores moveram os animais de pasto.</p>
  </div>)
}

const styles = (theme) => ({
  title: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 24,
  },
  description: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.default1.light,
  }
})

export default withStyles(styles)(PendencyListHeader);
