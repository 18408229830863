import React from "react";
import { withStyles } from "@material-ui/core";
export const fakeContent = (props) => {
  const { classes, content, showContent, height, width } = props;

  return (
    <React.Fragment>
      {!showContent ? (
        <span
          className={classes.animatedBackground}
          style={{ height: height, width: width, ...props.style }}
        />
      ) : (
        content || props.children
      )}
    </React.Fragment>
  );
};

const styles = (theme) => {
  return {
    "@keyframes placeHolderShimmer": {
      "0%": {
        backgroundPosition: "-468px 0",
      },
      "100%": {
        backgroundPosition: "468px 0",
      },
    },
    animatedBackground: {
      animationDuration: "1s",
      animationFillMode: "forwards",
      animationIterationCount: "infinite",
      animationName: "placeHolderShimmer",
      animationTimingFunction: "linear",
      // background: '#f6f7f8',
      background:
        "linear-gradient(to right, #cccccc 9%, #bbbbbb 19%, #cccccc 34%)",
      backgroundSize: "800px 104px",
      height: 96,
      position: "relative",
      display: "inline-block",
    },
  };
};

fakeContent.defaultProps = {
  content: null,
  showContent: false,
};
export default withStyles(styles)(fakeContent);
