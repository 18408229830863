import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import AjusteEscorePage from './ajusteEscorePage';
import * as AjusteEscoreOperations from '../redux/ajusteEscoreOperations';

export class AjusteEscoreContainer extends PureComponent {

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    await this.props.listarAjustesEscore();
  }

  render = () => {
    const {
      listarAjustesEscore,
      salvarAjustesEscore,
      excluirAjustesEscore,
      ajustesEscoreFazenda,
      idFazenda
    } = this.props;

    return (
      <AjusteEscorePage
        id='ajusteEscorePage'
        listarAjustesEscore={listarAjustesEscore}
        salvarAjustesEscore={salvarAjustesEscore}
        excluirAjustesEscore={excluirAjustesEscore}
        ajustesEscoreFazenda={ajustesEscoreFazenda}
        idFazenda={idFazenda}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    ajustesEscoreFazenda: state.ajusteEscore.ajustesEscoreFazenda,
    idFazenda: state.fazenda.FazendaSelecionada
  };
};

const mapDispatchToProps = {
  listarAjustesEscore: AjusteEscoreOperations.listarAjustesEscoreFazenda,
  salvarAjustesEscore: AjusteEscoreOperations.salvarAjustesEscoreFazenda,
  excluirAjustesEscore: AjusteEscoreOperations.excluirAjustesEscoreFazenda
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AjusteEscoreContainer);
