import {
  notifyError as notifyErrorAction,
  notifySuccess as notifySuccessAction,
} from "../../../redux/actions";
import { exibirCarregando as showLoading } from "../../../redux/actions/loadingAction";

export const notifyError = (mensagem) => async (dispatch) => {
  dispatch(notifyErrorAction({ mensagem }));
};

export const notifySuccess = (message) => async (dispatch) => {
  dispatch(
    notifySuccessAction({
      mensagem: message,
      variant: "success",
    })
  );
};

export const enableLoading = (loadingName) => async (dispatch, getState) => {
  const { loading } = getState();
  const { ExibirCarregando } = loading;
  const loadingRequests = Array.isArray(ExibirCarregando)
    ? [...ExibirCarregando, loadingName]
    : [loadingName];

  dispatch(showLoading([...loadingRequests]));
};

export const disableLoading = (loadingName) => async (dispatch, getState) => {
  const { loading } = getState();
  const { ExibirCarregando } = loading;

  if (Array.isArray(ExibirCarregando)) {
    const loadingNameIndex = ExibirCarregando.indexOf(loadingName);
    if (loadingNameIndex < 0) return;

    const loadingRequests = ExibirCarregando.filter(
      (_, index) => index !== loadingNameIndex
    );
    dispatch(showLoading([...loadingRequests]));
  } else {
    dispatch(showLoading(false));
  }
};
