import BaseService from "../../services/BaseService";
import { convertGoalData } from "./objetivoUtils";

const URL_BASE = `${process.env.REACT_APP_ENDPOINT}herds/goals`;

export class ObjetivoService extends BaseService {
  async getGoals(farmId, withDeleted = true) {
    const response = await this.get(
      `${URL_BASE}/${farmId}?withDeleted=${withDeleted}`
    );
    return convertGoalData(response.data.data);
  }

  async createGoal(goal) {
    const response = await this.post(URL_BASE, goal);
    return response.data;
  }

  async updateGoal(id, goal) {
    const response = await this.patch(`${URL_BASE}/${id}`, goal);
    return response;
  }

  async deleteGoal(id) {
    const response = await this.delete(`${URL_BASE}/${id}`);
    return response.data;
  }

  async reactivatingGoal(id) {
    const response = await this.patch(`${URL_BASE}/${id}/reactivate`);
    return response;
  }
}

export default new ObjetivoService();
