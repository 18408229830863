import React, { Component } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import { Card, CardContent, withStyles, Typography } from "@material-ui/core";
import { getIconCardConfiguracao } from "../configuracaoUtils";

export class ConfiguracaoCard extends Component {
  render() {
    const { classes, item, openItem } = this.props;
    const imageCard = "../images/" + getIconCardConfiguracao(item.label);
    const imageNew = "../images/ico-novo.svg";
    return (
      <React.Fragment>
        <Card className={classes.containerPage}>
          <CardContent
            id={item.id}
            className={classes.cardContent}
            onClick={() => openItem(item.label)}
          >
            <div className={classes.iconeContent}>
              <div className={classes.iconeFundo}>
                <img src={imageCard} alt="logo card" />
              </div>
            </div>
            <div>
              <div className={classes.titleWrapper}>
                <Typography
                  id="cardTitulo"
                  variant="h4"
                  noWrap
                  className={classes.tituloCard}
                >
                  {item.label}
                </Typography>
                {item.hasNewBadge && (
                  <img
                    src={imageNew}
                    alt="card-novo"
                    className={classes.newBadge}
                  ></img>
                )}
              </div>

              <Typography variant="body1" className={classes.descricaoCard}>
                <LinesEllipsis
                  id="cardDescricao"
                  text={item.descricao || ""}
                  maxLine="4"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              </Typography>
            </div>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
}

const styles = (theme) => {
  return {
    containerPage: {
      minWidth: 300,
      minHeight: 160,
      width: 300,
      height: 160,
      marginTop: "40px",
      borderRadius: 6,
      backgroundColor: "#FAFAFA",
    },
    cardContent: {
      padding: "13px",
      cursor: "pointer",
      display: "flex",
      flexWrap: "nowrap",
    },
    titleWrapper: {
      display: "flex",
    },
    tituloCard: {
      fontFamily: "Roboto",
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: "-0.03px",
      color: theme.palette.primary.main,
      margin: "8px 0 13px 0",
      textOverflow: "ellipsis",
      whiteSpace: "normal",
    },
    newBadge: {
      height: 26,
      margin: "8px 0 13px 6px",
    },
    descricaoCard: {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 300,
      lineHeight: 1.43,
      letterSpacing: "-0.03px",
      color: "#2a2a2a",
    },
    iconeContent: {
      marginRight: 15,
    },
    iconeFundo: {
      width: 40,
      height: 40,
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
};

export default withStyles(styles)(ConfiguracaoCard);
