import React from "react";
import { Modal, Button, withStyles } from "@material-ui/core";
import moment from "moment";

export const ModalExclusao = (props) => {
  const { classes, pesagemSelecionada, excluirOuCancelar } = props;
  const textoDescricao = `${pesagemSelecionada.Nome} _ ${moment(
    pesagemSelecionada.DataPesagem
  ).format("DD/MM/YYYY")}`;
  return (
    <Modal
      className={classes.modal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={true}
    >
      <div className={classes.modalContainer}>
        <div id="containerModalLote" className={classes.modalConteudo}>
          <div item className={classes.titulo}>
            Confirmar exclusão da Pesagem
          </div>
          <div data-testid="textoExclusao" item className={classes.data}>
            Você deseja <b>excluir</b> o registro de pesagem{" "}
            <b>{textoDescricao}?</b>
          </div>
          <div style={{ alignSelf: "flex-end", marginRight: "5px" }}>
            <Button
              className={classes.botoes}
              onClick={() => excluirOuCancelar(false)}
              style={{ color: "#333333" }}
              data-testid="cancelarExclusao"
            >
              CANCELAR
            </Button>
            <Button
              className={classes.botoes}
              onClick={() => excluirOuCancelar(true)}
              color="primary"
              data-testid="confirmarExclusao"
            >
              SIM
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const styles = () => {
  return {
    modal: {
      zIndex: "0",
    },
    modalContainer: {
      display: "flex",
      height: "100%",
    },
    modalConteudo: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      width: "348px",
      height: "200px",
      backgroundColor: "#ffffff",
      outline: "none",
      boxShadow: "0 2px 20px 0 rgba(0, 0, 0, 0.5)",
      borderRadius: "3px",
    },
    data: {
      fontFamily: "Roboto",
      letterSpacing: "0.2px",
      fontSize: "16px",
      margin: "10px",
      color: "rgba(51, 50, 56, 0.84)",
      lineHeight: 1.5,
    },
    titulo: {
      margin: "10px",
      alignSelf: "center",
      fontFamily: "Roboto",
      fontSize: "20px",
      color: "#333",
      fontWeight: 500,
    },
    botoes: {
      fontSize: "14px",
      margin: "20px 5px 30px 0px",
    },
  };
};

export default withStyles(styles)(ModalExclusao);
