import { IconButton, Tooltip, withStyles } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import React from "react";

const InactiveMessage =
  "Não é possível editar um planejamento fora de vigência.";

const PlanningEditButton = ({ onClick, initialDate, finalDate, classes }) => {
  const isInactive =
    initialDate && finalDate && new Date(finalDate) < new Date();

  const messageTooltip = isInactive ? InactiveMessage : "Editar";

  const handleClick = (event) => {
    event.stopPropagation();
    onClick && onClick();
  };

  return (
    <Tooltip
      title={messageTooltip}
      placement="left"
      classes={{ tooltip: classes.tooltip }}
    >
      <div className={classes.containerButton}>
        <IconButton
          data-testid="edit-planning-button"
          disabled={isInactive}
          onClick={handleClick}
        >
          <Edit />
        </IconButton>
      </div>
    </Tooltip>
  );
};
const styles = {
  containerButton: {
    width: "fit-content",
    margin: "auto",
  },
  tooltip: {
    padding: 16,
    maxWidth: 200,
    fontSize: 14,
  },
};

export default withStyles(styles)(PlanningEditButton);
