import React from "react";
import GoalPlanContent from "features/goalPlan/views/shared/goalPlanContent";
import GoalPlanDetailsHeader from "features/goalPlan/views/goalPlanRevision/components/goalPlanDetails/goalPlanDetailsHeader";
import GoalsRegisterTable from "features/goalPlan/views/shared/tableFormComponents/goalsTable";
import { renderTextOrComponent } from "features/goalPlan/goalPlanUtils";

const GoalPlanDetailsPage = ({
  onBack,
  title,
  goalPlanData,
  badge,
  goalsTableList,
  columns,
}) => {
  const titleToRender = renderTextOrComponent(title, goalPlanData);
  const badgeToRender = renderTextOrComponent(badge, goalPlanData);

  return (
    <GoalPlanContent
      data-testid="revision-plan-content"
      title="Voltar"
      onBack={onBack}
    >
      <GoalPlanDetailsHeader
        title={titleToRender}
        badge={badgeToRender && badgeToRender}
        revisionPlan={goalPlanData}
      />
      <GoalsRegisterTable
        renderTable
        goalsTableList={goalsTableList}
        columns={columns}
      />
    </GoalPlanContent>
  );
};

export default GoalPlanDetailsPage;
