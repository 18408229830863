import React, { useState } from "react";
import { withStyles } from "@material-ui/core";
import BackIcon from "@material-ui/icons/ArrowBack";
import ActionButtons from "features/shared/components/actionButtons";
import ConfirmationModal from "features/shared/components/confirmationModal";
import pendencyService from "features/pendency/pendencyService";
import ReportGmailerrorred from "@mui/icons-material/ReportGmailerrorred";

const DISCARD_BUTTON_TEXT = "Descartar pendência";
const EDIT_BUTTON_TEXT = "Editar pendência";
const MODAL_TEXT =
  "Ao descartar a pendência, ela desaparecerá da lista e não poderá mais ser resolvida. Tem certeza de que deseja sair e descartar a pendência?";

const PendencyDetailsHeader = ({
  classes,
  movementGrouper,
  onGoBack,
  goToEdit,
  history,
  enableButtonSubmit = true,
}) => {
  const [pendencyToRemove, setPendencyToRemove] = useState(null);

  const discardPendency = () => {
    setPendencyToRemove(true);
  };
  const onCancel = () => {
    setPendencyToRemove(false);
  };

  const modalTitle = (classes) => {
    return (
      <div className={classes.titleContaier}>
        <ReportGmailerrorred className={classes.warningIcon} />
        Descartar pendência
      </div>
    );
  };

  const handleSubmit = async () => {
    try {
      const response = await pendencyService.removePendency(movementGrouper);
      if (response.status === 200) {
        history.replace("/movimentacoesPendentes");
      }
    } catch (error) {
      console.log("erro", error);
    }
  };

  return (
    <div className={classes.goBackContainer}>
      <div
        data-testid="TestGoBack"
        className={classes.goBackButton}
        onClick={onGoBack}
      >
        <BackIcon className={classes.iconBack} />
        <h1 className={classes.goBackText}>Voltar</h1>
      </div>
      <div style={{ marginTop: "-50px" }}>
        <ActionButtons
          classes={{ actionButtonContainer: styles.actionButtonContainer }}
          textButtonSubmit={EDIT_BUTTON_TEXT}
          textButtonGoBack={DISCARD_BUTTON_TEXT}
          onSubmit={goToEdit}
          onBack={discardPendency}
          enableButtonSubmit={enableButtonSubmit}
        />
      </div>
      {pendencyToRemove && (
        <ConfirmationModal
          classes={classes}
          onCancel={onCancel}
          onSubmit={handleSubmit}
          title={modalTitle(classes)}
          text={MODAL_TEXT}
          submitButtonTitle={"Sim, sair e descartar"}
        />
      )}
    </div>
  );
};

const styles = (theme) => ({
  goBackContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "30px",
    marginTop: "8px",
  },
  goBackText: {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "bold",
    color: "#444",
  },
  goBackButton: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
  },
  iconBack: {
    color: theme.palette.primary.main,
    marginRight: "12px",
  },
  actionButtonContainer: {
    height: 36,
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    gap: "16px",
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "40%",
    height: "auto",
    maxWidth: "358px",
    backgroundColor: "#ffffff",
    outline: "none",
    boxShadow: "0 2px 20px 0 rgba(0, 0, 0, 0.5)",
    borderRadius: "18px",
    padding: "16px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    padding: "0px 0px 0px 30px",
  },
  titleContaier: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    textAlign: "left",
    color: "#333",
  },
  title: {
    marginBottom: "13px",
  },
  warningIcon: {
    fontSize: "14px",
    color: "#eb394b",
  },
  text: {
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    marginBottom: "30px",
  },
  buttonCancel: {
    fontSize: "12px",
    backgroundColor: "#FFFFFF",
    background: "transparent",
    color: "#333",
    height: 22,
    border: "none",
    padding: "0px 6px",
  },
  buttonSubmit: {
    fontSize: "12px",
    marginLeft: "8px",
    lineHeight: "16px",
    height: 22,
    color: "#eb394b",
    backgroundColor: "#FFFFFF",
    padding: "0px 6px",
  },
});
export default withStyles(styles)(PendencyDetailsHeader);
