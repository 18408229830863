import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core/";
import {
  padronizarMensagensProjecao,
  DADO_INDISPONIVEL,
} from "../../../loteUtils";

export const TabelaGanhoPeso = ({ classes, projecaoPesoSemestre }) => {
  const mensagensProjecaoPeso =
    padronizarMensagensProjecao(projecaoPesoSemestre);
  return (
    <Table className={classes.tabela}>
      <TableHead id="cabecalhoTableGanhoPeso">
        <TableRow style={{ height: "44px", maxHeight: "44px" }}>
          <TableCell align="left" className={classes.headerMes}>
            Data
          </TableCell>
          <TableCell align="left" className={classes.headerPeso}>
            Peso Esperado (kg)
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody id="bodyTableGanhoPeso">
        {mensagensProjecaoPeso.map((mensagem) => {
          return (
            <TableRow
              key={mensagem.key}
              style={{ height: "40px", maxHeight: "44px" }}
            >
              <TableCell align="left" className={classes.conteudoMes}>
                {mensagem.Mes}
              </TableCell>
              <TableCell align="left" className={classes.conteudoPeso}>
                {mensagem.Peso ? mensagem.Peso : DADO_INDISPONIVEL}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const styles = (theme) => {
  return {
    tabela: {
      width: "343px",
      height: "279px",
      margin: "0 0",
      padding: "0",
      borderRadius: "3px",
      border: "solid 0.5px #d1d1d6",
      fontSize: "14px",
      letterSpacing: "-0.03px",
      color: "#666666",
      fontFamily: "Roboto",
    },
    headerMes: {
      height: "auto",
      width: "141px",
      padding: "4px 30px 4px 24px!important",
      fontFamily: "Roboto",
      fontSize: 14,
      color: theme.palette.primary.main,
      backgroundColor: "#e1e1e1",
      textTransform: "uppercase",
      border: "solid 0.5px #e1e1e1",
    },
    headerPeso: {
      height: "auto",
      width: "200px",
      fontFamily: "Roboto",
      fontSize: 14,
      padding: "4px 10px 4px 20px!important",
      color: theme.palette.primary.main,
      textTransform: "uppercase",
      backgroundColor: "#e1e1e1",
      border: "solid 0.5px #e1e1e1",
    },
    conteudoMes: {
      height: "auto",
      backgroundColor: "#fafafa",
      padding: "4px 30px 4px 24px!important",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#666666",
      border: "solid 0.5px #e5e5ea",
    },
    conteudoPeso: {
      height: "auto",
      fontFamily: "Roboto",
      padding: "4px 10px 4px 20px!important",
      fontSize: "14px",
      fontWeight: "bold",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#666666",
      border: "solid 0.5px #e5e5ea",
    },
  };
};

export default withStyles(styles)(TabelaGanhoPeso);
