import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import { FazendaSelecionadaContainer } from "../../shared/crossCuttingComponents";
import { AppBarConteudoDefault } from "../../layout/";
import HistoricoMovimentacaoAppPrintTemplate from "./movementHistoryPrint/components/historicoMovimentacaoAppPrintTemplate";
import MovementHistoryFilterContainer from "./movementHistoryFilter/movementHistoryFilterContainer";
import MovementHistoryListContainer from "./movementHistoryList/movementHistoryListContainer";
import MovementHistoryModal from "./shared/movementHistoryModal";
import ContainerMovements from "features/shared/components/ContainerMovements";

class HistoricoMovimentacaoAppPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPagina: 10,
      filtros: {},
    };
  }

  async componentDidMount() {
    const { obtemFiltrosHistoricoMovimentacao } = this.props;
    await obtemFiltrosHistoricoMovimentacao();
    await this.carregamentoInicial();
  }

  renderConteudoCustomizadoAppBar = () => {
    return (
      <FazendaSelecionadaContainer
        render={(fazenda) => {
          const textoSecundarioAppBar = fazenda
            ? fazenda.NomeReduzido
            : "NENHUMA FAZENDA SELECIONADA";
          return <AppBarConteudoDefault tituloAppBar={textoSecundarioAppBar} />;
        }}
      />
    );
  };

  carregamentoInicial = async (page = 0, totalPagina = 10) => {
    const { obterHistoricoMovimentacaoFazenda, filtrosSelecionados } =
      this.props;
    await obterHistoricoMovimentacaoFazenda(
      page,
      totalPagina,
      filtrosSelecionados
    );
  };

  handlerTotalPagina = (totalPagina) => {
    this.setState({ totalPagina: totalPagina });
  };

  filtrarHistorioMovimentacao = async (filtro) => {
    const { obterHistoricoMovimentacaoFazenda, atualizaFiltroSelecionado } =
      this.props;
    atualizaFiltroSelecionado(filtro);
    await obterHistoricoMovimentacaoFazenda(0, this.state.totalPagina, filtro);
  };

  filtrarHistorioMovimentacaoSemPaginacao = async (filtro) => {
    const { obterHistoricoMovimentacaoFazenda, atualizaFiltroSelecionado } =
      this.props;
    atualizaFiltroSelecionado(filtro);
    this.setState({ filtros: filtro });
    await obterHistoricoMovimentacaoFazenda(0, 99999, filtro, true);
  };

  montarDadosGA = (clique = true) => {
    const localFazendas = this.props.fazenda;
    const idFazendaSelecionada = this.props.fazenda.FazendaSelecionada;

    const fazendaSelecionada = localFazendas.Fazendas.find(
      (fazenda) => fazenda.IdFazenda === idFazendaSelecionada
    );
    const emailUsuario = localStorage.getItem("Email");
    const inicioEmailUsuario = emailUsuario.split("@")[0];
    const finalEmailUsuario = emailUsuario.split("@")[1];

    const textoAcao = clique ? "Clique no filtro do" : "Acesso";

    const acao = `${textoAcao} Histórico de movimentação de Rebanho do Aplicativo:
    inicio do email do usuário: ${inicioEmailUsuario}
    final do email do usuário: ${finalEmailUsuario}
    Fazenda: ${fazendaSelecionada.Nome}
    IdFazenda: ${idFazendaSelecionada}`;
    const rotulo = "Histórico de movimentação de Rebanho do Aplicativo";
    const categoria = "Histórico de movimentação de Rebanho do Aplicativo";

    this.props.enviaDadosGAPorRotulo(acao, rotulo, categoria);
  };

  render() {
    const {
      classes,
      filtrosHistoricoMovimentacao,
      historicoMovimentacao,
      estadoModal,
      alterarEstadoModal,
      historicoMovimentacaoSemPaginacao,
      fazenda,
      history,
      match
    } = this.props;

    this.montarDadosGA(false);

    return (
      <ContainerMovements
        history={history}
        match={match}
      >
        <div className={classes.historicoContainer}>
          <div style={{ width: "100%" }}>
            <MovementHistoryFilterContainer
              filtrosHistoricoMovimentacao={filtrosHistoricoMovimentacao}
              componentRef={this.componentRef}
              filtrarHistorioMovimentacaoSemPaginacao={
                this.filtrarHistorioMovimentacaoSemPaginacao
              }
              filtrarHistorioMovimentacao={this.filtrarHistorioMovimentacao}
              montarDadosGA={this.montarDadosGA}
            />
          </div>
          <MovementHistoryListContainer
            historicoMovimentacao={historicoMovimentacao}
            carregamentoInicial={this.carregamentoInicial}
            handlerTotalPagina={this.handlerTotalPagina}
          />
        </div>
        <MovementHistoryModal
          exibirDescritivoModal={estadoModal}
          aoFechar={() => alterarEstadoModal(estadoModal)}
        />
        <div style={{ display: "none" }}>
          <HistoricoMovimentacaoAppPrintTemplate
            ref={(el) => (this.componentRef = el)}
            listaMovimentacao={historicoMovimentacaoSemPaginacao}
            filtrosSelecionados={this.state.filtros}
            fazenda={fazenda}
            filtrosHistoricoMovimentacao={filtrosHistoricoMovimentacao}
          />
        </div>
      </ContainerMovements>
    );
  }
}

const styles = () => {
  return {
    historicoContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      overflowX: "auto",
      zIndex: -1,
      backgroundColor: "#fff",
    },
    TextoLinkAppBar: {
      cursor: "pointer",
      fontWeight: "normal",
      fontSize: "24px",
      letterSpacing: "-0.05px",
      fontFamily: "Roboto",
      color: "#2a2a2a",
    },
    appBar: {
      backgroundColor: "#f8f8f8",
      padding: "0px 30px",
      width: "100%",
      minHeight: 60,
      display: "flex",
      alignItems: "center",
    },
    textoAppBar: {
      fontWeight: "normal",
      fontSize: "24px",
      margin: "0!important",
      padding: "0!important",
      letterSpacing: "-0.05px",
    },
  };
};

export default withStyles(styles)(HistoricoMovimentacaoAppPage);
