import {
  LISTAR_CONCILIACOES_FAZENDA,
  LISTAR_OPERACOES_POR_CATEGORIA_FAZENDA,
} from "./conciliacaoActions";

export const INITIAL_STATE = {
  Conciliacoes: [],
  OperacoesCategoria: [],
};

export default (state = INITIAL_STATE, action) => {
  if (action.type === LISTAR_CONCILIACOES_FAZENDA) {
    return {
      ...state,
      Conciliacoes: action.payload.ConciliacoesFazenda,
    };
  } else if (action.type === LISTAR_OPERACOES_POR_CATEGORIA_FAZENDA) {
    return {
      ...state,
      OperacoesCategoria: action.payload.OperacoesCategoria,
    };
  }
  return state;
};
