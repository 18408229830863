/* global google */
import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { PolygonF } from "@react-google-maps/api";

let listenerSetAt;
let listenerInsertAt;
let listenerRemoveAt;

export const Poligono = ({
  onEditandoPoligono,
  onMouseOverChange,
  onClick,
  coordenadas,
  coordenadasEditando,
  pastoSelecionado,
  pasto,
  raised,
  highlight,
  block,
  destacarModulo,
  corLinha,
  cor,
  editando,
}) => {

  const polygonRef = useRef(null);

  const EditandoPoligono = (poly) => {

    const polyArray = poly.getPath().getArray();
    var area = poly.getPath()
      ? (
        google.maps.geometry.spherical.computeArea(poly.getPath()) / 10000
      ).toFixed(1)
      : 0;
    let paths = [];
    polyArray.forEach(function (path) {
      paths.push({ lat: path.lat(), lng: path.lng() });
    });
    onEditandoPoligono(paths, area);
  };

  useEffect(() => {

    if (pastoSelecionado && pasto.IdPasto === pastoSelecionado.IdPasto) {
      google.maps.event.removeListener(listenerSetAt);
      google.maps.event.removeListener(listenerInsertAt);
      google.maps.event.removeListener(listenerRemoveAt);

      if (editando) {

        listenerSetAt = google.maps.event.addListener(
          polygonRef.current.getPath(),
          "set_at",
          () => EditandoPoligono(polygonRef.current)
        );
        listenerInsertAt = google.maps.event.addListener(
          polygonRef.current.getPath(),
          "insert_at",
          () => EditandoPoligono(polygonRef.current)
        );
        listenerRemoveAt = google.maps.event.addListener(
          polygonRef.current.getPath(),
          "remove_at",
          () => EditandoPoligono(polygonRef.current)
        );
      }
    }


  }, [editando, coordenadasEditando]);


  const coordenadasPasto =
    pasto && pasto.IdPasto === pastoSelecionado.IdPasto
      ? coordenadasEditando && coordenadasEditando.length > 0
        ? coordenadasEditando
        : coordenadas
      : coordenadas;

  return (
    <PolygonF
      onLoad={(polygon) => {
        polygonRef.current = polygon;
      }}
      onMouseOut={(e) => {
        onMouseOverChange && onMouseOverChange(false)
      }}
      onClick={(e) => onClick && onClick(e)}
      paths={coordenadasPasto}
      options={{

        editable: editando ? editando : false,
        zIndex: raised ? 3 : -3,
        fillOpacity: highlight
          ? 1.0
          : block
            ? 0.2
            : destacarModulo
              ? 0.6
              : 0.5,
        strokeColor: corLinha || "#000000",
        strokeOpacity: corLinha ? 1 : 0.4,
        strokeWeight: corLinha && destacarModulo ? 3.2 : 0.6,
        fillColor: cor,

      }}
      editable={editando ? editando : false}
      onMouseMove={(e) => {
        if (highlight) {
          if (e.domEvent.target.localName === "img") {
            onMouseOverChange && onMouseOverChange(false);
          }
        } else {
          if (e.domEvent.target.localName === "div") {
            onMouseOverChange && onMouseOverChange(true);
          }
        }
      }}

    />
  );

}
export default connect(null, null)(Poligono);
