import React from "react";
import { TextLabel, FeedBackPage } from "../../../../shared/components";
import { Grid, withStyles } from "@material-ui/core";

const UsersListEmpty = ({ isFilterEmpty, classes }) => {
  const MessageText = () =>
    isFilterEmpty ? (
      <>
        Você ainda não possui nenhum usuário cadastrado. Clique no botão{" "}
        <span className={classes.textAccent}>"Criar novo usuário"</span> para
        começar.
      </>
    ) : (
      <>Não encontramos resultados para a busca. Por favor, tente novamente</>
    );
  return (
    <Grid
      container
      direction="column"
      justify="center"
      style={{
        minHeight: "60vh",
      }}
    >
      <FeedBackPage>
        <Grid container direction="column" alignItems="center">
          <img src="../images/busca.png" alt="Lupa de tela vazia" />
          <TextLabel
            id="labelFeedBack"
            inputProps={{ "data-testid": "label-feedBack" }}
            name="usuariosSemItens"
            block
            color="default2"
            customStyle={{ maxWidth: 400, paddingTop: 10, textAlign: "center" }}
          >
            <MessageText />
          </TextLabel>
        </Grid>
      </FeedBackPage>
    </Grid>
  );
};

const styles = (theme) => ({
  textAccent: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
});

export default withStyles(styles)(UsersListEmpty);
