import React, { PureComponent } from "react";
import { NavegacaoTabs } from "../../navegacao/index";
import { getTabsConfiguracao } from "../configuracaoUtils";
import ConfiguracaoTabContent from "./configuracaoTabContent";
import { FazendaSelecionadaContainer } from "../../shared/crossCuttingComponents";
import { AppBar as AppBarCustomizado } from "../../layout/index";
import { AppBarConteudoDefault } from "../../layout/";
import { withStyles } from "@material-ui/core";

export class ConfiguracaoTabs extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      shouldInterceptOnChangeTab: false,
      changeTabRequested: false,
      tabsConfiguracao: getTabsConfiguracao(this.props),
    };
  }

  changeInterceptOnChangeTab = (shouldInterceptOnChangeTab) => {
    this.setState({ shouldInterceptOnChangeTab });
  };

  handleMudarAbaConfiguracao = (evento) => {
    if (this.state.shouldInterceptOnChangeTab) {
      this.setState({
        changeTabRequested: true,
        tabToChange: evento.target.textContent,
      });
    } else {
      this.props.selecionaAbaCadastro(evento.target.textContent);
      this.setState({
        shouldInterceptOnChangeTab: false,
        changeTabRequested: false,
        tabToChange: null,
      });
    }
  };

  onCancelChangeTab = () => {
    this.setState({
      changeTabRequested: false,
      tabToChange: null,
    });
  };

  confirmChangeTab = () => {
    this.props.selecionaAbaCadastro(this.state.tabToChange);
    this.setState({
      shouldInterceptOnChangeTab: false,
      changeTabRequested: false,
      tabToChange: null,
    });
  };

  renderConteudoCustomizadoAppBar = () => {
    return (
      <FazendaSelecionadaContainer
        render={(fazenda) => {
          const textoSecundarioAppBar = fazenda
            ? fazenda.NomeReduzido
            : "NENHUMA FAZENDA SELECIONADA";
          return <AppBarConteudoDefault tituloAppBar={textoSecundarioAppBar} />;
        }}
      />
    );
  };

  componentDidMount() {
    const { abaSelecionada } = this.props;
    this.setState((state) => ({
      tabsConfiguracao: state.tabsConfiguracao.sort((a) =>
        a.label === abaSelecionada ? -1 : 1
      ),
    }));
  }

  render() {
    const { tabsConfiguracao } = this.state;
    const { abaSelecionada } = this.props;
    const styles = {
      backgroundColor: "#f8f8f8",
      minHeight: 60,
      display: "flex",
      alignItems: "center",
      boxShadow: "unset",
    };

    return (
      <>
        <AppBarCustomizado
          classes={this.props.classes}
          conteudoCustomizadoAppBar={this.renderConteudoCustomizadoAppBar()}
          id="appBarPersonalizado"
        />
        <NavegacaoTabs
          tabs={tabsConfiguracao}
          mudarTab={this.handleMudarAbaConfiguracao}
          abaSelecionada={abaSelecionada}
          estilosTab={{ fontSize: 14 }}
          styles={styles}
        />
        <div style={{ width: "100%" }}>
          <ConfiguracaoTabContent
            abaSelecionada={abaSelecionada}
            confirmChangeTab={this.confirmChangeTab}
            onCancelChangeTab={this.onCancelChangeTab}
            changeInterceptOnChangeTab={this.changeInterceptOnChangeTab}
            changeTabRequested={this.state.changeTabRequested}
            tabToChange={this.state.tabToChange}
            {...this.props}
          />
        </div>
      </>
    );
  }
}

const styles = {
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
  },
};

export default withStyles(styles)(ConfiguracaoTabs);
