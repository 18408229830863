import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Tooltip from "./Tooltip";
import Poligono from "./Poligono";
import * as actions from "redux/actions";
import CalculadoraCoordenadas from "lib/calculadoraCoordenadas";
import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { Marker } from "@react-google-maps/api";

export class PoligonoPasto extends Component {
  state = {
    mouseOver: false,
    cadastroEmEdicao: false,
  };

  handleSetPasto = (pasto) => () => {
    this.props.selecionarPasto(pasto);
  };

  onMouseOverChange = (over) => {
    this.setState({ ...this.state, mouseOver: over });
  };

  renderInfo = () => {
    if (this.props.selecionado === false && this.state.mouseOver) {
      const position = CalculadoraCoordenadas.calcularCentroAlto(
        this.props.pasto.Coordenadas
      );
      return (
        <Tooltip
          Posicao={position}
          Texto={
            (this.props.pasto.NomeReduzido || this.props.pasto.Nome) +
            " \n " +
            this.props.NomeForragem
          }
        />
      );
    } else {
      return <div />;
    }
  };

  renderBotaoFechar = () => {
    if (!this.props.selecionado) return;
    const position = CalculadoraCoordenadas.calcularTop(
      this.props.pasto.Coordenadas
    );
    return (
      <Marker
        id="botaoFechar"
        position={position}
        options={{
          icon: {
            url: "/images/botaoFechar.svg",
            scaledSize: { height: 30, width: 30 },
          },
        }}
        onClick={() => {
          this.verificarExibicaoDialog();
        }}
      />
    );
  };

  verificarExibicaoDialog = () => {
    const { cadastroPastoModificado } = this.props;
    if (cadastroPastoModificado) {
      this.setState({ ...this.state, cadastroEmEdicao: true });
    } else {
      this.props.deselecionarPasto();
    }
  };

  handleClose = () => {
    this.props.deselecionarPasto();
    this.setState({ ...this.state, cadastroEmEdicao: false });
  };

  handleOpen = () => {
    this.setState({ ...this.state, cadastroEmEdicao: false });
  };

  renderDialog() {
    return (
      <div>
        <Dialog
          id="modalSair"
          open={this.state.cadastroEmEdicao}
          onClose={this.handleClose}
          fullWidth="false"
          style={{ maxWidth: "450px", marginLeft: "30%", marginRight: "30%" }}
        >
          <DialogTitle style={{ fontWeight: "bold" }}>
            Você realmente deseja sair sem salvar as novas informações no pasto?
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleOpen} color="primary" autoFocus>
              Cancelar
            </Button>
            <Button onClick={this.handleClose} color="primary">
              Descartar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  renderPoligono() {
    const {
      pasto,
      selecionado,
      pastoKML,
      coordenadasEditando,
      pastoSelecionado,
      criandoNovoPasto,
    } = this.props;

    return pastoKML ? (
      <Poligono
        id="poligonoKml"
        coordenadas={pasto.Coordenadas}
        cor={"#409df9"}
      />
    ) : (
      <Poligono
        id="poligonoPastos"
        onClick={!criandoNovoPasto && this.handleSetPasto(pasto)}
        coordenadas={pasto.Coordenadas}
        coordenadasEditando={coordenadasEditando}
        pasto={pasto}
        pastoSelecionado={pastoSelecionado}
        cor={"#2d6dac"}
        onMouseOverChange={(over) => this.onMouseOverChange(over)}
        highlight={this.state.mouseOver || selecionado}
        block={false}
        raised={selecionado}
        editando={selecionado}
        onEditandoPoligono={this.props.editaCoordsEAreaPasto}
      />
    );
  }

  render = () => {
    const { pastoKML } = this.props;
    const { cadastroEmEdicao } = this.state;
    return (
      <div id="poligonoPasto">
        {this.renderPoligono()}
        {!pastoKML && this.renderInfo()}
        {!pastoKML && this.renderBotaoFechar()}
        {cadastroEmEdicao ? this.renderDialog() : <div />}
      </div>
    );
  };
}

PoligonoPasto.propTypes = {
  pasto: PropTypes.object.isRequired,
  selecionado: PropTypes.bool.isRequired,
  pastoKML: PropTypes.bool.isRequired,
  cadastroPastoModificado: PropTypes.bool,
};

export default connect(null, actions)(PoligonoPasto);
