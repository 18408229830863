export const TIPO_COLETA = {
  1: "Entrada",
  2: "Aferição",
  3: "Saída",
};

export const TIPO_MOVIMENTACAO = {
  CREATION: 1,
  REMOVAL: 2,
  PURCHASE: 3,
  SALE: 4,
  ADJUSTMENT: 5,
  TRANSFER: 6,
  MOVEMENT_BETWEEN_PASTURES: 7,
  CATEGORY_CHANGE: 8,
  POSITIVE_INTERFUSION: 9, // ENTREVERO POSITIVO
  NEGATIVE_INTERFUSION: 10, // ENTREVERO NEGATIVO
  DEATH: 13,
  BIRTH: 14,
  INDETERMINATE: 999,
};
