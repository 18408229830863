import React from 'react';
import { withStyles } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

export const TableauPastaPaper = props => {
  const { classes, title, imagemSrc, cliqueCallback } = props;

  return (
    <ButtonBase key={title} disableRipple={true} style={{ textAlign: "left" }}>
      <Paper
        id={`card_${title}`}
        className={classes.rootPaper}
        style={{ backgroundImage: `url(${imagemSrc})`, backgroundPosition: 'center -2px' }}
        onClick={cliqueCallback}>
        <Typography className={classes.title}>{title}</Typography>
      </Paper>
    </ButtonBase >
  );
};

const styles = () => ({
  rootPaper: {
    width: '280px',
    height: '160px',
    overflow: 'hidden',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.65)',
    marginBottom: '30px'
  },
  title: {
    position: 'absolute',
    bottom: '0px',
    marginBottom: '37px',
    marginLeft: '16px',
    width: '90%',
    textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.03px',
    color: '#ffffff'
  }
});

export default withStyles(styles)(TableauPastaPaper);
