import { withStyles } from "@material-ui/core";
import React from "react";
import Title from "../../../shared/title";
import Subtitle from "../../../shared/subtitle";
import { formatDate } from "features/goalPlan/goalPlanUtils";
import GoalsRegisterForm from "../../../shared/tableFormComponents/goalsForm";
import GoalPlanStatus from "../../../shared/goalPlanStatus";

const GoalPlanDetailsHeader = ({ revisionPlan, classes, title, badge }) => {
  return (
    <div className={classes.header}>
      <div className={classes.title}>
        <Title>
          {title} {revisionPlan.name} - V{revisionPlan.version}
          {badge && <span className={classes.badge}>{badge}</span>}
        </Title>
      </div>
      <div className={classes.subtitles}>
        <Subtitle>
          Data de criação:{" "}
          <b style={{ color: "#444" }}>
            {revisionPlan && formatDate(revisionPlan.createdAt)}
          </b>
        </Subtitle>
        <Subtitle style={{ marginTop: "2px" }}>
          <div className={classes.containerGoalPlanStatus}>
            Situação:
            <GoalPlanStatus
              active={revisionPlan.active}
              initialDate={revisionPlan.initialDate}
              finalDate={revisionPlan.finalDate}
              situation={revisionPlan.situation}
            />
          </div>
        </Subtitle>
      </div>
      {revisionPlan && (
        <GoalsRegisterForm goalPlanData={revisionPlan} disabled />
      )}
    </div>
  );
};

const styles = (theme) => ({
  header: {
    marginTop: "40px",
  },
  containerGoalPlanStatus: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  title: {
    display: "flex",
    flexDirecton: "row",
    justifyContent: "space-between",
  },
  subtitles: {
    marginTop: "30px",
  },
  badge: {
    verticalAlign: "middle",
    backgroundColor: "#ccddec",
    color: theme.palette.primary.main,
    marginLeft: "10px",
    borderRadius: "100px",
    padding: "3px 15px",
    fontSize: "12px",
  },
  activeStatus: {
    flexGrow: "0",
    alignItems: "center",
    margin: "2px 0px 2px 0px",
    padding: "2px 10px 2px 10px",
    borderRadius: "100px",
    backgroundColor: "#dff1df",
    color: "#4caf50",
    fontWeight: "550",
    fontSize: "12px",
  },
  inactiveStatus: {
    flexGrow: "0",
    alignItems: "center",
    margin: "2px 0px 2px 0px",
    padding: "2px 10px 2px 10px",
    borderRadius: "100px",
    backgroundColor: "#f8807833",
    color: "#f44336",
    fontWeight: "550",
    fontSize: "12px",
  },
  cadastroFormContainer: {
    marginTop: "40px",
    gap: "24px",
  },
  rangeDateContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    gap: "5px",
  },
  separator: {
    color: theme.palette.default1.light,
  },
  datePicker: {
    width: "150px",
  },
});

export default withStyles(styles)(GoalPlanDetailsHeader);
