const camposCocho = [
  'Coberto',
  'Comprimento',
  'Elevado',
  'EstoqueFinal',
  'CochoCompartilhado'
];

export const montaEstadoCocho = Cocho => {
  const cochoEstado = {};
  camposCocho.forEach(campo => {
    cochoEstado[campo] =
      Cocho && Cocho[campo] !== null && typeof Cocho[campo] !== 'undefined'
        ? Cocho[campo]
        : '';
  });
  return cochoEstado;
};
