import React from "react";
import ConfirmationModal from "features/shared/components/confirmationModal";
import GoalPlanFormContainer from "features/goalPlan/views/shared/goalPlanForm/goalPlanFormContainer";

const TEXT_TITLE = "Cadastro de metas da fazenda";
const TEXT_SUBTITLE = `Informe nos campos abaixo o nome do plano e a data de início, logo após será habilitada uma tabela para finalizar a crição do seu plano.`;
const TEXT_BUTTON_SUBMIT = "SALVAR META";

const GoalsRegisterPage = ({
  onBack = () => {},
  onSubmit = () => {},
  showConfirmationModal,
  enableLoading,
  disableLoading,
  notifyError,
  farmId,
}) => {
  return (
    <GoalPlanFormContainer
      farmId={farmId}
      titlePage="Criar plano e metas"
      titleContent={TEXT_TITLE}
      subtitleContent={TEXT_SUBTITLE}
      onBack={onBack}
      onSubmit={onSubmit}
      enableLoading={enableLoading}
      disableLoading={disableLoading}
      notifyError={notifyError}
      textButtonSubmit={TEXT_BUTTON_SUBMIT}
    >
      {showConfirmationModal && (
        <ConfirmationModal
          title={showConfirmationModal.title}
          text={showConfirmationModal.text}
          submitButtonTitle={showConfirmationModal.buttonConfirmText}
          onSubmit={showConfirmationModal.onConfirm}
          onCancel={showConfirmationModal.onCancel}
        />
      )}
    </GoalPlanFormContainer>
  );
};

export default GoalsRegisterPage;
