import React from "react";
import { Input, withStyles } from "@material-ui/core";

const styles = (theme) => ({
  input: {
    borderRadius: "4px",
    border: "solid 1px #d9d9d9",
    fontSize: "14px",
    letterSpacing: "0.44px",
    padding: "3px 8px",
    color: "#333",
    "& input::placeholder": {
      color: "rgba(117, 117, 117, 0.7)",
    },
  },
});

const InputPriceIngredient = ({ classes, value, onChange }) => {
  const handleChangeValue = ({ target }) => {
    if (target.value) {
      onChange(Number(target.value.replace(/\D/g, "")) / 100);
    } else {
      onChange(null);
    }
  };

  const showInputValue = (valueToConvert) => {
    if (valueToConvert) {
      const value = Number(valueToConvert);
      const valueString = value.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: "currency",
        currency: "BRL",
      });
      return valueString;
    }

    return "";
  };

  return (
    <Input
      data-testid="input-price-ingredient"
      className={classes.input}
      placeholder="Informe o valor por kg para o ingrediente. Ex: R$ 1,98"
      disableUnderline={true}
      fullWidth={true}
      value={showInputValue(value)}
      onChange={handleChangeValue}
    />
  );
};

export default withStyles(styles)(InputPriceIngredient);
