import {
  LISTA_LOTES,
  SALVAR_CURRAL,
  EDITAR_CURRAL,
  EXCLUIR_CURRAL,
  EXIBIR_CARREGANDO,
} from "../../../redux/actions/actionsConstants";

export const listarLotes = (lotes) => {
  return {
    type: LISTA_LOTES,
    payload: { Lotes: lotes },
  };
};

export const salvarCurral = (curral) => {
  return {
    type: SALVAR_CURRAL,
    payload: curral,
  };
};

export const editarCurral = () => {
  return {
    type: EDITAR_CURRAL,
  };
};

export const excluirCurral = () => {
  return {
    type: EXCLUIR_CURRAL,
  };
};

export const exibirCarregando = (exibir) => {
  return {
    type: EXIBIR_CARREGANDO,
    payload: exibir,
  };
};
