import React from "react";
import { withStyles } from "@material-ui/core/styles";

function PermissionInfoCard({ classes }) {
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <span className={classes.headerTitle}>
          Permissões que o usuario terá
        </span>
        <div className={classes.headerRight}>
          <div>
            <CheckIcon />
            <span>Tem permissão</span>
          </div>
          <div>
            <BlockIcon />
            <span>Não tem permissão</span>
          </div>
          <div>
            <WarningIcon />
            <span>Necessita liberação</span>
          </div>
        </div>
      </div>
      <div className={classes.content}>
        <PermissionNeedsApproval name="Acessa plataforma" />
        <PermissionNeedsApproval name="Acessa aplicativo" />
        <PermissionNeedsApproval name="Estoque final" />
        <PermissionNeedsApproval name="Importar mapas" />
        <PermissionNeedsApproval name="Exportar CSV" />
        <PermissionBlocked name="Cadastra usuário" />
        <PermissionBlocked name="Edita usuário" />
        <PermissionBlocked name="Inativa usuário" />
        <PermissionAllowed name="Cria retiros" />
        <PermissionBlocked name="Vincula retiros a usuários" />
      </div>
    </div>
  );
}

function CheckIcon() {
  return (
    <img alt="check icon" src="/images/check.svg" width={20} height={20} />
  );
}

function WarningIcon() {
  return (
    <img alt="warning icon" src="/images/warning.svg" width={20} height={20} />
  );
}

function BlockIcon() {
  return (
    <img alt="block icon" src="/images/block.svg" width={20} height={20} />
  );
}

function DottedLine() {
  return <img alt="dotted line" src="/images/dotted-line.svg" width="100%" />;
}

function PermissionAllowed({ name }) {
  return (
    <div>
      <span>{name}</span>
      <DottedLine />
      <CheckIcon />
    </div>
  );
}

function PermissionBlocked({ name }) {
  return (
    <div>
      <span>{name}</span>
      <DottedLine />
      <BlockIcon />
    </div>
  );
}

function PermissionNeedsApproval({ name }) {
  return (
    <div>
      <span>{name}</span>
      <DottedLine />
      <WarningIcon />
    </div>
  );
}

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    padding: "16px",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "8px",
    backgroundColor: "#F6FAFE",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerTitle: {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: 700,
    color: "#212121",
  },
  headerRight: {
    display: "flex",
    flexDirection: "row",
    gap: "32px",
    "& div": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "8px",
    },
    "& span": {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 500,
      color: "#212121",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    "& div": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "8px",
      "& span": {
        fontFamily: "Roboto",
        fontSize: "14px",
        color: "#334150",
        whiteSpace: "nowrap",
      },
    },
  },
});

export default withStyles(styles)(PermissionInfoCard);
