import React from 'react';
import { DatePicker } from 'material-ui-pickers';
import { withStyles } from '@material-ui/core';
import { maskDatePicker } from '../../../../shared/utils';

export const campoDataPesagem = ({
  classes,
  lote,
  atualizaEstadoDataPesagem,
  handleAlteraLote,
}) => {
  const alteraDataPesagem = (date) => {
    atualizaEstadoDataPesagem(lote.IdLote, true);
    handleAlteraLote(lote, "DataPesagem", date);
  };

  return (
    <DatePicker
      disableFuture
      keyboard
      disableOpenOnEnter
      className={classes.campoData}
      invalidDateMessage="O Formato da data é inválido"
      maxDateMessage="A data inserida não pode ser maior que a data atual"
      minDateMessage="A data inserida ultrapassa a data minima permitida"
      format="DD/MM/YYYY"
      placeholder="dd/mm/aaaa"
      mask={(value) => (value ? maskDatePicker : [])}
      value={lote.DataPesagem ? lote.DataPesagem : null}
      onError={() => {
        atualizaEstadoDataPesagem(lote.IdLote, false);
      }}
      onChange={alteraDataPesagem}
    />
  );
};

const styles = {
  tableCell: {
    paddingLeft: "12px",
  },
  campoData: {
    width: "100%",
    minWidth: 140,
    maxWidth: 150,
    paddingTop: "15px",
    opacity: "0.9",
    fontSize: "8px",
  },
};

const campoDataPesagemIgual = (prevProps, nextProps) => {
  const loteIgual = prevProps.lote.IdLote === nextProps.lote.IdLote;
  const dataPesagemIgual =
    prevProps.lote.DataPesagem === nextProps.lote.DataPesagem;
  return loteIgual && dataPesagemIgual;
};

const campoDataPesagemMemo = React.memo(
  campoDataPesagem,
  campoDataPesagemIgual
);
export default withStyles(styles)(campoDataPesagemMemo);
