import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MapaGoogleCustomizado from "features/manejo/views/components/MapaGoogleCustomizado/MapaGoogleCustomizadoContainer";
import PoligonoPasto from "../PoligonoPasto";
import PoligonoMundo from "features/manejo/views/components/PoligonoMundo";
import * as pastoActions from "features/manejo/redux/actions/pastoActions";
import * as forragemActions from "features/manejo/redux/actions/forragemActions";
import {
  TODOS_OS_PASTOS,
  PASTOS_SEM_CADASTRO,
} from "../../../../../constants/retiro";
import _ from "lodash";
import ReactGA from "react-ga";
import { Marker } from "@react-google-maps/api";
import Poligono from "../Poligono";
import CalculadoraCoordenadas from "../../../../../lib/calculadoraCoordenadas";

export class MapaCadastro extends Component {
  constructor(props) {
    super(props);
    this.props.deselecionarPasto();
    this.props.obterTodasForragens();
    ReactGA.pageview("/cadastro");

    this.state = {
      mouseOver: false,
      pastoAtual: null,
    };
  }

  onMouseOverChange = (over, id) => {
    this.setState({ ...this.state, mouseOver: over, pastoAtual: id });
  };

  renderMundo() {
    return this.props.pastoSelecionado.IdPasto ? <PoligonoMundo /> : "";
  }

  renderPastos(tipoPasto) {
    const {
      retiroSelecionado,
      cadastroPastoModificado,
      forragens,
      Editando,
      CoordenadasEditando,
      PastoSelecionado,
      criandoNovoPasto,
    } = this.props;

    return _.chain(this.props[tipoPasto])
      .filter((pasto) => {
        if (retiroSelecionado === TODOS_OS_PASTOS) return true;
        else if (retiroSelecionado === PASTOS_SEM_CADASTRO)
          return !pasto.IdRetiro;
        else return pasto.IdRetiro === retiroSelecionado;
      })
      .map((pasto, idx) => {
        const forragem = _.find(forragens, (f) => {
          return f.IdForragem === pasto.IdForragem;
        });
        return (
          <PoligonoPasto
            key={`${pasto.IdPasto}-${idx}`}
            pastoKML={tipoPasto === "pastosKML"}
            pasto={pasto}
            coordenadasEditando={CoordenadasEditando}
            pastoSelecionado={PastoSelecionado}
            NomeForragem={forragem !== undefined ? forragem.Nome : ""}
            selecionado={pasto.IdPasto === this.props.pastoSelecionado.IdPasto}
            cadastroPastoModificado={cadastroPastoModificado}
            editando={Editando}
            criandoNovoPasto={criandoNovoPasto}
          />
        );
      })
      .value();
  }

  renderBotaoFechar = () => {
    const { mouseOver, pastoAtual } = this.state;
    const { CoordenadasNovosPastos } = this.props;

    return _.map(CoordenadasNovosPastos, (coord) => {
      const position = CalculadoraCoordenadas.calcularCentro(coord.Pasto);
      const pastoAtualSelecionado = pastoAtual === coord.Id;
      return (
        <React.Fragment>
          <Poligono
            onClick={(e) => e}
            coordenadas={coord.Pasto}
            cor={`#D3D3D3`}
            onMouseOverChange={(over) => this.onMouseOverChange(over, coord.Id)}
            highlight={mouseOver && pastoAtualSelecionado}
            block={false}
            raised={false}
          />
          ,
          {mouseOver && pastoAtualSelecionado ? (
            <Marker
              position={position}
              options={{
                icon: {
                  url: "/images/botaoFechar.svg",
                  scaledSize: { height: 30, width: 30 },
                },
              }}
              onClick={() => this.props.deletarPoligono(coord.Evento, coord.Id)}
            />
          ) : (
            <div />
          )}
        </React.Fragment>
      );
    });
  };

  render() {
    return (
      <div>
        <MapaGoogleCustomizado
          id="mapaCadastro"
          coordenadasCentro={this.props.coordenadasCentro}
          HabilitaDesenho={true}
          zoomPadrao={this.props.zoomPadrao}
        >
          {this.renderBotaoFechar()}
          {this.renderMundo()}
          {this.renderPastos("pastos")}
          {this.renderPastos("pastosKML")}
        </MapaGoogleCustomizado>
      </div>
    );
  }
}

MapaCadastro.propTypes = {
  coordenadasCentro: PropTypes.object.isRequired,
  pastos: PropTypes.array.isRequired,
  pastoSelecionado: PropTypes.object,
  retiroSelecionado: PropTypes.number,
  cadastroPastoModificado: PropTypes.bool,
};

const mapDispatchToProps = {
  obterTodasForragens: forragemActions.obterTodasForragens,
  deselecionarPasto: pastoActions.deselecionarPasto,
  deletarPoligono: pastoActions.deletarPoligono,
};

function mapStateToProps({ pasto }) {
  return {
    CoordenadasNovosPastos: pasto.CoordenadasNovoPasto,
    Editando: pasto.Editando,
    CoordenadasEditando: pasto.CoordenadasEditandoPasto,
    PastoSelecionado: pasto.PastoSelecionado,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MapaCadastro);
