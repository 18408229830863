import React, { useEffect, useState } from "react";
import GoalPlanRevisionPage from "./goalPlanRevisionPage";
import goalPlanService from "../../goalPlanService";
import { connect } from "react-redux";
import {
  disableLoading,
  enableLoading,
  notifyError,
} from "../../redux/goalPlanOperations";
import { getGoalPlanSituation } from "features/goalPlan/goalPlanUtils";

export const GoalPlanRevisionContainer = ({
  onBack,
  revisionPlanId,
  enableLoading,
  disableLoading,
  notifyError,
}) => {
  const [revisionList, setRevisionList] = useState([]);
  const [revisionId, setRevisionId] = useState(null);

  const onBackRevisionList = () => {
    setRevisionId(null);
  };

  const onShowRevisionPlan = (revisionId) => {
    setRevisionId(revisionId);
  };

  const onShowOriginalPlan = () => {
    const plan = revisionList.find((revision) => revision.version === 1);
    if (plan) setRevisionId(plan.id);
  };

  useEffect(() => {
    getListRevision(revisionPlanId);
  }, []);

  const getListRevision = async (planId) => {
    if (!planId || typeof planId !== "number") return;
    try {
      enableLoading("getListRevision");
      const plan = await goalPlanService.getRevision(planId);
      const enrichedPlans = plan.map((meta) => ({
        ...meta,
        situation: getGoalPlanSituation(meta)
      }));
      setRevisionList(enrichedPlans);
    } catch (err) {
      console.error(err);
      notifyError("Ocorreu um erro ao carregar a lista de revisões.");
    } finally {
      disableLoading("getListRevision");
    }
  };

  return (
    <GoalPlanRevisionPage
      onBack={onBack}
      goalPlanRevisionList={revisionList}
      onShowRevisionPlan={onShowRevisionPlan}
      onShowOriginalPlan={onShowOriginalPlan}
      revisionId={revisionId}
      onBackRevisionList={onBackRevisionList}
      enableLoading={enableLoading}
      disableLoading={disableLoading}
      notifyError={notifyError}
    />
  );
};

const mapDispatchToProps = {
  enableLoading: enableLoading,
  disableLoading: disableLoading,
  notifyError: notifyError,
};

export default connect(() => { }, mapDispatchToProps)(GoalPlanRevisionContainer);
