import React from "react";
import { withStyles } from "@material-ui/core";

const PendencyEditHeader = ({classes}) => {
  return (
    <div className={classes.tilteContainer}>
    <h1 data-testid="TestTitleEditPage" className={classes.titleText}>
      Editar pendência
    </h1>
  </div>
  )
}

const styles = () => ({
  tilteContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "30px",
    marginTop: "8px",
  },
  titleText: {
    fontFamily: "Roboto",
    fontSize: "24px",
    fontWeight: "700",
    color: "#307297",
  },
});
export default withStyles(styles)(PendencyEditHeader);
