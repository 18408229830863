import React from "react";
import { Modal, Grid, Typography, withStyles } from "@material-ui/core";

export const MovementHistoryModal = ({
  classes,
  exibirDescritivoModal,
  aoFechar,
}) => {
  return (
    <div className={classes.boxModal}>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={exibirDescritivoModal}
        onBackdropClick={aoFechar}
      >
        <Grid
          id="containerModalLote"
          container
          className={classes.modalConteudo}
          alignItems="flex-start"
        >
          <Grid item xs={12} className={classes.cabecalho}>
            <Typography variant="h6" className={classes.tituloTexto}>
              Não foram encontradas informações referentes a movimentações do
              aplicativo. Entre no aplicativo e verifique se suas informações
              foram sincronizadas.
            </Typography>
            <button className={classes.button} onClick={aoFechar}>
              Ok
            </button>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
};

const styles = (theme) => {
  return {
    boxModal: {
      overflow: "auto",
    },
    modalConteudo: {
      margin: "300px auto",
      padding: "40px 40px",
      width: "30%",
      maxHeight: "70%",
      backgroundColor: "#ffffff",
      outline: "none",
      boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.5)",
    },
    cabecalho: {
      display: "flex",
      marginTop: "16px",
      marginBottom: "16px",
      justifyContent: "space-between",
      flexDirection: "column",
      alignItems: "center",
    },
    tituloTexto: {
      position: "relative",
      fontSize: "18px",
      fontWeight: "500",
      letterSpacing: "-0.1px",
      color: "rgba(51, 50, 56, 0.84)",
    },
    fechar: {
      width: "24px",
      height: "24px",
      cursor: "pointer",
      objectFit: "contain",
    },
    button: {
      color: theme.palette.primary.main,
      backgroundColor: "white",
      border: "unset",
      width: "20px",
      height: "16px",
      margin: "21px 203px 0 183px",
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "500",
      letterSpacing: "0.4px",
      textAlign: "center",
      cursor: "pointer",
    },
  };
};

export default withStyles(styles)(MovementHistoryModal);
