import history from "features/app/History";
import PageTitle from "features/collect/supplementation/views/shared/pageTitle";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PastureRegistrations from "./PastureRegistrations";
import { deselecionarPasto } from "../../../../redux/actions/pastoActions";
import { withStyles } from "@material-ui/core";

export function PastureRegistrationsContainer({
  deselecionarPasto,
  classes,
  PastoSelecionado,
  PastosPrime,
  Cocho,
  RetiroSelecionado,
  FazendaSelecionada,
  errosPasto,
  errosCocho,
  Retiros,
  Forragens,
  cadastroPastoModificado,
  Lotes,
  CoordenadasNovosPastos,
  Toggles,
}) {
  const [newPasture, setNewPasture] = useState(PastoSelecionado);
  const [trough, setTrough] = useState(Cocho);
  const [creepTrough, setCreepTrough] = useState();

  const onGoBack = () => {
    deselecionarPasto();
    history.replace("/cadastro");
  };

  useEffect(() => {
    window.addEventListener("keydown", handleEscKeyPressed);
    return () => window.removeEventListener("keydown", handleEscKeyPressed);
  }, []);

  const handleEscKeyPressed = (e) => {
    if (e.key === "Escape" && isEdit) {
      onGoBack();
    }
  };

  const isEdit =
    history.location.pathname.includes("editar") || PastoSelecionado.IdPasto;

  const pageTitle = isEdit ? "Editar pasto" : "Novo Pasto";

  return (
    <>
      <PageTitle
        containerClass={classes.pageTitleContainer}
        titleClass={classes.pageTitle}
        title={pageTitle}
        onGoBack={() => onGoBack()}
      />
      <div className={classes.formWrapper}>
        <PastureRegistrations
          id="containerCadastro"
          pasture={newPasture}
          setPasture={setNewPasture}
          trough={trough}
          setTrough={setTrough}
          creepTrough={creepTrough}
          setCreepTrough={setCreepTrough}
          showTroughForm={true}
          isEdit={isEdit}
          PastoSelecionado={PastoSelecionado}
          PastosPrime={PastosPrime}
          Cocho={Cocho}
          IdRetiro={RetiroSelecionado}
          IdFazenda={FazendaSelecionada}
          errosPasto={errosPasto}
          errosCocho={errosCocho}
          Retiros={Retiros}
          Forragens={Forragens}
          cadastroPastoModificado={cadastroPastoModificado}
          Lotes={Lotes}
          AreaNovoPasto={PastoSelecionado.AreaNovoPasto}
          CoordenadasNovosPastos={CoordenadasNovosPastos}
          Toggles={Toggles}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const PastoSelecionado = state.pasto.PastoSelecionado;
  const errosPasto = state.pasto.erros;
  const errosCocho = state.cocho.erros;
  const { Cocho } = state.cocho;
  const { RetiroSelecionado, Retiros } = state.retiro;
  const { FazendaSelecionada } = state.fazenda;
  const { cadastroPastoModificado } = state.pasto;
  const Forragens = state.forragem.Forragens;
  const Lotes = state.lote.Lotes;
  const Toggles = state.fazenda.Toggles;
  const PastosPrime = state.pastosPrime.PastosPrime;
  const AreaNovoPasto = state.pasto.AreaNovoPasto;
  const CoordenadasNovosPastos = state.pasto.CoordenadasNovosPastos;
  return {
    errosPasto,
    errosCocho,
    PastoSelecionado,
    PastosPrime,
    Cocho,
    RetiroSelecionado,
    FazendaSelecionada,
    Retiros,
    Forragens,
    cadastroPastoModificado,
    Lotes,
    AreaNovoPasto,
    CoordenadasNovosPastos,
    Toggles,
  };
};

const mapDispatchToProps = {
  deselecionarPasto: deselecionarPasto,
};

const styles = () => ({
  pageTitleContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 32,
    marginStart: 40,
    marginEnd: 40,
    marginBottom: 0,
  },
  pageTitle: {
    margin: 0,
    fontSize: 18,
    fontWeight: "normal",
  },
  formWrapper: {
    height: "95%",
    width: "100%",
    padding: "32px 40px 0px 40px",
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PastureRegistrationsContainer));
