import React from 'react';

export const fakeList = props => {
  const { listarItens, itensListar, numeroItensFake, renderItem } = props;
  const itensFake = new Array(numeroItensFake).fill({});
  return (
    <React.Fragment>
      {listarItens
        ? itensListar.map((item, index) => {
            return renderItem(item, index);
          })
        : itensFake.map((item, index) => {
            return renderItem(item, index);
          })}
    </React.Fragment>
  );
};

fakeList.defaultProps = {
  listarItens: false,
  itensListar: [],
  numeroItensFake: 3,
  renderItem: () => {}
};
export default fakeList;
