import { Button } from "@material-ui/core";
import React from "react";
import { Typography, withStyles } from "@material-ui/core";

const PlanningListHeader = ({ classes, showNewPlanning = () => {} }) => {
  return (
    <div className={classes.container}>
      <div>
        <Typography className={classes.title}>
          Evolução de rebanho e plano de nutrição
        </Typography>
        <Typography className={classes.subTitle}>
          Veja abaixo todos os planejamentos cadastrados
        </Typography>
      </div>
      <div>
        <Button className={classes.buttonSubmit} onClick={showNewPlanning}>
          Novo Planejamento
        </Button>
      </div>
    </div>
  );
};

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  title: {
    ...theme.telas.defaultTitulo,
    fontWeight: 500,
  },
  subTitle: {
    marginTop: "16px",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    color: "#7f7f7f",
  },
  buttonSubmit: {
    fontSize: "14px",
    marginLeft: "16px",
    color: "#FFFFFF",
    padding: "8px 16px",
    height: 40,
    borderRadius: "30px",
    backgroundColor: theme.palette.primary.main,
  },
});

export default withStyles(styles)(PlanningListHeader);
