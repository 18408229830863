import {
  notifyError,
  notifySuccess,
} from "../../../redux/actions/notificacaoActions";
import GanhoPesoManager from "../ganhoPesoManager";
import {
  listarCurvas,
  excluirCurva,
  salvarCurva,
  editarCurva,
  exibirCarregando,
} from "./ganhoPesoActions";
import _ from "lodash";

export const obterCurvasGanhoPeso = () => async (dispatch, getState) => {
  dispatch(exibirCarregando(true));
  try {
    const { fazenda } = getState();
    const curvasGanhoPeso = await GanhoPesoManager.obterCurvasGanhoPeso(
      fazenda.FazendaSelecionada
    );
    dispatch(listarCurvas(curvasGanhoPeso));
  } catch (error) {
    dispatch(listarCurvas([]));
    dispatch(
      notifyError({
        mensagem:
          "Ocorreu um erro ao obter a lista de curvas de ganho de peso.",
      })
    );
  } finally {
    dispatch(exibirCarregando(false));
  }
};

export const criarCurva = (curva) => async (dispatch, getState) => {
  try {
    const { fazenda } = getState();
    curva.IdFazenda = fazenda.FazendaSelecionada;
    curva = _.omit(curva, "Selecionada");
    const curvaCriadaResposta = await GanhoPesoManager.salvarCurva(curva);

    dispatch(salvarCurva(curvaCriadaResposta));
    dispatch(
      notifySuccess({
        mensagem: "Curva de ganho de peso criada com sucesso.",
      })
    );
  } catch (error) {
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao criar a curva de ganho de peso.",
      })
    );
  }
};

export const atualizarCurva = (curva) => async (dispatch) => {
  try {
    const id = curva.IdCurvaGanhoPeso;
    const idFazenda = curva.IdFazenda;
    curva = _.pick(curva, [
      "Nome",
      "Janeiro",
      "Fevereiro",
      "Marco",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
      "IdFazenda"
    ]);
    await GanhoPesoManager.editarCurva(curva, id, idFazenda);
    dispatch(editarCurva());
    dispatch(
      notifySuccess({
        mensagem: "Curva editada com sucesso.",
      })
    );
  } catch (error) {
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao editar a curva.",
      })
    );
  }
};

export const deletarCurva = (idCurva) => async (dispatch) => {
  try {
    await GanhoPesoManager.excluirCurva(idCurva);
    dispatch(excluirCurva());
    dispatch(
      notifySuccess({
        mensagem: "Curva excluída com sucesso.",
      })
    );
  } catch (error) {
    if (
      !_.isNil(error.response) &&
      !_.isNil(error.response.data) &&
      !_.isNil(error.response.data.codigo) &&
      Number(error.response.data.codigo) === 406
    ) {
      dispatch(
        notifyError({
          mensagem: error.response.data.mensagem,
        })
      );
    } else {
      dispatch(
        notifyError({
          mensagem: "Ocorreu um erro ao excluir a curva.",
        })
      );
    }
  }
};
