import { EXIBIR_CARREGANDO } from "../../../redux/actions/actionsConstants";
export const LISTAR_PESAGENS = "LISTAR_PESAGENS";
export const LISTAR_QUANTIDADES_POR_CATEGORIA =
  "LISTAR_QUANTIDADES_POR_CATEGORIA";
export const EXIBIR_MODAL_DATA_INICIAL_CONCILIACAO =
  "EXIBIR_MODAL_DATA_INICIAL_CONCILIACAO";

export const listarPesagensFazenda = (pesagens) => {
  return {
    type: LISTAR_PESAGENS,
    payload: {
      PesagensFazenda: pesagens.items,
    },
  };
};

export const listarQuantidadePorCategoria = (quantidades) => {
  return {
    type: LISTAR_QUANTIDADES_POR_CATEGORIA,
    payload: quantidades,
  };
};

export const exibirModalDataInicial = (exibir) => {
  return {
    type: EXIBIR_MODAL_DATA_INICIAL_CONCILIACAO,
    payload: exibir,
  };
};

export const exibirCarregando = (exibir) => {
  return {
    type: EXIBIR_CARREGANDO,
    payload: exibir,
  };
};
