import React, { Component } from "react";
import { connect } from "react-redux";
import BalancoArrobaPage from "./balancoArrobaPage";
import { deveExibirConciliacao } from "../../shared/utils";
import * as actions from "redux/actions";
import * as BalancoArrobaOperations from "../redux/balancoArrobaOperations";
import { obterCategoriasFazenda } from "../../categoria/redux/categoriaOperations";
import { montarTextoMapaRebanhoGA } from "../../../shared/utils";

export class BalancoArrobaContainer extends Component {
  render() {
    const {
      idFazendaSelecionada,
      balancosFazenda,
      categoriasFazenda,
      obterCategorias,
      dataInicioConciliacao,
      obterDatas,
      obterDadosBalanco,
      permiteBalanco,
      datas,
      desativaModalFaltaData,
      exibirModalFaltaDataInicial,
      exibirModalFaltaInformacao,
    } = this.props;

    enviarDadosGA(idFazendaSelecionada);
    return (
      <BalancoArrobaPage
        idFazendaSelecionada={idFazendaSelecionada}
        balancosFazenda={balancosFazenda}
        categoriasAnimais={categoriasFazenda}
        obterCategoriasFazenda={obterCategorias}
        dataInicioConciliacao={dataInicioConciliacao}
        obterDatas={obterDatas}
        obterDadosBalanco={obterDadosBalanco}
        permiteBalanco={permiteBalanco}
        datas={datas}
        exibirModalFaltaInformacao={exibirModalFaltaInformacao}
        desativaModalFaltaData={desativaModalFaltaData}
        exibirModalFaltaDataInicial={exibirModalFaltaDataInicial}
      />
    );
  }
}

const enviarDadosGA = (idFazenda) => {
  montarTextoMapaRebanhoGA(idFazenda, "Balanço de Arroba");
};

const mapStateToProps = (state) => {
  const { fazenda, balanco, categoria } = state;
  const permiteBalanco = deveExibirConciliacao(state);
  let dataInicioConciliacao;
  fazenda.Fazendas.forEach((value) => {
    if (value.IdFazenda === fazenda.FazendaSelecionada)
      dataInicioConciliacao = value.DataInicioConciliacao;
  });

  return {
    idFazendaSelecionada: fazenda.FazendaSelecionada,
    balancosFazenda: balanco.Balancos,
    categoriasFazenda: categoria.categorias,
    arrayBalancos: balanco.Balancos.Categorias,
    dataInicioConciliacao: dataInicioConciliacao,
    permiteBalanco,
    datas: balanco.data,
    exibirModalFaltaInformacao: balanco.exibirModalFaltaInformacao,
    exibirModalFaltaDataInicial: balanco.exibirModalFaltaDataInicial,
  };
};

const mapDispatchToProps = {
  obterCategorias: obterCategoriasFazenda,
  loading: actions.exibirCarregandoSalvando,
  obterDatas: BalancoArrobaOperations.obterDatas,
  obterDadosBalanco: BalancoArrobaOperations.obterDadosBalanco,
  desativaModalFaltaData: BalancoArrobaOperations.desativaModalFaltaData,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BalancoArrobaContainer);
