import React from "react";
import { InfoOutlined } from "@material-ui/icons";
import TableDisclimer from "./TableDisclimer";
import TabelaProdap from "features/shared/components/TabelaProdap";
import { tableStyles } from "./tableStyle";
import { withStyles } from "@material-ui/core";
import { formatKgOrZero } from "../utils";

const DISCLIMER_TEXT =
  "Esse cálculo é realizado da seguinte forma: consumo objetivo (g/cab/dia) * quantidade de cabeças para a formulação * número dias mês / 1000";

function PlanningFormulaReportTable({
  planningFormulaReport,
  periodTitles,
  classes,
}) {
  const planningFormulaReportTableViewColumns = () => {
    const periodColumns = periodTitles.map((month, monthIndex) => ({
      id: month,
      titulo: month,
      ordenavel: false,
      pegaValor: (report) => (
        <span>{formatKgOrZero(report.months[monthIndex] || 0)}</span>
      ),
    }));

  return [
    {
      id: "formulacao",
      titulo: "Formulação",
      ordenavel: false,
      pegaValor: (report) => <span>{report.formula.name}</span>,
    },
    ...periodColumns,
    {
      id: "total",
      titulo: "Total",
      ordenavel: false,
      pegaValor: (report) => (
        <span className={classes.cellItemBold}>
          {formatKgOrZero(report.total || 0)}
        </span>
      ),
    },
  ];
};

return (
  <div className={classes.container}>
    <h1 className={classes.tableTitle}>
      Planejamento mensal de formulação (kg)
    </h1>
    <div
      className={classes.tableContent}
      data-testid="Plannings-Formula-Report-Table"
      >
      <TabelaProdap
        className={classes.tableContainer}
        idCabecalhoTabela={"planningFormulaReportTableHead"}
        colunas={planningFormulaReportTableViewColumns()}
        ativarPaginacao={false}
        linhas={planningFormulaReport}
        tabelaEsquerda={true}
        noPaper
        />
    </div>
    <TableDisclimer text={DISCLIMER_TEXT} icon={<InfoOutlined />} />
  </div>
);
}

const style = (theme) => ({
  ...tableStyles(theme),
});

export default withStyles(style)(PlanningFormulaReportTable);
