import React from "react";
const getItensConfiguracao = (props, funcionalidadeSelecionada) => {
  const {
    exibirCadastroFormulacao,
    exibirGanhoPeso,
    exibirObjetivoLote,
    exibirHistoricoChuva,
    exibirCausaMorte,
    exibirAjusteEscore,
    exibirEvolucaoRebanhoPan,
  } = props;

  const tabs = [
    {
      label: "Metas da Fazenda",
      id: "tabMetasFazenda",
      exibir:
        funcionalidadeSelecionada === "Todas" ||
        funcionalidadeSelecionada === "Metas da Fazenda",
      descricao:
        "Realizar o cadastro do plano para a fazenda e as metas desejadas para o ano.",
    },
    {
      label: "Evolução de rebanho e plano de nutrição",
      hasNewBadge: true,
      id: "tabEvolucaoPan",
      exibir:
        exibirEvolucaoRebanhoPan &&
        (funcionalidadeSelecionada === "Todas" ||
          funcionalidadeSelecionada ===
            "Evolução de rebanho e plano de nutrição"),
      descricao:
        "Realize o planejamento de rebanho e defina a utilização das formulações previstas para cada mês.",
    },
    {
      label: "Categoria Animal",
      id: "tabCategoriaAnimal",
      exibir:
        funcionalidadeSelecionada === "Todas" ||
        funcionalidadeSelecionada === "Entrada e saída" ||
        funcionalidadeSelecionada === "Cocho creep" ||
        funcionalidadeSelecionada === "Morte" ||
        funcionalidadeSelecionada === "Nascimento" ||
        funcionalidadeSelecionada === "Balanço de arroba" ||
        funcionalidadeSelecionada === "Conciliação",
      descricao:
        "Crie, edite, habilite ou desabilite as categorias dos lotes da fazenda. Vincula com faixa de idade e curvas de ganho de peso.",
    },
    {
      label: "Listagem de Lote",
      id: "tabCadastroObjetivo",
      exibir: funcionalidadeSelecionada === "Todas",
      descricao:
        "Acompanhamento da sanidade e peso projetado de todos os lotes da fazenda. Cadastro das pesagens e do objetivo dos lotes.",
    },
    {
      label: "Listagem de Curral",
      id: "tabCadastroCurral",
      exibir: funcionalidadeSelecionada === "Todas",
      descricao: "Administra e cadastra os currais de cada retiro da fazenda.",
    },
    {
      label: "Ingredientes",
      id: "tabIngredientes",
      exibir:
        funcionalidadeSelecionada === "Todas" ||
        funcionalidadeSelecionada === "Fechamento de consumo",
      descricao:
      "Crie, edite, habilite ou desabilite os ingredientes utilizados para criação das formulações.",
    },
    {
      label: "Formulações",
      id: "tabCadastroFormulacao",
      exibir:
        exibirCadastroFormulacao &&
        (funcionalidadeSelecionada === "Todas" ||
          funcionalidadeSelecionada === "Fechamento de consumo" ||
          funcionalidadeSelecionada === "Bombona" ||
          funcionalidadeSelecionada === "Cocho creep"),
      descricao:
        "Crie, edite, habilite ou desabilite as formulações que serão abastecidas nos cochos. Define se a formulação é creep ou não.",
    },
    {
      label: "Curva de ganho de peso",
      id: "tabCurvaGanhoPeso",
      exibir: exibirGanhoPeso && funcionalidadeSelecionada === "Todas",
      descricao:
        "Cadastro da projeção de ganho de peso por mês de cada uma das categorias animais da fazenda.",
    },
    {
      label: "Objetivos de lote",
      id: "tabObjetivoLote",
      exibir: exibirObjetivoLote && funcionalidadeSelecionada === "Todas",
      descricao:
        "Crie, edite, habilite ou desabilite os objetivos a serem atribuídos à cada lote.",
    },
    {
      label: "Histórico de Chuva",
      id: "tabHistoricoChuva",
      exibir: exibirHistoricoChuva && funcionalidadeSelecionada === "Todas",
      descricao:
        "Importa os registros de pluviosidade dos últimos anos da fazenda.",
    },
    {
      label: "Causa de morte",
      id: "tabCausaMorte",
      exibir:
        exibirCausaMorte &&
        (funcionalidadeSelecionada === "Todas" ||
          funcionalidadeSelecionada === "Morte"),
      descricao:
        "Crie, edite, habilite ou desabilite as causas de morte para o lançamento no Coletor.",
    },
    {
      label: "Ajuste de Escore",
      id: "tabEscore",
      exibir: exibirAjusteEscore && funcionalidadeSelecionada === "Todas",
      descricao: "Cadastro do ajuste de escore de semi confinamento.",
    },
    {
      label: "Pesagem por Categoria",
      id: "tabPesagemCategoria",
      exibir:
        funcionalidadeSelecionada === "Balanço de arroba" ||
        funcionalidadeSelecionada === "Todas",
      descricao:
        "Cadastro feito para lançar o peso pesado da categoria, para realizar o Balanço de Arroba.",
    },
  ];
  return tabs;
};

const getItensFuncionalidades = (props) => {
  const {
    exibirFechamentoDeConsumo,
    exibirCreep,
    exibirBombona,
    exibirMorteNascimento,
    exibirEvolucaoRebanhoPan,
  } = props;

  const funcionalidades = [
    {
      label: "Cocho creep",
      id: "chochoCreep",
      exibir: exibirCreep,
      descricao: (
        <span>
          Para conseguir visualizar o farol de Cocho Creep e ter o fechamento de
          consumo mais adequado a realidade da fazenda de cria a pasto, é
          necessário ter :
          <br />
          <br />- Cadastro de Formulações Creep.
          <br />- Cadastro de categoria animal Creep informando a faixa etária
          de 0 a 8 meses.
          <br />- Criar cocho creep (Tela de cadastro de pasto/ Avançado/
          Cocho).
        </span>
      ),
    },
    {
      label: "Metas da Fazenda",
      id: "metasFazenda",
      exibir: true,
      descricao: (
        <span>
          Realizar o cadastro do plano da fazenda com as metas definidas, ou
          seja, o que espera/planeja que seja o resultado de cada indicador da
          fazenda durante o ano.
        </span>
      ),
    },
    {
      label: "Evolução de rebanho e plano de nutrição",
      id: "evolucaoPan",
      exibir: exibirEvolucaoRebanhoPan,
      descricao: (
        <span>
          Realize o planejamento de rebanho e defina a utilização das
          formulações previstas para cada mês.
        </span>
      ),
    },
    {
      label: "Morte",
      id: "morte",
      exibir: exibirMorteNascimento,
      descricao: (
        <span>
          Para conseguir visualizar o farol de Morte da fazenda é necessário ter
          : <br />
          <br /> - Cadastro das categoria animais existentes na fazenda.
          <br />- Cadastro de causa da morte
        </span>
      ),
    },
    {
      label: "Nascimento",
      id: "nascimento",
      exibir: exibirMorteNascimento,
      descricao: (
        <span>
          Para conseguir visualizar o farol de Nascimento da fazenda é
          necessário ter :
          <br />
          <br /> - Cadastro de categoria animal
        </span>
      ),
    },
    {
      label: "Fechamento de consumo",
      id: "fechamentoConsumo",
      exibir: exibirFechamentoDeConsumo,
      descricao: (
        <span>
          Para fazer o fechamento de consumo da fazenda é necessário ter :<br />
          <br />
          <ul>
            <li>
              Cadastro dos ingredientes utilizados para criar as formulações
              utilizadas na fazenda.
            </li>
            <br />
            <li>Cadastro das formulações utilizadas na fazenda.</li>
          </ul>
        </span>
      ),
    },
    {
      label: "Bombona",
      id: "bombona",
      exibir: exibirBombona,
      descricao: (
        <span>
          Para fazer Conseguir fazer coletas de bombona na fazenda é necessário
          ter :
          <br />
          <br /> - Cadastro das formulações utilizadas na fazenda.
          <br /> - Para os cochos em que existem bombonas deve ser informado no
          cadastro de cocho que ele tem estoque final (Tela de cadastro de
          pasto/ Avançado/ Cocho)
        </span>
      ),
    },
    {
      label: "Balanço de arroba",
      id: "balancoDeArroba",
      exibir: true,
    },
    {
      label: "Entrada e saída",
      id: "entradaSaida",
      exibir: true,
      descricao: (
        <span>
          Para fazer lançamentos de entrada e saída da fazenda é necessário ter
          : <br />
          <br />- Cadastro das categoria animais existentes na fazenda.
        </span>
      ),
    },
    {
      label: "Conciliação",
      id: "concilacao",
      exibir: true,
      descricao: (
        <span>
          Para fazer a conciliação da fazenda é necessário ter : <br />
          <br /> - Cadastro das categoria animais existentes na fazenda.
          <br />- Cadastros de entradas e saídas
        </span>
      ),
    },
  ];
  funcionalidades.sort((a, b) =>
    a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
  );
  return [
    {
      label: "Todas",
      id: "todas",
      exibir: true,
    },
    ...funcionalidades,
  ];
};

const getTabsConfiguracao = (props) => {
  const {
    exibirGanhoPeso,
    exibirCadastroFormulacao,
    exibirObjetivoLote,
    exibirHistoricoChuva,
    exibirCausaMorte,
    exibirAjusteEscore,
    abaSelecionada,
    exibirEvolucaoRebanhoPan,
  } = props;

  const tabs = [
    {
      label: "Metas da Fazenda",
      id: "tabMetasFazenda",
      exibir: true,
    },
    {
      label: "Evolução de rebanho e plano de nutrição",
      id: "tabEvolucaoPan",
      exibir: exibirEvolucaoRebanhoPan,
    },
    {
      label: "Categoria Animal",
      id: "tabCategoriaAnimal",
      exibir: true,
    },
    {
      label: "Listagem de Lote",
      id: "tabListagemLote",
      exibir: true,
    },
    {
      label: "Listagem de Curral",
      id: "tabListagemCurral",
      exibir: true,
    },
    {
      label: "Formulações",
      id: "tabFormulacoes",
      exibir: exibirCadastroFormulacao,
    },
    {
      label: "Curva de ganho de peso",
      id: "tabCurvaGanhoPeso",
      exibir: exibirGanhoPeso,
    },
    {
      label: "Objetivos de lote",
      id: "tabObjetivoLote",
      exibir: exibirObjetivoLote,
    },
    {
      label: "Causa de morte",
      id: "tabCausaMorte",
      exibir: exibirCausaMorte,
    },
    {
      label: "Histórico de Chuva",
      id: "tabHisoricoChuva",
      exibir: exibirHistoricoChuva,
    },
    {
      label: "Ajuste de Escore",
      id: "tabAjusteEscore",
      exibir: exibirAjusteEscore,
    },
    {
      label: "Pesagem por Categoria",
      id: "tabPesagemCategoria",
      exibir: true,
    },
    {
      label: "Ingredientes",
      id: "tabIngredientes",
      exibir: true,
    },
  ];

  return tabs.sort((a) => (a.label === abaSelecionada ? -1 : 1));
};

const getIconCardConfiguracao = (label) => {
  const imagensList = [
    {
      label: "Metas da Fazenda",
      image: "ico-metas-fazenda.svg",
    },
    {
      label: "Evolução de rebanho e plano de nutrição",
      image: "ico-evolucao-pan.svg",
    },
    {
      label: "Categoria Animal",
      image: "ico-categoria-animal.svg",
    },
    {
      label: "Suporte de forragem",
      image: "ico-suporte-forragem.svg",
    },
    {
      label: "Listagem de Lote",
      image: "ico-listagem-lote.svg",
    },
    {
      label: "Listagem de Curral",
      image: "ico-listagem-currais.svg",
    },
    {
      label: "Formulações",
      image: "ico-formulacao.svg",
    },
    {
      label: "Curva de ganho de peso",
      image: "ico-ganho-peso.svg",
    },
    {
      label: "Objetivos de lote",
      image: "ico-objetivo-lote.svg",
    },
    {
      label: "Histórico de Chuva",
      image: "ico-historico-chuva.svg",
    },
    {
      label: "Causa de morte",
      image: "ico-causa-morte.svg",
    },
    {
      label: "Ajuste de Escore",
      image: "ico-ajuste-score.svg",
    },
    {
      label: "Pesagem por Categoria",
      image: "ico-pesagem.svg",
    },
    {
      label: "Ingredientes",
      image: "ico-ingrediente.svg",
    },
  ];
  const imageItem = imagensList.filter((value) => value.label === label);
  let image = "ico-listagem-lote.svg";
  if (imageItem.length > 0) image = imageItem[0].image;
  return image;
};
export {
  getItensConfiguracao,
  getItensFuncionalidades,
  getTabsConfiguracao,
  getIconCardConfiguracao,
};
