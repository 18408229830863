import React, { useState } from "react";
import PushPinIcon from "@mui/icons-material/PushPin";
import "./styles.css";

export const LegendaAlturaForragem = () => {
  const [open, setOpen] = useState(false);

  const handleOpenLegenda = (openLegenda) => {
    setOpen(openLegenda)
  }

  return (
    <div
      className="captionFooter"
      onMouseEnter={() => handleOpenLegenda(true)}
      onMouseLeave={() => handleOpenLegenda(false)}
    >
      <p className="captionFooterTitle">Legenda</p>

      {open && (
        <ul>
          <div>
            <PushPinIcon className="greenPin" sx={{ fontSize: 16 }} />
            <li>
              Coleta <strong>dentro</strong> da área dos pasto
            </li>
          </div>
          <div>
            <PushPinIcon className="redPin" sx={{ fontSize: 16 }} />{" "}
            <li>
              Coleta <strong>fora</strong> da área dos pasto
            </li>
          </div>
          <div>
            <PushPinIcon className="grayPin" sx={{ fontSize: 16 }} />{" "}
            <li>
              Coleta <strong>sem</strong> geolocalização
            </li>
          </div>
        </ul>
      )}

    </div>
  );
};

export default LegendaAlturaForragem;
