import BaseService from '../../services/BaseService';

const URL_BASE = `${process.env.REACT_APP_ENDPOINT}entradasaida/api/v1/tipo-movimentacao-dicionario`;

class TipoMovimentacaoService extends BaseService {
  async listarTipoMovimentacao() {
    return await this.get(URL_BASE);
  }
}

export default new TipoMovimentacaoService();
