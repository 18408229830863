import { CADASTRANDO_KML } from "redux/actions/actionsConstants";
import { SET_FAZENDA_INFO } from "features/manejo/redux/actions/kmlActions";
export const initialState = {
  CadastrandoKML: false,
  fazendaInfo: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CADASTRANDO_KML:
      return { ...state, CadastrandoKML: action.payload };

    case SET_FAZENDA_INFO:
      return { ...state, fazendaInfo: action.payload };

    default:
      return state;
  }
};
