/* eslint-disable */
import React, { useState } from "react";
import {
  TableCell,
  TextField,
  InputAdornment,
  withStyles,
} from "@material-ui/core";

const errorMessage = (classes, errorMessage) => (
  <p className={classes.errorMessage}>{errorMessage}</p>
);

export const campoPesoMedido = ({ handleAlteraLote, lote, classes }) => {
  const [error, setError] = useState("");

  const handleBlur = ({ target }) => {
    if (target.value === "0") {
      setError("O peso não pode ser zero");
    } else {
      setError("");
    }
  };

  const handleChange = ({ target }) => {
    setError("");
    handleAlteraLote(lote, "PesoMedido", target.value);
  };

  return (
    <div className={classes.cellWrapper}>
      <TextField
        id="txtPesoMedido"
        className={classes.input}
        value={lote.PesoMedido ? lote.PesoMedido : ""}
        onChange={handleChange}
        onBlur={handleBlur}
        type="number"
        placeholder="Peso"
        InputProps={{
          endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
        }}
        inputProps={{
          min: 0,
        }}
        error={error}
      />

      {error && errorMessage(classes, error)}
    </div>
  );
};

const styles = {
  cellWrapper: {
    width: "100%",
    display: "flex",
    height: 84,
    maxWidth: 100,
    minWidth: 90,
    flexDirection: "column",
    position: "relative",
    justifyContent: "center",
  },
  input: {
    maxWidth: 100,
    minWidth: 90,
    marginTop: 14,
    position: "relative",
  },
  errorMessage: {
    position: "absolute",
    bottom: "-15px",
    fontSize: "10px",
    color: "red",
  },
};

const campoPesoMedidoIgual = (prevProps, nextProps) => {
  return (
    prevProps.lote.IdLote === nextProps.lote.IdLote &&
    prevProps.lote.PesoMedido === nextProps.lote.PesoMedido
  );
};

const campoPesoMedidoMemo = React.memo(campoPesoMedido, campoPesoMedidoIgual);

export default withStyles(styles)(campoPesoMedidoMemo);
