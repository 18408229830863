import React, { PureComponent } from "react";
import { AppBar as AppBarCustomizado } from "../../layout/index";
import { Grid, Paper, withStyles } from "@material-ui/core";
import ConfiguracaoCard from "./configuracaoCard";
import FuncionalidadesDropdown from "./funcionalidadesDropdown";
import { enviaDadosGAPorRotulo } from "../../../shared/utils.js";
import { AppBarConteudoDefault } from "../../layout/";
import {
  getItensConfiguracao,
  getItensFuncionalidades,
} from "../configuracaoUtils";
import { FazendaSelecionadaContainer } from "../../shared/crossCuttingComponents";
import { withRouter } from "react-router-dom";

export class Configuracoes extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      opcaoDeCadastroSelecionada: null,
      funcionalidadeSelecionada: "Todas",
    };
  }

  selecionaOpcaoDeCadastro = (cadastroSelecionado) => {
    const { history, location, selecionaAbaCadastro } = this.props;
    selecionaAbaCadastro(cadastroSelecionado);
    history.push(`${location.pathname}/cadastros`);
  };

  selecionaFuncionalidade = (value) => {
    this.setState({ funcionalidadeSelecionada: value });
  };

  enviaDadosConfiguracoesGA = (tipoConfiguracao) => {
    const acao = "Ver Cadastros";
    const categoria = "Cadastro";
    const rotulo = tipoConfiguracao;
    enviaDadosGAPorRotulo(acao, rotulo, categoria);
  };

  renderConteudoCustomizadoAppBar = () => {
    return (
      <FazendaSelecionadaContainer
        render={(fazenda) => {
          const textoSecundarioAppBar = fazenda
            ? fazenda.NomeReduzido
            : "NENHUMA FAZENDA SELECIONADA";
          return <AppBarConteudoDefault tituloAppBar={textoSecundarioAppBar} />;
        }}
      />
    );
  };

  async componentDidMount() {
    const { fazendaSelecionada, obterCategoriasFazenda, obtemPastosFazenda } =
      this.props;

    await obterCategoriasFazenda();
    await obtemPastosFazenda(fazendaSelecionada);
    this.enviaDadosConfiguracoesGA(this.state.opcaoDeCadastroSelecionada);
  }

  render() {
    const { funcionalidadeSelecionada } = this.state;
    const { classes } = this.props;

    const itensConfiguracao = getItensConfiguracao(
      this.props,
      funcionalidadeSelecionada
    ).sort((a, b) => (a.label > b.label ? 1 : -1));
    const itensFuncionalidade = getItensFuncionalidades(this.props).filter(
      (item) => item.exibir
    );

    return (
      <React.Fragment>
        <AppBarCustomizado
          classes={classes}
          conteudoCustomizadoAppBar={this.renderConteudoCustomizadoAppBar()}
          id="appBarPersonalizado"
        />
        <Paper square className={classes.Paper}>
          <Grid container alignItems="center">
            <div className={classes.configuracaoWrapper}>
              <div className={classes.configuracaoContainer}>
                <Grid itens xs={12} className={classes.buscaDropdown}>
                  <FuncionalidadesDropdown
                    id="dropdownDeFuncionalidades"
                    handleChangeFuncionalidade={this.selecionaFuncionalidade}
                    itensFuncionalidade={itensFuncionalidade}
                    funcionalidadeSelecionada={funcionalidadeSelecionada}
                  />
                </Grid>
                <div className={classes.gridContainer}>
                  {itensConfiguracao.map((item) =>
                    item.exibir ? (
                      <div className={classes.gridItem}>
                        <ConfiguracaoCard
                          id={item.id}
                          item={item}
                          openItem={this.selecionaOpcaoDeCadastro}
                        />
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            </div>
          </Grid>
        </Paper>
      </React.Fragment>
    );
  }
}

const styles = () => {
  return {
    gridContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    },
    gridItem: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "1rem",
    },
    TextoLinkAppBar: {
      cursor: "pointer",
      fontWeight: "normal",
      fontSize: "24px",
      letterSpacing: "-0.05px",
    },
    Paper: {
      minHeight: "calc(100vh - 65px)",
    },
    configuracaoWrapper: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    configuracaoContainer: {
      width: "86%",
      display: "flex",
      flexDirection: "column",
    },
    dropdownFuncionalidade: {
      height: "72px",
      width: "100%",
      backgroundColor: "#FFFFFF",
    },
  };
};

export default withRouter(withStyles(styles)(Configuracoes));
