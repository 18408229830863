import { SelectItem } from "../selectItem";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./styles.css";

const filtrosColetas = [
  "todos",
  "dentro_da_area",
  "fora_da_area",
  "sem_geolocalizacao"
]

const filtrosMensagens = {
  "todos": "Todos",
  "dentro_da_area": "Coleta dentro da área",
  "fora_da_area": "Coleta fora da área",
  "sem_geolocalizacao": "Sem geolocalização"
}

export const SelectTypeOfCollection = ({ setFiltro, filtroColetas }) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState("Todos");

  const handleClick = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    setSelectedValue(filtrosMensagens[filtroColetas]);
  }, [filtroColetas])

  const handleOptionClick = (name) => {
    setIsActive(false);
    setFiltro(name);
  };

  return (
    <div className={`custom-select ${isActive ? "active" : ""}`}>
      <button className="select-button" onClick={handleClick}>
        <span className="selected-value">{selectedValue}</span>
        <ExpandMoreIcon className="arrow"></ExpandMoreIcon>
      </button>
      <ul className="select-dropdown" id="select-dropdown">
        {filtrosColetas.map((name) => (
          <SelectItem
            id={name}
            key={name}
            onClick={() => handleOptionClick(name)}
          >
            {filtrosMensagens[name]}
          </SelectItem>
        ))}
      </ul>
    </div>
  );
};
