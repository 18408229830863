import { SET_CONSUMOS_FORMULAS } from './fechamentoConsumoActions';

export const INITIAL_STATE = {
  formulas: [],
  diariasSemFormula: {},
  consumos: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONSUMOS_FORMULAS:
      const { consumos, formulas, diariasSemFormula } = { ...action.payload };
      return { ...state, consumos, formulas, diariasSemFormula };
    default:
      return state;
  }
};
