import React from "react";
import { withStyles } from "@material-ui/core";

const PlanningTableViewItem = ({ classes, formulas, formulaId, herdCount }) => {
  const formula = formulas.find(
    (formula) => formula.IdFormula === formulaId || formula.id === formulaId
  );

  return (
    <div className={classes.container}>
      <span data-testid="herd-count" className={classes.heardCountLabel}>
        {herdCount} cab
      </span>
      <span data-testid="formula">
        {formula && (formula.Nome || formula.name)}
      </span>
    </div>
  );
};

const styles = () => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  heardCountLabel: {
    fontWeight: "bold",
  },
});

export default withStyles(styles)(PlanningTableViewItem);
