import React from "react";
import CalculadoraCoordenadas from "../../../../../lib/calculadoraCoordenadas";
import Tooltip from "../Tooltip";
import {
  idsFarois,
  TipoFarois,
  stringSanidade,
} from "../../../../../constants/idsFarois";
import _ from "lodash";
import moment from "moment";
import TextInfoFarol from "./TextoInfoFarol";
import { constantsInfoFarol } from "constants/infoFarol";

const { labels, adornmentCochoCor, dataFormat } = constantsInfoFarol;

export const InfoFarol = (props) => {
  const {
    mouseOver,
    pasto,
    farolPasto,
    idFarolSelecionado,
    TipoIconeExibido,
    categoriasLotes,
    NomeForragem,
    TipoFarol,
    pastosComCochoCompartilhado,
    visualizarPontosColetas,
  } = props;

  if (mouseOver) {
    const position = CalculadoraCoordenadas.calcularCentroAlto(
      pasto.Coordenadas
    );
    const textoToolTip = obterTextoToolTip(
      idFarolSelecionado,
      farolPasto,
      TipoIconeExibido,
      categoriasLotes,
      pasto,
      NomeForragem,
      TipoFarol,
      pastosComCochoCompartilhado
    );
    return (
      <Tooltip
        Posicao={position}
        Texto={textoToolTip}
        visualizarPontosColetas={visualizarPontosColetas}
      />
    );
  } else {
    return null;
  }
};

const obterTextoToolTip = (
  idFarolSelecionado,
  farolPasto,
  TipoIconeExibido,
  categoriasLotes,
  pasto,
  NomeForragem,
  TipoFarol,
  pastosComCochoCompartilhado
) => {
  let textoToolTip = pasto.NomeReduzido ? pasto.NomeReduzido : pasto.Nome;
  if (TipoFarois.Altura.includes(idFarolSelecionado)) {
    textoToolTip = montaTextoTooltipAlturaPasto(
      textoToolTip,
      NomeForragem,
      farolPasto,
      TipoIconeExibido
    );
  } else if (TipoFarois.Sanidade.includes(idFarolSelecionado)) {
    textoToolTip = montaTextoTooltipSanidade(
      textoToolTip,
      farolPasto,
      categoriasLotes,
      idFarolSelecionado
    );
  } else if (TipoFarois.Cocho.includes(idFarolSelecionado)) {
    textoToolTip = montaTextoTooltipCochoCompartilhado(
      pastosComCochoCompartilhado,
      farolPasto,
      idFarolSelecionado
    );
  } else if (idFarolSelecionado === idsFarois.rebanhoPresenca) {
    textoToolTip = insereLotesDoPastoNoTooltip(
      textoToolTip,
      farolPasto,
      categoriasLotes
    );
  } else if (idFarolSelecionado === idsFarois.nascimentos) {
    textoToolTip += montaTextoTooltipNascimento(textoToolTip, farolPasto);
  } else if (
    idFarolSelecionado === idsFarois.aguadaDisponibilidade ||
    idsFarois.aguadaQualidade
  ) {
    textoToolTip = montaTextoTooltipAguada(
      textoToolTip,
      pasto.NomeReduzido ? pasto.NomeReduzido : pasto.Nome,
      farolPasto
    );
  }
  if (idFarolSelecionado === idsFarois.ganhoPeso) {
    textoToolTip += montaTextoTooltipGanhoPeso(farolPasto);
  }
  if (
    idFarolSelecionado === idsFarois.desabastecimento ||
    idFarolSelecionado === idsFarois.desabastecimentoCreep
  ) {
    textoToolTip = montaTextoTooltipCochoCompartilhado(
      pastosComCochoCompartilhado,
      farolPasto,
      idFarolSelecionado
    );
  }
  if (idFarolSelecionado === idsFarois.voltagem) {
    textoToolTip += montaTextoTooltipVoltagem(farolPasto);
  }

  if (idFarolSelecionado === idsFarois.fezes) {
    textoToolTip += montaTextoTooltipFezes(farolPasto);
  }
  if (idFarolSelecionado === idsFarois.entrevero) {
    textoToolTip += montaTextoTooltipEntreveiro(farolPasto);
  }

  return textoToolTip;
};

const montaTextoTooltipAlturaPasto = (
  textoToolTip,
  NomeForragem,
  farolPasto,
  TipoIconeExibido
) => {
  if (farolPasto && farolPasto.Valor && farolPasto.Valor.Valor) {
    textoToolTip = textoToolTip + NomeForragem;
    if (
      farolPasto.Valor.Tipo === TipoIconeExibido ||
      TipoIconeExibido === "Todos"
    ) {
      textoToolTip = formataTextoToolTipAltura(
        farolPasto,
        textoToolTip,
        TipoIconeExibido
      );
    }
  }
  return textoToolTip;
};

export const montaTextoTooltipAguada = (
  textoToolTip,
  NomePasto,
  farolPasto
) => {
  if (typeof farolPasto === typeof undefined) {
    return (textoToolTip = NomePasto);
  } else {
    const dataColeta = farolPasto.DataDaColeta
      ? " \n Data: " + farolPasto.DataDaColeta
      : "";
    return (textoToolTip = textoToolTip + " \n" + dataColeta);
  }
};

const formataTextoToolTipAltura = (
  farolPasto,
  textoToolTip,
  TipoIconeExibido
) => {
  const dataColeta = farolPasto.DataColeta
    ? " \n Data: " + new Date(farolPasto.DataColeta).toLocaleDateString()
    : "";
  const numeroTruncado = tranformaSeparacaoDoDecimalParaVirgula(
    Math.round(farolPasto.Valor.Valor) !== farolPasto.Valor.Valor
      ? farolPasto.Valor.Valor.toFixed(2)
      : farolPasto.Valor.Valor
  );
  const tipoFarol =
    TipoIconeExibido === "Todos" ? `\n${farolPasto.Valor.Tipo}` : "";
  const qualidade = farolPasto.Valor.QualidadeCapim
    ? " \n Qualidade: " + farolPasto.Valor.QualidadeCapim
    : "";
  return (
    textoToolTip +
    dataColeta +
    tipoFarol +
    " \n " +
    numeroTruncado.toString() +
    " cm" +
    qualidade
  );
};

const tranformaSeparacaoDoDecimalParaVirgula = (numero) => {
  numero = numero.toString().replace(".", ",");
  return numero;
};

export const insereLotesDoPastoNoTooltip = (
  textoToolTip,
  farolPasto,
  categoriasLotes
) => {
  let lotesDoPasto = "";
  _.forEach(farolPasto.Valor.Lotes, (lote) => {
    const categoria = _.find(
      categoriasLotes,
      (categoria) => categoria.IdCategoriaAnimal === lote.IdCategoriaAnimal
    );
    const dataFormatadaPesagem = lote.DataPesagem
      ? moment(lote.DataPesagem).format("DD/MM/YYYY")
      : "N/A";
    const dataFormatadaPesagemVisual = lote.DataPesagemVisual
      ? moment(lote.DataPesagemVisual).format("DD/MM/YYYY")
      : "N/A";
    if (categoria) {
      lotesDoPasto =
        lotesDoPasto +
        categoria.Nome.toUpperCase() +
        ":\n" +
        lote.Quantidade +
        " cab\n";
      if (
        lote.DataPesagemVisual > lote.DataPesagem ||
        lote.PesoMedido === null ||
        lote.PesoMedido === 0
      ) {
        lotesDoPasto =
          lotesDoPasto +
          "Peso visual coletado dia " +
          dataFormatadaPesagemVisual +
          ": " +
          lote.PesoVisual +
          "KG\n";
      } else if (
        lote.DataPesagem > lote.DataPesagemVisual ||
        lote.PesoMedido != null
      )
        lotesDoPasto =
          lotesDoPasto +
          "Peso pesado dia " +
          dataFormatadaPesagem +
          ": " +
          lote.PesoMedido +
          "KG\n";
      if (lote.PesoProjetado != null)
        lotesDoPasto =
          lotesDoPasto +
          "Peso Projetado para hoje: " +
          lote.PesoProjetado.toFixed(2) +
          "KG\n";
    }
  });
  if (farolPasto.AreaEfetiva != null)
    lotesDoPasto =
      lotesDoPasto + "Área efetiva empastada: " + farolPasto.AreaEfetiva + "% ";
  if (farolPasto.AreaAberta != null)
    lotesDoPasto =
      lotesDoPasto + "- Área aberta: " + farolPasto.AreaAberta + "ha ";
  const quantidadesLotesFormatada = lotesDoPasto.substring(
    0,
    lotesDoPasto.length - 1
  );
  textoToolTip = textoToolTip + "\n" + quantidadesLotesFormatada;
  return textoToolTip;
};

export const montaTextoTooltipSanidade = (
  textoToolTip,
  farolPasto,
  categoriasLotes,
  idFarolSelecionado
) => {
  let lotesDoPasto = "";
  if (farolPasto) {
    _.forEach(farolPasto.Valor.Lote, (lote) => {
      const categoria = _.find(
        categoriasLotes,
        (categoria) => categoria.IdCategoriaAnimal === lote.IdCategoriaAnimal
      );
      if (categoria) {
        lotesDoPasto = `\
          ${lotesDoPasto}${categoria.Nome.toUpperCase()}\
          - Data da Coleta: ${lote.DataColeta}
          - ${stringSanidade[idFarolSelecionado]} ${lote.Valor}`;
        if (lote.Quantidade > 0)
          lotesDoPasto = `${lotesDoPasto} - Qtd: ${lote.Quantidade}`;
        lotesDoPasto += `\n`;
      }
    });

    textoToolTip = `${textoToolTip}\n${lotesDoPasto}`;
  }
  return textoToolTip;
};

export const montaTextoTooltipNascimento = (textoToolTip, farolPasto) => {
  let corpoTooltip = "";
  if (farolPasto.Valor) {
    farolPasto.Valor.Coletas.forEach((coleta) => {
      corpoTooltip +=
        "\n\n Categoria: " +
        coleta.Categoria +
        "\n Quantidade: " +
        coleta.Quantidade +
        "\n Peso Visual: " +
        coleta.PesoVisual +
        "\n Data da Coleta: " +
        new Date(coleta.DataColeta).toLocaleDateString();
    });
  }
  return corpoTooltip;
};

const formataTextoTooltipGanhoPeso = (lote, percentual, status) => {
  const dataFormatada = lote.DataPesagemVisual
    ? moment(lote.DataPesagemVisual).format("DD/MM/YYYY")
    : "N/A";
  const pesoVisualTratado = lote.PesoVisual ? `${lote.PesoVisual} KG` : "N/A";
  const pesoProjetadoTratado = lote.PesoProjetado
    ? `${lote.PesoProjetado.toFixed(2)} KG`
    : "N/A";
  const textoToolTip = `\nCategoria: ${lote.Categoria.Nome}
  Peso visual: ${pesoVisualTratado}
  Peso projetado : ${pesoProjetadoTratado}
  Data da pesagem visual: ${dataFormatada}
  ${percentual}% ${status} do peso projetado`;
  return textoToolTip;
};

export const montaTextoTooltipGanhoPeso = (farolPasto) => {
  let detalhesDoPasto = "\nPasto sem referência";

  if (farolPasto && farolPasto.Lotes.length > 0) {
    farolPasto.Lotes.forEach((lote) => {
      let loteExibido = { Percentual: farolPasto.Lotes[0].Percentual };
      let detalhesDoLote;
      const percentual = Math.round(lote.Percentual);
      if (percentual >= 10) {
        detalhesDoLote = formataTextoTooltipGanhoPeso(
          lote,
          percentual,
          "acima"
        );
      }
      if (percentual < 10 && percentual >= -10) {
        detalhesDoLote = formataTextoTooltipGanhoPeso(
          lote,
          percentual,
          "esperado"
        );
      }
      if (percentual < -10) {
        detalhesDoLote = formataTextoTooltipGanhoPeso(
          lote,
          percentual,
          "abaixo"
        );
      }
      if (lote.Percentual <= loteExibido.Percentual) {
        detalhesDoPasto = detalhesDoLote;
        loteExibido = { ...lote };
      }
    });
  } else if (farolPasto && farolPasto.Lotes.length === 0) {
    detalhesDoPasto =
      "Pasto sem lançamento de\npeso visual ou pesado a mais\nde 30 dias.";
  }
  return detalhesDoPasto;
};

export const montaTextoTooltipVoltagem = (farolPasto) => {
  let textoToolTip = "";
  if (farolPasto && farolPasto.Cor) {
    const { Valor } = farolPasto;
    textoToolTip = Valor ? `\nVoltagem:${Valor}V` : "";
  }
  return textoToolTip;
};

export const montaTextoTooltipFezes = (farolPasto) => {
  let textoToolTip = "";
  if (farolPasto && farolPasto.Cor) {
    const { Valor } = farolPasto;
    textoToolTip = Valor ? `Fezes: ${Valor}` : "";
    if (
      farolPasto.Quantidade &&
      farolPasto.CategoriaAnimal &&
      farolPasto.DataOperacao
    ) {
      textoToolTip = `${textoToolTip} \n Data: ${farolPasto.DataOperacao}
       ${
         farolPasto.TotalReal > 0 ? farolPasto.TotalReal : farolPasto.Quantidade
       }cab`;
    }
  }

  return textoToolTip;
};

export const montaTextoTooltipEntreveiro = (farolPasto) => {
  let textoToolTip = "";
  if (farolPasto && farolPasto.Valor && farolPasto.Valor.length) {
    farolPasto.Valor.forEach((entrevero) => {
      const { Quantidade, Categoria, Data } = entrevero;
      textoToolTip = `${textoToolTip} \n ${Quantidade} ${Categoria}\n ${Data}`;
    });
  }
  return textoToolTip;
};

const mensagensFarolEstruturaEAcessoAoCocho = {
  [true]: labels.bom,
  [false]: labels.ruim,
};

const mensagensFarolCochoCoberto = {
  [true]: labels.cochoCobertoMsg,
  [false]: labels.cochoNaoCobertoMsg,
};

const infoPorFarolSelecionado = {
  [idsFarois.cochoProduto]: (valor) => ({
    [labels.quantidadeDeAnimais]: [valor.QuantidadeAnimais || 0],
  }),
  [idsFarois.cochoEstrutura]: (valor) => ({
    [labels.estruturaDoCocho]: [
      mensagensFarolEstruturaEAcessoAoCocho[valor.Valor],
    ],
  }),
  [idsFarois.cochoAcesso]: (valor) => ({
    [labels.acessoAoCocho]: [
      mensagensFarolEstruturaEAcessoAoCocho[valor.Valor],
    ],
  }),
  [idsFarois.cochoCoberto]: (valor) => ({
    [labels.cochoCoberto]: [mensagensFarolCochoCoberto[valor.Valor]],
  }),
  [idsFarois.cochoAbastecido]: (valor) => ({
    [labels.quantidadeDeAnimais]: [valor.QuantidadeAnimais || 0],
    ...(valor.Formula && { [labels.formulacao]: [valor.Formula] }),
    ...(valor.Formula && { [labels.quantidade]: [valor.Quantidade] }),
  }),
  [idsFarois.desabastecimento]: (valor) => ({
    ...(valor.DataColeta && {
      [labels.coleta]: [moment(valor.DataColeta).format(dataFormat)],
    }),
    ...(valor.DataDesabastecimento && {
      [labels.desabastecimento]: [
        moment(valor.DataDesabastecimento).format(dataFormat),
      ],
    }),
  }),
};

export const montaTextoTooltipCochoCompartilhado = (
  pastosComCochoCompartilhado,
  farolPasto,
  idFarolSelecionado
) => {
  const { title, nomesDosPastos, adornmentColor, labelNomePastos } =
    commonPropertiesCochoCompartilhado(pastosComCochoCompartilhado);

  let properties = {};

  if (nomesDosPastos) {
    properties[labelNomePastos] = nomesDosPastos;
  }

  if (farolPasto.DataDaColeta) {
    properties[labels.data] = [farolPasto.DataDaColeta];
  }

  if (
    farolPasto.Valor !== null &&
    infoPorFarolSelecionado[idFarolSelecionado]
  ) {
    const infoPorFarol = infoPorFarolSelecionado[idFarolSelecionado](
      farolPasto.Valor
    );
    properties = {
      ...properties,
      ...infoPorFarol,
    };
  }

  return () => (
    <TextInfoFarol
      title={title}
      properties={properties}
      titleAdornmentColor={adornmentColor}
    />
  );
};

const commonPropertiesCochoCompartilhado = (pastosComCochoCompartilhado) => {
  let title = labels.cochoPadrao;
  let nomesDosPastos;
  let adornmentColor = adornmentCochoCor.padrao;
  let labelNomePastos = labels.nomeDoPasto;

  if (pastosComCochoCompartilhado && pastosComCochoCompartilhado.length > 0) {
    if (pastosComCochoCompartilhado.length > 1) {
      title = labels.cochoCompartilhado;
      adornmentColor = adornmentCochoCor.compartilhado;
      labelNomePastos = labels.nomeDosPastos;
    }
    nomesDosPastos = pastosComCochoCompartilhado.map((pasto) => pasto.Nome);
  }

  return { title, nomesDosPastos, adornmentColor, labelNomePastos };
};

export default InfoFarol;
