import React from "react";
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  withStyles
} from "@material-ui/core";
import { colunasTabelaPorCategoria } from "./balancoArrobaUtil";

const BalancoArrobaTabelaCategorias = (props) => {
  const { classes, balancosFazenda } = props;
  const categorias = balancosFazenda.Categorias;
  return (
    <Paper className={classes.paperTabela}>
      <Table id="tabela_categorias" className={classes.tabelaCategorias}>
        <TableHead className={classes.cabecalhoTabela}>
          <TableRow>
            {colunasTabelaPorCategoria.map(({ label, style }, key) => (
              <TableCell key={key} className={classes[style]}>
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {categorias.map((categoria) => (
            <TableRow key={categoria.IdCategoriaAnimal}>
              <TableCell className={classes.linhasTabela}>
                {categoria.Nome}
              </TableCell>
              <TableCell className={classes.linhasTabela}>
                {categoria.QuantidadeInicial}
              </TableCell>
              <TableCell className={classes.linhasTabela}>
                {categoria.PesoInicial}
              </TableCell>
              <TableCell className={classes.linhasTabela}>
                {categoria.QuantidadeFinal}
              </TableCell>
              <TableCell className={classes.linhasTabela}>
                {categoria.PesoFinal}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

const celulaComumCabecalho = (theme) => ({
  color: theme.palette.primary.main,
  padding: "4px 4px",
  fontSize: "14px",
  fontWeight: "500",
  letterSpacing: "1px",
  textAlign: "center",
});
const styles = (theme) => ({
  paperTabela: {
    borderRadius: "16px",
  },
  tabelaCategorias: {
    width: "100%",
    fontFamily: "Roboto",
    textAlign: "center",
    borderRadius: "2px",
    padding: "4px 24px 4px 24px",
    fontSize: "14px",
  },
  cabecalhoTabela: {
    marginTop: "25px",
    marginBottom: "25px",
    padding: "4px 24px 4px 24px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    backgroundColor: "#e1e1e1",
  },
  celulaComumCabecalho: celulaComumCabecalho(theme),
  cabecalhoPrimeiraCelula: {
    ...celulaComumCabecalho(theme),
    borderRadius: "16px 0 0 0 ",
  },
  cabecalhoUltimaCelula: {
    ...celulaComumCabecalho(theme),
    borderRadius: "0 16px 0 0",
  },
  linhasTabela: {
    color: "#666",
    padding: "4px 24px 4px 24px",
    textAlign: "center",
    fontSize: "14px",
    letterSpacing: "1px",
  },
});

export default withStyles(styles)(BalancoArrobaTabelaCategorias);
