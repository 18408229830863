import PastoService from '../../services/PastoService';
import LoteService from './LoteService';

export class LoteManager {
  async obterLotesPasto(idPasto) {
    const respostaService = await LoteService.listaLotesPasto(idPasto);
    return respostaService.data;
  }

  async atualizarLotes(idsLotesParaAtualizar, lotes) {
    const idsLotes = [...new Set(idsLotesParaAtualizar)];
    const lotesAlterados = lotes.filter(lote => {
      return idsLotes.some(id => id === lote.IdLote);
    });
    return await LoteService.atualizarLotes(lotesAlterados);
  }

  verificaStatusDaSanidadeDoLote(sanidade) {
    if (!sanidade) {
      return {
        legenda: ' -  ',
        cor: 'disabled'
      };
    }

    sanidade = sanidade[0];

    if (
      sanidade.LivreDeParasitas === 0 ||
      sanidade.QuantidadeBicheira > 0 ||
      sanidade.QuantidadeOutrosRiscos > 0
    ) {
      return {
        legenda: 'Ruim',
        cor: 'error'
      };
    } else {
      return {
        legenda: 'Boa',
        cor: 'secondary'
      };
    }
  }

  async obterLotesReduzidosPorPastos(pastos, categorias, fazendaSelecionada) {
    let pastosUsados = pastos;
    if (pastosUsados.length === 0) {
      const pastosAtualizado = await PastoService.listaPastosPorIdFazenda(fazendaSelecionada, [], ['Modulo', 'Cocho']);
      pastosUsados = pastosAtualizado.data.filter(pasto => pasto.Coordenadas !== null);
    }
    const resposta = await LoteService.listaLotesReduzidosPorPastos(fazendaSelecionada);
    const lotes = resposta.map(lote => {
      const pastoDoLote = pastosUsados.find(pasto => pasto.IdPasto === lote.IdPasto);
      const categoriaDoLote = categorias.find(
        categoria => categoria.IdCategoriaAnimal === lote.IdCategoriaAnimal
      );
      lote.pasto = {
        NomeReduzido: pastoDoLote ? pastoDoLote.NomeReduzido : null
      };
      lote.categoria = {
        Nome: categoriaDoLote ? categoriaDoLote.Nome : null
      };
      lote.StatusSanidade = this.verificaStatusDaSanidadeDoLote(lote.Sanidade);
      return lote;
    });
    return lotes;
  }

  async obterProjecaoSemestrePorLote(lote) {
    const response = await LoteService.obterProjecaoSemestrePorLote(lote.IdLote);
    return response.data;
  }

  async obterCurvaGanhoPesoPorFiltro(idFazenda) {
    const response = await LoteService.obterCurvaGanhoPesoPorFiltro(idFazenda);
    return response.data;
  }
}

export default new LoteManager();
