import React from "react";
import { withStyles, Tooltip } from "@material-ui/core";
import constants from "../shared/constants";

function GoalPlanStatus({ classes, active, initialDate, finalDate, situation }) {
  const isActive = getStatus() === "Ativo";
  const isExpired = getStatus() === "Fora de vigência";
  const tooltipTitle = isExpiredOrInTheFuture();

  function isExpiredOrInTheFuture() {
    if (isExpired && new Date(finalDate) < new Date()) {
      return "O plano foi inativado automaticamente pois já atingiu sua vigência.";
    }
    if (isExpired && new Date(initialDate) > new Date()) {
      return "O plano foi inativado automaticamente pois ainda não está no período vigência.";
    }
    return "";
  }

  function getStatus() {
    switch (situation) {
      case constants.situation.INACTIVE:
        return "Inativo";
      case constants.situation.PAST:
        return "Fora de vigência";
      case constants.situation.FUTURE:
        return "Fora de vigência";
      default:
        return "Ativo";
    }
  }

  return (
    <Tooltip
      title={tooltipTitle}
      placement="left"
      classes={{ tooltip: classes.tooltip }}
    >
      <div
        className={`${classes.container} ${isActive ? classes.activeBackground : classes.inactiveBackground
          }`}
      >
        <span
          className={isActive ? classes.activeStatus : classes.inactiveStatus}
        >
          {getStatus()}
        </span>
      </div>
    </Tooltip>
  );
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    maxWidth: "150px",
    borderRadius: "100px",
    padding: "6px 12px",
  },
  tooltip: {
    padding: 16,
    width: 200,
    fontSize: 14,
  },
  activeBackground: {
    backgroundColor: "#dff1df",
  },
  inactiveBackground: {
    backgroundColor: "#f8807833",
  },
  activeStatus: {
    color: "#4caf50",
    fontWeight: "550",
  },
  inactiveStatus: {
    textAlign: "center",
    color: "#f44336",
    fontWeight: "550",
  },
};

export default withStyles(styles)(GoalPlanStatus);
