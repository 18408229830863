import ModalDetalhesFormulacao from "./components/modalDetalhesFormulacao";
import React from "react";

const FormulationModalPage = ({
  formula,
  open,
  onBackdropClick,
  onClickAway,
  dragProps,
  containerDragRef,
  capacidadeMisturador,
  changeCapacidade,
  imprimir,
  quantidadeTotal,
  custoCabTotal,
  alterarStatusModal,
  formatarCampos,
  imprimirFormula,
  listaIngredientes,
}) => {
  return (
    <ModalDetalhesFormulacao
      formula={formula}
      open={open}
      onBackdropClick={onBackdropClick}
      onClickAway={onClickAway}
      dragProps={dragProps}
      ref={containerDragRef}
      capacidadeMisturador={capacidadeMisturador}
      changeCapacidade={changeCapacidade}
      imprimir={imprimir}
      quantidadeTotal={quantidadeTotal}
      custoCabTotal={custoCabTotal}
      alterarStatusModal={alterarStatusModal}
      formatarCampos={formatarCampos}
      imprimirFormula={imprimirFormula}
      listaIngredientes={listaIngredientes} />
  )
};
export default FormulationModalPage;
