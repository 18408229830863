import memoize from "memoize-one";
import * as _ from "lodash";

export const filterCurralMemoize = memoize((list, textoFiltrar) =>
  _.filter(list, (curral) => {
    curral.Nome = curral.Nome || "";
    return curral.Nome.toUpperCase().includes(textoFiltrar.toUpperCase());
  })
);

export const convertLivestockToCurral = (livestocks) => {
  return livestocks.map((livestock) => {
    return {
      Ativo: livestock.endDate ? false : true,
      DataCriacao: livestock.createdAt,
      DataExclusao: livestock.deletedAt,
      DataOperacao: livestock.operationDate,
      DataValidade: livestock.endDate,
      Excluido: livestock.endDate ? true : false,
      IdCategoriaAnimal: livestock.animalCategoryId,
      IdPasto: livestock.pastureId,
      Quantidade: livestock.quantity,
    };
  });
};
