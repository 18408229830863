import React from "react";
import { Grid, FormControl, TextField, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import SelectDropdown from "components/SelectDropdown";

export const CategoriaForm = ({
  categoria,
  erroNome,
  exibeQuantidadeConciliacao,
  exibeTamanhoLote,
  handleChangeNomeCategoria,
  erroTamanho,
  handleChangeTamanhoCategoria,
  erroQuantidadeInicial,
  handleChangeQuantidadeInicialCategoria,
  handleChangeSexoCategoria,
  handleChangeFaixaIdade,
  faixasIdade,
  adicionarCategoria,
  habilitarCategoria,
  exibirGanhoPeso,
  CurvasGanhoPeso,
  handleChangeCurvaGanhoPeso,
  classes,
}) => {
  const retiraInput = [];

  if (!exibeQuantidadeConciliacao)
    retiraInput.push("adornment-quantidade-inicial");

  if (!exibeTamanhoLote) retiraInput.push("adornment-tamanho");

  const faixaIdadeOptions = faixasIdade.map((faixa) => ({
    value: faixa.IdFaixaIdade,
    label: faixa.FaixaIdade,
  }));

  const sexoOptions = [
    { value: "M", label: "M" },
    { value: "F", label: "F" },
  ];

  const exibeCadastroCurvaGanhoPeso = () => {
    if (exibirGanhoPeso) {
      return (
        <SelectDropdown
          isDisabled={!CurvasGanhoPeso.length}
          isClearable
          label={{
            id: "select-weight-gain-curve",
            text: "Curva de Ganho de Peso",
          }}
          options={mapWeightGainCurveOptions(CurvasGanhoPeso)}
          value={categoria.IdCurvaGanhoPeso}
          styles={{ width: "250px" }}
          onChange={handleChangeCurvaGanhoPeso}
        />
      );
    }
  };

  const mapWeightGainCurveOptions = (weightGainCurves) => {
    const weightGainCurveOptions = weightGainCurves.length
      ? weightGainCurves.map((curva) => ({
          value: curva.IdCurvaGanhoPeso,
          label: curva.Nome,
        }))
      : [{ value: null, label: "Sem cadastros" }];

    return weightGainCurveOptions;
  };

  return (
    <div className={classes.categoryContainer}>
      <Grid item sm={12} xs={12}>
        <Typography className={classes.categoryTitle} gutterBottom>
          {categoria.IdCategoriaAnimal ? "Editar Categoria" : "Nova Categoria"}
        </Typography>
      </Grid>
      <Typography className={classes.warningText}>
        <img
          src="../images/ic_warning_outlined.svg"
          alt="warning-icon"
          className={classes.warningIcon}
        />
        Para que o <b>peso projetado </b>
        fique disponível na listagem de lotes, é necessário associar uma curva
        de ganho de peso a categoria animal criada.
      </Typography>
      <Grid item sm={12} xs={12}>
        <Typography className={classes.subtitulo} gutterBottom>
          Insira as informações da categoria
        </Typography>
      </Grid>
      <div className={classes.formContainer}>
        <FormControl>
          <TextField
            value={categoria.Nome}
            autoFocus
            id={"adornment-nome"}
            required
            label={"Nome"}
            placeholder={"Digite um nome"}
            error={erroNome}
            helperText={erroNome ? "Erro - Nome já cadastrado" : ""}
            onChange={handleChangeNomeCategoria}
          />
        </FormControl>
        {exibeTamanhoLote && (
          <FormControl>
            <TextField
              className={classes.textTamanhoDesejado}
              value={categoria.TamanhoDesejado}
              id={"adornment-tamanho"}
              placeholder={"Digite o tamanho desejado"}
              label={"Tamanho desejado (cab)"}
              error={erroTamanho}
              onChange={handleChangeTamanhoCategoria}
              retiraInput={retiraInput}
            />
          </FormControl>
        )}
        <FormControl>
          {exibeQuantidadeConciliacao && (
            <TextField
              className={classes.textTamanhoDesejado}
              value={
                categoria.QuantidadeInicialParaConciliacao
                  ? categoria.QuantidadeInicialParaConciliacao
                  : ""
              }
              id={"adornment-quantidade-inicial"}
              placeholder={"Digite a quantidade desejada"}
              label={"Quantidade inicial"}
              error={erroQuantidadeInicial}
              onChange={handleChangeQuantidadeInicialCategoria}
              retiraInput={retiraInput}
            />
          )}
        </FormControl>
        <SelectDropdown
          label={{ text: "Sexo", required: true }}
          options={sexoOptions}
          value={categoria.Sexo}
          helperText={categoria.Sexo === "" ? "Sexo não pode ser vazio" : ""}
          styles={{ width: "100px" }}
          onChange={handleChangeSexoCategoria}
        />
        <SelectDropdown
          id="slc_FaixaIdade"
          label={{ text: "Faixa de Idade", required: true }}
          options={faixaIdadeOptions}
          value={categoria.IdFaixaIdade}
          helperText={
            categoria.FaixaIdade === "" ? "Faixa Idade não pode ser vazio" : ""
          }
          styles={{ width: "250px" }}
          onChange={handleChangeFaixaIdade}
        />
        {exibeCadastroCurvaGanhoPeso(exibirGanhoPeso)}
      </div>
      <Grid item sm={12} xs={12}>
        <Button
          id="botaoAdicionar"
          disabled={
            categoria.Nome === "" ||
            categoria.Sexo === "" ||
            erroNome ||
            erroQuantidadeInicial ||
            !categoria.IdFaixaIdade
          }
          variant="contained"
          color="primary"
          onClick={() => adicionarCategoria(categoria)}
          className={classes.corpoBotoesForm}
        >
          {categoria.IdCategoriaAnimal ? "Salvar" : "Adicionar"}
        </Button>
        <Button
          id="habilitarCategoria"
          variant="contained"
          color="primary"
          className={classes.corpoBotoesForm}
          onClick={() => habilitarCategoria(false)}
        >
          Cancelar
        </Button>
      </Grid>
    </div>
  );
};

const styles = (theme) => {
  return {
    categoryContainer: {
      paddingBottom: "16px",
    },
    categoryTitle: {
      fontSize: "20px",
      fontWeight: "bold",
      fontStyle: "medium",
      fontStretch: "medium",
      lineHeight: "medium",
      letterSpacing: "medium",
      color: "#333333",
      marginTop: "16px",
    },
    corpoBotoesForm: {
      marginTop: "36px",
      marginRight: "20px",
    },
    FaixaIdade: {
      marginRight: "20px",
      verticalAlign: "bottom",
    },
    subtitulo: {
      width: "227px",
      height: "16px",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "500",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.03px",
      color: "#535353",
      marginTop: "24px",
      marginBottom: "16px",
    },
    warningIcon: {
      marginRight: "4px",
      marginBottom: "-3px",
      height: "20px",
      color: "red",
    },
    warningText: {
      margin: "26px 0px 26px 0px",
      fontFamily: "Roboto",
      lineHeight: 1.71,
      color: "#666",
    },
    formContainer: {
      display: "flex",
      flexDirection: "row",
      gap: "32px",
      flexWrap: "wrap",
    },
    faixasIdadeLabel: {
      display: "flex",
      alignItems: "center",
      gap: "4px",
    },
    faixasIdadeLabelInfoIcon: {
      color: theme.palette.primary.main,
    },
  };
};

export default withStyles(styles)(CategoriaForm);
