import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  InputAdornment,
  Tooltip,
  withStyles
} from "@material-ui/core";
import { mapeiaCategoriasNasPesagens } from "../pesagemCategoriaUtils";
import InfoIcon from "@material-ui/icons/Info";

export const PesagemCategoriaTabela = ({
  classes,
  CategoriaEventoPesagems,
  categorias,
  setPesagens,
  indexEventoAtual,
  editar,
  criarNovo,
}) => {
  const iconeAlertaEQuantidade = (cat) => (
    <div style={{ position: "relative" }}>
      {cat.QuantidadeAnimais <= 0 && (
        <img
          style={{
            right: "56%",
            position: "absolute",
          }}
          src="../images/ic_warning.svg"
          alt="icone de alerta"
        />
      )}
      {cat.QuantidadeAnimais}
    </div>
  );
  const categoriasMapeadas = mapeiaCategoriasNasPesagens(
    categorias,
    CategoriaEventoPesagems
  );

  const qtdMessage =
    "É a quantidade de animais na conciliação de rebanho na visão do portal.";
  const diferencaAppMessage =
    "É a diferença da quantidade de animais do App com o portal.";

  // Altera o peso de uma categoria dentro de um evento
  const alteraPesagemCategoria = (value, indexCategoriaAtual) => {
    setPesagens((pesagens) => {
      return pesagens.map((pesagem, indexPesagens) => {
        // Procura dentro do estado o evento atual que está sendo modificado
        if (indexPesagens === indexEventoAtual) {
          /*
          Cria um array com as categorias dentro do evento selecionado,
          alterando somente a categoria que está sendo editada
          */
          const categoriasModificadas = pesagem.CategoriaEventoPesagems.map(
            (categoria, indexCategoria) => {
              // Procura a categoria editada pelo index
              if (indexCategoria === indexCategoriaAtual) {
                return {
                  ...categoria,
                  PesoPesado: Number(value),
                };
              } else {
                return categoria;
              }
            }
          );
          return {
            ...pesagem,
            CategoriaEventoPesagems: categoriasModificadas,
          };
        } else {
          return pesagem;
        }
      });
    });
  };

  return (
    <div style={{ overflowY: "auto", width: "100%", maxHeight: "600px" }}>
      <Table>
        <TableHead>
          <TableRow style={{ backgroundColor: "#e1e1e1" }}>
            <TableCell className={classes.tableCellHead}>CATEGORIA</TableCell>
            <TableCell className={classes.tooltipPadding}>
              QUANTIDADE DE ANIMAIS
              <Tooltip title={qtdMessage}>
                <InfoIcon
                  color="primary"
                  style={{
                    fontSize: "20px",
                    position: "relative",
                    left: "4%",
                    top: "4px",
                  }}
                />
              </Tooltip>
            </TableCell>
            <TableCell className={classes.tooltipPadding}>
              DIFERENÇA DO APP
              <Tooltip title={diferencaAppMessage}>
                <InfoIcon
                  color="primary"
                  style={{
                    fontSize: "20px",
                    position: "relative",
                    left: "4%",
                    top: "4px",
                  }}
                />
              </Tooltip>
            </TableCell>

            <TableCell className={classes.tableCellHead}>PESO ATUAL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categoriasMapeadas.map((cat, i) => (
            <TableRow
              style={{
                backgroundColor:
                  cat.QuantidadeAnimais <= 0 ? "rgba(216, 216, 216, 0.7)" : "",
              }}
              data-testid="linhasDaTabela"
              key={i}
            >
              <TableCell
                data-testid="nomeDaCategoria"
                className={classes.nomeCategoria}
                component="th"
                scope="row"
              >
                {cat.Nome}
              </TableCell>
              <TableCell
                data-testid="quantidadeAnimais"
                className={classes.celulas}
                component="th"
                scope="row"
                style={{
                  color: cat.QuantidadeAnimais <= 0 ? "#ff0102" : "",
                }}
              >
                {editar || criarNovo || cat.QuantidadeAnimais > 0 ? (
                  iconeAlertaEQuantidade(cat)
                ) : (
                  <Tooltip
                    title={
                      "Você não pode ter uma quantidade de animais menor ou igual a zero em uma categoria, entre em Controle de Rebanho, na aba de Entradas e Saídas e faça o lançamento correto da quantidade de animais, para que essa categoria seja contabilizada no Balanço de Arroba."
                    }
                    placement="top"
                    arrow
                  >
                    {iconeAlertaEQuantidade(cat)}
                  </Tooltip>
                )}
              </TableCell>
              <TableCell
                data-testid="diferencaDoApp"
                className={classes.celulas}
                component="th"
                scope="row"
              >
                {cat.DiferencaApp}
              </TableCell>
              <TableCell className={classes.celulas} component="th">
                {cat.QuantidadeAnimais > 0 &&
                  (editar || (criarNovo && indexEventoAtual === 0) ? (
                    <TextField
                      onChange={({ target: { value } }) => {
                        if (!isNaN(value)) {
                          alteraPesagemCategoria(value, i);
                        }
                      }}
                      style={{ width: "100px" }}
                      variant="standard"
                      InputProps={{
                        endAdornment: <InputAdornment>Kg</InputAdornment>,
                      }}
                      /* As propriedades tem o mesmo nome e comportamentos diferentes. */
                      /* eslint-disable-next-line */
                      inputProps={{
                        "data-testid": `inputPeso${i}`,
                        className: classes.inputPeso,
                        maxlength: 8,
                      }}
                      value={cat.PesoPesado}
                    />
                  ) : (
                    <Typography data-testid="pesoCategoria">{`${cat.PesoPesado}Kg`}</Typography>
                  ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const styles = (theme) => ({
  boxTexto: {
    justifyContent: "center",
  },
  nomeCategoriaHead: {
    textAlign: "center",
    fontSize: "14px",
    color: theme.palette.primary.main,
  },
  tableCellHead: {
    textAlign: "center",
    width: "10%",
    fontSize: "14px",
    color: theme.palette.primary.main,
    paddingTop: "6px",
  },
  celulas: {
    color: "#666",
    fontSize: "14px",
    textAlign: "center",
    justifyContent: "stretch",
  },
  nomeCategoria: {
    color: "#666",
    fontSize: "14px",
    textAlign: "center",
  },
  inputPeso: {
    color: "#666",
    textAlign: "center",
  },
  tooltipPadding: {
    paddingBottom: "10px",
    textAlign: "center",
    width: "10%",
    fontSize: "14px",
    color: theme.palette.primary.main,
  },
});

export default withStyles(styles)(PesagemCategoriaTabela);
