import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import { TextLabel, FeedBackPage } from "../../shared/components";

export const feedbackDescritivoLote = (props) => {
  const { classes } = props;
  const msgFeedback =
    "Não há operações para a categoria no período solicitado.";

  return (
    <Grid container direction='column' justify='center'>
      <FeedBackPage>
        <Grid container direction='column' alignItems='center'>
          <img
            className={classes.imagemFeedback}
            src='../images/fechamentoTelaVazia.png'
            alt={msgFeedback}
          />
          <TextLabel className={classes.textoFeedback}>{msgFeedback}</TextLabel>
        </Grid>
      </FeedBackPage>
    </Grid>
  );
};

const styles = (themes) => {
  return {
    textoFeedback: {
      fontSize: "12px",
      color: "#666666",
      fontFamily: "Roboto"
    },
    imagemFeedback: {
      position: "relative",
      top: "-30px",
      width: "150px",
      height: "150px",
      margin: "20px"
    }
  };
};

export default withStyles(styles)(feedbackDescritivoLote);
