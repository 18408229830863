import React from "react";
import { Button, TextField, withStyles } from "@material-ui/core";
import ImprimirIcon from "features/shared/components/icons/ImprimirIcon";
import SelectDropdown from "components/SelectDropdown";

const BatchListingFilter = ({
  classes,
  retiros,
  filtroPesoSelecionado,
  filtroPesoProjetadoSelecionado,
  tiposFiltrosPesoPesado,
  tiposFiltrosPesoProjetado,
  filtroDefault,
  handlePrint,
  valorRetiroSelecionado,
  categoriaTexto,
  setValorRetiroSelecionado,
}) => {
  const retreatOptions = retiros.map((retiro) => ({
    value: retiro.Nome,
    label: retiro.Nome,
  }));
  retreatOptions.unshift({ value: "Todos", label: "Todos" });

  const weighedWeightOptions = tiposFiltrosPesoPesado.map((option) => ({
    value: option.Tipo,
    label: option.Tipo,
  }));

  const projectedWeightOptions = tiposFiltrosPesoProjetado.map((option) => ({
    value: option.Tipo,
    label: option.Tipo,
  }));

  return (
    <div className={classes.container}>
      <SelectDropdown
        testId="selectFazendaRetiroListagemLote"
        label={{ text: "Retiro" }}
        options={retreatOptions}
        value={valorRetiroSelecionado}
        onChange={setValorRetiroSelecionado}
        styles={{ width: "200px" }}
      />
      <TextField
        value={categoriaTexto}
        id="inputFiltroLote"
        autoFocus
        className={classes.filtroTexto}
        label={"Buscar por categoria ou pastos"}
        style={{ width: 300 }}
        onChange={(event) => filtroDefault(event.target.value, "texto")}
      />
      <SelectDropdown
        id="selectTemPesoMedidoListagemLote"
        label={{ text: "Peso Pesado" }}
        options={weighedWeightOptions}
        value={filtroPesoSelecionado}
        onChange={(value) => filtroDefault(value, "peso")}
        styles={{ width: "130px" }}
      />
      <SelectDropdown
        id="selectTemPesoProjetadoListagemLote"
        label={{ text: "Peso Projetado" }}
        options={projectedWeightOptions}
        value={filtroPesoProjetadoSelecionado}
        onChange={filtroDefault}
        styles={{ width: "130px" }}
      />
      <Button
        id="botaoImprimirLotes"
        onClick={handlePrint}
        variant="contained"
        color="primary"
        className={classes.botaoImprimir}
      >
        <ImprimirIcon />
      </Button>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "32px",
    alignItems: "center",
  },
  filtroTexto: {
    marginTop: "4px",
  },
  botaoImprimir: {
    borderRadius: 18,
    boxShadow: "none",
  },
};

export default withStyles(styles)(BatchListingFilter);
