import history from "../app/History";

const validacaoRotas = (rota) => window.location.pathname.includes(rota);

/*
Função para criar manualmente o history,
validar a rota atual, se necessário,
e redirecionar para a pagina desejada
*/
const redirecionaHistoryManual = ({ rota = "", novaRota }) => {
  if (validacaoRotas(rota) && !!novaRota) {
    history.push(novaRota);
    history.go(0);
  }
};

const changeLocation = (rota) => {
  window.location = rota;
};

export { redirecionaHistoryManual, changeLocation };
