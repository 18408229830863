import React from "react";
import { Grid } from "@material-ui/core";
import LensIcon from "@material-ui/icons/Lens";

export const campoStatusSanidade = ({ exibirColunaSanidade, lote }) => {
  if (!exibirColunaSanidade) {
    return null;
  }
  const stylesGrid = {
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
    fontSize: 14,
  };
  return (
    <Grid padding="none" align="bottom" style={stylesGrid}>
      <LensIcon
        fontSize="inherit"
        style={{ marginRight: 6 }}
        color={lote.StatusSanidade.cor}
      ></LensIcon>
      {` ` + lote.StatusSanidade.legenda}
    </Grid>
  );
};

const campoStatusSanidadeIgual = (prevProps, nextProps) => {
  const loteIgual = prevProps.lote.IdLote === nextProps.lote.IdLote;
  const exibirColunaSanidadeIgual =
    prevProps.lote.exibirColunaSanidade === nextProps.lote.exibirColunaSanidade;

  return loteIgual && exibirColunaSanidadeIgual;
};

const campoStatusSanidadeMemo = React.memo(
  campoStatusSanidade,
  campoStatusSanidadeIgual
);

export default campoStatusSanidadeMemo;
