import React, { Component } from "react";
import { connect } from "react-redux";
import { default as LoadingSalvandoComponent } from "./LoadingSalvando";

export class LoadingSalvando extends Component {
  render() {
    const { loading, balanco } = this.props;
    return (
      <LoadingSalvandoComponent
        id="containerLoadingSalvando"
        loading={loading}
        balanco={balanco}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
  };
};

export default connect(mapStateToProps)(LoadingSalvando);
