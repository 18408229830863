import * as tiposMovimentacaoActions from './tipoMovimentacaoActions';

export const INITIAL_STATE = {
  TiposMovimentacao: [],
  TipoMovimentacao: null
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case tiposMovimentacaoActions.LISTA_TIPO_MOVIMENTACAO:
      return {
        ...state, TiposMovimentacao: action.payload.TiposMovimentacao
      };
    default:
      return state;
  }
};
