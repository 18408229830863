import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import { PaginaComAppBar } from "../../layout";
import { withStyles } from "@material-ui/core";
import Mapa from "./Mapa";
import SwitchMenuLateral from "./MenuLateral/SwitchMenuLateral";
import withLogin from "../../authentication/withLogin";
import * as actions from "redux/actions";

import RetiroDropDown from "features/manejo/views/components/TopDropdowns/RetiroDropdown";
import FazendaDropdown from "features/manejo/views/components/TopDropdowns/FazendaDropdown";
import ClienteDropdown from "features/manejo/views/components/TopDropdowns/ClienteDropdown";
import UserClientFarmValidation from "features/manejo/views/components/UserClientFarmValidation";
import { AvisoConsultoriaContainer } from "../../avisoConsultoria";
import {
  deveExibirAvisoConsultoria,
  deveExibirConfiguracaoFazenda,
} from "../../shared/utils";
import TracksaleNps from "features/manejo/views/components/TracksaleNps";
import MenuManejo from "./components/MenuManejo";

export class Manejo extends Component {
  async componentDidMount() {
    await this.props.obterDadosUsuario();
  }

  renderConteudoCustomizadoAppBar() {
    const { classes } = this.props;
    return (
      <div className={classes.appBar}>
        <ClienteDropdown />
        <FazendaDropdown />
        <RetiroDropDown />
      </div>
    );
  }

  render() {
    const {
      classes,
      ClienteSelecionado,
      FazendaSelecionada,
      Fazendas,
      exibirAvisoConsultoria,
      exibirConfiguracaoFazenda,
    } = this.props;

    return (
      <>
        <div id="manejo">
          <PaginaComAppBar
            id="paginaComAppBar"
            conteudoCustomizadoAppBar={this.renderConteudoCustomizadoAppBar()}
            menuNavegacaoProps={{
              usuarioPodeInserirConfiguracaoFazenda: exibirConfiguracaoFazenda,
              FazendaSelecionada: FazendaSelecionada,
            }}
            conteudoPagina={
              <Grid container className={classes.gridContainer}>
                <Grid item xs={12} sm={4} className={classes.grid}>
                  <Paper square={true} className={classes.lateralMenuContainer}>
                    <MenuManejo hideRegistrations={!FazendaSelecionada} />
                    <div className={classes.content}>
                      <SwitchMenuLateral />
                    </div>{" "}
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={8} className={classes.grid}>
                  <Paper square={true} className={classes.paper}>
                    <Mapa />
                  </Paper>
                </Grid>
              </Grid>
            }
            clienteSelecionado={ClienteSelecionado}
          />
          {exibirAvisoConsultoria && <AvisoConsultoriaContainer />}
          <TracksaleNps
            ClienteSelecionado={ClienteSelecionado}
            FazendaSelecionada={FazendaSelecionada}
            Fazendas={Fazendas}
          />
        </div>
        <UserClientFarmValidation
          clienteSelecionado={ClienteSelecionado}
          fazendaSelecionada={FazendaSelecionada}
        />
      </>
    );
  }
}

Manejo.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    ClienteSelecionado: state.cliente.ClienteSelecionado,
    Fazendas: state.fazenda.Fazendas,
    FazendaSelecionada: state.fazenda.FazendaSelecionada,
    exibirAvisoConsultoria: deveExibirAvisoConsultoria(state),
    exibirConfiguracaoFazenda: deveExibirConfiguracaoFazenda(state),
    Pastos: state.pasto.Pastos,
  };
}

const styles = (theme) => ({
  lateralMenuContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  lateralMenu: {
    height: "100%",
  },
  paper: {
    textAlign: "center",
    color: theme.palette.secondary.main,
    height: "100%",
  },
  gridContainer: {
    margin: 0,
  },
  grid: {
    margin: 0,
    padding: "0 !important",
  },
  content: {
    height: `calc(100vh - 100px - ${theme.props.tamanhoAppBarSm}px)`,
    "@media (min-width: 770px)": {
      height: `calc(100vh - 100px - ${theme.props.tamanhoAppBarMd}px)`,
    },
    "@media (min-width: 1129px)": {
      height: `calc(100vh - 100px - ${theme.props.tamanhoAppBarXs}px)`,
    },
    overflowY: "scroll",
  },
  appBar: { display: "flex", gap: "12px" },
  "@media (max-width: 600px)": {
    appBar: { flexWrap: "wrap", margin: "16px 0" },
  },
});

export default compose(
  withLogin,
  withStyles(styles),
  connect(mapStateToProps, actions)
)(Manejo);
