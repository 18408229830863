import { coresModulos } from "../../../constants/coresModulos";
export default (theme) => ({
  container: {
    flexGrow: 1,
  },
  abaContainer: {
    padding: "0px 24px 0px 24px",
    marginBottom: "30px",
  },
  AppBar: {
    boxShadow: "none",
  },
  tab: {
    margin: "0px 30px 0px 30px",
  },
  menuVinculo: {
    textAlign: "left",
    color: "#444444",
    minHeight: "50px",
    fontSize: "18px",
    letterSpacing: "0px",
    padding: "24px",
  },
  linhaAzul: {
    minHeight: "2px",
    minWidth: "20px",
    display: "inline-block",
    backgroundColor: "#0053a1",
    borderRadius: "2px",
  },
  textField: {
    width: "100%",
    label: {
      transform: "translate(0, 1.5px) scale(0.95)",
      "transform-origin": "top left",
    },
  },
  button: {
    float: "right",
  },
  buttonContainer: {
    marginTop: "100px",
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  titulo: {
    marginTop: "20px",
  },
  formlabel: {
    marginTop: "20px",
  },
  labels: {
    width: "100%",
    margin: "20px 0px 20px 8px",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#424242",
  },

  selectedPastures: {
    display: "flex",
    flexWrap: "wrap",
    paddingTop: "10px",
  },

  estoqueFinal: {
    marginTop: "20px",
  },
  iconCor1: {
    color: coresModulos.cor1,
  },
  iconCor2: {
    color: coresModulos.cor2,
  },
  iconCor3: {
    color: coresModulos.cor3,
  },
  iconCor4: {
    color: coresModulos.cor4,
  },
  iconCor5: {
    color: coresModulos.cor5,
  },
  iconCor6: {
    color: coresModulos.cor6,
  },
  iconCor7: {
    color: coresModulos.cor7,
  },
  buttonDelete: {
    marginTop: "30px",
    backgroundColor: "white",
    color: "#fa3336",
    border: "1px solid #fa3336",
    borderRadius: "30px",
    marginRight: "20px",
    "&:hover": {
      backgroundColor: "white",
      color: "#aa1d1e",
      border: "1px solid #aa1d1e",
      borderRadius: "30px",
    },
  },
});
