import React, { useMemo } from "react";
import { withStyles } from "@material-ui/core";
import SelectDropdown from "components/SelectDropdown";

const SelectIndicators = ({
  classes,
  disabled,
  goalLine,
  freeIndicators,
  addNewLine,
  indicators = [],
  onChangeIndicator,
}) => {
  const sortedIndicators = freeIndicators.sort();

  const onChange = (value) => {
    if (goalLine.indicatorName) {
      if (value === goalLine.indicatorName) return;
      onChangeIndicator(value, goalLine.indicatorName);
    } else {
      addNewLine(value);
    }
  };

  const indicatorDescription = useMemo(() => {
    const selectIndicator = indicators.find(
      (indicator) => indicator.name === goalLine.indicatorName
    );
    if (selectIndicator) {
      return selectIndicator.description;
    }
  }, [indicators, goalLine.indicatorName]);

  const indicatorOptions = sortedIndicators.map((indicator) => ({
    value: indicator,
    label: indicator,
  }));

  return (
    <div className={classes.container}>
      <SelectDropdown
        isDisabled={disabled}
        testId={`${goalLine.indicatorName || ""}-Select-Indicator`}
        label={{
          text: goalLine.indicatorName ? "Indicador" : "Selecione o indicador",
        }}
        noOptionsText="Não existem opções disponíveis"
        tooltipMessage={indicatorDescription}
        options={indicatorOptions}
        value={goalLine.indicatorName || null}
        onChange={onChange}
      />
    </div>
  );
};
const styles = () => ({
  container: {
    padding: "8px 0",
  },
  indicator: {
    width: 290,
    textAlign: "left",
    fontSize: "0.75rem",
  },
  select: {
    color: "rgba(0, 0, 0, 0.38)",
    fontSize: "0.75rem",
  },
  tooltip: {
    fontSize: "0.875rem",
  },
});

export default withStyles(styles)(SelectIndicators);
