import _ from 'lodash';
import polylabel from 'polylabel';

class CalculadoraCoordenadas {
  calcularCentroAlto(coordenadas) {
    const coordenadasPorLatitude = _.sortBy(coordenadas, coordenada => {
      return coordenada.lat;
    });
    const mediaLatitude = (_.first(coordenadasPorLatitude).lat + _.last(coordenadasPorLatitude).lat * 3) / 4;
    const coordenadasPorLongitude = _.sortBy(coordenadas, coordenada => {
      return coordenada.lng;
    });
    const mediaLongitude = (_.first(coordenadasPorLongitude).lng + _.last(coordenadasPorLongitude).lng) / 2;

    return { lat: mediaLatitude, lng: mediaLongitude };
  }

  calcularCentro(coordenadas) {
    let poligono = new Array(coordenadas.length);
    for (let i = 0; i < poligono.length; i++) {
      poligono[i] = [coordenadas[i].lat, coordenadas[i].lng];
    }

    let centroPoligono = polylabel([poligono], 0.001);

    return {
      lat: centroPoligono[0],
      lng: centroPoligono[1]
    };
  }

  calcularTop(coordenadas) {
    coordenadas = _.orderBy(coordenadas, coordenada => coordenada.lat);

    return _.last(coordenadas);
  }
}

export default new CalculadoraCoordenadas();
