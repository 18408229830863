import React from "react";
import { TextLabel, FeedBackPage } from "../../../../shared/components";
import { Grid, withStyles } from "@material-ui/core";

const PlanningListEmpty = ({classes}) => {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      style={{
        minHeight: "60vh",
      }}
    >
      <FeedBackPage>
        <Grid container direction="column" alignItems="center">
          <img src="../images/busca.png" alt="Lupa de tela vazia" />
          <TextLabel
            id="labelFechamentoFeedBack"
            inputProps={{ "data-testid": "label-fechamento-feedBack" }}
            name="planejamentoSemItens"
            block
            color="default2"
            customStyle={{ paddingTop: 10, textAlign: "center" }}
          >
            Você ainda não possui planejamentos cadastradas.
            <br />
            Clique no botão{" "}
            <b className={classes.textAccent}>"NOVO PLANEJAMENTO"</b> para
            começar.
          </TextLabel>
        </Grid>
      </FeedBackPage>
    </Grid>
  );
};

const styles = (theme) => ({
  textAccent: {
    color: theme.palette.primary.main,
  },
})

export default withStyles(styles)(PlanningListEmpty);
