import {
  SELECIONAR_PASTO,
  SALVAR_FORRAGEM,
  ERRO_VALIDAR_FORRAGEM,
  OBTER_FORRAGEM_FAZENDA,
  OBTER_TODAS_FORRAGENS,
} from "redux/actions/actionsConstants";

export const initialState = {
  ForragemSelecionada: null,
  Forragem: null,
  CiclosForragemFazenda: [],
  Forragens: [],
  erros: {
    Forragem: {
      AlturaForragem: "",
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECIONAR_PASTO:
      if (action.payload.forragem) {
        return {
          ...state,
          ForragemSelecionada: action.payload.IdPasto,
          Forragem: action.payload.forragem,
        };
      } else {
        return {
          ...initialState,
          Forragens: state.Forragens,
        };
      }
    case SALVAR_FORRAGEM:
      return {
        ...state,
        ForragemSelecionada: action.payload.IdPasto,
        Forragem: action.payload,
      };
    case OBTER_FORRAGEM_FAZENDA:
      return {
        ...state,
        CiclosForragemFazenda: action.payload.CiclosForragemFazenda,
      };
    case OBTER_TODAS_FORRAGENS:
      return { ...state, Forragens: action.payload.Forragens };
    case ERRO_VALIDAR_FORRAGEM:
      return {
        ...state,
        erros: {
          ...state.erros,
          Forragem: { ...initialState.erros.Forragem, ...action.payload },
        },
      };
    default:
      return state;
  }
};
