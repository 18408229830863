import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import EditIcon from "@material-ui/icons/Create";
import { Collapse, Paper, withStyles } from "@material-ui/core";
import { MESES_ANO } from "../ganhoPesoUtils";
import GanhoPesoTableForm from "./ganhoPesoTableForm";

const RowTable = (props) => {
  const {
    classes,
    row,
    habilitarEdicao,
    habilitarExibicao,
    adicionando,
    exibindo,
  } = props;

  return (
    <>
      <TableRow>
        <TableCell className={`${row.Selecionada && classes.nomeCellExpanded}`}>
          <div className={classes.nomeContainer}>
            <span className={classes.nome}>{row.Nome}</span>
            {row.Selecionada && (
              <span className={classes.subtitulo}>
                Projeção do ganho de peso (kg/dia). Para calcular a média
                mensal, são utilizados como referência 30 dias.
              </span>
            )}
          </div>
        </TableCell>
        <TableCell className={classes.celulaEditar}>
          <IconButton
            id="botaoHabilitar"
            aria-label="Edit"
            onClick={() => habilitarEdicao(row)}
            disabled={adicionando || exibindo}
          >
            <EditIcon />
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className={classes.celulaExibir}>
          <Link
            component="button"
            className={classes.link}
            onClick={() => habilitarExibicao(row)}
            disabled={(exibindo && !row.Selecionada) || adicionando}
            style={{ color: row.Selecionada ? "#000000" : "#666666" }}
          >
            {row.Selecionada ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}

            <span className={classes.exibir}>EXIBIR</span>
          </Link>
        </TableCell>
      </TableRow>
      <TableRow style={{ height: 0, borderTop: "none", width: "100%" }}>
        <TableCell
          style={{
            padding: 0,
            width: "100%",
            borderBottom: !exibindo && "none",
          }}
          colSpan={3}
        >
          <Collapse
            in={row.Selecionada}
            classes={classes.wrapperCollapse}
            style={{ backgroundColor: "#fafafa" }}
            timeout="auto"
            unmountOnExit
          >
            <GanhoPesoTableForm months={MESES_ANO} curves={row} disabled />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export const ganhoPesoTabela = (props) => {
  const { ganhosDePesoFiltrados, classes } = props;

  return (
    <Paper className={classes.table}>
      <div className={classes.divTable}>
        <Table id="#tabelaGanhoPeso">
          <TableHead>
            <TableRow className={classes.ganhoPesoTableHeader}>
              <TableCell className={classes.celulaNome}>Nome</TableCell>
              <TableCell className={classes.celulaEditarHeader}>
                Editar
              </TableCell>
              <TableCell className={classes.celulaExibirHeader}>Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ganhosDePesoFiltrados.map((row, index) => (
              <RowTable key={index} row={row} {...props} />
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

const styles = (theme) => {
  return {
    table: {
      width: "100%",
      marginTop: "20px",
      overflow: "hidden",
      borderRadius: "16px",
    },
    link: {
      width: "100%",
      textAlign: "center",
      paddingRight: "24px",
    },
    celulaEditar: {
      textAlign: "right",
      fontFamily: "Roboto",
      fontSize: 14,
      textTransform: "uppercase",
    },
    celulaEditarHeader: {
      textAlign: "right",
      fontFamily: "Roboto",
      fontSize: 14,
      textTransform: "uppercase",
      top: 0,
      position: "sticky",
      zIndex: 10,
      color: theme.palette.primary.main,
      backgroundColor: "#e1e1e1",
    },
    celulaExibir: {
      width: "20%",
      paddingLeft: "70px",
      textAlign: "center",
      fontFamily: "Roboto",
      fontSize: 14,
      textTransform: "uppercase",
    },
    celulaExibirHeader: {
      width: "20%",
      paddingLeft: "70px",
      textAlign: "center",
      fontFamily: "Roboto",
      fontSize: 14,
      textTransform: "uppercase",
      top: 0,
      position: "sticky",
      zIndex: 10,
      color: theme.palette.primary.main,
      backgroundColor: "#e1e1e1",
    },
    celulaNome: {
      paddingLeft: "40px",
      fontFamily: "Roboto",
      fontSize: 14,
      textTransform: "uppercase",
      top: 0,
      position: "sticky",
      zIndex: 10,
      color: theme.palette.primary.main,
      backgroundColor: "#e1e1e1",
    },
    nomeCellExpanded: {
      padding: "20px 16px",
    },
    nomeContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },
    nome: {
      fontFamily: "Roboto",
      fontSize: 14,
      color: "#757575",
      textTransform: "uppercase",
    },
    ganhoPesoTableHeader: {
      height: 56,
      backgroundColor: "#e1e1e1",
    },
    wrapperCollapse: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    exibir: {
      marginTop: "6px",
      position: "absolute",
      fontFamily: "Roboto",
      fontSize: "14px",
      letterSpacing: "1px",
    },
    divTable: {
      overflow: "auto",
      maxHeight: 600,
    },
    subtitulo: {
      fontFamily: "Roboto",
      fontSize: 14,
      color: "#333",
      fontWeight: "bold",
    },
  };
};

export default withStyles(styles)(ganhoPesoTabela);
