import BaseService from "./BaseService";
import axios from "axios";
import _ from "lodash";

export const URL_BASE = `${process.env.REACT_APP_ENDPOINT}fazendas/api/v1/fazendas`;
const URI_PAISES = `${process.env.REACT_APP_ENDPOINT}fazendas/api/v1/countries`;

class FazendaService extends BaseService {
  async listarFazendas(listaIdFazenda) {
    const parametrosURL = this.montaFiltro({
      where: { IdFazenda: listaIdFazenda },
    });
    const urlServico = this.montaURL(URL_BASE, parametrosURL);
    return await this.get(urlServico);
  }

  async listarFazendasPorCliente(idCliente) {
    const parametrosURL = this.montaFiltro({
      where: { IdCliente: idCliente },
    });
    const urlServico = this.montaURL(URL_BASE, parametrosURL);
    return await this.get(urlServico);
  }

  async salvaDataConciliacao(dataConciliacao, idFazenda) {
    return await this.patch(
      `${process.env.REACT_APP_ENDPOINT}fazendas/api/v1/fazendas/${idFazenda}`,
      dataConciliacao
    );
  }

  async excluirFazenda(idFazenda) {
    return await this.delete(
      `${process.env.REACT_APP_ENDPOINT}fazendas/api/v1/fazendas/${idFazenda}`
    );
  }

  async retornaEndereco(endereco) {
    const urlGoogle = `https://maps.googleapis.com/maps/api/geocode/json?address=${endereco}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
    let data;

    await axios.get(urlGoogle).then((res) => {
      data = res.data;
    });

    return data;
  }

  async retornaCidadeComCoordenadas(lat, lng) {
    const urlGoogle = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
    let data;

    await axios.get(urlGoogle).then((res) => {
      data = res.data;
    });

    return data;
  }

  async listarFazendasCliente(idUsuario, idCliente) {
    const urlServicoUsuarioFazenda = `${process.env.REACT_APP_ENDPOINT}usuarios/api/v1/usuarios/${idUsuario}/fazendas`;
    let fazendasUsuario = await this.get(urlServicoUsuarioFazenda);
    fazendasUsuario = fazendasUsuario.data || [];
    fazendasUsuario = _.filter(fazendasUsuario, ["Excluido", false]);
    const idsFazendas = fazendasUsuario.map((fazenda) => fazenda.IdFazenda);

    const parametrosURL = this.montaFiltro({
      where: {
        IdCliente: idCliente,
        $and: [{ IdFazenda: { in: idsFazendas } }],
      },
    });
    const urlServicoFazenda = this.montaURL(URL_BASE, parametrosURL);
    return await this.get(urlServicoFazenda);
  }

  async listarTodasrFazendasCliente(idCliente) {
    const parametrosURL = this.montaFiltro({
      where: {
        IdCliente: idCliente,
      },
    });
    const urlServicoFazenda = this.montaURL(URL_BASE, parametrosURL);
    return await this.get(urlServicoFazenda);
  }

  async listarFazendasUsuario(idUsuario) {
    const urlServicoUsuarioFazenda = `${process.env.REACT_APP_ENDPOINT}usuarios/api/v1/usuarios/${idUsuario}/fazendas`;
    let fazendasUsuario = await this.get(urlServicoUsuarioFazenda);
    fazendasUsuario = fazendasUsuario.data || [];
    fazendasUsuario = _.filter(fazendasUsuario, ["Excluido", false]);
    const idsFazendas = fazendasUsuario.map((fazenda) => fazenda.IdFazenda);

    const parametrosURL = this.montaFiltro({
      where: [{ IdFazenda: { in: idsFazendas } }],
    });
    const urlServicoFazenda = this.montaURL(URL_BASE, parametrosURL);
    return await this.get(urlServicoFazenda);
  }

  async checaDataConciliação(IdFazenda) {
    return this.get(`${URL_BASE}/checa-data-conciliacao/${IdFazenda}`);
  }

  async obtemPaises() {
    return this.get(URI_PAISES);
  }

  async obtemEstados(pais) {
    return this.get(`${URI_PAISES}/${pais}/states`);
  }
}

export default new FazendaService();
