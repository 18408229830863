import React, { Component } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';

export class ItemLateralModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      urlAnimalExiste: true,
      urlBrincoExiste: true,
    };
  };

  isItemAtivo() {
    const { coleta, idColetaFotoAtivo, classes } = this.props;
    if (coleta.IdColetaFoto === idColetaFotoAtivo)
      return classes.mainAtivo;
    else
      return classes.main;
  }

  getImgStyleAnimal(url) {
    const { slideIndex, listaFotos, classes } = this.props;
    const { urlAnimalExiste } = this.state;
    const urlAtiva = listaFotos[slideIndex];

    if (url === urlAtiva)
      return urlAnimalExiste ? classes.imgAtiva : classes.semImagemAtiva;
    else
      return urlAnimalExiste ? classes.img : classes.semImagem;
  }

  getImgStyleBrinco(url) {
    const { slideIndex, listaFotos, classes } = this.props;
    const { urlBrincoExiste } = this.state;
    const urlAtiva = listaFotos[slideIndex];

    if (url === urlAtiva)
      return urlBrincoExiste ? classes.imgAtiva : classes.semImagemAtiva;
    else
      return urlBrincoExiste ? classes.img : classes.semImagem;
  }


  substituiFotoAnimal() {
    this.setState({ urlAnimalExiste: false })
  }

  substituiFotoBrinco() {
    this.setState({ urlBrincoExiste: false })
  }

  atualizaIndex(e, atualizaIndex, coleta) {
    if (e.target === e.currentTarget) {
      atualizaIndex(coleta.IdColetaFoto, coleta.ref, false)
    }
  }

  render() {
    const { coleta, classes, atualizaIndex } = this.props;
    const { urlAnimalExiste, urlBrincoExiste } = this.state;
    const deveExibirBrinco = urlAnimalExiste || urlBrincoExiste;
    const dataColeta = new Date(coleta.DataColeta).toLocaleDateString();
    const urlAnimal = `${process.env.REACT_APP_ENDPOINT_BUCKET}/MORTE/${coleta.IdColetaFoto}/imgCARCACA.png`;
    const urlBrinco = `${process.env.REACT_APP_ENDPOINT_BUCKET}/MORTE/${coleta.IdColetaFoto}/imgBRINCO.png`;
    const urlSemImagem = '../images/sem-imagem.svg';
    const identificador = coleta.Identificador ? coleta.Identificador : " - ";

    return (
      <Grid container id='main' className={this.isItemAtivo()} justify={'center'}>
        <Grid container id='gridMainImagens' className={classes.gridMainImagens} justify={'center'} onClick={(e) => this.atualizaIndex(e, atualizaIndex, coleta)}>
          <Grid container id='gridImagens' className={classes.gridImagens} justify={'space-around'} onClick={(e) => this.atualizaIndex(e, atualizaIndex, coleta)}>
            <Grid container className={classes.gridImg} justify={'center'} onClick={(e) => this.atualizaIndex(e, atualizaIndex, coleta)}>
              <img
                id='imgAnimal'
                src={urlAnimalExiste ? urlAnimal : urlSemImagem}
                onError={() => this.substituiFotoAnimal()}
                alt="img_animal"
                className={this.getImgStyleAnimal(urlAnimal)}
                onClick={urlAnimalExiste || !urlBrincoExiste ? () => atualizaIndex(coleta.IdColetaFoto, coleta.ref, false) : null}
              />
            </Grid>
            {deveExibirBrinco &&
              <Grid container className={classes.gridImg} justify={'center'}>
                <img
                  id='imgBrinco'
                  src={urlBrincoExiste ? urlBrinco : urlSemImagem}
                  onError={() => this.substituiFotoBrinco()}
                  alt="img_brinco"
                  className={this.getImgStyleBrinco(urlBrinco)}
                  onClick={urlBrincoExiste ? () => atualizaIndex(coleta.IdColetaFoto, coleta.ref, true) : null}
                />
              </Grid>}
          </Grid>
        </Grid>
        <Grid container justify={'center'} className={classes.gridInferior} onClick={() => atualizaIndex(coleta.IdColetaFoto, coleta.ref, false)}>
          <Typography id="categoria" variant={'h6'} className={classes.textoCategoria}>{coleta.Categoria.toUpperCase()}</Typography>
          <Grid id='gridDados' container xs={10} className={classes.gridDados}>
            <Grid id='gridTituloDados' item xs={4} className={classes.gridTituloDados}>
              <Typography id="tituloCausa" variant="caption" className={classes.textoDados}>{"Causa: "}</Typography>
              <Typography id="tituloCodigo" variant="caption" className={classes.textoDados}>{"Código: "}</Typography>
              <Typography id="tituloDataColeta" variant="caption" className={classes.textoDados}>{"Coleta: "}</Typography>
            </Grid>
            <Grid id='gridValoresDados' item xs={8} className={classes.gridValoresDados}>
              <Typography id="valorCausa" variant="caption" className={classes.textoDados}>{coleta.Causa}</Typography>
              <Typography id="valorCodigo" variant="caption" className={classes.textoDados}>{identificador}</Typography>
              <Typography id="valorDataColeta" variant="caption" className={classes.textoDados}>{dataColeta}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
};

const styles = (theme) => ({
  main: {
    width: '168px',
    height: '200px',
    opacity: 0.5,
    borderRadius: '2px',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: '#ffffff',
    marginBottom: '20px'
  },
  mainAtivo: {
    width: '168px',
    height: '200px',
    borderRadius: '2px',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.5)',
    border: `solid 1.8px ${theme.palette.primary.main}`,
    backgroundColor: '#ffffff',
    marginBottom: '20px'
  },
  gridMainImagens: {
    width: '168px',
    height: '106px',
    backgroundColor: '#fafafa',
    alignItems: 'center',
    cursor: 'pointer'
  },
  gridImagens: {
    display: 'row',
    width: '140px',
    height: '64px',
    borderRadius: '2px'
  },
  gridSemImagemAtiva: {
    width: '56px',
    height: '64px',
    borderRadius: '2px',
    border: `solid 2px ${theme.palette.primary.main}`,
    cursor: 'pointer'
  },
  gridSemImagem: {
    width: '56px',
    height: '64px',
    borderRadius: '2px',
    cursor: 'pointer'
  },
  gridImg: {
    width: '56px',
    height: '64px',
    alignItems: 'center'
  },
  imgAtiva: {
    width: '56px',
    height: '64px',
    borderRadius: '2px',
    border: `solid 2px ${theme.palette.primary.main}`,
    cursor: 'pointer'
  },
  img: {
    width: '56px',
    height: '64px',
    borderRadius: '2px',
    cursor: 'pointer'
  },
  semImagemAtiva: {
    width: '24px',
    height: '24px',
    alignSelf: 'center',
    borderRadius: '2px',
  },
  semImagem: {
    width: '24px',
    height: '24px',
    alignSelf: 'center',
    borderRadius: '2px',
  },
  gridInferior: {
    height: '94px',
    paddingTop: '10px',
    cursor: 'pointer'
  },
  textoCategoria: {
    width: '85%',
    fontSize: '12px',
    fontWeight: 500,
    color: '#424242'
  },
  textoDados: {
    fontSize: '10px',
    color: '#666'
  }
});
export default withStyles(styles)(ItemLateralModal);
