import React from "react";
import { withStyles } from "@material-ui/core";

const MovementSituationCards = ({
  classes,
  countOrigin,
  countDestiny,
  originCategoryName,
  originPastureName,
  destinyCategoryName,
  destinyPastureName,
  cardTitle,
}) => {
  return (
    <div
      className={classes.situationCardsContainer}
      data-testid="MovementSituationCards"
    >
      <div className={classes.titleCurrentSituation}>
        <h1
          data-testid="TestTitleCurrentSituation"
          className={classes.currentSituationText}
        >
          {cardTitle}
        </h1>
      </div>
      <div className={classes.currentSituationContainer}>
        <div className={classes.container}>
          <h2 className={classes.title} data-testid="TestTitleOriginPasture">
            PASTO ORIGEM
          </h2>
          <table className={classes.currentSituationTable}>
            <thead>
              <tr>
                <th className={classes.currentSituationTh}>
                  NOME PASTO ORIGEM
                </th>
                <th className={classes.currentSituationTh}>CATEGORIA</th>
                <th
                  className={classes.currentSituationTh}
                  data-testid="TestNumberOriginPastureTitle"
                >
                  Nº ANIMAIS NESSE PASTO
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={classes.currentSituationTb}>
                  {originPastureName}
                </td>
                <td className={classes.currentSituationTb}>
                  {originCategoryName}
                </td>
                <td
                  className={classes.currentSituationTb}
                  data-testid="TestNumberOriginPasture"
                >
                  {countOrigin !== null ? `${countOrigin} animais` : ""}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={classes.container}>
          <h2 className={classes.title} data-testid="TestTitleDestinyPasture">
            PASTO DESTINO
          </h2>
          <table className={classes.currentSituationTable}>
            <thead>
              <tr>
                <th className={classes.currentSituationTh}>
                  NOME PASTO DESTINO
                </th>
                <th className={classes.currentSituationTh}>CATEGORIA</th>
                <th
                  className={classes.currentSituationTh}
                  data-testid="TestNumberDestinyPastureTitle"
                >
                  Nº ANIMAIS NESSE PASTO
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={classes.currentSituationTb}>
                  {destinyPastureName}
                </td>
                <td className={classes.currentSituationTb}>
                  {destinyCategoryName}
                </td>
                <td
                  className={classes.currentSituationTb}
                  data-testid="TestNumberDestinyPasture"
                >
                  {countDestiny !== null ? `${countDestiny} animais` : ""}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const styles = (theme) => ({
  titleCurrentSituation: {
    display: "flex",
    alignItems: "center",
    marginTop: "8px",
  },
  currentSituationText: {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "bold",
    color: "#444",
  },
  currentSituationContainer: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "100%",
  },
  container: {
    width: "48%",
    minHeight: "136px",
    borderRadius: "8px",
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2)",
    border: "1px solid #e5e5ea",
    backgroundColor: "#fff",
  },
  title: {
    padding: "15px 0px 16px 32px",
    margin: "0px",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: "800",
    letterSpacing: "0.5px",
    color: "#307297",
  },
  currentSituationTable: {
    width: "100%",
    borderCollapse: "collapse",
    textAlign: "left",
    fontFamily: "Roboto",
  },
  currentSituationTh: {
    padding: "10px",
    backgroundColor: theme.palette.disabled.light,
    border: "1px solid #E5E5EA",
    borderLeft: "1px solid #E5E5EA",
    fontWeight: "400",
    fontSize: "12px",
    letterSpacing: "0.75px",
    color: "#8E8E93",
  },
  currentSituationTb: {
    padding: "10px",
    fontWeight: "400",
    fontSize: "24px",
    letterSpacing: "-0.05px",
    color: theme.palette.default1.main,
    borderLeft: "1px solid #E5E5EA",
  },
});
export default withStyles(styles)(MovementSituationCards);
