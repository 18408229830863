import * as faixaIdadeActions from './faixaIdadeActions';

export const INITIAL_STATE = {
  Faixas: [],
  Faixa: null
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case faixaIdadeActions.LISTA_FAIXAS:
      return {
        ...state, Faixas: action.payload.FaixasIdade
      };
    default:
      return state;
  }
};
