import React, { useEffect, useState } from "react";
import { Paper, Typography, withStyles } from "@material-ui/core";
import IngredientRegisterListContainer from "./ingredientRegisterList/ingredientRegisterListContainer";
import IngredientRegisterFilterContainer, {
  STATE_OPTIONS,
} from "./ingredientRegisterFilter/ingredientRegisterFilterContainer";
import IngredientRegisterFormContainer from "./ingredientRegisterForm/ingredientRegisterFormContainer";
import IngredientRegisterNotFound from "./shared/ingredientRegisterNotFound";
import { isLoggedUserInternal } from "../../shared/utils";
import formulaService from "features/formulacao/formulaService";

const CadastroIngredientesPage = (props) => {
  const {
    classes,
    ingredientes,
    filtrarOperacoesIngrediente,
    obterTodasMateriasPrimas,
    ingredientesPadroes,
    criarIngrediente,
    editarIngrediente,
    ativaIngrediente,
    desativaIngrediente,
    obterHistorico,
    historico,
    getAvailableProducts,
    availableProducts,
    listAllProducts,
    getAllProducts,
    clearProducts,
    farmId,
    notifyError
  } = props;

  const [ingredienteEditado, setIngredienteEditado] = useState(null);
  const [editando, setEditando] = useState(false);
  const [lancaCompra, setLancaCompra] = useState(false);
  const [isInternalUser] = useState(isLoggedUserInternal());
  const [text, setText] = useState("Não existe ingrediente cadastrado!");
  const [formulasPorIngrediente, setFormulasPorIngrediente] = useState([])

  const getFormulasPorIdIngrediente = async (idIngrediente) => {
    try {
      const formulasPorIdIngrediente = await formulaService.buscarFormulacaoPorIdIngrediente(
        idIngrediente
      )
      setFormulasPorIngrediente(formulasPorIdIngrediente);
    } catch (error) {
      notifyError({
        mensagem: error.message,
      });
    }
  }
  const [selectedState, setSelectedState] = useState(STATE_OPTIONS[1].value);

  const filteredIngredients = () => {
    setText(
      <div className={classes.textNoResults}>
        Não encontramos nenhum resultado para a busca informada. Por favor tente
        novamente.
      </div>
    );
  };

  useEffect(() => {
    filtrarOperacoesIngrediente({ Ativo: true });
    obterTodasMateriasPrimas();
  }, []);

  const handleDeactivate = async (IdIngrediente, nomeUsuario) => {
    desativaIngrediente(IdIngrediente, nomeUsuario, selectedState);
  };

  const handleActivate = async (IdIngrediente, nomeUsuario) => {
    ativaIngrediente(IdIngrediente, nomeUsuario, selectedState);
  };

  const existeIngrediente =
    ingredientes && (ingredientes.items || []).length > 0;

  return (
    <Paper className={classes.ingredientesContainer}>
      <Typography className={classes.titulo} gutterBottom>
        Ingredientes
      </Typography>
      <IngredientRegisterFilterContainer
        ingredientesPadroes={ingredientesPadroes}
        filtrarOperacoesIngrediente={filtrarOperacoesIngrediente}
        isInternalUser={isInternalUser}
        listAllProducts={listAllProducts}
        getAllProducts={getAllProducts}
        clearProducts={clearProducts}
        filteredIngredients={filteredIngredients}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
      />
      <IngredientRegisterFormContainer
        salvarNovoIngrediente={criarIngrediente}
        editarIngrediente={editarIngrediente}
        ingredienteEditado={ingredienteEditado}
        ingredientesPadrao={ingredientesPadroes}
        listaDeIngredientes={ingredientes}
        editando={editando}
        setEditando={setEditando}
        setIngredienteEditado={setIngredienteEditado}
        setLancaCompra={setLancaCompra}
        lancaCompra={lancaCompra}
        isInternalUser={isInternalUser}
        getAvailableProducts={getAvailableProducts}
        availableProducts={availableProducts}
        farmId={farmId}
      />
      {existeIngrediente ? (
        <IngredientRegisterListContainer
          ingredientes={ingredientes.items}
          setEditando={setEditando}
          setIngredienteEditado={setIngredienteEditado}
          ativaIngrediente={handleActivate}
          desativaIngrediente={handleDeactivate}
          obterHistorico={obterHistorico}
          historico={historico}
          setLancaCompra={setLancaCompra}
          isInternalUser={isInternalUser}
          getFormulasPorIdIngrediente={getFormulasPorIdIngrediente}
          formulasPorIngrediente={formulasPorIngrediente}
        />
      ) : (
        <IngredientRegisterNotFound text={text} />
      )}
    </Paper>
  );
};

const styles = () => {
  return {
    ingredientesContainer: {
      width: "100%",
      height: "100%",
      overflowX: "auto",
      padding: "40px",
      zIndex: -1,
      bottom: 0,
      top: "17%",
      minHeight: "calc(100vh - 90px)",
    },
    titulo: {
      fontSize: "20px",
      fontWeight: "bold",
      fontStyle: "medium",
      fontStretch: "medium",
      lineHeight: "medium",
      letterSpacing: "medium",
      color: "#333333",
      marginTop: "40px",
    },
    textNoResults: {
      width: "323px",
      height: "44px",
      margin: "33.8px 0 0",
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.38,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#999",
    },
  };
};

export default withStyles(styles)(CadastroIngredientesPage);
