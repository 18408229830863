import BaseService from "../../services/BaseService";

const URL_BASE = `${process.env.REACT_APP_ENDPOINT}herds/causeDeath`;

class CausaMorteService extends BaseService {
  causaMorteToCauseDeath(causaMorte) {
    if (!causaMorte) return null;
    return {
      id: causaMorte.IdCausaMorte,
      name: causaMorte.Nome,
      farmId: causaMorte.IdFazenda,
      creationUser: causaMorte.UsuarioCriacao,
      updateUser: causaMorte.UsuarioAlteracao,
      deletionUser: causaMorte.UsuarioExclusao,
      createdAt: causaMorte.DataCriacao,
      updatedAt: causaMorte.DataAlteracao,
      deletedAt: causaMorte.DataExclusao,
    };
  }
  causeDeathToCausaMorte(causeOfDeath) {
    if (!causeOfDeath) return null;
    return {
      IdCausaMorte: causeOfDeath.id,
      Nome: causeOfDeath.name,
      IdFazenda: causeOfDeath.farmId,
      UsuarioCriacao: causeOfDeath.creationUser,
      UsuarioAlteracao: causeOfDeath.updateUser,
      UsuarioExclusao: causeOfDeath.deletionUser,
      DataCriacao: causeOfDeath.createdAt,
      DataAlteracao: causeOfDeath.updatedAt,
      DataExclusao: causeOfDeath.deletedAt,
      Excluido: causeOfDeath.deletedAt ? true : false,
    };
  }

  async listaCausasMortePorFazenda(idFazenda, deleted = true) {
    let query = {
      farmId: idFazenda,
      withDeleted: !!deleted,
    };
    const parametrosURL = this.montaURLParametros(query);
    const urlServico = this.montaURL(URL_BASE, parametrosURL);
    const result = await this.get(urlServico);
    if (!result || !result.data || !result.data.data) {
      return {
        data: [],
      };
    }
    const causaMorteResponse = result.data.data.map(
      this.causeDeathToCausaMorte
    );
    return { ...result, data: causaMorteResponse };
  }

  async adicionarCausaMorte(causaMorte) {
    const causeOfDeath = this.causaMorteToCauseDeath(causaMorte);
    const res = await this.post(URL_BASE, causeOfDeath);
    if (!res || !res.data || !res.data.data) {
      return {};
    }
    const causaMorteResponse = this.causeDeathToCausaMorte(res.data.data);
    return { ...res, data: causaMorteResponse };
  }

  async deletarCausaMorte(idCausaMorte) {
    return await this.delete(`${URL_BASE}/${idCausaMorte}/disable`);
  }

  async reativarCausaMorte(idCausaMorte, causaMorte) {
    const causeOfDeath = this.causaMorteToCauseDeath(causaMorte);
    const response = await this.patch(
      `${URL_BASE}/${idCausaMorte}/enable`,
      causeOfDeath
    );
    if (!response || !response.data || !response.data.data) {
      return {};
    }
    const causaMorteResponse = this.causeDeathToCausaMorte(response.data.data);
    return { ...response, data: causaMorteResponse };
  }

  async editarCausaMorte(causaMorte, idCausaMorte) {
    const causeOfDeath = this.causaMorteToCauseDeath(causaMorte);
    const response = await this.patch(
      `${URL_BASE}/${idCausaMorte}`,
      causeOfDeath
    );

    if (!response || !response.data || !response.data.data) {
      return {};
    }

    const causaMorteResponse = this.causeDeathToCausaMorte(response.data.data);
    return { ...response, data: causaMorteResponse };
  }

  async cadastrarCausasMortePadrao(idFazenda) {
    const urlServico = `${URL_BASE}/causas-padrao/${idFazenda}`;
    return await this.post(urlServico);
  }
}

export default new CausaMorteService();
