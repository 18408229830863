import { NOTIFICAR, FECHAR_NOTIFICACAO } from './actionsConstants';

export const notifySuccess = notficacao => {
  return { type: NOTIFICAR, payload: { ...notficacao, estilo: 'success' } };
};

export const notifyError = notficacao => {
  return { type: NOTIFICAR, payload: { ...notficacao, estilo: 'error' } };
};

export const notifyInfo = notficacao => {
  return { type: NOTIFICAR, payload: { ...notficacao, estilo: 'info' } };
};

export const notifyWarning = notficacao => {
  return { type: NOTIFICAR, payload: { ...notficacao, estilo: 'warning' } };
};

export const fecharNotificacao = () => {
  return { type: FECHAR_NOTIFICACAO, payload: {} };
};
