import React, { useEffect } from "react";
import {
  Button,
  Divider,
  InputLabel,
  Modal,
  Paper,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";

import { DragHandle, LocalPrintshopOutlined } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import ReactDragListView from "react-drag-listview";
import InfoIcon from "@material-ui/icons/Info";

const titulos = [
  "ORDERNAR",
  "INGREDIENTE",
  "QUANTIDADE (g/Cab/dia)",
  "CUSTO/CAB/DIA",
];

export const ModalDetalhesFormulacao = (props) => {
  const {
    formula,
    open,
    classes,
    onBackdropClick,
    onClickAway,
    dragProps,
    ref,
    capacidadeMisturador,
    changeCapacidade,
    imprimir,
    quantidadeTotal,
    custoCabTotal,
    alterarStatusModal,
    formatarCampos,
    imprimirFormula,
    listaIngredientes,
  } = props;

  const custoPorKgEnsacado = (formula) => {
    const custoEnsacado = formula.CustoSaco / formula.PesoSaco;

    if (Number.isNaN(custoEnsacado)) {
      return "-";
    }

    return custoEnsacado.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  };

  const custoPorKgGranel = () => {
    const custoGranel = (1000 * custoCabTotal) / quantidadeTotal;
    return custoGranel.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  };

  useEffect(() => {
    if (capacidadeMisturador) {
      changeCapacidade("");
    }
  }, [open]);

  const renderListaIngredientesECustos = () => (
    <>
      <div className={classes.tableDetalherWrapper}>
        <Typography className={classes.titleTable}>INGREDIENTES</Typography>
        <Typography className={classes.resumoTable}>
          É possivel ordenar os ingredientes para sairem na ordem da batida no
          misturador.
        </Typography>
        <Paper className={classes.table}>
          <div className={classes.headerTable}>
            {titulos.map((titulos, key) => {
              return (
                <div key={key + titulos} className={classes.tituloHeader}>
                  {titulos}
                  {key === 3 && (
                    <Tooltip title={"Custo por Kg * Quantidade / 1000"} arrow>
                      <InfoIcon
                        fontSize="inherit"
                        color="primary"
                        style={{
                          fontSize: "18px",
                          position: "relative",
                          left: "4%",
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
              );
            })}
          </div>
          <div className={classes.contentTable}>
            <ReactDragListView {...dragProps}>
              <lo>
                {listaIngredientes.map((ingrediente, index) => (
                  <>
                    <IngredienteTile
                      ingrediente={ingrediente}
                      ref={ref}
                      key={index}
                      classes={classes}
                    />
                  </>
                ))}
              </lo>
            </ReactDragListView>
          </div>
          <div className={classes.footerTable}>
            <div className={classes.totalRow}>
              <div className={classes.totalRowCell} />
              <div className={classes.totalRowCell}>TOTAL</div>
              <div className={classes.totalRowCell}>{quantidadeTotal}</div>
              <div className={classes.totalRowCell}>
                {custoCabTotal.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </div>
            </div>
          </div>
        </Paper>
      </div>
      <Divider className={classes.divider} />
      <Typography className={classes.resumoTable}>CUSTOS</Typography>
      <div className={classes.printSpace}>
        <div className={classes.capacidadeInputWrapper}>
          <div className={classes.costsInput}>
            <InputLabel id="epocaFormulacao" className={classes.inputLabel}>
              Custo por kg
            </InputLabel>
            <InputLabel className={classes.fieldFormula}>
              {formula
                ? !formula.Granel
                  ? custoPorKgEnsacado(formula)
                  : custoPorKgGranel()
                : "-"}
            </InputLabel>
          </div>
          {formula
            ? !formula.Granel && (
                <div className={classes.costsInput}>
                  <InputLabel
                    id="epocaFormulacao"
                    className={classes.inputLabel}
                  >
                    Custo por saco
                  </InputLabel>
                  <InputLabel className={classes.fieldFormula}>
                    {formula
                      ? formula.CustoSaco.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })
                      : "-"}
                  </InputLabel>
                </div>
              )
            : null}
        </div>
      </div>
      <Divider className={classes.divider} />

      <Typography className={classes.resumoTable}>MISTURADOR</Typography>
      <div className={classes.printSpace}>
        <div className={classes.capacidadeInputWrapper}>
          <TextField
            label="Capacidade do misturador (Kg)"
            InputLabelProps={{ shrink: true }}
            type="number"
            placeholder="Digite a capacidade"
            style={{ width: "100%" }}
            value={capacidadeMisturador}
            onChange={({ target }) => changeCapacidade(target.value)}
          />
        </div>

        <Button
          id="botao-imprimir"
          className={classes.botaoImprimir}
          variant="contained"
          color="primary"
          disabled={!imprimir}
          onClick={imprimirFormula}
        >
          <LocalPrintshopOutlined height={20} style={{ marginRight: "12px" }} />
          Imprimir
        </Button>
        {!imprimir && (
          <div className={classes.tooltipFake}>
            Por favor informe a capacidade do misturador
          </div>
        )}
      </div>
    </>
  );

  const renderInformeListaIngredientesVazia = () => (
    <div>
      <img
        src="/images/busca.png"
        alt="empty list"
        className={classes.imgInformeListaVazia}
      />
      <p className={classes.txtInformeListaVazia}>
        Não há ingredientes cadastrados para a formulação. Por favor, cadastre
        um ingrediente para ver mais detalhes.
      </p>
    </div>
  );

  return (
    <Modal
      id="DialogFormulacao"
      open={open}
      onBackdropClick={onBackdropClick}
      keyboard={false}
      onClickAway={onClickAway}
      className={classes.modalWrapper}
    >
      <div className={classes.dialogWrapper}>
        <div className={classes.titleRow}>
          <Typography className={classes.tituloModal}>
            Detalhe da formulação
          </Typography>
          <CloseIcon
            onClick={alterarStatusModal}
            className={classes.closeIcon}
          />
        </div>
        <div className={classes.formFormulacao}>
          <div className={classes.formulaInput}>
            <InputLabel
              id="id-nome-formula-input"
              className={classes.inputLabel}
            >
              Nome da fórmula
            </InputLabel>
            <InputLabel className={classes.fieldFormula}>
              {formula ? formula.Nome : "-"}
            </InputLabel>
          </div>
          <div className={classes.formulaInput}>
            <InputLabel id="embalagemFormulacao" className={classes.inputLabel}>
              Embalagem
            </InputLabel>
            <InputLabel className={classes.fieldFormula}>
              {formula ? (formula.Granel ? "GRANEL" : "ENSACADO") : "-"}
            </InputLabel>
          </div>
          {formula
            ? !formula.Granel && (
                <div className={classes.formulaInput}>
                  <InputLabel id="pesoSaco" className={classes.inputLabel}>
                    Peso do saco(kg)
                  </InputLabel>
                  <InputLabel className={classes.fieldFormula}>
                    {formula && formula.PesoSaco}
                  </InputLabel>
                </div>
              )
            : null}
          <div className={classes.formulaInput}>
            <InputLabel id="epocaFormulacao" className={classes.inputLabel}>
              Rebanho Alvo
            </InputLabel>
            <InputLabel className={classes.fieldFormula}>
              {formatarCampos(formula ? formula.Categoria : null)}
            </InputLabel>
          </div>
          <div className={classes.formulaInput}>
            <InputLabel id="epocaFormulacao" className={classes.inputLabel}>
              Época
            </InputLabel>
            <InputLabel className={classes.fieldFormula}>
              {formatarCampos(formula ? formula.Epocas : null)}
            </InputLabel>
          </div>
        </div>
        {listaIngredientes.length > 0 && renderListaIngredientesECustos()}
        {!listaIngredientes.length > 0 && renderInformeListaIngredientesVazia()}
      </div>
    </Modal>
  );
};

const IngredienteTile = ({ classes, ingrediente, ref }) => {
  return (
    <li className={classes.ingredienteRow}>
      <div className={classes.ingredienteRowCell}>
        <a href={ref}>
          <DragHandle className={classes.iconGrab} />
        </a>
      </div>
      <div className={classes.ingredienteRowCell}>
        {ingrediente.OperacaoIngrediente.Descricao}
      </div>
      <div className={classes.ingredienteRowCell}>
        {ingrediente.FormulaIngrediente.Quantidade}
      </div>
      <div className={classes.ingredienteRowCell}>
        {ingrediente.CustoCabDia.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })}
      </div>
    </li>
  );
};

ModalDetalhesFormulacao.propTypes = {};

const styles = (theme) => {
  return {
    modalWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
    },
    titleRow: {
      display: "flex",
      justifyContent: "space-between",
    },
    closeIcon: {
      cursor: "pointer",
    },
    iconGrab: {
      cursor: "grab",
      "&:active": {
        cursor: "grabbing",
      },
    },
    ingredienteRow: {
      height: "44px",
      width: "100%",
      borderBottom: "solid 1px #e0e0e0",
      display: "flex",
      alignItems: "center",
      padding: "0 24px",
      justifyContent: "space-between",
    },
    ingredienteRowCell: {
      width: "100%",
      color: "#757575",
      fontSize: 14,
      textTransform: "uppercase",
      fontFamily: "Roboto",
      marginLeft: 4,
    },
    dialogWrapper: {
      backgroundColor: "white",
      width: "70%",
      borderRadius: 18,
      padding: 24,
      display: "flex",
      flexDirection: "column",
      maxHeight: "85vh",
      minHeight: "85vh",
      overflow: "auto!important",
    },
    tituloModal: {
      fontSize: 20,
      fontWeight: 500,
      letterSpacing: 0.2,
      color: "#333",
      marginTop: 10,
    },
    formFormulacao: {
      display: "flex",
      width: "100%",
      marginTop: 16,
      borderBottom: "solid 1px #e0e0e0",
    },
    formulaInput: {
      display: "flex",
      flexDirection: "column",
      marginRight: 36,
      marginTop: 5,
      marginBottom: 21,
    },
    costsInput: {
      display: "flex",
      flexDirection: "column",
      marginRight: 36,
      marginTop: 5,
    },
    inputLabel: {
      fontSize: 12,
    },
    fieldFormula: {
      fontSize: 16,
      color: "#333",
      marginTop: 8,
      textTransform: "uppercase",
    },
    tableDetalherWrapper: {
      padding: "12px 0 0 0",
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    titleTable: {
      fontSize: 12,
      letterSpacing: 0.5,
      color: "#333",
    },
    resumoTable: {
      fontSize: 12,
      letterSpacing: 0.5,
      color: "#333",
      marginRight: 24,
    },
    table: {
      height: "100%",
      marginTop: 18,
      position: "relative",
      display: "flex",
      flexDirection: "column",
    },
    headerTable: {
      display: "flex",
      width: "100%",
      backgroundColor: "#e1e1e1",
      borderRadius: "16px 16px 0 0",
      padding: "0 24px",
    },
    tituloHeader: {
      height: 44,
      color: theme.palette.primary.main,
      fontSize: 14,
      display: "flex",
      alignItems: "center",
      width: "100%",
      fontWeight: "bold",
      fontFamily: "Roboto",
    },
    footerTable: {
      backgroundColor: "#f5f5f5",
      borderRadius: "0 0 16px 16px",
      width: "100%",
    },
    totalRow: {
      display: "flex",
      alignItems: "center",
      height: 44,
      width: "100%",
      padding: "0 24px",
      justifyContent: "space-between",
      borderTop: "solid 1px #e0e0e0",
    },
    totalRowCell: {
      height: 56,
      color: theme.palette.primary.main,
      fontSize: 14,
      display: "flex",
      alignItems: "center",
      width: "100%",
      fontWeight: "bold",
      fontFamily: "Roboto",
    },
    printSpace: {
      height: 80,
      display: "flex",
      alignItems: "center",
    },
    capacidadeInputWrapper: {
      maxWidth: 280,
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "100%",
      marginRight: 26,
    },
    botaoImprimir: {
      borderRadius: 18,
      width: 173,
      height: 38,
      boxShadow: "none!important",
      fontWeight: 500,
      marginTop: 9,
    },
    contentTable: {
      width: "100%",
      height: "100%",
      overflowY: "auto",
      maxHeight: "180px",
    },
    divider: {
      margin: "20px 0px",
    },
    imgInformeListaVazia: {
      display: "flex",
      margin: "66px auto 27px",
      width: "200px",
    },
    txtInformeListaVazia: {
      fontSize: "14px",
      color: "#999999",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      display: "flex",
      width: "310px",
      margin: "0 auto",
      textAlign: "center",
    },
    tooltipFake: {
      background: "rgba(0,0,0,0.6)",
      display: "block",
      width: "133px",
      marginLeft: "14px",
      fontSize: "10px",
      color: "white",
      padding: "5px 8px",
      marginTop: "10px",
      borderRadius: "17px",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  };
};

export default withStyles(styles)(ModalDetalhesFormulacao);
