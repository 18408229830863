import React from "react";
import moment from "moment";
import {
  Button,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import ClearIcon from "@material-ui/icons/Clear";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import SelectDropdown from "components/SelectDropdown";

const IngredientRegisterFilterPage = ({
  classes,
  nomeIngredienteFiltro,
  setNomeIngredienteFiltro,
  controleDataFiltro,
  setControleDataFiltro,
  ingredientesPadroesFormatados,
  selecaoIngredientes,
  handleChangeIngredientes,
  filtrarIngredientes,
  isInternalUser,
  productFilter,
  statesList,
  selectedState,
  handleStateChange,
}) => {
  const regexData = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];

  const {
    list: mappedProductsList,
    selected: selectedProductIds,
    onChange: onProductChange,
    onChangeSearchText: onProductChangeSearchText,
    onRequestData: onProductRequestData,
  } = productFilter;

  return (
    <div className={classes.containerFiltro}>
      <Typography className={classes.tituloFiltro}>FILTROS</Typography>
      <div className={classes.listaCampoFiltro}>
        {isInternalUser && (
          <div id="productIdFilter" className={classes.formControlFiltro}>
            <SelectDropdown
              isMulti
              isSearchable
              isClearable
              testId="codigo-ingrediente-filtro"
              label={{ text: "CÓDIGO DO INGREDIENTE (PA)" }}
              options={mappedProductsList}
              value={selectedProductIds}
              onChange={onProductChange}
              onPaginate={onProductRequestData}
              onPagineThresholdInPorcentage={0.6}
              onSearch={onProductChangeSearchText}
            />
          </div>
        )}
        <div className={classes.formControlFiltro}>
          <SelectDropdown
            testId="state-filtro"
            id="state-select"
            label={{ text: "Situação do Ingrediente" }}
            options={statesList}
            value={selectedState}
            onChange={handleStateChange}
          />
        </div>
        <div className={classes.formControlFiltro}>
          <SelectDropdown
            isMulti
            isSearchable
            isClearable
            id="ingrediente-multiple-checkbox-label"
            testId="materia-prima-filtro"
            label={{ text: "MATÉRIA PRIMA" }}
            options={ingredientesPadroesFormatados}
            value={selecaoIngredientes}
            onChange={handleChangeIngredientes}
          />
        </div>
        <FormControl
          id="nomeIngredienteFiltro"
          className={classes.formControlFiltro}
        >
          <InputLabel shrink className={classes.labelNomeIngrediente}>
            NOME DO INGREDIENTE
            <Tooltip
              title={
                "É o nome escolhido pela fazenda para identificação do ingrediente."
              }
              placement="top"
            >
              <InfoIcon className={classes.iconeInfoFiltro} placement="right" />
            </Tooltip>
          </InputLabel>
          <Input
            id="nomeIngredienteFiltro"
            required={true}
            placeholder={"DIGITE UM NOME"}
            value={nomeIngredienteFiltro}
            style={{ padding: "2px 0" }}
            inputProps={{
              "data-testid": "nome-ingrediente",
              style: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "14px",
              },
            }}
            onChange={({ target: { value } }) =>
              setNomeIngredienteFiltro(value)
            }
          />
        </FormControl>
        <div className={classes.campoDataFiltro}>
          <MuiPickersUtilsProvider moment={moment} utils={MomentUtils}>
            <InputLabel
              shrink
              id="ingrediente-multiple-checkbox-label"
              style={{ fontSize: 13, fontFamily: "Roboto" }}
            >
              DATA DA COMPRA
            </InputLabel>
            <DatePicker
              disableFuture
              keyboard
              mask={(value) => (value ? regexData : [])}
              disableOpenOnEnter
              id="data-filtro"
              onChange={(newData) => setControleDataFiltro(newData)}
              invalidDateMessage="O Formato da data é inválido"
              minDateMessage="A data selecionada é inválida"
              maxDateMessage="A data selecionada é inválida"
              format="DD/MM/YYYY"
              placeholder="dd/mm/aaaa"
              style={{
                width: "100%",
                padding: "2px 0",
              }}
              value={controleDataFiltro}
            />
          </MuiPickersUtilsProvider>
        </div>
        {controleDataFiltro && (
          <IconButton
            className={classes.botaoLimparFiltro}
            edge="end"
            size="small"
            data-testid="botao-limpar-data"
            onClick={() => setControleDataFiltro(null)}
          >
            <ClearIcon />
          </IconButton>
        )}
        <Button
          id="botao-filtro"
          variant="contained"
          color="primary"
          data-testid="botao-filtrar"
          className={classes.botaoFiltrar}
          onClick={filtrarIngredientes}
        >
          FILTRAR
        </Button>
      </div>
    </div>
  );
};

const styles = (theme) => {
  return {
    containerFiltro: {
      textTransform: "uppercase",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      marginTop: 20,
    },
    tituloFiltro: {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: "0.5px",
      color: "#666666",
      marginBottom: 16,
    },
    listaCampoFiltro: {
      width: "100%",

      display: "flex",
      flexWrap: "wrap",
      columnGap: "32px",
      rowGap: "16px",
    },
    formControlFiltro: {
      fontSize: 14,
      minWidth: 300,
    },
    campoDataFiltro: {
      minWidth: 150,
      maxWidth: 187,
    },
    iconeInfoFiltro: {
      fontSize: 16,
      position: "relative",
      left: 8,
      color: theme.palette.primary.main,
    },
    containerCheckboxIngrediente: {
      maxWidth: "250px",
      width: "200px",
      fontSize: 14,
      marginRight: 33,
      zIndex: 2,
    },
    botaoLimparFiltro: {
      maxHeight: 40,
      maxWidth: 40,
      marginTop: 15,
      marginLeft: 4,
    },
    botaoFiltrar: {
      height: 36,
      borderRadius: 30,
      width: 128,
      fontFamily: "Roboto",
      fontSize: 14,
      letterSpacing: "1.25px",
      boxShadow: "none!important",
      marginTop: 10,
    },
    selectCodigoInput: {
      maxWidth: 232,
      width: "100%",
      marginRight: 33,
    },
    multiSelect: {
      fontFamily: "Roboto",
      flexDirection: "row",
      "--rmsc-gray": "#33333380 !important",
      "--rmsc-ta": "left",
      "--rmsc-border": "none !important",
      "-rmsc-main": "none !important",
      borderBottom: "1px solid #33333380",
      "& .dropdown-container": {
        color: "#333333",
        border: "none !important",
        boxShadow: "none !important",
      },
      "& .dropdown-heading": {
        textAlign: "left",
        color: "#666666",
        padding: "6px 0 0 0",
        minHeight: 42,
      },
      "& .no-options": {
        textAlign: "left",
        color: "#666666",
        textTransform: "none",
      },
      labelNomeIngrediente: {
        marginBottom: "8px",
      },
    },
  };
};

export default withStyles(styles)(IngredientRegisterFilterPage);
