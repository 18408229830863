import React from 'react';
import { Button, Dialog, DialogTitle } from '@material-ui/core/';
import DialogActions from '@material-ui/core/DialogActions';

export const modalLogout = props => {
  return (
    <div>
      <Dialog
        open={props.modalAberto}
        onClose={props.handleClose}
        fullWidth={false}
        style={{ maxWidth: '450px', marginLeft: '30%', marginRight: '30%' }}
      >
        <DialogTitle style={{ fontWeight: 'bold' }} id="tituloModalLogout">
          Deseja realmente sair ?
        </DialogTitle>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary" id="btnCancelarLogout">
            Cancelar
          </Button>
          <Button onClick={props.handleOpen} color="primary" autoFocus id="btnSairLogout">
            Sair
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default modalLogout;
