import {
  notifyError,
  notifySuccess,
} from "../../../redux/actions/notificacaoActions";
import LoteManager from "../loteManager";
import {
  listarLotesPorPasto,
  exibirCarregando,
  listarProjecaoPesoSemestre,
  listarCurvaGanhoPeso,
} from "./loteAction";

export const listaLotesPasto = (idPasto) => async (dispatch) => {
  try {
    const lotesPasto = await LoteManager.obterLotesPasto(idPasto);
    dispatch(listarLotesPorPasto(lotesPasto));
  } catch (error) {
    dispatch(listarLotesPorPasto([]));
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao obter a lotes desse pasto",
      })
    );
  }
};

export const atualizarLotes = (idsLotes, lotes) => async (dispatch) => {
  dispatch(exibirCarregando(true));
  try {
    await LoteManager.atualizarLotes(idsLotes, lotes);
    dispatch(listarLotesPorPasto(lotes));
    dispatch(
      notifySuccess({
        mensagem: "Lotes salvos com sucesso.",
      })
    );
  } catch (error) {
    dispatch(listarLotesPorPasto([]));
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao salvar os objetivos dos lotes",
      })
    );
  } finally {
    dispatch(exibirCarregando(false));
  }
};

export const obterLotesReduzidosPorPastos =
  (pastos, fazendaSelecionada) => async (dispatch, getState) => {
    dispatch(exibirCarregando(true));
    try {
      const { categoria } = getState();
      const lotes = await LoteManager.obterLotesReduzidosPorPastos(
        pastos,
        categoria.categorias || [],
        fazendaSelecionada
      );
      dispatch(listarLotesPorPasto(lotes));
    } catch (error) {
      dispatch(listarLotesPorPasto([]));
      dispatch(
        notifyError({
          mensagem: "Ocorreu um erro ao requisitar os lotes",
        })
      );
    } finally {
      dispatch(exibirCarregando(false));
    }
  };

export const obterProjecaoSemestrePorLote = (lote) => async (dispatch) => {
  dispatch(exibirCarregando(true));
  try {
    const projecaoPesoSemestre = await LoteManager.obterProjecaoSemestrePorLote(
      lote
    );
    dispatch(listarProjecaoPesoSemestre(projecaoPesoSemestre));
  } catch (error) {
    dispatch(
      notifyError({
        mensagem:
          "Ocorreu um erro ao requisitar a projeção de ganho de peso do lote",
      })
    );
  } finally {
    dispatch(exibirCarregando(false));
  }
};

export const obterCurvaGanhoPeso = (idFazenda) => async (dispatch) => {
  try {
    const curvaGanhoPeso = await LoteManager.obterCurvaGanhoPesoPorFiltro(
      idFazenda
    );
    dispatch(listarCurvaGanhoPeso(curvaGanhoPeso));
  } catch (error) {
    dispatch(listarCurvaGanhoPeso([]));
    dispatch(
      notifyError({
        mensagem:
          "Ocorreu um erro ao requisitar a curva de ganho de peso da categoria",
      })
    );
  }
};
