import React, { useState } from "react";
import { MapTypeButton } from "../mapTypeButton";
import { Title } from "../title";
import { connect } from "react-redux";
import * as actions from "redux/actions";
import SubMenuOfButtonMapType from "../subMenuOfButtonMapType";

const CustomMapButtons = ({ setTipoMapa, mapTypeId }) => {
  const [focusedButton, setFocusedButton] = useState("");

  const handleFocus = (buttonLabel) => {
    setFocusedButton(buttonLabel);
  };

  const handleClick = (mapType) => {
    if (mapType !== mapTypeId) {
      setTipoMapa(mapType);
    }

    handleFocus(mapType);
  };

  return (
    <div style={styles.container}>
      <Title>Visualização</Title>
      <div style={styles.body}>
        <div
          style={styles.buttonWrapper}
          onFocus={() => setFocusedButton("roadmap")}
          onMouseLeave={() => setFocusedButton("")}
        >
          <MapTypeButton
            text={"Mapa"}
            onClick={() => handleClick("roadmap")}
            active={mapTypeId === "roadmap" || mapTypeId === "terrain"}
          />
          {focusedButton === "roadmap" && (
            <SubMenuOfButtonMapType value="terrain" title="Relevos" />
          )}
        </div>

        <div style={styles.separator}></div>

        <div
          style={styles.buttonWrapper}
          onFocus={() => setFocusedButton("satellite")}
          onMouseLeave={() => setFocusedButton("")}
        >
          <MapTypeButton
            text={"Satélite"}
            onClick={() => handleClick("satellite")}
            active={mapTypeId === "satellite" || mapTypeId === "hybrid"}
          />
          {focusedButton === "satellite" && (
            <SubMenuOfButtonMapType value="hybrid" title="Marcadores" />
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: "8px 10px 8px 10px",
    backgroundColor: "#fff",
    borderRadius: "4px",
    height: "fit-content",
  },
  body: {
    display: "flex",
    alignItems: "center",
    columnGap: "16px",
    minWidth: "140px",
  },
  separator: {
    width: "1px",
    height: "20px",
    backgroundColor: "rgba(225, 229, 234, 1)",
  },
  buttonWrapper: {
    position: "relative",
    pointerEvents: "all",
  },
};

const mapDispatchToProps = {
  setTipoMapa: actions.setTipoMapa,
};

function mapStateToProps({ mapa }) {
  return {
    mapTypeId: mapa.mapTypeId,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomMapButtons);
