import React from "react";
import { withStyles } from "@material-ui/core";
/*
 * This is a component that is used to render the text information
 * of the farol.
 * title: string
 * properties: object of arrays values
 * * example:{ "name": ["Farol 1"], "description": ["Farol 1 description"] }
 */
const TextInfoFarol = ({ title, properties, titleAdornmentColor, classes }) => {
  const Info = Object.keys(properties).map((key) => {
    const values = properties[key].join(", ");

    return (
      <span>
        <b>{key}</b>: {values}
        <br />
      </span>
    );
  });

  return (
    <span className={classes.content}>
      <span className={classes.title}>
        {titleAdornmentColor && (
          <div
            className={classes.titleAdornment}
            style={{
              backgroundColor: titleAdornmentColor,
            }}
          />
        )}
        <b>{title}</b>
      </span>
      <br />
      {Info.map((info) => info)}
    </span>
  );
};

const styles = () => {
  return {
    content: {
      flexDirection: "column",
      display: "flex",
      textAlign: "left",
    },
    title: {
      display: "flex",
      alignItems: "center",
    },
    titleAdornment: {
      width: "10px",
      height: "10px",
      margin: "2px",
      borderRadius: "50%",
    },
  };
};

export default withStyles(styles)(TextInfoFarol);
