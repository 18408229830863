import {
  SET_PRIMEIRA_VISUALIZACAO,
  GET_PRIMEIRA_VISUALIZACAO,
  SET_VISIBILIDADE_AVISO
} from './avisoConsultoriaActions';
export const INITIAL_STATE = { primeiraVisualizacao: true, visibilidadeAviso: false };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_VISIBILIDADE_AVISO:
      return { ...state, visibilidadeAviso: action.payload.visibilidadeAviso };
    case SET_PRIMEIRA_VISUALIZACAO:
      return { ...state, primeiraVisualizacao: action.payload.primeiraVisualizacao };
    case GET_PRIMEIRA_VISUALIZACAO:
      return { ...state, primeiraVisualizacao: action.payload.primeiraVisualizacao };
    default:
      return state;
  }
};
