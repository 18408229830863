import _ from 'lodash';

import { todasAsPastas } from './listaPastas';

export const tratarRelatorios = (relatorios) => {
  const views = relatorios.data.views.view;
  const viewsTratadas = [];
  for (const view of views) {
    const urlPartida = view.contentUrl.split('/');
    if (urlPartida.length && urlPartida.length === 3) {
      const viewTratada = {
        nome: view.name,
        url: `${urlPartida[0]}/${urlPartida[2]}`,
        urlPasta: urlPartida[0],
        nomePasta: view.workbook.name,
        descricaoPasta: view.workbook.description
      }
      viewsTratadas.push(viewTratada);
    }
  }
  return _.groupBy(viewsTratadas, (view) => {
    return view.urlPasta
  });
}

export const tratarPastas = (workbooksUsuario) => {
  const listaNomePastas = [...new Set(workbooksUsuario.map((v) => v.project.name))];
  const workbooksAgrupadosPorProjeto = _.groupBy(workbooksUsuario, 'project.name');
  const todasAsPastasAgrupadas = _.groupBy(todasAsPastas, 'titulo')

  listaNomePastas.forEach((value) => {
    if (!todasAsPastasAgrupadas[value]) {
      todasAsPastas.push({
        titulo: value,
        imagemSrc: "",
        workBooks: []
      })
    }
  })

  return todasAsPastas.filter(pasta => {
    const workbooksFiltradosPorTitulo = workbooksAgrupadosPorProjeto[pasta.titulo];
    _.map(workbooksFiltradosPorTitulo, workbook => {
      if (!pasta.workBooks.includes(workbook.contentUrl)) {
        pasta.workBooks.push(workbook.contentUrl)
      }
    });
    return workbooksUsuario.some(pastaUsuario => {
      return usuarioPodeAcessarPasta(pasta, pastaUsuario);
    });
  });
}

export const usuarioPodeAcessarPasta = (pasta, pastaUsuario) => {
  return pasta.workBooks.some(titulo => {
    return pastaUsuario.contentUrl === titulo;
  });
}
