import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  withStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { stableSort, getComparator } from "shared/utils";
import GoalPlanListTableHeader from "./goalPlanListTableHeader";
import constants from "constants/global";
import MenuDropdown from "./menuDropdown";
import { formatDate } from "features/goalPlan/goalPlanUtils";
import localConstants from "../../shared/constants";
import GoalPlanStatus from "../../shared/goalPlanStatus";

const TABLE_DATE_FORMAT_PATTERN = localConstants.dateShortFormat;

const GoalPlanListing = ({
  classes,
  goalPlans,
  showRevisions,
  showEdition,
  showReview,
  showCopy,
}) => {
  const [order, setOrder] = useState(constants.orderBy.desc);
  const [orderBy, setOrderBy] = useState("active");

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === constants.orderBy.asc;
    setOrder(isAsc ? constants.orderBy.desc : constants.orderBy.asc);
    setOrderBy(property);
  };

  let sortedGoalPlans = stableSort(goalPlans, getComparator(order, orderBy));

  if (orderBy === "active") {
    sortedGoalPlans = sortedGoalPlans.sort((a, b) => {
      if (order === constants.orderBy.desc) {
        return sortByActive(a, b);
      }

      return sortByActive(b, a);
    });
  }

  function sortByActive(a, b) {
    const isAExpired =
      a.active &&
      (a.situation === localConstants.situation.PAST ||
        a.situation === localConstants.situation.FUTURE);

    const isBExpired =
      b.active &&
      (b.situation === localConstants.situation.PAST ||
        b.situation === localConstants.situation.FUTURE);

    if (a.active && !b.active) {
      return -1;
    }
    if (!a.active && b.active) {
      return 1;
    }
    if (isAExpired && !isBExpired) {
      return 1;
    }
    if (!isAExpired && isBExpired) {
      return -1;
    }
    return 0;
  }

  return (
    <Paper
      className={classes.goalPlanListingWrapper}
      data-testid="listagem-metas"
    >
      <Table
        id="tabela-metas"
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <GoalPlanListTableHeader
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={goalPlans.length}
        />
        <TableBody>
          {sortedGoalPlans.map((goalPlan) => (
            <TableRow
              hover
              className={classes.tableRow}
              onClick={() => showRevisions(goalPlan.id)}
              tabIndex={-1}
              key={goalPlan.id}
            >
              <TableCell
                className={classes.bodyCell}
                component="th"
                scope="row"
              >
                <div className={classes.goalPlanIconWrapper}>
                  <div className={classes.icons}>
                    {goalPlan.name.trim().charAt(0).toUpperCase()}
                  </div>
                  {goalPlan.name}
                </div>
              </TableCell>
              <TableCell className={classes.bodyCell}>
                {`V${goalPlan.version}`}
              </TableCell>
              <TableCell className={classes.bodyCell}>
                <div className={classes.dateCell}>
                  <b>
                    {formatDate(
                      goalPlan.initialDate,
                      TABLE_DATE_FORMAT_PATTERN
                    )}
                  </b>{" "}
                  até{" "}
                  <b>
                    {formatDate(goalPlan.finalDate, TABLE_DATE_FORMAT_PATTERN)}
                  </b>
                </div>
              </TableCell>
              <TableCell
                className={classes.bodyCell}
                id={`statusMetas-${goalPlan.id}`}
              >
                <GoalPlanStatus
                  active={goalPlan.active}
                  initialDate={goalPlan.initialDate}
                  finalDate={goalPlan.finalDate}
                  situation={goalPlan.situation}
                />
              </TableCell>
              <TableCell className={classes.bodyCell}>
                <MenuDropdown
                  goalPlan={goalPlan}
                  classes={classes}
                  showRevisions={showRevisions}
                  showEdition={showEdition}
                  showReview={showReview}
                  showCopy={showCopy}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

const styles = (theme) => ({
  goalPlanListingWrapper: {
    overflow: "hidden",
  },
  tableRow: {
    cursor: "pointer",
    padding: "0 50px",
  },
  bodyCell: {
    fontFamily: "Roboto",
    color: "#757575",
    fontSize: 14,
  },
  goalPlanIconWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icons: {
    width: "32px",
    height: "32px",
    flexGrow: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "17px 16px 16px 0px",
    borderRadius: "30px",
    backgroundColor: "#e5f3ff",
    fontSize: "16px",
    fontWeight: "600",
    color: theme.palette.primary.main,
    border: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#B6DDFF",
    },
  },
  activeStatus: {
    width: "80px",
    height: "28px",
    flexGrow: "0",
    alignItems: "center",
    margin: "2px 0px 2px 0px",
    padding: "5px 20px 5px 20px",
    borderRadius: "100px",
    backgroundColor: "#dff1df",
    color: "#4caf50",
    fontWeight: "550",
  },
  inactiveStatus: {
    width: "80px",
    height: "28px",
    flexGrow: "0",
    alignItems: "center",
    margin: "2px 0px 2px 0px",
    padding: "5px 20px 5px 20px",
    borderRadius: "100px",
    backgroundColor: "#f8807833",
    color: "#f44336",
    fontWeight: "550",
  },
});

export default withStyles(styles)(GoalPlanListing);
