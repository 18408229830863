import React, { Component } from 'react';
import { Typography, Grid, withStyles } from '@material-ui/core';
import Carousel from 'nuka-carousel';
import Icon from '@material-ui/core/Icon';
import Modal from '@material-ui/core/Modal';
import ItemModalFoto from './itemModalFoto';
import ItemLateralModal from './itemLateralModal';

export class ModalFoto extends Component {

  constructor(props) {
    super(props);
    this.atualizaIndex = this.atualizaIndex.bind(this);
    this.state = {
      slideIndex: 0,
      idColetaFotoAtivo: 0,
      listaFotos: []
    };
  };

  componentDidMount() {
    const { slideIndex, idColetaFotoAtivo } = this.props;
    this.setState({
      slideIndex: slideIndex,
      idColetaFotoAtivo: idColetaFotoAtivo,
      listaFotos: this.obtemListaUrlFoto()
    })
  }

  componentDidUpdate() {
    const { idColetaFotoAtivo } = this.state;
    const { coletasPastoSelecionado } = this.props;
    this.scrollToActiveItem(coletasPastoSelecionado.find(coleta => coleta.IdColetaFoto === idColetaFotoAtivo).ref);
  }

  atualizaIndex(idColetaFotoAtivo, ref, isBrinco) {
    const { getFotoIndex } = this.props;
    let newSlideIndex = getFotoIndex(idColetaFotoAtivo);

    if (isBrinco)
      newSlideIndex += 1;

    this.setState({
      slideIndex: newSlideIndex,
      idColetaFotoAtivo: idColetaFotoAtivo
    });

    this.scrollToActiveItem(ref);
  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });

  }

  obtemListaUrlFoto() {
    const { listaIdFotosPasto } = this.props;
    let listaFotosUrl = [];
    listaIdFotosPasto.forEach(foto => {
      listaFotosUrl.push(`${process.env.REACT_APP_ENDPOINT_BUCKET}/MORTE/${foto}/imgCARCACA.png`);
      listaFotosUrl.push(`${process.env.REACT_APP_ENDPOINT_BUCKET}/MORTE/${foto}/imgBRINCO.png`);
    });
    return listaFotosUrl;
  }

  setNewSlideIndex(newSlideIndex) {
    const { listaIdFotosPasto } = this.props;
    this.setState({
      slideIndex: newSlideIndex,
      idColetaFotoAtivo: listaIdFotosPasto[newSlideIndex % 2 === 0 ? newSlideIndex / 2 : (newSlideIndex - 1) / 2]
    })
  }

  scrollToActiveItem(myRef) {
    if (myRef.current !== null)
      myRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  render() {
    const { listaFotos, slideIndex, idColetaFotoAtivo } = this.state;
    const { classes, modal, toggleModal, nomePasto, listaIdFotosPasto, coletasPastoSelecionado } = this.props;

    return (
      <Modal
        open={modal} onClose={toggleModal} style={styles.center}
      >
        <Grid conteiner direction="row" className={classes.mainGrid} id="mainGrid">
          <Grid item xs={2} id='gridLeft' className={classes.gridLeft}>
            <Typography id="nomeDoPasto" variant={'h6'} style={styles.pastoModelTxt}>{nomePasto}</Typography>
            <Typography id="contagemModal" variant={'h6'} style={styles.counterModelTxt}>{slideIndex + 1} / {listaIdFotosPasto.length * 2} </Typography>
          </Grid>
          <Grid item xs={5} id='gridItemCenter' className={classes.gridItemCenter}>
            <Grid container justify={'center'} alignItems={'center'} id='gridContainerCenter' className={classes.gridContainerCenter}>
              <Grid container justify={'center'} alignItems={'center'} id='gridCarousel' className={classes.gridCarousel}>
                <Carousel
                  className={classes.carousel}
                  slideIndex={slideIndex}
                  afterSlide={slideIndex => this.setNewSlideIndex(slideIndex)}
                  renderCenterLeftControls={({ previousSlide }) => (
                    <Grid container id="gridIconBefore" onClick={previousSlide} style={styles.gridIconBefore} justify={'center'}>
                      <Icon id="iconBefore" fontSize={'large'} style={styles.iconBefore}>
                        navigate_before
                      </Icon>
                    </Grid>
                  )}
                  renderCenterRightControls={({ nextSlide }) => (
                    <Grid container onClick={nextSlide} style={styles.gridIconNext} justify={'center'}>
                      <Icon id="iconNext" fontSize={'large'} style={styles.iconNext}>
                        navigate_next
                      </Icon>
                    </Grid>
                  )}
                  cellAlign="center"
                  enableKeyboardControls={true}
                  id="carousel"
                  renderBottomCenterControls={() => { return false }}
                >
                  {listaFotos.map(foto => <ItemModalFoto id='ItemModalFoto' foto={foto} />)}
                </Carousel>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} className={classes.gridItemLateral}>
            <Grid container className={classes.gridContainerLateral}>
              {coletasPastoSelecionado.map(coleta => {
                return (
                  <Grid item xs={6}>
                    <div ref={coleta.ref} className={classes.divItemLateralModal}>
                      <ItemLateralModal id="itemLateralModal" nomePasto={nomePasto} coleta={coleta} idColetaFotoAtivo={idColetaFotoAtivo} atualizaIndex={this.atualizaIndex} slideIndex={slideIndex} listaFotos={listaFotos} />
                    </div>
                  </Grid>);
              }
              )}
            </Grid>
          </Grid>
          <Grid container item xs={1} justify={'flex-end'} id='gridClose'>
            <Icon id="close" onClick={toggleModal} fontSize={'large'} className={classes.iconClose}>close</Icon>
          </Grid>
        </Grid>
      </Modal>
    );
  }
};


const styles = {
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  mainGrid: {
    display: 'flex',
    width: '97%',
    height: '94%',
    backgroundColor: '#FFFFFF',
    padding: '20px 20px 20px 36px',
    borderRadius: '5px'
  },
  pastoModelTxt: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '18px',
    color: '#333333'
  },
  counterModelTxt: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#333333',
    marginBottom: '17px'
  },
  iconClose: {
    color: '#333'
  },
  gridIconBefore: {
    height: '100px',
    width: '80px',
    marginLeft: '-80px',
    cursor: 'pointer'
  },
  iconBefore: {
    color: '#333',
    alignSelf: 'center'
  },
  gridIconNext: {
    height: '100px',
    width: '80px',
    marginRight: '-80px',
    cursor: 'pointer'
  },
  iconNext: {
    color: '#333',
    alignSelf: 'center'
  },
  gridLeft: {
    paddingLeft: 24,
  },
  gridContainerCenter: {
    height: '100%',
    width: '100%',
  },
  gridCarousel: {
    width: '80%',
    height: '100%',
    borderRadius: '2px'
  },
  carousel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  gridItemLateral: {
    height: '100%',
    overflowX: 'auto',
    paddingLeft: '4%'
  },
  gridContainerLateral: {
    display: 'flex'
  }
};
export default withStyles(styles)(ModalFoto);
