import BaseService from "../../services/BaseService";
const BASE_URL = `${process.env.REACT_APP_ENDPOINT}entradasaida/api/v1/`;

class BalancoArrobaService extends BaseService {
  async obterDatasPesagem(IdFazenda) {
    return await this.get(
      `${BASE_URL}evento-pesagem?where={"IdFazenda": ${IdFazenda} }&attributes=["DataPesagem","IdFazenda"]&sort=[["DataPesagem", "ASC"]]`
    );
  }

  async obterDadosBalanco(IdFazenda, dataInicio, dataFim) {
    const DataInicial = dataInicio.split("/");
    let dataInicioFormatada = DataInicial;
    if (DataInicial.length > 1)
      dataInicioFormatada = `${DataInicial[2]}-${DataInicial[1]}-${DataInicial[0]}`;
    const DataFinal = dataFim.split("/");
    let dataFinalFormatada = DataFinal;
    if (DataFinal.length > 1)
      dataFinalFormatada = `${DataFinal[2]}-${DataFinal[1]}-${DataFinal[0]}`;
    return await this.get(
      `${BASE_URL}analises/balanco-de-arroba?IdFazenda=${IdFazenda}&DataInicial=${dataInicioFormatada}&DataFinal=${dataFinalFormatada}`
    );
  }
}

export default new BalancoArrobaService();
