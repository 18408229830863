import React, { useState } from "react";
import { Icon, withStyles } from "@material-ui/core";
import moment from "moment";

const verificaSeBannerSeraExibido = (dataMudancaBaseDeDados) => {
  const dataFim = moment(dataMudancaBaseDeDados).add(1, "month");
  const hoje = moment();
  const exibirBanner = hoje.isBefore(dataFim);
  return exibirBanner;
};

function FechamentoConsumoBannerNovidades({ classes, dataMudancaBaseDeDados }) {
  const [bannerVisible, setBannerVisible] = useState(
    verificaSeBannerSeraExibido(dataMudancaBaseDeDados)
  );
  const dataMudancaBaseDeDadosString =
    dataMudancaBaseDeDados.format("DD/MM/YYYY");

  if (bannerVisible) {
    return (
      <div
        className={classes.bannerContainer}
        id="fechamentoConsumoBannerNovidades"
      >
        <div className={classes.bannerCloseButton}>
          <span
            id="botaoFecharBannerNovidades"
            onClick={() => setBannerVisible(false)}
          >
            <Icon>close-thick</Icon>
          </span>
        </div>
        <h1 className={classes.bannerTitle}>Temos novidades!</h1>
        <p className={classes.bannerText}>
          Prezado cliente, informamos que no dia {dataMudancaBaseDeDadosString}{" "}
          realizamos uma reestruturação em nossa base de dados, de modo a trazer
          resultados cada vez mais assertivos. Essa reestruturação está
          disponível filtrando o período a partir de{" "}
          {dataMudancaBaseDeDadosString}.{" "}
          <strong>
            Qualquer dúvida entre em contato com nosso canal de suporte.
          </strong>
        </p>
      </div>
    );
  }
  return null;
}

const styles = (theme) => {
  return {
    bannerContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "24px",
      margin: "24px 0px 49px",
      padding: "24px",
      borderRadius: "8px",
      boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)",
      border: "solid 1px #f2f2f2",
      backgroundColor: "#f6fafe",
    },
    bannerCloseButton: {
      alignSelf: "flex-end",
      cursor: "pointer",
    },
    bannerTitle: {
      fontFamily: "Roboto",
      fontSize: "20px",
      fontWeight: "bold",
      marginTop: "-30px",
      color: theme.palette.primary.main,
    },
    bannerText: {
      fontFamily: "Roboto",
      fontSize: "16px",
      lineHeight: 1.5,
      color: "#666",
    },
  };
};

export default withStyles(styles)(FechamentoConsumoBannerNovidades);
