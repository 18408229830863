import React from "react";
import "./styles.css";
import { formatarData } from "lib/tratamentoDatas";
import { TIPO_COLETA } from "constants/tipoColetas";

import { OverlayViewF } from "@react-google-maps/api";

export const renderCorDoIcone = (cor) => {
  switch (cor) {
    case "red":
      return "#FDDFDD";
    case "green":
      return "#F4F7D4";
    default:
      return "rgba(240, 240, 240, 0.98)";
  }
};

const formatarCoordenada = (coordenada) => coordenada.toFixed(2);

const formatarMedidaAltura = (medida) => {
  const mediaMedidaAltura =
    medida.reduce((prev, curr) => {
      return prev + curr.Altura;
    }, 0) / medida.length;
  return mediaMedidaAltura.toFixed(2);
};

const obterNomeTipoColeta = (idTipoColeta) => {
  return TIPO_COLETA[idTipoColeta] || "Tipo Desconhecido";
};

const renderTipoColetaHeader = (gpsLigado, CapturadaDentroDoPasto) => {
  if (!gpsLigado) {
    return <>sem geolocalização</>;
  }
  if (CapturadaDentroDoPasto) {
    return <>dentro da área do pasto</>;
  }
  return <>fora da área do pasto</>;
};

const renderGeolocalizacaoLabel = (gpsLigado, position) => {
  if (gpsLigado) {
    return (
      <>
        latitude {formatarCoordenada(position.lat)} | longitude{" "}
        {formatarCoordenada(position.lng)}
      </>
    );
  }
  return <>Não foi possível coletar a localização.</>;
};

export const TooltipPasto = ({ informacoesColeta, forragem }) => {
  const {
    pasto,
    position,
    CapturadaDentroDoPasto,
    DataColeta,
    IdTipoColetaAltura,
    MedidaAltura,
    gpsLigado,
    url,
    color
  } = informacoesColeta;


  if (pasto) {
    return (
      <OverlayViewF
        key={"tooltip-marcador-" + pasto.IdPasto}
        position={pasto.CentroPasto[0]}
        mapPaneName={"overlayMouseTarget"}

        getPixelPositionOffset={(width, height) => ({
          x: -(width / 2),
          y: -(height / 2),
        })}
      >
        <div className="modal-coleta-wrapper">
          <div
            className="modal-coleta"
          >
            <div className="modal-header">
              <div
                className="coleta-icon"
                style={{
                  backgroundColor: `${renderCorDoIcone(color)}`,
                }}
              >
                <img src={url} alt="" />
              </div>

              <div className="modal-title">
                <h3 className="bold">
                  Coleta -{" "}
                  {renderTipoColetaHeader(gpsLigado, CapturadaDentroDoPasto)}
                </h3>
                <p>GPS {gpsLigado ? "online" : "offline"}</p>
              </div>
            </div>

            <div className="divider"></div>

            <div className="modal-body">
              <ul>
                <li>
                  Nome do pasto: <span className="bold">{pasto.Nome}</span>
                </li>
                <li>
                  Forragem: <span className="bold">{forragem.Nome}</span>
                </li>
                <li>
                  Data da coleta:{" "}
                  <span className="bold">{formatarData(DataColeta)}</span>
                </li>
                <li>
                  Tipo:{" "}
                  <span className="bold">
                    {obterNomeTipoColeta(IdTipoColetaAltura)}
                  </span>
                </li>
                <li>
                  Altura coletada:{" "}
                  <span className="bold">
                    {formatarMedidaAltura(MedidaAltura)} cm
                  </span>
                </li>
                <li>
                  Geolocalização:{" "}
                  <span className="bold">
                    {renderGeolocalizacaoLabel(gpsLigado, position)}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </OverlayViewF>

    );
  }
  return null;
};
