import React from "react";
import { DatePicker as DatePickerMUI } from "material-ui-pickers";
import moment from "moment";
const DatePicker = (props) => {
  const {
    className,
    onChangeDate,
    label,
    maxDateMessage,
    minDateMessage,
    value,
    maxDate,
    minDate,
    format,
    disableFuture,
    customStyle,
    id,
    clearable,
    okLabel = "Selecionar",
    cancelLabel = "Cancelar",
    clearLabel = "Limpar",
    todayLabel = "Hoje",
    showTodayButton = false,
    placeholder = "DD/MM/AAAA",
    keyboard = true,
    InputProps,
    onError,
    onChangeInputValue,
  } = props;
  const functionError = onError ? onError : () => {};
  const inputChange = onChangeInputValue ? onChangeInputValue : () => {};
  return (
    <DatePickerMUI
      id={id}
      format={format}
      minDate={minDate}
      onInputChange={(e) => {
        inputChange(e.target.value);
      }}
      maxDate={maxDate}
      onlyCalendar
      keyboard={keyboard}
      okLabel={okLabel}
      cancelLabel={cancelLabel}
      clearable={clearable}
      clearLabel={clearLabel}
      todayLabel={todayLabel}
      showTodayButton={showTodayButton}
      placeholder={placeholder}
      className={className}
      label={label}
      InputProps={InputProps}
      disableFuture={disableFuture}
      invalidDateMessage="O Formato da data é inválido"
      maxDateMessage={maxDateMessage}
      minDateMessage={minDateMessage}
      mask={(value) =>
        value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : []
      }
      onError={(e, error) => functionError(e, error)}
      disableOpenOnEnter
      animateYearScrolling={false}
      value={value}
      onChange={(e) => {
        onChangeDate(e);
      }}
      style={
        !customStyle
          ? {
              width: 150,
            }
          : customStyle
      }
    />
  );
};

DatePicker.defaultProps = {
  disableFuture: true,
  maxDate: moment(),
  minDate: moment(),
  format: "DD/MM/YYYY",
};
export default DatePicker;
