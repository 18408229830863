import React, { useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

const TabelaCadastroIngrediente = (props) => {
  const {
    ingredientesCadastrados,
    setIngredientesCadastrados,
    ingredientesParaMostrar = [],
    classes,
    quantidadeTotal,
    setQuantidadeTotal,
    modeloImpressao = false,
    capacidadeMisturador,
  } = props;

  const titulosTabelaConst = ["DESCRIÇÃO DO INGREDIENTE"];

  let titulosTabela = [];

  if (!modeloImpressao) {
    titulosTabela = [
      ...titulosTabelaConst,
      "QUANTIDADE (g/Cab/dia)",
      "EXCLUIR",
    ];
  } else {
    titulosTabela = [...titulosTabelaConst, "QUANTIDADE (KG)"];
  }

  // remove o ingrediente da lista de ingredientes
  const removeIngrediente = (idIngrediente) => {
    const ingredienteIndex = ingredientesCadastrados.findIndex(
      (ing) => ing.IdIngrediente === idIngrediente
    );

    if (ingredienteIndex !== -1) {
      const ingrediente = ingredientesCadastrados[ingredienteIndex];
      const listaIngredienteTratada = [...ingredientesCadastrados];
      if (ingrediente.OperacaoIngrediente.IdOperacaoIngrediente) {
        ingrediente._destroy = true;
        listaIngredienteTratada[ingredienteIndex] = ingrediente;
      } else {
        listaIngredienteTratada.splice(ingredienteIndex, 1);
      }
      setIngredientesCadastrados([...listaIngredienteTratada]);
    }
  };

  useEffect(() => {
    let somaQuantidade = 0;
    ingredientesParaMostrar.forEach((ingCad) => {
      somaQuantidade += Number(ingCad.FormulaIngrediente.Quantidade);
    });
    setQuantidadeTotal(somaQuantidade);
  }, [ingredientesParaMostrar]);

  // remove o valor da quantidade total quando a lista esvazia
  useEffect(() => () => setQuantidadeTotal(""), []);

  const formataCampoNumerico = (valor) => {
    if (!valor) return "-";
    const valorTratado = parseFloat(valor).toFixed(2);
    return Number.isNaN(valorTratado)
      ? "-"
      : valorTratado.toString().replace(".", ",") + " KG";
  };

  return (
    <Paper className={classes.paper}>
      <div
        className={modeloImpressao ? classes.divTablePrint : classes.divTable}
      >
        <Table>
          <TableHead>
            <TableRow>
              {titulosTabela.map((titulo, key) => (
                <TableCell key={key} className={classes.headerCell}>
                  {titulo}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {ingredientesParaMostrar.map((ingrediente, key) => (
              <TableRow key={key}>
                <TableCell className={classes.bodyCell}>
                  {ingrediente.OperacaoIngrediente.Descricao}
                </TableCell>
                <TableCell className={classes.bodyCell}>
                  {!modeloImpressao
                    ? ingrediente.FormulaIngrediente.Quantidade
                    : formataCampoNumerico(
                        ingrediente.FormulaIngrediente.Quantidade
                      )}
                </TableCell>
                {!modeloImpressao && (
                  <TableCell>
                    <div style={{ marginLeft: "18px" }}>
                      <DeleteIcon
                        style={{
                          color: "rgba(0, 0, 0, 0.7)",
                          fontSize: "24px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          removeIngrediente(ingrediente.IdIngrediente)
                        }
                      />
                    </div>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell className={classes.footerCell}>
                {!modeloImpressao ? "TOTAL (Consumo objetivo)" : "TOTAL"}
              </TableCell>
              <TableCell className={classes.footerCell}>
                {!modeloImpressao
                  ? quantidadeTotal
                  : formataCampoNumerico(capacidadeMisturador)}
              </TableCell>
              {!modeloImpressao && <TableCell className={classes.footerCell} />}
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </Paper>
  );
};

const styles = (theme) => {
  return {
    paper: {
      overflow: "hidden",
      width: "100%",
      borderRadius: 16,
      marginTop: 30,
    },
    headerCell: {
      color: theme.palette.primary.main,
      backgroundColor: "#e1e1e1",
      fontSize: 14,
      position: "sticky",
      top: 0,
    },
    bodyCell: {
      fontSize: 14,
      color: "#757575",
      textTransform: "uppercase",
    },
    footerCell: {
      color: theme.palette.primary.main,
      fontSize: 14,
      fontWeight: "bold",
      position: "sticky",
      bottom: 0,
      backgroundColor: "white",
    },
    divTable: {
      overflowY: "auto",
      maxHeight: 300,
    },
    divTablePrint: {
      overflowY: "none",
    },
  };
};

export default withStyles(styles)(TabelaCadastroIngrediente);
