import moment from "moment";

export const getConciliacoesEnriquecidas = (conciliacoes, entradasSaidas) => {
  const TIPO_REGISTRO_ENTRADA = 1;
  const TIPO_REGISTRO_SAIDA = 2;
  return conciliacoes.map((conciliacao) => {
    const idCategoria = conciliacao.IdCategoriaAnimal;
    const entradas = entradasSaidas.filter(
      (elem) =>
        elem.IdCategoriaAnimal === idCategoria &&
        elem.TipoRegistro === TIPO_REGISTRO_ENTRADA
    );
    const saidas = entradasSaidas.filter(
      (elem) =>
        elem.IdCategoriaAnimal === idCategoria &&
        elem.TipoRegistro === TIPO_REGISTRO_SAIDA
    );
    conciliacao.Entrada = 0;
    entradas.forEach((entrada) => {
      conciliacao.Entrada += entrada.Quantidade;
    });
    conciliacao.Saida = 0;
    saidas.forEach((saida) => {
      conciliacao.Saida += saida.Quantidade;
    });
    return conciliacao;
  });
};

export const getCategoriasDeConciliacoes = (conciliacoes) => {
  if (conciliacoes) {
    return conciliacoes.map((conciliacao) => {
      return {
        IdCategoriaAnimal: conciliacao.CategoriaAnimal.IdCategoriaAnimal,
        Nome: conciliacao.CategoriaAnimal.Nome,
      };
    });
  }
};

export const filtraFazendaPorIdFazendaSelecionada = (
  idFazendaSelecionada,
  listaFazendas
) => {
  if (!idFazendaSelecionada || !listaFazendas) {
    return {};
  }
  return listaFazendas.find(
    (fazenda) => fazenda.IdFazenda === idFazendaSelecionada
  );
};

export const getInitialDateValues = (
  currentTime,
  initialreconciliationTime
) => {
  const currentYear = moment(currentTime).year();
  const PRIMEIRO_CICLO_ANO = new moment(`${currentYear}-05-01T00:00:00`);
  const SEGUNDO_CICLO_ANO = new moment(`${currentYear}-11-01T00:00:00`);
  const response = {
    dataInicioFiltro: {},
    dataFimFiltro: {},
  };

  //Foi alterado para quando tivel data de conciliação inicial funciona corretamente das duas formas
  if (initialreconciliationTime) {
    response.dataInicioFiltro = moment(initialreconciliationTime).format(
      "YYYY-MM-DDThh:mm:ss"
    );
  } else {
    if (moment(currentTime).isBefore(PRIMEIRO_CICLO_ANO)) {
      response.dataInicioFiltro = moment(SEGUNDO_CICLO_ANO).subtract("year", 1);
    } else if (moment(currentTime).isBefore(SEGUNDO_CICLO_ANO)) {
      response.dataInicioFiltro = PRIMEIRO_CICLO_ANO;
    } else {
      response.dataInicioFiltro = SEGUNDO_CICLO_ANO;
    }
  }
  response.dataInicioFiltro = moment(response.dataInicioFiltro);
  response.dataFimFiltro = moment(currentTime).format("YYYY-MM-DDTHH:mm:ss");
  return response;
};

export const getStylesLinha = (height, color, opacity) => {
  return {
    color: color,
    opacity: `${opacity}`,
    height: `${height.toString()}px`,
    width: "100%",
    marginTop: "32px",
    marginBottom: "28px",
  };
};

export const gerarListaCustomSelect = (categoriaAnimais) => {
  if (categoriaAnimais) {
    const response = [
      {
        value: 0,
        label: "TODOS",
      },
    ];
    categoriaAnimais.forEach((categoria) => {
      response.push({
        value: categoria.IdCategoriaAnimal,
        label: categoria.Nome,
      });
    });
    return response;
  }
  return [{
    value: 0,
    label: "TODOS",
  }];
};

export const TODAS_CATEGORIAS = 0;
export const filtrarConciliacoesPorCategoria = (
  conciliacoes,
  categoriaSelecionada
) => {
  let conciliacoesFiltradas;
  if (categoriaSelecionada === TODAS_CATEGORIAS) return conciliacoes;
  if (conciliacoes.length > 0) {
    conciliacoesFiltradas = conciliacoes.filter(
      (conc) => conc.CategoriaAnimal.IdCategoriaAnimal === categoriaSelecionada
    );
  }
  return conciliacoesFiltradas;
};

// Util do filtro
export const getDataMin = (dataAtual) => {
  const qtdMesesEmDoisCiclos = 12;
  return moment(dataAtual)
    .subtract(qtdMesesEmDoisCiclos, "month")
    .format("MM-DD-YYYY");
};

export const getCurrentMinDate = (dataInicio, minDate) => {
  return dataInicio ? dataInicio : minDate;
};

//Util de Resultados
export const calcularEstoquePortal = (conciliacao) => {
  if (conciliacao) {
    return (
      conciliacao.EstoqueInicial +
      conciliacao.Entrada +
      conciliacao.Nascimentos +
      conciliacao.TrocaCategoriaEntrada -
      conciliacao.TrocaCategoriaSaida -
      conciliacao.Mortes +
      conciliacao.Entrevero -
      conciliacao.Saida
    );
  } else {
    return 0;
  }
};
export const calcularDiferencaColetorPortal = (conciliacao) => {
  return conciliacao.EstoqueFinal - calcularEstoquePortal(conciliacao);
};

export const styleFonteVermelha = {
  color: "#eb394c",
};
export const styleFonteCinza = {
  color: "#333333",
};
export const styleFonteVerde = {
  color: "#32A07D",
};
export const styleFonteValorZero = {
  color: "#8E8E93",
};
export const obterCorTexto = (campo, valor) => {
  if (campo === "DIFERENCA") {
    if (valor === 0) return styleFonteValorZero;
    if (valor < 0) return styleFonteVermelha;
    return styleFonteVerde;
  } else {
    return styleFonteCinza;
  }
};

export const getSimboloNumero = (origin, value) => {
  if (origin === "DIFERENCA" && value > 0) return "+";
  return "";
};

export const nomesColunas = [
  "CATEGORIA",
  "ORIGEM",
  "ESTOQUE INICIAL",
  "ENTRADA",
  "SAÍDA",
  "TROCA_CAT. ENTRADA",
  "TROCA_CAT. SAÍDA",
  "NASCIMENTOS",
  "MORTES",
  "ENTREVERO",
  "ESTOQUE FINAL",
  "DIFERENÇA",
];

export const nomesColunasResumo = [
  "ORIGEM",
  "ESTOQUE INICIAL",
  "ENTRADA",
  "SAÍDA",
  "TROCA_CAT. ENTRADA",
  "TROCA_CAT. SAÍDA",
  "NASCIMENTOS",
  "MORTES",
  "SALDO_DE ENTREVERO_",
  "ESTOQUE FINAL",
  "DIFERENÇA",
];

export const camposEsperados = [
  "CategoriaAnimal",
  "Origem",
  "EstoqueInicial",
  "Entrada",
  "Saida",
  "EntradaTrocaDeCategoria",
  "SaidaTrocaDeCategoria",
  "Nascimento",
  "Morte",
  "Entrevero",
  "EstoqueFinal",
  "Diferenca",
];
export const camposEsperadosResumo = [
  "Origem",
  "EstoqueInicial",
  "Entrada",
  "Saída",
  "EntradaTrocaDeCategoria",
  "SaidaTrocaDeCategoria",
  "Nascimento",
  "Morte",
  "Entrevero",
  "EstoqueFinal",
  "Diferenca",
];
const baseStyleDiferencaBackground = {
  width: "53px",
  height: "24px",
  borderRadius: "12px",
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: "12px",
  fontWeight: "500",
  color: "#ffffff",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

export const STYLE_DIFERENCA_POSITIVA = {
  ...baseStyleDiferencaBackground,
  backgroundColor: "#32a07d",
};

export const STYLE_DIFERENCA_NEGATIVA = {
  ...baseStyleDiferencaBackground,
  backgroundColor: "#eb394c",
};
export const STYLE_DIFERENCA_POSITIVA_RESUMO = {
  color: "#32a07d",
  fontSize: "30px",
};

export const STYLE_DIFERENCA_NEGATIVA_RESUMO = {
  color: "#eb394c",
  fontSize: "30px",
};

export const STYLE_DIFERENCA_POSITIVA_RESUMO_MINI = {
  color: "#32a07d",
  fontSize: "20px",
};

export const STYLE_DIFERENCA_NEGATIVA_RESUMO_MINI = {
  color: "#eb394c",
  fontSize: "20px",
};
export const obterEstiloDiferenca = (campo, valor) => {
  if (campo === "Diferenca") {
    if (valor) {
      return STYLE_DIFERENCA_NEGATIVA;
    } else {
      return STYLE_DIFERENCA_POSITIVA;
    }
  }
  return {};
};

export const obterEstiloDiferencaResumo = (campo, valor, minimizado) => {
  if (campo === "Diferenca") {
    if (valor) {
      return minimizado
        ? STYLE_DIFERENCA_NEGATIVA_RESUMO_MINI
        : STYLE_DIFERENCA_NEGATIVA_RESUMO;
    } else {
      return minimizado
        ? STYLE_DIFERENCA_POSITIVA_RESUMO_MINI
        : STYLE_DIFERENCA_POSITIVA_RESUMO;
    }
  }
  return {};
};

export const retirarAcentuacao = (texto) => {
  return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const primeiraColunaStyle = {
  paddingLeft: "34px",
  paddingRight: "48px",
};

export const ultimaColunaStyle = {
  marginRight: "32px",
};

export const obterEstiloElemento = (elemento) => {
  const primeiroItemColuna = nomesColunas[0];
  const ultimoItemColuna = nomesColunas[nomesColunas.length - 1];
  const primeiroCampo = camposEsperados[0];
  const ultimoCampo = camposEsperados[camposEsperados.length - 1];
  switch (elemento) {
    case primeiroItemColuna:
      return primeiraColunaStyle;
    case primeiroCampo:
      return primeiraColunaStyle;
    case ultimoItemColuna:
      return ultimaColunaStyle;
    case ultimoCampo:
      return ultimaColunaStyle;
    default:
      return {};
  }
};

export const calculaData = (array, data) => {
  if (array) {
    var dataReferencia = moment(data);
    var dataProxima = moment(array[0]);
    let dif = Math.abs(dataReferencia.diff(dataProxima, "days"));
    array.forEach((date) => {
      let diferenca = Math.abs(dataReferencia.diff(date, "days"));
      if (diferenca < dif) {
        dif = diferenca;
        dataProxima = date;
      }
    });
    return dataProxima;
  } else return {};
};

export const GREY_BACKGROUND_COLOR = {
  backgroundColor: "#fafafa",
};
export const obterEstiloLinhaTabela = (index) => {
  if (index & 1) {
    return GREY_BACKGROUND_COLOR;
  }
  return {};
};

export const getTabsConciliacaoBalanco = () => [
  {
    label: "Categoria Animal",
    id: "categoriaAnimal",
    exibir: true,
  },
  {
    label: "Entradas e Saídas",
    id: "tabEntradasSaidas",
    exibir: true,
  },
  {
    label: "Conciliação",
    id: "tabConciliacao",
    exibir: true,
  },
  {
    label: "Balanço de Arroba",
    id: "tabBalancoArroba",
    exibir: true,
  },
];

export const deveExibirAba = (nomeAba, abaSelecionada, idFazenda) => {
  return abaSelecionada === nomeAba && idFazenda;
};

export const extraiNomeCategoria = (descritivoOperacao) => {
  if (!descritivoOperacao || descritivoOperacao.length === 0) return "";
  const nomeCategoria = descritivoOperacao[0]
    ? descritivoOperacao[0].CategoriaAnimal.Nome
    : null;
  return nomeCategoria;
};

export const exibeTipoOperacaoLote = (tipo) => {
  let textoTipo;
  switch (tipo) {
    case 1:
      textoTipo = "Criação";
      break;
    case 5:
      textoTipo = "Saída";
      break;
    case 6:
      textoTipo = "Entrada";
      break;
    case 7:
      textoTipo = "Mudança de categoria";
      break;
    default:
      textoTipo = "";
      break;
  }

  return textoTipo;
};
export const obterTipoOperacaoEntradaSaida = (tipoRegistro) => {
  const TIPO_REGISTRO_ENTRADA = 1;
  const TIPO_REGISTRO_ENTRADA_TEXTO = "Entrada";
  const TIPO_REGISTRO_SAIDA_TEXTO = "Saída";
  return tipoRegistro === TIPO_REGISTRO_ENTRADA
    ? TIPO_REGISTRO_ENTRADA_TEXTO
    : TIPO_REGISTRO_SAIDA_TEXTO;
};

export const obterTipoOperacaoMorteNascimento = (tipoRegistro) => {
  const TIPO_REGISTRO_MORTE = "MORTE";
  const TIPO_REGISTRO_MORTE_TEXTO = "Morte";
  const TIPO_REGISTRO_NASCIMENTO_TEXTO = "Nascimento";
  return tipoRegistro === TIPO_REGISTRO_MORTE
    ? TIPO_REGISTRO_MORTE_TEXTO
    : TIPO_REGISTRO_NASCIMENTO_TEXTO;
};
