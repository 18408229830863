import BaseService from "../../services/BaseService";
import FuncoesUtils from "../shared/funcoesUtils";
import * as _ from "lodash";
import { convertLivestockToCurral } from "./curralUtils";
import { incrementDate } from "shared/utils";

/**
 * FIXME: Retirar chamadas pra serviços deste local
 * O service deve ser o responsável por fazer requisições
 **/
const URL_BASE_HERD_SERVICE = `${process.env.REACT_APP_ENDPOINT}herds/livestock/from-pastures-ids`;
const URL_BASE_CURRAL = `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/pastos`;

export class CurralService extends BaseService {
  async listaLotesPorCurrais(pastos) {
    try {
      const idsPastos = pastos.map((pasto) => pasto.IdPasto);

      const idsPastosDivididos = _.chunk(idsPastos, 200);
      let todosLotes = [];

      /**
       * FIXME: Retirar montagem do filtro deste local
       * O service deve ser o responsável por fazer requisições
       **/
      await FuncoesUtils.asyncForEach(idsPastosDivididos, async (idsPastos) => {
        const checkDate = incrementDate(new Date(), 1, "day");
        const query = { pasturesIds: idsPastos.join(","), checkDate };
        const resposta = await this.get(URL_BASE_HERD_SERVICE, query);
        todosLotes = _.concat(todosLotes, resposta.data);
      });

      return convertLivestockToCurral(todosLotes);
    } catch (err) {
      return { err };
    }
  }

  /**
   * FIXME: Retirar chamadas pra serviços deste local
   * O service deve ser o responsável por fazer requisições
   **/
  async adicionarCurral(curral) {
    return await this.post(URL_BASE_CURRAL, curral);
  }

  /**
   * FIXME: Retirar chamadas pra serviços deste local
   * O service deve ser o responsável por fazer requisições
   **/
  async editarCurral(curral, idCurral) {
    return await this.put(`${URL_BASE_CURRAL}/${idCurral}`, curral);
  }

  /**
   * FIXME: Retirar chamadas pra serviços deste local
   * O service deve ser o responsável por fazer requisições
   **/
  async deletarCurral(idCurral) {
    return await this.delete(`${URL_BASE_CURRAL}/${idCurral}`);
  }
}

export default new CurralService();
