import BaseService from "./BaseService";

const URL_BASE = `${process.env.REACT_APP_ENDPOINT}relatorios/api/v1/relatorios`;

class RelatorioService extends BaseService {
  async enviaEmailHistoricoFazenda(IdFazenda, ListaEmails) {
    const urlServico = `${URL_BASE}/fazendas/${IdFazenda}/suplementacao/historico`;

    return await this.post(urlServico, { ListaEmails });
  }
}

export default new RelatorioService();
