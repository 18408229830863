import React from "react";
import {
  Modal,
  Grid,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  withStyles
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import IngredientRegisterModalList from "./ingredientRegisterModalList";

const IngredientRegisterHistoricModal = ({
  classes,
  abrirHistorico,
  aoFechar,
  historico,
  ingrediente,
  isInternalUser
}) => {
  const nomeIngredienteSelecionado = ingrediente
    ? ingrediente.NomeMateriaPrima
    : "";

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={abrirHistorico}
      className={classes.modalWrapper}
      onBackdropClick={aoFechar}
    >
      <Grid
        id="containerModalLote"
        container
        className={classes.modalConteudo}
        alignItems="flex-start"
      >
        <Grid item xs={12} className={classes.cabecalho}>
          <Typography variant="h6" className={classes.tituloTexto}>
            Histórico do Ingrediente - {nomeIngredienteSelecionado}
          </Typography>
          <CloseIcon className={classes.fechar} onClick={aoFechar} />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.boxTabela}>
            <div className={classes.divTable}>
              <Table className={classes.tabela}>
                <TableHead id="cabecalhoTableHistoricoIngrediente">
                  <TableRow>
                    <TableCell align="center" className={classes.defaultCells}>
                      TIPO DE OPERAÇÃO
                    </TableCell>
                    <TableCell align="center" className={classes.defaultCells}>
                      USUÁRIO
                    </TableCell>
                    {isInternalUser &&
                      <TableCell align="center" className={classes.defaultCells} data-testid="Codigo-ingrediente-PA">
                        CÓDIGO DO INGREDIENTE (PA)
                      </TableCell>
                    }
                    <TableCell align="center" className={classes.defaultCells}>
                      MATÉRIA PRIMA
                    </TableCell>
                    <TableCell align="center" className={classes.defaultCells}>
                      <div className={classes.tooltipCell}>
                        <div className={classes.divTootip}>
                          NOME DO INGREDIENTE
                        </div>
                        <Tooltip title="É o nome escolhido para identificação do produto.">
                          <InfoIcon
                            fontSize="inherit"
                            color="primary"
                            style={{
                              fontSize: "18px",
                              position: "relative",
                            }}
                          />
                        </Tooltip>
                      </div>
                    </TableCell>
                    <TableCell align="center" className={classes.defaultCells}>
                      CUSTO POR KG
                    </TableCell>
                    <TableCell align="center" className={classes.defaultCells}>
                      DATA DA COMPRA
                    </TableCell>
                    <TableCell align="center" className={classes.defaultCells}>
                      DATA/HORA
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody id="corpoTableHistoricoIngrediente">
                  {historico.map((item, i) => {
                    return (
                      <IngredientRegisterModalList
                        isInternalUser={isInternalUser}
                        classes={classes}
                        item={item}
                        key={i}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
};

const styles = (theme) => {
  const header = {
    height: "auto",
    backgroundColor: "#e1e1e1",
    fontSize: "12px",
    color: theme.palette.primary.main,
    fontFamily: "Roboto",
    fontWeight: 500,
    letterSpacing: "1px",
    paddingLeft: 1,
    paddingRight: 1,
  };
  const row = {
    fontFamily: "Roboto",
    fontSize: "14px",
    color: "#333333",
    paddingLeft: 1,
    paddingRight: 1,
    width: "100%",
  };
  return {
    row,
    modalWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    boxTabela: {
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.2)",
      height: "294px",
      overflow: "hidden",
      borderRadius: "16px",
    },
    tabela: {
      position: "relative",
      tableLayout: "fixed",
      width: "100%",
      borderRadius: "3px",
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.2)",
      background: "#fff",
    },
    tipoRow: {
      ...row,
      textTransform: "capitalize",
    },
    userRow: {
      ...row,
      wordBreak: "break-all",
    },
    codigoRow: {
      ...row,
      wordBreak: "break-all",
    },
    materiaRow: {
      ...row,
      wordBreak: "break-all",
    },
    defaultCells: {
      ...header,
      width: "100%",
    },
    divTable: {
      overflow: "auto",
      maxHeight: 300,
      height: 300,
    },
    boxModal: {
      overflow: "auto",
    },
    tooltipCell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalConteudo: {
      padding: "0 40px 40px 40px",
      width: "80%",
      maxHeight: "70%",
      backgroundColor: "#ffffff",
      outline: "none",
      boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.5)",
      borderRadius: 16,
    },
    cabecalho: {
      display: "flex",
      marginTop: "16px",
      marginBottom: "16px",
      justifyContent: "space-between",
      alignItems: "center",
    },
    nome: {
      height: "32px",
      textAlign: "left",
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 500,
      letterSpacing: "normal",
      color: "#333333",
    },
    data: {
      height: "32px",
      textAlign: "right",
      fontFamily: "Roboto",
      fontSize: "14px",
      letterSpacing: "normal",
      color: "#333333",
    },
    tituloTexto: {
      position: "relative",
      fontSize: "18px",
      fontWeight: "500",
      letterSpacing: "-0.1px",
      color: "rgba(51, 50, 56, 0.84)",
    },
    fechar: {
      width: "24px",
      height: "24px",
      cursor: "pointer",
      objectFit: "contain",
    },
    divTootip: {
      display: "flex",
      flexDirection: "row",
      marginRight: 3,
    },
  };
};

export default withStyles(styles)(IngredientRegisterHistoricModal);
