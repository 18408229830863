import React from "react";
import moment from "moment";
import "moment/locale/pt-br";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link, TableSortLabel, withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

export const movimentacaoTabela = ({
  classes,
  confirmaMovimentacao,
  habilitarEdicao,
  movimentacoes,
  montaTextoMovimentacao,
  order,
  orderBy,
  handleRequestSort
}) => {
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  return (
    <div>
      <Table id="#tabelaMovimentacao" className={classes.table}>
        <TableHead>
          <TableRow style={{ backgroundColor: "#e1e1e1", justifyContent: "space-between" }}>
            <TableCell className={classes.nomeCategoriaHead}>
              CATEGORIA
            </TableCell>
            <TableCell className={classes.tableCellHead}>
              MOVIMENTAÇÃO
            </TableCell>
            <TableCell className={classes.tableCellHead}>QUANTIDADE</TableCell>
            <TableCell
              id="ordenar"
              className={classes.tableCellHeadData}
              sortDirection={orderBy === "DataRegistro" ? order : false}
            >
              <TableSortLabel
                active={orderBy === "DataRegistro"}
                onClick={createSortHandler("DataRegistro")}
                direction={orderBy === "DataRegistro" ? order : "asc"}
              >
                <span>
                  DATA
                </span>
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableCellHeadTipo}>TIPO</TableCell>
            <TableCell className={classes.tableCellHead}>PESO PESADO</TableCell>
            <TableCell className={classes.tableCellHeadRendimento}>
              RENDIMENTO DE CARCAÇA
            </TableCell>
            <TableCell className={classes.acaoHead}>AÇÃO</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {movimentacoes.map((m) => (
            <React.Fragment>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.nomeCategoria}
                >
                  {m.CategoriaAnimal}
                </TableCell>
                <TableCell>
                  {m.TipoRegistro === 1 ? (
                    <div className={classes.tipoRegistroCell}>
                      <FiberManualRecordIcon className={classes.entradaIcon} />
                      <Typography className={classes.movimentacao}>
                        {" "}
                        Entrada{" "}
                      </Typography>
                    </div>
                  ) : (
                    <div className={classes.tipoRegistroCell}>
                      <FiberManualRecordIcon className={classes.saidaIcon} />
                      <Typography className={classes.movimentacao}>
                        {" "}
                        Saída{" "}
                      </Typography>
                    </div>
                  )}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {m.Quantidade}
                </TableCell>
                <TableCell
                  id="colunaBodyDataRegistro"
                  padding="checkbox"
                  align="left"
                  style={{ paddingLeft: "1%" }}
                >
                  <Typography id="dataRegistro">
                    {moment.utc(m.DataRegistro).format("DD/MM/YYYY")}
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCellTipo}>
                  {montaTextoMovimentacao(m)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {m.PesoPesado ? m.PesoPesado + "kg" : "-"}
                </TableCell>
                <TableCell className={classes.tableCellRendimento}>
                  {m.RendimentoDeCarcaca ? m.RendimentoDeCarcaca + "%" : "-"}
                </TableCell>
                <TableCell className={classes.tableCellAcao}>
                  {m.Confirmado === false ? (
                    <Link
                      id="confirmarM"
                      component="button"
                      className={classes.linkAcao}
                      onClick={() => confirmaMovimentacao(m)}
                    >
                      CONFIRMAR
                    </Link>
                  ) : (
                    <Link
                      component="button"
                      className={classes.linkAcao}
                      onClick={() => habilitarEdicao(m)}
                    >
                      EDITAR
                    </Link>
                  )}
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const styles = (theme) => {
  return {
    campoData: {
      width: "90%",
      paddingTop: "15px",
      opacity: "0.9",
      fontSize: "8px",
    },
    celulaEditar: {
      textAlign: "left",
    },
    celulaLancaCompra: {
      textAlign: "left",
    },
    celulaExibir: {
      width: "20%",
      paddingLeft: "70px",
      textAlign: "left",
    },
    celulaNome: {
      paddingLeft: "40px",
    },
    confirmado: {
      backgroundColor: "#e5e5ea",
      boxShadow: "0 1px 3px 1px rgba(0, 0, 0, 0.1)",
    },
    corpoAdicionar: {
      width: "150%",
      padding: "20px",
      height: "122px",
      backgroundColor: "#f5f5f5",
      borderStyle: "solid",
      borderWidth: "1px 1px 1px 1px",
      borderColor: "#e5e5ea",
    },
    dateCell: {
      display: "flex",
      textAlign: "center",
      height: "56px",
    },
    dateHead: {
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "0.75rem",
      fontWeight: "500",
      fontFamily: "Roboto",
    },
    editado: {
      backgroundColor: "#ffffff",
      boxShadow: "0 1px 3px 1px rgba(0, 0, 0, 0.5)",
    },
    entradaIcon: {
      color: "#4fd26b",
      width: "20px",
      height: "20px",
      paddingRight: "4%",
    },
    exibir: {
      marginTop: "6px",
      position: "absolute",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.14",
      letterSpacing: "1.25px",
    },
    link: {
      width: "100%",
      textAlign: "center",
      paddingRight: "24px",
    },
    linkAcao: {
      fontFamily: "Roboto",
      fontWeight: "500",
    },
    pesoPesadoCell: {
      textAlign: "center",
      width: "100%",
    },
    saidaIcon: {
      color: "#e98e4f",
      width: "20px",
      height: "20px",
      paddingRight: "4%",
    },
    table: {
      width: "100%",
      marginTop: "20px",
    },
    tableCell: {
      textAlign: "left",
    },
    tableCellRendimento: {
      textAlign: "center",
    },
    tableCellTipo: {
      textAlign: "left",
      position: "relative",
    },
    nomeCategoria: {
      textAlign: "left",
    },
    nomeCategoriaHead: {
      textAlign: "left",
      fontSize: "14px",
      color: theme.palette.primary.main,
      borderRadius: "16px 0 0 0",
    },
    tableCellHead: {
      textAlign: "left",
      fontSize: "14px",
      color: theme.palette.primary.main,
    },
    acaoHead: {
      textAlign: "center",
      fontSize: "14px",
      color: theme.palette.primary.main,
      borderRadius: "0 16px 0 0 ",
    },
    tableCellAcao: {
      textAlign: "center",
    },
    tableCellHeadTipo: {
      textAlign: "left",
      fontSize: "14px",
      color: theme.palette.primary.main,
    },
    tableCellHeadData: {
      textAlign: "left",
      fontSize: "14px",
      color: theme.palette.primary.main,
      position: "relative",
      paddingLeft: "1%",
    },
    tableCellHeadRendimento: {
      textAlign: "center",
      fontSize: "14px",
      color: theme.palette.primary.main,
      position: "relative",
      paddingLeft: "1%",
    },
    tipoRegistroCell: {
      display: "flex",
      justifyContent: "left",
    },
    movimentacao: {
      width: 65,
    },
  };
};

export default withStyles(styles)(movimentacaoTabela);
