import React, { useEffect, useMemo } from "react";
import moment from "moment";
import GoalPlanFormPage from "./goalPlanFormPage";
import useGoalsTable from "features/goalPlan/hooks/useGoalsTable";
import { useGoalPlanDataForm } from "features/goalPlan/hooks/useGoalPlanDataForm";
import getValuesTable from "../getValuesTable";
import goalPlanService from "features/goalPlan/goalPlanService";
import { usePlanningValidations } from "features/goalPlan/hooks/usePlanningValidations";
import ConfirmationModal from "features/shared/components/confirmationModal";

const checkNotValues = (values) => {
  return (
    (values.value === null || values.value === undefined) &&
    (values.firstValue === null ||
      values.firstValue === undefined ||
      values.lastValue === null ||
      values.lastValue === undefined)
  );
};

const GoalPlanFormContainer = ({
  children,
  titlePage,
  titleContent,
  subtitleContent,
  onBack = () => {},
  onSubmit = () => {},
  enableLoading,
  disableLoading,
  notifyError,
  initialDataPlanId,
  disableEditTable = false,
  disableEditPlanInfo = false,
  textButtonSubmit,
  farmId,
}) => {
  const {
    indicators,
    goalsTableList,
    freeIndicators,
    addNewLine,
    onChangeIndicator,
    onChangeName,
    onChangeParameter,
    onDeleteLine,
    onChangeGoal,
    parameters,
    linesGoals,
    setLinesGoals,
  } = useGoalsTable(enableLoading, notifyError, disableLoading);

  const {
    goalPlanData,
    periodTitles,
    setGoalPlanData,
    changeGoalPlanName,
    changeGoalPlanInitialDate,
  } = useGoalPlanDataForm();

  const {
    setCurrentGoalPlan,
    alertOptions,
    modalSubmitActivePlanningPeriod,
    modalDatePickerActivePlanningPeriod: modalActivePlanningPeriod,
  } = usePlanningValidations({
    initialDataPlanId,
    goalPlanInitialDate: goalPlanData.initialDate,
    farmId,
    enableLoading,
    disableLoading,
    onChangeGoalPlanInitialDate: changeGoalPlanInitialDate,
  });

  const renderTable = periodTitles.length > 0;

  const shouldEnableButtonSubmit = useMemo(() => {
    if (
      !goalPlanData.name ||
      !goalPlanData.initialDate ||
      !goalPlanData.finalDate
    ) {
      return false;
    }
    if (linesGoals.length < 1) {
      return false;
    }
    let shouldEnableButton = true;
    linesGoals.map((goalLine) => {
      if (
        !goalLine.parameterName ||
        goalLine.parameterName === "selecione-parametro"
      ) {
        shouldEnableButton = false;
        return goalLine;
      }

      goalLine.values.map((goal) => {
        if (checkNotValues(goal)) {
          shouldEnableButton = false;
          return goal;
        }
        return goal;
      });
      return goalLine;
    });

    return shouldEnableButton;
  }, [goalPlanData, linesGoals]);

  const findPlan = async () => {
    if (!initialDataPlanId) return;
    try {
      enableLoading("findInitialPlan");
      const plan = await goalPlanService.getPlan(initialDataPlanId);
      setCurrentGoalPlan({
        id: plan.id,
        name: plan.name,
        farmId: plan.farmId,
        initialDate: plan.initialDate,
        finalDate: plan.finalDate,
      });
      setGoalPlanData({
        id: plan.id,
        name: plan.name,
        farmId: plan.farmId,
        initialDate: moment(plan.initialDate),
        finalDate: moment(plan.finalDate),
      });
      setLinesGoals(
        plan.goals.map((goal) => {
          delete goal.id;
          return goal;
        })
      );
    } catch (error) {
      notifyError(error);
    } finally {
      disableLoading("findInitialPlan");
    }
  };

  useEffect(() => {
    findPlan();
  }, [initialDataPlanId]);

  const handleOnChangeGoalPlanInitialDate = (value) => {
    changeGoalPlanInitialDate(value);
    modalActivePlanningPeriod.onChangeInitialDate();
  };

  const handleOnSubmit = () => {
    modalSubmitActivePlanningPeriod.setModalSubmitActivePlanningPeriod(false);
    onSubmit({ ...goalPlanData, linesGoals });
  };

  const intercepetSubmit = () => {
    if (modalSubmitActivePlanningPeriod.shouldInterceptSubmit) {
      modalSubmitActivePlanningPeriod.setModalSubmitActivePlanningPeriod(true);
    } else {
      handleOnSubmit();
    }
  };

  return (
    <GoalPlanFormPage
      titlePage={titlePage}
      titleContent={titleContent}
      subtitleContent={subtitleContent}
      goalPlanData={goalPlanData}
      goalsTableList={goalsTableList}
      columns={getValuesTable({
        indicators,
        addNewLine,
        freeIndicators,
        onChangeIndicator,
        onChangeName,
        onChangeParameter,
        parameters,
        periodTitles,
        onChangeGoal,
        onDelete: onDeleteLine,
        disabled: disableEditTable,
      })}
      onBack={onBack}
      onSubmit={intercepetSubmit}
      onChangeName={changeGoalPlanName}
      onChangeInitialDate={handleOnChangeGoalPlanInitialDate}
      renderTable={renderTable}
      enableButtonSubmit={shouldEnableButtonSubmit}
      disableEditPlanInfo={disableEditPlanInfo}
      textButtonSubmit={textButtonSubmit}
      onClickInitialDate={modalActivePlanningPeriod.openModal}
      interceptDatePicker={modalActivePlanningPeriod.interceptDatePicker}
      showDatePicker={modalActivePlanningPeriod.showDatePicker}
      setShowDatePicker={modalActivePlanningPeriod.setShowDatePicker}
      alertOptions={alertOptions}
    >
      {modalSubmitActivePlanningPeriod.modalSubmitActivePlanningPeriod && (
        <ConfirmationModal
          title="Editar meta"
          text={modalSubmitActivePlanningPeriod.getSubmitActivePlanningMessage()}
          submitButtonTitle="Sim, salvar"
          onSubmit={handleOnSubmit}
          onCancel={modalSubmitActivePlanningPeriod.onCancelSubmit}
        />
      )}

      {modalActivePlanningPeriod.modalPlanningDateSuggest && (
        <ConfirmationModal
          title="Novo plano de metas"
          text={modalActivePlanningPeriod.getActivePlanningPeriodMessage()}
          cancelButtonTitle="Não utilizar data"
          submitButtonTitle="Sim, utilizar data"
          onSubmit={modalActivePlanningPeriod.onConfirmPlanningPeriodDate}
          onCancel={modalActivePlanningPeriod.onCancelPlanningPeriodDate}
        />
      )}
      {children}
    </GoalPlanFormPage>
  );
};

export default GoalPlanFormContainer;
