import React from "react";
import { OutlinedInput, withStyles } from "@material-ui/core";
import { checkNullOrUndefined } from "features/goalPlan/goalPlanUtils";

const DefaultInput = ({
  testId,
  classes,
  type,
  value,
  disabled,
  placeholder,
  onChange,
  textAlign,
  fullWidth = false,
}) => {
  return (
    <OutlinedInput
      data-testid={testId}
      classes={{
        input: classes.innerInput,
        disabled: classes.disabled,
        root: textAlign === "center" && classes.rootCenter,
      }}
      inputProps={{
        size: fullWidth && checkNullOrUndefined(value) && String(value).length,
        style: {
          width: fullWidth && "max-content",
          textAlign,
        },
      }}
      className={classes.inputInTable}
      type={type}
      value={value}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
      variant="outlined"
    />
  );
};

const styles = (theme) => ({
  inputInTable: {
    width: 179,
    fontSize: "0.75rem",
    height: 53,
    "&>fieldset": {
      borderRadius: "4px",
      border: `solid 1px ${theme.palette.default2.light}`,
    },
    alignSelf: "center",
  },
  rootCenter: {
    justifyContent: "center",
    width: 179,
  },
  innerInput: {
    boxSizing: "border-box",
    borderRadius: 4,
    width: 179,
  },
  disabled: {
    height: 42,
    borderRadius: "4px",
    color: theme.palette.default1.light,
    backgroundColor: theme.palette.disabled.light,
    fontSize: "0.75rem",
    width: 179,
  },
});

export default withStyles(styles)(DefaultInput);
