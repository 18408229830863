import React from "react";
import {
  Button,
  Typography,
  TextField,
  FormControl,
  withStyles,
} from "@material-ui/core";
import SelectDropdown from "components/SelectDropdown";

const FormCadastroIngrediente = ({
  classes,
  listaIngredientes,
  ingredienteSelecionado,
  setIngredienteSelecionado,
  quantidade,
  botaoCadastroDesativado,
  adicionarIngrediente,
  setQuantidade,
}) => {
  return (
    <div>
      <Typography className={classes.tituloFormIngrediente}>
        ADICIONAR INGREDIENTES
      </Typography>
      <div className={classes.camposIngredientes}>
        <div className={classes.selectIngredientes}>
          <SelectDropdown
            id="ingredientes-select"
            label={{ text: "Nome do ingrediente" }}
            options={listaIngredientes}
            value={ingredienteSelecionado}
            onChange={setIngredienteSelecionado}
          />
        </div>
        <FormControl className={classes.inputQuantidade}>
          <TextField
            id="quantidade-input"
            InputLabelProps={{ shrink: true }}
            label={"Quantidade (g/Cab/dia)*"}
            type="number"
            onChange={({ target: { value } }) => setQuantidade(value)}
            value={quantidade}
          />
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          className={classes.botaoAdiciona}
          onClick={adicionarIngrediente}
          disabled={botaoCadastroDesativado}
        >
          ADICIONAR INGREDIENTE
        </Button>
      </div>
    </div>
  );
};

const styles = () => {
  return {
    tituloFormIngrediente: {
      fontSize: 12,
      letterSpacing: 0.5,
      color: "#666",
      fontWeight: 500,
      marginTop: 24,
    },
    camposIngredientes: {
      display: "flex",
      width: "100%",
      marginTop: 10,
      height: 100,
      alignItems: "center",
    },
    selectIngredientes: {
      maxWidth: 216,
      width: "100%",
      marginRight: 32,
    },
    inputQuantidade: {
      maxWidth: 200,
      width: "100%",
      marginRight: 32,
      marginTop: 7,
    },
    botaoAdiciona: {
      width: 220,
      height: 36,
      fontSize: 14,
      marginTop: 16,
      boxShadow: "none",
    },
  };
};

export default withStyles(styles)(FormCadastroIngrediente);
