import React from "react";
import { withStyles } from "@material-ui/core";
import history from "features/app/History";
import SelectDropdown from "components/SelectDropdown";

function MenuManejo({ classes, hideRegistrations }) {
  let opcoesMenu = {
    "/farol": {
      path: "/farol",
      nome: "Visualizar Faróis",
    },
    ...(hideRegistrations
      ? {}
      : {
        "/cadastro": {
          path: "/cadastro",
          nome: "Cadastrar Pastos",
        },
        "/vinculo": {
          path: "/vinculo",
          nome: "Gestão de Vínculos",
        },
      }),
  };

  let currentPath = opcoesMenu["/farol"].path;
  if (!hideRegistrations && history.location.pathname.includes("vinculo"))
    currentPath = opcoesMenu["/vinculo"].path;
  else if (!hideRegistrations && history.location.pathname.includes("cadastro"))
    currentPath = opcoesMenu["/cadastro"].path;

  const onChange = (value) => {
    history.push(value);
  };

  const opcoesMenuMapeadas = Object.entries(opcoesMenu).map(
    ([key, option]) => ({
      value: option.path,
      label: option.nome,
    })
  );

  return (
    <div className={classes.container}>
      <SelectDropdown
        options={opcoesMenuMapeadas}
        value={currentPath}
        onChange={onChange}
      />
    </div>
  );
}

const styles = () => ({
  container: {
    width: "100%",
    minHeight: "90px",
    padding: "25px 40px",
    backgroundColor: "#f3f8fe",
  },
  select: {
    borderRadius: "4px",
    border: "solid 1px #e6e6e6",
    backgroundColor: "#fff",
    width: "100%",
    padding: "8px 16px;",
  },
  input: {
    "&:focus": {
      backgroundColor: "#FFF",
    },
  },
  option: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "normal",
    color: "#222",
  },
});

export default withStyles(styles)(MenuManejo);
