import React from "react";
import { compose } from "recompose";
import { withRouter } from "react-router";
import {
  List,
  ListSubheader,
  Divider,
  Grid,
  withStyles,
} from "@material-ui/core";
import { LogoProdap } from "../../layout";
import CloseICon from "@material-ui/icons/Close";
import ItemMenuNavegacao from "./itemMenuNavegacao";
import { obterTokenAcessoFinanceiro } from "../../shared/utils";
import { redirecionaHistoryManual } from "../navegacaoUtils";
import MetricsService, { EventType } from "services/MetricsService";

export const MenuNavegacao = (props) => {
  const {
    classes,
    abreFechaMenu,
    exibirConfiguracaoFazenda,
    exibirFechamentoConsumo,
    exibirRelatorioTableau,
    exibirFinanceiro,
    exibirAnalise,
    fazendaSelecionada,
    exibirCadastroUsuario,
  } = props;
  const renderFuncionalidadesManejo = props.location.pathname !== "/analytics";
  const abriracessofinanceiro = async () => {
    const obterToken = await obterTokenAcessoFinanceiro(props.dadosState);
    if (obterToken) {
      window.open(
        process.env.REACT_APP_ENDPOINT_FINANCEIRO_TOKEN + obterToken.data.token,
        "_blank"
      );
    }
    props.abreFechaMenu();
  };

  const navegaParaFechementoConsumo = () => {
    if (!window.location.href.includes("fechamento_consumo")) {
      MetricsService.sendEvent({
        event: EventType.ACESSO_FECHAMENTO_CONSUMO,
        route: window.location.href,
      });
    }
    props.history.push("/fechamento_consumo");
  };

  return (
    <div className={classes.list}>
      <List>
        <ListSubheader>
          <Grid container direction="row" className={classes.heightConteudo}>
            <Grid
              lg={10}
              md={10}
              sm={10}
              container
              item
              direction="column"
              alignItems="flex-start"
              justify="center"
              className={classes.alturaTotal}
            >
              <LogoProdap />
            </Grid>
            <Grid
              lg={2}
              md={2}
              sm={2}
              container
              item
              direction="column"
              justify="flex-start"
              alignItems="flex-end"
              style={{ flexGrow: 1 }}
              className={[classes.iconeClose, classes.alturaTotal].join(" ")}
            >
              <CloseICon onClick={abreFechaMenu} id="iconFechaMenuNavegacao" />
            </Grid>
          </Grid>
        </ListSubheader>
        <Divider />
        <ItemMenuNavegacao
          icone="home"
          idComponent="home"
          navegar={() => redirecionaHistoryManual({ novaRota: "/farol" })}
          descricaoItem="Home"
        />
        {exibirAnalise ? (
          <ItemMenuNavegacao
            icone="insert_chart"
            idComponent="analytics"
            navegar={() => props.history.push("/analytics")}
            descricaoItem="Análises"
          />
        ) : null}
        {exibirFinanceiro ? (
          <ItemMenuNavegacao
            icone="attach_money"
            idComponent="Financeiro"
            navegar={() => abriracessofinanceiro()}
            descricaoItem="Financeiro"
          />
        ) : null}
        {exibirFechamentoConsumo && (
          <ItemMenuNavegacao
            icone="straighten"
            idComponent="menuItemFechamentoConsumo"
            navegar={navegaParaFechementoConsumo}
            descricaoItem="Fechamento de consumo"
          />
        )}
        {renderFuncionalidadesManejo && exibirConfiguracaoFazenda ? (
          <ItemMenuNavegacao
            icone="settings"
            idComponent="configuracoes"
            navegar={() =>
              props.history.push("/configuracoes") || window.location.reload()
            }
            descricaoItem="Cadastros da fazenda"
          />
        ) : null}
        {exibirCadastroUsuario ? (
          <ItemMenuNavegacao
            icone="group"
            idComponent="gestaoUsuarios"
            navegar={() =>
              props.history.push("/gestao-de-usuarios") ||
              window.location.reload()
            }
            descricaoItem="Cadastros de usuários"
          />
        ): null}
        {fazendaSelecionada && (
          <ItemMenuNavegacao
            icone="mobile_screen_share_outlined"
            idComponent="coletasDoAplicativo"
            navegar={() =>
              props.history.push("/coletas") || window.location.reload()
            }
            descricaoItem="Coletas do aplicativo"
          />
        )}
        {exibirRelatorioTableau ? (
          <ItemMenuNavegacao
            icone="assignment"
            idComponent="relatorioTableau"
            navegar={() => props.setModalTableau(true)}
            descricaoItem="Gerador de Relatórios"
          />
        ) : null}
        <ItemMenuNavegacao
          icone="device_hub"
          idComponent="controleDeRebanho"
          navegar={() => props.history.push("/controleDeRebanho")}
          descricaoItem="Controle de Rebanho"
        />
        <ItemMenuNavegacao
          icone="find_replace"
          idComponent="historicoMovimentacaoAppPage"
          navegar={() =>
            props.history.push("/historico_movimentacao_aplicativo")
          }
          descricaoItem="Movimentações de Rebanho"
        />
      </List>
    </div>
  );
};

const styles = {
  list: {
    width: 250,
  },
  alturaTotal: {
    flexGrow: 1,
  },
  heightConteudo: {
    height: 70,
  },
  iconeClose: {
    cursor: "pointer",
  },
};

export default compose(withRouter, withStyles(styles))(MenuNavegacao);
