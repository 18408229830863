import BaseService from "./BaseService";

const URL_BASE = `${process.env.REACT_APP_ENDPOINT}usuarios/api/v1/usuarios/`;
//const URL_BASE = 'http://localhost:3001/api/v1/usuarios/';
const URL_BASE_OBTER_TOKEN = `${process.env.REACT_APP_ENDPOINT}usuarios/api/v1/usuarios/tokenFinanceiro`;
//const URL_BASE_OBTER_TOKEN = 'http://localhost:3001/api/v1/usuarios/tokenFinanceiro';

class UsuarioService extends BaseService {
  async BuscarDadosUsuarioPorId(idUsuario) {
    const retorno = await this.get(URL_BASE + idUsuario);
    return retorno;
  }

  async obterTokenAcessoFinanceiro(requestToken) {
    const retorno = await this.post(URL_BASE_OBTER_TOKEN, requestToken);
    return retorno;
  }

  async obtemRoleUsuario(requestToken) {
    const retorno = await this.get(URL_BASE + `${requestToken}/roles`);
    return retorno;
  }

  async tokenCentralAjuda(idUsuario, liberadoSuporte = false) {
    const query = liberadoSuporte ? "?tags=suporte_liberado" : "";

    const url = `${URL_BASE}${idUsuario}/tokenCentralAjuda${query}`;

    const retorno = await this.get(url);
    return retorno;
  }
}

export default new UsuarioService();
