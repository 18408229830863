import { DADOS_USUARIO } from "../actions/actionsConstants";

export const initialState = {
  Usuario: null,
  erros: {
    Usuario: "",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DADOS_USUARIO:
      return { ...state, Usuario: action.payload.Usuario };
    default:
      return state;
  }
};
