import React from "react";
import { Modal, Button, withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export const ModalAlerta = (props) => {
  const { aoFechar, classes, textoDescricao } = props;

  return (
    <div className={classes.boxModal}>
      <Modal
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={true}
      >
        <div className={classes.modalContainer}>
          <div id="containerModalLote" className={classes.modalConteudo}>
            <Button
              id="botao-fechar-modal"
              className={classes.fechar}
              onClick={aoFechar}
            >
              <CloseIcon />
            </Button>

            <div
              item
              className={classes.data}
              dangerouslySetInnerHTML={{ __html: textoDescricao }}
            ></div>

            <Button
              id="botao-sair-modal"
              className={classes.sair}
              onClick={aoFechar}
            >
              Sair
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const styles = (theme) => {
  return {
    modal: {
      zIndex: "0",
    },
    modalContainer: {
      display: "flex",
      height: "100%",
      width: "100%",
    },
    modalConteudo: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      width: "40%",
      height: "auto",
      backgroundColor: "#ffffff",
      outline: "none",
      boxShadow: "0 2px 20px 0 rgba(0, 0, 0, 0.5)",
      borderRadius: "3px",
    },
    data: {
      fontFamily: "Roboto",
      padding: "0px 30px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.5",
      letterSpacing: "0.2px",
      fontSize: "18px",
      fontWeight: "normal",
      color: "rgba(51, 50, 56, 0.84)",
      textAlign: "inherit",
    },
    fechar: {
      minWidth: "auto",
      alignSelf: "flex-end",
      margin: "3px",
    },
    sair: {
      fontSize: "18px",
      margin: "40px 0px 20px 0px",
      alignSelf: "center",
      color: theme.palette.primary.main,
    },
  };
};

export default withStyles(styles)(ModalAlerta);
