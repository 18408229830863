import React from "react";
import { withStyles } from "@material-ui/core";
import styles from "./styles";
import auth from "../authentication/Auth";
import history from "../app/History";

function PaginaInicial({ classes }) {
  const handleLogin = () => {
    if (auth.isAuthenticated()) {
      history.replace("/farol");
    } else {
      history.replace("/login");
    }
  };

  setTimeout(handleLogin, 2000);

  return (
    <div className={classes.container}>
      <img
        src="../../images/logo-farmtell-views-color-4.png"
        alt="Logo FarmTell Views"
      />
    </div>
  );
}

export default withStyles(styles)(PaginaInicial);
