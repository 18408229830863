import { useCallback } from "react";
import { useCookies } from "react-cookie";

const getUserIdFromLocalStorage = () => window.localStorage.getItem("IdUsuario");

const NAME_COOKIE = "ftvnp";

const useCookNotification = () => {
  const [cookies, setCookie] = useCookies([NAME_COOKIE]);

  const getPendencie = useCallback((farmId) => {
    const userId = getUserIdFromLocalStorage();
    const ftvnpCookie = cookies[NAME_COOKIE] ? cookies[NAME_COOKIE] : [];

    if (!Array.isArray(ftvnpCookie)) {
      return null;
    }

    const item = ftvnpCookie.find(item => item.includes(`${userId}|${farmId}|`));
    if (item) {
      return item.split("|")[2];
    }
    return;
  }, [cookies]);

  const setPendencie = useCallback((farmId, data) => {
    const userId = getUserIdFromLocalStorage();
    if (userId === null || farmId === null || userId === undefined || farmId === undefined)
      return;

    const cookiesToSave = cookies[NAME_COOKIE] ? cookies[NAME_COOKIE] : [];

    if (!Array.isArray(cookiesToSave)) {
      setCookie(NAME_COOKIE, [`${userId}|${farmId}|${data}`]);
      return;
    }

    const index = cookiesToSave.findIndex(item => item.includes(`${userId}|${farmId}|`));

    if (index !== -1)
      cookiesToSave[index] = `${userId}|${farmId}|${data}`;
    else
      cookiesToSave.push(`${userId}|${farmId}|${data}`);

    setCookie(NAME_COOKIE, cookiesToSave);

  }, [cookies]);

  return [getPendencie, setPendencie];
}

export default useCookNotification;
