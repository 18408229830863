import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import styles from "styles/shared/LoadingStyle";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

export class Loading extends Component {
  render() {
    const { classes, loading } = this.props;

    const shouldShowLoading = Array.isArray(loading.ExibirCarregando)
      ? loading.ExibirCarregando.length > 0
      : loading.ExibirCarregando;

    return (
      <div
        className={classes.backdrop}
        style={{ display: shouldShowLoading ? "inherit" : "none" }}
      >
        <Paper
          className={classes.corpoCarregandoPequeno}
          elevation={1}
          style={{ borderRadius: 16 }}
        >
          <img
            id="logoFarmTellAppBar"
            src="/images/logo-farmtell-views-color-2.png"
            alt="logo farmtell views"
            className={classes.logo}
          />
          <React.Fragment>
            <div>
              <Typography
                variant="h6"
                component="h6"
                className={classes.corpoFormulario}
              >
                Carregando...
              </Typography>
            </div>
          </React.Fragment>
        </Paper>
      </div>
    );
  }
}
Loading.propTypes = {
  loading: PropTypes.object.isRequired,
};
export default withStyles(styles)(Loading);
