import dayjs from "dayjs";
import planningService from "features/planning/planningService";
import React, { useEffect, useMemo, useState } from "react";
import localConstants from "../views/shared/constants";
import { InfoOutlined } from "@mui/icons-material";
import goalPlanService from "../goalPlanService";
import { isLoggedUserInternal } from "../../shared/utils";
import { palette } from "styles/theme";

export const usePlanningValidations = ({
  initialDataPlanId,
  goalPlanInitialDate,
  farmId,
  enableLoading,
  disableLoading,
  onChangeGoalPlanInitialDate,
}) => {
  const [currentGoalPlan, setCurrentGoalPlan] = useState({
    initialDate: null,
    finalDate: null,
  });
  const [alertOptions, setAlertOptions] = useState(null);
  const [modalPlanningDateSuggest, setModalPlanningDateSuggest] =
    useState(false);
  const [modalSubmitActivePlanningPeriod, setModalSubmitActivePlanningPeriod] =
    useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [activePlanningPeriod, setActivePlanningPeriod] = useState({
    initialDate: null,
    finalDate: null,
  });
  const [hasSynchedGoal, setHasSynchedGoal] = useState(false);

  const compareDateForPlanning = (a, b) =>
    dayjs(a)
      .startOf("day")
      .startOf("month")
      .isSame(dayjs(b).startOf("day").startOf("month"));

  const isActive = (goal) => {
    const today = new Date();
    const { initialDate, finalDate } = goal;
    return today >= new Date(initialDate) && today <= new Date(finalDate);
  };

  const checkErrorAlert = () => {
    if (activePlanningPeriod.initialDate && goalPlanInitialDate) {
      if (
        dayjs(activePlanningPeriod.initialDate).format("YYYY-MM-DD") !==
        dayjs(goalPlanInitialDate).set("date", 1).format("YYYY-MM-DD")
      ) {
        setAlertOptions({
          type: "error",
          icon: <InfoOutlined color="error" />,
          message: (
            <div>
              <div>
                <strong>
                  O período do plano de metas não está sincronizado com o
                  período da evolução de rebanho e plano de nutrição
                </strong>
              </div>
              <div>
                Para sincronizá-los novamente, por favor, defina a mesma data
                para ambos.
              </div>
            </div>
          ),
        });
      } else {
        setAlertOptions(null);
      }
    }
  };

  useEffect(() => {
    checkErrorAlert();
  }, [goalPlanInitialDate, activePlanningPeriod]);


  const getActivePlanningPeriod = async () => {
    if (!isLoggedUserInternal()) return;

    try {
      enableLoading();
      const initialDate = dayjs().toISOString();
      const finalDate = dayjs().add(1, "day").toISOString();
      const { data: result } = await planningService.findPlanByPeriod(
        farmId,
        initialDate,
        finalDate
      );

      if (result) {
        setActivePlanningPeriod({
          initialDate: result.initialDate,
          finalDate: result.finalDate,
        });
        getActiveGoalPeriod(result.initialDate, result.finalDate);
      }
    } catch {
    } finally {
      disableLoading();
    }
  };

  const getActiveGoalPeriod = async (goalInitialDate, goalFinalDate) => {
    try {
      const initialDate = dayjs(goalInitialDate)
        .startOf("day")
        .startOf("month")
        .format("YYYY-MM-DD");
      const finalDate = dayjs(goalFinalDate)
        .startOf("day")
        .startOf("month")
        .format("YYYY-MM-DD");

      enableLoading();
      const { data: result } = await goalPlanService.findPlanByPeriod(
        farmId,
        initialDate,
        finalDate
      );
      if (result && result.length) {
        const activeGoalPlan = result.find(isActive);
        if (
          activeGoalPlan &&
          compareDateForPlanning(activeGoalPlan.initialDate, initialDate) &&
          compareDateForPlanning(activeGoalPlan.finalDate, finalDate)
        ) {
          setHasSynchedGoal(true);
        }
      }
    } catch {
    } finally {
      disableLoading();
    }
  };

  useEffect(() => {
    getActivePlanningPeriod();
  }, []);

  const getActivePlanningPeriodMessage = () => {
    const formattedInitialDate = dayjs(activePlanningPeriod.initialDate).format(
      localConstants.dateShortFormat
    );
    const formattedFinalDate = dayjs(activePlanningPeriod.finalDate).format(
      localConstants.dateShortFormat
    );
    return (
      <>
        Verifiquei que você possui um planejamento ativo, com data de{" "}
        <strong style={{ color: palette.primary.main }}>
          {formattedInitialDate} a {formattedFinalDate}
        </strong>
        . Deseja utilizar a mesma data para o novo plano de metas e mantê-los
        sincronizados?
      </>
    );
  };

  const onConfirmPlanningPeriodDate = () => {
    setShowDatePicker(false);
    onChangeGoalPlanInitialDate(activePlanningPeriod.initialDate);
    setModalPlanningDateSuggest(false);
  };

  const onCancelPlanningPeriodDate = () => {
    setModalPlanningDateSuggest(false);
    setShowDatePicker(true);
  };

  const openModal = () => {
    if (interceptDatePicker) setModalPlanningDateSuggest(true);
    else setShowDatePicker(true);
  };

  const onChangeInitialDate = () => {
    if (showDatePicker) {
      setShowDatePicker(false);
    }
  };

  const getSubmitActivePlanningMessage = () => (
    <span>
      Verificamos que você modificou o período na meta. Por essa razão, ele{" "}
      <strong>
        não estará mais sincronizado com o plenjamento da fazenda.
      </strong>{" "}
      Você tem certeza de que deseja salvar essas alterações?
    </span>
  );

  const onCancelSubmit = () => {
    setModalSubmitActivePlanningPeriod(false);
  };

  const shouldInterceptSubmit = useMemo(
    () =>
      isLoggedUserInternal() &&
      initialDataPlanId &&
      activePlanningPeriod.initialDate != null &&
      currentGoalPlan.initialDate != null &&
      compareDateForPlanning(
        currentGoalPlan.initialDate,
        activePlanningPeriod.initialDate
      ) &&
      !compareDateForPlanning(goalPlanInitialDate, currentGoalPlan.initialDate),
    [
      goalPlanInitialDate,
      activePlanningPeriod.initialDate,
      currentGoalPlan.initialDate,
    ]
  );

  const interceptDatePicker = useMemo(() => {
    if (hasSynchedGoal) return false;

    if (initialDataPlanId)
      return (
        isLoggedUserInternal() &&
        activePlanningPeriod !== null &&
        activePlanningPeriod.initialDate !== null &&
        goalPlanInitialDate &&
        !compareDateForPlanning(
          goalPlanInitialDate,
          activePlanningPeriod.initialDate
        )
      );

    return (
      isLoggedUserInternal() &&
      activePlanningPeriod !== null &&
      activePlanningPeriod.initialDate !== null
    );
  }, [
    hasSynchedGoal,
    goalPlanInitialDate,
    activePlanningPeriod.initialDate,
    currentGoalPlan.initialDate,
  ]);

  return {
    setCurrentGoalPlan,
    alertOptions,
    activePlanningPeriod,
    modalSubmitActivePlanningPeriod: {
      shouldInterceptSubmit,
      modalSubmitActivePlanningPeriod,
      setModalSubmitActivePlanningPeriod,
      getSubmitActivePlanningMessage,
      onCancelSubmit,
    },
    modalDatePickerActivePlanningPeriod: {
      modalPlanningDateSuggest,
      onChangeInitialDate,
      openModal,
      getActivePlanningPeriodMessage,
      onConfirmPlanningPeriodDate,
      onCancelPlanningPeriodDate,
      interceptDatePicker,
      showDatePicker,
      setShowDatePicker,
    },
  };
};
