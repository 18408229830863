export const tableStyles = (theme) => ({
  tableContainer: {
    borderRadius: "4px",
  },
  tableTitle: {
    margin: "0px",
    color: "#333",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
  },

  tableDisclaimer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "12px",
    color: "#212121",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    gap: "4px",
    "& svg": {
      width: "20px",
      height: "20px",
      alignSelf: "center",
      fill: "#BDBDBD",
    },
  },

  cellItemBold: {
    color: "#333",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  },

  tableContent: {
    "& > :first-child": {
      maxHeight: "unset",
    },
    "& table": {
      minHeight: "unset",
      background: "unset",

      "& tr ": {
        height: "36px",
      },
      "& tr:hover ": {
        backgroundColor: "inherit !important",
      },
      "& tr th:first-of-type, & tr td:first-of-type": {
        borderTopLeftRadius: "4px",
        borderBottomLeftRadius: "4px",
      },
      "& tr th:last-child, & tr td:last-child": {
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
      },
      "& tr:nth-child(even)": {
        background: "#F2F5F7",
      },
      "& tr:nth-child(even):hover": {
        background: "#F2F5F7  !important",
      },

      "& th": {
        padding: "8px !important",
        backgroundColor: "#F2F5F7",
        textTransform: "capitalize",
        color: theme.palette.primary.main,
        fontFamily: "Roboto",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
      },

      "& td": {
        width: "67px",
        padding: "16px 8px !important",
      },

      "& td, th": {
        border: "unset",
        textAlign: "unset",
      },
    },
  },
});
