import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import AppContainer from "./features/app/AppContainer";
import reducers from "./redux/reducers";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/pt-br";
import Notificacao from "features/shared/components/Notificacao";
import Loading from "features/shared/components/Loading/LoadingContainer";
import LoadingSalvando from "features/shared/components/LoadingSalvando/LoadingSalvandoContainer";
import { NotificacaoContainer } from "./features/notificacao";
import { checaESalvaLocalstorageProps } from "./shared/utils";
import News from "features/shared/components/News";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { CookiesProvider } from "react-cookie";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk, checaESalvaLocalstorageProps))
);
const locale = "pt-BR";
moment.locale(locale);
dayjs.locale(locale);

ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider defaultSetOptions={{ path: '/' }} >
      <MuiPickersUtilsProvider
        utils={MomentUtils}
        locale={locale}
        moment={moment}
      >
        <Loading />
        <LoadingSalvando />
        <AppContainer />
        <Notificacao />
        <NotificacaoContainer />
        <News />
      </MuiPickersUtilsProvider>
    </CookiesProvider>
  </Provider>,
  document.getElementById("root")
);
