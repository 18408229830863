import React from "react";
import {
  Paper,
  TableBody,
  TableCell,
  Table,
  TableHead,
  TableRow,
  IconButton,
  withStyles,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Create";
import SwitchCustomizado from "features/shared/components/SwitchCustomizado";

export const objetivoTabela = (props) => {
  const {
    classes,
    objetivosFiltrados,
    ativaDesativaObjetivo,
    habilitarEdicao,
    adicionando,
    exibindo,
  } = props;

  return (
    <Paper className={classes.objetivoTabelaWrapper}>
      <div className={classes.divTable}>
        <Table id="#tabelaObjetivo" className={classes.table}>
          <TableHead>
            <TableRow className={classes.objetivoTabelaHeader}>
              <TableCell className={classes.headerCelulaNome}>
                Objetivo de Lote
              </TableCell>
              <TableCell className={classes.headerCelulaEditar}>
                Editar
              </TableCell>
              <TableCell className={classes.headerCelulaAtivo}>Ativo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {objetivosFiltrados.map((o) => (
              <React.Fragment>
                <TableRow classes={classes.objetivoTabelaRow}>
                  <TableCell className={classes.celulaNome}>{o.Nome}</TableCell>
                  <TableCell className={classes.celulaEditar}>
                    <IconButton
                      id="botaoHabilitar"
                      aria-label="Edit"
                      onClick={() => habilitarEdicao(o)}
                      disabled={adicionando || exibindo}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell className={classes.celulaEditar}>
                    <SwitchCustomizado
                      id={`switch-${o.IdObjetivo}`}
                      classes={classes.switch}
                      defaultChecked={!o.Excluido}
                      checked={!o.Excluido}
                      handleClick={() =>
                        ativaDesativaObjetivo(o.IdObjetivo, o.Excluido)
                      }
                    />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

const styles = (theme) => {
  return {
    objetivoTabelaWrapper: {
      width: "100%",
      marginTop: "20px",
      marginBottom: "60px",
      overflow: "hidden",
      borderRadius: "16px",
    },
    table: {
      width: "100%",
    },
    objetivoTabelaHeader: {
      backgroundColor: "#e1e1e1",
      height: 56,
    },
    objetivoTabelaRow: {
      height: 56,
    },
    celulaNome: {
      paddingLeft: "40px",
      fontWeight: "500",
      textTransform: "uppercase",
      color: "#757575",
    },
    headerCelulaNome: {
      paddingLeft: "40px",
      fontWeight: "500",
      fontFamily: "Roboto",
      fontSize: 14,
      textTransform: "uppercase",
      top: 0,
      position: "sticky",
      zIndex: 10,
      color: theme.palette.primary.main,
      backgroundColor: "#e1e1e1",
    },
    celulaAtivo: {
      textAlign: "right",
      paddingRight: "68px",
    },
    headerCelulaEditar: {
      textAlign: "right",
      paddingRight: "68px",
      fontWeight: "500",
      fontFamily: "Roboto",
      fontSize: 14,
      textTransform: "uppercase",
      top: 0,
      position: "sticky",
      zIndex: 10,
      color: theme.palette.primary.main,
      backgroundColor: "#e1e1e1",
    },
    celulaEditar: {
      textAlign: "right",
    },
    headerCelulaAtivo: {
      textAlign: "right",
      fontWeight: "500",
      fontFamily: "Roboto",
      fontSize: 14,
      textTransform: "uppercase",
      top: 0,
      position: "sticky",
      zIndex: 10,
      color: theme.palette.primary.main,
      backgroundColor: "#e1e1e1",
    },
    switch: {
      marginLeft: "80%",
    },
    divTable: {
      overflow: "auto",
      maxHeight: 600,
    },
  };
};

export default withStyles(styles)(objetivoTabela);
