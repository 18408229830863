const localConstants = {
  period: {
    monthly: "monthly",
    annual: "annual",
  },
  dateMonthFormat: "MMM/YY",
  dateShortFormat: "MM/YYYY",
  titles: {
    indicator: "Indicador",
    name: "Apelido do indicador",
    unit: "Unidade de medida",
    parameter: "Parâmetro",
    accumulated: "Acumulado",
    delete: "Deletar",
  },
  parametersTranslated: {
    below: "Abaixo",
    above: "Acima",
    between: "Entre",
  },
  tooltipMessage:
    "Parâmetro é o que o usuário considera um BOM parâmetro para sua fazenda.",
  monthDiff: 12,
  situation: {
    PAST: "PAST",
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    FUTURE: "FUTURE"
  }
};

export default localConstants;
