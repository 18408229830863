import React, { Component } from "react";
import { GetApp } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import { Grid, Paper, Modal, Button, withStyles } from "@material-ui/core";
import CloseICon from "@material-ui/icons/Close";
import { TableauLoading } from "../tableauLoading";
import RelatorioItem from "./relatorioItem";
import {
  dataMinimaRelatorios,
  dataMinimaRelatorioEspecifico,
} from "../../../../constants/datasMinimas";

export class TableauReportFormv2 extends Component {
  state = {
    itemAtivado: -1,
  };

  activateItem = (index, text) => {
    this.props.alteraTipoRelatorio(text);
    this.setState({ itemAtivado: index });
  };

  deactivateItem = () => {
    this.props.desativaPeriodoCompleto();
    this.setState({ itemAtivado: -1 });
  };

  componentWillUnmount() {
    this.props.handleClose();
  }

  render() {
    const { classes, relatorios, downloadDisable, enableDownload } = this.props;
    const relatoriosOrdenados = relatorios.sort((a, b) =>
      a.Nome > b.Nome ? 1 : b.Nome > a.Nome ? -1 : 0
    );
    const imagem = "../images/" + this.props.imagem;
    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={!!this.props.modalAberto}
        onBackdropClick={
          this.props.opacity === 1 ? this.props.handleClose : null
        }
        disableBackdropClick={true}
        onClickAway={this.props.opacity === 1 ? this.props.handleClose : null}
        className={classes.modalGeral}
      >
        <Grid
          container
          spacing={0}
          justify="space-evenly"
          alignItems="flex-start"
          className={classes.modalContainer}
        >

          <Grid item xs={12} className={classes.header}>
            <div className={classes.horizontalGrowth}>
              <Typography variant="h6" id="modal-title">
                <img
                  src={imagem}
                  alt="logo prodap"
                  className={classes.imagem}
                />
              </Typography>
              <div style={{ padding: "2rem 0" }}>
                <div className={classes.verticalGrowth}>
                  <Typography
                    variant="h6"
                    id="modal-title"
                    className={classes.headerTitle}
                  >
                    Relatórios
                  </Typography>
                  <span className={classes.headerSubtitle}>
                    Receba as informações da sua fazenda no período desejado.
                  </span>
                </div>
              </div>
              <Grid item xs={1}>
                <CloseICon
                  className={classes.fechar}
                  id="btnFechar"
                  onClick={this.props.handleClose}
                />
              </Grid>
            </div>
          </Grid>

          <TableauLoading classes={classes} opacity={this.props.opacity} />

          {this.props.opacity === 1 && (
            <>
              <Grid
                item
                xs={12}
                style={{ opacity: this.props.opacity }}
                className={classes.body}
              >
                <Paper>
                  {relatoriosOrdenados.map((elem, index) => {
                    return (
                      <RelatorioItem
                        enableDownload={enableDownload}
                        key={`relatorioItem-${index}`}
                        checked={index === this.state.itemAtivado}
                        text={elem.Nome}
                        handleChangeInicio={this.props.handleChangeInicio}
                        selecionaRetiro={this.props.selecionaRetiro}
                        filtroRetiro={elem.FiltroRetiro}
                        filtroData={elem.FiltroData}
                        periodoCompleto={this.props.periodoCompleto}
                        valor={elem.Valor}
                        indexItem={index}
                        ativaItem={this.activateItem}
                        dataFinal={this.props.valFinal}
                        dataInicial={this.props.valInicial}
                        desativaItem={this.deactivateItem}
                        handleChangeFinal={this.props.handleChangeFinal}
                        setPeriodoCompleto={this.props.setPeriodoCompleto}
                        conteudoDetalhado={index === this.state.itemAtivado}
                        retiros={this.props.retiros}
                        retiroSelecionado={this.props.retiroSelecionado}
                        minDate={
                          dataMinimaRelatorioEspecifico[elem.Valor] ||
                          dataMinimaRelatorios
                        }
                      />
                    );
                  })}
                </Paper>
              </Grid>

              <Grid
                item
                xs={12}
                style={{ opacity: this.props.opacity }}
                className={classes.footerModal}
              >
                <Button
                  disabled={downloadDisable || this.state.itemAtivado < 0}
                  variant="contained"
                  color={"primary"}
                  className={classes.botaoBaixar}
                  id="btnBaixar"
                  onClick={() => this.props.botaoBaixar()}
                >
                  <GetApp style={{ fontSize: 22, marginRight: 10 }} />
                  Baixar
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Modal>
    );
  }
}
const styles = (theme) => {
  return {
    modalContainer: {
      height: "100%",
    },
    divImg: {
    },
    divGif: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    gif: {
    },
    gifText: {
    },
    modalGeral: {
      margin: "auto",
      width: "600px",
      height: "516px",
      borderRadius: "2.4",
      backgroundColor: "#ffffff",
      ontFamily: "Roboto",
      color: "#212121",
    },
    botao: {
      position: "absolute",
      bottom: "40px",
      right: "40px",
    },
    fechar: {
      position: "absolute",
      width: "24px",
      height: "24px",
      right: "20px",
      top: "20px",
      cursor: "pointer",
      color: "#616161",
    },
    imagem: {
      padding: "2rem",
    },
    header: {
      position: "relative",
      padding: "auto 10%",
      width: "612px",
      height: "116px",
      maxHeight: "116px",
      backgroundColor: "#e1e1e1",
    },
    verticalGrowth: {
      display: "flex",
      flexDirection: "column",
    },
    horizontalGrowth: {
      display: "flex",
      flexDirection: "row",
    },
    headerTitle: {
      fontSize: 22,
      fontWeight: "500",
      fontFamily: "Roboto",
      color: theme.palette.primary.main,
    },
    headerSubtitle: {
      fontFamily: "Roboto",
      fontSize: 16,
      color: theme.palette.primary.main,
    },
    body: {
      width: "100%",
      height: "318px",
      maxHeight: "318px",
      overflow: "auto",
      backgroundColor: "#ffffff",
      "&::-webkit-scrollbar": {
        width: 4,
        borderRadius: 2,
      },
    },
    footerModal: {
      width: "100%",
      height: "82px",
      backgroundColor: "#ffffff",
      border: "solid 1px #eeeeee",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    botaoBaixar: {
      borderRadius: "18px",
      width: 110,
      height: 36,
      marginRight: 24,
    },
  };
};

export default withStyles(styles)(TableauReportFormv2);
