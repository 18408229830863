import React from "react";
import { connect } from "react-redux";
import ConciliacaoPage from "./conciliacaoPage";
import * as actions from "redux/actions";
import * as ConciliacaoOperations from "../redux/conciliacaoOperations";
import { obterCategoriasFazenda } from "../../categoria/redux/categoriaOperations";
import { deveExibirConciliacao } from "../../shared/utils";
import { montarTextoMapaRebanhoGA } from "../../../shared/utils";

export const ConciliacaoContainer = (props) => {
  const {
    idFazendaSelecionada,
    obterConciliacoes,
    conciliacoesFazenda,
    obterCategorias,
    categoriasFazenda,
    obterDetalhesCategoria,
    arrayEntradaSaida,
    loading,
    operacoesCategoria,
    listaMorteNascimento,
    dataInicioConciliacao,
    exibirConciliacao,
    fazenda,
  } = props;
  enviarDadosGA(idFazendaSelecionada);
  return (
    <ConciliacaoPage
      idFazendaSelecionada={idFazendaSelecionada}
      obterConciliacoes={obterConciliacoes}
      conciliacoesFazenda={conciliacoesFazenda}
      obterCategorias={obterCategorias}
      categoriasAnimais={categoriasFazenda}
      fazenda={fazenda}
      loading={loading}
      obterDetalhesCategoria={obterDetalhesCategoria}
      arrayEntradaSaida={arrayEntradaSaida}
      operacoesCategoria={operacoesCategoria}
      listaMorteNascimento={listaMorteNascimento}
      dataInicioConciliacao={dataInicioConciliacao}
      exibirConciliacao={exibirConciliacao}
    />
  );
};

const enviarDadosGA = (idFazenda) => {
  montarTextoMapaRebanhoGA(idFazenda, "Conciliação");
};

const mapStateToProps = (state) => {
  var exibirConciliacao = deveExibirConciliacao(state);
  return {
    idFazendaSelecionada: state.fazenda.FazendaSelecionada,
    fazenda: state.fazenda,
    dataInicioConciliacao: state.fazenda.DataInicioConciliacao,
    conciliacoesFazenda: state.conciliacao.Conciliacoes,
    categoriasFazenda: state.categoria.categorias,
    arrayEntradaSaida: state.conciliacao.ArrayEntradaSaida,
    operacoesCategoria: state.conciliacao.OperacoesCategoria,
    exibirConciliacao: exibirConciliacao,
    listaMorteNascimento: state.conciliacao.OperacoesMorteNascimento,
  };
};

const mapDispatchToProps = {
  obterConciliacoes: ConciliacaoOperations.obterConciliacoes,
  obterCategorias: obterCategoriasFazenda,
  obterDetalhesCategoria: ConciliacaoOperations.obterDetalhesCategoria,
  loading: actions.exibirCarregandoSalvando,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConciliacaoContainer);
