import { EXIBIR_CARREGANDO } from "../../../redux/actions/actionsConstants";
export const LISTA_CATEGORIAS = "LISTA_CATEGORIAS";
export const LISTA_CURVAS = "LISTA_CURVAS";
export const SALVAR_CATEGORIA = "SALVAR_CATEGORIA";
export const EXCLUIR_CATEGORIA = "EXCLUIR_CATEGORIA";
export const DESEXCLUIR_CATEGORIA = "DESEXCLUIR_CATEGORIA";
export const EDITAR_CATEGORIA = "EDITAR_CATEGORIA";

export const listarCategoriasFazenda = (categorias) => {
  return {
    type: LISTA_CATEGORIAS,
    payload: {
      CategoriasFazenda: categorias,
    },
  };
};

export const salvarCategoria = (categoria) => {
  return {
    type: SALVAR_CATEGORIA,
    payload: categoria,
  };
};

export const excluirCategoria = () => {
  return {
    type: EXCLUIR_CATEGORIA,
  };
};

export const desexcluirCategoria = () => {
  return {
    type: DESEXCLUIR_CATEGORIA,
  };
};

export const editarCategoria = () => {
  return {
    type: EDITAR_CATEGORIA,
  };
};

export const exibirCarregando = (exibir) => {
  return {
    type: EXIBIR_CARREGANDO,
    payload: exibir,
  };
};

export const listarCurvasGanhoPeso = (curvas) => {
  return {
    type: LISTA_CURVAS,
    payload: {
      CurvasGanhoPeso: curvas,
    },
  };
};
