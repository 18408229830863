import React, { Component } from 'react';
import * as Tableau from 'tableau-api-js';

class ContainerRelatorio extends Component {
  state = {
    url: `https://tableau.prodapviews.com.br/trusted/${localStorage.getItem('ticketTableau')}/views/${this.props.url}`,
    ticket: localStorage.getItem('ticketTableau')
  };

  componentDidMount() {
    this.iniciarViz()
  }

  iniciarViz() {
    try {
      const vizUrl = this.state.url;
      const vizContainer = this.vizContainer;
      const opcoes = {
        hideTabs: false,
        toolbarPosition: "top",
        width: "100%",
        height: window.innerHeight * 0.95,
        ticket: this.state.ticket,
        embed: "yes",
      };
      const viz = new Tableau.Viz(vizContainer, vizUrl, opcoes);
      return viz;
    } catch (exception) {
      console.log(`Erro ao instanciar view tableau embedded: ${exception}`);
    }
  }

  render() {
    return (
      <div ref={(div) => { this.vizContainer = div }} />
    )
  }
}

export default ContainerRelatorio;
