import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import {
  Radio,
  Grid,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  FormControl,
  ListItem,
  Typography,
  withStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import DropdownTipoAfericaoContainer from "./DropdownTipoAfericaoContainer";
import styles from "../../../../../styles/MenuLateral/Farol";
import * as actions from "../../../../../redux/actions";
import shortid from "shortid";
import {
  convertStringBooleanToBoolean,
  enviaDadosGAPorRotulo,
} from "../../../../../shared/utils.js";
import { toggleAvisoConsultoria } from "../../../../avisoConsultoria";
import { idsFarois } from "../../../../../constants/idsFarois";
import { verificaToggleVisualizaGeolocalizacaoAlturaPasto } from "./FarolUtils";

const niveisELegendaEntradaESaidaAlturaForragem = {
  Acima: "Maior que 105%",
  Dentro: "95% - 105%",
  Abaixo: "85% - 94%",
  Crítico: "0% - 84%",
  legenda: {
    titulo: "Níveis de altura de forragem",
    texto: (filtro) => (
      <p>
        As faixas da legenda são definidas em percentual com base na{" "}
        <b>
          média dos valores da última coleta/valor de {filtro} cadastrado no
          ciclo de forragem.
        </b>
      </p>
    ),
  },
};
const legendasDetalhadasFarol = {
  [idsFarois.alturaPastoAlturaForragem]: {
    Entrada: {
      ...niveisELegendaEntradaESaidaAlturaForragem,
    },
    Saida: {
      ...niveisELegendaEntradaESaidaAlturaForragem,
    },
    Afericao: {
      Acima: "do esperado",
      Dentro: "do esperado",
      Abaixo: "do esperado",
      legenda: {
        titulo: "Níveis de altura de forragem",
        texto: () => (
          <p>
            Indica se a coleta de aferição realizada está abaixo, dentro ou
            acima do valor de referência de{" "}
            <b>entrada e saída cadastrado para a forragem.</b>
          </p>
        ),
      },
    },
  },
};

export function RadioFarol({
  classes,
  farol,
  farolSelecionado,
  filtroFarol,
  fazendaSelecionada,
  setFarolSelecionado,
  visualizarPontosColetas,
  toggles,
}) {
  const [mostrarPontosColetas, setMostrarPontosColetas] = useState("false");
  const [mostrarRadiosPontosColetas, setMostrarRadiosPontosColetas] =
    useState(false);

  useEffect(() => {
    if (verificaToggleVisualizaGeolocalizacaoAlturaPasto(toggles)) {
      setMostrarRadiosPontosColetas(true);
      return;
    }
  }, [fazendaSelecionada]);

  const handleChange = ({ target }) => {
    setMostrarPontosColetas(target.value);
    visualizarPontosColetas(convertStringBooleanToBoolean(target.value));
  };

  const handleSelecionaFarol = (e) => {
    enviaDadosGA();
    const opcaoSelecionada = Number(e.target.value);
    setFarolSelecionado(opcaoSelecionada);
    if (opcaoSelecionada !== idsFarois.alturaPastoAlturaForragem) {
      setMostrarPontosColetas("false");
      visualizarPontosColetas(false);
    }
    const acao = "Ver Farol";
    const rotulo = farol.Nome;
    const categoria = "Mapa";
    obtemDadosDeUtilizacao();
    enviaDadosGAPorRotulo(acao, rotulo, categoria);
  };

  const obtemDadosDeUtilizacao = () => {
    const acao = `ver farol de ${farol.Nome}`;
    const rotulo = `idFazenda ${fazendaSelecionada}`;
    const categoria = "Mapa";
    enviaDadosGAPorRotulo(acao, rotulo, categoria);
  };

  const listaLegendasFarois = (farol) => {
    return (
      <Grid key={shortid.generate()} xs={12} container>
        {farolSelecionado === farol.IdFarol &&
          legendasDetalhadasFarol[farol.IdFarol] &&
          legendasDetalhadasFarol[farol.IdFarol][filtroFarol] && (
            <Grid item xs={12}>
              <Typography className={classes.tituloExplicacaoFarol}>
                {
                  legendasDetalhadasFarol[farol.IdFarol][filtroFarol].legenda
                    .titulo
                }
              </Typography>

              <Typography className={classes.textoExplicacaoFarol}>
                {legendasDetalhadasFarol[farol.IdFarol][
                  filtroFarol
                ].legenda.texto(filtroFarol.toLowerCase())}
              </Typography>
            </Grid>
          )}
        {renderLegendaFarol(farol)}
      </Grid>
    );
  };

  const criaDropdownTipoColeta = (farol, farolSelecionado) => {
    if (farol.IdFarol === idsFarois.alturaPastoAlturaForragem) {
      return (
        <Grid
          key={shortid.generate()}
          item
          xs={12}
          style={{ padding: "0px 0px 16px 0px" }}
        >
          <DropdownTipoAfericaoContainer
            farolRetratoSelecionado={farol.IdFarol === farolSelecionado}
          />
        </Grid>
      );
    }
  };

  const enviaDadosGA = () => {
    const email = localStorage.getItem("Email");
    if (email) {
      const inicioEmailUsuario = email.split("@")[0];
      const finalEmailUsuario = email.split("@")[1];

      const acao = `Acessou um farol com inicio do E-Mail: ${inicioEmailUsuario} e o final ${finalEmailUsuario}`;
      const rotulo = `Usuário acessou um farol`;
      const categoria = "Acesso Farol";
      enviaDadosGAPorRotulo(acao, rotulo, categoria);
    }
  };

  const renderLegendaFarol = (farol) => {
    let legendaFarol = farol.Referencia;
    if (farol.IdFarol === idsFarois.alturaPastoAlturaForragem) {
      legendaFarol =
        filtroFarol === "Todos"
          ? legendaFarol
          : farol.Referencia.filter((ele) => ele.Valor.Tipo === filtroFarol);
    }
    const chainFarol = _.chain(legendaFarol);
    const agrupado = chainFarol.groupBy(
      (referencia) => referencia.LabelReferencia
    );
    const mapeado = agrupado
      .map((ref, i) => {
        const referencia = _.first(ref);
        /* Caso esteja no formato "em 5 dias" deve permanecer com a primeira letra minúscula*/
        const labelFormatada =
          (/(em ([\d]) dias)/i.test(referencia.LabelReferencia)
            ? referencia.LabelReferencia[0].toLowerCase()
            : referencia.LabelReferencia[0].toUpperCase()) +
          referencia.LabelReferencia.substr(1);
        return (
          <div key={referencia.IdReferencia} className={classes.legendColumn}>
            <div className={classes.legendRow}>
              <div
                className={classes.bolinhaFarol}
                style={{
                  backgroundColor: referencia.Cor,
                }}
              />
              <Typography variant="body1" className={classes.textoBolinhaFarol}>
                {labelFormatada}
              </Typography>
            </div>
            <div>
              {farolSelecionado === farol.IdFarol &&
                legendasDetalhadasFarol[farol.IdFarol] &&
                legendasDetalhadasFarol[farol.IdFarol][filtroFarol] && (
                  <Typography className={classes.subTextoBolinhaFarol}>
                    {
                      legendasDetalhadasFarol[farol.IdFarol][filtroFarol][
                      labelFormatada
                      ]
                    }
                  </Typography>
                )}
            </div>
          </div>
        );
      })
      .value();
    return <div className={classes.legendContainer}>{mapeado}</div>;
  };

  const criaRadioLabel = (valor) => {
    return (
      <span
        style={{
          fontWeight: `${mostrarPontosColetas === valor ? "bold" : "normal"}`,
          display: "block",
        }}
      >
        {valor === "true" ? "Sim" : "Não"}
      </span>
    );
  };

  const criaVisualizarPontosColeta = (farol, farolSelecionado) => {
    if (
      farol.IdFarol === idsFarois.alturaPastoAlturaForragem &&
      mostrarRadiosPontosColetas
    ) {
      return (
        <Grid
          key={shortid.generate()}
          item
          xs={12}
          style={{ margin: "16px 0" }}

        >
          <Grid item xs={12}>
            <FormControl disabled={farolSelecionado !== idsFarois.alturaPastoAlturaForragem}>
              <FormLabel
                className={classes.tituloExplicacaoFarol}
                style={{ marginBottom: "4px" }}
                id="mostrar-ponts-coleta-label"
              >
                Deseja visualizar os pontos onde foram coletadas as alturas das
                forragens?
              </FormLabel>
              <RadioGroup
                aria-labelledby="mostrar-ponts-coleta"
                name="mostrar-ponts-coleta"
                value={mostrarPontosColetas}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label={criaRadioLabel("true")}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label={criaRadioLabel("false")}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <ListItem
      id="RadioFarol"
      className={farol.IdFarol === farolSelecionado ? classes.selecionado : ""}
    >
      <Grid container>
        <Grid item xs={12}>
          <RadioGroup>
            <Grid container justify="space-between">
              <FormControlLabel
                value={String(farol.IdFarol)}
                onChange={handleSelecionaFarol}
                control={<Radio checked={farolSelecionado === farol.IdFarol} />}
                name="coleta"
                label={farol.Nome}
                style={{ fontSize: "14px" }}
              />
            </Grid>
          </RadioGroup>
        </Grid>
        {[
          criaDropdownTipoColeta(farol, farolSelecionado),
          criaVisualizarPontosColeta(farol, farolSelecionado),
          listaLegendasFarois(farol, classes),
        ]}
      </Grid>
    </ListItem>
  );
}

Map.propTypes = {
  farol: PropTypes.object,
  farolSelecionado: PropTypes.number,
  tipoIconeExibido: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    filtroFarol: state.farol.TipoIconeExibido,
  };
};

const mapDispatchToProps = {
  setFarolSelecionado: actions.setFarolSelecionado,
  toggleAvisoConsultoria: toggleAvisoConsultoria,
  setTipoColeta: actions.setTipoColeta,
  visualizarPontosColetas: actions.visualizarPontosColetas,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(RadioFarol);
