import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { AppBar as AppBarCustomizado, AppBarConteudoDefault } from "../layout";
import ClienteService from "services/ClienteService";
import { withStyles } from "@material-ui/core";

import ScrollToTop from "../shared/components/ScrollToTop";
import UserListContainer from "./views/list/userListContainer";
import UserCreationPage from "./views/create/userCreationPage";
import UserEditionPage from "./views/edit/userEditionPage";

const ClientNameAppBar = () => {
  const [clientName, setClientName] = useState();
  useEffect(() => {
    const clientId = sessionStorage.getItem("clienteSelecionado");
    if (!clientId) return;
    ClienteService.listarDadosClientes([clientId]).then((response) => {
      setClientName(
        "CLIENTE " + response[0].NomeReduzido || response[0].NomeReduzido
      );
    });
  }, []);

  return <AppBarConteudoDefault tituloAppBar={clientName} />;
};

const UserManagementRouter = ({ match, classes }) => {
  return (
    <>
      <ScrollToTop />
      <div className={classes.root}>
        <AppBarCustomizado
          conteudoCustomizadoAppBar={<ClientNameAppBar />}
          id="appBarPersonalizado"
        />
        <main className={classes.container}>
          <Switch>
            <Route
              exact
              path={`/gestao-de-usuarios`}
              component={UserListContainer}
            />
            <Route
              exact
              path={`/gestao-de-usuarios/cadastrar`}
              component={UserCreationPage}
            />
            <Route
              exact
              path={`/gestao-de-usuarios/editar/:idUsuario`}
              component={UserEditionPage}
            />
          </Switch>
        </main>
      </div>
    </>
  );
};
const styles = {
  root: {
    "& header": {
      background: "#F8F8F8",
    },
  },
  container: {
    padding: "40px",
    background: "#FFF",
  },
};
export default withStyles(styles)(UserManagementRouter);
