import React from "react";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const CheckboxIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const CheckboxCheckedIcon = <CheckBoxIcon fontSize="small" />;

function Option({ isMulti, props, option, selected }) {
  return (
    <li data-testid={option.label} {...props}>
      {isMulti && (
        <Checkbox
          icon={CheckboxIcon}
          checkedIcon={CheckboxCheckedIcon}
          style={{ marginRight: 8 }}
          checked={selected}
        />
      )}
      {option.label}
    </li>
  );
}

export default Option;
