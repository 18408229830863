import BaseService from "../../services/BaseService";

const URL_BASE = `${process.env.REACT_APP_ENDPOINT}herds/herdRecords`;

class MovimentacaoService extends BaseService {
  movimentacaoToherdRecord(movimentacao) {
    return {
      id: movimentacao.IdRegistro,
      createdAt: movimentacao.DataCriacao,
      creationUser: movimentacao.UsuarioCriacao,
      updatedAt: movimentacao.DataAlteracao,
      updateUser: movimentacao.UsuarioAlteracao,
      deletedAt: movimentacao.DataExclusao,
      deletionUser: movimentacao.UsuarioExclusao,
      farmId: movimentacao.IdFazenda,
      animalCategoryId: movimentacao.IdCategoriaAnimal,
      recordTypeId: movimentacao.TipoRegistro,
      quantity: movimentacao.Quantidade,
      weight: movimentacao.PesoPesado,
      carcassYield: movimentacao.RendimentoDeCarcaca
        ? parseFloat(movimentacao.RendimentoDeCarcaca.replace(",", "."))
        : null,
      operationDate: new Date(movimentacao.DataRegistro).toISOString(),
      operationTypeId: movimentacao.TipoMovimentacao,
      confirmed: movimentacao.Confirmado,
    };
  }
  herdRecordToMovimentacao(herdRecord) {
    return {
      IdRegistro: herdRecord.id,
      DataCriacao: herdRecord.createdAt,
      UsuarioCriacao: herdRecord.creationUser,
      DataAlteracao: herdRecord.updatedAt,
      UsuarioAlteracao: herdRecord.updateUser,
      DataExclusao: herdRecord.deletedAt,
      UsuarioExclusao: herdRecord.deletionUser,
      IdFazenda: herdRecord.farmId,
      IdCategoriaAnimal: herdRecord.animalCategoryId,
      TipoRegistro: herdRecord.recordTypeId,
      Quantidade: herdRecord.quantity,
      PesoPesado: herdRecord.weight,
      RendimentoDeCarcaca: herdRecord.carcassYield
        ? herdRecord.carcassYield.toString().replace(".", ",")
        : "",
      DataRegistro: herdRecord.operationDate,
      TipoMovimentacao: herdRecord.operationTypeId,
      Confirmado: herdRecord.confirmed,
    };
  }

  async obterMovimentacoesFazenda() {
    const response = await this.get(URL_BASE);
    const movimentacaoResponse = response.data.data.map(
      this.herdRecordToMovimentacao
    );
    return { ...response, data: movimentacaoResponse };
  }

  async adicionarMovimentacao(movimentacao) {
    const herdRecord = this.movimentacaoToherdRecord(movimentacao);
    const response = await this.post(URL_BASE, herdRecord);
    const movimentacaoResponse = this.herdRecordToMovimentacao(
      response.data.data
    );
    return { ...response, data: movimentacaoResponse };
  }

  async deletarMovimentacao(IdRegistro) {
    return await this.delete(`${URL_BASE}/${IdRegistro}`);
  }

  async editarMovimentacao(movimentacao, IdRegistro) {
    const herdRecord = this.movimentacaoToherdRecord(movimentacao);
    const response = await this.patch(`${URL_BASE}/${IdRegistro}`, herdRecord);
    const movimentacaoResponse = this.herdRecordToMovimentacao(
      response.data.data
    );
    return { ...response, data: movimentacaoResponse };
  }
}

export default new MovimentacaoService();
