import React, { Component } from "react";
import styles from "styles/MenuLateral/Vinculos";
import { compose } from "recompose";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import * as moduloActions from "features/manejo/redux/actions/moduloActions";
import * as cochoActions from "features/manejo/redux/actions/cochoActions";
import * as mapaActions from "features/manejo/redux/actions/mapaActions";
import * as vinculoActions from "features/manejo/redux/actions/vinculoActions";
import * as pastoActions from "features/manejo/redux/actions/pastoActions";
import { Grid, withStyles, withTheme } from "@material-ui/core";
import { enviaDadosGAPorRotulo } from "../../../../../shared/utils.js";
import { CadastroCochoCompartilhado } from "./views/CochoCompartilhado/CadastroCochoCompartilhado";
import CadastroModulo from "./views/Modulo/CadastroModulo";
import Box from "@mui/material/Box";

const initialState = {
  ModalConfirmacaoExclusao: false,
  ModalConfirmacaoExclusaoCochoCompartilhado: false,
  Cocho: {
    Coberto: "",
    Comprimento: "",
    Elevado: "",
    EstoqueFinal: "",
  },
};
export class Vinculo extends Component {
  state = initialState;

  constructor(props) {
    super(props);
    this.desabilitaBotaoAtualizar = true;
    this.props.setNomeModulo("");
    this.props.setNomeReduzidoModulo("");
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.criaModulo(
      this.props.nomeModulo,
      this.props.nomeReduzido,
      this.props.cor,
      this.props.pastosSelecionados
    );
    const acao = "Criar";
    const rotulo = "Módulo";
    const categoria = "Cadastro";
    enviaDadosGAPorRotulo(acao, rotulo, categoria);
  };

  handleSubmitCochoCompartilhado = (e) => {
    e.preventDefault();
    const { fazendaSelecionada } = this.props;
    this.props.criaCochoCompartilhado(
      this.state.Cocho,
      this.props.cor,
      this.props.pastosSelecionados,
      fazendaSelecionada
    );
    const acao = "Criar";
    const rotulo = "Cocho Compartilhado";
    const categoria = "Cadastro";
    enviaDadosGAPorRotulo(acao, rotulo, categoria);
  };

  onChangeCor = (e) => {
    this.props.setCorModulo(e.target.value);
    this.desabilitaBotaoAtualizar = false;
  };

  onChangeNome = (e) => {
    const nome = e.target.value.trim() === "" ? "" : e.target.value;
    this.props.setNomeModulo(nome);
    this.desabilitaBotaoAtualizar = false;
  };

  onChangeNomeReduzido = (e) => {
    const nomeReduzido = e.target.value.trim() === "" ? "" : e.target.value;
    this.props.setNomeReduzidoModulo(nomeReduzido);
    this.desabilitaBotaoAtualizar = false;
  };

  handleAbreModalExclusao = () => {
    this.setState({ ModalConfirmacaoExclusao: true });
  };

  handleFechaModalExclusao = () => {
    this.setState({ ModalConfirmacaoExclusao: false });
  };

  handleAbreModalExclusaoCochoCompartilhado = () => {
    this.setState({ ModalConfirmacaoExclusaoCochoCompartilhado: true });
  };

  handleFechaModalExclusaoCochoCompartilhado = () => {
    this.setState({ ModalConfirmacaoExclusaoCochoCompartilhado: false });
  };

  handleDeleteCochoCompartilhado = () => {
    const { cochoSelecionado, fazendaSelecionada } = this.props;

    this.props.excluiCochoCompartilhado(
      cochoSelecionado.IdCocho,
      fazendaSelecionada
    );
    this.props.alteraEstadoPoligonoMundo(false);
    this.modificaAtributosMapa(true);
    this.handleFechaModalExclusaoCochoCompartilhado();
    const acao = "Excluir";
    const rotulo = "Cocho Compartilhado";
    const categoria = "Cadastro";
    enviaDadosGAPorRotulo(acao, rotulo, categoria);
  };

  handleDelete = () => {
    const { moduloSelecionado, fazendaSelecionada } = this.props;

    this.props.excluirModulo(moduloSelecionado, fazendaSelecionada);
    this.props.alteraEstadoPoligonoMundo(false);
    this.modificaAtributosMapa(true);
    this.handleFechaModalExclusao();
    const acao = "Excluir";
    const rotulo = "Módulo";
    const categoria = "Cadastro";
    enviaDadosGAPorRotulo(acao, rotulo, categoria);
  };

  handleUpdate = () => {
    this.props.atualizarModulo(
      this.props.moduloSelecionado,
      this.props.fazendaSelecionada,
      this.props.nomeModulo,
      this.props.nomeReduzido,
      this.props.cor
    );
    this.props.alteraEstadoPoligonoMundo(false);
    this.modificaAtributosMapa(true);
    const acao = "Editar";
    const rotulo = "Módulo";
    const categoria = "Cadastro";
    enviaDadosGAPorRotulo(acao, rotulo, categoria);
  };

  modificaAtributosMapa = (valor) => {
    const estadoMapa = {
      scrollwheel: valor,
      zoomControl: valor,
      panControl: valor,
      draggable: valor,
      scaleControl: valor,
      mapTypeControl: valor,
    };
    this.props.setEstadoMapa(estadoMapa);
  };

  componentDidUpdate() {
    this.desabilitaBotaoAtualizar = true;
  }

  componentWillUnmount() {
    this.modificaAtributosMapa(true);
    this.props.alteraEstadoPoligonoMundo(false);
  }

  handleChangeAba = (event, aba) => {
    this.props.limparSelecao();
    this.setState({
      Cocho: {},
    });
    this.props.deselecionaCochoCompartilhado();
    this.props.selecionaAba(aba);
  };

  handleChangeFormCocho = (propertyName, event) => {
    this.setState({
      Cocho: { ...this.state.Cocho, [propertyName]: event },
    });
  };

  handleRemovePasture = (pasture) => {
    this.props.deselecionaPasto(pasture);
  };

  limparNovoCochoCompartilhado() {
    this.props.limparSelecao();
    this.props.novoCocho();
    this.setState({ Cocho: {} });
  }

  habilitarCochoCompartilhado(toggles) {
    const cochoCompartilhado = toggles.filter(
      (toggle) => toggle.Nome === "COCHO_COMPARTILHADO"
    )[0];
    return cochoCompartilhado && cochoCompartilhado.Ativo;
  }

  render() {
    const {
      classes,
      moduloSelecionado,
      cochoSelecionado,
      aba,
      limparNovoCochoCompartilhado,
      toggles,
    } = this.props;
    const telaEdicao = moduloSelecionado !== 0 ? true : false;

    if (limparNovoCochoCompartilhado) {
      this.limparNovoCochoCompartilhado();
    }

    return (
      <div>
        <Grid container direction="row" />
        <Box
          sx={{ borderBottom: 1, borderColor: "divider" }}
          className={classes.tab}
        >
          <Tabs
            id="tabController"
            value={aba}
            onChange={this.handleChangeAba}
            indicatorColor="primary"
            fullWidth
          >
            <Tab
              id="tabCadastroModulo"
              label="Módulo"
              style={{
                textTransform: "none",
                fontSize: "15px",
                textAlign: "center",
              }}
            />
            {this.habilitarCochoCompartilhado(toggles) ? (
              <Tab
                id="tabCochoCompartilhado"
                label="Cocho Compartilhado"
                style={{
                  textTransform: "none",
                  fontSize: "15px",
                  textAlign: "center",
                }}
              />
            ) : null}
          </Tabs>
        </Box>
        <Grid />
        {aba === 0 ? (
          <form onSubmit={this.handleSubmit}>
            <CadastroModulo
              id="cadastroModulo"
              classes={classes}
              pastos={this.props.pastos}
              pastosSelecionados={this.props.pastosSelecionados}
              telaEdicao={telaEdicao}
              nomeModulo={this.props.nomeModulo}
              nomeReduzido={this.props.nomeReduzido}
              onChangeNome={this.onChangeNome}
              onChangeNomeReduzido={this.onChangeNomeReduzido}
              corProps={this.props.cor}
              onChangeCor={this.onChangeCor}
              handleAbreModalExclusao={this.handleAbreModalExclusao}
              modalConfirmacaoExclusao={this.state.ModalConfirmacaoExclusao}
              handleFechaModalExclusao={this.handleFechaModalExclusao}
              handleDelete={this.handleDelete}
              handleUpdate={this.handleUpdate}
              desabilitaBotaoAtualizar={this.desabilitaBotaoAtualizar}
              handleLimparSelecao={this.props.limparSelecao}
              handleSubmit={this.handleSubmit}
            />
          </form>
        ) : (
          <form onSubmit={this.handleSubmitCochoCompartilhado}>
            <CadastroCochoCompartilhado
              id="cadastroCochoCompartilhado"
              classes={classes}
              pastos={this.props.pastos}
              getPasturesQuantity={this.props.getPasturesQuantity}
              pasturesQuantity={this.props.pasturesQuantity}
              pastosSelecionados={this.props.pastosSelecionados}
              Cocho={cochoSelecionado ? cochoSelecionado : this.state.Cocho}
              handleChangeFormCocho={this.handleChangeFormCocho}
              corProps={this.props.cor}
              onChangeCor={this.onChangeCor}
              handleLimparSelecao={() => {
                this.props.limparSelecao();
                this.setState({ Cocho: {} });
              }}
              handleSubmit={this.handleSubmitCochoCompartilhado}
              handleAbreModalExclusao={
                this.handleAbreModalExclusaoCochoCompartilhado
              }
              modalConfirmacaoExclusao={
                this.state.ModalConfirmacaoExclusaoCochoCompartilhado
              }
              handleFechaModalExclusao={
                this.handleFechaModalExclusaoCochoCompartilhado
              }
              handleDelete={this.handleDeleteCochoCompartilhado}
              handleRemovePasture={this.handleRemovePasture}
            />
          </form>
        )}
      </div>
    );
  }
}

Vinculo.propTypes = {
  fazendaSelecionada: PropTypes.number,
  retiroSelecionado: PropTypes.number,
  pastosSelecionados: PropTypes.array.isRequired,
  pastos: PropTypes.array.isRequired,
  moduloSelecionado: PropTypes.number,
  corModulo: PropTypes.string,
  nomeModulo: PropTypes.string,
  nomeReduzido: PropTypes.string,
  cochoSelecionado: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    aba: state.vinculo.aba,
    limparNovoCochoCompartilhado: state.cocho.limparNovoCocho,
    toggles: state.fazenda.Toggles,
    pasturesQuantity: state.pasto.pasturesQuantity,
  };
};

const mapDispatchToProps = {
  deselecionaPasto: moduloActions.deselecionaPasto,
  criaModulo: moduloActions.criaModulo,
  excluirModulo: moduloActions.excluirModulo,
  atualizarModulo: moduloActions.atualizarModulo,
  limparSelecao: moduloActions.limparSelecao,
  setCorModulo: moduloActions.setCorModulo,
  setNomeModulo: moduloActions.setNomeModulo,
  setNomeReduzidoModulo: moduloActions.setNomeReduzidoModulo,
  alteraEstadoPoligonoMundo: moduloActions.alteraEstadoPoligonoMundo,
  setEstadoMapa: mapaActions.setEstadoMapa,
  criaCochoCompartilhado: cochoActions.criarCochoCompartilhado,
  selecionaAba: vinculoActions.selecionaAba,
  excluiCochoCompartilhado: cochoActions.excluiCochoCompartilhado,
  deselecionaCochoCompartilhado: cochoActions.deselecionaCochoCompartilhado,
  novoCocho: cochoActions.novoCocho,
  getPasturesQuantity: pastoActions.getPasturesQuantity,
};

export default compose(
  withTheme(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Vinculo);
