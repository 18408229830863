// Util das Tabelas
export const nomesColunasTabelaTotal = [
  "",
  "ESTOQUE INICIAL",
  "ENTRADA",
  "SAÍDA",
  "NASCIMENTOS",
  "MORTES",
  "ESTOQUE FINAL",
  "PRODUÇÃO TOTAL DE ARROBAS",
];

export const colunasTabelaGmd = [
  {
    label: "REBANHO MÉDIO (Cab)",
    tooltip: "Média de cabeças no período de 30 dias",
    style: "cabecalhoPrimeiraCelula",
  },
  {
    label: "PRODUÇÃO/CABEÇA (Kg)",
    tooltip: "É a quantidade que cada cabeça produziu em Kg",
    style: "cabecalhoTabelaConteudo",
  },
  {
    label: "PRODUÇÃO/CABEÇA (@)",
    tooltip: "É a quantidade que cada cabeça produziu em @",
    style: "cabecalhoTabelaConteudo",
  },
  {
    label: "PERÍODO (dias)",
    tooltip: "",
    style: "cabecalhoTabelaConteudo",
  },
  {
    label: "GMD",
    tooltip:
      "Ganho Médio Diário= (Produção por Cab(Kg)  x 1.000)/ Período(dias)",
    style: "gmdCabecalho",
    iconColor: "#FFF",
  },
];

export const colunasTabelaPorCategoria = [
  {
    label: "CATEGORIA",
    style: "cabecalhoPrimeiraCelula",
  },
  { label: "QUANTIDADE INICIAL (Cab)", style: "celulaComumCabecalho" },
  { label: "PESO PARA BALANÇO INICIAL (Kg)", style: "celulaComumCabecalho" },
  { label: "QUANTIDADE FINAL (Cab)", style: "celulaComumCabecalho" },
  { label: "PESO PARA BALANÇO FINAL (Kg)", style: "cabecalhoUltimaCelula" },
];

export const BalancoDeArroba = {
  EstoqueInicial: {
    Quantidade: 82,
    Peso: 73.3,
  },
  Entrada: {
    Quantidade: 20,
    Peso: 3.3,
  },
  Saida: {
    Quantidade: 1,
    Peso: 10,
  },
  Nascimentos: {
    Quantidade: 2,
    Peso: 50,
  },
  Mortes: {
    Quantidade: 1,
    Peso: 50,
  },
  EstoqueFinal: {
    Quantidade: 102,
    Peso: 76.6,
  },
  Total: 10,
  RebanhoMedio: 102,
  ProducaoEmQuilos: 5,
  ProducaoEmArrobas: 0.098,
  Periodo: 3,
  GMD: 0.032,
  Categorias: [
    {
      IdCategoria: 1,
      Nome: "Bezerro",
      QuantidadeInicial: 32,
      PesoInicial: 500,
      QuantidadeFinal: 32,
      PesoFinal: 500,
    },
    {
      IdCategoria: 2,
      Nome: "Vaca",
      QuantidadeInicial: 50,
      PesoInicial: 600,
      QuantidadeFinal: 70,
      PesoFinal: 650,
    },
  ],
};

export const camposEsperados = [
  "Nome",
  "EstoqueInicial",
  "Entradas",
  "Saidas",
  "EstoqueFinal",
  "Balanco",
];

export const mensagem = {
  erroDataMax: "A Data Inicial não pode ser maior que a Data Final.",
  erroMesmaData: "Não é possível gerar o Balanço de Arroba para a mesma data.",
};
