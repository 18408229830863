import React from "react";
import { connect } from "react-redux";
import * as modalActions from "../../../redux/actions/modalActions";

import {
  deveExibirConfiguracaoFazenda,
  deveExibirFechamentoConsumo,
  deveExibirRelatorioTableau,
  deveExibirFinanceiro,
  deveExibirAnalises,
  deveExibirCadastroUsuario
} from "../../shared/utils";
import MenuNavegacao from "./menuNavegacao";

export const menuNavegacaoContainer = (props) => {
  return <MenuNavegacao {...{ ...props }} />;
};

const mapStateToProps = (state) => {
  const { fazenda, cliente, usuario } = state;
  return {
    exibirConfiguracaoFazenda: deveExibirConfiguracaoFazenda(state),
    exibirFechamentoConsumo: deveExibirFechamentoConsumo(state),
    exibirRelatorioTableau: deveExibirRelatorioTableau(state),
    fazendaSelecionada: state.fazenda.FazendaSelecionada,
    exibirFinanceiro: deveExibirFinanceiro(state),
    exibirAnalise: deveExibirAnalises(state),
    dadosState: { fazenda, cliente, usuario },
    exibirCadastroUsuario: deveExibirCadastroUsuario(state)
  };
};

const mapDispatchToProps = {
  setModalHistorico: modalActions.setModalHistorico,
  setModalTableau: modalActions.setModalTableau,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(menuNavegacaoContainer);
