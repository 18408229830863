import AjusteEscoreService from "./ajusteEscoreService";

export class AjusteEscoreManager {
  async listarAjustesEscore(fazendaSelecionada) {
    const resposta = await AjusteEscoreService.listarAjustesEscore(fazendaSelecionada);

    const ajustesEscoreFazendaObjeto = {
      IdFazenda: fazendaSelecionada,
      AjustesEscore: resposta.data,
    };

    return ajustesEscoreFazendaObjeto;
  }

  async excluirAjustesEscore(IdFazenda) {
    await AjusteEscoreService.excluirAjustesEscore(IdFazenda);
  }

  async salvarAjustesEscore(ajusteEscore) {
    const respostaService = await AjusteEscoreService.salvarAjustesEscore(ajusteEscore);
    return respostaService.data;
  }
}

export default new AjusteEscoreManager();
