import CausaMorteService from "./causaMorteService";
import GanhoPesoManager from "../projecaoGanhoPeso/ganhoPesoManager";
import _ from "lodash";

export class CausaMorteManager {
  async obterCausasMorteFazenda(fazendaSelecionada, deleted) {
    const causasMorteFazenda =
      await CausaMorteService.listaCausasMortePorFazenda(
        fazendaSelecionada,
        deleted
      );
    return causasMorteFazenda.data;
  }

  async excluirCausaMorte(idCausaMorte) {
    await CausaMorteService.deletarCausaMorte(idCausaMorte);
  }

  async reativarCausaMorte(idCausaMorte, causaMorte) {
    await CausaMorteService.reativarCausaMorte(idCausaMorte, causaMorte);
  }

  async salvarCausaMorte(causaMorte) {
    const respostaService = await CausaMorteService.adicionarCausaMorte(
      causaMorte
    );
    return respostaService.data;
  }

  async editarCausaMorte(causaMorte, idCausaMorte) {
    causaMorte = _.pick(causaMorte, ["Nome", "IdFazenda"]);
    await CausaMorteService.editarCausaMorte(causaMorte, idCausaMorte);
  }
  async obterCurvasGanhoPeso(idFazenda) {
    return await GanhoPesoManager.obterCurvasGanhoPeso(idFazenda);
  }
}

export default new CausaMorteManager();
