import React from "react";
import { connect } from "react-redux";
import Configuracoes from "./configuracoes";
import * as configuracaoOperations from "../redux/configuracaoOperations";
import * as pastoActions from "features/manejo/redux/actions/pastoActions";
import * as categoriaOperations from "../../categoria/redux/categoriaOperations";

import {
  deveExibirCadastroFormulacao,
  deveExibirGanhoPeso,
  deveExibirObjetivoLote,
  deveExibirHistoricoChuva,
  deveExibirCausaMorte,
  deveExibirFechamentoConsumo,
  deveExibirCreep,
  deveExibirBombona,
  deveExibirMorteNascimento,
  deveExibirAjusteEscore,
  isLoggedUserInternal,
} from "../../shared/utils";

export const ConfiguracoesContainer = (props) => {
  return (
    <Configuracoes
      fazendaSelecionada={props.fazendaSelecionada}
      exibirCadastroFormulacao={props.exibirCadastroFormulacao}
      exibirGanhoPeso={props.exibirGanhoPeso}
      exibirObjetivoLote={props.exibirObjetivoLote}
      exibirHistoricoChuva={props.exibirHistoricoChuva}
      exibirCausaMorte={props.exibirCausaMorte}
      exibirFechamentoDeConsumo={props.exibirFechamentoDeConsumo}
      exibirCreep={props.exibirCreep}
      exibirBombona={props.exibirBombona}
      exibirMorteNascimento={props.exibirMorteNascimento}
      exibirAjusteEscore={props.exibirAjusteEscore}
      exibirEvolucaoRebanhoPan={props.exibirEvolucaoRebanhoPan}
      selecionaAbaCadastro={props.selecionaAbaCadastro}
      obterCategoriasFazenda={props.obterCategoriasFazenda}
      obtemPastosFazenda={props.obtemPastosFazenda}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    fazendaSelecionada: state.fazenda.FazendaSelecionada,
    exibirGanhoPeso: deveExibirGanhoPeso(state),
    exibirCadastroFormulacao: deveExibirCadastroFormulacao(state),
    exibirObjetivoLote: deveExibirObjetivoLote(state),
    exibirHistoricoChuva: deveExibirHistoricoChuva(state),
    exibirCausaMorte: deveExibirCausaMorte(state),
    exibirFechamentoDeConsumo: deveExibirFechamentoConsumo(state),
    exibirCreep: deveExibirCreep(state),
    exibirBombona: deveExibirBombona(state),
    exibirMorteNascimento: deveExibirMorteNascimento(state),
    exibirAjusteEscore: deveExibirAjusteEscore(state),
    exibirEvolucaoRebanhoPan: isLoggedUserInternal(),
  };
};

const mapDispatchToProps = {
  selecionaAbaCadastro: configuracaoOperations.selecionaAba,
  obterCategoriasFazenda: categoriaOperations.obterCategoriasFazenda,
  obtemPastosFazenda: pastoActions.obtemPastosFazenda,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfiguracoesContainer);
