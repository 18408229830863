import { useState, useEffect, useRef } from "react";
import auth from "../Auth";

export function useChangePassword({ token, notifyError, notifySuccess }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [password, setPassword] = useState();
  const [passwordConfirmation, setPasswordConfirmation] = useState();
  const origin = useRef();

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      setError(false);

      if (!password || !passwordConfirmation) {
        throw new Error("Preencha todos os campos");
      }

      if (password !== passwordConfirmation) {
        throw new Error("As senhas não coincidem");
      }

      const regex =
        /(?=^.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=^.{8,}$)(?=.*[+_!@#S%^&*?])/;
      if (!regex.test(password)) {
        throw new Error(
          "A senha deve conter pelo menos 8 caracteres, uma letra maiúscula, uma letra minúscula e um número ou um caractere especial"
        );
      }

      await auth.changePassword(token, password);

      notifySuccess({ mensagem: "Senha criada com sucesso" });
      setTimeout(() => {
        if (origin.current === "app") {
          window.location.href = process.env.REACT_APP_URL_APP;
        } else {
          window.location.href = "/login";
        }
      }, 2000);
    } catch (error) {
      setError(true);
      notifyError({ mensagem: error.message });
    }
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === "password") {
      setPassword(value);
    } else {
      setPasswordConfirmation(value);
    }
  };

  const validateToken = async () => {
    try {
      setLoading(true);
      const response = await auth.verifyPasswordToken(token);

      if (response.status === 200) {
        setIsTokenValid(true);
        origin.current = response.data.Origem;
      } else {
        setIsTokenValid(false);
      }
    } catch (error) {
      setError(error);
      setIsTokenValid(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    validateToken();
  }, [token]);

  return { isTokenValid, loading, error, onSubmit, onChange };
}
