import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import FechamentoConsumoPage from "./fechamentoConsumoPage";
import { obtemFechamentoConsumo } from "../redux/fechamentoConsumoOperations";
import { mapeiaConsumosComPesoFormulas } from "../fechamentoConsumoUtils";
import { filtraFazendaSelecionadaMemo } from "../../shared/crossCuttingComponents";
import { INCLUIR_FORMULAS_DELETADAS } from "../../formulacao/formulaUtils";
import { obterFormulasFazenda } from "../../formulacao/redux/formulaOperations";
import { deveExibirFechamentoConsumo } from "../../shared/utils";
import { enviaDadosGAPorRotulo } from "../../../shared/utils.js";
import { DATA_INICIO, DATA_TERMINO } from '../fechamentoConsumoUtils';
import { listarRetiros } from "../../retiro/redux/retiroOperations";
export class FechamentoConsumoContainer extends Component {
  state = {
    chamadaConcluida: false,
  };

  obtemDados = async (filtroAplicar = {}) => {
    this.setState({
      chamadaConcluida: false,
    });
    const { fazendaSelecionada, formulas } = this.props;
    if (fazendaSelecionada) {
      const {
        dataInicio = DATA_INICIO,
        dataFim = DATA_TERMINO,
        formulaSelecionada,
        retirosSelecionados,
      } = filtroAplicar;

      await this.props.obterFormulasFazenda(INCLUIR_FORMULAS_DELETADAS);
      await this.props.obtemFechamentoConsumo(
        fazendaSelecionada.IdFazenda,
        formulas,
        dataInicio,
        dataFim,
        formulaSelecionada,
        retirosSelecionados
      );
    }
    this.setState({
      chamadaConcluida: true,
    });
  };

  componentDidMount() {
    const acao = `Consulta Fechamento de Consumo`;
    const rotulo = "Fechamento de Consumo";
    const categoria = "Análise";
    enviaDadosGAPorRotulo(acao, rotulo, categoria);
    if (!this.props.possuiTogglesParaFechamentoConsumo) {
      this.props.history.replace("/farol");
    } else {
      this.obtemDados();
    }
  }

  componentDidUpdate() {
    const acao = `Consulta Fechamento de Consumo`;
    const rotulo = "Fechamento de Consumo";
    const categoria = "Análise";
    enviaDadosGAPorRotulo(acao, rotulo, categoria);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.chamadaConcluida !== nextState.chamadaConcluida;
  }

  render() {
    const {
      formulas,
      consumos,
      diariasSemFormula,
      retiros,
      listarRetiros,
      fazendaSelecionada
    } = this.props;
    return (
      <FechamentoConsumoPage
        formulas={formulas}
        retiros={retiros}
        consumos={consumos}
        diariasSemFormula={diariasSemFormula}
        chamadaConcluida={this.state.chamadaConcluida}
        obtemFechamentoConsumo={this.obtemDados}
        listarRetiros={listarRetiros}
        fazendaSelecionada={fazendaSelecionada}
      />
    );
  }
}

const mapStateToProps = ({ fechamentoConsumo, cliente, fazenda, formula, retiro }) => {
  return {
    formulas: formula.FormulasFazenda.map((formula) => ({
      ...formula,
      NomeFormula: formula.Nome,
    })),
    retiros: retiro.Retiros.map((retiro) => ({
      ...retiro,
      NomeRetiro: retiro.Nome,
    })),
    consumos: mapeiaConsumosComPesoFormulas(fechamentoConsumo.consumos, formula.FormulasFazenda),
    diariasSemFormula: fechamentoConsumo.diariasSemFormula,
    fazendaSelecionada: filtraFazendaSelecionadaMemo({ fazenda }),
    possuiTogglesParaFechamentoConsumo: deveExibirFechamentoConsumo({ cliente, fazenda }),
  };
};

const mapDispatchToProps = {
  obtemFechamentoConsumo: obtemFechamentoConsumo,
  obterFormulasFazenda: obterFormulasFazenda,
  listarRetiros: listarRetiros,
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FechamentoConsumoContainer));
