import React from "react";

import { Grid } from "@material-ui/core";
import { FeedBackPage, TextLabel } from "features/shared/components";

const IngredientRegisterNotFound = ({ text }) => {
  return(
  <Grid
    container
    direction="column"
    justify="center"
    style={{
      minHeight: "60vh",
    }}
  >
    <FeedBackPage>
      <Grid container direction="column" alignItems="center">
        <img src="../images/fechamentoTelaVazia.png" alt="Lupa de tela vazia" />
        <TextLabel
          id="labelFechamentoFeedBack"
          block
          color="default2"
          customStyle={{ paddingTop: 10 }}
        >
          {text}
        </TextLabel>
      </Grid>
    </FeedBackPage>
  </Grid>
)};

export default IngredientRegisterNotFound;
