import React from "react";
import * as actions from "redux/actions";
import { connect } from "react-redux";
import "./styles.css";

export const SubMenuOfButtonMapType = ({
  title,
  value,
  setTipoMapa,
  mapTypeId,
}) => {
  return (
    <div className="sub-menu">
      <label>
        <input
          type="checkbox"
          checked={value === mapTypeId}
          onChange={() => setTipoMapa(value)}
        />
        {title}
      </label>
    </div>
  );
};

const mapDispatchToProps = {
  setTipoMapa: actions.setTipoMapa,
};

function mapStateToProps({ mapa }) {
  return {
    mapTypeId: mapa.mapTypeId,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubMenuOfButtonMapType);
