import { withStyles } from "@material-ui/core";
import { Search } from "@mui/icons-material";
import { TextField } from "@mui/material";
import SelectDropdown from "components/SelectDropdown";
import SkeletonLoading from "features/shared/components/SkeletonLoading";
import { useFilter } from "features/userManagement/hooks/useFilter";
import React from "react";

export const UserListFilter = ({
  clientId,
  filter,
  onFilterChange,
  classes,
}) => {
  const { search, status, farms, retires, permissions } = useFilter(
    clientId,
    filter,
    onFilterChange
  );

  return (
    <div className={classes.root}>
      <TextField
        classes={{ root: classes.searchInput }}
        variant="standard"
        startAdornment={<Search />}
        label="BUSCAR"
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="Busque por nome, login ou e-mail"
        value={search.value}
        onChange={(e) => search.onChange(e.target.value)}
      />
      <SelectDropdown
        styles={{ width: "auto", minWidth: 129, maxWidth: 300 }}
        label={{ text: "STATUS" }}
        options={status.list}
        value={status.selected}
        onChange={status.onChange}
      />
      {farms.isLoading ? (
        <MultiSelectSkeleton />
      ) : (
        <SelectDropdown
          isMulti
          styles={{ width: "auto", minWidth: 227, maxWidth: 300 }}
          label={{ text: "FAZENDAS" }}
          options={farms.list}
          value={farms.selected}
          onChange={farms.onChange}
          isLoading={farms.isLoading}
        />
      )}
      {retires.isLoading ? (
        <MultiSelectSkeleton />
      ) : (
        <SelectDropdown
          isMulti
          styles={{ width: "auto", minWidth: 170, maxWidth: 300 }}
          label={{ text: "RETIROS" }}
          options={retires.list}
          value={retires.selected}
          onChange={retires.onChange}
          isLoading={retires.isLoading}
        />
      )}
      <SelectDropdown
        isMulti
        styles={{ width: "auto", minWidth: 200, maxWidth: 300 }}
        label={{ text: "PERMISSÕES" }}
        options={permissions.list}
        value={permissions.selected}
        onChange={permissions.onChange}
      />
    </div>
  );
};

const MultiSelectSkeleton = () => <SkeletonLoading width={250} height={56} />;

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "24px",
  },
  searchInput: {
    minWidth: "250px !important",
  },
});

export default withStyles(styles)(UserListFilter);
