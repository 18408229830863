import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { idsFarois } from "../../../../../../constants/idsFarois";

import {
  FormControl,
  Button,
  FormHelperText,
  Grid,
  Typography,
  ListItem,
  Divider,
} from "@material-ui/core";
import moment from "moment";
import { InlineDatePicker } from "material-ui-pickers";
import InfoOutlined from "@material-ui/icons/InfoOutlined";

import * as actions from "../../../../../../redux/actions";
import { diferencaEntreDatas } from "../../../../../../lib/tratamentoDatas";
import { enviaDadosGA } from "../../../../../../shared/utils.js";

moment.locale("pt-BR");

const initialState = {
  desabilitaBotao: false,
  periodoColeta: null,
  ultimoAlterado: "",
  erros: {
    inicio: "",
    final: "",
  },
};
let periodoInicial = {};

class FiltroPeriodoColeta extends Component {
  constructor(props) {
    super(props);
    periodoInicial = props.periodoColeta;
    this.state = { ...initialState, periodoColeta: props.periodoColeta };
  }

  componentDidUpdate(prevProps, prevState) {
    let { periodoColeta } = this.state;
    if (prevState.periodoColeta === periodoColeta) {
      return;
    } else {
      const diferencaDatas = diferencaEntreDatas(
        periodoColeta.inicio,
        periodoColeta.final,
        "dias"
      );
      let { erros, ultimoAlterado } = this.state;
      const copiaErros = { ...erros };
      if (diferencaDatas > 90) {
        copiaErros[ultimoAlterado] =
          "As datas não devem ter diferença maior que 90 dias";
        this.setState({ erros: copiaErros, desabilitaBotao: true });
      } else if (diferencaDatas < 0) {
        copiaErros[ultimoAlterado] =
          "A data de inicio não pode ser maior que a data final";
        this.setState({ erros: copiaErros, desabilitaBotao: true });
      } else {
        this.setState({
          erros: { ...initialState.erros },
          desabilitaBotao: false,
        });
      }
    }
  }

  handlePeriodoColeta = (event, name) => {
    const tempPeriodo = { ...this.state.periodoColeta };
    tempPeriodo[name] = event;
    this.setState({ periodoColeta: tempPeriodo, ultimoAlterado: name });
  };

  resetPeriodo = () => {
    this.setState({ periodoColeta: periodoInicial });
    this.props.resetPeriodoColeta();
    this.props.listaFarol(false);
  };

  aplicaFiltroPeriodo = () => {
    const { periodoColeta } = this.state;
    this.props.alteraPeriodoColeta(periodoColeta);
    this.props.listaFarol(false);
    const diasFiltrados = diferencaEntreDatas(
      periodoColeta.inicio,
      periodoColeta.final,
      "dias"
    );
    const acao = `Filtrar periodo coleta em ${diasFiltrados} dias`;
    const valor = diasFiltrados;
    const categoria = "Análise";
    const rotulo = "Coleta";
    enviaDadosGA(acao, rotulo, categoria, valor);
  };

  renderBotoes = () => {
    return (
      <Grid container spacing={16} justify={"flex-end"} alignItems={"flex-end"}>
        <Grid item>
          <Button
            onClick={this.resetPeriodo}
            color="primary"
            style={{ margin: 0, borderRadius: "18px" }}
          >
            Limpar Data
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={this.aplicaFiltroPeriodo}
            disabled={this.state.desabilitaBotao}
            color="primary"
            style={{ margin: 0, borderRadius: "18px" }}
          >
            Aplicar
          </Button>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { periodoColeta, erros } = this.state;
    return (
      <div>
        <ListItem key="FiltroPeriodoListItem">
          <Grid container spacing={16}>
            <Grid item xs={6}>
              <Typography align="left" variant="subtitle1" gutterBottom>
                Período:
              </Typography>
            </Grid>
            {this.props.farolSelecionado ===
              idsFarois.alturaPastoTaxaCrescimento ||
              this.props.farolSelecionado === idsFarois.alturaPastoMediaSaida ||
              this.props.farolSelecionado ===
              idsFarois.alturaPastoMediaEntrada ? (
              <div />
            ) : (
              <Grid
                item
                xs={12}
                container
                justify="flex-start"
                alignItems="center"
                spacing={8}
              >
                <Grid item>
                  <InfoOutlined color="primary" />
                </Grid>
                <Grid item>
                  {" "}
                  <Typography variant="body2" color="textSecondary">
                    Informações sobre a última coleta desse período.
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              container
              justify="space-between"
              alignItems="flex-start"
              spacing={8}
            >
              <Grid item xs={6}>
                <FormControl style={{ maxWidth: "100%" }}>
                  <InlineDatePicker
                    onlyCalendar
                    keyboard
                    label="Início"
                    disableFuture
                    invalidDateMessage="O Formato da data é inválido"
                    maxDateMessage="A data inserida não pode ser maior que a data atual"
                    minDateMessage="A data inserida ultrapassa a data minima permitida"
                    format="DD/MM/YYYY"
                    mask={(value) =>
                      value
                        ? [
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]
                        : []
                    }
                    disableOpenOnEnter
                    animateYearScrolling={false}
                    value={periodoColeta.inicio}
                    onChange={(e) => this.handlePeriodoColeta(e, "inicio")}
                  />
                  {erros.inicio !== "" && (
                    <FormHelperText error id="inicio-erro">
                      {erros.inicio}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl style={{ maxWidth: "100%" }}>
                  <InlineDatePicker
                    onlyCalendar
                    keyboard
                    label="Final"
                    disableFuture
                    invalidDateMessage="O Formato da data é inválido"
                    maxDateMessage="A data inserida não pode ser maior que a data atual"
                    minDateMessage="A data inserida ultrapassa a data minima permitida"
                    format="DD/MM/YYYY"
                    mask={(value) =>
                      value
                        ? [
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          "/",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]
                        : []
                    }
                    disableOpenOnEnter
                    animateYearScrolling={false}
                    value={periodoColeta.final}
                    onChange={(e) => this.handlePeriodoColeta(e, "final")}
                  />
                  {erros.final !== "" && (
                    <FormHelperText error id="final-erro">
                      {erros.final}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {this.renderBotoes()}
            </Grid>
          </Grid>
        </ListItem>
        <Divider key="DividerFiltroPeriodoListItem" variant="middle" />
      </div>
    );
  }
}

Map.propTypes = {
  farois: PropTypes.array,
  FazendaSelecionada: PropTypes.number.isRequired,
  farolSelecionado: PropTypes.number,
  TipoFarol: PropTypes.string.isRequired,
};

export default connect(null, actions)(FiltroPeriodoColeta);
