export const LISTA_FAIXAS = 'LISTA_FAIXAS';


export const listarFaixasIdade = faixas => {
  return {
    type: LISTA_FAIXAS,
    payload: {
      FaixasIdade: faixas
    }
  };
};
