import memoize from "memoize-one";
import * as _ from "lodash";

export const filterCausaMorteMemoize = memoize(
  (list, textoFiltrar, stateFilter) =>
    _.filter(list, (causaMorte) => {
      const deleted = causaMorte.Excluido;

      if (deleted && stateFilter === 1) return false; //Ativado
      if (!deleted && stateFilter === 2) return false; //Inativo

      causaMorte.Nome = causaMorte.Nome || "";
      return causaMorte.Nome.toUpperCase().includes(textoFiltrar.toUpperCase());
    })
);
