import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";

export function SingleClickButton({
  onClick,
  disabled,
  delay = 3000,
  ...props
}) {
  const [_disabled, _setDisabled] = useState(disabled);

  const _onClick = (ev) => {
    _setDisabled(true);
    onClick(ev);
    setTimeout(() => _setDisabled(false), 3000);
  };

  useEffect(() => _setDisabled(disabled), [disabled]);

  return (
    <Button
      data-testid={"SingleClickButton"}
      {...props}
      onClick={_onClick}
      disabled={_disabled || disabled}
    />
  );
}
