import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { Link, Grid, Tooltip, Typography } from "@material-ui/core";

import {
  obterMensagemMesAtualProjecaoPeso,
  obtemEstiloLink,
} from "../../../loteUtils";
import { TextLabel } from "../../../../shared/components";
import InfoIcon from "@material-ui/icons/Info";

export const campoPesoProjetado = ({
  exibirColunaPesoProjetado,
  obterProjecaoSemestrePorLote,
  showLoteModal,
  lote,
  getWeightGainCurve,
  classes,
}) => {
  if (!exibirColunaPesoProjetado) {
    return null;
  }

  const weightGainCurve = getWeightGainCurve ? getWeightGainCurve(lote) : null;

  const infoMessage = (measuredWeight) => {
    if (!weightGainCurve && !measuredWeight && !lote.DataPesagem) {
      return "É necessário criar uma curva de ganho de peso e associá-la uma categoria animal, informar o peso pesado e a data da pesagem, para obter informações sobre o peso projetado.";
    }

    if (weightGainCurve && !measuredWeight && !lote.DataPesagem) {
      return "É necessário informar o peso pesado e a data da pesagem, para obter informações sobre o peso projetado.";
    }

    if (weightGainCurve && measuredWeight && !lote.DataPesagem) {
      return "É necessário inserir a data da pesagem, para obter informações sobre o peso projetado.";
    }

    if (!weightGainCurve && measuredWeight && lote.DataPesagem) {
      return "É necessário criar uma curva de ganho de peso e associá-la uma categoria animal, para obter informações sobre o peso projetado.";
    }

    if (!weightGainCurve && !measuredWeight && lote.DataPesagem) {
      return "É necessário criar uma curva de ganho de peso e associá-la uma categoria animal, e inserir o peso pesado, para obter informações sobre o peso projetado.";
    }

    if (!weightGainCurve && measuredWeight && !lote.DataPesagem) {
      return "É necessário criar uma curva de ganho de peso e associá-la uma categoria animal, e informar a data da pesagem, para obter informações sobre o peso projetado.";
    }

    if (weightGainCurve && !measuredWeight && lote.DataPesagem) {
      return "É necessário informar o peso pesado, para então obter informações sobre o peso projetado.";
    }
  };

  const openDetailsProjectedWeight = async () => {
    await obterProjecaoSemestrePorLote(lote);
    showLoteModal(lote);
  };

  return (
    <Grid container direction="column" alignItems="center">
      {(lote.PesoProjetado && lote.PesoMedido) ? (
        <>
          <Typography style={{ fontSize: 14 }}>
            {obterMensagemMesAtualProjecaoPeso(lote.PesoProjetado, lote.PesoMedido)}
          </Typography>
          <Link
            id="linkVerMaisProjecaoGanhoPeso"
            component="button"
            disabled={!lote.PesoProjetado}
            style={obtemEstiloLink(lote.PesoProjetado)}
            onClick={openDetailsProjectedWeight}
          >
            Ver mais
          </Link>
        </>
      ) : (
        <div>
          <NoProjectedWeight
            id="noProjectedWeight"
            classes={classes}
            tooltipMessage={infoMessage(lote.PesoMedido)}
          />
        </div>
      )}
    </Grid>
  );
};

const NoProjectedWeight = (props) => {
  const { classes, tooltipMessage } = props;
  return (
    <div className={classes.optionMessage}>
      <TextLabel label={"Não disponível"} />
      <Tooltip
        id="tooltip"
        title={tooltipMessage}
        classes={{ tooltip: classes.tooltipWidth }}
      >
        <InfoIcon className={classes.icon} fontSize="16"></InfoIcon>
      </Tooltip>
    </div>
  );
};

const styles = {
  tableCell: {
    paddingLeft: "20px",
  },
  optionMessage: {
    display: "flex",
    alignItems: "center",
    color: "#757575",
    fontSize: 14,
  },
  icon: {
    marginLeft: 12,
    cursor: "pointer",
  },
  tooltipWidth: {
    maxWidth: 200,
  },
};

export default withStyles(styles)(campoPesoProjetado);
