import React from "react";
import { withStyles } from "@material-ui/core";
import SelectDropdown from "components/SelectDropdown";

function CampoObjetivos({ lote, objetivos, handleAlteraLote, classes }) {
  const listaObjetivos = objetivos.map((objetivo) => ({
    value: objetivo.IdObjetivo,
    label: objetivo.Nome,
  }));

  const opcaoSelecionada = listaObjetivos.find(
    (objetivo) => objetivo.value === lote.IdObjetivo
  );

  const onChange = (objetivoSelecionado) => {
    handleAlteraLote(lote, "IdObjetivo", objetivoSelecionado);
  };

  return (
    <SelectDropdown
      isClearable
      testId="select-objetivo"
      label={{ text: opcaoSelecionada ? "Objetivo" : "Selecione" }}
      options={listaObjetivos}
      value={opcaoSelecionada}
      onChange={onChange}
      styles={{ width: "200px" }}
    />
  );
}

const styles = {
  formControl: {
    paddingTop: "9px",
  },
};

export default withStyles(styles)(CampoObjetivos);
