import auth from "../../authentication/Auth";
import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../redux/actions";
import ModalLogout from "./modalLogout";

export const modalLogoutContainer = (props) => {
  const handleOpen = () => {
    props.setModalLogout(false);
    auth.logout();
  };
  const handleClose = () => {
    props.setModalLogout(false);
  };
  return (
    <ModalLogout
      handleOpen={handleOpen}
      handleClose={handleClose}
      {...props.reduxProps}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    reduxProps: {
      modalAberto: state.modal.ModalLogoutAberto,
    },
  };
};

export default connect(mapStateToProps, actions)(modalLogoutContainer);
