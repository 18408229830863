import React from "react";
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Tooltip,
  Paper,
  withStyles
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { nomesColunasTabelaTotal } from "./balancoArrobaUtil";

const BalancoArrobaTabelaTotal = (props) => {
  const { classes, balancosFazenda } = props;

  return (
    <Paper className={classes.paperTabela}>
      <Table id="tabela_total" className={classes.tabelaTotal}>
        <TableHead className={classes.cabecalhoTabela}>
          <TableRow>
            {nomesColunasTabelaTotal.map((titulo, key) => {
              if (titulo === "PRODUÇÃO TOTAL DE ARROBAS") {
                return (
                  <TableCell
                    key={key}
                    className={classes.fechamentoArrobaCabecalho}
                  >
                    {titulo}
                    <Tooltip
                      title={
                        "Estoque Final - Estoque Inicial - Entrada + Saída"
                      }
                      placement="top"
                      arrow
                    >
                      <InfoIcon
                        className={classes.iconeInfo}
                        placement="right"
                      />
                    </Tooltip>
                  </TableCell>
                );
              }
              if (titulo === "") {
                return (
                  <TableCell
                    key={key}
                    className={classes.cabecalhoPrimeiraCelula}
                  >
                    {titulo}
                  </TableCell>
                );
              }
              return (
                <TableCell
                  key={key}
                  className={classes.cabecalhoTabelaConteudo}
                >
                  {titulo}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.quantidadeEpeso}>
            <TableCell className={classes.quantidadeEpeso}>
              QUANTIDADE (Cab)
            </TableCell>
            <TableCell className={classes.linhasQuantidadePeso}>
              {balancosFazenda.EstoqueInicial.Quantidade}
            </TableCell>
            <TableCell className={classes.linhasQuantidadePeso}>
              {balancosFazenda.Entrada.Quantidade}
            </TableCell>
            <TableCell className={classes.linhasQuantidadePeso}>
              {balancosFazenda.Saida.Quantidade}
            </TableCell>
            <TableCell className={classes.linhasQuantidadePeso}>
              {balancosFazenda.Nascimentos.Quantidade}
            </TableCell>
            <TableCell className={classes.linhasQuantidadePeso}>
              {balancosFazenda.Mortes.Quantidade}
            </TableCell>
            <TableCell className={classes.linhasQuantidadePeso}>
              {balancosFazenda.EstoqueFinal.Quantidade}
            </TableCell>
            <TableCell rowSpan={2} className={classes.fechamentoArrobaTotal}>
              {balancosFazenda.Total}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.quantidadeEpeso}>PESO (@)</TableCell>
            <TableCell className={classes.linhasQuantidadePeso}>
              {balancosFazenda.EstoqueInicial.Peso}
            </TableCell>
            <TableCell className={classes.linhasQuantidadePeso}>
              {balancosFazenda.Entrada.Peso}
            </TableCell>
            <TableCell className={classes.linhasQuantidadePeso}>
              {balancosFazenda.Saida.Peso}
            </TableCell>
            <TableCell className={classes.linhasQuantidadePeso}>
              {balancosFazenda.Nascimentos.Peso}
            </TableCell>
            <TableCell className={classes.linhasQuantidadePeso}>
              {balancosFazenda.Mortes.Peso}
            </TableCell>
            <TableCell className={classes.linhasQuantidadePeso}>
              {balancosFazenda.EstoqueFinal.Peso}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

const styles = (theme) => {
  return {
    paperTabela: {
      borderRadius: "16px",
    },
    tabelaTotal: {
      width: "100%",
      fontFamily: "Roboto",
      textAlign: "center",
      padding: "4px 24px 4px 24px",
      fontSize: "14px",
      borderRadius: "16px",
    },
    cabecalhoTabela: {
      marginTop: "25px",
      marginBottom: "25px",
      padding: "4px 24px 4px 24px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      backgroundColor: "#e1e1e1",
      borderRadius: "16px",
    },
    cabecalhoTabelaConteudo: {
      backgroundColor: "#e1e1e1",
      color: theme.palette.primary.main,
      padding: "4px 24px 4px 24px",
      fontSize: "14px",
      fontWeight: "500",
      letterSpacing: "1px",
      textAlign: "center",
    },
    fechamentoArrobaCabecalho: {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
      fontSize: "14px",
      textAlign: "center",
      fontWeight: "500",
      letterSpacing: "1px",
      paddingTop: "4px",
      paddingBottom: "4px",
      borderRadius: "0 16px 0 0",
    },
    cabecalhoPrimeiraCelula: {
      backgroundColor: "#e1e1e1",
      color: theme.palette.primary.main,
      padding: "4px 24px 4px 24px",
      fontSize: "14px",
      fontWeight: "500",
      letterSpacing: "1px",
      textAlign: "center",
      borderRadius: "16px 0 0 0 ",
    },
    iconeInfo: {
      fontSize: "14px",
      position: "relative",
      color: "#fff",
    },
    linhasQuantidadePeso: {
      color: "#666",
      textAlign: "center",
      fontSize: "18px",
      fontWeight: "500",
      letterSpacing: "1px",
      padding: "4px 24px 4px 24px",
    },
    quantidadeEpeso: {
      borderBottom: "1px solid #e5e5ea",
      color: theme.palette.primary.main,
      padding: "4px 4px",
      fontSize: "14px",
      fontWeight: "500",
      letterSpacing: "1px",
      textAlign: "center",
      width: "15%",
    },
    fechamentoArrobaTotal: {
      textAlign: "center",
      borderLeft: "1px solid #e5e5ea",
      padding: "4px 24px 4px 24px",
      fontSize: "21px",
      fontWeight: "500",
      lineHeight: "normal",
      color: theme.palette.primary.main,
      borderRadius: "0 0 0 16px",
    },
  };
};

export default withStyles(styles)(BalancoArrobaTabelaTotal);
