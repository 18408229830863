import React, { useState } from "react";
import { Typography, Grid, withStyles } from "@material-ui/core";
import Carousel from "nuka-carousel";
import Icon from "@material-ui/core/Icon";
import ModalFoto from "./modalFoto";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

export const ItemFotoMorte = (props) => {
  const {
    coleta,
    classes,
    listaIdFotosPasto,
    idColetaFoto,
    nomePasto,
    coletasPastoSelecionado,
  } = props;

  const [state, setState] = useState({
    urlAnimal: `${process.env.REACT_APP_ENDPOINT_BUCKET}/MORTE/${idColetaFoto}/imgCARCACA.png`,
    urlBrinco: `${process.env.REACT_APP_ENDPOINT_BUCKET}/MORTE/${idColetaFoto}/imgBRINCO.png`,
    urlAnimalExiste: true,
    urlBrincoExiste: true,
    modal: false,
    slideIndex: 0,
    idColetaFotoAtivo: 0,
    botaoSlideAnteriorVisivel: false,
    botaoProximoSlideVisivel: true,
  });

  const toggleModal = (index, idColetaFoto) => {
    setState((prevState) => ({
      ...prevState,
      modal: !prevState.modal,
      slideIndex: index,
      idColetaFotoAtivo: idColetaFoto,
    }));
  };

  const renderCenterLeftControls = ({ previousSlide }) =>
    state.botaoSlideAnteriorVisivel ? (
      <Grid
        onClick={() => slideAnterior(previousSlide)}
        className={classes.gridBotaoCarousel}
      >
        <Icon style={{ color: "#ffffff", fontSize: "36px" }}>
          navigate_before
        </Icon>
      </Grid>
    ) : null;

  const slideAnterior = (previousSlide) => {
    setState((prevState) => ({
      ...prevState,
      botaoSlideAnteriorVisivel: false,
      botaoProximoSlideVisivel: true,
    }));
    previousSlide();
  };

  const renderCenterRightControls = ({ nextSlide }) =>
    state.botaoProximoSlideVisivel ? (
      <Grid
        onClick={() => proximoSlide(nextSlide)}
        className={classes.gridBotaoCarousel}
      >
        <Icon style={{ color: "#ffffff", fontSize: "36px" }}>
          navigate_next
        </Icon>
      </Grid>
    ) : null;

  const proximoSlide = (nextSlide) => {
    setState((prevState) => ({
      ...prevState,
      botaoSlideAnteriorVisivel: true,
      botaoProximoSlideVisivel: false,
    }));
    nextSlide();
  };

  const renderBottomCenterControls = ({ currentSlide }) => (
    <Grid style={styles.gridSvg}>
      <svg
        width={8}
        height={8}
        className={
          currentSlide === 0
            ? classes.controlCarouselSelecionado
            : classes.controlCarousel
        }
      >
        <circle cx={4} cy={4} r={4}></circle>
      </svg>
      <svg
        width={8}
        height={8}
        className={
          currentSlide === 1
            ? classes.controlCarouselSelecionado
            : classes.controlCarousel
        }
      >
        <circle cx={4} cy={4} r={4}></circle>
      </svg>
    </Grid>
  );

  const getFotoIndex = (idColetaFoto) => {
    if (listaIdFotosPasto.find((foto) => foto === idColetaFoto))
      return listaIdFotosPasto.indexOf(idColetaFoto) * 2;
    else return 0;
  };

  const substituiFotoAnimal = () => {
    setState((prevState) => ({ ...prevState, urlAnimalExiste: false }));
  };

  const substituiFotoBrinco = () => {
    setState((prevState) => ({ ...prevState, urlBrincoExiste: false }));
  };

  const getStyleImgAnimal = () =>
    state.urlAnimalExiste ? classes.imagemCard : classes.semImagem;

  const getStyleImgBrinco = () =>
    state.urlBrincoExiste ? classes.imagemCard : classes.semImagem;

  const dataColeta = new Date(coleta.DataColeta).toLocaleDateString();

  return (
    <Card className={classes.card}>
      <Carousel
        renderCenterLeftControls={renderCenterLeftControls}
        renderCenterRightControls={renderCenterRightControls}
        renderBottomCenterControls={renderBottomCenterControls}
        cellAlign="center"
        className={classes.carousel}
        id="carousel"
      >
        <Grid container justify="center" style={styles.gridImg}>
          <img
            id="img_animal"
            src={
              state.urlAnimalExiste
                ? state.urlAnimal
                : "../images/sem-imagem.svg"
            }
            alt="img_animal"
            onError={substituiFotoAnimal}
            className={getStyleImgAnimal()}
            onClick={() =>
              toggleModal(
                getFotoIndex(coleta.IdColetaFoto),
                coleta.IdColetaFoto
              )
            }
          />
        </Grid>
        <Grid container justify="center" style={styles.gridImg}>
          <img
            id="img_brinco"
            src={
              state.urlBrincoExiste
                ? state.urlBrinco
                : "../images/sem-imagem.svg"
            }
            alt="img_brinco"
            onError={substituiFotoBrinco}
            className={getStyleImgBrinco()}
            onClick={() =>
              toggleModal(
                getFotoIndex(coleta.IdColetaFoto) + 1,
                coleta.IdColetaFoto
              )
            }
          />
        </Grid>
      </Carousel>
      <CardContent>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            align="left"
            className={classes.infoCardCategoria}
            id="nomeCategoria"
          >
            {coleta.Categoria ? coleta.Categoria.toUpperCase() : ""}
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.gridInfoCard}>
          <Typography
            variant="caption"
            align="left"
            className={classes.labelInfoCard}
          >
            {"Causa: "}
          </Typography>
          <Typography variant="caption" align="left" id="causa">
            {coleta.Causa}
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.gridInfoCard}>
          <Typography
            variant="caption"
            align="left"
            className={classes.labelInfoCard}
          >
            {"Código: "}
          </Typography>
          <Typography variant="caption" align="left" id="codigo">
            {coleta.Identificador ? coleta.Identificador : " - "}
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.gridInfoCard}>
          <Typography
            variant="caption"
            align="left"
            className={classes.labelInfoCard}
          >
            {"Coleta: "}
          </Typography>
          <Typography variant="caption" align="left" id="dataColeta">
            {dataColeta}
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.gridInfoCard}>
          <Typography
            variant="caption"
            align="left"
            className={classes.labelInfoCard}
          >
            Pendencia:
            {coleta.ColetaComPendencia && coleta.MotivoDaPendencia ? (
              <span className={classes.textoPendencia}>
                {coleta.MotivoDaPendencia}
              </span>
            ) : (
              "-"
            )}
          </Typography>
        </Grid>

        {state.modal && (
          <ModalFoto
            id="modal"
            idColetaFoto={idColetaFoto}
            nomePasto={nomePasto}
            coleta={coleta}
            coletasPastoSelecionado={coletasPastoSelecionado}
            listaIdFotosPasto={listaIdFotosPasto}
            modal={state.modal}
            toggleModal={() => toggleModal()}
            slideIndex={state.slideIndex}
            idColetaFotoAtivo={state.idColetaFotoAtivo}
            getFotoIndex={getFotoIndex}
          />
        )}
      </CardContent>
    </Card>
  );
};

const styles = {
  card: {
    width: "90%",
    height: "100%",
  },
  carousel: {
    minHeight: "297px",
    maxHeight: "45% !important",
    width: "100%",
  },
  imagemCard: {
    width: "100%",
    cursor: "default",
  },
  labelInfoCard: {
    fontWeight: "bold",
  },
  gridInfoCard: {
    display: "flex",
    gap: "5px",
  },
  infoCardCategoria: {
    color: "#424242",
    fontSize: "16px",
    fontWeight: "500",
    marginBottom: "10px",
  },
  gridImg: {
    height: "200px",
  },
  gridBotaoCarousel: {
    flexDirection: "row",
    alignItems: "center",
    width: "45px",
    height: "45px",
    display: "flex",
    cursor: "pointer",
  },
  controlCarousel: {
    cursor: "default",
    opacity: 0.5,
    background: "transparent",
    border: "none",
    fill: "white",
    borderRadius: "10px",
    boxShadow: "rgb(8, 8, 8) 0px 0px 2px 0px",
  },
  controlCarouselSelecionado: {
    cursor: "default",
    opacity: 1,
    background: "transparent",
    border: "none",
    fill: "white",
    borderRadius: "10px",
    boxShadow: "rgb(8, 8, 8) 0px 0px 2px 0px",
  },
  semImagem: {
    width: "24px",
    height: "24px",
    opacity: 0.9,
    alignSelf: "center",
  },
  gridSvg: {
    display: "flex",
    justifyContent: "space-between",
    width: "25px",
    marginBottom: "5px",
  },
  textoPendencia: {
    color: "#EB394B",
    fontStyle: "italic",
    fontWeight: "400",
    marginLeft: "2px",
  },
};

export default withStyles(styles)(ItemFotoMorte);
