import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  withStyles
} from "@material-ui/core/";
import moment from "moment";

export const tabelaDescritivoLote = (props) => {
  const { classes, operacoesCategoria } = props;
  return (
    <div className={classes.boxTabela}>
      <div className={classes.divTable}>
        <Table className={classes.tabela}>
          <TableHead id="cabecalhoTableDescritivoLote">
            <TableRow>
              <TableCell align="center" className={classes.headerTipo}>
                TIPO DE OPERAÇÃO
              </TableCell>
              <TableCell align="center" className={classes.headerAppPortal}>
                APP/PORTAL
              </TableCell>
              <TableCell align="center" className={classes.headerTipo}>
                PASTO
              </TableCell>
              <TableCell align="center" className={classes.headerData}>
                DATA
              </TableCell>
              <TableCell align="center" className={classes.headerQuantidade}>
                QUANTIDADE
              </TableCell>
              <TableCell align="center" className={classes.headerPeso}>
                PESO
              </TableCell>
              <TableCell align="center" className={classes.headerUsuario}>
                USUÁRIO
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="corpoTableDescritivoLote">
            {operacoesCategoria.map((operacao, j) =>
              getLinhaOperacaApp(j, classes, operacao)
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

const getLinhaOperacaApp = (j, classes, operacao) => {
  // fazer if com os pesos recebidos e default sendo traço colocar o tooltip
  const mostrarPeso = () => {
    if (operacao.PesoMedido) {
      return (
        <Tooltip title={"Peso Pesado"} className={classes.row}>
          <div width="50px" id={`peso-${j}`}>
            {operacao.PesoMedido} P
          </div>
        </Tooltip>
      );
    }
    if (operacao.PesoVisual === 0 || operacao.PesoVisual === null) {
      return (
        <Tooltip title={"Peso não registrado"} className={classes.row}>
          <div id={`peso-${j}`} width="50px">
            {" "}
            -{" "}
          </div>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={"Peso Visual"} className={classes.row}>
          <div id={`peso-${j}`} width="50px">
            {operacao.PesoVisual} V
          </div>
        </Tooltip>
      );
    }
  };

  return (
    <TableRow key={`linha-${j}`}>
      <TableCell id={`tipo-${j}`} align="left" className={classes.tipoRow}>
        {operacao.TipoOperacao}
      </TableCell>
      <TableCell id={`origem-${j}`} align="center" className={classes.row}>
        {operacao.Origem}
      </TableCell>
      <TableCell id={`pasto-${j}`} align="center" className={classes.row}>
        {operacao.PastoOperacao ? operacao.PastoOperacao.Nome : "-"}
      </TableCell>
      <TableCell id={`data-${j}`} align="center" className={classes.row}>
        {moment(operacao.DataOperacao || operacao.DataRegistro).format(
          "DD/MM/YYYY"
        )}
      </TableCell>
      <TableCell id={`quantidade-${j}`} align="center" className={classes.row}>
        {operacao.Quantidade}
      </TableCell>
      <TableCell align="center" className={classes.row}>
        {mostrarPeso()}
      </TableCell>
      <TableCell id={`usuario-${j}`} align="center" className={classes.userRow}>
        {operacao.UsuarioCriacao.includes("script:")
          ? "sistema"
          : operacao.UsuarioCriacao}
      </TableCell>
    </TableRow>
  );
};

const styles = (theme) => {
  const header = {
    height: "auto",
    backgroundColor: "#e1e1e1",
    fontSize: "12px",
    color: theme.palette.primary.main,
    fontFamily: "Roboto",
    fontWeight: 500,
    letterSpacing: "1px",
  };
  const row = {
    fontFamily: "Roboto",
    fontSize: "14px",
    color: "#333333",
    width: "45px",
  };
  return {
    row,
    boxTabela: {
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.2)",
      height: "300px",
      overflow: "hidden",
      borderRadius: "16px",
    },
    tabela: {
      position: "relative",
      tableLayout: "fixed",
      width: "100%",
      borderRadius: "3px",
      border: "solid 0.5px #e5e5ea",
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.2)",
      background: "#fff",
    },
    tipoRow: {
      ...row,
      textTransform: "capitalize",
    },
    userRow: {
      ...row,
      wordBreak: "break-all",
    },
    headerTipo: {
      ...header,
      width: "140px",
    },
    headerAppPortal: {
      ...header,
      width: "120px",
    },
    headerData: {
      ...header,
      width: "102px",
    },
    headerQuantidade: {
      ...header,
      width: "112px",
    },
    headerPeso: {
      ...header,
      width: "50px",
    },
    headerUsuario: {
      ...header,
      width: "172px",
    },
    conteudoMes: {
      height: "auto",
      backgroundColor: "#fafafa",
      fontFamily: "Roboto",
      fontSize: "14px",
      color: "#fff",
      border: "solid 0.5px #e5e5ea",
    },
    conteudoPeso: {
      height: "auto",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "bold",
      color: "#fff",
      border: "solid 0.5px #e5e5ea",
    },
    divTable: {
      overflow: "auto",
      maxHeight: 270,
    },
  };
};

export default withStyles(styles)(tabelaDescritivoLote);
