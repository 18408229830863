import * as tableauReportActions from './tableauReportActions';

export const INITIAL_STATE = {
  RelatorioBaixado: null,
  Relatorios: []
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case tableauReportActions.OBTER_RELATORIO:
      return { ...state, RelatorioBaixado: action.payload.relatorio };
    case tableauReportActions.OBTER_RELATORIOS_TABLEAU:
      return { ...state, Relatorios: action.payload.relatorios }
    default:
      return state;
  }
};
