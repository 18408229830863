export default function(Coordenadas) {
  let lowx,
    highx,
    lowy,
    highy,
    center_x,
    center_y,
    lats = [],
    lngs = [];


  for (var i = 0; i < Coordenadas.length; i++) {
    lngs.push(Coordenadas[i].lng);
    lats.push(Coordenadas[i].lat);
  }

  lats.sort();
  lngs.sort();
  lowx = lats[0];
  highx = lats[Coordenadas.length - 1];
  lowy = lngs[0];
  highy = lngs[Coordenadas.length - 1];
  center_x = lowx + (highx - lowx) / 2;
  center_y = lowy + (highy - lowy) / 2;
  return { lat: center_x, lng: center_y };
}
