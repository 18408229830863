import FormulaService from "./formulaService";
import _ from "lodash";

class FormulaManager {
  async adicionarFormula(formulaCriada) {
    const respostaService = await FormulaService.adicionarFormula(
      formulaCriada
    );
    return respostaService.data;
  }

  async atualizarFormula(idFormula, formulaAtualizada) {
    const formula = _.omit(formulaAtualizada, ["IdFormula"]);
    const respostaService = await FormulaService.atualizarFormula(
      idFormula,
      formula
    );
    return respostaService.data ? respostaService.data : [];
  }

  async excluirFormula(idFormula) {
    await FormulaService.excluirFormula(idFormula);
  }

  async desexcluirFormula(idFormula) {
    await FormulaService.desexcluirFormula(idFormula);
  }

  async obterFormulasFazenda(fazendaSelecionada, pegarFormulasDeletadas) {
    const respostaService = await FormulaService.listarFormulasPorFazenda(
      fazendaSelecionada,
      pegarFormulasDeletadas
    );
    return respostaService.data ? respostaService.data.body : [];
  }

  async obterEpocasFormula() {
    const response = await FormulaService.listarEpocas();
    return response.data;
  }

  async obterCategoriasFormula() {
    const response = await FormulaService.listarCategoriaFormula();
    return response.data;
  }
}

export default new FormulaManager();
