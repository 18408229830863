import CurralService from "./curralService";
import * as _ from "lodash";

export class CurralManager {
  async obterLotesPorCurrais(currais) {
    const lotes = await CurralService.listaLotesPorCurrais(currais);

    const lotesDoCurral = [];

    _.map(lotes, lote => {
      const curral = currais.filter(c => c.IdPasto === lote.IdPasto);
      if (curral) {
        lotesDoCurral.push(lote);
      }
    });

    return lotesDoCurral;
  }

  async salvarCurral(curral) {
    const respostaService = await CurralService.adicionarCurral(curral);
    return respostaService.data;
  }

  async editarCurral(curral, idCurral) {
    await CurralService.editarCurral(curral, idCurral);
  }

  async excluirCurral(idCurral) {
    await CurralService.deletarCurral(idCurral);
  }
}

export default new CurralManager();
