import React from 'react';
import { FazendaSelecionadaContainer } from '../../shared/crossCuttingComponents';
import { AppBar as AppBarCustomizado } from '../../layout/index';
import { Toolbar, AppBar, Typography, withStyles } from '@material-ui/core';
import { AppBarConteudoDefault } from '../../layout/';

export const fechamentoConsumoConteudoAppBar = props => {
  const { classes } = props;

  const renderConteudoCustomizadoAppBar = () => {
    return (
      <FazendaSelecionadaContainer
        render={fazenda => {
          const textoSecundarioAppBar = fazenda
            ? fazenda && fazenda.NomeReduzido
            : 'NENHUMA FAZENDA SELECIONADA';
          return (
            <AppBarConteudoDefault
              id="fechamentoConsumoNomeFazenda"
              tituloAppBar={textoSecundarioAppBar}
            />
          );
        }}
      />
    );
  };
  return (
    <>
      <AppBarCustomizado
        classes={classes}
        conteudoCustomizadoAppBar={renderConteudoCustomizadoAppBar()}
        id="appBarPersonalizado"
      />
      <AppBar position="static" className={classes.AppBar}>
        <Toolbar>
          <Typography
            variant="h6"
            className={classes.TextoAppBar}
          >
            Fechamento de Consumo
          </Typography>
        </Toolbar>
      </AppBar>
    </>
  );
};

const styles = theme => {
  return {
    nomeFazenda: {
      color: '#444444',
      fontFamily: 'Roboto-Medium',
      fontSize: 18,
      fontWeight: 500,
    },
    AppBar: {
      backgroundColor: '#f8f8f8'
    },
    TextoAppBar: {
      fontWeight: 'normal',
      fontSize: '24px',
      letterSpacing: '-0.05px',
    },
  };
};
export default withStyles(styles)(fechamentoConsumoConteudoAppBar);
