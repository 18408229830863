import { EXIBIR_CARREGANDO } from "../../../redux/actions/actionsConstants";

export const LISTAR_INGREDIENTES = "LISTAR_INGREDIENTES";
export const LISTAR_INGREDIENTE_PADROES = "LISTAR_INGREDIENTE_PADROES";
export const LISTAR_HISTORICO_INGREDIENTE_PADROES =
  "LISTAR_HISTORICO_INGREDIENTE_PADROES";

export const listarIngredientes = (Ingredientes) => ({
  type: LISTAR_INGREDIENTES,
  payload: {
    Ingredientes,
  },
});

export const listarIngredientesPadroes = (IngredientesPadroes) => ({
  type: LISTAR_INGREDIENTE_PADROES,
  payload: {
    IngredientesPadroes,
  },
});

export const listarHistoricoIngredientes = (historicoIngredientes) => ({
  type: LISTAR_HISTORICO_INGREDIENTE_PADROES,
  payload: {
    historicoIngredientes,
  },
});

export const exibirCarregando = (exibir) => {
  return {
    type: EXIBIR_CARREGANDO,
    payload: exibir,
  };
};
