import React from "react";
import Info from "@material-ui/icons/Info";
import history from "../../../../app/History";
import { Button, withStyles } from "@material-ui/core";

const MENSAGEM_SEM_INGREDIENTES = "Você não possui ingredientes cadastrados. Clique no botão abaixo para ser direcionado para a tela de cadastro de ingredientes.";

const WarningWithoutIngredients = ({
  ingredientes = [],
  classes,
  selecionaAba
}) => {

  if (ingredientes.length > 0) return null;

  const handleClick = () => {
    selecionaAba("Ingredientes");
    history.push("/configuracoes/cadastros");
  }

  return (
    <div data-testid="alerta-sem-ingredientes">
      <div className={classes.contentText}>
        <Info className={classes.icon} />
        <span className={classes.text}>{MENSAGEM_SEM_INGREDIENTES}</span>
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
      >Ir para ingredientes</Button>
    </div>
  )
}

const styles = () => ({
  container: {
    display: "flex",
    alignItems: "center"
  },
  contentText: {
    display: "flex",
    alignItems: "center",
    marginBottom: "24px"
  },
  icon: {
    color: "#ce2d24",
    fontSize: "18px",
    margin: "6px 4px 30px 0",
  },
  text: {
    color: "#ce2d24",
    fontSize: "13px",
    width: "430px",
    height: "48px",
    margin: "0 0 0 4px",
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.85,
  }
})

export default withStyles(styles)(WarningWithoutIngredients);
