import React, { useEffect, useMemo, useState } from "react";
import PastosFarol from "./PastosFarol";
import { ColetaMarcadores } from "./components/coletaMarcadores";
import { CustomControls } from "./components/customControls";
import constantsFarolAlturaPasto from "constants/mapaFarolAlturaPasto";

const MapaContent = ({
  retiroSelecionado,
  farolSelecionado,
  coletas,
  pastos,
  farois,
  TipoIconeExibido,
  zoomExibeIconePastoFarol,
  forragens,
  TipoFarol,
  pastosPorCocho,
  periodoColeta,
  visualizarPontosColetas,
  setCentroMap,
  atualizaZoom,
}) => {
  const [pastoMouseOver, setPastoMouseOver] = useState(null);
  const [filtroColetas, setFiltroColetas] = useState(constantsFarolAlturaPasto.collect.collect_filter.all);

  useEffect(() => {
    if (!visualizarPontosColetas) {
      setFiltroColetas(constantsFarolAlturaPasto.collect.collect_filter.all);
    }
  }, [visualizarPontosColetas])

  const pastosComColetas = useMemo(() => {
    return pastos.map(pasto => ({ ...pasto, ColetaAlturaPasto: coletas.find((coleta) => coleta.pasto.IdPasto === pasto.IdPasto) }))
  }, [pastos, coletas])

  const onMouseOverPasto = (over, pasto, farolPasto) => {
    if (over) {
      setPastoMouseOver({ pasto, farolPasto });
    } else {
      setPastoMouseOver(null);
    }

  }

  return <>
    <PastosFarol
      retiroSelecionado={retiroSelecionado}
      farolSelecionado={farolSelecionado}
      pastos={pastosComColetas}
      farois={farois}
      TipoIconeExibido={TipoIconeExibido}
      zoomExibeIconePastoFarol={zoomExibeIconePastoFarol}
      visualizarPontosColetas={visualizarPontosColetas}
      forragens={forragens}
      TipoFarol={TipoFarol}
      pastosPorCocho={pastosPorCocho}
      onMouseOverPasto={onMouseOverPasto}
    />
    {visualizarPontosColetas && (
      <ColetaMarcadores
        retiroSelecionado={retiroSelecionado}
        filtroColetas={filtroColetas}
        tipoIconeExibido={TipoIconeExibido}
        coletas={coletas}
        periodoColeta={periodoColeta}
        visualizarPontosColetas={visualizarPontosColetas}
        pastos={pastosComColetas}
        forragens={forragens}
        setCentroMap={setCentroMap}
        atualizaZoom={atualizaZoom}
        farolSelecionado={farolSelecionado}
        farois={farois}
        pastoMouseOver={pastoMouseOver}
        onMouseOverPasto={onMouseOverPasto}
      />
    )}

    <CustomControls
      showAllButons={visualizarPontosColetas}
      setFiltro={setFiltroColetas}
      filtroColetas={filtroColetas}
    />

  </>
}

export default MapaContent;
