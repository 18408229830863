import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Create";
import SwitchCustomizado from "features/shared/components/SwitchCustomizado";
import { Paper, withStyles } from "@material-ui/core";

export const causaMorteTabela = (props) => {
  const {
    classes,
    causasMorteFiltradas,
    adicionando,
    habilitarEdicao,
    ativaDesativaCausaMorte,
  } = props;
  return (
    <Paper>
      <div className={classes.divTable}>
        <Table id="#tabelaCausaMorte" className={classes.table}>
          <TableHead className={classes.tabelaHeader}>
            <TableRow>
              <TableCell className={classes.textoHeader}>Causa</TableCell>
              <TableCell className={classes.textoHeader}>Editar</TableCell>
              <TableCell className={classes.textoHeader}>Ativo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {causasMorteFiltradas.map((c) => (
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.colunaNome}
                >
                  {c.Nome}
                </TableCell>
                <TableCell style={{ width: "5%" }}>
                  <IconButton
                    id="botaoHabilitar"
                    aria-label="Edit"
                    disabled={adicionando}
                    onClick={() => habilitarEdicao(c)}
                    className={classes.margin}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell style={{ width: "5%" }}>
                  <SwitchCustomizado
                    id={`switch-${c.IdCausaMorte}`}
                    defaultChecked={!c.Excluido}
                    checked={!c.Excluido}
                    handleClick={() =>
                      ativaDesativaCausaMorte(c.IdCausaMorte, c.Excluido, c)
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

const styles = (theme) => {
  return {
    table: {
      width: "100%",
      overflow: "hidden",
    },
    tabelaHeader: {
      backgroundColor: "#e1e1e1",
      fontFamily: "Roboto",
      height: 56,
    },
    colunaNome: {
      width: "90%",
      textTransform: "uppercase",
      color: "#757575",
    },
    textoHeader: {
      fontSize: 14,
      fontWeight: 500,
      textTransform: "uppercase",
      top: 0,
      position: "sticky",
      zIndex: 10,
      color: theme.palette.primary.main,
      backgroundColor: "#e1e1e1",
    },
    link: {
      width: "100%",
      textAlign: "left",
    },
    defaultCell: {
      top: 0,
      position: "sticky",
      zIndex: 10,
      color: theme.palette.primary.main,
      backgroundColor: "#e1e1e1",
    },
    divTable: {
      overflow: "auto",
      maxHeight: 600,
    },
  };
};

export default withStyles(styles)(causaMorteTabela);
