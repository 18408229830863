import React, { useMemo } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import LogoProdap from "../../../../layout/views/logoProdap";
import { TextLabel } from "../../../../shared/components";
import CampoStatusSanidade from "../../batchListingTable/components/campoStatusSanidade";
import moment from "moment";
import { obterMensagemMesAtualProjecaoPeso } from "features/lote/loteUtils";

const headers = [
  "LOTES",
  "QTDE",
  "SANIDADE",
  "OBJETIVO",
  "PESO OBJETIVO",
  "PESO PESADO",
  "DATA DA PESAGEM",
  "PESO PROJETADO",
];

const LoteListagemPrintTemplate = (props) => {
  const {
    classes,
    fazenda,
    lotes,
    exibirColunaPesoProjetado,
    exibirColunaSanidade,
    objetivos,
    filtroPesoSelecionado,
    filtroTexto,
    retiroSelecionado,
    filtroPesoProjetadoSelecionado,
  } = props;

  const getPageMargins = () => {
    return `
    @media all {
      .page-break {
        display: none;
      }
    }
    @media print {
      html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
      .page-break {
        display: block;
        page-break-before: auto;
      }
    }
    @page {
      size: auto;
      margin: 6mm 0 0 0;
      size: portrait;
    }
    `;
  };

  const nomeFazendaMemo = useMemo(() => {
    return (fazenda.Fazendas || []).find(
      (v) => v.IdFazenda === fazenda.FazendaSelecionada
    ).NomeReduzido;
  });

  const pegaObjetivo = (lote) => {
    const nomeObjetivo = objetivos.find(
      (objetivo) => Number(lote.IdObjetivo) === objetivo.IdObjetivo
    );
    return nomeObjetivo ? nomeObjetivo.Nome : "-";
  };

  const formatMessageProjectedWeight = (peso) => {
    const mensagem = obterMensagemMesAtualProjecaoPeso(peso);
    const pesos = mensagem.split("-");
    return pesos;
  };

  return (
    <div>
      <style>{getPageMargins()}</style>
      <div className={classes.container}>
        <div className={classes.logoContainer}>
          <LogoProdap genericClass={classes.logo} id="logoPropdapAppBar" />
        </div>
        <div className={classes.titleContainer}>
          <TextLabel bold size={21} style={{ flex: 2 }} id="tituloDoAppBar">
            {nomeFazendaMemo}
          </TextLabel>
        </div>
        <div className={classes.filtroLinha}>
          <TextField
            value={retiroSelecionado}
            id="filtroRetiro"
            className={classes.filtroPrint}
            label={"Retiro"}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={() => { }}
          />
          <TextField
            value={filtroTexto}
            id="filtroNome"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: 224 }}
            className={classes.filtroPrint}
            label={"Categoria ou pastos"}
            onChange={() => { }}
          />
          <TextField
            value={filtroPesoSelecionado}
            InputLabelProps={{
              shrink: true,
            }}
            id="filtroPeso"
            className={classes.filtroPrint}
            label={"Peso Pesado"}
            onChange={() => { }}
          />
          <TextField
            value={filtroPesoProjetadoSelecionado}
            InputLabelProps={{
              shrink: true,
            }}
            id="filtroPesoPesado"
            className={classes.filtroPrint}
            label={"Peso Projetado"}
            onChange={() => { }}
          />
        </div>
        <Paper className={classes.paper} style={{ margin: "16px" }}>
          <div className={classes.divTable}>
            <Table className={classes.table}>
              <TableHead
                id="cabecalhoTabela2"
                className={classes.headLotes}
                color="primary"
              >
                <TableRow>
                  {headers.map((header, index) => {
                    if (
                      (index === 2 && !exibirColunaSanidade) ||
                      (index === 7 && !exibirColunaPesoProjetado)
                    )
                      return null;
                    else
                      return (
                        <TableCell
                          padding="checkbox"
                          align="left"
                          className={classes.headerLotes}
                        >
                          {header}
                        </TableCell>
                      );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {lotes.map((lote, index) => {
                  return (
                    <TableRow
                      key={index}
                      id={"linhasTabelaPrint-" + index}
                      className={classes.gridTableRow}
                    >
                      <TableCell padding="checkbox" align="left">
                        <div className={classes.cellNome}>
                          <TextLabel
                            bold
                            label={lote.categoria.Nome}
                            className={classes.text}
                          />
                          <TextLabel
                            label={
                              lote.pasto.NomeReduzido
                                ? lote.pasto.NomeReduzido.toLowerCase()
                                : ""
                            }
                            className={classes.text}
                          />
                        </div>
                      </TableCell>
                      <TableCell
                        padding="checkbox"
                        align="left"
                        className={classes.quantidade}
                      >
                        {lote.Quantidade}
                      </TableCell>
                      <CampoStatusSanidade
                        exibirColunaSanidade={exibirColunaSanidade}
                        lote={lote}
                      />
                      <TableCell padding="checkbox" align="left">
                        <div className={classes.cellObjetivo}>
                          <Typography>{pegaObjetivo(lote)}</Typography>
                        </div>
                      </TableCell>
                      <TableCell padding="checkbox" align="left">
                        <div className={classes.quantidade}>
                          <Typography>
                            {(lote.PesoObjetivo || "0") + " Kg"}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell
                        padding="checkbox"
                        align="left"
                        className={classes.quantidade}
                      >
                        {lote.PesoMedido ? lote.PesoMedido + "Kg" : "-"}
                      </TableCell>
                      <TableCell
                        padding="checkbox"
                        align="left"
                        className={classes.quantidade}
                      >
                        {lote.DataPesagem
                          ? moment(lote.DataPesagem).format("DD/MM/YYYY")
                          : "-"}
                      </TableCell>
                      <TableCell>
                        <Grid
                          container
                          direction="column"
                          style={{ textAlign: "center" }}
                        >
                          {lote.PesoProjetado ? (
                            <>
                              <Typography style={{ fontSize: 14 }}>
                                {formatMessageProjectedWeight(
                                  lote.PesoProjetado
                                )}
                              </Typography>
                            </>
                          ) : (
                            <div>
                              <div className={classes.optionMessage}>
                                <TextLabel label={"Não disponível"} />
                              </div>
                            </div>
                          )}
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    </div>
  );
};

const style = (theme) => {
  return {
    container: {
      maxWidth: "1200px",
      boxSizing: "border-box",
      paddingTop: "2rem",
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 90,
      width: "100%",
    },
    logo: {
      width: "101px",
      height: "35px",
      cursor: "pointer",
    },
    logoContainer: {
      marginTop: 21,
      marginLeft: "1rem",
      position: "absolute",
    },
    headerLotes: {
      fontWeight: 500,
      fontSize: 14,
      color: theme.palette.primary.main,
      backgroundColor: "#e1e1e1",
    },
    cellObjetivo: {
      display: "flex",
      height: 84,
      alignItems: "center",
    },
    cellNome: {
      display: "flex",
      flexDirection: "column",
      height: 84,
      justifyContent: "center",
    },
    filtroLinha: {
      margin: "0px 16px",
      height: 120,
      display: "flex",
      alignItems: "center",
    },
    filtroPrint: {
      marginRight: 32,
    },
    optionMessage: {
      display: "flex",
      alignItems: "center",
      color: "#757575",
      fontSize: 14,
    },
    gridTableRow: {
      height: 90,
    },
    quantidade: {
      color: "#757575",
      fontSize: 14,
    },
    paper: {
      overflow: "hidden",
      borderRadius: "16px",
    },
    table: {
      width: "100%",
      marginBottom: 25,
      fontSize: 14,
    },
    divTable: {
      overflow: "auto",
    },
  };
};

export default withStyles(style)(LoteListagemPrintTemplate);
