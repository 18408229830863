import React, { useEffect } from "react";
import moment from "moment";
import { useGoalPlanDataForm } from "features/goalPlan/hooks/useGoalPlanDataForm";
import useGoalsTable from "features/goalPlan/hooks/useGoalsTable";
import getValues from "../../../shared/getValuesTable";
import GoalPlanDetailsPage from "features/goalPlan/views/goalPlanRevision/components/goalPlanDetails/goalPlanDetailsPage";
import goalPlanService from "features/goalPlan/goalPlanService";
import { getGoalPlanSituation } from "features/goalPlan/goalPlanUtils";

const GoalPlanDetailsContainer = ({
  onBack,
  title,
  goalPlanId,
  badge,
  enableLoading,
  notifyError,
  disableLoading,
}) => {
  const { goalPlanData, periodTitles, setGoalPlanData } = useGoalPlanDataForm();

  const { freeIndicators, parameters, linesGoals, setLinesGoals, indicators } =
    useGoalsTable(enableLoading, notifyError, disableLoading);

  const findPlan = async () => {
    if (!goalPlanId) return;
    try {
      enableLoading("findInitialPlan");
      const plan = await goalPlanService.getPlan(goalPlanId);
      setLinesGoals(plan.goals);
      delete plan.goals;
      setGoalPlanData({
        ...plan,
        initialDate: moment(plan.initialDate),
        finalDate: moment(plan.finalDate),
        situation: getGoalPlanSituation(plan)
      });
    } catch (error) {
      notifyError(error);
    } finally {
      disableLoading("findInitialPlan");
    }
  };

  useEffect(() => {
    findPlan();
  }, [goalPlanId]);

  return (
    <GoalPlanDetailsPage
      title={title}
      badge={badge}
      goalPlanData={goalPlanData}
      goalsTableList={linesGoals}
      columns={getValues({
        freeIndicators,
        parameters,
        periodTitles,
        disabled: true,
        indicators,
      })}
      onBack={onBack}
    />
  );
};

export default GoalPlanDetailsContainer;
