import React from "react";
import { withStyles } from "@material-ui/core";
import TabelaProdap from "features/shared/components/TabelaProdap";

const PlanningIngredientsTable = ({
  classes,
  planningIngredients = [],
  columns,
}) => {
  return (
    <div className={classes.wrapperTable}>
      <TabelaProdap
        idCabecalhoTabela={"planningsTableHead"}
        colunas={columns}
        ativarPaginacao={false}
        linhas={planningIngredients}
        tabelaEsquerda={true}
      />
    </div>
  );
};

const styles = () => ({
  wrapperTable: {
    marginTop: "40px",
    "& table": {
      "& tr:hover": {
        backgroundColor: "inherit !important",
      },
      "& tbody td:nth-child(2)": {
        padding: "4px 16px 4px 24px !important",
      },
    },
  },
});

export default withStyles(styles)(PlanningIngredientsTable);
