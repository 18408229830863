import { useState, useMemo, useEffect } from "react";
import goalPlanService from "features/goalPlan/goalPlanService";

const useGoalsTable = (enableLoading, notifyError, disableLoading) => {
  const [linesGoals, setLinesGoals] = useState([]);
  const [indicators, setIndicators] = useState([]);
  const [parameters, setParameters] = useState([]);

  const findInitialData = async () => {
    try {
      enableLoading("findInitialData");
      const indicatorsList = await goalPlanService.listIndicators();
      setIndicators(indicatorsList);
      const parametersList = await goalPlanService.listParameters();
      setParameters(parametersList);
    } catch (error) {
      console.error(error);
      notifyError("Ocorreu um erro ao buscar dados dos indicadores.");
    } finally {
      disableLoading("findInitialData");
    }
  };

  useEffect(() => {
    findInitialData();
  }, []);

  const freeIndicators = useMemo(
    () =>
      indicators
        .filter(
          (value) =>
            !linesGoals.find((line) => line.indicatorName === value.name)
        )
        .map((indicator) => indicator.name),
    [linesGoals, indicators]
  );

  const findIndicator = (indicatorName) =>
    indicators.find((indicator) => indicator.name === indicatorName);

  const findLineIndexByIndicator = (indicatorName) =>
    linesGoals.findIndex((line) => line.indicatorName === indicatorName);

  const addNewLine = (value) => {
    const indicatorName = value;
    const { type, unit, name, description } = findIndicator(indicatorName);
    const initialGoals = {
      annual: [{ value: null, firstValue: null, lastValue: null }],
      monthly: Array(12).fill({ value: null, firstValue: null, lastValue: null }),
    };
    const initialGoal = initialGoals[type];

    const newLine = {
      indicatorType: type,
      indicatorUnit: unit,
      indicatorName: name,
      name: null,
      parameterName: null,
      parameterType: "",
      values: initialGoal,
      description,
    };
    setLinesGoals((prevState) => [...prevState, newLine]);
  };

  const onChangeIndicator = (value, currentIndicatorName) => {
    const { type, unit, name, description } = findIndicator(value);
    const indexCurrentLine = findLineIndexByIndicator(currentIndicatorName);
    const currentLine = { ...linesGoals[indexCurrentLine] };
    const initialGoals = {
      annual: [{ value: null, firstValue: null, lastValue: null }],
      monthly: Array(12).fill({ value: null, firstValue: null, lastValue: null }),
    };
    const initialGoal = initialGoals[type];
    const newLine = {
      ...currentLine,
      indicatorType: type,
      indicatorUnit: unit,
      indicatorName: name,
      values: initialGoal,
      description,
    };

    setLinesGoals((prevState) => {
      return [
        ...prevState.slice(0, indexCurrentLine),
        { ...newLine },
        ...prevState.slice(indexCurrentLine + 1),
      ];
    });
  };

  const onChangeName = (event, currentIndicatorName) => {
    const indexCurrentLine = findLineIndexByIndicator(currentIndicatorName);
    setLinesGoals((prevState) => {
      const currLine = { ...prevState[indexCurrentLine] };
      currLine.name = event.target.value;
      return [
        ...prevState.slice(0, indexCurrentLine),
        { ...currLine },
        ...prevState.slice(indexCurrentLine + 1),
      ];
    });
  };

  const onChangeParameter = (value, currentIndicatorName) => {
    const indexCurrentLine = findLineIndexByIndicator(currentIndicatorName);
    const initialGoals = {
      annual: [{ value: null, firstValue: null, lastValue: null }],
      monthly: Array(12).fill({ value: null, firstValue: null, lastValue: null }),
    };
    const selectedParameter = parameters.find(
      (parameter) => parameter.name === value
    );

    setLinesGoals((prevState) => {
      const currentLine = { ...prevState[indexCurrentLine] };
      currentLine.parameterName = selectedParameter.name;
      currentLine.parameterType = selectedParameter.type;
      const initialGoal = initialGoals[currentLine.indicatorType];
      currentLine.values = initialGoal;
      return [
        ...prevState.slice(0, indexCurrentLine),
        { ...currentLine },
        ...prevState.slice(indexCurrentLine + 1),
      ];
    });
  };

  const onDeleteLine = (currentIndicatorName) => {
    const indexCurrentLine = findLineIndexByIndicator(currentIndicatorName);
    setLinesGoals((prevState) => {
      const newState = [...prevState];
      newState.splice(indexCurrentLine, 1);
      return newState;
    });
  };

  const onChangeGoal = (value, currentIndicatorName, monthIndex) => {
    const indexCurrentLine = findLineIndexByIndicator(currentIndicatorName);
    const currentLine = { ...linesGoals[indexCurrentLine] };
    if (currentLine.values[monthIndex])
      currentLine.values[monthIndex] = { ...value };

    setLinesGoals((prevState) => {
      return [
        ...prevState.slice(0, indexCurrentLine),
        { ...currentLine },
        ...prevState.slice(indexCurrentLine + 1),
      ];
    });
  };

  const firstLineTable = {
    id: "selecionaIndicador",
  };

  const goalsTableList = [...linesGoals, firstLineTable];

  return {
    indicators,
    goalsTableList,
    freeIndicators,
    addNewLine,
    onChangeIndicator,
    onChangeName,
    onChangeParameter,
    onDeleteLine,
    onChangeGoal,
    parameters,
    linesGoals,
    setLinesGoals,
  };
};

export default useGoalsTable;
