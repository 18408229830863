import React from "react";
import { Grid } from "@material-ui/core";
import MensagemMenuLateral from "features/manejo/views/components/MensagemMenuLateral";
export const mensagemFazendaSemLotes = (props) => {
  return (
    <Grid container spacing={0} id="gridContainer">
      <MensagemMenuLateral mensagem="Não existem categorias ou animais cadastrados para essa fazenda.">
        <img src="../images/map.svg" alt="icone de um mapa" />
      </MensagemMenuLateral>
      <div style={{ marginBottom: "60px" }} />
    </Grid>
  );
};

export default mensagemFazendaSemLotes;
