import {
  SET_FAZENDA,
  LISTA_FAZENDAS,
  SET_COORDENADAS_CENTRO,
  BUSCA_PASTOS_POR_ID_FAZENDA,
  CHANGE_CENTER,
  EXCLUIR_FAZENDA,
  TEM_COCHO_CREEP,
  SET_FAZENDA_ROOT,
} from "redux/actions/actionsConstants";

import { ATUALIZA_DATA_CONCILIACAO } from "features/conciliacao/redux/conciliacaoActions";
import { getFazendaStorage } from "shared/storageUtils";

const prodapCoordenadas = {
  lat: -19.9211964,
  lng: -43.948999,
};
const localFazendas = getFazendaStorage();
const fazendas = localFazendas
  ? JSON.parse(localFazendas)
  : {
    Fazendas: [],
  };
const toggles = localFazendas
  ? JSON.parse(localFazendas)
  : {
    Toggles: [],
  };

const currentFarm =
  fazendas && fazendas.Fazendas
    ? fazendas.Fazendas.find(
      (fazenda) => fazenda.IdFazenda === fazendas.FazendaSelecionada
    )
    : null;
export const initialState = {
  Fazendas: fazendas.Fazendas,
  FazendaSelecionada: fazendas.FazendaSelecionada,
  centroMapa: prodapCoordenadas,
  TemPastos: false,
  DropdownBloqueado: false,
  TemCochoCreep: false,
  Toggles: toggles.Toggles,
  DataInicioConciliacao: fazendas.DataInicioConciliacao,
  currentFarm,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COORDENADAS_CENTRO:
      return {
        ...state,
        centroMapa: action.payload,
      };
    case SET_FAZENDA: {
      const fazendaSelecionada = state.Fazendas.find(
        (f) => f.IdFazenda === action.payload.FazendaSelecionada
      );
      const extraiDataConciliacao = fazendaSelecionada
        ? fazendaSelecionada.DataInicioConciliacao
        : undefined;
      return {
        ...state,
        FazendaSelecionada: action.payload.FazendaSelecionada,
        Toggles: action.payload.Toggles,
        currentFarm: fazendaSelecionada,
        DataInicioConciliacao: extraiDataConciliacao,
      };
    }
    case LISTA_FAZENDAS:
      return {
        ...state,
        Fazendas: action.payload.Fazendas,
        FazendaSelecionada: action.payload.FazendaSelecionada,
      };
    case ATUALIZA_DATA_CONCILIACAO:
      return {
        ...state,
        DataInicioConciliacao: action.payload.DataInicioConciliacao,
      };
    case BUSCA_PASTOS_POR_ID_FAZENDA:
      return {
        ...state,
        TemPastos: action.payload,
      };
    case CHANGE_CENTER:
      return {
        ...state,
        centroMapa: action.payload.Coordenadas,
      };
    case EXCLUIR_FAZENDA:
      return {
        ...state,
        FazendaSelecionada: action.payload,
      };
    case TEM_COCHO_CREEP:
      return {
        ...state,
        TemCochoCreep: action.payload,
      };
    case SET_FAZENDA_ROOT:
      return {
        ...state,
        fazenda: action.payload,
      };
    default:
      return state;
  }
};
