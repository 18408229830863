import moment from "moment";
import constants from "./views/shared/constants";
import dayjs from "dayjs";
import { debounce } from "features/shared/utils";

const FORMAT_DATE = "DD/MM/YYYY";

const CONSUMO_DE_SUPLEMENTO = "CONSUMO DE SUPLEMENTO";

export const getGoalPlanSituation = (goalPlan) => {
  const { active, initialDate, finalDate } = goalPlan;
  if (!active) {
    return constants.situation.INACTIVE;
  }
  if (active && initialDate && finalDate) {
    const finalDateDetails = {
      month: dayjs(finalDate).month(),
      year: dayjs(finalDate).year(),
    };

    const initialDateDetails = {
      month: dayjs(initialDate).month(),
      year: dayjs(initialDate).year(),
    };

    const actualDateDetails = {
      month: dayjs().month(),
      year: dayjs().year(),
    };

    const pastPlanYear = finalDateDetails.year < actualDateDetails.year;
    let equalPlanYear = finalDateDetails.year === actualDateDetails.year;
    const pastPlanMonth = finalDateDetails.month < actualDateDetails.month;

    const pastPlan = pastPlanYear || (equalPlanYear && pastPlanMonth);

    const futurePlanYear = initialDateDetails.year > actualDateDetails.year;
    equalPlanYear = initialDateDetails.year === actualDateDetails.year;
    const futurePlanMonth = initialDateDetails.month > actualDateDetails.month;

    const futurePlan = futurePlanYear || (equalPlanYear && futurePlanMonth);

    if (pastPlan) {
      return constants.situation.PAST;
    }

    if (futurePlan) {
      return constants.situation.FUTURE;
    }
  }

  return constants.situation.ACTIVE;
};

export const formatDate = (date, formatPattern) =>
  moment(date).format(formatPattern || FORMAT_DATE);

const MetasFazendaUtils = {
  debounce,
  formatDate,
};

export const renderTextOrComponent = (textComponent, dataProps) => {
  if (typeof textComponent === "function") {
    if (
      textComponent(dataProps) === undefined ||
      textComponent(dataProps) === null
    )
      return undefined;
    if (typeof textComponent(dataProps) === "string") {
      return textComponent(dataProps);
    }
    return () => textComponent(dataProps);
  }
  return textComponent;
};

export const checkNullOrUndefined = (value) =>
  value !== null && value !== undefined;

export const formatPercentFieldGoals = (value, indicatorName) => {
  let inputValue = `${value}`.replace(",", ".");
  if (inputValue.includes(".")) {
    const splitValue = inputValue.split(".");
    if (splitValue[1] && splitValue[1].length > 2) {
      splitValue[1] = splitValue[1].substr(0, 2);
    }
    inputValue = splitValue.join(".");
  }

  if (indicatorName === CONSUMO_DE_SUPLEMENTO) {
    return Number(inputValue);
  }

  const inputValueMax = Math.max(0, Math.min(100, Number(inputValue)));
  return inputValueMax;
};

export default MetasFazendaUtils;
