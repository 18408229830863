import memoize from "memoize-one";
import * as _ from "lodash";

export const filterCategoriaMemoize = memoize(
  (list, textoFiltrar, categoriaEstado, curvaStatus) => {
    let listaFiltrada = [...(list || [])].filter((categoria) => {
      categoria.Nome = categoria.Nome || "";
      return categoria.Nome.toUpperCase().includes(textoFiltrar.toUpperCase());
    });

    if (categoriaEstado > 0) {
      listaFiltrada = listaFiltrada.filter((categoria) => {
        return !(categoria.Excluido === (categoriaEstado === 1));
      });
    }

    if (curvaStatus > 0 && curvaStatus !== 2) {
      listaFiltrada = listaFiltrada.filter((categoria) => {
        return categoria.IdCurvaGanhoPeso !== null;
      });
    }

    if (curvaStatus === 2) {
      listaFiltrada = listaFiltrada.filter((categoria) => {
        return categoria.IdCurvaGanhoPeso === null;
      });
    }

    return listaFiltrada;
  }
);

export const filtrarSexo = (value, faixas) => {
  return _.filter(faixas, (f) => {
    return f.Sexo === value;
  });
};

// ordena tabela ordem alfabética
const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const translateAnimalCategoryToPortuguese = (animalCategory) => ({
  IdCategoriaAnimal: animalCategory.id,
  IdFazenda: animalCategory.farmId,
  IdFaixaIdade: animalCategory.ageRangeId,
  IdCurvaGanhoPeso: animalCategory.weightGainCurveId,
  Nome: animalCategory.name,
  Sexo: animalCategory.gender,
  Hash: animalCategory.hash,
  Alertas: animalCategory.warnings,
  TamanhoDesejado: animalCategory.desiredNumber,
  QuantidadeInicialParaConciliacao: animalCategory.initialNumberConference,
  UsuarioCriacao: animalCategory.creationUser,
  DataCriacao: animalCategory.createdAt,
  UsuarioAlteracao: animalCategory.updateUser,
  DataAlteracao: animalCategory.updatedAt,
  Excluido: !!(animalCategory.deletedAt || animalCategory.deletionUser),
  UsuarioExclusao: animalCategory.deletionUser,
  DataExclusao: animalCategory.deletedAt,
});

export const translateAnimalCategoryToEnglish = (animalCategory) => ({
  id: animalCategory.IdCategoriaAnimal,
  farmId: animalCategory.IdFazenda,
  ageRangeId: animalCategory.IdFaixaIdade,
  weightGainCurveId: animalCategory.IdCurvaGanhoPeso,
  name: animalCategory.Nome,
  gender: animalCategory.Sexo,
  hash: animalCategory.Hash,
  warnings: animalCategory.Alertas,
  desiredNumber: animalCategory.TamanhoDesejado,
  initialNumberConference: animalCategory.QuantidadeInicialParaConciliacao,
  creationUser: animalCategory.UsuarioCriacao,
  createdAt: animalCategory.DataCriacao,
  updateUser: animalCategory.UsuarioAlteracao,
  updatedAt: animalCategory.DataAlteracao,
  deletedAt: animalCategory.DataExclusao,
  deletionUser: animalCategory.UsuarioExclusao,
});

export const tabelaCategoriaUtils = {
  getComparator,
  descendingComparator,
  stableSort,
};
