export const OBTER_RELATORIO = 'OBTER_RELATORIO';
export const OBTER_RELATORIOS_TABLEAU = 'OBTER_RELATOROBTER_RELATORIOS_TABLEAUIO';

export const obterRelatorio = relatorios => {
  return {
    type: OBTER_RELATORIO,
    payload: { relatorio: relatorios }
  };
};
export const obterRelatoriosAction = relatorios => {
  return {
    type: OBTER_RELATORIOS_TABLEAU,
    payload: { relatorios: relatorios }
  }
};

