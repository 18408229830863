import moment from "moment";
import "moment/locale/pt-br";
import { objetoPreenchido } from "../shared/utils";
import { DATA_FECHAMENTO_CONSUMO } from "./views/dataFechamentoConsumo";

export const LEGENDA_VERDE = "#008000";
export const LEGENDA_VERMELHA = "#ff0000";
export const LEGENDA_AZUL = "#00f";
export const LEGENDA_PRETA = "#000000";
export const TAMANHO_GRAFICO_RANGE = 280;
export const FAIXA_ACEITE = 15;
export const LIMITE_REBANHO_SEM_FORMULACAO = 10.0;

export const FORMATO_DATA = "DDMMYYYY";
// Data minima a ser selecionada para busca no fechamento de consumo
export const DATA_MINIMA = "2019-08-08";

const getDataAtual = () => {
  return moment();
};

const getDataTermino = () => {
  const fechamentoConsumo = moment(DATA_FECHAMENTO_CONSUMO);
  const dataLimiteFechamento = moment(DATA_FECHAMENTO_CONSUMO).add(15, "days");
  const dataAtual = getDataAtual();
  if (
    dataAtual.isAfter(fechamentoConsumo) &&
    dataAtual.isBefore(dataLimiteFechamento)
  ) {
    return moment(fechamentoConsumo).subtract(1, "day").format();
  }

  return moment(getDataAtual()).subtract(1, "day").format();
};

export const DATA_TERMINO = getDataTermino();

const getDataInicio = () => {
  const fechamentoConsumo = moment(DATA_FECHAMENTO_CONSUMO);
  const dataFechamentoMais15 = moment(DATA_FECHAMENTO_CONSUMO).add(15, "days");
  const dataFechamentoMais30 = moment(DATA_FECHAMENTO_CONSUMO).add(1, "month");

  const dataAtual = getDataAtual();
  const dataMenosTrintaDias = moment(dataAtual).subtract(1, "month");
  const sugestao = dataMenosTrintaDias.format();

  if (
    dataAtual.isAfter(fechamentoConsumo) &&
    dataAtual.isBefore(dataFechamentoMais15)
  ) {
    return moment(fechamentoConsumo).subtract(1, "month").format();
  }

  if (
    dataAtual.isAfter(moment(dataFechamentoMais15).subtract(1, "day")) &&
    dataAtual.isBefore(dataFechamentoMais30)
  ) {
    return moment(fechamentoConsumo).format();
  }

  return sugestao;
};

export const DATA_INICIO = getDataInicio();

export const getPercentualConsumido = (consumoOcorrido, consumoObjetivo) => {
  if (isNaN(consumoObjetivo) || isNaN(consumoOcorrido)) {
    return -1;
  } else if (consumoOcorrido === 0 || consumoObjetivo === 0) {
    return 0;
  }
  return consumoOcorrido / consumoObjetivo;
};

export const getDadosConsumo = (
  consumoOcorrido,
  consumoObjetivo,
  faixaAceite
) => {
  if (isNaN(consumoObjetivo) || isNaN(consumoOcorrido) || isNaN(faixaAceite)) {
    return -1;
  }
  const percentualConsumoOcorrido =
    fechamentoConsumoUtils.getPercentualConsumido(
      consumoOcorrido,
      consumoObjetivo
    );
  const percentualAceite = faixaAceite / 100;
  const limiteInferior = 1 - percentualAceite;
  const limiteSuperior = 1 + percentualAceite;
  return {
    percentualConsumoOcorrido,
    percentualAceite,
    limiteInferior,
    limiteSuperior,
  };
};

export const getPosicaoMarcador = (consumoOcorrido, consumoObjetivo) => {
  let percentualConsumoOcorrido = fechamentoConsumoUtils.getPercentualConsumido(
    consumoOcorrido,
    consumoObjetivo
  );
  return (TAMANHO_GRAFICO_RANGE / 2) * percentualConsumoOcorrido;
};
export const getinlineStyleMarcador = (theme, posicaoMarcadorReal) => {
  if (!theme) {
    return {};
  }
  if (posicaoMarcadorReal > TAMANHO_GRAFICO_RANGE) {
    posicaoMarcadorReal = TAMANHO_GRAFICO_RANGE;
  }
  const tamanhoMarcador = 30;
  const transicaoMarcador = theme.transitions.easing.easeInOut;
  const duracaoTransicaoMarcador = `${
    theme.transitions.duration.standard / 1000
  }s`;
  return {
    fontSize: tamanhoMarcador,
    position: "relative",
    left: `${posicaoMarcadorReal}px`,
    marginBottom: -10,
    marginLeft: -tamanhoMarcador / 2,
    transition: transicaoMarcador,
    transitionDuration: duracaoTransicaoMarcador,
  };
};

export const getInlineStyleFaixas = (
  consumoOcorrido,
  consumoObjetivo,
  faixaAceite
) => {
  const { percentualConsumoOcorrido, limiteInferior, limiteSuperior } =
    fechamentoConsumoUtils.getDadosConsumo(
      consumoOcorrido,
      consumoObjetivo,
      faixaAceite
    );
  const widthFaixaIdeal = (FAIXA_ACEITE / 100) * TAMANHO_GRAFICO_RANGE;
  const faixaIdeal = {
    backgroundColor: LEGENDA_VERDE,
    width: widthFaixaIdeal,
    opacity:
      percentualConsumoOcorrido >= limiteInferior &&
      percentualConsumoOcorrido <= limiteSuperior
        ? 1
        : 0.3,
  };
  const faixasAbaixo = {
    backgroundColor: LEGENDA_VERMELHA,
    width: `${(TAMANHO_GRAFICO_RANGE - widthFaixaIdeal) / 2}px`,
    opacity: percentualConsumoOcorrido < limiteInferior ? 1 : 0.3,
  };
  const faixasAcima = {
    backgroundColor: LEGENDA_AZUL,
    width: `${(TAMANHO_GRAFICO_RANGE - widthFaixaIdeal) / 2}px`,
    opacity: percentualConsumoOcorrido > limiteSuperior ? 1 : 0.3,
  };
  return {
    faixaIdeal,
    faixasAbaixo,
    faixasAcima,
  };
};

export const getDesvioObjetivo = (consumoOcorrido = 0, consumoObjetivo = 0) => {
  const desvio = (consumoOcorrido - consumoObjetivo) / consumoObjetivo || 0;
  return `${Math.floor(desvio * 100)}%`;
};

export const getStyleConsumoOcorrido = (
  consumoOcorrido,
  consumoObjetivo,
  faixaAceite
) => {
  const { percentualConsumoOcorrido, limiteInferior, limiteSuperior } =
    fechamentoConsumoUtils.getDadosConsumo(
      consumoOcorrido,
      consumoObjetivo,
      faixaAceite
    );
  let corConsumoOcorrido = "";
  const style = {
    fontSize: 14,
  };
  if (percentualConsumoOcorrido < limiteInferior) {
    corConsumoOcorrido = LEGENDA_VERMELHA;
  } else if (
    percentualConsumoOcorrido >= limiteInferior &&
    percentualConsumoOcorrido <= limiteSuperior
  ) {
    corConsumoOcorrido = LEGENDA_VERDE;
  } else if (percentualConsumoOcorrido > limiteSuperior) {
    corConsumoOcorrido = LEGENDA_AZUL;
  }
  return {
    ...style,
    color: corConsumoOcorrido,
  };
};

export const getLegendas = () => {
  return [
    {
      legendaLabel: "Abaixo do consumo objetivo",
      legendaCor: LEGENDA_VERMELHA,
      legendaId: "LEGENDA_VERMELHA",
    },
    {
      legendaLabel: "Desvio de até 15% do consumo objetivo",
      legendaCor: LEGENDA_VERDE,
      legendaId: "LEGENDA_VERDE",
    },
    {
      legendaLabel: "Acima do consumo objetivo",
      legendaCor: LEGENDA_AZUL,
      legendaId: "LEGENDA_AZUL",
    },
  ];
};

// define filtros padroes e dinamicos;
export const filterUtils = {
  Todas: (consumosFormulas) => consumosFormulas,
  Ativadas: (consumosFormulas) =>
    consumosFormulas.filter((formula) => !formula.Excluido),
  Desativadas: (consumosFormulas) =>
    consumosFormulas.filter((formula) => formula.Excluido),
  Dinamico: (consumosFormulas, propriedadeFiltrar, valorFiltrar) =>
    consumosFormulas.filter(
      (formula) =>
        String(formula[propriedadeFiltrar]).toUpperCase() ===
        String(valorFiltrar).toUpperCase()
    ),
};

export const filtraConsumosPorFormulas = (
  consumosFormulas = [],
  propriedadeFiltrar = "",
  valorFiltrar = ""
) =>
  (filterUtils[valorFiltrar] || filterUtils["Dinamico"])(
    consumosFormulas,
    propriedadeFiltrar,
    valorFiltrar
  );

export const mapeiaIdsFormulasSelecionadas = (formulas, formulaSelecionada) => {
  const key = Object.keys(formulaSelecionada)[0];
  const value = Object.values(formulaSelecionada)[0];
  const formulasFiltradas = (filterUtils[value] || filterUtils["Dinamico"])(
    formulas,
    key,
    value
  );
  return formulasFiltradas.map((formula) => formula.IdFormula);
};

export const getFiltroFechamentoConsumo = (
  formulas,
  formulaSelecionada = {
    NomeFormula: "Todas",
  },
  fazendaSelecionada,
  dataInicio,
  dataFim,
  idRetiroSelecionada
) => {
  let retiros = [];
  if (idRetiroSelecionada) {
    retiros = idRetiroSelecionada.map((v) => v.value);
  }
  const filtro = {
    IdFazenda: fazendaSelecionada.IdFazenda,
    FormulasIds: formulas.map((formula) => formula.IdFormula),
    DataInicio: moment(dataInicio).format("YYYY-MM-DDT00:00:00"),
    DataFim: moment(dataFim).format("YYYY-MM-DDT23:59:59"),
    IdsRetiros: retiros,
  };

  return filtro;
};

export const mapeiaConsumosComPesoFormulas = (consumos = [], formulas = []) => {
  return consumos.map((consumo) => {
    const formula = formulas.find(
      (formula) => formula.IdFormula === consumo.IdFormula
    );
    return {
      ...consumo,
      pesoSacoFormula: formula ? formula.PesoSaco : 0,
      Excluido: formula.Excluido,
    };
  });
};

export const getMensagemFeedbackFechamento = (fazenda) => {
  const fazendaPreenchida = objetoPreenchido(fazenda);
  return !fazendaPreenchida
    ? `Nenhuma fazenda selecionada`
    : `Não foi possível listar formulas`;
};

export const listarConsumos = (chamadaConcluida, consumosFormulas) => {
  return !chamadaConcluida || (chamadaConcluida && consumosFormulas.length > 0);
};

const fechamentoConsumoUtils = {
  getPercentualConsumido,
  getDadosConsumo,
  mapeiaIdsFormulasSelecionadas,
  mapeiaConsumosComPesoFormulas,
};

export default fechamentoConsumoUtils;
