import BaseService from "../../services/BaseService";
const URL_BASE = `${process.env.REACT_APP_ENDPOINT}semi-confinamentos/api/v1/ajuste-escore`;

class AjusteEscoreService extends BaseService {
  listarAjustesEscore = async (idFazenda) => {
    const parametrosURL = this.montaFiltro({
      where: {
        IdFazenda: idFazenda,
      },
    });
    const urlServico = this.montaURL(URL_BASE, parametrosURL);
    return this.get(urlServico);
  };

  excluirAjustesEscore = async (idFazenda) => {
    return this.delete(`${URL_BASE}/${idFazenda}`);
  };

  salvarAjustesEscore = async (ajustesEscore) => {
    return this.post(`${URL_BASE}`, ajustesEscore);
  };
}

export default new AjusteEscoreService();
