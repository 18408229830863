import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Create";
import SwitchCustomizado from "features/shared/components/SwitchCustomizado";
import Tooltip from "@material-ui/core/Tooltip";
import { Chip, withStyles } from "@material-ui/core";
import { tabelaCategoriaUtils } from "../categoriaUtils";
import { CategoriaTabelaCabecalho } from "./cabecalhoTabela";
import { idsCabecalhoCategoriaAnimal } from "../../../constants/idsCabecalhoCategoriaAnimal";

function CategoriaTabela(props) {
  const {
    classes,
    categoriasFiltradas,
    exibeQuantidadeConciliacao,
    exibeTamanhoLote,
    exibeCurvaGanhoPeso,
  } = props;

  const retiraColunas = [];

  if (!exibeQuantidadeConciliacao) {
    retiraColunas.push(
      idsCabecalhoCategoriaAnimal.QuantidadeInicialParaConciliacao
    );
  }

  if (!exibeTamanhoLote) {
    retiraColunas.push(idsCabecalhoCategoriaAnimal.TamanhoDesejado);
  }

  if (!exibeCurvaGanhoPeso) {
    retiraColunas.push(idsCabecalhoCategoriaAnimal.CurvaGanhoPeso);
  }

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Nome");
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  let categoriaAnimalOrdenado = tabelaCategoriaUtils.stableSort(
    categoriasFiltradas,
    tabelaCategoriaUtils.getComparator(order, orderBy)
  );

  return (
    <div className={classes.tableContainer}>
      <Table
        id="tabelaCategorias"
        className={classes.table}
        aria-labelledby="tableTitle"
        size="medium"
        stickyHeader
        aria-label="enhanced table"
      >
        <CategoriaTabelaCabecalho
          id="tabelaHead"
          classes={classes}
          order={order}
          retiraColunas={retiraColunas}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={(categoriasFiltradas.items || []).length}
        />
        <TableBody>
          {categoriaAnimalOrdenado.map((c, index) => {
            return (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {c.Alertas && !c.Excluido && (
                    <Tooltip title={c.Alertas} placement="right">
                      <IconButton aria-label="delete">
                        <img src="../images/ic-infoRed.svg" alt="Altert" />
                      </IconButton>
                    </Tooltip>
                  )}
                  <span id={`NomeCategoriaRegistroTabela`}>{c.Nome}</span>
                </TableCell>
                {exibeQuantidadeConciliacao && (
                  <TableCell
                    className={props.classes.tableCell}
                    id={"quantidade-inicial"}
                  >
                    {c.QuantidadeInicialParaConciliacao
                      ? c.QuantidadeInicialParaConciliacao
                      : "-"}
                  </TableCell>
                )}
                {exibeTamanhoLote && (
                  <TableCell
                    className={props.classes.tableCell}
                    id={"tamanho-desejado"}
                  >
                    {c.TamanhoDesejado ? c.TamanhoDesejado : "-"}
                  </TableCell>
                )}
                <TableCell className={props.classes.tableCell}>
                  {c.Sexo}
                </TableCell>
                <TableCell className={props.classes.tableCell}>
                  {c.FaixaIdade}
                </TableCell>
                {exibeCurvaGanhoPeso && (
                  <TableCell
                    className={props.classes.tableCell}
                    id={"curva-ganho-peso"}
                  >
                    {c.IdCurvaGanhoPeso ? (
                      <Chip label="Sim" className={props.classes.chipSim} />
                    ) : (
                      <Chip label="Não" className={props.classes.chipNao} />
                    )}
                  </TableCell>
                )}
                <TableCell className={props.classes.tableCell}>
                  <IconButton
                    id="botaoHabilitar"
                    aria-label="Edit"
                    disabled={props.adicionando}
                    onClick={() => props.habilitarEdicao(c)}
                    className={props.classes.margin}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <SwitchCustomizado
                    id={`switch-${c.IdCategoriaAnimal}`}
                    defaultChecked={!c.Excluido}
                    checked={!c.Excluido}
                    handleClick={() =>
                      props.ativaDesativaCategoriaAnimal(
                        c.IdCategoriaAnimal,
                        c.Excluido
                      )
                    }
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

const styles = (theme) => {
  return {
    tableContainer: {
      width: "100%",
      maxHeight: 600,
      overflow: "auto",
    },
    table: {
      width: "100%",
    },
    headerCategoria: {
      textTransform: "uppercase",
    },
    headerTamanhoLote: {
      flexDirection: "row",
      display: "flex",
      height: 66,
      alignItems: "center",
    },
    defaultCells: {
      fontFamily: "Roboto",
      fontSize: 14,
      lineHeight: 1.29,
      color: theme.palette.primary.main,
      position: "sticky",
      top: 0,
      backgroundColor: "#e1e1e1",
      zIndex: 10,
      width: "110px",
      "@media (min-Width: 720px) and (max-Width: 1280px)": {
        maxWidth: "181px",
      },
      "@media (min-Width: 768px) and (max-Width: 1024px)": {
        maxWidth: "150px",
      },
    },
    defaultSpan: {
      fontFamily: "Roboto",
      fontSize: 14,
      lineHeight: 1.29,
      color: theme.palette.primary.main,
    },
    link: {
      width: "100%",
      textAlign: "left",
    },
    chipSim: {
      backgroundColor: "#dff1df",
      color: "#4caf50",
      fontWeight: 600,
      width: 80,
      height: 28,
      alignItems: "center",
      borderRadius: 100,
    },
    chipNao: {
      backgroundColor: "#f8807833",
      color: "#f44336",
      fontWeight: 600,
      width: 80,
      height: 28,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 100,
    },
  };
};

export default withStyles(styles)(CategoriaTabela);
