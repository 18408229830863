import BaseService from "../../services/BaseService";
import { fixInitialDateAndFinalDateTimezone } from "../shared/utils"

export const URL_PLANNINGS = `${process.env.REACT_APP_ENDPOINT}locales/plannings`;

class PlanningService extends BaseService {
  async postPlan(planningData) {
    const response = await this.post(URL_PLANNINGS, planningData);
    return response.data.data;
  }

  async postNewVersion(planningCode, planningData) {
    const response = await this.post(
      `${URL_PLANNINGS}/${planningCode}/version`,
      planningData
    );
    return response.data.data;
  }

  async listPlannings(farmId, page, size) {
    const response = await this.get(URL_PLANNINGS, { farmId, page, size });
    return fixInitialDateAndFinalDateTimezone(response.data.data);
  }

  async getPlan(planningCode) {
    const response = await this.get(
      `${URL_PLANNINGS}/${planningCode}/?relations=["planningIngredientCost"]`
    );
    return fixInitialDateAndFinalDateTimezone(response.data.data);
  }

  async getPlanReport(planningCode) {
    const response = await this.get(
      `${URL_PLANNINGS}/${planningCode}/?relations=["planningReport"]`
    );
    return fixInitialDateAndFinalDateTimezone(response.data.data);
  }

  async findPlanByPeriod(farmId, initialDate, finalDate) {
    const urlFindPlanByPeriod = `${URL_PLANNINGS}/period`;

    const response = await this.get(urlFindPlanByPeriod, {
      farmId,
      initialDate,
      finalDate,
    });

    return fixInitialDateAndFinalDateTimezone(response.data);
  }
}

export default new PlanningService();
