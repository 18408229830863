import React from 'react';
import { Grid } from '@material-ui/core';
import { TextLabel } from '../../shared/components';

const appBarConteudoDefault = props => {
  const { tituloAppBar, textoSecundario } = props;
  return (
    <Grid container direction="column">
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        direction="row"
        justify="center"
      >
        <TextLabel bold size={18} id="tituloDoAppBar">
          {tituloAppBar}
        </TextLabel>
      </Grid>
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        direction="row"
        justify="center"
      >
        <TextLabel size={14} color="default2" id="subtituloDoAppBar">
          {textoSecundario}
        </TextLabel>
      </Grid>
    </Grid>
  );
};

export default appBarConteudoDefault;
