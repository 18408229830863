import React from "react";
import { Typography, withStyles } from "@material-ui/core";
import ConfirmationModal from "../../../shared/components/confirmationModal";
import GoalPlanFormContainer from "../shared/goalPlanForm/goalPlanFormContainer";

const TEXT_BUTTON_SUBMIT = "SALVAR EDIÇÃO";

const GoalPlanEditionPage = ({
  farmId,
  goalPlanId,
  onBack,
  onSubmit,
  enableLoading,
  disableLoading,
  notifyError,
  showConfirmationModal,
  classes,
}) => {
  const subtitle = () => (
    <Typography className={classes.subtitle}>
      Ao editar um plano, é possível alterar o <b>nome e data</b> do plano. Caso
      queira editar outros dados, é necessário criar uma revisão para o plano.
      Para isso, basta acessar a tela inicial, na coluna de <b>“Ações”</b>, e
      escolher a opção <b>“Criar revisão do plano”</b>.
    </Typography>
  );

  return (
    <GoalPlanFormContainer
      farmId={farmId}
      titlePage="Editar plano"
      titleContent=""
      subtitleContent={subtitle}
      onBack={onBack}
      onSubmit={onSubmit}
      enableLoading={enableLoading}
      disableLoading={disableLoading}
      notifyError={notifyError}
      initialDataPlanId={goalPlanId}
      disableEditTable
      textButtonSubmit={TEXT_BUTTON_SUBMIT}
    >
      {showConfirmationModal && (
        <ConfirmationModal
          title={showConfirmationModal.title}
          text={showConfirmationModal.text}
          submitButtonTitle={showConfirmationModal.buttonConfirmText} //"SIM, SALVAR PLANO"
          onSubmit={showConfirmationModal.onConfirm}
          onCancel={showConfirmationModal.onCancel}
        />
      )}
    </GoalPlanFormContainer>
  );
};

const styles = () => {
  return {
    title: {
      fontSize: "20px",
      color: "#444444",
    },
    subtitle: {
      fontSize: "16px",
      color: "#444444",
      paddingTop: "20px",
    },
  };
};

export default withStyles(styles)(GoalPlanEditionPage);
