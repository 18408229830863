import BaseService from "../../services/BaseService";
import {
  validateLivestockResponse,
  convertLivestockToLote,
  convertProjectedWeightToPesoProjetado,
} from "./loteUtils";

const URL_BASE_LIVESTOCK = `${process.env.REACT_APP_ENDPOINT}herds/livestock`;
const URL_BASE_CURVA_PESO = `${process.env.REACT_APP_ENDPOINT}herds/weightGainCurve`;
const URL_BASE_PESO_PROJETADO = `${process.env.REACT_APP_ENDPOINT}herds/projected-weight`;
const URL_BASE_WEIGHT = `${process.env.REACT_APP_ENDPOINT}herds/weight/finish-weights`;
// const URL_BASE_OBJETIVO = `${process.env.REACT_APP_ENDPOINT}herds/livestockGoal`;

export class LoteService extends BaseService {
  async listaLotesPasto(idFazenda) {
    try {
      const urlServico = `${URL_BASE_LIVESTOCK}/from-farm/${idFazenda}`;
      const response = await this.get(urlServico);

      return validateLivestockResponse(response, (response) => ({
        ...response,
        data: convertLivestockToLote(response.data.data),
      }));
    } catch (erro) {
      return {
        erro,
      };
    }
  }

  async atualizarLotes(lotes) {
    if (!Array.isArray(lotes) || lotes.length === 0) {
      return;
    }

    const weightIdsToDelete = [];

    const livestockAccesories = lotes.map(
      ({
        IdPesoMedido,
        PesoMedido,
        DataPesagem,
        PesoObjetivo,
        IdPesoObjetivo,
        IdLote,
        IdObjetivo,
      }) => {
        const weights = [];

        if (PesoMedido === "" && IdPesoMedido) {
          weightIdsToDelete.push(IdPesoMedido);
        } else if (typeof PesoMedido === "number" && PesoMedido > 0) {
          weights.push({
            weightType: "PESADO",
            operationDate: DataPesagem ? new Date(DataPesagem) : null,
            weight: PesoMedido,
          });
        }

        if (PesoObjetivo === "" && IdPesoObjetivo) {
          weightIdsToDelete.push(IdPesoObjetivo);
        } else if (typeof PesoObjetivo === "number" && PesoObjetivo > 0) {
          weights.push({
            weightType: "OBJETIVO",
            weight: PesoObjetivo,
          });
        }

        return {
          livestockId: IdLote,
          accessories: {
            goalId: IdObjetivo || null,
            weights,
          },
        };
      }
    );

    const urlService = `${URL_BASE_LIVESTOCK}/accessories`;

    if (weightIdsToDelete.length > 0) {
      return await Promise.all([
        this.patch(URL_BASE_WEIGHT, {
          weightIds: weightIdsToDelete,
        }),
        this.patch(urlService, livestockAccesories),
      ]);
    } else {
      return await this.patch(urlService, livestockAccesories);
    }
  }

  async listaLotesReduzidosPorPastos(idFazenda) {
    try {
      const query = {
        include: ["goal", "weight", "healthCheck"],
      };

      const urlServico = this.montaURL(
        `${URL_BASE_LIVESTOCK}/from-farm/${idFazenda}`,
        this.montaURLParametros(query)
      );

      const response = await this.get(urlServico);

      return validateLivestockResponse(response, (response) =>
        convertLivestockToLote(response.data.data)
      );
    } catch (error) {
      return {
        error,
      };
    }
  }

  async obterProjecaoSemestrePorLote(idLote) {
    try {
      const urlServico = `${URL_BASE_PESO_PROJETADO}/from-livestock/${idLote}`;
      const response = await this.get(urlServico);

      return validateLivestockResponse(response, (response) => ({
        ...response,
        data: convertProjectedWeightToPesoProjetado(response.data.data),
      }));
    } catch (error) {
      return {
        error,
      };
    }
  }

  async obterCurvaGanhoPesoPorFiltro(idFazenda) {
    try {
      const params = {
        farmId: idFazenda,
      };
      const urlServico = this.montaURL(
        URL_BASE_CURVA_PESO,
        this.montaURLParametros(params)
      );
      const response = await this.get(urlServico);

      return validateLivestockResponse(response, (response) => ({
        ...response,
        data: response.data.data,
      }));
    } catch (error) {
      return { error };
    }
  }
}

export default new LoteService();
