import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

export const useDialogConfirm = ({
  title = "Alerta",
  text = "Texto alerta",
  handleCancel = () => {},
  handleConfirm = () => {},
  cancelButtonText = "CANCELAR",
  confirmButtonText = "SIM, CONTINUAR",
}) => {
  const [visible, setVisible] = useState(false);
  const [textModal, setTextModal] = useState(text);

  const _handleConfirm = () => {
    setVisible(false);
    handleConfirm();
  };

  const _handleCancel = () => {
    setVisible(false);
    handleCancel();
  };

  const render = () => {
    return visible ? (
      <Dialog
        id="modalConfirm"
        open={visible}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={_handleConfirm}
        PaperProps={{ style: { padding: "8px 0 24px 0" } }}
      >
        <React.Fragment>
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent id="alert-dialog-description">
            <DialogContentText>{textModal}</DialogContentText>
          </DialogContent>
        </React.Fragment>

        <DialogActions style={{ padding: "0 20px" }}>
          <Button
            id="botaoCancelar"
            onClick={_handleCancel}
            color="primary"
            variant="outlined"
            autoFocus
          >
            {cancelButtonText}
          </Button>
          <Button
            variant="contained"
            id="botaoConfirmar"
            onClick={_handleConfirm}
            color="primary"
          >
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    ) : null;
  };

  return {
    render,
    setVisible,
    setText: setTextModal,
  };
};
