import React from "react";
import PropTypes from "prop-types";
import { version } from "../../../../package.json";
import { connect } from "react-redux";
import { compose } from "recompose";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import { Icon, withStyles } from "@material-ui/core";
import auth from "../../authentication/Auth";
import { ModalLogout } from "../../layout";
import * as modalActions from "../../../redux/actions/modalActions";
import AvatarUsuario from "./avatarUsuario";
import TableauReportContainer from "../../analiticopdf/views/tableauReportContainer";
import ModalHistorico from "../../analiticopdf/views/modalHistorico";

export class DropDownUsuario extends React.Component {
  constructor(props) {
    super(props);
    this.auth = auth;
  }

  state = {
    open: false,
  };

  abreDropDownUsuario = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  handleOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleOpenModalLogout = () => {
    this.props.setModalLogout(true);
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    const usuario = this.auth.getSession();
    const { open } = this.state;

    return (
      <React.Fragment>
        <Button
          id="btnDropDownUsuario"
          buttonRef={(node) => {
            this.anchorEl = node;
          }}
          aria-owns={open ? "menu-list-grow" : null}
          aria-haspopup="true"
          onClick={this.abreDropDownUsuario}
          className={classes.menuDropDown}
        >
          <AvatarUsuario usuario={usuario} handleOpen={this.handleOpen} />
        </Button>
        <Popper
          open={open}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          className={classes.menuDropDown}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper className={classes.menuDropDown}>
                <ClickAwayListener
                  onClickAway={this.handleClose}
                  id="clickAwayDropDownUsuario"
                >
                  <MenuList>
                    <MenuItem>
                      <Icon className={classes.icones}>swap_horiz_black</Icon>
                      <Typography className={classes.textoMenu}>
                        Versão v{version}
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={this.handleOpenModalLogout}>
                      <Icon className={classes.icones}>logout_black</Icon>
                      <Typography className={classes.textoMenu}>
                        Sair
                      </Typography>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

        <TableauReportContainer usuario={usuario} />
        <ModalHistorico />
        <ModalLogout />
      </React.Fragment>
    );
  }
}

DropDownUsuario.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => {
  return {
    menuDropDown: {
      minWidth: 200,
    },
    textoMenu: {
      paddingLeft: 20,
    },
    icones: {
      color: "#666666",
      width: 24,
      height: 24,
      flexGrow: 0,
      objectFit: "contain",
    },
  };
};
export default compose(
  withStyles(styles),
  connect(null, { setModalLogout: modalActions.setModalLogout })
)(DropDownUsuario);
