export const SET_CONSUMOS_FORMULAS = 'fechamentoConsumo/SET_CONSUMOS_FORMULAS';

export const setConsumosFormulas = (consumos, formulas, diariasSemFormula) => {
  return {
    type: SET_CONSUMOS_FORMULAS,
    payload: {
      consumos,
      formulas,
      diariasSemFormula
    }
  };
};
