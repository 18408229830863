import dayjs from "dayjs";
import goalPlanService from "features/goalPlan/goalPlanService";
import React, { useEffect, useMemo, useState } from "react";
import localConstants from "../views/shared/constants";
import planningService from "../planningService";
import { palette } from "styles/theme";

const compareDateForPlanning = (a, b) => {
  const dateA = dayjs(a).startOf("day").startOf("month");
  const dateB = dayjs(b).startOf("day").startOf("month");
  return dateA.isSame(dateB);
};

export const useGoalValidations = ({
  farmId,
  enableLoading,
  disableLoading,
  currentPlanning = {},
  planningPlanDataInitialDate,
  onChangePlanningInitialDate,
}) => {
  const [modalGoalsDateSuggest, setModalGoalsDateSuggest] = useState(false);
  const [modalSubmitActiveGoalPeriod, setModalSubmitActiveGoalPeriod] =
    useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [activeGoalPeriod, setActiveGoalPeriod] = useState({
    initialDate: null,
    finalDate: null,
  });
  const [hasSynchedPlanning, setHasSynchedPlanning] = useState(false);

  function isActive(goal) {
    const today = new Date();
    const { initialDate, finalDate } = goal;
    return today >= new Date(initialDate) && today <= new Date(finalDate);
  }

  const getActiveGoalPeriod = async () => {
    try {
      enableLoading();
      const initialDate = dayjs().subtract(12, "M").format("YYYY-MM-DD");
      const finalDate = dayjs().add(12, "M").format("YYYY-MM-DD");
      const { data: result } = await goalPlanService.findPlanByPeriod(
        farmId,
        initialDate,
        finalDate
      );
      if (result && result.length) {
        const activeGoalPlan = result.find(isActive);
        if (activeGoalPlan) {
          setActiveGoalPeriod({
            initialDate: activeGoalPlan.initialDate,
            finalDate: activeGoalPlan.finalDate,
          });
        }
        findSynchedPlaning(
          activeGoalPlan.initialDate,
          activeGoalPlan.finalDate
        );
      }
    } catch {
    } finally {
      disableLoading();
    }
  };

  const findSynchedPlaning = async (goalInitialDate, goalFinalDate) => {
    try {
      const initialDate = dayjs(goalInitialDate)
        .startOf("day")
        .startOf("month")
        .format("YYYY-MM-DD");
      const finalDate = dayjs(goalFinalDate)
        .startOf("day")
        .startOf("month")
        .format("YYYY-MM-DD");

      enableLoading();
      const { data: result } = await planningService.findPlanByPeriod(
        farmId,
        initialDate,
        finalDate
      );

      if (
        result &&
        compareDateForPlanning(result.initialDate, initialDate) &&
        compareDateForPlanning(result.finalDate, finalDate)
      ) {
        setHasSynchedPlanning(true);
      }
    } catch (error) {
    } finally {
      disableLoading();
    }
  };

  useEffect(() => {
    getActiveGoalPeriod();
  }, []);

  const getActiveGoalPeriodMessage = () => {
    const formattedInitialDate = dayjs(activeGoalPeriod.initialDate).format(
      localConstants.dateShortFormat
    );
    const formattedFinalDate = dayjs(activeGoalPeriod.finalDate).format(
      localConstants.dateShortFormat
    );
    return (
      <>
        Verifiquei que você possui um plano de metas ativo, com data de{" "}
        <strong style={{ color: palette.primary.main }}>
          {formattedInitialDate} a {formattedFinalDate}
        </strong>
        . Deseja utilizar a mesma data para o novo planejamento e mantê-los
        sincronizados?
      </>
    );
  };

  const onConfirmGoalPeriodDate = () => {
    onChangePlanningInitialDate(activeGoalPeriod.initialDate);
    setModalGoalsDateSuggest(false);
  };

  const onCancelGoalPeriodDate = () => {
    setModalGoalsDateSuggest(false);
    setShowDatePicker(true);
  };

  const openModal = () => {
    setModalGoalsDateSuggest(true);
  };

  const onChangeInitialDate = () => {
    if (showDatePicker) {
      setShowDatePicker(false);
    }
  };

  const interceptDatePicker =
    !hasSynchedPlanning &&
    activeGoalPeriod !== null &&
    activeGoalPeriod.initialDate !== null;

  const getSubmitActiveGoalMessage = () => (
    <span>
      Verificamos que você modificou o período do planejamento. Por essa razão,
      ele <strong>não estará mais sincronizado com as metas da fazenda.</strong>{" "}
      Você tem certeza de que deseja salvar essas alterações?
    </span>
  );

  const onCancelSubmit = () => {
    setModalSubmitActiveGoalPeriod(false);
  };

  const hasGoalPlanSync = useMemo(
    () =>
      activeGoalPeriod.initialDate != null &&
      currentPlanning.initialDate != null &&
      compareDateForPlanning(
        currentPlanning.initialDate,
        planningPlanDataInitialDate
      ) &&
      compareDateForPlanning(
        currentPlanning.initialDate,
        activeGoalPeriod.initialDate
      ),
    [currentPlanning.initialDate, planningPlanDataInitialDate, activeGoalPeriod]
  );

  const isGoalPlanUnsync = useMemo(
    () =>
      activeGoalPeriod.initialDate != null &&
      planningPlanDataInitialDate != null &&
      !compareDateForPlanning(
        planningPlanDataInitialDate,
        activeGoalPeriod.initialDate
      ),
    [planningPlanDataInitialDate, activeGoalPeriod.initialDate]
  );

  const shouldInterceptSubmit = useMemo(() => {
    const intercept =
      planningPlanDataInitialDate != null &&
      activeGoalPeriod.initialDate != null &&
      currentPlanning.initialDate != null &&
      compareDateForPlanning(
        currentPlanning.initialDate,
        activeGoalPeriod.initialDate
      ) &&
      !compareDateForPlanning(
        planningPlanDataInitialDate,
        currentPlanning.initialDate
      );
    return intercept;
  }, [
    planningPlanDataInitialDate,
    activeGoalPeriod.initialDate,
    currentPlanning.initialDate,
  ]);

  return {
    activeGoalPeriod,
    hasGoalPlanSync,
    isGoalPlanUnsync,
    modalSubmitActiveGoalPeriod: {
      shouldInterceptSubmit,
      modalSubmitActiveGoalPeriod,
      setModalSubmitActiveGoalPeriod,
      getSubmitActiveGoalMessage,
      onCancelSubmit,
    },
    modalDatePickerActiveGoalPeriod: {
      modalGoalsDateSuggest,
      onChangeInitialDate,
      openModal,
      getActiveGoalPeriodMessage,
      onConfirmGoalPeriodDate,
      onCancelGoalPeriodDate,
      interceptDatePicker,
      showDatePicker,
      setShowDatePicker,
    },
  };
};
