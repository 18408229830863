import React, { Component } from "react";
import MensagemSemPasto from "../MensagemSemPasto";
import PropTypes from "prop-types";
import _ from "lodash";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormatadorNumericoCustomizado from "features/manejo/views/components/FormatadorNumericoCustomizado";
import { cores } from "constants/coresModulos";
import RadioCor from "../../RadioCor";
import { DialogExcluir } from "../DialogExcluir";
import { Grid, FormLabel, Button, Typography } from "@material-ui/core";
import TextField from "@mui/material/TextField";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import { enviaDadosGAPorRotulo } from "../../../../../../../shared/utils";
import SelectDropdown from "components/SelectDropdown";
import { getAnimalsQuantity } from "features/manejo/views/PasturesUtils";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Cancel from "@material-ui/icons/Cancel";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    slotProps={{
      popper: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [-32, -24],
            },
          },
        ],
      },
    }}
  />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#ffffff",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    width: "190px",
    borderRadius: "20px",
    fontSize: 11,
  },
}));

export class CadastroCochoCompartilhado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Cocho: {
        IdCocho: null,
        Coberto: "",
        Comprimento: "",
        Elevado: "",
        EstoqueFinal: "",
      },
    };
  }

  construtirEstadoCocho = (Cocho) => {
    const cochoEstado = {
      Coberto:
        Cocho && Cocho.Coberto !== null && Cocho.Coberto !== undefined
          ? Cocho.Coberto
          : "",
      Comprimento:
        Cocho && Cocho.Comprimento !== null && Cocho.Comprimento !== undefined
          ? Cocho.Comprimento
          : "",
      Elevado:
        Cocho && Cocho.Elevado !== null && Cocho.Elevado !== undefined
          ? Cocho.Elevado
          : "",
      EstoqueFinal:
        Cocho && Cocho.EstoqueFinal !== null && Cocho.EstoqueFinal !== undefined
          ? Cocho.EstoqueFinal
          : "",
      IdCocho: Cocho ? Cocho.IdCocho : null,
      Cor:
        Cocho && Cocho.Cor !== null && Cocho.Cor !== undefined ? Cocho.Cor : "",
    };
    return cochoEstado;
  };

  onChangeCochoTipo = (value) => {
    const event = {
      target: {
        value,
      },
    };
    this.props.handleChangeFormCocho("Coberto", event);
  };

  onChangeCochoElevado = (value) => {
    const event = {
      target: {
        value,
      },
    };
    this.props.handleChangeFormCocho("Elevado", event);
  };

  onChangeCochoEstoqueFinal = (value) => {
    const event = {
      target: {
        value,
      },
    };
    this.props.handleChangeFormCocho("EstoqueFinal", event);
  };

  componentDidMount = () => {
    const { Cocho, getPasturesQuantity } = this.props;
    getPasturesQuantity();

    this.construtirEstadoCocho(Cocho);

    const acao = "Gestão de vínculos";
    const rotulo = "Cocho Compartilhado";
    const categoria = "Mapa";
    enviaDadosGAPorRotulo(acao, rotulo, categoria);
  };

  calculateAnimalsQuantity = () => {
    const { pastosSelecionados, pasturesQuantity } = this.props;
    const animalsQuantity = getAnimalsQuantity(
      pastosSelecionados,
      pasturesQuantity
    );

    return animalsQuantity;
  };

  isDisabledButton = () => {
    const {
      Cocho: { Coberto, Elevado, EstoqueFinal, Comprimento },
    } = this.props;
    const { pastosSelecionados } = this.props;

    const isCobertoSelected =
      Coberto !== null &&
      Coberto !== undefined &&
      (Coberto === true || Coberto === false);

    const isElevadoSelected =
      Elevado !== null &&
      Elevado !== undefined &&
      (Elevado === true || Elevado === false);

    const isEstoqueFinalSelected =
      EstoqueFinal !== null &&
      EstoqueFinal !== undefined &&
      (EstoqueFinal === true || EstoqueFinal === false);

    const isComprimentoSelected = Comprimento !== "";

    const isPasturesSelected = pastosSelecionados.length > 1;

    return (
      isCobertoSelected &&
      isElevadoSelected &&
      isEstoqueFinalSelected &&
      isComprimentoSelected &&
      isPasturesSelected
    );
  };

  onChangeCochoTipo = (value) => {
    this.props.handleChangeFormCocho("Coberto", value);
  };
  onChangeCochoElevado = (value) => {
    this.props.handleChangeFormCocho("Elevado", value);
  };
  onChangeCochoEstoqueFinal = (value) => {
    this.props.handleChangeFormCocho("EstoqueFinal", value);
  };

  render() {
    const {
      classes,
      pastos,
      onChangeCor,
      corProps,
      handleLimparSelecao,
      handleSubmit,
      handleAbreModalExclusao,
      modalConfirmacaoExclusao,
      handleFechaModalExclusao,
      handleDelete,
      handleRemovePasture,
    } = this.props;

    const animalsQuantity = this.calculateAnimalsQuantity();
    const isDisabled = !this.isDisabledButton();

    let Cocho = null;
    if (!this.props.Cocho) {
      Cocho = this.construtirEstadoCocho(this.state.Cocho);
    } else {
      Cocho = this.construtirEstadoCocho(this.props.Cocho);
    }

    if (!Cocho) {
      return null;
    }

    const { Coberto, Elevado, EstoqueFinal } = this.props.listas;
    const pastosSelecionados = this.props.pastosSelecionados;
    if (
      !Cocho.IdCocho &&
      (!pastos || pastos.length === 0 || pastosSelecionados.length === 0)
    ) {
      const mensagemUsuario = {
        semPastos: "Para começar cadastre o KML da fazenda selecionada.",
        nadaSelecionado:
          "Para começar, selecione mais de um pasto no mapa ao lado.",
      };

      return (
        <MensagemSemPasto
          id="mensagemSemPasto"
          pastos={pastos}
          pastosSelecionados={pastosSelecionados}
          mensagemUsuario={mensagemUsuario}
          imagemFundo={"../images/map_indicator.svg"}
        />
      );
    }

    return (
      <div id="cadastroCochoCompartilhado" className={classes.abaContainer}>
        <Grid
          container
          alignItems="flex-start"
          direction="row"
          justify="flex-start"
          spacing={16}
          style={{ marginTop: "16px" }}
        >
          <Grid item xs={12} style={{ marginTop: "30px" }}>
            <div>
              <SelectDropdown
                isDisabled={Cocho.IdCocho}
                id="cocho-coberto"
                label={{
                  text: "Tipo",
                  required: true,
                }}
                onChange={this.onChangeCochoTipo}
                options={Coberto}
                value={Cocho.Coberto}
                emptyText="Selecione"
              />
            </div>
            <div>
              <TextField
                id="comprimento"
                variant="outlined"
                required
                style={{
                  width: "100%",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
                InputProps={{
                  inputComponent: FormatadorNumericoCustomizado,
                  endAdornment: (
                    <InputAdornment position="end">cm</InputAdornment>
                  ),
                }}
                label="Comprimento"
                onChange={(event) =>
                  this.props.handleChangeFormCocho(
                    "Comprimento",
                    event.target.value
                  )
                }
                value={Cocho.Comprimento ? Cocho.Comprimento : ""}
                disabled={Cocho.IdCocho}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Insira o comprimento"
              />
            </div>
            <div>
              <SelectDropdown
                isDisabled={Cocho.IdCocho}
                id="cocho-elevado"
                label={{
                  text: "Elevado",
                  required: true,
                }}
                onChange={this.onChangeCochoElevado}
                options={Elevado}
                value={Cocho.Elevado}
                emptyText="Selecione"
              />
            </div>
            <div className={classes.estoqueFinal}>
              <SelectDropdown
                isDisabled={Cocho.IdCocho}
                id="cocho-estoque-final"
                label={{
                  text: "Estoque Final",
                  required: true,
                }}
                onChange={this.onChangeCochoEstoqueFinal}
                options={EstoqueFinal}
                value={Cocho.EstoqueFinal}
                emptyText="Selecione"
              />
            </div>

            {pastosSelecionados.length > 0 && (
              <>
                <div className={classes.labels}>
                  <FormLabel
                    gutterBottom
                    style={{ color: "#666666", fontWeight: "500" }}
                  >
                    Pastos selecionados:
                  </FormLabel>
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: "normal",
                      color: "#8a8a8a",
                      margin: "4px 0 0",
                    }}
                  >
                    Selecione dois ou mais pastos.
                  </p>
                  <div className={classes.selectedPastures}>
                    {pastosSelecionados.map((pasture) => (
                      <Stack direction="row" spacing={1}>
                        <Chip
                          label={pasture.Nome}
                          onDelete={() => handleRemovePasture(pasture)}
                          style={{
                            backgroundColor: "#e7f1fd",
                            fontWeight: "bold",
                            margin: "5px 5px 0px 0px",
                            color: "#0053a0",
                          }}
                          deleteIcon={
                            <Cancel
                              style={{ fontSize: "14px", color: "#616161" }}
                            />
                          }
                        />
                      </Stack>
                    ))}
                  </div>
                </div>
                <FormLabel
                  gutterBottom
                  className={classes.labels}
                  style={{ color: "#666666", fontWeight: "500" }}
                >
                  Quantidade de animais nos pastos selecionados:
                </FormLabel>
                <Typography
                  gutterBottom
                  className={classes.labels}
                  color="primary"
                  style={{ color: "#0053a0", fontWeight: "bold" }}
                >
                  {animalsQuantity}
                  <span style={{ fontWeight: "normal" }}>
                    {animalsQuantity === 1 ? " animal" : " animais"}
                  </span>
                </Typography>
              </>
            )}

            <FormLabel
              className={classes.labels}
              component="legend"
              align="left"
              style={{ color: "#666666", fontWeight: "500" }}
            >
              Escolher cor:
            </FormLabel>

            <Grid container justify="space-between" className={classes.labels}>
              {_.map(cores, (cor, index) => (
                <RadioCor
                  key={index}
                  corSelecionada={Cocho.IdCocho != null ? Cocho.Cor : corProps}
                  corRadio={cor}
                  style={classes[`iconCor${index + 1}`]}
                  handleChange={Cocho.IdCocho == null ? onChangeCor : null}
                />
              ))}
            </Grid>
          </Grid>
          <Grid
            id="conteudoMenuCriacao"
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-start"
            style={{ marginTop: "30px" }}
          >
            <Button
              id="botaoLimparMenuCriacao"
              className={classes.buttonDelete}
              style={{ marginTop: "0" }}
              onClick={
                Cocho.IdCocho ? handleAbreModalExclusao : handleLimparSelecao
              }
              color="default"
              variant="contained"
              aling="rigth"
            >
              {Cocho.IdCocho ? "EXCLUIR" : "CANCELAR"}
            </Button>
            <DialogExcluir
              texto={"Excluir o Cocho Compartilhado selecionado?"}
              modalConfirmacaoExclusao={modalConfirmacaoExclusao}
              handleFechaModalExclusao={handleFechaModalExclusao}
              handleDelete={handleDelete}
            />

            <CustomTooltip
              title="Preencha todos os campos e selecione pelo menos dois pastos."
              placement="top"
            >
              <div>
                <Button
                  id="botaoSalvarMenuCriacao"
                  onSubmit={handleSubmit}
                  variant="contained"
                  color="primary"
                  type="submit"
                  aling="rigth"
                  style={{ borderRadius: "30px" }}
                  disabled={isDisabled}
                >
                  SALVAR
                </Button>
              </div>
            </CustomTooltip>
          </Grid>
        </Grid>
      </div>
    );
  }
}

CadastroCochoCompartilhado.propTypes = {
  Cocho: PropTypes.object,
  errosValidacao: PropTypes.object,
  handleChangeFormCocho: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

CadastroCochoCompartilhado.defaultProps = {
  errosValidacao: {
    Coberto: "",
    Comprimento: "",
    Elevado: "",
    EstoqueFinal: "",
  },
  listas: {
    Coberto: [
      {
        value: true,
        label: "Coberto",
      },
      {
        value: false,
        label: "Descoberto",
      },
    ],
    Elevado: [
      {
        value: true,
        label: "Sim",
      },
      {
        value: false,
        label: "Não",
      },
    ],
    EstoqueFinal: [
      {
        value: true,
        label: "Sim",
      },
      {
        value: false,
        label: "Não",
      },
    ],
  },
};

export default CadastroCochoCompartilhado;
