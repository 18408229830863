import * as categoriaActions from './categoriaActions';

export const INITIAL_STATE = {
  categorias: [],
  categoria: null,
  curvas: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case categoriaActions.LISTA_CATEGORIAS:
      return {
        ...state, categorias: action.payload.CategoriasFazenda
      };
    case categoriaActions.LISTA_CURVAS:
      return {
        ...state, curvas: action.payload.CurvasGanhoPeso
      };
    case categoriaActions.SALVAR_CATEGORIA:
      return {
        ...state, categoria: action.payload
      };
    default:
      return state;
  }
};
