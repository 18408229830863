import {
  SELECIONA_FAROL,
  LISTA_FAROL,
  SELECIONA_TIPOCOLETA,
  SELECIONA_TIPOFAROL,
  SET_FAZENDA,
  ALTERA_ZOOM_MAPA_FAROL,
  EXIBIR_DETALHES,
  ALETERA_PERIODO_COLETA_FAROL,
  EXIBIR_ICONE_MAPA_FAROL,
  RESET_PERIODO_COLERA_FAROL,
} from "redux/actions/actionsConstants";
import moment from "moment";
moment.locale("pt-BR");
const dataAtual = moment();
const dataCalculada = moment().subtract(90, "days");

export const initialState = {
  FarolSelecionado: null,
  exibirDetalhes: false,
  TipoFarol: null,
  ZoomMapa: 14,
  zoomExibeIconePastoFarol: false,
  PeriodoColeta: {
    inicio: dataCalculada,
    final: dataAtual,
  },
  Farois: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECIONA_FAROL:
      return {
        ...state,
        FarolSelecionado: action.payload,
      };
    case SELECIONA_TIPOCOLETA:
      return { ...state, TipoIconeExibido: action.payload };
    case SELECIONA_TIPOFAROL:
      return {
        ...state,
        TipoFarol: action.payload,
        TipoIconeExibido: action.payload === "rebanho" ? "UA" : "Todos",
      };
    case LISTA_FAROL:
      return { ...state, Farois: action.payload.Farois };
    case SET_FAZENDA:
      return { ...initialState };
    case ALTERA_ZOOM_MAPA_FAROL:
      return { ...state, ZoomMapa: action.payload };
    case EXIBIR_ICONE_MAPA_FAROL:
      return { ...state, zoomExibeIconePastoFarol: action.payload };
    case EXIBIR_DETALHES:
      return { ...state, exibirDetalhes: action.payload };
    case ALETERA_PERIODO_COLETA_FAROL:
      return { ...state, PeriodoColeta: action.payload };
    case RESET_PERIODO_COLERA_FAROL:
      return { ...state, PeriodoColeta: initialState.PeriodoColeta };
    default:
      return state;
  }
};
