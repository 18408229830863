import React from "react";
import { Button, withStyles } from "@material-ui/core";

import DatePicker from "./datePicker";
import SkeletonLoading from "features/shared/components/SkeletonLoading";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import SelectDropdown from "components/SelectDropdown";

const SupplementationFilterContainer = ({
  classes,
  filter,
  isDeletedSupplementationsPage,
  goToDeletedSupplementationsPage = () => {},
}) => {
  const {
    formula: {
      list: formulaList,
      selected: formulaSelected,
      onChange: onChangeFormula,
      isLoading: formulaIsLoading,
    },
    pasture: {
      list: pastureList,
      selected: pastureSelected,
      onChange: onChangePasture,
      isLoading: pastureIsLoading,
    },
    user: {
      list: userList,
      selected: userSelected,
      onChange: onChangeUser,
      isLoading: userIsLoading,
    },
    beginDate: {
      value: beginDateValue,
      onChange: onChangeBeginDate,
      isLoading: beginDateIsLoading,
    },
    endDate: {
      value: endDateValue,
      onChange: onChangeEndDate,
      isLoading: endDateIsLoading,
    },
  } = filter;

  const optionsFormula = [
    ...formulaList.activeFormulas,
    ...formulaList.disabledFormulas,
  ];

  return (
    <div className={classes.container}>
      <div>
        {formulaIsLoading ? (
          <MultiSelectSkeleton />
        ) : (
          <SelectDropdown
            testId={"formula-select"}
            isMulti
            isClearable
            isSearchable
            isLoading={formulaIsLoading}
            label={{ text: "Formulação" }}
            options={optionsFormula}
            value={formulaSelected}
            onChange={onChangeFormula}
            groupBy={(option) => option.group}
            styles={{
              minWidth: 250,
            }}
          />
        )}
      </div>

      <div>
        {pastureIsLoading ? (
          <MultiSelectSkeleton />
        ) : (
          <SelectDropdown
            isMulti
            isClearable
            isSearchable
            isLoading={pastureIsLoading}
            label={{ text: "Pasto" }}
            options={pastureList}
            value={pastureSelected}
            onChange={onChangePasture}
            styles={{
              minWidth: 250,
            }}
          />
        )}
      </div>

      <div className={classes.datePickerContainer}>
        {beginDateIsLoading ? (
          <DatePickerSkeleton />
        ) : (
          <div className={classes.datePickerWrapper}>
            <DatePicker
              label="Data início"
              minDate={new Date(0)}
              maxDate={endDateValue || new Date()}
              onChangeDate={onChangeBeginDate}
              value={beginDateValue}
            />
          </div>
        )}
        {endDateIsLoading ? (
          <DatePickerSkeleton />
        ) : (
          <div className={classes.datePickerWrapper}>
            <DatePicker
              label="Data fim"
              minDate={beginDateValue || new Date(0)}
              onChangeDate={onChangeEndDate}
              value={endDateValue}
            />
          </div>
        )}
      </div>

      <div>
        {userIsLoading ? (
          <MultiSelectSkeleton />
        ) : (
          <SelectDropdown
            isMulti
            isClearable
            isSearchable
            isLoading={userIsLoading}
            label={{ text: "Responsável pela coleta" }}
            options={userList}
            value={userSelected}
            onChange={onChangeUser}
            styles={{
              minWidth: 250,
            }}
          />
        )}
      </div>

      {!isDeletedSupplementationsPage && (
        <div>
          <Button
            data-testid="deleted-collections-button"
            variant="outlined"
            color="primary"
            onClick={goToDeletedSupplementationsPage}
            className={classes.button}
          >
            <div className={classes.buttonTitle}>
              <span>COLETAS EXCLUÍDAS</span>
            </div>
            <div className={classes.buttonArrowWrapper}>
              <ArrowForwardIcon />
            </div>
          </Button>
        </div>
      )}
    </div>
  );
};

const MultiSelectSkeleton = () => <SkeletonLoading width={250} height={56} />;

const DatePickerSkeleton = () => (
  <div>
    <SkeletonLoading width={35} height={14} marginBottom={4} />
    <SkeletonLoading width={150} height={32} marginRight={12} />
  </div>
);

const styles = () => ({
  container: {
    width: "100%",
    minHeight: "110px",
    display: "flex",
    padding: "8px 0",
    alignItems: "center",
    justifyContent: "start",
    flexWrap: "wrap",
    gap: "30px",
  },
  inputLabel: {
    fontSize: 12,
    color: "#7f7f7f",
    fontFamily: "Roboto",
  },
  multiSelect: {
    fontSize: 15,
    color: "rgb(0, 0, 0)",
    fontFamily: "Roboto",
    "--rmsc-gray": "rgba(0, 0, 0, 0.54) !important",
    "--rmsc-border": "none !important",
    "--rmsc-main": "none !important",
    borderBottom: "1px solid rgba(0, 0, 0, 0.54)",
  },
  datePickerContainer: {
    display: "flex",
    gap: "15px",
  },
  datePickerWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    padding: "10px 16px",
    borderRadius: "100px",
  },
  buttonTitle: {
    textAlign: "left",
    whiteSpace: "nowrap",
  },
  buttonArrowWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 4,
  },
});

export default withStyles(styles)(SupplementationFilterContainer);
