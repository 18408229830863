import React, { Component } from "react";
import { Grid, withStyles } from "@material-ui/core";
import FechamentoConsumoCabecalho from "./fechamentoConsumoCabecalho";
import FechamentoConsumoCard from "./fechamentoConsumoCard";
import FechamentoConsumoConteudoAppBar from "./fechamentoConsumoConteudoAppBar";
import {
  filtraConsumosPorFormulas,
  listarConsumos,
  DATA_MINIMA,
  DATA_INICIO,
  DATA_TERMINO,
  FAIXA_ACEITE,
} from "../fechamentoConsumoUtils";
import { FakeList } from "../../shared/components";
import FechamentoConsumoFeedback from "./fechamentoConsumoFeedback";
import { retornaDiferencaDatas, objetoPreenchido } from "../../shared/utils";
import { FazendaSelecionadaContainer } from "../../shared/crossCuttingComponents/fazendaSelecionada";
import moment from "moment";
import FechamentoConsumoLegenda from "./fechamentoConsumoLegenda";

export class FechamentoConsumoPage extends Component {
  state = {
    formulaSelecionada: { NomeFormula: "Todas" },
    formulasSelecionadas: [{ NomeFormula: "Todas" }],
    dataInicio: DATA_INICIO,
    dataFim: DATA_TERMINO,
    retirosSelecionados: [{ Nome: 'Todos' }],
    newValorInicial: [],
  };

  async componentDidMount() {
    const { listarRetiros, fazendaSelecionada } = this.props;
    await listarRetiros(fazendaSelecionada.IdFazenda);
  }

  aplicarFiltros = async (
    dataInicio,
    dataFim,
    formulaSelecionada,
    retirosSelecionados
  ) => {
    this.setState({
      dataInicio,
      dataFim,
      formulaSelecionada: { NomeFormula: "Todas" },
      formulasSelecionadas: [{ NomeFormula: "Todas" }],
      retirosSelecionados: [{ Nome: 'Todos' }],
    });
    const { obtemFechamentoConsumo } = this.props;
    await obtemFechamentoConsumo({
      dataInicio: moment(dataInicio).format(),
      dataFim: moment(dataFim).format(),
      formulaSelecionada,
      retirosSelecionados,
    });
  };

  setFormulaSelecionada = (formula) => {
    this.setState({
      formulaSelecionada: formula,
    });
  };

  setFormulasSelecionadas = (formulas) => {
    this.setState({
      formulasSelecionadas: formulas,
    });
  };

  setRetirosSelecionadas = (retiros) => {
    this.setState({
      retirosSelecionados: retiros,
    });
  };

  corpoFechamentoConsumo = (fazenda) => {
    const { consumos, chamadaConcluida, classes } = this.props;
    const {
      formulaSelecionada,
      dataInicio,
      dataFim,
      formulasSelecionadas,
    } = this.state;

    const fazendaPreenchida = objetoPreenchido(fazenda);
    let consumosFormulas;
    // caso exista somente o Todas marcado vai pegar tudo
    if (
      formulasSelecionadas.length <= 1 &&
      formulasSelecionadas.some((f) => f.NomeFormula === "Todas")
    ) {
      consumosFormulas = filtraConsumosPorFormulas(
        consumos,
        "NomeFormula",
        formulaSelecionada.NomeFormula
      );

    } else {
      // caso tenha mais formulas vai pegar uma por uma para criar os cards
      consumosFormulas = consumos
        .filter((consumo) => formulasSelecionadas
          .some((formula) =>
            String(formula.NomeFormula).toUpperCase() === String(consumo.NomeFormula).toUpperCase()
          )
        )
    }

    const diferencaDatas = retornaDiferencaDatas(dataInicio, dataFim) + 1;

    if (fazendaPreenchida && listarConsumos(chamadaConcluida, consumosFormulas)) {
      return (
        <Grid
          container
          justify="flex-start"
          direction="row"
          className={classes.cardList}
        >
          <FechamentoConsumoLegenda />
          <FakeList
            listarItens={chamadaConcluida}
            itensListar={consumosFormulas}
            numeroItensFake={3}
            renderItem={(consumoFormula, idx) => {
              return (
                <FechamentoConsumoCard
                  key={`fechamentoConsumo-${idx}`}
                  dadosCarregados={chamadaConcluida}
                  consumoFormula={{
                    ...consumoFormula,
                    PeriodoBuscado: diferencaDatas,
                  }}
                  index={idx}
                  faixaAceite={FAIXA_ACEITE}
                />
              );
            }}
          />
        </Grid>
      );
    }

    return <FechamentoConsumoFeedback />;
  };

  // Irá inicializar as fomulas selecionadas no checkbox deve ser um array de texto
  iniciandoValoresFormulas = () => {
    const { formulas } = this.props;
    const { newValorInicial } = this.state;
    if (newValorInicial.length <= 1) {
      formulas.forEach((f) => {
        newValorInicial.push({ label: f.NomeFormula, value: f.IdFormula });
      });
    }
  };

  conteudoPagina = () => {
    const { formulas, classes, chamadaConcluida, retiros } = this.props;
    const desabilitaControles = !chamadaConcluida;
    const {
      formulaSelecionada,
      dataInicio,
      dataFim,
      retirosSelecionados,
      newValorInicial,
    } = this.state;
    this.iniciandoValoresFormulas();
    const filtroRetiro = retiros.map((v) => ({ label: v.Nome, value: v.IdRetiro }));

    return (
      <Grid
        container
        direction="column"
        className={classes.containerPage}
        alignItems="center"
      >
        <Grid container item direction="column" className={classes.content}>
          <React.Fragment>
            <Grid container item>
              <FechamentoConsumoCabecalho
                formulaSelecionada={formulaSelecionada}
                desabilitaControles={desabilitaControles}
                aplicarFiltros={this.aplicarFiltros}
                dataInicio={dataInicio}
                dataFim={dataFim}
                minDate={DATA_MINIMA}
                maxDate={DATA_TERMINO}
                selectFormula={this.setFormulaSelecionada}
                formulas={formulas}
                filtroRetiro={filtroRetiro}
                retiros={retiros}
                retirosSelecionados={retirosSelecionados}
                valorInicial={newValorInicial}
                setFormulasSelecionadas={this.setFormulasSelecionadas}
                setRetirosSelecionados={this.setRetirosSelecionadas}
              />
            </Grid>
          </React.Fragment>
        </Grid>
          <FazendaSelecionadaContainer
            render={(fazenda) => this.corpoFechamentoConsumo(fazenda)}
          />
      </Grid>
    );
  };
  render() {
    return (
      <>
        {<FechamentoConsumoConteudoAppBar />}
        {this.conteudoPagina()}
      </>
    );
  }
}

const styles = (theme) => {
  return {
    containerPage: {
      backgroundColor: theme.palette.white.main,
      paddingTop: 30,
    },
    content: {
      backgroundColor: theme.palette.white.main,
      width: "90%",
      height: 'auto',
      padding: "5px 0",
    },
    cardList: {
      paddingTop: 20,
      paddingLeft: '5%',
      paddingRight: '5%',
      backgroundColor: '#f8f8f8',
      "&:hover": { overflowY: "auto" },
    },
  };
};
export default withStyles(styles)(FechamentoConsumoPage);
