import { notifyError } from "../../../redux/actions/notificacaoActions";
import TableauEmbeddedManager from "../tableauEmbeddedManager";
import {
  listarRelatorios,
  listarPastasUsuario,
  exibirCarregando,
} from "./relatoriosActions";

export const obterRelatorios = () => async (dispatch, getState) => {
  dispatch(exibirCarregando(true));
  try {
    const relatorios = await TableauEmbeddedManager.listarRelatorios();
    dispatch(listarRelatorios(relatorios));
  } catch (error) {
    dispatch(listarRelatorios([]));
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao obter a lista de relatorios.",
      })
    );
  } finally {
    dispatch(exibirCarregando(false));
  }
};

export const obterPastasUsuario = (user) => async (dispatch, getState) => {
  dispatch(exibirCarregando(true));
  try {
    const relatorios = await TableauEmbeddedManager.listarPastasUsuario(user);
    dispatch(listarPastasUsuario(relatorios));
  } catch (error) {
    dispatch(listarPastasUsuario([]));
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao obter a lista de pastas.",
      })
    );
  } finally {
    dispatch(exibirCarregando(false));
  }
};
