import React from "react";
import { TextField, Typography, withStyles } from "@material-ui/core";
import { DatePicker } from "../../shared/components";
import LogoProdap from "../../layout/views/logoProdap";
import { TextLabel } from "../../shared/components";
import ConciliacaoResumoTable from "./conciliacaoResumoTable";
import ConciliacaoTabela from "./conciliacaoTabela";

const ConciliacaoPrintTemplate = (props) => {
  const {
    classes,
    resumo,
    dadosConciliacao,
    exibirDescritivoModal,
    obterDescritivoDeColetas,
    stylesLinhaHorizontal,
    fazenda,
    categoriaSelecionada,
    listaDeCategorias,
    dataInicio,
    dataFim,
  } = props;

  const nomeFazenda = (fazenda.Fazendas || []).find(
    (v) => v.IdFazenda === fazenda.FazendaSelecionada
  ).NomeReduzido;

  const categoriaInput = (
    listaDeCategorias || [{ label: "TODOS", value: 0 }]
  ).find((v) => v.value === categoriaSelecionada);

  const getPageMargins = () => {
    return `
    @media all {
      .page-break {
        display: none;
      }
    }
    @media print {
      html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
      }
      .page-break {
        display: block;
        page-break-before: auto;
      }
    }
    @page {
      size: auto;
      margin: 6mm 0 0 0;
      size: portrait;
    }
    `;
  };

  return (
    <div>
      <style>{getPageMargins()}</style>
      <div className={classes.container}>
        <div style={{ width: "100%" }}>
          <div className={classes.logoContainer}>
            <LogoProdap genericClass={classes.logo} id="logoPropdapAppBar" />
          </div>
          <div className={classes.titleContainer}>
            <TextLabel size={21} style={{ flex: 2 }} id="tituloDoAppBar">
              {nomeFazenda}
            </TextLabel>
          </div>
        </div>
        <div className={classes.listaFiltros}>
          <div style={{ marginTop: 10, marginBottom: 22 }}>
            <TextLabel
              size={18}
              bold
              customStyle={{ color: "#424242" }}
              id="tituloPage"
            >
              CONCILIAÇÃO DE REBANHO
            </TextLabel>
          </div>
          <div className={classes.linhaFiltro}>
            <div className={classes.camposInput}>
              <TextField
                id="categoria-nome"
                label="Categoria"
                style={{ width: "100%" }}
                value={categoriaInput.label}
                margin="none"
              />
            </div>
            <div className={classes.camposData}>
              <DatePicker
                label="Data Inicial"
                value={dataInicio}
                minDate={dataInicio}
                customStyle={{ marginLeft: 5 }}
              />
            </div>
            <div className={classes.camposData}>
              <DatePicker
                label="Data Final"
                value={dataFim}
                minDate={dataFim}
              />
            </div>
          </div>
        </div>
        <div style={{ margin: "1rem" }}>
          <Typography className={classes.titulo} gutterBottom>
            RESUMO
          </Typography>
          {resumo && (
            <ConciliacaoResumoTable minimizado={true} resumo={resumo} />
          )}
          <hr style={stylesLinhaHorizontal} />
          <Typography className={classes.titulo} gutterBottom>
            DETALHADO
          </Typography>
          <ConciliacaoTabela
            minimizado={true}
            dadosConciliacao={dadosConciliacao}
            exibirDescritivoModal={exibirDescritivoModal}
            obterDescritivoDeColetas={obterDescritivoDeColetas}
          />
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "1200px",
    boxSizing: "border-box",
  },
  logo: {
    width: "101px",
    height: "35px",
    cursor: "pointer",
  },
  logoContainer: {
    marginTop: 21,
    marginLeft: "1.4rem",
    position: "absolute",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 90,
    width: "100%",
  },
  listaFiltros: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
    marginLeft: "1.4rem",
  },
  linhaFiltro: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    height: 96,
  },
  camposInput: {
    maxWidth: 255,
    width: "100%",
    marginRight: 32,
  },
  camposData: {
    marginRight: 32,
    minWidth: 102,
    maxWidth: 238,
  },
  titulo: {
    fontSize: "16px",
    fontWeight: "500",
    fontFamily: "Roboto",
    color: "#424242",
    marginBottom: "20px",
  },
};

export default withStyles(styles)(ConciliacaoPrintTemplate);
