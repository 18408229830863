import React from 'react';

const marcacaoVertical = props => {
  const { color } = props;
  return (
    <div
      style={{
        width: 8,
        height: 26,
        borderRadius: 3,
        marginRight: 10,
        backgroundColor: color
      }}
    ></div>
  );
};
export default marcacaoVertical;
