import React, { useMemo, useState } from "react";
import PlanningContent from "../planningContent";
import { Button, withStyles } from "@material-ui/core";
import PlanningsForm from "../planningsForm";
import ActionButtons from "features/shared/components/actionButtons";
import PlanningRegisterTable from "../planningRegisterTable";
import PlanningRegisterSteps from "../planningRegisterSteps";
import PlanningIngredientsTable from "../planningIngredientsTable";
import PlanningSummary from "../planningSummary";

const TOOLTIP_MESSAGE =
  "Para salvar, é necessário preencher todos os campos da tabela, informando a formulação e número de animais.";
const TOOLTIP_MESSAGE_MISSING_INGREDIENTS =
  "Por favor, realize o cadastro dos ingredientes para prosseguir.";
const SAVE_BUTTON_TEXT = "Salvar Planejamento";
const NEXT_BUTTON_TEXT = "Próximo";
const BACK_BUTTON_TEXT = "Voltar";
const CANCEL_BUTTON_TEXT = "Cancelar";

const STEPS = {
  PLANNING: 0,
  INGREDIENTS: 1,
  REVISION: 2,
};

const PlanningFormPage = ({
  classes,
  title,
  subtitle,
  ingredientsSubtitle,
  summarySubTitle,
  planningPlanData,
  periodTitles,
  categories,
  formulas,
  planningsTableList,
  formulasIngredients,
  columns,
  ingredientsColumns = [],
  updateIngredientsList,
  onClickInitialDate,
  interceptDatePicker,
  showDatePicker,
  setShowDatePicker,
  onSubmit = () => {},
  goBack = () => {},
  onChangeInitialDate = () => {},
  summaryReport,
  updateSummaryReport,
}) => {
  const [activeStep, setActiveStep] = useState(STEPS.PLANNING);

  const [tooltipMessage, setTooltipMessage] = useState(TOOLTIP_MESSAGE);

  const renderTable = columns.length > 12;

  const enableButtonNext = useMemo(() => {
    setTooltipMessage(TOOLTIP_MESSAGE);
    let shouldEnableButton = true;

    if (!planningPlanData.initialDate || !planningPlanData.finalDate) {
      return false;
    }
    if (planningsTableList.length < 2) {
      return false;
    }

    planningsTableList.forEach((planningLine) => {
      if (planningLine.id === "selecionaCategoria") {
        return;
      }
      if (!planningLine.items) {
        shouldEnableButton = false;
        return;
      }
      planningLine.items.forEach((planningItem) => {
        if (planningItem.herdCount === null || !planningItem.formulaId) {
          shouldEnableButton = false;
          return;
        }
        if (planningItem.formulaId && planningItem.hasIngredients === false) {
          shouldEnableButton = false;
          setTooltipMessage(TOOLTIP_MESSAGE_MISSING_INGREDIENTS);
          return;
        }
      });
    });

    return shouldEnableButton;
  }, [planningPlanData, planningsTableList]);

  const firstStep = () => (
    <>
      <div className={classes.subTitle}>{subtitle}</div>
      <PlanningsForm
        planningPlanData={planningPlanData}
        onChangeInitialDate={onChangeInitialDate}
        onClickInitialDate={onClickInitialDate}
        interceptDatePicker={interceptDatePicker}
        showDatePicker={showDatePicker}
        setShowDatePicker={setShowDatePicker}
      />
      <PlanningRegisterTable
        planningsTableList={planningsTableList}
        renderTable={renderTable}
        columns={columns}
      />
    </>
  );

  const firstStepActionButton = () => (
    <div className={classes.actionButtonsContainer}>
      <ActionButtons
        className={classes.actionButtons}
        onSubmit={() => {
          setActiveStep(STEPS.INGREDIENTS);
          updateIngredientsList && updateIngredientsList();
        }}
        onBack={goBack}
        enableButtonSubmit={enableButtonNext}
        textButtonSubmit={NEXT_BUTTON_TEXT}
        tooltipMessage={!enableButtonNext && tooltipMessage}
      />
    </div>
  );

  const secondStep = () => (
    <>
      <div className={classes.subTitle}>{ingredientsSubtitle}</div>
      <PlanningsForm
        planningPlanData={planningPlanData}
        onChangeInitialDate={onChangeInitialDate}
        onClickInitialDate={onClickInitialDate}
        interceptDatePicker={interceptDatePicker}
        showDatePicker={showDatePicker}
        setShowDatePicker={setShowDatePicker}
        disabled={true}
      />
      <PlanningIngredientsTable
        columns={ingredientsColumns}
        planningIngredients={formulasIngredients}
      />
    </>
  );

  const secondStepActionButton = () => (
    <div className={classes.actionButtonsContainer}>
      <Button
        className={classes.cancelButton}
        variant="outlined"
        color="primary"
        onClick={goBack}
      >
        {CANCEL_BUTTON_TEXT}
      </Button>
      <ActionButtons
        className={classes.actionButtons}
        onSubmit={() => {
          setActiveStep(STEPS.REVISION);
          updateSummaryReport(planningPlanData.initialDate);
        }}
        onBack={() => setActiveStep(STEPS.PLANNING)}
        enableButtonSubmit={true}
        textButtonSubmit={NEXT_BUTTON_TEXT}
        textButtonGoBack={BACK_BUTTON_TEXT}
        tooltipMessage={!true && TOOLTIP_MESSAGE}
      />
    </div>
  );

  const thirdStep = () => {
    if (!summaryReport || !summaryReport.planning) return <></>;

    return (
      <>
        <div className={classes.subTitle}>{summarySubTitle}</div>
        <PlanningsForm
          planningPlanData={planningPlanData}
          onChangeInitialDate={onChangeInitialDate}
          onClickInitialDate={onClickInitialDate}
          interceptDatePicker={interceptDatePicker}
          showDatePicker={showDatePicker}
          setShowDatePicker={setShowDatePicker}
          disabled={true}
        />
        <PlanningSummary
          periodTitles={periodTitles}
          categories={categories}
          formulas={formulas}
          summaryReport={summaryReport}
          planningList={summaryReport.planning.planning}
          planningTotal={summaryReport.planning.total}
        />
      </>
    );
  };

  const thirdStepActionButton = () => (
    <div className={classes.actionButtonsContainer}>
      <Button
        className={classes.cancelButton}
        variant="outlined"
        color="primary"
        onClick={goBack}
      >
        {CANCEL_BUTTON_TEXT}
      </Button>
      <ActionButtons
        className={classes.actionButtons}
        onSubmit={onSubmit}
        onBack={() => setActiveStep(STEPS.INGREDIENTS)}
        enableButtonSubmit={true}
        textButtonSubmit={SAVE_BUTTON_TEXT}
        textButtonGoBack={BACK_BUTTON_TEXT}
        tooltipMessage={!true && TOOLTIP_MESSAGE}
      />
    </div>
  );

  return (
    <PlanningContent title={title} onBack={goBack}>
      <PlanningRegisterSteps
        stepsContent={[firstStep, secondStep, thirdStep]}
        activeStep={activeStep}
        stepsActionButons={[
          firstStepActionButton,
          secondStepActionButton,
          thirdStepActionButton,
        ]}
      />
    </PlanningContent>
  );
};

const styles = (theme) => ({
  actionButtonsContainer: {
    display: "flex",
    marginTop: "auto",
  },
  actionButtons: {
    marginTop: 32,
  },
  cancelButton: {
    marginTop: 50,
    width: 196,
    height: 36,
    fontSize: 14,
  },
});

export default withStyles(styles)(PlanningFormPage);
