import React from "react";
import { Switch, Route } from "react-router-dom";

import SupplementationListContainer from "./supplementationList/supplementationListContainer";
import SupplementationDetailContainer from "./supplementationDetail/supplementationDetailContainer";
import SupplementationEditionContainer from "./supplementationEdition/supplementationEditionContainer";

const SupplementationRouter = ({ match }) => {
  return (
    <Switch>
      <Route
        key="supplementationList"
        exact
        path={`${match.path}/`}
        component={SupplementationListContainer}
      />
      <Route
        key="supplementationDeletedList"
        exact
        path={`${match.path}/excluidas`}
        component={SupplementationListContainer}
      />
      <Route
        exact
        path={`${match.path}/:supplementationId`}
        component={SupplementationDetailContainer}
      />
      <Route
        path={`${match.path}/:supplementationId/edicao`}
        component={SupplementationEditionContainer}
      />
    </Switch>
  );
};

export default SupplementationRouter;
