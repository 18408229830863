import React from "react";
import HistoricoMovimentacaoUtils from "../../../historicoMovimentacaoUtils";

import LogoProdap from "../../../../layout/views/logoProdap";
import { TextLabel } from "../../../../shared/components";
import { DatePicker } from "../../../../shared/components";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  withStyles,
  TextField,
} from "@material-ui/core";
import { HistoricoMovimentacaoHead, renderDateDiff } from "../../movementHistoryList/components/movementHistoryListTable";
class HistoricoMovimentacaoAppPrintTemplate extends React.PureComponent {
  render() {
    const {
      listaMovimentacao,
      filtrosSelecionados,
      classes,
      fazenda,
      filtrosHistoricoMovimentacao,
    } = this.props;
    const {
      DataInicio,
      DataFinal,
      Usuario,
      TipoOperacao,
      IdCategoriaOrigem,
      IdCategoriaDestino,
      IdPastoOrigem,
      IdPastoDestino,
    } = filtrosSelecionados;

    const { TiposOperacoes, CategoriasAnimal, Pastos } =
      filtrosHistoricoMovimentacao;

    const nomeFazenda = (fazenda.Fazendas || []).find(
      (v) => v.IdFazenda === fazenda.FazendaSelecionada
    ).NomeReduzido;

    const usuarioSelecionado = Usuario ? Usuario : "TODOS";

    const tipoOperacaoSelecionado =
      TipoOperacao === 0
        ? "TODOS"
        : {
          ...((TiposOperacoes || []).find(
            (v) => v.IdTipoOperacao === TipoOperacao
          ) || { Nome: "TODOS" }),
        }.Nome;

    const CategoriaOrigemSelecionado =
      IdCategoriaOrigem === 0
        ? "TODOS"
        : {
          ...((CategoriasAnimal || []).find(
            (v) => v.IdCategoriaAnimal === IdCategoriaOrigem
          ) || { Nome: "TODOS" }),
        }.Nome;

    const CategoriaDestinoSelecionado =
      IdCategoriaDestino === 0
        ? "TODOS"
        : {
          ...((CategoriasAnimal || []).find(
            (v) => v.IdCategoriaAnimal === IdCategoriaDestino
          ) || { Nome: "TODOS" }),
        }.Nome;

    const PastoOrigemSelecionado =
      IdPastoOrigem === 0
        ? "TODOS"
        : {
          ...((Pastos || []).find((v) => v.IdPasto === IdPastoOrigem) || {
            Nome: "TODOS",
          }),
        }.Nome;

    const PastoDestinoSelecionado =
      IdPastoDestino === 0
        ? "TODOS"
        : {
          ...((Pastos || []).find((v) => v.IdPasto === IdPastoDestino) || {
            Nome: "TODOS",
          }),
        }.Nome;

    return (
      <div className={classes.container}>
        <div className={classes.logoContainer}>
          <LogoProdap genericClass={classes.logo} id="logoPropdapAppBar" />
        </div>
        <div className={classes.titleContainer}>
          <TextLabel bold size={21} style={{ flex: 2 }} id="tituloDoAppBar">
            {nomeFazenda}
          </TextLabel>
        </div>
        <div className={classes.listaFiltros}>
          <div className={classes.filtroRow}>
            <div
              id="historico-primeiro-DataPicker"
              className={classes.camposData}
            >
              <DatePicker
                label="Data Inicial"
                value={DataInicio}
                minDate={DataInicio}
                customStyle={{ marginLeft: 5, marginTop: 16 }}
              />
            </div>
            <div className={classes.camposData}>
              <DatePicker
                label="Data Final"
                minDate={DataInicio}
                value={DataFinal}
                customStyle={{ marginTop: 16 }}
              />
            </div>
            <div className={classes.camposInput}>
              <TextField
                id="usuario-name"
                label="Usuário"
                value={usuarioSelecionado}
                margin="normal"
              />
            </div>
            <div className={classes.camposInput}>
              <TextField
                id="historico-tipo"
                label="Tipo Operação"
                value={tipoOperacaoSelecionado}
                margin="normal"
              />
            </div>
          </div>
          <div className={classes.filtroRow}>
            <div className={classes.camposInput}>
              <TextField
                id="historico-inicial"
                label="Categoria Inicial"
                value={CategoriaOrigemSelecionado}
                margin="normal"
              />
            </div>
            <div className={classes.camposInput}>
              <TextField
                id="historico-final"
                label="Categoria Final"
                value={CategoriaDestinoSelecionado}
                margin="normal"
              />
            </div>
            <div className={classes.camposInput}>
              <TextField
                id="select-filter-pasto-origem"
                label="Pasto de Origem"
                value={PastoOrigemSelecionado}
                margin="normal"
              />
            </div>
            <div className={classes.camposInput}>
              <TextField
                id="select-filter-pasto-destino"
                label="Pasto de Destino"
                value={PastoDestinoSelecionado}
                margin="normal"
              />
            </div>
          </div>
        </div>
        <div className={classes.tableContainer}>
          <Paper style={{ width: "100%" }}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              stickyHeader
              aria-label="enhanced table"
            >
              <HistoricoMovimentacaoHead
                {...{
                  classes,
                  order: "desc",
                  orderBy: "DataISO",
                  onRequestSort: () => { },
                }}
              />
              <TableBody>
                {HistoricoMovimentacaoUtils.stableSort(
                  listaMovimentacao.items,
                  HistoricoMovimentacaoUtils.getComparator(
                    "desc",
                    "DataISO"
                  )
                ).map((row, index) => {
                  const labelId = `historico-movimentacao-${index}`;
                  return (
                    <TableRow hover tabIndex={-1} key={row.IdOperacaoLote}>
                      <TableCell
                        style={{ padding: "4px 0px 4px 12px" }}
                        component="th"
                        id={labelId}
                        scope="row"
                      >
                        {row.UsuarioCriacao}
                      </TableCell>
                      <TableCell
                        style={{ padding: "4px 0px 4px 12px" }}
                        className={classes.bodyCell}
                      >
                        {row.TipoOperacao}
                      </TableCell>
                      <TableCell
                        style={{ padding: "4px 0px 4px 12px" }}
                        className={classes.bodyCell}
                      >
                        {row.Quantidade}
                      </TableCell>
                      <TableCell
                        style={{ padding: "4px 0px 4px 12px" }}
                        className={classes.bodyCell}
                      >
                        {row.OrigemNomeCategoriaAnimal || "_"}
                      </TableCell>
                      <TableCell
                        style={{ padding: "4px 0px 4px 12px" }}
                        className={classes.bodyCell}
                      >
                        {row.DestinoNomeCategoriaAnimal || "_"}
                      </TableCell>
                      <TableCell
                        style={{ padding: "4px 0px 4px 12px" }}
                        className={classes.bodyCell}
                      >
                        {row.OrigemNomePasto || "_"}
                      </TableCell>
                      <TableCell
                        style={{ padding: "4px 0px 4px 12px" }}
                        className={classes.bodyCell}
                      >
                        {row.DestinoNomePasto || "_"}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        style={{ padding: "4px 0px 4px 12px" }}
                        className={classes.bodyCell}
                      >
                        <div className={classes.dateCell}>
                          {`${row.Data}H`}
                        </div>
                      </TableCell>
                      {renderDateDiff(row, classes)}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        </div>
      </div>
    );
  }
}
const styles = (theme) => {
  return {
    container: {
      maxWidth: "1200px",
      boxSizing: "border-box",
      paddingTop: "2rem",
    },
    tableContainer: {
      boxSizing: "border-box",
      maxWidth: "1200px",
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
    table: {
      width: "100%",
      boxSizing: "border-box",
    },
    bodyCell: {
      textTransform: "uppercase",
    },
    dateCell: {
      textAlign: "center",
      width: 54,
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 90,
      width: "100%",
    },
    logo: {
      width: "101px",
      height: "35px",
      cursor: "pointer",
    },
    logoContainer: {
      marginTop: 21,
      marginLeft: "1rem",
      position: "absolute",
    },
    listaFiltros: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      width: "100%",
      marginLeft: "1rem",
    },
    defaultCells: {
      fontFamily: "Roboto",
      textTransform: "uppercase",
      fontSize: 14,
      lineHeight: 1.29,
      color: theme.palette.primary.main,
      backgroundColor: "#e1e1e1",
      position: "sticky",
      top: 0,
      zIndex: 10,
      width: "10%",
      maxWidth: "130px",
      padding: "4px 0 4px 12px",
      "&:last-child": {
        paddingRight: "0!important",
      },
    },
    filtroRow: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      height: 96,
    },
    camposInput: {
      maxWidth: 232,
      width: "100%",
      marginRight: 37,
    },
    camposData: {
      marginRight: 34,
      minWidth: 140,
      maxWidth: 238,
    },
  };
};

export default withStyles(styles)(HistoricoMovimentacaoAppPrintTemplate);
