import React, { Component } from "react";
import { connect } from "react-redux";
import App from "./App";

export class AppContainer extends Component {
  render() {
    return <App id="containerApp" {...this.props.reduxProps} />;
  }
}

const mapStateToProps = (state) => {
  return {
    reduxProps: {
      Toggles: state.fazenda.Toggles,
    },
  };
};

export default connect(mapStateToProps)(AppContainer);
