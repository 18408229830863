import { EXIBIR_CARREGANDO } from "../../../redux/actions/actionsConstants";
export const LISTAR_AJUSTES_ESCORE = "LISTAR_AJUSTES_ESCORE";
export const SALVAR_AJUSTES_ESCORE = "SALVAR_AJUSTES_ESCORE";
export const EXCLUIR_AJUSTES_ESCORE = "EXCLUIR_AJUSTES_ESCORE";

export const listarAjustesEscore = (ajustesEscore) => {
  return {
    type: LISTAR_AJUSTES_ESCORE,
    payload: { ajustesEscoreFazenda: ajustesEscore },
  };
};

export const excluirAjustesEscore = () => {
  return {
    type: EXCLUIR_AJUSTES_ESCORE,
  };
};

export const salvarAjustesEscore = () => {
  return {
    type: SALVAR_AJUSTES_ESCORE,
  };
};

export const exibirCarregando = (exibir) => {
  return {
    type: EXIBIR_CARREGANDO,
    payload: exibir,
  };
};
