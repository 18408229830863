import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import { Button, Divider, withStyles } from "@material-ui/core";
import { formatDate } from "features/goalPlan/goalPlanUtils";
import GoalPlanStatus from "../../shared/goalPlanStatus";

const imgDocument = "../images/ico-documents.svg";
const arrowView = "../images/ico-arrow-right.svg";

const GoalPlanRevisionList = ({
  classes,
  goalPlanRevisionList,
  onShowRevisionPlan,
}) => {
  return (
    <div className={classes.containerList} testid="revision-goals-list">
      {goalPlanRevisionList.map((revision) => (
        <List key={revision.id} className={classes.list}>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <img src={imgDocument} className="list" alt="document-icon" />
            </ListItemAvatar>
            <ListItemText>
              <Typography className={classes.texts}>
                Nome do plano:{" "}
                <b>
                  {revision.name} - V{revision.version}
                  {revision.version === 1 ? (
                    <span className={classes.badge}>Plano original</span>
                  ) : null}
                </b>
              </Typography>
              <Typography className={classes.texts}>
                Criado em: <b>{formatDate(revision.createdAt)}</b> Por: <b>{revision.creationUser}</b>
              </Typography>
              <Typography className={classes.texts}>
                <div className={classes.containerGoalPlanStatus}>
                  Situação:
                  <GoalPlanStatus
                    active={revision.active}
                    initialDate={revision.initialDate}
                    finalDate={revision.finalDate}
                    situation={revision.situation}
                  />
                </div>
              </Typography>
            </ListItemText>
          </ListItem>
          <Button
            data-testid="button-revision-plan"
            className={classes.button}
            onClick={() => onShowRevisionPlan(revision.id)}
          >
            Visualizar{" "}
            <img
              src={arrowView}
              className={classes.arrowView}
              alt="arrow-icon"
            />
          </Button>
          <Divider />
        </List>
      ))}
    </div>
  );
};

const styles = (theme) => ({
  containerList: {
    marginLeft: "8px",
  },
  containerGoalPlanStatus: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  texts: {
    color: "#444",
  },
  list: {
    borderLeft: "1px solid #c4c4c4",
    borderLeftStyle: "dashed",
    position: "relative",
    paddingLeft: "20px",
    "&:lastChild": {
      border: "0px",
      paddingBottom: 0,
    },
    "&::before": {
      content: '""',
      width: "15px",
      height: "15px",
      backgroundColor: "#c4c4c4",
      borderRadius: "50%",
      position: "absolute",
      top: "0px",
      left: "-8px",
    },
  },
  badge: {
    backgroundColor: "#ccddec",
    color: theme.palette.primary.main,
    marginLeft: "10px",
    borderRadius: "100px",
    padding: "3px 15px",
    fontSize: "12px",
  },
  button: {
    color: theme.palette.primary.main,
    marginBottom: "10px",
  },
  activeStatus: {
    flexGrow: "0",
    alignItems: "center",
    margin: "2px 0px 2px 0px",
    padding: "2px 10px 2px 10px",
    borderRadius: "100px",
    backgroundColor: "#dff1df",
    color: "#4caf50",
    fontWeight: "550",
    fontSize: "12px",
  },
  inactiveStatus: {
    flexGrow: "0",
    alignItems: "center",
    margin: "2px 0px 2px 0px",
    padding: "2px 10px 2px 10px",
    borderRadius: "100px",
    backgroundColor: "#f8807833",
    color: "#f44336",
    fontWeight: "550",
    fontSize: "12px",
  },

  arrowView: {
    width: "20px",
    height: "20px",
    color: theme.palette.primary.main,
    marginRight: "10px",
    marginLeft: "10px",
  },
  userText: {
    color: theme.palette.primary.main,
  },
});
export default withStyles(styles)(GoalPlanRevisionList);
