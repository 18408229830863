const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const descendingComparator = (a, b, orderBy) => {
  if (!a.hasOwnProperty(orderBy) || !b.hasOwnProperty(orderBy)) {
    throw new Error(`Property ${orderBy} is not found on one of the objects.`);
  }

  const isOrderByDate = orderBy && orderBy.toLowerCase().includes("data");

  const aValue = isOrderByDate ? new Date(a[orderBy]) : a[orderBy];
  const bValue = isOrderByDate ? new Date(b[orderBy]) : b[orderBy];

  return typeof aValue === 'string' ? bValue.localeCompare(aValue) : bValue - aValue;
}

const getComparator = (order, orderBy) => {
  return order === "desc" ?
    (a, b) => descendingComparator(a, b, orderBy) :
    (a, b) => -descendingComparator(a, b, orderBy);
}

const HistoricoMovimentacaoUtils = {
  getComparator,
  descendingComparator,
  stableSort
};

export default HistoricoMovimentacaoUtils;
