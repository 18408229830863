import React from "react";
import { withStyles } from "@material-ui/core";
import BackIcon from "@material-ui/icons/ArrowBack";

function PageTitle({ containerClass, titleClass, classes, title, onGoBack }) {
  const pageTitleContainer = [classes.pageTitleContainer, containerClass].join(
    " "
  );
  const pageTitle = [classes.pageTitle, titleClass].join(" ");

  return (
    <div className={pageTitleContainer}>
      <BackIcon className={classes.iconBack} onClick={onGoBack} />
      <h1 data-testid="TestPageTitle" className={pageTitle}>
        {title}
      </h1>
    </div>
  );
}

const styles = (theme) => ({
  pageTitle: {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "bold",
    color: "#444",
  },
  pageTitleContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "40px",
    marginTop: "8px",
  },
  iconBack: {
    color: theme.palette.primary.main,
    marginRight: "12px",
    cursor: "pointer",
  },
});

export default withStyles(styles)(PageTitle);
