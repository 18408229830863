import _ from "lodash";

export const roundNumberTwoDecimalPlaces = (value) =>
  Math.round((value + Number.EPSILON) * 100) / 100;

export const formatToInteger = (number) => {
  return number === undefined || number === null
    ? ""
    : new Intl.NumberFormat("pt-BR", {
        currency: "BRL",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(number);
};

export function formatKgOrZero(number) {
  const formattedNumber = formatToInteger(number);

  return formattedNumber === "0" ? "0 " : formattedNumber + " kg";
}

export function formatBRL(number) {
  const BRL = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return BRL.format(number);
}
export const parsePlanning = (result, formulaHasIngredients) => {
  const group = _.groupBy(result.planningItems, "categoryId");
  const plannings = Object.entries(group).map(([, value]) => ({
    planningId: value[0].planningId,
    categoryId: value[0].categoryId,
    items: value
      .map((item) => ({
        id: item.id,
        itemIndex: item.itemIndex,
        formulaId: item.formulaId,
        herdCount: item.herdCount,
        hasIngredients: formulaHasIngredients(item.formulaId),
      }))
      .sort((a, b) => a.itemIndex - b.itemIndex),
  }));

  return plannings;
};

export const parsePlanningItems = (planning) =>
  Object.values(
    planning.planningItems.reduce((acc, item) => {
      const itemsInCategory = acc[item.categoryId] || [];
      acc[item.categoryId] = [...itemsInCategory, item];
      return acc;
    }, {})
  );

export const fillPlanningItems = (planningList) => {
  const planningItems = [];

  planningList.forEach((planningListLine) => {
    planningItems.push(
      ...planningListLine.items.map((planning, index) => ({
        id: planning.id,
        planningId: planningListLine.planningId,
        categoryId: planningListLine.categoryId,
        formulaId: planning.formulaId,
        herdCount: planning.herdCount,
        itemIndex: index + 1,
      }))
    );
  });
  return planningItems;
};

export const fillCreatePlanningItems = (planningList) => {
  const planningItems = [];

  planningList.forEach((planningListLine) => {
    planningItems.push(
      ...planningListLine.items.map((planning, index) => ({
        categoryId: planningListLine.categoryId,
        formulaId: planning.formulaId,
        herdCount: planning.herdCount,
        itemIndex: index + 1,
      }))
    );
  });
  return planningItems;
};
