import React from "react";
import PlanningContent from "../shared/planningContent";
import PlanningListHeader from "./components/planningListHeader";
import PlanningList from "./components/planningList";
import PlanningListEmpty from "./components/planningListEmpty";

const PlanningListPage = ({
  plannings,
  showPlanning = () => {},
  showEditPlanning = () => {},
  showNewPlanning = () => {},
  downloadReport = () => {},
}) => {
  const isExistsPlannings = plannings.length > 0;

  return (
    <PlanningContent>
      <PlanningListHeader showNewPlanning={showNewPlanning} />
      {isExistsPlannings ? (
        <PlanningList
          plannings={plannings}
          showPlanning={showPlanning}
          showEditPlanning={showEditPlanning}
          downloadReport={downloadReport}
        />
      ) : (
        <PlanningListEmpty />
      )}
    </PlanningContent>
  );
};

export default PlanningListPage;
