import React from "react";
import BatchListingTable from "./components/batchListingTable";

const BatchListingTablePage = ({
  objetivos,
  handleAlteraLote,
  atualizaEstadoDataPesagem,
  showLoteModal,
  exibirColunaPesoProjetado,
  exibirColunaSanidade,
  exibirTrocaCategoria,
  obterProjecaoSemestrePorLote,
  lotes,
  mensagemPesoObjetivo,
  calculaDataParaPesoObjetivo,
  alteraPagina,
  paginaAtual,
  linhasPorPagina,
  alteraLinhasPorPagina,
  lotesPaginados,
  obterACurvaGanhoPeso,
  naoTemLotes,
  naoTemLotesComFiltro,
  getWeightGainCurve,
}) => {
  return (
    <BatchListingTable
      objetivos={objetivos}
      handleAlteraLote={handleAlteraLote}
      atualizaEstadoDataPesagem={atualizaEstadoDataPesagem}
      showLoteModal={showLoteModal}
      exibirColunaPesoProjetado={exibirColunaPesoProjetado}
      exibirColunaSanidade={exibirColunaSanidade}
      exibirTrocaCategoria={exibirTrocaCategoria}
      obterProjecaoSemestrePorLote={obterProjecaoSemestrePorLote}
      lotes={lotes}
      mensagemPesoObjetivo={mensagemPesoObjetivo}
      calculaDataParaPesoObjetivo={calculaDataParaPesoObjetivo}
      alteraPagina={alteraPagina}
      paginaAtual={paginaAtual}
      linhasPorPagina={linhasPorPagina}
      alteraLinhasPorPagina={alteraLinhasPorPagina}
      lotesPaginados={lotesPaginados}
      obterACurvaGanhoPeso={obterACurvaGanhoPeso}
      naoTemLotes={naoTemLotes}
      naoTemLotesComFiltro={naoTemLotesComFiltro}
      getWeightGainCurve={getWeightGainCurve}
    />
  );
};

export default BatchListingTablePage;
