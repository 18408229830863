export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  linhaAzul: {
    minHeight: "2px",
    minWidth: "20px",
    display: "inline-block",
    backgroundColor: "#0053a1",
    borderRadius: "2px",
  },
  formControl: {
    minWidth: "100%",
    textAlign: "left",
  },
  container: {
    flexGrow: 1,
    height: "100%",
    maxHeight: "550px",
    letterSpacing: "0",
  },
  icones: {
    textAlign: "left",
    marginLeft: "10%",
    marginTop: "-10px",
  },
  linha: {
    borderBottom: "1px solid rgba(0,0,0,0.12)",
    marginTop: "5%",
    marginRight: "0%",
  },
  mensagem: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.38",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#999999",
  },
  iconeConsumo: {
    margin: "127px 89px 40px 89px",
  },
  botaoFormulacao: {
    width: "100%",
    height: "55px",
    border: "solid 1px #e5e5ea",
    backgroundColor: "#f9f8f9",
    borderRadius: "0px 0px 0px 0px",
    textAlign: "center",
  },
  leftIcon: {
    marginLeft: 0,
    marginRight: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  footer: {
    width: "34%",
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  textField: {
    flex: 1,
    width: "100%",
    marginRight: 20,
  },
  listaText: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.25",
    letterSpacing: "0.3px",
    color: "rgba(0, 0, 0, 0.87)",
  },
  botaoSalvar: {
    minWidth: 100,
    fontSize: "16px",
    borderRadius: 24,
    alignSelf: "center",
  },
  botaoExcluir: {
    marginLeft: "22px",
    backgroundColor: "#eb394b",
    "&:hover": {
      backgroundColor: "#eb3939",
    },
    width: "95%",
  },
  botoes: {
    marginTop: "10px",
    marginLeft: "30px",
  },
  corpoLista: {
    height: "70%",
  },
  divisoria: {
    margin: "8px 0px",
  },
  textoValidacao: {
    color: "#ff0000",
    textAlign: "left",
    marginLeft: "10%",
  },
  corpoFormulario: {
    marginTop: "25px",
    marginBottom: "10px",
  },
  corpoFormularioRetiro: {
    marginTop: "0px",
    marginBottom: "0px",
  },
  BotaoSalvar: {
    width: "100%",
  },
  corpoSelect: {
    marginLeft: "18%",
  },
  botaoDesenhar: {
    marginTop: "10%",
    marginBottom: "10%",
  },
  menuCadastroRetiro: {
    textAlign: "left",
    color: "#444444",
    minHeight: "50px",
    fontSize: "18px",
    letterSpacing: "0px",
    borderBottom: "1px solid rgba(0,0,0,0.12)",
    padding: "14px",
  },
  marginLeft: {
    marginLeft: "5%",
  },
  iconeSucesso: {
    marginTop: "19px",
  },
  listaRetiro: {
    marginTop: "20px",
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    overflow: "visible",
  },
  marginRigth: {
    marginRight: "10px",
  },
  selecaoEstado: {
    marginTop: "17px",
    minWidth: "100%",
    textAlign: "left",
  },
  textLocalizacao: {
    marginLeft: "10%",
    textAlign: "left",
  },
  imgAtalho: {
    marginTop: "5px",
  },
  coordenadas: {
    width: "110px",
    marginLeft: "8px",
    marginRight: "22px",
  },
  corAvatar: {
    color: "#fff",
    backgroundColor: "#4fd26b",
    marginRight: "16px",
    maxHeight: 32,
    maxWidth: 32,
    "& :first-child": {
      padding: 2,
    },
  },
  campoEditar: {
    marginLeft: "8px",
  },
  textoRetiro: {
    marginLeft: "8px",
  },
  iconeCancelarEdicao: {
    color: "#d10000",
  },
  iconeEditar: {
    color: "#4fd26b",
  },
  listItemSecondary: {
    right: "-12px",
  },
});
