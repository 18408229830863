/* global google */
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, DrawingManager } from "@react-google-maps/api";
import { connect } from "react-redux";
import { compose } from "recompose";
import * as actions from "redux/actions";
import guid from "uuid/v1";
import mapaConstants from "constants/mapaFarolAlturaPasto";

let listenerSetAt;
let listenerInsertAt;
let listenerRemoveAt;

export function MapaGoogleCustomizado(props) {
  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      version={process.env.REACT_APP_GOOGLE_MAPS_VERSION_API_KEY}
      loadingElement={<div id="MAP_GOOGLE_DIV" style={{ height: `100%` }} />}
      libraries={mapaConstants.google_maps.libraries}
    >
      <MapaGoogleContent {...props} />
    </LoadScript>
  );
}

export function MapaGoogleContent({
  coordenadasCentro,
  onZoomChanged,
  zoomPadrao,
  onMapMounted,
  zoom,
  mapTypeId,
  ...props
}) {
  const [disableDefaultUI] = useState(true);
  const [drawingMode, setDrawingMode] = useState(null);
  const [isOpen] = useState(false);

  useEffect(() => {
    setDrawingMode(mapaConstants.google_maps.drawing_mode.polygon);

    document.addEventListener("keydown", atalhosDesenho, false);

    return () => {
      document.removeEventListener("keydown", atalhosDesenho, false);
    };
  }, []);

  const atalhosDesenho = (event) => {
    const currentLocation = window.location.pathname;

    if (
      currentLocation === "/cadastro_novo_pasto" ||
      currentLocation === "/nova_fazenda"
    ) {
      if (
        currentLocation === "/cadastro_novo_pasto" &&
        props.ListaCoordenadasPastos.length > 0
      ) {
        props.desabilitarDesenhar();
      } else if (event.keyCode === 27) {
        props.desabilitarDesenhar();
      } else if (event.keyCode === 80) {
        props.habilitarDesenhar();
      }
    }
  };

  const onPolygonComplete = (poly) => {
    google.maps.event.removeListener(listenerSetAt);
    google.maps.event.removeListener(listenerInsertAt);
    google.maps.event.removeListener(listenerRemoveAt);

    const polyArray = poly.getPath().getArray();
    var area = poly.getPath()
      ? (
          google.maps.geometry.spherical.computeArea(poly.getPath()) / 10000
        ).toFixed(1)
      : 0;
    let paths = [];
    polyArray.forEach(function (path) {
      paths.push({ lat: path.lat(), lng: path.lng() });
    });

    if (!poly.Id) {
      poly.Id = guid();
      props.coordenadasNovoPasto(paths, poly, area);
    } else {
      props.atualizaCoordenadasPasto(paths, poly.Id, area);
    }

    listenerSetAt = google.maps.event.addListener(
      poly.getPath(),
      "set_at",
      () => onPolygonComplete(poly)
    );
    listenerInsertAt = google.maps.event.addListener(
      poly.getPath(),
      "insert_at",
      () => onPolygonComplete(poly)
    );
    listenerRemoveAt = google.maps.event.addListener(
      poly.getPath(),
      "remove_at",
      () => onPolygonComplete(poly)
    );
  };

  const renderDesenhar = (google) => {
    const { Desenhando } = props;

    return Desenhando ? (
      <DrawingManager
        options={{
          drawingControl: false,
          drawingMode: drawingMode,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [],
          },
          polygonOptions: {
            strokeWeight: 3,
            strokeColor: "#00FF00",
            clickable: false,
            editable: true,
            zIndex: 1,
          },
        }}
        onPolygonComplete={onPolygonComplete}
      />
    ) : null;
  };

  const propsOpcionais = onZoomChanged ? { onZoomChanged } : {};
  const opcoesMapa = {
    mapId: process.env.REACT_APP_GOOGLE_MAPS_MAP_ID,
    mapTypeId,
    disableDefaultUI,
    isOpen,
    scrollwheel: props.scrollwheel,
    zoomControl: props.zoomControl,
    panControl: props.panControl,
    draggable: props.draggable,
    scaleControl: props.scaleControl,
    mapTypeControl: props.mapTypeControl,
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: google ? google.maps.ControlPosition.BOTTOM_LEFT : 1,
    },
  };

  return (
    <GoogleMap
      mapContainerClassName={props.classes.content}
      zoom={zoomPadrao}
      center={coordenadasCentro}
      options={opcoesMapa}
      {...propsOpcionais}
    >
      {props.children}
      {google ? renderDesenhar(google) : ""}
    </GoogleMap>
  );
}

MapaGoogleCustomizado.propTypes = {
  coordenadasCentro: PropTypes.object.isRequired,
  onZoomChanged: PropTypes.func,
  zoomControl: PropTypes.bool,
  scrollwheel: PropTypes.bool,
  panControl: PropTypes.bool,
  draggable: PropTypes.bool,
  scaleControl: PropTypes.bool,
  mapTypeControl: PropTypes.bool,
  visualizarPontosColetas: PropTypes.string,
  mapTypeId: PropTypes.bool,
};

const mapDispatchToProps = {
  coordenadasNovoPasto: actions.salvarCoordNovoPasto,
  desabilitarDesenhar: actions.desabilitarDesenhar,
  habilitarDesenhar: actions.habilitarDesenhar,
  atualizaCoordenadasPasto: actions.atualizaCoordenadasPasto,
  atualizaZoom: actions.setZoomMapa,
};

function mapStateToProps({ pasto, farol, mapa }) {
  return {
    ListaCoordenadasPastos: pasto.CoordenadasNovoPasto,
    farolSelecionado: farol.FarolSelecionado,
    zoom: mapa.zoom,
  };
}

const styles = (theme) => ({
  content: {
    width: "100%",
    height: `calc(100vh - ${theme.props.tamanhoAppBarSm}px)`,
    "@media (min-width: 769px)": {
      height: `calc(100vh - ${theme.props.tamanhoAppBarMd}px)`,
    },
    "@media (min-width: 1128px)": {
      height: `calc(100vh - ${theme.props.tamanhoAppBarXs}px)`,
    },
  },
});

export default compose(
  withStyles(styles),
  // withProps((ownerProps) => ({
  //   googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&${process.env.REACT_APP_GOOGLE_MAPS_VERSION_API_KEY}&libraries=geometry,drawing,places`,
  //   loadingElement: <div style={{ height: `100%` }} />,
  //   containerElement: <div className={ownerProps.classes.content} />,
  //   mapElement: <div style={{ height: `100%`, zIndex: `50` }} />,
  // })),
  connect(mapStateToProps, mapDispatchToProps)
  // withHandlers(() => {
  //   const refs = {
  //     map: undefined,
  //   };

  //   return {
  //     onMapMounted: () => (ref) => {
  //       refs.map = ref;
  //     },
  //     onZoomChanged:
  //       ({ atualizaZoom }) =>
  //         () => {
  //           atualizaZoom(refs.map.getZoom());
  //         },
  //   };
  // })
)(MapaGoogleCustomizado);
