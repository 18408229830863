import FormCadastroIngrediente from "./components/formCadastroIngrediente";
import React from "react";

const IngredientRegisterPage = ({ listaIngredientes, ingredienteSelecionado, setIngredienteSelecionado, quantidade, botaoCadastroDesativado, adicionarIngrediente, setQuantidade }) => {
  return (
    <FormCadastroIngrediente
      listaIngredientes={listaIngredientes}
      ingredienteSelecionado={ingredienteSelecionado}
      setIngredienteSelecionado={setIngredienteSelecionado}
      quantidade={quantidade}
      botaoCadastroDesativado={botaoCadastroDesativado}
      adicionarIngrediente={adicionarIngrediente}
      setQuantidade={setQuantidade}
    />
  )
}

export default IngredientRegisterPage;
