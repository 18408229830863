import React from "react";
import { withStyles } from "@material-ui/core";
import styles from "../NewLoginPage/styles";
import SkeletonLoading from "features/shared/components/SkeletonLoading";

export function ChangePasswordSkeletonLoading({ classes }) {
  return (
    <div className={classes.content}>
      <SkeletonLoading width="48%" height={33} />
      <SkeletonLoading width="75%" height={21} marginTop={16} />

      <SkeletonLoading width="100%" height={40} marginTop={64} />
      <SkeletonLoading width="100%" height={40} marginTop={24} />

      <SkeletonLoading width="100%" height={140} marginTop={16} />

      <SkeletonLoading width="100%" height={42} marginTop={80} />
      <SkeletonLoading width="100%" height={28} marginTop={14} />
    </div>
  );
}

export default withStyles(styles)(ChangePasswordSkeletonLoading);
