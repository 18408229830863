import React from "react";
import { useState } from "react";
import MovementHistoryListPage from "./movementHistoryListPage";

const MovementHistoryListContainer = ({
  historicoMovimentacao,
  carregamentoInicial,
  handlerTotalPagina,
}) => {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("Data");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = historicoMovimentacao.items.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    carregamentoInicial(newPage, rowsPerPage);
    setPage(newPage);
    handlerTotalPagina(rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(page);
    carregamentoInicial(page, event.target.value);
    handlerTotalPagina(event.target.value);
  };
  return (
    <MovementHistoryListPage
      handleRequestSort={handleRequestSort}
      handleSelectAllClick={handleSelectAllClick}
      handleClick={handleClick}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      selected={selected}
      order={order}
      orderBy={orderBy}
      historicoMovimentacao={historicoMovimentacao}
      rowsPerPage={rowsPerPage}
      page={page}
    />
  );
};

export default MovementHistoryListContainer;
