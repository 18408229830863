import CategoriaService from './categoriaService';
import FaixaIdadeManager from './faixaIdadeManager';
import GanhoPesoManager from '../projecaoGanhoPeso/ganhoPesoManager';
export class CategoriaManager {
  async obterCategoriasFazenda(fazendaSelecionada, categoriaExcluida) {
    const promiseCategorias = CategoriaService.listaCategoriasPorFazenda(fazendaSelecionada, categoriaExcluida);
    const promiseFaixas = FaixaIdadeManager.obterFaixaIdade();
    const promiseCurvas = this.obterCurvasGanhoPeso(fazendaSelecionada);
    const [categoriasFazenda, faixas, curvasGanhoPeso] = await Promise.all([
      promiseCategorias,
      promiseFaixas,
      promiseCurvas
    ]);
    const categorias = categoriasFazenda.data;
    return this.enriqueceCategorias(categorias, faixas, curvasGanhoPeso);
  }
  enriqueceCategorias(categorias, faixas, curvasGanhoPeso) {
    return categorias.map(categoria => {
      const faixaDaCategoria = faixas.find(faixa => faixa.IdFaixaIdade === categoria.IdFaixaIdade);
      const curvaDaCategoria = curvasGanhoPeso.find(
        curva => curva.IdCurvaGanhoPeso === categoria.IdCurvaGanhoPeso
      );
      categoria.FaixaIdade = faixaDaCategoria ? faixaDaCategoria.FaixaIdade : '';
      categoria.CurvaGanhoPeso = curvaDaCategoria ? curvaDaCategoria : null;
      return categoria;
    });
  }
  async excluirCategoria(idCategoria) {
    await CategoriaService.deletarCategoria(idCategoria);
  }

  async desexcluirCategoria(idCategoria) {
    await CategoriaService.reativarCategoria(idCategoria);
  }

  async salvarCategoria(categoria) {
    const respostaService = await CategoriaService.adicionarCategoria(categoria);
    return respostaService.data;
  }

  async editarCategoria(categoria, idCategoria) {
    await CategoriaService.editarCategoria(categoria, idCategoria);
  }
  async obterCurvasGanhoPeso(idFazenda) {
    return await GanhoPesoManager.obterCurvasGanhoPeso(idFazenda);
  }
}

export default new CategoriaManager();
