import BaseService from "../../services/BaseService";

const URL_BASE = `${process.env.REACT_APP_ENDPOINT}herds/ageRange`;
class FaixaIdadeService extends BaseService {
  translateAgeRangeToPortuguese(ageRange) {
    return {
      IdFaixaIdade: ageRange.id,
      FaixaIdade: ageRange.ageRange,
      Sexo: ageRange.gender,
      UsuarioCriacao: ageRange.creationUser,
      UsuarioAlteracao: ageRange.updateUser,
      UsuarioExclusao: ageRange.deletionUser,
      DataCriacao: ageRange.createdAt,
      DataAlteracao: ageRange.updatedAt,
      DataExclusao: ageRange.deletedAt,
      TamanhoLote: ageRange.lotSize,
      Creep: ageRange.creep.data,
    };
  }

  async listarFaixaIdade() {
    const response = await this.get(URL_BASE);
    if (response.data && response.data.data && response.data.total) {
      const dataResponse = response.data.data;
      const totalResponse = response.data.total;

      const covertedResponseAgeRange = dataResponse.map(
        this.translateAgeRangeToPortuguese
      );
      return {
        ...response,
        data: covertedResponseAgeRange,
        total: totalResponse,
      };
    } else {
      return { data: [], total: 0 };
    }
  }
}

export default new FaixaIdadeService();
