import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import { Paper, Button, Typography, withStyles } from "@material-ui/core";
import Accordion from "./accordion";
import PesagemCategoriaHeader from "./pesagemCategoriaHeader";
import { mapeiaEdicoes } from "../pesagemCategoriaUtils";
import ModalExclusao from "./modalExclusao";
import ModalPesagemCategoria from "./pesagemCategoriaModal";

export const PesagemCategoriaPage = ({
  classes,
  obtemEventosPesagens,
  PesagensFazenda,
  categorias,
  fazendaSelecionada,
  criarEventoPesagem,
  editarPesagensFazenda,
  excluirPesagemFazenda,
  obterQuantidadesPorCategoria,
  desativaModal,
  exibirModalConciliacao,
}) => {
  const [pesagens, setPesagens] = useState(PesagensFazenda);
  const [painel, setPainel] = useState({});
  const [criarNovo, setCriarNovo] = useState(false);
  const [editar, setEditar] = useState(false);
  const [exibirModal, setExibirModal] = useState(false);
  const [pesagemAExcluir, setPesagemAExcluir] = useState();

  useEffect(() => {
    obtemEventosPesagens();
  }, []);

  useEffect(() => {
    setPesagens(PesagensFazenda);
  }, [PesagensFazenda]);

  /*
  Desabilita o botão de salvar caso a categoria não tenha peso,
  ou tenha o peso igual a 0
  */
  const desabilitaSalvamento = () =>
    !pesagens.every((pesagem) =>
      pesagem.CategoriaEventoPesagems.some((categoria) => categoria.PesoPesado)
    );

  const criarNovoEventoPesagem = async (nome, data) => {
    const obterQtdePorCat = await obterQuantidadesPorCategoria(data);
    const response = obterQtdePorCat.data;
    const obterQtdePorCatMap = response.map((item) => ({
      ...item,
      PesoPesado: 0,
    }));

    const novoRegistro = {
      Nome: nome,
      DataPesagem: moment(data).format("YYYY-MM-DD"),
      IdFazenda: fazendaSelecionada,
      CategoriaEventoPesagems: [...obterQtdePorCatMap],
    };
    setPesagens([novoRegistro, ...pesagens]);
    setPainel({ 0: true });
    setCriarNovo(true);
  };

  const cancelarEdicaoOuCricao = () => {
    setPesagens(PesagensFazenda);
    setPainel({});
    setCriarNovo(false);
    setEditar(false);
  };

  const salvarEdicoesOuNovoRegistro = async () => {
    if (criarNovo) {
      criarEventoPesagem(pesagens[0]);
      setCriarNovo(false);
      setPesagens(PesagensFazenda);
    }
    if (editar) {
      const edicoesMapeadas = mapeiaEdicoes(pesagens, PesagensFazenda);
      await editarPesagensFazenda(edicoesMapeadas);
      setEditar(false);
    }
  };

  const deletarEventoPesagemOuAbrirModal = (IdEventoPesagem) => {
    if (!IdEventoPesagem) {
      setPainel({});
      setPesagens(PesagensFazenda);
      setCriarNovo(false);
    } else {
      setExibirModal(true);
      setPesagemAExcluir(IdEventoPesagem);
    }
  };

  const excluirOuCancelar = (resposta) => {
    if (resposta) {
      excluirPesagemFazenda(pesagemAExcluir);
    }
    setExibirModal(false);
  };

  return (
    <Paper square className={classes.root}>
      {exibirModal && (
        <ModalExclusao
          pesagemSelecionada={pesagens.find(
            (pesagem) => pesagem.IdEventoPesagem === pesagemAExcluir
          )}
          excluirOuCancelar={excluirOuCancelar}
        />
      )}
      <PesagemCategoriaHeader
        pesagens={pesagens}
        criarNovoEventoPesagem={criarNovoEventoPesagem}
        editar={editar}
        criarNovo={criarNovo}
      />
      <Grid style={{ marginTop: "30px" }} container>
        {(editar || criarNovo) && (
          <Grid xs={12} direction="column">
            {pesagens[0].CategoriaEventoPesagems.some(
              (cat) => Number(cat.QuantidadeAnimais) <= 0
            ) && (
              <Grid container direction="row">
                <Grid item>
                  <img
                    style={{
                      marginRight: "10px",
                    }}
                    src="../images/ic_warning.svg"
                    alt="icone de alerta"
                  />
                </Grid>
                <Grid item>
                  <Typography style={{ fontSize: "12px", color: "#ff0102" }}>
                    Você não pode ter uma quantidade de animais menor ou igual a
                    zero em uma categoria, entre em{" "}
                    <span style={{ fontWeight: "bold" }}>
                      Controle de Rebanho, na aba de Entradas e
                    </span>
                  </Typography>
                  <Typography style={{ fontSize: "12px", color: "#ff0102" }}>
                    <span style={{ fontWeight: "bold" }}>Saídas</span> e faça o
                    lançamento correto da quantidade de animais,{" "}
                    <span style={{ fontWeight: "bold" }}>
                      para que essa categoria seja contabilizada no Balanço de
                      Arroba.
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid
              style={{ marginBottom: "25px" }}
              xs={12}
              md={12}
              container
              justify="flex-end"
            >
              <Grid item>
                <Button
                  id="botaoCancelar"
                  onClick={cancelarEdicaoOuCricao}
                  color="primary"
                  /* Atributo para utilizar nos testes do React Testing Library */
                  data-testid="botaoCancelar"
                  style={{
                    height: "41px",
                    width: "130px",
                    marginRight: "15px",
                  }}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={desabilitaSalvamento()}
                  id="botaoSalvar"
                  data-testid="botaoSalvar"
                  onClick={salvarEdicoesOuNovoRegistro}
                  variant="contained"
                  color="primary"
                  style={{ height: "41px", width: "130px" }}
                >
                  SALVAR
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}

        {exibirModalConciliacao ? (
          <ModalPesagemCategoria desativaModal={desativaModal} />
        ) : (
          <Accordion
            categorias={categorias}
            criarNovo={criarNovo}
            editar={editar}
            painel={painel}
            pesagens={pesagens}
            setEditar={setEditar}
            setPainel={setPainel}
            setPesagens={setPesagens}
            deletarEventoPesagemOuAbrirModal={deletarEventoPesagemOuAbrirModal}
          />
        )}
      </Grid>
    </Paper>
  );
};

const styles = () => {
  return {
    root: {
      width: "100%",
      height: "100%",
      overflowX: "auto",
      padding: "46px",
      zIndex: -1,
      bottom: 0,
      top: "17%",
      minHeight: "calc(100vh - 90px)",
    },
  };
};

export default withStyles(styles)(PesagemCategoriaPage);
