import moment from "moment";
import BaseService from "../../services/BaseService";

export const URL_BASE = `${process.env.REACT_APP_ENDPOINT}herds/movements/`;

class HistoricoMovimentacaoService extends BaseService {
  aggregatedMovementsToHistoricoMovimentacao(aggregatedMovements) {
    return {
      Data: moment(aggregatedMovements.operationDate).format(
        "DD/MM/YYYY HH:mm"
      ),
      DataISO: aggregatedMovements.operationDate,
      UsuarioCriacao: aggregatedMovements.createdBy,
      IdOperacaoLote: aggregatedMovements.movementId,
      TipoOperacao: aggregatedMovements.movementType,
      OrigemNomeCategoriaAnimal: aggregatedMovements.animalCategoryOrigin,
      DestinoNomeCategoriaAnimal: aggregatedMovements.animalCategoryDestiny,
      OrigemNomePasto: aggregatedMovements.pastureOrigin,
      DestinoNomePasto: aggregatedMovements.pastureDestiny,
      Quantidade: aggregatedMovements.quantity,
      DestinoQuantidade: aggregatedMovements.destinyNumber,
      OrigemIdRetiro: aggregatedMovements.retreatAreas,
      DestinoIdRetiro: aggregatedMovements.retreatAreas,
      DataCriacao: aggregatedMovements.createdAt,
    };
  }

  filtersToFiltros(filters) {
    const formattedResponse = {
      Usuarios: [],
      TiposOperacoes: [],
      CategoriasAnimal: [],
      Pastos: [],
      Retiros: [],
    };

    if (filters.data && filters.data.users) {
      const formattedUsers = filters.data.users;
      formattedResponse.Usuarios = formattedUsers.map((user) => user.value);
    }

    if (filters.data && filters.data.movementTypes) {
      const formattedMovementTypes = filters.data.movementTypes;
      formattedResponse.TiposOperacoes = formattedMovementTypes.map((type) => {
        return {
          IdTipoOperacao: type.value,
          Nome: type.label,
        };
      });
    }

    if (filters.data && filters.data.categories) {
      const formattedAnimalCategories = filters.data.categories;
      formattedResponse.CategoriasAnimal = formattedAnimalCategories.map(
        (category) => {
          return {
            IdCategoriaAnimal: category.value,
            Nome: category.label,
          };
        }
      );
    }

    if (filters.data && filters.data.pastures) {
      const formattedPastures = filters.data.pastures;
      formattedResponse.Pastos = formattedPastures.map((pasture) => {
        return {
          IdPasto: pasture.value,
          Nome: pasture.label,
          NomeReduzido: pasture.label,
        };
      });
    }

    if (filters.data && filters.data.retreatAreas) {
      const formattedRetreatAreas = filters.data.retreatAreas;
      formattedResponse.Retiros = formattedRetreatAreas.map((area) => {
        return {
          IdRetiro: area.value,
          Nome: area.label,
          NomeReduzido: area.label,
        };
      });
    }

    return formattedResponse;
  }

  entriesFromQuery(query) {
    return Object.fromEntries(
      Object.entries(query).filter(([_, value]) => value !== 0)
    );
  }
  async listaHistoricoMovimentacaoFazenda(
    idFazenda,
    offset,
    limit,
    filtro,
    exactStartDate = false
  ) {
    const startDate = exactStartDate
      ? moment(filtro.DataInicio).toISOString()
      : moment(filtro.DataInicio).startOf("day").toISOString();
    let query = {
      farmId: idFazenda,
      startDate,
      endDate: moment(filtro.DataFinal).endOf("day").toISOString(),
      users: filtro.Usuario,
      movementTypes: filtro.TipoOperacao,
      originPastures: filtro.IdPastoOrigem,
      originCategories: filtro.IdCategoriaOrigem,
      destinyPastures: filtro.IdPastoDestino,
      destinyCategories: filtro.IdCategoriaDestino,
      page: offset,
      size: limit,
    };

    if (filtro.IdRetiro) {
      query = {
        ...query,
        retreatAreas: filtro.IdRetiro,
      };
    }

    this.entriesFromQuery(query);

    const parametrosURL = this.montaURLParametros(query);

    const urlHistoricoMovimentacao = URL_BASE + "aggregated";

    const urlServico = this.montaURL(urlHistoricoMovimentacao, parametrosURL);
    const resposta = await this.get(urlServico);

    if (
      resposta.data &&
      resposta.data.data &&
      resposta.data.data.aggregateMovements &&
      resposta.data.data.total
    ) {
      const dataResposta = resposta.data.data.aggregateMovements;
      const totalRespostaData = resposta.data.data.total;

      const convertedRespostaAggregatedMovements = dataResposta.map(
        this.aggregatedMovementsToHistoricoMovimentacao
      );

      return {
        items: convertedRespostaAggregatedMovements,
        total: totalRespostaData,
      };
    } else {
      return {
        items: [],
        total: 0,
      };
    }
  }

  async listarfiltrosHistoricoMovimentacao(idFazenda) {
    let query = {
      farmId: idFazenda,
    };

    this.entriesFromQuery(query);

    const parametrosURL = this.montaURLParametros(query);
    const urlFilters = URL_BASE + "filters";
    const urlServico = this.montaURL(urlFilters, parametrosURL);
    const resposta = await this.get(urlServico);

    const dataResposta = resposta.data;

    const convertedRespostaFilters = this.filtersToFiltros(dataResposta);

    return convertedRespostaFilters;
  }
}

export default new HistoricoMovimentacaoService();
