import { Typography, withStyles } from "@material-ui/core";
import React from "react";
import GoalPlanFormContainer from "../shared/goalPlanForm/goalPlanFormContainer";

const TEXT_BUTTON_SUBMIT = "SALVAR REVISÃO";

const GoalPlanReviewPage = ({
  goalPlanId,
  onBack,
  onSubmit,
  enableLoading,
  disableLoading,
  notifyError,
  classes,
}) => {
  const subtitle = () => (
    <Typography className={classes.subtitle}>
      A criação da revisão de um plano, permite{" "}
      <b>editar, adicionar ou excluir</b> qualquer dado informado no plano
      escolhido. Caso queira alterar somente o nome ou a data de um plano, basta
      acessar a tela inicial, na coluna de <b>“Ações”</b>, e escolher a opção{" "}
      <b>“Editar”</b>.
    </Typography>
  );

  return (
    <GoalPlanFormContainer
      titlePage="Criar revisão do plano"
      titleContent=""
      subtitleContent={subtitle}
      onBack={onBack}
      onSubmit={onSubmit}
      enableLoading={enableLoading}
      disableLoading={disableLoading}
      notifyError={notifyError}
      initialDataPlanId={goalPlanId}
      disableEditPlanInfo
      textButtonSubmit={TEXT_BUTTON_SUBMIT}
    />
  );
};

const styles = () => {
  return {
    subtitle: {
      fontSize: "16px",
      color: "#444444",
      paddingTop: "20px",
    },
  };
};

export default withStyles(styles)(GoalPlanReviewPage);
