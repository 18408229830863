import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  withStyles
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Create";

export class CurralTabela extends React.Component {
  obterLotesDoCurral = (idCurral, lotes) => {
    let lotesDoCurral = [];
    lotesDoCurral = lotes.filter(function (lote) {
      return lote.IdPasto === idCurral && lote.DataValidade === null;
    });
    return lotesDoCurral;
  };

  calculaQtdAnimaisNoCurral = (lotes) => {
    let quantidadeDeCabecas = 0;
    _.forEach(lotes, (lote) => {
      quantidadeDeCabecas += lote.Quantidade;
    });
    return quantidadeDeCabecas;
  };

  obterSomaDeAnimaisNoCurral = (idCurral) => {
    const { lotes } = this.props;
    const lotesNoCurral = this.obterLotesDoCurral(idCurral, lotes);
    const quantidadeDeCabecas = this.calculaQtdAnimaisNoCurral(lotesNoCurral);
    return quantidadeDeCabecas;
  };

  obterRetiroDoCurral = (idRetiroCurral) => {
    const { retiros } = this.props;
    const retiroCurral = _.find(retiros, (retiro) => {
      return idRetiroCurral === retiro.IdRetiro;
    });
    if (retiroCurral) {
      return retiroCurral.Nome;
    } else {
      return "";
    }
  };

  render() {
    const { classes, currais } = this.props;

    return (
      <Paper className={classes.containnerTable}>
        <div className={classes.divTable}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.headerTabelaCurral}>
                <TableCell className={classes.labelTabelaCurralFirst}>
                  Retiro
                </TableCell>
                <TableCell className={classes.labelTabelaCurral} align="left">
                  Nome Curral
                </TableCell>
                <TableCell className={classes.labelTabelaCurral} align="left">
                  Nome Reduzido Curral
                </TableCell>
                <TableCell className={classes.labelTabelaCurral} align="left">
                  Quantidade de animais
                </TableCell>
                <TableCell className={classes.labelTabelaCurral} align="left">
                  Editar
                </TableCell>
                <TableCell
                  className={classes.labelTabelaCurralLast}
                  align="right"
                >
                  Excluir
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody id="dadosTabela">
              {_.map(currais, (curral) => (
                <TableRow
                  key={curral.IdPasto}
                  id="linhasTabela"
                  className={classes.tableLinhas}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.tabelaCurralConteudoInicial}
                  >
                    {this.obterRetiroDoCurral(curral.IdRetiro)}
                  </TableCell>
                  <TableCell className={classes.tabelaCurralConteudo}>
                    {curral.Nome}{" "}
                  </TableCell>
                  <TableCell className={classes.tabelaCurralConteudo}>
                    {curral.NomeReduzido}
                  </TableCell>
                  <TableCell
                    className={classes.quantidadeConteudo}
                  >{`${this.obterSomaDeAnimaisNoCurral(
                    curral.IdPasto
                  )} animais`}</TableCell>
                  <TableCell>
                    <IconButton
                      id="botaoHabilitar"
                      onClick={() => this.props.habilitarEdicao(curral)}
                      aria-label="Edit"
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      id="botaoDeletar"
                      onClick={() => this.props.abreDialogExclusao(curral)}
                      aria-label="Delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }
}

CurralTabela.propTypes = {
  currais: PropTypes.array.isRequired,
  retiros: PropTypes.array,
};

const styles = (theme) => {
  return {
    table: {
      width: "100%",
    },
    tableLinhas: {
      height: 56,
    },
    headerTabelaCurral: {
      height: 56,
    },
    labelTabelaCurral: {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 500,
      textTransform: "uppercase",
      top: 0,
      position: "sticky",
      zIndex: 10,
      color: theme.palette.primary.main,
      backgroundColor: "#e1e1e1",
    },
    labelTabelaCurralFirst: {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 500,
      textTransform: "uppercase",
      paddingLeft: "40px!important",
      top: 0,
      position: "sticky",
      zIndex: 10,
      color: theme.palette.primary.main,
      backgroundColor: "#e1e1e1",
    },
    labelTabelaCurralLast: {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 500,
      textTransform: "uppercase",
      paddingRight: "29px!important",
      top: 0,
      position: "sticky",
      zIndex: 10,
      color: theme.palette.primary.main,
      backgroundColor: "#e1e1e1",
    },
    tabelaCurralConteudo: {
      fontFamily: "Roboto",
      fontSize: 14,
      color: "#757575",
      textTransform: "uppercase",
    },
    tabelaCurralConteudoInicial: {
      fontFamily: "Roboto",
      fontSize: 14,
      color: "#757575",
      textTransform: "uppercase",
      paddingLeft: "40px!important",
    },
    quantidadeConteudo: {
      fontSize: 14,
      color: "#757575",
    },
    containnerTable: {
      overflow: "hidden",
      borderRadius: "16px",
    },
    divTable: {
      overflow: "auto",
      maxHeight: 600,
    },
  };
};

export default withStyles(styles)(CurralTabela);
