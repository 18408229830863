import { EXIBIR_CARREGANDO } from "../../../redux/actions/actionsConstants";
export const LISTAR_BALANCOS_FAZENDA = "LISTAR_BALANCOS_FAZENDA";
export const LISTAR_DATAS_PESAGEM = "LISTAR_DATAS_PESAGEM";
export const ABRIR_MODAL_FALTA_INFORMACAO = "ABRIR_MODAL_FALTA_INFORMACAO";
export const ABRIR_MODAL_FALTA_DATA_CONCILIACAO =
  "ABRIR_MODAL_FALTA_DATA_CONCILIACAO";

export const obterDadosPeriodoBalanco = (balancos) => {
  return {
    type: LISTAR_BALANCOS_FAZENDA,
    payload: {
      BalancosFazenda: balancos,
    },
  };
};

export const listarDatasPesagem = (datasPesagem) => {
  return {
    type: LISTAR_DATAS_PESAGEM,
    payload: datasPesagem,
  };
};

export const exibirCarregando = (exibir) => {
  return {
    type: EXIBIR_CARREGANDO,
    payload: exibir,
  };
};

export const exibirModalFaltaInformacao = (exibir) => {
  return {
    type: ABRIR_MODAL_FALTA_INFORMACAO,
    payload: exibir,
  };
};

export const exibirModalFaltaDataInicial = (exibir) => {
  return {
    type: ABRIR_MODAL_FALTA_DATA_CONCILIACAO,
    payload: exibir,
  };
};
