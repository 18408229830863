import React from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Tooltip, Grid, withStyles } from "@material-ui/core";
import { TextLabel } from "../../../../shared/components";
import { condicaoToolTip } from "../../../loteUtils";

const TITLE_TOOLTIP = "Ocorreu uma evolução de categoria recentemente.";
export const campoNomeCategoriaPasto = ({
  classes,
  lote,
  exibirTrocaCategoria,
}) => {
  const exibirTooltip = condicaoToolTip(
    exibirTrocaCategoria,
    lote.TipoOperacaoLote
  );

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <Grid item>
        <Grid container direction="column">
          <TextLabel
            bold
            label={lote.categoria.Nome}
            className={classes.text}
          />
          <TextLabel
            label={
              lote.pasto.NomeReduzido
                ? lote.pasto.NomeReduzido.toLowerCase()
                : ""
            }
            className={classes.text}
          />
        </Grid>
      </Grid>
      {exibirTooltip ? (
        <Grid item>
          <Tooltip
            title={TITLE_TOOLTIP}
            classes={{ tooltip: classes.tooltip }}
            arrow
          >
            <InfoOutlinedIcon color="primary" />
          </Tooltip>
        </Grid>
      ) : null}
    </Grid>
  );
};

const styles = {
  text: {
    color: "#757575!important",
    textTransform: "uppercase",
    fontSize: "14px!important",
  },
  tableCell: {
    paddingLeft: "2.35rem",
    paddingRight: "0.5rem",
  },
  containerTooltip: {
    paddingLeft: "1rem",
    paddingTop: "0.3rem",
  },
  tooltip: {
    backgroundColor: "#333333",
    maxWidth: 136,
    fontSize: 10,
    margin: "0.5em auto",
  },
};

const campoNomeCategoriaPastoIgual = (prevProps, nextProps) => {
  const loteIgual = prevProps.lote.IdLote === nextProps.lote.IdLote;
  const exibirTrocaCategoriaIgual =
    prevProps.exibirTrocaCategoria === nextProps.exibirTrocaCategoria;
  return loteIgual && exibirTrocaCategoriaIgual;
};

const campoNomeCategoriaPastoMemo = React.memo(
  campoNomeCategoriaPasto,
  campoNomeCategoriaPastoIgual
);
export default withStyles(styles)(campoNomeCategoriaPastoMemo);
