export const colunasListagemAjustesEscores = [
  {
    id: 'diaAtual',
    className: 'celulaDia',
    titulo: 'Dia atual',
  },
  {
    id: 'diaAnterior',
    className: 'celulaDia',
    titulo: 'Dia anterior',
  },
  {
    id: 'ajuste',
    className: 'celulaAjuste',
    titulo: 'Ajuste sugerido em kg de matéria natural',
  }
];

export const resetarAjustesEscore = ajustesEscoreFazenda => {
  const ajustesEscoreArray = ajustesEscoreFazenda.AjustesEscore;
  const ajustesEscoresAtualizados = ajustesEscoreArray.map(ajuste => (
    {
      ...ajuste,
      Modificado: false,
      Erro: false
    }
  ));

  return ajustesEscoresAtualizados;
}

export const verificaErroAjuste = (ajusteValor) => {
  const regexInteiroDecimal = /^[+-]?[0-9]+([,.][0-9]+)?$/g;
  const erroAjuste = !regexInteiroDecimal.test(ajusteValor);

  return erroAjuste;
}

export const trocaVirgulaPorPonto = (erroAjuste, valorAjuste) => {
  if (erroAjuste) {
    return valorAjuste;
  } else {
    return parseFloat(valorAjuste.replace(/,/, '.'))
  }
}
