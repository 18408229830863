import {
  notifyError,
  notifySuccess,
} from "../../../redux/actions/notificacaoActions";
import CategoriaManager from "../categoriaManager";
import {
  listarCategoriasFazenda,
  listarCurvasGanhoPeso,
  excluirCategoria,
  salvarCategoria,
  editarCategoria,
  exibirCarregando,
} from "./categoriaActions";
import _ from "lodash";

export const obterCategoriasFazenda =
  (categoriaExcluida) => async (dispatch, getState) => {
    dispatch(exibirCarregando(true));
    try {
      const { fazenda } = getState();
      const categoriasFazenda = await CategoriaManager.obterCategoriasFazenda(
        fazenda.FazendaSelecionada,
        categoriaExcluida
      );
      dispatch(listarCategoriasFazenda(categoriasFazenda));
    } catch (error) {
      dispatch(listarCategoriasFazenda([]));
      dispatch(
        notifyError({
          mensagem: "Ocorreu um erro ao obter a lista de categorias.",
        })
      );
    } finally {
      dispatch(exibirCarregando(false));
    }
  };
export const desexcluirCategoria = (idCategoria) => async (dispatch) => {
  try {
    await CategoriaManager.desexcluirCategoria(idCategoria);
    dispatch(
      notifySuccess({
        mensagem: "Categoria ativada com sucesso.",
      })
    );
  } catch (error) {
    if (
      !_.isNil(error.response) &&
      !_.isNil(error.response.data) &&
      !_.isNil(error.response.data.codigo) &&
      Number(error.response.data.codigo) === 406
    ) {
      dispatch(
        notifyError({
          mensagem: error.response.data.mensagem,
        })
      );
    } else {
      dispatch(
        notifyError({
          mensagem: "Ocorreu um erro ao alterar a categoria.",
        })
      );
    }
  }
};

export const deletarCategoria = (idCategoria) => async (dispatch) => {
  try {
    await CategoriaManager.excluirCategoria(idCategoria);
    dispatch(excluirCategoria());
    dispatch(
      notifySuccess({
        mensagem: "Categoria desativada com sucesso.",
      })
    );
  } catch (error) {
    if (
      !_.isNil(error.response) &&
      !_.isNil(error.response.data) &&
      !_.isNil(error.response.data.message) &&
      Number(error.response.status) === 400
    ) {
      dispatch(
        notifyError({
          mensagem: error.response.data.message,
        })
      );
    } else {
      dispatch(
        notifyError({
          mensagem: "Ocorreu um erro ao excluir a categoria.",
        })
      );
    }
  }
};

export const criarCategoria = (categoria) => async (dispatch, getState) => {
  try {
    const { fazenda } = getState();
    categoria.IdFazenda = fazenda.FazendaSelecionada;

    const categoriaCriadaResposta = await CategoriaManager.salvarCategoria(
      categoria
    );

    dispatch(salvarCategoria(categoriaCriadaResposta));
    dispatch(
      notifySuccess({
        mensagem: "Categoria criada com sucesso.",
      })
    );
  } catch (error) {
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao criar a categoria.",
      })
    );
  }
};

export const atualizarCategoria = (categoria) => async (dispatch) => {
  try {
    const id = categoria.IdCategoriaAnimal;
    categoria = _.pick(categoria, [
      "Nome",
      "Sexo",
      "IdFazenda",
      "IdFaixaIdade",
      "TamanhoDesejado",
      "IdCurvaGanhoPeso",
      "QuantidadeInicialParaConciliacao",
    ]);

    await CategoriaManager.editarCategoria(categoria, id);
    dispatch(editarCategoria());
    dispatch(
      notifySuccess({
        mensagem: "Categoria editada com sucesso.",
      })
    );
  } catch (error) {
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao editar a categoria.",
      })
    );
  }
};

export const obterCurvasGanhoPeso = () => async (dispatch, getState) => {
  try {
    const { fazenda } = getState();
    const curvasGanhoPeso = await CategoriaManager.obterCurvasGanhoPeso(
      fazenda.FazendaSelecionada
    );
    dispatch(listarCurvasGanhoPeso(curvasGanhoPeso));
  } catch (error) {
    dispatch(listarCurvasGanhoPeso([]));
    dispatch(
      notifyError({
        mensagem:
          "Ocorreu um erro ao obter as curvas de ganho de peso da fazenda",
      })
    );
  }
};
