import BaseService from "services/BaseService";

export const SUPPLEMENTATION_URL = `${process.env.REACT_APP_ENDPOINT}supplementations/supplementations`;

class SupplementationService extends BaseService {
  async getSupplementation(supplementationId) {
    const result = await this.get(
      `${SUPPLEMENTATION_URL}/${supplementationId}/enriched`
    );
    return result.data;
  }

  async getSupplementations(
    page,
    size,
    farmId,
    formulaId,
    pastureId,
    creationUser,
    beginDate,
    endDate
  ) {
    const params = {
      page,
      size,
      farmId,
      formulaId,
      pastureId,
      creationUser,
      beginDate,
      endDate,
    };

    return this._getSupplementations(params);
  }

  async getDeletedSupplementations(
    page,
    size,
    farmId,
    formulaId,
    pastureId,
    creationUser,
    beginDate,
    endDate,
    deleted
  ) {
    const params = {
      page,
      size,
      farmId,
      formulaId,
      pastureId,
      creationUser,
      beginDate,
      endDate,
      deleted,
    };

    return this._getSupplementations(params);
  }

  async listHistorySupplementation(supplementationId, params) {
    if (!supplementationId) return;

    return this.get(
      `${SUPPLEMENTATION_URL}/${supplementationId}/histories`,
      params
    );
  }

  async getFormulas(farmId, deleted) {
    const params = {
      farmId,
      deleted,
    };

    const urlFormulas = `${SUPPLEMENTATION_URL}/formulas`;

    return this.get(urlFormulas, params).then((response) => response.data.data);
  }

  async getPastures(farmId, deleted) {
    const params = {
      farmId,
      deleted,
    };

    const urlPastures = `${SUPPLEMENTATION_URL}/pastures`;

    return this.get(urlPastures, params).then((response) => response.data.data);
  }

  async getUsers(farmId, deleted) {
    const params = {
      farmId,
      deleted,
    };

    const urlUsers = `${SUPPLEMENTATION_URL}/users`;

    return this.get(urlUsers, params).then((response) => response.data.data);
  }

  async deleteSupplementation(supplementationId) {
    if (!supplementationId) return;
    return this.delete(`${SUPPLEMENTATION_URL}/${supplementationId}`);
  }

  async updateSupplementation(supplementationId, values) {
    return this.patch(`${SUPPLEMENTATION_URL}/${supplementationId}`, values);
  }

  async _getSupplementations(params) {
    const urlEnrichSupplementation = `${SUPPLEMENTATION_URL}/enriched`;
    return this.get(urlEnrichSupplementation, params).then(
      (response) => response.data
    );
  }
}

export default new SupplementationService();
