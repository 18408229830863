import { useState } from "react";
import auth from "../Auth";
import history from "../../app/History";

export function useLogin({ exibirCarregando, notifyError }) {
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [error, setError] = useState(false);

  const handleShowPassword = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const navigateToForgotPassword = () => {
    history.push("/esqueceu-senha");
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      setError(false);
      exibirCarregando(true);

      const username = event.target.username && event.target.username.value;
      const password = event.target.password && event.target.password.value;

      if (!username || !password) {
        exibirCarregando(false);
        notifyError({ mensagem: "Informe o usuário e a senha" });
        setError(true);
        return;
      }

      await auth.login(username, password);
    } catch (error) {
      notifyError({ mensagem: error.message });
      setError(true);
    } finally {
      exibirCarregando(false);
    }
  };

  return {
    passwordVisibility,
    error,
    handleShowPassword,
    navigateToForgotPassword,
    onSubmit,
  };
}
