import {
  LISTA_FORMULAS,
  EXCLUIR_FORMULA,
  DESEXCLUIR_FORMULA,
  EPOCAS_FORMULA,
  CATEGORIAS_FORMULA,
} from "../../../redux/actions/actionsConstants";

export const initialState = {
  FormulaSelecionada: null,
  FormulasFazenda: [],
  categoriasFormula: [],
  epocasFormula: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LISTA_FORMULAS:
      return { ...state, FormulasFazenda: action.payload.FormulasFazenda };
    case EXCLUIR_FORMULA:
      return { state };
    case DESEXCLUIR_FORMULA:
      return { state };
    case EPOCAS_FORMULA:
      return { ...state, epocasFormula: action.payload.epocasFormula };
    case CATEGORIAS_FORMULA:
      return { ...state, categoriasFormula: action.payload.categoriasFormula };
    default:
      return state;
  }
};
