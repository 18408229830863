import { Button, Icon, Typography, withStyles } from "@material-ui/core";
import PlanningContent from "../shared/planningContent";
import React from "react";
import PlanningsForm from "../shared/planningsForm";
import PlanningSummary from "../shared/planningSummary";
import dayjs from "dayjs";

const WARNING_TEXT =
  "Os dados referentes aos nomes das categorias, formulações e ingredientes representam os registros no momento da criação.";

const PlanningViewPage = ({
  classes,
  goBack,
  planningPlanData,
  showDatePicker,
  categories = [],
  formulas = [],
  periodTitles = [],
  planningList = [],
  planningTotal = { months: [] },
  summaryReport = {},
  handlePrintPlanning,
}) => {
  const infoLastUpdate = dayjs(planningPlanData.createdAt).format(
    "[Última atualização:] DD/MM/YY [- ás] HH:mm"
  );
  return (
    <div data-testid="planning-view-page" className={classes.container}>
      <PlanningContent
        title="Voltar"
        onBack={goBack}
        headerInfo={infoLastUpdate}
      >
        <div className={classes.headerContent}>
          <Typography data-testid="header-title" className={classes.title}>
            Visualizar planejamento
          </Typography>
          <Button
            onClick={handlePrintPlanning}
            color="primary"
            variant="contained"
            className={classes.buttonDownloadReport}
          >
            <Icon className={classes.iconDownload}>
              <img src="../images/download_white.svg" alt="download" />
            </Icon>{" "}
            Planejamento
          </Button>
        </div>

        <PlanningsForm
          planningPlanData={planningPlanData}
          showDatePicker={showDatePicker}
          disabled={true}
          showStatus={true}
        />

        <PlanningSummary
          periodTitles={periodTitles}
          categories={categories}
          formulas={formulas}
          planningList={planningList}
          summaryReport={summaryReport}
          planningTotal={planningTotal}
          warning={WARNING_TEXT}
        />
      </PlanningContent>
    </div>
  );
};

const styles = (theme) => ({
  container: {},
  subTitle: {
    margin: "24px 0 0 0",
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "normal",
    letterSpacing: "0.2px",
    textAlign: "left",
    color: "#616161",
  },
  subTitleHighlight: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  actionButtons: {
    marginTop: 32,
  },
  headerContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "50px",
  },
  title: {
    fontSize: "22px",
    fontWeight: 600,
    color: "#333",
  },
  buttonDownloadReport: {
    fontSize: "14px",
    padding: "8px 16px",
    height: 40,
    borderRadius: "30px",
  },
  iconDownload: {
    marginRight: "12px",
  },
  formContent: {
    display: "flex",
  },
});

export default withStyles(styles)(PlanningViewPage);
