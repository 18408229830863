import React, { Component } from "react";
import { connect } from "react-redux";
import DropdownTipoAfericao from "./DropdownTipoAfericao";
import { opcoesFarolAltura } from "./farolContainerUtils";

class DropdownTipoAfericaoContainer extends Component {
  render() {
    const { farolSelecionado } = this.props.reduxProps;
    return (
      <DropdownTipoAfericao
        farolRetratoSelecionado={this.props.farolRetratoSelecionado}
        {...this.props.reduxProps}
        opcoesFarol={this.obtemListaOpcoesFarol(
          farolSelecionado,
          opcoesFarolAltura
        )}
      />
    );
  }

  obtemListaOpcoesFarol = () => {
    return opcoesFarolAltura;
  };
}

const mapStateToProps = (state) => {
  return {
    reduxProps: {
      TipoIconeExibido: state.farol.TipoIconeExibido,
      farolSelecionado: state.farol.FarolSelecionado,
    },
  };
};

export default connect(mapStateToProps)(DropdownTipoAfericaoContainer);
