import moment from "moment";
import FarolService from "services/FarolService";
import {
  SELECIONA_FAROL,
  LISTA_FAROL,
  NOTIFICAR,
  EXIBIR_CARREGANDO,
  SELECIONA_TIPOCOLETA,
  SELECIONA_TIPOFAROL,
  ALTERA_ZOOM_MAPA_FAROL,
  EXIBIR_DETALHES,
  ALETERA_PERIODO_COLETA_FAROL,
  EXIBIR_ICONE_MAPA_FAROL,
  RESET_PERIODO_COLERA_FAROL,
} from "redux/actions/actionsConstants";
import { ordenaFarois } from "features/manejo/views/MenuLateral/Farol/FarolUtils";
import ToggleService from "services/ToggleService";
moment.locale("pt-BR");

export const setExibirDetalhes = (exibirDetalhes) => (dispatch) => {
  dispatch({ type: EXIBIR_DETALHES, payload: exibirDetalhes });
};

export const setFarolSelecionado = (IdFarol) => (dispatch, getState) => {
  const { farol } = getState();
  const zoom = farol.ZoomMapa;
  dispatch({ type: SELECIONA_FAROL, payload: IdFarol });
  exibirIcone(zoom, dispatch);
};

export const setTipoColeta = (TipoIconeExibido) => ({
  type: SELECIONA_TIPOCOLETA,
  payload: TipoIconeExibido,
});

export const setTipoFarol = (TipoFarol) => (dispatch) => {
  dispatch({ type: SELECIONA_TIPOFAROL, payload: TipoFarol });
  dispatch({ type: EXIBIR_DETALHES, payload: false });
};

export const listaFarol = (atualizaSuporte) => async (dispatch, getState) => {
  const { farol, fazenda, cliente } = getState();
  const { FazendaSelecionada } = fazenda;
  const { ClienteSelecionado } = cliente;
  const { TipoFarol, PeriodoColeta } = farol;

  dispatch({ type: EXIBIR_CARREGANDO, payload: true });
  try {
    const response = await FarolService.listaFarol(
      FazendaSelecionada,
      TipoFarol,
      PeriodoColeta.inicio,
      PeriodoColeta.final,
      ClienteSelecionado.IdCliente
    );

    let farois = null;

    if (TipoFarol === "nascimento-morte") {
      farois = await filtraNascimentoMorte(response, FazendaSelecionada);
    } else {
      farois = response.data;
    }

    dispatch({ type: LISTA_FAROL, payload: { Farois: farois ? farois : [] } });
    if (farois.length) {
      const faroisOrdenados = ordenaFarois(farois);
      if (atualizaSuporte) setFarolSelecionado(13)(dispatch, getState);
      else setFarolSelecionado(faroisOrdenados[0].IdFarol)(dispatch, getState);
    }
  } catch (error) {
    let mensagem = "";
    let codigoErro = "";
    const regex = /\d+/g;
    const matches = String(error).match(regex);

    if (matches && matches[0]) {
      codigoErro = matches[0];
    }

    switch (codigoErro) {
      case "404":
        mensagem =
          "Desculpe, nenhum farol encontrado. Por favor, tente novamente.";
        break;
      case "422":
        mensagem =
          "Não foi possível realizar essa ação. Por favor, tente novamente.";
        break;
      default:
        mensagem =
          "Ocorreu algo inesperado ao listar faróis. Por favor, tente novamente.";
    }

    dispatch({ type: LISTA_FAROL, payload: { Farois: [] } });
    dispatch({ type: SELECIONA_FAROL, payload: null });
    dispatch({ type: EXIBIR_DETALHES, payload: false });

    // codigo necessario refatoracao
    // foi criado dessa forma para solucionar o erro na hra de retorno da lista de coletas vazias
    if (TipoFarol === "cerca") {
      mensagem = "Não há nehuma coleta para este farol, neste periodo!";
      dispatch({
        type: NOTIFICAR,
        payload: {
          mensagem: mensagem,
          variant: "info",
          estilo: "info",
        },
      });
    } else {
      dispatch({
        type: NOTIFICAR,
        payload: {
          mensagem: mensagem,
          variant: "error",
          estilo: "error",
        },
      });
    }
  } finally {
    dispatch({ type: EXIBIR_CARREGANDO, payload: false });
  }
};

async function filtraNascimentoMorte(response, FazendaSelecionada) {
  const toggleResponse = await ToggleService.listarToggles(FazendaSelecionada);
  const toggleMorte = toggleResponse.data.filter(
    (toggle) => toggle.Nome === "COLETA_MORTE"
  )[0];
  const toggleNascimento = toggleResponse.data.filter(
    (toggle) => toggle.Nome === "COLETA_NASCIMENTO"
  )[0];
  let farois = null;
  if (!toggleMorte) farois = await removeColetaDeMorte(response.data);
  else if (!toggleNascimento)
    farois = await removeColetaDeNascimento(response.data);
  else farois = response.data;

  return farois;
}

export const removeColetaDeNascimento = async (farois) => {
  return farois.filter((farol) => farol.IdFarol === 16);
};

export const removeColetaDeMorte = async (farois) => {
  return farois.filter((farol) => farol.IdFarol === 20);
};

export const alteraZoomMapaFarol = (zoom) => (dispatch) => {
  dispatch({ type: ALTERA_ZOOM_MAPA_FAROL, payload: zoom });
  exibirIcone(zoom, dispatch);
};

export const alteraPeriodoColeta = (periodoColeta) => (dispatch) => {
  dispatch({ type: ALETERA_PERIODO_COLETA_FAROL, payload: periodoColeta });
};

function exibirIcone(zoom, dispatch) {
  const zoomExibeIconePastoFarol = zoom && zoom < 13 ? false : true;
  dispatch({
    type: EXIBIR_ICONE_MAPA_FAROL,
    payload: zoomExibeIconePastoFarol,
  });
}
export const resetPeriodoColeta = () => (dispatch) => {
  dispatch({ type: RESET_PERIODO_COLERA_FAROL });
};

export { filtraNascimentoMorte };
