import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as actions from "../../../../../redux/actions";
import Button from "@material-ui/core/Button";
import styles from "../../../../../styles/MenuLateral";

class MenuLateralKML extends Component {
  salvarPastosKML = (pastosKMLCarregado, idFazenda) => {
    const { fazendaInfo } = this.props;
    this.props.atualizarKMLFazenda(fazendaInfo); // kmlActions
  };

  atualizarKML = () => {
    // kmlActions
    this.props.limparCoordPastosKML();
    this.props.setCadastrandoKml(false);
    this.props.setFazendaInfo(null);
  };

  render() {
    const { classes, pastosKML, IdFazenda } = this.props;
    let pastosKMLCarregado = pastosKML;
    return (
      <div className={classes.telaInfomacao}>
        <div className={classes.iconeKML}>
          <img src="../images/kml.svg" alt="icone de um kml" />
        </div>
        <Typography className={classes.mensagem}>
          Você pode confirmar o KLM da fazenda?
        </Typography>
        <Button
          className={classes.botaoPrimarioKml}
          variant="contained"
          color="primary"
          onClick={() => this.salvarPastosKML(pastosKMLCarregado, IdFazenda)}
        >
          Confirmar KML
        </Button>
        <Button
          className={classes.botaoSecundarioKml}
          color="primary"
          onClick={this.atualizarKML}
        >
          <img
            className={classes.leftIcon}
            src="../images/ic-file-upload.svg"
            alt="ic-file-upload"
          />
          Desejo atualizar o kml
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { kml } = state;
  return {
    fazendaInfo: kml.fazendaInfo,
  };
};

MenuLateralKML.propTypes = {
  pastosKML: PropTypes.array.isRequired,
  IdFazenda: PropTypes.number.isRequired,
};

export default withStyles(styles)(
  connect(mapStateToProps, actions)(MenuLateralKML)
);
