import BaseService from "../../services/BaseService";
import {
  translateAnimalCategoryToEnglish,
  translateAnimalCategoryToPortuguese,
} from "./categoriaUtils";
import qs from "qs";

export const URL_BASE = `${process.env.REACT_APP_ENDPOINT}herds/animalCategory`;

class CategoriaService extends BaseService {
  listaCategoriasPorFazenda(idFazenda, categoriaExcluida = true) {
    const parametrosURL = qs.stringify({
      farmId: idFazenda,
      withDeleted: !!categoriaExcluida,
    });
    const urlServico = this.montaURL(URL_BASE, parametrosURL);

    return this.get(urlServico).then((response) => {
      if (!response.data.data) return response;
      const categoriasAnimal = response.data.data.map((categoria) =>
        translateAnimalCategoryToPortuguese(categoria)
      );
      return {
        data: categoriasAnimal,
      };
    });
  }

  async adicionarCategoria(categoria) {
    const res = await this.post(
      URL_BASE,
      translateAnimalCategoryToEnglish(categoria)
    );
    return res;
  }

  async deletarCategoria(idCategoriaAnimal) {
    return await this.delete(`${URL_BASE}/${idCategoriaAnimal}`);
  }

  async reativarCategoria(idCategoriaAnimal) {
    return await this.patch(`${URL_BASE}/${idCategoriaAnimal}/activate`);
  }

  async editarCategoria(categoria, idCategoriaAnimal) {
    return await this.patch(
      `${URL_BASE}/${idCategoriaAnimal}`,
      translateAnimalCategoryToEnglish(categoria)
    );
  }
}

export default new CategoriaService();
