import {
  SELECIONA_PASTO,
  DESELECIONA_PASTO,
  ALTERA_ZOOM_MAPA,
  OBTEM_MODULOS_FAZENDA,
  ERRO_VALIDAR_MODULO,
  SELECIONA_MODULO,
  DESELECIONA_MODULO,
  SET_COR_MODULO,
  SET_NOME_MODULO,
  SET_NOME_REDUZIDO,
  SET_ESTADO_POLIGONO_MUNDO,
  MARCA_PASTOS_MODULO,
  INICIALIZAR,
} from "features/manejo/redux/actions/moduloActions.js";
import { SET_FAZENDA } from "redux/actions/actionsConstants";
import _ from "lodash";

export const initialState = {
  Nome: "",
  NomeReduzido: "",
  IdModulo: 0,
  IdCocho: 0,
  Cor: "",
  PastosSelecionados: [],
  FazendaSelecionada: null,
  ZoomMapa: null,
  Modulos: [],
  Cocho: {},
  PastoChaveModulo: null,
  PoligonoMundo: false,
  erros: {
    Modulo: {
      Nome: "",
      NomeReduzido: "",
      CorSelecionada: "",
      PastosSelecionados: [],
    },
  },
  ModuloMarcado: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MARCA_PASTOS_MODULO:
      return { ...state, ModuloMarcado: action.payload };
    case SELECIONA_PASTO:
      return {
        ...state,
        PastosSelecionados: [...state.PastosSelecionados, action.payload],
      };
    case DESELECIONA_PASTO:
      const newPastosSelecionados = state.PastosSelecionados;
      _.remove(
        newPastosSelecionados,
        (pasto) => pasto.IdPasto === action.payload.IdPasto
      );
      return { ...state, PastosSelecionados: [...newPastosSelecionados] };
    case ALTERA_ZOOM_MAPA:
      return { ...state, ZoomMapa: action.payload };
    case SET_FAZENDA:
      return initialState;
    case OBTEM_MODULOS_FAZENDA:
      return { ...state, Modulos: action.payload };
    case ERRO_VALIDAR_MODULO:
      return {
        ...state,
        erros: {
          ...state.erros,
          Modulo: { ...initialState.erros.Modulo, ...action.payload },
        },
      };

    case SELECIONA_MODULO:
      return {
        ...state,
        Nome: action.payload.Nome,
        NomeReduzido: action.payload.NomeReduzido,
        IdModulo: action.payload.IdModulo,
        Cor: action.payload.Cor,
        PastosSelecionados: action.payload.Pasto,
        FazendaSelecionada: action.payload.IdFazenda,
        RetiroSelecionado: action.payload.IdRetiro,
      };
    case DESELECIONA_MODULO:
      return {
        ...state,
        Nome: "",
        NomeReduzido: "",
        IdModulo: 0,
        Cor: "",
        PastosSelecionados: [],
        FazendaSelecionada: null,
      };
    case SET_COR_MODULO:
      return { ...state, Cor: action.payload };
    case SET_NOME_MODULO:
      return { ...state, Nome: action.payload };
    case SET_NOME_REDUZIDO: {
      return { ...state, NomeReduzido: action.payload };
    }

    case SET_ESTADO_POLIGONO_MUNDO:
      return { ...state, PoligonoMundo: action.payload };
    case INICIALIZAR:
      return initialState;
    default:
      return state;
  }
};
