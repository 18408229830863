import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import { TableRow, TableSortLabel, Tooltip } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { headCells } from "../nomesColunasCabecalho";

export function CategoriaTabelaCabecalho(props) {
  const infoMessage = "Quantidade de cabeças desejadas para esse lote.";
  const { classes, order, orderBy, onRequestSort, retiraColunas } = props;
  const headCellsFiltrada = headCells.filter(
    (hc) => !retiraColunas.includes(hc.id)
  );
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCellsFiltrada.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.defaultCells}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <span id="NomeCategoriaRegistroTabela">{headCell.label}</span>
              {headCell.id === "TamanhoDesejado" ? (
                <Tooltip title={infoMessage} style={{ maxWidth: 60 }}>
                  <InfoIcon color="primary" />
                </Tooltip>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell className={props.classes.defaultCells}>EDITAR</TableCell>
        <TableCell className={props.classes.defaultCells}>ATIVO</TableCell>
      </TableRow>
    </TableHead>
  );
}
