import BaseService from "./BaseService";

const URL_BASE = `${process.env.REACT_APP_ENDPOINT}toggles/api/v1/toggle`;

class ToggleService extends BaseService {
  async listarToggles(idFazenda) {
    const parametrosURL = this.montaFiltro({
      where: { Ativo: true },
      include: [{ model: "FazendasHabilitadas", where: { IdFazenda: idFazenda } }]
    });
    const urlServico = this.montaURL(URL_BASE, parametrosURL);
    return await this.get(urlServico);
  }

  async cadastrarTogglesPadroesLite(idFazenda) {
    const urlServico = URL_BASE + `/toggles-lite/${idFazenda}`;
    return await this.post(urlServico);
  }
}

export default new ToggleService();
