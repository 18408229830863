import moment from "moment";

const isAValidNumber = (number) => {
  if (typeof number === "number") {
    number = number.toString().replace(/\./g, ",");
  }
  const valid = /^\d+(,\d+)?$/.test(number);
  return valid;
};

export const validate = (values) => {
  const errors = {};
  if (values.formulaId !== null && !isAValidNumber(values.amountTroughBags)) {
    errors.amountTroughBags = "O valor informado não é válido";
  }
  if (values.formulaId !== null && values.amountTroughBags === null) {
    errors.amountTroughBags = "Este valor deve ser informado";
  }
  if (
    values.formulaId !== null &&
    values.amountTroughBags !== null &&
    Number(values.amountTroughBags) === 0
  ) {
    errors.amountTroughBags = "O valor informado deve ser maior que 0";
  }

  if (values.collectionDate) {
    const date = moment(values.collectionDate);
    const now = moment();
    if (now < date) {
      errors.collectionDate =
        "Não é possível selecionar uma data no futuro.";
    } else if (now.subtract(60, "days") > date.endOf("day")) {
      errors.collectionDate =
        "Para editar a data de uma coleta, só será possível selecionar uma data que esteja em um período de 60 dias, anterior ao dia atual.";
    }
  }
  return errors;
};
