import React from "react";
import PlanningListItem from "../../planningView/components/planningTableViewItem";
import PlanningListCategoryItem from "./planningTableViewCategoryItem";
import PlanningListTotalItem from "./PlanningListTotalItem";
import { formatToInteger } from "../utils";

export function planningTableViewColumns({
  categories,
  formulas,
  periodTitles,
}) {
  const periodColumns = periodTitles.map((month, monthIndex) => ({
    id: month,
    titulo: month,
    ordenavel: false,
    pegaValor: (planning) => {
      return planning.months ? (
        <PlanningListTotalItem
          total={formatToInteger(planning.months[monthIndex])}
        />
      ) : (
        <PlanningListItem
          formulas={formulas}
          formulaId={planning[monthIndex].formulaId || ""}
          herdCount={
            planning[monthIndex].herdCount !== null
              ? formatToInteger(planning[monthIndex].herdCount)
              : ""
          }
        />
      );
    },
  }));
  return [
    {
      id: "categoria",
      titulo: "Categoria",
      ordenavel: false,
      pegaValor: (planning) => (
        <PlanningListCategoryItem
          total={planning.months}
          categories={categories}
          categoryId={planning[0] && planning[0].categoryId}
        />
      ),
    },
    ...periodColumns,
  ];
}
