import FarolService from "services/FarolService";
import constantsAlturaPasto from "constants/mapaFarolAlturaPasto";

export const getPasturesQuantity = async (selectedFarmId, selectedClientId) => {
  const response = await FarolService.listaFarol(
    selectedFarmId,
    "rebanho",
    null,
    null,
    selectedClientId
  );

  const HERD_LIGHTHOUSE_ID = 11;

  if (
    !response.data ||
    !Array.isArray(response.data) ||
    !response.data.length
  ) {
    return {};
  }
  const flockLighthouse = response.data.find(
    (farol) => farol.IdFarol === HERD_LIGHTHOUSE_ID
  );

  if (!flockLighthouse.Pasto) {
    return {};
  }

  const pasturesQuantity = flockLighthouse.Pasto.reduce(
    (pasturesInfo, pasture) => {
      return {
        ...pasturesInfo,
        [pasture.IdPasto]: {
          pastureId: pasture.IdPasto,
          quantity: pasture.Valor.QuantidadeLotes,
        },
      };
    },
    {}
  );
  return pasturesQuantity;
};

export const getAnimalsQuantity = (pastures, pasturesQuantity) => {
  return pastures.reduce((animalsQuantity, pasture) => {
    const pastureInfo = pasturesQuantity[pasture.IdPasto];

    return pastureInfo
      ? pastureInfo.quantity + animalsQuantity
      : animalsQuantity;
  }, 0);
};

export const getColorIconCollect = (CapturadaDentroDoPasto, Latitude, Longitude) => {
  if (CapturadaDentroDoPasto) {
    return constantsAlturaPasto.collect.collect_types_colors.insidePasture;
  } else {
    return Latitude && Longitude ? constantsAlturaPasto.collect.collect_types_colors.outsidePasture : constantsAlturaPasto.collect.collect_types_colors.noGps;
  }
};
