import React from "react";
import PlanningFormPage from "./planningFormPage";
import { planningTableColumns } from "../planningTableColumns";
import { planningIngredientsColumns } from "../planningIngredientsColumns";

const PlanningFormContainer = ({
  title,
  subtitle,
  ingredientsSubtitle,
  summarySubTitle,
  planningPlanData,
  periodTitles,
  freeCategories,
  categories,
  formulas,
  formulasIngredients,
  planningsTableList,
  ingredientsPrices,
  onChangeCategory,
  onChangeFormula,
  onChangeHerdCount,
  onChangeIngredientPrice,
  updateIngredientsList,
  addNewLine,
  onDeleteLine,
  onChangePlanningInitialDate,
  onClickInitialDate,
  interceptDatePicker,
  showDatePicker,
  setShowDatePicker,
  onSubmit = () => {},
  goBack = () => {},
  summaryReport,
  updateSummaryReport,
}) => {
  return (
    <PlanningFormPage
      title={title}
      subtitle={subtitle}
      ingredientsSubtitle={ingredientsSubtitle}
      summarySubTitle={summarySubTitle}
      planningPlanData={planningPlanData}
      planningsTableList={planningsTableList}
      columns={planningTableColumns({
        freeCategories,
        categories,
        formulas,
        periodTitles,
        onChangeCategory,
        onChangeFormula,
        onChangeHerdCount,
        addNewLine,
        onDeleteLine,
      })}
      formulasIngredients={formulasIngredients}
      ingredientsColumns={planningIngredientsColumns({
        ingredientsPrices,
        onChangeIngredientPrice,
      })}
      categories={categories}
      formulas={formulas}
      periodTitles={periodTitles}
      onClickInitialDate={onClickInitialDate}
      interceptDatePicker={interceptDatePicker}
      showDatePicker={showDatePicker}
      setShowDatePicker={setShowDatePicker}
      onSubmit={onSubmit}
      goBack={goBack}
      onChangeInitialDate={onChangePlanningInitialDate}
      updateIngredientsList={updateIngredientsList}
      summaryReport={summaryReport}
      updateSummaryReport={updateSummaryReport}
    />
  );
};

export default PlanningFormContainer;
