import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as pastoActions from "features/manejo/redux/actions/pastoActions";
import * as mapaActions from "features/manejo/redux/actions/mapaActions";
import PropTypes from "prop-types";
import Poligono from "../Poligono";
import { Marker } from "@react-google-maps/api";
import { coresModulos } from "../../../../../constants/coresModulos";
import IconeFarol from "./IconeFarol";
import InfoFarol from "./InfoFarol";
import { obtemValorIconeFarois } from "./mapaFarolUtil";
import * as _ from "lodash";
import CalculadoraCoordenadas from "lib/calculadoraCoordenadas";
import { idsFarois } from "constants/idsFarois";
import PoligonoMundo from "../../components/PoligonoMundo";
import { TooltipPasto } from "./components/tooltipPasto";
import Marcador from "./components/marcador";
import constantsFarolAlturaPasto from "constants/mapaFarolAlturaPasto";
import { moverPosicao } from "shared/utils";
import ErrorIcon from "@mui/icons-material/Error";

const PoligonoMundoFarol = ({ selecionado }) => {
  return selecionado ? <PoligonoMundo /> : null;
};

const BotaoFechar = ({
  selecionado,
  pastoSelecionado,
  handleClose = () => {},
}) => {
  if (!selecionado) return null;
  const position = CalculadoraCoordenadas.calcularTop(
    pastoSelecionado.Coordenadas
  );
  return (
    <Marker
      id="botaoFechar"
      position={position}
      options={{
        icon: {
          url: "/images/botaoFechar.svg",
          scaledSize: { height: 30, width: 30 },
        },
      }}
      onClick={handleClose}
    />
  );
};

export const PoligonoFarol = ({
  pastoSelecionado,
  pasto,
  setModuloSelecionado,
  setCochoCompartilhadoSelecionado,
  onMouseOverChange,
  selecionarPasto,
  deselecionarPasto,
  farolPasto,
  destacarModulo,
  TipoIconeExibido,
  idFarolSelecionado,
  destacarCochoCompartilhado,
  TipoFarol,
  categoriasLotes,
  forragens,
  zoomExibeIconePastoFarol,
  pastosComCochoCompartilhado,
  visualizarPontosColetas,
  coletaAlturaPasto,
  pastosComPendencia,
}) => {
  const [mouseOver, setMouseOver] = useState(false);
  const [selecionado, setSelecionado] = useState(null);
  const estadoSelecionado = selecionado !== null && selecionado !== undefined;

  useEffect(() => {
    if (selecionado !== null) {
      selecionarPasto(pasto);
    } else {
      deselecionarPasto();
    }
  }, [selecionado]);

  const handleMouseOverChange = (over) => {
    setMouseOver(over);

    const { Cocho, IdModulo } = pasto;

    if (setModuloSelecionado) {
      if (over) {
        if (IdModulo) {
          setModuloSelecionado(IdModulo);
        }
      } else {
        setModuloSelecionado(null);
      }
    }

    if (setCochoCompartilhadoSelecionado) {
      if (over) {
        if (Cocho && Cocho.CochoCompartilhado) {
          setCochoCompartilhadoSelecionado(Cocho.IdCocho);
        }
      } else {
        setCochoCompartilhadoSelecionado(null);
      }
    }

    if (onMouseOverChange) {
      onMouseOverChange(over, pasto, farolPasto);
    }
  };

  const handleSetPastoComColetaMorte =
    (pasto, idFarolSelecionado, farolPasto) => () => {
      if (
        farolPasto &&
        farolPasto.Valor &&
        idFarolSelecionado === idsFarois.mortes &&
        pastoSelecionado.IdPasto === null
      ) {
        setSelecionado(pasto);
      }
    };

  const handleClose = () => {
    setSelecionado((prev) => {
      return null;
    });
  };

  const renderPoligono = () => {
    var corFarol = "#FFFFFFCC";
    const NaoExibirCorFarolAltura =
      TipoIconeExibido !== "Todos" &&
      farolPasto &&
      farolPasto.Valor &&
      farolPasto.Valor.Tipo &&
      farolPasto.Valor.Tipo !== TipoIconeExibido;
    if (farolPasto && farolPasto.Cor && !NaoExibirCorFarolAltura) {
      corFarol = farolPasto.Cor;
    }

    if (
      farolPasto &&
      farolPasto.Valor &&
      farolPasto.Valor.Lote &&
      farolPasto.Valor.Lote.length > 0
    ) {
      // 17: "Livre de Parasitas",
      // 18: "Livre de Bicheiras",
      // 19: "Livre de Outros problemas",

      const condicao =
        idFarolSelecionado !== 17
          ? (item) => item.Valor === "Sim"
          : (item) => item.Valor === "Não";

      const animaisComParasitas = farolPasto.Valor.Lote.some(condicao);
      corFarol = animaisComParasitas ? "#eb394b" : "#4fd26b";
    }

    return (
      <Poligono
        onClick={handleSetPastoComColetaMorte(
          pasto,
          idFarolSelecionado,
          farolPasto
        )}
        coordenadas={pasto.Coordenadas}
        cor={corFarol}
        onMouseOverChange={handleMouseOverChange}
        highlight={mouseOver || estadoSelecionado || destacarCochoCompartilhado}
        block={false}
        raised={mouseOver || estadoSelecionado}
        corLinha={pasto.Modulo ? coresModulos[pasto.Modulo.Cor] : undefined}
        destacarModulo={destacarModulo}
      />
    );
  };

  const renderIcone = () => {
    const { exibeIcone, valorExibido, cor } = obtemValorIconeFarois(
      farolPasto,
      TipoIconeExibido,
      idFarolSelecionado,
      pasto
    );
    if (exibeIcone) {
      const posicaoIcone = CalculadoraCoordenadas.calcularCentro(
        pasto.Coordenadas
      );
      return (
        <IconeFarol
          cor={cor}
          valorExibido={valorExibido}
          posicaoIcone={posicaoIcone}
          handleSetPastoComColetaMorte={handleSetPastoComColetaMorte(
            pasto,
            idFarolSelecionado,
            farolPasto
          )}
        />
      );
    }
  };

  const renderIconPendencia = () => {
    if (pastosComPendencia && pastosComPendencia.length > 0) {
      const pendencia = pastosComPendencia.filter(
        (pendencia) => pendencia.IdPasto === pasto.IdPasto
      );

      if (pendencia.length > 0 && idFarolSelecionado === idsFarois.mortes) {
        const posicaoIcone = CalculadoraCoordenadas.calcularCentro(
          pasto.Coordenadas
        );

        const distanciaSul = 20; // Distância em metros para mover para o sul

        const novaPosicao = moverPosicao(
          posicaoIcone.lat,
          posicaoIcone.lng,
          distanciaSul
        );

        return (
          <IconeFarol
            cor={"yellow"}
            valorExibido={""}
            posicaoIcone={novaPosicao}
            handleSetPastoComColetaMorte={handleSetPastoComColetaMorte(
              pasto,
              idFarolSelecionado,
              farolPasto
            )}
            styles={{
              borderRadius: "4px",
              width: "32px",
              height: "24px",
            }}
            icon={<ErrorIcon fontSize="20px" color="#FFC944" />}
          />
        );
      }
    }
    return null;
  };

  const forragem = _.find(forragens, (f) => {
    return f.IdForragem === pasto.IdForragem;
  });
  const nomeForragemFormatado = forragem ? "\n" + forragem.Nome : "";
  return (
    <div>
      {renderIcone()}
      {renderIconPendencia()}
      <BotaoFechar
        selecionado={estadoSelecionado}
        pastoSelecionado={pastoSelecionado}
        handleClose={handleClose}
      />
      <PoligonoMundoFarol selecionado={estadoSelecionado} />

      {mouseOver && visualizarPontosColetas && coletaAlturaPasto ? (
        <>
          {pasto.ColetaAlturaPasto.color ===
            constantsFarolAlturaPasto.collect.collect_types_colors
              .outsidePasture && <PoligonoMundo />}
          <TooltipPasto
            informacoesColeta={{
              ...pasto.ColetaAlturaPasto,
            }}
            forragem={forragem}
          />
          <Marcador
            position={pasto.ColetaAlturaPasto.position}
            url={pasto.ColetaAlturaPasto.url}
          />
        </>
      ) : (
        <InfoFarol
          visualizarPontosColetas={visualizarPontosColetas}
          mouseOver={mouseOver}
          pasto={pasto}
          farolPasto={farolPasto}
          NomeForragem={nomeForragemFormatado}
          idFarolSelecionado={idFarolSelecionado}
          TipoIconeExibido={TipoIconeExibido}
          categoriasLotes={categoriasLotes}
          TipoFarol={TipoFarol}
          pastosComCochoCompartilhado={pastosComCochoCompartilhado}
        />
      )}
      {renderPoligono()}
    </div>
  );
};

PoligonoFarol.propTypes = {
  pastos: PropTypes.array,
  farolPasto: PropTypes.object,
  destacarModulo: PropTypes.bool,
  destacarCochoCompartilhado: PropTypes.bool,
  onMouseOverChange: PropTypes.func,
  TipoIconeExibido: PropTypes.string,
  idFarolSelecionado: PropTypes.number,
  categoriasLotes: PropTypes.array,
  pastoSelecionado: PropTypes.object,
  setCochoCompartilhadoSelecionado: PropTypes.func,
  setModuloSelecionado: PropTypes.func,
  visualizarPontosColetas: PropTypes.bool,
};

const mapDispatchToProps = {
  selecionarPasto: pastoActions.selecionarPasto,
  deselecionarPasto: pastoActions.deselecionarPasto,
  setEstadoMapa: mapaActions.setEstadoMapa,
};

const mapStateToProps = (state) => {
  return {
    pastoSelecionado: state.pasto.PastoSelecionado,
    visualizarPontosColetas: state.mapa.visualizarPontosColetas,
  };
};

const diffToMemoize = (prevProps, nextProps) => {
  const destacarModulo = prevProps.destacarModulo === nextProps.destacarModulo;
  const idFarolSelecionado =
    prevProps.idFarolSelecionado === nextProps.idFarolSelecionado;
  const destacarCochoCompartilhado =
    prevProps.destacarCochoCompartilhado ===
    nextProps.destacarCochoCompartilhado;
  const pastoSelecionado =
    prevProps.pastoSelecionado.IdPasto === nextProps.pastoSelecionado.IdPasto;
  const farolPasto = prevProps.farolPasto === nextProps.farolPasto;
  const tipoIconeExibido =
    prevProps.TipoIconeExibido === nextProps.TipoIconeExibido;
  const visualizarPontosColetas =
    prevProps.visualizarPontosColetas === nextProps.visualizarPontosColetas;

  const coletaAlturaPasto =
    prevProps.coletaAlturaPasto === nextProps.coletaAlturaPasto;

  return (
    destacarModulo &&
    destacarCochoCompartilhado &&
    idFarolSelecionado &&
    pastoSelecionado &&
    farolPasto &&
    tipoIconeExibido &&
    visualizarPontosColetas &&
    coletaAlturaPasto
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(PoligonoFarol, diffToMemoize));
