import React, { useState } from "react";
import { withStyles } from "@material-ui/core";
import styles from "../NewLoginPage/styles";

export function PasswordInput({
  classes,
  label,
  inputName,
  placeholder,
  error,
  onChange,
}) {
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const onTogglePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  return (
    <div
      className={[classes.inputWrapper, error ? classes.onError : ""].join(" ")}
    >
      <label className={classes.inputLabel} htmlFor={inputName}>
        {label}
      </label>
      <input
        className={classes.input}
        type={passwordVisibility ? "text" : "password"}
        name={inputName}
        placeholder={placeholder}
        onChange={onChange}
      />
      <span
        className={classes.spanIcon}
        data-testid={"show-password-icon"}
        onClick={onTogglePasswordVisibility}
      >
        {passwordVisibility ? (
          <img
            data-testid="visibilityOn"
            width="20px"
            height="21px"
            src="https://storage.googleapis.com/armazenamento-imagens/assets/visibility-on-new-blue.svg"
            alt="visibilityOn"
          />
        ) : (
          <img
            data-testid="visibilityOff"
            width="20px"
            height="21px"
            src="https://storage.googleapis.com/armazenamento-imagens/assets/visibility-off-new-blue.svg"
            alt="visibilityOff"
          />
        )}
      </span>
    </div>
  );
}

export default withStyles(styles)(PasswordInput);
