import React from "react";
import { withStyles } from "@material-ui/core";

import GoalPlanListFilter from "./goalPlanListFilter";
import Button from "../../shared/button";

const GoalPlanListHeader = ({ classes, filter, onChangeFilter, onClick }) => {
  return (
    <div className={classes.goalPlanListHeaderWrapper}>
      <GoalPlanListFilter
        id="goalPlanListFilter"
        filter={filter}
        onChangeFilter={onChangeFilter}
      />
      <Button label="CRIAR PLANO E METAS" onClick={onClick} />
    </div>
  );
};

const styles = () => {
  return {
    goalPlanListHeaderWrapper: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "40px",
      marginTop: "40px",
    },
  };
};
export default withStyles(styles)(GoalPlanListHeader);
