import BaseService from "../../services/BaseService";
import {
  translateWeightGainCurveToPortuguese,
  translateWeightGainCurveToEnglish,
} from "./ganhoPesoUtils";

const URL_BASE = `${process.env.REACT_APP_ENDPOINT}herds/weightGainCurve`;

class GanhoPesoService extends BaseService {
  async obterCurvasPorFazenda(idFazenda) {
    let query = {
      farmId: idFazenda,
    };
    const parametrosURL = this.montaURLParametros(query);
    const urlServico = this.montaURL(URL_BASE, parametrosURL);
    const response = await this.get(urlServico);

    if (!response || !response.data || !response.data.data) {
      return { data: [] };
    }

    const curvaPesoResponse = response.data.data.map(
      translateWeightGainCurveToPortuguese
    );
    return { ...response, data: curvaPesoResponse };
  }

  async adicionarCurva(curva) {
    const weightGainCurve = translateWeightGainCurveToEnglish(curva);
    const response = await this.post(URL_BASE, weightGainCurve);
    if (!response || !response.data || !response.data.data) {
      return {};
    }
    const curvaPesoResponse = translateWeightGainCurveToPortuguese(
      response.data.data
    );

    return { ...response, data: curvaPesoResponse };
  }

  async deletarCurva(idCurvaGanhoPeso) {
    const response = await this.delete(`${URL_BASE}/${idCurvaGanhoPeso}`);
    return response;
  }

  async editarCurva(curva, idCurvaGanhoPeso) {
    const weightGainCurve = translateWeightGainCurveToEnglish(curva);
    const response = await this.patch(
      `${URL_BASE}/${idCurvaGanhoPeso}`,
      weightGainCurve
    );

    if (!response || !response.data || !response.data.data) {
      return {};
    }

    const curvaPesoResponse = translateWeightGainCurveToPortuguese(
      response.data.data
    );
    return { ...response, data: curvaPesoResponse };
  }
}

export default new GanhoPesoService();
