import categoriaService from "features/categoria/categoriaService";
import formulaService from "features/formulacao/formulaService";
import { generateSummaryData } from "./utils";
import { useEffect, useMemo, useState } from "react";
import { parsePlanning } from "../views/shared/utils";

const usePlanningRegisterTable = (
  farmId,
  enableLoading,
  disableLoading,
  notifyError
) => {
  const [planningList, setPlanningList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [formulas, setFormulas] = useState([]);
  const [formulasIngredients, setFormulasIngredients] = useState([]);
  const [ingredientsPrices, setIngredientsPrices] = useState({});
  const [summaryReport, setSummaryReport] = useState({});

  const loadExistingPlanning = (result) => {
    const plannings = parsePlanning(result, formulaHasIngredients);
    setPlanningList(plannings);

    const ingredientsList = listIngredients(plannings, formulas);
    setFormulasIngredients([...ingredientsList]);

    const prices = listAvailableIngredientsCosts(
      ingredientsList,
      result.planningIngredientCost
    );
    setIngredientsPrices(prices);
  };

  const formulaHasIngredients = (formulaId) => {
    const selectedFormula = formulas.find(
      (formula) => formula.IdFormula === formulaId
    );
    return (
      selectedFormula &&
      selectedFormula.Ingrediente &&
      selectedFormula.Ingrediente.length > 0
    );
  };

  const freeCategories = useMemo(
    () =>
      categories.filter(
        (value) =>
          !planningList.find(
            (line) => line.categoryId === value.IdCategoriaAnimal
          )
      ),
    [planningList, categories]
  );

  const findInitialData = async () => {
    try {
      enableLoading("findInitialData");
      const categoriesList = await categoriaService.listaCategoriasPorFazenda(
        farmId
      );
      setCategories(categoriesList.data);
      const formulas = await formulaService.listarFormulasPorFazenda(
        farmId,
        true
      );
      setFormulas(formulas.data.body);
    } catch (error) {
      console.error(error);
      notifyError("Ocorreu um erro ao buscar dados dos indicadores.");
    } finally {
      disableLoading("findInitialData");
    }
  };

  useEffect(() => {
    findInitialData();
  }, []);

  const findLineIndexByCategory = (categoryId) =>
    planningList.findIndex((line) => line.categoryId === categoryId);

  const onChangeCategory = (currentCategoryId, categoryId) => {
    const indexCurrentLine = findLineIndexByCategory(currentCategoryId);
    const currentLine = { ...planningList[indexCurrentLine] };

    const newLine = {
      ...currentLine,
      categoryId,
    };

    setPlanningList((prevState) => {
      return [
        ...prevState.slice(0, indexCurrentLine),
        { ...newLine },
        ...prevState.slice(indexCurrentLine + 1),
      ];
    });
  };

  const addNewLine = (categoryId) => {
    const indexCurrentLine = findLineIndexByCategory(categoryId);
    const currentLine = { ...planningList[indexCurrentLine] };

    const newLine = {
      ...currentLine,
      categoryId,
      items: createItems(),
    };

    setPlanningList((prevState) => {
      return [...prevState, { ...newLine }];
    });
  };

  const onDeleteLine = (categoryId) => {
    const indexCurrentLine = findLineIndexByCategory(categoryId);

    setPlanningList((prevState) => {
      const _planningList = [...prevState];
      _planningList.splice(indexCurrentLine, 1);
      return _planningList;
    });
  };

  const createItems = () => {
    return Array(12).fill({
      formulaId: null,
      hasIngredients: null,
      herdCount: null,
    });
  };

  const onChangeFormula = (formulaId, planning, monthIndex) => {
    const hasIngredients = formulaHasIngredients(formulaId);
    handleChange({ formulaId, hasIngredients }, planning, monthIndex);
  };

  const onChangeHerdCount = (herdCount, planning, monthIndex) =>
    handleChange({ herdCount: Number(herdCount) }, planning, monthIndex);

  const handleChange = (fieldValue, planning, monthIndex) => {
    const indexCurrentLine = findLineIndexByCategory(planning.categoryId);

    setPlanningList((prevState) => {
      const currentLine = { ...planningList[indexCurrentLine] };
      const item = currentLine.items[monthIndex];

      currentLine.items[monthIndex] = {
        ...item,
        ...fieldValue,
      };

      return [
        ...prevState.slice(0, indexCurrentLine),
        { ...currentLine },
        ...prevState.slice(indexCurrentLine + 1),
      ];
    });
  };

  const onChangeIngredientPrice = (ingredientId) => (ingredientPrice) => {
    setIngredientsPrices((prevState) => ({
      ...prevState,
      [ingredientId]: ingredientPrice,
    }));
  };

  const selectedFormulasId = (planningList) => {
    const selectedFormulas = planningList
      .map((line) => line.items.map((item) => item.formulaId))
      .flat();
    return [...new Set(selectedFormulas)];
  };

  const listIngredients = (planningList, formulas) => {
    const uniqueFormulas = selectedFormulasId(planningList);
    const ingredients = uniqueFormulas.map((formulaId) => {
      const formula = formulas.find(
        (formula) => formula.IdFormula === formulaId
      );

      return formula && formula.Ingrediente
        ? formula.Ingrediente.map((ingredient) => ({
            id: ingredient.OperacaoIngrediente.IdIngrediente,
            name: ingredient.OperacaoIngrediente.Descricao,
          }))
        : [];
    });

    return [
      ...new Map(ingredients.flat().map((item) => [item.id, item])).values(),
    ];
  };

  const listAvailableIngredientsCosts = (ingredientsList, ingredientsCosts) => {
    let prices = {};
    ingredientsCosts.forEach((ingredient) => {
      if (ingredientsList.find((fi) => fi.id === ingredient.ingredientId)) {
        prices = { ...prices, [ingredient.ingredientId]: ingredient.cost };
      }
    });

    return prices;
  };

  const updateIngredientsList = () => {
    const ingredientsList = listIngredients(planningList, formulas);
    setFormulasIngredients([...ingredientsList]);
  };

  const updateSummaryReport = (initialDate) => {
    const selectedUniqueIdFormulas = selectedFormulasId(planningList);
    const summaryData = generateSummaryData(
      initialDate,
      planningList,
      formulas,
      categories,
      formulasIngredients,
      ingredientsPrices,
      selectedUniqueIdFormulas
    );
    setSummaryReport(summaryData);
  };

  const firstLineTable = {
    id: "selecionaCategoria",
  };

  const planningsTableList = [...planningList, firstLineTable];

  return {
    freeCategories,
    updateIngredientsList,
    categories,
    formulas,
    planningsTableList,
    formulasIngredients,
    ingredientsPrices,
    summaryReport,
    addNewLine,
    onChangeCategory,
    onChangeFormula,
    onChangeHerdCount,
    onChangeIngredientPrice,
    onDeleteLine,
    planningList,
    setPlanningList,
    formulaHasIngredients,
    updateSummaryReport,
    setSummaryReport,
    setCategories,
    loadExistingPlanning,
  };
};

export default usePlanningRegisterTable;
