import dayjs from "dayjs";
import { roundNumberTwoDecimalPlaces } from "../views/shared/utils";

const sumFormulaPrice = (formula, ingredientsPrices) => {
  return formula.Ingrediente.reduce((acc, curr) => {
    const ingredientPrice =
      ingredientsPrices[curr.OperacaoIngrediente.IdIngrediente] || 0;
    const ingredientPricePerGram = ingredientPrice / 1000;
    const ingredientPriceTotal =
      ingredientPricePerGram * curr.FormulaIngrediente.Quantidade;
    return acc + ingredientPriceTotal;
  }, 0);
};

const initializeCabCategoriesPerMonth = (planningList) => {
  return planningList.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.categoryId]: new Array(12).fill(0),
    }),
    {}
  );
};

const getAndFillSelectedFormulas = (
  formulas,
  selectedUniqueIdFormulas,
  ingredientsPrices,
  planningList
) => {
  const selectedFormulas = new Map();

  formulas
    .filter((formula) => selectedUniqueIdFormulas.includes(formula.IdFormula))
    .forEach((formula) => {
      const totalPriceFormulacao = sumFormulaPrice(formula, ingredientsPrices);

      selectedFormulas.set(formula.IdFormula, {
        ...formula,
        cabPerMonth: {
          categoriesPerMonth: initializeCabCategoriesPerMonth(planningList),
          totalPerMonth: new Array(12).fill(0),
        },
        price: totalPriceFormulacao,
      });
    });

  for (let index = 0; index < 12; index++) {
    planningList.forEach((line) => {
      selectedFormulas.get(
        line.items[index].formulaId
      ).cabPerMonth.categoriesPerMonth[line.categoryId][index] =
        line.items[index].herdCount;

      selectedFormulas.get(
        line.items[index].formulaId
      ).cabPerMonth.totalPerMonth[index] += line.items[index].herdCount;
    });
  }

  return selectedFormulas;
};

const calcConsumoFormulaInMonth = (
  consumoDiario,
  cab,
  initialDate,
  actualMonth
) =>
  (cab *
    consumoDiario *
    dayjs(initialDate).add(actualMonth, "month").daysInMonth()) /
  1000;

const sumFormulaConsumo = (consumoPerMonth) =>
  consumoPerMonth.reduce((acc, curr) => acc + curr, 0);

const getFormulaPlanning = (selectedFormulas, initialDate) => {
  const formulaPlanning = [];
  selectedFormulas.forEach((formula) => {
    let cabPerMonth = selectedFormulas.get(formula.IdFormula).cabPerMonth
      .totalPerMonth;

    const consumoPerMonth = cabPerMonth.map((cab, index) =>
      calcConsumoFormulaInMonth(formula.ConsumoDiario, cab, initialDate, index)
    );

    formulaPlanning.push({
      formula: {
        id: formula.IdFormula,
        name: formula.Nome,
      },
      months: consumoPerMonth,
      total: sumFormulaConsumo(consumoPerMonth),
    });
  });

  return formulaPlanning;
};

const getFormulasPerMonth = (planningList) => {
  let formulasPerMonth = new Array(12).fill([]);
  formulasPerMonth = formulasPerMonth.map((_, index) => {
    return planningList.map((line) => line.items[index].formulaId);
  });

  return formulasPerMonth;
};

const getFormulasWithIngredient = (
  ingredient,
  formulasPerMonth,
  selectedFormulas
) => {
  let formulasWithIngredientPerMonth = new Array(12).fill([]);

  formulasWithIngredientPerMonth = formulasWithIngredientPerMonth.map(
    (_, index) => {
      return formulasPerMonth[index].filter((formulaId) => {
        const formula = selectedFormulas.get(formulaId);
        return (
          formula &&
          formula.Ingrediente &&
          formula.Ingrediente.find(
            (ingrediente) =>
              ingrediente.OperacaoIngrediente.IdIngrediente === ingredient.id
          )
        );
      });
    }
  );

  return formulasWithIngredientPerMonth;
};

const calcConsumoIngredientInMonth = (
  ingredient,
  formulasWithIngredientInMonth,
  selectedFormulas,
  actualMonth
) => {
  return formulasWithIngredientInMonth.reduce((acc, formulaId) => {
    const formula = selectedFormulas.get(formulaId);

    let consumoIngredient = formula.Ingrediente.find(
      (ingrediente) =>
        ingrediente.OperacaoIngrediente.IdIngrediente === ingredient.id
    ).FormulaIngrediente.Quantidade;

    consumoIngredient = Object.keys(
      formula.cabPerMonth.categoriesPerMonth
    ).reduce((acc, key) => {
      return (
        acc +
        formula.cabPerMonth.categoriesPerMonth[key][actualMonth] *
          consumoIngredient
      );
    }, 0);

    return acc + consumoIngredient;
  }, 0);
};

const calcTotalConsumoIngredientInMonth = (
  consumoTotalIngrediente,
  initialDate,
  actualMonth
) =>
  (consumoTotalIngrediente *
    dayjs(initialDate).add(actualMonth, "month").daysInMonth()) /
  1000;

const sumConsumoIngredients = (consumoIngredientPerMonth) =>
  consumoIngredientPerMonth.reduce((acc, curr) => acc + curr, 0);

const getIngredientPlanning = (
  planningList,
  selectedFormulas,
  formulasIngredients,
  initialDate
) => {
  const formulasPerMonth = getFormulasPerMonth(planningList);

  const ingredientPlanning = formulasIngredients.map((ingredient) => {
    let formulasWithIngredientPerMonth = getFormulasWithIngredient(
      ingredient,
      formulasPerMonth,
      selectedFormulas
    );

    const consumoIngredientPerMonth = formulasWithIngredientPerMonth.map(
      (formulasWithIngredient, index) => {
        const consumoIngrediente = calcConsumoIngredientInMonth(
          ingredient,
          [...new Set(formulasWithIngredient)],
          selectedFormulas,
          index
        );

        const consumoTotalIngredientInMonth = calcTotalConsumoIngredientInMonth(
          consumoIngrediente,
          initialDate,
          index
        );

        return consumoTotalIngredientInMonth;
      }
    );

    const totalCost = sumConsumoIngredients(consumoIngredientPerMonth);

    return {
      ingredient: {
        id: ingredient.id,
        name: ingredient.name,
      },
      months: consumoIngredientPerMonth,
      total: roundNumberTwoDecimalPlaces(totalCost),
    };
  });

  return ingredientPlanning;
};

const calcFormulaPriceInMonth = (price, initialDate, actualMonth) =>
  price * dayjs(initialDate).add(actualMonth, "month").daysInMonth();

const sumTotalCategoryPrice = (months) =>
  months.reduce((acc, curr) => acc + curr, 0);

const sumTotalCategoriesPriceInMonth = (categories, actualMonth) =>
  categories.reduce((acc, curr) => acc + curr.months[actualMonth], 0);

const weightedAveragePerMonth = (categories) => {
  const totalHerdPerMonth = [];
  for (let i = 0; i < 12; i++) {
    const totalHerdsMonth = categories.reduce(
      (prev, curr) => prev + curr.herdCount[i],
      0
    );
    totalHerdPerMonth.push(totalHerdsMonth);
  }
  const totalCategoriesCostsPerMonth = [];
  for (let i = 0; i < 12; i++) {
    const totalCostCategoryMonth = categories.reduce(
      (prev, curr) => prev + curr.herdCount[i] * curr.months[i],
      0
    );
    const totalCostCategoryMonthRounded = roundNumberTwoDecimalPlaces(
      totalCostCategoryMonth
    );
    totalCategoriesCostsPerMonth.push(totalCostCategoryMonthRounded);
  }
  const weightedAverageCategoryPerMonth = totalCategoriesCostsPerMonth.map(
    (total, index) => total / totalHerdPerMonth[index]
  );
  return weightedAverageCategoryPerMonth;
};

const getCategoryPlanning = (
  planningList,
  selectedFormulas,
  categories,
  initialDate
) => {
  const categoryPlanning = {
    total: {
      months: [],
      total: 0,
    },
    weightedAverage: {
      months: [],
    },
    categories: [],
  };

  categoryPlanning.categories = planningList.map((line) => {
    const planningItem = {
      category: {
        id: line.categoryId,
        name: categories.find(
          (category) => category.IdCategoriaAnimal === line.categoryId
        ).Nome,
      },
      herdCount: [],
      months: [],
      total: 0,
    };

    planningItem.months = line.items.map((item, index) => {
      const formula = selectedFormulas.get(item.formulaId);

      let total = calcFormulaPriceInMonth(formula.price, initialDate, index);

      if (item.herdCount === 0 || item.herdCount === "0") {
        total = 0;
      }

      return roundNumberTwoDecimalPlaces(total);
    });

    planningItem.herdCount = line.items.map((item) => item.herdCount);

    planningItem.total = sumTotalCategoryPrice(planningItem.months);

    return planningItem;
  });

  for (let index = 0; index < 12; index++) {
    let totalCostMonth = sumTotalCategoriesPriceInMonth(
      categoryPlanning.categories,
      index
    );

    totalCostMonth = roundNumberTwoDecimalPlaces(totalCostMonth);

    categoryPlanning.total.months.push(totalCostMonth);

    categoryPlanning.total.total += totalCostMonth;
  }

  categoryPlanning.weightedAverage.months = weightedAveragePerMonth(
    categoryPlanning.categories
  );

  return categoryPlanning;
};

const getPlanning = (planningList) => {
  const planning = {
    planning: [...planningList],
    total: {
      months: new Array(12).fill(0),
    },
  };

  planningList.forEach((line) => {
    line.items.forEach((item, index) => {
      planning.total.months[index] += Number(item.herdCount);
    });
  });
  return planning;
};

export const generateSummaryData = (
  initialDate,
  planningList,
  formulas,
  categories,
  formulasIngredients,
  ingredientsPrices,
  selectedUniqueIdFormulas
) => {
  const selectedFormulas = getAndFillSelectedFormulas(
    formulas,
    selectedUniqueIdFormulas,
    ingredientsPrices,
    planningList
  );

  const formulaPlanning = getFormulaPlanning(selectedFormulas, initialDate);

  const ingredientPlanning = getIngredientPlanning(
    planningList,
    selectedFormulas,
    formulasIngredients,
    initialDate
  );

  const categoryPlanning = getCategoryPlanning(
    planningList,
    selectedFormulas,
    categories,
    initialDate
  );

  const planning = getPlanning(planningList);

  return {
    formulaPlanning,
    ingredientPlanning,
    categoryPlanning,
    planning,
  };
};
