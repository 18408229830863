import React, { useMemo, useState } from "react";
import constants from "constants/global";
import localConstants from "../../shared/constants";
import {
  IconButton,
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  withStyles,
} from "@material-ui/core";
import PlanningListTableHeader from "./planningListTableHeader";
import dayjs from "dayjs";
import PlanningStatus from "../../shared/planningStatus";
import PlanningEditButton from "./planningEditButton";
import { stableSort, getComparator } from "shared/utils";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const TABLE_DATE_FORMAT_PATTERN = localConstants.dateShortFormat;
const FORMAT_DATE = "DD/MM/YYYY";

export const formatDate = (date, formatPattern) =>
  dayjs(date)
    .utc()
    .format(formatPattern || FORMAT_DATE);

const PlanningList = ({
  classes,
  plannings,
  showPlanning = () => {},
  showEditPlanning = () => {},
  downloadReport = () => {},
}) => {
  const [order, setOrder] = useState(constants.orderBy.desc);
  const [orderBy, setOrderBy] = useState("active");

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === constants.orderBy.asc;
    setOrder(isAsc ? constants.orderBy.desc : constants.orderBy.asc);
    setOrderBy(property);
  };

  const sortedPlannings = useMemo(() => {
    if (orderBy !== "active") {
      return stableSort(plannings, getComparator(order, orderBy));
    }
    return plannings.sort((a, b) => {
      if (order === constants.orderBy.desc) {
        return sortByActive(a, b);
      }

      return sortByActive(b, a);
    });
  }, [plannings, order, orderBy]);

  function sortByActive(a, b) {
    const isAExpired =
      new Date(a.finalDate) < new Date() ||
      new Date(a.initialDate) > new Date();

    const isBExpired =
      new Date(b.finalDate) < new Date() ||
      new Date(b.initialDate) > new Date();

    if (isAExpired && !isBExpired) {
      return 1;
    }
    if (!isAExpired && isBExpired) {
      return -1;
    }
    return 0;
  }

  const handleDownloadReport = (event, planningId) => {
    event.stopPropagation();
    downloadReport(planningId);
  };

  return (
    <Paper className={classes.planningListWrapper}>
      <Table
        id="tabela-planejamentos"
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <PlanningListTableHeader
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={plannings.length}
        />
        <TableBody>
          {sortedPlannings.map((planning) => (
            <TableRow
              data-testid="planning-row"
              hover
              className={classes.tableRow}
              tabIndex={-1}
              key={planning.id}
              onClick={() => showPlanning(planning.planningCode)}
            >
              <TableCell
                className={classes.bodyCell}
                component="th"
                scope="row"
              >
                <div className={classes.dateCell}>
                  <b>
                    {formatDate(
                      planning.initialDate,
                      TABLE_DATE_FORMAT_PATTERN
                    )}
                  </b>{" "}
                  até{" "}
                  <b>
                    {formatDate(planning.finalDate, TABLE_DATE_FORMAT_PATTERN)}
                  </b>
                </div>
              </TableCell>
              <TableCell
                className={classes.bodyCell}
                component="th"
                scope="row"
              >
                <PlanningStatus
                  initialDate={planning.initialDate}
                  finalDate={planning.finalDate}
                />
              </TableCell>
              <TableCell
                className={classes.bodyCellCenter}
                component="th"
                scope="row"
              >
                <IconButton
                  data-testid="download-planning-report"
                  className={classes.icons}
                  onClick={(event) =>
                    handleDownloadReport(event, planning.planningCode)
                  }
                >
                  <Icon className={classes.iconDownload}>
                    <img src="../images/download.svg" alt="download" />
                  </Icon>{" "}
                </IconButton>
              </TableCell>
              <TableCell
                className={classes.bodyCellCenter}
                component="th"
                scope="row"
              >
                <PlanningEditButton
                  onClick={() => showEditPlanning(planning.planningCode)}
                  initialDate={planning.initialDate}
                  finalDate={planning.finalDate}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

const styles = (theme) => ({
  planningListWrapper: {
    overflow: "hidden",
    marginTop: "32px",
  },
  tableRow: {
    cursor: "pointer",
    padding: "0 50px",
  },
  bodyCell: {
    fontFamily: "Roboto",
    color: "#757575",
    fontSize: 14,
  },
  bodyCellCenter: {
    fontFamily: "Roboto",
    color: "#757575",
    fontSize: 14,
    textAlign: "center",
  },
  icons: {
    flexGrow: "0",
    margin: "4px 0px 4px 0px",
    borderRadius: "30px",
    fontSize: "16px",
    fontWeight: "600",
    color: theme.palette.primary.main,
    border: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#B6DDFF",
    },
  },
});

export default withStyles(styles)(PlanningList);
