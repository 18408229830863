export const idsFarois = {
  cochoCoberto: 1,
  cochoElevado: 2,
  cochoEstoqueFinal: 3,
  cochoProduto: 4,
  cochoEstrutura: 5,
  cochoAcesso: 6,
  alturaPastoAlturaForragem: 7,
  alturaPastoTaxaCrescimento: 8,
  alturaPastoMediaEntrada: 9,
  alturaPastoMediaSaida: 10,
  rebanhoPresenca: 11,
  cochoAbastecido: 12,
  aguadaDisponibilidade: 14,
  aguadaQualidade: 15,
  mortes: 16,
  sanidadeLivreDeParasitas: 17,
  sanidadeAnimaisComBicheira: 18,
  sanidadeAnimaisComOutrosProblemas: 19,
  nascimentos: 20,
  ganhoPeso: 28,
  desabastecimento: 29,
  desabastecimentoCreep: 30,
  acesso: 31,
  voltagem: 32,
  possuiCercaEletrica: 33,
  evitaEntrevero: 34,
  fezes: 35,
  entrevero: 36,
};
export const TipoFarois = {
  Cocho: [
    idsFarois.cochoAcesso,
    idsFarois.cochoCoberto,
    idsFarois.cochoElevado,
    idsFarois.cochoEstoqueFinal,
    idsFarois.cochoEstrutura,
    idsFarois.cochoAbastecido,
    idsFarois.cochoProduto,
  ],
  Altura: [
    idsFarois.alturaPastoAlturaForragem,
    idsFarois.alturaPastoTaxaCrescimento,
    idsFarois.alturaPastoMediaEntrada,
    idsFarois.alturaPastoMediaSaida,
  ],
  Sanidade: [
    idsFarois.sanidadeLivreDeParasitas,
    idsFarois.sanidadeAnimaisComBicheira,
    idsFarois.sanidadeAnimaisComOutrosProblemas,
  ],
  Cerca: [idsFarois.voltagem],
  Fezes: [idsFarois.fezes],
};

export const nomesTogglesPorIdFarol = {
  28: "CADASTRO_GANHO_PESO",
};

export const stringSanidade = {
  [idsFarois.sanidadeLivreDeParasitas]: "Livre de Parasitas?",
  [idsFarois.sanidadeAnimaisComBicheira]: "Bicheiras?",
  [idsFarois.sanidadeAnimaisComOutrosProblemas]: "Outros problemas?",
};
