import React from "react";
import { TextLabel, FeedBackPage } from "../../../../shared/components";
import { Grid } from "@material-ui/core";

const SupplementationNotFound = ({ notFoundText, testid }) => (
  <Grid
    container
    direction="column"
    justify="center"
    style={{
      minHeight: "60vh",
    }}
  >
    <FeedBackPage>
      <Grid container direction="column" alignItems="center">
        <img src="/images/busca.png" alt="Empty screen magnifer" />
        <TextLabel
          id="labelEmptyScreen"
          data-testid={testid}
          name="emptyScreen"
          block
          color="default2"
          customStyle={{
            paddingTop: 10,
            fontFamily: "Roboto",
            fontSize: "18px",
            textAlign: "center",
            maxWidth: "471px",
          }}
        >
          {notFoundText}
        </TextLabel>
      </Grid>
    </FeedBackPage>
  </Grid>
);

export default SupplementationNotFound;
