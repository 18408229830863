import React, { PureComponent } from "react";
import { connect } from "react-redux";

import * as modalActions from "../../../redux/actions/modalActions";
import TableauReportPage from "../views/tableauReportPage";
import * as tableauReportOperations from "../redux/tableauReportOperations";
export class TableauReportContainer extends PureComponent {
  render() {
    let listaRetiro = [];

    if (this.props.Retiros)
      listaRetiro = this.props.Retiros.sort((a, b) =>
        a.NomeReduzido.localeCompare(b.NomeReduzido, "pt-BR")
      );

    return (
      <TableauReportPage
        id="ReportPage"
        obterRelatorios={this.props.obterRelatorios}
        RelatoriosTableau={this.props.RelatoriosTableau}
        modalAberto={this.props.modalAberto}
        modalEmails={this.props.modalEmails}
        imagem={"fileIcon.svg"}
        titulo={"Receba as informações da sua fazenda no período desejado!"}
        setModalTableau={this.props.setModalTableau}
        relatorioBaixado={this.props.relatorioBaixado}
        relatorios={this.props.relatorios}
        usuario={this.props.usuario}
        retiros={listaRetiro}
      />
    );
  }
}

const mapDispatchToProps = {
  obterRelatorios: tableauReportOperations.obterRelatorios,
  RelatoriosTableau: tableauReportOperations.obterRelatoriosTableau,
  setModalTableau: modalActions.setModalTableau,
};

export const mapStateToProps = (state) => {
  return {
    modalAberto: state.modal.ModalTableauAberto,
    statusEnvioEmail: state.modal.ModalHistoricoStatusEnvioEmail,
    retiroSelecionado: state.retiro.RetiroSelecionado,
    relatorioBaixado: state.relatorio.RelatorioBaixado,
    relatorios: state.relatorio.Relatorios,
    Retiros: state.retiro.Retiros,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableauReportContainer);
