import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as operations from '../../redux/formulaOperations';
import ListagemFormulacaoPage from '../formulationList/listagemFormulacaoPage';
import { deveExibirCreep, deveExibirSemiConfinamento } from '../../../shared/utils';

export class ListagemFormulacaoContainer extends Component {
  render() {
    return (
      <ListagemFormulacaoPage
        id="containerCadastroConsumo"
        obterFormulasFazenda={this.props.obterFormulasFazenda}
        criarFormula={this.props.criarFormula}
        atualizarFormula={this.props.atualizarFormula}
        excluirFormula={this.props.excluirFormula}
        desexcluirFormula={this.props.desexcluirFormula}
        formulasFazenda={this.props.formulasFazenda}
        fazendaSelecionada={this.props.fazendaSelecionada}
        exibirCreep={this.props.exibirCreep}
        exibirSemiConfinamento={this.props.exibirSemiConfinamento}
      />
    );
  }
}

const mapDispatchToProps = {
  obterFormulasFazenda: operations.obterFormulasFazenda,
  criarFormula: operations.criarFormula,
  atualizarFormula: operations.atualizarFormula,
  excluirFormula: operations.excluirFormula,
  desexcluirFormula: operations.desexcluirFormula
};

const mapStateToProps = state => {
  const {
    formula,
    fazenda
  } = state;

  return {
    formulasFazenda: formula.FormulasFazenda,
    exibirCreep: deveExibirCreep(state),
    fazendaSelecionada: fazenda.Fazendas.find(f => f.IdFazenda === fazenda.FazendaSelecionada),
    exibirSemiConfinamento: deveExibirSemiConfinamento(state),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListagemFormulacaoContainer);
