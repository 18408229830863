export const txtAjudaCreep =
  "Não é possível selecionar CREEP e outras categorias de\n" +
  "Rebanho Alvo. Uma formulação Creep é destinada a\n" +
  "animais com faixa etária 0 a 8 meses. Desmarque as\n" +
  "outras opções escolhidas para escolher o creep.";

export const txtAjudaNaoCreep =
  "Não é possível selecionar CREEP e outras categorias de\n" +
  "Rebanho Alvo. Uma formulação Creep é destinada a\n" +
  "animais com faixa etária 0 a 8 meses. Desmarque o\n" +
  "creep para  escolher outras opções.";

export const mockOpcoesRebanho = [
  {
    label: "CRIA",
    value: 1,
    disabledHelpText: txtAjudaNaoCreep,
  },
  {
    label: "RECRIA",
    value: 2,
    disabledHelpText: txtAjudaNaoCreep,
  },
  {
    label: "ENGORDA",
    value: 3,
    disabledHelpText: txtAjudaNaoCreep,
  },
  {
    label: "CREEP",
    value: 4,
    disabledHelpText: txtAjudaCreep,
  },
  {
    label: "SEMICONFINAMENTO",
    value: 5,
    disabledHelpText: txtAjudaNaoCreep,
  },
];

export const formulaCategoriaMock = [
  {
    IdCategoria: 1,
    Descricao: "Cria",
  },
  {
    IdCategoria: 2,
    Descricao: "Recria",
  },
  {
    IdCategoria: 3,
    Descricao: "Engorda",
  },
  {
    IdCategoria: 4,
    Descricao: "Creep",
  },
  {
    IdCategoria: 5,
    Descricao: "Semiconfinamento",
  },
];
