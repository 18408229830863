import RetiroService from "./retiroService";

class RetiroManager {
  async listarRetiros(IdFazenda) {
    const respostaService = await RetiroService.listaRetirosPorIdFazenda(IdFazenda);
    return respostaService.data ? respostaService.data : [];
  }

  async criarRetiros(retiros) {
    const respostaService = await RetiroService.salvaMultiplosRetirosFazenda(retiros);
    return respostaService;
  }

  async criarUsuarioRetiro(idUsuario, usuarioRetiros) {
    const respostaService = await RetiroService.criarUsuarioRetiro(idUsuario, usuarioRetiros);
    return respostaService;
  }

  async deletarRetiro(idRetiro) {
    await RetiroService.excluirRetiro(idRetiro);
  }

  async atualizarRetiro(retiro) {
    const id = retiro.IdRetiro;
    delete retiro.IdRetiro;
    delete retiro.IdFazenda;
    const respostaService = await RetiroService.atualizarRetiro(id, retiro);
    return respostaService.data ? respostaService.data : [];
  }
}

export default new RetiroManager();
