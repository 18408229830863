import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dropzone from 'react-dropzone';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

function BotaoUpload({ extensaoArquivoAceito,
  tipoUpload,
  confirmBefore,
  onInputFileOpen,
  onDrop,
  disabled,
  tituloBotao,
  cunstomClasses,
  buttomCustomClasses,
  confirmTitle,
  confirmMessage,
  confirmButtonText,
  classes }) {
  const dropZoneRef = React.createRef();
  const { containerBotao } = classes; // from material ui

  const [openDialog, setDialog] = useState(false);

  const handleOpenInputFile = () => {
    if (confirmBefore) {
      setDialog(true);
    } else {
      openInputFile();
    }
  };

  const openInputFile = () => {
    dropZoneRef.current.open();
    onInputFileOpen(tipoUpload, extensaoArquivoAceito);
  };

  const handleDialogClose = () => {
    setDialog(false);
    openInputFile();
  }

  return (
    <>
      <div className={cunstomClasses ? cunstomClasses : containerBotao}>
        <Button
          variant="contained"
          color="primary"
          className={(buttomCustomClasses || null)}
          disabled={disabled}
          onClick={() => handleOpenInputFile()}
        >
          <Dropzone
            ref={dropZoneRef}
            accept={extensaoArquivoAceito}
            onDrop={onDrop}
            style={styles.dropzone}
            disabled={disabled}
          />
          {tituloBotao}
        </Button>
      </div>
      {confirmBefore &&
        <Dialog
          data-testid="confirm-dialog-container"
          open={openDialog}>
          <div style={styles.dialogContainter} >
            <DialogTitle> {confirmTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText> {confirmMessage} </DialogContentText>
            </DialogContent>
            <DialogActions style={styles.dialogButtonsContainter}>
              <Button
                variant="outlined"
                onClick={handleDialogClose}
                color="primary">
                {confirmButtonText}
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      }
    </>);
}

const styles = {
  containerInfo: {
    padding: 20
  },
  dropzone: {
    display: 'none'
  },
  containerBotao: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    margin: 20
  },
  dialogContainter: {
    color: '#333333',
    padding: '24px 0'
  },
  dialogButtonsContainter: {
    padding: '24px 20px 10px 0px'
  }
};

export default withStyles(styles)(BotaoUpload);
