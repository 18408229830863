import _ from "lodash";
import trataErroGenerico from "lib/trataErroGenerico";

import {
  EXIBIR_CARREGANDO,
  LISTA_PASTOS,
} from "redux/actions/actionsConstants";
import PastoService from "services/PastoService";
import { createServiceHandler } from "services/ServiceHandler";
import ModuloService from "services/ModuloService";

export const SELECIONA_PASTO = "MODULO/SELECIONA_PASTO";
export const DESELECIONA_PASTO = "MODULO/DESELECIONA_PASTO";
export const ALTERA_ZOOM_MAPA = "MODULO/ALTERA_ZOOM";
export const CRIA_MODULO = "MODULO/CRIA_MODULO";
export const ATUALIZA_MODULO = "MODULO/ATUALIZA_MODULO";
export const EXCLUI_MODULO = "MODULO/EXCLUI_MODULO";
export const OBTEM_MODULOS_FAZENDA = "MODULO/OBTEM_MODULOS_FAZENDA";
export const SET_COR_MODULO = "MODULO/SET_COR_MODULO";
export const SET_NOME_MODULO = "MODULO/SET_NOME_MODULO";
export const SET_NOME_REDUZIDO = "MODULO/SET_NOME_REDUZIDO";
export const ERRO_VALIDAR_MODULO = "ERRO_VALIDAR_MODULO";
export const SELECIONA_MODULO = "MODULO/SELECIONA_MODULO";
export const DESELECIONA_MODULO = "MODULO/DESELECIONA_MODULO";
export const SELECIONA_COCHO_COMPARTILHADO =
  "COCHO_COMPARTILHADO/SELECIONA_COCHO";
export const DESELECIONA_COCHO_COMPARTILHADO =
  "COCHO_COMPARTILHADO/DESELECIONA_COCHO";
export const SET_ESTADO_POLIGONO_MUNDO = "MODULO/SET_ESTADO_POLIGONO_MUNDO";
export const MARCA_PASTOS_MODULO = "MODULO/MARCA_PASTOS_MODULO";
export const INICIALIZAR = "MODULO/INICIALIZAR";

export const selecionaPasto = (IdPasto) => ({
  type: SELECIONA_PASTO,
  payload: IdPasto,
});
export const marcaPastosModulo = (Modulo) => ({
  type: MARCA_PASTOS_MODULO,
  payload: Modulo,
});
export const deselecionaPasto = (IdPasto) => ({
  type: DESELECIONA_PASTO,
  payload: IdPasto,
});
export const alteraZoomMapa = (zoom) => ({
  type: ALTERA_ZOOM_MAPA,
  payload: zoom,
});
export const selecionaModulo = (modulo) => ({
  type: SELECIONA_MODULO,
  payload: modulo,
});
export const deselecionaModulo = () => ({ type: DESELECIONA_MODULO });
export const setCorModulo = (cor) => ({ type: SET_COR_MODULO, payload: cor });
export const setNomeModulo = (nome) => ({
  type: SET_NOME_MODULO,
  payload: nome,
});
export const setNomeReduzidoModulo = (nomeReduzido) => ({
  type: SET_NOME_REDUZIDO,
  payload: nomeReduzido,
});

function trataErro(dispatch, error, acao) {
  trataErroGenerico(
    dispatch,
    error,
    `Ocorreu um erro ao tentar ${acao} o modulo.`,
    ERRO_VALIDAR_MODULO,
    `Erro ao ${acao}  o MODULO.`
  );
}

/**
 * FIXME: Retirar chamadas pra serviços deste local
 * O service deve ser o responsável por fazer requisições
 **/
export const criaModulo =
  (Nome, NomeReduzido, Cor, PastosSelecionados) => async (dispatch) => {
    try {
      dispatch({ type: EXIBIR_CARREGANDO, payload: true });
      const listaPastos = PastosSelecionados.map(function (pasto) {
        return { IdPasto: pasto.IdPasto };
      });

      await ModuloService.salvaModuloFazenda({
        IdFazenda: _.first(PastosSelecionados).IdFazenda,
        IdRetiro: _.first(PastosSelecionados).IdRetiro,
        Nome: Nome,
        NomeReduzido: NomeReduzido,
        Cor: Cor,
        Pasto: listaPastos,
      });

      const response = await PastoService.listaPastosPorIdFazenda(
        _.first(PastosSelecionados).IdFazenda,
        [],
        ["Modulo", "Cocho"]
      );
      const pastosComCordenadas = await _.filter(
        response.data,
        (pasto) => pasto.Coordenadas !== null
      );
      dispatch({
        type: LISTA_PASTOS,
        payload: { Pastos: pastosComCordenadas },
      });

      dispatch({ type: CRIA_MODULO });
      dispatch({ type: INICIALIZAR });
    } catch (error) {
      trataErro(dispatch, error, "salvar");
    } finally {
      dispatch({ type: EXIBIR_CARREGANDO, payload: false });
    }
  };

export const limparSelecao = () => async (dispatch) => {
  dispatch({ type: INICIALIZAR });
};

/**
 * FIXME: Retirar chamadas pra serviços deste local
 * O service deve ser o responsável por fazer requisições
 **/
export const atualizarModulo =
  (moduloSelecionado, fazendaSelecionada, nome, nomeReduzido, cor) =>
  async (dispatch) => {
    try {
      const data = {
        Nome: nome,
        NomeReduzido: nomeReduzido,
        Cor: cor,
      };
      /**
       * //TODO:
       * Passar método para service
       */
      await createServiceHandler().put(
        `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/modulos/${moduloSelecionado}`,
        data
      );

      var pastos = await PastoService.listaPastosPorIdFazenda(
        fazendaSelecionada,
        [],
        ["Modulo", "Cocho"]
      );

      dispatch({ type: LISTA_PASTOS, payload: { Pastos: pastos.data } });
      dispatch({ type: ATUALIZA_MODULO });
      dispatch({ type: INICIALIZAR });
    } catch (error) {
      trataErro(dispatch, error, "atualizar");
    }
  };

/**
 * FIXME: Retirar chamadas pra serviços deste local
 * O service deve ser o responsável por fazer requisições
 **/
export const excluirModulo = (IdModulo, IdFazenda) => async (dispatch) => {
  try {
    /**
     * //TODO:
     * Passar método para service
     */
    await createServiceHandler().delete(
      `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/modulos/${IdModulo}`
    );
    var pastos = await PastoService.listaPastosPorIdFazenda(
      IdFazenda,
      [],
      ["Modulo", "Cocho"]
    );

    dispatch({ type: LISTA_PASTOS, payload: { Pastos: pastos.data } });
    dispatch({ type: EXCLUI_MODULO });
    dispatch({ type: INICIALIZAR });
  } catch (error) {
    trataErro(dispatch, error, "excluir");
  }
};

export const alteraEstadoPoligonoMundo = (estado) => (dispatch) => {
  dispatch({ type: SET_ESTADO_POLIGONO_MUNDO, payload: estado });
};
