import {
  SELECIONA_MODAL_HISTORICO,
  ENVIA_EMAIL_HISTORICO,
  SELECIONA_MODAL_LOGOUT,
  SELECIONA_MODAL_TABLEAU,
} from "./actionsConstants";
import { createServiceHandler } from "../../services/ServiceHandler";
import RelatorioService from "../../services/RelatorioService";

export const setModalHistorico = (estadoModal) => {
  return { type: SELECIONA_MODAL_HISTORICO, payload: estadoModal };
};
export const setModalTableau = (estadoModal) => {
  return { type: SELECIONA_MODAL_TABLEAU, payload: estadoModal };
};

export const setModalLogout = (estadoModal) => {
  return {
    type: SELECIONA_MODAL_LOGOUT,
    payload: estadoModal,
  };
};

export const setEmailsHistoricoRetiro =
  (IdRetiro, listaEmails) => async (dispatch) => {
    try {
      /**
       * //FIXME:
       * Passar método para service
       */
      const res = await createServiceHandler().post(
        `${process.env.REACT_APP_ENDPOINT}relatorios/api/v1/relatorios/fazendas/${IdRetiro}/suplementacao/historico`,
        {
          ListaEmails: listaEmails,
        }
      );

      dispatch({ type: ENVIA_EMAIL_HISTORICO, payload: res.status });
    } catch (error) {}
  };

export const setEmailsHistoricoFazenda =
  (IdFazenda, listaEmails) => async (dispatch) => {
    try {
      const res = await RelatorioService.enviaEmailHistoricoFazenda(
        IdFazenda,
        listaEmails
      );

      dispatch({ type: ENVIA_EMAIL_HISTORICO, payload: res.status });
    } catch (error) {}
  };

export const setFalhaEnvioEmail = (envioEmail) => (dispatch) => {
  dispatch({ type: ENVIA_EMAIL_HISTORICO, payload: envioEmail });
};
