import React, { useState, useEffect } from "react";
import moment from "moment";
import { Grid, Button, Typography, withStyles } from "@material-ui/core";
import { DatePicker } from "../../shared/components";
import FechamentoConsumoDetalhes from "./fechamentoConsumoDetalhes";
import "moment/locale/pt-br";
import { retornaDiferencaDatas } from "../../shared/utils";
import SelectDropdown from "components/SelectDropdown";
import DialogInformacaoDataMudancaBaseDados from "./DialogInformacaoDataMudancaBaseDados";
import FechamentoConsumoBannerNovidades from "../../shared/components/Banners/FechamentoConsumoBannerNovidades";
import { DATA_FECHAMENTO_CONSUMO } from "./dataFechamentoConsumo";

const OPCOES_SELECT_INPUT = [
  { value: 0, label: "TODAS" },
  { value: 1, label: "ATIVADAS" },
  { value: 2, label: "DESATIVADAS" },
];

export const FechamentoConsumoCabecalho = (props) => {
  const {
    classes,
    formulas,
    filtroRetiro,
    retiros,
    dataInicio,
    dataFim,
    minDate,
    aplicarFiltros,
    desabilitaControles,
    valorInicial,
    setFormulasSelecionadas,
    setRetirosSelecionados,
  } = props;

  const maxDate = moment().subtract(1, "day").format("YYYY/MM/DD");
  const [dataInicioControl, setDataInicioControl] = useState(dataInicio);
  const [dataFimControl, setDataFimControl] = useState(dataFim);
  const [exibirMensagemSugerida, setExibirMensagemSugerida] = useState(null);
  const [erroData, setErroData] = useState(false);
  const [estadoSelecionado, setEstadoSelecionado] = useState([]);
  const [estadoSelecionadoEstado, setEstadoSelecionadoEstado] = useState(1);
  const [estadoInicialFiltroFormula, setEstadoInicialFiltroFormula] =
    useState(valorInicial);
  const [novosRetirosSelecionados, setNovosRetirosSelecionados] = useState([]);
  const [exibeModalDataMudancaBaseDados, setExibeModalDataMudancaBaseDados] =
    useState(false);
  const [dataMudancaBaseDadosValida, setDataMudancaBaseDadosValida] =
    useState(true);

  const dataMudancaBaseDeDados = moment(DATA_FECHAMENTO_CONSUMO, "YYYY-MM-DD");
  const dataMudancaBaseDeDadosString =
    dataMudancaBaseDeDados.format("DD/MM/YYYY");
  const dataMudancaBaseDeDadosPublicacao = moment(
    DATA_FECHAMENTO_CONSUMO,
    "YYYY-MM-DD"
  );

  const onFilter = async () => {
    if (verificaDataMudancaBaseDados()) {
      const novosRetirosSelecionadosMapeado = novosRetirosSelecionados.map(
        (item) => ({ value: item })
      );

      aplicarFiltros(
        dataInicioControl,
        dataFimControl,
        estadoSelecionado,
        novosRetirosSelecionadosMapeado
      );
    }
  };

  const setDatas = (e) => {
    setErroData(false);
    setDataInicioControl(e);
  };

  useEffect(() => {
    verificarQtdDiasSugeridos();
    setDataMudancaBaseDadosValida(true);
  }, [dataInicioControl, dataFimControl, erroData]);

  const verificaMudancaBaseDadosEhDataFim = () => {
    const inicio = moment(dataInicioControl).format("YYYY-MM-DD");
    const fim = moment(dataFimControl).format("YYYY-MM-DD");
    return (
      fim === dataMudancaBaseDeDados.format("YYYY-MM-DD") &&
      inicio !== dataMudancaBaseDeDados.format("YYYY-MM-DD")
    );
  };

  const verificaDataMudancaBaseDados = () => {
    const inicio = moment(dataInicioControl);
    const fim = moment(dataFimControl);

    if (
      dataMudancaBaseDeDados.isBetween(inicio, fim) ||
      verificaMudancaBaseDadosEhDataFim()
    ) {
      setExibirMensagemSugerida("Erro ao filtrar a data selecionada.");
      setExibeModalDataMudancaBaseDados(true);
      setDataMudancaBaseDadosValida(false);
      return false;
    } else {
      setExibeModalDataMudancaBaseDados(false);
      setDataMudancaBaseDadosValida(true);
      return true;
    }
  };

  const verificarQtdDiasSugeridos = () => {
    if (erroData) {
      setExibirMensagemSugerida(null);
    } else {
      const diasCalculados = retornaDiferencaDatas(
        dataInicioControl,
        dataFimControl
      );
      if (diasCalculados >= 14) {
        setExibirMensagemSugerida(null);
      } else {
        setExibirMensagemSugerida("Período mínino recomendado 15 dias.");
      }
    }
  };

  const handleChangeFormula = (novasFormulas) => {
    setEstadoSelecionado(novasFormulas);
    const formulasFiltradas = formulas.filter((formula) =>
      novasFormulas.some(
        (novaFormulaId) => Number(novaFormulaId) === Number(formula.IdFormula)
      )
    );
    const formulasSelecionadas =
      novasFormulas && novasFormulas.length
        ? formulasFiltradas
        : [{ NomeFormula: "Todas" }];
    setFormulasSelecionadas(formulasSelecionadas);
  };

  const handleChangeRetiro = (novosRetiros) => {
    setNovosRetirosSelecionados(novosRetiros);
    const retirosFormatados = retiros.filter((retiro) =>
      novosRetiros.some((novoRetiro) => novoRetiro === retiro.IdRetiro)
    );
    setRetirosSelecionados(retirosFormatados);
  };

  // faz a filtragem das formulas que vão aparecer no checkbox
  // a partir da escolha "Todas, Ativadas, Desativadas"
  const obterFormulasPorFiltro = (escolha) => {
    let filtroFormula;
    if (escolha > 0) {
      filtroFormula = formulas
        .filter((value) => (escolha === 1 ? !value.Excluido : value.Excluido))
        .map((formula) => ({
          label: formula.NomeFormula,
          value: formula.IdFormula,
        }));
      setEstadoSelecionadoEstado(escolha);
    } else {
      filtroFormula = formulas.map((formula) => ({
        label: formula.NomeFormula,
        value: formula.IdFormula,
      }));
      setEstadoSelecionadoEstado(0);
    }

    setEstadoInicialFiltroFormula(filtroFormula);
    const formulasFormatadas = formulas.filter((v) =>
      filtroFormula.some((f) => f.label === v.NomeFormula)
    );
    setFormulasSelecionadas(formulasFormatadas);
  };

  useEffect(() => {
    if (estadoSelecionadoEstado === 1) {
      obterFormulasPorFiltro(1);
    }
  }, [estadoSelecionadoEstado, formulas]);

  return (
    <>
      <FechamentoConsumoBannerNovidades
        dataMudancaBaseDeDados={dataMudancaBaseDeDadosPublicacao}
      />
      <div className={classes.containerFiltro}>
        <div className={classes.linhaFiltro}>
          <div className={classes.filtrosContainer}>
            <div className={classes.defaultDiv}>
              <DatePicker
                className={
                  exibirMensagemSugerida ? classes.errorDatepicker : undefined
                }
                minDate={minDate}
                maxDate={maxDate}
                onChangeDate={setDatas}
                onError={() => setErroData(true)}
                label="Data de início"
                maxDateMessage="Não pode ser maior que a data atual"
                minDateMessage={`A data de inicio do fechamento de consumo é ${moment(
                  minDate
                ).format("DD/MM/YYYY")}`}
                value={dataInicioControl}
              />
              {exibirMensagemSugerida && (
                <Grid
                  container
                  alignItems={"center"}
                  direction={"row"}
                  xs={12}
                  md={12}
                  className={classes.menssageAlerta}
                >
                  <Typography className={classes.textoAlerta}>
                    {exibirMensagemSugerida}
                  </Typography>
                </Grid>
              )}
            </div>
            <div className={classes.defaultDiv}>
              <DatePicker
                minDate={dataInicioControl || minDate}
                maxDate={maxDate}
                onChangeDate={setDataFimControl}
                label="Data de término"
                maxDateMessage="Não existe cálculo para o dia de hoje"
                minDateMessage="Não pode ser menor que a data de início"
                value={dataFimControl}
              />
            </div>
            <div className={classes.containerCheckboxRetiro}>
              <SelectDropdown
                isMulti
                isClearable
                isSearchable
                options={filtroRetiro}
                value={novosRetirosSelecionados}
                label={{ id: "select-retreat", text: "Retiros" }}
                styles={{ minWidth: "250px", maxWidth: "500px" }}
                onChange={handleChangeRetiro}
              />
            </div>
            <div className={classes.containerBotao}>
              <Button
                color="primary"
                variant="contained"
                onClick={onFilter}
                disabled={
                  desabilitaControles ||
                  moment(dataInicioControl).isAfter(dataFimControl) ||
                  !dataMudancaBaseDadosValida
                }
                id="btnAplicarFiltros"
                className={classes.button}
              >
                Aplicar
              </Button>
            </div>
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <Typography className={classes.titulo}>
            Filtre pelas formulações que deseja visualizar
          </Typography>
          <Typography className={classes.subTitulo}>
            O filtro não altera o valor do cálculo do fechamento de consumo.
          </Typography>
        </div>
        <div className={classes.linhaFiltro}>
          <div className={classes.filtrosContainer}>
            <div className={classes.defaultDiv}>
              <SelectDropdown
                options={OPCOES_SELECT_INPUT}
                value={estadoSelecionadoEstado}
                label={{
                  id: "selectEstadoFormulacao",
                  text: "Situação da Formulação",
                }}
                styles={{ width: "200px" }}
                onChange={obterFormulasPorFiltro}
              />
            </div>

            <div className={classes.containerCheckboxFomulacao}>
              <SelectDropdown
                isMulti
                isClearable
                isSearchable
                options={estadoInicialFiltroFormula}
                value={estadoSelecionado}
                label={{ id: "select-formula", text: "Formulação" }}
                styles={{ minWidth: "250px" }}
                onChange={handleChangeFormula}
              />
            </div>
          </div>
        </div>
      </div>
      <FechamentoConsumoDetalhes />
      <DialogInformacaoDataMudancaBaseDados
        dataReferencia={dataMudancaBaseDeDadosString}
        dataInicio={moment(dataInicioControl).format("DD/MM/YYYY")}
        dataFim={moment(dataFimControl).format("DD/MM/YYYY")}
        visivel={exibeModalDataMudancaBaseDados}
        processaFechar={() => {
          setExibeModalDataMudancaBaseDados(false);
        }}
      />
    </>
  );
};

const styles = (theme) => {
  return {
    cabecalho: {
      minHeight: 80,
    },
    containerFiltro: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
    },
    linhaFiltro: {
      display: "flex",
      minHeight: 80,
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
    },
    titulo: {
      color: "#333333",
      fontSize: 16,
      fontFamily: "Roboto",
      fontWeight: 500,
      textTransform: "uppercase",
    },
    subTitulo: {
      fontSize: "0.875rem",
      fontFamily: "Roboto",
      fontWeight: 500,
      lineHeight: 1.57,
      letterSpacing: "0.00714em",
      marginTop: 3,
      color: "rgba(0, 0, 0, 0.54)",
      marginBottom: 12,
    },
    filtrosContainer: {
      display: "flex",
    },
    filtros: {
      padding: "0px 2px 0 0",
    },
    actionButton: {
      alignItems: "center",
      justifyContent: "center",
      width: 127,
      height: 35,
      borderRadius: 3,
      background: theme.palette.primary.main,
      fontFamily: "Roboto",
      fontSize: 14,
      letterSpacing: 0,
      color: "#FFFFFF",
      marginTop: 13,
    },
    textoAlerta: {
      color: "#ff0102",
      fontSize: 12,
      fontFamily: "Roboto",
    },
    errorDatepicker: {
      color: "#fa3336",
    },
    containerBotao: {
      display: "flex",
      marginTop: 18,
    },
    menssageAlerta: {
      color: "#ff0102",
      marginTop: 6,
      marginBottom: 16,
    },
    button: {
      borderRadius: "18px",
      height: "35px",
      width: "146px",
    },
    selectInput: {
      maxWidth: "200px",
      width: "100%",
    },
    textMultiSelect: {
      fontSize: 14,
      fontFamily: "Roboto",
      "--rmsc-gray": "rgba(0, 0, 0, 0.54) !important",
      "--rmsc-border": "none !important",
      "-rmsc-main": "none !important",
      borderBottom: "1px solid rgba(0, 0, 0, 0.54)",
    },
    containerCheckboxFomulacao: {
      fontSize: 14,
      marginTop: 6,
      marginRight: 34,
    },
    containerCheckboxRetiro: {
      fontSize: 14,
      marginRight: 12,
    },
    defaultDiv: {
      marginTop: 6,
      marginRight: 28,
      minHeight: 80,
    },
  };
};

export default withStyles(styles)(FechamentoConsumoCabecalho);
