import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import PendencyInfosPage from "./pendencyInfosPage";
import dayjs from "dayjs";
import pendencyService from "features/pendency/pendencyService";

const PendencyInfosContainer = ({ match, history, classes }) => {
  const [pendency, setPendency] = useState({});
  const [counts, setCounts] = useState({ origin: 0, destiny: 0 });
  const [origin, setOrigin] = useState({
    categoryId: null,
    pastureId: null,
    categoryName: "",
    pastureName: "",
  });
  const [destiny, setDestiny] = useState({
    categoryId: null,
    pastureId: null,
    categoryName: "-",
    pastureName: "-",
  });

  const todayDate = dayjs().format("YYYY-MM-DD");
  const movementGrouper = match.params.movementGrouper;
  const pagePath = match.path;

  const isEditPage =
    pagePath === "/movimentacoesPendentes/:movementGrouper/edicao";

  const fetchPendency = useCallback(async () => {
    const result = await pendencyService.getPendenciesByMovementGrouper(
      movementGrouper
    );
    setPendency(result);
  }, [movementGrouper]);

  const fetchCounts = useCallback(async () => {
    if (
      origin.categoryId !== null &&
      origin.pastureId !== null &&
      destiny.categoryId !== null &&
      destiny.pastureId !== null
    ) {
      const [resultOriginCount, resultDestinyCount] = await Promise.all([
        pendencyService.getCountAnimalCategoryInPasture(
          origin.categoryId,
          origin.pastureId,
          todayDate
        ),
        pendencyService.getCountAnimalCategoryInPasture(
          destiny.categoryId,
          destiny.pastureId,
          todayDate
        ),
      ]);
      setCounts({ origin: resultOriginCount, destiny: resultDestinyCount });
    }
  }, [origin, destiny, todayDate]);

  useEffect(() => {
    fetchPendency();
  }, [fetchPendency]);

  useEffect(() => {
    if (pendency && Object.keys(pendency).length > 0) {
      setOrigin({
        categoryId: pendency.origin.categoryId,
        pastureId: pendency.origin.pastureId,
        categoryName: pendency.origin.category,
        pastureName: pendency.origin.pasture,
      });
      if (pendency.destiny) {
        setDestiny({
          categoryId: pendency.destiny.categoryId,
          pastureId: pendency.destiny.pastureId,
          categoryName: pendency.destiny.category,
          pastureName: pendency.destiny.pasture,
        });
      }
    }
  }, [pendency]);

  useEffect(() => {
    fetchCounts();
  }, [fetchCounts]);

  return (
    <PendencyInfosPage
      classes={classes}
      history={history}
      movementGrouper={movementGrouper}
      countOrigin={counts.origin}
      countDestiny={counts.destiny}
      pendency={pendency}
      originCategoryName={origin.categoryName}
      originCategoryId={origin.categoryId}
      originPastureName={origin.pastureName}
      originPastureId={origin.pastureId}
      destinyCategoryName={destiny.categoryName}
      destinyCategoryId={destiny.categoryId}
      destinyPastureName={destiny.pastureName}
      destinyPastureId={destiny.pastureId}
      pageType={isEditPage ? "edit" : "details"}
      isEditPage={isEditPage}
    />
  );
};

const mapStateToProps = ({ fazenda }) => ({
  farmId: fazenda.FazendaSelecionada,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendencyInfosContainer);
