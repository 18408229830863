import { EXIBIR_CARREGANDO } from "../../../redux/actions/actionsConstants";
export const LISTA_HISTORICO_MOVIMENTACAO = "LISTA_HISTORICO_MOVIMENTACAO";
export const LISTA_HISTORICO_MOVIMENTACAO_SEM_PAGINACAO =
  "LISTA_HISTORICO_MOVIMENTACAO_SEM_PAGINACAO";
export const LISTA_FILTROS_HITORICO_MOVIMENTACAO =
  "LISTA_FILTROS_HITORICO_MOVIMENTACAO";
export const LISTA_FILTROS_SELECIONADO = "LISTA_FILTROS_SELECIONADO";
export const ALTERAR_ESTADO_MODAL = "ALTERAR_ESTADO_MODAL";

export const listarHistoricoMovimentacaoFazenda = (historicoMovimentacao) => {
  return {
    type: LISTA_HISTORICO_MOVIMENTACAO,
    payload: {
      historicoMovimentacao: historicoMovimentacao,
    },
  };
};

export const listarHistoricoMovimentacaoFazendaSemPaginacao = (
  historicoMovimentacaoSemPaginacao
) => {
  return {
    type: LISTA_HISTORICO_MOVIMENTACAO_SEM_PAGINACAO,
    payload: {
      historicoMovimentacaoSemPaginacao: historicoMovimentacaoSemPaginacao,
    },
  };
};

export const listarFiltrosHistoricoMovimentacao = (
  filtrosHistoricoMovimentacao
) => {
  return {
    type: LISTA_FILTROS_HITORICO_MOVIMENTACAO,
    payload: {
      filtrosHistoricoMovimentacao: filtrosHistoricoMovimentacao,
    },
  };
};

export const salvarFiltrosSelecionados = (filtrosSelecionados) => {
  return {
    type: LISTA_FILTROS_SELECIONADO,
    payload: {
      filtrosSelecionados: filtrosSelecionados,
    },
  };
};

export const exibirCarregando = (exibir) => {
  return {
    type: EXIBIR_CARREGANDO,
    payload: exibir,
  };
};
export const exibirModal = (exibir) => {
  return {
    type: ALTERAR_ESTADO_MODAL,
    payload: {
      exibir,
    },
  };
};
