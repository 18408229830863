import { EXIBIR_CARREGANDO } from "../../../redux/actions/actionsConstants";
export const LISTAR_CONCILIACOES_FAZENDA = "LISTAR_CONCILIACOES_FAZENDA";
export const LISTAR_OPERACOES_POR_CATEGORIA_FAZENDA =
  "LISTAR_OPERACOES_POR_CATEGORIA_FAZENDA";
export const ATUALIZA_DATA_CONCILIACAO = "ATUALIZA_DATA_CONCILIACAO";

export const listarConciliacoesFazenda = (conciliacoes) => {
  return {
    type: LISTAR_CONCILIACOES_FAZENDA,
    payload: { ConciliacoesFazenda: conciliacoes },
  };
};

export const atualizaDataConciliacao = (dataInicioConciliacao) => {
  return {
    type: ATUALIZA_DATA_CONCILIACAO,
    payload: { DataInicioConciliacao: dataInicioConciliacao },
  };
};

export const listarDetalhesCategoria = (operacoes) => {
  return {
    type: LISTAR_OPERACOES_POR_CATEGORIA_FAZENDA,
    payload: { OperacoesCategoria: operacoes },
  };
};

export const exibirCarregando = (exibir) => {
  return {
    type: EXIBIR_CARREGANDO,
    payload: exibir,
  };
};
