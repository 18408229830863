import React from "react";
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Paper,
} from "@material-ui/core";
import { colunasListagemAjustesEscores } from "../ajusteEscoreUtils";

export const ajusteEscoreTabela = (props) => {
  const { classes, ajustesEscoresTabela, editarHabilitado, atualizarAjuste } =
    props;

  return (
    <Paper className={classes.containnerTable}>
      <div className={classes.divTable}>
        <Table id="tabelaAjusteEscore" className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableAjusteHeader}>
              {colunasListagemAjustesEscores.map((coluna) => (
                <TableCell id={coluna.id} className={classes[coluna.className]}>
                  {coluna.titulo}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody id="dadosTabelaAjusteEscore">
            {ajustesEscoresTabela.map((ajuste, index) => {
              const ajusteEscoreValor =
                ajuste.Ajuste || ajuste.Ajuste === 0
                  ? ajuste.Ajuste.toLocaleString("pt-BR")
                  : "";
              return (
                <TableRow id={`tabelaLinhaAjuste-${index}`}>
                  <TableCell className={classes.celulaDiaContent}>
                    {ajuste.DiaAtual}
                  </TableCell>
                  <TableCell className={classes.celulaDiaContent}>
                    {ajuste.DiaAnterior}
                  </TableCell>
                  <TableCell className={classes.celulaAjusteContent}>
                    {editarHabilitado ? (
                      <TextField
                        id={`inputAjuste-${index}`}
                        className={classes.inputAjuste}
                        type="text"
                        value={ajusteEscoreValor}
                        InputProps={{
                          classes: {
                            input: classes.resize,
                          },
                        }}
                        error={ajuste.Erro}
                        onChange={(event) =>
                          atualizarAjuste(
                            event,
                            ajuste.DiaAtual,
                            ajuste.DiaAnterior
                          )
                        }
                      />
                    ) : (
                      ajuste.Ajuste.toLocaleString("pt-BR")
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

const styles = (theme) => {
  return {
    table: {
      width: "100%",
      marginBottom: "0!important",
    },
    tableAjusteHeader: {
      backgroundColor: "#e1e1e1",
      height: 56,
    },
    celulaDia: {
      width: "20%",
      paddingLeft: "45px",
      fontSize: 14,
      textTransform: "uppercase",
      top: 0,
      position: "sticky",
      zIndex: 10,
      color: theme.palette.primary.main,
      backgroundColor: "#e1e1e1",
      textAlign: "center",
    },
    celulaDiaContent: {
      width: "20%",
      paddingLeft: "45px",
      color: "#757575",
      fontSize: 14,
      textAlign: "center",
    },
    celulaAjuste: {
      width: "60%",
      fontSize: 14,
      textTransform: "uppercase",
      top: 0,
      position: "sticky",
      zIndex: 10,
      color: theme.palette.primary.main,
      backgroundColor: "#e1e1e1",
      textAlign: "center",
    },
    celulaAjusteContent: {
      width: "60%",
      color: "#757575",
      fontSize: 14,
      textAlign: "center",
    },
    inputAjuste: {
      width: "50px",
    },
    resize: {
      fontFamily: "Roboto",
      fontSize: "14px",
    },
    containnerTable: {
      width: "100%",
      overflow: "hidden",
      borderRadius: "16px",
    },
    divTable: {
      overflow: "auto",
      maxHeight: 600,
    },
  };
};

export default withStyles(styles)(ajusteEscoreTabela);
