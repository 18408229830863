import React, { Component } from "react";
import { connect } from "react-redux";
import PesagemCategoriaPage from "./pesagemCategoriaPage";
import * as PesagemCateogiraOperations from "../redux/pesagemCategoriaOperations";

export class PesagemCategoriaContainer extends Component {
  render() {
    const {
      categorias,
      criarEventoPesagem,
      editarPesagensFazenda,
      excluirPesagemFazenda,
      fazendaSelecionada,
      obtemEventosPesagens,
      PesagensFazenda,
      obterQuantidadesPorCategoria,
      desativaModal,
      exibirModalConciliacao,
    } = this.props;

    return (
      <PesagemCategoriaPage
        desativaModal={desativaModal}
        categorias={categorias}
        criarEventoPesagem={criarEventoPesagem}
        editarPesagensFazenda={editarPesagensFazenda}
        excluirPesagemFazenda={excluirPesagemFazenda}
        fazendaSelecionada={fazendaSelecionada}
        id="containerPesagemCategoria"
        obtemEventosPesagens={obtemEventosPesagens}
        PesagensFazenda={PesagensFazenda}
        obterQuantidadesPorCategoria={obterQuantidadesPorCategoria}
        exibirModalConciliacao={exibirModalConciliacao}
      />
    );
  }
}

const mapDispatchToProps = {
  criarEventoPesagem: PesagemCateogiraOperations.criarEventoPesagem,
  editarPesagensFazenda: PesagemCateogiraOperations.editarPesagensFazenda,
  excluirPesagemFazenda: PesagemCateogiraOperations.excluirPesagemFazenda,
  obtemEventosPesagens: PesagemCateogiraOperations.obterPesagensFazenda,
  obterQuantidadesPorCategoria:
    PesagemCateogiraOperations.obterQuantidadesPorCategoria,
  desativaModal: PesagemCateogiraOperations.desativaModal,
};

const mapStateToProps = ({ categoria, fazenda, pesagemCategoria }) => ({
  categorias: categoria.categorias,
  fazendaSelecionada: fazenda.FazendaSelecionada,
  PesagensFazenda: pesagemCategoria.PesagensFazenda,
  exibirModalConciliacao: pesagemCategoria.exibirModalConciliacao,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PesagemCategoriaContainer);
