import React from "react";
import { Grid, Divider, withStyles } from "@material-ui/core";
import CardDetalhesFarol from "features/manejo/views/components/cardDetalhesFarol";
import { TextLabel } from "../../../../shared/components";
import DetalhePeriodoForragem from "./views/detalhePeriodoForragem";

const detalhesForragem = (props) => {
  const { CicloForragemFazenda, classes } = props;
  const { marginBottom, containerDetalhes, divider } = classes;

  const renderInformacaoForragem = (cicloForragemFazenda) => {
    return (
      <Grid container xs={12} md={12}>
        <Grid className={marginBottom}>
          <TextLabel
            color="default3"
            size={12}
            bold
            label={cicloForragemFazenda.Forragem.Nome.toUpperCase()}
          />
        </Grid>
        <Grid container direction="row" alignItems="space-between">
          {cicloForragemFazenda.PeriodoCicloForragemFazenda.map(
            (periodo, idx) => {
              return (
                <DetalhePeriodoForragem
                  periodo={periodo}
                  key={`periodoForragem-${idx}`}
                />
              );
            }
          )}
        </Grid>
      </Grid>
    );
  };
  return (
    <Grid container className={containerDetalhes}>
      <CardDetalhesFarol id="detalhesForragens" tituloCard="Forragens">
        {CicloForragemFazenda.map((cicloForragemFazenda) => {
          if (!cicloForragemFazenda.Excluido) {
            return (
              <React.Fragment>
                {renderInformacaoForragem(cicloForragemFazenda)}
                <Divider className={`${divider} ${marginBottom}`} />
              </React.Fragment>
            );
          }
          return null;
        })}
      </CardDetalhesFarol>
    </Grid>
  );
};

const styles = {
  marginBottom: {
    marginBottom: 15,
  },
  divider: {
    width: "100%",
  },
  containerDetalhes: {
    padding: "0 15px",
  },
};

export default withStyles(styles)(detalhesForragem);
