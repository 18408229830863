import { Grid, withStyles } from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import FormStep from "./FormStep";
import { listaLotesPasto } from "features/lote/redux/loteOperations";
import { listarPastosPrime } from "features/manejo/redux/actions/pastoPrimeActions";
import { modificarCadastroPasto } from "features/manejo/redux/actions/pastoActions";
import PastureRegistrations from "./PastureRegistrations";
import FormLocation from "./FormLocation";
import history from "features/app/History";

const steps = ["Localização", "Desenhar pastos"];

function NewFarmFormContainer({
  classes,
  PastoSelecionado,
  errosPasto,
  errosCocho,
  Cocho,
  Retiros,
  RetiroSelecionado,
  FazendaSelecionada,
  cadastroPastoModificado,
  Forragens,
  Lotes,
  Toggles,
  PastosPrime,
  CoordenadasNovosPastos,
}) {
  const [activeStep, setActiveStep] = useState(0);

  const [trough, setTrough] = useState(Cocho);
  const [creepTrough, setCreepTrough] = useState();
  const [newPasture, setNewPasture] = useState(PastoSelecionado);
  const [location, setLocation] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");

  const handleOnNext = () => {
    setActiveStep(1);
  };
  const handleLocationBack = () => {
    history.replace("/cadastro");
  };
  const handlePastureBack = () => {
    setActiveStep(0);
  };

  return (
    <Grid containter xs className={classes.root} direction="column">
      <FormStep steps={steps} activeStep={activeStep} />

      <Grid container item xs>
        {activeStep === 0 && (
          <FormLocation
            onBack={handleLocationBack}
            onNext={handleOnNext}
            location={location}
            setLocation={setLocation}
            lat={lat}
            setLat={setLat}
            long={long}
            setLong={setLong}
          />
        )}
        {activeStep === 1 && (
          <PastureRegistrations
            id="containerCadastro"
            pasture={newPasture}
            setPasture={setNewPasture}
            trough={trough}
            setTrough={setTrough}
            creepTrough={creepTrough}
            setCreepTrough={setCreepTrough}
            isEdit={false}
            PastoSelecionado={PastoSelecionado}
            PastosPrime={PastosPrime}
            IdRetiro={RetiroSelecionado}
            IdFazenda={FazendaSelecionada}
            errosPasto={errosPasto}
            errosCocho={errosCocho}
            Retiros={Retiros}
            Forragens={Forragens}
            cadastroPastoModificado={cadastroPastoModificado}
            Lotes={Lotes}
            AreaNovoPasto={PastoSelecionado.AreaNovoPasto}
            CoordenadasNovosPastos={CoordenadasNovosPastos}
            Toggles={Toggles}
            onBack={handlePastureBack}
          />
        )}
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  PastoSelecionado: state.pasto.PastoSelecionado,
  errosPasto: state.pasto.erros,
  errosCocho: state.cocho.erros,
  Cocho: state.cocho.Cocho,
  Retiros: state.retiro.Retiros,
  RetiroSelecionado: state.retiro.RetiroSelecionado,
  FazendaSelecionada: state.fazenda.FazendaSelecionada,
  cadastroPastoModificado: state.pasto.cadastroPastoModificado,
  Forragens: state.forragem.Forragens,
  Lotes: state.lote.Lotes,
  Toggles: state.fazenda.Toggles,
  PastosPrime: state.pastosPrime.PastosPrime,
  CoordenadasNovosPastos: state.pasto.CoordenadasNovosPastos,
});

const mapDispachToProps = {
  listaLotesPasto,
  listarPastosPrime,
  modificarCadastroPasto,
};
const styles = () => ({
  root: {
    height: "100%",
    width: "100%",
    padding: "32px 40px",
    display: "flex",
  },
});

export default compose(
  connect(mapStateToProps, mapDispachToProps),
  withStyles(styles)
)(NewFarmFormContainer);
