import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import styles from "styles/shared/LoadingStyle";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import * as actions from "redux/actions";

export class LoadingSalvando extends Component {
  fecharCarregando = () => {
    this.props.exibirCarregandoSalvando(false);
  };

  render() {
    const { classes, loading, balanco } = this.props;
    return (
      <div
        className={classes.backdrop}
        style={{
          display: loading.ExibirCarregandoSalvando ? "inherit" : "none",
        }}
      >
        <Paper
          className={classes.corpoCarregando}
          elevation={1}
          style={{ borderRadius: 16 }}
        >
          <IconButton
            color="primary"
            aria-label="Add"
            onClick={() => this.fecharCarregando()}
            className={classes.botaoFechar}
          >
            <ClearIcon />
          </IconButton>
          <img
            id="logoFarmTellAppBar"
            src="/images/logo-farmtell-views-color-2.png"
            alt="logo farmtell views"
            className={classes.logo}
          />
          {loading.valorBarraProgresso === 100 ? (
            <React.Fragment>
              {loading.sucesso ? (
                <div>
                  <div className={classes.iconeSucesso}>
                    <img
                      className={classes.imgParabens}
                      src="../images/parabens.svg"
                      alt="loading"
                    />
                  </div>
                  <Typography
                    variant="h6"
                    component="h6"
                    className={classes.corpoFormulario}
                  >
                    Parabéns!
                  </Typography>
                  <Typography
                    variant="p"
                    component="p"
                    className={classes.mensagem}
                  >
                    Fazenda cadastrada com sucesso!
                  </Typography>
                </div>
              ) : (
                <div>
                  <div className={classes.iconeSucesso}>
                    <img
                      className={classes.imgParabens}
                      src="../images/erro.svg"
                      alt="loading"
                    />
                  </div>
                  <Typography
                    variant="h6"
                    component="h6"
                    className={classes.corpoFormulario}
                  >
                    Ocorreu um imprevisto!
                  </Typography>
                  <Typography
                    variant="p"
                    component="p"
                    className={classes.mensagem}
                  >
                    Fazenda não pode ser cadastrada. Entre em contato com o
                    suporte!
                  </Typography>
                </div>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography variant="h6" component="h6">
                {balanco ? "Cadastrando..." : "Carregando..."}
              </Typography>
              <Typography component="p">{loading.textoCarregando}</Typography>
            </React.Fragment>
          )}
        </Paper>
      </div>
    );
  }
}
LoadingSalvando.propTypes = {
  loading: PropTypes.object.isRequired,
};
export default connect(null, actions)(withStyles(styles)(LoadingSalvando));
