import React from "react";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import parseEmails from "../../../lib/parseEmails";
import * as actions from "../../../redux/actions";
import { PASTOS_SEM_CADASTRO } from "../../../constants/retiro";
import ModalCustomizado from "features/shared/components/ModalCustomizado";
import { enviaDadosGAPorRotulo } from "../../../shared/utils.js";

export class ModalHistorico extends React.Component {
  state = {
    emails: "",
  };

  handleInputEmail = (event) => {
    this.setState({
      emails: event.target.value,
    });
  };

  handlerEmail = () => {
    return (
      <TextField
        required
        multiline
        id="email"
        label="Email"
        rowsMax="4"
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="Digite um ou mais e-mails"
        fullWidth
        margin="normal"
        value={this.state.emails}
        onChange={this.handleInputEmail}
      />
    );
  };

  callbackModal = async () => {
    let validaEmails = parseEmails(this.state.emails);

    if (validaEmails.valido) {
      if (this.props.retiroSelecionado === PASTOS_SEM_CADASTRO) {
        this.props.notifyError({
          mensagem: "Sem cadastro de pasto, erro no envio do e-mail",
          variant: "error",
        });
        await this.props.setFalhaEnvioEmail(404);
      } else {
        await this.props.setEmailsHistoricoFazenda(
          this.props.fazendaSelecionada,
          validaEmails.listaEmails
        );
      }

      if (this.props.statusEnvioEmail === 422) {
        this.props.notifyError({
          mensagem: "E-mail inválido",
          variant: "error",
        });
      } else {
        this.props.setModalHistorico(false);
        this.props.notifySuccess({
          mensagem: "Enviamos um e-mail com o seu histórico",
          variant: "success",
        });
        this.enviaDadosGeradorHistorico();
      }
    } else {
      this.props.notifyError({ mensagem: "E-mail inválido", variant: "error" });
    }
  };

  enviaDadosGeradorHistorico = () => {
    const acao = "Gerar Histórico";
    const categoria = "Análise";
    const rotulo = "Histórico Manejo";
    enviaDadosGAPorRotulo(acao, rotulo, categoria);
  };

  render() {
    return (
      <ModalCustomizado
        modalAberto={this.props.modalAberto}
        modalEmails={this.props.modalEmails}
        exibeBotaoFechar={true}
        imagem={"csv.svg"}
        titulo={"Criar histórico do manejo"}
        mensagem={
          <span>
            Adicione um ou mais e-mails para receber <br /> o histórico em um
            arquivo .csv
          </span>
        }
        personalizado={() => this.handlerEmail()}
        botaoCor={"primary"}
        botaoNome={"Enviar"}
        botaoCallback={() => this.callbackModal()}
        id="mdl_Customizado"
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modalAberto: state.modal.ModalHistoricoAberto,
    statusEnvioEmail: state.modal.ModalHistoricoStatusEnvioEmail,
    retiroSelecionado: state.retiro.RetiroSelecionado,
    fazendaSelecionada: state.fazenda.FazendaSelecionada,
  };
};
const mapDispatchToProps = {
  notifyError: actions.notifyError,
  notifySuccess: actions.notifySuccess,
  setModalHistorico: actions.setModalHistorico,
  setFalhaEnvioEmail: actions.setFalhaEnvioEmail,
  setEmailsHistoricoFazenda: actions.setEmailsHistoricoFazenda,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalHistorico);
