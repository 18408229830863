import {
  SET_CLIENTE,
  LISTA_USUARIOS_CLIENTES,
} from "../../../redux/actions/actionsConstants";
import ClienteService from "../../../services/ClienteService";
import { TratarErroAoObterDados } from "../../../redux/actions/funcoesAuxiliares/TratarErroAoObterDados";
import { getClienteStorage } from "shared/storageUtils";

export const setClienteSelecionado = (idCliente) => {
  return {
    type: SET_CLIENTE,
    payload: { IdCliente: idCliente },
  };
};

export const obterClientesAtivos = () => async (dispatch) => {
  try {
    const { data: userClients } = await ClienteService.listarClientesAtivos(
      localStorage.getItem("IdUsuario")
    );
    const idsClientes = (userClients || []).map((valor) => valor.IdCliente);
    const responseTodosClientes = await ClienteService.listarDadosClientes(
      idsClientes
    );

    const savedClientId = Number(getClienteStorage());
    let savedClient = null;
    if (userClients && userClients.length) {
      savedClient =
        userClients.find((client) => client.IdCliente === savedClientId) ||
        userClients[0];
    }

    dispatch({
      type: LISTA_USUARIOS_CLIENTES,
      payload: {
        ClienteSelecionado: savedClient,
        UsuarioClientes: userClients ? userClients : [],
        Clientes: responseTodosClientes ? responseTodosClientes : [],
      },
    });
  } catch (error) {
    TratarErroAoObterDados(error, dispatch);
  }
};
