import { Grid, withStyles } from "@material-ui/core";
import history from "features/app/History";
import PageTitle from "features/collect/supplementation/views/shared/pageTitle";
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import * as operations from "../redux/retiroOperations";
import CadastroRetiro from "./cadastroRetiro";

export const CadastroRetiroContainer = (props) => {
  const onGoBack = () => {
    history.replace("cadastro");
  };

  return (
    <Grid container className={props.classes.root}>
      <Grid item xs={12}>
        <PageTitle
          containerClass={props.classes.pageTitleContainer}
          titleClass={props.classes.pageTitle}
          title="Voltar"
          onGoBack={() => onGoBack()}
        />
      </Grid>
      <Grid item xs={12} className={props.classes.subtitle}>
        Cadastrar retiro
      </Grid>
      <Grid item xs={12}>
        <CadastroRetiro
          id="containerCadastroRetiro"
          criarRetiros={props.criarRetiros}
          listarRetiros={props.listarRetiros}
          deletarRetiro={props.deletarRetiro}
          fazendaSelecionada={props.idFazenda}
          listaRetiros={props.listaRetiros}
          atualizarRetiro={props.atualizarRetiro}
        />
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = {
  criarRetiros: operations.criarRetiros,
  listarRetiros: operations.listarRetiros,
  deletarRetiro: operations.deletarRetiro,
  atualizarRetiro: operations.atualizarRetiro,
};

const mapStateToProps = (state) => {
  const { fazenda, retiro } = state;
  return {
    listaRetiros: retiro.Retiros,
    idFazenda: fazenda.FazendaSelecionada,
  };
};

const style = () => ({
  root: {
    padding: "32px 40px",
  },
  pageTitleContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 0,
  },
  pageTitle: {
    margin: 0,
    fontSize: 18,
    fontWeight: "normal",
  },
  subtitle: {
    margin: "32px 0px 16px 0px",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    color: "#212121",
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(style)
)(CadastroRetiroContainer);
