import React, { PureComponent } from "react";
import { Grid, withStyles } from "@material-ui/core";
import FuncionalidadesModal from "./funcionalidadesModal";
import { InfoRounded } from "@material-ui/icons";
import * as _ from "lodash";
import SelectDropdown from "components/SelectDropdown";

export class FuncionalidadesDropdown extends PureComponent {
  state = {
    abrirModal: false,
    descricao: null,
  };

  opemModal() {
    const { itensFuncionalidade, funcionalidadeSelecionada } = this.props;
    this.setState({
      descricao: _.find(
        itensFuncionalidade,
        (funcionalidade) => funcionalidade.label === funcionalidadeSelecionada
      ),
    });
    this.setState({ abrirModal: true });
  }

  closeModal() {
    this.setState({ abrirModal: false });
  }

  render() {
    const {
      handleChangeFuncionalidade,
      itensFuncionalidade,
      funcionalidadeSelecionada,
      classes,
    } = this.props;
    const { descricao } = this.state;

    const funcionalidadesOptions = itensFuncionalidade.map((funcionalidade) => {
      return {
        value: funcionalidade.label,
        label: funcionalidade.label,
      };
    });

    return (
      <React.Fragment>
        <Grid container alignItems="center">
          <Grid item xs={3} className={classes.selectFuncionalidades}>
            <SelectDropdown
              id="select-funcionalidade"
              label={{ text: "Filtrar por funcionalidades" }}
              options={funcionalidadesOptions}
              value={funcionalidadeSelecionada}
              onChange={handleChangeFuncionalidade}
            />
          </Grid>
          {funcionalidadeSelecionada === "Todas" ||
          funcionalidadeSelecionada === "Balanço de arroba" ? null : (
            <Grid item xs={2} className={classes.infoFuncionalidades}>
              <InfoRounded
                id="iconeInformacao"
                style={{ fontSize: 24 }}
                color="primary"
                cursor="pointer"
                onClick={() => this.opemModal()}
              />
            </Grid>
          )}
        </Grid>
        <FuncionalidadesModal
          id="modalFuncionalidades"
          exibirModal={this.state.abrirModal}
          handleClose={() => this.closeModal()}
          descricao={descricao ? descricao.descricao : null}
          titulo={funcionalidadeSelecionada}
        />
      </React.Fragment>
    );
  }
}

const styles = (theme) => {
  return {
    AppBar: {
      backgroundColor: "#f8f8f8",
    },
    TextoLinkAppBar: {
      cursor: "pointer",
    },
    TextoAppBar: {
      marginLeft: "5px",
    },
    selectFuncionalidades: {
      paddingTop: "16px",
      marginLeft: "30px",
    },
    infoFuncionalidades: {
      marginLeft: "12px",
      marginTop: "30px",
    },
    textField: {
      width: "100%",
      label: {
        transform: "translate(0, 1.5px) scale(0.95)",
        "transform-origin": "top left",
      },
    },
  };
};

export default withStyles(styles)(FuncionalidadesDropdown);
