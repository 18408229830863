import React from 'react';
import { withStyles } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

export const TableauRelatorioPaper = props => {
  const { classes, title, descricao, cliqueCallback } = props;

  return (
    <div>
      <ButtonBase key={title} disableRipple={true} style={{ textAlign: "left" }} >
        <Paper className={classes.rootPaper} id={`card_${title}`} onClick={cliqueCallback}>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.descricao}>{descricao}</Typography>
        </Paper>
      </ButtonBase>
    </div>
  );
};

const styles = () => ({
  rootPaper: {
    width: '280px',
    height: '160px',
    overflow: 'hidden',
    backgroundColor: '#fafafa',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.65)',
    marginBottom: '30px'
  },
  title: {
    marginTop: '16px',
    marginLeft: '16px',
    marginBottom: '8px',
    width: '80%',
    height: '21px',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.03px',
    color: '#2a2a2a',
    position: 'absolute'
  },
  descricao: {
    marginTop: '60px',
    marginLeft: '16px',
    marginBottom: '8px',
    width: '80%',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.03px',
    color: '#2a2a2a',
    position: 'relative'
  },
  botaoEllipsis: {
    marginTop: '10px',
    marginLeft: '-32px',
    width: '24px',
    height: '24px',
    color: '#2a2a2a',
    position: 'absolute'
  },
  imagem: {
    marginLeft: '91px',
    marginTop: '63px',
    position: 'absolute'
  }
});

export default withStyles(styles)(TableauRelatorioPaper);
