import { Edit, MoreVert, Password, Send } from "@mui/icons-material";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";

export const UserActionsMenu = ({ user, onCopyLink, onSendLink, onEdit }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton data-testid="btn-menu" onClick={handleClick}>
        <MoreVert />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{ sx: { maxWidth: 250 } }}
      >
        <MenuItem
          disablePadding
          sx={{ minHeight: 48 }}
          onClick={() => onEdit(user)}
          data-testid="editar-btn"
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText primary="Editar" />
        </MenuItem>
        <MenuItem
          disablePadding
          sx={{ minHeight: 48 }}
          onClick={() => onCopyLink(user)}
        >
          <ListItemIcon>
            <Password />
          </ListItemIcon>
          <ListItemText
            sx={{ textWrap: "wrap" }}
            primary="Gerar e copiar link de criação de senha"
          />
        </MenuItem>
        <MenuItem
          disablePadding
          sx={{ minHeight: 48 }}
          onClick={() => onSendLink(user)}
        >
          <ListItemIcon>
            <Send />
          </ListItemIcon>
          <ListItemText
            sx={{ textWrap: "wrap" }}
            primary="Enviar link de criação de senha, por e-mail"
          />
        </MenuItem>
      </Menu>
    </>
  );
};
