import GanhoPesoService from './ganhoPesoService';
export class GanhoPesoManager {
  async obterCurvasGanhoPeso(fazendaSelecionada) {
    const respostaService = await GanhoPesoService.obterCurvasPorFazenda(fazendaSelecionada);
    let curvas = respostaService.data;
    return curvas;
  }
  async excluirCurva(idCurvaGanhoPeso) {
    await GanhoPesoService.deletarCurva(idCurvaGanhoPeso);
  }

  async salvarCurva(curva) {
    const respostaService = await GanhoPesoService.adicionarCurva(curva);
    return respostaService.data;
  }
  async editarCurva(curva, idCurvaGanhoPeso) {
    await GanhoPesoService.editarCurva(curva, idCurvaGanhoPeso);
  }
}

export default new GanhoPesoManager();
