export const amountTroughBagsFormat = (
  amountTroughBags,
  formulaFractionable
) => {
  if (!amountTroughBags) return "Não abastecido";

  const amountTroughBagsString = amountTroughBags.toString().replace(".", ",");

  if (formulaFractionable) {
    return `${amountTroughBagsString} kg`;
  }

  if (amountTroughBags > 1) {
    return `${amountTroughBagsString} sacos`;
  } else {
    return `${amountTroughBagsString} saco`;
  }
};

export const troughTypeFormat = (trough) => {
  if (trough.creepCollection) {
    return "Creep";
  } else if (trough.sharedTrough) {
    return "Compartilhado";
  } else {
    return "Padrão";
  }
};

export const pastureNamesFormat = (value) => {
  return value && typeof value.join === "function" ? value.join(" / ") : "-";
};

export const formulaNameFormat = (value) => {
  if (!value) return "Não informada";

  return value;
};
