/* GLOBAL */
export const SET_APP = "SET_APP";

/* FAZENDA */
export const LISTA_FAZENDAS = "LISTA_FAZENDAS";
export const SET_FAZENDA_ROOT = "SET_FAZENDA_ROOT";
export const SET_FAZENDA = "SET_FAZENDA";
export const SET_COORDENADAS_CENTRO = "SET_COORDENADAS_CENTRO";
export const BUSCA_PASTOS_POR_ID_FAZENDA = "BUSCA_PASTOS_POR_ID_FAZENDA";
export const CHANGE_CENTER = "CHANGE_CENTER";
export const EXCLUIR_FAZENDA = "EXCLUIR_FAZENDA";
export const LIMPAR_NOVA_FAZENDA = "LIMPAR_NOVA_FAZENDA";
export const TEM_COCHO_CREEP = "TEM_COCHO_CREEP";

/* NOTIFICACAO */
export const NOTIFICAR = "NOTIFICAR";
export const FECHAR_NOTIFICACAO = "FECHAR_NOTIFICACAO";

/* PASTO */
export const CARREGAR_COORDENADAS_PASTOS = "CARREGAR_COORDENADAS_PASTOS";
export const CRIAR_PASTO = "CRIAR_PASTO";
export const ERRO_SALVAR_PASTO = "ERRO_SALVAR_PASTO";
export const ERRO_VALIDAR_PASTO = "ERRO_VALIDAR_PASTO";
export const LISTA_PASTOS = "LISTA_PASTOS";
export const LISTA_CURRAIS = "LISTA_CURRAIS";
export const SALVAR_PASTO = "SALVAR_PASTO";
export const SELECIONAR_PASTO = "SELECIONAR_PASTO";
export const DESELECIONAR_PASTO = "DESELECIONAR_PASTO";
export const FOCAR_PASTO = "FOCAR_PASTO";
export const DESFOCAR_PASTO = "DESFOCAR_PASTO";
export const CADASTRO_PASTO_MODIFICADO = "CADASTRO_PASTO_MODIFICADO";
export const ERRO_EXCLUIR_PASTO = "ERRO_EXCLUIR_PASTO";
export const SALVAR_COORDENADAS_PASTO = "SALVAR_COORDENADAS_PASTO";
export const DESENHAR_MAPA = "DESENHAR_MAPA";
export const LIMPAR_MAPA = "LIMPAR_MAPA";
export const LIMPAR_COORDENADAS = "LIMPAR_COORDENADAS";
export const ATUALIZAR_LISTA_COORDENADAS_PASTO =
  "ATUALIZAR_LISTA_COORDENADAS_PASTO";
export const EDITANDO_COORD_PASTO = "EDITANDO_COORD_PASTO";
export const EDITANDO_COORD_E_AREA_PASTO = "EDITANDO_COORD_E_AREA_PASTO";
export const EDITANDO = "EDITANDO";
export const LIMPAR_BALANCOS = "LIMPAR_BALANCOS";
export const GET_PASTURES_QUANTITY = "GET_PASTURES_QUANTITY";

/* PASTO PRIME */

export const LISTA_PASTOS_PRIME = "LISTA_PASTOS_PRIME";
export const ERRO_VALIDAR_PASTO_PRIME = "ERRO_VALIDAR_PASTO_PRIME";

/* COCHO */
export const SALVAR_COCHO = "SALVAR_COCHO";
export const ERRO_VALIDAR_COCHO = "ERRO_VALIDAR_COCHO";
export const SALVAR_COCHO_COMPARTILHADO = "SALVAR_COCHO_COMPARTILHADO";
export const SELECIONA_COCHO_COMPARTILHADO = "SELECIONA_COCHO_COMPARTILHADO";
export const DESELECIONA_COCHO_COMPARTILHADO =
  "DESELECIONA_COCHO_COMPARTILHADO";
export const EXCLUI_COCHO_COMPARTILHADO = "COCHO/EXCLUI_COCHO";
export const NOVO_COCHO = "COCHO/NOVO_COCHO";
export const LIMPAR_NOVO_COCHO = "COCHO/LIMPAR_NOVO_COCHO";

/* FORRAGEM */
export const SALVAR_FORRAGEM = "SALVAR_FORRAGEM";
export const ERRO_VALIDAR_FORRAGEM = "ERRO_VALIDAR_FORRAGEM";
export const OBTER_FORRAGEM_FAZENDA = "OBTER_FORRAGEM_FAZENDA";
export const OBTER_TODAS_FORRAGENS = "OBTER_TODAS_FORRAGENS";

/* REBANHO */
export const SALVAR_REBANHO = "SALVAR_REBANHO";
export const ERRO_VALIDAR_REBANHO = "ERRO_VALIDAR_REBANHO";

/* CARGA-SUPORTE */
export const SALVAR_DATA = "SALVAR_DATA";

/* RETIRO */
export const LISTA_RETIROS = "LISTA_RETIROS";
export const SET_RETIRO = "SET_RETIRO";
export const SET_COR_RETIRO = "SET_COR_RETIRO";
export const CRIAR_RETIROS = "CRIAR_RETIROS";
export const ATUALIZA_RETIRO = "ATUALIZA_RETIRO";
export const DELETA_RETIRO = "DELETA_RETIRO";

/* LOADING */
export const SET_LOADING = "SET_LOADING";
export const EXIBIR_CARREGANDO = "EXIBIR_CARREGANDO";
export const ALTERAR_TEXTO = "ALTERAR_TEXTO";
export const EXIBIR_CARREGANDO_SALVANDO = "EXIBIR_CARREGANDO_SALVANDO";
export const EXIBIR_SUCESSO = "EXIBIR_SUCESSO";

/* FAROL */
export const SELECIONA_FAROL = "SELECIONA_FAROL";
export const LISTA_FAROL = "LISTA_FAROL";
export const SELECIONA_TIPOCOLETA = "SELECIONA_TIPOCOLETA";
export const SELECIONA_TIPOFAROL = "SELECIONA_TIPOFAROL";
export const ALTERA_ZOOM_MAPA_FAROL = "ALTERA_ZOOM_MAPA_FAROL";
export const EXIBIR_DETALHES = "EXIBIR_DETALHES";
export const ALETERA_PERIODO_COLETA_FAROL = "ALETERA_PERIODO_COLETA_FAROL";
export const EXIBIR_ICONE_MAPA_FAROL = "EXIBIR_ICONE_MAPA_FAROL";
export const RESET_PERIODO_COLERA_FAROL = "RESET_PERIODO_COLERA_FAROL";

/* MODAL */
export const SELECIONA_MODAL_HISTORICO = "SELECIONA_MODAL_HISTORICO";
export const SELECIONA_MODAL_LOGOUT = "SELECIONA_MODAL_LOGOUT";
export const ENVIA_EMAIL_HISTORICO = "ENVIA_EMAIL_HISTORICO";
export const SELECIONA_MODAL_TABLEAU = "SELECIONA_MODAL_TABLEAU";

/* KML */
export const CADASTRANDO_KML = "CADASTRANDO_KML";
export const LIMPAR_COORDENADAS_PASTOS_KML = "LIMPAR_COORDENADAS_PASTOS_KML";

/* MAPA */
export const DESABILITA_ZOOM = "DESABILITA_ZOOM";
export const ALTERA_ESTADO_MAPA = "ALTERA_ESTADO_MAPA";
export const ALTERA_VALOR_ZOOM = "ALTERA_VALOR_ZOOM";
export const VISUALIZAR_PONTOS_COLETAS = "VISUALIZAR_PONTOS_COLETAS";
export const ALTERA_TIPO_MAPA = "ALTERA_TIPO_MAPA";

/* FORMULA */
export const LISTA_FORMULAS = "LISTA_FORMULAS";
export const EXCLUIR_FORMULA = "EXCLUIR_FORMULA";
export const DESEXCLUIR_FORMULA = "DESEXCLUIR_FORMULA";
export const EPOCAS_FORMULA = "EPOCAS_FORMULA";
export const CATEGORIAS_FORMULA = "CATEGORIAS_FORMULA";

/* CLIENTE */
export const LISTA_USUARIOS_CLIENTES = "LISTA_USUARIOS_CLIENTES";
export const SET_CLIENTE = "SET_CLIENTE";

/* CATEGORIA */
export const LISTA_CATEGORIAS = "LISTA_CATEGORIAS";
export const SALVAR_CATEGORIA = "SALVAR_CATEGORIA";
export const EXCLUIR_CATEGORIA = "EXCLUIR_CATEGORIA";

/* LOTE */
export const LISTA_LOTES = "LISTA_LOTES";
export const LISTA_LOTES_POR_PASTO = "LISTA_LOTES_POR_PASTO";
export const SALVAR_CURRAL = "SALVAR_CURRAL";
export const EDITAR_CURRAL = "EDITAR_CURRAL";
export const EXCLUIR_CURRAL = "EXCLUIR_CURRAL";
export const PROJECAO_PESO = "PROJECAO_PESO";
export const BUSCA_ENTREVERO = "BUSCA_ENTREVERO";
export const CURVA_GANHO_PESO = "CURVA_GANHO_PESO";

/* OBJETIVO */
export const LISTA_OBJETIVOS = "LISTA_OBJETIVOS";

/* USUARIO */
export const DADOS_USUARIO = "DADOS_USUARIO";

/* MODULO */
export const INICIALIZAR = "MODULO/INICIALIZAR";

/* FILTER */
export const SET_FILTER = "SET_FILTER";
export const CLEAR_FILTER = "CLEAR_FILTER";
