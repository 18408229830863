import axios from "axios";

const AUTH_SERVICE_URL = `${process.env.REACT_APP_ENDPOINT}usuarios/api/v1/auth`

export function login(username, password) {
  if (!username || !password) {
    return;
  }

  return axios.post(`${AUTH_SERVICE_URL}/login`, {
    username,
    password
  })
}

export function resetPassword(email) {
  if (!email) {
    return;
  }

  return axios.post(`${AUTH_SERVICE_URL}/reset-password`, {
    email
  })
}

export function verifyPasswordToken(token) {
  if (!token) {
    return;
  }

  return axios.get(`${AUTH_SERVICE_URL}/password-token`, {
    params: {
      token
    }
  })
}

export function changePassword(token, password) {
  if (!password || !token) {
    return;
  }

  return axios.post(`${AUTH_SERVICE_URL}/change-password`, {
    token,
    password
  })
}
