import React, { Component } from "react";
import { connect } from "react-redux";
import { default as MapaVinculoComponent } from "../MapaVinculo";

export class MapaVinculo extends Component {
  render() {
    return (
      <MapaVinculoComponent
        id="containerMapaVinculo"
        {...this.props.reduxProps}
        zoomPadrao={14}
      />
    );
  }
}

const mapStateToProps = ({ pasto, fazenda, modulo, cocho, cliente }) => {
  return {
    reduxProps: {
      pastos: pasto.Pastos,
      selectedClientId:
        cliente &&
        cliente.ClienteSelecionado &&
        cliente.ClienteSelecionado.IdCliente,
      coordenadasCentro: fazenda.centroMapa,
      fazendaSelecionada: fazenda.FazendaSelecionada,
      pastosSelecionados: modulo.PastosSelecionados,
      retiroSelecionado: modulo.RetiroSelecionado,
      zoomMapa: modulo.ZoomMapa,
      corPasto: modulo.CorPasto,
      corPastoSelecionado: modulo.CorPastoSelecionado,
      modulos: modulo.Modulos,
      Cor: modulo.Cor,
      poligonoMundo: modulo.PoligonoMundo,
      moduloSelecionado: modulo.IdModulo,
      moduloMarcado: modulo.ModuloMarcado,
      cochoSelecionado: cocho.CochoSelecionado,
      corCocho: cocho.Cor,
    },
  };
};

export default connect(mapStateToProps)(MapaVinculo);
