/*
  Mapeia o nome das categorias presentes com base no Id.
*/
const mapeiaCategoriasNasPesagens = (categorias, pesagens) =>
  pesagens.reduce(
    (pesagensComCategoria, pesagemAtual) => [
      ...pesagensComCategoria,
      {
        ...pesagemAtual,
        Nome: categorias.find(
          (categoria) =>
            categoria.IdCategoriaAnimal === pesagemAtual.IdCategoriaAnimal
        ).Nome,
      },
    ],
    []
  );

/*
Mapeia Pesagens que tiveram edição no Peso Pesado
de acordo com a pesagem obtida no carregamento da tela.
*/
const mapeiaEdicoes = (pesagensEditadas, pesagensIniciais) =>
  pesagensEditadas.filter((pesagem, index) => {
    const pesagemSemEdicao = pesagensIniciais[index];
    if (
      pesagem.CategoriaEventoPesagems.some(
        (categoria, i) =>
          Number(pesagemSemEdicao.CategoriaEventoPesagems[i].PesoPesado) !==
          Number(categoria.PesoPesado)
      )
    ) {
      return pesagem;
    }
    return false;
  });



export { mapeiaCategoriasNasPesagens, mapeiaEdicoes };
