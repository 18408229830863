
export const tiposColetaAltura = {
  Todos: "Todos",
  Entrada: "Entrada",
  Afericao: "Afericao",
  Saida: "Saida",
};

export const idsTiposColetaAltura = {
  [tiposColetaAltura.Entrada]: 1,
  [tiposColetaAltura.Afericao]: 2,
  [tiposColetaAltura.Saida]: 3,
};

export const opcoesFarolAltura = [
  { key: "Todos", value: tiposColetaAltura.Todos },
  { key: "Entrada", value: tiposColetaAltura.Entrada },
  { key: "Aferição", value: tiposColetaAltura.Afericao },
  { key: "Saída", value: tiposColetaAltura.Saida },
];
