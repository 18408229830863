import React, { Component } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import ItemFotoMorte from './itemFotoMorte';
export class AlbumFotoMortePage extends Component {
  obterQuantidadeMortesNoPasto = listaColetaMorte => {
    let quantidadeAnimal = listaColetaMorte.Valor.Quantidade;
    return quantidadeAnimal <= 1 ? quantidadeAnimal + ' Morte' : quantidadeAnimal + ' Mortes';
  };

  render() {
    const { farois, pastoSelecionado, classes } = this.props;
    const farolMorte = farois.find(farol => farol.Nome === 'Mortes');
    const mortePastoSelecionado = farolMorte.Pasto.find(
      pasto => pasto.IdPasto === pastoSelecionado.IdPasto
    );
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            align="left"
            style={{ marginLeft: '3.5%', fontWeight: 'bold', marginTop: '20px' }}
            id="tituloQuantidadeAnimais"
          >
            {this.obterQuantidadeMortesNoPasto(mortePastoSelecionado)}
          </Typography>
        </Grid>

        {mortePastoSelecionado.Valor.Coletas.map(coleta => (
          <Grid item xs={6} className={classes.containerItemFoto}>
            <ItemFotoMorte
              id="itemFotoMorte"
              nomePasto={pastoSelecionado.Nome}
              categoria={coleta.Categoria}
              coleta={coleta}
              coletasPastoSelecionado={this.adicionaRefColetasPastoSelecionado(mortePastoSelecionado.Valor.Coletas)}
              idColetaFoto={coleta.IdColetaFoto}
              listaIdFotosPasto={this.getListaIdFotosPasto(mortePastoSelecionado)}
            />
          </Grid>
        ))}

      </Grid>
    );
  }

  adicionaRefColetasPastoSelecionado(coletasPastoSelecionado) {
    const teste = coletasPastoSelecionado;
    teste.forEach(coleta => {
      coleta.ref = React.createRef();
    });
    return teste;
  }

  getListaIdFotosPasto(mortePastoSelecionado) {
    return mortePastoSelecionado.Valor.Coletas.map(coleta => (coleta.IdColetaFoto))
  }
}

const styles = {
  containerItemFoto: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '24px'
  }
}

export default withStyles(styles)(AlbumFotoMortePage);
