import React from 'react';
import { ListItem, Grid, Button, withStyles } from '@material-ui/core';
export const botaoExibirDetalhesFarol = props => {
  const { classes, abrirDetalhes, exibirBotao, desabilitaBotao } = props;
  return !exibirBotao ? null : (
    <ListItem key="BotaoDetalhes">
      <Grid container>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Button
            disabled={desabilitaBotao}
            fullWidth
            variant="outlined"
            color="primary"
            onClick={abrirDetalhes}
            className={classes.buttonDetalhes}
          >
            DETALHES
          </Button>
        </Grid>
      </Grid>
    </ListItem>
  );
};

const styles = {
  buttonDetalhes: {
    borderRadius: '18px',
    height: '36px'
  }
};
const botaoExibirDetalhesFarolMemoized = React.memo(botaoExibirDetalhesFarol, (prevProps, nextProps) => {
  const exibirBotaoIguais = prevProps.exibirBotao === nextProps.exibirBotao;
  const desabilitaBotaoIguais = prevProps.desabilitaBotao === nextProps.desabilitaBotao;
  return exibirBotaoIguais && desabilitaBotaoIguais;
});
export default withStyles(styles)(botaoExibirDetalhesFarolMemoized);
