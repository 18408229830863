import moment from 'moment';
import * as historicoMovimentacaoActions from './historicoMovimentacaoActions';

export const INITIAL_STATE = {
  historico: {
    items: [],
    total: 0,
    limit: 0,
    offset: 0
  },
  historicoSemPaginacao: {
    items: [],
  },
  filtrosHistoricoMovimentacao: [],
  estadoModal: false,
  filtrosSelecionados: {
    DataInicio: moment().utc().subtract(7, "days").startOf("day"),
    DataFinal: moment().utc().endOf("day"),
    Usuario: undefined,
    TipoOperacao: undefined,
    IdCategoriaOrigem: undefined,
    IdCategoriaDestino: undefined,
    IdPastoOrigem: undefined,
    IdPastoDestino: undefined,
    IdRetiro: undefined
  }
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case historicoMovimentacaoActions.LISTA_HISTORICO_MOVIMENTACAO:
      return {
        ...state, historico: action.payload.historicoMovimentacao
      };
    case historicoMovimentacaoActions.LISTA_FILTROS_HITORICO_MOVIMENTACAO:
      return {
        ...state, filtrosHistoricoMovimentacao: action.payload.filtrosHistoricoMovimentacao
      };
    case historicoMovimentacaoActions.LISTA_FILTROS_SELECIONADO:
      return {
        ...state, filtrosSelecionados: action.payload.filtrosSelecionados
      };
    case historicoMovimentacaoActions.ALTERAR_ESTADO_MODAL:
      return {
        ...state, estadoModal: action.payload.exibir
      };
    case historicoMovimentacaoActions.LISTA_HISTORICO_MOVIMENTACAO_SEM_PAGINACAO:
      return {
        ...state, historicoSemPaginacao: action.payload.historicoMovimentacaoSemPaginacao
      };
    default:
      return state;
  }
};
