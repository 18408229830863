import BaseService from "../../services/BaseService";

const URL_BASE = `${process.env.REACT_APP_ENDPOINT}consumo/api/v1/formulas`;
export const URL_BASE_NOVA = `${process.env.REACT_APP_ENDPOINT}consumo/api/v1/formulas/listar`;

class FormulaService extends BaseService {
  async listarFormulasPorFazenda(idFazenda, booleanPegarFormulasDeletadas) {
    const parametrosURL = this.montaFiltro({
      where: {
        IdFazenda: idFazenda,
      },
      paranoid: !booleanPegarFormulasDeletadas,
    });
    const urlServico = this.montaURL(URL_BASE_NOVA, parametrosURL);
    return await this.get(urlServico);
  }

  async buscarFormulacaoAtivaPorNome(idFazenda, nome) {
    const parametrosURL = this.montaFiltro({
      where: {
        IdFazenda: idFazenda,
        Nome: nome,
      },
      paranoid: true,
    });
    const urlServico = this.montaURL(URL_BASE_NOVA, parametrosURL);
    const {
      data: { body },
    } = await this.get(urlServico);
    return body;
  }

  async buscarFormulacaoPorId(idFazenda, idFormula) {
    const parametrosURL = this.montaFiltro({
      where: {
        IdFazenda: idFazenda,
        IdFormula: idFormula,
      },
      paranoid: true,
    });
    const urlServico = this.montaURL(URL_BASE_NOVA, parametrosURL);
    const {
      data: { body },
    } = await this.get(urlServico);
    return body.length ? body[0] : null;
  }

  async buscarFormulacaoPorIdIngrediente(idIngrediente) {
    const response = await this.get(`${URL_BASE}/ingrediente/${idIngrediente}`);
    return response.data;
  }

  async listarEpocas() {
    return await this.get(
      `${process.env.REACT_APP_ENDPOINT}consumo/api/v1/formula-epoca`
    );
  }

  async listarCategoriaFormula() {
    return await this.get(
      `${process.env.REACT_APP_ENDPOINT}consumo/api/v1/formula-categoria`
    );
  }

  async adicionarFormula(formula) {
    return await this.post(URL_BASE, formula);
  }

  async atualizarFormula(idFormula, formula) {
    return await this.patch(`${URL_BASE}/${idFormula}`, formula);
  }

  async desexcluirFormula(idFormula) {
    return await this.get(`${URL_BASE}/desexcluir/${idFormula}`);
  }

  async excluirFormula(idFormula) {
    return await this.delete(`${URL_BASE}/${idFormula}`);
  }
}

export default new FormulaService();
