/**
 * Gera uma lista de opções para uso em componentes de seleção.
 * @param {Array} items - Lista de itens para gerar opções.
 * @param {string|number|null} excludeId - ID do item a ser excluído (opcional).
 * @param {string} nameKey - Chave para o nome do item (padrão: "Nome").
 * @param {string} idKey - Chave para o ID do item (padrão: "IdCategoriaAnimal").
 * @returns {Array} Lista de opções no formato { label, value }.
 */
export const generateSelectOptions = (
  items,
  excludeId = null,
  nameKey = "Nome",
  idKey = "IdCategoriaAnimal"
) => {
  return items
    .filter((item) => item[idKey] !== excludeId)
    .map((item) => ({
      label: item[nameKey],
      value: item[idKey],
    }));
};
