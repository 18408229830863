import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as actions from "redux/actions";
import ForragemService from "services/ForragemService";
import csvtojson from "csvtojson";
import { Typography, withStyles } from "@material-ui/core";
import { ButtonWithLabel, UploadButtonWithLabel } from "./ButtonWithLabel";
import ConfirmationModal from "features/shared/components/confirmationModal";

class ForageOptions extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      csvTratado: null,
      idFazenda: null,
    };
  }

  setOpen = (status) => {
    this.setState({ open: status });
  };

  handleOpen = () => {
    this.setOpen(true);
  };

  handleParamentros = (idaFazenda = null, csv = null) => {
    this.setState({ idFazenda: idaFazenda, csvTratado: csv });
  };

  handleClose = () => {
    this.setOpen(false);
    this.handleParamentros();
  };

  // pega o csv trata e valida se tem o compra IdCicloForragemFazenda está fazio
  handleSalvaCicloForragemCsv = async (...parametros) => {
    const [csv, IdFazenda] = parametros;
    const csvTratado = await this.converteCsv(csv);
    let continua = true;
    this.handleParamentros(IdFazenda, csvTratado);
    csvTratado.forEach((linha) => {
      if (!linha.IdCicloForragemFazenda) {
        continua = false;
        this.handleOpen();
      }
    });
    if (continua) {
      this.salvarForragemCsv();
    }
  };
  // salva e esvazia o state da pagina
  salvarForragemCsv = async () => {
    try {
      await ForragemService.salvaCiclosForragemCsv(
        this.state.csvTratado,
        this.state.idFazenda
      );
      this.handleClose();
      this.props.notifySuccess({
        mensagem: "Ciclos de Forragem atualizados com sucesso",
      });
    } catch (error) {
      this.handleClose();
      const mensagemErro = error.message
        ? error.message
        : "Ocorreu um erro ao salvar o CSV de Ciclos de Forragens";
      this.props.notifyError({
        mensagem: mensagemErro,
      });
    }
  };

  converteCsv = async (csv) => {
    const csvConverterJson = csv.replace(/ï»¿/g, "");
    const objetoCsv = await csvtojson({ delimiter: "\t" }).fromString(
      csvConverterJson.replace(/;/g, "\t")
    );
    return objetoCsv;
  };

  onDrop = (files) => {
    const { fazendaSelecionada } = this.props;

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () =>
        this.handleSalvaCicloForragemCsv(reader.result, fazendaSelecionada);
      reader.onerror = () =>
        this.props.notifyError({
          mensagem: "Houve um erro com o aquivo KML",
          variant: "error",
        });
      reader.readAsBinaryString(file);
    });
  };

  validaInputArquivo = (accepted, rejected) => {
    if (accepted.length > 1) {
      this.props.notifyInfo({
        mensagem: "Somente 1 arquivo pode ser importado por vez",
      });
    } else if (accepted.length) {
      this.onDrop(accepted);
    } else if (rejected.length) {
      this.props.notifyInfo({
        mensagem: `Somente arquivos CSV são suportados`,
      });
    }
  };

  showError = () => {
    this.props.notifyError({
      mensagem: "Ocorreu um erro ao realizar o Download do arquivo",
    });
  };

  listaForragensERealizaDownload = async () => {
    ForragemService.listaForragensERealizaDownloadCsv().catch(() => {
      this.showError();
    });
  };

  listaCiclosForragemERealizaDownload = () => {
    const { fazendaSelecionada } = this.props;
    ForragemService.listaCiclosForragensPorIdFazenda(fazendaSelecionada).catch(
      () => {
        this.showError();
      }
    );
  };

  formatModalText() {
    const { classes } = this.props;
    return (
      <>
        <Typography className={classes.modalText}>
          Foi identificado que existem linha(s) sem a coluna
          "IdCicloForragemFazenda" preenchida no csv, ao enviar esses dados
          assim, caso já haja informações cadastradas para essas forragens
          poderá acarretar na duplicação dos dados.
        </Typography>
        <Typography className={classes.modalText}>
          <b>
            Caso esteja somente atualizando dados, recomendamos exportar
            novamente o csv e editar somente os campos desejados.
          </b>
        </Typography>
      </>
    );
  }

  render() {
    const { classes } = this.props;
    const { open } = this.state;
    return (
      <>
        <div data-testid="containerCadastroForragem">
          <div className={classes.containerInfo}>
            <Typography variant="subtitle2" className={classes.subtitle}>
              Selecione abaixo a opção que deseja.
            </Typography>
          </div>
          <ButtonWithLabel
            labelText="Exporta o CSV de todas as forragens cadastradas"
            buttonText="Exportar CSV"
            onClick={this.listaForragensERealizaDownload}
          />
          <ButtonWithLabel
            labelText="Exporta o CSV com período de ciclo das forragens cadastradas"
            buttonText="Exportar CSV"
            onClick={this.listaCiclosForragemERealizaDownload}
          />
          <UploadButtonWithLabel
            labelText="Importar CSV para cadastro/edição do ciclo de forragem"
            buttonText="Importar CSV"
            allowedExtensions=".csv"
            uploadType="uploadCsv"
            onInputFileOpen={() => {}}
            onDrop={this.validaInputArquivo}
            disabled={this.props.disable}
          />
        </div>
        {open && (
          <ConfirmationModal
            title="Observação"
            text={this.formatModalText()}
            submitButtonTitle="Confirmar"
            cancelButtonTitle="Cancelar"
            onSubmit={this.salvarForragemCsv}
            onCancel={this.handleClose}
          />
        )}
      </>
    );
  }
}

ForageOptions.propTypes = {
  classes: PropTypes.object.isRequired,
  fazendaSelecionada: PropTypes.number,
};

const mapDispatchToProps = {
  notifyInfo: actions.notifyInfo,
  notifyError: actions.notifyError,
  notifySuccess: actions.notifySuccess,
};

function mapStateToProps({ fazenda }) {
  return {
    fazendaSelecionada: fazenda.FazendaSelecionada,
  };
}

const styles = {
  modal: {
    zIndex: 3000,
  },
  modalText: {
    fontSize: "16px",
    fontFamily: "Roboto",
  },
  containerInfo: {
    margin: "32px 0px 16px 0px",
  },
  subtitle: {
    fontSize: "16px",
    fontFamily: "Roboto",
    letterSpacing: "normal",
  },
  dialogConfirm: {
    color: "#333333",
  },
  divider: {
    margin: "32px 0px 16px 0px",
  },
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ForageOptions));
