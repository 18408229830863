import { useState, useEffect } from "react";
import { PERMISSSIONS } from "../userService";
import { debounce } from "features/shared/utils";
import retiroService from "features/retiro/retiroService";
import FazendaService from "services/FazendaService";

const STATUS_LIST = [
  {
    label: "Todos",
    value: 0,
  },
  {
    label: "Ativo",
    value: 1,
  },
  {
    label: "Inativo",
    value: 2,
  },
];
const PERMISSSIONS_LIST = PERMISSSIONS.map((p, i) => ({
  label: p.name,
  value: p.id,
}));

export const useFilter = (clientId, filter = {}, onFilterChange = () => { }) => {
  const [search, setSearch] = useState({
    value: filter.busca || "",
    onChange: (e) => onChangeSearch(e),
  });
  const [status, setStatus] = useState({
    list: STATUS_LIST,
    selected: filter.status || 0,
    onChange: (e) => onChangeStatus(e),
  });
  const [farms, setFarms] = useState({
    list: [],
    selected: filter.idFazenda || [],
    onChange: (e) => onChangeFarm(e),
    isLoading: true,
  });
  const [retires, setRetires] = useState({
    list: [],
    selected: filter.idRetiro || [],
    onChange: (e) => onChangeRetire(e),
    isLoading: true,
  });
  const [permissions, setPermissions] = useState({
    list: PERMISSSIONS_LIST,
    selected: filter.permissao || [],
    onChange: (e) => onChangePermission(e),
  });

  const loadRetires = async () => {
    const response = await retiroService.listaRetirosPorIdFazenda(
      farms.list.map((f) => f.value)
    );
    return response.data;
  };

  const loadFarms = async () => {
    const response = await FazendaService.listarTodasrFazendasCliente(clientId);
    return response.data;
  };

  useEffect(() => {
    loadFarms().then((farmsList) => {
      const list = farmsList.map((f) => ({
        label: f.Nome,
        value: f.IdFazenda,
      }));
      setFarms({ ...farms, list, isLoading: false });
    });
  }, [clientId]);

  useEffect(() => {
    if (farms.list.length > 0) {
      loadRetires().then((retiresList) => {
        const list = retiresList.map((r) => ({
          label: r.Nome,
          value: r.IdRetiro,
        }));
        setRetires({ ...retires, list, isLoading: false });
      });
    }
  }, [farms.list]);

  useEffect(() => {
    debounce(
      () =>
        onFilterChange({
          busca: search.value,
          status: status.selected === 0 ? null : status.selected === 1,
          idFazenda: farms.selected,
          idRetiro: retires.selected,
          permissao: permissions.selected
        }),
      500
    );
  }, [
    search.value,
    status.selected,
    farms.selected,
    retires.selected,
    permissions.selected,
  ]);

  const onChangeStatus = (selected) => {
    setStatus((prev) => ({ ...prev, selected }));
  };
  const onChangeFarm = (selected) => {
    setFarms((prev) => ({ ...prev, selected }));
  };
  const onChangeRetire = (selected) => {
    setRetires((prev) => ({ ...prev, selected }));
  };
  const onChangePermission = (selected) => {
    setPermissions((prev) => ({ ...prev, selected }));
  };
  const onChangeSearch = (value) => {
    setSearch((prev) => ({ ...prev, value }));
  };

  return { search, status, farms, retires, permissions };
};
