import React from "react";
import Typography from "@material-ui/core/Typography";
export const TableauLoading = (props) => {
  const { classes } = props;
  return props.opacity === 0 ? (
    <div className={classes.divGif}>
      <div className={classes.divImg}>
        <img
          className={classes.gif}
          src="../images/logo-farmtell-views-color-2.png"
          alt="loading"
        />
      </div>
      <div>
        <Typography variant="h6" id="modal-title" className={classes.gifText}>
          {"Carregando..."}
        </Typography>
      </div>
    </div>
  ) : null;
};
