import React from "react";
import { tableStyles } from "./tableStyle";
import { withStyles } from "@material-ui/core";
import { Box } from "@mui/material";

function TableDisclimer({ text, icon, iconColor, classes }) {
  const iconStyle = iconColor
    ? {
        "& svg": {
          fill: iconColor,
        },
      }
    : {};
  return (
    <Box className={classes.tableDisclaimer} sx={iconStyle}>
      {icon} {text}
    </Box>
  );
}

const style = (themes) => ({
  ...tableStyles(themes),
});

export default withStyles(style)(TableDisclimer);
