import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";

import SupplementationService from "../../supplementationService";
import SupplementationListPage from "./supplementationListPage";
import * as actions from "redux/actions";

import history from "../../../../app/History";

const SupplementationListContainer = ({
  farmId,
  loading,
  notifyError,
  filter,
  setFilter,
  location,
}) => {
  const isDeletedSupplementationsPage = location.pathname.includes("excluidas");
  const [isLoading, setIsLoading] = useState(true);

  const [formulaList, setFormulaList] = React.useState({
    activeFormulas: [],
    disabledFormulas: [],
  });
  const [formulaIsLoading, setFormulaIsLoading] = React.useState(false);
  const [pastureList, setPastureList] = React.useState([]);

  const [pastureIsLoading, setPastureIsLoading] = React.useState(false);

  const [
    { formulaSelected, pastureSelected, userSelected, beginDate, endDate },
    setFilterSupplementation,
  ] = useState({
    formulaSelected: [],
    pastureSelected: [],
    userSelected: [],
    beginDate: null,
    endDate: null,
  });

  const [userList, setUserList] = React.useState([]);

  const [userIsLoading, setUserIsLoading] = React.useState(false);

  const claimFilterFromRedux = () => {
    if (isDeletedSupplementationsPage && filter.excludedSupplementationFilter) {
      const { excludedSupplementationFilter } = filter;
      const filterWithoutExcludedSupplementationFilter = filter;
      delete filterWithoutExcludedSupplementationFilter.excludedSupplementationFilter;

      setFilter({ ...filterWithoutExcludedSupplementationFilter });
      setFilterSupplementation({ ...excludedSupplementationFilter });
      return { ...excludedSupplementationFilter };
    }

    if (!isDeletedSupplementationsPage && filter.supplementationFilter) {
      const { supplementationFilter } = filter;
      const filterWithoutSupplementationFilter = filter;
      delete filterWithoutSupplementationFilter.supplementationFilter;
      setFilter({ ...filterWithoutSupplementationFilter });
      setFilterSupplementation({ ...supplementationFilter });
      return {
        ...supplementationFilter,
      };
    }

    return null;
  };

  const saveFilterInRedux = () => {
    if (isDeletedSupplementationsPage) {
      setFilter({
        ...filter,
        excludedSupplementationFilter: {
          formulaSelected,
          pastureSelected,
          userSelected,
          beginDate,
          endDate,
        },
      });
    } else {
      setFilter({
        ...filter,
        supplementationFilter: {
          formulaSelected,
          pastureSelected,
          userSelected,
          beginDate,
          endDate,
        },
      });
    }
  };

  const goToSupplementationEdit = (supplementationId) => {
    saveFilterInRedux();
    history.push(`/coletas/suplementacao/${supplementationId}/edicao`);
  };

  const goBack = () => {
    history.push("/coletas/suplementacao");
  };

  const goToViewSupplementation = (supplementationId) => {
    saveFilterInRedux();
    history.push(`/coletas/suplementacao/${supplementationId}`);
  };

  const goToDeletedSupplementationsPage = () => {
    saveFilterInRedux();
    history.push("/coletas/suplementacao/excluidas");
  };

  const onChangeFormula = (selected) => {
    setFilterSupplementation((prevState) => ({
      ...prevState,
      formulaSelected: selected,
    }));
  };

  const onChangePasture = (selected) => {
    setFilterSupplementation((prevState) => ({
      ...prevState,
      pastureSelected: selected,
    }));
  };

  const onChangeUser = (selected) => {
    setFilterSupplementation((prevState) => ({
      ...prevState,
      userSelected: selected,
    }));
  };

  const onChangeBeginDate = (date) => {
    setFilterSupplementation((prevState) => ({
      ...prevState,
      beginDate: date,
    }));
  };

  const onChangeEndDate = (date) => {
    setFilterSupplementation((prevState) => ({
      ...prevState,
      endDate: date,
    }));
  };

  const supplementationFilter = {
    formula: {
      list: formulaList,
      selected: formulaSelected,
      isLoading: formulaIsLoading,
      onChange: onChangeFormula,
    },
    pasture: {
      list: pastureList,
      selected: pastureSelected,
      isLoading: pastureIsLoading,
      onChange: onChangePasture,
    },
    user: {
      list: userList,
      selected: userSelected,
      isLoading: userIsLoading,
      onChange: onChangeUser,
    },
    beginDate: {
      value: beginDate,
      onChange: onChangeBeginDate,
      isLoading: isLoading,
    },
    endDate: {
      value: endDate,
      onChange: onChangeEndDate,
      isLoading: isLoading,
    },
  };

  const getSupplementations = useCallback(
    async (params) => {
      if (!farmId) return;
      const previousFilter = claimFilterFromRedux();

      const requestFilters = {
        formulaSelected: formulaSelected,
        pastureSelected: pastureSelected,
        userSelected: userSelected,
        beginDate: beginDate,
        endDate: endDate,
      };

      if (previousFilter) {
        requestFilters.formulaSelected = previousFilter.formulaSelected;
        requestFilters.pastureSelected = previousFilter.pastureSelected;
        requestFilters.userSelected = previousFilter.userSelected;
        requestFilters.beginDate = previousFilter.beginDate;
        requestFilters.endDate = previousFilter.endDate;
      }

      const page = params.offset / params.limit + 1;
      const size = params.limit;

      try {
        let supplementations;

        if (isDeletedSupplementationsPage) {
          supplementations =
            await SupplementationService.getDeletedSupplementations(
              page,
              size,
              farmId,
              requestFilters.formulaSelected,
              requestFilters.pastureSelected,
              requestFilters.userSelected,
              moment(requestFilters.beginDate).startOf("day").toISOString(),
              moment(requestFilters.endDate).endOf("day").toISOString(),
              true
            );
        } else {
          supplementations = await SupplementationService.getSupplementations(
            page,
            size,
            farmId,
            requestFilters.formulaSelected,
            requestFilters.pastureSelected,
            requestFilters.userSelected,
            moment(requestFilters.beginDate).startOf("day").toISOString(),
            moment(requestFilters.endDate).endOf("day").toISOString()
          );
        }

        return {
          total: supplementations.total,
          itens: supplementations.data,
        };
      } catch (err) {
        console.error(err);
        notifyError({
          mensagem: "Ocorreu um erro ao buscar as coletas de suplementação.",
          variant: "error",
        });
        return {
          total: 0,
          itens: [],
        };
      } finally {
        setIsLoading(false);
      }
    },
    [
      farmId,
      formulaSelected,
      pastureSelected,
      userSelected,
      beginDate,
      endDate,
      isDeletedSupplementationsPage,
    ]
  );

  const getFormulas = useCallback(async () => {
    if (!farmId) return;

    try {
      const formulas = await SupplementationService.getFormulas(
        farmId,
        isDeletedSupplementationsPage
      ).then((response) =>
        response
          .map((formula) => ({
            label: formula.name,
            value: formula.id,
            deleted: formula.deleted,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      );

      const activeFormulas = formulas
        .filter((formula) => !formula.deleted)
        .map((formula) => ({ ...formula, group: "Ativas" }));
      const disabledFormulas = formulas
        .filter((formula) => formula.deleted)
        .map((formula) => ({
          ...formula,
          group: "Inativas",
        }));

      return {
        activeFormulas,
        disabledFormulas,
      };
    } catch (err) {
      console.error(err);
      notifyError({
        mensagem: "Ocorreu um erro ao listar as formulações.",
        variant: "error",
      });
    }
  }, [farmId]);

  const getPastures = useCallback(async () => {
    if (!farmId) return;

    try {
      return SupplementationService.getPastures(
        farmId,
        isDeletedSupplementationsPage
      ).then((response) =>
        response.map((item) => ({ label: item.name, value: item.id }))
      );
    } catch (err) {
      console.error(err);
      notifyError({
        mensagem: "Ocorreu um erro ao listar os pastos.",
        variant: "error",
      });
    }
  }, [farmId]);

  const getUsers = useCallback(async () => {
    if (!farmId) return;

    try {
      return SupplementationService.getUsers(
        farmId,
        isDeletedSupplementationsPage
      ).then((response) =>
        response.map((item) => ({ label: item, value: item }))
      );
    } catch (err) {
      console.error(err);
      notifyError({
        mensagem: "Ocorreu um erro ao listar os usuários responsáveis.",
        variant: "error",
      });
    }
  }, [farmId]);

  const getFiltersData = async () => {
    setFormulaIsLoading(true);
    setPastureIsLoading(true);
    setUserIsLoading(true);

    try {
      await Promise.all([
        getFormulas()
          .then(setFormulaList)
          .finally(() => setFormulaIsLoading(false)),
        getPastures()
          .then(setPastureList)
          .finally(() => setPastureIsLoading(false)),
        getUsers()
          .then(setUserList)
          .finally(() => setUserIsLoading(false)),
      ]);
    } catch (err) {
      console.error(err);
    }
  };

  const deleteSupplementation = async (supplementationId) => {
    try {
      loading(true);
      return await SupplementationService.deleteSupplementation(
        supplementationId
      );
    } catch (err) {
      console.error(err);
      notifyError("Ocorreu um erro ao deletar a coleta.");
    } finally {
      loading(false);
    }
  };

  useEffect(() => {
    getFiltersData();
  }, [farmId]);

  return (
    <SupplementationListPage
      filter={supplementationFilter}
      getSupplementations={getSupplementations}
      deleteSupplementation={deleteSupplementation}
      isDeletedSupplementationsPage={isDeletedSupplementationsPage}
      goToSupplementationEdit={goToSupplementationEdit}
      goToDeletedSupplementationsPage={goToDeletedSupplementationsPage}
      goBack={goBack}
      goToViewSupplementation={goToViewSupplementation}
    />
  );
};

const mapStateToProps = ({ fazenda, filter }) => ({
  farmId: fazenda.FazendaSelecionada,
  filter,
});

export const mapDispatchToProps = {
  loading: actions.exibirCarregando,
  notifyError: actions.notifyError,
  setFilter: actions.setFilter,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplementationListContainer);
