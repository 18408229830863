import { notificacaoActions } from '../../notificacao/redux';
import HistoricoChuvaManager from '../historicoChuvaManager';
export const MSG = {
  SUCESSO_SALVAR: 'Upload concluído com sucesso',
  SUCESSO_OBTER: 'Download concluído com sucesso',
  ERRO_OBTER_CSV_CHUVA: 'Não foi possivel exportar csv de historico chuva',
  ERRO_INSERIR_CSV_CHUVA: 'Não foi possivel importar csv de historico chuva'
};
export const obtemHistoricoChuva = (IdFazendaSelecionada, IdRetiroSelecionado) => async (
  dispatch,
  getState
) => {
  try {
    await HistoricoChuvaManager.getHistoricoChuva(IdFazendaSelecionada, IdRetiroSelecionado);
    dispatch(notificacaoActions.notificaSucesso(MSG.SUCESSO_OBTER));
  } catch (error) {
    console.log(error);
    dispatch(notificacaoActions.notificaErro(MSG.ERRO_OBTER_CSV_CHUVA));
  }
};

export const atualizaHistoricoChuva = (
  IdFazendaSelecionada,
  IdRetiroSelecionado,
  arquivoCsv
) => async (dispatch, getState) => {
  try {
    await HistoricoChuvaManager.setHistoricoChuva(
      IdFazendaSelecionada,
      IdRetiroSelecionado,
      arquivoCsv
    );
    dispatch(notificacaoActions.notificaSucesso(MSG.SUCESSO_SALVAR));
  } catch (error) {
    console.log(error);
    dispatch(notificacaoActions.notificaErro(MSG.ERRO_INSERIR_CSV_CHUVA));
  }
};
