import { DADOS_USUARIO } from "./actionsConstants";
import UsuarioService from "../../services/UsuarioService";
import { TratarErroAoObterDados } from "./funcoesAuxiliares/TratarErroAoObterDados";

export const obterDadosUsuario = () => async (dispatch) => {
  try {
    const response = await UsuarioService.BuscarDadosUsuarioPorId(
      localStorage.getItem("IdUsuario")
    );

    dispatch({
      type: DADOS_USUARIO,
      payload: {
        Usuario: response.data ? response.data : null,
      },
    });
  } catch (error) {
    TratarErroAoObterDados(error, dispatch);
  }
};
