import { getFazendaSelecionadaStorage } from "shared/storageUtils";
import BaseService from "./BaseService";

const URL_BASE = `${process.env.REACT_APP_ENDPOINT}metrics/`;
class MetricsService extends BaseService {
  sendEvent(data) {
    try {
      this._validate(data);

      const enrichedData = {
        ...data,
        eventDate: new Date(),
        userId: Number(localStorage.getItem("IdUsuario")),
        user: localStorage.getItem("Login"),
        userEmail: localStorage.getItem("Email"),
        farmId: Number(getFazendaSelecionadaStorage()),
      }

      return this.post(URL_BASE, enrichedData);
    } catch (error) {
      console.error(error);
    }
  }

  _validate(data) {
    if (!data) {
      throw new Error("Metrics Service - Data is required");
    }

    if (!data.event) {
      throw new Error("Metrics Service - Event type is required");
    }
  }
}

export const EventType = {
  VISUALIZA_COLETA_SAL: "VISUALIZA_COLETA_SAL",
  EDITA_COLETA_SAL: "EDITA_COLETA_SAL",
  EXCLUI_COLETA_SAL: "EXCLUI_COLETA_SAL",
  ACESSO_FECHAMENTO_CONSUMO: "ACESSO_FECHAMENTO_CONSUMO",
  SOLICITA_FECHAMENTO_CONSUMO: "SOLICITA_FECHAMENTO_CONSUMO"
}

export default new MetricsService();
