import React from "react";
import { withStyles } from "@material-ui/core";
import TabelaProdap from "features/shared/components/TabelaProdap";

const PlanningRegisterTable = ({
  classes,
  renderTable,
  planningsTableList,
  columns,
  fixLastColumn = true,
  tableClass,
  noPaper = false,
}) => {
  return renderTable ? (
    <div
      className={`${tableClass || classes.wrapperTable} ${
        fixLastColumn && classes.fixLastColumn
      }`}
      data-testid="Plannings-Register-Table"
    >
      <TabelaProdap
        idCabecalhoTabela={"planningsTableHead"}
        colunas={columns}
        ativarPaginacao={false}
        linhas={planningsTableList}
        tabelaEsquerda={true}
        noPaper={noPaper}
      />
    </div>
  ) : null;
};

const styles = () => ({
  fixLastColumn: {
    "& td + td:last-child": {
      position: "sticky",
      minWidth: "104px !important",
      right: 0,
      zIndex: 0,
      boxShadow: "inset 4px 0 4px -2px rgba(0, 0, 0, 0.2)",
    },
    "& thead tr th:last-child": {
      padding: "0  !important",
      textAlign: "center",
      position: "sticky",
      minWidth: "104px !important",
      right: 0,
      top: 0,
      zIndex: 200,
      boxShadow: "inset 4px 0 4px -2px rgba(0, 0, 0, 0.2)",
    },
  },
  wrapperTable: {
    marginTop: "40px",
    "& tr:hover": {
      backgroundColor: "white !important",
    },
    "& thead th": {
      padding: "0 8px  !important",
      position: "sticky",
      zIndex: 100,
      top: 0,
      backgroundColor: "#e1e1e1",
    },
    "& td": {
      padding: "11px 8px  !important",
      backgroundColor: "white",
      zIndex: 1,
      minWidth: 152,
    },
    "& td:not(:first-child):not(:last-child) ": {
      verticalAlign: "top",
    },
    "& td:first-child": {
      padding: "0 !important",
      position: "sticky",
      minWidth: "185px !important",
      left: 0,
      zIndex: 200,
      boxShadow: "inset -4px 0 4px -2px rgba(0, 0, 0, 0.2)",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
    "& thead tr th:first-child": {
      padding: "0 16px  !important",
      position: "sticky",
      minWidth: "185px !important",
      left: 0,
      top: 0,
      zIndex: 250,
      boxShadow: "inset -4px 0 4px -2px rgba(0, 0, 0, 0.2)",
    },
  },
});

export default withStyles(styles)(PlanningRegisterTable);
