import React, { useEffect } from "react";
import { connect } from "react-redux";
import ModalCustomizado from "features/shared/components/ModalCustomizado";
import { obterDadosAvisoConsultoria } from "../avisoConsultoriaUtil";
import {
  setPrimeiraVisualizacao,
  obterPrimeiraVisualizacao,
  toggleAvisoConsultoria,
} from "../avisoConsultoriaOperation";

export const AvisoConsultoriaContainer = (props) => {
  const {
    setPrimeiraVisualizacao,
    obterPrimeiraVisualizacao,
    toggleAvisoConsultoria,
  } = props;
  const { visibilidadeAviso, primeiraVisualizacao } = props.reduxProps;
  const { titulo, imagem, texto, textoBotao } = obterDadosAvisoConsultoria();
  useEffect(() => obterPrimeiraVisualizacao(), []);

  return (
    <ModalCustomizado
      id="ModalAvisoConsultoria"
      modalAberto={primeiraVisualizacao || visibilidadeAviso}
      imagem={imagem}
      titulo={titulo}
      mensagem={texto}
      botaoNome={textoBotao}
      proporcoes={{ height: "321px", width: "490px" }}
      alturaImagem={{ height: 35 }}
      botaoCallback={() => {
        if (primeiraVisualizacao) setPrimeiraVisualizacao(false);
        else toggleAvisoConsultoria();
      }}
    />
  );
};

const mapDispatchToProps = {
  setPrimeiraVisualizacao,
  obterPrimeiraVisualizacao,
  toggleAvisoConsultoria,
};

const mapStateToProps = ({ avisoConsultoria }) => {
  return {
    reduxProps: {
      visibilidadeAviso: avisoConsultoria.visibilidadeAviso,
      primeiraVisualizacao: avisoConsultoria.primeiraVisualizacao,
    },
  };
};

const validacaoMemo = (prevProps, nextProps) => {
  return (
    prevProps.reduxProps.primeiraVisualizacao ===
      nextProps.reduxProps.primeiraVisualizacao &&
    prevProps.reduxProps.visibilidadeAviso ===
      nextProps.reduxProps.visibilidadeAviso
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(AvisoConsultoriaContainer, validacaoMemo));
