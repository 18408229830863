import { useState, useEffect, useCallback } from "react";
import { calcPeriodMonthTitles } from "shared/utils";
import localConstants from "../views/shared/constants";
import dayjs from "dayjs";

const initialPlanningPlanData = {
  initialDate: null,
  finalDate: null,
};

export const usePlanningPlanDataForm = () => {
  const [planningPlanData, setPlanningPlanData] = useState(
    initialPlanningPlanData
  );
  const [periodTitles, setPeriodTitles] = useState([]);

  const calcPeriodTitles = useCallback(() => {
    const titles = calcPeriodMonthTitles(
      planningPlanData.initialDate,
      localConstants.dateMonthFormat
    );
    setPeriodTitles(titles);
  }, [planningPlanData.initialDate]);

  const onChangePlanningInitialDate = (initialDate) => {
    setPlanningPlanData((prev) => ({ ...prev, initialDate }));
  };

  const changeFinalDate = (finalDate) => {
    setPlanningPlanData((prev) => ({ ...prev, finalDate }));
  };

  useEffect(() => {
    if (planningPlanData.initialDate) {
      const finalDateClone = dayjs(planningPlanData.initialDate)
        .add(11, "M")
        .toISOString();
      changeFinalDate(finalDateClone);
      calcPeriodTitles();
    } else {
      changeFinalDate(null);
      setPeriodTitles([]);
    }
  }, [planningPlanData.initialDate]);

  return {
    planningPlanData,
    periodTitles,
    onChangePlanningInitialDate,
    setPlanningPlanData,
  };
};
