import * as ajusteEscoreActions from './ajusteEscoreActions';

export const INITIAL_STATE = {
  ajustesEscoreFazenda: null
};

export default (state = INITIAL_STATE, action) => {
  if (action.type === ajusteEscoreActions.LISTAR_AJUSTES_ESCORE) {
    return { ...state, ajustesEscoreFazenda: action.payload.ajustesEscoreFazenda };
  } else {
    return state;
  }
};
