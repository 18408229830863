import TableauReportService from './tableauReportService';
export class TableauReportManager {
  async obterRelatorioManager(body) {
    let errormsg = 'Ocorreu um erro ao baixar Relatório';
    try {
      const respostaService = await TableauReportService.retornaRelatorios(body);
      return respostaService.data;
    } catch (error) {
      throw errormsg;
    }
  }
  async obterRelatoriosTableau() {
    let errormsg = 'Ocorreu um erro ao baixar Relatório';
    try {
      let respostaService = await TableauReportService.obterRelatorios();
      return respostaService.data;
    } catch (error) {
      throw errormsg;
    }
  }
}
export default new TableauReportManager();
