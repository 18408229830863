import React from "react";
import { withStyles } from "@material-ui/core";
import { tableStyles } from "../../shared/planningReport/tableStyle";

const PlanningTableViewCategoryItem = ({
  classes,
  total,
  categories,
  categoryId,
}) => {
  if (total) return <span className={classes.cellItemBold}>Total</span>;

  const category = categories.find(
    (category) =>
      category.IdCategoriaAnimal === categoryId || category.id === categoryId
  );
  return (
    <div className={classes.container}>
      <span>{category && (category.Nome || category.name)}</span>
    </div>
  );
};

const styles = (theme) => ({
  cellItemBold: tableStyles(theme).cellItemBold,
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginLeft: "16px",
  },
});

export default withStyles(styles)(PlanningTableViewCategoryItem);
