import { EXIBIR_CARREGANDO } from "../../../redux/actions/actionsConstants";
export const LISTA_RELATORIOS = "LISTA_RELATORIOS";
export const LISTA_PASTAS_USUARIO = "LISTA_PASTAS_USUARIO";

export const listarRelatorios = (relatorios) => {
  return {
    type: LISTA_RELATORIOS,
    payload: { Relatorios: relatorios },
  };
};

export const listarPastasUsuario = (pastas) => {
  return {
    type: LISTA_PASTAS_USUARIO,
    payload: { Pastas: pastas },
  };
};

export const exibirCarregando = (exibir) => {
  return {
    type: EXIBIR_CARREGANDO,
    payload: exibir,
  };
};
