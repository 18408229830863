import { NOTIFICAR } from "../redux/actions/actionsConstants";
export default function (
  dispatch,
  error,
  mensagemErroDiferenteResponse,
  dispatchType,
  mensagemDiferenteValidationError
) {
  if (!error.response) {
    dispatch({
      type: NOTIFICAR,
      payload: {
        mensagem: mensagemErroDiferenteResponse,
        variant: "error",
        estilo: "error",
      },
    });
    return;
  }
  let err = error.response && error.response.data && error.response.data.error;
  let validationErrors = {};
  if (err && err.name === "ValidationError") {
    for (var key in err.details.messages) {
      validationErrors[key] = err.details.messages[key][0];
    }
    dispatch({ type: dispatchType, payload: validationErrors });
  }
  dispatch({
    type: NOTIFICAR,
    payload: {
      mensagem: mensagemDiferenteValidationError,
      variant: "error",
      estilo: "error",
    },
  });
}
