import React from "react";
import { connect } from "react-redux";
import GoalsRegisterPage from "./goalPlanRegisterPage";
import { useSubmitUtils } from "features/goalPlan/hooks/useSubmitUtils";
import {
  disableLoading,
  enableLoading,
  notifyError as notifyErrorAction,
  notifySuccess as notifySuccessAction,
} from "../../redux/goalPlanOperations";

const GoalsRegisterContainer = ({
  farmId,
  onBack = () => {},
  enableLoading,
  disableLoading,
  notifySuccess,
  notifyError,
}) => {
  const [showConfirmationModal, { onCreatePlan }] = useSubmitUtils({
    disableLoading,
    enableLoading,
    notifyError,
    notifySuccess,
    farmId,
    callbackSubmit: onBack,
  });

  return (
    <GoalsRegisterPage
      onBack={onBack}
      farmId={farmId}
      onSubmit={onCreatePlan}
      showConfirmationModal={showConfirmationModal}
      enableLoading={enableLoading}
      disableLoading={disableLoading}
      notifyError={notifyError}
    />
  );
};

export const mapDispatchToProps = {
  notifySuccess: notifySuccessAction,
  enableLoading: enableLoading,
  disableLoading: disableLoading,
  notifyError: notifyErrorAction,
};

const mapStateToProps = ({ fazenda }) => {
  return {
    farmId: fazenda.FazendaSelecionada,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoalsRegisterContainer);
