export const checkIfItemIsActive = (values, isCreep) => {
  if (values.length === 0) return true;
  if (
    isCreep &&
    values.filter((item) => item.label !== "CREEP").length > 0
  ) {
    return false;
  }
  if (
    !isCreep &&
    values.filter((item) => item.label === "CREEP").length > 0
  ) {
    return false;
  }
  return true;
}
