import React, { useEffect, useState } from "react";
import { AppBar as AppBarCustomizado } from "../../../layout/index";
import { AppBarConteudoDefault } from "../../../layout";
import { FazendaSelecionadaContainer } from "../../../shared/crossCuttingComponents";
import { Button, Tooltip, Typography, withStyles } from "@material-ui/core";
import CadastroIngredienteWrapper from "../ingredientRegister/components/cadastroIngrendienteWrapper";
import FormCadastroFormulacao from "./components/formCadastroFormulacao";
import TabelaCadastroIngrediente from "../ingredientRegister/components/tabelaCadastroIngrediente";
import history from "../../../app/History";
import IngredientRegisterContainer from "../ingredientRegister/ingredientRegisterContainer";
import { useDebounce } from "hooks/useDebounce";
import formulaService from "features/formulacao/formulaService";
import WarningWithoutIngredients from "./components/warningWithoutIngredients";

const ID_CATEGORIA_CREEP = 4;

const renderConteudoCustomizadoAppBar = () => {
  return (
    <FazendaSelecionadaContainer
      render={(fazenda) => {
        const textoSecundarioAppBar = fazenda
          ? fazenda.NomeReduzido
          : "NENHUMA FAZENDA SELECIONADA";
        return <AppBarConteudoDefault tituloAppBar={textoSecundarioAppBar} />;
      }}
    />
  );
};

const AcoesIngredientes = (props) => {
  const { formulacaoValida, classes, salvaFormulacao, formulacao } = props;
  const tooltipMessage = formulacaoValida
    ? "Por favor, preencha todos os campos obrigatórios para salvar."
    : "";
  return (
    <div className={classes.linhaBotoes}>
      <Button
        className={classes.botaoCancelar}
        onClick={() => history.goBack()}
      >
        CANCELAR
      </Button>
      <Tooltip title={tooltipMessage} classes={{ tooltip: classes.tooltip }}>
        <div className={classes.tooltipWrapperContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={salvaFormulacao}
            className={classes.botaoSalvar}
            disabled={formulacaoValida}
          >
            {formulacao ? "ATUALIZAR" : "SALVAR FORMULAÇÃO"}
          </Button>
        </div>
      </Tooltip>
    </div>
  );
};

const CadastroFormulacaoPage = (props) => {
  const {
    ingredientes,
    exibirCreep,
    exibirSemiConfinamento,
    classes,
    fazendaSelecionada,
    obterTodosIngredientes,
    obterEpocasFormula,
    obterCategoriasFormula,
    criarFormula,
    atualizaFormula,
    location,
    categoriasRebanho,
    epocas,
    copyFormula,
    selecionaAba
  } = props;

  const { state } = location;

  const [formulacaoValida, setFormulacaoValida] = useState(true);
  const [ingredientesCadastrados, setIngredientesCadastrados] = useState([]);
  const [quantidadeTotal, setQuantidadeTotal] = useState(0);

  //mockado até integracao
  const [formulacao] = useState((state || { formula: null }).formula);

  const ingredientesParaMostrar = ingredientesCadastrados.filter(
    (ingrediente) => !ingrediente._destroy
  );
  const quantidadeCalculada = ingredientesParaMostrar.length > 0;

  // Inicia a nova formulacao, ja com dados previos
  const [novaFormulacao, setNovaFormulacao] = useState({
    IdFazenda: fazendaSelecionada,
    IdFormula: formulacao ? formulacao.IdFormula : null,
  });

  useEffect(() => {
    const carregaTudo = async () => {
      await Promise.all([
        obterTodosIngredientes(fazendaSelecionada),
        obterEpocasFormula(),
        obterCategoriasFormula(),
      ]);
    };
    carregaTudo();
    if (formulacao) {
      setFormulacaoValida(false);
      setQuantidadeTotal(formulacao.ConsumoDiario);
      setIngredientesCadastrados(
        formulacao.Ingrediente ? formulacao.Ingrediente : []
      );
    }
  }, []);

  // controla a quantidade total conforme o cadastro de ingredientes
  useEffect(() => {
    if (ingredientesCadastrados.length === 0) {
      if ((formulacao || {}).hasOwnProperty("Ingredientes"))
        setQuantidadeTotal(0);
    }
  }, [ingredientesCadastrados]);

  const [sameNameFormula, setSameNameFormula] = useState(null);
  const debouncedNomeFormula = useDebounce(novaFormulacao.Nome, 500);

  // busca formula ativa com o mesmo nome
  useEffect(() => {
    setSameNameFormula(null);
    if (!debouncedNomeFormula) return;

    if (!formulacao || debouncedNomeFormula !== formulacao.Nome) {
      formulaService
        .buscarFormulacaoAtivaPorNome(
          fazendaSelecionada,
          debouncedNomeFormula.trim()
        )
        .then((formulas) => {
          if (!(formulas && formulas.length)) return;

          const sameNameFormula = formulas.find(
            (item) =>
              item.Nome.trim().toUpperCase() ===
              debouncedNomeFormula.trim().toUpperCase()
          );

          const hasCurrentFormula =
            formulacao &&
            sameNameFormula &&
            sameNameFormula.IdFormula === formulacao.IdFormula;

          if (sameNameFormula && (!formulacao || !hasCurrentFormula))
            setSameNameFormula(sameNameFormula);
        });
    }
  }, [debouncedNomeFormula]);

  // salva a formulacao
  const salvaFormulacao = async () => {
    try {
      formulacao ? await updateFormula() : await createFormula();
      history.goBack();
    } catch (error) { }
  };

  const createIngredientsList = (ingredientesCadastrados) =>
    ingredientesCadastrados.map((ingrediente) => ({
      IdIngrediente: ingrediente.IdIngrediente,
      Quantidade: ingrediente.FormulaIngrediente.Quantidade,
      _create: ingrediente._create,
      _destroy: ingrediente._destroy,
    }));

  const prepareFormulaToUpdate = (
    novaFormulacao,
    ingredientes,
    fazendaSelecionada
  ) => {
    return {
      Nome: novaFormulacao.Nome,
      Granel: novaFormulacao.Granel ? true : false,
      Epocas: novaFormulacao.Epocas,
      RebanhosAlvos: novaFormulacao.RebanhosAlvo,
      ConsumoDiario: novaFormulacao.ConsumoDiario,
      PesoSaco: novaFormulacao.PesoSaco,
      Ingredientes: ingredientes,
      IdFazenda: fazendaSelecionada,
      FormulaCreep: novaFormulacao.RebanhosAlvo.includes(ID_CATEGORIA_CREEP)
        ? 1
        : 0,
    };
  };

  const updateFormula = async () => {
    const ingredientes = createIngredientsList(ingredientesCadastrados);
    const formulacaoTratadaUpdate = prepareFormulaToUpdate(
      novaFormulacao,
      ingredientes,
      fazendaSelecionada
    );
    return await atualizaFormula(formulacao.IdFormula, formulacaoTratadaUpdate);
  };

  const prepareFormulaToCreate = (
    novaFormulacao,
    ingredientes,
    fazendaSelecionada
  ) => ({
    Nome: novaFormulacao.Nome,
    Granel: novaFormulacao.Granel ? true : false,
    Epoca: novaFormulacao.Epocas,
    RebanhoAlvo: novaFormulacao.RebanhosAlvo,
    ConsumoDiario: novaFormulacao.ConsumoDiario,
    PesoSaco: novaFormulacao.PesoSaco,
    Ingredientes: ingredientes,
    IdFazenda: fazendaSelecionada,
    FormulaCreep: novaFormulacao.RebanhosAlvo.includes(ID_CATEGORIA_CREEP)
      ? 1
      : 0,
  });

  const createFormula = async () => {
    const ingredientes = createIngredientsList(ingredientesCadastrados);
    const formulacaoTratada = prepareFormulaToCreate(
      novaFormulacao,
      ingredientes,
      fazendaSelecionada
    );
    return await criarFormula(formulacaoTratada);
  };

  const createCopy = async ({
    formulaToCopy,
    ingredients,
    idFormula,
    fazendaSelecionada,
  }) => {
    const ingredientes = createIngredientsList(ingredients);
    const formulacaoTratada = prepareFormulaToCreate(
      formulaToCopy,
      ingredientes,
      fazendaSelecionada
    );
    return await copyFormula(idFormula, formulacaoTratada);
  };

  const createCopySameName = async () => {
    let copy;
    if (formulacao && formulacao.IdFormula) {
      copy = {
        formulaToCopy: {
          ...novaFormulacao,
          IdFormula: null,
          Nome: sameNameFormula.Nome,
        },
        ingredients: ingredientesCadastrados,
        idFormula: sameNameFormula.IdFormula,
        fazendaSelecionada: fazendaSelecionada,
      };
    } else {
      copy = {
        formulaToCopy: {
          ...sameNameFormula,
          RebanhosAlvo: sameNameFormula.Categoria.map((c) => c.IdCategoria),
          Epocas: sameNameFormula.Epocas.map((ep) => ep.IdEpoca),
          IdFormula: null,
        },
        ingredients: sameNameFormula.Ingrediente,
        idFormula: sameNameFormula.IdFormula,
        fazendaSelecionada: fazendaSelecionada,
      };
    }
    const newFormula = await createCopy(copy);
    if (newFormula) reloadPageNewFormula(newFormula);
  };

  const reloadPageNewFormula = (formula) => {
    const url = window.location.pathname;
    history.replace({
      pathname: url,
      state: { formula: formula },
    });
    history.go(0);
  };

  return (
    <div className={classes.page}>
      <AppBarCustomizado
        conteudoCustomizadoAppBar={renderConteudoCustomizadoAppBar()}
        id="appBarPersonalizado"
      />
      <div className={classes.formWrapper}>
        <Typography className={classes.titulo} gutterBottom>
          Cadastro de formulações
        </Typography>
        <FormCadastroFormulacao
          nameExistsError={Boolean(sameNameFormula)}
          sameNameFormula={sameNameFormula}
          clearNameExistsError={() => setSameNameFormula(null)}
          setFormulacaoValida={setFormulacaoValida}
          formulacao={formulacao}
          quantidadeTotal={quantidadeTotal}
          setQuantidadeTotal={setQuantidadeTotal}
          quantidadeCalculada={quantidadeCalculada}
          exibirCreep={exibirCreep}
          exibirSemiConfinamento={exibirSemiConfinamento}
          setNovaFormulacao={setNovaFormulacao}
          novaFormulacao={novaFormulacao}
          categoriasRebanho={categoriasRebanho}
          epocas={epocas}
          createCopySameName={createCopySameName}
        />
        <CadastroIngredienteWrapper>
          <IngredientRegisterContainer
            setFormulacaoValida={setFormulacaoValida}
            setIngredientesCadastrados={setIngredientesCadastrados}
            ingredientes={ingredientes}
            ingredientesCadastrados={ingredientesCadastrados}
            setNovaFormulacao={setNovaFormulacao}
          />
          <WarningWithoutIngredients ingredientes={ingredientes} selecionaAba={selecionaAba} />
          {ingredientesParaMostrar.length > 0 && (
            <TabelaCadastroIngrediente
              ingredientesCadastrados={ingredientesCadastrados}
              ingredientesParaMostrar={ingredientesParaMostrar}
              setIngredientesCadastrados={setIngredientesCadastrados}
              quantidadeTotal={quantidadeTotal}
              setQuantidadeTotal={setQuantidadeTotal}
            />
          )}
        </CadastroIngredienteWrapper>
        <AcoesIngredientes
          formulacaoValida={formulacaoValida}
          classes={classes}
          salvaFormulacao={salvaFormulacao}
          formulacao={formulacao}
        />
      </div>
    </div>
  );
};

const styles = () => {
  return {
    page: {
      backgroundColor: "white",
      minHeight: "100vh",
    },
    formWrapper: {
      width: "100%",
      padding: "0 42px",
    },
    titulo: {
      fontSize: "20px",
      fontWeight: "bold",
      fontStyle: "medium",
      fontStretch: "medium",
      lineHeight: "medium",
      letterSpacing: "medium",
      color: "#333333",
      marginTop: "40px",
    },
    linhaBotoes: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      marginTop: 40,
      height: 80,
    },
    botaoCancelar: {
      marginRight: 32,
      width: 124,
      height: 36,
      fontSize: 14,
    },
    botaoSalvar: {
      fontSize: 14,
      width: 196,
      boxShadow: "none",
      height: 36,
    },
    tooltipWrapperContainer: {
      alignSelf: "flex-start",
    },
    tooltip: {
      padding: "16px",
      borderRadius: "4px",
      fontFamily: "Roboto",
      fontSize: "14px",
    },
  };
};
export default withStyles(styles)(CadastroFormulacaoPage);
