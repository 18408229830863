import {
  montaURLParametros,
  montaURL,
  montaCamposParaRetornar,
} from "./funcoesAuxiliares";
import { createServiceHandler } from "./ServiceHandler";

class BaseService {
  montaURL(url, filtro) {
    return montaURL(url, `?${filtro}`);
  }
  montaURLParametros(...params) {
    return montaURLParametros(...params);
  }

  montaCampos(campos) {
    return montaCamposParaRetornar(campos);
  }

  montaFiltro(tempFilter) {
    const filtro = montaURLParametros({
      filter: JSON.stringify(tempFilter),
    });
    return filtro;
  }

  async get(url, params = {}) {
    return await createServiceHandler().get(url, { params });
  }

  async post(url, data) {
    return await createServiceHandler().post(url, data);
  }

  async put(url, data) {
    return await createServiceHandler().put(url, data);
  }

  async patch(url, data) {
    return await createServiceHandler().patch(url, data);
  }

  async delete(url) {
    return await createServiceHandler().delete(url);
  }
}

export default BaseService;
