import React from 'react';
import { Card, CardHeader, CardContent, Grid, Tooltip, withStyles } from '@material-ui/core';
import FechamentoConsumoGrafico from './fechamentoConsumoGrafico';
import FechamentoConsumoInfo from './fechamentoConsumoInfo';
import { getStyleConsumoOcorrido, FAIXA_ACEITE } from '../fechamentoConsumoUtils';
import { FakeContent, TextLabel } from '../../shared/components';
import { CONSUMOOCORRIDO, CONSUMOOBJETIVO } from '../fechamentoConsumoTextos';

export const fechamentoConsumoCard = props => {
  const { classes, consumoFormula, index, faixaAceite, dadosCarregados } = props;
  const { ConsumoOcorrido, ConsumoObjetivo } = consumoFormula;
  const { cardFormula, cardHeader, cardContent, fontBold, valorConsumo, tooltipStyle } = classes;
  const inlineStyleConsumoOcorrido = getStyleConsumoOcorrido(ConsumoOcorrido, ConsumoObjetivo, FAIXA_ACEITE);

  return (
    <Card className={cardFormula} key={`consumoFormula-${index}`}>
      <CardHeader
        id={`headerCardFechamento-${index}`}
        className={cardHeader}
        title={
          <FakeContent
            showContent={dadosCarregados}
            content={consumoFormula.NomeFormula}
            height={20}
            width="30%"
          />
        }
        titleTypographyProps={{
          variant: 'button',
          className: fontBold
        }}
        subheader={
          <FakeContent
            showContent={dadosCarregados}
            content={<FechamentoConsumoInfo consumoFormula={consumoFormula} />}
            height={16}
            width="70%"
          />
        }
      />
      <CardContent className={cardContent}>
        <Grid container direction="column" justify="space-between">
          <Tooltip className={tooltipStyle} title={"É a quantidade que cada cabeça comeu."} arrow>
            <Grid item>
              <FakeContent showContent={dadosCarregados} height={16} width="50%">
                <TextLabel color="default2" bold size={15} id={`labelConsumoOcorrido-${index}`}>
                  {CONSUMOOCORRIDO}
                </TextLabel>
                <TextLabel
                  id={`valorConsumoOcorrido-${index}`}
                  size={14}
                  bold
                  className={valorConsumo}
                  customStyle={inlineStyleConsumoOcorrido}
                >
                  {Number(ConsumoOcorrido).toFixed(1)}
                </TextLabel>
              </FakeContent>
            </Grid>
          </Tooltip>
          <Tooltip className={tooltipStyle} title={"É a quantidade ideal que cada cabeça deve consumir."}>
            <Grid item>
              <FakeContent showContent={dadosCarregados} height={16} width="70%">
                <TextLabel id={`labelConsumoObjetivo-${index}`} color="default2" size={14}>
                  {CONSUMOOBJETIVO}
                </TextLabel>
                <TextLabel
                  id={`valorConsumoObjetivo-${index}`}
                  size={14}
                  color="default2"
                  bold
                  className={valorConsumo}
                >
                  {Math.floor(ConsumoObjetivo)}
                </TextLabel>
              </FakeContent>
            </Grid>
          </Tooltip>

          <FakeContent
            showContent={dadosCarregados}
            height={110}
            width="100%"
            id={`fechamentoConsumoGrafico-${index}`}
          >
            <FechamentoConsumoGrafico faixaAceite={faixaAceite} consumoFormula={consumoFormula} />
          </FakeContent>
        </Grid>
      </CardContent>
    </Card>
  );
};

var styles = () => {
  const animationFade = {
    animationName: 'fadeInOpacity',
    animationIterationCount: 1,
    animationTimingFunction: 'ease-in'
  };
  return {
    tooltipStyle: {
      cursor: 'pointer'
    },
    cardFormula: {
      width: 320,
      minHeight: 270,
      margin: '1px 15px 15px 0',
      borderStyle: 'solid',
      borderWidth: '1px 1px 0 1px',
      borderColor: '#bbb',
      borderRadius: 2,
    },
    '@keyframes fadeInOpacity': {
      '0%': {
        opacity: 0
      },
      '100%': {
        opacity: 1
      }
    },
    cardHeader: {
      paddingBottom: 10,
      ...animationFade,
      animationDuration: '0.3s'
    },
    cardContent: {
      padding: '0 16px',
      margin: 0,
      ...animationFade,
      animationDuration: '0.5s',
    },
    fontBold: {
      fontWeight: 'bold'
    },
    valorConsumo: {
      paddingLeft: 2,
      display: 'inline'
    }
  };
};

export default React.memo(withStyles(styles)(fechamentoConsumoCard), (prevProps, nextProps) => {
  return prevProps.consumoFormula === nextProps.consumoFormula;
});
