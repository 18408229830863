import React from "react";
import ConfirmationModal from "features/shared/components/confirmationModal";
import history from "features/app/History";
import { WarningAmberOutlined } from "@mui/icons-material";
import { withStyles } from "@material-ui/core";

const PendencyMovementsModal = ({
  classes,
  onClose,
}) => {

  const navigateToPendencies = () => {
    history.push("/movimentacoesPendentes");
    onClose();
  }

  return (
    <ConfirmationModal
      title={
        <span
          className={classes.title}
        >
          <WarningAmberOutlined color="error" />
          Movimentações pendentes
        </span>
      }
      text={
        <span
        >
          Há movimentações pendentes de sincronização que precisam ser corrigidas. Isso ocorre porque outras sincronizações
          foram enviadas antes e moveram os animais de pasto. Para garantir a atualização contínua de seus dados,
          recomendamos realizar esses ajustes imediatamente.
        </span>
      }
      submitButtonTitle="Visualizar pendências"
      cancelButtonTitle="Fechar"
      onSubmit={navigateToPendencies}
      onCancel={onClose}
      onClose={onClose}
    />);
};

const styles = () => ({
  title: {
    display: "flex",
    gap: "10px"
  }
})

export default withStyles(styles)(PendencyMovementsModal);
