import React from "react";
import localConstants from "./constants";
import InputIngredientPrice from "./InputIngredientPrice";

export function planningIngredientsColumns({
  ingredientsPrices,
  onChangeIngredientPrice = () => () => {},
}) {
  return [
    {
      id: "ingredientesColumn",
      titulo: localConstants.titles.ingredients,
      ordenavel: false,
      estilo: {
        minWidth: "185px",
        width: "25%",
      },
      pegaValor: (linha) => linha.name,
    },
    {
      id: "ingredientPriceColumn",
      titulo: localConstants.titles.priceIngredient,
      ordenavel: false,
      infoMessage: localConstants.info.priceIngredient,
      pegaValor: (linha) => (
        <InputIngredientPrice
          value={ingredientsPrices ? ingredientsPrices[linha.id] : ""}
          onChange={onChangeIngredientPrice(linha.id)}
        />
      ),
    },
  ];
}
