export const CONTENT_TOOLTIP =
  "Atenção: esta operação demorou mais de 12 horas para ser sincronizada.";

export const VERIFICATION_MESSAGE =
  "As pendências foram resolvidas e já podem ser aplicadas. Para concluir e aplicar os ajustes realizados, clique no botão “Resolver e aplicar pendência”.";

export const LATE_SYNC_STYLE = {
  backgroundColor: "#FFF3D6",
  borderTopLeftRadius: "8px",
  borderBottomLeftRadius: "8px",
  padding: "4px",
};
