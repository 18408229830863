import React, { Component } from "react";
import { connect } from "react-redux";
import {
  listaFazendas,
  setLoading,
  setFazenda,
  obterClientesAtivos,
} from "redux/actions";
import SelectDropdown from "components/SelectDropdown";
import { setarClienteSelecionado } from "features/cliente/redux/clienteOperations";
import { getFazendaSelecionada } from "features/shared/utils";
import { getFazendaSelecionadaStorage } from "shared/storageUtils";

export class ClienteDropdown extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  selecionaCliente(IdCliente) {
    this.props.setLoading();
    this.props.listaFazendas(IdCliente);
    this.props.setarClienteSelecionado(IdCliente);
  }

  handleChange(newValue) {
    if (Number(newValue) !== Number(this.props.ClienteSelecionado.IdCliente)) {
      this.selecionaCliente(newValue);
    }
  }

  async componentDidMount() {
    await this.props.obterClientesAtivos(true);

    if (!this.props.ClienteSelecionado && getFazendaSelecionadaStorage()) {
      const fazenda = getFazendaSelecionada(
        JSON.parse(getFazendaSelecionadaStorage()),
        this.props.Fazendas
      );

      if (fazenda) {
        this.selecionaCliente(fazenda.IdCliente);
      } else if (this.props.UsuarioClientes.length > 0) {
        this.selecionaCliente(this.props.UsuarioClientes[0].IdCliente);
      } else {
        this.selecionaCliente(null);
      }
    } else if (
      !this.props.ClienteSelecionado &&
      this.props.UsuarioClientes.length > 0
    ) {
      this.selecionaCliente(this.props.UsuarioClientes[0].IdCliente);
    }
  }

  componentDidUpdate(prev) {
    const { UsuarioClientes, ClienteSelecionado } = this.props;
    if (
      !prev.UsuarioClientes.length &&
      UsuarioClientes &&
      UsuarioClientes.length &&
      ClienteSelecionado
    ) {
      this.selecionaCliente(ClienteSelecionado.IdCliente);
    }
  }

  render() {
    const { UsuarioClientes, Bloqueado } = this.props;
    const disabled = UsuarioClientes.length <= 1 || Bloqueado;
    const clienteSelecionadoId = this.props.ClienteSelecionado
      ? this.props.ClienteSelecionado.IdCliente
      : null;
    const listaClientes = UsuarioClientes.sort((a, b) => {
      a.NomeReduzido = a.NomeReduzido || a.Nome;
      b.NomeReduzido = b.NomeReduzido || b.Nome;
      return a.NomeReduzido.localeCompare(b.NomeReduzido, "pt-BR");
    }).map((cliente) => ({
      value: Number(cliente.IdCliente),
      label: cliente.NomeReduzido || cliente.Nome,
    }));

    return (
      <SelectDropdown
        isDisabled={disabled}
        options={listaClientes}
        value={clienteSelecionadoId}
        label={{ id: "select-cliente", text: "CLIENTE" }}
        styles={{ minWidth: "180px" }}
        onChange={this.handleChange}
        size="small"
      />
    );
  }
}

function mapStateToProps({ cliente, fazenda, ui }) {
  return {
    UsuarioClientes: cliente.Clientes,
    ClienteSelecionado: cliente.ClienteSelecionado,
    Fazendas: fazenda.Fazendas,
    Bloqueado: !ui.Dropdown.Cliente.ativo,
  };
}

const mapDispatchToProps = {
  setLoading,
  obterClientesAtivos,
  setarClienteSelecionado,
  listaFazendas,
  setFazenda,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClienteDropdown);
