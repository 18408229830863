import React from "react";
import { withStyles } from "@material-ui/core";
import ActionButtons from "features/shared/components/actionButtons";

const CANCEL_BUTTON_TEXT = "Cancelar";
const SAVE_BUTTON_TEXT = "Resolver e aplicar pendência";
const TOOLTIP_MESSAGE =
  "Só será possível resolver a pendência após realizar os ajustes na movimentação";
const PendencyEditButtons = ({
  classes,
  onGoBack = () => {},
  onSubmit = () => {},
  isSaveEnabled,
}) => {
  return (
    <div className={classes.buttonsContainer}>
      <ActionButtons
        className={classes.actionButtons}
        onSubmit={onSubmit}
        onBack={onGoBack}
        enableButtonSubmit={isSaveEnabled}
        textButtonSubmit={SAVE_BUTTON_TEXT}
        textButtonGoBack={CANCEL_BUTTON_TEXT}
        tooltipMessage={!true && TOOLTIP_MESSAGE}
      />
    </div>
  );
};

const styles = () => ({
  buttonsContainer: {
    display: "flex",
    marginTop: "auto",
  },
  actionButtons: {
    marginTop: 32,
  },
});

export default withStyles(styles)(PendencyEditButtons);
