import BaseService from '../../services/BaseService';

const URL_BASE = `${process.env.REACT_APP_ENDPOINT}tableauembedded/api/v1`;

class TableauEmbeddedService extends BaseService {
  async listarRelatorios() {
    return this.get(`${URL_BASE}/relatorios`);
  }

  async listarPastasUsuario(user) {
    return this.get(`${URL_BASE}/pastas?user=${user}`);
  }
}

export default new TableauEmbeddedService();
