import novatentativa from "../lib/novatentativa";
import BaseService from "./BaseService";

const URL_BASE = `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/modulos`;

class ModuloService extends BaseService {
  /**
   * Busca os modulos de uma fazenda
   * @param {number} IdFazenda
   * @param {Array} camposRetornar campos a retornar, opcional
   */
  async listaModulosPorIdFazenda(IdFazenda, camposRetornar = []) {
    try {
      const tempFilter = { where: { IdFazenda } };

      if (camposRetornar.length) {
        tempFilter.attributes = camposRetornar;
      }
      tempFilter.include = "Pastos";
      const filtro = this.montaFiltro(tempFilter);

      const urlServico = this.montaURL(URL_BASE, filtro);
      let response = await this.get(urlServico);
      return response;
    } catch (erro) {
      return {
        erro,
      };
    }
  }

  /**
   * Salva um modulo da fazenda
   * @param {Object} modulo
   */
  async salvaModuloFazenda(modulo) {
    try {
      await this.post(
        `${process.env.REACT_APP_ENDPOINT}pastos/api/v1/modulos`,
        modulo
      );
    } catch (error) {
      throw new Error("Erro ao tentar salvar o modulo");
    }
  }

  /**
   * Salva multiplos modulos da fazenda
   * @param {Array} modulosSalvar
   */
  async salvaMultiplosModulosFazenda(modulosSalvar) {
    try {
      if (modulosSalvar.length) {
        const tentativa = new novatentativa(2);
        const modulosInseridos = await Promise.all(
          modulosSalvar.map((modulo) =>
            tentativa.postServicoUrl(URL_BASE, modulo)
          )
        );
        return modulosInseridos;
      }
    } catch (error) {
      throw new Error("Erro ao tentar salvar alguns modulos");
    }
  }

  async excluirMultiplosModulos(idsModulosExcluir) {
    try {
      if (idsModulosExcluir.length) {
        let modulosDeletados = [];
        modulosDeletados = await Promise.all(
          idsModulosExcluir.map((idModulo) =>
            this.delete(`${URL_BASE}/${idModulo}`)
          )
        );
        return modulosDeletados;
      }
      return [];
    } catch (erro) {
      return [];
    }
  }
  async excluirModulosSemVinculo(IdFazenda) {
    try {
      await this.delete(`${URL_BASE}/fazenda/${IdFazenda}`);
    } catch (erro) {
      throw new Error("Erro ao tentar excluir modulos sem vinculo");
    }
  }
}

export default new ModuloService();
