import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import ListaFormulas from "./components/listaFormulas";
import FormulacaoSemItems from "./components/formulacaoSemItems";
import history from "../../../app/History";
import shortid from "shortid";
import {
  Paper,
  Typography,
  Button,
  FormControl,
  TextField,
  withStyles,
} from "@material-ui/core";
import { filterFormulaMemoize, OBTER_FORMULAS } from "../../formulaUtils";
import FormulacaoModeloImpressao from "../formulationModal/components/formulacaoModeloImpressao";
import FormulationModalContainer from "../formulationModal/formulationModalContainer";
import SelectDropdown from "components/SelectDropdown";
import ConfirmationModal from "features/shared/components/confirmationModal";
import cadastroIngredientesService from "features/cadastroIngredientes/cadastroIngredientesService";


const OPCOES_SELECT_INPUT = [
  { value: 0, label: "TODAS" },
  { value: 1, label: "ATIVADAS" },
  { value: 2, label: "DESATIVADAS" },
];
export class ListagemFormulacaoPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      filtro: "",
      adicionarFormulacao: false,
      estadoSelecionado: 1,
      formula: null,
      capacidadeMisturador: "",
      quantidadeTotal: 0,
      confirmationModal: {
        showModal: false,
        id: null,
        descricao: null,
        ingredientes: []
      },
    };
  }

  componentDidMount = () => {
    this.props.obterFormulasFazenda(OBTER_FORMULAS);
  };

  handreChangeStatus = (status) => {
    this.setState({ estadoSelecionado: status });
  };

  handreQuantidadeTotal = (status) => {
    this.setState({ quantidadeTotal: status });
  };

  handleEstado = (value) => {
    this.handreChangeStatus(value);
  };

  handlerFormula = (formula) => {
    this.setState({ formula: formula });
  };

  cadastroFormulacao = () => {
    const url = window.location.pathname + "/edicao-formulacao";
    history.push(url);
  };

  editarFormulacao = (formula) => {
    const url = window.location.pathname + "/edicao-formulacao";
    history.push({
      pathname: url,
      state: { formula: formula },
    });
  };

  closeModal = () => {
    this.setState({
      confirmationModal: {
        showModal: false,
        id: null,
        descricao: null,
        ingredientes: []
      }
    })
  };

  temIngredienteInativo = (formula) => {
    const ingredientesFormula = formula.Ingrediente;
    if (ingredientesFormula && ingredientesFormula.length > 0) {
      return ingredientesFormula.some(ingrediente => ingrediente.OperacaoIngrediente.Ativo === false)
    }
  };

  activateIngredientAndFormula = async () => {
    const nomeUsuario = localStorage.getItem("Login");
    const arrayIngredientes = this.state.confirmationModal.ingredientes;
    for await (const ingrediente of arrayIngredientes) {
      if(ingrediente.OperacaoIngrediente.Ativo === false) {
        await cadastroIngredientesService.ativaIngrediente(
          ingrediente.IdIngrediente,
          nomeUsuario
          );
      }
      }
      await this.props.desexcluirFormula(this.state.confirmationModal.id, this.state.confirmationModal.ingredientes);
      await this.props.obterFormulasFazenda(OBTER_FORMULAS)
      this.closeModal();
  };

    ativaDesativaFormulacao = async (formula, idFormula, excluido) => {
      if (excluido) {
        const formulaTemIngredienteInativo = this.temIngredienteInativo(formula);
        if (formulaTemIngredienteInativo === true) {
          this.setState({
            confirmationModal: {
              showModal: true,
              id: idFormula,
              descricao: formula.Nome,
              ingredientes: formula.Ingrediente
            }
          })
        } else {
          await this.props.desexcluirFormula(idFormula);
        }
      } else {
        await this.props.excluirFormula(idFormula);
      }
      await this.props.obterFormulasFazenda(OBTER_FORMULAS);
    };


    formartModalText = (Descricao) => (
      <span>
        A formulação <span className={this.props.classes.formula}>{Descricao}</span>, possui um ou mais
        ingredientes que estão inativos, por isso, não pode ser ativada.
        Deseja ativar os ingredientes inativos? Após isso, a formulação será
        automaticamente ativada.
      </span>
    );

    handlerCapacidade = (capacidade) => {
      this.setState({ capacidadeMisturador: capacidade });
    };

    alterarStatusModal = (formula) => {
      if (formula) {
        this.setState({ formula: formula, open: true });
      } else {
        this.setState({ formula: null, open: false });
      }
    };

    render() {
      const {
        classes,
        formulasFazenda,
        exibirCreep,
        exibirSemiConfinamento,
        fazendaSelecionada,
      } = this.props;
      const {
        filtro,
        estadoSelecionado,
        open,
        formula,
        capacidadeMisturador,
        quantidadeTotal,
        confirmationModal
      } = this.state;

      let formulacoesFiltradas = filterFormulaMemoize(formulasFazenda, filtro);
      // filtra as formulações com base do filtro de status
      formulacoesFiltradas = formulacoesFiltradas.filter((v) => {
        if (estadoSelecionado === 0) return true;
        else if (estadoSelecionado === 1) return !v.Excluido;
        else return v.Excluido;
      });

      let existeFormulacao =
        formulacoesFiltradas && (formulacoesFiltradas || []).length > 0;

      return (
        <>
          <Paper square className={classes.root}>
            <Grid item className={classes.containerPage}>
              <Typography className={classes.titulo} gutterBottom>
                Formulações
              </Typography>
              <div className={classes.linhaFiltro}>
                <FormControl className={classes.formContainer}>
                  <TextField
                    id="inputFiltro"
                    autoFocus
                    label={"Buscar formulação"}
                    className={classes.inputFiltro}
                    onChange={(event) =>
                      this.setState({ filtro: event.target.value })
                    }
                  />
                </FormControl>
                <div className={classes.selectInput}>
                  <SelectDropdown
                    id="categorias-select"
                    label={{ text: "Situação da Formulação" }}
                    options={OPCOES_SELECT_INPUT}
                    value={estadoSelecionado}
                    onChange={this.handleEstado}
                  />
                </div>
              </div>
              <Button
                id="botaoSalvar"
                className={classes.botaoSalvar}
                variant="contained"
                color="primary"
                onClick={this.cadastroFormulacao}
              >
                Nova formulação
              </Button>
              {existeFormulacao ? (
                <Grid item>
                  <ListaFormulas
                    id="listaFormulas"
                    key={shortid.generate()}
                    listaFormulas={formulacoesFiltradas}
                    editarFormulacao={this.editarFormulacao}
                    ativaDesativaFormulacao={this.ativaDesativaFormulacao}
                    exibirCreep={exibirCreep}
                    exibirSemiConfinamento={exibirSemiConfinamento}
                    alterarStatusModal={this.alterarStatusModal}
                  />
                </Grid>
              ) : (
                <FormulacaoSemItems />
              )}
            </Grid>
          </Paper>
          {this.state.confirmationModal.showModal && (
        <ConfirmationModal
          onSubmit={this.activateIngredientAndFormula}
          onCancel={this.closeModal}
          title={"Ativar Formulação"}
          submitButtonTitle={"SIM, ATIVAR INGREDIENTES"}
          text={this.formartModalText(confirmationModal.descricao)}
        />
      )}
          <FormulationModalContainer
            disableBackdropClick={true}
            open={open}
            keyboard={false}
            formula={formula}
            alterarStatusModal={() => this.alterarStatusModal()}
            onBackdropClick={() => this.alterarStatusModal()}
            onClickAway={() => this.alterarStatusModal()}
            handlerCapacidade={this.handlerCapacidade}
            componentRef={this.componentRef}
            handlerFormula={this.handlerFormula}
            handrerQuantidadeTotal={this.handreQuantidadeTotal}
          />
          <div style={{ display: "none" }}>
            <FormulacaoModeloImpressao
              referencia={(el) => (this.componentRef = el)}
              formula={formula}
              capacidadeMisturador={capacidadeMisturador}
              fazendaSelecionada={fazendaSelecionada}
              quantidadeTotal={quantidadeTotal}
            />
          </div>
        </>
      );
    }
  }

  const styles = (theme) => {
    return {
      root: {
        width: "100%",
        height: "100%",
        overflowX: "auto",
        padding: "46px",
        zIndex: -1,
        bottom: 0,
        top: "17%",
        minHeight: "calc(100vh - 90px)",
      },
      containerPage: {
        flexgrow: 1,
        height: "100%",
        backgroundColor: "#fff",
        width: "100%",
      },
      selectInput: {
        maxWidth: 200,
        width: "100%",
        marginLeft: 32,
      },
      titulo: {
        fontSize: 20,
        fontWeight: "bold",
        fontStyle: "medium",
        fontStretch: "medium",
        lineHeight: "medium",
        letterSpacing: "medium",
        color: "#333333",
        marginTop: 40,
      },
      formContainer: {
        marginTop: 2,
      },
      inputFiltro: {
        width: 274,
      },
      linhaFiltro: {
        display: "flex",
      },
      botaoSalvar: {
        borderRadius: 18,
        float: "right",
        marginRight: 0,
        marginTop: 34,
        width: 173,
        height: 38,
        boxShadow: "none!important",
        fontWeight: 500,
        marginBottom: 15,
      },
      formula: {
        color: theme.palette.primary.main,
        fontWeight: 500,
      },
    };
  };

export default withStyles(styles)(ListagemFormulacaoPage);
