import React, { useState, useEffect } from "react";
import supplementationService from "../../supplementationService";
import SupplementationDetailPage from "./supplementationDetailPage";

const SupplementationDetailContainer = ({ match, classes, history }) => {
  const [supplementation, setSupplementation] = useState({});

  const supplementationId = match.params.supplementationId;

  const prepare = async () => {
    const result = await supplementationService.getSupplementation(
      supplementationId
    );
    setSupplementation(result.data);
  };

  useEffect(() => {
    prepare();
  }, [supplementationId]);

  return (
    <SupplementationDetailPage
      supplementation={supplementation}
      supplementationId={supplementationId}
      history={history}
    />
  );
};

export default SupplementationDetailContainer;
