import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import GoalPlanListPage from "./goalPlanListPage";
import goalPlanService from "../../goalPlanService";
import { getGoalPlanSituation } from "../../goalPlanUtils";
import {
  disableLoading,
  enableLoading,
  notifyError,
} from "../../redux/goalPlanOperations";
import { debounce } from "features/shared/utils";

export const GoalPlanListContainer = ({
  selectedFarm,
  enableLoading,
  disableLoading,
  notifyError,
  showCreation,
  showEdition,
  showRevisions,
  showReview,
  showCopy,
}) => {
  const [goalPlans, setGoalPlans] = useState([]);
  const [filter, setFilter] = useState({ name: "" });

  useEffect(() => {
    getGoalPlans(selectedFarm, filter);
  }, []);

  const getGoalPlans = async (selectedFarm, selectedFilter) => {
    try {
      enableLoading();
      const metas = await goalPlanService.listLastGoalPlans(
        selectedFarm,
        selectedFilter
      );
      const enrichedPlans = metas.map((meta) => ({
        ...meta,
        situation: getGoalPlanSituation(meta),
      }));
      setGoalPlans(enrichedPlans);
    } catch (err) {
      console.error(err);
      notifyError("Ocorreu um erro ao listar os planos de metas.");
    } finally {
      disableLoading();
    }
  };

  const onChangeFilter = (selectedFilter) => {
    setFilter(selectedFilter);
    debounce(() => getGoalPlans(selectedFarm, selectedFilter), 500);
  };

  return (
    <GoalPlanListPage
      id="metas-page"
      goalPlans={goalPlans}
      filter={filter}
      onChangeFilter={onChangeFilter}
      showCreation={showCreation}
      showEdition={showEdition}
      showRevisions={showRevisions}
      showReview={showReview}
      showCopy={showCopy}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    selectedFarm: state.fazenda.FazendaSelecionada,
  };
};

const mapDispatchToProps = {
  enableLoading: enableLoading,
  disableLoading: disableLoading,
  notifyError: notifyError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoalPlanListContainer);
