import HistoricoChuvaService from './historicoChuvaService';

export const MSG = {
  ERRO_OBTER_CSV_CHUVA: 'Não foi possivel exportar csv de historico chuva',
  ERRO_INSERIR_CSV_CHUVA: 'Não foi possivel importar csv de historico chuva'
};

class HistoricoChuvaManager {
  async getHistoricoChuva(IdFazenda, IdRetiro) {
    return HistoricoChuvaService.getHistoricoChuva(IdFazenda, IdRetiro);
  }

  async setHistoricoChuva(IdFazenda, IdRetiro, csv) {
    return HistoricoChuvaService.setHistoricoChuva(IdFazenda, IdRetiro, csv);
  }
}
export default new HistoricoChuvaManager();
