export const todasAsPastas = [
  {
    titulo: 'Gestão interna de informações',
    imagemSrc: "../../../images/CardAdminInsigths.jpg",
    workBooks: []
  },
  {
    titulo: 'KPIs Plano de vôo',
    imagemSrc: "../../../images/CardAnalisesPontuais.jpg",
    workBooks: []
  },

  {
    titulo: 'Confinamento',
    imagemSrc: "../../../images/CardConfinamento.jpg",
    workBooks: []
  },
  {
    titulo: 'Default',
    imagemSrc: "../../../images/CardDefault.png",
    workBooks: []
  },
  {
    titulo: 'Gestão Financeira',
    imagemSrc: "../../../images/CardDinheiro.jpg",
    workBooks: []
  },
  {
    titulo: 'Escolha de Produtos',
    imagemSrc: "../../../images/CardEscolhaDeProdutos.jpg",
    workBooks: []
  },
  {
    titulo: 'Comercial',
    imagemSrc: "../../../images/CardFaturamentoClientes.jpg",
    workBooks: []
  },
  {
    titulo: 'Bkp e Homologação',
    imagemSrc: "../../../images/CardHomologacao.jpg",
    workBooks: []
  },
  {
    titulo: 'Jedis',
    imagemSrc: "../../../images/CardConfinamento.jpg",
    workBooks: []
  },
  {
    titulo: 'Painel do cliente',
    imagemSrc: "../../../images/CardPainelDoCliente.jpg",
    workBooks: []
  },
  {
    titulo: 'Pessoal',
    imagemSrc: "../../../images/CardConfinamento.jpg",
    workBooks: []
  },
  {
    titulo: 'Manejo a pasto cliente',
    imagemSrc: "../../../images/CardProdapViewsCliente.jpg",
    workBooks: []
  },
  {
    titulo: 'Monitoramento de utilização',
    imagemSrc: "../../../images/CardUtilizacaoViewsMaster.jpg",
    workBooks: []
  },
  {
    titulo: 'Manejo a pasto',
    imagemSrc: "../../../images/CardViewsMaster.jpg",
    workBooks: []
  }];
