import React from "react";
import "./styles.css";
import constantsAlturaPasto from "constants/mapaFarolAlturaPasto";

export const renderCorDoIcone = (cor) => {
  switch (cor) {
    case constantsAlturaPasto.collect.collect_types_colors.outsidePasture:
      return "#FDDFDD";
    case constantsAlturaPasto.collect.collect_types_colors.insidePasture:
      return "#F4F7D4";
    default:
      return "rgba(240, 240, 240, 0.98)";
  }
};

const ModalMarcador = ({ pasto, color, url, onMouseLeave, onClick }) => {
  return (
    <div onMouseLeave={(e) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.target && e.target.id !== "modal-marcador") {
        return;
      }
      onMouseLeave && onMouseLeave(e)
    }}
      onMouseMove={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onClick={onClick}
      id="modal-marcador" className="modal-marcador-wrapper">
      <div
        className="modal-marcador"
      >
        <div className="modal-header"

        >
          <div
            className="marcador-icon"
            style={{
              backgroundColor: `${renderCorDoIcone(color)}`,
            }}
          >
            <img id="marcador-image" src={url} alt="" />
          </div>
          <div className="modal-title">
            <h3 className="bold">
              {pasto.Nome}
            </h3>
          </div>
        </div>
      </div>
    </div>)
}

export default ModalMarcador;
