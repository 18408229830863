import React from 'react';
import NumberFormat from 'react-number-format';

function FormatadorPorcentagemCustomizado(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      ref={inputRef}
      maxLength="3"
      isAllowed={values => {
        return values.floatValue <= 100.0 || values.value === '';
      }}
      allowNegative={false}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
    />
  );
}

export default FormatadorPorcentagemCustomizado;
