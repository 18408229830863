export const SET_ABA_SELECIONADA = 'SET_ABA_SELECIONADA';

const abaSelecionada = localStorage.getItem('abaSelecionada');

export const initialState = {
  abaSelecionada: abaSelecionada ? abaSelecionada : 'Categoria Animal'
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ABA_SELECIONADA: {
      return {
        ...state,
        abaSelecionada: action.payload
      }
    }
    default:
      return state;
  }
};
