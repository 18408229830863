import { LISTA_PASTOS_PRIME } from "redux/actions/actionsConstants";
import PastoPrimeService from "services/PastoPrimeService";
import { notificaErro } from "features/notificacao/redux/notificacaoActions";

export const listarPastosPrime = (IdFazenda) => async (dispatch) => {
  try {
    let pastos = await PastoPrimeService.listaPastosPrimePorIdFazenda(
      IdFazenda
    );
    dispatch({
      type: LISTA_PASTOS_PRIME,
      payload: {
        ListaPastosPrime: pastos,
      },
    });
  } catch (error) {
    dispatch(notificaErro(error));
  }
};
