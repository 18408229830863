import MetricsService, { EventType } from 'services/MetricsService';
import BaseService from '../../services/BaseService';

const URL_BASE = process.env.REACT_APP_ENDPOINT;
export const URI_FECHAMENTO_CONSUMOS = 'consumo/api/v1/fechamento';

class FechamentoConsumoService extends BaseService {
  obtemFechamentoConsumo(filtro) {
    MetricsService.sendEvent({
      event: EventType.SOLICITA_FECHAMENTO_CONSUMO,
      extraData: filtro
    });
    return this.post(`${URL_BASE}${URI_FECHAMENTO_CONSUMOS}/coleta-sal`, filtro);
  }
}

export default new FechamentoConsumoService();
