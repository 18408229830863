import moment from "moment";
import { IconButton, Tooltip, withStyles } from "@material-ui/core";
import TabelaProdap from "features/shared/components/TabelaProdap";
import {
  formatDate,
  formatDateToFarmTimezone,
} from "features/collect/supplementation/supplementationUtils";
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SupplementationConfirmationModal from "../../shared/supplementationConfirmationModal/supplementationConfirmationModal";
import {
  amountTroughBagsFormat,
  pastureNamesFormat,
  troughTypeFormat,
  formulaNameFormat,
} from "../../shared/utils";
import { dateDiff } from "shared/utils";
import InfoIcon from "@material-ui/icons/Info";

const TOOLTIP_MESSAGE = [
  "Não é possivel excluir ou editar coletas com data superior a 60 dias.",
  "Essa coleta teve um atraso considerável na sincronização. Indicamos que as coletas sejam sincronizadas todos os dias ao fim do trabalho, e que o aplicativo seja sincronizado também antes de iniciar o trabalho a fim de receber as operações realizadas por outros usuários e sincronizadas após a sua ultima sincronização",
];

const SupplementationListTable = ({
  classes,
  getSupplementations,
  NotFoundComponent,
  deleteSupplementation,
  removeActionsColumn,
  goToSupplementationEdit = () => {},
  goToViewSupplementation = () => {},
}) => {
  const [supplementationToDelete, setSupplementationToDelete] =
    React.useState(null);
  const [lastUpdate, setLastUpdate] = React.useState(new Date().getTime());
  const doDelete = async () => {
    const response = await deleteSupplementation(supplementationToDelete.id);
    if (response) {
      setSupplementationToDelete(null);
      setLastUpdate(new Date().getTime());
    }
  };

  const verifyDate = (date) => {
    const currentDay = moment();
    const collectionDate = moment(date);

    const duration = moment.duration(currentDay.diff(collectionDate));

    const diffDays = duration.asDays();

    if (parseInt(diffDays) > 60) {
      return true;
    } else {
      return false;
    }
  };

  const onDeleleSupplementation = (event, supplementation) => {
    event.stopPropagation();
    setSupplementationToDelete(supplementation);
  };

  const onGoToSupplementationEdition = (event, supplementationId) => {
    event.stopPropagation();
    if (!supplementationId) return;
    goToSupplementationEdit(supplementationId);
  };

  const columns = [
    {
      id: "formulaName",
      titulo: <span>Formulação</span>,
      ordenavel: false,
      pegaValor: (row) => (
        <div className={classes.collectionPlanIconWrapper}>
          <div className={classes.icons}>
            {row.formulaName
              ? row.formulaName.trim().charAt(0).toUpperCase()
              : "N"}
          </div>
          {formulaNameFormat(row.formulaName)}
        </div>
      ),
      propriedadesCustomizadas: {
        "data-testid": "formulaName",
      },
    },
    {
      id: "pastureName",
      titulo: "Pasto",
      ordenavel: false,
      pegaValor: (row) => <span>{pastureNamesFormat(row.pastureName)}</span>,
      propriedadesCustomizadas: {
        "data-testid": "pastureName",
      },
    },
    {
      id: "retreatName",
      titulo: "Retiro",
      ordenavel: false,
      pegaValor: (row) => <span>{pastureNamesFormat(row.retreatName)}</span>,
      propriedadesCustomizadas: {
        "data-testid": "retreatName",
      },
    },
    {
      id: "responsibleCollection",
      titulo: "Responsável pela coleta",
      ordenavel: false,
      pegaValor: (row) => <span>{row.creationUser}</span>,
      propriedadesCustomizadas: {
        "data-testid": "responsibleCollection",
      },
    },
    {
      id: "troughType",
      titulo: <span className={classes.noWrap}>Tipo do cocho</span>,
      ordenavel: false,
      pegaValor: (row) => <span>{troughTypeFormat(row)}</span>,
      propriedadesCustomizadas: {
        "data-testid": "troughType",
      },
    },
    {
      id: "quantitySupplied",
      titulo: <span className={classes.noWrap}>Quant. abastecida</span>,
      ordenavel: false,
      pegaValor: (row) => (
        <span>
          {amountTroughBagsFormat(
            row.amountTroughBags,
            row.formulaFractionable
          )}
        </span>
      ),
      propriedadesCustomizadas: {
        "data-testid": "quantitySupplied",
      },
    },
    {
      id: "collectionDate",
      titulo: <span className={classes.noWrap}>Data da coleta</span>,
      ordenavel: false,
      pegaValor: (row) => (
        <span>{formatDateToFarmTimezone(row.collectionDate)}</span>
      ),
      propriedadesCustomizadas: {
        "data-testid": "collectionDate",
      },
    },
    {
      id: "createdAt",
      titulo: <span className={classes.noWrap}>Data da sincronização</span>,
      ordenavel: false,
      pegaValor: (row) => {
        const { hours } = dateDiff(row.collectionDate, row.createdAt);
        if (hours && hours > 12) {
          return (
            <div className={classes.dataSinc}>
              <Tooltip
                title={TOOLTIP_MESSAGE[1]}
                className={classes.tooltipDataSinc}
                placement="right"
              >
                <InfoIcon style={{ color: "#FFC944" }} />
              </Tooltip>
              <span>{formatDateToFarmTimezone(row.createdAt)}</span>
            </div>
          );
        }
        return <span>{formatDateToFarmTimezone(row.createdAt)}</span>;
      },
      propriedadesCustomizadas: {
        "data-testid": "createdAt",
      },
    },
    {
      id: "actions",
      titulo: "Ações",
      ordernavel: false,
      pegaValor: (row) => {
        const isValidDate = verifyDate(row.collectionDate);

        return (
          <div className={classes.actionColumnContainer}>
            <Tooltip
              title={isValidDate ? TOOLTIP_MESSAGE[0] : ""}
              className={classes.tooltip}
              placement="right"
            >
              <div>
                <IconButton
                  id="buttonDelete"
                  data-testid="button-delete"
                  style={{ marginLeft: "-16px", marginRight: "8px" }}
                  onClick={(e) =>
                    onDeleleSupplementation(e, {
                      id: row.id,
                      formulaName: row.formulaName,
                    })
                  }
                  disabled={isValidDate}
                  aria-label="Delete"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </Tooltip>

            <Tooltip
              title={isValidDate ? TOOLTIP_MESSAGE[0] : ""}
              className={classes.tooltip}
              placement="left"
            >
              <div>
                <IconButton
                  id="buttonEdit"
                  data-testid="button-edit"
                  onClick={(e) => onGoToSupplementationEdition(e, row.id)}
                  disabled={isValidDate}
                  aria-label="Edit"
                >
                  <EditIcon />
                </IconButton>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  if (removeActionsColumn) {
    columns.pop();
  }

  const onDeleteText = () => {
    return (
      <div>
        <span>
          <b>Atenção! </b>Essa ação não poderá ser defeita, tem certeza que
          deseja excluir a coleta da suplementação{" "}
          <b>{supplementationToDelete.formulaName}</b>?
        </span>
        <span>
          {" "}
          Essa ação repercutirá no Fechamento de Consumo e Módulo Analítico
          apenas no <b>próximo dia {formatDate(moment().add(1, "day"))}.</b>
        </span>
      </div>
    );
  };

  return (
    <>
      <div>
        <TabelaProdap
          ordemInicial="collectionDate"
          ordenarAsc="asc"
          ativarPaginacao
          ComponenteListaVazia={NotFoundComponent}
          servico={getSupplementations}
          atualizar={lastUpdate}
          propriedadesrows={{
            "data-testid": "linesSupplementationTable",
          }}
          propriedadesCelulasCabecalho={{
            "data-testid": "columnSupplementationTable",
          }}
          colunas={columns}
          tabelaEsquerda={true}
          aoClicarNaLinha={(row) => goToViewSupplementation(row.id)}
          aoClicarNaLinhaCursor={true}
        />
      </div>

      {!!supplementationToDelete && (
        <SupplementationConfirmationModal
          title={"Excluir coleta"}
          text={onDeleteText()}
          onCancel={() => setSupplementationToDelete(null)}
          submitButtonTitle="Sim, excluir"
          onSubmit={doDelete}
        />
      )}
    </>
  );
};

const styles = (theme) => {
  return {
    collectionPlanIconWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    icons: {
      width: "32px",
      height: "32px",
      flexGrow: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "17px 16px 16px 0px",
      borderRadius: "30px",
      backgroundColor: "#e5f3ff",
      fontSize: "16px",
      fontWeight: "600",
      color: theme.palette.primary.main,
      border: "none",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#B6DDFF",
      },
    },
    actionColumnContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    noWrap: {
      whiteSpace: "no-wrap",
    },
    dataSinc: {
      position: "relative",
      display: "flex",
    },
    tooltipDataSinc: {
      maxWidth: 16,
      fontSize: "16px",
    },
  };
};

export default withStyles(styles)(SupplementationListTable);
