import React from "react";
import { withStyles, TextField } from "@material-ui/core";
import CustomLabel from "./CustomLabel";
const CustomTextField = ({
  classes,
  testId = undefined,
  disabled = true,
  flex = 2,
  ...props
}) => {
  return (
    <div className={classes.fieldFlex} style={{ flex }}>
      <TextField
        data-testid={testId}
        InputLabelProps={{ shrink: true }}
        inputProps={{
          style: {
            padding: "9px 0",
          },
        }}
        className={classes.textField}
        disabled={disabled}
        label={<CustomLabel label={props.label} />}
        {...props}
      />
    </div>
  );
};

const styles = {
  textField: {
    backgroundColor: "#fff",
    width: "100%",
  },
  fieldFlex: {},
};

export default withStyles(styles)(CustomTextField);
