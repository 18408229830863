import React from "react";
import { InfoOutlined, WarningAmberOutlined } from "@mui/icons-material";
import PlanningFormContainer from "../shared/planningForm/planningFormContainer";
import AlertBanner from "features/shared/components/alertBanner";
import { Typography, withStyles } from "@material-ui/core";

const TITLE = "Editar planejamento";

const PlanningEditPage = ({
  classes,
  planningPlanData,
  periodTitles,
  freeCategories,
  categories,
  formulas,
  planningsTableList,
  planningList,
  onChangeCategory,
  onChangeFormula,
  onChangeHerdCount,
  addNewLine,
  onDeleteLine,
  onChangePlanningInitialDate,
  hasGoalPlanSync,
  isGoalPlanUnsync,
  activeGoalPeriod,
  ingredientsPrices,
  formulasIngredients,
  onChangeIngredientPrice,
  updateIngredientsList,
  onSubmit = () => {},
  goBack = () => {},
  summaryReport,
  updateSummaryReport,
}) => {
  const SubTitle = ({ hasGoalPlanSync, isGoalPlanUnsync }) => {
    return (
      <div className={classes.subTitle}>
        {hasGoalPlanSync && (
          <AlertBanner type="warnning" icon={<WarningAmberOutlined />}>
            Atenção! Ao editar a data, ela deixará de estar vinculada às metas
            da fazenda.
          </AlertBanner>
        )}
        {isGoalPlanUnsync && (
          <AlertBanner type="error" icon={<InfoOutlined color="error" />}>
            <div>
              <div>
                <strong>
                  O período do planejamento não está sincronizado com o período
                  das metas da fazenda
                </strong>
              </div>
              <div>
                Para sincronizá-los novamente, por favor, defina a mesma data
                para ambos.
              </div>
            </div>
          </AlertBanner>
        )}
        <Typography className={classes.subtitle}>
          Todos os valores preenchidos serão de uso único para este
          planejamento. Qualquer edição na fórmula não terá impacto nesta página
          até que uma nova edição deste planejamento seja realizada. É
          importante ressaltar que as edições são válidas para o período total
          do planejamento, ou seja, os 12 meses. Caso a formulação que você
          procura não estiver disponível, você poderá cadastrá-la acessando a
          tela <span className={classes.subTitleHighlight}>"Formulações"</span>{" "}
          e, em seguida, ela será exibida aqui.
        </Typography>
      </div>
    );
  };

  const IngredientSubTitle = () => (
    <Typography className={classes.subtitle}>
      Aqui, você pode criar um planejamento de custo de todos os ingredientes
      utilizados nas formulações, selecionadas anteriormente, informando o valor
      anual que deseja investir em cada ingrediente ao longo do período de
      planejamento.
    </Typography>
  );

  return (
    <PlanningFormContainer
      title={TITLE}
      subtitle={
        <SubTitle
          hasGoalPlanSync={hasGoalPlanSync}
          isGoalPlanUnsync={isGoalPlanUnsync}
          initialDate={activeGoalPeriod.initialDate}
          finalDate={activeGoalPeriod.finalDate}
        />
      }
      ingredientsSubtitle={<IngredientSubTitle />}
      ingredientsPrices={ingredientsPrices}
      formulasIngredients={formulasIngredients}
      onChangeIngredientPrice={onChangeIngredientPrice}
      updateIngredientsList={updateIngredientsList}
      planningPlanData={planningPlanData}
      periodTitles={periodTitles}
      freeCategories={freeCategories}
      categories={categories}
      formulas={formulas}
      planningsTableList={planningsTableList}
      planningList={planningList}
      onChangeCategory={onChangeCategory}
      onChangeFormula={onChangeFormula}
      onChangeHerdCount={onChangeHerdCount}
      addNewLine={addNewLine}
      onDeleteLine={onDeleteLine}
      onChangePlanningInitialDate={onChangePlanningInitialDate}
      onSubmit={onSubmit}
      goBack={goBack}
      summaryReport={summaryReport}
      updateSummaryReport={updateSummaryReport}
    />
  );
};

const styles = (theme) => ({
  subTitle: {
    margin: "41px 0 6px 0",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  subTitleHighlight: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
});

export default withStyles(styles)(PlanningEditPage);
