import React from "react";
import { Modal, Button, withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export const ConfirmationModal = ({
  classes,
  title,
  text,
  submitButtonTitle,
  cancelButtonTitle = "CANCELAR",
  onSubmit,
  onCancel,
  onClose,
  cancelButtonClass,
  submitButtonClass,
  submitButtonContainer,
  buttonsContainer,
  customClass,
}) => {
  return (
    <Modal
      className={classes.modal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={true}
      data-testid="Confirmation-Modal"
    >
      <div
        id="containerConfirmationModal"
        className={[classes.modalContent, customClass].join(" ")}
      >
        {onClose && (
          <div className={classes.closeIconContainer}>
            <CloseIcon className={classes.closeIcon} onClick={onClose} />
          </div>
        )}
        <div className={classes.title}>{title}</div>
        <div className={classes.text} data-testid="Confirmation-Modal-Text">
          {text}
        </div>
        <div
          className={[
            classes.buttonContainer,
            submitButtonContainer,
            buttonsContainer,
          ].join(" ")}
        >
          {onCancel && (
            <Button
              id="cancel-button-modal"
              data-testid="Confirmation-Modal-Cancel-Button"
              className={[classes.buttonCancel, cancelButtonClass].join(" ")}
              onClick={onCancel}
            >
              {cancelButtonTitle}
            </Button>
          )}
          {onSubmit && (
            <Button
              id="submit-button-modal"
              data-testid="Confirmation-Modal-Submit-Button"
              className={[classes.buttonSubmit, submitButtonClass].join(" ")}
              onClick={onSubmit}
            >
              {submitButtonTitle}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

const styles = (theme) => {
  return {
    modal: {
      zIndex: "49999",
      display: "flex",
      height: "100%",
      width: "100%",
    },
    title: {
      fontFamily: "Roboto",
      fontSize: "20px",
      fontWeight: 500,
      textAlign: "left",
      color: "#333",
      marginBottom: "23px",
    },
    text: {
      fontFamily: "Roboto",
      fontSize: "16px",
    },
    modalContent: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      width: "40%",
      height: "auto",
      maxWidth: "500px",
      backgroundColor: "#ffffff",
      outline: "none",
      boxShadow: "0 2px 20px 0 rgba(0, 0, 0, 0.5)",
      borderRadius: "18px",
      padding: "32px 24px",
      zIndex: "2",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      padding: "35px 0px 0px 0px",
    },
    buttonCancel: {
      fontSize: "14px",
      color: theme.palette.primary.main,
      padding: "7px 22px",
      height: 36,
      border: `solid 1px ${theme.palette.primary.main}`,
    },
    buttonSubmit: {
      fontSize: "14px",
      marginLeft: "16px",
      color: "#FFFFFF",
      padding: "7px 22px",
      height: 36,
      backgroundColor: theme.palette.primary.main,
    },
    closeIconContainer: {
      marginBottom: "16px",
      marginTop: "-20px",
      cursor: "pointer",
      padding: "0",
      display: "flex",
      justifyContent: "flex-end",
      alignSelf: "end",
    },
    closeIcon: {
      width: 20,
      height: 20,
    },
  };
};

export default withStyles(styles)(ConfirmationModal);
