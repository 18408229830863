import React, { useState } from "react";
import { withStyles } from "@material-ui/core";
import IngredientRegisterTable from "./components/ingredientRegisterTable";
import ConfirmationModal from "features/shared/components/confirmationModal";

const IngredientRegisterListContainer = ({
  ingredientes,
  setEditando,
  setIngredienteEditado,
  ativaIngrediente,
  desativaIngrediente,
  obterHistorico,
  historico,
  setLancaCompra,
  isInternalUser,
  classes,
  formulasPorIngrediente,
  getFormulasPorIdIngrediente
}) => {
  const [abrirHistorico, setAbrirHistorico] = useState(false);
  const [ingredienteSelecionado, setIngredienteSelecionado] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState({
    showModal: false,
    id: null,
    descricao: null,
  });

  const ativarEdicaoIngrediente = (ingredienteParaEditar) => {
    setEditando(true);
    setLancaCompra(false);
    setIngredienteEditado(ingredienteParaEditar);
  };


  const ativaOuDesativaIngrediente = (IdIngrediente, Ativo, Descricao) => {
    const nomeUsuario = localStorage.getItem("Login");
    getFormulasPorIdIngrediente(IdIngrediente);
    if (!Ativo) {
      ativaIngrediente(IdIngrediente, nomeUsuario);
    } else {
      setConfirmationModal({
        showModal: true,
        id: IdIngrediente,
        descricao: Descricao,
      });
    }
  };

  const onDeactiveIngredient = () => {
    const nomeUsuario = localStorage.getItem("Login");
    desativaIngrediente(confirmationModal.id, nomeUsuario);
    closeModal();
  };

  const closeModal = () => {
    setConfirmationModal({
      showModal: false,
      id: null,
      descricao: null,
    });
  };

  const formartModalText = (Descricao) => (
    <span>
      Atenção, ao desativar o ingrediente{" "}
      <span className={classes.ingredient}>{Descricao}</span>, ele não poderá
      ser utilizado em nenhuma formulação, tem certeza deseja desativar? Você
      poderá ativar quando desejar.
    </span>
  );

  const abreHistoricoIngredientes = async (ingrediente) => {
    setAbrirHistorico(true);
    setIngredienteSelecionado(ingrediente);
    await obterHistorico(ingrediente.IdIngrediente);
  };

  const lancarCompra = (ingredienteCompra) => {
    setEditando(true);
    setLancaCompra(ingredienteCompra);
  };

  return (
    <>
      <IngredientRegisterTable
        historico={historico}
        ingredientes={ingredientes}
        abrirHistorico={abrirHistorico}
        ingredienteSelecionado={ingredienteSelecionado}
        abreHistoricoIngredientes={abreHistoricoIngredientes}
        obterHistorico={obterHistorico}
        setAbrirHistorico={setAbrirHistorico}
        lancarCompra={lancarCompra}
        ativaOuDesativaIngrediente={ativaOuDesativaIngrediente}
        ativarEdicaoIngrediente={ativarEdicaoIngrediente}
        isInternalUser={isInternalUser}
      />
      {confirmationModal.showModal && (
        <ConfirmationModal
          onSubmit={onDeactiveIngredient}
          onCancel={closeModal}
          title={"Desativar ingrediente"}
          submitButtonTitle={"SIM, DESATIVAR"}
          text={formartModalText(confirmationModal.descricao)}
        />
      )}
    </>
  );
};

const styles = (theme) => {
  return {
    ingredient: {
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
    cancelButtonClass: {
      color: "#FFFFFF",
      backgroundColor: "#eb394b",
      border: "none",
    },
  };
};

export default withStyles(styles)(IngredientRegisterListContainer);
