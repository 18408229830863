export default (theme) => ({
  container: {
    minHeight: "100vh",
    height: "100%",
    padding: 0,
    margin: 0,
    boxSizing: "border-box",
    fontFamily: '"Roboto", "sans-serif"',
    backgroundColor: "#F2F5F7",
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: "75px",
    padding: "0 72px",
    borderBottom: "1px solid #E5E5EA",
    backgroundColor: "#FFF",
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.12)",
    "@media (max-width: 768px)": {
      padding: "0 32px",
    },
  },
  logoWrapperImg: {
    width: "101px",
    height: "35px",
  },
  page: {
    width: "65%",
    maxWidth: "900px",
    margin: "0 auto",
    padding: "60px 0 84px 0",
    backgroundColor: "#FFF",
    borderRadius: "0 0 30px 30px",
    boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.12)",
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  content: {
    width: "47%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 768px)": {
      width: "80%",
    },
  },
  greetingTitle: {
    fontSize: "22px",
    color: "#163e6a",
    marginBottom: "24px",
  },
  form: {
    marginTop: "64px",
    display: "flex",
    flexDirection: "column",
  },
  greetings: {
  },
  greetingWelcome: {
    color: "#333333",
    fontSize: "28px",
    padding: 0,
    margin: 0,
  },
  greetingsMessage: {
    marginTop: "16px",
    fontSize: "18px",
    color: "#757575",
  },
  inputWrapper: {
    display: "flex",
    alignItems: "center",
    paddingRight: "16px",
    marginBottom: "24px",
    position: "relative",
    borderRadius: "4px",
    border: "1px solid #bdbdbd",
    "&:label": {
      position: "absolute",
      top: "-10px",
      left: "8px",
      padding: "0 4px",
    },
    "&:error": {
      border: "solid 1px #ff2851 !important",
    },
  },
  inputLabel: {
    position: "absolute",
    top: "-8px",
    left: "8px",
    color: "#333333",
    fontSize: "12px",
    backgroundColor: "#fff",
    padding: "0 4px",
  },
  onError: {
    border: "solid 1px #ff2851 !important",
  },
  spanIcon: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  input: {
    width: "100%",
    height: "40px",
    padding: "12px",
    border: "none",
    borderRadius: "4px",
    outline: "none",
    "&::placeholder": {
      color: "#9e9e9e",
      fontSize: "0.875rem",
    },
    "&::-ms-reveal,&::-ms-clear": {
      display: "none",
    },
  },
  submitButton: {
    width: "100%",
    padding: "14px 0",
    border: "none",
    borderRadius: "4px",
    boxShadow:
      "0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 3px 0 rgba(0, 0, 0, 0.14), 0 3px 5px -2px rgba(0, 0, 0, 0.2)",
    fontSize: "14px",
    textAlign: "center",
    color: "#fff",
    cursor: "pointer",
    backgroundColor: "#307297",
    "&:hover,&:visited,&:focus,&:link,&:active": {
      backgroundColor: "#30719780",
      color: "#fff",
    },
    "&:disabled": {
      border: "1px solid #999999",
      color: "#999999",
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  forgotPasswordLink: {
    cursor: "pointer",
    textDecoration: "underline",
    fontSize: "14px",
    marginBottom: "60px",
    color: theme.palette.primary.main,
    "&:active, &:visited, &:focus, &:link": {
      color: theme.palette.primary.main
    }
  },
  resetPasswordInfo: {
    color: "#757575",
    fontSize: "13px",
    marginTop: 0,
    marginBottom: "102px",
  },
  phoneNumber: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  navigateToLoginLink: {
    cursor: "pointer",
    marginTop: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    rowGap: "8px",
    fontSize: "16px",
    textAlign: "center",
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:active, &:visited, &:focus, &:link": {
      color: theme.palette.primary.main
    }
  },
  arrowIcon: {
    marginRight: "16px",
    width: "20px",
    height: "20px"
  },
  passwordCreationRules: {
    marginTop: "16px",
    marginBottom: "80px",
    "& p": {
      margin: "0 0",
      fontSize: "14px",
      color: "#616161",
    },
  },
  passwordCreationRulesSection: {
    marginBottom: "12px",
  },
  footer: {
    marginTop: "14px",
    "& p": {
      margin: "0",
      fontSize: "16px",
    },
  },
  footerHighlight: {
    color: "#EB394B",
    fontWeight: 700
  },
  invalidTokenContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 16px",

    "& p": {
      color: "#333333",
      fontSize: "28px",
      textAlign: "center",
      marginBottom: "162px",
      fontWeight: 500
    },
  },
});
