import { IconButton, Menu, MenuItem, withStyles } from "@material-ui/core";
import React, { useState, useRef } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import constants from "../../shared/constants";

const imgEdit = "../images/ico-edit.svg";
const imgCreate = "../images/ico-create.svg";
const imgCopy = "../images/ico-copy.svg";
const icoDocument = "../images/ico-document.svg";

const MenuDropdown = ({
  goalPlan,
  classes,
  showEdition,
  showReview,
  showCopy,
  showRevisions,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const selectedPlan = useRef(null);

  const isActive = goalPlan.active && goalPlan.situation !== constants.situation.PAST;

  const open = Boolean(anchorEl);
  const handleClickMenu = (event, plan) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    selectedPlan.current = plan;
  };

  const handleEdit = (event) => {
    event.stopPropagation();
    if (!selectedPlan.current) return;
    showEdition(selectedPlan.current.id);
  };

  const handleReview = (event) => {
    event.stopPropagation();
    if (!selectedPlan.current) return;
    showReview(selectedPlan.current.id);
  };

  const handleCopy = (event) => {
    event.stopPropagation();
    if (!selectedPlan.current) return;
    showCopy(selectedPlan.current.id);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const menuDropdown = (
    <Menu
      id="long-menu"
      MenuListProps={{
        "aria-labelledby": "long-button",
        "border-radius": "3px",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: "3px",
        },
      }}
    >
      {isActive && (
        <>
          <MenuItem onClick={handleEdit}>
            <img src={imgEdit} className={classes.iconsMenu} alt="icon-edit" />
            Editar
          </MenuItem>

          <MenuItem key="revisao" onClick={handleReview}>
            <img
              src={imgCreate}
              className={classes.iconsMenu}
              alt="icon-create"
            />
            Criar revisão do plano
          </MenuItem>
        </>
      )}
      <MenuItem key="copiar" onClick={handleCopy}>
        <img src={imgCopy} className={classes.iconsMenu} alt="icon-copy" />
        Copiar plano
      </MenuItem>
      <MenuItem key="revisions" onClick={() => showRevisions(goalPlan.id)}>
        <img
          src={icoDocument}
          className={classes.iconsMenu}
          alt="icon-document"
        />
        Revisões do plano
      </MenuItem>
    </Menu>
  );

  return (
    <div className="menu-dropdown">
      {menuDropdown}

      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={(event) => handleClickMenu(event, goalPlan)}
      >
        <MoreVertIcon />
      </IconButton>
    </div>
  );
};

const styles = {
  iconsMenu: {
    paddingRight: "10px",
  },
};

export default withStyles(styles)(MenuDropdown);
