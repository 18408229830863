import React from "react";
import { Tooltip } from "@material-ui/core";
import { TextLabel } from "../../shared/components";
import {
  PERIODO,
  CONSUMOTOTAL,
  SEMCOLETAS,
  QUANTIDADEABASTECIDA,
  DIARIAS,
} from "../fechamentoConsumoTextos";
import InfoIcon from "@mui/icons-material/Info";
import dayjs from "dayjs";

export const fechamentoConsumoInfo = (props) => {
  const {
    PeriodoBuscado,
    QuantidadeDiasConsumo,
    DataInicioConsumo,
    DataFimConsumo,
    DataPrimeiroAbastecimento,
    DataUltimoAbastecimento,
    TotalConsumidoFormula,
    TamanhoRebanho,
    QuantidadeAbastecida,
    PesoSaco,
    Granel,
    Versao,
  } = props.consumoFormula;

  const ehFechamentoConsumoDoBDW = () => Versao === "bdw";

  return (
    <React.Fragment>
      <div>
        <TextLabel color="default2" size={14} component="p">
          {PERIODO}
        </TextLabel>
        <TextLabel color="default2" bold size={14} component="p">
          {` ${PeriodoBuscado}`}
        </TextLabel>
      </div>
      {(QuantidadeDiasConsumo || QuantidadeDiasConsumo === 0) && (
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <div>
            <TextLabel color="default2" size={14} component="p">
              Qtd dias utilização:
            </TextLabel>
            <TextLabel color="default2" bold size={14} component="p">
              {` ${QuantidadeDiasConsumo}`}
            </TextLabel>
          </div>
          <div style={{ display: "flex" }}>
            <Tooltip
              title={
                <ConsumoTooltip
                  dataInicioConsumo={DataInicioConsumo}
                  dataFimConsumo={DataFimConsumo}
                  dataPrimeiroAbastecimento={DataPrimeiroAbastecimento}
                  dataUltimoAbastecimento={DataUltimoAbastecimento}
                />
              }
              placement="right-start"
            >
              <InfoIcon
                color="primary"
                style={{ width: "15px", height: "15px" }}
              />
            </Tooltip>
          </div>
        </div>
      )}
      <Tooltip
        style={{ cursor: "pointer" }}
        title={"É a quantidade de sacos abastecidos vezes o peso do saco."}
        arrow
      >
        <div>
          <TextLabel color="default2" size={14} component="p">
            {CONSUMOTOTAL}
          </TextLabel>
          <TextLabel color="default2" bold size={14} component="p">
            {` ${TotalConsumidoFormula}`}
          </TextLabel>
        </div>
      </Tooltip>
      {TotalConsumidoFormula === 0 && (
        <div>
          <TextLabel color="error" size={10.5} component="p">
            {SEMCOLETAS}
          </TextLabel>
        </div>
      )}
      <Tooltip
        style={{ cursor: "pointer" }}
        title="Cada dia que um animal permanece em
        um pasto com formulação, é contado como uma diária.
        Bezerros de idade 0 a 8 meses contam como meia diária."
        arrow
      >
        <div>
          <TextLabel color="default2" size={14} component="p">
            {DIARIAS}
          </TextLabel>
          <TextLabel color="default2" bold size={14} component="p">
            {` ${TamanhoRebanho}`}
          </TextLabel>
        </div>
      </Tooltip>
      <div>
        <TextLabel color="default2" size={14} component="p">
          {QUANTIDADEABASTECIDA}
        </TextLabel>
        <TextLabel color="default2" bold size={14} component="p">
          {ehFechamentoConsumoDoBDW()
            ? ` ${QuantidadeAbastecida}`
            : ` ${(TotalConsumidoFormula || 0) / (Granel || PesoSaco)}`}

          {Granel ? " kg" : " sacos"}
        </TextLabel>
      </div>
    </React.Fragment>
  );
};

const ConsumoTooltip = ({
  dataInicioConsumo,
  dataFimConsumo,
  dataPrimeiroAbastecimento,
  dataUltimoAbastecimento,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        fontFamily: "Roboto",
        padding: "2px",
      }}
    >
      <div>
        <div>
          <strong>Início consumo:</strong>{" "}
          {dataInicioConsumo
            ? dayjs(dataInicioConsumo).format("DD/MM/YYYY")
            : "-"}
        </div>
        <div>
          <strong>Fim consumo:</strong>{" "}
          {dataFimConsumo ? dayjs(dataFimConsumo).format("DD/MM/YYYY") : "-"}
        </div>
      </div>
      <div>
        <div>
          <strong>Primeiro abastecimento:</strong>{" "}
          {dataPrimeiroAbastecimento
            ? dayjs(dataPrimeiroAbastecimento).format("DD/MM/YYYY")
            : "-"}
        </div>
        <div>
          <strong>Último abastecimento:</strong>{" "}
          {dataUltimoAbastecimento
            ? dayjs(dataUltimoAbastecimento).format("DD/MM/YYYY")
            : "-"}
        </div>
      </div>
    </div>
  );
};

export default React.memo(fechamentoConsumoInfo, (props, nextProps) => {
  return (
    props.consumoFormula.ConsumoOcorrido ===
    nextProps.consumoFormula.ConsumoOcorrido
  );
});
