import React from "react";

import { Typography, Grid, withStyles, Button } from "@material-ui/core";

export const CabecalhoFarol = (props) => {
  const {
    classes,
    exibirDetalhes,
    handleClose,
    limparTudo,
    exibirAlbumFotoMorte,
    pastoSelecionado,
    handleCloseAlbumFoto,
  } = props;
  const titulo = exibirAlbumFotoMorte
    ? pastoSelecionado.Nome
    : exibirDetalhes
    ? "Detalhes"
    : "Filtros";
  const textoBotao = exibirAlbumFotoMorte
    ? "Fechar"
    : exibirDetalhes
    ? "Voltar"
    : "Limpar";
  const acao = exibirAlbumFotoMorte
    ? handleCloseAlbumFoto
    : exibirDetalhes
    ? handleClose
    : limparTudo;
  return (
    <div>
      <Grid
        item
        xs={12}
        container
        className={classes.menuFarol}
        direction="row"
        justify="space-around"
      >
        <Grid item xs={6}>
          <Typography variant="h6" id="titulo">
            {titulo}
          </Typography>
          <div className={classes.linhaAzul} />
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          {!exibirAlbumFotoMorte && (
            <Button
              id="btn-superior"
              color="primary"
              style={{ borderRadius: "18px" }}
              onClick={acao}
            >
              {textoBotao}
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const styles = (theme) => {
  return {
    menuFarol: {
      textAlign: "left",
      color: "#444444",
      minHeight: "50px",
      fontSize: "18px",
      letterSpacing: "0px",
      borderBottom: "1px solid rgba(0,0,0,0.12)",
      padding: "24px",
    },
    linhaAzul: {
      minHeight: "2px",
      minWidth: "20px",
      display: "inline-block",
      backgroundColor: "#0053a1",
      borderRadius: "2px",
    },
  };
};

export default withStyles(styles)(CabecalhoFarol);
