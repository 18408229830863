import { Tabs, withStyles } from "@material-ui/core";
import React from "react";

export const ManejoTabs = withStyles((theme) => ({
  tabs: {
    minHeight: "33px",
    borderBottom: "1px solid #e0e0e0",
  },
  tabIndicator: {
    backgroundColor: theme.palette.primary.main,
    width: "30% !important",
  },
}))((props) => (
  <Tabs
    {...props}
    fullWidth
    classes={{
      root: props.classes.tabs,
      indicator: props.classes.tabIndicator,
    }}
  />
));
