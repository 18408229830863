import React, { useEffect, useRef } from "react";
import { checkNullOrUndefined, formatPercentFieldGoals } from "features/goalPlan/goalPlanUtils";


const checkSize = (value) => `${value}`.length;

const initialIntervalValue = {
  lastValue: null,
  firstValue: null,
};

const IntervalInput = ({
  classes,
  testId,
  value = initialIntervalValue,
  onChangeInterval = () => { },
  disabled = false,
  goalLine,
}) => {
  const inputMinRef = useRef(null);
  const inputMaxRef = useRef(null);
  const inputMinInitialOffset = useRef(null);
  const inputMaxInitialOffset = useRef(null);

  const { indicatorUnit, indicatorName } = goalLine;
  const checkInputValue = (value, atribute) => {
    if (checkNullOrUndefined(value[atribute]) && indicatorUnit.includes("%")) {
      return formatPercentFieldGoals(value[atribute], indicatorName);
    }
    if (value && checkNullOrUndefined(value[atribute])) {
      return value[atribute];
    }
    return ""
  };


  const adjustMinInputSize = (inputValue) => {
    if (!checkNullOrUndefined(inputValue)) return;
    if (
      !checkNullOrUndefined(inputMinRef.current) ||
      !checkNullOrUndefined(inputMaxRef.current)
    )
      return;

    let sizeInputMin = checkSize(inputValue) * 8 + 4;
    if (inputMinInitialOffset.current === null) {
      inputMinInitialOffset.current = inputMinRef.current.offsetWidth;
    }

    if (sizeInputMin < inputMinInitialOffset.current) {
      sizeInputMin = inputMinInitialOffset.current;
    }

    inputMinRef.current.style.width = sizeInputMin + "px";
  };

  const adjustMaxInputSize = (inputValue) => {
    if (!checkNullOrUndefined(inputValue)) return;
    if (
      !checkNullOrUndefined(inputMinRef.current) ||
      !checkNullOrUndefined(inputMaxRef.current)
    )
      return;
    let sizeInputMax = checkSize(inputValue) * 8 + 4;

    if (inputMaxInitialOffset.current === null) {
      inputMaxInitialOffset.current = inputMaxRef.current.offsetWidth;
    }

    if (sizeInputMax < inputMaxInitialOffset.current) {
      sizeInputMax = inputMaxInitialOffset.current;
    }

    inputMaxRef.current.style.width = sizeInputMax + "px";
  };

  useEffect(() => {
    if (value.firstValue) {
      adjustMinInputSize(value.firstValue);
    }
    if (value.lastValue) {
      adjustMaxInputSize(value.lastValue);
    }
  }, []);

  const changeBelow = (event) => {
    const goalValue = event.target.value
      ? event.target.value
      : undefined;
    const copyCurrentValue = {
      firstValue: goalValue,
      lastValue: value.lastValue,
    };
    onChangeInterval(copyCurrentValue);
    adjustMinInputSize(event.target.value || "");
  };

  const changeAbove = (event) => {
    const goalValue = event.target.value
      ? event.target.value
      : undefined;
    const copyCurrentValue = {
      firstValue: value.firstValue,
      lastValue: goalValue,
    };
    onChangeInterval(copyCurrentValue);
    adjustMaxInputSize(event.target.value || "");
  };

  return (
    <div
      data-testid={testId}
      disabled={disabled}
      className={classes.inputIntervalWrapper}
    >
      <input
        disabled={disabled}
        className={classes.inputInterval}
        ref={inputMinRef}
        type={indicatorUnit.includes("%") ? "number" : "text"}
        value={checkInputValue(value, "firstValue")}
        onChange={changeBelow}
        dir="rtl"
      />
      <span className={classes.separator}>/</span>
      <input
        disabled={disabled}
        className={classes.inputInterval}
        ref={inputMaxRef}
        type={indicatorUnit.includes("%") ? "number" : "text"}
        value={checkInputValue(value, "lastValue")}
        onChange={changeAbove}
      />
    </div>
  );
};

export default IntervalInput;
