import React from "react";
import ObjetivoPage from "./objetivoPage";
import * as ObjetivoOperations from "../redux/objetivoOperations";
import { connect } from "react-redux";

export const ObjetivoContainer = (props) => {
  return (
    <ObjetivoPage
      id="objetivoPage"
      fazendaSelecionada={props.fazendaSelecionada}
      listarObjetivos={props.listarObjetivos}
      criarObjetivo={props.criarObjetivo}
      deletarObjetivo={props.deletarObjetivo}
      atualizarObjetivo={props.atualizarObjetivo}
      ativaObjetivo={props.ativaObjetivo}
      objetivos={props.objetivos}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    fazendaSelecionada: state.fazenda.FazendaSelecionada,
    objetivos: state.objetivo.Objetivos,
  };
};

const mapDispatchToProps = {
  listarObjetivos: ObjetivoOperations.listarObjetivos,
  criarObjetivo: ObjetivoOperations.criarObjetivo,
  atualizarObjetivo: ObjetivoOperations.atualizarObjetivo,
  deletarObjetivo: ObjetivoOperations.deletarObjetivo,
  ativaObjetivo: ObjetivoOperations.ativaObjetivo,
};

export default connect(mapStateToProps, mapDispatchToProps)(ObjetivoContainer);
