import {
  notifyError,
  notifySuccess,
} from "../../../redux/actions/notificacaoActions";
import MovimentacaoManager from "../movimentacaoManager";
import _ from "lodash";
import {
  listarMovimentacoesFazenda,
  exibirCarregando,
  excluirMovimentacao,
  salvarMovimentacao,
  editarMovimentacao,
} from "./movimentacaoActions";

export const obterMovimentacoes = () => async (dispatch, getState) => {
  dispatch(exibirCarregando(true));
  try {
    const { fazenda } = getState();
    const movimentacoesFazenda =
      await MovimentacaoManager.obterMovimentacoesFazenda(
        fazenda.FazendaSelecionada
      );
    dispatch(listarMovimentacoesFazenda(movimentacoesFazenda));
  } catch (error) {
    console.log(error);
    dispatch(listarMovimentacoesFazenda([]));
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao obter a lista de entradas e saidas.",
      })
    );
  } finally {
    dispatch(exibirCarregando(false));
  }
};

export const criarMovimentacao =
  (movimentacao) => async (dispatch, getState) => {
    try {
      const { fazenda } = getState();
      movimentacao.IdFazenda = fazenda.FazendaSelecionada;
      const movimentacaoCriadaResposta =
        await MovimentacaoManager.salvarMovimentacao(movimentacao);

      dispatch(salvarMovimentacao(movimentacaoCriadaResposta));
      dispatch(
        notifySuccess({
          mensagem: "Movimentação criada com sucesso.",
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(
        notifyError({
          mensagem: "Ocorreu um erro ao criar o registro.",
        })
      );
    }
  };

export const atualizarMovimentacao = (movimentacao) => async (dispatch) => {
  try {
    const id = movimentacao.IdRegistro;
    await MovimentacaoManager.editarMovimentacao(movimentacao, id);
    dispatch(editarMovimentacao());
    dispatch(
      notifySuccess({
        mensagem: "Movimentação editada com sucesso.",
      })
    );
  } catch (error) {
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao editar a Movimentação.",
      })
    );
  }
};

export const deletarMovimentacao = (IdRegistro) => async (dispatch) => {
  try {
    await MovimentacaoManager.excluirMovimentacao(IdRegistro);
    dispatch(excluirMovimentacao());
    dispatch(
      notifySuccess({
        mensagem: "Movimentação excluída com sucesso.",
      })
    );
  } catch (error) {
    if (
      !_.isNil(error.response) &&
      !_.isNil(error.response.data) &&
      !_.isNil(error.response.data.codigo) &&
      Number(error.response.data.codigo) === 406
    ) {
      dispatch(
        notifyError({
          mensagem: error.response.data.mensagem,
        })
      );
    } else {
      dispatch(
        notifyError({
          mensagem: "Ocorreu um erro ao excluir a Movimentação.",
        })
      );
    }
  }
};
