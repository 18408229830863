import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@material-ui/core';

export const DialogExclusaoPasto = props => {
  const { ModalConfirmacaoExclusao, ContemLote } = props;

  return ModalConfirmacaoExclusao ? (
    <Dialog
      id="modalConfirmacaoExclusao"
      open={ModalConfirmacaoExclusao}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={() => props.handleFechaModalExclusao()}
    >
      {ContemLote ?
        <React.Fragment>
          <DialogTitle id="alert-dialog-modal-exclusao-title">
            {'Não é possível excluir esse pasto.'}
          </DialogTitle>
          <DialogContent id="alert-dialog-description">
            <DialogContentText>
              {'Existem Lotes relacionados. É necessário movimentar esses lotes para outro pasto.'}
            </DialogContentText>
          </DialogContent>
        </React.Fragment>
        :
        <React.Fragment>
          <DialogTitle id="alert-dialog-modal-exclusao-title">
            {'Deseja confirmar a exclusão do pasto?'}
          </DialogTitle>
          <DialogContent id="alert-dialog-description">
            <DialogContentText>
              {'Ao confirmar a exclusão você não verá mais as informações deste pasto.'}
            </DialogContentText>
          </DialogContent>
        </React.Fragment>
      }

      <DialogActions>
        <Button id="botaoCancelar" onClick={() => props.handleFechaModalExclusao()} color="primary" autoFocus>
          CANCELAR
        </Button>
        {!ContemLote ? <Button id="botaoConfirmar" onClick={() => props.handleExcluiPasto()} color="primary">
          CONFIRMAR
        </Button> : ''}
      </DialogActions>
    </Dialog>
  ) : null;
};

DialogExclusaoPasto.propTypes = {
  ModalConfirmacaoExclusao: PropTypes.bool.isRequired,
  handleFechaModalExclusao: PropTypes.func.isRequired,
  handleExcluiPasto: PropTypes.func.isRequired
};

export default DialogExclusaoPasto;
