import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Marker } from "@react-google-maps/api";
import Tooltip from "../Tooltip";
import calculaCoordenadaBotao from "../../../../../lib/calculaCoordenadaBotao";
import CalculadoraCoordenadas from "../../../../../lib/calculadoraCoordenadas";
import Poligono from "../Poligono";
import { coresModulos } from "../../../../../constants/coresModulos";
import { getAnimalsQuantity } from "../../PasturesUtils";

class PastosCochoCompartilhado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseOver: false,
    };
  }

  onMouseOverChange = (over) => {
    this.setState({ ...this.state, mouseOver: over });
  };

  render() {
    return (
      <div>
        {this.renderPoligonos()}
        {this.renderBotaoFechar()}
        {this.renderInfo()}
      </div>
    );
  }

  renderPoligonos = () => {
    const { Pastos, selecionado, bloqueado, corSelecionada } = this.props;
    return _.map(Pastos, (pasto) => {
      return (
        <Poligono
          key={pasto.IdPasto}
          onClick={(e) => this.props.onClick(e)}
          coordenadas={pasto.Coordenadas}
          cor={coresModulos[selecionado ? corSelecionada : pasto.Cocho.Cor]}
          onMouseOverChange={(over) => this.onMouseOverChange(over)}
          highlight={this.state.mouseOver || selecionado}
          block={bloqueado}
          raised={selecionado}
        />
      );
    });
  };

  renderBotaoFechar = () => {
    if (!this.props.selecionado) return null;

    const position = calculaCoordenadaBotao(
      _.flatMap(this.props.Pastos, (pasto) => {
        return pasto.Coordenadas;
      })
    );

    return (
      <Marker
        position={position}
        options={{
          icon: {
            url: "/images/botaoFechar.svg",
            scaledSize: { height: 30, width: 30 },
          },
        }}
        onClick={() => {
          this.props.onClose();
        }}
      />
    );
  };
  renderInfo = () => {
    const { pasturesQuantity, Pastos } = this.props;

    const animalsQuantity = getAnimalsQuantity(Pastos, pasturesQuantity);

    const renderTooltipText = () => {
      const getPasturesNames = (pastures) => {
        return pastures.map((pasture) => pasture.Nome).join(", ");
      };
      return (
        "Nome dos pastos: " +
        getPasturesNames(Pastos) +
        "\n" +
        "Quantidade de animais: " +
        animalsQuantity
      );
    };

    if (this.props.selecionado === false && this.state.mouseOver) {
      const position = CalculadoraCoordenadas.calcularCentroAlto(
        _.flatMap(this.props.Pastos, (pasto) => {
          return pasto.Coordenadas;
        })
      );
      return (
        <Tooltip
          Posicao={position}
          Texto={renderTooltipText()}
          title={"Cocho compartilhado:"}
          adornmentColor={true}
        />
      );
    } else {
      return <div />;
    }
  };
}

PastosCochoCompartilhado.propTypes = {
  Modulo: PropTypes.object.isRequired,
  Pastos: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  zoomMapa: PropTypes.number,
  selecionado: PropTypes.bool.isRequired,
  bloqueado: PropTypes.bool.isRequired,
  corSelecionada: PropTypes.string.isRequired,
};

export default PastosCochoCompartilhado;
