import React from "react";
import { Button, Paper, withStyles, Grid } from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Title from "./title";

const backButton = (onClick) =>
  onClick && (
    <Button onClick={onClick} style={{ marginLeft: -23 }}>
      <ArrowBack color="primary" />
    </Button>
  );

const PlanningContent = ({
  testid,
  classes,
  children,
  title,
  onBack,
  headerInfo,
}) => {
  return (
    <Paper data-testid={testid} className={classes.planningListContainer}>
      <Grid container alignItems="center" className={classes.tituloContainer}>
        {backButton(onBack)}
        <Title>{title}</Title>
        {headerInfo && <span className={classes.headerInfo}>{headerInfo}</span>}
      </Grid>

      {children}
    </Paper>
  );
};

const styles = (theme) => ({
  tituloContainer: {
    marginTop: theme.telas.marginTopTitulo,
  },
  planningListContainer: {
    ...theme.telas.telaContainer,
    display: "flex",
    flexDirection: "column",
  },
  button: {
    margin: "none",
  },
  headerInfo: {
    marginLeft: "auto",
    alignSelf: "center",
    textAlign: "right",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 600,
  },
});

export default withStyles(styles)(PlanningContent);
