import BaseService from "./BaseService";
const URL_BASE = `${process.env.REACT_APP_ENDPOINT}herds`;
class LoteService extends BaseService {
  /*
    Não é necessário mais do que um lote
    para exibir a mensagem. E os campos não são utilizados.
  */
  async obtemLotesEntrevero(idFazenda) {
    const urlServico = `${URL_BASE}/interfusions/${idFazenda}`;
    return await this.get(urlServico).then((response) => ({
      data: response.data.data
    }));
  }
}

export default new LoteService();
