export default theme => ({
  modalGeral: {
    marginRight: '-50%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    borderRadius: '2.4px',
    backgroundColor: '#ffffff',
    position: 'relative'
  },
  fechar: {
    width: '24px',
    height: '24px',
    right: '20px',
    top: '20px',
    cursor: 'pointer'
  },
  imagem: {
    left: '40px',
    top: '40px',
    width: 'auto'
  },
  titulo: {
    fontSize: '18px',
    fontWeight: '500',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.1px',
    color: 'rgba(51, 50, 56, 0.84)'
  },
  mensagem: {
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normaL',
    lineHeight: 'normal',
    letterSpacing: '0px',
    color: '#8e8e93'
  },
  personalizado: {
    top: '184px',
    left: '40px',
    width: '410px'
  }
});
