import React, { useState } from "react";
import localConstants from "../views/shared/constants";
import planningService from "features/planning/planningService";
import { fillCreatePlanningItems } from "../views/shared/utils";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const useSubmitUtils = ({
  farmId,
  enableLoading,
  disableLoading,
  notifyError,
  notifySuccess,
  callbackSubmit,
}) => {
  const [showConfirmationModal, setConfirmationModal] = useState(null);

  const onCloseConfirmModal = () => {
    setConfirmationModal(null);
  };

  const showModalError = (title, text) => {
    setConfirmationModal({
      title,
      text,
      buttonConfirmText: "Continuar",
      onConfirm: onCloseConfirmModal,
      onCancel: null,
    });
  };

  const saveMessage = (formattedInitialDate, formattedFinalDate) => (
    <span>
      Não foi possível salvar o planejamento, pois já existe outro com o período{" "}
      <strong>{formattedInitialDate}</strong> a{" "}
      <strong>{formattedFinalDate}</strong>. Por favor, escolha outra data para
      salvar.
    </span>
  );

  const editMessage = (formattedInitialDate, formattedFinalDate) => (
    <span>
      Não foi possível salvar as alterações, pois já existe um planejamento para
      o período selecionado <strong>{formattedInitialDate}</strong> a{" "}
      <strong>{formattedFinalDate}</strong>.. Por favor, selecione outra data
      para continuar.
    </span>
  );

  const showErrorModalDateExist = (planning) => {
    const formattedInitialDate = dayjs
      .utc(planning.initialDate)
      .format(localConstants.dateShortFormat);
    const formattedFinalDate = dayjs
      .utc(planning.finalDate)
      .format(localConstants.dateShortFormat);
    const title = planning.id ? "Editar planejamento" : "Salvar planejamento";
    const text = planning.id
      ? editMessage(formattedInitialDate, formattedFinalDate)
      : saveMessage(formattedInitialDate, formattedFinalDate);
    showModalError(title, text);
  };

  const checkErrorPlanning = (error, planning) => {
    if (
      error.response &&
      error.response.status === 400 &&
      error.response.data &&
      error.response.data.message
    ) {
      let periodAlreadyExists = error.response.data.message.includes(
        "period has already been saved"
      );
      if (periodAlreadyExists) {
        showErrorModalDateExist(planning);
      } else {
        notifyError(error.response.data.message);
      }
    }
  };

  const checkNotifyError = (error) => {
    if (error.response && error.response.status === 500) {
      notifyError("Ocorreu um erro ao salvar o plano, tente novamente.");
    }
  };

  function createPlanData(
    planningList,
    summaryReport,
    formulas,
    ingredientsPrices,
    farmId,
    initialDate,
    finalDate
  ) {
    const planningItems = fillCreatePlanningItems(planningList);
    const planningCategories = summaryReport.categoryPlanning.categories.map(
      (item) => item.category
    );
    const planningFormulasIds = summaryReport.formulaPlanning.map(
      (item) => item.formula.id
    );
    const planningFormulas = formulas
      .filter((formula) => planningFormulasIds.includes(formula.IdFormula))
      .map((formula) => ({
        id: formula.IdFormula,
        name: formula.Nome,
        ingredients: formula.Ingrediente.map((i) => ({
          id: i.OperacaoIngrediente.IdIngrediente,
          name: i.OperacaoIngrediente.Descricao,
        })),
      }));
    const ingredientCosts = summaryReport.ingredientPlanning
      .filter((item) => ingredientsPrices[item.ingredient.id])
      .map((item) => ({
        ingredientId: item.ingredient.id,
        ingredientName: item.ingredient.name,
        cost: ingredientsPrices[item.ingredient.id],
      }));

    const planningDataToRegister = {
      farmId: farmId,
      initialDate: dayjs
        .utc(initialDate)
        .startOf("day")
        .startOf("month")
        .toISOString(),
      finalDate: dayjs
        .utc(finalDate)
        .startOf("day")
        .startOf("month")
        .toISOString(),
      planningItems,
      formulas: planningFormulas,
      categories: planningCategories,
      ingredientCosts,
      total: summaryReport.planning.total,
      reports: {
        formulaPlanning: summaryReport.formulaPlanning,
        ingredientPlanning: summaryReport.ingredientPlanning,
        categoryPlanning: summaryReport.categoryPlanning,
      },
    };
    return planningDataToRegister;
  }

  const onCreatePlan = async ({
    initialDate,
    finalDate,
    planningList,
    ingredientsPrices,
    summaryReport,
    formulas,
  }) => {
    const planningDataToRegister = createPlanData(
      planningList,
      summaryReport,
      formulas,
      ingredientsPrices,
      farmId,
      initialDate,
      finalDate
    );

    try {
      enableLoading();
      await planningService.postPlan(planningDataToRegister);
      callbackSubmit && callbackSubmit();
      notifySuccess("Plano adicionado com sucesso!");
    } catch (error) {
      checkErrorPlanning(error, planningDataToRegister);
      checkNotifyError(error);
    } finally {
      disableLoading();
    }
  };

  const onEditPlan = async (
    planningCode,
    {
      initialDate,
      finalDate,
      planningList,
      ingredientsPrices,
      summaryReport,
      formulas,
    }
  ) => {
    const planningDataToRegister = createPlanData(
      planningList,
      summaryReport,
      formulas,
      ingredientsPrices,
      farmId,
      initialDate,
      finalDate
    );

    try {
      enableLoading();
      await planningService.postNewVersion(
        planningCode,
        planningDataToRegister
      );
      callbackSubmit && callbackSubmit();
      notifySuccess("Plano editado com sucesso!");
    } catch (error) {
      checkErrorPlanning(error, planningDataToRegister);
      checkNotifyError(error);
    } finally {
      disableLoading();
    }
  };

  return [
    showConfirmationModal,
    {
      onCreatePlan,
      onEditPlan,
      onCloseConfirmModal,
    },
  ];
};
