import React from "react";
import { withStyles } from "@material-ui/core";

const CadastroIngredienteWrapper = (props) => {
  const { children, classes } = props;
  return (
    <div className={classes.cadastroIngredienteWrapper}>
      {children}
    </div>
  );
}

const styles = (theme) => {
  return {
    cadastroIngredienteWrapper: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
    }
  }
}


export default withStyles(styles)(CadastroIngredienteWrapper);
