import FaixaIdadeManager from "../faixaIdadeManager";
import { notifyError } from "../../../redux/actions/notificacaoActions";
import { listarFaixasIdade } from "./faixaIdadeActions";

export const obterFaixas = () => async (dispatch) => {
  try {
    const faixasIdade = await FaixaIdadeManager.obterFaixaIdade();
    dispatch(listarFaixasIdade(faixasIdade));
  } catch (error) {
    dispatch(
      notifyError({
        mensagem: "Ocorreu um erro ao obter faixas de idade",
      })
    );
  }
};
