import React from "react";
import {
  TextField,
  Typography,
  Button,
  FormControl,
  withStyles,
} from "@material-ui/core";

export const ObjetivoForm = (props) => {
  const {
    classes,
    objetivo,
    erroNome,
    handleChangeNomeObjetivo,
    adicionarObjetivo,
    habilitarObjetivo,
  } = props;

  return (
    <div className={classes.objetivoFormContainer}>
      <Typography className={classes.tituloObjetivoForm} gutterBottom>
        {objetivo.IdObjetivo ? "Editar Objetivo" : "Novo Objetivo de lote"}
      </Typography>
      <Typography className={classes.subtituloObjetivoForm} gutterBottom>
        Informações gerais
      </Typography>
      <FormControl className={classes.textoCadastro}>
        <TextField
          value={objetivo.Nome}
          autoFocus
          inputProps={{ maxLength: 20 }}
          id={"adornment-nome"}
          required
          label={"Nome do objetivo"}
          error={erroNome}
          helperText={erroNome ? "Erro - Nome já cadastrado" : ""}
          onChange={(value) => handleChangeNomeObjetivo(value)}
        />
      </FormControl>
      <div className={classes.botoesFormInline}>
        <Button
          id="habilitarObjetivo"
          color="primary"
          className={classes.botaoObjetivoForm}
          onClick={() => habilitarObjetivo(false)}
        >
          Cancelar
        </Button>
        <Button
          id="botaoAdicionar"
          disabled={objetivo.Nome === "" || erroNome}
          variant="contained"
          color="primary"
          onClick={() => adicionarObjetivo(objetivo)}
          className={classes.botaoObjetivoForm}
        >
          {objetivo.IdObjetivo ? "Salvar" : "Adicionar"}
        </Button>
      </div>
    </div>
  );
};

const styles = () => {
  return {
    objetivoFormContainer: {
      display: "flex",
      flexDirection: "Column",
    },
    tituloObjetivoForm: {
      fontFamily: "Roboto",
      fontSize: 20,
      fontWeight: "bold",
      color: "#212121",
    },
    subtituloObjetivoForm: {
      fontFamily: "Roboto",
      marginTop: 9,
      fontSize: 14,
      fontWeight: "bold",
      color: "#757575",
      marginBottom: 12,
    },
    textoCadastro: {
      maxWidth: 200,
    },
    botoesFormInline: {
      marginTop: 32,
    },
    botaoObjetivoForm: {
      marginRight: "20px",
      boxShadow: "none",
      heigth: 35,
    },
  };
};

export default withStyles(styles)(ObjetivoForm);
