import moment from "moment";
moment.locale("pt-BR");

export const validaData = (data, formatoValidar) => {
  const dataValida = moment(data, formatoValidar, true);
  const dataMenorQueDataAtual = validaDatasMenorIgual(
    dataValida,
    moment().format(formatoValidar)
  );
  return dataValida._isValid && dataMenorQueDataAtual;
};

export const diferencaEntreDatas = (dataInicial, dataFinal, unidadeTempo) => {
  dataInicial = moment(dataInicial, "DD/MM/YYYY");
  dataFinal = moment(dataFinal, "DD/MM/YYYY");
  const duracao = moment.duration(dataFinal.diff(dataInicial));

  let diferenca = 0;
  switch (unidadeTempo) {
    case "horas":
      diferenca = duracao.asHours();
      break;

    case "dias":
      diferenca = dataFinal.diff(dataInicial, "days");
      break;

    case "meses":
      diferenca = duracao.asMonths();
      break;
    case "anos":
      diferenca = duracao.asYears();
      break;

    default:
      break;
  }

  return diferenca;
};

export const validaDatasMenorIgual = (primeiraData, segundaData) => {
  primeiraData = moment(primeiraData, "DD/MM/YYYY");
  segundaData = moment(segundaData, "DD/MM/YYYY");
  return primeiraData.isSameOrBefore(segundaData);
};

export const formatarData = (
  data,
  opcoesDeFormato = { day: "2-digit", month: "2-digit", year: "numeric" }
) => {
  const dataObj = new Date(data);
  const formatoDaData = new Intl.DateTimeFormat("pt-BR", opcoesDeFormato);

  return formatoDaData.format(dataObj);
};
