import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography, FormControl, TextField, Button, withStyles } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/pt-br";
const locale = "pt-BR";
moment.locale(locale);

export const PesagemCategoriaHeader = ({
  classes,
  editar,
  criarNovoEventoPesagem,
  criarNovo,
  pesagens,
}) => {
  const [controleData, setControleData] = useState(moment());
  const [erroData, setErroData] = useState(false);
  const [controleNomeDoEvento, setControleNomeDoEvento] = useState("");
  const [exibirMensagem, setExibirMensagem] = useState(false);

  const regexData = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];

  useEffect(() => {
    if (
      pesagens.some((pesagem) =>
        moment(pesagem.DataPesagem).isSame(
          moment(controleData).format("YYYY-MM-DD")
        )
      )
    ) {
      setErroData(true);
      setExibirMensagem(true);
    } else {
      setErroData(false);
      setExibirMensagem(false);
    }
  }, [pesagens]);

  const alteraData = (value) => {
    if (
      pesagens.some((pesagem) =>
        moment(pesagem.DataPesagem).isSame(moment(value).format("YYYY-MM-DD"))
      )
    ) {
      setErroData(true);
      setExibirMensagem(true);
    } else {
      setErroData(false);
      setExibirMensagem(false);
    }
    setControleData(value);
  };
  return (
    <React.Fragment>
      <Grid item className={classes.containerPage}>
        <Typography
          id="pesagemCategoriaTitulo"
          className={classes.titulo}
          gutterBottom
        >
          Cadastro de Pesagem por Categoria
        </Typography>
      </Grid>
      {!editar && !criarNovo && (
        <Grid>
          <Grid
            container
            alignItems={"center"}
            direction={"row"}
            xs={12}
            md={12}
            className={classes.menssageAlerta}
          >
            <Typography className={classes.textoAlerta}>
              Insira a data e o evento do dia da pesagem para criar um novo
              cadastro.
            </Typography>
          </Grid>
          <Grid
            container
            xs={12}
            md={12}
            direction="row"
            justify="space-between"
          >
            <Grid style={{ display: "flex" }} item>
              <Grid item>
                <FormControl id="formEvento" className={classes.textoBuscar}>
                  <TextField
                    className={classes.textField}
                    id="nomeEventoPesagem"
                    inputProps={{
                      "data-testid": "nomeEventoPesagem",
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    focused
                    label={"Evento (nome que será dado a pesagem)"}
                    style={{ minWidth: "300px", marginRight: "40px" }}
                    value={controleNomeDoEvento}
                    onChange={({ target: { value } }) =>
                      setControleNomeDoEvento(value)
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <Grid item>
                  <MuiPickersUtilsProvider
                    locale={locale}
                    moment={moment}
                    utils={MomentUtils}
                  >
                    <DatePicker
                      disableFuture
                      keyboard
                      mask={(value) => (value ? regexData : [])}
                      disableOpenOnEnter
                      id="data-pesagem-DataPicker"
                      data-testid="data-pesagem-DataPicker"
                      label="Data da Pesagem"
                      onChange={alteraData}
                      invalidDateMessage="O Formato da data é inválido"
                      minDateMessage="A data selecionada é inválida"
                      maxDateMessage="A data selecionada é inválida"
                      format="DD/MM/YYYY"
                      placeholder="dd/mm/aaaa"
                      value={controleData}
                      onError={() => setErroData(true)}
                      onInputChange={(e) => {
                        if (
                          e.target.value.includes("_") >= 0 ||
                          e.target.value === ""
                        ) {
                          setExibirMensagem(false);
                        }
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                  {exibirMensagem && (
                    <div>
                      <Typography className={classes.textoAlerta}>
                        Esta data já possui cadastro, escolha
                        <br />
                        outra data ou edite a existente.
                      </Typography>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                id="botaoCriarEvento"
                data-testid="botaoCriarEvento"
                onClick={() =>
                  criarNovoEventoPesagem(controleNomeDoEvento, controleData)
                }
                variant="contained"
                color="primary"
                style={{ height: "41px" }}
                disabled={!controleNomeDoEvento || !controleData || erroData}
              >
                CRIAR NOVO
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

const styles = () => ({
  containerPage: {
    flexgrow: 1,
    height: "100%",
    backgroundColor: "#fff",
    width: "100%",
  },
  titulo: {
    fontSize: "20px",
    fontWeight: "bold",
    fontStyle: "medium",
    fontStretch: "medium",
    lineHeight: "medium",
    letterSpacing: "medium",
    color: "#333333",
    marginTop: "40px",
  },
  textoAlerta: {
    color: "#424242",
    fontSize: 12,
    fontFamily: "Roboto",
    alignSelf: "flex-end",
  },
  menssageAlerta: {
    marginTop: 30,
    marginBottom: 30,
  },
});

export default withStyles(styles)(PesagemCategoriaHeader);
