import { withStyles } from "@material-ui/core";
import React from "react";
import { tableStyles } from "./tableStyle";

const PlanningListTotalItem = ({ classes, total }) => {
  return <span className={classes.cellItemBold}>{total} cab</span>;
};
const styles = (theme) => ({
  cellItemBold: tableStyles(theme).cellItemBold,
});

export default withStyles(styles)(PlanningListTotalItem);
