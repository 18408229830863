import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { useFarmAndRetreatLink } from "../../../../hooks/useFarmAndRetreatLink";

import SelectDropdown from "components/SelectDropdown";
import SkeletonLoading from "features/shared/components/SkeletonLoading";
import TabelaProdap from "features/shared/components/TabelaProdap";

function FarmAndRetreatLink({ classes, data, onAdd, onRemove }) {
  const {
    loading,
    error,
    farms,
    retreats,
    selectedFarm,
    selectedRetreats,
    onAddLink,
    onRemoveLink,
    onChange,
  } = useFarmAndRetreatLink(data, onAdd, onRemove);

  const retreatsNoOptionsLabel = selectedFarm
    ? "Nenhum retiro disponível"
    : "Selecione uma fazenda";

  if (loading) {
    return (
      <div>
        <SkeletonLoading width={310} height={24} />
        <SkeletonLoading width={769} height={53} marginTop={24} />
      </div>
    );
  }

  return (
    <div>
      <span className={classes.title}>
        Selecione as fazendas que o usuário terá acesso
      </span>

      <div className={classes.selectContainer}>
        <SelectDropdown
          isSearchable
          label={{ text: "Fazenda" }}
          styles={{ width: "300px" }}
          options={farms}
          value={selectedFarm}
          onChange={onChange("farm")}
          error={error.farm || data.error}
        />
        <SelectDropdown
          isSearchable
          isMulti
          label={{ text: "Retiros" }}
          overrideStrings={{
            noOptions: retreatsNoOptionsLabel,
          }}
          styles={{ maxWidth: "500px" }}
          options={retreats}
          value={selectedRetreats}
          onChange={onChange("retreats")}
          error={error.retreats || data.error}
        />
        <button className={classes.addButton} onClick={onAddLink}>
          ADICIONAR
        </button>
      </div>

      {data && data.value.length > 0 && (
        <div className={classes.tableContainer}>
          <TabelaProdap
            tabelaEsquerda
            ativarPaginacao={false}
            modoOrdenacao="CLIENTE"
            colunas={[
              {
                id: "farm",
                titulo: "FAZENDA",
                ordenavel: true,
                estilo: { width: "25%" },
                pegaValor: (linha) => linha.farm.name,
              },
              {
                id: "retreats",
                titulo: "RETIROS",
                ordenavel: false,
                estilo: { width: "70%" },
                pegaValor: (linha) =>
                  linha.retreats.map((retreat) => retreat.name).join(", "),
              },
              {
                id: "remove",
                titulo: "REMOVER",
                ordenavel: false,
                estilo: { width: "5%" },
                pegaValor: (linha) => (
                  <div className={classes.removeButton}>
                    <button
                      style={{
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                      }}
                      onClick={() => onRemoveLink(linha.farm.id)}
                    >
                      <img src="/images/trash.svg" alt="Remover" />
                    </button>
                  </div>
                ),
              },
            ]}
            linhas={data.value}
          />
        </div>
      )}
    </div>
  );
}

const styles = (theme) => ({
  title: {
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: "20px",
    color: "#444444",
  },
  selectContainer: {
    marginTop: "24px",
    display: "flex",
    gap: "32px",
    alignItems: "center",
  },
  addButton: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    color: "#ffffff",
    backgroundColor: theme.palette.primary.main,
    padding: "12px 16px",
    borderRadius: "18px",
    border: "none",
    cursor: "pointer",
  },
  removeButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  tableContainer: {
    marginTop: "40px",
  },
});

export default withStyles(styles)(FarmAndRetreatLink);
