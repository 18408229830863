import axios from "axios";
import { getFazendaSelecionadaStorage } from "shared/storageUtils";

export const createServiceHandler = () => {
  const instance = axios.create({
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      usuario: localStorage.getItem("Email"),
      user: localStorage.getItem("Login"),
      userId: localStorage.getItem("IdUsuario"),
      farmId: getFazendaSelecionadaStorage(),
      userEmail: localStorage.getItem("Email")
    },
  })

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        window.location.href = '/login';
        localStorage.clear();
        return Promise.resolve({});
      }
      return Promise.reject(error);
    }
  );

  return instance;
};
