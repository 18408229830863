import React from "react";
import {
  withStyles,
  TableCell,
  Typography,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { obterEstiloDiferenca } from "../conciliacaoUtil";
import { FormatListBulleted } from "@material-ui/icons";
import PropTypes from "prop-types";

export const ConciliacaoItemTabela = (props) => {
  const {
    classes,
    fieldName,
    valorApp,
    valorPortal,
    uniqueKey,
    camposUnicos,
    camposUnicosApps,
    exibirDescritivoModal,
    obterDescritivoDeColetas,
    dado,
    minimizado,
  } = props;
  const abreDescritivoDeColetas = async () => {
    await obterDescritivoDeColetas(dado.CategoriaAnimal.IdCategoriaAnimal);
    exibirDescritivoModal(dado.CategoriaAnimal.Nome);
  };

  const textoValorApp = camposUnicosApps.find(
    (element) => element === fieldName
  )
    ? null
    : valorPortal;

  return (
    <TableCell
      key={`${uniqueKey}-TableCell`}
      padding="none"
      component="th"
      scope="row"
      align="left"
    >
      <Grid container style={{ justifyContent: "center" }} direction="row">
        <div className={classes.divBodyContent}>
          <Typography
            key={`${uniqueKey}-Typography`}
            className={
              minimizado
                ? classes.tabelaBodyContentMini
                : classes.tabelaBodyContent
            }
            style={obterEstiloDiferenca(fieldName, valorApp)}
          >
            {valorApp}
          </Typography>

          {!camposUnicos.find((element) => element === fieldName) ? (
            <Typography
              key={`${uniqueKey}-Typography`}
              className={
                minimizado
                  ? classes.tabelaBodyContent2Mini
                  : classes.tabelaBodyContent2
              }
              style={obterEstiloDiferenca(fieldName, valorApp)}
            >
              {textoValorApp}
            </Typography>
          ) : null}
        </div>
        {fieldName === "Diferenca" && !minimizado ? (
          <Tooltip title={"Ver Histórico de Operações"} placement="top" arrow>
            <IconButton
              size="small"
              disableRipple={true}
              classes={{
                root: classes.root,
              }}
              onClick={abreDescritivoDeColetas}
            >
              <FormatListBulleted className={classes.icone} />
            </IconButton>
          </Tooltip>
        ) : null}
      </Grid>
    </TableCell>
  );
};

export const styles = () => {
  return {
    tabelaBodyContent: {
      fontFamily: "Roboto",
      fontSize: "13.5px",
      fontWeight: "normal",
      color: "#333333",
      paddingLeft: "8px",
      paddingRight: "8px"
    },
    tabelaBodyContent2: {
      fontFamily: "Roboto",
      fontSize: "13.5px",
      fontWeight: "normal",
      color: "#333333",
      paddingLeft: "8px",
      paddingRight: "8px"
    },
    tabelaBodyContentMini: {
      fontFamily: "Roboto",
      fontSize: "12px",
      maxHeight: 66,
      display: "flex",
      alignItems: "center",
      fontWeight: "normal",
      color: "#333333",
      textAlign: "center",
    },
    tabelaBodyContent2Mini: {
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: "normal",
      color: "#333333",
      marginTop: "12px"
    },
    root: {
      height: 20,
      width: 20,
      left: 5,
    },

    icone: {
      height: 20,
      width: 20,
      color: "#1F77B4",
    },
    divBodyContent: {
      with: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
    },
  };
};

ConciliacaoItemTabela.propTypes = {
  fieldName: PropTypes.string,
  valorApp: PropTypes.number,
  uniqueKey: PropTypes.number,
  classes: PropTypes.object,
};

export const validacaoMemo = (prevProps, nextProps) => {
  const prevFieldValue = prevProps.valorApp;
  const nextFieldValue = nextProps.valorApp;
  const valorAntigoPortal = prevProps.valorPortal;
  const valorNovoPortal = nextProps.valorPortal;
  return (
    prevFieldValue === nextFieldValue && valorAntigoPortal === valorNovoPortal
  );
};

export default withStyles(styles)(
  React.memo(ConciliacaoItemTabela, validacaoMemo)
);
