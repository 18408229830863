import React from "react";
import { withStyles } from "@material-ui/core";
import RadioFarol from "./RadioFarol";
import BotaoExibirDetalhesFarol from "./btnExibirDetalhesFarol";
import { deveDesabilitarBotao } from "./FarolUtils";

export const farolItem = (props) => {
  const {
    farol,
    farolSelecionado,
    tipoIconeExibido,
    //classes,
    abrirDetalhes,
    exibirBotao,
    fazendaSelecionada,
    toggles,
  } = props;
  const desabilitaBotao = deveDesabilitarBotao(
    farolSelecionado,
    tipoIconeExibido
  );
  return (
    <>
      <RadioFarol
        farol={farol}
        farolSelecionado={farolSelecionado}
        tipoIconeExibido={tipoIconeExibido}
        fazendaSelecionada={fazendaSelecionada}
        toggles={toggles}
      />
      <BotaoExibirDetalhesFarol
        abrirDetalhes={abrirDetalhes}
        exibirBotao={exibirBotao}
        desabilitaBotao={desabilitaBotao}
      />
    </>
  );
};

const styles = {
  farolItem: { paddingBottom: 10 },
  selecionado: { backgroundColor: "rgba(193, 221, 248, 0.2)" },
};
export default withStyles(styles)(farolItem);
