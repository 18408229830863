import React from "react";
import UserListFilter from "./components/userListFilter";
import UserList from "./components/userList";
import { withStyles } from "@material-ui/core";
import { Button } from "@mui/material";
import styled from "@emotion/styled";
import { palette } from "styles/theme";

const NewUserButton = styled(Button)({
  width: "188px",
  height: "36px",
  borderRadius: "18px",
  background: "#307297",
  color: "white",
  "&:hover": {
    backgroundColor: palette.primary.main,
    filter: "brightness(0.8)",
  },
});

export const UserListPage = ({
  clientId,
  getUsers,
  lastUpdate,
  filter,
  onFilterChange,
  onEdit,
  onSendLink,
  onCopyLink,
  onNewUser,
  onChangeStatus,
  classes = {},
}) => {
  const isFilterEmpty =
    !filter ||
    (filter.busca === "" &&
      (filter.status === null || filter.status === 0) &&
      filter.idFazenda.length === 0 &&
      filter.idRetiro.length === 0 &&
      filter.permissao.length === 0);

  return (
    <div>
      <section className={classes.title}>Usuários cadastrados</section>
      <section className={classes.filter}>
        <UserListFilter
          filter={filter}
          onFilterChange={onFilterChange}
          clientId={clientId}
        />
        <NewUserButton onClick={onNewUser}>Criar novo usuário</NewUserButton>
      </section>
      <section className={classes.list}>
        <UserList
          isFilterEmpty={isFilterEmpty}
          getUsers={getUsers}
          lastUpdate={lastUpdate}
          onEdit={onEdit}
          onSendLink={onSendLink}
          onCopyLink={onCopyLink}
          onChangeStatus={onChangeStatus}
        />
      </section>
    </div>
  );
};

const styles = (theme) => ({
  root: {
    display: "flex",
    padding: "40px",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "23.44px",
    textAlign: "left",
    color: "#444444",
  },
  filter: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
    marginTop: "35px",
    "@media (max-width: 1024px)": {
      gridTemplateColumns: "auto",
      marginTop: "16px",
    },
  },
  list: {
    marginTop: "40px",
  },
});

export default withStyles(styles)(UserListPage);
