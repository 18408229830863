import React from "react";

export const SelectItem = ({ id, children, ...props }) => {
  return (
    <li value={id} {...props} style={styles.text}>
      {children}
    </li>
  );
};

const styles = {
  text: {
    color: "#212121",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "19px",
    letterSpacing: "0px",
    textAlign: "left",
  },
};
