import React from "react";
import { Button, Tooltip, withStyles } from "@material-ui/core";

const ActionButtons = ({
  classes,
  onSubmit,
  onBack,
  enableButtonSubmit = true,
  textButtonSubmit = "SALVAR",
  textButtonGoBack = "CANCELAR",
  tooltipMessage,
}) => {
  return (
    <div className={classes.actionButtonContainer}>
      <Button
        className={classes.cancelButton}
        variant="outlined"
        color="primary"
        onClick={onBack}
      >
        {textButtonGoBack}
      </Button>
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={tooltipMessage || ""}
        placement="left-start"
        enterDelay={500}
        leaveDelay={200}
      >
        <div>
          <Button
            className={classes.saveButton}
            variant="contained"
            data-testid="Action-Button-Submit"
            color="primary"
            onClick={onSubmit}
            disabled={!enableButtonSubmit}
          >
            {textButtonSubmit}
          </Button>
        </div>
      </Tooltip>
    </div>
  );
};

const styles = () => ({
  actionButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 50,
    gap: "16px",
  },
  cancelButton: {
    minWidth: 196,
    height: 36,
    fontSize: 14,
  },
  saveButton: {
    fontSize: 14,
    minWidth: 196,
    boxShadow: "none",
    height: 36,
    "&:disabled": {
      backgroundColor: "rgba(0, 83, 160, 0.5)",
      color: "#FFF",
    },
  },
  tooltip: {
    width: "325px",
    padding: "16px",
    borderRadius: "4px",
    fontFamily: "Roboto",
    fontSize: "14px",
  },
});

export default withStyles(styles)(ActionButtons);
