import React from "react";
import {
  Grid,
  TextField,
  Button,
  Typography,
  withStyles,
} from "@material-ui/core";
import SelectDropdown from "components/SelectDropdown";

export const CurralForm = ({
  classes,
  erroNome,
  curral,
  retiros,
  handleChangeForm,
  handleChangeNomeCurral,
  handleExibirForm,
  adicionarCurral,
}) => {
  const retreatOptions = retiros.map((retreat) => ({
    value: retreat.IdRetiro,
    label: retreat.Nome,
  }));

  const onChangeRetreat = (value) => {
    const newValue = {
      target: {
        value,
      },
    };
    handleChangeForm("IdRetiro", newValue);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.titulo} gutterBottom>
            {curral.IdPasto ? "Editar Curral" : "Novo Curral"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.subtitulo} gutterBottom>
            Dados básicos
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.formContainer}>
          <SelectDropdown
            testId="slc_Retiro"
            label={{ text: "Retiro", required: true }}
            options={retreatOptions}
            value={curral.IdRetiro}
            onChange={onChangeRetreat}
            styles={{ width: "200px" }}
          />
          <TextField
            value={curral.Nome}
            id={"Nome"}
            required
            label={"Nome do Curral"}
            error={erroNome}
            helperText={erroNome ? "Erro - Nome já cadastrado" : ""}
            onChange={handleChangeNomeCurral}
            className={classes.formField}
          />
          <TextField
            value={curral.NomeReduzido}
            className={classes.formField}
            id={"NomeReduzido"}
            required
            label={"Nome reduzido"}
            onChange={(event) => handleChangeForm("NomeReduzido", event)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            id="habilitarCurral"
            color="primary"
            onClick={() => handleExibirForm(false)}
            className={classes.corpoBotaoCancelar}
          >
            Cancelar
          </Button>
          <Button
            id="botaoAdicionar"
            disabled={
              curral.Nome === "" ||
              curral.NomeReduzido === "" ||
              curral.IdRetiro === ""
            }
            variant="contained"
            color="primary"
            onClick={() => adicionarCurral(curral)}
            className={classes.corpoBotoesForm}
          >
            {curral.IdPasto ? "Salvar" : "Adicionar"}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const styles = () => {
  return {
    corpoBotoesForm: {
      marginTop: "36px",
      width: 106,
      marginRight: "20px",
      boxShadow: "none!important",
    },
    corpoBotaoCancelar: {
      width: 90,
      marginTop: "36px",
      marginRight: "20px",
    },
    titulo: {
      fontFamily: "Roboto",
      fontSize: 20,
      fontWeight: "bold",
      color: "#212121",
      marginBottom: 0,
    },
    formContainer: {
      marginTop: "8px",

      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "32px",
    },
    formField: {
      width: 200,
      color: "#666666",
    },
    subtitulo: {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: "bold",
      color: "#757575",
      marginTop: 16,
    },
  };
};

export default withStyles(styles)(CurralForm);
