import React from "react";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core";
import history from "../../app/History";
import { changeLocation } from "../../navegacao/navegacaoUtils";
import Auth from "features/authentication/Auth";

export const logoProdap = (props) => {
  const { classes, genericClass } = props;
  return (
    <>
      <img
        id="logoFarmtellAppBar"
        onClick={() => {
          Auth.isAnalyticsUser()
            ? history.replace("/analytics")
            : changeLocation("/farol");
        }}
        src={window.location.origin + "/images/logo-farmtell-views-color-2.png"}
        alt="logo farmtell views"
        className={genericClass ? genericClass : classes.logo}
      />
    </>
  );
};
const styles = () => {
  return {
    logo: {
      width: "101px",
      height: "35px",
      cursor: "pointer",
    },
  };
};

export default withRouter(withStyles(styles)(logoProdap));
