import {
  EXIBIR_CARREGANDO,
  ALTERAR_TEXTO,
  EXIBIR_CARREGANDO_SALVANDO,
  EXIBIR_SUCESSO,
} from "./actionsConstants";

export const exibirCarregando = (carregando) => {
  return {
    type: EXIBIR_CARREGANDO,
    payload: carregando,
  };
};

export const exibirCarregandoSalvando = (carregando) => {
  return {
    type: EXIBIR_CARREGANDO_SALVANDO,
    payload: carregando,
  };
};

export const exibirSucesso = (status) => {
  return {
    type: EXIBIR_SUCESSO,
    payload: status,
  };
};

export const mudarTextoCarregando = (texto, valor) => {
  return {
    type: ALTERAR_TEXTO,
    payload: { textoCarregando: texto, valorBarraProgresso: valor },
  };
};
