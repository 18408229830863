import { useEffect, useState } from "react";

export const useExitConfirmation = ({
  goBack,
  changeTabRequested,
  onCancelChangeTab,
}) => {
  const beforeUnloadListener = (event) => {
    event.preventDefault();
    return (event.returnValue = "");
  };

  useEffect(() => {
    window.addEventListener("beforeunload", beforeUnloadListener, {
      capture: true,
    });
    return () =>
      window.removeEventListener("beforeunload", beforeUnloadListener, {
        capture: true,
      });
  }, []);

  useEffect(() => setShowExitModal(changeTabRequested), [changeTabRequested]);

  const [showExitModal, setShowExitModal] = useState(false);

  const handleExitModalCancel = (e) => {
    setShowExitModal(false);
    onCancelChangeTab();
  };

  const handleExitModalSubmmit = () => {
    setShowExitModal(false);
    goBack();
  };

  const requestExit = () => setShowExitModal(true);

  return {
    showExitModal,
    handleExitModalCancel,
    handleExitModalSubmmit,
    requestExit,
  };
};
