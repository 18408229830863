import React from "react";
import { OutlinedInput, withStyles } from "@material-ui/core";

const DisableInput = ({
  testId,
  classes,
  type,
  value,
  disabled,
  placeholder,
  onChange,
  textAlign,
}) => {
  return (
    <OutlinedInput
      data-testid={testId}
      classes={{
        input: classes.innerInput,
        disabled: classes.disabled,
        root: textAlign === "center" && classes.rootCenter,
      }}
      inputComponent={(props) => (
        <div
          {...props}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: textAlign,
          }}
        >
          {value}
        </div>
      )}
      className={classes.inputInTable}
      type={type}
      value={value}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
      variant="outlined"
    />
  );
};

const styles = (theme) => ({
  inputInTable: {
    width: 195,
    height: 53,
    "&>fieldset": {
      borderRadius: "4px",
      border: `solid 1px ${theme.palette.default2.light}`,
    },
    alignSelf: "center",
  },
  rootCenter: {
    justifyContent: "center",
    width: 195,
  },
  innerInput: {
    boxSizing: "border-box",
    borderRadius: 4,
    width: 195,
  },
  disabled: {
    height: 53,
    borderRadius: "4px",
    color: theme.palette.default1.light,
    fontSize: "0.75rem",
    backgroundColor: theme.palette.disabled.light,
    width: 195,
  },
});

export default withStyles(styles)(DisableInput);
