import React from "react";
import { Route, Switch } from "react-router-dom";
import PendencyListViewContainer from "./pendencyListView/pendencyListViewContainer";
import ContainerMovements from "features/shared/components/ContainerMovements";
import PendencyInfosContainer from "./pendencyInfosContainer";
const PendencyPage = ({ history, match }) => {
  return (
    <ContainerMovements history={history} match={match}>
      <Switch>
        <Route
          key="pendencyList"
          exact
          path={`/movimentacoesPendentes`}
          component={PendencyListViewContainer}
        />
          <Route
            key="pendencyDetails"
            exact
            path={`/movimentacoesPendentes/detalhes/:movementGrouper`}
            component={PendencyInfosContainer}
          />
        <Route
          key="pendencyedit"
          exact
          path={`/movimentacoesPendentes/:movementGrouper/edicao`}
          component={PendencyInfosContainer}
        />
      </Switch>
    </ContainerMovements>
  );
};

export default PendencyPage;
