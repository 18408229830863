import React from "react";
import { FeedBackPage, TextLabel } from "features/shared/components";
import { Grid } from "@material-ui/core";


const infoListaVazia = "Não existe nenhum lote cadastrado!";
const infoListaVaziaFiltro = "Não temos resultado para o filtro aplicado. Por favor tente novamente.";

const LotesTabelaEmpty = ({ naoTemLotesComFiltro, naoTemLotes }) => {

  const message = () => {
    if (naoTemLotes) return infoListaVazia;
    if (naoTemLotesComFiltro) return infoListaVaziaFiltro;
    return infoListaVazia;
  }

  return (
    <Grid
      container
      direction="column"
      justify="center"
      style={{
        minHeight: "40vh",
      }}
    >
      <FeedBackPage>
        <Grid container direction="column" alignItems="center">
          <img src="../images/busca.png" alt="Lupa de tela vazia" />
          <TextLabel
            id="labelNaoTemLotes"
            block
            color="default2"
            customStyle={{ paddingTop: 10, textAlign: "center" }}
          >
            {message()}
          </TextLabel>
        </Grid>
      </FeedBackPage>
    </Grid>
  );

};

export default LotesTabelaEmpty;
