import React from "react";
import { Typography, withStyles } from "@material-ui/core";

const SubTitle = (props) => {
  const { children, classes, style } = props;
  return (
    <Typography className={classes.subTitulo} style={style}>
      {children}
    </Typography>
  );
};

const styles = (theme) => ({
  subTitulo: {
    color: theme.palette.default1.light,
  },
});

export default withStyles(styles)(SubTitle);
