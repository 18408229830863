

export const ordenaListaRetiros = listaRetiros => {

  return (listaRetiros ?
    listaRetiros.sort((anterior, proximo) => proximo.IdRetiro - anterior.IdRetiro)
    :
    null);
};

export const estadoInicialListaRetiro = () => {
  return {
    valorCampoEditar: false,
    valorNovoNome: '',
    retiroSelecionadoEditar: {}
  }
};

export const atualizaCampo = (campoEditar, nomeEditado, nome) => {
  return (campoEditar && nomeEditado === nome);
}
