export const SET_PRIMEIRA_VISUALIZACAO = 'notificacao/SET_PRIMEIRA_VISUALIZACAO';
export const GET_PRIMEIRA_VISUALIZACAO = 'notificacao/GET_PRIMEIRA_VISUALIZACAO';
export const SET_VISIBILIDADE_AVISO = 'notificacao/SET_VISIBILIDADE_AVISO';

export const setPrimeiraVisualizacao = primeiraVisualizacao => {
  return {
    type: SET_PRIMEIRA_VISUALIZACAO,
    payload: { primeiraVisualizacao }
  };
};

export const setVisibilidadeAviso = visibilidadeAviso => {
  return {
    type: SET_VISIBILIDADE_AVISO,
    payload: { visibilidadeAviso }
  };
};

export const getPrimeiraVisualizacao = primeiraVisualizacao => {
  return {
    type: GET_PRIMEIRA_VISUALIZACAO,
    payload: { primeiraVisualizacao }
  };
};
