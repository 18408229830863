import { SELECIONA_ABA } from "features/manejo/redux/actions/vinculoActions";

export const initialState = {
  aba: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECIONA_ABA:
      return { ...state, aba: action.payload.aba };
    default:
      return state;
  }
};
