import React, { useEffect, useState } from "react";
import { Grid, withStyles } from "@material-ui/core";
import ForageOptions from "./ForageOptions";
import PastureOptions from "./PastureOptions";
import { ManejoTab } from "../../components/Tabs/ManejoTab";
import { ManejoTabs } from "../../components/Tabs/ManejoTabs";
import { connect } from "react-redux";
import Auth from "features/authentication/Auth";

function Registrations({ pastoSelecionado, classes, history }) {
  const [selectedTab, setSelectedTab] = useState(0);
  const {permissions} = Auth.getSession();

  useEffect(() => {
    if (pastoSelecionado.IdPasto) {
      history.replace("cadastro_editar_pasto");
    }
  }, [pastoSelecionado.IdPasto]);

  const onTabChange = (event, value) => {
    setSelectedTab(value);
  };

  const TabContainer = ({ children }) => (
    <Grid sm={12} item>
      {children}
    </Grid>
  );

  return (
    <Grid container className={classes.root}>
      <Grid sm={12} item>
        <ManejoTabs onChange={onTabChange} value={selectedTab}>
          <ManejoTab label="Pastos">Pasto</ManejoTab>
          {permissions.cadastrar_ciclo_forragem && (
            <ManejoTab label="Forragem">Forragem</ManejoTab>
          )}
        </ManejoTabs>
      </Grid>
      <Grid sm={12} item>
        {selectedTab === 0 && (
          <TabContainer>
            <PastureOptions history={history} />
          </TabContainer>
        )}
        {permissions.cadastrar_ciclo_forragem && selectedTab === 1 && (
          <TabContainer>
              <ForageOptions history={history} />
          </TabContainer>
        )}
      </Grid>
    </Grid>
  );
}

const styles = () => ({
  root: {
    padding: "32px 40px",
  },
});

const mapStateToProps = (state) => ({
  pastoSelecionado: state.pasto.PastoSelecionado,
});

export default connect(mapStateToProps)(withStyles(styles)(Registrations));
