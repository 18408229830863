import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import { TextLabel } from "../../../../../shared/components";

const detalhePeriodoForragem = (props) => {
  const { periodo, classes } = props;
  const {
    DiaInicio,
    MesInicio,
    DiaFim,
    MesFim,
    AlturaEntrada,
    AlturaSaida,
    QuantidadeDiasDescanso,
  } = periodo;

  return (
    <Grid item xs={6} md={6} className={classes.container}>
      <Grid container direction="row" alignItems="flex-start">
        <TextLabel
          color="default2"
          bold
          size={12}
          label={`${DiaInicio}/${MesInicio} a ${DiaFim}/
          ${MesFim}`}
          className={classes.linha}
        />
      </Grid>

      <Grid container direction="row" className={classes.linha}>
        <TextLabel
          label={`Entrada:`}
          color="default2"
          bold
          size={12}
          className={classes.label}
        />
        <TextLabel label={`${AlturaEntrada}cm`} bold size={12} />
      </Grid>

      <Grid container direction="row" className={classes.linha}>
        <TextLabel
          color="default2"
          bold
          size={12}
          label="Saída:"
          className={classes.label}
        />
        <TextLabel label={`${AlturaSaida}cm`} bold size={12} />
      </Grid>

      <Grid container direction="row" className={classes.linha}>
        <TextLabel
          label="Descanso:"
          bold
          size={12}
          color="default2"
          className={classes.label}
        />
        <TextLabel label={`${QuantidadeDiasDescanso} dias`} bold size={12} />
      </Grid>
    </Grid>
  );
};
const styles = {
  container: {
    marginBottom: 15,
  },
  linha: {
    marginBottom: 8,
  },
  label: {
    marginRight: 3,
  },
};
export default withStyles(styles)(detalhePeriodoForragem);
