import TableauEmbeddedService from './tableauEmbeddedService';
import { tratarRelatorios, tratarPastas } from './tableauEmbeddedUtils';

export class TableauEmbeddedManager {
  async listarRelatorios() {
    const relatorios = await TableauEmbeddedService.listarRelatorios();
    return tratarRelatorios(relatorios);
  }

  async listarPastasUsuario(user) {
    const relatorios = await TableauEmbeddedService.listarPastasUsuario(user);
    return tratarPastas(relatorios.data);
  }
}

export default new TableauEmbeddedManager();
