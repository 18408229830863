import React from 'react';
import Slide from '@material-ui/core/Slide';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  withStyles
} from '@material-ui/core/';

const transitionDialog = props => {
  return <Slide direction="up" {...props} />;
};

export const DialogoExclusaoCurral = props => {
  const { dialogAberto, aoFecharDialog,
    cancelarAcaoDialog, objetoSelecionado,
    excluir, comLote } = props;

  const confirmar = async Id => {
    await excluir(Id);
    cancelarAcaoDialog();
  };

  return (
    <Dialog
      TransitionComponent={transitionDialog}
      open={dialogAberto}
      onClose={aoFecharDialog}
      aria-labelledby="dialogTitle"
      aria-describedby="dialogDescription"
    >
      <div>
        <React.Fragment>
          <DialogTitle id="exclusao-titulo">
            {comLote ? 'Não é possível excluir esse Curral.' : 'Deseja confirmar a exclusão do curral?'}
          </DialogTitle>
          <DialogContent id="exclusao-subtitulo">
            <DialogContentText>
              {comLote
                ? 'Existem Lotes relacionados. É necessário movimentar esses lotes para um pasto.'
                : 'Ao confirmar a exclusão você não verá mais as informações deste curral.'}
            </DialogContentText>
          </DialogContent>
        </React.Fragment>
        <DialogActions>
          <Button id="btnCancelaAcaoDialog" onClick={cancelarAcaoDialog} autoFocus>
            Cancelar
          </Button>
          {comLote ? (
            <div />
          ) : (
            <Button id="btnConfirmaAcaoDialog" onClick={() => confirmar(objetoSelecionado)} color="primary">
              Sim
            </Button>
          )}
        </DialogActions>
      </div>
    </Dialog>
  );
};

DialogoExclusaoCurral.defaultProps = {
  tituloDialog: '',
  dialogAberto: false
};
const styles = theme => ({
  botaoInLine: {
    paddingBottom: 24,
    paddingRight: 24
  },
  conteudoDialog: {
    maxWidth: 340
  }
});
export default withStyles(styles)(DialogoExclusaoCurral);
