import React, { useEffect, useState } from "react";
import * as actions from "redux/actions";
import { connect } from "react-redux";
import productService from "../../productService";
import IngredientRegisterFilterPage from "./ingredientRegisterFilterPage";
import { debounce } from "features/shared/utils";

export const STATE_OPTIONS = [
  { value: 0, label: "TODOS" },
  { value: 1, label: "ATIVADOS" },
  { value: 2, label: "DESATIVADOS" },
];

const IngredientRegisterFilterContainer = ({
  notifyError,
  ingredientesPadroes,
  filtrarOperacoesIngrediente,
  isInternalUser,
  filteredIngredients,
  selectedState,
  setSelectedState,
}) => {
  const [nomeIngredienteFiltro, setNomeIngredienteFiltro] = useState("");
  const [controleDataFiltro, setControleDataFiltro] = useState(null);
  const [selecaoIngredientes, setSelecaoIngredientes] = useState([]);

  const [listAllProducts, setListAllProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productSearchText, setProductSearchText] = useState("");
  const [productSearchPage, setProductSearchPage] = useState(1);

  const ingredientesPadroesFormatados =
    ingredientesPadroes && ingredientesPadroes.items
      ? ingredientesPadroes.items.map((ingredientePadrao) => ({
          label: ingredientePadrao.Nome,
          value: ingredientePadrao.IdMateriaPrima,
        }))
      : [];

  useEffect(() => {
    if (isInternalUser) {
      getNewPageProducts();
    }
  }, []);

  const onProductChange = (newSelectedProductIds) => {
    setSelectedProducts(newSelectedProductIds);
  };

  const mapProductsList = () =>
    listAllProducts.map((product) => ({
      label: `${product.code} ${product.name}`,
      value: product.id,
    }));

  const onProductChangeSearchText = (text) => {
    setProductSearchText(text);
    debounce(() => {
      getSearchProducts(text);
      setProductSearchPage(1);
    }, 1000);
  };

  const onProductRequestData = () => {
    setProductSearchPage((previousPage) => {
      const newPage = previousPage + 1;
      getNewPageProducts(productSearchText, newPage);
      return newPage;
    });
  };

  const productFilter = {
    list: mapProductsList(),
    listPage: productSearchPage,
    selected: selectedProducts,
    searchText: productSearchText,
    onChange: onProductChange,
    onChangeSearchText: onProductChangeSearchText,
    onRequestData: onProductRequestData,
  };

  const getSearchProducts = async (filter, page = 1, size = 10) => {
    try {
      const allProductsList = await productService.getAllProducts(
        filter,
        page,
        size
      );
      setListAllProducts(allProductsList);
    } catch (error) {
      notifyError({
        mensagem: error.message,
      });
    }
  };

  const getNewPageProducts = async (filter, page = 1, size = 10) => {
    try {
      const allProductsList = await productService.getAllProducts(
        filter,
        page,
        size
      );

      setListAllProducts((listAllProducts) =>
        listAllProducts.concat(allProductsList)
      );
    } catch (error) {
      notifyError({
        mensagem: error.message,
      });
    }
  };

  const handleChangeIngredientes = (novosIngredientes) => {
    setSelecaoIngredientes(novosIngredientes);
  };

  const filtrarIngredientes = () => {
    const listaMateriaPrima =
      selecaoIngredientes.length > 0
        ? selecaoIngredientes
        : ingredientesPadroesFormatados.map(
            (ingredientePadraoFormatado) => ingredientePadraoFormatado.value
          );

    const listProductId = selectedProducts.length > 0 ? selectedProducts : null;

    let filtro = {
      ProductId: listProductId,
      IdMateriaPrima: listaMateriaPrima,
      Descricao: nomeIngredienteFiltro,
      DataCompra: controleDataFiltro
        ? controleDataFiltro.format("YYYY-MM-DD")
        : null,
    };

    filtro =
      selectedState === 0 ? filtro : { ...filtro, Ativo: selectedState === 1 };

    Object.keys(filtro).forEach((key) => {
      if (filtro[key] === null || filtro[key] === "") {
        delete filtro[key];
      }
    });
    filtrarOperacoesIngrediente(filtro);
    filteredIngredients();
  };

  const handleStateChange = (value) => {
    setSelectedState(value);
  };

  return (
    <IngredientRegisterFilterPage
      nomeIngredienteFiltro={nomeIngredienteFiltro}
      setNomeIngredienteFiltro={setNomeIngredienteFiltro}
      controleDataFiltro={controleDataFiltro}
      setControleDataFiltro={setControleDataFiltro}
      ingredientesPadroesFormatados={ingredientesPadroesFormatados}
      selecaoIngredientes={selecaoIngredientes}
      handleChangeIngredientes={handleChangeIngredientes}
      filtrarIngredientes={filtrarIngredientes}
      isInternalUser={isInternalUser}
      productFilter={productFilter}
      statesList={STATE_OPTIONS}
      selectedState={selectedState}
      handleStateChange={handleStateChange}
    />
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  notifyError: actions.notifyError,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IngredientRegisterFilterContainer);
