import React from "react";

const GoalTitleTable = ({ title, required = false }) => {
  return (
    <>
      {title}
      {required && <span style={{ color: "red" }}>*</span>}
    </>
  );
};

export default GoalTitleTable;
