import { Stepper, Step, withStyles, StepLabel } from "@material-ui/core";
import React from "react";

const STEP_LABELS = [
  "Planejamento",
  "Custo do ingrediente para o planejamento",
  "Revisar planejamento",
];

const PlanningRegisterSteps = ({
  classes,
  stepsContent = [],
  stepsActionButons = [],
  activeStep = 0,
}) => {
  const contentToRender = stepsContent[activeStep] || (() => <> </>);
  const actionButtonToRender = stepsActionButons[activeStep] || (() => <> </>);

  return (
    <div className={classes.stepContainer}>
      <Stepper
        className={classes.stepper}
        activeStep={activeStep}
        nonLinear={true}
      >
        {STEP_LABELS.map((label) => (
          <Step key={label}>
            <StepLabel
              StepIconProps={{
                classes: {
                  root: classes.stepIconRoot,
                  active: classes.stepIconActive,
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {contentToRender()}
      {actionButtonToRender()}
    </div>
  );
};

const styles = () => ({
  stepIconActive: {
    color: "#1976D2 !important",
  },
  stepContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
});

export default withStyles(styles)(PlanningRegisterSteps);
