import BaseService from "../../services/BaseService";

const URL_BASE = `${process.env.REACT_APP_ENDPOINT}consumo/api/v1/ingrediente`;
const URL_BASE_MATERIAPRIMA = `${process.env.REACT_APP_ENDPOINT}consumo/api/v1/materia-prima`;
class cadastroIngredientesService extends BaseService {
  async obterTodasOperacoesIngrediente(IdFazenda) {
    return this.get(
      `${URL_BASE}/listagem?where={"IdFazenda":${IdFazenda},"Excluido": 0}&sort=[["DataCriacao", "DESC"]]`
    );
  }

  async filtrarOperacoesIngredientes(IdFazenda, filtro) {
    const whereFiltroBase = `{"IdFazenda":${IdFazenda},"Excluido": 0`;
    let whereComposto = "";
    //gera o where com base no objeto do filtro passado
    Object.keys(filtro).forEach((key) => {
      if (key === "DataCompra")
        whereComposto = whereComposto + `,"${key}":"${filtro[key]}T00:00:00z"`;
      else if (key === "Descricao")
        whereComposto =
          whereComposto + `,"${key}":{"$like":"%25${filtro[key]}%25"}`;
      else if (key === "ProductId")
        whereComposto = whereComposto + `,"${key}":[${filtro[key]}]`;
      else if (key === "IdMateriaPrima")
        whereComposto = whereComposto + `,"${key}":[${filtro[key]}]`;
      else if (key === "Ativo")
        whereComposto = whereComposto + `,"${key}":${filtro[key]}`;
    });
    const urlFinal = `${URL_BASE}/listagem?where=${
      whereFiltroBase + whereComposto
    }}&sort=[["DataCriacao", "DESC"]]`;
    return this.get(urlFinal);
  }

  async obterTodasMateriasPrimas() {
    return this.get(
      `${URL_BASE_MATERIAPRIMA}?where={"$and":[{"Excluido": 0}]}&attributes=["IdMateriaPrima","Nome"]`
    );
  }

  async obterHistorico(IdIngrediente) {
    return this.get(
      `${URL_BASE}/historico?where={"$and":[{"IdIngrediente":${IdIngrediente}},{"Excluido": 0}]}&attributes=["UsuarioCriacao","Descricao","Custo","DataCriacao","DataCompra","ProductId"]&sort=[["DataCriacao", "DESC"]]`
    );
  }

  async criarIngrediente(ingrediente) {
    return this.post(URL_BASE, ingrediente);
  }

  async editarIngrediente(idIngrediente, ingrediente) {
    return this.put(`${URL_BASE}/${idIngrediente}`, ingrediente);
  }

  async desativaIngrediente(IdIngrediente, usuarioAlteracao) {
    return this.put(
      `${URL_BASE}/${IdIngrediente}/desativacao?usuarioAlteracao=${usuarioAlteracao}`
    );
  }

  async ativaIngrediente(IdIngrediente, usuarioAlteracao) {
    return this.put(
      `${URL_BASE}/${IdIngrediente}/ativacao?usuarioAlteracao=${usuarioAlteracao}`
    );
  }
}

export default new cadastroIngredientesService();
