import React, { Component } from "react";
import { connect } from "react-redux";
import { default as Vinculo } from "./Vinculo";

class VinculoMenuLateral extends Component {
  render() {
    return <Vinculo {...this.props.reduxProps} />;
  }
}

const mapStateToProps = ({ modulo, fazenda, pasto, cocho, cliente }) => {
  return {
    reduxProps: {
      selectedClientId:
        cliente &&
        cliente.ClienteSelecionado &&
        cliente.ClienteSelecionado.IdCliente,
      fazendaSelecionada: fazenda.FazendaSelecionada,
      retiroSelecionado: modulo.RetiroSelecionado,
      pastosSelecionados: modulo.PastosSelecionados,
      pastos: pasto.Pastos,
      cor: modulo.Cor,
      nomeModulo: modulo.Nome,
      nomeReduzido: modulo.NomeReduzido,
      moduloSelecionado: modulo.IdModulo,
      cochoSelecionado: cocho.Cocho,
    },
  };
};

export default connect(mapStateToProps)(VinculoMenuLateral);
