import {
  DESABILITA_ZOOM,
  ALTERA_ESTADO_MAPA,
  ALTERA_VALOR_ZOOM,
  VISUALIZAR_PONTOS_COLETAS,
  ALTERA_TIPO_MAPA,
} from "redux/actions/actionsConstants";

export const initialState = {
  zoomControl: true,
  scrollwheel: true,
  panControl: true,
  draggable: true,
  scaleControl: true,
  mapTypeControl: false,
  zoom: 14,
  visualizarPontosColetas: false,
  mapTypeId: "satellite",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DESABILITA_ZOOM:
      return { ...state, zoomControl: action.payload };
    case ALTERA_ESTADO_MAPA:
      return {
        ...state,
        zoomControl: action.payload.zoomControl,
        scrollwheel: action.payload.scrollwheel,
        panControl: action.payload.panControl,
        draggable: action.payload.draggable,
        scaleControl: action.payload.scaleControl,
        mapTypeControl: action.payload.mapTypeControl,
      };
    case ALTERA_VALOR_ZOOM:
      return { ...state, zoom: action.payload.zoom };
    case VISUALIZAR_PONTOS_COLETAS:
      return { ...state, visualizarPontosColetas: action.payload };
    case ALTERA_TIPO_MAPA:
      return { ...state, mapTypeId: action.payload };
    default:
      return state;
  }
};
