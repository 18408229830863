import {
  LISTA_FORMULAS,
  EXCLUIR_FORMULA,
  EXIBIR_CARREGANDO,
  DESEXCLUIR_FORMULA,
  EPOCAS_FORMULA,
  CATEGORIAS_FORMULA,
} from "../../../redux/actions/actionsConstants";

export const deletarFormula = () => {
  return {
    type: EXCLUIR_FORMULA,
  };
};

export const restaurarFormula = () => {
  return {
    type: DESEXCLUIR_FORMULA,
  };
};

export const listarFormulasFazenda = (formulas) => {
  return {
    type: LISTA_FORMULAS,
    payload: {
      FormulasFazenda: formulas,
    },
  };
};

export const listarCategoriasFormula = (categorias) => {
  return {
    type: CATEGORIAS_FORMULA,
    payload: {
      categoriasFormula: categorias,
    },
  };
};

export const listarEpocasFormula = (epocas) => {
  return {
    type: EPOCAS_FORMULA,
    payload: {
      epocasFormula: epocas,
    },
  };
};

export const exibirCarregando = (exibir) => {
  return {
    type: EXIBIR_CARREGANDO,
    payload: exibir,
  };
};
