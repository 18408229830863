import { withStyles, Button, Collapse } from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import React from "react";
import ImprimirIcon from "features/shared/components/icons/ImprimirIcon";
import { DatePicker } from "features/shared/components";
import SelectDropdown from "components/SelectDropdown";

const MovementHistoryFilter = ({
  handlerUsuariosSelecionados,
  handlerFiltroCategoriaInicial,
  handlerFiltroCategoriaFinal,
  handlerTipoOperacao,
  handlerPastoOrigemSelecionado,
  handlerPastoDestinoSelecionado,
  handlerCollapse,
  handlerDataInicial,
  handlerDataFinal,
  maxDataInicio,
  minDataInicio,
  dataInicio,
  maxDataFinal,
  dataFinal,
  listaUsuarios,
  usuariosSelecionados,
  listaTipoOperacoes,
  tiposOperacoesSelecionadas,
  collapseFilter,
  listaCategoriaAnimal,
  categoriasFinaisSelecionadas,
  categoriasIniciaisSelecionadas,
  listaPastoFazenda,
  pastoOrigemSelecionado,
  pastoDestinoSelecionado,
  executarFiltro,
  executarFiltroParaPrint,
  classes,
  retirosSelecionados,
  listaRetiros,
  handleRetirosSelecionados,
}) => {
  const erroMaxDateFinalMessage = "A data não pode ser maior que a atual.";
  const erroMinDateFinalMessage =
    "A data não pode ser menor que a data inicial de busca";
  const erroMaxDateInicialMessage =
    "A data não pode ser maior que a data final de busca";
  const erroMinDateInicialMessage = "A data não pode ser inferior a 17/08/2021";

  return (
    <div id="filtrosHistoricoFazenda" className={classes.containerMenuFiltro}>
      <div className={classes.containerFiltros}>
        <div className={classes.listaFiltros}>
          <div className={classes.filtroRow}>
            <div
              id="historico-primeiro-DataPicker"
              className={classes.camposData}
            >
              <DatePicker
                maxDate={maxDataInicio}
                minDate={minDataInicio}
                onChangeDate={(e) => handlerDataInicial(e)}
                label="Data Inicial"
                maxDateMessage={erroMaxDateInicialMessage}
                minDateMessage={erroMinDateInicialMessage}
                value={dataInicio}
              />
            </div>
            <div className={classes.camposData}>
              <DatePicker
                id="historico-segundo-DataPicker"
                minDate={dataInicio}
                maxDate={maxDataFinal}
                onChangeDate={(e) => {
                  handlerDataFinal(e);
                }}
                label="Data Final"
                maxDateMessage={erroMaxDateFinalMessage}
                minDateMessage={erroMinDateFinalMessage}
                value={dataFinal}
              />
            </div>
            <div className={classes.camposInput}>
              <SelectDropdown
                isMulti
                isClearable
                isSearchable
                label={{ text: "Usuário" }}
                options={listaUsuarios}
                value={usuariosSelecionados}
                onChange={handlerUsuariosSelecionados}
              />
            </div>
            <div className={classes.camposInput}>
              <SelectDropdown
                isMulti
                isClearable
                isSearchable
                label={{ text: "Tipo Operação" }}
                options={listaTipoOperacoes}
                value={tiposOperacoesSelecionadas}
                onChange={handlerTipoOperacao}
              />
            </div>
            <div>
              <Button
                id="botaoAplicarFiltros"
                variant="contained"
                className={classes.botaoAplicar}
                onClick={executarFiltro}
                color="primary"
              >
                Aplicar
              </Button>
              <Button
                id="botaoImprimirConciliacao"
                onClick={executarFiltroParaPrint}
                variant="contained"
                color="primary"
                className={classes.printButton}
              >
                <ImprimirIcon />
              </Button>
            </div>
          </div>
          <Collapse in={collapseFilter}>
            <div className={classes.filtroRow}>
              <div className={classes.camposInput}>
                <SelectDropdown
                  isMulti
                  isClearable
                  isSearchable
                  label={{ text: "Categoria Inicial" }}
                  options={listaCategoriaAnimal}
                  value={categoriasIniciaisSelecionadas}
                  onChange={handlerFiltroCategoriaInicial}
                />
              </div>
              <div className={classes.camposInput}>
                <SelectDropdown
                  isMulti
                  isClearable
                  isSearchable
                  label={{ text: "Categoria Final" }}
                  options={listaCategoriaAnimal}
                  value={categoriasFinaisSelecionadas}
                  onChange={handlerFiltroCategoriaFinal}
                />
              </div>
              <div className={classes.camposInput}>
                <SelectDropdown
                  isMulti
                  isClearable
                  isSearchable
                  label={{ text: "Pasto de Origem" }}
                  options={listaPastoFazenda}
                  value={pastoOrigemSelecionado}
                  onChange={handlerPastoOrigemSelecionado}
                />
              </div>
              <div className={classes.camposInput}>
                <SelectDropdown
                  isMulti
                  isClearable
                  isSearchable
                  label={{ text: "Pasto de Destino" }}
                  options={listaPastoFazenda}
                  value={pastoDestinoSelecionado}
                  onChange={handlerPastoDestinoSelecionado}
                />
              </div>
              <div className={classes.camposInput}>
                <SelectDropdown
                  isMulti
                  isClearable
                  isSearchable
                  label={{ text: "Retiro" }}
                  options={listaRetiros}
                  value={retirosSelecionados}
                  onChange={handleRetirosSelecionados}
                />
              </div>
            </div>
          </Collapse>
        </div>
      </div>
      <Button
        className={classes.actionButton}
        onClick={() => handlerCollapse(collapseFilter)}
      >
        outros Filtros
        {!collapseFilter ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
      </Button>
    </div>
  );
};

const styles = (theme) => {
  return {
    containerMenuFiltro: {
      display: "flex",
      width: "90%",
      flexDirection: "column",
      alignItems: "center",
      marginTop: 10,
      marginLeft: 46,
    },
    listaFiltros: {
      width: "100%",
      padding: "8px 0",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      gap: "32px",
    },
    containerFiltros: {
      display: "flex",
      width: "100%",
    },
    filtroRow: {
      width: "100%",

      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      columnGap: "32px",
      rowGap: "16px",
    },
    camposData: {
      minWidth: 140,
      maxWidth: 221,
      fontSize: 14,
    },
    camposInput: {
      minWidth: 221,
    },
    textMultiSelect: {
      fontSize: 15,
      color: "rgb(0, 0, 0)",
      fontFamily: "Roboto",
      "--rmsc-gray": "rgba(0, 0, 0, 0.54) !important",
      "--rmsc-border": "none !important",
      "--rmsc-main": "none !important",
      borderBottom: "1px solid rgba(0, 0, 0, 0.54)",
    },
    botaoAplicar: {
      borderRadius: "18px",
      width: 128,
      height: 52,
      boxShadow: "none",
    },
    printButton: {
      borderRadius: "18px",
      width: 56,
      height: 52,
      boxShadow: "none",
      marginLeft: 16,
    },
    actionButton: {
      fontFamily: "Roboto",
      fontSize: 14,
      letterSpacing: 1.25,
      color: theme.palette.primary.main,
      marginTop: 20,
    },
  };
};

export default withStyles(styles)(MovementHistoryFilter);
