import React, { Component } from "react";
import Icon from "@material-ui/core/Icon";
import Radio from "@material-ui/core/Radio";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";

export class RadioCor extends Component {
  render() {
    const { corSelecionada, corRadio, style, handleChange } = this.props;
    const sizeIcon = { fontSize: 40 };
    return (
      <Grid item>
        <Radio
          id="radio"
          style={{ padding: "0px 0px 0px 0px" }}
          checked={corSelecionada === corRadio}
          onChange={handleChange}
          value={corRadio}
          name="cor"
          icon={
            <Icon className={style} style={sizeIcon}>
              lens
            </Icon>
          }
          checkedIcon={
            <Icon className={style} style={sizeIcon}>
              check_circle
            </Icon>
          }
        />
      </Grid>
    );
  }
}

RadioCor.propTypes = {
  corSelecionada: PropTypes.string,
  corRadio: PropTypes.string.isRequired,
  style: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default RadioCor;
