import { withStyles } from "@material-ui/core";
import React, { useCallback } from "react";
import TabelaProdap from "features/shared/components/TabelaProdap";
import supplementationService from "../../../supplementationService";
import {
  amountTroughBagsFormat,
  pastureNamesFormat,
  troughTypeFormat,
  formulaNameFormat,
} from "../../shared/utils";
import { formatDateToFarmTimezone } from "features/collect/supplementation/supplementationUtils";

function SupplementationHistory({ supplementationId, classes }) {
  const service = useCallback(
    async (params) => {
      const page = params.offset / params.limit + 1;
      const size = params.limit;
      const result = await supplementationService.listHistorySupplementation(
        supplementationId,
        { page, size }
      );

      if (result && result.data) {
        return {
          total: result.data.total || 0,
          itens: result.data.data || [],
        };
      }
      return {
        total: 0,
        itens: [],
      };
    },
    [supplementationId]
  );

  return (
    <>
      <TabelaProdap
        servico={service}
        colunas={columns(classes)}
        tabelaEsquerda={true}
      />
    </>
  );
}

const columns = (classes) => [
  {
    id: "formulaName",
    nome: "formula",
    titulo: "Formulação",
    ordenavel: false,
    pegaValor: (row) => (
      <span className={classes.text}>{formulaNameFormat(row.formulaName)}</span>
    ),
  },
  {
    id: "pastureName",
    nome: "pasto",
    titulo: "Pasto",
    ordenavel: false,
    pegaValor: (row) => (
      <span className={classes.text}>
        {pastureNamesFormat(row.pastureName)}
      </span>
    ),
  },
  {
    id: "users",
    nome: "usuario",
    titulo: "Usuário",
    ordenavel: false,
    pegaValor: (row) => {
      const users = {
        i: row.supplementationCreationUser,
        u: row.supplementationUpdateUser,
        d: row.supplementationDeletionUser,
      };
      return (
        <span className={classes.text}>
          {users[row.operationType.toLowerCase()]}
        </span>
      );
    },
  },
  {
    id: "troughType",
    nome: "Tipo do Cocho",
    titulo: "Tipo do Cocho",
    ordenavel: false,
    pegaValor: (row) => (
      <span className={classes.text}>{troughTypeFormat(row)}</span>
    ),
  },
  {
    id: "amountTroughBags",
    nome: "Quant. Abastecida",
    titulo: "Quant. Abastecida",
    ordenavel: false,
    pegaValor: (row) => {
      return (
        <span className={classes.text}>
          {amountTroughBagsFormat(
            row.amountTroughBags,
            row.formulaFractionable
          )}
        </span>
      );
    },
  },
  {
    id: "action",
    nome: "Ação",
    titulo: "Ação",
    ordenavel: false,
    pegaValor: (row) => {
      const types = {
        i: "CRIAÇÃO",
        u: "EDIÇÃO",
        d: "EXCLUSÃO",
      };
      return (
        <span className={classes.text}>
          {types[row.operationType.toLowerCase()] || row.operationType}
        </span>
      );
    },
  },
  {
    id: "collectionDate",
    nome: "Data da Coleta",
    titulo: "Data da Ação",
    ordenavel: false,
    pegaValor: (row) => {
      const dates = {
        i: row.supplementationCreatedAt,
        u: row.supplementationUpdatedAt,
        d: row.supplementationDeletedAt,
      };
      const date = formatDateToFarmTimezone(
        dates[row.operationType.toLowerCase()]
      );
      return <span className={classes.text}>{date}</span>;
    },
  },
];

const styles = {
  text: {},
  textCenter: {
    display: "block",
    textAlign: "center",
  },
};

export default withStyles(styles)(SupplementationHistory);
