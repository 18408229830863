import React from "react";
import { Typography, withStyles } from "@material-ui/core";

const Title = ({ children, classes, style = {} }) => {
  return (
    <Typography style={style} component="div" className={classes.titulo}>
      {children}
    </Typography>
  );
};

const styles = (theme) => ({
  titulo: {
    ...theme.telas.defaultTitulo,
    display: "flex",
    alignItems: "center",
  },
});

export default withStyles(styles)(Title);
