import React, { Component } from "react";
import { connect } from "react-redux";
import * as operations from "features/retiro/redux/retiroOperations";
import SelectDropdown from "components/SelectDropdown";

class CustomDropDownContainer extends Component {
  state = {
    retiros: [],
  };
  selecionaRetiro = (IdRetiro) => {
    this.props.setRetiro(IdRetiro);
  };

  handleChange = (value) => {
    this.selecionaRetiro(value);
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.Retiros !== prevProps.Retiros &&
      this.props.Retiros.length > 0
    ) {
      this.selecionaRetiro(-1);
    }
  }

  componentDidMount() {
    this.setState({ retiros: this.props.retiros });
  }

  render() {
    const { Retiros } = this.props;

    const listaOpcoesRetiro = [
      {
        value: -1,
        label: "Todos",
      },
      {
        value: -2,
        label: "Pastos sem cadastro",
      },
    ];

    const listaRetiros = Retiros.sort((a, b) =>
      a.NomeReduzido.localeCompare(b.NomeReduzido, "pt-BR")
    ).map((retiro) => ({
      value: Number(retiro.IdRetiro),
      label: retiro.NomeReduzido || retiro.Nome,
    }));

    listaRetiros.unshift(...listaOpcoesRetiro);

    const paginaNovaFazenda = window.location.pathname.includes("nova_fazenda");
    const disabled =
      this.props.Retiros.length <= 0 ||
      !this.props.FazendaTemPastos ||
      this.props.Bloqueado ||
      paginaNovaFazenda;
    let valorSelecionado = this.props.FazendaTemPastos
      ? this.props.RetiroSelecionado
      : -2;
    if (paginaNovaFazenda) valorSelecionado = -3;

    return (
      <SelectDropdown
        isDisabled={disabled}
        options={listaRetiros}
        value={valorSelecionado}
        label={{ id: "select-retreat", text: "RETIRO" }}
        styles={{ minWidth: "180px" }}
        onChange={this.handleChange}
        size="small"
      />
    );
  }
}

function mapStateToProps({ retiro, pasto, fazenda, ui }) {
  return {
    Fazendas: fazenda.Fazendas,
    FazendaSelecionada: fazenda.FazendaSelecionada,
    Retiros: retiro.Retiros,
    Pastos: pasto.Pastos,
    Pasto: pasto.PastoSelecionado,
    RetiroSelecionado: retiro.RetiroSelecionado,
    FazendaTemPastos: fazenda.TemPastos,
    Bloqueado: !ui.Dropdown.Retiro.ativo,
  };
}

CustomDropDownContainer.defaultProps = {
  Retiros: [],
};
export default connect(mapStateToProps, operations)(CustomDropDownContainer);
