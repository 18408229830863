export const coresModulos = {
  corPastoSemModulo: 'rgb(255, 255, 255)',
  corPastoNaoCadastrado: 'rgb(255, 255, 255)',
  corPastoOutroRetiro: 'rgb(102, 102, 102)',
  corPastosCadastrados: 'rgb(0,255,255)',
  cor1: '#840000',
  cor2: '#ff4300',
  cor3: '#d18e00',
  cor4: '#389326',
  cor5: '#0e6d72',
  cor6: '#0015ff',
  cor7: '#5b0589'
};

export const cores = ['cor1', 'cor2', 'cor3', 'cor4', 'cor5', 'cor6', 'cor7'];
