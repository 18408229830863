import { EXIBIR_CARREGANDO } from "../../../redux/actions/actionsConstants";
export const LISTA_CAUSAS_MORTE = "LISTA_CAUSAS_MORTE";
export const SALVAR_CAUSAS_MORTE = "SALVAR_CAUSAS_MORTE";
export const EXCLUIR_CAUSAS_MORTE = "EXCLUIR_CAUSAS_MORTE";
export const REATIVAR_CAUSAS_MORTE = "REATIVAR_CAUSAS_MORTE";
export const EDITAR_CAUSAS_MORTE = "EDITAR_CAUSAS_MORTE";

export const listarCausasMorteFazenda = (causasMorte) => {
  return {
    type: LISTA_CAUSAS_MORTE,
    payload: { CausasMorteFazenda: causasMorte },
  };
};

export const salvarCausaMorte = (causaMorte) => {
  return {
    type: SALVAR_CAUSAS_MORTE,
    payload: causaMorte,
  };
};

export const excluirCausaMorte = () => {
  return {
    type: EXCLUIR_CAUSAS_MORTE,
  };
};

export const reativarCausaMorte = () => {
  return {
    type: REATIVAR_CAUSAS_MORTE,
  };
};

export const editarCausaMorte = () => {
  return {
    type: EDITAR_CAUSAS_MORTE,
  };
};

export const exibirCarregando = (exibir) => {
  return {
    type: EXIBIR_CARREGANDO,
    payload: exibir,
  };
};
