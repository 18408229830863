import { NOTIFICAR } from '../actionsConstants';

export function TratarErroAoObterDados(error, dispatch) {
  let mensagem = '';
  let codigoErro = '';
  const regex = /\d+/g;
  const matches = String(error).match(regex);
  if (matches && matches[0]) {
    codigoErro = matches[0];
  }
  switch (codigoErro) {
    case '404':
      mensagem = 'Desculpe, nenhum cliente encontrado. Por favor, tente novamente.';
      break;
    case '422':
      mensagem = 'Não foi possível realizar essa ação. Por favor, tente novamente.';
      break;
    default:
      mensagem = 'Ocorreu algo inesperado ao listar clientes. Por favor, tente novamente.';
  }
  dispatch({
    type: NOTIFICAR,
    payload: {
      mensagem: mensagem,
      variant: 'error',
      estilo: 'error'
    }
  });
}
