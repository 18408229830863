import React from "react";
import { withStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/InfoOutlined";

const CustomLabel = ({ classes, label, required = true, tooltip = null }) => (
  <>
    <span>{label}</span>
    {required && <span className={classes.requiredSignal}> *</span>}
    {tooltip && (
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={tooltip}
        arrow
        placement="right"
      >
        <InfoIcon className={classes.tooltipIcon} />
      </Tooltip>
    )}
  </>
);

const styles = (theme) => ({
  requiredSignal: {
    color: "#f44336",
    fontSize: "16px",
    marginRight: "2px",
  },
  tooltipIcon: {
    width: "20px",
    height: "20px",
    color: theme.palette.primary.main,
    margin: "-5px 0 0 8px",
    position: "absolute",
  },
  tooltip: {
    width: "257px",
    padding: "16px",
    borderRadius: "4px",
    backgroundColor: "#616161",
    fontFamily: "Roboto",
    fontSize: "14px",
  },
});

export default withStyles(styles)(CustomLabel);
