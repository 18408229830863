import {
  DESABILITA_ZOOM,
  ALTERA_ESTADO_MAPA,
  ALTERA_VALOR_ZOOM,
  VISUALIZAR_PONTOS_COLETAS,
  ALTERA_TIPO_MAPA,
} from "redux/actions/actionsConstants";

export const setZoomControl = (valor) => ({
  type: DESABILITA_ZOOM,
  payload: valor,
});
export const setEstadoMapa = (estado) => ({
  type: ALTERA_ESTADO_MAPA,
  payload: estado,
});

export const setZoomMapa = (zoom) => ({
  type: ALTERA_VALOR_ZOOM,
  payload: {
    zoom,
  },
});

export const setTipoMapa = (tipo) => ({
  type: ALTERA_TIPO_MAPA,
  payload: tipo,
});

export const visualizarPontosColetas = (value) => (dispatch) => {
  dispatch({ type: VISUALIZAR_PONTOS_COLETAS, payload: value });
};
