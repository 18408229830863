import React from "react";
import PropTypes from "prop-types";
import { Divider, Grid, withStyles } from "@material-ui/core";
import MensagemFazendaSemLotes from "./MensagemFazendaSemLotes";
import DetalhesCategorias from "./detalhesCategorias";
import DetalhesFazenda from "./detalhesFazenda";
import {
  obterPastosPorRetiroSelecionado,
  obterPastosComLote,
  obterSomaDeTodasAsCategorias,
  obterPastosDoRetiroComLotes,
  obterSomaDeAnimaisNoCurral,
  obterPesoMedioTodasCategorias,
} from "./detalhesRebanhoUtils";

export const detalhesRebanho = (props) => {
  const {
    classes,
    pastos,
    retiroSelecionado,
    farolPasto,
    categorias,
    currais,
    nomeFazenda,
  } = props;

  const pastosRetiro = obterPastosPorRetiroSelecionado(
    retiroSelecionado,
    pastos
  );
  const pastosComLotes = obterPastosComLote(farolPasto);
  const pastosRetiroComLotes = obterPastosDoRetiroComLotes(
    pastosRetiro,
    pastosComLotes
  );
  const quantidadeRebanhoTodasCategorias = obterSomaDeTodasAsCategorias(
    categorias,
    pastosRetiroComLotes
  );

  const curraisRetiro = obterPastosPorRetiroSelecionado(
    retiroSelecionado,
    currais
  );
  const curraisRetiroComLotes = obterPastosDoRetiroComLotes(
    curraisRetiro,
    pastosComLotes
  );
  const quantidadeRebanhoNoCurral = obterSomaDeAnimaisNoCurral(
    curraisRetiroComLotes
  );

  const fazendasSemLotes =
    categorias.length === 0 || (quantidadeRebanhoTodasCategorias === 0 && quantidadeRebanhoNoCurral === 0);

  const pesoMedioTodasCategorias =
    obterPesoMedioTodasCategorias(pastosRetiroComLotes, curraisRetiroComLotes);

  if (fazendasSemLotes) {
    return <MensagemFazendaSemLotes id="mensagemFazendaSemLotes" />;
  }

  return (
    <Grid container className={classes.gridContainer}>
      <DetalhesFazenda
        id="detalhesRebanhoFazenda"
        nomeFazenda={nomeFazenda}
        quantidadeRebanhoNoCurral={quantidadeRebanhoNoCurral}
        quantidadeRebanhoTodasCategorias={quantidadeRebanhoTodasCategorias}
        pesoMedioTodasCategorias={pesoMedioTodasCategorias}
      />
      <Divider className={classes.divider} />
      <DetalhesCategorias
        id="detalhesRebanhoCategorias"
        categorias={categorias}
        pastosRetiroComLotes={pastosRetiroComLotes}
        curraisRetiroComLotes={curraisRetiroComLotes}
      />
    </Grid>
  );
};

const styles = (theme) => ({
  gridContainer: {
    padding: 15,
  },
  divider: {
    width: "100%",
    margin: "15px 0",
  },
});

detalhesRebanho.propTypes = {
  pastos: PropTypes.array,
  currais: PropTypes.array,
  retiroSelecionado: PropTypes.number,
  farolPasto: PropTypes.array,
  categorias: PropTypes.array,
};

export default withStyles(styles)(detalhesRebanho);
