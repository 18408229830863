import React, { useMemo } from "react";
import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  withStyles,
} from "@material-ui/core";
import { Minimize } from "@material-ui/icons";
import DatePicker from "features/shared/components/DatePickers/MonthDatePicker";

const TOOLTIP_MESSAGE =
  "A data é referente a 12 meses a partir do mês escolhido.";

const GoalsForm = ({
  classes,
  goalPlanData,
  disabled,
  onChangeInitialDate,
  onChangeName,
  showDatePicker,
  setShowDatePicker,
  interceptDatePicker,
  onClickInitialDate,
}) => {
  const { name, initialDate, finalDate } = goalPlanData;
  const changeGoalPlanName = (event) => onChangeName(event.target.value);

  const onClick = (e) => {
    if (interceptDatePicker && onClickInitialDate) {
      onClickInitialDate();
    } else {
      setShowDatePicker(true);
    }
  };

  const dialogProps = useMemo(() => {
    // if (!interceptDatePicker) return null;
    return {
      open: showDatePicker,
      onDismiss: () => setShowDatePicker(false),
    };
  }, [interceptDatePicker, showDatePicker]);

  return (
    <Grid container className={classes.registerFormContainer}>
      <FormControl>
        <InputLabel
          shrink
          required
          classes={{ required: !disabled && classes.required }}
        >
          Nome do plano
        </InputLabel>
        <Input
          id="nomePlano"
          inputProps={{ "data-testid": "Goal-Plan-Name-Input" }}
          autoFocus
          required
          className={classes.inputPlanName}
          disabled={disabled}
          placeholder="Crie um nome para o plano"
          value={name}
          onChange={changeGoalPlanName}
        />
      </FormControl>
      <Grid className={classes.rangeDateContainer}>
        <DatePicker
          testid="Initial-Date-Picker"
          className={classes.datePicker}
          label="Data de início"
          required
          tooltipMessage={TOOLTIP_MESSAGE}
          value={initialDate}
          disabled={disabled}
          onChange={onChangeInitialDate}
          onClick={onClick}
          DialogProps={dialogProps}
        />
        <Minimize className={classes.separator} />
        <DatePicker
          testid="Final-Date-Picker"
          className={classes.datePicker}
          disabled
          value={finalDate}
        />
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  registerFormContainer: {
    marginTop: "25px",
    gap: "24px",
  },
  rangeDateContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    gap: "5px",
  },
  separator: {
    color: theme.palette.default1.light,
  },
  datePicker: {
    width: "150px",
  },
  inputPlanName: {
    width: "263px",
  },
  required: {
    "&>span": {
      color: "red",
    },
  },
});

export default withStyles(styles)(GoalsForm);
