/* eslint-disable */
import React, { useState } from "react";
import {
  TableCell,
  TextField,
  InputAdornment,
  withStyles,
} from "@material-ui/core";

const errorMessage = (classes, errorMessage) => (
  <p className={classes.errorMessage}>{errorMessage}</p>
);

export const campoPesoObjetivo = ({
  handleAlteraLote,
  lote,
  classes,
  mensagemPesoObjetivo,
  calculaDataParaPesoObjetivo,
}) => {
  const [error, setError] = useState("");

  const mensagemAlertaPesoObjetivo = (lote, pesoObjetivo) => {
    const nuloOuData = calculaDataParaPesoObjetivo(lote, pesoObjetivo);
    if (nuloOuData === null) {
      return null;
    } else
      return (
        <span data-testid="alertaData" className={classes.alertaData}>
          Esperado atingir em {calculaDataParaPesoObjetivo(lote, pesoObjetivo)}
        </span>
      );
  };

  const handleBlur = ({ target }) => {
    if (target.value === "0") {
      setError("O peso não pode ser zero");
    } else {
      setError("");
    }
  };

  const handleChange = ({ target }) => {
    setError("");
    handleAlteraLote(lote, "PesoObjetivo", target.value);
  };

  return (
    <div className={classes.cellWrapper}>
      <TextField
        id="txtPesoObjetivo"
        className={classes.input}
        value={lote.PesoObjetivo ? lote.PesoObjetivo : null}
        onChange={handleChange}
        onBlur={handleBlur}
        type="number"
        placeholder="Peso"
        InputProps={{
          endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
        }}
        inputProps={{
          min: 0,
        }}
        error={error}
      />
      {mensagemPesoObjetivo &&
        mensagemAlertaPesoObjetivo(lote, lote.PesoObjetivo)}

      {error && errorMessage(classes, error)}
    </div>
  );
};

const styles = {
  cellWrapper: {
    width: "100%",
    display: "flex",
    height: 84,
    maxWidth: 100,
    minWidth: 90,
    flexDirection: "column",
    position: "relative",
    justifyContent: "center",
  },
  input: {
    maxWidth: 100,
    minWidth: 90,
    marginTop: 14,
    position: "relative",
  },
  alertaData: {
    width: 170,
    height: 14,
    fontSize: 12,
    position: "absolute",
    color: "#32a07d",
    bottom: 2,
  },
  errorMessage: {
    position: "absolute",
    bottom: "-15px",
    fontSize: "10px",
    color: "red",
  },
};

const campoPesoObjetivoIgual = (prevProps, nextProps) => {
  return (
    prevProps.lote.IdLote === nextProps.lote.IdLote &&
    prevProps.lote.PesoObjetivo === nextProps.lote.PesoObjetivo
  );
};

const campoPesoObjetivoMemo = React.memo(
  campoPesoObjetivo,
  campoPesoObjetivoIgual
);

export default withStyles(styles)(campoPesoObjetivoMemo);
