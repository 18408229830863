import React from 'react';
import { connect } from 'react-redux';
import { filtraFazendaSelecionadaMemo } from './fazendaSelecionadaSelector';

export const fazendaSelecionadaContainer = props => {
  const { fazenda, render } = props;
  return <React.Fragment>{render(fazenda)}</React.Fragment>;
};

const mapStateToProps = state => {
  const fazenda = filtraFazendaSelecionadaMemo(state);
  return { fazenda };
};

export default connect(mapStateToProps)(fazendaSelecionadaContainer);
