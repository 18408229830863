import {
  NOTIFICAR,
  SALVAR_PASTO,
  CARREGAR_COORDENADAS_PASTOS,
  ERRO_VALIDAR_PASTO,
  LISTA_PASTOS,
  LISTA_CURRAIS,
  SET_LOADING,
  SET_COORDENADAS_CENTRO,
  SELECIONAR_PASTO,
  DESELECIONAR_PASTO,
  FOCAR_PASTO,
  DESFOCAR_PASTO,
  CADASTRO_PASTO_MODIFICADO,
  ERRO_SALVAR_PASTO,
  ERRO_EXCLUIR_PASTO,
  SALVAR_COORDENADAS_PASTO,
  DESENHAR_MAPA,
  LIMPAR_COORDENADAS,
  ATUALIZAR_LISTA_COORDENADAS_PASTO,
  EDITANDO_COORD_PASTO,
  EXIBIR_CARREGANDO,
  EDITANDO_COORD_E_AREA_PASTO,
  GET_PASTURES_QUANTITY,
} from "redux/actions/actionsConstants";
import _ from "lodash";
import calculaCentro from "lib/calculaCentroPoligono";
import trataErroGenerico from "lib/trataErroGenerico";
import PastoService from "services/PastoService";
import { notificaErro } from "features/notificacao/redux/notificacaoActions";
import CochoService from "services/CochoService";
import * as MapUtils from "../../views/PasturesUtils";

function trataInformacoesParaSalvarPasto(Pasto) {
  let dataPasto = infoBasicaPasto(Pasto);
  dataPasto = _.omitBy(dataPasto, _.isNil);
  dataPasto.IdPastoPrime = Pasto.IdPastoPrime !== 0 ? Pasto.IdPastoPrime : null;
  return dataPasto;
}

function infoBasicaPasto(Pasto) {
  return {
    Nome: Pasto.Nome ? Pasto.Nome.trim() : Pasto.Nome,
    NomeReduzido: Pasto.NomeReduzido
      ? Pasto.NomeReduzido.trim()
      : Pasto.NomeReduzido,
    AreaAberta: Pasto.AreaAberta,
    AreaEfetiva: Pasto.AreaEfetiva,
    PotencialDeProducao: Pasto.PotencialDeProducao,
    IdForragem: Pasto.IdForragem,
    IdModulo: Pasto.IdModulo,
    IdPastoPrime: Pasto.IdPastoPrime,
    IdRetiro: Pasto.IdRetiro,
    IdFazenda: Pasto.IdFazenda,
    Coordenadas: Pasto.Coordenadas ? Pasto.Coordenadas : null,
  };
}

function trataErro(dispatch, error) {
  trataErroGenerico(
    dispatch,
    error,
    error.message,
    ERRO_VALIDAR_PASTO,
    "Ocorreu um erro ao tentar salvar o cadastro de pasto."
  );
}

const salvarCocho = async (Pasto, CochosSalvos, Cocho) => {
  let cochoParaEnviar = {
    CochoCreep: Cocho.CochoCreep || false,
    Coberto: Cocho.Coberto,
    Comprimento: Cocho.Comprimento,
    Elevado: Cocho.Elevado,
    EstoqueFinal: Cocho.EstoqueFinal,
    IdFazenda: Pasto.IdFazenda,
  };
  const cochoAtt = CochosSalvos.find(
    (e) => cochoParaEnviar.CochoCreep === e.CochoCreep
  );
  await PastoService.atualizarOuCriarCocho(
    Pasto.IdPasto,
    cochoAtt ? cochoAtt.IdCocho : null,
    _.omitBy(cochoParaEnviar, _.isNil)
  );
};

export const salvarCadastroPasto =
  (Pasto, Cocho) => async (dispatch, getState) => {
    const { pasto } = getState();
    if (pasto.CoordenadasEditandoPasto.length > 0) {
      Pasto.Coordenadas = pasto.CoordenadasEditandoPasto;
    }

    if (pasto.AreaNovoPasto) {
      Pasto.AreaAberta = pasto.AreaNovoPasto;
    }

    const dataPasto = trataInformacoesParaSalvarPasto(Pasto, Cocho);

    try {
      dispatch({ type: EXIBIR_CARREGANDO, payload: true });
      await PastoService.atualizarPasto(Pasto.IdPasto, dataPasto);
      if (Cocho) {
        await salvarCocho(Pasto, Pasto.PastoCochos || [Pasto.Cochos], Cocho);
      }
      const response = await PastoService.listaPastosPorIdFazenda(
        Pasto.IdFazenda,
        [],
        ["Modulo", "Cocho"]
      );
      const pastosComCordenadas = _.filter(
        response.data,
        (pasto) => pasto.Coordenadas !== null
      );
      dispatch({ type: SALVAR_PASTO });
      dispatch({
        type: LISTA_PASTOS,
        payload: { Pastos: pastosComCordenadas },
      });
      dispatch({ type: CADASTRO_PASTO_MODIFICADO, payload: false });
      dispatch({ type: DESELECIONAR_PASTO });
    } catch (error) {
      if (
        error.message ===
        "Erro ao alterar o retiro de um pasto cadastrado em um módulo."
      ) {
        dispatch({
          type: ERRO_SALVAR_PASTO,
          payload: { ExcluirModuloAlterandoRetiro: true },
        });
      } else {
        trataErro(dispatch, error);
      }
    } finally {
      dispatch({ type: EXIBIR_CARREGANDO, payload: false });
    }
  };

export const novoPasto =
  (Pasto, Cocho, CochoCreep) => async (dispatch, getState) => {
    const { pasto } = getState();
    try {
      dispatch({ type: EXIBIR_CARREGANDO, payload: true });

      if (pasto.EventoMapa) {
        _.map(pasto.EventoMapa, (e) => {
          e.setMap(null);
        });
      }

      Pasto.AreaAberta = Pasto.AreaAberta
        ? Pasto.AreaAberta
        : pasto.AreaNovoPasto;

      const dataPasto = infoBasicaPasto(Pasto);
      if (
        (!dataPasto.Coordenadas || !dataPasto.Coordenadas.length > 0) &&
        pasto.CoordenadasNovoPasto.length > 0
      ) {
        dataPasto.Coordenadas = pasto.CoordenadasNovoPasto[0].Pasto;
      }

      if (!dataPasto.Coordenadas) {
        dispatch(
          notificaErro("Por gentileza, desenhe o pasto antes de salvar.")
        );
        return false;
      }

      const { data: pastoSalvo } = await PastoService.cadastrarPasto(dataPasto);

      const { data: cochosDoPasto } = await CochoService.getCochosPorIdPasto(
        pastoSalvo.IdPasto
      );

      if (Cocho) {
        await salvarCocho(pastoSalvo, cochosDoPasto, Cocho);
      }

      if (CochoCreep) {
        await salvarCocho(pastoSalvo, cochosDoPasto, CochoCreep);
      }

      const response = await PastoService.listaPastosPorIdFazenda(
        Pasto.IdFazenda,
        [],
        ["Modulo", "Cocho"]
      );
      const pastosComCordenadas = _.filter(
        response.data,
        (pasto) => pasto.Coordenadas !== null
      );
      dispatch({ type: SALVAR_PASTO });
      dispatch({
        type: LISTA_PASTOS,
        payload: { Pastos: pastosComCordenadas },
      });
      dispatch({ type: LIMPAR_COORDENADAS });
      return true;
    } catch (error) {
      trataErro(dispatch, error);
    } finally {
      dispatch({ type: EXIBIR_CARREGANDO, payload: false });
    }
  };

export const limparErroExcluirModuloPelaAlteracaoRetiro =
  () => async (dispatch) => {
    dispatch({
      type: ERRO_SALVAR_PASTO,
      payload: { ExcluirModuloAlterandoRetiro: false },
    });
  };

export const limparErroExcluirModuloPelaExclusaoPasto =
  () => async (dispatch) => {
    dispatch({
      type: ERRO_EXCLUIR_PASTO,
      payload: { ExcluirModuloAoExcluirPasto: false },
    });
  };

export const selecionarPasto = (pasto) => async (dispatch) => {
  dispatch({ type: EXIBIR_CARREGANDO, payload: true });
  try {
    dispatch({ type: FOCAR_PASTO });
    var res = await PastoService.obtemPastoPorId(pasto.IdPasto);
    pasto.Centro = calculaCentro(pasto.Coordenadas);
    res = { ...res, Selecionado: true };
    dispatch({ type: SELECIONAR_PASTO, payload: { ...res } });
  } catch (error) {
    dispatch({ type: DESFOCAR_PASTO });
    dispatch({
      type: NOTIFICAR,
      payload: {
        mensagem: "Ocorreu um erro ao selecionar pasto",
        estilo: "error",
      },
    });
  } finally {
    dispatch({ type: EXIBIR_CARREGANDO, payload: false });
  }
};

export const listaPastos = (IdFazenda, Coordenadas) => async (dispatch) => {
  try {
    const pastos = await PastoService.listaPastosPorIdFazenda(
      IdFazenda,
      [],
      ["Modulo", "Cocho"]
    );
    const pastosComCordenadas = _.filter(
      pastos.data,
      (pasto) => pasto.Coordenadas !== null
    );

    const coordenadasEhArray =
      _.isArray(Coordenadas) && Coordenadas.length && Coordenadas.length > 0;
    if (coordenadasEhArray && Coordenadas[0].lat && Coordenadas[0].lng) {
      dispatch({ type: SET_COORDENADAS_CENTRO, payload: Coordenadas[0] });
    } else {
      const todasCoordenadas = [].concat.apply(
        [],
        pastosComCordenadas.map((pasto) => pasto.Coordenadas)
      );
      if (todasCoordenadas.length) {
        const centro = await calculaCentro(todasCoordenadas);
        dispatch({ type: SET_COORDENADAS_CENTRO, payload: centro });
      }
    }

    dispatch({ type: LISTA_PASTOS, payload: { Pastos: pastosComCordenadas } });
  } catch (error) {
    dispatch(notificaErro(error));
  }
};

export const listaCurrais = (IdFazenda) => async (dispatch) => {
  try {
    const pastos = await PastoService.listaPastosPorIdFazenda(
      IdFazenda,
      [],
      ["Modulo", "Cocho"]
    );
    const currais = _.filter(
      pastos.data,
      (pasto) => pasto.Coordenadas === null && pasto.Curral === true
    );
    dispatch({ type: LISTA_CURRAIS, payload: { Currais: currais } });
  } catch (error) {
    dispatch(notificaErro(error));
  }
};

export const obtemPastosFazenda = (IdFazenda) => async (dispatch) => {
  try {
    const pastos = await PastoService.listaPastosPorIdFazenda(
      IdFazenda,
      [],
      ["Modulo", "Cocho"]
    );
    const currais = _.filter(
      pastos.data,
      (pasto) => pasto.Coordenadas !== null
    );

    dispatch({ type: LISTA_PASTOS, payload: { Pastos: currais } });
  } catch (error) {
    dispatch(notificaErro(error));
  }
};

export const carregarCoordPastos = (pastos) => (dispatch) => {
  dispatch({ type: CARREGAR_COORDENADAS_PASTOS, payload: pastos });
};

export const salvarCoordNovoPasto =
  (coordenadas, event, area) => (dispatch) => {
    const NovoPasto = { Id: event.Id, Pasto: coordenadas, Evento: event };
    dispatch({
      type: SALVAR_COORDENADAS_PASTO,
      payload: {
        Coordenadas: NovoPasto,
        Evento: event,
        Desenhando: false,
        AreaNovoPasto: area,
      },
    });
  };

export const editaCoordsEAreaPasto = (coordenadas, area) => (dispatch) => {
  dispatch({
    type: EDITANDO_COORD_E_AREA_PASTO,
    payload: {
      Coordenadas: coordenadas,
      Area: area,
    },
  });
};

export const editandoCoordenadasPasto = (coordenadas) => (dispatch) => {
  dispatch({
    type: EDITANDO_COORD_PASTO,
    payload: { Coordenadas: coordenadas },
  });
};

export const atualizaCoordenadasPasto =
  (coordenadas, id, area) => (dispatch, getState) => {
    const { pasto } = getState();

    const Pastos = pasto.CoordenadasNovoPasto;

    _.map(Pastos, (pasto) => {
      if (pasto.Id === id) {
        pasto.Pasto = coordenadas;
      }
    });
    dispatch({
      type: ATUALIZAR_LISTA_COORDENADAS_PASTO,
      payload: { Coordenadas: Pastos, AreaNovoPasto: area },
    });
  };

export const deletarPoligono = (evento, id) => (dispatch, getState) => {
  const { pasto } = getState();
  evento.setMap(null);

  const pastosDesenhados = pasto.CoordenadasNovoPasto;

  const PastosFiltrados = _.remove(pastosDesenhados, (pasto) => {
    return pasto.Id !== id;
  });
  dispatch({
    type: ATUALIZAR_LISTA_COORDENADAS_PASTO,
    payload: { Coordenadas: PastosFiltrados },
  });
};

export const habilitarDesenhar = () => (dispatch) => {
  dispatch({ type: DESENHAR_MAPA, payload: { Desenhando: true } });
};

export const desabilitarDesenhar = () => (dispatch) => {
  dispatch({ type: DESENHAR_MAPA, payload: { Desenhando: false } });
};

export const setLoading = () => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
};

export const deselecionarPasto = () => (dispatch) => {
  dispatch({ type: DESELECIONAR_PASTO });
  dispatch({ type: EDITANDO_COORD_PASTO, payload: { Coordenadas: [] } });
};

export const excluiPasto = (IdPasto, IdFazenda) => async (dispatch) => {
  try {
    await PastoService.deletarPasto(IdPasto);
    const response = await PastoService.listaPastosPorIdFazenda(
      IdFazenda,
      [],
      ["Modulo", "Cocho"]
    );
    const pastosComCordenadas = await _.filter(
      response.data,
      (pasto) => pasto.Coordenadas !== null
    );
    dispatch({ type: LISTA_PASTOS, payload: { Pastos: pastosComCordenadas } });
    dispatch({ type: CADASTRO_PASTO_MODIFICADO, payload: false });
    dispatch({ type: DESELECIONAR_PASTO });
  } catch (error) {
    dispatch(notificaErro(error));
  }
};

export const modificarCadastroPasto =
  (cadastroAlteradao) => async (dispatch) => {
    dispatch({ type: CADASTRO_PASTO_MODIFICADO, payload: cadastroAlteradao });
  };

export const getPasturesQuantity = () => async (dispatch, getState) => {
  try {
    const { fazenda, cliente } = getState();
    const selectedFarmId = fazenda.FazendaSelecionada;
    const selectedClientId =
      cliente.ClienteSelecionado && cliente.ClienteSelecionado.IdCliente;

    const pasturesQuantity = await MapUtils.getPasturesQuantity(
      selectedFarmId,
      selectedClientId
    );
    dispatch({ type: GET_PASTURES_QUANTITY, payload: { pasturesQuantity } });
  } catch (err) {
    dispatch(notificaErro(err));
  }
};
