import React, { Component } from "react";
import CurralTabela from "./curralTabela";
import CurralForm from "./curralForm";
import { filterCurralMemoize } from "../curralUtils";
import { DialogoExclusaoCurral } from "./dialogExclusaoCurral";
import {
  Grid,
  withStyles,
  Typography,
  Paper,
  FormControl,
  TextField,
  Button,
} from "@material-ui/core";

export class CurralPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtro: "",
      exibirForm: false,
      comLote: false,
      curral: {
        IdPasto: null,
        IdFazenda: props.fazendaSelecionada,
        Nome: "",
        NomeReduzido: "",
        IdRetiro: "",
        AreaAberta: null,
        AreaEfetiva: null,
        PotencialDeProducao: null,
        IdForragem: null,
        IdModulo: null,
        Coordenadas: null,
        Curral: true,
      },
      curralSelecionado: {
        IdPasto: null,
        Nome: "",
      },
      erroNome: false,
      confirmacaoExclusaoAberto: false,
    };
  }

  obterLotes = (idCurral) => {
    const { lotes } = this.props;
    let lotesDoCurral = lotes.filter(function (lote) {
      return lote.IdPasto === idCurral && lote.DataValidade === null;
    });
    return lotesDoCurral;
  };

  componentDidMount() {
    const {
      fazendaSelecionada,
      listaCurrais,
      listaRetiros,
      obterLotesPorCurral,
      currais,
    } = this.props;
    listaCurrais(fazendaSelecionada);
    listaRetiros(fazendaSelecionada);
    obterLotesPorCurral(currais);
  }

  componentDidUpdate(prevProps) {
    const { obterLotesPorCurral, currais } = this.props;
    if (prevProps.currais !== currais) {
      obterLotesPorCurral(currais);
    }
  }

  handleExibirForm = (valor) => {
    this.setState({
      curral: {
        ...this.state.curral,
        IdPasto: null,
        Nome: "",
        NomeReduzido: "",
        IdRetiro: "",
      },
      exibirForm: valor,
    });
  };

  handleChangeForm = (propertyName, event) => {
    this.setState({
      curral: { ...this.state.curral, [propertyName]: event.target.value },
    });
  };

  handleChangeNomeCurral = (event) => {
    const { currais } = this.props;
    this.setState({
      curral: { ...this.state.curral, Nome: event.target.value },
    });
    this.setState({
      erroNome: currais.some(
        (curral) =>
          curral.Nome.toUpperCase().trim() ===
          event.target.value.toUpperCase().trim()
      ),
    });
  };

  abreDialogExclusao = async (curral) => {
    let existeLote = await this.obterLotes(curral.IdPasto);
    this.setState({
      confirmacaoExclusaoAberto: true,
      curralSelecionado: curral,
      comLote: existeLote.length > 0,
    });
  };

  fechaDialogExclusao = async () => {
    this.setState({ confirmacaoExclusaoAberto: false });
    await this.props.listaCurrais(this.props.fazendaSelecionada);
  };

  habilitarEdicao = (curral) => {
    this.setState({
      exibirForm: true,
      curral: curral,
    });
  };

  excluirCurral = async (idCurral) => {
    await this.props.excluirCurral(idCurral);
  };

  adicionarCurral = async (curral) => {
    if (curral.IdPasto) {
      await this.props.editarCurral(curral);
    } else {
      await this.props.criarCurral(curral);
    }
    await this.props.listaCurrais(this.props.fazendaSelecionada);
    this.handleExibirForm(false);
  };

  render() {
    const {
      filtro,
      exibirForm,
      curral,
      erroNome,
      confirmacaoExclusaoAberto,
      comLote,
    } = this.state;

    const { classes, retiros, currais, lotes } = this.props;

    const curraisFiltrados = filterCurralMemoize(currais, filtro);

    return (
      <React.Fragment>
        <Paper square className={classes.root}>
          <Grid container className={classes.containerPage}>
            <Grid item xs={12}>
              <Typography className={classes.titulo} gutterBottom>
                Informações sobre os currais da fazenda
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formContainer}>
                <TextField
                  id="inputFiltro"
                  autoFocus
                  label={"Buscar Curral"}
                  className={classes.inputFiltro}
                  onChange={(event) =>
                    this.setState({ filtro: event.target.value })
                  }
                />
              </FormControl>
              {exibirForm ? (
                <Paper square className={classes.corpoAdicionar}>
                  <CurralForm
                    id="formAdicionar"
                    handleExibirForm={this.handleExibirForm}
                    handleChangeForm={this.handleChangeForm}
                    handleChangeNomeCurral={this.handleChangeNomeCurral}
                    adicionarCurral={this.adicionarCurral}
                    retiros={retiros}
                    curral={curral}
                    erroNome={erroNome}
                  />
                </Paper>
              ) : (
                <Button
                  id="botaoAdicionar"
                  onClick={() => this.handleExibirForm(true)}
                  variant="contained"
                  color="primary"
                  className={classes.botaoNovo}
                >
                  Novo Curral
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <div style={{ marginTop: "20px" }}>
                <CurralTabela
                  id="curralTabela"
                  abreDialogExclusao={this.abreDialogExclusao}
                  habilitarEdicao={this.habilitarEdicao}
                  currais={curraisFiltrados}
                  retiros={retiros}
                  lotes={lotes}
                />
              </div>
            </Grid>
          </Grid>
        </Paper>
        <DialogoExclusaoCurral
          id={`dialogConfirmaExclusaoCurral`}
          dialogAberto={confirmacaoExclusaoAberto}
          cancelarAcaoDialog={this.fechaDialogExclusao}
          objetoSelecionado={this.state.curralSelecionado.IdPasto}
          excluir={this.props.excluirCurral}
          comLote={comLote}
        />
      </React.Fragment>
    );
  }
}

const styles = () => {
  return {
    containerPage: {
      flexgrow: 1,
      height: "100%",
      backgroundColor: "#fff",
      width: "100%",
    },
    root: {
      width: "100%",
      height: "100%",
      overflowX: "auto",
      padding: "46px",
      zIndex: -1,
      bottom: 0,
      top: "17%",
      minHeight: "calc(100vh - 90px)",
    },
    botaoNovo: {
      borderRadius: "18px",
      float: "right",
      marginRight: 0,
      boxShadow: "none!important",
      marginTop: "34px",
      marginBottom: "15px",
    },
    corpoAdicionar: {
      width: "100%",
      marginTop: "15px",
      padding: "24px 0px 23px 32px",
    },
    titulo: {
      fontSize: "20px",
      fontWeight: "bold",
      fontStyle: "medium",
      fontStretch: "medium",
      lineHeight: "medium",
      letterSpacing: "medium",
      color: "#333333",
      marginTop: "40px",
    },
    formContainer: {
      marginTop: "20px",
    },
    inputFiltro: {
      width: "274px",
    },
  };
};

export default withStyles(styles)(CurralPage);
